import { datadogRum } from '@datadog/browser-rum'
import { AxiosError } from 'axios'
import { QueryClient } from 'react-query'

import { acpService } from '@cais-group/caisiq/util/acp-service'
import { queryDefaults } from '@cais-group/caisiq/util/default-query-function'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import {
  GetExperienceDocument,
  GetExperienceQuery,
  GetExperienceQueryVariables,
} from '@cais-group/caisiq/util/graphql/contentful'
import { createFetcher } from '@cais-group/caisiq/util/hook/use-graphql-request'
import { themeExperienceSettingsService } from '@cais-group/caisiq/util/theme-experience-settings-service'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { GeneralError } from '@cais-group/shared/util/general-error'
import { ApiPaths, User } from '@cais-group/shared/util/type/caisiq-be'
import { ExperienceData } from '@cais-group/shared/util/type/experience-data'

export const ACCESS_FORBIDDEN_STATUS_CODE = 403
export const ACCESS_FORBIDDEN_TITLE = 'You do not have access to this page.'
export const ACCESS_FORBIDDEN_DESCRIPTION =
  'Your organization may have removed access to CAIS IQ. '

const queryClient = new QueryClient({
  defaultOptions: {
    queries: queryDefaults,
  },
})

let user: User

export const loginService = {
  setDeeplink: (deeplink: string) => {
    // Ignore these deeplinks
    if (deeplink === '/' || deeplink.match(/authenticated[/]?$/)) {
      return
    }
    const now = Date.now()
    const setAs =
      Number(sessionStorage.getItem('auth0:deeplink:time')) ?? Date.now()
    // Do *not* set if this has already been set (maybe need to have expiry?)
    if (now - setAs > 10000) {
      sessionStorage.setItem('auth0:deeplink', deeplink)
      sessionStorage.setItem('auth0:deeplink:time', `${now}`)
    }
  },
  onLogin: async () => {
    if (user) {
      return Promise.resolve(user)
    }
    try {
      user = await queryClient.fetchQuery<User>(ApiPaths.getLoggedInLearner)
      experienceService.setUser(user)
      userSettingsService.initiateUser(user)
      themeExperienceSettingsService.initiateFirmExperience(user)
      previewService.setResolver(userSettingsService)
    } catch (e) {
      datadogRum.addError(e, { method: 'loginService.onLogin', user })

      const error = e as AxiosError
      if (error.response?.status === ACCESS_FORBIDDEN_STATUS_CODE) {
        throw new GeneralError(
          ACCESS_FORBIDDEN_TITLE,
          ACCESS_FORBIDDEN_DESCRIPTION
        )
      }

      throw new GeneralError(
        'User not found',
        'Unable to load user profile so cannot access app'
      )
    }
    try {
      // Fetch theme
      const data = await queryClient.fetchQuery<GetExperienceQuery>(
        ['getExperience', { id: user.experience.contentfulId }],
        createFetcher<GetExperienceQuery, GetExperienceQueryVariables>(
          GetExperienceDocument,
          {
            id: previewService.enabledFirm || user.experience.contentfulId,
            preview: previewService.enabled,
          }
        )
      )
      if (!data?.firm) {
        throw new Error(
          `Unable to find a contentful experience for '${
            previewService.enabledFirm || user.experience.contentfulId
          }'`
        )
      }
      userSettingsService.initiateFirm(data?.firm as unknown as ExperienceData)
      // This initiates a redirect login
      return acpService.setUser(user)
    } catch (e) {
      previewService.enabled = false
      previewService.enabledFirm = false

      const error = e as AxiosError
      throw new GeneralError(
        'Contentful experience not found',
        `${error.message}`
      )
    }
  },
}
