export const iconSizes = {
  tiny: 16, // 16x16
  small: 20, // 20x20
  regular: 24, // 24x24
  large: 32, // 32x32
  largest: 64, // 64x64
} as const

export type IconSize = keyof typeof iconSizes

// Note: Ordering here is from Figma (down each column, across rows)
export const iconTypes = {
  // Column One
  User: 'User',
  Email: 'Email',
  Notifications: 'Notifications',
  Calendar: 'Calendar',
  Check: 'Check',
  Eye: 'Eye',
  Link: 'Link',
  LinkOff: 'LinkOff',
  Filter: 'Filter',
  Add: 'Add',
  Remove: 'Remove',
  SortHighToLow: 'SortHighToLow',
  Comment: 'Comment',
  Target: 'Target',
  Video: 'Video',

  // Column Two
  Heart: 'Heart',
  Settings: 'Settings',
  MoreVert: 'MoreVert',
  Home: 'Home',
  Copy: 'Copy',
  Launch: 'Launch',
  BookmarkOutline: 'BookmarkOutline',
  Bookmarks: 'Bookmarks',
  BookmarksOutline: 'BookmarksOutline',
  Teams: 'Teams',
  More: 'More',
  SortLowToHigh: 'SortLowToHigh',
  SortByAlphaReverse: 'SortByAlphaReverse',
  ZoomIn: 'ZoomIn',

  // Column Three
  ArrowBackLrg: 'ArrowBackLrg',
  ArrowForwardLrg: 'ArrowForwardLrg',
  KeyboardArrowLeft: 'KeyboardArrowLeft',
  KeyboardArrowRight: 'KeyboardArrowRight',
  KeyboardArrowUp: 'KeyboardArrowUp',
  KeyboardArrowDown: 'KeyboardArrowDown',
  ArrowDropUp: 'ArrowDropUp',
  ArrowDropDown: 'ArrowDropDown',
  ArrowRight: 'ArrowRight',
  ArrowLeft: 'ArrowLeft',
  Override: 'Override',
  SortByAlpha: 'SortByAlpha',
  SortByDate: 'SortByDate',
  ZoomOut: 'ZoomOut',
  Expand: 'Expand',

  // Column Four
  Search: 'Search',
  DragIndicator: 'DragIndicator',
  Edit: 'Edit',
  InfoOutline: 'InfoOutline',
  Instituition: 'Instituition',
  Lightbulb: 'Lightbulb',
  Map: 'Map',
  ColorLens: 'ColorLens',
  Wallet: 'Wallet',
  Files: 'Files',
  Refresh: 'Refresh',
  AddToLibrary: 'AddToLibrary',
  SortByDateReverse: 'SortByDateReverse',
  SubdirectoryArrowRight: 'SubdirectoryArrowRight',

  // Column Five
  Bookmark: 'Bookmark',
  Document: 'Document',
  DocumentOutline: 'DocumentOutline',
  Clock: 'Clock',
  Apps: 'Apps',
  Event: 'Event',
  ViewList: 'ViewList',
  Exit: 'Exit',
  FileUpload: 'FileUpload',
  FileDownload: 'FileDownload',
  Build: 'Build',
  ForkedArrow: 'ForkedArrow',
  CheckFilled: 'CheckFilled',
  Sync: 'Sync',

  // Column Six
  Close: 'Close',
  Delete: 'Delete',
  DeleteOutline: 'DeleteOutline',
  Info: 'Info',
  Error: 'Error',
  CompareArrows: 'CompareArrows',
  Firm: 'Firm',
  Groups: 'Groups',
  Warning: 'Warning',
  Lock: 'Lock',
  BarChart: 'BarChart',
  Shield: 'Shield',
  HamburgerMenu: 'HamburgerMenu',
  SyncDisabled: 'SyncDisabled',

  // Column Seven
  FormatBold: 'FormatBold',
  FormatItalic: 'FormatItalic',
  FormatUnderlined: 'FormatUnderlined',
  FormatAlignLeft: 'FormatAlignLeft',
  FormatAlignCenter: 'FormatAlignCenter',
  FormatAlignRight: 'FormatAlignRight',
  FormatListNumbered: 'FormatListNumbered',
  FormatListBulleted: 'FormatListBulleted',
  FormatColorText: 'FormatColorText',
  Image: 'Image',
  EyeClosed: 'EyeClosed',
  CityBlock: 'CityBlock',
  Product: 'Product',
  CodeKey: 'CodeKey',
  PdfLibrary: 'PdfLibrary',

  // Not in grid
  LinkedIn: 'LinkedIn',
  ShoppingBasket: 'ShoppingBasket',
  FlagOutlined: 'FlagOutlined',
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
} as const

export type IconType = keyof typeof iconTypes

export default iconTypes
