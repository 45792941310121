import { ChangeEvent } from 'react'

import { BinaryInputStateToValueMapping } from '../types'

const defaultMapping: BinaryInputStateToValueMapping = {
  Indeterminate: undefined,
  On: true,
  Off: false,
}

// Maps the value emitted by the binary input change handler back to an input state.
export const getBinaryInputValue = (
  value: unknown,
  mapping: BinaryInputStateToValueMapping = defaultMapping
) => {
  const indeterminate = value === mapping.Indeterminate
  const checked = indeterminate ? mapping.Indeterminate : value === mapping.On

  return { indeterminate, checked }
}

// Transforms input event values into the values defined in the mapping
// If there is no mapping, the default change handler is passed through -
// the default correlates with our default mapping.
export const createBinaryInputChangeHandler = (
  onChange: (value: unknown) => void,
  mapping?: BinaryInputStateToValueMapping
) => {
  if (!mapping) return onChange

  return (e: ChangeEvent<HTMLInputElement>) => {
    if (mapping) {
      if (e.currentTarget.indeterminate) {
        return onChange(mapping.Indeterminate)
      }
      if (e.currentTarget.checked) {
        return onChange(mapping.On)
      }
      if (!e.currentTarget.checked) {
        return onChange(mapping.Off)
      }
    }
  }
}
