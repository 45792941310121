import styled from '@emotion/styled'
import InputLabel from '@mui/material/InputLabel'
import Tooltip from '@mui/material/Tooltip'

import { BodySmallCss } from '@cais-group/approved/ui/typography'
import { Icon } from '@cais-group/equity/atoms/icon'

const StyledInputLabel = styled(InputLabel)`
  ${BodySmallCss};
  color: rgb(var(--colors-neutral-900));
`
const StyledLabelDetailsContainer = styled.div`
  ${BodySmallCss};
  display: flex;
  margin-top: var(--s4);
  margin-bottom: var(--s4);
`

export type TextInputLabelProps = {
  label: string
  htmlFor?: string
  description?: string
  required?: boolean
  className?: string
}
export function TextInputLabel({
  htmlFor,
  label,
  description,
  required,
  className,
}: TextInputLabelProps) {
  return (
    <StyledLabelDetailsContainer>
      <StyledInputLabel
        data-testid={`${htmlFor?.replace(/\s/g, '-')}-label`}
        htmlFor={htmlFor}
        required={required}
        className={className}
      >
        {label}
      </StyledInputLabel>
      {description && (
        <Tooltip title={description} placement="top">
          <div className="my-auto ml-4 flex">
            <Icon
              size="small"
              type="InfoOutline"
              color="eq-color-neutral-400"
              data-testid={`${htmlFor}-info-icon`}
            />
          </div>
        </Tooltip>
      )}
    </StyledLabelDetailsContainer>
  )
}
