import { useFormContext } from 'react-hook-form'

import { showToast } from '@cais-group/equity/organisms/notifications'

import { extractGraphQLError, isGraphQLError } from '../backend-errors'
import type { ErrorWithResponse } from '../backend-errors'
import { useUniformContext } from '../context'
import type { UniformAny, UniformFieldRules } from '../types'
import type { UniformValues } from '../uniform'

export function useUniformSubmit<TUniformValues extends UniformValues>({
  onSubmit = () => Promise.resolve(),
  onSuccess = () => {},
  onFailure = () => {},
}: {
  onSubmit?: (
    values: TUniformValues,
    rules: Partial<Record<string, UniformFieldRules>>
  ) => Promise<UniformAny>
  onSuccess?: (response: unknown) => void
  onFailure?: (error: unknown) => void
}) {
  const methods = useFormContext()
  const uniform = useUniformContext()

  function handleGraphQLError(err: ErrorWithResponse) {
    const message =
      'There has been an error processing your request. Please try again later.'
    const gqlError = extractGraphQLError(err.response)
    if ('message' in gqlError) {
      console.error(
        'General message error from handleSubmit:',
        gqlError.message
      )
      methods.setError('root.generalServerError', {
        type: gqlError.status ? String(gqlError.status) : '400',
        message,
      })
    } else {
      if (gqlError.status === 400) {
        const types = Object.fromEntries(
          gqlError.fieldViolations.map((error) => [
            error.fieldName,
            error.message,
          ])
        )
        methods.setError('root.serverErrorForFields', { types })
      } else {
        console.error('General error from handleSubmit', gqlError.errorMessage)
        methods.setError('root.generalServerError', {
          type: String(gqlError.status),
          message,
        })
      }
    }
  }

  function handleUnknownError(error: unknown) {
    console.error('Unknown error from handleSubmit', error)
    const message =
      'If this issue still persists please contact your CAIS Client Service Representative.'
    methods.setError('root.generalServerError', { type: '500', message })
  }

  return async (values: UniformValues) => {
    try {
      const response = await onSubmit(
        values as TUniformValues,
        uniform.rules ?? {}
      )
      onSuccess(response)
    } catch (error) {
      onFailure(error)
      if (isGraphQLError(error)) {
        handleGraphQLError(error)
      } else {
        handleUnknownError(error)
      }
    }
  }
}

export function useMultistepSubmit() {
  const methods = useFormContext()

  function handleGraphQLError(err: ErrorWithResponse) {
    const message =
      'There has been an error processing your request. Please try again later.'
    const gqlError = extractGraphQLError(err.response)
    if ('message' in gqlError) {
      console.error(
        'General message error from useMultistepSubmit:',
        gqlError.message
      )
      methods.setError('root.generalServerError', {
        type: gqlError.status ? String(gqlError.status) : '400',
        message,
      })
    } else {
      if (gqlError.status === 400) {
        const types = Object.fromEntries(
          gqlError.fieldViolations.map((error) => [
            error.fieldName,
            error.message,
          ])
        )
        methods.setError('root.serverErrorForFields', { types })
      } else {
        console.error(
          'General error from useMultistepSubmit',
          gqlError.errorMessage
        )
        methods.setError('root.generalServerError', {
          type: String(gqlError.status),
          message,
        })
      }
    }
  }

  function handleUnknownError(error: unknown) {
    if (error instanceof TypeError) {
      showToast({ title: 'Oops, something went wrong!', type: 'error' })
      console.error('useMultistepSubmit', error)
      return
    }
    console.error('Unknown error from useMultistepSubmit', error)
    const message =
      'If this issue still persists please contact your CAIS Client Service Representative.'
    methods.setError('root.generalServerError', { type: '500', message })
  }

  /** in case of failed promise this will handle react-hook-form errors and error attention box */
  return async (callback: () => Promise<void>) => {
    try {
      return await callback()
    } catch (error) {
      if (isGraphQLError(error)) {
        handleGraphQLError(error)
      } else {
        handleUnknownError(error)
      }
      throw new Error('Error submitting form')
    }
  }
}
