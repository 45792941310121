import classNames from 'classnames'
import React from 'react'

import { getEnvConfig } from '@cais-group/shared/ui/env'
import { type NavItem } from '@cais-group/shared/util/entitled-nav-links'
import { useTrackItemClicked } from '@cais-group/shared/util/segment'

import { ReactComponent as CAISLogo } from './assets/cais-logo.svg'
import { ReactComponent as CaretDown } from './assets/caret-down.svg'
import { Menu } from './components/menu'
import { MobileMenu } from './components/mobile-menu'
import { NavLink } from './components/nav-link'
import { Profile } from './components/profile'
import { prepareEnvData } from './prepareEnvData'
import { Profile as ProfileType } from './types'

const EnvironmentTag = () => {
  const environmentColorMap: Record<string, string> = {
    localhost: 'bg-neutral-200 text-neutral-900',
    staging: 'bg-primary-200 text-neutral-900',
    dev: 'bg-warning-600 text-neutral-900',
    qa: 'bg-success-500 text-neutral-0',
    ephemeral: 'bg-error-500 text-neutral-0',
  }

  const environment = getEnvConfig()?.ENVIRONMENT || ''
  return ['localhost', 'dev', 'qa', 'staging', 'ephemeral'].includes(
    environment
  ) ? (
    <div className="flex items-center justify-center min-[1311px]:-ml-24 min-[1311px]:mr-32">
      <span
        className={classNames(
          'small-mono pointer-events-none flex w-fit items-center justify-center gap-8 rounded-full px-16 py-4 transition-colors',
          environmentColorMap[environment]
        )}
      >
        {environment.toUpperCase()}
      </span>
    </div>
  ) : null
}

export function MainNav({
  profile,
  navItems,
  logos = [<CAISLogo key="logo" />],
  logoHref = '/',
  isExternalNav = false,
  isContainerNav = false,
}: {
  navItems: NavItem[]
  profile: ProfileType
  logos?: React.ReactNode[]
  logoHref?: string
  isExternalNav?: boolean
  isContainerNav?: boolean
}) {
  const envData = prepareEnvData()
  const navRef = React.useRef<HTMLElement>(null)
  const [openMenu, setOpenMenu] = React.useState<string | null>(null)

  const trackClick = useTrackItemClicked()

  const handleOpenMenu = (id: string) => setOpenMenu(id)
  const handleCloseMenu = () => setOpenMenu(null)

  React.useEffect(() => {
    const handleNavLinkClick = (event: Event) => {
      const el = event.target as HTMLAnchorElement

      if (!el.hasAttributes()) {
        return
      }

      const payload: Record<string, string> = {
        section: 'Global Nav',
        click_type: 'Nav Link',
      }

      for (const attr of el.attributes) {
        if (attr.name.startsWith('data-track-')) {
          const key = attr.name.replace('data-track-', '')
          payload[key] = attr.value
        }
      }

      trackClick(payload)
    }

    const navElement = navRef.current
    if (!navElement) return

    navElement.addEventListener('click', handleNavLinkClick)
    return () => navElement.removeEventListener('click', handleNavLinkClick)
  }, [trackClick])
  return (
    <nav
      ref={navRef}
      className={classNames(
        { 'fixed left-0 right-0 top-0 z-10': isExternalNav },
        'bg-neutral-0 border-b-1 flex h-[theme(constants.mainMenu.height)px] items-center border-0 border-solid border-neutral-100 px-24 lg:px-32 xl:px-56'
      )}
      id={isContainerNav ? 'container-nav' : ''}
      role="menubar"
      aria-label="global navigation"
      data-testid="global-nav"
    >
      {/* This displays as a hamburger menu when on mobile */}
      {navItems.length ? <MobileMenu navItems={navItems} /> : null}
      {/* Logo */}
      <NavLink
        to={logoHref}
        className="mx-auto flex scale-75 items-center gap-x-16 leading-4 md:scale-100 min-[1311px]:m-0 min-[1311px]:mr-56 [&>*]:flex-none [&>*]:border-0"
        env={envData}
      >
        {logos.map((logo, index) => (
          <React.Fragment key={`$logo-${index}`}>
            {index > 0 ? (
              <span className="w-[1px] self-stretch bg-neutral-200" />
            ) : null}
            {logo}
          </React.Fragment>
        ))}
      </NavLink>
      <EnvironmentTag />
      {/* Logo */}
      {/* Nav Items */}
      <ul
        data-testid="main-nav-items"
        className="z-20 hidden flex-grow space-x-24 min-[1311px]:flex"
      >
        {navItems.map((navItem) => {
          return 'menu' in navItem ? (
            <Menu
              handleOpenMenu={handleOpenMenu}
              handleCloseMenu={handleCloseMenu}
              openMenu={openMenu}
              menu={navItem.menu}
              testId={navItem.name}
              trackMenuLabel={navItem.name}
              trigger={
                <span
                  className="body flex items-center gap-8 text-neutral-600 group-focus-within:text-neutral-900 group-hover:text-neutral-900"
                  data-menu-label
                  data-pendo={`${navItem.name.toLowerCase()}-menu`}
                >
                  {navItem.name}
                  <CaretDown
                    className="text-neutral-300 group-hover:text-inherit"
                    aria-hidden
                  />
                </span>
              }
              key={navItem.name}
              env={envData}
            />
          ) : (
            <li key={navItem.href}>
              <NavLink
                to={navItem.href}
                className="body text-neutral-600 outline-none hover:text-neutral-900 focus-visible:text-neutral-900 focus-visible:ring [&.active]:font-semibold [&.active]:text-neutral-900"
                urlType={navItem.urlType}
                env={envData}
              >
                {navItem.name}
              </NavLink>
            </li>
          )
        })}
      </ul>
      {/* Nav Items */}
      <Profile
        profile={profile}
        handleOpenMenu={handleOpenMenu}
        handleCloseMenu={handleCloseMenu}
        openMenu={openMenu}
        env={envData}
      />
    </nav>
  )
}

export default MainNav
