import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { SetterOrUpdater } from 'recoil'

import { H5StrongCss, H5Css } from '@cais-group/approved/ui/typography'
import { Button } from '@cais-group/equity/atoms/button'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING, BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { LayoutCenter } from '@cais-group/shared/ui/layout'

const CenteredColumn = styled(LayoutCenter)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  padding-top: ${SPACING.s232};
  padding-bottom: ${SPACING.s232};
  background: ${colors['eq-color-neutral-0']};
  padding-left: ${SPACING.s24};
  padding-right: ${SPACING.s24};
  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
    padding-left: ${SPACING.s32};
    padding-right: ${SPACING.s32};
  }
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    padding-left: ${SPACING.s56};
    padding-right: ${SPACING.s56};
  }
`

const StyledContainer = styled.div`
  margin-bottom: ${SPACING.s56};
  position: relative;
`

const StyledNoSearchResult = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  gap: ${SPACING.s32};
  white-space: pre;
`

const StyledClearSearchBtn = styled(Button)`
  margin-top: ${SPACING.s32};
`

const StyledSearchTermHighlight = styled.span`
  ${H5StrongCss};
`

const StyledNoResultText = styled.div`
  ${H5Css};
`

export const TableNoResults = (props: {
  searchTerm: string
  setSearchTerm?: SetterOrUpdater<string>
  tab: string
}) => {
  const { searchTerm, setSearchTerm, tab } = props
  return (
    <CenteredColumn>
      <Grid container width="100%">
        <Grid item width="100%">
          <StyledContainer>
            <StyledNoSearchResult data-testid="table-no-results-view">
              <StyledNoResultText>
                There are no {tab} matching{' '}
                <StyledSearchTermHighlight>
                  {searchTerm}
                </StyledSearchTermHighlight>
              </StyledNoResultText>
              <StyledClearSearchBtn
                variant="secondary"
                onClick={() => setSearchTerm && setSearchTerm('')}
              >
                Clear search
              </StyledClearSearchBtn>
            </StyledNoSearchResult>
          </StyledContainer>
        </Grid>
      </Grid>
    </CenteredColumn>
  )
}
