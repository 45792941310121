import {
  SearchableList,
  SearchableListProps,
} from '@cais-group/shared/ui/searchable-list'
import { SelectableListItem } from '@cais-group/shared/ui/selectable-list'

import { FilterList, FilterListProps } from './shared-ui-filter-list'

export type SearchableFilterListProps<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
> = FilterListProps<TItem, TValue> &
  Pick<
    SearchableListProps<SelectableListItem<TValue>, TValue>,
    'ListContainer' | 'className' | 'setSearchText' | 'placeholder'
  > & {
    filterFn?: (item: TItem, searchValue: string) => boolean
  }
export const SearchableFilterList = <
  TItem extends SelectableListItem<TValue>,
  TValue extends string
>({
  filterFn,
  items,
  testId,
  className,
  placeholder,
  ListContainer,
  setSearchText,
  ...rest
}: SearchableFilterListProps<TItem, TValue>) => (
  <SearchableList
    setSearchText={setSearchText}
    filterFn={filterFn}
    items={items}
    placeholder={placeholder}
    ListContainer={ListContainer}
    renderList={(listItems) => (
      <FilterList {...rest} items={listItems} testId={testId} />
    )}
    className={className}
    testId={testId}
  />
)
