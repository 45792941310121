import {
  ApiState,
  useReactQueryResultAsApiState,
} from '@cais-group/caisiq/domain/common'
import {
  GetVideoPlaylistsQuery,
  useGetVideoPlaylistsQuery,
} from '@cais-group/caisiq/util/graphql/contentful'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { TypeVideoPlaylistData } from '@cais-group/shared/util/type/video-playlist-data'

import { INDIVIDUAL_VIDEO_PROJECT } from './config'

export type VideoPlaylistsByExperienceResult = {
  firm?: {
    videoPlaylistsCollection: {
      items: Array<TypeVideoPlaylistData>
    }
    individualVideosCollection: {
      items: Array<{
        sys: {
          id: string
        }
      }>
    }
  }
}

export type VideoPlaylistsApiState = ApiState<Array<TypeVideoPlaylistData>>

export const useGetVideoPlaylists = (options?: {
  firmId?: string
  pause?: boolean
}): VideoPlaylistsApiState => {
  return useReactQueryResultAsApiState(
    useGetVideoPlaylistsQuery(
      {
        firmId: userSettingsService.themeId,
        preview: previewService.enabled,
      },
      {
        enabled: !options?.pause,
        refetchOnMount: false,
        staleTime: 1000 * 60 * 10,
      }
    ),
    (data) => extractData(data),
    'Could not load caisiqVideoPlaylistCollectionQuery'
  )
}

const extractData = (data: GetVideoPlaylistsQuery) => {
  const allowedIndividualVideoIds =
    data?.firm?.individualVideosCollection?.items.map((item) => item?.sys.id)

  const playlists = data?.firm?.videoPlaylistsCollection?.items

  const individualVideosPlaylist = playlists?.filter(
    (playlist) => playlist?.sys.id === INDIVIDUAL_VIDEO_PROJECT
  )[0]

  const trimmedIndividualVideosPlaylist =
    individualVideosPlaylist && allowedIndividualVideoIds?.length
      ? {
          ...individualVideosPlaylist,
          videosCollection: {
            items: individualVideosPlaylist.videosCollection?.items.filter(
              (video) => allowedIndividualVideoIds.includes(video?.sys.id)
            ),
          },
        }
      : individualVideosPlaylist

  const trimmedPlaylists = [
    ...(playlists?.filter(
      (playlist) => playlist?.sys.id !== INDIVIDUAL_VIDEO_PROJECT
    ) || []),
    ...(trimmedIndividualVideosPlaylist
      ? [trimmedIndividualVideosPlaylist]
      : []),
  ]

  return trimmedPlaylists as TypeVideoPlaylistData[]
}
