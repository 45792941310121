import { flow, take } from 'lodash-es'

import { ContentDataType } from '@cais-group/homepage/util/types'

import { CAROUSEL_ITEM_LIMIT } from '../constants'

import { excludeBySysId } from './exclude-by-sys-id'
import { sortContentData } from './sort-content-data'

export const excludeFeaturedItemAndExtract = flow(
  excludeBySysId<ContentDataType>,
  (c) => take(c, CAROUSEL_ITEM_LIMIT)
)

export const excludeFeaturedItemSortAndExtract = flow(
  excludeBySysId<ContentDataType>,
  sortContentData,
  (c) => take(c, CAROUSEL_ITEM_LIMIT)
)
