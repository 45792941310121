import { colors, ColorValue } from '@cais-group/equity/particles/colors'

export type Presets =
  | 'breaker-default'
  | 'hero-gradient-light'
  | 'hero-gradient-dark'
  | 'hero-solid-default'
  | 'hero-solid-brand'

export type PresetValue = {
  background: `bg-${string}`
  topStart: ColorValue
  topEnd?: ColorValue
  type: 'breaker' | 'hero'
}

export const presets: Record<Presets, PresetValue> = {
  'breaker-default': {
    type: 'breaker',
    background: 'bg-gradient-3-1',
    topStart: colors['eq-color-gradient-3-1'],
    topEnd: colors['eq-color-gradient-3-2'],
  },
  'hero-gradient-light': {
    type: 'hero',
    background: 'bg-gradient-2-1',
    topStart: colors['eq-color-gradient-2-1'],
    topEnd: colors['eq-color-gradient-2-2'],
  },
  'hero-gradient-dark': {
    type: 'hero',
    background: 'bg-gradient-3-1',
    topStart: colors['eq-color-gradient-3-1'],
    topEnd: colors['eq-color-gradient-3-2'],
  },
  'hero-solid-default': {
    type: 'hero',
    background: 'bg-neutral-0/50',
    topStart: colors['eq-color-gradient-2-1'],
  },
  'hero-solid-brand': {
    type: 'hero',
    background: 'bg-neutral-0/50',
    topStart: colors['eq-color-brand-600'],
  },
}

export const defaultPreset = 'hero-gradient-light'
