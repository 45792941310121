import { match, P } from 'ts-pattern'

import { HelperMessageProps } from '@cais-group/equity/atoms/helper-message'
import { TextInputColor } from '@cais-group/equity/atoms/text-input'

export function getTextInputColor(
  helper?: HelperMessageProps,
  color?: TextInputColor
) {
  return match(helper)
    .with({ type: 'info' }, (): TextInputColor => 'primary')
    .with({ type: P.not(P.union('info', P.nullish)) }, (helper) => helper.type)
    .otherwise(() => color)
}
