import { MouseEvent, useEffect } from 'react'

import { ModalLayout } from '@cais-group/approved/ui/modal/layout'
import { BodySmall } from '@cais-group/approved/ui/typography'
import { ConfirmModalSummaryType } from '@cais-group/caisiq/ui/modal/enable-disable-experience'

type ConfirmExperienceModalPropTypes = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  summary?: ConfirmModalSummaryType
}
export function ConfirmExperienceModal({
  open,
  onClose,
  onConfirm,
  summary,
}: ConfirmExperienceModalPropTypes) {
  const handleClosedModal = (e: globalThis.MouseEvent | MouseEvent) => {
    e.stopPropagation()
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClosedModal, false)
    }
    return () => {
      document.removeEventListener('click', handleClosedModal, false)
    }
  }, [open])

  return (
    <div onClick={handleClosedModal}>
      <ModalLayout
        open={open}
        onClose={() => onClose()}
        title={summary?.title || ''}
        actions={[
          {
            variant: 'secondary',
            handler: () => onClose(),
            text: 'Cancel',
          },
          {
            variant: 'danger',
            handler: () => {
              onConfirm()
            },
            text: summary?.confirmButtonText || '',
            testId: 'confirm-update-create-experience-firms',
          },
        ]}
        body={<BodySmall as="span">{summary?.body}</BodySmall>}
      />
    </div>
  )
}
