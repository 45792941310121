import { ProgressRing } from '@cais-group/caisiq-ui-progress-ring'
import { Course } from '@cais-group/shared/util/type/caisiq-be'

// List of course states that should display progress
const COURSE_STATES_WITH_PROGRESS = [
  'AssessmentUnlocked',
  'Learning',
  'Review',
  'Retry',
  'LearningOrAssessing',
]
interface CourseProgressStateProps {
  course: Course
  size?: 'small' | 'large'
  withCeCredits?: boolean
}
export const CourseProgressState = ({
  course,
  size = 'small',
  withCeCredits = false,
}: CourseProgressStateProps) => {
  if (!COURSE_STATES_WITH_PROGRESS.includes(course.type) || !course.progress) {
    return null
  }

  const progress = Math.round(100 * course.progress)

  const description = withCeCredits
    ? `progress to course completion and CE credits`
    : `progress to course completion`

  return (
    <ProgressRing
      data-testid="progress-ring"
      progress={progress}
      progressTooltipText={description}
      size={size}
    />
  )
}
