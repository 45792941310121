import styled from '@emotion/styled'
import MuiCard from '@mui/material/Card'
import MuiCardContent from '@mui/material/CardContent'
import dayjs from 'dayjs'

import { BodySmall, Label } from '@cais-group/approved/ui/typography'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import {
  CourseCredits,
  CreditStatus,
} from '@cais-group/shared/util/type/caisiq-be'

export interface CardCourseCeProfileProps {
  course: CourseCredits
}

const StyledMuiCard = styled(MuiCard)`
  border-top: 1px solid ${colors['eq-color-neutral-200']};
  width: calc(100vw - ${SPACING.s32} * 2) !important;
  margin-left: 0 !important;
  place-self: center;
  padding: 0;
`

const StyledMuiCardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.s4};
`

const CardRow = styled(BodySmall)<{
  status: CreditStatus | undefined
}>`
  ${({ status }) =>
    status === 'ARCHIVED' &&
    `
      color: ${colors['eq-color-neutral-500']};
      background-color: ${colors['eq-color-neutral-100']};
    `}
`

export function CeProfileCourseCard({ course }: CardCourseCeProfileProps) {
  const { courseMetadataUuid, courseName, learnedAt, cfp, cima } = course
  return (
    <StyledMuiCard
      variant="mobile-full-viewport-width"
      sx={{
        '& .MuiCardContent-root': {
          paddingTop: 'var(--s24)',
          paddingBottom: 'var(--s24)',
          paddingRight: '0px',
          paddingLeft: '0px',
        },
      }}
    >
      <StyledMuiCardContent>
        <Label>{courseName}</Label>
        <BodySmall>Course ID: {courseMetadataUuid}</BodySmall>
        <BodySmall>
          Date of Completion:{' '}
          {learnedAt ? dayjs(learnedAt).format('MM/DD/YY') : 'Not completed'}
        </BodySmall>
        <CardRow status={cfp?.status}>CFP credits: {cfp?.value || '-'}</CardRow>
        <CardRow status={cima?.status}>
          CIMA credits: {cima?.value || '-'}
        </CardRow>
      </StyledMuiCardContent>
    </StyledMuiCard>
  )
}

export default CeProfileCourseCard
