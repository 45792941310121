import { ReactElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export type WithControllerRenderListArgs<T extends string> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void
  selectedItems: T[]
}

export type WithControllerRenderList<T extends string> = ({
  onChange,
  selectedItems,
}: WithControllerRenderListArgs<T>) => ReactElement

export interface WithControllerProps<T extends string> {
  name: string
  renderList: WithControllerRenderList<T>
}
export const WithController = <T extends string>({
  name,
  renderList,
}: WithControllerProps<T>) => {
  const { getValues } = useFormContext()
  return (
    <Controller
      defaultValue={[]}
      name={name}
      render={({ field: { onChange } }) => {
        const selectedItems = getValues(name) ?? []
        return renderList({ onChange, selectedItems })
      }}
    />
  )
}
