import { ChangeEventHandler, useCallback } from 'react'

import { RadioGroup } from './radio-group'
import { RadioColor } from './types'

export type SimpleRadioGroupOption<V extends string = string> = {
  value: V
  label: string
  testId?: string
  disabled?: boolean
  color?: RadioColor
}

export function SimpleRadioGroup<V extends string = string>({
  value,
  onChange,
  options,
  color,
}: {
  value: V
  onChange: (value: V) => void
  options: SimpleRadioGroupOption<V>[]
  color?: RadioColor
}) {
  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onChange(event.target.value as V)
    },
    [onChange]
  )
  return (
    <RadioGroup color={color} value={value} onChange={onChangeHandler}>
      {options.map((option, index) => (
        <RadioGroup.Option
          value={option.value}
          selectedValue={value}
          key={index}
          data-testid={option.testId}
          disabled={option.disabled}
          color={option.color ?? color}
        >
          {option.label}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  )
}
