import { faker } from '@faker-js/faker/locale/en'
import { Sync } from 'factory.ts'

import {
  AccountV1,
  ClientsResponseV1,
  ContactReferenceV1,
  ContactResponseV1,
  IndividualAccountSubTypeV1,
  IndividualAccountV1AccountCategory,
  IndividualAccountV1AllOf,
  InvestmentEntitiesV1,
  InvestmentEntityDetailsV1,
  NonIndividualAccountSubTypeV1,
  NonIndividualAccountV1AccountCategory,
  NonIndividualAccountV1AllOf,
  PrimarySignersResponseV1,
  TaxExemptOrganizationAccountV1AccountCategory,
  TaxExemptOrganizationAccountV1AllOf,
  TaxExemptOrganizationSubTypeV1,
} from '../../generated/api.schemas'

export const accountFactory = Sync.makeFactory<
  Omit<AccountV1, 'accountCategory'>
>(() => ({
  createdAt: faker.date.past().toISOString(),
  updatedAt: faker.date.recent().toISOString(),
  householdId: faker.string.uuid(),
  id: faker.string.uuid(),
  isLatest: true,
  name: faker.company.name(),
  version: 1,
}))

export const contactResponseV1Factory = Sync.makeFactory<ContactResponseV1>({
  id: faker.string.uuid(),
  firstName: faker.person.firstName(),
  lastName: faker.person.lastName(),
  createdAt: '',
  householdId: '',
  isLatest: true,
  updatedAt: '',
  version: 1,
})

export const contactReferenceV1Factory = Sync.makeFactory<ContactReferenceV1>({
  id: faker.string.uuid(),
  version: 1,
  contactDetails: contactResponseV1Factory.build(),
})

export const clientsResponseV1Factory = Sync.makeFactory<ClientsResponseV1>({
  primaryClient: contactReferenceV1Factory.build(),
  jointClient: contactReferenceV1Factory.build(),
})

export const individualAccountFactory = Sync.makeFactoryWithRequired<
  IndividualAccountV1AllOf & {
    accountCategory: IndividualAccountV1AccountCategory
  },
  'clients'
>({
  accountCategory: 'INDIVIDUAL',
  individualAccountDetails: {},
  subType: faker.helpers.arrayElement(
    Object.values(IndividualAccountSubTypeV1)
  ),
}).combine(accountFactory)

export const primarySignersResponseV1Factory =
  Sync.makeFactory<PrimarySignersResponseV1>({
    primarySigner: contactReferenceV1Factory.build(),
  })

export const nonIndividualAccountFactory = Sync.makeFactory<
  NonIndividualAccountV1AllOf & {
    accountCategory: NonIndividualAccountV1AccountCategory
  }
>({
  accountCategory: 'NON_INDIVIDUAL',
  subType: faker.helpers.arrayElement(
    Object.values(NonIndividualAccountSubTypeV1)
  ),
}).combine(accountFactory)

export const investmentEntityDetailsV1Factory =
  Sync.makeFactory<InvestmentEntityDetailsV1>({
    investingEntityName: faker.company.name(),
  })

export const investmentEntitiesV1Factory =
  Sync.makeFactory<InvestmentEntitiesV1>({
    primaryInvestmentEntity: investmentEntityDetailsV1Factory.build(),
  })

export const taxExemptOrganizationAccountFactory = Sync.makeFactory<
  TaxExemptOrganizationAccountV1AllOf & {
    accountCategory: TaxExemptOrganizationAccountV1AccountCategory
  }
>({
  accountCategory: 'TAX_EXEMPT_ORGANIZATION',
  subType: faker.helpers.arrayElement(
    Object.values(TaxExemptOrganizationSubTypeV1)
  ),
}).combine(accountFactory)
