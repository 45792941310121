import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Row } from '@tanstack/react-table'

import { colors } from '@cais-group/equity/particles/colors'
import { CaisIqFirm } from '@cais-group/shared/util/type/caisiq-be'

interface ExpandableCellProps<TData> {
  row: Row<TData>
  onExpandClick: () => void
  color?: string
  children: React.ReactNode
}

export function ExpandableCell(props: ExpandableCellProps<CaisIqFirm>) {
  return (
    <div
      style={{
        color: props.color ? props.color : colors['eq-color-primary-600'],
      }}
      onClick={(e) => {
        e.stopPropagation()
        props.onExpandClick()
      }}
    >
      {props.children}
      {props.row.getCanExpand() ? (
        props.row.getIsExpanded() ? (
          <KeyboardArrowDown />
        ) : (
          <KeyboardArrowRight />
        )
      ) : null}
    </div>
  )
}
