import { formatNumberWithCommas } from './formatNumberWithCommas'

const defaultNumberFormatOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: true,
  signDisplay: 'auto',
}

/**
 * Formats a number as currency for display.
 *
 * @param options - An object containing the number to format as currency and formatting options (defaults to USD and no decimal places).
 * @returns The formatted currency string.
 *
 * @example
 * // Example 1: Formatting without decimal places
 * formatNumberToCurrency({ value: 1234567.11 }); // Returns "$1,234,567"
 *
 * // Example 2: Formatting with 2 decimal places
 * formatNumberToCurrency({ value: 1234567.11, maximumFractionDigits: 2 }); // Returns "$1,234,567.11"
 */
export const formatNumberToCurrency = ({
  value,
  ...rest
}: {
  value: number
} & Intl.NumberFormatOptions): string => {
  return formatNumberWithCommas(value, {
    ...defaultNumberFormatOptions,
    ...rest,
  })
}
