import type { UniformAny } from '../types'

/**
 * This function, collectAllValuesFrom, searches through a given object for a specific key, and collects all the values of this key into an array.
 * The search is performed recursively, allowing it to handle deeply nested structures.
 *
 * It accepts two parameters:
 * 1. keyword: The key to search for in the object.
 * 2. obj: An object of any type that may contain nested objects.
 *
 * The function returns an array of values of the specified key.
 *
 * For example:
 *```
 * Input:
 * keyword = "name"
 * obj = {
 *  "name": "object1",
 *  "children": [
 *    {
 *      "name": "object2",
 *      "details": {
 *        "name": "object3"
 *      }
 *    },
 *    {
 *      "name": "object4"
 *    }
 *  ]
 * }
 *```
 *```
 * Output: ["object1", "object2", "object3", "object4"]
 *```
 */
export function collectAllValuesFrom(
  keyword: string,
  obj: Record<string, UniformAny>
): UniformAny[] {
  let results: string[] = []

  // eslint-disable-next-line prefer-const
  for (let key in obj) {
    if (key === keyword) {
      results.push(obj[key])
    } else if (typeof obj[key] === 'object') {
      results = results.concat(collectAllValuesFrom(keyword, obj[key]))
    }
  }
  // BUG: https://caisgroup.atlassian.net/browse/CAS-877: produces duplicates, need to update the function...
  return Array.from(new Set(results))
}
