import styled from '@emotion/styled'
import { FunctionComponent, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SetterOrUpdater } from 'recoil'

import {
  useCourseModal,
  useGetCourseCeCredits,
} from '@cais-group/caisiq/domain/course'
import { usePatchEnrollCourse } from '@cais-group/caisiq/domain/library-course'
import { getHeaderLabel } from '@cais-group/caisiq/feature/common/get-header-label'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { FundModal } from '@cais-group/caisiq/ui/fund-modal'
import { useQueryParams } from '@cais-group/caisiq/util/hook/use-query-params'
import {
  CourseDetailModal,
  CourseModalHeader,
} from '@cais-group/caisiq-ui-modal-course-detail'
import { ModalPortal } from '@cais-group/caisiq-ui-modal-portal'
import { LayoutGrid } from '@cais-group/shared/ui/layout'
import { Fund } from '@cais-group/shared/util/type/caisiq-be'

import { FundCourse } from './caisiq-feature-funds-container'
import { FundsList } from './caisiq-feature-funds-list'

const StyledGrid = styled(LayoutGrid)`
  padding-top: var(--s24);
  padding-bottom: var(--s56);
  grid-gap: var(--s24) var(--s32);
  place-content: center;
  grid-template-columns: repeat(auto-fill, minmax(min(var(--s608), 100%), 1fr));
  @media screen and (max-width: 2000px) {
    grid-template-columns: repeat(auto-fill, minmax(min(440px, 100%), 1fr));
  }
`
StyledGrid.displayName = 'StyledGrid'

const WideModalPortal = styled(ModalPortal)`
  width: 60%;
  @media screen and (max-width: 900px) {
    width: 100%;
    scroll: auto;
  }
`

export type CourseMapIndex = {
  [key: string]: boolean
}

export interface FundsProps {
  searchTerm: string
  setSearchTerm: SetterOrUpdater<string>
  selectedFund: Fund | null
  setSelectedFund: React.Dispatch<React.SetStateAction<Fund | null>>
  funds: Fund[] | undefined
  filteredFunds: Fund[] | undefined
}

export const Funds: FunctionComponent<FundsProps> = (props) => {
  const {
    funds,
    selectedFund,
    setSelectedFund,
    filteredFunds,
    searchTerm,
    setSearchTerm,
  } = props

  const { ceCreditsAvailable } = useGetCourseCeCredits()

  const navigate = useNavigate()
  const { course: selectedCourse, openModal, closeModal } = useCourseModal()

  const { executeCourseEnrollPatch } = usePatchEnrollCourse()

  const fundId = useQueryParams().get('fundId')

  useEffect(() => {
    if (fundId) {
      funds?.forEach((fund) => {
        if (fundId === fund.fundIdentifier) {
          setSelectedFund(fund)
          navigate(ROUTES.funds, { replace: true })
        }
      })
    }
  }, [fundId, funds, navigate, setSelectedFund])

  useLayoutEffect(() => {
    window.scroll(0, 0)
  }, [])

  const onCloseFundModal = () => {
    setSelectedFund(null)
  }

  const onCloseCourseModal = () => {
    closeModal()
  }

  const onSetSelectedFund = (fund: Fund) => {
    setSelectedFund(fund)
  }

  const onSetSelectedCourse = (course: FundCourse) => {
    openModal(course)
    onCloseFundModal()
  }

  return (
    <>
      <FundsList
        filteredFunds={filteredFunds}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSetSelectedFund={onSetSelectedFund}
      />

      <WideModalPortal onClose={onCloseFundModal}>
        {selectedFund && (
          <FundModal
            fund={selectedFund}
            onSetSelectedCourse={onSetSelectedCourse}
          />
        )}
      </WideModalPortal>
      {selectedCourse && (
        <WideModalPortal
          onClose={onCloseCourseModal}
          header={
            <CourseModalHeader
              imageUrl={selectedCourse?.imageUrl || ''}
              enrolledNumber={selectedCourse?.totalEnrolled || 0}
              headerLabel={getHeaderLabel(ceCreditsAvailable(selectedCourse))}
            />
          }
        >
          <CourseDetailModal
            onSetSelectedFund={onSetSelectedFund}
            onClickEnroll={(course: FundCourse) => {
              executeCourseEnrollPatch({
                id: course.id,
                enroll: true,
              })
            }}
            ceCreditsAvailable={ceCreditsAvailable(selectedCourse)}
          />
        </WideModalPortal>
      )}
    </>
  )
}
