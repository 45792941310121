import styled from '@emotion/styled'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import { BodySmall, H6Strong } from '@cais-group/approved/ui/typography'
import { ExternalLink } from '@cais-group/shared/ui/block-link'
import { LayoutCluster } from '@cais-group/shared/ui/layout'

export type AuthorProps = {
  authorName?: string
  jobTitle?: string | null
  firm?: string | null
  avatar?: {
    altText: string
    image: {
      url: string
      width: number
      height: number
    }
  } | null
  linkedin?: {
    externalLink?: string | null
  } | null
}

const AuthorContainer = styled(LayoutCluster)`
  width: calc(((100% - var(--measure)) / 2)- 56);
  flex-wrap: nowrap;
  img {
    border-radius: var(--s56);
  }
`

const AuthorAvatar = styled.div`
  height: var(--s56);
  width: var(--s56);
  margin-right: var(--s24);
  margin-bottom: var(--s8);
`

const Linkedin = styled(LinkedInIcon)`
  margin-top: var(--s8);
`

const AuthorInformation = styled.div`
  max-width: 214px;
`

const JobDescription = styled(BodySmall)`
  color: rgb(var(--colors-neutral-600));
`

const getFallbackImage = () => {
  return {
    image: {
      url: `https://images.ctfassets.net/matahbe7799j/1QThzqDj5bnhB6QxYCKzO6/b09df65f30eb04a26ebc66009d664e64/default_author.jpg`,
      height: 56,
      width: 56,
    },
    altText: '',
  }
}

export function Author(props: AuthorProps) {
  const { authorName, jobTitle, firm, avatar, linkedin } = props
  const authorImage = avatar ? avatar : getFallbackImage()

  return (
    <AuthorContainer align="flex-start" space="0">
      <AuthorAvatar>
        <img
          src={authorImage.image.url}
          height={authorImage.image.height}
          width={authorImage.image.width}
          alt={authorImage.altText}
        />
      </AuthorAvatar>
      <AuthorInformation>
        <H6Strong as="p">{authorName ?? 'CAIS'}</H6Strong>
        <JobDescription>
          {jobTitle}
          {jobTitle && firm && ' | '}
          {firm}
        </JobDescription>
        {linkedin?.externalLink && (
          <ExternalLink href={linkedin.externalLink}>
            <Linkedin fill="rgb(var(--colors-neutral-300))" />
          </ExternalLink>
        )}
      </AuthorInformation>
    </AuthorContainer>
  )
}

export default Author
