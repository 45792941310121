import dayjs from 'dayjs'

import type { UniformAny } from '../types'
import { pathOr } from '../utils/path-or'

import type {
  ConditionValue,
  LogicalOperators,
  OperatorKeys,
  Operators,
  PreCompareOperators,
} from './operators'

function hasValue(value: unknown) {
  return (
    value !== undefined &&
    value !== null &&
    (typeof value === 'string' ? Boolean(value.length) : true) &&
    (typeof value === 'number' ? !isNaN(Number(value)) : true)
  )
}

type OptionalMessage =
  | { key: string; operator?: OperatorKeys; message?: string }
  | undefined
function operatorMessageOrUndefined(
  key: string,
  operator: OperatorKeys,
  condition: boolean,
  message?: string
): OptionalMessage {
  const defaultMessage = `Validation failed for field "${key}" with operator ${operator}`
  if (!condition) {
    return { key, operator, message: message ?? defaultMessage }
  }
  return undefined
}

const ISO_DATE_REGEX = /\d{4}-\d{2}-\d{2}/

// If the first or the last character is whitespace then the text is not well formed.
// Specifying which specific characters *are valid* will inevitably tie us in knots.
// Instead, we specify as narrowly as possible which patterns are unacceptable.
// This means that a string like "(x) \/ (x)" is "well formed",
// but that's OK until it becomes a problem.
const WELL_FORMED_TEXT_REGEX = /^[^\s](.*[^\s])?$/i

function isDate(maybeDate: unknown) {
  return typeof maybeDate === 'string' && ISO_DATE_REGEX.test(maybeDate)
}
export const preCompareFunctions: Record<
  PreCompareOperators,
  (fieldValue: unknown) => unknown
> = {
  length: (fieldValue) => {
    if (fieldValue && typeof fieldValue === 'string') {
      return fieldValue.length
    }
    if (fieldValue && Array.isArray(fieldValue)) {
      return fieldValue.length
    }
    return fieldValue
  },
  currencyToNumber: (fieldValue) => {
    if (fieldValue && typeof fieldValue === 'string') {
      // Strip no numeric values
      return fieldValue.replace(/[^0-9.-]+/g, '')
    }
    return fieldValue
  },
}

export const operatorsFns: Record<
  OperatorKeys,
  (fieldValue: unknown, conditionValue?: unknown) => boolean
> = {
  // Checks if the field value is equal to the condition value
  _eq: (fieldValue, conditionValue) => {
    if (typeof fieldValue === typeof conditionValue) {
      return fieldValue === conditionValue
    }
    return false
  },
  // Checks if the field value is not equal to the condition value
  _neq: (fieldValue, conditionValue) => {
    // If there types are not the same, then they are not equal
    if (typeof fieldValue !== typeof conditionValue) return true
    if (typeof fieldValue === typeof conditionValue) {
      return fieldValue !== conditionValue
    }
    return false
  },
  // Checks if the field value is less than the condition value
  _lt: (fieldValue, conditionValue) => {
    if (typeof fieldValue === 'number' && typeof conditionValue === 'number') {
      return fieldValue < conditionValue
    }
    return false
  },
  // Checks if the field value is less than or equal to the condition value
  _lte: (fieldValue, conditionValue) => {
    if (
      typeof Number(fieldValue) === 'number' &&
      typeof conditionValue === 'number'
    ) {
      return Number(fieldValue) <= conditionValue
    }

    if (isDate(fieldValue) && isDate(conditionValue)) {
      const pickedDate = new Date(fieldValue as string)
      const conditionDate = new Date(conditionValue as string)
      return pickedDate <= conditionDate
    }
    return false
  },
  // Checks if the field value is greater than the condition value
  _gt: (fieldValue, conditionValue) => {
    if (typeof fieldValue === 'number' && typeof conditionValue === 'number') {
      return fieldValue > conditionValue
    }

    if (isDate(fieldValue) && isDate(conditionValue)) {
      const pickedDate = new Date(fieldValue as string)
      const conditionDate = new Date(conditionValue as string)

      return pickedDate > conditionDate
    }
    return false
  },
  // Checks if the field value is greater than or equal to the condition value
  _gte: (fieldValue, conditionValue) => {
    if (
      typeof Number(fieldValue) === 'number' &&
      typeof conditionValue === 'number'
    ) {
      return Number(fieldValue) >= conditionValue
    }

    if (isDate(fieldValue) && isDate(conditionValue)) {
      const pickedDate = new Date(fieldValue as string)
      const conditionDate = new Date(conditionValue as string)
      conditionDate.setHours(0, 0, 0, 0)
      return pickedDate >= conditionDate
    }
    return false
  },
  // Checks if the field value is included in the condition value
  _in: (fieldValue, conditionValue) => {
    if (Array.isArray(conditionValue)) {
      return conditionValue.includes(fieldValue)
    }
    return false
  },
  // Checks if the field value is not included in the condition value
  _nin: (fieldValue, conditionValue) => {
    if (Array.isArray(conditionValue)) {
      return !conditionValue.includes(fieldValue)
    }
    return false
  },
  // Checks if the field value matches the regular expression provided in the condition value
  _like: (fieldValue, conditionValue) => {
    if (typeof fieldValue === 'string' && typeof conditionValue === 'string') {
      return new RegExp(conditionValue).test(fieldValue)
    }
    return false
  },
  // Checks if the field value matches the case-insensitive regular expression provided in the condition value
  _ilike: (fieldValue, conditionValue) => {
    if (typeof fieldValue === 'string' && typeof conditionValue === 'string') {
      return new RegExp(conditionValue, 'i').test(fieldValue)
    }
    return false
  },
  // Checks if the field value is null
  _is_null: (fieldValue) => !hasValue(fieldValue),
  // Checks if the field value is not null
  _not_null: (fieldValue) => hasValue(fieldValue),
  // Checks if the field value contains the condition value
  _contains: (fieldValue, conditionValue) => {
    if (typeof fieldValue === 'string' && typeof conditionValue === 'string') {
      return fieldValue.includes(conditionValue)
    }
    if (Array.isArray(fieldValue)) {
      return fieldValue.includes(conditionValue)
    }
    return false
  },
  _not_contain: (fieldValue, conditionValue) => {
    if (typeof fieldValue === 'string' && typeof conditionValue === 'string') {
      return !fieldValue.includes(conditionValue)
    }
    if (Array.isArray(fieldValue)) {
      return !fieldValue.includes(conditionValue)
    }
    return false
  },
  // Checks if the field value starts with the condition value
  _starts_with: (fieldValue, conditionValue) => {
    if (typeof fieldValue === 'string' && typeof conditionValue === 'string') {
      return fieldValue.startsWith(conditionValue)
    }
    return false
  },
  // Checks if the field value ends with the condition value
  _ends_with: (fieldValue, conditionValue) => {
    if (typeof fieldValue === 'string' && typeof conditionValue === 'string') {
      return fieldValue.endsWith(conditionValue)
    }
    return false
  },
  _is_date: (fieldValue) => {
    if (typeof fieldValue === 'string') {
      return dayjs(fieldValue).isValid()
    }
    return false
  },
  _is_well_formed_text: (fieldValue) => {
    if (typeof fieldValue === 'string') {
      return WELL_FORMED_TEXT_REGEX.test(fieldValue)
    }

    return false
  },
}

export type OperatorsLayer<TPossibleEntries extends keyof UniformAny> = Partial<
  Operators<TPossibleEntries>
>

type LogicalOperatorsLayer<TPossibleEntries extends keyof UniformAny> = Partial<
  LogicalOperators<TPossibleEntries>
>

export type WhereInput<TPossibleEntries extends keyof UniformAny> =
  LogicalOperatorsLayer<TPossibleEntries> &
    OperatorsLayer<TPossibleEntries> &
    Partial<Record<TPossibleEntries, OperatorsLayer<TPossibleEntries>>>

function executeWhere<TPossibleEntries extends keyof UniformAny>(
  where: WhereInput<TPossibleEntries>,
  data: Record<string, UniformAny>,
  fieldKey: string = '',
  prefix: string = ''
): { messages: OptionalMessage[]; allPassed: boolean } {
  if (where._and) {
    const result = where._and.value.map((condition) =>
      executeWhere(condition, data, fieldKey, prefix)
    )
    return {
      messages: where._and?.message
        ? [
            {
              key: fieldKey,
              message: where._and?.message,
            },
          ]
        : result.flatMap((r) => r.messages),
      allPassed: result.flatMap((r) => r.allPassed).every(Boolean),
    }
  }
  if (where._or) {
    const result = where._or.value.map((condition) =>
      executeWhere(condition, data, fieldKey, prefix)
    )
    return {
      messages: where._or?.message
        ? [
            {
              key: fieldKey,
              message: where._or?.message,
            },
          ]
        : result.flatMap((r) => r.messages),
      allPassed: result.flatMap((r) => r.allPassed).some(Boolean),
    }
  }
  if (where._not) {
    const result = where._not.map((condition) =>
      executeWhere(condition, data, fieldKey, prefix)
    )
    return {
      messages: result.flatMap((r) => r.messages),
      allPassed: result.flatMap((r) => r.allPassed).every((passed) => !passed),
    }
  }

  const messages: OptionalMessage[] = []

  /** key as remote field id */
  Object.entries(where)
    .filter(([key]) => !key.startsWith('_'))
    .forEach(([key, value]) => {
      const fieldCondition = value as OperatorsLayer<UniformAny>
      if (!fieldCondition) {
        throw new Error(`Condition for field "${key}" is missing`)
      }
      const prefixKey = key.replace(/\[prefix\]/, prefix ? `${prefix}.` : '')
      const fieldValue = pathOr(undefined, prefixKey.split('.'), data)
      const operatorKeys = Object.keys(
        fieldCondition
      ) as unknown as OperatorKeys

      for (const operator of operatorKeys) {
        const condition = fieldCondition[operator as OperatorKeys]
        if (!condition) {
          throw new Error(`Condition for operator "${operator}" is missing`)
        }
        pushOperatorMessage(
          condition,
          operator as OperatorKeys,
          prefixKey,
          fieldValue,
          messages,
          data,
          prefix
        )
      }
    })

  /** key as operator */
  Object.entries(where)
    .filter(([key]) => key.startsWith('_'))
    .forEach(([operator, condition]) => {
      const prefixKey = prefix ? `${prefix}.${fieldKey}` : fieldKey
      const fieldValue = pathOr(undefined, prefixKey.split('.'), data)
      pushOperatorMessage(
        condition as ConditionValue<UniformAny>,
        operator as OperatorKeys,
        prefixKey,
        fieldValue,
        messages,
        data,
        prefix
      )
    })

  return { messages, allPassed: messages.filter(Boolean).length === 0 }
}

export function evaluateWhereFilters<TPossibleEntries extends keyof UniformAny>(
  where: WhereInput<TPossibleEntries>,
  data: Record<string, UniformAny>,
  fieldKey: string = '',
  prefix: string = ''
): { messages: OptionalMessage[]; allPassed: boolean } {
  // NOTE: typeof where checks are needed to support requiredIf boolean or string.
  if (typeof where === 'boolean') {
    return { allPassed: where, messages: [] }
  }
  // NOTE: if where is string, we return true here because string means required. Messages are not used in this case because it's handled by react hook form required prop.
  if (typeof where === 'string') {
    return { allPassed: true, messages: [] }
  }

  const result = Object.entries(where).map(([operator, value]) =>
    executeWhere(
      { [operator]: value } as WhereInput<TPossibleEntries>,
      data,
      fieldKey,
      prefix
    )
  )

  return {
    messages: result.flatMap((item) => item.messages).filter(Boolean),
    allPassed: result.every((item) => item.allPassed),
  }
}

function pushOperatorMessage(
  condition: ConditionValue<UniformAny>,
  operator: OperatorKeys,
  fieldKey: string,
  fieldValue: UniformAny,
  messages: OptionalMessage[],
  data: Record<string, UniformAny>,
  prefix?: string
) {
  const operatorFn = operatorsFns[operator]
  if (typeof operatorFn !== 'function') {
    throw new Error(`Operator "${operator}" is not supported`)
  }
  let conditionValue
  if (condition.field) {
    const prefixField = condition.field.replace(
      /\[prefix\]/,
      prefix ? `${prefix}.` : ''
    )

    conditionValue = pathOr(undefined, prefixField.split('.'), data)
  } else {
    conditionValue = condition?.value
  }
  let fieldValueToCompare = fieldValue
  if (condition.compareFunction) {
    fieldValueToCompare =
      preCompareFunctions[condition.compareFunction](fieldValue)
  }

  const result = operatorFn(fieldValueToCompare, conditionValue)
  messages.push(
    operatorMessageOrUndefined(fieldKey, operator, result, condition.message)
  )
}
