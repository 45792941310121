import { useReactQueryResultAsApiState } from '@cais-group/caisiq/domain/common'
import {
  GetArticlesByFirmQuery,
  useGetArticlesByFirmQuery,
} from '@cais-group/caisiq/util/graphql/contentful'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { GeneralError } from '@cais-group/shared/util/general-error'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'

export type ArticlesByExperienceResult = {
  firm?: {
    articlesCollection: {
      items: Array<TypeArticleData>
    }
  }
}

export const useGetArticles = (options?: { pause?: boolean }) => {
  const firmId = userSettingsService.themeId

  return useReactQueryResultAsApiState<
    GetArticlesByFirmQuery,
    TypeArticleData[]
  >(
    useGetArticlesByFirmQuery(
      { firmId: firmId || '', preview: previewService.enabled },
      {
        enabled: !(firmId === undefined || options?.pause),
      }
    ),
    (data) => {
      if (!data.firm) {
        throw new GeneralError(
          'Articles not found',
          `Unable to load articles for the firm ${firmId}`
        )
      }
      return data.firm?.articlesCollection?.items as TypeArticleData[]
    },
    'Could not load articlesCollectionQuery'
  )
}
