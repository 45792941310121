import styled from '@emotion/styled'

import { H4, BodySmall } from '@cais-group/approved/ui/typography'
import { Navigation, NavTheme } from '@cais-group/caisiq/ui/navigation'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { FooterDefault } from '@cais-group/shared/ui/footer/default'
import { LayoutCenter } from '@cais-group/shared/ui/layout'

const OuterContainer = styled.div`
  overflow: hidden;
`

const Container = styled.div`
  background-color: rgb(var(--colors-neutral-0));
  padding: var(--s144) var(--s16) var(--s144) var(--s16);
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    padding: var(--s144);
  }
`

const Description = styled(BodySmall)`
  margin-top: var(--s24);
`

const CircleBg = styled.div`
  background-color: rgb(var(--colors-warning-600));
  height: 186px;
  width: calc(186px * 2);
  border-top-left-radius: calc(186px * 2);
  border-top-right-radius: calc(186px * 2);
  position: relative;
  left: 65%;
`

export const LoginError = () => {
  return (
    <OuterContainer>
      {/* TODO Confirm that we do not actually use this component anymore and remove it if it is not used. if still used, do we need to use the legacy Navigation? */}
      <Navigation headerThemeName={NavTheme.WithIframe} />
      <Container>
        <LayoutCenter centerText={true}>
          <H4>You need to log in to access this page.</H4>
          <Description>Please log in and try again.</Description>
        </LayoutCenter>
      </Container>
      <CircleBg></CircleBg>
      <FooterDefault />
    </OuterContainer>
  )
}
