import styled, { CSSObject } from '@emotion/styled'

import {
  SubtitleMonoCss,
  CaptionMonoCSS,
} from '@cais-group/approved/ui/typography'

type CSSProps = {
  backgroundColor?: CSSObject['backgroundColor']
}

export type PillProps = CSSProps & {
  label: string | number | React.ReactElement
  size?: 'small' | 'large'
}

const BasePill = styled.span<CSSProps>`
  align-self: flex-start;
  flex-grow: 0;
  display: inline-block;
  border-radius: 20px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'var(--color-white)'};
  border: 1px solid
    ${({ backgroundColor }) => backgroundColor || 'var(--color-gray-14)'};
`

const SmallPill = styled(BasePill)`
  ${CaptionMonoCSS}
  padding: var(--s4) 10px;
`

const LargePill = styled(BasePill)`
  ${SubtitleMonoCss}
  padding: var(--s4) 10px;
`

export const Pill = (props: PillProps) => {
  const { label, size, ...restProps } = props
  return size === 'small' ? (
    <SmallPill data-testid="small-pill" {...restProps}>
      {label}
    </SmallPill>
  ) : (
    <LargePill data-testid="large-pill" {...restProps}>
      {label}
    </LargePill>
  )
}
