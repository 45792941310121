import { isArray, isPlainObject } from 'lodash-es'
import { useCallback } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'

import { APPS } from '@cais-group/shared/domain/apps'

import { TrackArgs, useTrack } from './use-track'

export type NullableValue<T = unknown> = null | T

type FilterEvents = Record<string, string> & {
  CLEAR_ALL_FILTERS: 'Clear All Filters'
}
type EventTrigger = FilterEvents[keyof FilterEvents]

export interface UseFilterTrackProps<
  FiltersFormInputs extends FieldValues,
  SegmentFilterDrawerState extends Record<string, NullableValue> // null clears the value from segment
> {
  app: APPS
  primaryTab: string
  FILTER_EVENTS: FilterEvents
  transformFormInputsToSegmentState: (
    formInputs: FiltersFormInputs
  ) => SegmentFilterDrawerState
  calculateNumberOfFiltersSelected: (formInputs: FiltersFormInputs) => Record<
    string,
    number
  > & {
    total: number
    totalTypes: number
  }
  // Sometimes you don't want to send everything in the state to segment
  // This function allows you to filter or transform the state before sending it to segment
  transformSegmentState?: (
    currentState: SegmentFilterDrawerState
  ) => Partial<SegmentFilterDrawerState>
}

export type UseFilterTrackReturnType<
  SegmentFilterDrawerState extends Record<string, NullableValue>
> = {
  filterTrack: (
    event_trigger: string,
    mergePayload?: Partial<SegmentFilterDrawerState>
  ) => void
}

export const useFilterTrack = <
  FiltersFormInputs extends FieldValues,
  SegmentFilterDrawerState extends Record<string, NullableValue>
>({
  app,
  primaryTab,
  FILTER_EVENTS,
  transformFormInputsToSegmentState,
  calculateNumberOfFiltersSelected,
  transformSegmentState,
}: UseFilterTrackProps<
  FiltersFormInputs,
  SegmentFilterDrawerState
>): UseFilterTrackReturnType<SegmentFilterDrawerState> => {
  const track = useTrack({ app, primaryTab })
  const { getValues } = useFormContext<FiltersFormInputs>()

  const getSegmentFilterDrawerState = useCallback(
    () => transformFormInputsToSegmentState(getValues()),
    [getValues, transformFormInputsToSegmentState]
  )

  const filterTrack = useCallback(
    (
      event_trigger: EventTrigger,
      mergePayload?: Partial<SegmentFilterDrawerState>
    ) => {
      const event = 'Filter Selected'

      let payload: TrackArgs<
        | {
            event_trigger: EventTrigger
            number_of_filters_selected: number
            number_of_filter_types_selected: number
          }
        | ({
            event_trigger: EventTrigger
            number_of_filters_selected: number
            number_of_filter_types_selected: number
          } & Partial<SegmentFilterDrawerState>)
      >

      if (event_trigger === FILTER_EVENTS.CLEAR_ALL_FILTERS) {
        payload = {
          event,
          data: {
            event_trigger,
            number_of_filters_selected: 0,
            number_of_filter_types_selected: 0,
          },
        }
      } else {
        let segmentFilterDrawerState = getSegmentFilterDrawerState()

        segmentFilterDrawerState = mergePayload
          ? { ...segmentFilterDrawerState, ...mergePayload }
          : segmentFilterDrawerState

        segmentFilterDrawerState = removeUndefinedNullAndEmptyValues<
          Partial<SegmentFilterDrawerState>
        >(segmentFilterDrawerState) as SegmentFilterDrawerState

        const transformedSegmentState = transformSegmentState
          ? transformSegmentState(segmentFilterDrawerState)
          : segmentFilterDrawerState

        const formInputs = getValues()
        const numberOfFiltersSelected =
          calculateNumberOfFiltersSelected(formInputs)

        payload = {
          event,
          data: {
            event_trigger,
            ...transformedSegmentState,
            number_of_filters_selected: numberOfFiltersSelected?.total || 0,
            number_of_filter_types_selected:
              numberOfFiltersSelected?.totalTypes || 0,
          },
        }
      }

      track(payload)
    },
    [
      FILTER_EVENTS.CLEAR_ALL_FILTERS,
      track,
      getSegmentFilterDrawerState,
      transformSegmentState,
      getValues,
      calculateNumberOfFiltersSelected,
    ]
  )

  return { filterTrack }
}

const isEmptyArray = (value: unknown) => isArray(value) && value.length === 0
const isEmptyObject = (value: unknown) =>
  isPlainObject(value) && Object.keys(value as object).length === 0

export const removeUndefinedNullAndEmptyValues = <
  SegmentFilterDrawerState extends Record<string, NullableValue>
>(
  segmentFilterDrawerState: SegmentFilterDrawerState
) => {
  Object.entries(segmentFilterDrawerState).forEach(([key, value]) => {
    if (
      value &&
      typeof value === 'object' &&
      !isEmptyArray(value) &&
      !isEmptyObject(value)
    ) {
      removeUndefinedNullAndEmptyValues(value as Record<string, NullableValue>)
    } else if (value === null || isEmptyArray(value) || isEmptyObject(value)) {
      delete segmentFilterDrawerState[key]
    }
  })
  return segmentFilterDrawerState
}

export const __TEST__ = {
  removeUndefinedNullAndEmptyValues,
}
