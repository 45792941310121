import {
  Link,
  LinkProps,
  Navigate,
  NavigateProps,
  To,
  useNavigate,
} from 'react-router-dom'

import { getLoneOrMfePath } from '@cais-group/caisiq/util/js/get-lone-or-mfe-path'

import { ROUTES } from './routes'

export function useCaisiqNavigate() {
  const navigate = useNavigate()

  return (
    to: To,
    options?: { replace?: boolean; state?: { from: string } }
  ) => {
    const caisiqUrl = getLoneOrMfePath(to)
    navigate(caisiqUrl, options)
  }
}

interface CaisIqLinkProps extends LinkProps {
  to: keyof typeof ROUTES
  args?: Array<unknown>
}

export function CaisiqLink({ to, args = [], ...props }: CaisIqLinkProps) {
  const caisiqUrl =
    typeof ROUTES[to] === 'function'
      ? (ROUTES[to] as (...args: Array<unknown>) => string)(...args)
      : ROUTES[to]
  return (
    <Link {...props} to={caisiqUrl as string}>
      {props.children}
    </Link>
  )
}

export function NavigateCaisiq(props: NavigateProps) {
  const caisiqUrl = getLoneOrMfePath(props.to)
  return <Navigate to={caisiqUrl} replace={props.replace} state={props.state} />
}

export function matchCaisiqPath(path: string) {
  if (!path.startsWith('/')) {
    throw new Error(`Path must start with '/'`)
  }
  return window.location.pathname.startsWith(getLoneOrMfePath(path))
}
