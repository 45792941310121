import { DotTag, DotTagProps } from '@cais-group/equity/atoms/dot-tag'
import { HelperLabel } from '@cais-group/equity/atoms/helper-label'
import {
  HelperMessage,
  HelperMessageProps,
} from '@cais-group/equity/atoms/helper-message'
import { Icon } from '@cais-group/equity/atoms/icon'
import { EquityText, isMarkdown } from '@cais-group/equity/atoms/markdown'
import { StatusTag, StatusTagProps } from '@cais-group/equity/atoms/status-tag'
import { Tooltip } from '@cais-group/equity/atoms/tooltip'

export type FieldProps = {
  id?: string
  htmlFor?: string
  label: EquityText
  required?: boolean
  supportingText?: string
  helper?: HelperMessageProps
  tag?:
    | ({ type: 'DotTag' } & DotTagProps)
    | ({ type: 'StatusTag' } & StatusTagProps)
    | StatusTagProps // This allows for backwards compatibility when this only accepted StatusTagProps

  testId?: string
  description?: string
}

export const Field = (props: FieldProps & { children: React.ReactNode }) => {
  const {
    id = 'field',
    htmlFor,
    label = '',
    required,
    supportingText,
    testId,
    helper,
    tag,
    children,
    description,
  } = props
  const labelString = isMarkdown(label) ? label.markdown : label
  const Tag = getTag(tag)

  return (
    <div className="flex flex-col gap-y-4" data-testid={testId ?? labelString}>
      <div className="flex items-center gap-x-8">
        {label ? (
          <HelperLabel
            id={`${id}-label`}
            htmlFor={htmlFor || id}
            required={required}
          >
            {label}
          </HelperLabel>
        ) : null}
        {description ? (
          <Tooltip title={description}>
            <div className="flex w-fit cursor-pointer items-center">
              <Icon
                type="InfoOutline"
                size="small"
                color="eq-color-neutral-400"
              />
            </div>
          </Tooltip>
        ) : null}
        {Tag ? (
          <div className="my-8">
            <Tag />
          </div>
        ) : null}
      </div>
      {children}
      {supportingText && (
        <p className="bodySmall text-neutral-600">{supportingText}</p>
      )}
      {helper && <HelperMessage id={`${id}--helper`} {...helper} />}
    </div>
  )
}

export function getTag(tag: FieldProps['tag']) {
  if (!tag || !tag?.children) return

  const type = 'type' in tag ? tag.type : 'StatusTag'

  switch (type) {
    case 'DotTag':
      return () => <DotTag {...(tag as DotTagProps)} />
    case 'StatusTag':
      return () => <StatusTag {...(tag as StatusTagProps)} />
  }
}
