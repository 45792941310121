import { Icon } from '@cais-group/equity/atoms/icon'
import { Tooltip as EquityTooltip } from '@cais-group/equity/atoms/tooltip'

export const Tooltip = ({ tooltip }: { tooltip: string }) => {
  return (
    <EquityTooltip title={tooltip} placement="top">
      <div className="ml-4">
        <Icon type="InfoOutline" size="tiny" color="eq-color-neutral-400" />
      </div>
    </EquityTooltip>
  )
}
