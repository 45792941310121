import { ClientError } from 'graphql-request'
import { QueryObserverResult } from 'react-query'

import {
  FundProductId,
  MemberFirmCollection,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { logError } from '@cais-group/shared/util/logging'

export class ApiError extends Error {
  originalError?: string | ApiError
  httpStatus?: number
  constructor(options: {
    message: string
    originalError?: string | ApiError
    httpStatus?: number
  }) {
    super(options.message)
    this.name = this.constructor.name
    this.originalError = options.originalError
    this.httpStatus = options.httpStatus
  }
}

export enum ApiStateEnum {
  LOADING = 'LOADING',
  INIT = 'INIT',
}

export type ApiState<T> = T | ApiError | ApiStateEnum

export const isApiState = <T>(
  maybeApiState: ApiState<T> | unknown
): maybeApiState is ApiState<T> => {
  return (
    maybeApiState === ApiStateEnum.LOADING ||
    maybeApiState === ApiStateEnum.INIT ||
    maybeApiState instanceof ApiError
  )
}

export const isQueryObserverResult = (
  maybeQueryObserverResult: unknown
): maybeQueryObserverResult is QueryObserverResult<unknown, unknown> => {
  return (
    typeof maybeQueryObserverResult === 'object' &&
    maybeQueryObserverResult !== null &&
    'status' in maybeQueryObserverResult
  )
}

export const isData = <T>(apiState: ApiState<T>): apiState is T => {
  return (
    apiState !== ApiStateEnum.LOADING &&
    apiState !== ApiStateEnum.INIT &&
    !(apiState instanceof ApiError)
  )
}

type GQLCollection<TData> = {
  items: Array<TData>
  total: number
}

export function parseFromCollection<TCollection, TData>(
  collection: TCollection | undefined,
  name: keyof TCollection
) {
  if (!collection) {
    return []
  }

  const collectionObject = collection[name] as GQLCollection<TData>

  if (!collectionObject || !('items' in collectionObject)) {
    return []
  }
  return collectionObject['items']
}

export function parseFirstFromCollection<TCollection, TData>(
  collection: TCollection | undefined,
  name: keyof TCollection
) {
  const all = parseFromCollection<TCollection, TData>(collection, name)
  return all.length ? all[0] : undefined
}

export const isError = (apiState: ApiState<unknown>): apiState is ApiError =>
  apiState instanceof ApiError

export function useReactQueryResultAsApiState<TQuery, TData>(
  result: QueryObserverResult<TQuery, ClientError>,
  extractData: (data: TQuery) => TData,
  errorMessage: string
) {
  const { data, isFetching, error } = result

  if (isFetching) {
    return ApiStateEnum.LOADING
  } else if (error) {
    const apiError = new ApiError({
      message: errorMessage,
      originalError: error,
    })
    logError({
      message: errorMessage,
      error: apiError,
    })
    return apiError
  } else if (data) {
    return extractData(data)
  }
  return ApiStateEnum.INIT
}

type ContentAccessKeys = {
  fundProductIds?: (string | null)[]
  firmIds?: string[]
}
export type ContentPermissionsData = {
  [key in keyof ContentAccessKeys]: ContentAccessKeys[key]
}

/**
 * Generic and easily extendable function that checks if the user has access to the content they are trying to access based on the permissions they have by comparing the allowed permissions with the content permissions.
 * If useGetAllowedPermissions' allowedPermissionsData is ever extended, this function will still work when ContentAccessKeys is updated.
 * @param allowedPermissionsData
 * @param contentPermissionsData
 * @returns boolean
 */
export function checkContentAccess<
  TData extends Record<string, Array<unknown>>
>(
  allowedPermissionsData: TData,
  contentPermissionsData?: Partial<TData>
): boolean {
  if (!contentPermissionsData) {
    return true
  }
  const keys = Object.keys(allowedPermissionsData)
  return keys.every((key) => {
    const contentPermissionIds = contentPermissionsData?.[key]
    const allowedPermissionIds = allowedPermissionsData[key]
    return Array.isArray(contentPermissionIds) &&
      contentPermissionIds.length > 0
      ? allowedPermissionIds.some((c) => contentPermissionIds?.includes(c))
      : true
  })
}

type PermissionsData = {
  fundProductIds?: FundProductId | null
  firmsCollection?: MemberFirmCollection | null
}
export function transformContentPermissionsData<TData>({
  data,
  name,
}: {
  name: keyof TData
  data?: TData
}): ContentPermissionsData & {
  notFound?: boolean
} {
  const contentPermissions = parseFirstFromCollection<TData, PermissionsData>(
    data,
    name
  )

  const { fundProductIds, firmsCollection } = contentPermissions || {}

  return {
    fundProductIds: fundProductIds?.fundProductIds || [],
    firmIds: (firmsCollection?.items.map((firm) => firm?.id) ||
      []) as ContentPermissionsData['firmIds'],
    notFound: !contentExists(data, name),
  }
}

function contentExists<TCollection, TData>(
  collection: TCollection | undefined,
  name: keyof TCollection
): boolean {
  if (!collection) {
    return false
  }
  const collectionObject = collection[name] as GQLCollection<TData>
  return collectionObject.total > 0
}
