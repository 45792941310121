import { useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

import { isData } from '@cais-group/caisiq/domain/common'
import {
  useFilterFundsBySearch,
  useGetFunds,
} from '@cais-group/caisiq/domain/fund'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { Course, Fund } from '@cais-group/shared/util/type/caisiq-be'

import { Funds } from './caisiq-feature-funds'

export type FundCourse = Course

export const FundsContainer = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)
  const [selectedFund, setSelectedFund] = useState<Fund | null>(null)
  const fundsApiState = useGetFunds()
  const funds = useMemo(
    () => (isData(fundsApiState) ? fundsApiState.data : []),
    [fundsApiState]
  )
  const filteredFunds = useFilterFundsBySearch(funds)

  return (
    <Funds
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      selectedFund={selectedFund}
      setSelectedFund={setSelectedFund}
      funds={funds}
      filteredFunds={filteredFunds}
    />
  )
}
