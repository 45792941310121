export type RGBColor = [r: number, g: number, b: number]

export const hexToRgb = (hex: string) => {
  const result = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result || result?.length !== 4) {
    throw new Error(`Failed to parse hex to RGB`)
  }

  const rgb = result.slice(1).map((x) => parseInt(x, 16))

  return rgb as RGBColor
}

export const rgbToHex = (rgb: RGBColor): string =>
  `#${rgb.map((x) => Math.round(x).toString(16).padStart(2, '0')).join('')}`

export const transparentize = (
  hex: string,
  opacity: number,
  {
    type = 'rgba',
    bakeHex = '#ffffff',
  }: { type?: 'rgba' | 'bake'; bakeHex?: string } = {}
) => {
  if (hex === 'transparent') return hex

  if (opacity > 1 || opacity < 0) {
    throw new Error(`opacity must me between 0 and 1.`)
  }

  const rgb = hexToRgb(hex)

  if (rgb) {
    if (type === 'rgba') {
      const [r, g, b] = rgb
      return `rgba(${r}, ${g}, ${b}, ${opacity})`
    }

    if (type === 'bake') {
      const bakeBgRgb = hexToRgb(bakeHex)
      // Here we're compositing a solid white background (by default) with our colour
      //
      // https://stackoverflow.com/questions/8743482/calculating-opacity-value-mathematically
      // https://en.wikipedia.org/wiki/Transparency_%28graphic%29#Compositing_calculations
      return rgbToHex(
        rgb.map(
          (x, i) =>
            (1 - opacity) * bakeBgRgb[i] /* white background by default */ +
            opacity * x /* our colour foreground */
        ) as RGBColor
      )
    }
  }

  return null
}
