import styled from '@emotion/styled'
import { Skeleton } from '@mui/material'
import cx from 'classnames'
import { Dispatch, SetStateAction } from 'react'

import { H5Strong } from '@cais-group/approved/ui/typography'
import { Avatar } from '@cais-group/equity/atoms/avatar'
import { Icon } from '@cais-group/equity/atoms/icon'
import { BackPillButton } from '@cais-group/equity/atoms/pill'
import { colors } from '@cais-group/equity/particles/colors'
import { ExternalLink } from '@cais-group/shared/ui/block-link'
import { BREAKPOINTS, SPACING } from '@cais-group/shared/ui/design-tokens'

export interface NavigationSidebarProps {
  onBack?(): void
  className?: string
  links?: { description?: string; buttonText: string; destination: string }[]
  /**
   * @todo: Will we want to pass the whole Firm type down or just what we need
   */
  firm?: { caisId: string; name: string }
  isLoading?: boolean
  backButtonText?: string
  setShowSidebar?: Dispatch<SetStateAction<boolean>>
  showSidebar?: boolean
}

const StyledNavigationSidebar = styled.nav`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(3)};
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    padding: ${({ theme }) => theme.spacing(5)};
  }
`

export const Links = styled.div`
  padding-top: ${SPACING.s32};
  display: flex;
  flex-direction: column;
  row-gap: ${SPACING.s16};
`

export const TeamDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${SPACING.s24};
  padding-bottom: ${SPACING.s24};
`

const StyledLink = styled(ExternalLink)`
  cursor: pointer;
  color: ${colors['eq-color-neutral-900']};
  width: min-content;
  &:hover {
    color: ${colors['eq-color-primary-600']};
  }
  &[class*='active'] {
    color: ${colors['eq-color-primary-600']};
  }
  &:active {
    color: ${colors['eq-color-primary-600']};
  }
`
const Content = styled.div`
  padding-top: ${SPACING.s24};
`
export const NavigationSidebar: React.FC<NavigationSidebarProps> = ({
  onBack,
  firm,
  links,
  isLoading,
  backButtonText,
  setShowSidebar,
  showSidebar = true,
}) => {
  return (
    <>
      {(firm || links) && (
        <div
          className={cx(
            'absolute -right-12 top-24 z-10 h-24 w-24 cursor-pointer rounded-full',
            {
              'bg-primary-600 outline-primary-600 text-neutral-0 outline':
                showSidebar,
              'bg-neutral-0 text-neutral-600 outline outline-neutral-600':
                !showSidebar,
            }
          )}
          onClick={() => setShowSidebar && setShowSidebar((state) => !state)}
        >
          <Icon
            type={showSidebar ? 'KeyboardArrowLeft' : 'KeyboardArrowRight'}
          />
        </div>
      )}
      {showSidebar && backButtonText && (
        <StyledNavigationSidebar aria-label="Navigation Sidebar">
          <BackPillButton label={backButtonText} onClick={onBack} />
          {firm && (
            <Content>
              <Avatar
                size="large"
                testId="sidebar-team-acronym"
                type="group"
                loading={isLoading || false}
              >
                {firm.name}
              </Avatar>
              <TeamDetails>
                <H5Strong id="sidebar-firm-name">
                  {isLoading ? <Skeleton /> : firm.name}
                </H5Strong>
              </TeamDetails>
            </Content>
          )}
          {links && (
            <Links>
              {links.map((link) => (
                /**
                 * @todo: will any of these links eventually need to be protected based on permissions?
                 * @todo: Some of these may eventually be internal links
                 */
                <StyledLink
                  href={link.destination}
                  aria-label={link.buttonText}
                  key={link.buttonText}
                >
                  {link.buttonText}
                </StyledLink>
              ))}
            </Links>
          )}
        </StyledNavigationSidebar>
      )}
    </>
  )
}

export default NavigationSidebar
