import { Block } from '@contentful/rich-text-types'
import { useRecoilValue } from 'recoil'

import { isData } from '@cais-group/caisiq/domain/common'
import { searchTermAtom, inBody } from '@cais-group/caisiq/domain/search'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

import { useGetWhitepapers, WhitepapersApiState } from './useGetWhitepapers'

export function useFilteredWhitepapers() {
  const searchTerm = useRecoilValue(searchTermAtom)
  const whitepapersApiState = useGetWhitepapers()

  return filterWhitepaperState(whitepapersApiState, searchTerm)
}

export function filterWhitepaperState(
  whitepapersApiState: WhitepapersApiState,
  searchTerm: string
) {
  return isData(whitepapersApiState)
    ? whitepapersApiState.filter((whitepaper: WhitepaperData) => {
        if (searchTerm === '') return true
        return (
          whitepaper.title.toLowerCase().includes(searchTerm) ||
          whitepaper.author.name.toLowerCase().includes(searchTerm) ||
          inBody(whitepaper?.body?.json as unknown as Block, searchTerm)
        )
      })
    : undefined
}
