import styled from '@emotion/styled'
import BigNumber from 'bignumber.js'
import clamp from 'lodash-es/clamp'
import { Cell } from 'react-table'

import { BodySmall } from '@cais-group/approved/ui/typography'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { ProgressBar } from '@cais-group/shared/ui/progress-bar'
import { moreExactPercentageFormatter } from '@cais-group/shared/util/number'

const CourseProgressWrapper = styled('div')`
  display: flex;
  align-items: center;
`

const CourseProgressPercent = styled(BodySmall)`
  width: 38px;
  text-align: end;
  margin-right: ${SPACING.s8};
`
const PROGRESS_BAR_WIDTH = 64

export const CourseProgressCell = (cell: Cell) => {
  const value = Number.isFinite(cell.value)
    ? clamp(cell.value as number, 0, 1)
    : 0
  return (
    <CourseProgressWrapper>
      <CourseProgressPercent data-testid="course-progress">
        {/* Format the decimal value as an integer with the % symbol appended */}
        {moreExactPercentageFormatter({
          value: value,
          appendSymbol: true,
          roundingMode: BigNumber.ROUND_DOWN,
        })}
      </CourseProgressPercent>
      <ProgressBar width={PROGRESS_BAR_WIDTH} fraction={value} rounded={true} />
    </CourseProgressWrapper>
  )
}
