import styled from '@emotion/styled'
import { FunctionComponent, useState } from 'react'
import { SetterOrUpdater } from 'recoil'

import { shouldForwardProp } from '@cais-group/approved/ui/typography'
import {
  useCourseModal,
  useGetCourseCeCredits,
} from '@cais-group/caisiq/domain/course'
import { usePatchEnrollCourse } from '@cais-group/caisiq/domain/library-course'
import { getHeaderLabel } from '@cais-group/caisiq/feature/common/get-header-label'
import { BannerWithButtonContainer } from '@cais-group/caisiq/ui/banner-with-button'
import { CardCourseSkeleton } from '@cais-group/caisiq/ui/card/course'
import {
  CourseDetailModal,
  CourseModalHeader,
} from '@cais-group/caisiq-ui-modal-course-detail'
import { ModalPortal } from '@cais-group/caisiq-ui-modal-portal'
import { NoResults } from '@cais-group/caisiq-ui-no-results'
import { Notification } from '@cais-group/caisiq-ui-notification'
import {
  LayoutCenter,
  LayoutStack,
  LayoutGrid,
} from '@cais-group/shared/ui/layout'
import { Course } from '@cais-group/shared/util/type/caisiq-be'
import {
  isLearningComplete,
  isPassed,
} from '@cais-group/shared/util/type/course'

import { CoursesThemeGroup } from './CoursesGroup'
import { coursesByThemeType } from './coursesByThemeType'
import { useNotifications, INotification } from './useNotifications'

const completed = (course: Course) =>
  isPassed(course) || isLearningComplete(course)

const filters = {
  completed: completed,
  notCompleted: (course: Course) => !completed(course),
  all: (course: Course) => completed(course) || !completed(course),
} as const

/**
 * Sorts an array of tuples [string, Course[]] in alphabetical order
 * based on the first element in the tuple.
 */
function alphaSort(items: [string, Course[]][]) {
  return items.sort(([a], [b]) => {
    return a > b ? 1 : -1
  })
}

const WideModalPortal = styled(ModalPortal)`
  width: 60%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const StyledContainer = styled(LayoutStack)`
  width: max-content;
  max-width: 100%;
`

const NoResultsContainer = styled(LayoutCenter, {
  shouldForwardProp: shouldForwardProp('empty'),
})<{ empty: boolean }>`
  padding-top: var(--s24);
  padding-bottom: ${(props) => (props.empty ? 'var(--s56)' : '0')};
`

const StyledGrid = styled(LayoutGrid)`
  padding-top: var(--s24);
  padding-bottom: var(--s56);
`
StyledGrid.displayName = 'StyledGrid'

export interface CoursesProps {
  searchTerm: string
  setSearchTerm: SetterOrUpdater<string>
  searchFilteredCourses: Course[] | undefined
}

export const Courses: FunctionComponent<CoursesProps> = (props) => {
  const { searchTerm, setSearchTerm, searchFilteredCourses } = props

  const { ceCreditsAvailable } = useGetCourseCeCredits()

  const { executeCourseEnrollPatch } = usePatchEnrollCourse()

  const { course: modalCourse, openModal, closeModal } = useCourseModal()

  const selectedLibraryCourse = searchFilteredCourses
    ? searchFilteredCourses.find((course) => course.id === modalCourse?.id)
    : undefined

  const showNotifications = searchTerm?.length === 0
  const { notifications, setNotifications } = useNotifications(
    searchFilteredCourses
  )

  const [currentFilter] = useState<(course: Course) => boolean>(
    () => filters.all
  )

  const closeNotification = (notification: INotification) => () => {
    if (notification.onClose) {
      notification.onClose()
    } else {
      setNotifications(notifications?.filter((x) => x.id !== notification.id))
    }
  }
  const filteredCourses = searchFilteredCourses?.filter(currentFilter)
  const { thematic, nonThematic } = coursesByThemeType(filteredCourses ?? [])

  return (
    <>
      {showNotifications && (
        <StyledContainer space="var(--s24)">
          {notifications?.map((notification, index) => (
            <Notification
              key={`${notification.id}-${index}`}
              {...notification}
              onClose={closeNotification(notification)}
            ></Notification>
          ))}
        </StyledContainer>
      )}

      {searchFilteredCourses === undefined ? (
        <StyledGrid
          data-testid="skeleton-courses"
          aria-busy
          aria-label="skeleton"
          aria-valuetext="Loading Courses..."
        >
          {Array.from(Array(10).keys(), (i) => (
            <CardCourseSkeleton key={i} />
          ))}
        </StyledGrid>
      ) : filteredCourses?.length === 0 && searchTerm !== '' ? (
        <NoResultsContainer empty={filteredCourses?.length === 0}>
          <NoResults
            data-testid="courses-no-results"
            searchSetName="Courses"
            searchTerm={searchTerm}
            onClick={() => setSearchTerm('')}
          />
        </NoResultsContainer>
      ) : (
        <>
          {/* Thematic courses */}
          {alphaSort(Object.entries(thematic)).map(([name, courses], index) => (
            <CoursesThemeGroup
              key={`thematic-${index}`}
              name={name}
              courses={courses}
              withSeparator={true}
              onClickViewDetails={(libraryCourse) => {
                openModal(libraryCourse)
              }}
            />
          ))}

          {/* Non-thematic courses */}
          <CoursesThemeGroup
            key="nonThematic"
            courses={nonThematic}
            onClickViewDetails={(libraryCourse) => {
              openModal(libraryCourse)
            }}
          />
          <BannerWithButtonContainer />
        </>
      )}

      <WideModalPortal
        onClose={closeModal}
        header={
          <CourseModalHeader
            imageUrl={selectedLibraryCourse?.imageUrl || ''}
            enrolledNumber={selectedLibraryCourse?.totalEnrolled || 0}
            withCertificate={
              selectedLibraryCourse &&
              (isPassed(selectedLibraryCourse) ||
                isLearningComplete(selectedLibraryCourse))
            }
            headerLabel={getHeaderLabel(
              ceCreditsAvailable(selectedLibraryCourse as Course)
            )}
          />
        }
      >
        {modalCourse && (
          <CourseDetailModal
            onSetSelectedFund={() => {}}
            onClickEnroll={(course: Course) =>
              executeCourseEnrollPatch({
                id: course.id,
                enroll: true,
              })
            }
            ceCreditsAvailable={ceCreditsAvailable(
              selectedLibraryCourse as Course
            )}
          />
        )}
      </WideModalPortal>
    </>
  )
}
