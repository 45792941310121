import { useUniformContext } from '../context'
import { ComboBox } from '../equity/combobox'
import type { OptionLayoutProps } from '../equity/common/option-layout'
import type { OptionsLayoutProps } from '../equity/common/options-layout'
import type {
  OptionsOrAsyncOptions,
  UseOptionsPropsReturns,
} from '../hooks/async-options'
import { AsyncOptions } from '../hooks/async-options'
import {
  useDropdownAction,
  type ActionModalProps,
} from '../hooks/use-dropdown-action'
import type { UniformServices } from '../services'
import type {
  InitialOptions,
  UniformAny,
  UniformFieldOption,
  UniformFieldProps,
} from '../types'

import { UniformAdapter } from './adapter'

type RenderUnderComponent = (props: {
  initialOptions: InitialOptions<string>
  refetch?: () => Promise<UniformAny>
  setSelectedValue?: (id: string) => void
}) => React.ReactNode

type CombinedComboBoxInputProps<
  TFormFieldIds extends keyof UniformAny = UniformAny
> = UniformFieldProps<'ComboBoxInput'> &
  OptionLayoutProps &
  OptionsLayoutProps & {
    id: TFormFieldIds
    actionModal?: ActionModalProps
    renderUnderComponent?: RenderUnderComponent
  }
export type ComboBoxInputProps<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
> = CombinedComboBoxInputProps<TFormFieldIds> &
  OptionsOrAsyncOptions<TUniformServices>

type ComboBoxComponentProps<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
> = {
  field: ComboBoxInputProps<TFormFieldIds, TUniformServices>
  onFocus?: () => void
  selectOptions: UniformFieldOption[]
  isLoading?: boolean
  hasNextPage?: boolean
  depsValues?: string[]
} & UseOptionsPropsReturns

const ComboBoxComponent = <
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
>({
  field,
  onFocus,
  selectOptions,
  fetchNextPage,
  isLoading,
  hasNextPage,
  setAsyncOptionsFilter,
  refetch,
  depsValues = [],
}: ComboBoxComponentProps<TFormFieldIds, TUniformServices>) => {
  const { initialOptions } = useUniformContext()
  const { handleDropdownAction, actionName } = useDropdownAction({
    modalProps: field.actionModal,
    refetch,
  })

  return (
    <UniformAdapter
      field={field}
      render={({
        uniform: { error, id, isDisabled, required, tag },
        field: { ref, onChange, onBlur, value, name },
      }) => (
        <>
          <ComboBox
            id={id}
            ref={ref}
            label={field.label}
            required={!!required}
            disabled={isDisabled}
            error={!!error}
            errorMessage={error?.message}
            options={selectOptions}
            onFocus={onFocus}
            depsValues={depsValues}
            tag={tag}
            onChange={(option) => {
              if (!option) return
              onChange(option.value)
            }}
            onBlur={onBlur}
            value={value}
            name={name}
            optionLayout={field.optionLayout}
            optionsLayout={field.optionsLayout}
            optionsLayoutGroupBy={field.optionsLayoutGroupBy}
            placeholder={field.placeholder}
            setAsyncOptionsFilter={setAsyncOptionsFilter}
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            dropdownActionName={actionName}
            dropdownAction={handleDropdownAction}
            tooltip={field.tooltip}
          />
          {field.renderUnderComponent?.({
            initialOptions,
            refetch: async () => await refetch?.(),
            setSelectedValue: (id) => onChange(id),
          })}
        </>
      )}
    />
  )
}

export const ComboBoxInput = <
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
>(
  field: ComboBoxInputProps<TFormFieldIds, TUniformServices>
) => {
  return (
    <AsyncOptions
      field={field}
      asyncOptions={({
        value: options = [],
        fetchNextPage,
        isLoading,
        isFetching,
        hasNextPage,
        setAsyncOptionsFilter,
        depsValues,
      }) => (
        <ComboBoxComponent
          field={field}
          selectOptions={options}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading || isFetching}
          hasNextPage={hasNextPage}
          setAsyncOptionsFilter={setAsyncOptionsFilter}
          depsValues={depsValues}
        />
      )}
      options={(options) => (
        <ComboBoxComponent field={field} selectOptions={options} />
      )}
    />
  )
}

export default ComboBoxInput
