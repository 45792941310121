import styled from '@emotion/styled'

import { H6StrongCss } from '@cais-group/approved/ui/typography'
import { Icon } from '@cais-group/equity/atoms/icon'

const StyledErrorPanel = styled.div`
  padding: var(--s24);
  border-left: var(--s4) solid rgb(var(--colors-error-600));
  box-shadow: 0px 4px 8px rgba(33, 28, 33, 0.04);
`
const StyledTopPanel = styled.div`
  display: flex;
  margin-bottom: var(--s16);
`

const StyledErrorTitle = styled.div`
  ${H6StrongCss}
  margin-left: var(--s16);
  color: rgb(var(--colors-error-600));
`

export type ErrorPanelProps = {
  title: string
  children: React.ReactNode
  className?: string
}
export function ErrorPanel({ title, children, className }: ErrorPanelProps) {
  return (
    <StyledErrorPanel className={className}>
      <StyledTopPanel>
        <Icon
          type="Error"
          color="eq-color-error-600"
          aria-label="Error icon"
          data-testid="error-icon"
        />
        <StyledErrorTitle>{title}</StyledErrorTitle>
      </StyledTopPanel>
      {children}
    </StyledErrorPanel>
  )
}
