import {
  ApiState,
  useReactQueryResultAsApiState,
} from '@cais-group/caisiq/domain/common'
import { useGetVideoPlaylistQuery } from '@cais-group/caisiq/util/graphql/contentful'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { TypeVideoPlaylistData } from '@cais-group/shared/util/type/video-playlist-data'

export type VideoPlaylistApiState = ApiState<TypeVideoPlaylistData>

export type VideoPlaylistResult = {
  caisiqVideoPlaylist: TypeVideoPlaylistData
}

export const useGetVideoPlaylist = (id: string): VideoPlaylistApiState =>
  useReactQueryResultAsApiState(
    useGetVideoPlaylistQuery(
      { id: id || '', preview: previewService.enabled },
      {
        enabled: id !== undefined,
        refetchOnMount: false,
        staleTime: 1000 * 60 * 10,
      }
    ),
    (data) => data.caisiqVideoPlaylist as TypeVideoPlaylistData,
    'Could not load caisiqVideoPlaylistQuery'
  )
