import {
  parseFromCollection,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  CaisiqArticleFilter,
  GetArticlesQuery,
  useGetArticlesQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'

import { FIRMS_COLLECTION_LIMIT } from '../constants'

export const useGetArticles = (where?: CaisiqArticleFilter) => {
  return useReactQueryResultAsApiState<GetArticlesQuery, TypeArticleData[]>(
    useGetArticlesQuery(
      {
        preview: previewService.enabled,
        where,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      { refetchOnWindowFocus: false }
    ),
    (data) =>
      parseFromCollection<GetArticlesQuery, TypeArticleData>(
        data,
        'caisiqArticleCollection'
      ),
    'Could not load articlesCollectionQuery'
  )
}
