import { useEffect, useMemo, useState } from 'react'

export function useHoverState(handler?: (index: string | undefined) => void) {
  // Note usually it would be better to use null for intentional absence of value; but using undefined allows us to pass in something more simply as a optional prop
  const [hoverId, setHoverId] = useState<string | undefined>(undefined)

  useEffect(() => {
    handler?.(hoverId)
  }, [hoverId, handler])

  return useMemo(
    () => ({
      hoverId,
      enterHandlerFor: (id: string) => () => setHoverId(id),
      leaveHandlerFor: (id: string) => () =>
        setHoverId((existing) => (existing === id ? undefined : existing)),
      toggleFor: (id: string) => () =>
        setHoverId((existing) => (existing === id ? undefined : id)),
      resetHoverId: () => setHoverId(undefined),
    }),
    [setHoverId, hoverId]
  )
}
