import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import { useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useMemo } from 'react'

import {
  H6Strong,
  BodySmall,
  CaptionRegular,
  Label,
} from '@cais-group/approved/ui/typography'
import { customTableSort } from '@cais-group/caisiq/util/js/custom-table-sort'
import {
  HomeOfficeCompletedRequirementsTableCell,
  HomeOfficeRequirementMet,
} from '@cais-group/shared/util/type/homeoffice-data'

const Name = styled.div`
  display: flex;
  align-items: center;

  ${Label} {
    margin-right: var(--s8);
  }
`
const MobileName = styled.div`
  display: flex;
  align-items: center;
  min-width: 6.25rem;

  ${Label} {
    margin-right: var(--s8);
  }
`
const MailTo = styled.a`
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
`
const RequirementMet = styled(CaptionRegular)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--s8);
`

const StyledCheck = styled(CheckIcon)`
  fill: rgb(var(--colors-success-600));
`
const DateMet = styled(CaptionRegular)``

type RequirementsFormatTableProps = {
  data: HomeOfficeRequirementMet[]
}

export const useFormatCompleteRequirementsTableData = (
  props: RequirementsFormatTableProps
) => {
  const { data } = props
  dayjs.extend(duration)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 75))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const requirementData = data.map((dataPoint) => {
    return {
      name: <div>{dataPoint.name}</div>,
      email: (
        <BodySmall>
          <MailTo href={`mailto:${dataPoint.email}`}>{dataPoint.email}</MailTo>
        </BodySmall>
      ),
      teams: <BodySmall>{dataPoint.teams.join(', ')}</BodySmall>,
      course: (
        <Name>
          <H6Strong>{dataPoint.course}</H6Strong>
        </Name>
      ),
      learned: <BodySmall>{dataPoint.learned}</BodySmall>,
      date: <BodySmall>{dataPoint.date}</BodySmall>,
    }
  })

  const desktopColumns = useMemo(
    () => [
      {
        sortType: customTableSort,
        Header: 'Name',
        accessor: 'name',
        width: 200,
        maxWidth: 200,
        minWidth: 200,
        Cell: (cell: HomeOfficeCompletedRequirementsTableCell) => {
          return (
            <Name>
              <H6Strong>{cell.value.props?.children}</H6Strong>
            </Name>
          )
        },
      },
      {
        sortType: customTableSort,
        Header: 'Email',
        accessor: 'email',
        width: 200,
        maxWidth: 200,
        minWidth: 200,
      },
      {
        Header: 'Team',
        accessor: 'teams',
        width: 400,
        maxWidth: 750,
        sortType: customTableSort,
      },
      {
        Header: 'Course',
        accessor: 'course',
        minWidth: 300,
        maxWidth: 600,
        sortType: customTableSort,
      },
      {
        Header: 'Product access requirement',
        accessor: 'learned',
        Cell: (cell: HomeOfficeCompletedRequirementsTableCell) => {
          return cell.row.original.learned ? (
            <RequirementMet data-testid="requirement-met">
              <StyledCheck />
              Requirement met
            </RequirementMet>
          ) : (
            '-'
          )
        },
        minWidth: 150,
        maxWidth: 300,
        disableSortBy: true,
      },
      {
        Header: 'Date requirement met',
        accessor: 'date',
        sortType: customTableSort,
        Cell: (cell: HomeOfficeCompletedRequirementsTableCell) => {
          return (
            <DateMet data-testid="requirement-met-date">
              {cell.row.original?.date?.props.children
                ? dayjs(cell.row.original.date.props.children).format(
                    'MM/DD/YY'
                  )
                : '-'}
            </DateMet>
          )
        },
        minWidth: 150,
        maxWidth: 300,
      },
    ],
    []
  )

  const tabletColumns = useMemo(() => {
    if (isTablet) {
      return [
        {
          sortType: customTableSort,
          Header: 'Name',
          accessor: 'name',
          width: 120,
          maxWidth: 120,
          minWidth: 120,
          Cell: (cell: HomeOfficeCompletedRequirementsTableCell) => {
            return (
              <MobileName>
                <H6Strong>{cell.value.props?.children}</H6Strong>
              </MobileName>
            )
          },
        },
        {
          sortType: customTableSort,
          Header: 'Email',
          accessor: 'email',
          width: 241,
          maxWidth: 241,
          minWidth: 241,
        },
        {
          Header: 'Team',
          accessor: 'teams',
          width: 351,
          maxWidth: 351,
          sortType: customTableSort,
        },
        {
          Header: 'Course',
          accessor: 'course',
          minWidth: 241,
          maxWidth: 241,
          sortType: customTableSort,
        },
        {
          Header: 'Product access requirement',
          accessor: 'learned',
          Cell: (cell: HomeOfficeCompletedRequirementsTableCell) => {
            return cell.row.original.learned ? (
              <RequirementMet data-testid="requirement-met">
                <StyledCheck />
                Requirement met
              </RequirementMet>
            ) : (
              '-'
            )
          },
          minWidth: 175,
          maxWidth: 175,
          disableSortBy: true,
        },
        {
          Header: 'Date requirement met',
          accessor: 'date',
          sortType: customTableSort,
          Cell: (cell: HomeOfficeCompletedRequirementsTableCell) => {
            return (
              <DateMet data-testid="requirement-met-date">
                {cell.row.original?.date?.props.children
                  ? dayjs(cell.row.original.date.props.children).format(
                      'MM/DD/YY'
                    )
                  : '-'}
              </DateMet>
            )
          },
          minWidth: 200,
          maxWidth: 200,
        },
      ]
    }
    return
  }, [isTablet])

  const mobileColumns = useMemo(
    () => () => {
      if (isMobile) {
        return [
          {
            sortType: customTableSort,
            Header: 'Name',
            accessor: 'name',
            width: 110,
            maxWidth: 149,
            minWidth: 110,
            Cell: (cell: HomeOfficeCompletedRequirementsTableCell) => {
              return (
                <MobileName>
                  <H6Strong>{cell.value.props?.children}</H6Strong>
                </MobileName>
              )
            },
          },
          {
            sortType: customTableSort,
            Header: 'Email',
            accessor: 'email',
            width: 220,
            maxWidth: 220,
            minWidth: 220,
          },
          {
            Header: 'Team',
            accessor: 'teams',
            width: 120,
            maxWidth: 120,
            sortType: customTableSort,
          },
          {
            Header: 'Course',
            accessor: 'course',
            minWidth: 200,
            maxWidth: 200,
            sortType: customTableSort,
          },
          {
            Header: 'Product access requirement',
            accessor: 'learned',
            Cell: (cell: HomeOfficeCompletedRequirementsTableCell) => {
              return cell.row.original.learned ? (
                <RequirementMet data-testid="requirement-met">
                  <StyledCheck />
                  Requirement met
                </RequirementMet>
              ) : (
                '-'
              )
            },
            minWidth: 200,
            maxWidth: 200,
            disableSortBy: true,
          },
          {
            Header: 'Date requirement met',
            accessor: 'date',
            sortType: customTableSort,
            Cell: (cell: HomeOfficeCompletedRequirementsTableCell) => {
              return (
                <DateMet data-testid="requirement-met-date">
                  {cell.row.original?.date?.props.children
                    ? dayjs(cell.row.original.date.props.children).format(
                        'MM/DD/YY'
                      )
                    : '-'}
                </DateMet>
              )
            },
            minWidth: 150,
            maxWidth: 150,
          },
        ]
      }
      return
    },
    [isMobile]
  )

  if (isMobile) {
    return {
      requirementData: requirementData,
      requirementColumns: mobileColumns,
    }
  }

  if (isTablet) {
    return {
      requirementData: requirementData,
      requirementColumns: tabletColumns,
    }
  }

  return {
    requirementData: requirementData,
    requirementColumns: desktopColumns,
  }
}
