import { useFormContext } from 'react-hook-form'
import { useRecoilState } from 'recoil'

import { H5Strong } from '@cais-group/approved/ui/typography'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { ManageCoursesConfigurationTable } from '@cais-group/caisiq/ui/manage/course-configuration-table'
import {
  ExperienceFields,
  FormStepProps,
} from '@cais-group/caisiq/util/type/manage-caisiq'
import {
  OrderedFieldset,
  StepNavigation,
  useStepState,
} from '@cais-group/shared/ui/ordered-fieldset'
import { CourseMetadata } from '@cais-group/shared/util/type/caisiq-be'

interface CourseConfigurationStepProps extends FormStepProps {
  disabled?: boolean
  ignoreStep?: boolean
  legend?: string | undefined
  courses?: CourseMetadata[]
  isFetched?: boolean
  className?: string
}

export function CourseConfigurationStep({
  order,
  disabled = false,
  legend,
  ignoreStep = false,
  courses,
  isFetched,
  className,
}: CourseConfigurationStepProps) {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)
  const { watch, trigger } = useFormContext<ExperienceFields>()

  const coursesEnabled = watch('coursesEnabled') && watch('catalogId')
  const { deriveState, showStep } = useStepState()

  if (!coursesEnabled) {
    return null
  }

  return (
    <OrderedFieldset
      legend={
        legend ? (
          <H5Strong className={`scroll-mt-144 ${className}`} id="course-config">
            {legend}
          </H5Strong>
        ) : undefined
      }
      state={deriveState(order)}
    >
      {(showStep(order) || ignoreStep) && (
        <div className="pt-16">
          <ManageCoursesConfigurationTable
            tableOptions={{
              data: courses || [],
            }}
            params={{}}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isFetched={isFetched}
          />
        </div>
      )}
      <StepNavigation
        order={order}
        onBeforeNext={() => {
          /**
           * @todo don't add until set up in segment
           */
          // trackingService.manageNewExperienceCoursesEntered({
          //   almCatalog: getValues('catalogId') ?? '',
          //   coursesEnabled: getValues('coursesEnabled') ?? '',
          //   courses: getValues('courses') ?? [],
          // })
          return trigger(['courses'])
        }}
      />
    </OrderedFieldset>
  )
}
