import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { EventOrder } from '@cais-group/shared/util/graphql/mfe-contentful'
import { today } from '@cais-group/shared/util/time/date-time-contentful'

import {
  CAROUSEL_ITEM_LIMIT,
  FEATURED_CONTENT_APP_ID,
  FILTERS,
  FIRMS_COLLECTION_LIMIT,
  TAGS_COLLECTION_LIMIT,
} from '../constants'

/*
  Avoid losing too many items when permissions are applied.
  An alternative is to also fetch the same number of items but have Contentful
  only return items with zero permissions.
  Then join, de-dupe, sort
*/
const OVER_FETCH_LIMIT = 2 * CAROUSEL_ITEM_LIMIT

export const makeHomeQueryParams = () => {
  const replaysFilter = {
    ...FILTERS.webinars.is,
    displayDate_lte: today(),
  }
  // TODO - is the eventType right or should it allow for all?
  const upcomingFilter = { dateTime_gte: today(), eventType: 'Webinar' }

  const commonQueryParams = {
    featuredId: FEATURED_CONTENT_APP_ID,
    includeFeatured: false,
    preview: previewService.enabled,
    firmsLimit: FIRMS_COLLECTION_LIMIT,
    tagsLimit: TAGS_COLLECTION_LIMIT,
  }

  const researchFilter = { AND: [FILTERS.research.is] }

  const researchQueryParams = {
    preview: previewService.enabled,
    limit: OVER_FETCH_LIMIT - 4,
    articleWhere: researchFilter,
    videoWhere: researchFilter,
    videoPlaylistWhere: researchFilter,
    whitepaperWhere: researchFilter,
    externalWhere: researchFilter,
    firmsLimit: FIRMS_COLLECTION_LIMIT,
  }

  const tutorialFilter = {
    AND: [FILTERS.tutorials.is],
  }

  const tutorialQueryParams = {
    ...commonQueryParams,
    limit: OVER_FETCH_LIMIT,
    articleWhere: tutorialFilter,
    videoPlaylistWhere: tutorialFilter,
    videoWhere: tutorialFilter,
    whitepaperWhere: tutorialFilter,
  }

  const pressReleasesQueryParams = {
    limit: OVER_FETCH_LIMIT,
    preview: previewService.enabled,
  }

  const externalContentQueryParams = {
    preview: previewService.enabled,
    limit: OVER_FETCH_LIMIT,
    where: { type: 'Fund' },
    firmsLimit: FIRMS_COLLECTION_LIMIT,
  }

  const webinarQueryParams = {
    ...commonQueryParams,
    upcomingWhere: upcomingFilter,
    videosWhere: replaysFilter,
    playlistWhere: replaysFilter,
    replaysLimit: OVER_FETCH_LIMIT,
    upcomingLimit: OVER_FETCH_LIMIT,
  }

  const inPersonEventQueryParams = {
    where: {
      eventType: 'In-Person',
      dateTime_gte: today(),
    },
    order: 'dateTime_ASC' as EventOrder,
    limit: OVER_FETCH_LIMIT,
    firmsLimit: commonQueryParams.firmsLimit,
    tagsLimit: commonQueryParams.tagsLimit,
    preview: commonQueryParams.preview,
    queryKey: 'homepage-browse-content-events',
  }

  return {
    externalContentQueryParams,
    inPersonEventQueryParams,
    pressReleasesQueryParams,
    researchQueryParams,
    tutorialQueryParams,
    webinarQueryParams,
  }
}
