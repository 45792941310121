import { ControlledModalProps } from './controlled-modal'

/**
 * HTML dialog not supported in jsdom https://github.com/jsdom/jsdom/issues/3294
 *
 * PR here https://github.com/jsdom/jsdom/pull/3403 not updated for about a year(!)
 *
 * But we want to be able to test modals in Jest/react testing library
 *
 * Can use this mock version instead; obviously not a real/proper modal but might be
 * close enough for headless/jsdom testing purposes
 *
 * Definitely not following any details of the real modal implementation
 *
 */
export function MockControlledModalForJestUsage({
  open,
  onClose,
  children,
  title,
  testId,
  actions,
}: ControlledModalProps) {
  return open ? (
    <div data-testid={testId}>
      <h1>{title}</h1>
      <div>{children}</div>
      {actions?.map(
        (
          {
            onClick,
            children: actionChildren,
            testId: actionTestId,
            ...otherProps
          },
          i
        ) => (
          <button
            key={i}
            onClick={onClick}
            {...otherProps}
            data-testid={actionTestId}
          >
            {actionChildren}
          </button>
        )
      )}
    </div>
  ) : null
}
