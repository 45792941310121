import { Avatar } from '@cais-group/equity/atoms/avatar'

import { ReactComponent as CaretDown } from '../assets/caret-down.svg'
import { EnvType, Profile as ProfileType } from '../types'

import { Menu } from './menu'

export const Profile = ({
  profile,
  handleOpenMenu,
  handleCloseMenu,
  openMenu,
  env,
}: {
  profile: ProfileType
  handleOpenMenu: (id: string) => void
  handleCloseMenu: () => void
  openMenu: string | null
  env: EnvType
}) => {
  return (
    <>
      {/* Display only avatar on mobile */}
      <div className="md:hidden">
        <Avatar>{`${profile.firstName} ${profile.lastName}`}</Avatar>
      </div>
      {/* Display avatar with menu on screens bigger than mobile */}
      <div className="hidden md:block">
        <Menu
          handleOpenMenu={handleOpenMenu}
          handleCloseMenu={handleCloseMenu}
          openMenu={openMenu}
          position="right-0"
          menu={profile.menu}
          testId="profile"
          trigger={
            <div className="flex items-center space-x-8 focus-visible:ring">
              <div className="hidden min-[1311px]:block">
                <p className="label text-right">
                  {profile.firstName} {profile.lastName}
                </p>
                <p
                  className="caption text-right uppercase text-neutral-600"
                  data-testid="company-name"
                >
                  {profile.companyName}
                </p>
              </div>

              <div className="ml-12 mr-16">
                <Avatar>{`${profile.firstName} ${profile.lastName}`}</Avatar>
              </div>
              <CaretDown
                className="fill-neutral-600 group-focus-within:fill-neutral-900 group-hover:fill-neutral-900 md:hidden"
                aria-hidden
              />
            </div>
          }
          env={env}
        />
      </div>
    </>
  )
}
