import { InputMaybe, PageResult } from '@cais-group/shared/util/graphql'

import { components } from './generated'
import { PageRequest } from './pagination'

export type GeneratedSearchRequestFirmFilterFirmSort =
  components['schemas']['SearchDtoFirmFilterRequest']['sort']

export type FirmFilterRequest = components['schemas']['FirmFilterRequest']

type GeneratedFirm = components['schemas']['FirmResponse']

export type UpdateFirmRequest = components['schemas']['FirmRequest']

export type SearchRequestFirmFilterFirmSort = {
  filter?: InputMaybe<FirmFilterRequest>
  pageRequest?: PageRequest
  sort?: InputMaybe<GeneratedSearchRequestFirmFilterFirmSort>
}

export type CaisIqFirm = GeneratedFirm

export type CaisIqFirmWithChildren = CaisIqFirm & {
  children?: CaisIqFirmWithChildren[]
}

export type GetCaisIqFirmSearchResult = PageResult & {
  items: NonNullable<Array<CaisIqFirm>>
}
