/**
 * @param {T} obj - The input object to sanitize.
 * @returns {T} - The sanitized object.
 */

/**
 * Recursively sanitize an object by removing empty string values and converting them to null.
 *
 * @param {T} obj - The input object to sanitize.
 * @returns {T} - The sanitized object.
 *
 * @example
 * sanitizeObject({name: "", surname: "hello", address: { street: '', country: 'U.K' }}); // {name: null, surname: "Hello", address: {street: null, country: "U.K}}
 */
export function sanitizeObject<T>(obj: T): T {
  const sanitizedObj: T = {} as T

  for (const key in obj) {
    const value = obj[key as keyof T]

    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      sanitizedObj[key as keyof T] = sanitizeObject(value)
    } else if (typeof value === 'string' && value.trim() !== '') {
      sanitizedObj[key as keyof T] = value.trim() as T[keyof T]
    } else if (
      (typeof value === 'string' && value.trim() === '') ||
      typeof value === 'undefined'
    ) {
      sanitizedObj[key as keyof T] = null as T[keyof T]
    } else {
      sanitizedObj[key as keyof T] = value
    }
  }

  return sanitizedObj
}
