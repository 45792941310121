import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import { useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Cell } from 'react-table'

import { BodySmall, Label } from '@cais-group/approved/ui/typography'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CourseProgressCell } from '@cais-group/caisiq/ui/table/cells'
import { customTableSort } from '@cais-group/caisiq/util/js/custom-table-sort'
import { TableAssessmentStatusTag } from '@cais-group/caisiq-ui-table-assessment-status-tag'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import {
  HomeOfficeCourseStatusCommon,
  FormattedHomeOfficePivotData,
  AssessmentStatus,
} from '@cais-group/shared/util/type/homeoffice-data'

const StyledDate = styled(BodySmall)``

const AssessmentScore = styled(BodySmall)``

const RequirementMet = styled(BodySmall)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--s8);
  color: rgb(var(--colors-neutral-900));
  text-align: center;
  width: 162px;
`

const RequirementNotMet = styled(BodySmall)`
  width: 162px;
  height: var(--s24);
  display: flex;
  align-items: center;
  color: rgb(var(--colors-neutral-500));
`

const StyledCheck = styled(CheckIcon)`
  fill: rgb(var(--colors-success-600));
`
const CourseName = styled(Label)`
  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) and (max-width: ${BREAKPOINTS.breakpointXl}) {
    width: var(--s232);
  }
  @media screen and (max-width: ${BREAKPOINTS.breakpointSm}) {
    &:first-of-type {
      width: var(--s144);
    }
  }
`

type FormatPivotTableProps = {
  data: HomeOfficeCourseStatusCommon[]
}

const blankAssessmentStatusTag = (status: string) => {
  if (status === 'unstarted' || status === 'locked' || status === 'unlocked') {
    return true
  } else {
    return false
  }
}
export const useFormatPivotTableData = (props: FormatPivotTableProps) => {
  const { data } = props

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 75))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const courseData: FormattedHomeOfficePivotData[] = data.map((dataPoint) => {
    return {
      ...dataPoint,
      assessmentStatus:
        dataPoint.assessment &&
        !blankAssessmentStatusTag(dataPoint.assessment.status)
          ? dataPoint.assessment.status
          : AssessmentStatus.NotApplicable,
      assessmentScore:
        dataPoint.assessment && dataPoint.assessment.score
          ? dataPoint.assessment.score
          : undefined,
      assessmentDateCompleted:
        dataPoint.assessment && dataPoint.assessment.completed
          ? dataPoint.assessment.completed
          : undefined,
      learnedAt: dataPoint.learnedAt ? dataPoint.learnedAt : undefined,
    }
  })

  const desktopPivotColumns = useMemo(
    () => [
      {
        Header: 'Course name',
        accessor: 'name',
        width: 290,
        minWidth: 290,
        maxWidth: 290,
        Cell: (cell: Cell) => {
          return <CourseName>{cell.value}</CourseName>
        },
      },
      {
        Header: 'Course progress',
        accessor: 'progress',
        Cell: CourseProgressCell,
        width: 110,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'Assessment status',
        accessor: 'assessmentStatus',
        Cell: (cell: Cell) => {
          return (
            <TableAssessmentStatusTag status={cell.value as AssessmentStatus} />
          )
        },
        width: 110,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'Assessment score',
        accessor: 'assessmentScore',
        sortType: customTableSort,
        Cell: (cell: Cell) => {
          return (
            <AssessmentScore data-testid="assessment-score">
              {cell.value
                ? `${Math.round((cell.value as number) * 100)}%`
                : '-'}
            </AssessmentScore>
          )
        },
        width: 110,
        maxWidth: 110,
        minWidth: 110,
      },
      {
        Header: 'Date assessment completed',
        accessor: 'assessmentDateCompleted',
        sortType: customTableSort,
        Cell: (cell: Cell) => {
          return (
            <StyledDate data-testid="assessment-date-completed">
              {cell.value
                ? dayjs(cell.value as string).format('MM/DD/YY')
                : '-'}
            </StyledDate>
          )
        },
        width: 140,
        maxWidth: 170,
        minWidth: 140,
      },
      {
        Header: 'Product access requirement',
        accessor: 'learned',
        Cell: (cell: Cell) => {
          return cell.value ? (
            <RequirementMet data-testid="requirement-met">
              <StyledCheck />
              Requirement met
            </RequirementMet>
          ) : (
            <RequirementNotMet data-testid="requirement-met">
              Requirement not met
            </RequirementNotMet>
          )
        },
        width: 110,
        maxWidth: 120,
        minWidth: 120,
        disableSortBy: true,
      },
      {
        Header: 'Date course completed',
        accessor: 'learnedAt',
        id: 'learnedAt',
        sortType: customTableSort,
        Cell: (cell: Cell) => {
          return (
            <StyledDate data-testid="date-course-completed">
              {cell.value
                ? dayjs(cell.value as string).format('MM/DD/YY')
                : '-'}
            </StyledDate>
          )
        },
        width: 150,
        maxWidth: 180,
        minWidth: 150,
      },
    ],

    []
  )

  const tabletPivotColumns = useMemo(() => {
    if (isTablet) {
      return [
        {
          Header: 'Course name',
          accessor: 'name',
          width: 246,
          minWidth: 246,
          maxWidth: 246,
        },
        {
          Header: 'Course progress',
          accessor: 'progress',
          Cell: CourseProgressCell,
          width: 110,
          minWidth: 110,
          maxWidth: 110,
        },
        {
          Header: 'Assessment status',
          accessor: 'assessmentStatus',
          Cell: (cell: Cell) => {
            return (
              <TableAssessmentStatusTag
                status={cell.value as AssessmentStatus}
              />
            )
          },
          width: 100,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          Header: 'Assessment score',
          accessor: 'assessmentScore',
          sortType: customTableSort,
          Cell: (cell: Cell) => {
            return (
              <AssessmentScore data-testid="assessment-score">
                {cell.value
                  ? `${Math.round((cell.value as number) * 100)}%`
                  : '-'}
              </AssessmentScore>
            )
          },
          width: 110,
          maxWidth: 110,
          minWidth: 110,
        },
        {
          Header: 'Date assessment completed',
          accessor: 'assessmentDateCompleted',
          sortType: customTableSort,
          Cell: (cell: Cell) => {
            return (
              <StyledDate data-testid="assessment-date-completed">
                {cell.value
                  ? dayjs(cell.value as string).format('MM/DD/YY')
                  : '-'}
              </StyledDate>
            )
          },
          width: 170,
          maxWidth: 170,
          minWidth: 170,
        },
        {
          Header: 'Product access requirement',
          accessor: 'learned',
          Cell: (cell: Cell) => {
            return cell.value ? (
              <RequirementMet data-testid="requirement-met">
                <StyledCheck />
                Requirement met
              </RequirementMet>
            ) : (
              <RequirementNotMet data-testid="requirement-met">
                Requirement not met
              </RequirementNotMet>
            )
          },
          width: 135,
          maxWidth: 135,
          minWidth: 135,
          disableSortBy: true,
        },
        {
          Header: 'Date course completed',
          accessor: 'learnedAt',
          sortType: customTableSort,
          Cell: (cell: Cell) => {
            return (
              <StyledDate data-testid="date-course-completed">
                {cell.value
                  ? dayjs(cell.value as string).format('MM/DD/YY')
                  : '-'}
              </StyledDate>
            )
          },
          width: 180,
          maxWidth: 180,
          minWidth: 180,
        },
      ]
    }
    return
  }, [isTablet])

  const mobilePivotColumns = useMemo(() => {
    if (isMobile) {
      return [
        {
          Header: 'Course name',
          accessor: 'name',
          width: 120,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          Header: 'Course progress',
          accessor: 'progress',
          Cell: CourseProgressCell,
          width: 110,
          minWidth: 110,
          maxWidth: 110,
        },
        {
          Header: 'Assessment status',
          accessor: 'assessmentStatus',
          Cell: (cell: Cell) => {
            return (
              <TableAssessmentStatusTag
                status={cell.value as AssessmentStatus}
              />
            )
          },
          width: 100,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          Header: 'Assessment score',
          accessor: 'assessmentScore',
          sortType: customTableSort,
          Cell: (cell: Cell) => {
            return (
              <AssessmentScore data-testid="assessment-score">
                {cell.value
                  ? `${Math.round((cell.value as number) * 100)}%`
                  : '-'}
              </AssessmentScore>
            )
          },
          width: 100,
          maxWidth: 100,
          minWidth: 100,
        },
        {
          Header: 'Date assessment completed',
          accessor: 'assessmentDateCompleted',
          sortType: customTableSort,
          Cell: (cell: Cell) => {
            return (
              <StyledDate data-testid="assessment-date-completed">
                {cell.value
                  ? dayjs(cell.value as string).format('MM/DD/YY')
                  : '-'}
              </StyledDate>
            )
          },
          width: 125,
          maxWidth: 170,
          minWidth: 125,
        },
        {
          Header: 'Product access requirement',
          accessor: 'learned',
          Cell: (cell: Cell) => {
            return cell.value ? (
              <RequirementMet data-testid="requirement-met">
                <StyledCheck />
                Requirement met
              </RequirementMet>
            ) : (
              <RequirementNotMet data-testid="requirement-met">
                Requirement not met
              </RequirementNotMet>
            )
          },
          width: 140,
          maxWidth: 140,
          minWidth: 140,
          disableSortBy: true,
        },
        {
          Header: 'Date course completed',
          accessor: 'learnedAt',
          sortType: customTableSort,
          Cell: (cell: Cell) => {
            return (
              <StyledDate data-testid="date-course-completed">
                {cell.value
                  ? dayjs(cell.value as string).format('MM/DD/YY')
                  : '-'}
              </StyledDate>
            )
          },
          width: 175,
          maxWidth: 175,
          minWidth: 175,
        },
      ]
    }
    return
  }, [isMobile])

  if (isMobile) {
    return {
      pivotData: courseData,
      pivotColumns: mobilePivotColumns,
    }
  }

  if (isTablet) {
    return {
      pivotData: courseData,
      pivotColumns: tabletPivotColumns,
    }
  }

  return {
    pivotData: courseData,
    pivotColumns: desktopPivotColumns,
  }
}
