import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import {
  ApiError,
  ApiState,
  ApiStateEnum,
  checkContentAccess,
  isData,
  isError,
  parseFirstFromCollection,
  transformContentPermissionsData,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  GetVideoQuery,
  useGetVideoPermissionsQuery,
  useGetVideoQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

import { FIRMS_COLLECTION_LIMIT } from '../constants'

import { TypeVideoDataWithParentPlaylist } from './types'

export type VideoApiState = ApiState<TypeVideoDataWithParentPlaylist>

export type VideoResult = {
  caisiqVideo: TypeVideoDataWithParentPlaylist
}

type Data = VideoApiState | null

export const useGetVideo = (id?: string) => {
  const allowedUserPermissions = useGetAllowedContentPermissions()
  const { fundProductIds, firmIds } = allowedUserPermissions.data

  const { data: videoPermissionsData, isError: videoPermissionsDataError } =
    useGetVideoPermissionsQuery(
      {
        id: id || '',
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      {
        enabled: Boolean(fundProductIds && firmIds),
        select: (data) =>
          transformContentPermissionsData({
            data: data,
            name: 'caisiqVideoCollection',
          }),
        refetchOnWindowFocus: false,
      }
    )

  const hasAccess = checkContentAccess(
    allowedUserPermissions.data,
    videoPermissionsData
  )

  const notFound =
    Boolean(videoPermissionsData?.notFound) && !previewService.enabled

  const existsWithNoAccess = !hasAccess && !notFound

  const response = useReactQueryResultAsApiState<GetVideoQuery, VideoApiState>(
    useGetVideoQuery(
      {
        id: id || '',
        preview: previewService.enabled,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      {
        enabled:
          hasAccess &&
          !notFound &&
          Boolean(fundProductIds && firmIds && videoPermissionsData),
        refetchOnWindowFocus: false,
      }
    ),
    (data) =>
      parseFirstFromCollection(
        data,
        'caisiqVideoCollection'
      ) as TypeVideoDataWithParentPlaylist,

    'Could not load caisiqVideoEntryQuery'
  )

  // This might not be necessary but there might be videos in a playlist videoCollection that users do not have access to and we want to be sure that they are not shown in playlist
  const filteredResponse = isData(response)
    ? {
        ...response,
        linkedFrom: {
          ...response.linkedFrom,
          caisiqVideoPlaylistCollection: {
            ...response.linkedFrom?.caisiqVideoPlaylistCollection,
            items:
              response.linkedFrom?.caisiqVideoPlaylistCollection?.items?.map(
                (playlist) => {
                  return {
                    ...playlist,
                    videosCollection: {
                      ...playlist.videosCollection,
                      items: playlist.videosCollection?.items?.filter(
                        (video) => {
                          // @ts-ignore
                          return video.fundProductIds?.fundProductIds
                            ? // @ts-ignore
                              video.fundProductIds?.fundProductIds?.some(
                                (id) => id && fundProductIds?.includes(id)
                              )
                            : true
                        }
                      ),
                    },
                  }
                }
              ),
          },
        },
      }
    : response

  const loading =
    filteredResponse === ApiStateEnum.LOADING ||
    allowedUserPermissions.isLoading
  const error = isError(filteredResponse) || videoPermissionsDataError

  return compileResults(
    filteredResponse,
    existsWithNoAccess,
    notFound,
    loading,
    error
  )
}

const compileResults = (
  data: Data | ApiError | ApiStateEnum,
  existsWithNoAccess: boolean,
  notFound: boolean,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
    existsWithNoAccess,
    notFound,
  }
}
