import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { Role, User } from '@cais-group/shared/util/type/caisiq-be'
import { ExperienceData } from '@cais-group/shared/util/type/experience-data'

import { userSettingsService } from './caisiq-util-user-settings-service'

type MockUserType = {
  capabilities?: Record<string, boolean>
  roles?: Array<Role>
  switchedUser?: boolean
  permissions?: Array<string>
}
export const initiateUserSettingsWithMockData = (args: MockUserType = {}) => {
  const {
    capabilities = {},
    roles = [],
    switchedUser = false,
    permissions = [],
    ...rest
  } = args
  userSettingsService.initiateUser({
    fullname: 'Caisiq User',
    experience: { capabilities: capabilities },
    roles: roles,
    switchedUser,
    permissions: permissions,
    ...rest,
  } as User)
  userSettingsService.initiateFirm({} as ExperienceData)
  previewService.setResolver({
    hasRole: (r) => r === 'portal:ROLE_CAISIQ_ADMIN',
  })
}
