import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { ReactNode, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { useConfig } from '@cais-group/caisiq-ui-env'

export type Auth0ExperienceAwareProviderProps = {
  children: ReactNode
}

// This is only used during Cypress tests
const sessionStorageCache = {
  get: function (key: string): string | null {
    const storageItem = sessionStorage.getItem(key)
    return storageItem ? JSON.parse(storageItem) : null
  },
  set: function (key: string, value: string) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  remove: function (key: string) {
    sessionStorage.removeItem(key)
  },
}

export function Auth0ExperienceAwareProvider({
  children,
}: Auth0ExperienceAwareProviderProps) {
  const navigate = useNavigate()
  const config = useConfig()

  const authType = experienceService.getExperienceAuth()

  const onRedirectCallback = useCallback(
    (appState?: AppState | undefined): void => {
      const redirectPath =
        sessionStorage.getItem('auth0:deeplink') ??
        window.location.pathname + window.location.search
      navigate(redirectPath, {
        state: appState,
      })
    },
    [navigate]
  )

  const [clientId, audience] = useMemo(() => {
    return authType === 'direct'
      ? [config.AUTH0_CLIENT_ID, config.AUTH0_DIRECT_AUDIENCE]
      : [config.AUTH0_MEMBERS_CLIENT_ID, config.AUTH0_MEMBERS_AUDIENCE]
  }, [authType, config])

  if (config === undefined || authType === undefined) {
    return null
  }

  return (
    <Auth0Provider
      clientId={clientId}
      domain={config.AUTH0_DOMAIN}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${ROUTES.home}`,
        audience,
      }}
      authorizeTimeoutInSeconds={5}
      // When Cypress tests are running, swap the default inMemory cache for sessionStorageCache so we can manipulate the state
      // @ts-ignore
      cache={window.Cypress ? sessionStorageCache : undefined}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ExperienceAwareProvider
