export function pathOr<TExpected = unknown, TDefault = TExpected>(
  defaultValue: TDefault,
  path: (string | number)[],
  thing: unknown | unknown[]
): TExpected | TDefault {
  if (path.length === 0) {
    return defaultValue
  }

  let currentValue: unknown = thing

  for (const key of path) {
    if (currentValue === null || currentValue === undefined) {
      return defaultValue
    }
    if (typeof currentValue === 'object') {
      currentValue = (currentValue as Record<string | number, unknown>)[key]
    }
  }

  if (currentValue === undefined || currentValue === '') {
    return defaultValue
  }

  return currentValue as unknown as TExpected
}
