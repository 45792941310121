import styled from '@emotion/styled'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useMediaQuery, useTheme } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import {
  H5,
  H4,
  CaptionRegular,
  H3Serif,
} from '@cais-group/approved/ui/typography'
import { modalStateAtom, isData } from '@cais-group/caisiq/domain/common'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { useAppTheme } from '@cais-group/caisiq/util/hook/use-app-theme'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { getEnvConfig } from '@cais-group/caisiq-ui-env'
import { Button } from '@cais-group/equity/atoms/button'
import { colors } from '@cais-group/equity/particles/colors'
import { BlockLink } from '@cais-group/shared/ui/block-link'
import { ContextMenuApp } from '@cais-group/shared/ui/context-menu/app'
import { LayoutCluster } from '@cais-group/shared/ui/layout'
import {
  LogoPoweredByCaisStacked,
  LogoCaisIqDark,
  LogoCaisIqLight,
} from '@cais-group/shared/ui/logos'
import { transparentize } from '@cais-group/shared/util/color/transparentize'

export type TypeHomeOfficeCallToActionData = {
  destination: string
  buttonText: string
}

export const enum NavTheme {
  WithHero = 'navHeaderWithHero',
  WithIframe = 'navHeaderWithIframe',
  ForAdmin = 'navHeaderForAdmin',
}

export type NavigationProps = {
  userName?: string
  subnav?: string
  headerThemeName: NavTheme
  navButtonsMap?: TNavButtonsMap
}

const HeaderNav = styled.div`
  padding-top: var(--s32);
  padding-bottom: var(--s32);
  padding-left: var(--s32);
  padding-right: var(--s32);
`

export const UnderlinedSerif = styled(H3Serif)`
  color: inherit;
  display: inline-block;
  border-bottom: 2px solid rgb(var(--colors-warning-600));
`

type UserNameProps = {
  color: string
}
export const UserName = styled(CaptionRegular)<UserNameProps>`
  letter-spacing: 0.0625rem;
  margin-right: var(--s12);
  line-height: 0; // We do not want to affect the overall height of the nav bar
  color: ${(props) => props.color};
`

type NavContainerProps = {
  backgroundColor: string
  underlineColor: string
  underlineOpacity: number
}
const NavContainer = styled.header<NavContainerProps>`
  background-color: ${(props) => props.backgroundColor};
  ${(props) =>
    `border-bottom: 1px solid ${transparentize(
      props.underlineColor,
      props.underlineOpacity
    )}`};
`
NavContainer.defaultProps = {
  role: 'banner',
}

const StyledH4 = styled(H4)`
  color: rgb(var(--colors-neutral-300));
`
const StyledH5 = styled(H5)`
  color: rgb(var(--colors-neutral-600));
`
const VerticalRule = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.14);
  align-self: stretch;
`

const ClientLogoImage = styled.img`
  height: var(--s32);
`

const VisibleOverflowCluster = styled(LayoutCluster)`
  overflow: visible;
`

const StyledButton = styled.button`
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.24);
  margin-right: var(--s16);
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.64);
  }
`

const StyledButtonContainer = styled.div<{
  color: string
}>`
  & > button {
    background-color: transparent;
    border: 1px solid ${({ color }) => transparentize(color, 0.24)} !important;
    margin-right: var(--s16);
    font-size: 0.875rem;
    color: ${({ color }) => color} !important;
    &:hover {
      border: 1px solid ${({ color }) => transparentize(color, 0.64)} !important;
    }
  }
`
const StyledLogoCaisIqDark = styled(LogoCaisIqDark)`
  width: 129px;
  height: 19px;
`
StyledLogoCaisIqDark.displayName = 'StyledLogoCaisIqDark'

const StyledLogoCaisIqLight = styled(LogoCaisIqLight)`
  width: 129px;
  height: 19px;
`
StyledLogoCaisIqLight.displayName = 'StyledLogoCaisIqLight'

export type CaisiqDropDownMenuProps = {
  items: Array<TNavButton | undefined>
  navButtonsColor: string
}

export const CaisiqNavigationMenu = (props: CaisiqDropDownMenuProps) => {
  const { items } = props

  const menuRef = useRef<HTMLDivElement>(null)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = () => {
    setAnchorEl(menuRef.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={{ height: '38px' }}>
      <StyledButton
        onClick={handleClick}
        style={{
          color: props.navButtonsColor,
          height: '38px',
          width: '120px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          borderColor:
            transparentize(props.navButtonsColor, 0.24) ||
            props.navButtonsColor,
        }}
      >
        Menu
        <ArrowDropDownIcon style={{ marginLeft: '16px' }} />
      </StyledButton>
      <div style={{ width: '100%' }} ref={menuRef} />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {items.map((item, index) => {
          if (!item) {
            return null
          }

          return (
            <MenuItem
              disableGutters={false}
              style={{
                width: '238px',
                justifyContent: 'flex-start',
              }}
              key={index}
              onClick={handleClose}
            >
              <Link
                to={item.destination}
                style={{
                  textDecoration: 'none',
                  color: colors['eq-color-neutral-900'],
                }}
                onClick={item?.onClick}
              >
                {item.buttonText}
              </Link>
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

interface TNavButton {
  destination: string
  buttonText: string
  onClick: () => void
}

export interface TNavButtonsMap {
  homeOffice?: TNavButton
  manageCaisiq?: TNavButton
}

interface CaisiqNavigationLinksProps {
  navButtonsMap?: TNavButtonsMap
  navButtonsColor: string
}

/**
 * A temporary component to aid users navigation to Portal, Home Office and Manage CAIS IQ.
 * @todo: Remove component once CAIS IQ is migrated to the global nav.
 */
export const CaisiqNavigationLinks = (props: CaisiqNavigationLinksProps) => {
  const { homeOffice, manageCaisiq } = props.navButtonsMap ?? {}
  const { PORTAL_URL } = getEnvConfig()

  const isOnlyFundManager = userSettingsService.hasPersona({
    keys: 'FUND_MANAGER',
    strategy: 'ONLY',
  })

  const backHomeDestination = isOnlyFundManager
    ? `${PORTAL_URL}/fund-manager`
    : PORTAL_URL

  const muiTheme = useTheme()
  const isTablet = useMediaQuery(muiTheme.breakpoints.down('md'))
  const experience = userSettingsService.theme
  // This would be true if the user is not authenticated and an experience cannot be fetched
  // from Contentful.
  const isFallbackExperience = !isData(experience)
  // Filter out undefined values.
  const navMenuItems = [homeOffice, manageCaisiq].filter(Boolean)

  if (isTablet) {
    // Do not show the Menu button if no nav buttons are passed.
    if (navMenuItems.length === 0) {
      return null
    }
    return (
      <CaisiqNavigationMenu
        items={navMenuItems}
        navButtonsColor={props.navButtonsColor}
      />
    )
  }

  return (
    <>
      {/**
       *  The 'Back to Portal' button is a temporary addition to help users navigate
       *  between the platforms until CAIS IQ and Portal share the main navigation.
       * .We don't show it on mobile because Portal at the moment is not mobile friendly.
       */}
      {!isFallbackExperience &&
        experience?.heroHeaderNavigateToButton?.buttonText && (
          <a
            href={backHomeDestination}
            style={{
              textDecoration: 'none',
              color: 'rgb(var(--colors-neutral-0))',
            }}
            data-testid="back-to-link"
          >
            <StyledButtonContainer color={props.navButtonsColor}>
              <Button
                variant="secondary"
                id="1"
                size="medium"
                data-testid="back-to-button"
              >
                {experience.heroHeaderNavigateToButton.buttonText}
              </Button>
            </StyledButtonContainer>
          </a>
        )}
      {homeOffice?.buttonText && (
        <NavButton item={homeOffice} navButtonsColor={props.navButtonsColor} />
      )}
      {manageCaisiq?.buttonText && (
        <NavButton
          item={manageCaisiq}
          navButtonsColor={props.navButtonsColor}
        />
      )}
    </>
  )
}

const NavButton = (props: { item: TNavButton; navButtonsColor: string }) => {
  const { item, navButtonsColor } = props
  return (
    <Link
      to={item.destination}
      style={{ textDecoration: 'none', color: 'rgb(var(--colors-neutral-0))' }}
    >
      <StyledButtonContainer color={navButtonsColor}>
        <Button size="medium" onClick={item?.onClick} variant="secondary">
          {item.buttonText}
        </Button>
      </StyledButtonContainer>
    </Link>
  )
}

export const Navigation = ({
  userName,
  subnav,
  headerThemeName,
  navButtonsMap,
}: NavigationProps) => {
  const theme = useAppTheme()

  const [, setModalState] = useRecoilState(modalStateAtom)

  const logoMap = {
    dark: StyledLogoCaisIqDark,
    light: StyledLogoCaisIqLight,
  } as const

  const CaisIqLogo = logoMap[theme[headerThemeName].caisIqLogo]
  const clientLogoUrl = theme[headerThemeName].clientLogoUrl
  const backgroundColor = theme[headerThemeName].backgroundColor
  const foregroundColor = theme[headerThemeName].foregroundColor
  const underlineColor = theme[headerThemeName].underlineColor
  const underlineOpacity = theme[headerThemeName].underlineOpacity
  const poweredBy = theme[headerThemeName].poweredBy

  return (
    <NavContainer
      backgroundColor={backgroundColor}
      underlineColor={underlineColor}
      underlineOpacity={underlineOpacity}
    >
      <HeaderNav>
        <VisibleOverflowCluster justify="space-between">
          {poweredBy ? (
            <LayoutCluster space="var(--s24)" align="baseline">
              {clientLogoUrl && (
                <ClientLogoImage src={clientLogoUrl} alt="client logo" />
              )}
              {poweredBy && (
                <>
                  <VerticalRule></VerticalRule>
                  <LogoPoweredByCaisStacked />
                </>
              )}
            </LayoutCluster>
          ) : (
            <LayoutCluster space="var(--s16)">
              <BlockLink to={ROUTES.dashboard} data-testid="header-logo">
                <CaisIqLogo onClick={() => setModalState(undefined)} />
              </BlockLink>
              {subnav && (
                <>
                  <StyledH4>|</StyledH4>
                  <StyledH5>{subnav}</StyledH5>
                </>
              )}
              {clientLogoUrl && (
                <>
                  <VerticalRule></VerticalRule>
                  <ClientLogoImage src={clientLogoUrl} alt="client logo" />
                </>
              )}
            </LayoutCluster>
          )}
          {userName && (
            <VisibleOverflowCluster space="var(--s8)">
              <CaisiqNavigationLinks
                navButtonsMap={navButtonsMap}
                navButtonsColor={foregroundColor}
              />

              <UserName color={foregroundColor} data-testid="header-username">
                {userName}
              </UserName>
              <ContextMenuApp fill={foregroundColor} />
            </VisibleOverflowCluster>
          )}
        </VisibleOverflowCluster>
      </HeaderNav>
    </NavContainer>
  )
}
