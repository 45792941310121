import styled from '@emotion/styled'
import { ReactElement, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useRecoilState } from 'recoil'

import { BodySmall, H5Strong } from '@cais-group/approved/ui/typography'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { LayoutSettingsPage } from '@cais-group/caisiq/ui/layout/settings-page'
import { CapabilitiesStep } from '@cais-group/caisiq/ui/manage/capabilities-step'
import { CourseConfigurationStep } from '@cais-group/caisiq/ui/manage/course-configuration-step'
import { filterExperienceCoursesBySearch } from '@cais-group/caisiq/ui/manage/course-configuration-table'
import { ExperienceNameStepDropdown } from '@cais-group/caisiq/ui/manage/experience-name-step'
import { ModalCancel } from '@cais-group/caisiq/ui/modal/cancel'
import {
  ExperienceFields,
  ExperienceListBox,
} from '@cais-group/caisiq/util/type/manage-caisiq'
import { Avatar } from '@cais-group/equity/atoms/avatar'
import { Button } from '@cais-group/equity/atoms/button'
import { colors } from '@cais-group/equity/particles/colors'
import { BREAKPOINTS, SPACING } from '@cais-group/shared/ui/design-tokens'
import {
  CaisIqFirm,
  Catalog,
  CourseMetadata,
  ExperienceResponse,
} from '@cais-group/shared/util/type/caisiq-be'

const FormLeftContainer = styled.section`
  max-width: 560px;
  display: flex;
  flex-direction: column;
  grid-column: 1 / 1;
  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    flex-direction: column;
    max-width: 560px;
    align-self: center;
    justify-content: center;
    > fieldset > div {
      width: 560px;
    }
  }
`
const ButtonContainer = styled.div`
  grid-column: 1 / 2;
  margin-left: calc(${SPACING.s56} / 2);
  padding-left: calc(${SPACING.s56} / 2 + ${SPACING.s32});
`

const Content = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${SPACING.s56};
`

const TeamDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${SPACING.s24};
`
const StyledBodySmall = styled(BodySmall)`
  color: ${colors['eq-color-neutral-600']};
`

const LegendContainer = styled.div`
  display: flex;
`
const StyledH5Strong = styled(H5Strong)<{ color: string }>`
  color: ${({ color }) => color};
`
export type EnableCaisIqProps = {
  firmExperiences: ExperienceResponse[]
  firm: CaisIqFirm
  catalogs: Catalog[]
  headerNav: ReactElement
  saveLabel?: string
  sideNavigation?: ReactElement
  handleSubmit(request: ExperienceFields): void
  defaultCourses?: CourseMetadata[]
  coursesFetched?: boolean
  contentfulExperiences?: ExperienceListBox
}

export const EnableCaisiqPage = ({
  firmExperiences,
  firm,
  catalogs,
  sideNavigation,
  headerNav,
  saveLabel,
  handleSubmit,
  defaultCourses = [],
  coursesFetched,
  contentfulExperiences,
}: EnableCaisIqProps) => {
  const [searchTerm] = useRecoilState(searchTermAtom)
  const methods = useForm<ExperienceFields>({
    defaultValues: {
      name: firm.experience?.uuid,
      label: firm.experience?.label,
      contentfulId: firm.experience?.contentfulId as string,
      coursesEnabled: firm.experience?.coursesEnabled,
      ceCreditEnabled: firm.experience?.ceCreditEnabled,
      active: firm.active,
      catalogId: firm.experience?.catalog?.id,
      courses: defaultCourses,
    },
  })

  const formCourses = methods.watch('courses')

  // we want the submit buttons to not be disabled unless there happens to be no experience entered
  useEffect(() => {
    methods.setValue('active', Boolean(firm.experience?.uuid), {
      shouldDirty: true,
    })
  }, [firm.experience?.uuid, methods])

  const maybeSearchFilteredExperienceCourses = filterExperienceCoursesBySearch(
    searchTerm,
    formCourses
  )

  return (
    <LayoutSettingsPage<ExperienceFields>
      onSubmit={handleSubmit}
      methods={methods}
      sideNavigation={sideNavigation}
      headerNav={headerNav}
    >
      <FormLeftContainer>
        <Content>
          <Avatar type="group" size="large" testId={`${firm.name}-acronym`}>
            {firm.name}
          </Avatar>
          <TeamDetails>
            <H5Strong aria-label={`enable-page-${firm.name}`}>
              {firm.name}
            </H5Strong>
            <StyledBodySmall>CAIS ID: {firm.id}</StyledBodySmall>
          </TeamDetails>
        </Content>
        <ExperienceNameStepDropdown
          firmExperiences={firmExperiences}
          contentfulExperiences={contentfulExperiences}
          order={1}
          legend={
            <LegendContainer>
              <StyledH5Strong color={colors['eq-color-neutral-900']}>
                Select an Experience
              </StyledH5Strong>
              <StyledH5Strong color={colors['eq-color-error-600']}>
                *
              </StyledH5Strong>
            </LegendContainer>
          }
        />
        <CapabilitiesStep
          disabled
          order={2}
          legend="Review Capabilities"
          ignoreStep
          catalogs={catalogs}
          formLabels={{ catalogId: 'ALM Course Catalog' }}
        />
        <CourseConfigurationStep
          order={3}
          legend="Course Configuration"
          // This currently only controls the formToggles
          disabled={true}
          ignoreStep
          courses={maybeSearchFilteredExperienceCourses}
          isFetched={coursesFetched}
        />
      </FormLeftContainer>
      <ButtonContainer>
        <Button type="submit">{saveLabel || ''}</Button>
      </ButtonContainer>
      <ModalCancel />
    </LayoutSettingsPage>
  )
}
