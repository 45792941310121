/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Experience Manager API
 * API for Theme and Content Management
 * OpenAPI spec version: v1
 */
export type UploadOrUpdateFirmAssetBody = {
  /** The file to upload. */
  file: Blob
}

export type GetFirmAssets200 = { [key: string]: Asset }

/**
 * The values and paths to update on the object, following JsonPatch syntax
 */
export type PatchRequestBodyBody = PatchDocument[]

/**
 * Internal Server Error
 */
export type N500Response = ErrorResponseV1

/**
 * Precondition failed
 */
export type N412Response = ErrorResponseV1

/**
 * Conflict
 */
export type N409Response = ErrorResponseV1

/**
 * Not found
 */
export type N404Response = ErrorResponseV1

/**
 * User not authorized to perform operation
 */
export type N403Response = ErrorResponseV1

/**
 * User not authenticated
 */
export type N401Response = ErrorResponseV1

/**
 * Bad request
 */
export type N400Response = ErrorResponseV1

/**
 * The type of action which the event occurred (create|update|delete|read)
 */
export type EnvelopeTypeActionType =
  (typeof EnvelopeTypeActionType)[keyof typeof EnvelopeTypeActionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnvelopeTypeActionType = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  read: 'read',
} as const

/**
 * Object to specify the type of the event enclosed within the envelope.
 */
export interface EnvelopeTypeAction {
  /** The name of action which the event occurred */
  name: string
  /** The type of action which the event occurred (create|update|delete|read) */
  type: EnvelopeTypeActionType
}

/**
 * Object to specify the type of the event enclosed within the envelope.
 */
export type EnvelopeType = {
  action: EnvelopeTypeAction
  /** The list of actions which describe the changes in the event occurred */
  actions?: EnvelopeTypeAction[]
  /** The domain of the event */
  domain: string
  /** The type of resource upon which the event occurred */
  resource: string
  /** The schema major version of the event type */
  version: number
}

export type EnvelopeRelatedIdsItem = {
  /** The domain of the related resource ID */
  domain: string
  /** Unique ID of the related resource ID */
  id: string
  /** The resource type of the related ID */
  resource: string
  /** The schema major version of the resource of the related ID */
  version?: number
}

/**
 * Object to specify the actor of the event enclosed within the envelope. This can include both the real and switch user.
 */
export type EnvelopeActor = {
  /** The ID of the real user. */
  actorId: string
  /** The ID of the effective user. Only required where the real user is different from the effective user. */
  switchActorId?: string | null
}

/**
 * CAIS Event envelope model
 */
export interface Envelope {
  /** Object to specify the actor of the event enclosed within the envelope. This can include both the real and switch user. */
  actor?: EnvelopeActor
  /**
   * Deprecated. Use actor instead.
   * @deprecated
   */
  actorId?: string
  /** Optional correlation identifier which can be used to logically group or associate events */
  correlationId?: string
  /** Human readable description of the event */
  description: string
  /** The list of differences represented as JSONPatch PatchDocument objects */
  diff?: PatchDocument[]
  /** The ID of the firm that owns the resource. May not be present if the event does not relate to a specific resource. */
  firmId?: string
  /** Unique identifier for an event */
  id: string
  /** An optional list of related IDs. As an example an Order event may include a related product's ID */
  relatedIds?: EnvelopeRelatedIdsItem[]
  /** The ID of the resource affected by the event. May not be present if the event does not relate to a specific resource. */
  resourceId?: string
  /** ISO Datetime when the event occurred. */
  timestamp: string
  /** Object to specify the type of the event enclosed within the envelope. */
  type: EnvelopeType
}

export interface ThemeEventPayloadV1 {
  theme?: ThemeDetailsV1
}

export type ThemeEventV1 = Envelope & {
  payload: ThemeEventPayloadV1
}

/**
 * The operation to be performed
 */
export type PatchDocumentOp =
  (typeof PatchDocumentOp)[keyof typeof PatchDocumentOp]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchDocumentOp = {
  add: 'add',
  remove: 'remove',
  replace: 'replace',
} as const

/**
 * A JSONPatch document as defined by RFC 6902 https://jsonpatch.com/
 */
export interface PatchDocument {
  /** Previous value for replace operation */
  fromValue?: string
  /** The operation to be performed */
  op: PatchDocumentOp
  /** A JSON-Pointer */
  path: string
  /** The value to be used within the operations. */
  value?: unknown
}

export type FirmExperienceEventV1 = Envelope & {
  payload: FirmExperienceEventPayloadV1
}

export type FirmExperienceV1BrandingTheme = { [key: string]: unknown }

export type FirmExperienceV1Branding = {
  /** Whether the theme is enabled */
  enabled: boolean
  theme?: FirmExperienceV1BrandingTheme
  /** theme id associated with the firm */
  themeId?: string
}

/**
 * Firm experience details
 */
export interface FirmExperienceV1 {
  assets?: FirmExperienceV1Assets
  branding: FirmExperienceV1Branding
  /** ID of the firm */
  firmId: string
}

export interface FirmExperienceEventPayloadV1 {
  firmExperience?: FirmExperienceV1
}

/**
 * Asset Label
 */
export type AssetLabel = string

/**
 * Asset
 */
export interface Asset {
  asset_url: string
}

export type FirmExperienceV1Assets = { [key: string]: Asset }

export type ThemeDetailsV1Data = { [key: string]: unknown }

/**
 * Full Theme details
 */
export interface ThemeDetailsV1 {
  data: ThemeDetailsV1Data
  description?: string
  enabled: boolean
  id: string
  isDefault: boolean
  name: string
  primaryColorHex?: string
}

/**
 * Individual theme
 */
export interface ThemeV1 {
  description?: string
  enabled: boolean
  id: string
  isDefault: boolean
  name: string
  primaryColorHex?: string
}

/**
 * Available themes
 */
export interface ThemesV1 {
  themes: ThemeV1[]
}

export type ThemeRequestV1Data = { [key: string]: unknown }

/**
 * Theme request
 */
export interface ThemeRequestV1 {
  data: ThemeRequestV1Data
  /** Description of the theme */
  description?: string
  /** Whether the theme is enabled */
  enabled: boolean
  /** Whether the theme is default */
  isDefault?: boolean
  /** Name of the theme */
  name: string
  primaryColorHex?: string
}

export type ErrorResponseV1ErrorAttributes = { [key: string]: string }

/**
 * Error response
 */
export interface ErrorResponseV1 {
  displayMessage?: string | null
  errorAttributes?: ErrorResponseV1ErrorAttributes
  errorCode?: string | null
  errorMessage?: string
  errorUri?: string | null
  status?: number
}

export type FirmExperienceRequestV1Theme = {
  /** Whether the theme is enabled */
  enabled?: boolean
  /** theme id to be associated with the firm */
  themeId?: string
}

export interface FirmExperienceRequestV1 {
  theme: FirmExperienceRequestV1Theme
}

export type TableSettingsColumnSortDirectionV1 =
  (typeof TableSettingsColumnSortDirectionV1)[keyof typeof TableSettingsColumnSortDirectionV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TableSettingsColumnSortDirectionV1 = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const

export interface TableSettingsColumnSortV1 {
  /** Column id */
  columnId: string
  direction: TableSettingsColumnSortDirectionV1
}

export interface TableSettingsColumnV1 {
  /** Column id */
  columnId: string
  /** Display name for column */
  name: string
  /** Whether the column is sticky/frozen or should scroll with the table */
  sticky: boolean
  /** Whether the column is visible or hidden */
  visible: boolean
}

/**
 * Optional filters for the table. The format is undefined other than it is an object.
 */
export type TableSettingsRequestV1Filters = { [key: string]: unknown }

/**
 * Aggregate root for table settings
 */
export interface TableSettingsRequestV1 {
  /** List of columns for the table, where the order is the display order. */
  columns?: TableSettingsColumnV1[]
  /** Optional filters for the table. The format is undefined other than it is an object. */
  filters?: TableSettingsRequestV1Filters
  /** Optional group by columns for the table. The order is the display order. Each item is a column id. */
  groupBy?: string[]
  /** Optional user friendly / supplied name for the settings */
  name?: string
  /** Optional sort by columns for the table. The first item is the primary sort, the second is the secondary sort, etc. */
  sortBy?: TableSettingsColumnSortV1[]
}

/**
 * Optional filters for the table. The format is undefined other than it is an object.
 */
export type TableSettingsV1Filters = { [key: string]: unknown }

/**
 * Aggregate root for table settings
 */
export interface TableSettingsV1 {
  /** List of columns for the table, where the order is the display order. */
  columns?: TableSettingsColumnV1[]
  /** Date and time the settings were created */
  readonly createdAt: string
  /** Optional filters for the table. The format is undefined other than it is an object. */
  filters?: TableSettingsV1Filters
  /** Optional group by columns for the table. The order is the display order. Each item is a column id. */
  groupBy?: string[]
  /** Optional user friendly / supplied name for the settings */
  name?: string
  /** Server generated unique identifier for the table settings */
  readonly settingsId: string
  /** Optional sort by columns for the table. The first item is the primary sort, the second is the secondary sort, etc. */
  sortBy?: TableSettingsColumnSortV1[]
  /** Logical table id */
  readonly tableId: string
  /** Date and time the settings were created */
  readonly updatedAt?: string
  /** Version of the settings */
  readonly version: number
}

/**
 * PageInfo describes the pagination information for a page of results.

 */
export interface PageInfoV1 {
  endCursor?: string | null
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  startCursor?: string | null
}
