import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import {
  transformFirmRequest,
  usePutFirm,
} from '@cais-group/caisiq/domain/firm'
import {
  useFormSaveHandler,
  useGetExperienceCoursesMetadata,
} from '@cais-group/caisiq/domain/manage-caisiq'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { useInvalidateByRegex } from '@cais-group/caisiq/util/default-query-function'
import { useGetExperiencesQuery } from '@cais-group/caisiq/util/graphql/contentful'
import { useApiPathWithParams } from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { FormStepProvider } from '@cais-group/shared/ui/ordered-fieldset'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  ApiPaths,
  CaisIqFirm,
  Catalog,
  ExperienceResponse,
} from '@cais-group/shared/util/type/caisiq-be'

import { EditFirm } from './caisiq-feature-manage-edit-firm'
import { useGetSidebarLinks } from './hooks/useGetSidebarLinks'

/**
 *  @todo will links come from fetch along with firm?
 */
export type SideBarLink = {
  destination: string
  buttonText: string
}

export const EditFirmContainer = () => {
  const invalidate = useInvalidateByRegex()
  const navigate = useNavigate()
  const firm = useQuery<CaisIqFirm>(useApiPathWithParams(ApiPaths.getFirm))
  const sidebarLinks = useGetSidebarLinks({ firmId: firm?.data?.id })
  const firmExperiences = useQuery<ExperienceResponse[]>(
    ApiPaths.getAllExperiences
  )
  const experiences = useGetExperiencesQuery({
    preview: previewService.enabled,
  })
  const firmExperience = firm?.data?.experience
  const catalogs = useQuery<Catalog[]>(ApiPaths.getAllCatalogs)

  const {
    data: experienceCoursesMetadata,
    isFetched: experienceCoursesMetadataFetched,
    isFetching: experienceCoursesMetadataFetching,
  } = useGetExperienceCoursesMetadata(firmExperience?.uuid)

  const { mutate } = usePutFirm()

  const handleSubmit = useFormSaveHandler({
    mutate,
    transform: transformFirmRequest(firm.data),
    onSuccess: async () => {
      navigate(ROUTES.manageFirms)
      await invalidate(/manage[/]firms/)
    },
  })

  const handleSubmitWithTracking = (params: ExperienceFields) => {
    return handleSubmit(params).then(() => {
      trackingService.manageFirmUpdated({
        firmId: firm?.data?.id ?? '',
        firmName: firm?.data?.name ?? '',
        firmActive: Boolean(firm?.data?.active),
        firmExperienceId: firm?.data?.experience?.uuid ?? '',
      })
    })
  }

  if (
    firmExperiences.isFetching ||
    firm.isFetching ||
    catalogs.isFetching ||
    experienceCoursesMetadataFetching
  ) {
    return <LoadingContainer state={LoadingState.LOADING} />
  }

  if (!firm.data) {
    return null
  }

  return (
    <FormStepProvider stateless={true}>
      <EditFirm
        sidebarLinks={sidebarLinks}
        firmExperiences={firmExperiences.data || []}
        firm={firm.data}
        catalogs={catalogs.data || []}
        defaultCourses={experienceCoursesMetadata}
        handleSubmit={handleSubmitWithTracking}
        coursesFetched={experienceCoursesMetadataFetched}
        contentfulExperiences={experiences.data?.firmCollection?.items}
      />
    </FormStepProvider>
  )
}
