import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { CardContent } from '@mui/material'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import { ButtonBaseProps } from '@mui/material/ButtonBase'
import MuiCard from '@mui/material/Card'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper, { PopperPlacementType } from '@mui/material/Popper'
import * as React from 'react'
import { MouseEvent, useCallback, useState } from 'react'
import { useMedia } from 'react-use'

import { breakpointsPx } from '@cais-group/equity/particles/breakpoints'
import { muiTheme } from '@cais-group/shared/ui/mui'

const FADE_DURATION_MS = 100

export interface ButtonMenuContainerProps {
  label: string
  variant?: 'dark' | 'neutral'
  buttonProps?: ButtonBaseProps
  menuPlacement?: PopperPlacementType
  onClickAway?: () => void
  children: ({
    isMenuActive,
    setIsMenuActive,
  }: {
    isMenuActive: boolean
    setIsMenuActive: React.Dispatch<React.SetStateAction<boolean>>
  }) => React.ReactElement | null
  'data-testid'?: string
}
export function ButtonMenuContainer({
  label,
  variant = 'dark',
  buttonProps,
  menuPlacement: externalMenuPlacement = 'bottom-start',
  onClickAway,
  children,
  'data-testid': dataTestId,
}: ButtonMenuContainerProps) {
  const isNotMobile = useMedia(`(min-width: ${breakpointsPx.sm})`)
  const anchorEl = React.useRef<HTMLButtonElement | null>(null)
  const menuEl = React.useRef<HTMLDivElement | null>(null)
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [menuPlacement, setMenuPlacement] = useState<PopperPlacementType>(
    externalMenuPlacement
  )
  const [menuOpacity, setMenuOpacity] = useState(0)

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  )

  const fadeAndDisplayMenu = useCallback(
    (isMenuActive: boolean) => {
      setMenuOpacity(0)
      if (isMenuActive) {
        setIsMenuActive(isMenuActive)
        setTimeout(() => setMenuOpacity(1), 0)
      } else {
        setTimeout(() => setIsMenuActive(isMenuActive), FADE_DURATION_MS)
      }
    },
    [setMenuOpacity, setIsMenuActive]
  )

  const toggleMenu = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      fadeAndDisplayMenu(!isMenuActive)

      setTimeout(() => {
        const menu = menuEl.current?.getBoundingClientRect()
        const anchor = anchorEl.current?.getBoundingClientRect()

        if (anchor && menu && menuEl && menuEl.current) {
          const placement =
            anchor.left + menu.width > vw ? 'bottom-end' : 'bottom-start'

          setMenuPlacement(placement)
          fadeAndDisplayMenu(!isMenuActive)
        }
      }, 0)
    },
    [fadeAndDisplayMenu, isMenuActive, menuEl, vw]
  )

  const handleClickAway = () => {
    onClickAway?.()
    fadeAndDisplayMenu(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box display="inline-block" position="relative" data-testid={dataTestId}>
        <MuiButton
          disabled={buttonProps?.disabled}
          data-testid="ButtonMenuContainer-button"
          {...buttonProps}
          ref={anchorEl}
          aria-describedby="button-menu"
          onClick={toggleMenu}
          color={isMenuActive || variant === 'dark' ? 'black' : 'neutral'}
          variant={variant === 'dark' ? 'contained-round' : 'outlined-round'}
          sx={{ px: 'var(--s24)', py: 'var(--s8)', ...buttonProps?.sx }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {label}
        </MuiButton>
        {isMenuActive ? (
          <Popper
            placeholder={false}
            placement={menuPlacement}
            open={isMenuActive}
            id="button-menu-container"
            anchorEl={anchorEl.current}
            style={
              isNotMobile
                ? { zIndex: 'var(--z10)' }
                : {
                    width: '100vw',
                    position: 'absolute',
                    left: 0,
                  }
            }
          >
            <MuiCard
              sx={{
                position: 'absolute',
                top: 'var(--s8)',
                ...(menuPlacement.includes('start')
                  ? { left: 0 }
                  : { right: 0 }),
                zIndex: 1000,
                width: isNotMobile ? undefined : '100vw',
                minWidth: isNotMobile ? 'var(--s376)' : 'auto',
                opacity: menuOpacity,
                background: 'rgb(var(--colors-neutral-0))',
                boxShadow: muiTheme.shadows[5],
                transition: `opacity ${FADE_DURATION_MS}ms linear`,
              }}
              data-testid="ButtonMenuContainer-menu"
              ref={menuEl}
            >
              <CardContent>
                {children({
                  isMenuActive,
                  setIsMenuActive,
                })}
              </CardContent>
            </MuiCard>
          </Popper>
        ) : null}
      </Box>
    </ClickAwayListener>
  )
}

export const __TEST__ = {
  FADE_DURATION_MS,
}
