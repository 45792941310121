export function makeFundIdFilter(fundProductIds?: string[]) {
  return {
    OR: [
      {
        fundProductIds: { fundProductIds_contains_some: fundProductIds },
      },
      { fundProductIds_exists: false },
    ],
  }
}
