import cx from 'classnames'

import { StatusTag } from '@cais-group/equity/atoms/status-tag'
import type { ContentDataType } from '@cais-group/homepage/util/types'
import { getContentOwnershipTag } from '@cais-group/shared/ui/contentful/card'
import { PageResource } from '@cais-group/shared/util/graphql/mfe-contentful'
import { publishedDateFormat } from '@cais-group/shared/util/time/date-time-contentful'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

type FrontMatterProps = {
  date: string
  eyebrow: string
  title: string
  author?: string
  duration?: number
  withLine?: boolean
  contentOwnershipTag?: {
    name?: string | null
  } | null
}

export function prepareFrontMatterProps<
  T extends TypeArticleData | WhitepaperData | PageResource
>(data: T) {
  const contentOwnershipTag = getContentOwnershipTag(data as ContentDataType)
  return {
    author: data?.author?.name || '',
    date: data.displayDate || data.sys?.publishedAt || '',
    title: data.title || '',
    contentOwnershipTag,
  }
}
export function FrontMatter(props: FrontMatterProps) {
  const {
    author,
    date,
    duration,
    eyebrow,
    title,
    withLine,
    contentOwnershipTag,
  } = props

  return (
    <header className="flex max-w-prose flex-col gap-y-8 py-24 text-neutral-800">
      <h1 className="headline-l-strong">{title}</h1>
      <p
        className={cx('overtext order-first pb-16', {
          [lineCss]: withLine,
        })}
      >
        {eyebrow}
      </p>
      <div
        className={cx({
          'flex flex-wrap items-center gap-8 md:flex-nowrap md:gap-y-0':
            contentOwnershipTag?.name,
        })}
      >
        {contentOwnershipTag?.name ? (
          <StatusTag variant="ghost" color="primary">
            {`By ${contentOwnershipTag?.name}`}
          </StatusTag>
        ) : null}
        <p className="max-w-fit first-letter:uppercase [&>span:not(:first-child)]:before:content-['_•_']">
          {date && <time dateTime={date}>{publishedDateFormat(date)}</time>}
          {author ? <span>by {author}</span> : null}
          {duration ? <span>{duration} min</span> : null}
        </p>
      </div>
    </header>
  )
}

const lineCss =
  'before:mb-24 before:pb-16 before:block before:w-56 before:border-solid before:border-yellow-600 before:border-t-2'
