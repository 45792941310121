import styled from '@emotion/styled'
import { Stack } from '@mui/material'
import { isInteger, range } from 'lodash-es'

import { Body } from '@cais-group/approved/ui/typography'
import { StatusTag } from '@cais-group/equity/atoms/status-tag'
import {
  SPACING,
  DATA_VISUALIZATION_COLORS,
} from '@cais-group/shared/ui/design-tokens'

import { CeCreditsListItem } from './ce-credits-list'

const CIRCLE_DIAMETER = SPACING.s16

const CertificationColors = {
  cima: DATA_VISUALIZATION_COLORS.Pistachio,
  cfp: DATA_VISUALIZATION_COLORS.Ube,
} as const

const Circle = styled('div')<Pick<CeCreditsListItem, 'type'>>`
  width: ${CIRCLE_DIAMETER};
  height: ${CIRCLE_DIAMETER};
  background-color: ${(props) => CertificationColors[props.type]};
  border-radius: 100%;
  margin-left: var(--s4);
`

const HalfCircle = styled('div')<Pick<CeCreditsListItem, 'type'>>`
  width: calc(${CIRCLE_DIAMETER} / 2);
  height: ${CIRCLE_DIAMETER};
  background-color: ${(props) => CertificationColors[props.type]};
  border-bottom-left-radius: ${CIRCLE_DIAMETER};
  border-top-left-radius: ${CIRCLE_DIAMETER};
  border-right: 0;
  margin-left: var(--s4);
`

const LicenseNumber = styled(Body)`
  color: rgb(var(--colors-neutral-600));
`

export function renderLicenseNumberAndStatus(row: CeCreditsListItem) {
  return (
    <>
      <Stack display="flex" flexDirection="row" justifyContent="space-between">
        <LicenseNumber data-testid={`ce-license-number-${row.type}`}>
          {row.licenseNumber}
        </LicenseNumber>
      </Stack>
      <Stack>
        <StatusTag
          data-testid="ce-completed-tag"
          color="success"
          variant="light"
        >
          Completed
        </StatusTag>
      </Stack>
    </>
  )
}

export function renderCeCreditCircles(row: CeCreditsListItem) {
  return (
    <div style={{ display: 'flex' }}>
      {range(Math.floor(row.credits)).map((idx) => (
        <Circle
          key={idx}
          data-testid={`ce-circle-${row.type}`}
          type={row.type}
        />
      ))}
      {!isInteger(row.credits) && (
        <HalfCircle
          data-testid={`ce-half-circle-${row.type}`}
          type={row.type}
        />
      )}
    </div>
  )
}
