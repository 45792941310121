import styled from '@emotion/styled'
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { BodySmallCss, LabelCss } from '@cais-group/approved/ui/typography'
import { Icon } from '@cais-group/equity/atoms/icon'

import { BreadcrumbsProps } from './approved-ui-breadcrumbs'

export const StyledBreadcrumbLink = styled.a`
  ${LabelCss};
  cursor: pointer;
`

export const CurrentBreadcrumbLink = styled.div`
  ${BodySmallCss};
  color: rgb(var(--colors-neutral-900));
`

export const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)`
  .MuiBreadcrumbs-ol {
    .MuiBreadcrumbs-separator {
      margin: 0;
    }
  }
`

interface BreadcrumbsFullProps {
  items: BreadcrumbsProps['items']
}

export const BreadcrumbsFull = (props: BreadcrumbsFullProps) => {
  const { items } = props
  const navigate = useNavigate()
  const rest = items.slice(0, items.length - 1)
  const last = items[items.length - 1]
  return (
    <StyledMuiBreadcrumbs
      aria-label="breadcrumb"
      separator={
        <Icon
          size="small"
          color="eq-color-neutral-300"
          type="KeyboardArrowRight"
        />
      }
      data-testid="breadCrumbsFull"
    >
      {rest.map((it, idx) => {
        return (
          <StyledBreadcrumbLink
            href=""
            onClick={(event) => {
              if (it.onClick) {
                it.onClick(event)
              } else if (it.path?.length) {
                event.preventDefault()
                navigate(it.path)
              }
            }}
            key={it.path ?? idx}
            data-testid={`breadcrumb-item-${idx}`}
          >
            {it.label}
          </StyledBreadcrumbLink>
        )
      })}
      <CurrentBreadcrumbLink data-testid="breadcrumb-item-current">
        {last.label}
      </CurrentBreadcrumbLink>
    </StyledMuiBreadcrumbs>
  )
}
