import { Theme } from './types'

export const colors: Theme = {
  brand: {
    '100': '#f9f5f6',
    '100-c': '#211c21',
    '200': '#e5d2d7',
    '200-c': '#211c21',
    '300': '#cba4af',
    '300-c': '#211c21',
    '400': '#b27787',
    '400-c': '#ffffff',
    '500': '#98495f',
    '500-c': '#ffffff',
    '600': '#7e1c37',
    '600-c': '#ffffff',
    '700': '#65162c',
    '700-c': '#ffffff',
    '800': '#4c1121',
    '800-c': '#ffffff',
    '900': '#320b16',
    '900-c': '#ffffff',
  },
  primary: {
    '100': '#f6f6f6',
    '100-c': '#211c21',
    '200': '#e3e3e3',
    '200-c': '#211c21',
    '300': '#c7c6c7',
    '300-c': '#211c21',
    '400': '#b5b3b5',
    '400-c': '#211c21',
    '500': '#8f8c8f',
    '500-c': '#211c21',
    '600': '#4c1121',
    '600-c': '#ffffff',
    '700': '#320b16',
    '700-c': '#ffffff',
    '800': '#2e2a2e',
    '800-c': '#ffffff',
    '900': '#211c21',
    '900-c': '#ffffff',
  },
  datavis: {
    '1': '#65162c',
    '1-c': '#ffffff',
    '2': '#c0bbf7',
    '2-c': '#211c21',
    '3': '#595c5c',
    '3-c': '#ffffff',
    '4': '#2a92ba',
    '4-c': '#ffffff',
    '5': '#86d3f2',
    '5-c': '#211c21',
    '6': '#6c8f70',
    '6-c': '#211c21',
    '7': '#d1e0a5',
    '7-c': '#211c21',
    '8': '#f3d7bc',
    '8-c': '#211c21',
    '9': '#a8864d',
    '9-c': '#211c21',
    '10': '#925364',
    '10-c': '#ffffff',
    '11': '#917da3',
    '11-c': '#211c21',
    '12': '#cdd1d8',
    '12-c': '#211c21',
    '13': '#ffffff',
    '13-c': '#ffffff',
    '14': '#c0e0db',
    '14-c': '#211c21',
    '15': '#f9d974',
    '15-c': '#211c21',
    '16': '#c47774',
    '16-c': '#211c21',
    '17': '#00407b',
    '17-c': '#ffffff',
    '18': '#8cade9',
    '18-c': '#211c21',
    '19': '#327174',
    '19-c': '#211c21',
    '20': '#96dbc8',
    '20-c': '#211c21',
    '21': '#ffc170',
    '21-c': '#211c21',
    '22': '#bb530a',
    '22-c': '#ffffff',
  },
  gradient: {
    '1-1': '#cba4af',
    '1-1-c': '#211c21',
    '1-2': '#f9f5f6',
    '1-2-c': '#211c21',
    '2-1': '#7e1c37',
    '2-1-c': '#ffffff',
    '2-2': '#cba4af',
    '2-2-c': '#ffffff',
    '3-1': '#320b16',
    '3-1-c': '#ffffff',
    '3-2': '#7e1c37',
    '3-2-c': '#ffffff',
    '4-1': '#7e1c37',
    '4-1-c': '#ffffff',
    '4-2': '#cba4af',
    '4-2-c': '#ffffff',
  },
} as const
