import {
  SelectableList,
  SelectableListItem,
  SelectableListProps,
} from './shared-ui-selectable-list'

export type TwoColumnSelectableListProps<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
> = SelectableListProps<TItem, TValue>
export const TwoColumnSelectableList = <
  TItem extends SelectableListItem<TValue>,
  TValue extends string
>({
  items,
  onChange,
  renderItem,
  selectedItems,
}: TwoColumnSelectableListProps<TItem, TValue>) => {
  const splitItemsAt = Math.ceil(items.length / 2)
  const colOneItems = items.slice(0, splitItemsAt)

  return (
    <div className="flex flex-row">
      <SelectableList<TItem, TValue>
        items={colOneItems}
        onChange={onChange}
        renderItem={renderItem}
        selectedItems={selectedItems}
      />
      <SelectableList<TItem, TValue>
        items={items.slice(splitItemsAt)}
        onChange={onChange}
        renderItem={renderItem}
        selectedItems={selectedItems}
      />
    </div>
  )
}
