// Store for global consumption

import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { getAppEnvConfigPath } from '@cais-group/shared/domain/apps'

export type Feature = string // No CURRENT FEATURES

export interface MfeEnv {
  CONTENTFUL_PREVIEW_ACCESS_TOKEN: string
  CONTENTFUL_ACCESS_TOKEN: string
  CONTENTFUL_URL: string
  ENVIRONMENT: string
  SEGMENT_API_KEY: string
  AUTH0_DOMAIN: string
  AUTH0_CLIENT_ID: string
  AUTH0_AUDIENCE: string
  REST_API: string
  GRAPHQL_API: string
  DATADOG_APPLICATION_ID: string
  DATADOG_CLIENT_TOKEN: string
  INSIGHTS_API_URL: string
  PORTAL_URL: string
  SESSION_DURATION_TIME: number
  ACP_REDIRECT_URL: string
  FEATURE_FLAGS: Record<Feature, boolean>
  CAISIQ_API_HOST: string
  TURNSTILE_SITE_KEY: string
}

export type EnvConfigContainerProviderProps<TConfig> = {
  children: ReactNode
  initializers?: (config: TConfig) => void
  configPath?: string
  value?: TConfig
}

export type EnvConfigSubAppProviderProps<TConfig> = {
  children: ReactNode
  envConfig: TConfig
}

export async function initEnvConfig<T>(path: string): Promise<T> {
  return window?.fetch(path).then((response) => response.json())
}

export function createEnvConfigContext<TConfig = MfeEnv>(configPath: string) {
  let ENV_CONFIG: TConfig

  const EnvContext = createContext<TConfig | undefined>(undefined)

  const EnvConfigContainerProvider = ({
    children,
    initializers,
    value,
  }: EnvConfigContainerProviderProps<TConfig>) => {
    const [env, setEnv] = useState<TConfig | undefined>(undefined)
    const [error, setError] = useState<ReactElement | undefined>(undefined)

    useEffect(() => {
      const initializeConfig = async () => {
        if (value) {
          initializers && initializers(value)
          setEnvConfig(value)
          setEnv(value)
        } else {
          try {
            const config = await initEnvConfig<TConfig>(configPath)
            initializers && initializers(config)
            setEnvConfig(config)
            setEnv(config)
          } catch (e) {
            console.error(`Failed to load env.json because of ${e}`)
            setError(
              <div>
                <h1>Application misconfigured</h1>

                <p>Missing environment configuration, cannot continue</p>
              </div>
            )
          }
        }
      }
      initializeConfig()
    }, [initializers, value])

    if (error) {
      return error
    }

    return env ? (
      <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
    ) : (
      <LoadingContainer
        state={LoadingState.LOADING}
        coverPage="FULL_SCREEN"
        type="large"
      ></LoadingContainer>
    )
  }

  const EnvConfigSubAppProvider = ({
    children,
    envConfig,
  }: EnvConfigSubAppProviderProps<TConfig>) => (
    <EnvContext.Provider value={envConfig}>{children}</EnvContext.Provider>
  )

  const setEnvConfig = (config: TConfig) => {
    ENV_CONFIG = config
  }

  const getEnvConfig = () => ENV_CONFIG

  const EnvContextRenderProp = ({
    children,
  }: {
    children: (envConfig: TConfig) => ReactElement
  }) => {
    const envConfig = useContext(EnvContext)
    return envConfig ? children(envConfig) : null
  }

  return {
    EnvContext,
    EnvConfigContainerProvider,
    EnvConfigSubAppProvider,
    EnvContextRenderProp,
    getEnvConfig,
    setEnvConfig,
  }
}

// Export the previously available components / functions to maintain backward compatibility in other libraries
export const {
  EnvContext,
  EnvConfigContainerProvider,
  EnvConfigSubAppProvider,
  EnvContextRenderProp,
  getEnvConfig,
  setEnvConfig,
} = createEnvConfigContext<MfeEnv>(getAppEnvConfigPath())
