import { uiTypeSize } from '@cais-group/shared/ui/type/size'
import styled from '@emotion/styled'
import { forwardRef, ReactNode } from 'react'
import { omit } from 'remeda'

export type GridProps = {
  min?: uiTypeSize // A CSS length value representing x in `minmax(min(x, 100%), 1fr)`
  space?: uiTypeSize // Space between grid cells
  children: ReactNode
  className?: string
}

export const LayoutGrid = styled(
  forwardRef<HTMLDivElement, GridProps>((props, ref) => {
    const passedProps = omit(props, ['min', 'space', 'className', 'children'])
    return (
      <div
        ref={ref}
        className={props.className}
        data-layout="LayoutGrid"
        {...passedProps}
      >
        {props.children}
      </div>
    )
  })
)(({ min = 'var(--s376)', space = 'var(--s24)' }) => {
  return `
    display: grid;
    grid-gap: ${space};
    align-content: start;
    grid-template-columns: 100%;
    @supports (width: min(${min}, 100%)) {
      grid-template-columns: repeat(auto-fill, minmax(min(${min}, 100%), 1fr));
    }
  `
})

LayoutGrid.displayName = 'LayoutGrid'
