import {
  useFilterFundsBySearch,
  useGetFunds,
} from '@cais-group/caisiq/domain/fund'

export const FundCount = () => {
  const funds = useGetFunds()
  const filteredFunds = useFilterFundsBySearch(funds.data)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{filteredFunds?.length}</>
}
