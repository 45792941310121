import { Button } from '@cais-group/equity/atoms/button'
import { Icon } from '@cais-group/equity/atoms/icon'
import { StatusTag } from '@cais-group/equity/atoms/status-tag'

import { useUniformContext } from '../context'
import { useModal } from '../modals/context'
import type { UniformServices } from '../services'
import type {
  ComponentDict,
  UniformAny,
  UniformFieldProps,
  UniformSchema,
} from '../types'
import type { UniformValues } from '../uniform'
import { matchUniformService } from '../utils'
import { formatDate } from '../utils/format-date'
import { pathOr } from '../utils/path-or'

import { UniformAdapter } from './adapter'

function FileEditCard<
  TUniformServices extends UniformServices = UniformServices
>({
  fileId,
  title,
  expiryDate,
  issueDate,
  onClickEdit,
  downloadService,
}: {
  fileId?: string
  title?: string
  expiryDate?: string
  issueDate?: string
  onClickEdit?: () => void
  downloadService?: keyof TUniformServices
}) {
  const { services } = useUniformContext<TUniformServices>()

  return (
    <div className="border-gray-14 flex min-h-[80px] w-full items-center border border-solid">
      <div className="flex-0 flex items-center px-12">
        <div className="flex h-32 w-32 items-center justify-center rounded-full bg-green-700">
          <Icon type="Check" color="eq-color-neutral-0" />
        </div>
      </div>
      <div className="flex flex-1 flex-col overflow-hidden py-12">
        <p className="body-strong truncate" title={title}>
          {title}
        </p>
        {expiryDate && (
          <p className="body">Expiration Date: {formatDate(expiryDate)}</p>
        )}
        {issueDate && (
          <p className="body">Date Issued: {formatDate(issueDate)}</p>
        )}
      </div>
      <div className="flex-0 flex items-center gap-12 px-12">
        <button
          type="button"
          onClick={async () => {
            const fn = matchUniformService({
              services,
              service: downloadService,
            })
            const fileHref = await fn({ fileId })
            if (fileHref) window.open(fileHref, '_blank')
          }}
          className="flex"
        >
          <span className="sr-only">Download</span>
          <Icon type="FileDownload" color="eq-color-primary-600" />
        </button>
        <button type="button" onClick={onClickEdit} className="flex">
          <span className="sr-only">Edit</span>
          <Icon type="Edit" color="eq-color-primary-600" />
        </button>
      </div>
    </div>
  )
}

function FileUploadCard({
  onClick,
  disabled,
}: {
  onClick: () => void
  disabled?: boolean
}) {
  return (
    <div className="border-gray-14 flex min-h-[80px] items-center justify-between border border-solid p-16">
      <p className="body-strong">Upload a document</p>
      <Button size="small" onClick={onClick} disabled={disabled}>
        Add Document
      </Button>
    </div>
  )
}

function getDisplayValues(
  defaultValues?: UniformValues,
  paths: {
    fileIdPath: string
    documentFilePath: string
    expiryDatePath: string
    issueDatePath: string
  } = {
    fileIdPath: 'fileId',
    documentFilePath: 'documentFile',
    expiryDatePath: 'expiryDate',
    issueDatePath: 'issueDate',
  }
) {
  const fileId = pathOr<string>('', paths.fileIdPath.split('.'), defaultValues)
  const documentFile = pathOr<string>(
    '',
    paths.documentFilePath.split('.'),
    defaultValues
  )
  const expiryDate = pathOr<string>(
    '',
    paths.expiryDatePath.split('.'),
    defaultValues
  )
  const issueDate = pathOr<string>(
    '',
    paths.issueDatePath.split('.'),
    defaultValues
  )

  return { fileId, documentFile, expiryDate, issueDate }
}

export type FileCardProps<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict
> = UniformFieldProps<'FileCard'> & {
  id: TFormFieldIds
  subtitleValid?: string
  subtitleInvalid?: string
  downloadService?: keyof TUniformServices
  formService?: keyof TUniformServices
  schema: UniformSchema<TUniformServices, TComponentDict>
  defaultValues?: UniformValues
  onSuccess?: () => void
  testId?: string
  displayValuesFromDefaultValues?: {
    fileIdPath: string
    documentFilePath: string
    expiryDatePath: string
    issueDatePath: string
  }
}

export const FileCard = <
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = UniformAny
>(
  field: FileCardProps<TFormFieldIds, TUniformServices, TComponentDict>
) => {
  const { openModal } = useModal()
  const { services } = useUniformContext()
  const { fileId, documentFile, expiryDate, issueDate } = getDisplayValues(
    field.defaultValues,
    field.displayValuesFromDefaultValues
  )

  return (
    <UniformAdapter
      field={field}
      render={({
        uniform: { id, isDisabled, required, tag, settings },
        field: { ref, name },
      }) => {
        return (
          <div
            ref={ref}
            tabIndex={0}
            className="relative flex w-full flex-col gap-16"
            data-testid={field.testId}
          >
            {field.label ||
            tag?.children ||
            field.subtitleValid ||
            field.subtitleInvalid ? (
              <label htmlFor={id} className="flex w-fit flex-col">
                <span className="flex flex-row items-center">
                  <p className="body-strong">
                    {field.label}{' '}
                    {required ? (
                      <span className="text-error-500">*</span>
                    ) : null}
                  </p>
                  {tag?.children && tag?.children !== '' && (
                    <span className="ml-8">
                      <StatusTag {...tag} />
                    </span>
                  )}
                </span>
                <span className="eq-small text-neutral-600">
                  {documentFile ? field.subtitleValid : field.subtitleInvalid}
                </span>
              </label>
            ) : null}
            <input type="text" name={name} hidden disabled />
            {documentFile ? (
              <FileEditCard
                fileId={fileId}
                title={documentFile}
                expiryDate={expiryDate}
                issueDate={issueDate}
                downloadService={field.downloadService}
                onClickEdit={openModal('CREATE_UNIFORM_MODAL', {
                  title: 'Edit Document',
                  schema: field.schema,
                  defaultValues: field.defaultValues,
                  service: String(field.formService),
                  services,
                  settings,
                  onSuccess: field.onSuccess,
                })}
              />
            ) : (
              <FileUploadCard
                onClick={openModal('CREATE_UNIFORM_MODAL', {
                  title: 'Add Document',
                  schema: field.schema,
                  defaultValues: field.defaultValues,
                  service: String(field.formService),
                  services,
                  settings,
                  onSuccess: field.onSuccess,
                })}
                disabled={isDisabled}
              />
            )}
          </div>
        )
      }}
    />
  )
}

export default FileCard
