import cx from 'classnames'

import { Icon } from '@cais-group/equity/atoms/icon'
import { TailwindEquityColor } from '@cais-group/equity/particles/colors'
import { IconType } from '@cais-group/equity/particles/icons'

type InfoTagState = 'default' | 'selected' | 'error'

const stateBackgroundColorMap: Record<InfoTagState, TailwindEquityColor> = {
  default: 'bg-neutral-100 group-hover/infotag:bg-neutral-200',
  selected: 'bg-primary-200',
  error: 'bg-error-200',
}

const getBackgroundColor = (state: InfoTagState): string => {
  return stateBackgroundColorMap[state]
}

export type InfoTagProps = {
  /** The text to display in the info tag */
  children: string
  /** Optionally show an icon after the text */
  endAdornment?: IconType
  /** Optionally show an icon before the text */
  startAdornment?: IconType
  /** The state of the info tag */
  state?: InfoTagState
  /** The size to apply */
  size?: 'small' | 'regular'
}

/**
 *
 * An info tag can be used to:
 *
 * - uniquely identify publicly traded shares of a particular stock on a particular stock market.
 * - indicate a content-type on a card.
 *
 * This component displays an info tag in either an active or inactive state and can be used with icons.
 */
export const InfoTag = ({
  startAdornment,
  endAdornment,
  children,
  state = 'default',
  size = 'regular',
}: InfoTagProps) => {
  return (
    <span
      className={cx(
        'pointer-events-none flex w-fit items-center justify-center rounded-full px-4 py-4 text-neutral-900 transition-colors',
        size === 'small' ? 'px-4' : 'px-8',
        getBackgroundColor(state)
      )}
    >
      {startAdornment ? <Icon type={startAdornment} size="small" /> : null}
      <span
        className={cx(
          size === 'small' ? 'caption-mono px-4' : 'small-mono px-8'
        )}
      >
        {children}
      </span>
      {endAdornment ? <Icon type={endAdornment} size="small" /> : null}
    </span>
  )
}
