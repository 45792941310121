import styled from '@emotion/styled'
import { useMediaQuery } from '@mui/material'
import cx from 'classnames'
import {
  useLayoutEffect,
  useCallback,
  useState,
  MutableRefObject,
  useRef,
} from 'react'

import { TypeVideoDataWithParentPlaylist } from '@cais-group/caisiq/domain/video'
import { breakpoints } from '@cais-group/equity/particles/breakpoints'
import { LayoutStack } from '@cais-group/shared/ui/layout'
import {
  FadeScroll,
  Playlist,
  ScrollbarWrapper,
  usePlaylist,
} from '@cais-group/shared/ui/video/playlist'
import { TypeVideoData } from '@cais-group/shared/util/type/video-data'
import { TypeVideoPlaylistData } from '@cais-group/shared/util/type/video-playlist-data'

import { Player } from './components/player'
import { PlaylistContainer } from './components/playlist-container'
import { VideoHeader } from './components/video-header'
import { VideoPageFrontmatter } from './components/video-page-front-matter'

const Video = styled(Player)<{
  targetHeight?: number
  isFullScreen?: boolean
}>`
  width: 100%;
  ${({ targetHeight, isFullScreen }) =>
    targetHeight === undefined || isFullScreen
      ? ''
      : `height: ${targetHeight}px;`}
  outline: none;
`

const SingleVideo = styled(Player)<{
  maxHeight: number
  isFullScreen?: boolean
}>`
  ${({ maxHeight, isFullScreen }) =>
    isFullScreen ? '' : `max-height: ${maxHeight}px;`}
  margin: 0;
`

const VideoResizeWrapper = styled.div`
  width: 100%;
  line-height: 0; // Otherwise there is a gap below the video player and the height calculation is too large
  background: #000000; // Form black bars for wider videos
`

export type VideoPageProps = {
  cachedVideoData?: TypeVideoDataWithParentPlaylist
  isSingleVideo: boolean
  playlist?: TypeVideoPlaylistData
  episodeNumber?: number
  videoId?: string
  setVideoId: React.Dispatch<React.SetStateAction<string | undefined>>
  nav: React.ReactNode
  userId: string
}
export const VideoPage = (props: VideoPageProps) => {
  const {
    cachedVideoData,
    isSingleVideo,
    playlist,
    episodeNumber,
    videoId,
    setVideoId,
    nav,
    userId,
  } = props
  const service = usePlaylist()
  const isFullScreen = useMediaQuery(`(min-width:${breakpoints.lg}px)`)
  const [targetListHeight, setTargetListHeight] = useState<number>(500)
  const [videoWidth, setVideoWidth] = useState<number>(0)
  const [targetVideoHeight, setTargetVideoHeight] = useState<number>(0)
  const [maxVideoHeight, setMaxVideoHeight] = useState<number>(0)
  const [playlistIndex, setPlaylistIndex] = useState(0)
  const videosCollection = playlist?.videosCollection?.items
  const listRef = useRef<HTMLDivElement>() as
    | MutableRefObject<HTMLDivElement>
    | undefined
  const [selectedEpisode, setSelectedEpisode] = useState<
    TypeVideoData | undefined
  >()
  const currentVideo = selectedEpisode || cachedVideoData

  // TODO: Rethink this temp fix for truncating the series title, probably using text-overflow: ellipsis
  const seriesTitleMaxChars =
    window.innerWidth < 481
      ? 45
      : window.innerWidth < 1000
      ? 70
      : videoWidth < 594
      ? 45
      : Math.max(50, (videoWidth - 400) / 9)

  const handleVideoSelected = (video: TypeVideoData) => {
    if (videosCollection && video.sys.id !== videosCollection[0].sys.id) {
      setPlaylistIndex(videosCollection.findIndex((video) => video?.sys.id) + 1)
    }
    setVideoId(video.sys.id)

    setSelectedEpisode(video)
    service.startAutoplay()
  }

  const handlePlaybackEnded = () => {
    if (!videosCollection) {
      return
    }
    const nextIndex =
      videosCollection.findIndex((video) => video?.sys.id === videoId) + 1
    const video = videosCollection[nextIndex]

    if (video) {
      handleVideoSelected(video)
    } else {
      service.stopAutoplay()
    }
  }

  const videoRef = useCallback((videoResizeWrapper: HTMLDivElement) => {
    const updateMeasurements = () => {
      if (videoResizeWrapper?.clientHeight !== undefined) {
        const measuredVideoHeight = videoResizeWrapper.clientHeight
        const measuredVideoWidth = videoResizeWrapper.clientWidth
        const ratioBasedHeight = Math.round((measuredVideoWidth / 16) * 9)
        setVideoWidth(measuredVideoWidth)
        const maxHeight = window.innerHeight - videoResizeWrapper.offsetTop
        setMaxVideoHeight(maxHeight)
        const constrainedHeight = Math.min(ratioBasedHeight, maxHeight)
        setTargetVideoHeight(constrainedHeight)
        setTargetListHeight(Math.max(constrainedHeight, measuredVideoHeight))
      }
    }

    if (videoResizeWrapper) {
      const resizeObserver = new ResizeObserver(updateMeasurements)
      resizeObserver.observe(videoResizeWrapper)
      window.addEventListener('resize', updateMeasurements)
    }
  }, [])

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
    })
  }, [])

  return (
    <>
      <div
        data-testid="video-page"
        className="isolate mx-auto w-full flex-auto scroll-mt-32 bg-neutral-900 px-16 lg:flex-col lg:px-32 lg:pt-8"
      >
        <PlaylistContainer
          header={
            <VideoHeader
              playlistTitle={playlist?.title}
              videoTitle={cachedVideoData?.title}
              seriesTitleMaxChars={seriesTitleMaxChars}
              nav={nav}
            />
          }
        >
          {isSingleVideo ? (
            <VideoResizeWrapper
              ref={videoRef}
              className={cx('me-auto ms-auto aspect-video', {
                'basis-3/4': isFullScreen,
                'w-full': !isFullScreen,
              })}
            >
              <SingleVideo
                isFullScreen={isFullScreen}
                maxHeight={maxVideoHeight}
                handlePlaybackEnded={handlePlaybackEnded}
                video={currentVideo}
                userId={userId}
              />
            </VideoResizeWrapper>
          ) : (
            <>
              <VideoResizeWrapper ref={videoRef} className="aspect-video">
                <Video
                  isFullScreen={isFullScreen}
                  targetHeight={targetVideoHeight}
                  handlePlaybackEnded={handlePlaybackEnded}
                  autoPlay={service.autoplay}
                  onPlay={service.startAutoplay}
                  video={currentVideo}
                  userId={userId}
                />
              </VideoResizeWrapper>
              <LayoutStack
                className={cx(
                  'lg:basis-1/3',
                  isFullScreen && `h-[${targetListHeight}px}]`
                )}
              >
                {isFullScreen ? (
                  <FadeScroll
                    className="bg-neutral-900"
                    length="var(--s24)"
                    fadeColor="eq-color-neutral-900"
                    ref={listRef}
                  >
                    <ScrollbarWrapper>
                      <Playlist
                        currentVideoId={videoId}
                        playlist={playlist}
                        onVideoSelected={handleVideoSelected}
                        videos={playlist?.videosCollection.items}
                        episodic={playlist?.episodic}
                        currentIndex={playlistIndex}
                      />
                    </ScrollbarWrapper>
                  </FadeScroll>
                ) : (
                  <ScrollbarWrapper>
                    <Playlist
                      currentVideoId={videoId}
                      playlist={playlist}
                      onVideoSelected={handleVideoSelected}
                      videos={playlist?.videosCollection.items}
                      episodic={playlist?.episodic}
                      currentIndex={playlistIndex}
                    />
                  </ScrollbarWrapper>
                )}
              </LayoutStack>
            </>
          )}
        </PlaylistContainer>
      </div>
      <div className="bg-neutral-0 me-auto ms-auto flex w-full flex-col items-center px-16">
        <VideoPageFrontmatter
          video={currentVideo}
          episodic={playlist?.episodic}
          episodeNumber={episodeNumber}
        />
      </div>
    </>
  )
}

VideoPage.displayName = 'VideoPage'
