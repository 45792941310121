import styled from '@emotion/styled'
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { equals, uniqWith } from 'remeda'

import { FieldsErrorPanel } from '@cais-group/approved/ui/error-panel'
import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import {
  formLabels,
  useGetManageCaisiqCoursesByCatalogId,
} from '@cais-group/caisiq/domain/manage-caisiq'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { LayoutSettingsPage } from '@cais-group/caisiq/ui/layout/settings-page'
import { CapabilitiesStep } from '@cais-group/caisiq/ui/manage/capabilities-step'
import { CourseConfigurationStep } from '@cais-group/caisiq/ui/manage/course-configuration-step'
import { filterExperienceCoursesBySearch } from '@cais-group/caisiq/ui/manage/course-configuration-table'
import { EnableDisableBox } from '@cais-group/caisiq/ui/manage/enable-disable-box'
import { ExperienceNameStep } from '@cais-group/caisiq/ui/manage/experience-name-step-textfield'
import { ModalCancel } from '@cais-group/caisiq/ui/modal/cancel'
import {
  ConfirmModalSummaryType,
  EnableDisableExperienceModal,
  buildConfirmFirmModalDetails,
  buildEnableDisableModalDetails,
} from '@cais-group/caisiq/ui/modal/enable-disable-experience'
import { ExperiencePageTable } from '@cais-group/caisiq/ui/table/manage'
import { useContentfulNameFromIdMapper } from '@cais-group/caisiq/util/contentful/use-contentful-name-from-id-mapper'
import {
  ExperienceFields,
  ExperienceListBox,
  ModalType,
} from '@cais-group/caisiq/util/type/manage-caisiq'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Button } from '@cais-group/equity/atoms/button'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { LastUpdateDetails } from '@cais-group/shared/ui/last-update-details'
import { useStepState } from '@cais-group/shared/ui/ordered-fieldset'
import {
  CaisIqFirm,
  Catalog,
  CourseMetadata,
  ExperienceResponse,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'

import { ConfirmExperienceModal } from './components/ConfirmExperienceModal'
import { ExperienceTypeStep } from './components/ExperienceTypeStep'
import { FirmSelectionStep } from './components/FirmSelectionStep'
import { experienceResponseFormErrorHandler } from './hooks/experience-response-form-error-handler'

export interface ExperienceProps {
  experience?: ExperienceResponse
  firms?: CaisIqFirm[] | undefined
  saveLabel?: string
  contentfulExperiences?: ExperienceListBox
  catalogs: Catalog[]
  handleSubmit(request: ExperienceFields): void
  sideNavigation?: ReactElement
  headerNav: ReactElement
  onOpen: Dispatch<SetStateAction<ModalType | undefined>>
  isOpen?: ModalType
  courses?: CourseMetadata[]
  coursesFetched?: boolean
  mutateLoading: boolean
}

const FormLeftContainer = styled.section`
  max-width: 560px;
  grid-column: 1 / 1;
`

const FormAside = styled.aside`
  position: absolute;
  justify-self: right;
  margin-right: ${SPACING.s32};
  & > div:first-of-type {
    margin-bottom: ${SPACING.s16};
  }
`

const ButtonRow = styled.div`
  grid-column: 1 / 2;
`

const FormTableRow = styled.div`
  grid-column: 1 / 2;
`

const createExperienceDefaults: ExperienceFields = {
  name: '',
  label: '',
  contentfulId: undefined,
  coursesEnabled: false,
  ceCreditEnabled: false,
  catalogId: undefined,
  experienceType: 'FIRM',
  firms: [],
  active: false,
  courses: [],
}

const getEnableDisableOptions = (active?: boolean) => {
  if (active) {
    return {
      title: 'Disable Experience',
      body: 'All assigned firms will receive the default experience.',
      buttonText: 'Disable Experience',
    }
  } else {
    return {
      title: 'Enable Experience',
      body: 'All assigned firms will receive this experience.',
      buttonText: 'Enable Experience',
    }
  }
}

export function CreateUpdateExperience({
  experience,
  firms = [],
  courses = [],
  saveLabel = 'Save',
  contentfulExperiences,
  catalogs,
  handleSubmit,
  sideNavigation,
  headerNav,
  isOpen,
  onOpen,
  coursesFetched,
  mutateLoading,
}: ExperienceProps) {
  const location = useLocation()

  const isCreate = location.pathname.includes('create')

  const [updateRemoveFirmId, setUpdateRemoveFirmId] = useState<
    string | undefined
  >()

  const [modalSummary, setModalSummary] = useState<ConfirmModalSummaryType>()
  const [searchTerm] = useRecoilState(searchTermAtom)

  const methods = useForm<ExperienceFields>({
    mode: 'onSubmit',
    defaultValues: experience
      ? mapExperienceToFormValues(experience, firms, courses)
      : createExperienceDefaults,
  })

  const navigate = useNavigate()
  const { updateStepCount } = useStepState()

  const experienceFirms = methods.watch('firms')
  const experienceFirmIds = methods.watch('firmIds')
  const experienceCourses = methods.watch('courses')
  const coursesEnabled = methods.watch('coursesEnabled')
  const activeState = methods.watch('active')
  const catalogId = methods.watch('catalogId')

  const { isFetched: catalogIdCoursesFetched } =
    useGetManageCaisiqCoursesByCatalogId({
      catalogId,
      enabled: Boolean(catalogId),
    })
  const hasFullAccess = userSettingsService.hasRole(UserRole.CaisiqManageWrite)

  const originalFirmIds = firms.map((firm) => firm.id)

  const showFirms = methods.watch('experienceType') === 'FIRM'

  const getContentfulNameById = useContentfulNameFromIdMapper()

  useEffect(() => {
    updateStepCount(
      showFirms ? (coursesEnabled ? 5 : 4) : coursesEnabled ? 4 : 3
    )
  }, [coursesEnabled, showFirms, updateStepCount])

  const executeUpdateFirmExperience = () => {
    setUpdateRemoveFirmId(undefined)
    onOpen(false)
  }

  const attemptToUpdateFirmExperience = useCallback(
    (clickedFirmId: string) => {
      const firmNameToUpdate = methods
        .getValues('firms')
        .find((firm) => firm.id === clickedFirmId)?.name

      setModalSummary(buildConfirmFirmModalDetails(firmNameToUpdate, 'confirm'))
      onOpen('confirm')
    },
    [methods, onOpen]
  )

  const executeRemoveFirm = useCallback(
    (newRemovedFirmId: string) => {
      const firmToRemove = newRemovedFirmId || updateRemoveFirmId
      // below removes the removed firm from being checked

      methods.setValue(
        'firmIds',
        experienceFirmIds?.filter((firm) => firm !== firmToRemove),
        { shouldDirty: true }
      )
      // below removes removed firm from table
      methods.setValue(
        'firms',
        experienceFirms?.filter((firm) => firm.id !== firmToRemove)
      )

      onOpen(false)
      setUpdateRemoveFirmId(undefined)
    },
    [experienceFirmIds, experienceFirms, methods, onOpen, updateRemoveFirmId]
  )

  const attemptToRemoveFirm = useCallback(
    (clickedFirmId: string) => {
      // if user attempts to remove firm that's on the current experience, show modal
      if (firms.find((firm) => firm.id === clickedFirmId)) {
        setUpdateRemoveFirmId(clickedFirmId)
        setModalSummary(
          buildConfirmFirmModalDetails(
            experienceFirms?.find((firm) => firm.id === clickedFirmId)
              ?.name as string,
            'remove'
          )
        )
        onOpen('remove')
      } else {
        // else just remove the firm from the table
        executeRemoveFirm(clickedFirmId)
      }
    },
    [executeRemoveFirm, experienceFirms, firms, onOpen]
  )

  const updateOrRemoveExperienceFromList = useCallback(
    (firmId: string, checked: boolean) => {
      setUpdateRemoveFirmId(firmId)
      const firm = firms.find((f) => f.id === firmId)
      // if firm is on experience and user attemps to remove, show remove modal
      if (firm && checked) {
        attemptToRemoveFirm(firmId)

        // if user attempts to update a firm with an experience, show update modal
      } else if (!firm && !checked) {
        attemptToUpdateFirmExperience(firmId)
      }
    },
    [attemptToRemoveFirm, attemptToUpdateFirmExperience, firms]
  )

  const onClose = useCallback(() => {
    const firm = firms.find((f) => f.id === updateRemoveFirmId)

    if (!firm) {
      methods.setValue(
        'firms',
        experienceFirms.filter((firm) => firm.id !== updateRemoveFirmId)
      )

      methods.setValue(
        'firmIds',
        experienceFirmIds?.filter((id) => id !== updateRemoveFirmId)
      )
    } else {
      methods.setValue('firms', uniqWith([...firms, firm], equals))
      methods.setValue('firmIds', [
        ...originalFirmIds,
        updateRemoveFirmId as string,
      ])
    }

    onOpen(false)
    setUpdateRemoveFirmId(undefined)
  }, [
    experienceFirmIds,
    experienceFirms,
    firms,
    methods,
    onOpen,
    originalFirmIds,
    updateRemoveFirmId,
  ])

  const handleEnableDisable = (active: boolean) => {
    methods.setValue('active', active)
    onOpen(false)
  }

  const handleSubmitCreateModal = (active: boolean) => {
    methods.setValue('active', active)
    handleSubmit(methods.getValues())
    onOpen(false)
  }

  const maybeSearchFilteredExperienceCourses = filterExperienceCoursesBySearch(
    searchTerm,
    experienceCourses ?? []
  )

  return (
    <LayoutSettingsPage<ExperienceFields>
      methods={methods}
      onSubmit={handleSubmit}
      handleFormError={experienceResponseFormErrorHandler}
      sideNavigation={sideNavigation}
      headerNav={headerNav}
      isOpen={Boolean(isOpen)}
    >
      <FieldsErrorPanel
        className="mb-32 mr-32"
        fields={
          Object.entries(methods.formState.errors)
            .map(([key, value]) =>
              value ? formLabels[key as keyof ExperienceFields] : undefined
            )
            .filter((k) => k !== undefined) as string[]
        }
      />
      <FormLeftContainer>
        <ExperienceNameStep
          order={1}
          defaultLabel={experience?.label}
          experiences={contentfulExperiences}
          legend="Experience Name"
          formLabels={formLabels}
          contentfulId={getContentfulNameById(experience?.contentfulId)}
        />
        <CapabilitiesStep
          order={2}
          legend="Capabilities"
          // This currently only controls the formToggles
          disabled={!hasFullAccess}
          catalogs={catalogs}
          formLabels={formLabels}
        />
        <CourseConfigurationStep
          order={3}
          legend="Course Configuration"
          disabled={!hasFullAccess}
          courses={maybeSearchFilteredExperienceCourses}
          isFetched={coursesFetched || catalogIdCoursesFetched}
          className={!isCreate ? 'pt-32' : ''}
        />
        <ExperienceTypeStep
          order={coursesEnabled ? 4 : 3}
          formLabels={formLabels}
        />
        <FirmSelectionStep
          order={coursesEnabled ? 5 : 4}
          onRemoveUpdateFirm={updateOrRemoveExperienceFromList}
          formLabels={formLabels}
        />
      </FormLeftContainer>
      {experience !== undefined && (
        <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
          <FormAside>
            {experience?.updatedAt !== undefined && (
              <LastUpdateDetails
                updatedAt={experience.updatedAt}
                email={experience?.updatedBy || ''}
              />
            )}
            <EnableDisableBox
              onSubmit={
                activeState
                  ? () => {
                      onOpen('disable')
                    }
                  : () => handleEnableDisable(!activeState)
              }
              options={getEnableDisableOptions(activeState)}
              control={methods.control}
              testId="experience-enable-disable-button"
            />
          </FormAside>
        </HasPermissions>
      )}
      {showFirms && Boolean(experienceFirms.length) && (
        <FormTableRow>
          <ExperiencePageTable
            tableOptions={{
              data: experienceFirms,
            }}
            params={{}}
            actions={(firmId) => [
              {
                text: 'Remove firm',
                handler: () => attemptToRemoveFirm(firmId),
                testId: 'experience-firm-settings-table-actions-button-remove',
              },
              ...(experience
                ? [
                    {
                      text: 'Edit firm',
                      handler: () => navigate(ROUTES.manageEditFirm(firmId)),
                      testId:
                        'experience-firm-settings-table-actions-button-edit',
                    },
                  ]
                : []),
            ]}
          />
        </FormTableRow>
      )}
      <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
        <ButtonRow>
          <Button
            onClick={
              isCreate && methods.formState.isValid
                ? (ev) => {
                    onOpen('create')
                    ev.stopPropagation()
                  }
                : undefined
            }
            // if we are on the create page and the form is valid, this is a button that opens the modal
            type={isCreate && methods.formState.isValid ? 'button' : 'submit'}
            loading={mutateLoading}
          >
            {saveLabel}
          </Button>
        </ButtonRow>
      </HasPermissions>
      <ModalCancel />
      <ConfirmExperienceModal
        open={Boolean(
          (isOpen === 'confirm' || isOpen === 'remove') && modalSummary
        )}
        onConfirm={
          isOpen === 'confirm'
            ? executeUpdateFirmExperience
            : isOpen === 'remove'
            ? (executeRemoveFirm as () => void)
            : () => {}
        }
        onClose={onClose}
        summary={modalSummary}
      />
      <EnableDisableExperienceModal
        open={(isOpen === 'disable' || isOpen === 'create') && Boolean(isOpen)}
        onConfirm={() =>
          isCreate
            ? handleSubmitCreateModal(true)
            : handleEnableDisable(Boolean(!activeState))
        }
        onConfirmWithoutEnable={
          isCreate ? () => handleSubmitCreateModal(false) : undefined
        }
        onClose={() => onOpen(false)}
        options={buildEnableDisableModalDetails(
          // Not sure if we should show the default experience name or unSubmitted name
          // Either way for create page, we will show unsubmitted name
          experience?.name || methods.getValues('name'),
          isOpen
        )}
      />
    </LayoutSettingsPage>
  )
}

export const mapExperienceToFormValues = (
  e: ExperienceResponse,
  f: CaisIqFirm[] = [],
  c: CourseMetadata[] = []
): ExperienceFields => ({
  label: e.label,
  name: e.name,
  contentfulId: e.contentfulId || '',
  coursesEnabled: e.coursesEnabled || false,
  ceCreditEnabled: e.ceCreditEnabled || false,
  catalogId: e.catalog?.id,
  experienceType: e.type,
  firms: f,
  firmIds: f.map((f) => f.id),
  active: e.active,
  courses: c || [],
})

export default CreateUpdateExperience
