import * as React from 'react'

import { ModalDefault } from '@cais-group/shared/ui/modal/default'
import { SharedPortal } from '@cais-group/shared/ui/modal/shared-portal'

export type ModalPortalProps = {
  onClose: () => void
  children?: React.ReactNode
  header?: React.ReactNode
  className?: string
}

/**
 * ModalPortal manages
 *
 * Do not conditionally render the ModalPortal itself because that may cause
 * the parent page to not scroll, since it is this component that controls
 * the body's scroll state. You should conditionally render its children.
 *
 * If you style this component with styled components `styled` it will style
 * the actual ModalDefault inside, this allows more control over size and position
 * if needed.
 *
 * This modal has tab trapping and keyboard functionality for ADA compliance
 */
export const ModalPortal = (props: ModalPortalProps) => {
  const { children, onClose, className, header } = props

  const modalRef =
    React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>

  return (
    <SharedPortal onClose={onClose} modalRef={modalRef} portalId="modal">
      {!!children && (
        <ModalDefault
          dataTestId="shared-ui-modal-portal"
          ref={modalRef}
          onClose={onClose}
          className={className}
          header={header}
        >
          {children}
        </ModalDefault>
      )}
    </SharedPortal>
  )
}
