import styled from '@emotion/styled'
import { PropsWithChildren, SVGAttributes } from 'react'

export interface PieProps {
  pies: PieSection[]
  size: number
  strokeWidth?: number
  strokeLinecap?: SVGAttributes<SVGCircleElement>['strokeLinecap']
  startDegrees?: number
}

interface PieSection {
  percent: number
  color: string
}

interface PieSectionProps {
  start: number
  percent: number
}

const StyledPie = styled.svg`
  display: block;
  width: 100%;
`

const PieSectionComponent = styled.circle<PieSectionProps>`
  fill: none;
  stroke: ${({ color }) => color};
  animation: progress 1s ease-out forwards;
  transform-origin: center;
  transform: rotateZ(${({ start }) => start}deg);
  stroke-dasharray: ${({ percent, r }) =>
    percentInCircumference(percent, parseInt(`${r}`)) +
    ' ' +
    percentInCircumference(100, parseInt(`${r}`))};
`

const Children = styled.g`
  text-anchor: middle;
  transform: translate(50%, 50%);
`

export const Pie: React.FC<PropsWithChildren<PieProps>> = ({
  children,
  pies,
  size = 36,
  strokeWidth = 1,
  strokeLinecap = 'butt',
  startDegrees = 0,
  ...rest
}) => {
  let start,
    cumulativePercent = 0
  const radius = (size * ((100 - strokeWidth) / 100)) / 2
  return (
    <StyledPie viewBox={`0 0 ${size} ${size}`} {...rest}>
      <g name="pies">
        {pies.map(({ percent, color }) => {
          start = calculateStart(cumulativePercent, startDegrees)
          cumulativePercent += percent
          return (
            <PieSectionComponent
              key={`pie-${start}-${percent}`}
              strokeLinecap={strokeLinecap}
              strokeWidth={`${strokeWidth}%`}
              color={color}
              start={start}
              percent={percent}
              r={radius}
              cx={size / 2}
              cy={size / 2}
            />
          )
        })}
      </g>
      <Children>{children}</Children>
    </StyledPie>
  )
}

const calculateStart = (
  cumulativePercent: number,
  startDegrees: number
): number => {
  return 360 * (cumulativePercent / 100) + (-90 + startDegrees)
}

const percentInCircumference = (percent: number, radius: number) => {
  return 2 * radius * Math.PI * (percent / 100)
}

export default Pie
