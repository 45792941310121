import { Breadcrumbs } from '@mui/material'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Icon } from '@cais-group/equity/atoms/icon'
import { Pill } from '@cais-group/equity/atoms/pill'

import { Aside, Container } from './layout'

/**
 *
 * @deprecated use PageHeader from Equity instead
 *
 * import { PageHeader } from '@cais-group/equity/molecules/page-header'
 */
export const ActionToolbar = ({
  title,
  breadcrumbs = [],
  actions = [],
  onClickBack,
}: {
  title: string
  breadcrumbs?: React.ReactNode[]
  actions?: React.ReactNode[]
  onClickBack?: () => void
}) => {
  const navigate = useNavigate()

  return (
    <Container className="border-b-1 border-0 border-solid border-neutral-200">
      <nav
        className={classNames(
          'flex flex-col gap-16 md:flex-row',
          breadcrumbs.length > 0 ? 'py-12' : 'py-24'
        )}
      >
        <Aside className="justify-center">
          <Pill
            label="Back"
            icon="KeyboardArrowLeft"
            onClick={() =>
              typeof onClickBack === 'function' ? onClickBack() : navigate(-1)
            }
          />
        </Aside>
        <div className="flex flex-1 flex-col justify-between gap-16 md:flex-row md:items-center">
          <div className="flex flex-col">
            {breadcrumbs && (
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={
                  <Icon
                    type="KeyboardArrowRight"
                    color="eq-color-neutral-300"
                  />
                }
              >
                {breadcrumbs}
              </Breadcrumbs>
            )}
            <h1 className="headline-m-strong leading-10">{title}</h1>
          </div>
          <div className="flex gap-16 self-end md:self-center">{actions}</div>
        </div>
      </nav>
    </Container>
  )
}
