import { setBrowserTheme } from '@cais-group/shared/domain/theme-manager'
import type { UserProfile } from '@cais-group/shared/util/graphql'
import { logWarning } from '@cais-group/shared/util/logging'
import type { User } from '@cais-group/shared/util/type/caisiq-be'

export const themeExperienceSettingsService = {
  initiateFirmExperience: (user: User) => {
    const firmExperience = user.firmExperience
    try {
      setBrowserTheme({
        experience: firmExperience || null,
        userProfile: user as unknown as UserProfile,
      })
    } catch (e) {
      logWarning({
        message: 'Failed to set firm experience',
        error: e,
      })
    }
  },
}
