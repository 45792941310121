import styled from '@emotion/styled'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Grid, FormHelperText, ClickAwayListener } from '@mui/material'
import {
  DesktopDatePicker as MUIDatePicker,
  PickersDay,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {
  ChangeEventHandler,
  useEffect,
  useState,
  SetStateAction,
  Dispatch,
} from 'react'
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
} from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUnmount } from 'react-use'
import { isDefined } from 'remeda'

import { ErrorPanel } from '@cais-group/approved/ui/error-panel'
import {
  LabelCssObject,
  H5Strong,
  Body,
} from '@cais-group/approved/ui/typography'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { IconCeCredits } from '@cais-group/caisiq/ui/icons'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Button } from '@cais-group/equity/atoms/button'
import { colors } from '@cais-group/equity/particles/colors'
import { BREAKPOINTS, SPACING } from '@cais-group/shared/ui/design-tokens'
import { TextInputProps, TextInput } from '@cais-group/shared/ui/input/text'
import { LayoutBox } from '@cais-group/shared/ui/layout'
import { TextInputLabel } from '@cais-group/shared/ui/text-field-label'
import { InputMaybe, Scalars } from '@cais-group/shared/util/graphql'
import { PutUserLicencesErrorResponse } from '@cais-group/shared/util/type/caisiq-be'

export type TSize = 'regular' | 'compact'

type DateTimerPickerProps = {
  label: string
  id: string
  description?: string
  disabled?: boolean
  required?: boolean
  dateTime?: boolean
  className?: string
  value: {
    value?: InputMaybe<Scalars['Date']>
  }
  defaultValue?: Date
  onChange: (value: string | null) => void
  onBlur?: () => void
  errorMessage?: string
  isDatePickerVisible: boolean
  setIsDatePickerVisible: Dispatch<SetStateAction<boolean>>
  onFocus: () => void
}
export interface LicenceFormProps {
  title?: string
  description?: string
  cta?: string
  ctaSecondary?: string
  withTargetDate?: boolean
  fields: LicenceFormFields
  formError?: string
  size?: TSize
  onChange?: ChangeEventHandler<HTMLInputElement>
  onSubmit?: SubmitHandler<LicenceFormFields>
  className?: string
  addDatePicker?: boolean
  cfpTargetCompletionDate?: string
  cimaTargetCompletionDate?: string
}

const StyledTextField = styled(TextInput)<TextInputProps>`
  fieldset {
    border-radius: 0;
    border: '1.5px solid rgb(var(--colors-neutral-200))';
  }
  margin-bottom: var(--s8);
  width: 100%;
  .MuiOutlinedInput-root {
    &:hover fieldset {
      border: '1.5px solid rgb(var(--colors-neutral-200))';
    }
  }
`
const StyledErrorText = styled.span`
  color: rgb(var(--colors-error-600));
`
const LayoutCard = styled(LayoutBox)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);

  padding: ${({ theme }) => theme.spacing(3)};

  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    padding: ${({ theme }) => theme.spacing(5)};
  }
`

const FormHeader = styled.div<{ size: TSize }>`
  position: relative;

  ${({ size }) => size === 'regular' && `padding-right: ${SPACING.s88};`}
  & svg {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`

const FormHeaderCaption = styled(Body)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => {
    // @ts-ignore
    return theme.palette.neutral.darker
  }};
`

const StyledTextInput = styled(TextInput)`
  margin-bottom: var(--s24);
`

const FieldSet = styled.div<{ size: TSize; fullWidth: boolean }>`
  margin: ${({ theme }) => theme.spacing(4)} 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    flex-basis: 100%;

    &:first-of-type {
      margin-right: 0;
    }
  }

  ${({ size, theme }) =>
    size === 'regular' &&
    `
  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    flex-wrap: nowrap;

    & > * {
      flex-grow: 1;
      flex-basis: unset;

      &:first-of-type {
        margin-right: ${theme.spacing(3)};

      }
    }
  }
  `}

  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '80%')};
  }
`

const HeaderIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${SPACING.s56};
  height: ${SPACING.s56};
  border-radius: ${SPACING.s56};
  background-color: rgb(var(--colors-primary-100));
`

const StyledCeCreditsIcon = styled(IconCeCredits)`
  color: ${({ theme }) => theme.palette.primary.main};
  width: ${SPACING.s32};
  height: ${SPACING.s32};
  transform: translate(50%, 12px);
`

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > button {
    margin-bottom: var(--s16);

    /* Not mobile */
    @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
      flex-direction: row;
      margin-right: var(--s24);
      margin-bottom: 0;
    }
  }

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
    flex-direction: row;
    align-items: center;
  }
`

const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
`
const StyledDatePicker = styled(LicenseDatePicker)`
  & .MuiOutlinedInput-input {
    color: ${colors['eq-color-neutral-900']};
  }
`

const StyledCalendarTodayIcon = styled(CalendarTodayIcon)`
  fill: ${colors['eq-color-neutral-300']};
`

export interface LicenceFormFields {
  cfpNumber: string
  cimaNumber: string
  cfpTargetCompletionDate?: string | null
  cimaTargetCompletionDate?: string | null
}

export function LicenceForm({
  title,
  description,
  cta = 'Save to Profile',
  ctaSecondary,
  size = 'regular',
  fields,
  onSubmit = () => {},
  formError,
  className,
  addDatePicker = false,
}: LicenceFormProps) {
  const methods = useForm({
    defaultValues: fields,
  })
  const { control, formState, handleSubmit, reset, getValues, setError } =
    methods
  const width = addDatePicker ? '50%' : '100%'
  const [isCfpDatePickerVisible, setIsCfpDatePickerVisible] = useState(false)
  const [isCimaDatePickerVisible, setIsCimaDatePickerVisible] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  const isSwitchedUser = userSettingsService.user.switchedUser

  const bothFieldsEmpty =
    getValues('cfpNumber')?.trim()?.length === 0 &&
    getValues('cimaNumber')?.trim()?.length === 0

  useUnmount(() => {
    if (formState.isDirty || bothFieldsEmpty) {
      const payload = {
        ...(formState.dirtyFields.cfpNumber && {
          cfpNumber: getValues('cfpNumber'),
        }),
        ...(formState.dirtyFields.cimaNumber && {
          cimaNumber: getValues('cimaNumber'),
        }),
        cfpNumberOnFile: formState.defaultValues?.cfpNumber ?? '',
        cimaNumberOnFile: formState.defaultValues?.cimaNumber ?? '',
      }

      trackingService.ceCredentialsAbandoned(
        payload,
        location as unknown as Location
      )
    }
  })

  // This reset is used to fix isDirty to the current values, so that
  // the submit button only activates on changes to data
  useEffect(() => {
    if (formState.isSubmitted) {
      reset(undefined, { keepValues: true, keepErrors: true })
    }
  }, [formState, getValues, reset])

  return (
    <LayoutCard className={className}>
      {title ? (
        <FormHeader size={size}>
          <H5Strong>{title}</H5Strong>
          {Boolean(description) && (
            <FormHeaderCaption>{description}</FormHeaderCaption>
          )}
          {size === 'regular' && (
            <HeaderIconWrapper>
              <StyledCeCreditsIcon />
            </HeaderIconWrapper>
          )}
        </FormHeader>
      ) : null}
      {Boolean(formError) && (
        <ErrorPanel title="There was an error with the form">
          {formError}
        </ErrorPanel>
      )}
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(async (values) => {
            try {
              await onSubmit(values)
              trackingService.ceCredentialsSubmitted({
                cfpNumber: values.cfpNumber,
                cimaNumber: values.cimaNumber,
                cfpNumberOnFile: formState.defaultValues?.cfpNumber ?? '',
                cimaNumberOnFile: formState.defaultValues?.cimaNumber ?? '',
              })
            } catch (error) {
              const response = (error as PutUserLicencesErrorResponse).response
              if (response?.status === 400) {
                const data = JSON.parse(response.config.data)
                if (response.data.cfpNotValid) {
                  return setError('cfpNumber', {
                    type: 'value',
                    message: 'Please enter a valid CFP number',
                  })
                }
                if (response.data.cimaNotValid) {
                  return setError('cimaNumber', {
                    type: 'value',
                    message: 'Please enter a valid CIMA number',
                  })
                }
                /**
                 * @todo will the BE send back and error message for below?
                 */
                if (data.cfp.targetCompletionDate === 'Invalid') {
                  setError('cfpTargetCompletionDate', {
                    type: 'value',
                    message: 'Please enter a valid CFP target completion date',
                  })
                }
                if (data.cima.targetCompletionDate === 'Invalid') {
                  setError('cimaTargetCompletionDate', {
                    type: 'value',
                    message: 'Please enter a valid CIMA target completion date',
                  })
                }
              }
            }
          })}
          aria-label="CE User Licence Form"
        >
          <FieldSet
            size={size}
            className="field"
            fullWidth={!addDatePicker || size !== 'compact'}
          >
            {isDefined(fields.cfpNumber) && (
              <StyledGrid container spacing={4}>
                <Grid item width={width}>
                  <Controller
                    name="cfpNumber"
                    control={control}
                    rules={{
                      validate: {
                        requiredWhen: (licence) =>
                          getValues().cfpTargetCompletionDate && !licence.length
                            ? 'CFP number is required when a target date has been entered'
                            : true,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <StyledTextInput
                        disabled={isSwitchedUser}
                        id="cfpNumber"
                        size="regular"
                        {...field}
                        onBlur={() => {
                          formState.dirtyFields.cfpNumber &&
                            trackingService.ceCredentialsEntered({
                              credentialType: 'cfp',
                              credentialValue: field.value,
                            })
                        }}
                        label="CFP Number"
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        bold
                      />
                    )}
                  />
                </Grid>

                {addDatePicker && (
                  <ClickAwayListener
                    onClickAway={() => {
                      setIsCfpDatePickerVisible(false)
                    }}
                  >
                    <Grid item width={width}>
                      <LicenseFormDatePickerController
                        disabled={isSwitchedUser}
                        name="cfpTargetCompletionDate"
                        label="Target completion date"
                        dateTime={true}
                        description="Set a target completion date to track your credits against the CFP requirement window. The target completion date will automatically reset every two years."
                        isDatePickerVisible={isCfpDatePickerVisible}
                        setIsDatePickerVisible={setIsCfpDatePickerVisible}
                        onFocus={() => {
                          setIsCfpDatePickerVisible(true)
                        }}
                      />
                    </Grid>
                  </ClickAwayListener>
                )}
              </StyledGrid>
            )}
            {isDefined(fields.cimaNumber) && (
              <StyledGrid container spacing={4}>
                <Grid item width={width}>
                  <Controller
                    name="cimaNumber"
                    control={control}
                    rules={{
                      validate: {
                        requiredWhen: (licence) =>
                          getValues().cimaTargetCompletionDate &&
                          !licence.length
                            ? 'CIMA number is required when a target date has been entered'
                            : true,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <StyledTextInput
                        disabled={isSwitchedUser}
                        id="cimaNumber"
                        size="regular"
                        label="CIMA Number"
                        {...field}
                        onBlur={() => {
                          formState.dirtyFields.cfpNumber &&
                            trackingService.ceCredentialsEntered({
                              credentialType: 'cima',
                              credentialValue: field.value,
                            })
                        }}
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        bold
                      />
                    )}
                  />
                </Grid>

                {addDatePicker && (
                  <ClickAwayListener
                    onClickAway={() => {
                      setIsCimaDatePickerVisible(false)
                    }}
                  >
                    <Grid item width={width} role="presentation">
                      <LicenseFormDatePickerController
                        disabled={isSwitchedUser}
                        name="cimaTargetCompletionDate"
                        label="Target completion date"
                        dateTime={true}
                        description="Set a target completion date to track your credits against the CIMA requirement window. The target completion date will automatically reset every two years."
                        isDatePickerVisible={isCimaDatePickerVisible}
                        setIsDatePickerVisible={setIsCimaDatePickerVisible}
                        onFocus={() => setIsCimaDatePickerVisible(true)}
                      />
                    </Grid>
                  </ClickAwayListener>
                )}
              </StyledGrid>
            )}
          </FieldSet>

          <ButtonsContainer>
            <Button
              disabled={formState.isDirty === false || isSwitchedUser}
              type="submit"
              loading={formState.isSubmitting}
            >
              {cta}
            </Button>
            {ctaSecondary ? (
              <Button
                variant="tertiary"
                data-testid="ce-credits-secondary-cta"
                onClick={() => navigate(ROUTES.courses)}
              >
                {ctaSecondary}
              </Button>
            ) : null}
          </ButtonsContainer>
        </form>
      </FormProvider>
    </LayoutCard>
  )
}

interface LicenseFormControllerProps {
  name: 'cfpTargetCompletionDate' | 'cimaTargetCompletionDate'
  id?: string
  label: string
  required?: boolean
  disabled?: boolean
  description?: string
  dateTime?: boolean
  isDatePickerVisible: boolean
  setIsDatePickerVisible: Dispatch<SetStateAction<boolean>>
  onFocus: () => void
}

export const LicenseFormDatePickerController = (
  props: LicenseFormControllerProps
) => {
  const {
    name,
    label,
    disabled,
    dateTime,
    required,
    description,
    isDatePickerVisible,
    setIsDatePickerVisible,
    onFocus,
  } = props

  return (
    <Controller
      name={name}
      rules={{
        validate: {
          validDate: (date) =>
            date && isNaN(Date.parse(date))
              ? 'Please enter a valid date'
              : true,
        },
      }}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <StyledDatePicker
          label={label}
          id={name}
          disabled={disabled}
          data-testid={name}
          value={value}
          required={required}
          errorMessage={error?.message}
          dateTime={dateTime}
          onChange={onChange}
          onBlur={onBlur}
          description={description}
          isDatePickerVisible={isDatePickerVisible}
          setIsDatePickerVisible={setIsDatePickerVisible}
          onFocus={onFocus}
        />
      )}
    />
  )
}

function LicenseDatePicker(props: DateTimerPickerProps) {
  const {
    label,
    id,
    description,
    required,
    value,
    defaultValue = null, // We have to default to null. Undefined triggers the input to display current date by default
    dateTime,
    onChange,
    onBlur,
    className,
    disabled,
    errorMessage,
    setIsDatePickerVisible,
    isDatePickerVisible,
    onFocus,
  } = props

  const valueToRender = typeof value !== 'string' ? value?.value : value

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={className}>
        <TextInputLabel
          htmlFor={id}
          label={label}
          description={description}
          required={required}
        />
        <MUIDatePicker
          components={{ OpenPickerIcon: StyledCalendarTodayIcon }}
          closeOnSelect
          disabled={disabled}
          value={valueToRender || defaultValue}
          open={isDatePickerVisible}
          onOpen={() => {
            setIsDatePickerVisible(true)
          }}
          onAccept={() => {
            setIsDatePickerVisible(false)
          }}
          onChange={(newValue) => {
            onChange(
              newValue
                ? dayjs(newValue).isValid()
                  ? dateTime
                    ? dayjs(newValue).toISOString()
                    : dayjs(newValue).format('YYYY-MM-DD')
                  : 'Invalid'
                : null
            )
          }}
          onClose={() => {
            if (onBlur) {
              onBlur()
            }
            setIsDatePickerVisible(false)
          }}
          renderInput={(params) => (
            <StyledTextField
              bold
              id={id}
              onBlur={() => {
                if (onBlur) {
                  onBlur()
                }
                setIsDatePickerVisible(false)
              }}
              {...params}
              error={!!errorMessage}
              onFocus={onFocus}
            />
          )}
          PaperProps={{
            sx: {
              ...styles.root,
            },
            elevation: 4,
          }}
          renderDay={(day, _, pickersDayProps) => (
            <PickersDay
              {...pickersDayProps}
              day={day}
              onDaySelect={(value, isFinished) => {
                onChange(
                  value
                    ? dayjs(value).isValid()
                      ? dateTime
                        ? dayjs(value).toISOString()
                        : dayjs(value).format('YYYY-MM-DD')
                      : 'Invalid'
                    : null
                )
                if (isFinished === 'finish') {
                  setIsDatePickerVisible(false)
                }
              }}
              outsideCurrentMonth={false}
              sx={{
                ...LabelCssObject,
                color: colors['eq-color-neutral-600'],
                borderRadius: 0,
                '&.Mui-selected': {
                  backgroundColor: colors['eq-color-primary-600'],
                },
              }}
            />
          )}
        />
        {errorMessage && (
          <FormHelperText>
            <StyledErrorText>{errorMessage}</StyledErrorText>
          </FormHelperText>
        )}
      </div>
    </LocalizationProvider>
  )
}

const styles = {
  root: {
    marginLeft: `calc(${SPACING.s88} + ${SPACING.s4})`,
    '& .MuiCalendarPicker-root > div:first-of-type': {
      maxHeight: '48px',
      minHeight: '48px',
      backgroundColor: colors['eq-color-neutral-100'],
      color: colors['eq-color-neutral-600'],
      marginTop: '0 !important',
    },
    '& .MuiSvgIcon-root': {
      fill: colors['eq-color-neutral-600'],
    },
  },
}
