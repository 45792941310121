import { CeCreditsType } from '@cais-group/caisiq/domain/course'

export const getHeaderLabel = (
  ceCreditsAvailable: CeCreditsType
): string | undefined => {
  return ceCreditsAvailable?.totalCeCreditsAvailable
    ? `${ceCreditsAvailable?.totalCeCreditsAvailable} CE Credit${
        ceCreditsAvailable?.totalCeCreditsAvailable > 1 ? 's' : ''
      }`
    : undefined
}
