import { useState } from 'react'
import { useMutation } from 'react-query'

import { ManageGlobalCoursesTable } from '@cais-group/caisiq/ui/manage/global-courses-table'
import { ManageCaisIqNav } from '@cais-group/caisiq/ui/manage/nav'
import { useInvalidateByRegex } from '@cais-group/caisiq/util/default-query-function'
import { useAxiosInstance } from '@cais-group/caisiq/util/hook/use-axios-instance'
import { ManageTableProps } from '@cais-group/caisiq/util/type/manage-caisiq'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Button } from '@cais-group/equity/atoms/button'
import { showToast } from '@cais-group/equity/organisms/notifications'
import {
  CourseMetadata,
  UserRole,
  ApiPaths,
} from '@cais-group/shared/util/type/caisiq-be'

export function useSynchCoursesMetadataWithALM() {
  const axios = useAxiosInstance()
  return useMutation(() => axios.post(ApiPaths.triggerCourseMetadataSynch))
}

export const ManageCourses = (props: ManageTableProps<CourseMetadata>) => {
  const [syncInProgress, setSyncInProgress] = useState(false)
  const hasFullAccess = userSettingsService.hasRole(UserRole.CaisiqManageWrite)
  const invalidate = useInvalidateByRegex()

  const { mutate: synchCoursesMetadataWithALM } =
    useSynchCoursesMetadataWithALM()
  return (
    <>
      <ManageCaisIqNav
        placeholder="Search by course name or ID"
        rightButton={
          hasFullAccess ? (
            <Button
              loading={syncInProgress}
              onClick={() => {
                setSyncInProgress(true)
                synchCoursesMetadataWithALM(undefined, {
                  onSuccess: async () => {
                    setSyncInProgress(false)
                    await invalidate(/v1[/]manage[/]courses[/]metadata/)
                    showToast({
                      type: 'success',
                      title: `Synced with ALM`,
                    })
                  },
                  onError: () => {
                    setSyncInProgress(false)
                    showToast({
                      type: 'error',
                      title: `There was an error syncing data with ALM`,
                    })
                  },
                })
              }}
            >
              Sync with ALM
            </Button>
          ) : undefined
        }
      />
      <ManageGlobalCoursesTable {...props} />
    </>
  )
}
