import cx from 'classnames'
import { ReactNode, forwardRef } from 'react'

export interface InputProps {
  placeholder?: string
  isSearchable?: boolean
  disabled?: boolean
  isOpen?: boolean
  selectedOptionsLength?: number
  children?: ReactNode
}

export const DefaultInput = forwardRef<HTMLInputElement, InputProps>(
  (
    { isOpen, disabled, isSearchable, selectedOptionsLength, ...validProps },
    ref
  ) => {
    return (
      <input
        readOnly={!isSearchable}
        className={cx(
          'bodySmall placeholder:bodySmall grow border-0 p-0 text-neutral-900 outline-0 ring-0 placeholder:text-neutral-500 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-500',
          {
            'cursor-default': !isSearchable,
          }
        )}
        disabled={disabled}
        ref={ref}
        {...validProps}
      />
    )
  }
)
