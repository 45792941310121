import { AllColor, allColors } from '@cais-group/equity/particles/colors'
import {
  BrandIconSize,
  BrandIconType,
  IconSize,
  IconType,
  brandIconSizes,
  brandIconsMapping,
  iconSizes,
  iconsMapping,
} from '@cais-group/equity/particles/icons'

const ICON_COMPONENT_INDEX = 1

export type MixedIconProps = BrandIconProps | IconProps

const isBrandIcon = (icon: MixedIconProps): icon is BrandIconProps =>
  icon.type in brandIconsMapping

export const isBrandIconType = (
  icon: IconType | BrandIconType
): icon is BrandIconType => icon in brandIconsMapping

/**
 * The MixedIcon icon component allows to use either a regular icon or a mixed icon.
 * Useful in situations where we want to use one or other depending of certain conditions.
 */
export const MixedIcon = (props: MixedIconProps) => {
  if (isBrandIcon(props)) {
    return <BrandIcon {...props} />
  }
  return <Icon {...props} />
}

export interface IconProps {
  /** Mandatory property to define which icon to be rendered */
  type: IconType
  /** Optional property to determine what size the icon should be */
  size?: IconSize
  /** Optional property to set which Equity color to be used, if not defined it will fallback to 'currentColor'   */
  color?: AllColor
}

/**
 * The Icon component currently works as a proxy to the [MUI icons](https://mui.com/material-ui/material-icons/).
 * This means if we ever want to move away from MUI we only need to change in one place. No need to go through all the code base and update every single instance of the icon component.
 *
 * The component is also less permissive, it only allows using the icons, sizes and colors that are defined by the Equity design system.
 */
export const Icon = ({ type, size = 'regular', color, ...rest }: IconProps) => {
  const Icon = iconsMapping[type][ICON_COMPONENT_INDEX]
  return (
    <Icon
      {...rest}
      style={{
        fill: color ? allColors[color] : 'currentColor',
        fontSize: iconSizes[size],
      }}
    />
  )
}

export interface BrandIconProps {
  /** Mandatory property to define which icon to be rendered */
  type: BrandIconType
  /** Optional property to determine what size the icon should be */
  size?: BrandIconSize
}

/**
 * This component allows using the brand icons defined in the [Icons Particle](?path=/docs/particles-icons-about--docs#brand-icons)
 */
export const BrandIcon = ({ type, size = 'large' }: BrandIconProps) => {
  const Icon = brandIconsMapping[type][ICON_COMPONENT_INDEX]
  return <Icon width={brandIconSizes[size]} />
}

export { iconSizes }
