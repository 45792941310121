import styled from '@emotion/styled'
import LockIcon from '@mui/icons-material/Lock'

import { H6Strong, CaptionRegular } from '@cais-group/approved/ui/typography'
import { MercerRating } from '@cais-group/caisiq/ui/mercer-rating'
import { LayoutCluster } from '@cais-group/shared/ui/layout'
import { Fund, FundWithThumbnail } from '@cais-group/shared/util/type/caisiq-be'

type StyledContainerProps = {
  onClick: () => void
}
export interface FundCardProps {
  fund: FundWithThumbnail
  onSetSelectedFund: (arg: Fund) => void
}

const StyledContainer = styled(LayoutCluster)<StyledContainerProps>`
  background-color: rgb(var(--colors-neutral-0));
  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 24px rgb(var(--colors-neutral-300));
  }

  // Prevents the Mercer rating from dropping to the next line
  & > div {
    flex-wrap: nowrap;
  }
`
const InfoContainer = styled.div`
  flex-direction: row;
  display: flex;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  width: calc(var(--s56) - 2px);
  height: calc(var(--s56) - 2px);
  overflow: hidden;
  position: relative;
  margin: var(--s32) var(--s24) var(--s32) var(--s32);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 700px) {
    margin-bottom: var(--s16);
  }
`

const Image = styled.img`
  width: 100%;
  position: absolute;
  object-fit: contain;
  object-position: center;
`

const TitleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 700px) {
    margin-left: var(--s32);
    margin-bottom: var(--s32);
  }
`
const Title = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
`
const Rating = styled.div`
  margin: var(--s32);
  @media screen and (max-width: 700px) {
    display: none;
  }
`
const Subtitle = styled(CaptionRegular)`
  color: rgb(var(--colors-neutral-600));
`
const ImageOverlay = styled.div`
  background-color: rgb(var(--colors-neutral-900));
  opacity: 0.5;
  width: 3.375rem;
  height: 3.375rem;
  position: absolute;
  z-index: 2;
`

const StyledLockIcon = styled(LockIcon)`
  fill: rgb(var(--colors-neutral-0));
  position: absolute;
  height: var(--s16);
  width: var(--s16);
  z-index: 3;
`

export const FundCard = ({ fund, onSetSelectedFund }: FundCardProps) => {
  const { fundIdentifier, smallThumbnailUrl, name, managerName, mercerRating } =
    fund

  const handleFundCardClick = () => {
    onSetSelectedFund(fund)
  }

  return (
    <StyledContainer
      justify="space-between"
      data-testid={`fund-card-${fundIdentifier}`}
      onClick={handleFundCardClick}
    >
      <InfoContainer>
        <ImageContainer data-testid={`image-fund-${fundIdentifier}`}>
          {Boolean(fund.blocked) && (
            <>
              <StyledLockIcon />
              <ImageOverlay />
            </>
          )}
          <Image src={smallThumbnailUrl}></Image>
        </ImageContainer>
        <TitleInfoContainer>
          <Title>
            <H6Strong>{name}</H6Strong>
          </Title>
          <Subtitle>{managerName}</Subtitle>
        </TitleInfoContainer>
      </InfoContainer>
      <Rating data-testid={`mercer-rating-fund-${fundIdentifier}`}>
        <MercerRating rating={mercerRating} small={true} />
      </Rating>
    </StyledContainer>
  )
}
