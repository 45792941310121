import { useGetFundProductIdsAndNames } from '@cais-group/funds-pre-trade/domain/api'
import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'
import { logWarning } from '@cais-group/shared/util/logging'

const hourInMs = 1000 * 60 * 60

export const useGetAllowedFundIds = () => {
  const userProfile = useUserProfile()
  const userId = userProfile.userProfileState.userProfile?.id

  const {
    data: allowedFundIds,
    isLoading,
    isError,
  } = useGetFundProductIdsAndNames(
    {},
    {
      query: {
        select: (data) =>
          data.fundProducts?.map((fund) => fund.fundProductId) ?? [],
        cacheTime: hourInMs,
        onError: (error) => {
          logWarning({
            message:
              error.type.displayMessage ??
              error.message ??
              'There was an error loading the allowed funds',
            error,
            context: {
              userId: userId ?? 'unknown',
            },
          })
        },
        onSettled: (data, error) => {
          if (!data?.length && !error) {
            logWarning({
              message: 'User had no allowed funds returned from API',
              error: new Error('User had no allowed funds returned from API'),
              context: {
                userId: userId ?? 'unknown',
              },
            })
          }
        },
        retry: (failureCount, error) => {
          if (error.type.status === 403) {
            return false
          }
          return failureCount < 3
        },
      },
    }
  )
  return {
    data: allowedFundIds ?? [],
    isLoading: isLoading,
    isError,
  }
}
