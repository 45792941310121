import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import type { ContentDataType } from '@cais-group/homepage/util/types'
import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
} from '@cais-group/shared/domain/contentful/api'
import { useGetAllResearchQuery } from '@cais-group/shared/util/graphql/mfe-contentful'
import type { GetAllResearchQuery } from '@cais-group/shared/util/graphql/mfe-contentful'

import { FILTERS } from '../constants'
import { makeResearchQueryVariables, sortContentData } from '../helper'
import { selectResearch } from '../helper/select-content-helpers'

type Data = {
  allResearch: ContentDataType[] | null
} | null

export type Filters = Record<string, string[]>

export const useGetResearch = (
  variables: {
    selectedFilters: Filters
    defaultFilters?: Record<string, unknown>
    limit?: number
    searchText?: string
  },
  options?: {
    enabled?: boolean
    queryKey?: string[]
    cacheTime?: number
  }
) => {
  const allowedUserPermissions = useGetAllowedContentPermissions()

  const queryParams = makeResearchQueryVariables(
    variables.selectedFilters,
    variables.limit,
    variables.defaultFilters
      ? variables.defaultFilters
      : { ...FILTERS.research.is },
    variables.searchText
  )

  const response = useReactQueryResultAsApiState<GetAllResearchQuery, Data>(
    useGetAllResearchQuery(queryParams, {
      enabled: options?.enabled,
      queryKey: options?.queryKey ? options.queryKey : ['all-research'],
      cacheTime: options?.cacheTime ? options.cacheTime : 0, // because using the filters will affect the results
      refetchOnWindowFocus: false,
    }),
    (data) => ({
      allResearch: sortContentData(
        selectResearch(allowedUserPermissions.data, data)
      ),
    }),
    'Could not load the "all research" query'
  )
  const loading =
    response === ApiStateEnum.LOADING || allowedUserPermissions.isLoading
  const error = isError(response)

  return compileResults(response, loading, error)
}

function compileResults(
  data: Data | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}
