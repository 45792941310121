import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react'

import { TypeVideoData } from '@cais-group/shared/util/type/video-data'
import { TypeVideoPlaylistData } from '@cais-group/shared/util/type/video-playlist-data'

import {
  PlaylistItem,
  type PlaylistItemProps,
} from './components/playlist-item'

type PlaylistProps = {
  videos?: TypeVideoData[]
  episodic?: boolean | null
  onVideoSelected?: (video: TypeVideoData) => void
  currentVideoId?: string
  playlist?: TypeVideoPlaylistData
  currentIndex?: number
  theme?: PlaylistItemProps['theme']
}

export const Playlist = ({
  episodic,
  videos = [],
  onVideoSelected,
  currentVideoId,
  currentIndex,
  theme = 'dark',
}: PlaylistProps) => {
  const ref = useRef<HTMLOListElement>(null)

  const totalVideos = videos.length

  /** scroll active video into view */
  useEffect(() => {
    if (!ref.current || !currentIndex) return
    const playlistHeight = ref.current.scrollHeight
    const targetScroll = (currentIndex * playlistHeight) / totalVideos
    ref.current.scrollTo({ top: targetScroll, behavior: 'smooth' })
  }, [totalVideos, currentIndex])

  const handleClick = () => {
    const video = document.querySelector('mux-player') as HTMLVideoElement
    if (video) {
      const abort = new Event('abort')
      video.dispatchEvent(abort)
    }
  }

  return (
    <ol reversed ref={ref} onClick={handleClick} data-testid="video-playlist">
      {videos.map((video, index) => (
        <PlaylistItem
          key={video.sys.id}
          video={video}
          episodeNumber={episodic ? index + 1 : 0}
          active={video.sys.id === currentVideoId}
          onVideoSelected={onVideoSelected}
          theme={theme}
        />
      ))}
    </ol>
  )
}

export const PlaylistContainer = ({
  children,
  header,
}: PropsWithChildren & {
  header: ReactNode
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return
    ref.current.focus({ preventScroll: true })
  }, [])

  return (
    <div className="mx-auto w-full py-12 pb-56">
      {header}
      <div
        ref={ref}
        tabIndex={-1}
        className="relative mx-auto my-12 flex w-full flex-col gap-24 lg:flex-row lg:gap-0"
      >
        {children}
      </div>
    </div>
  )
}
