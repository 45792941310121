import { type TrackingProps as EquityTrackingProps } from '@cais-group/equity/util/tracking-utils'

// TODO - updating consuming code to import from Equity and use updated attributes
// event -> click_type
// sub-section -> sub_section
// content-type -> content_type
export interface TrackingProps extends EquityTrackingProps {
  'data-track-event'?: string
  'data-track-item-name'?: string
  'data-track-section'?: string
  'data-track-sub-section'?: string
  'data-track-content-type'?: string
}
