import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import {
  BreadcrumbItem,
  Breadcrumbs,
} from '@cais-group/approved/ui/breadcrumbs'
import { BackPillButton } from '@cais-group/equity/atoms/pill'
import { Grid } from '@cais-group/shared/ui/grid'

const StyledCenterColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: var(--s24);
  padding-bottom: var(--s16);
`

const StyledGroupWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: var(--s24) 0;
  column-gap: var(--s16);
`

export type NavBarProps = {
  breadcrumbs: Array<BreadcrumbItem>
  testId?: string
  actions?: React.ReactElement[]
  information?: React.ReactElement
  backAction?: () => void
  backBtnText?: string
  largeMode?: boolean
}

export const NavBar = ({
  breadcrumbs,
  testId,
  actions,
  information,
  backAction,
  backBtnText,
  largeMode,
}: NavBarProps) => {
  const navigate = useNavigate()

  // Note this allows for somewhat improved layouts for navbar; I don't think the MUI grid is working that well for such a general purpose component; however it is used in about 20 places to don't want to break anything; this provides a backwards compatible way to give more space for actions section; possibly we want to expose both actions and info/all responsive options so people can customise; but perhaps with equity will be new design anyway. Maybe we want to allow info/actions to be take available space, with a space-between-ish layout?
  const layoutProps = largeMode
    ? ({
        back: {
          lg: 2,
        },
        info: {
          lg: actions ? 5 : 10,
        },
        actions: {
          lg: 5,
        },
      } as const)
    : ({
        back: {
          md: 2,
        },
        info: {
          md: actions ? 6 : 10,
        },
        actions: {
          md: 4,
        },
      } as const)

  return (
    <Grid container data-testid={testId}>
      <Grid
        item
        {...layoutProps.back}
        alignSelf="center"
        style={{ padding: 0 }}
      >
        <div className="ml-32">
          <BackPillButton
            label={backBtnText}
            onClick={backAction ?? (() => navigate(-1))}
          />
        </div>
      </Grid>
      <Grid item {...layoutProps.info}>
        <StyledCenterColumnWrapper>
          <Breadcrumbs items={breadcrumbs} />
          {information}
        </StyledCenterColumnWrapper>
      </Grid>
      {actions ? (
        <Grid item {...layoutProps.actions} alignSelf="flex-end">
          <StyledGroupWrapper>{actions}</StyledGroupWrapper>
        </Grid>
      ) : null}
    </Grid>
  )
}
