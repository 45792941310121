import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { ManageCaisIqNav } from '@cais-group/caisiq/ui/manage/nav'
import {
  EnableDisableExperienceModal as EnableExperienceModal,
  buildEnableDisableModalDetails,
  usePutEnableExperience,
} from '@cais-group/caisiq/ui/modal/enable-disable-experience'
import { ManageCaisIqTable } from '@cais-group/caisiq/ui/table/manage'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { ManageTableProps } from '@cais-group/caisiq/util/type/manage-caisiq'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Button } from '@cais-group/equity/atoms/button'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import {
  ExperienceResponse,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'

const Container = styled.div`
  display: flex;
  padding: ${SPACING.s12} ${SPACING.s56} ${SPACING.s12} ${SPACING.s56};
  background-color: rgb(var(--colors-neutral-100));
  flex-direction: column;
  position: relative;
  flex-grow: 1;
`

export type ManageExperiencesProps = ManageTableProps<ExperienceResponse> & {
  isOpen?: boolean
  onClose: () => void
  selectedExperience?: ExperienceResponse
}
export const ManageExperiences = (props: ManageExperiencesProps) => {
  const navigate = useNavigate()
  const [, setSearchTerm] = useRecoilState(searchTermAtom)
  const { isOpen, onClose, selectedExperience } = props

  // Unable to use the other put experience b/c it uses the params and we won't have those on this page
  const { handleEnable } = usePutEnableExperience(selectedExperience)

  const hasFullAccess = userSettingsService.hasRole(UserRole.CaisiqManageWrite)

  return (
    <>
      <ManageCaisIqNav
        placeholder="Search by experience name or label"
        rightButton={
          hasFullAccess ? (
            <Button
              onClick={() => {
                trackingService.manageNewExperienceStarted()
                navigate(ROUTES.manageCreateExperience())
              }}
            >
              Create New Experience
            </Button>
          ) : undefined
        }
      />
      <Container data-testid="firms-table-container">
        <ManageCaisIqTable<ExperienceResponse>
          tableType="experiences"
          {...props}
          onRowClick={({ uuid }) => {
            setSearchTerm('')
            navigate(ROUTES.manageEditExperience(uuid))
          }}
          classNames={{ container: 'pt-12 pb-56' }}
        />
      </Container>
      <EnableExperienceModal
        open={isOpen}
        onConfirm={async () => {
          await handleEnable()
          onClose()
        }}
        onClose={onClose}
        options={buildEnableDisableModalDetails(selectedExperience?.name || '')}
      />
    </>
  )
}
