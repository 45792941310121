import styled from '@emotion/styled'
import * as React from 'react'
import { omit } from 'remeda'

import { Pill } from '@cais-group/caisiq-ui-pill'
import { LayoutCluster } from '@cais-group/shared/ui/layout'

export type StyledSubnavItemButtonProps = {
  active?: boolean
}

export const Subnav = styled(LayoutCluster)`
  width: auto;
  padding-top: var(--s16);
  margin-bottom: var(--s24);
  @media screen and (max-width: 860px) {
    & {
      justify-content: flex-start;
      margin: 0 var(--s32) var(--s32) var(--s32);
    }
  }
`

const StyledSubnavItemButton = styled.button<StyledSubnavItemButtonProps>`
  background: none;
  font-weight: ${(props) => (props.active ? '600' : 'normal')};
  cursor: pointer;
  opacity: ${(props) => (props.active ? '1' : '.64')};
`

export type SubnavItemProps = React.ComponentProps<
  typeof StyledSubnavItemButton
> & {
  count?: number
  disableRipple?: boolean
}

const StyledSubNavItemContainer = styled.div`
  display: flex;
`

const StyledPill = styled(Pill)`
  position: relative;
  margin-left: var(--s8);
`

export const SubnavItem = (props: SubnavItemProps) => {
  const buttonProps = omit(props, ['count'])
  return (
    <StyledSubNavItemContainer>
      <StyledSubnavItemButton {...buttonProps}></StyledSubnavItemButton>
      {props.count !== undefined && (
        <StyledPill
          label={props.count}
          backgroundColor={
            props.count === 0
              ? 'rgb(var(--colors-neutral-0))'
              : 'rgb(var(--colors-neutral-200))'
          }
        />
      )}
    </StyledSubNavItemContainer>
  )
}
