import styled from '@emotion/styled'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton, useMediaQuery, useTheme } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import { H6Strong, BodySmall, Label } from '@cais-group/approved/ui/typography'
import { getDurationString } from '@cais-group/shared/util/time/get-duration-string'
import {
  HomeOfficeStatusType,
  HomeOfficeTableCell,
  StatusesType,
} from '@cais-group/shared/util/type/homeoffice-data'

const Name = styled.div`
  display: flex;
  align-items: center;

  overflow-wrap: break-word;
  ${H6Strong} {
    margin-right: var(--s4);
    color: rgb(var(--colors-primary-600));
    letter-spacing: 0.3px;
    &:hover {
      cursor: pointer;
    }
  }
`
const StyledChevronRightIcon = styled(ChevronRightIcon)`
  fill: rgb(var(--colors-primary-600));
  &:hover {
    cursor: pointer;
  }
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  fill: rgb(var(--colors-primary-600));
  &:hover {
    cursor: pointer;
  }
`

const Email = styled(BodySmall)`
  overflow-wrap: break-word;
  width: var(--s232);
`
const MailTo = styled.a`
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
`

const StyledFirmTeamsContainer = styled.div`
  @media only screen and (orientation: landscape) {
    padding-left: var(--s24);
  }
`
const StyledBodySmall = styled(BodySmall)`
  @media only screen and (orientation: landscape) {
    padding-left: var(--s24);
  }
`

const getMilliseconds = (date: string | undefined) => {
  if (!date) {
    return
  }

  const today = new Date()
  const previousDate = new Date(date)
  const difference = today.getTime() - previousDate.getTime()
  return difference
}
type FormatTableProps = {
  data: StatusesType[]
  isLoading: boolean
  skeletonLoadingState: boolean
  isPreviousData: boolean
}

export const useFormatTableData = (props: FormatTableProps) => {
  const { data, isLoading, skeletonLoadingState, isPreviousData } = props
  const theme = useTheme()
  // Custom breakpoint to ensure all size tablets get correct styling
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 75))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const onExpandedChanged = (
    cell: HomeOfficeTableCell<Record<string, unknown>>,
    newExpandedId: string
  ) => {
    cell.rows.forEach((row) => {
      if (row.id === newExpandedId) {
        if (row.isExpanded === true) {
          // necessary to delay closing the row before animation is done
          setTimeout(() => {
            row.toggleRowExpanded(false)
          }, 250)
        } else {
          // nesessary to delay opening new row before it receives the new data if user had another row open when clicked
          setTimeout(() => {
            row.toggleRowExpanded(true)
          }, 251)
        }
      } else if (row.id !== newExpandedId) {
        if (row.toggleRowExpanded) {
          // necessary to delay closing the row before animation is done
          setTimeout(() => {
            row.toggleRowExpanded(false)
          }, 250)
        }
      }
    })
  }

  const learnersData: HomeOfficeStatusType[] & { teams?: string } = data.map(
    ({ status, teams }) => ({
      ...status,
      teams: teams.join(', '),
      lastStudied: getMilliseconds(status.lastStudied as string),
      lastCourseCompletionDate: status.lastCourseCompletionDate
        ? dayjs(status.lastCourseCompletionDate).format('MM/DD/YY')
        : '-',
    })
  )

  /**
   * @todo After merger, all of the necessary fields needed to be added to new columns...Look into cleaning up types. There seems to be redundantcies
   */
  const desktopColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 500,
        maxWidth: 500,
        minWidth: 500,
        title: 'Name',
        id: 'name',
        sort: {
          kind: 'alphabetical',
        },
        Cell: (cell: HomeOfficeTableCell) => {
          cell.row.canExpand = true
          return (
            <Name>
              <IconButton
                onClick={() => onExpandedChanged(cell, cell.row.id)}
                disableRipple
              >
                <H6Strong data-testid="name-cell">{cell.value}</H6Strong>
              </IconButton>
              {!cell.row.isExpanded ? (
                <IconButton
                  onClick={() => onExpandedChanged(cell, cell.row.id)}
                  disableRipple
                >
                  <StyledChevronRightIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => onExpandedChanged(cell, cell.row.id)}
                  disableRipple
                >
                  <StyledExpandMoreIcon />
                </IconButton>
              )}
            </Name>
          )
        },
      },
      {
        Header: 'Email',
        title: 'Email',
        accessor: 'email',
        id: 'email',
        sort: {
          kind: 'alphabetical',
        },
        width: 200,
        maxWidth: 200,
        minWidth: 200,
      },
      {
        Header: 'Firm',
        accessor: 'firmName',
        width: 130,
        maxWidth: 130,
        minWidth: 130,
        id: 'firmName',
        title: 'Firm',
        sort: {
          kind: 'alphabetical',
        },
        Cell: (cell: HomeOfficeTableCell) => {
          return <BodySmall>{cell.value}</BodySmall>
        },
      },
      {
        Header: 'Team',
        title: 'Team',
        accessor: 'teams',
        id: 'teams',
        disableSortBy: true,
        width: 200,
        maxWidth: 200,
        minWidth: 200,
        Cell: (cell: HomeOfficeTableCell) => {
          return <BodySmall>{cell.value}</BodySmall>
        },
      },
      {
        Header: 'Last studied',
        accessor: 'lastStudied',
        id: 'lastStudied',
        title: 'Last studied',
        sort: { kind: 'date' },
        minWidth: 130,
        width: 130,
        maxWidth: 130,
        Cell: (cell: HomeOfficeTableCell) => {
          return (
            <BodySmall>
              {getDurationString(cell.value as unknown as number)}
            </BodySmall>
          )
        },
      },
      {
        Header: 'Last course completed',
        accessor: 'lastCourseCompletionDate',
        title: 'Last course completed',
        id: 'lastCourseCompletionDate',
        sort: { kind: 'date' },
        width: 130,
        minWidth: 130,
        maxWidth: 130,
        Cell: (cell: HomeOfficeTableCell) => {
          return <BodySmall>{cell.value}</BodySmall>
        },
      },
    ],
    []
  )

  const tabletColumns = useMemo(() => {
    if (isTablet) {
      return [
        {
          disableSortBy: false,
          Header: 'Name / Email',
          accessor: 'name',
          id: 'name/email',
          title: 'Name / Email',
          sort: {
            kind: 'alphabetical',
          },
          minWidth: 115,
          width: 115,
          maxWidth: 115,
          Cell: (cell: HomeOfficeTableCell) => {
            const name = cell.value
            const email = cell?.row?.original['email']

            cell.row.getToggleRowExpandedProps()
            cell.row.canExpand = true

            return (
              <>
                <Name>
                  <IconButton
                    onClick={() => onExpandedChanged(cell, cell.row.id)}
                    disableRipple
                  >
                    <H6Strong data-testid="name-cell">{name}</H6Strong>
                  </IconButton>
                  {!cell.row.isExpanded ? (
                    <IconButton
                      onClick={() => onExpandedChanged(cell, cell.row.id)}
                      disableRipple
                    >
                      <StyledChevronRightIcon fill="rgb(var(--colors-primary-600))" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => onExpandedChanged(cell, cell.row.id)}
                      disableRipple
                    >
                      <StyledExpandMoreIcon fill="rgb(var(--colors-primary-600))" />
                    </IconButton>
                  )}
                </Name>
                <IconButton
                  onClick={() => onExpandedChanged(cell, cell.row.id)}
                  disableRipple
                >
                  <BodySmall>
                    <MailTo href={`mailto:${email}`}>
                      {email as unknown as string}
                    </MailTo>
                  </BodySmall>
                </IconButton>
              </>
            )
          },
        },
        {
          Header: 'Firm / Team',
          accessor: 'firmName',
          id: 'firmName',
          title: 'Firm / Team',
          sort: {
            kind: 'alphabetical',
          },
          width: 110,
          minWidth: 110,
          maxWidth: 110,
          Cell: (cell: HomeOfficeTableCell) => {
            const firm = cell.value
            const teams = cell?.row?.original['teams']
            return (
              <StyledFirmTeamsContainer>
                <Label data-testid="firmName-cell">{firm}</Label>
                <BodySmall>{teams as unknown as string}</BodySmall>
              </StyledFirmTeamsContainer>
            )
          },
        },
        {
          Header: 'Last studied',
          accessor: 'lastStudied',
          title: 'Last studied',
          id: 'lastStudied',
          sort: {
            kind: 'date',
          },
          minWidth: 110,
          width: 110,
          maxWidth: 110,
          Cell: (cell: HomeOfficeTableCell) => {
            return (
              <StyledBodySmall>
                {getDurationString(cell.value as unknown as number)}
              </StyledBodySmall>
            )
          },
        },
        {
          Header: 'Last course completed',
          accessor: 'lastCourseCompletionDate',
          title: 'Last course completed',
          id: 'lastCourseCompletionDate',
          sort: { kind: 'date' },
          width: 130,
          minWidth: 130,
          maxWidth: 130,
          Cell: (cell: HomeOfficeTableCell) => {
            return <BodySmall>{cell.value}</BodySmall>
          },
        },
      ]
    }
    return
  }, [isTablet])

  const mobileColumns = useMemo(() => {
    if (isMobile) {
      return [
        {
          disableSortBy: false,
          Header: 'Name / Email',
          accessor: 'name',
          id: 'name/email',
          title: 'Name / Email',
          sort: {
            kind: 'alphabetical',
          },
          Cell: (cell: HomeOfficeTableCell) => {
            const email = cell?.row?.original['email']

            cell.row.canExpand = true

            return (
              <>
                <Name>
                  <IconButton
                    onClick={() => onExpandedChanged(cell, cell.row.id)}
                    disableRipple
                  >
                    <H6Strong data-testid="name-cell">{cell?.value}</H6Strong>
                  </IconButton>
                  {!cell.row.isExpanded ? (
                    <IconButton
                      onClick={() => onExpandedChanged(cell, cell.row.id)}
                      disableRipple
                    >
                      <StyledChevronRightIcon fill="rgb(var(--colors-primary-600))" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => onExpandedChanged(cell, cell.row.id)}
                      disableRipple
                    >
                      <StyledExpandMoreIcon fill="rgb(var(--colors-primary-600))" />
                    </IconButton>
                  )}
                </Name>
                <IconButton
                  onClick={() => onExpandedChanged(cell, cell.row.id)}
                  disableRipple
                >
                  <Email style={{ textAlign: 'left' }}>
                    {email as unknown as string}
                  </Email>
                </IconButton>
              </>
            )
          },
          width: 144,
          maxWidth: 232,
          minWidth: 144,
        },
        {
          Header: 'Firm / Teams',
          accessor: 'firmName',
          id: 'firmName',
          title: 'Firm / Teams',
          sort: {
            kind: 'alphabetical',
          },
          minWidth: 96,
          width: 120,
          maxWidth: 144,
          Cell: (cell: HomeOfficeTableCell) => {
            const firm = cell.value
            const teams = cell?.row?.original['teams']
            return (
              <div>
                <Label data-testid="firmName-cell">{firm}</Label>
                <BodySmall>{teams as unknown as string}</BodySmall>
              </div>
            )
          },
        },
      ]
    }
    return
  }, [isMobile])

  let columns:
    | {
        Header: string
        accessor: string
        width: number
        maxWidth: number
        minWidth: number
        disableSortBy?: boolean
        Cell?: (cell: HomeOfficeTableCell) => JSX.Element
      }[]
    | undefined

  if (isMobile) {
    columns = mobileColumns
  } else if (isTablet) {
    columns = tabletColumns
  } else {
    columns = desktopColumns
  }

  const tableColumns = useMemo(
    () =>
      isLoading || (skeletonLoadingState && isPreviousData)
        ? columns?.map((column) => ({
            ...column,
            Cell: (
              <Skeleton
                data-testid="table-skeleton"
                variant="rectangular"
                animation="wave"
                height="var(--s24)"
              />
            ),
          }))
        : columns,
    [isLoading, skeletonLoadingState, isPreviousData, columns]
  )

  const tableData = useMemo(
    () =>
      isLoading || (skeletonLoadingState && isPreviousData)
        ? Array(20).fill({})
        : learnersData,
    [isLoading, isPreviousData, learnersData, skeletonLoadingState]
  )

  return {
    learnersData: tableData,
    learnersColumns: tableColumns,
  }
}
