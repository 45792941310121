import { css } from '@emotion/css'
import cx from 'classnames'
import { useLocation } from 'react-router-dom'

import {
  richHeroHeader,
  RichText,
} from '@cais-group/caisiq/ui/get-rich-text-render-options'
import { NavigationProps } from '@cais-group/caisiq/ui/navigation'
import { VideoHeaderContainer } from '@cais-group/caisiq/ui/video/header'
import { Body } from '@cais-group/shared/util/type/body'
import { User } from '@cais-group/shared/util/type/caisiq-be'
import { ExperienceData } from '@cais-group/shared/util/type/experience-data'

export type HeroHeaderProps = Omit<NavigationProps, 'headerThemeName'> & {
  backgroundImageUrl?: string
  large?: boolean
  alignToTopOfScreen?: boolean
  heroHeaderContent: {
    body: Body
  }
  isHomeofficeDashboard: boolean
}

const FullScreenGradientCSS = css`
  @media screen and (max-width: 480px) {
    background: linear-gradient(
      to right,
      rgb(var(--colors-neutral-900)) 0%,
      rgb(var(--colors-neutral-900)) 50%,
      rgba(33, 28, 33, 0) 82%,
      rgba(33, 28, 33, 0) 100%
    );
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    background: linear-gradient(
      to right,
      rgb(var(--colors-neutral-900)) 0%,
      rgb(var(--colors-neutral-900)) 40%,
      rgba(33, 28, 33, 0) 75%,
      rgba(33, 28, 33, 0) 100%
    );
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    background: linear-gradient(
      to right,
      rgb(var(--colors-neutral-900)) 0%,
      rgb(var(--colors-neutral-900)) 52%,
      rgba(33, 28, 33, 0) 80%,
      rgba(33, 28, 33, 0) 100%
    );
  }
  @media screen and (min-width: 1025px) and (max-width: 1400px) {
    background: linear-gradient(
      to right,
      rgb(var(--colors-neutral-900)) 0%,
      rgb(var(--colors-neutral-900)) 50%,
      rgba(33, 28, 33, 0) 75%,
      rgba(33, 28, 33, 0) 100%
    );
  }

  @media screen and (min-width: 1401px) and (max-width: 1800px) {
    background: linear-gradient(
      to right,
      rgb(var(--colors-neutral-900)) 0%,
      rgb(var(--colors-neutral-900)) 75%,
      rgba(33, 28, 33, 0) 85%
    );
  }
  @media screen and (min-width: 1801px) {
    background: linear-gradient(
      to right,
      rgb(var(--colors-neutral-900)) 0%,
      rgb(var(--colors-neutral-900)) 71%,
      rgba(33, 28, 33, 0) 80%,
      rgba(33, 28, 33, 0) 100%
    );
  }
`

const SEGMENT_PAGE_ID = 'header'

const fallbackHeaderImage =
  'https://images.ctfassets.net/matahbe7799j/2chqGPkR37yZhXPVNZmj2N/f09c9f9d59302ec7445ec1718d52d498/default_hero__3_.jpg?fm=jpg'

const defaultTitleClasses = 'md:pl-56 pl-32 text-neutral-0 headline-l-strong'
const heroHeaderClasses = {
  common:
    'absolute bottom-0 right-0 top-0 z-[-1] bg-no-repeat sm:bg-cover md:left-[35%] lg:left-[50%] xl:left-0 xl:bg-contain xl:bg-right',
  aligned:
    '[@media_screen_(max-width:887px)]:left-[-60%] [@media_screen_(max-width:887px)]:bg-cover [@media_screen_(max-width:887px)]:[background-position:revert]',
  notAligned:
    'max-md:left-[-60%] max-md:bg-cover max-md:[background-position:revert]',
  largeBgPosition: 'md:bg-right',
  defaultBgPosition: 'md:[background-position:revert]',
}
const gradientClasses = `${FullScreenGradientCSS} absolute inset-0 z-[-1] overflow-hidden`

const HeaderTitle = ({
  large,
  isHomeofficeDashboard,
  body,
}: {
  large: boolean
  isHomeofficeDashboard: boolean
  body: Body
}) => {
  const hasHeroHeader = Boolean(body?.json)
  const heroHeader = hasHeroHeader && (
    <RichText
      field={body}
      renderNode={richHeroHeader}
      segmentPageId={SEGMENT_PAGE_ID}
      overrides={{
        h1: {
          props: {
            className: 'headline-l-strong text-wrap',
          },
        },
        h6: {
          props: {
            className: 'body-strong text-wrap',
          },
        },
      }}
    />
  )

  if (isHomeofficeDashboard) {
    return (
      <p
        className={cx(`${defaultTitleClasses}`, {
          'pt-144 pb-232': large,
          'pb-32 pt-32': !large,
        })}
        data-testid="custom-title"
      >
        {' '}
        Learner Progress
      </p>
    )
  }

  if (hasHeroHeader) {
    return (
      <div
        // TODO - not sure why h3Serif className is not working so had to use all the styles individually
        className={cx(
          'text-neutral-0 [&>.rich-hero-header-p:nth-of-type(2)]:border-warning-600 flex basis-full flex-wrap whitespace-pre pl-32 md:basis-3/5 md:pl-56 [&>.rich-hero-header-p:nth-of-type(2)]:inline-block [&>.rich-hero-header-p:nth-of-type(2)]:border-b-2 [&>.rich-hero-header-p:nth-of-type(2)]:font-serif [&>.rich-hero-header-p:nth-of-type(2)]:font-normal',
          {
            'pt-144 pb-232': large,
            'pb-32 pt-32': !large,
          }
        )}
        data-testid="custom-title"
      >
        {heroHeader}
      </div>
    )
  }

  return (
    <p
      className={cx(`${defaultTitleClasses}`, {
        'pt-144 pb-232': large,
        'pb-32 pt-32': !large,
      })}
      data-testid="custom-title"
    >
      {' '}
      Knowledge is{' '}
      <span className="headline-l-strong border-warning-600 border-b-2 border-solid pb-8 font-serif font-normal text-inherit">
        power.
      </span>
    </p>
  )
}

export const HeroHeader = (props: HeroHeaderProps) => {
  const {
    backgroundImageUrl,
    large = false,
    alignToTopOfScreen = false,
    heroHeaderContent,
    isHomeofficeDashboard,
  } = props

  return (
    <div
      className={`${FullScreenGradientCSS} relative w-full min-w-full overflow-hidden`}
    >
      {alignToTopOfScreen && (
        <>
          <div
            style={{ backgroundImage: `url(${backgroundImageUrl})` }}
            className={cx(`${heroHeaderClasses.common}`, {
              [heroHeaderClasses.aligned]: alignToTopOfScreen,
              [heroHeaderClasses.notAligned]: !alignToTopOfScreen,

              [heroHeaderClasses.largeBgPosition]: large,
              [heroHeaderClasses.defaultBgPosition]: !large,
            })}
          />
          <div className={gradientClasses} />
        </>
      )}
      <div className="relative flex items-center py-32">
        {!alignToTopOfScreen && (
          <>
            <div
              style={{ backgroundImage: `url(${backgroundImageUrl})` }}
              className={cx(`${heroHeaderClasses.common}`, {
                [heroHeaderClasses.aligned]: alignToTopOfScreen,
                [heroHeaderClasses.notAligned]: !alignToTopOfScreen,

                [heroHeaderClasses.largeBgPosition]: large,
                [heroHeaderClasses.defaultBgPosition]: !large,
              })}
            />

            <div className={gradientClasses} />
          </>
        )}

        <HeaderTitle
          large={large}
          body={heroHeaderContent?.body}
          isHomeofficeDashboard={isHomeofficeDashboard}
        />
      </div>
    </div>
  )
}

export type ExperienceHeaderProps = {
  userProfile?: User
  currentExperience?: string
  contentfulExperience: ExperienceData
}
export const ExperienceHeader = (props: ExperienceHeaderProps) => {
  const { userProfile, currentExperience, contentfulExperience } = props
  const { pathname } = useLocation()
  const isHomeofficeDashboard = pathname.endsWith('homeoffice')
  if (!userProfile || !currentExperience) return null

  if (contentfulExperience.headerType === 'Video Playlist Feature') {
    if (!contentfulExperience.heroVideoPlaylist) {
      console.error('`Video Feature` hero selected, but no playlist set')
      return null
    }
    return (
      contentfulExperience.heroVideoPlaylist && (
        <VideoHeaderContainer
          userProfile={userProfile}
          playlistId={contentfulExperience.heroVideoPlaylist.sys.id}
          backgroundImageUrl={
            contentfulExperience.headerImage?.url
              ? `${contentfulExperience.headerImage.url}?fm=jpg`
              : ''
          }
        />
      )
    )
  }

  if (
    contentfulExperience.headerType === 'Short Hero Image' ||
    contentfulExperience.headerType === 'Tall Hero Image'
  ) {
    return (
      <HeroHeader
        backgroundImageUrl={
          contentfulExperience.headerImage?.url
            ? `${contentfulExperience.headerImage.url}?fm=jpg`
            : fallbackHeaderImage
        }
        large={contentfulExperience.headerType === 'Tall Hero Image'}
        // TODO: remove this prop once it's confirmed that LoginError is not actually being used(it's the last route using the old navigation)
        alignToTopOfScreen={
          contentfulExperience.theme.navHeaderWithHero.backgroundColor ===
          'transparent'
        }
        heroHeaderContent={contentfulExperience.heroHeaderContent}
        isHomeofficeDashboard={isHomeofficeDashboard}
      />
    )
  } else {
    return null
  }
}
