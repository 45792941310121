import styled from '@emotion/styled'
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  OutlinedTextFieldProps,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectProps } from '@mui/material/Select'
import { ReactNode, useId } from 'react'

import { Body, BodyCss } from '@cais-group/approved/ui/typography'
import { Button } from '@cais-group/equity/atoms/button'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { TextInputLabel } from '@cais-group/shared/ui/input/label'

const StyledErrorText = styled.span`
  color: rgb(var(--colors-error-600));
`
const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
`

type StyledSelectBoxProps = {
  $renderBlueVariant?: boolean
}
const FormAppearanceSelectBox = styled(Select, {
  shouldForwardProp: (prop) => {
    return prop !== '$renderBlueVariant' && prop !== 'size'
  },
})<StyledSelectBoxProps>`
  height: ${({ size }) => (size === 'small' ? '40px' : '48px')};
  background-color: ${({ $renderBlueVariant, disabled }) =>
    disabled
      ? 'rgb(var(--colors-neutral-100))'
      : $renderBlueVariant && 'rgb(var(--colors-primary-100))'};
  .MuiOutlinedInput-notchedOutline,
  & > div {
    padding: var(--s12) var(--s16);
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-radius: 0;
    border: ${({ $renderBlueVariant }) =>
      $renderBlueVariant
        ? '1px solid rgb(var(--colors-primary-300))'
        : '1px solid rgb(var(--colors-neutral-200))'};
  }
  em {
    ${BodyCss};
    color: rgb(var(--colors-neutral-600));
  }
`

// This was utterly maddening to get right; somehow getting non-border radius box; eventually found `overflow: hidden;` seemed to sort out; overriding mui styling for stuff like this is incredibly frustrating.
const SelectionAppearanceSelectBox = styled(Select, {
  shouldForwardProp: (prop) => {
    return prop !== '$renderBlueVariant' && prop !== 'size'
  },
})<StyledSelectBoxProps>`
  height: ${({ size }) => (size === 'small' ? '40px' : '48px')};
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: rgb(var(--colors-primary-100));
  .MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline {
    border-radius: 20px;
    border: 1px solid rgb(var(--colors-neutral-200));
  }

  &:focus {
    border-radius: 20px;
    border: 1px solid rgb(var(--colors-neutral-200));
  }
  em {
    ${BodyCss};
    color: rgb(var(--colors-neutral-600));
  }
`

const SelectAppearanceInput = styled(InputBase)(() => ({
  '&': {
    backgroundColor: 'transparent',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  '& .MuiInputBase-input': {
    borderRadius: 20,
    height: 40,
    backgroundColor: 'transparent',
    border: '1.5px solid var(--color-gray-7)',
    padding: 'var(--s8) var(--s56) var(--s8) var(--s24)',
    overflow: 'hidden',
  },
  '& .MuiInputBase-input:focus': {
    borderRadius: 20,
    backgroundColor: 'transparent',
  },
  '&:focus': {
    borderRadius: 20,
    overflow: 'hidden',
  },
}))

const StyledFormHelperText = styled(FormHelperText)`
  // This make sure the error message is included in the dom before the field.
  // This gives a better experience for screen reader users
  order: 3;
`
const StyledDivider = styled(Divider)`
  margin: var(--s32) var(--s24) var(--s16) var(--s16);
`
export type SelectOption<Item extends string = string> = {
  label: string
  value: Item
  bottomButton?: ReactNode
  testId?: string
}

export type SelectBoxProps<Item extends string = string> = SelectProps & {
  id: string
  label?: string | Omit<OutlinedTextFieldProps, 'variant'>
  errorMessage?: string
  description?: string
  placeholder?: string
  selectOptions: SelectOption<Item>[]
  extensibleSelectOptions?: { text: string; handler: () => void }
  className?: string
  $renderBlueVariant?: boolean
  menuAppearance?: 'form' | 'selection'
  boxAppearance?: 'form' | 'selection'
  hideSelectPrompt?: boolean
  valuePreviewPrefix?: string
  renderOptions?: (option: SelectOption) => ReactNode
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

export function SelectBox<Item extends string = string>(
  props: SelectBoxProps<Item>
) {
  const {
    id,
    label,
    required,
    errorMessage,
    description,
    placeholder = 'Select',
    selectOptions,
    extensibleSelectOptions,
    size,
    className,
    value,
    onChange,
    onBlur,
    onFocus,
    disabled,
    multiple,
    $renderBlueVariant,
    boxAppearance = 'form',
    menuAppearance,
    hideSelectPrompt = false,
    valuePreviewPrefix,
    renderValue,
    renderOptions,
  } = props

  const SelectBoxComponent = {
    form: FormAppearanceSelectBox,
    selection: SelectionAppearanceSelectBox,
  }[boxAppearance]

  const input = {
    selection: <SelectAppearanceInput />,
    form: undefined,
  }[boxAppearance]

  const MenuProps = {
    selection: {
      PaperProps: {
        style: {
          boxShadow: '0px var(--s16) var(--s24) rgba(0, 0, 0, 0.08)',
          paddingRight: 'var(--s24)',
          paddingLeft: 'var(--s24)',
          paddingTop: 'var(--s8)',
          paddingBottom: 'var(--s8)',
        },
      },
    },
    form: {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          boxShadow: '0px var(--s16) var(--s24) rgba(0, 0, 0, 0.08)',
        },
      },
    },
  }[menuAppearance ?? boxAppearance]

  const menuItemStyle = {
    selection: { height: 'calc(var(--s32) + var(--s8))' },
    form: undefined,
  }[menuAppearance ?? boxAppearance]

  return (
    <StyledInput className={className}>
      {label && (
        <TextInputLabel
          htmlFor={id}
          label={label}
          description={description}
          required={required}
        />
      )}
      {errorMessage && (
        <StyledFormHelperText>
          <StyledErrorText data-testid={`select-box-error-${id}`}>
            {errorMessage}
          </StyledErrorText>
        </StyledFormHelperText>
      )}
      <FormControl error={!!errorMessage}>
        {multiple ? (
          <SelectBoxComponent
            placeholder={placeholder}
            id={id}
            value={value || []}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            data-testid={id}
            size={size}
            error={!!errorMessage}
            multiple
            MenuProps={MenuProps}
            fullWidth={boxAppearance === 'form'}
            displayEmpty
            $renderBlueVariant={$renderBlueVariant}
            disabled={disabled}
            renderValue={(selected: unknown) => {
              return renderValue
                ? renderValue({ selected })
                : ((selected as string[]) || [])
                    ?.map((value) => {
                      const matchingOption = selectOptions.find(
                        (option) => option.value === value
                      )
                      return matchingOption?.label || ''
                    })
                    .join(', ')
            }}
          >
            {selectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  checked={
                    ((value as string[]) || []).indexOf(option.value) >= 0
                  }
                />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
            {extensibleSelectOptions && (
              <div>
                <StyledDivider data-testid="add-new-option-divider" />
                <div className="m-16">
                  <Button
                    variant="tertiary"
                    data-testid="add-new-option"
                    onClick={extensibleSelectOptions.handler}
                  >
                    {extensibleSelectOptions.text}
                  </Button>
                </div>
              </div>
            )}
          </SelectBoxComponent>
        ) : (
          <SelectBoxComponent
            placeholder={placeholder}
            id={id}
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            data-testid={id}
            size={size}
            error={!!errorMessage}
            MenuProps={MenuProps}
            fullWidth
            displayEmpty
            $renderBlueVariant={$renderBlueVariant}
            disabled={disabled}
            renderValue={(value) => {
              const item = selectOptions.find(
                (option) => value && option.value === value
              )

              if (renderValue) {
                return renderValue({ item })
              }

              const { label: itemLabel } = item || {}

              if (itemLabel) {
                return valuePreviewPrefix ? (
                  <Body>
                    <span>{valuePreviewPrefix}</span> {itemLabel}
                  </Body>
                ) : (
                  <span>{itemLabel}</span>
                )
              } else {
                return <em className="not-italic">{placeholder}</em>
              }
            }}
            input={input}
          >
            {!hideSelectPrompt && (
              <MenuItem value="">
                <em className="not-italic">Select</em>
              </MenuItem>
            )}
            {selectOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={menuItemStyle}
                data-testid={option.testId}
              >
                {renderOptions ? renderOptions(option) : option.label}
              </MenuItem>
            ))}
            {extensibleSelectOptions && (
              <div>
                <StyledDivider data-testid="add-new-option-divider" />
                <div className="m-16">
                  <Button
                    variant="tertiary"
                    data-testid="add-new-option"
                    onClick={extensibleSelectOptions.handler}
                  >
                    {extensibleSelectOptions.text}
                  </Button>
                </div>
              </div>
            )}
          </SelectBoxComponent>
        )}
      </FormControl>
    </StyledInput>
  )
}

type SelectionBoxProps<Item extends string> = Omit<
  SelectBoxProps<Item>,
  | 'placeholder'
  | 'boxAppearance'
  | 'multiple'
  | 'label'
  | 'errorMessage'
  | 'disabled'
  | 'extensibleSelectOptions'
  | 'required'
  | '$renderBlueVariant'
  | 'hideSelectPrompt'
  | 'valuePreviewPrefix'
  | 'size'
  | 'onChange'
  | 'id'
> & { prefix: string; onChange: (value: Item) => void; width?: number | string }

/**
 * Like a SelectBox but for making a specific selection from a set of items
 * Stronger typing, more compact appearance, simplified API
 *
 * @deprecated see TransitionalEquitySelectionBoxEquitySelectionBox for an attempt to embrace Equity approaches (attempts to make fully generic Equity version previously didn't work out)
 */
export function SelectionBox<Item extends string>({
  prefix,
  onChange,
  onBlur,
  width = SPACING.s232,
  ...props
}: SelectionBoxProps<Item>) {
  const id = useId()
  return (
    <Box width={width}>
      <SelectBox
        {...props}
        id={id}
        onBlur={onBlur}
        onChange={(event) => onChange(event.target.value as Item)}
        boxAppearance="selection"
        hideSelectPrompt
        valuePreviewPrefix={prefix}
      />
    </Box>
  )
}

/**
 * NB this is quick build of something we needed in PM (admin only area); will raise
 * properly with equity team as seems like gap in current components
 *
 * Choose true/false with custom labels (default: "Yes"/"No")
 */
export function BooleanSelectionBox({
  value,
  onChange,
  onBlur,
  trueLabel = 'Yes',
  falseLabel = 'No',
  label,
  legacyMenuAppearance = false,
}: {
  value: boolean
  onChange: (value: boolean) => void
  onBlur?: () => void
  trueLabel?: string
  falseLabel?: string
  label?: string
  legacyMenuAppearance?: boolean
}) {
  const id = useId()

  const selectOptions = [
    { label: trueLabel, value: 'true' },
    { label: falseLabel, value: 'false' },
  ]

  return (
    <div className="mt-32 flex flex-col gap-4">
      <label htmlFor={id} className="small">
        {label}
      </label>
      <SelectBox
        id={id}
        onBlur={onBlur}
        value={String(value)}
        onChange={(event) => {
          const raw = event.target.value

          switch (raw) {
            case 'true':
              onChange(true)
              break
            case 'false':
              onChange(false)
              break
            default:
              console.warn(
                "Invalid option selected; this shouldn't be possible"
              )
          }
        }}
        boxAppearance="form"
        hideSelectPrompt
        selectOptions={selectOptions}
        // allow for new appearance (though actually for current use don't want this)
        menuAppearance={legacyMenuAppearance ? undefined : 'selection'}
        // TODO TS complained without this; don't understand why as optional
        variant="outlined"
      />
    </div>
  )
}
