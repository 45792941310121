import { useRecoilState } from 'recoil'

import { useFilterCoursesBySearch } from '@cais-group/caisiq/domain/course'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'

import { Courses } from './caisiq-feature-courses'

export const CoursesContainer = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)

  const searchFilteredCourses = useFilterCoursesBySearch()

  return (
    <Courses
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      searchFilteredCourses={searchFilteredCourses}
    />
  )
}
