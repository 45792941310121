import { useFormContext } from 'react-hook-form'

import { FieldSurround } from '../../components/field-surround'
import { UniformFieldProps } from '../../types'

import { ContactSummaryTable } from './contact-summary'
import { useContactAccountData } from './use-contact-account-summary'

export const ContactAccountSummary = <
  TFormFieldIds extends keyof any = any // eslint-disable-line @typescript-eslint/no-explicit-any
>(
  field: UniformFieldProps<'ContactAccountSummary'> & {
    id: TFormFieldIds
  }
) => {
  const { getValues } = useFormContext()
  const fieldId = field.id
  const values = getValues()
  const contactId = values['id']
  const contactAccountFetcher = useContactAccountData(contactId)
  return (
    <FieldSurround
      fieldId={fieldId}
      cols={field.cols}
      testId={`${field.type}__${fieldId}`}
    >
      {contactAccountFetcher.isLoading ? (
        <div>Loading...</div>
      ) : (
        <ContactSummaryTable
          contactAccounts={contactAccountFetcher.data?.contactAccounts}
        />
      )}
    </FieldSurround>
  )
}
