import styled from '@emotion/styled'
import DownloadIcon from '@mui/icons-material/Download'
import { Stack } from '@mui/material'
import { ReactElement } from 'react'

import { H5Strong, SubtitleMono } from '@cais-group/approved/ui/typography'

const DownloadCardContainer = styled(Stack)`
  background-color: rgb(var(--colors-neutral-0));
  box-shadow: 0px 4px 13px rgb(var(--colors-neutral-200));
  &:hover {
    box-shadow: 0px 4px 24px rgb(var(--colors-neutral-300));
    cursor: pointer;
  }
`

const Image = styled.div<{ url: string }>`
  background-image: ${(props) => `url(${props.url});`};
  background-size: cover;
  min-width: 10.5rem;
  height: 10.5rem;
`

const DownloadIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--s56);
  height: var(--s56);
  background-color: rgb(var(--colors-primary-600));
`

const Title = styled(H5Strong)`
  color: rgb(var(--colors-neutral-900));
`
const LastUpdatedDate = styled(SubtitleMono)`
  color: rgb(var(--colors-neutral-500));
`

export type CardDownloadProps = {
  file: string
  image: string | ReactElement
  title: string
  date?: string
  onClick?: () => void
}
export const CardDownload = ({
  file,
  image,
  title,
  date,
  onClick = () => {},
}: CardDownloadProps) => (
  <DownloadCardContainer
    data-testid="downloadCardContainer"
    // @ts-ignore
    as="a"
    href={file}
    onClick={() => {
      onClick()
    }}
    target="_blank"
    flexDirection="row"
    justify="flex-start"
  >
    {typeof image === 'string' ? (
      <Image url={image} data-testid="downloadCardImage" />
    ) : (
      image
    )}
    <Stack
      justifyContent="space-between"
      flexDirection="row"
      alignSelf="center"
      alignItems="center"
      width="100%"
      margin="0 var(--s32)"
    >
      <Stack>
        <Title data-testid="downloadCardTitle">{title}</Title>
        {date ? (
          <LastUpdatedDate data-testid="downloadCardDate">
            Last updated {date}
          </LastUpdatedDate>
        ) : null}
      </Stack>
      <DownloadIconContainer>
        <DownloadIcon sx={{ color: 'rgb(var(--colors-neutral-0))' }} />
      </DownloadIconContainer>
    </Stack>
  </DownloadCardContainer>
)
