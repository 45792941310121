import { AxiosError } from 'axios'
import { Dispatch, SetStateAction } from 'react'
import {
  FieldValues,
  SubmitHandler,
  useFormContext,
  UseFormSetError,
} from 'react-hook-form'
import { useRecoilState } from 'recoil'

import { BreadcrumbItem } from '@cais-group/approved/ui/breadcrumbs'
import { H4Strong } from '@cais-group/approved/ui/typography'
import { modalStateAtom } from '@cais-group/caisiq/domain/common'
import { ModalType } from '@cais-group/caisiq/util/type/manage-caisiq'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Button } from '@cais-group/equity/atoms/button'
import { NavBar } from '@cais-group/shared/ui/nav-bar'
import { ErrorResponse, UserRole } from '@cais-group/shared/util/type/caisiq-be'

export interface ManageNavbarHeaderProps<TFields extends FieldValues> {
  pageName: string
  submitLabel: string
  onSubmit: SubmitHandler<TFields>
  handleFormError?(
    e: AxiosError<ErrorResponse>,
    setError: UseFormSetError<TFields>
  ): void
  onOpen?: Dispatch<SetStateAction<ModalType | undefined>>
  open?: boolean
  mutateLoading?: boolean
}

export function ManageNavbarHeader<TFields extends FieldValues>({
  submitLabel = 'Save',
  pageName,
  onSubmit,
  handleFormError,
  onOpen,
  open,
  mutateLoading = false,
}: ManageNavbarHeaderProps<TFields>) {
  const { handleSubmit, setError, formState } = useFormContext<TFields>()
  const [, setModalState] = useRecoilState(modalStateAtom)

  const hasFullAccess = userSettingsService.hasRole(UserRole.CaisiqManageWrite)

  const showModal = !open && onOpen && formState.isValid
  return (
    <NavBar
      breadcrumbs={createBreadcrumbs(pageName)}
      information={<H4Strong>{pageName}</H4Strong>}
      actions={
        hasFullAccess
          ? [
              <Button
                variant="secondary"
                type="button"
                onClick={() => setModalState(true)}
                key="cancel"
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                onClick={
                  showModal
                    ? () => onOpen('create')
                    : () => {
                        handleSubmit(onSubmit)().catch((e) => {
                          handleFormError && handleFormError(e, setError)
                        })
                      }
                }
                loading={mutateLoading}
              >
                {submitLabel}
              </Button>,
            ]
          : undefined
      }
    />
  )
}

function createBreadcrumbs(pageName: string): BreadcrumbItem[] {
  return [
    { label: 'Manage CAIS IQ', path: '../' },
    { label: pageName, path: window.location.pathname },
  ]
}

export default ManageNavbarHeader
