import cx from 'classnames'
import { MutableRefObject, useCallback, useRef, useState } from 'react'

import { Pill } from '@cais-group/equity/atoms/pill'
import { ListItem } from '@cais-group/equity/molecules/list-item'
import { useOnClickOutside } from '@cais-group/equity/utils/event'

/**
 * Simple selection pill using equity components and click outside handling
 *
 * Allows us to migrate some SP stuff; likely this either gets moved into equity or swapped out for some other component
 */
export function TransitionalEquitySelectionBox<Item extends string>({
  selectOptions,
  value,
  onChange,
  prefix,
}: {
  selectOptions: {
    label: string
    value: Item
    testId?: string
  }[]
  value: Item
  onChange: (value: Item) => void
  prefix?: string
}) {
  const [menuOpen, setMenuOpen] = useState(false)
  const callback = useCallback(() => {
    setMenuOpen(false)
  }, [setMenuOpen])

  const elRef = useRef<HTMLDivElement>(null)
  useOnClickOutside<HTMLDivElement>({
    callback,
    ref: elRef as MutableRefObject<HTMLDivElement>,
    stopPropagation: true,
    active: menuOpen,
  })

  const currentOption = selectOptions.find((option) => option.value === value)

  return (
    <div className="relative flex" data-testid="quick-views">
      <div ref={elRef}>
        <Pill
          aria-haspopup="menu"
          label={
            <>
              {prefix ? `${prefix}: ` : ''}
              <span className="small">{currentOption?.label ?? ''}</span>
            </>
          }
          type="dropdown"
          testId="quick-views-menu-toggle"
          onClick={(e) => {
            setMenuOpen((open) => !open)
            e.stopPropagation()
          }}
          state={menuOpen ? 'active' : 'regular'}
          color={menuOpen ? 'primary' : 'neutral'}
        />
        <ul
          role="menu"
          className={cx(
            'bg-neutral-0 shadow-3 w-fit-content group absolute right-0 top-[50px] z-30 min-w-full px-24 py-16',
            {
              hidden: !menuOpen,
            }
          )}
        >
          {selectOptions.map(({ value, testId, label }) => (
            <ListItem
              onClick={() => {
                onChange(value)
                setMenuOpen(false)
              }}
              key={value}
              data-testid={testId}
            >
              {label}
            </ListItem>
          ))}
        </ul>
      </div>
    </div>
  )
}
