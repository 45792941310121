import { useParams } from 'react-router-dom'

import { PathReplacementError } from './path-replacement-error'

const urlParamPattern = /:((:?[^/]|$)+)/g // https://regexr.com/766s0

const replacerFactory =
  (replacements: Record<string, unknown>) => (_: string, p1: string) => {
    if (p1 in replacements === false) {
      throw new PathReplacementError(
        `Unable to replace required variable ${p1} in api path from object ${JSON.stringify(
          replacements
        )}`
      )
    }
    return `${replacements[p1]}`
  }

export function useApiPathWithParams(apiPath: string) {
  const params = useParams()

  return apiPath.replace(urlParamPattern, replacerFactory(params))
}

export function useApiPathWithPayload(apiPath: string) {
  return (payload: Record<string, unknown>) =>
    apiPath.replace(urlParamPattern, replacerFactory(payload))
}

export default useApiPathWithParams
