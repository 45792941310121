import { useId } from 'react'
import { useLocation } from 'react-router-dom'

import {
  MuxVideoPlayer,
  MuxVideoPlayerProps,
} from '@cais-group/shared/ui/video/mux-video-player'
import { millisecondsToSeconds } from '@cais-group/shared/util/js/format-length'
import { TypeVideoData } from '@cais-group/shared/util/type/video-data'

interface PlayerProps extends Omit<MuxVideoPlayerProps, 'metadata'> {
  video?: TypeVideoData
  userId: string
}

export const Player = ({ video, userId, ...rest }: PlayerProps) => {
  const watchId = useId()
  const location = useLocation()
  const videoSource = video?.muxVideo
    ? { playbackId: video.muxVideo.playbackId }
    : { src: video?.video?.url }

  const durationInSeconds = video?.muxVideo?.duration
    ? video?.muxVideo.duration
    : millisecondsToSeconds(video?.duration ?? 0, 'round')

  const muxVideoPayload = {
    thumbnailTime: video?.muxThumbnailTime,
    poster: video?.poster ? video.poster[0]?.secure_url : null,
  }
  const videoMetadata: MuxVideoPlayerProps['metadata'] = {
    video_id: video?.sys.id || '',
    duration: durationInSeconds,
    video_title: video?.title || 'Title not defined',
    url: location.pathname,
    watchId: watchId,
    viewer_user_id: userId,
  }

  return (
    <MuxVideoPlayer
      data-testid="video-player"
      data-chromatic="ignore"
      preload="metadata"
      autoPlay={false}
      {...videoSource}
      {...muxVideoPayload}
      metadata={videoMetadata}
      {...rest}
    />
  )
}
