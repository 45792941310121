import {
  MultiSelectType,
  Select,
  SelectProps,
  SingleSelectType,
} from '@cais-group/equity/molecules/select'

import { Field, FieldProps } from '../field'

export type SelectFieldProps<T> = SelectProps<T> & FieldProps

export const SelectField = <T,>(props: SelectFieldProps<T>) => {
  const {
    label,
    required,
    supportingText,
    testId,
    helper,
    id,
    tag,
    description,
    isMulti,
    onChange,
    value,
    options,
    ...rest
  } = props

  return (
    <Field
      htmlFor={id}
      tag={tag}
      label={label}
      required={required}
      helper={helper}
      supportingText={supportingText}
      testId={`${testId}-select-field`}
      description={description}
    >
      <Select<T>
        isMulti={isMulti as typeof isMulti extends true ? true : false}
        onChange={
          onChange as typeof isMulti extends true
            ? MultiSelectType<T>['onChange']
            : SingleSelectType<T>['onChange']
        }
        value={
          value as typeof isMulti extends true
            ? MultiSelectType<T>['value']
            : SingleSelectType<T>['value']
        }
        options={options}
        testId={testId}
        {...Object.fromEntries(
          Object.entries(rest).filter(
            ([key]) =>
              ![
                'label',
                'required',
                'supportingText',
                'helper',
                'description',
              ].includes(key)
          )
        )}
      />
    </Field>
  )
}
