import { useAuth0 } from '@auth0/auth0-react'
import React, { PropsWithChildren } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { RoleV1 } from '@cais-group/access-manager/domain/api'
import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import { ArticlesContainer } from '@cais-group/caisiq/feature/articles'
import {
  Assignment,
  AssignmentExitPage,
} from '@cais-group/caisiq/feature/assignment'
import { CourseLibraryContainer } from '@cais-group/caisiq/feature/course-library'
import { CoursesContainer } from '@cais-group/caisiq/feature/courses'
import { FundsContainer } from '@cais-group/caisiq/feature/funds'
import { HomeOffice } from '@cais-group/caisiq/feature/home-office'
import { InterstitialsRoutes } from '@cais-group/caisiq/feature/interstitials'
import { ManageCoursesContainer } from '@cais-group/caisiq/feature/manage/courses'
import {
  CreateExperiencePageContainer,
  UpdateExperiencePageContainer,
} from '@cais-group/caisiq/feature/manage/create-update-experience'
import { EditFirmContainer } from '@cais-group/caisiq/feature/manage/edit-firm'
import { EnableCaisiqContainer } from '@cais-group/caisiq/feature/manage/enable-cais-iq-page'
import { ManageExperiencesContainer } from '@cais-group/caisiq/feature/manage/experiences'
import { ManageFirmsContainer } from '@cais-group/caisiq/feature/manage/firms'
import { ProfilePageContainer } from '@cais-group/caisiq/feature/profile/page'
import { VideoPageContainer } from '@cais-group/caisiq/feature/video/page'
import { VideosContainer } from '@cais-group/caisiq/feature/video/videos'
import { WhitepaperPageContainer } from '@cais-group/caisiq/feature/whitepaper/page'
import { WhitepapersContainer } from '@cais-group/caisiq/feature/whitepaper/whitepapers'
import { ArticlePageContainer } from '@cais-group/caisiq/ui/article/page'
import { Dashboard } from '@cais-group/caisiq/ui/dashboard'
import { PreviewBannerContainer } from '@cais-group/caisiq/ui/preview-banner'
import { logoutService } from '@cais-group/caisiq/util/logout-service'
import {
  getUserNavSettings,
  linkToMfe,
} from '@cais-group/caisiq/util/user-nav-settings-service'
import {
  ExtendedUserCapabilities,
  userSettingsService,
} from '@cais-group/caisiq/util/user-settings-service'
import { NotificationsManager } from '@cais-group/equity/organisms/notifications'
import { APPS } from '@cais-group/shared/domain/apps'
import { usePendo } from '@cais-group/shared/ui/app-wrappers'
import { FooterWithDisclaimer } from '@cais-group/shared/ui/footer/with-disclaimer'
import { CaisiqLogo } from '@cais-group/shared/ui/logos'
import { MainNav } from '@cais-group/shared/ui/main-nav'
import { muiTheme } from '@cais-group/shared/ui/mui'
import {
  previewService,
  usePreviewService,
} from '@cais-group/shared/util/contentful/preview-service'
import { LocationTrackingValuesProvider } from '@cais-group/shared/util/segment'
import { ThemeProviders } from '@cais-group/shared/util/theme-providers'
import { TUserRole, UserRole } from '@cais-group/shared/util/type/caisiq-be'

function ProtectedPage({
  children,
  permissions,
  capabilities,
  redirectPath = '/',
}: {
  children: JSX.Element
  redirectPath?: string
  permissions?: Array<TUserRole>
  capabilities?: Array<keyof ExtendedUserCapabilities>
}) {
  return (
    <RequireCapability
      capabilities={capabilities}
      permissions={permissions}
      Fallback={
        <Navigate
          to={redirectPath}
          replace
          state={{ from: window.location.pathname }}
        />
      }
    >
      {children}
    </RequireCapability>
  )
}

interface RequireCapabilityProps {
  permissions?: Array<TUserRole>
  capabilities?: Array<keyof ExtendedUserCapabilities>
  Fallback?: JSX.Element | null
}

function RequireCapability({
  permissions = [],
  capabilities = [],
  children,
  Fallback = null,
}: PropsWithChildren<RequireCapabilityProps>): JSX.Element | null {
  if (
    permissions.every((p) => userSettingsService.hasRole(p)) &&
    capabilities.every((c) => userSettingsService.hasCapability(c))
  ) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }
  return Fallback
}

export const Authenticated = () => {
  // Defined in Contentful on a CAISIQ Firm.
  const disclaimer = userSettingsService.theme.disclaimer
  usePreviewService()

  usePendo(
    userSettingsService.getUserAsProfile(),
    userSettingsService.getUserRolesWithArchetypes() as RoleV1[]
  )

  const isDirectUser =
    userSettingsService.user.personas === undefined ||
    userSettingsService.user.personas.length === 0

  const { logout } = useAuth0()
  const { navItems, profileMenu, logos } = getUserNavSettings((event) => {
    event?.preventDefault()
    logoutService.logout(logout)
  })

  return (
    <ThemeProviders
      secondaryTheme={{
        // TODO - Once it's confirmed, we will no longer use theme from contentful firms, we can probably get rid of this call and just call it in the login service
        ...userSettingsService.generateTheme(),
        ...muiTheme,
      }}
    >
      <HasPermissions capabilities="portal:ROLE_CAISIQ_ADMIN">
        <PreviewBannerContainer key={previewService.enabled ? 'on' : 'off'} />
      </HasPermissions>
      <NotificationsManager />

      <LocationTrackingValuesProvider appName={APPS.CAIS_IQ}>
        <MainNav
          logos={
            isDirectUser
              ? [<CaisiqLogo key="logo" data-testid="caisiq-logo" />]
              : logos
              ? logos
              : undefined
          }
          logoHref={isDirectUser ? '/' : linkToMfe('/homepage')}
          navItems={navItems}
          profile={{
            firstName: userSettingsService.user.firstName || '',
            lastName: userSettingsService.user.lastName || '',
            companyName: userSettingsService.companyName,
            menu: profileMenu,
          }}
        />
        <Routes>
          <Route
            path="ce-credits/*"
            element={
              <ProtectedPage capabilities={['ceCredit']}>
                <FooterWithDisclaimer disclaimer={disclaimer}>
                  <InterstitialsRoutes />
                </FooterWithDisclaimer>
              </ProtectedPage>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedPage capabilities={['ceCredit']}>
                <FooterWithDisclaimer disclaimer={disclaimer}>
                  <ProfilePageContainer />
                </FooterWithDisclaimer>
              </ProtectedPage>
            }
          />
          <Route
            path="guides/:slug"
            element={
              <ProtectedPage capabilities={['ceCredit']}>
                <FooterWithDisclaimer disclaimer={disclaimer}>
                  <ArticlePageContainer />
                </FooterWithDisclaimer>
              </ProtectedPage>
            }
          />
          <Route
            path="homeoffice"
            element={
              <ProtectedPage permissions={[UserRole.CaisiqReporting]}>
                <FooterWithDisclaimer
                  disclaimer={userSettingsService.theme.homeoffice?.disclaimer}
                >
                  <HomeOffice />
                </FooterWithDisclaimer>
              </ProtectedPage>
            }
          />
          <Route path="manage">
            <Route path="courses">
              <Route
                index
                element={
                  <ProtectedPage permissions={[UserRole.CaisiqManageRead]}>
                    <FooterWithDisclaimer disclaimer={disclaimer}>
                      <ManageCoursesContainer />
                    </FooterWithDisclaimer>
                  </ProtectedPage>
                }
              />
            </Route>

            <Route
              path="firms/*"
              element={
                <ProtectedPage permissions={[UserRole.CaisiqManageRead]}>
                  <FooterWithDisclaimer disclaimer={disclaimer}>
                    <Routes>
                      <Route index element={<ManageFirmsContainer />} />
                      <Route
                        path="enable/:firmId"
                        element={
                          <ProtectedPage
                            permissions={[UserRole.CaisiqManageWrite]}
                          >
                            <EnableCaisiqContainer />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="edit/:firmId"
                        element={<EditFirmContainer />}
                      />
                    </Routes>
                  </FooterWithDisclaimer>
                </ProtectedPage>
              }
            />

            <Route
              path="experiences/*"
              element={
                <ProtectedPage permissions={[UserRole.CaisiqManageRead]}>
                  <FooterWithDisclaimer disclaimer={disclaimer}>
                    <Routes>
                      <Route
                        path="create"
                        element={
                          <ProtectedPage
                            permissions={[UserRole.CaisiqManageWrite]}
                          >
                            <CreateExperiencePageContainer />
                          </ProtectedPage>
                        }
                      />
                      <Route
                        path="edit/:experienceId"
                        element={<UpdateExperiencePageContainer />}
                      />
                      <Route index element={<ManageExperiencesContainer />} />
                    </Routes>
                  </FooterWithDisclaimer>
                </ProtectedPage>
              }
            />
          </Route>

          <Route
            path="videos/:playlistId?/:id/"
            element={
              <FooterWithDisclaimer disclaimer={disclaimer}>
                <VideoPageContainer />
              </FooterWithDisclaimer>
            }
          />
          <Route
            path="articles/:slug"
            element={
              <FooterWithDisclaimer disclaimer={disclaimer}>
                <ArticlePageContainer />
              </FooterWithDisclaimer>
            }
          />
          <Route
            path="whitepapers/:slug"
            element={
              <FooterWithDisclaimer disclaimer={disclaimer}>
                <WhitepaperPageContainer />
              </FooterWithDisclaimer>
            }
          />
          <Route path="assignment/:assignmentId" element={<Assignment />} />
          <Route
            path="assignment/:assignmentId/exit"
            element={<AssignmentExitPage />}
          />
          <Route
            path="*"
            element={
              <Dashboard>
                <Routes>
                  <Route path="courses" element={<CoursesContainer />} />
                  <Route path="videos" element={<VideosContainer />} />
                  <Route
                    path="course-library"
                    element={<CourseLibraryContainer />}
                  />
                  <Route path="articles" element={<ArticlesContainer />} />
                  <Route
                    path="whitepapers"
                    element={<WhitepapersContainer />}
                  />
                  <Route path="funds" element={<FundsContainer />} />
                  <Route path="*" />
                </Routes>
              </Dashboard>
            }
          />
        </Routes>
      </LocationTrackingValuesProvider>
    </ThemeProviders>
  )
}
