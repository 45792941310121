import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import LockIcon from '@mui/icons-material/Lock'
import { useCallback, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import {
  CaptionRegular,
  Overline,
  H6Strong,
  H4Strong,
  Body,
  BodyStrong,
} from '@cais-group/approved/ui/typography'
import {
  useGetCourseCeCredits,
  useGetCourses,
} from '@cais-group/caisiq/domain/course'
import {
  usePatchEnrollCourse,
  useGetLibraryCourses,
} from '@cais-group/caisiq/domain/library-course'
import { TrackingId } from '@cais-group/caisiq/domain/segment'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import {
  CourseCallToActionButton,
  CourseProgressState,
  CourseAssessmentTag,
} from '@cais-group/caisiq/ui/card/course'
import { MercerRating } from '@cais-group/caisiq/ui/mercer-rating'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { useConfig } from '@cais-group/caisiq-ui-env'
import { Button, ButtonLink } from '@cais-group/equity/atoms/button'
import { LayoutCluster } from '@cais-group/shared/ui/layout'
import {
  Course,
  FundWithThumbnail,
} from '@cais-group/shared/util/type/caisiq-be'
import { isCourse } from '@cais-group/shared/util/type/course'
import { Experience } from '@cais-group/shared/util/type/experience'
import { isLibraryCourse } from '@cais-group/shared/util/type/library-course'
import { Segment } from '@cais-group/shared/util/type/segment'

export type FundModalProps = {
  fund: FundWithThumbnail
  onSetSelectedCourse: (course: Course) => void
}

const ChildrenContainer = styled.div`
  padding: var(--s56);
  margin-top: 0;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1100px) {
    padding: var(--s24);
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--s24);
`

const InfoContainer = styled.div`
  flex-direction: row;
  display: flex;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`
const ImageContainer = styled.div`
  width: 5.75rem;
  height: 5.75rem;
  overflow: hidden;
  margin-right: var(--s32);
  position: relative;
`
const Image = styled.img`
  width: 5.75rem;
  height: 5.75rem;
`

const Enrolled = styled(BodyStrong)`
  color: rgb(var(--colors-success-600));
  padding-top: var(--s56);
`
const Checked = styled(CheckIcon)`
  fill: rgb(var(--colors-success-600));
  margin-left: var(--s8);
`

const CourseListRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid rgb(var(--colors-neutral-200));
  padding: var(--s32) 0;
  align-items: center;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const CourseListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: var(--s24);
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const CoursesContainer = styled.div`
  margin-top: var(--s24);
`

const Description = styled(Body)`
  max-width: 700px;
  color: rgb(var(--colors-neutral-600));
  margin-bottom: var(--s24);
`

const TitleInfoContainer = styled.div`
  @media screen and (max-width: 1100px) {
    margin-top: var(--s32);
  }
`

const Strap = styled(Overline)`
  color: rgb(var(--colors-neutral-600));
  @media screen and (max-width: 1100px) {
    margin-bottom: var(--s16);
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  @media screen and (max-width: 1100px) {
    align-items: flex-start;
    margin-bottom: var(--s16);
  }
`

const Subtitle = styled(CaptionRegular)`
  color: rgb(var(--colors-neutral-600));
`

const RequiredCourses = styled(Overline)`
  color: rgb(var(--colors-neutral-600));
  @media screen and (max-width: 1100px) {
    margin-bottom: var(--s16);
  }
`

const ImageOverlay = styled.div`
  background-color: rgb(var(--colors-neutral-900));
  opacity: 0.5;
  width: 5.75rem;
  height: 5.75rem;
  position: absolute;
  z-index: 2;
`

const StyledLockIcon = styled(LockIcon)`
  fill: rgb(var(--colors-neutral-0));
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`

const MercerContainerMobile = styled.div`
  display: none;
  @media screen and (max-width: 1100px) {
    display: flex;
    margin-top: var(--s16);
    margin-bottom: var(--s24);
  }
`

const MercerContainerDesktop = styled.div`
  @media screen and (max-width: 1100px) {
    display: none;
  }
`

const CourseInfo = styled.div`
  width: 40%;
  @media screen and (max-width: 1100px) {
    margin-bottom: var(--s8);
    width: 100%;
  }
`

const CourseCallToActionContainer = styled(LayoutCluster)<CTAContainerProps>`
  display: flex;
  width: 60%;
  margin-top: auto;
  position: relative;
  overflow: visible;
  flex: 0 0 auto;
  > div {
    flex: 1;
    > button {
      min-width: ${(props) => `${props.buttonwidth}px`};
      height: auto;
      align-items: center;
      justify-content: center;
    }
    > a {
      > button {
        min-width: ${(props) => `${props.buttonwidth}px`};
        height: auto;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
    div:nth-of-type(1) {
      flex-direction: row;
      order: 1;
    }
    > div {
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
    }
  }
`

const TwoButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1100px) {
    align-items: flex-start;
  }
`

export type CTAContainerProps = {
  buttonwidth: number
  'data-testid': string
}

export type CallToActionButtonProps = {
  size?: string
  buttonWidth: number
  course: Course
  segment: Segment<TrackingId> | undefined
  experience: Experience | undefined
}

export const FundModal = (props: FundModalProps) => {
  const { fund, onSetSelectedCourse } = props
  const [buttonWidth, setButtonWidth] = useState(0)
  const [isNarrowViewport, setIsNarrowViewPort] = useState(false)
  const { PORTAL_URL } = useConfig()

  const { executeCourseEnrollPatch } = usePatchEnrollCourse()

  const selectRequiredCourses = useCallback(
    (courses: Course[]) => {
      return courses.filter((course: Course) =>
        fund.requiredCourses?.includes(course.id)
      )
    },
    [fund]
  )
  const { ceCreditsAvailable } = useGetCourseCeCredits()

  const selectRequiredLibraryCourses = useCallback(
    (courses: Course[]) => {
      return courses.filter(
        (course: Course) =>
          /**
           * We only want to include library courses the user is not enrolled
           * in (.enrolled == false) because enrolled library courses should be flowing
           * through /api/courses with the same ID.
           */
          fund.requiredCourses?.includes(course.id) && course.enrolled === false
      )
    },
    [fund]
  )

  const { data: courses } = useGetCourses({
    select: selectRequiredCourses,
  })
  const { data: libraryCourses } = useGetLibraryCourses({
    select: selectRequiredLibraryCourses,
  })

  const isLoading = courses === undefined || libraryCourses === undefined
  const requiredCourses = [...(courses ?? []), ...(libraryCourses ?? [])]

  useLayoutEffect(() => {
    let allButtons
    if (!isLoading) {
      allButtons = document.querySelectorAll(
        '[data-testid=fund-modal-button]>div>a>button, [data-testid=fund-modal-button]>div>div>a>button, [data-testid=fund-modal-button]>div>button'
      )

      let largestButtonWidth = -Infinity

      allButtons.forEach((button) => {
        if (button.clientWidth > largestButtonWidth) {
          largestButtonWidth = button.clientWidth
        }
      })

      setButtonWidth(largestButtonWidth)
    }
  }, [isLoading])

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsNarrowViewPort(window.matchMedia(`(max-width: 1480px)`).matches)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const enrollInAll = () => {
    requiredCourses.forEach((course) => {
      if (isLibraryCourse(course) && !course.enrolled) {
        executeCourseEnrollPatch({
          id: course.id,
          enroll: true,
        })
      }
    })
  }

  // .enrolled == false can only be true for a library course
  const enrolledInAll = requiredCourses.every(
    (course) => course.enrolled !== false
  )

  // Below function is needed to strip any HTML that might be within the description that comes from API call. Potentially open to XSS attacks
  const stripPotentialHTMLFromDescription = (description: string) => {
    const tempDescriptionElement = document.createElement('p')
    tempDescriptionElement.innerHTML = description
    const descriptionTextToReturn =
      tempDescriptionElement.textContent ||
      tempDescriptionElement.innerText ||
      ''
    return descriptionTextToReturn
  }

  return (
    <ChildrenContainer data-testid="fund-modal-container">
      <HeaderContainer>
        <InfoContainer>
          <ImageContainer data-testid="fund-modal-thumbnail">
            {Boolean(fund.blocked) && (
              <>
                <StyledLockIcon aria-label="lock icon" />
                <ImageOverlay />
              </>
            )}
            <Image
              aria-label="fund thumbnail"
              src={fund.largeThumbnailUrl}
            ></Image>
          </ImageContainer>
          <TitleInfoContainer>
            <Strap>{fund.type.replace(/_/g, ' ')}</Strap>
            <Title>
              <H4Strong>{fund.name}</H4Strong>
            </Title>
            <Subtitle>{fund.managerName}</Subtitle>
          </TitleInfoContainer>
        </InfoContainer>
        <MercerContainerDesktop>
          <MercerRating rating={fund.mercerRating} />
        </MercerContainerDesktop>
      </HeaderContainer>
      <Description aria-label="fund description">
        {stripPotentialHTMLFromDescription(fund.description ?? '')}
      </Description>
      <ButtonLink variant="tertiary" href={`${PORTAL_URL}${fund.relativeUrl}`}>
        View Fund Page
      </ButtonLink>
      <MercerContainerMobile>
        <MercerRating rating={fund.mercerRating} />
      </MercerContainerMobile>
      <CoursesContainer aria-label="list-of-required-courses">
        <LoadingContainer
          state={isLoading ? LoadingState.LOADING : LoadingState.SUCCESS}
          coverPage={false}
        >
          <CourseListHeader>
            <RequiredCourses>
              {requiredCourses?.length > 0
                ? `${requiredCourses.length} required courses`
                : null}
            </RequiredCourses>
            {requiredCourses?.length > 0 ? (
              enrolledInAll ? (
                <Enrolled>
                  Enrolled in all
                  <Checked />
                </Enrolled>
              ) : (
                <Button
                  onClick={enrollInAll}
                  size={isNarrowViewport ? 'medium' : 'regular'}
                >
                  Enroll in all
                </Button>
              )
            ) : null}
          </CourseListHeader>

          {requiredCourses.map((course) => (
            <CourseListRow key={course.id}>
              <CourseInfo>
                <H6Strong>{course.name}</H6Strong>
                <Button
                  variant="tertiary"
                  color="neutral"
                  onClick={() => onSetSelectedCourse(course)}
                >
                  View details
                </Button>
              </CourseInfo>

              <CourseCallToActionContainer
                data-testid="fund-modal-button"
                justify="flex-end"
                align="center"
                space="var(--s24)"
                buttonwidth={buttonWidth}
              >
                {isCourse(course) && !isNarrowViewport && (
                  <CourseAssessmentTag course={course} />
                )}

                <CourseProgressState
                  course={course as Course}
                  withCeCredits={ceCreditsAvailable(course).hasCeCredits}
                />

                {/* We have this branching logic because we want to
                      display the standard 'AssessmentUnlocked' button state * used
                      in other places (dashboard and course detail modal AND a
                      'Review' button that only exists * in the fund modal.*/}
                {(course as Course).type === 'AssessmentUnlocked' ? (
                  <TwoButtonContainer>
                    <CourseCallToActionButton
                      course={course as Course}
                      buttonWidth={buttonWidth}
                      size={isNarrowViewport ? 'medium' : 'large'}
                    />
                    <Link
                      to={ROUTES.assignment(course.id)}
                      onClick={() =>
                        trackingService.courseCardClicked(course, 'Review')
                      }
                    >
                      <Button
                        variant="tertiary"
                        size={isNarrowViewport ? 'medium' : 'regular'}
                      >
                        Review
                      </Button>
                    </Link>
                  </TwoButtonContainer>
                ) : (
                  <CourseCallToActionButton
                    course={course as Course}
                    buttonWidth={buttonWidth}
                    size={isNarrowViewport ? 'medium' : 'large'}
                  />
                )}
              </CourseCallToActionContainer>
            </CourseListRow>
          ))}
        </LoadingContainer>
      </CoursesContainer>
    </ChildrenContainer>
  )
}
