import { useMemo } from 'react'

import { useLocalStorage } from '@cais-group/caisiq/util/hook/use-local-storage'

export type SortOrder = 'asc' | 'desc'

export type WithinGroupSortConfig = {
  sortId: string
  sortOrder: SortOrder
}

/**
 * For setting and persisting user's choice of within group sort.
 *
 * This would be pretty simple however we need to return two sort configs, one primary one and a backup in case primary one is being used to group by. To keep decoupled from other hooks and avoid cycles we don't know which group by if any is being applied by table (hence are returning two sort configs).
 */
export function useWithinGroupSortConfig(
  tableName: string,
  defaultSorts: Readonly<[WithinGroupSortConfig, WithinGroupSortConfig]>
): {
  sortConfigs: [WithinGroupSortConfig, WithinGroupSortConfig]
  setSortConfig: (sortConfig: WithinGroupSortConfig) => void
} {
  const [groupSortConfig, setGroupSortConfig] = useLocalStorage(
    `withinGroupSort-${tableName}`,
    defaultSorts[0]
  )

  const backupSortConfig = useMemo(() => {
    if (defaultSorts[0].sortId === groupSortConfig.sortId) {
      return defaultSorts[1]
    } else {
      return defaultSorts[0]
    }
  }, [defaultSorts, groupSortConfig.sortId])

  return {
    sortConfigs: [groupSortConfig, backupSortConfig],
    setSortConfig: setGroupSortConfig,
  }
}

export function groupSortToApply(
  sortConfigs: Readonly<[WithinGroupSortConfig, WithinGroupSortConfig]>,
  exclude?: string
): WithinGroupSortConfig {
  if (!exclude) return sortConfigs[0]

  const allowed = sortConfigs.filter(
    (sortConfig) => sortConfig.sortId !== exclude
  )
  if (allowed.length === 0) {
    throw new Error('Invalid configuration setup, no available sort config')
  } else {
    return allowed[0]
  }
}
