import { Route, Routes } from 'react-router-dom'

import { InterstitialsPageContainer } from './caisiq-feature-interstitials-container'
import { CeCreditsSuccessPageContainer } from './ce-credits-success-page-container'

export const InterstitialsRoutes = () => (
  <Routes>
    <Route path=":courseId" element={<InterstitialsPageContainer />} />
    <Route path=":courseId/ok" element={<CeCreditsSuccessPageContainer />} />
  </Routes>
)
