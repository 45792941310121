import styled from '@emotion/styled'
import LockIcon from '@mui/icons-material/Lock'
import { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Body,
  BodyStrong,
  CaptionRegular,
  H4Strong,
  H6Strong,
  SubtitleMono,
} from '@cais-group/approved/ui/typography'
import {
  CeCreditsType,
  CourseCeStatus,
  useCourseModal,
} from '@cais-group/caisiq/domain/course'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import {
  CourseAssessmentTag,
  CourseCallToActionButton,
  CourseProgressState,
} from '@cais-group/caisiq/ui/card/course'
import { CeCreditGuideLink } from '@cais-group/caisiq/ui/ce-credit-guide-link'
import { IconCertificate, IconCeCredits } from '@cais-group/caisiq/ui/icons'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { StatusTag } from '@cais-group/equity/atoms/status-tag'
import { colors } from '@cais-group/equity/particles/colors'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { LayoutBox, LayoutCluster } from '@cais-group/shared/ui/layout'
import {
  CeCredits,
  Course,
  Fund,
  FundWithThumbnail,
} from '@cais-group/shared/util/type/caisiq-be'
import {
  isCourse,
  isLearningComplete,
  isPassed,
} from '@cais-group/shared/util/type/course'

export type CourseDetailModalProps = {
  onSetSelectedFund?: (fund: Fund) => void
  onClickEnroll: (course: Course) => void
  ceCreditsAvailable?: CeCreditsType
}

export type CourseModalHeaderProps = {
  imageUrl?: string
  enrolledNumber?: number
  withCertificate?: boolean
  headerLabel?: string
}

type HeaderImgProps = {
  url?: string
}

const Certificate = styled(IconCertificate)`
  position: absolute; // To bring this to the top vs absolute positioned background
  bottom: var(--s24);
  right: var(--s56);
  width: var(--s56);
  height: var(--s56);
`
const HeaderLabel = styled(H6Strong)`
  position: absolute; // To bring this to the top vs absolute positioned background

  bottom: var(--s24);
  color: rgb(var(--colors-neutral-0));

  /* Mobile first */
  padding-left: var(--s24);

  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    padding-left: var(--s56);
  }
`
const CompletedTag = styled(StatusTag)`
  margin-right: var(--s16);
`

const HeaderImg = styled.div<HeaderImgProps>`
  position: relative;
  height: calc(var(--s88) * 1.5);
  background-color: rgb(var(--colors-primary-600));
  background-size: cover;
  background-image: ${({ url }) => `url('${url}');`};
`

const Description = styled(BodyStrong)`
  margin-top: var(--s8);
  color: rgb(var(--colors-neutral-600));
`
const DescriptionSubTitle = styled(SubtitleMono)`
  font-family: var(--font-text);
  font-weight: 600;
  margin-top: var(--s24);
  margin-bottom: var(--s24);
  color: rgb(var(--colors-neutral-600));
  display: flex;
  align-items: center;
`
const ChildrenContainer = styled.div`
  padding: var(--s32) var(--s56) var(--s56) var(--s56);
  @media screen and (max-width: 1100px) {
    padding: var(--s8) var(--s24) var(--s24) var(--s24);
  }
`

const CourseCallToActionContainer = styled(LayoutCluster)`
  display: flex;
  margin-top: auto;
  position: relative;
  overflow: visible;
  width: 100%;
  > div {
    flex: 1;

    > div {
      order: 1;

      > button {
        height: auto;
        align-items: center;
        justify-content: center;
      }
      > a {
        > button {
          height: auto;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`
const ProductsContainer = styled.div`
  border-top: 1px solid rgb(var(--colors-neutral-200));
  margin-top: var(--s24);
`

const ProductsTitle = styled(H6Strong)`
  margin-top: var(--s16);
  margin-bottom: var(--s8);
`

const ProductsSubtitle = styled(Body)`
  color: rgb(var(--colors-neutral-600));
`

const ProductFirm = styled(CaptionRegular)`
  color: rgb(var(--colors-neutral-600));
`

const ProductInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: var(--s24);

  &:hover {
    cursor: pointer;
  }
`

const ImageOverlay = styled.div`
  background-color: rgb(var(--colors-neutral-900));
  opacity: 0.5;
  width: var(--s56);
  height: var(--s56);
  position: absolute;
  z-index: 2;
`

const ImageContainer = styled.div`
  width: var(--s56);
  height: var(--s56);
  overflow: hidden;
  margin-right: var(--s24);
  position: relative;
  background-color: rgb(var(--colors-neutral-400));
`
const Image = styled.img`
  width: var(--s56);
  height: var(--s56);
`

const Bullet = styled.span`
  margin-left: var(--s8);
  margin-right: var(--s8);
`
const StyledLockIcon = styled(LockIcon)`
  fill: rgb(var(--colors-neutral-0));
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: var(--s16);
  width: var(--s16);
  z-index: 3;
`

const StyledBox = styled(LayoutBox)`
  background: ${colors['eq-color-neutral-100']};
  width: fit-content;
  color: ${colors['eq-color-neutral-600']};
  display: flex;
  justify-content: center;

  /* Mobile first */
  flex-direction: column;
  margin-top: var(--s32);
  height: var(--s144);
  align-items: flex-start;
  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    height: 48px;
    flex-direction: row;
    align-items: center;
    margin-top: 75px;
    gap: var(--s16);
  }
`

const StyledCeCreditsIcon = styled(IconCeCredits)`
  fill: rgb(var(--colors-neutral-600));
  margin-bottom: var(--s8);
  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    margin-bottom: 0;
  }
`

type RenderBoxTypes = {
  courseCeStatus: CourseCeStatus
  cfpCeCredit?: CeCredits['cimaCeCredit']
  cimaCeCredit?: CeCredits['cfpCeCredit']
}

const renderBoxText = ({
  courseCeStatus,
  cfpCeCredit,
  cimaCeCredit,
}: RenderBoxTypes): string => {
  const makePlural = (credit: number): string =>
    credit === 0 || credit > 1 ? 's' : ''

  if (courseCeStatus === CourseCeStatus.EARNED) {
    return `You earned ${cfpCeCredit || 0} CFP credit${makePlural(
      cfpCeCredit ?? 0
    )} and ${cimaCeCredit || 0} CIMA credit${makePlural(
      cimaCeCredit ?? 0
    )} for completing this course`
  } else if (courseCeStatus === CourseCeStatus.MASTERED) {
    return `You met a requirement for Continuing Education Credits.`
  } else if (courseCeStatus === CourseCeStatus.ELIGIBLE) {
    return `This course is eligible for ${
      cfpCeCredit || 0
    } CFP credit${makePlural(cfpCeCredit ?? 0)} and ${
      cimaCeCredit || 0
    } CIMA credit${makePlural(cimaCeCredit ?? 0)}`
  }
  return ''
}

export const CourseModalHeader = (props: CourseModalHeaderProps) => {
  const { imageUrl, withCertificate, headerLabel } = props

  return (
    <HeaderImg url={imageUrl}>
      {headerLabel && (
        <HeaderLabel data-testid="ceCredit-label">{headerLabel}</HeaderLabel>
      )}
      {withCertificate && <Certificate fill="rgb(var(--colors-neutral-0))" />}
    </HeaderImg>
  )
}

export const CourseDetailModal = (props: CourseDetailModalProps) => {
  const { onSetSelectedFund, ceCreditsAvailable } = props
  const navigate = useNavigate()
  const { course, closeModal } = useCourseModal()
  const [isNarrowViewport, setIsNarrowViewPort] = useState(false)

  const isCompleteOrPassed = isLearningComplete(course) || isPassed(course)

  const experience = userSettingsService.theme

  useLayoutEffect(() => {
    // TODO: Refactor the matchMedia usage to mui useMediaQuery
    if (window.matchMedia(`(max-width: 1100px)`).matches) {
      setIsNarrowViewPort(true)
    }
  }, [])

  if (!course) {
    return null
  }

  const productAccessInfo =
    (course.funds?.length ?? 0) > 0
      ? `Required to access ${course.funds?.length} products`
      : ''

  return (
    <ChildrenContainer data-testid="course-modal">
      <H4Strong>{course.name}</H4Strong>
      <Description>{course.description}</Description>
      <DescriptionSubTitle as="div">
        {isCompleteOrPassed && (
          <CompletedTag variant="light" color="success">
            Completed
          </CompletedTag>
        )}
        {productAccessInfo}
        {isCourse(course) && (
          <>
            {productAccessInfo !== '' ? <Bullet>&bull;</Bullet> : null}
            <CourseAssessmentTag course={course} />
          </>
        )}
      </DescriptionSubTitle>
      <CourseCallToActionContainer
        justify="space-between"
        align="center"
        space="var(--s24)"
      >
        <CourseCallToActionButton
          course={course}
          size={isNarrowViewport ? 'medium' : 'large'}
          buttonWidth={0}
        />
        <CourseProgressState
          course={course}
          size="large"
          withCeCredits={Boolean(ceCreditsAvailable?.hasCeCredits)}
        />
      </CourseCallToActionContainer>
      {course.funds?.length ? (
        <ProductsContainer>
          <ProductsTitle>Product access</ProductsTitle>
          <ProductsSubtitle>
            Completing this course completes a requirement for the following
            products.
          </ProductsSubtitle>
          {course.funds.map((fund) => {
            return (
              <ProductInfo
                key={fund.fundIdentifier}
                onClick={() => {
                  navigate(ROUTES.fund(`${fund.fundIdentifier}`))
                  closeModal()
                  if (onSetSelectedFund) {
                    onSetSelectedFund(fund as Fund)
                  }
                }}
                data-testid="fund-product"
              >
                <ImageContainer>
                  {Boolean(fund.blocked) && (
                    <>
                      <StyledLockIcon />
                      <ImageOverlay />
                    </>
                  )}
                  <Image
                    src={(fund as FundWithThumbnail).smallThumbnailUrl}
                  ></Image>
                </ImageContainer>
                <div>
                  <H6Strong>{fund.name}</H6Strong>
                  <ProductFirm>{fund.managerName}</ProductFirm>
                </div>
              </ProductInfo>
            )
          })}
        </ProductsContainer>
      ) : null}

      {ceCreditsAvailable && ceCreditsAvailable.hasCeCredits && (
        <StyledBox>
          <StyledCeCreditsIcon />
          <Body>
            {renderBoxText({
              courseCeStatus: ceCreditsAvailable.courseCeStatus,
              cfpCeCredit: ceCreditsAvailable.cfpCeCredit,
              cimaCeCredit: ceCreditsAvailable.cimaCeCredit,
            })}
          </Body>
          <CeCreditGuideLink
            slug={experience?.ceGuidePage?.slug}
            onClick={() => {
              const bodyDomNode = document.getElementsByTagName('body')[0]
              bodyDomNode.style.overflow = 'auto'
              trackingService.ceLearnMoreAboutCeCreditsLinkClicked()
              closeModal()
            }}
          />
        </StyledBox>
      )}
    </ChildrenContainer>
  )
}
