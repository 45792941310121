import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'

export const useGetAllowedFirms = () => {
  const userProfile = useUserProfile()
  const isLoading = userProfile.userProfileState.status === 'loading'
  const isError = userProfile.userProfileState.status === 'error'
  const allowedFirms =
    userProfile.userProfileState.userProfile?.allParentFirms?.map(
      (firm) => firm
    ) || []

  return {
    data: allowedFirms,
    isLoading,
    isError,
  }
}
