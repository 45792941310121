import styled from '@emotion/styled'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import cx from 'classnames'
import React, { useRef, useState } from 'react'

import { Icon } from '@cais-group/equity/atoms/icon'
import { Color } from '@cais-group/equity/particles/colors'
import { useIsTruncated } from '@cais-group/shared/util/hook/use-is-truncated'

export const ColumnHeaderContainer = styled.div<{
  width?: number
  stickLastRight?: boolean
  stickLeft?: number
  active?: boolean
}>`
  height: 40px;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  background-color: ${({ stickLastRight, stickLeft, active }) => {
    return active
      ? 'var(--color-gray-64)'
      : stickLeft !== undefined || stickLastRight
      ? 'var(--color-black)'
      : 'var(--color-gray-84)'
  }};
  color: rgb(var(--colors-neutral-0));
  flex-shrink: 0;
  padding: 0 var(--s16);
  font-size: 12px;
  ${({ stickLeft }) => {
    return stickLeft !== undefined
      ? `position: sticky;
    left: ${stickLeft}px;
    `
      : ''
  }}

  ${({ stickLastRight }) => {
    return stickLastRight
      ? `position: sticky;
    right: 0px;
    `
      : ''
  }}

  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
`

const MenuWithShadow = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0px var(--s16) var(--s24) rgba(0, 0, 0, 0.08);
    padding: var(--s16);
    ul {
      padding: 0;
    }
  }
`

const InfoIcon = styled(InfoOutlinedIcon)`
  margin-right: var(--s8);
  fill: rgb(var(--colors-neutral-400));
  width: 18px;
  height: 18px;
`

export type SortIndicatorIcon =
  | 'sort'
  | 'sortReverse'
  | 'alphabetical'
  | 'alphabeticalReverse'
  | 'date'
  | 'dateReverse'

export type IconKind = SortIndicatorIcon | 'hide'

const iconLookup = {
  sort: ({ color = 'eq-color-neutral-400' }: { color: Color }) => (
    <Icon size="small" type="SortHighToLow" color={color} />
  ),
  sortReverse: ({ color = 'eq-color-neutral-400' }: { color: Color }) => (
    <Icon size="small" type="SortLowToHigh" color={color} />
  ),
  hide: ({ color = 'eq-color-neutral-400' }: { color: Color }) => (
    <Icon size="small" type="EyeClosed" color={color} />
  ),
  alphabetical: ({ color = 'eq-color-neutral-400' }: { color: Color }) => (
    <Icon size="small" type="SortByAlpha" color={color} />
  ),
  alphabeticalReverse: ({
    color = 'eq-color-neutral-400',
  }: {
    color: Color
  }) => <Icon size="small" type="SortByAlphaReverse" color={color} />,
  date: ({ color = 'eq-color-neutral-400' }: { color: Color }) => (
    <Icon size="small" type="SortByDate" color={color} />
  ),
  dateReverse: ({ color = 'eq-color-neutral-400' }: { color: Color }) => (
    <Icon size="small" type="SortByDateReverse" color={color} />
  ),
} as const

export type ColumnHeaderMenuItem = {
  onClick: () => void
  icon: IconKind
  label: string
}

export function SortMenuColumnHeader({
  children,
  width,
  stickLeft,
  active,
  actions,
  sortIcon,
  alignment,
  listItemIconColor,
  stickLastRight,
  tooltip,
  className,
}: {
  children: string
  width?: number
  stickLeft?: number
  active?: boolean
  actions?: ColumnHeaderMenuItem[]
  sortIcon?: SortIndicatorIcon
  alignment?: 'left' | 'right'
  listItemIconColor?: Color
  stickLastRight?: boolean
  tooltip?: React.ReactElement
  value?: string
  className?: string
}) {
  const elRef = useRef<HTMLDivElement | null>(null)
  const { ref, disabled } = useIsTruncated<HTMLDivElement>()

  const [open, setOpen] = useState(false)

  const hasMenu = actions && actions.length > 0

  const SelectedSortIcon = iconLookup[sortIcon || 'sort']

  return (
    <>
      <ColumnHeaderContainer
        width={width}
        stickLeft={stickLeft}
        ref={elRef}
        onClick={() => {
          hasMenu && setOpen(!open)
        }}
        active={active || open}
        // Making this a button caused some issues with layout, for now setting aria attributes explicitly (and cursor)
        aria-label={children}
        aria-sort={active ? 'other' : 'none'}
        aria-haspopup="true"
        aria-expanded={open}
        data-testid={`column-header-for-${children}`}
        stickLastRight={stickLastRight}
        className={className}
      >
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <InfoIcon />
          </Tooltip>
        )}
        <Tooltip
          title={children}
          placement="top"
          disableHoverListener={disabled}
        >
          <div
            className={cx(
              'caption line-clamp-2 flex-1 select-none text-ellipsis',
              { 'text-left': alignment === 'left' },
              { 'text-right': alignment === 'right' }
            )}
            ref={ref}
          >
            {children}
          </div>
        </Tooltip>
        {active && <SelectedSortIcon color="eq-color-neutral-0" />}

        {!!actions?.length && (
          <ArrowDropDownIcon
            style={{ color: 'rgb(var(--colors-neutral-500))' }}
          />
        )}
      </ColumnHeaderContainer>
      {hasMenu && (
        <MenuWithShadow
          anchorEl={elRef.current}
          open={open}
          onClose={() => setOpen(false)}
        >
          {actions.map((action, index) => {
            const Icon = iconLookup[action.icon]

            return (
              <MenuItem
                key={index}
                onClick={() => {
                  action.onClick()
                  setOpen(false)
                }}
                sx={{
                  '&.Mui-focusVisible': {
                    background: listItemIconColor
                      ? 'transparent'
                      : // This was previously defaulting to theme.palette.action.focus
                        'rgb(var(--colors-neutral-200))',
                  },
                  '&:hover': {
                    backgroundColor: 'rgb(var(--colors-neutral-100))',
                  },
                }}
              >
                <ListItemIcon>
                  <Icon
                    color={
                      listItemIconColor
                        ? listItemIconColor
                        : 'eq-color-neutral-500'
                    }
                  />
                </ListItemIcon>
                {action.label}
              </MenuItem>
            )
          })}
        </MenuWithShadow>
      )}
    </>
  )
}
