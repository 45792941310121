import { useMutation } from 'react-query'
import { isEmpty } from 'remeda'

import { defaultPersister } from '@cais-group/caisiq/util/default-query-function'
import { useApiPathWithPayload } from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import {
  ApiPaths,
  CourseMetadata,
  CourseMetadataRequest,
} from '@cais-group/shared/util/type/caisiq-be'

export function usePutExperienceCourseMetadata() {
  const getParsedEndpoint = useApiPathWithPayload(
    ApiPaths.createOrUpdateCourseMetadataForExperience
  )
  return useMutation(
    (
      data: CourseMetadataRequest & {
        experienceId: string
        newVersion?: number
      }
    ) => {
      // Do not send a theme value if it is an empty string
      const cleanMetadata = data.metadata.map((course) => {
        const theme = (course.theme ?? '').trim()
        return {
          ...course,
          theme: isEmpty(theme) ? undefined : theme,
        }
      })
      return defaultPersister<CourseMetadataRequest, CourseMetadata>(
        getParsedEndpoint({ experienceId: data.experienceId }),
        { metadata: cleanMetadata },
        data.newVersion,
        'PUT'
      )
    }
  )
}
