import styled from '@emotion/styled'
import { SelectChangeEvent } from '@mui/material'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { BodySmall, H5Strong } from '@cais-group/approved/ui/typography'
import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import { FormSettingsToggle } from '@cais-group/caisiq/ui/form/settings-toggle'
import { UseModalState } from '@cais-group/caisiq/util/hooks/use-modal-state'
import { tranformCatalogToList } from '@cais-group/caisiq/util/manage/transform-data-to-list-items'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import {
  ExperienceFields,
  FormStepProps,
} from '@cais-group/caisiq/util/type/manage-caisiq'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { DisabledList } from '@cais-group/shared/ui/disabled-list'
import {
  OrderedFieldset,
  StepNavigation,
  useStepState,
} from '@cais-group/shared/ui/ordered-fieldset'
import { SelectBox } from '@cais-group/shared/ui/select-box'
import { Catalog, UserRole } from '@cais-group/shared/util/type/caisiq-be'

import { ConfirmAlmChangeModal } from './components/caisiq-ui-manage-capabilities-step-confirm-alm-change'

interface CapabilitiesStepProps extends FormStepProps {
  catalogs: Catalog[]
  disabled?: boolean
  ignoreStep?: boolean
  legend?: string | undefined
}

const SubPanel = styled.div`
  margin-top: ${SPACING.s32};
`

export function CapabilitiesStep({
  catalogs,
  order,
  disabled = false,
  legend,
  ignoreStep = false,
  formLabels,
}: CapabilitiesStepProps) {
  const { control, watch, trigger, getValues, setValue } =
    useFormContext<ExperienceFields>()

  const catalogId = watch('catalogId')

  const [previousCatalogId, setPreviousCatalogId] = useState<
    string | undefined
  >(catalogId)
  const { isOpen, onOpen } = UseModalState<boolean>()
  const details = tranformCatalogToList({
    name: catalogs.find((item) => item.id === catalogId)?.name || '',
  })
  const coursesEnabled = watch('coursesEnabled')
  const { deriveState, showStep } = useStepState()

  const handleChange = (
    onChange: (...event: unknown[]) => void,
    e: SelectChangeEvent<unknown>
  ) => {
    onChange(e)
    // Do not open the modal if there was no catalogId default value (i.e. the user is creating a new experience)
    if (previousCatalogId && e.target.value) {
      onOpen(true)
    } else {
      setPreviousCatalogId(e.target.value as string)
    }
  }

  return (
    <OrderedFieldset
      legend={
        legend ? (
          <H5Strong className="scroll-mt-144" id="capabilities">
            {legend}
          </H5Strong>
        ) : undefined
      }
      state={deriveState(order)}
    >
      {(showStep(order) || ignoreStep) && (
        <>
          <Controller
            name="coursesEnabled"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormSettingsToggle
                onChange={onChange}
                checked={Boolean(value)}
                label="Courses"
                helperText="Allow learners to access courses"
                disabled={disabled}
              />
            )}
          />

          {coursesEnabled && (
            <>
              <SubPanel>
                <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
                  <HasPermissions.Then>
                    <>
                      <Controller
                        name="catalogId"
                        control={control}
                        rules={{
                          validate: {
                            value: (value) =>
                              coursesEnabled && !value
                                ? 'Catalog is required when courses are enabled'
                                : true,
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <SelectBox
                            variant="standard"
                            hideSelectPrompt
                            disabled={disabled}
                            id="catalogId"
                            label={formLabels?.catalogId}
                            required
                            error={Boolean(error)}
                            errorMessage={error?.message}
                            onChange={(e) => {
                              handleChange(field.onChange, e)
                            }}
                            onBlur={field.onBlur}
                            value={field.value}
                            selectOptions={catalogs.map((c) => ({
                              label:
                                c.name === 'CAIS Network Advanced - Default'
                                  ? 'CAIS Network - Default'
                                  : c.name,
                              value: c.id,
                            }))}
                          />
                        )}
                      />
                      <BodySmall>
                        Catalogs determine the courses that are assigned to the
                        experience. You must create a catalog in ALM to view it
                        here.
                      </BodySmall>
                    </>
                  </HasPermissions.Then>
                  <HasPermissions.Else>
                    <DisabledList
                      details={details}
                      helperText="Catalogs determine the courses that are assigned to the experience."
                    />
                  </HasPermissions.Else>
                </HasPermissions>
              </SubPanel>
              <Controller
                name="ceCreditEnabled"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormSettingsToggle
                    onChange={onChange}
                    checked={Boolean(value)}
                    label="CE credits"
                    helperText="Allow learners to earn CFP and CIMA credits for eligible courses"
                    disabled={disabled}
                  />
                )}
              />
            </>
          )}
        </>
      )}
      <StepNavigation
        order={order}
        onBeforeNext={() => {
          trackingService.manageNewExperienceCapabilitiesEntered({
            almCatalog: getValues('catalogId') ?? '',
            ceCreditsEnabled: getValues('ceCreditEnabled') ?? '',
            coursesEnabled: getValues('coursesEnabled') ?? '',
          })
          return trigger(['catalogId'])
        }}
      />
      <ConfirmAlmChangeModal
        open={Boolean(isOpen)}
        onClose={() => {
          setValue('catalogId', previousCatalogId)
          onOpen(false)
        }}
        onConfirm={() => {
          onOpen(false)
          setPreviousCatalogId(getValues('catalogId'))
        }}
      />
    </OrderedFieldset>
  )
}
