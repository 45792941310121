import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { Divider } from '@mui/material'
import {
  ActionMeta,
  ClearIndicatorProps,
  ControlProps,
  GroupHeadingProps,
  NoticeProps,
  OnChangeValue,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  ValueContainerProps,
  components,
} from 'react-select'
import AsyncSelect from 'react-select/async'

import {
  Body,
  BodyStrong,
  CaptionRegular,
  H6Strong,
} from '@cais-group/approved/ui/typography'
import { Pill } from '@cais-group/caisiq-ui-pill'

const customStyles: StylesConfig<OptionType, false, GroupType> = {
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 'var(--s56)',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: '1fr',
    '&:hover': {
      cursor: 'text',
    },
  }),
  groupHeading: (provided) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'var(--s16)',
    marginBottom: 'var(--s8)',
  }),
  option: (provided, state) => ({
    paddingTop: 'var(--s8)',
    paddingBottom: 'var(--s8)',
    paddingLeft: 'var(--s16)',
    paddingRight: 'var(--s8)',
    margin: '0 var(--s24)',
    display: 'flex',
    justifyContent: 'space-between',
    color: state.isSelected ? 'var(--color-blue)' : 'var(--color-black)',
    backgroundColor:
      state.isFocused && state.selectProps.menuIsOpen
        ? 'var(--color-blue-7)'
        : 'var(--color-white)',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor:
        state.isFocused || state.isSelected
          ? 'var(--color-blue-7)'
          : 'var(--color-white)',
    },
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    position: 'absolute',
    marginTop: 0,
    top: 48,
  }),
  control: (provided, state) => ({
    ...provided,
    height: 48,
    width: 550,
    borderRadius: 0,
    borderStyle: 'solid',
    background: 'var(--color-white)',
    borderColor:
      state.isFocused || state.menuIsOpen
        ? 'var(--color-blue)'
        : 'var(--color-white)',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
}

export type OptionType = {
  type: 'advisor' | 'team'
  name: string
  count: number
  email?: string
}

export type GroupType = {
  count: number
  options: Array<OptionType>
  label: string
}

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: var(--s16);
  flex-grow: 0;
`
StyledSearchIcon.displayName = 'StyledSearchIcon'

const PaddedCenter = styled.div`
  padding-top: var(--s56);
  padding-bottom: var(--s56);
  margin: auto;
  text-align: center;
`
PaddedCenter.displayName = 'PaddedCenter'

const ClearIcon = styled(CloseIcon)`
  fill: rgb(var(--colors-neutral-500));
  margin-right: var(--s8);
  margin-left: var(--s8);
  margin-top: var(--s8);
  cursor: pointer;
`
ClearIcon.displayName = 'ClearIcon'

const PrimaryLabel = styled(Body)`
  color: rgb(var(--colors-neutral-900));
  &:hover {
    cursor: pointer;
  }
`
PrimaryLabel.displayName = 'PrimaryLabel'

const SecondaryLabel = styled(CaptionRegular)`
  color: rgb(var(--colors-neutral-900));
`
SecondaryLabel.displayName = 'SecondaryLabel'

const StyledDivider = styled(Divider)`
  margin: calc(var(--s16) / 2) var(--s0);
`
StyledDivider.displayName = 'StyledDivider'

const StyledGroupHeading = styled.div`
  margin: var(--s8) var(--s24);
`
StyledGroupHeading.displayName = 'StyledGroupHeading'

const ClearIndicator = (
  props: ClearIndicatorProps<OptionType, false, GroupType>
) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props
  return (
    <div {...restInnerProps} ref={ref}>
      <ClearIcon />
    </div>
  )
}

const Option = ({
  children,
  ...props
}: OptionProps<OptionType, false, GroupType>) => {
  const { data: option } = props
  return (
    <components.Option {...props}>
      <PrimaryLabel>{option.name}</PrimaryLabel>
      <SecondaryLabel>
        {option.type === 'advisor'
          ? option.email?.toLowerCase()
          : `${option.count} members`}
      </SecondaryLabel>
    </components.Option>
  )
}

const SingleValue = ({
  children,
  ...props
}: SingleValueProps<OptionType, false, GroupType>) => {
  const { data: optionSelected } = props
  return (
    <components.SingleValue {...props}>
      <H6Strong style={{ color: 'rgb(var(--colors-primary-600))' }}>
        {optionSelected.name}
      </H6Strong>
      <CaptionRegular>
        {optionSelected.type === 'advisor'
          ? optionSelected.email?.toLowerCase()
          : `${optionSelected.count} members`}
      </CaptionRegular>
    </components.SingleValue>
  )
}

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<OptionType, false, GroupType>) => {
  return (
    <components.ValueContainer {...props}>
      <StyledSearchIcon />
      {children}
    </components.ValueContainer>
  )
}

const GroupHeading = ({
  children,
  ...props
}: GroupHeadingProps<OptionType, false, GroupType>) => {
  const { data: group } = props

  return (
    <StyledGroupHeading>
      <StyledDivider />
      <components.GroupHeading {...props}>
        <H6Strong>{group.label}</H6Strong>
        <Pill
          label={group.count}
          size="small"
          backgroundColor="rgb(var(--colors-neutral-200))"
        />
      </components.GroupHeading>
    </StyledGroupHeading>
  )
}

const Control = ({
  children,
  ...props
}: ControlProps<OptionType, false, GroupType>) => {
  return <components.Control {...props}>{children}</components.Control>
}

const NoOptionsMessage = (props: NoticeProps<OptionType, false, GroupType>) => {
  if (props.hasValue || !props.selectProps.value) return null
  return <PaddedCenter>{props.children}</PaddedCenter>
}

export type AutoCompleteProps = {
  placeholderText?: string
  onSelectionChange: (
    value: OnChangeValue<OptionType, false>,
    action: ActionMeta<OptionType>
  ) => void
  promiseOptions: (
    inputValue: string
  ) => Promise<ReadonlyArray<OptionType | GroupType>> | void
}

export const AutoComplete = ({
  promiseOptions,
  onSelectionChange,
  placeholderText,
}: AutoCompleteProps) => {
  return (
    <AsyncSelect
      placeholder={placeholderText ?? 'Search'}
      styles={customStyles}
      cacheOptions
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.name}
      // NOTE: Setting `captureMenuScroll` to `false` is a workaround to a bug with non-touchpad scroll behavior:
      // https://github.com/JedWatson/react-select/issues/4640#issuecomment-867067828.
      captureMenuScroll={false}
      openMenuOnFocus={true}
      closeMenuOnSelect={true}
      blurInputOnSelect={true}
      maxMenuHeight={500}
      isClearable
      menuShouldBlockScroll={true}
      loadOptions={promiseOptions}
      components={{
        Control,
        Option,
        GroupHeading,
        NoOptionsMessage,
        ValueContainer,
        SingleValue,
        ClearIndicator,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length > 0 ? (
          <Body>
            {`No matching results for `}
            <BodyStrong as="span">{`${inputValue}`}</BodyStrong>
          </Body>
        ) : (
          <Body>No matching results</Body>
        )
      }
      onChange={onSelectionChange}
    />
  )
}
AutoComplete.displayName = 'AutoComplete'
