import {
  SemanticColor,
  StatusTag,
  StatusTagVariant,
} from '@cais-group/equity/atoms/status-tag'
import { AssessmentStatus } from '@cais-group/shared/util/type/homeoffice-data'

const statusStatusMap: Record<
  AssessmentStatus,
  { variant: StatusTagVariant; color: SemanticColor }
> = {
  [AssessmentStatus.Failed]: {
    variant: 'regular',
    color: 'error',
  },
  [AssessmentStatus.InProgress]: {
    variant: 'regular',
    color: 'warning',
  },
  [AssessmentStatus.Passed]: {
    variant: 'regular',
    color: 'success',
  },
  [AssessmentStatus.Unstarted]: {
    variant: 'regular',
    color: 'primary',
  },
  [AssessmentStatus.Locked]: {
    variant: 'dark',
    color: 'neutral',
  },
  [AssessmentStatus.Unlocked]: {
    variant: 'regular',
    color: 'neutral',
  },
  [AssessmentStatus.NotApplicable]: {
    variant: 'light',
    color: 'neutral',
  },
}

export const TableAssessmentStatusTag = (props: {
  status?: AssessmentStatus
}) => {
  const { status } = props
  let statusString = 'Not applicable'

  if (status) {
    statusString = status.replace(
      /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi,
      ' '
    )
    statusString = `${statusString[0].toUpperCase()}${statusString.slice(1)}`
  }

  if (status === AssessmentStatus.Unstarted) {
    statusString = 'Unlocked'
  }

  if (status === AssessmentStatus.NotApplicable) {
    statusString = 'Not applicable'
  }

  const { variant, color } =
    statusStatusMap[status || AssessmentStatus.NotApplicable]
  return (
    <StatusTag
      variant={variant}
      color={color}
      data-testid="table-assessment-tag"
    >
      {statusString}
    </StatusTag>
  )
}
