import { AxiosRequestConfig } from 'axios'

import { getEnvConfig as getCaisIQEnvConfig } from '@cais-group/caisiq-ui-env'
import { getEnvConfig } from '@cais-group/shared/ui/env'
import { fetchInstance as sharedFetchInstance } from '@cais-group/shared/util/fetch'

export async function fetchInstance<T>(requestConfig: AxiosRequestConfig) {
  const envConfig = getEnvConfig() || getCaisIQEnvConfig()
  return sharedFetchInstance<T>(requestConfig, { envConfig })
}
