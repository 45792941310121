import { BreadcrumbsCollapsed } from './BreadcrumbsCollapsed'
import { BreadcrumbsFull } from './BreadcrumbsFull'

const BREADCRUMBS_MAX_ITEMS = 5
const MINIMUM_ITEMS = 2

export type BreadcrumbItem = {
  label: string
  path?: string
  onClick?:
    | ((
        event: React.MouseEvent<HTMLAnchorElement | HTMLElement, MouseEvent>
      ) => void)
    | undefined
}

export interface BreadcrumbsProps {
  items: Array<BreadcrumbItem>
}

export function Breadcrumbs(props: BreadcrumbsProps) {
  const { items } = props
  if (items.length < MINIMUM_ITEMS) {
    console.error(
      `Breadcrumbs error. You must provide at least ${MINIMUM_ITEMS} items, received ${items.length}`
    )
    return null
  }

  if (items.length <= BREADCRUMBS_MAX_ITEMS) {
    return <BreadcrumbsFull items={items} />
  } else {
    return <BreadcrumbsCollapsed items={items} />
  }
}
