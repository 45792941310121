import { ColumnDef } from '@tanstack/react-table'

import { CourseMetadata } from '@cais-group/shared/util/type/caisiq-be'

import { CourseTableTooltip } from './courses-table-tooltip'
import { NameCell } from './name-cell'
import { RadioCell } from './radio-cell'
import { ThemeCell } from './theme.cell'

export const CellWidths = {
  smallCell: 100,
  labelCell: 214,
  giantCell: 326,
} as const

export const courseConfigColumns: ColumnDef<CourseMetadata>[] = [
  {
    header: 'Course name',
    accessorKey: 'fullname',
    id: 'fullname',
    sortingFn: 'alphanumeric',
    cell: ({ getValue, row }) => (
      <NameCell image={row.original.imageUrl} name={getValue<string>()} />
    ),
    size: CellWidths.giantCell,
    meta: {
      ratio: 4,
    },
  },
  {
    header: 'Group',
    accessorKey: 'theme',
    sortingFn: 'alphanumeric',
    id: 'theme',
    cell: ({ row, getValue }) => (
      <ThemeCell theme={getValue<string>()} row={row} />
    ),
    size: CellWidths.labelCell,
    meta: {
      ratio: 2,
    },
  },
  {
    header: () => (
      <div className="flex items-center gap-4">
        Mandatory
        <CourseTableTooltip title="Course will appear on the CAIS IQ homepage" />
      </div>
    ),
    enableSorting: false,
    accessorKey: 'enrollType',
    sortingFn: 'alphanumeric',
    id: 'mandatory',
    cell: ({ row }) => <RadioCell row={row} enrollType="MANDATORY" />,
    size: CellWidths.smallCell,
    meta: {
      ratio: 1,
      alignment: 'center',
    },
  },
  {
    header: () => (
      <div className="flex items-center gap-4">
        Elective
        <CourseTableTooltip title="Course will appear within the elective library" />
      </div>
    ),
    accessorKey: 'enrollType',
    sortingFn: 'alphanumeric',
    id: 'elective',
    enableSorting: false,
    cell: ({ row }) => <RadioCell row={row} enrollType="ELECTIVE" />,
    size: CellWidths.smallCell,
    meta: {
      ratio: 1,
      alignment: 'center',
    },
  },
  {
    header: () => (
      <div className="flex items-center gap-4">
        Custom
        <CourseTableTooltip title="Course assigned to a subset of users via ALM. Will appear on CAIS IQ homepage" />
      </div>
    ),
    accessorKey: 'enrollType',
    sortingFn: 'alphanumeric',
    id: 'custom',
    enableSorting: false,
    cell: ({ row }) => <RadioCell row={row} enrollType="MANUAL" />,
    size: CellWidths.smallCell,
    meta: {
      ratio: 0,
      alignment: 'center',
    },
  },
]
