import { ClientError } from 'graphql-request'
import { QueryObserverResult } from 'react-query'
export class ApiError extends Error {
  originalError?: string | ApiError
  httpStatus?: number
  constructor(options: {
    message: string
    originalError?: string | ApiError
    httpStatus?: number
  }) {
    super(options.message)
    this.name = this.constructor.name
    this.originalError = options.originalError
    this.httpStatus = options.httpStatus
  }
}

export enum ApiStateEnum {
  LOADING = 'LOADING',
  INIT = 'INIT',
}

export type ApiState<T> = T | ApiError | ApiStateEnum

export const isApiState = <T>(
  maybeApiState: ApiState<T> | unknown
): maybeApiState is ApiState<T> => {
  return (
    maybeApiState === ApiStateEnum.LOADING ||
    maybeApiState === ApiStateEnum.INIT ||
    maybeApiState instanceof ApiError
  )
}

export const isQueryObserverResult = (
  maybeQueryObserverResult: unknown
): maybeQueryObserverResult is QueryObserverResult<unknown, unknown> => {
  return (
    typeof maybeQueryObserverResult === 'object' &&
    maybeQueryObserverResult !== null &&
    'status' in maybeQueryObserverResult
  )
}

export const isData = <T>(apiState: ApiState<T>): apiState is T => {
  return (
    apiState !== ApiStateEnum.LOADING &&
    apiState !== ApiStateEnum.INIT &&
    !(apiState instanceof ApiError)
  )
}

type GQLCollection<TData> = {
  items: Array<TData>
}

export function parseFromCollection<TCollection, TData>(
  collection: TCollection | undefined,
  name: keyof TCollection
) {
  if (!collection) {
    return undefined
  }

  const collectionObject = collection[name] as GQLCollection<TData>

  if (!('items' in collectionObject)) {
    return undefined
  }

  return collectionObject['items'][0]
}

export const isError = (apiState: ApiState<unknown>): apiState is ApiError =>
  apiState instanceof ApiError

export function useReactQueryResultAsApiState<TQuery, TData>(
  result: QueryObserverResult<TQuery, ClientError>,
  extractData: (data: TQuery) => TData,
  errorMessage: string
) {
  const { data, isFetching, error } = result

  if (isFetching) {
    return ApiStateEnum.LOADING
  } else if (error) {
    return new ApiError({
      message: errorMessage,
      originalError: error,
    })
  } else if (data) {
    return extractData(data)
  }
  return ApiStateEnum.INIT
}
