import { AxiosError } from 'axios'
import { UseFormSetError } from 'react-hook-form'

import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { ErrorResponse } from '@cais-group/shared/util/type/caisiq-be'

export const experienceResponseFormErrorHandler = (
  e: AxiosError<ErrorResponse>,
  setError: UseFormSetError<ExperienceFields>
) => {
  // 409 only occurs when the label already exists
  if (e.response?.status === 409) {
    setError('label', { message: e.response?.data.errorMessage })
  }
  // We get a 404 when the catalog doesn't have a matching usergroup
  if (e.response?.status === 404) {
    setError('catalogId', { message: e.response?.data.errorMessage })
  }
}
