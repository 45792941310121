import * as R from 'remeda'

import { UniformAny } from '../types'
import { UniformValues } from '../uniform'

/**
 * This function, cleanEmptyObjects, is designed to recursively traverse through an object
 * of any structure and replace any empty strings or undefined values with null.
 * If all properties of an object are null or undefined, the function will replace the entire object with null.
 *
 * It accepts one parameter:
 * 1. obj: An object of generic type T, which extends UniformValues. This is the object to perform the operation upon.
 *
 * The function returns an object of the same type T, with any empty strings or undefined values replaced with null,
 * and objects replaced with null if all their properties are null or undefined.
 *
 * For example:
 *```
 * Input:
 * obj = {
 *  "name": "",
 *  "info": {
 *    "details": undefined
 *  }
 * }
 *```
 *```
 * Output:
 * {
 *  "name": null,
 *  "info": null
 * }
 *```
 */
export function cleanEmptyObjects<T extends UniformValues>(obj: T): T | null {
  if (obj instanceof File) {
    return obj // If the input is a File object, return it as is
  }

  const keys = Object.keys(obj)

  const newObj = keys.reduce((accumulator: UniformAny, key: string) => {
    if (Array.isArray(obj[key])) {
      // Preserve arrays
      accumulator[key] = [...obj[key]]
    } else if (obj[key] && typeof obj[key] === 'object' && obj[key] !== null) {
      accumulator[key] = cleanEmptyObjects(obj[key] as T)
    } else if (obj[key] === '' || obj[key] === undefined) {
      accumulator[key] = null
    } else {
      accumulator[key] = obj[key]
    }
    return accumulator
  }, {} as T)

  if (keys.every((key) => newObj[key] === null || newObj[key] === undefined)) {
    return null
  }
  return newObj
}

function isEmpty(value: unknown) {
  if (value === null || value === undefined) return true
  if (typeof value === 'string' && value.trim() === '') return true
  if (Array.isArray(value) && value.length === 0) return true
  if (
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length === 0
  )
    return true
  return false
}

/**
 *
 * Removes any "empty" value from nested objects
 * e.g.
 * empty {}, null, ' ' etc
 */
export function cleanObject<T extends Record<string, unknown>>(obj: T): T {
  const cleaned = R.pickBy(obj, (value) => !isEmpty(value))

  for (const key of Object.keys(cleaned)) {
    const value = cleaned[key]

    if (typeof value === 'object' && value !== null) {
      Object.assign(cleaned, {
        [key]: Array.isArray(value)
          ? value.map((item) =>
              item && typeof item === 'object'
                ? cleanObject(item as Record<string, unknown>)
                : item
            )
          : cleanObject(value as Record<string, unknown>),
      })
    }
  }

  return cleaned as T
}

export const __SPEC__ = { isEmpty }
