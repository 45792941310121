import { AxiosError } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'

import {
  CourseMetadata,
  ApiPaths,
} from '@cais-group/shared/util/type/caisiq-be'

export function useGetExperienceCoursesMetadata(
  experienceId?: string,
  options?: Omit<
    UseQueryOptions<CourseMetadata[], AxiosError, CourseMetadata[], string>,
    'queryKey'
  >
) {
  return useQuery(
    ApiPaths.getAllCourseMetadataPerExperience.replace(
      ':experienceId',
      experienceId ?? ''
    ),
    {
      ...options,
      enabled: (options?.enabled ?? true) && Boolean(experienceId),
    }
  )
}
