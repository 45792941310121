import {
  Dispatch,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useEffect,
} from 'react'

import { ModalLayout, Variant } from '@cais-group/approved/ui/modal/layout'
import { BodySmall } from '@cais-group/approved/ui/typography'
import { useContentfulNameFromIdMapper } from '@cais-group/caisiq/util/contentful/use-contentful-name-from-id-mapper'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { ExperienceResponse } from '@cais-group/shared/util/type/caisiq-be'

import { useDeleteSwitchExperience } from '../hooks/caisiq-ui-preview-experience-use-delete-switch-experience'
import { useSwitchExperience } from '../hooks/caisiq-ui-preview-experience-use-switch-experience'

export type ConfirmModalSummaryType = {
  body: string
  title: string
  confirmButtonText: string
}

type ConfirmExperienceModalPropTypes = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  modalContent: {
    body: ReactNode
    title: string
    primaryBtnText: string
    variant: Variant
  }
}

export function ConfirmPreviewCoursesExperienceModalContainer(props: {
  isOpen: boolean
  onOpen: Dispatch<SetStateAction<boolean>>
  showDeleteSwitchTab: boolean
  previewedExperience?: ExperienceResponse
}) {
  const { isOpen, onOpen, showDeleteSwitchTab, previewedExperience } = props
  const { switchExperienceMutate } = useSwitchExperience()
  const { deleteSwitchExperienceMutate } = useDeleteSwitchExperience()
  const getContentfulNameById = useContentfulNameFromIdMapper()

  const contentfulName = getContentfulNameById(
    previewedExperience?.contentfulId
  )

  const modalContent = {
    body: !showDeleteSwitchTab ? (
      <BodySmall as="span">
        Confirming you would like to switch your learning experience to{' '}
        <span style={{ fontWeight: 900 }}>{contentfulName}</span>. Please be
        sure to delete your switch experience when you are finished.
      </BodySmall>
    ) : (
      <BodySmall as="span">
        Confirming you would like to delete the switch you created for{' '}
        <span style={{ fontWeight: 900 }}>{contentfulName}</span>, and switch
        back to your default experience.
      </BodySmall>
    ),
    title: !showDeleteSwitchTab
      ? 'Preview courses experience'
      : 'Delete switch experience',
    primaryBtnText: !showDeleteSwitchTab ? 'Switch' : 'Delete',
    variant: !showDeleteSwitchTab
      ? ('primary' as Variant)
      : ('danger' as Variant),
  }
  return (
    <ConfirmPreviewCoursesExperienceModal
      open={isOpen}
      onConfirm={
        !showDeleteSwitchTab
          ? async () => {
              await switchExperienceMutate({
                email: userSettingsService.user.email,
                experience: previewService.enabledFirm
                  ? previewedExperience?.label || ''
                  : 'false',
              })
            }
          : async () =>
              await deleteSwitchExperienceMutate({
                email: userSettingsService.user.email,
              })
      }
      onClose={() => onOpen(false)}
      modalContent={modalContent}
    />
  )
}
function ConfirmPreviewCoursesExperienceModal({
  open,
  onClose,
  onConfirm,
  modalContent,
}: ConfirmExperienceModalPropTypes) {
  const handleClosedModal = (e: globalThis.MouseEvent | MouseEvent) => {
    e.stopPropagation()
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClosedModal, false)
    }
    return () => {
      document.removeEventListener('click', handleClosedModal, false)
    }
  }, [open])

  return (
    <div onClick={handleClosedModal}>
      <ModalLayout
        open={open}
        onClose={() => onClose()}
        title={modalContent.title}
        actions={[
          {
            variant: 'secondary',
            handler: () => onClose(),
            text: 'Cancel',
          },
          {
            variant: modalContent.variant,
            handler: () => {
              onConfirm()
            },
            text: modalContent.primaryBtnText,
            testId: 'confirm-switch',
          },
        ]}
        body={modalContent.body}
      />
    </div>
  )
}
