import styled from '@emotion/styled'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import type { UIEvent, ReactNode, MutableRefObject, ForwardedRef } from 'react'

import { Color } from '@cais-group/equity/particles/colors'
import { isHorizontal, isReverse } from '@cais-group/shared/util/js/common'
import { Side } from '@cais-group/shared/util/type/side'

import { FadeDefault } from './fade-default'

export type FadeScrollProps = {
  length?: string
  side?: Side
  children?: ReactNode
  fadeColor?: Color
  testId?: string
  className?: string
}

// Flex properties required for safari only
const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

// Since there is no global styling for scrollbar, we need to add it here
export const ScrollbarWrapper = ({ children }: { children: ReactNode }) => (
  <div className="max-h-376 md:max-h-608 [&::-webkit-scrollbar-thumb]:bg-primary-600 [scrollbar-color:rgb(var(--colors-primary-600)) rgb(var(--colors-neutral-100))] flex flex-none overflow-x-hidden overflow-y-scroll [-webkit-overflow-scrolling-x:hidden] [-webkit-overflow-scrolling-y:scroll] lg:h-[99%] lg:max-h-none [&::-webkit-scrollbar-thumb]:!h-[50px] [&::-webkit-scrollbar-thumb]:rounded-lg [&::-webkit-scrollbar-thumb]:border-4 [&::-webkit-scrollbar-thumb]:border-neutral-800 [&::-webkit-scrollbar-thumb]:bg-clip-content [&::-webkit-scrollbar-track]:bg-neutral-100 [&::-webkit-scrollbar]:w-8 [&::-webkit-scrollbar]:bg-neutral-800 ">
    {children}
  </div>
)

export const FadeScroll = forwardRef(
  (props: FadeScrollProps, ref?: ForwardedRef<HTMLDivElement>) => {
    const {
      length = '20%',
      side = Side.BOTTOM,
      children,
      fadeColor,
      testId,
      className,
    } = props

    const [animatedLength, setAnimatedLength] = useState(length)
    const [containerLength, setContainerLength] = useState(0)
    const [contentLength, setContentLength] = useState(0)
    const [scrollLength, setScrollLength] = useState(0)

    const ownContainerRef =
      useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
    const containerRef = (
      ref ? ref : ownContainerRef
    ) as MutableRefObject<HTMLDivElement>
    const contentRef =
      useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>

    const handleScroll = useCallback(
      (event: UIEvent<HTMLElement>) => {
        const lengthProperty = isHorizontal(side) ? 'scrollLeft' : 'scrollTop'
        containerRef.current?.[lengthProperty] !== undefined &&
          setScrollLength(containerRef.current?.[lengthProperty])
      },
      [containerRef, side]
    )

    const updateMeasurements = useCallback(
      (side: Side) => {
        const lengthProperty = isHorizontal(side)
          ? 'clientWidth'
          : 'clientHeight'
        containerRef?.current?.[lengthProperty] !== undefined &&
          setContainerLength(containerRef?.current?.[lengthProperty])

        contentRef?.current?.[lengthProperty] !== undefined &&
          setContentLength(contentRef?.current?.[lengthProperty])
      },
      [containerRef]
    )

    useEffect(() => {
      const resizeObserver = new ResizeObserver(() => updateMeasurements(side))
      resizeObserver.observe(containerRef.current)
      resizeObserver.observe(contentRef.current)
      return () => resizeObserver.disconnect()
    }, [side, containerRef, updateMeasurements])

    useEffect(() => {
      const totalScroll = Math.max(0, contentLength - containerLength)
      if (totalScroll > 0) {
        const fractionScrolled = isReverse(side)
          ? scrollLength / totalScroll
          : 1 - scrollLength / totalScroll

        setAnimatedLength(`calc(${length} * ${fractionScrolled})`)
      } else {
        setAnimatedLength(length)
      }
    }, [contentLength, containerLength, scrollLength, length, side])

    return (
      <Container data-testid={testId} className={className}>
        <FadeDefault
          side={side}
          length={animatedLength}
          allowScroll
          contentRef={contentRef}
          containerRef={containerRef}
          onScroll={handleScroll}
          fadeColor={fadeColor}
        >
          {children}
        </FadeDefault>
      </Container>
    )
  }
)
