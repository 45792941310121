import styled from '@emotion/styled'
import { useFormContext } from 'react-hook-form'

import { H4Strong } from '@cais-group/approved/ui/typography'
import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import { ManageCaisIqIcon } from '@cais-group/caisiq/ui/icons'
import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { Button } from '@cais-group/equity/atoms/button'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { UserRole } from '@cais-group/shared/util/type/caisiq-be'

const StyledButton = styled(Button)`
  place-self: end;
`
const StyledFirmSettingsHeaderContainer = styled.span`
  display: flex;
  height: calc(${SPACING.s24} * 2);
  align-items: center;
  justify-content: space-between;
  gap: ${SPACING.s16};
  grid-area: head;
  grid-column: span 2;
`
const IconContainer = styled.div`
  display: flex;
  height: calc(${SPACING.s24} * 2);
  align-items: center;
  gap: ${SPACING.s16};
`
const StyledH4Strong = styled(H4Strong)<{ color: string }>`
  color: ${({ color }) => color};
`
export const EditFirmsHeader = () => {
  const { formState } = useFormContext<ExperienceFields>()
  const { isValid, dirtyFields } = formState

  // This is necessary because the formState is not dirty for some reason when creating a new experience
  const isDirty = Object.keys(dirtyFields).length > 0
  return (
    <StyledFirmSettingsHeaderContainer>
      <IconContainer>
        <ManageCaisIqIcon />
        <StyledH4Strong color={colors['eq-color-neutral-900']}>
          CAIS IQ
        </StyledH4Strong>
      </IconContainer>
      <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
        <StyledButton
          data-testid="submit-update-firm-form"
          key="save"
          type="submit"
          disabled={!isDirty || !isValid}
        >
          Save
        </StyledButton>
      </HasPermissions>
    </StyledFirmSettingsHeaderContainer>
  )
}
