import { useMutation } from 'react-query'

import { defaultPersister } from '@cais-group/caisiq/util/default-query-function'
import {
  useApiPathWithParams,
  useApiPathWithPayload,
} from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import {
  ApiPaths,
  CaisIqFirm,
  UpdateFirmRequest,
} from '@cais-group/shared/util/type/caisiq-be'

export function usePutFirm(version?: number) {
  const parsedPath = useApiPathWithParams(ApiPaths.createOrUpdateFirm)
  const getParsedEndpoint = useApiPathWithPayload(parsedPath)
  return useMutation((data: UpdateFirmRequest) =>
    defaultPersister<UpdateFirmRequest, CaisIqFirm>(
      getParsedEndpoint(data),
      data,
      version,
      'PUT'
    )
  )
}
