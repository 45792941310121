import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  QueryObserverOptions,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  QueryFunctionContext,
  useQueryClient,
} from 'react-query'

import { getAxiosInstance } from '@cais-group/caisiq/util/hook/use-axios-instance'
import { showToast } from '@cais-group/equity/organisms/notifications'
import {
  ApiPaths,
  SearchRequestFilterSort,
} from '@cais-group/shared/util/type/caisiq-be'
const FIVE_MINUTES = 1000 * 60 * 5
const DEFAULT_PAGE_SIZE = 20

export type UseInfiniteOptionsApiEndpointOptions<TData> = Partial<
  Omit<
    UseInfiniteQueryOptions<TData, AxiosError, TData, QueryKey>,
    'queryFn' | 'queryKey'
  > &
    (Omit<SearchRequestFilterSort, 'pageRequest'> | undefined) & {
      searchTerm?: string
    }
>

const defaultQuerier: QueryFunction<AxiosResponse> = ({
  queryKey,
  pageParam,
}) => {
  const axios = getAxiosInstance()

  const apiPath = queryKey[0] as string
  const queryParams = queryKey[1] as Record<string, string>

  return axios
    .get(apiPath, { params: { page: pageParam, ...queryParams } })
    .then((response) => response.data)
}

export const defaultPersister = <
  TData extends Record<string, unknown>,
  TResponse
>(
  apiPath: string,
  data?: TData,
  version?: number,
  method: AxiosRequestConfig['method'] = undefined
) => {
  const axios = getAxiosInstance()

  const headers = version ? { 'If-Match': version } : {}

  return axios({
    method:
      method || (data && ('id' in data || 'uuid' in data) ? 'PUT' : 'POST'),
    url: apiPath,
    data,
    headers,
  }).then((response: AxiosResponse<TResponse>) => response.data)
}

export interface SearchResponse<T> {
  items: T
  pageInfo: {
    hasNextPage: boolean
    hasPreviousPage: boolean
  }
}

export function defaultSearchQuerier<T, TQueryKey extends QueryKey>({
  queryKey,
  pageParam,
}: QueryFunctionContext<TQueryKey>) {
  const axios = getAxiosInstance()

  const apiPath = queryKey[0] as string
  const queryParams = queryKey[1] as Record<string, string>

  return axios
    .post<SearchResponse<T>>(apiPath, { page: pageParam, ...queryParams })
    .then((response) => response.data.items)
}

export const useDefaultInfiniteQueryFunction = <TData>(
  options: UseInfiniteOptionsApiEndpointOptions<TData>,
  endpoint: ApiPaths
): QueryFunction<TData> => {
  const axios = getAxiosInstance()

  return async ({ pageParam = null }: { pageParam?: unknown }) =>
    axios
      .post(endpoint, {
        sort: options.sort,
        pageRequest: { first: DEFAULT_PAGE_SIZE, after: pageParam },
        filter: options.filter,
      })
      .then((r) => r.data)
}

export const queryDefaults: QueryObserverOptions = {
  onError: (error) => {
    const apiError = error as AxiosError
    console.error(
      `There was a problem retrieving data from an API call to ${apiError.config?.url}`,
      apiError,
      apiError.response?.status
    )
    showToast({
      type: 'error',
      title: 'An error occurred, some features may be unavailable.',
    })
  },
  refetchOnMount: false,
  retry: 1,
  staleTime: FIVE_MINUTES,
  notifyOnChangeProps: 'tracked',
  queryFn: defaultQuerier,
}

export function useInvalidateByRegex() {
  const queryClient = useQueryClient()

  // Remove any queries cache keys that match the URL patterns to prevent them
  // from being refetched on invalidation.
  const removeCacheKeyPatterns = [
    /experiences[/]undefined/,
    /firms[/]undefined/,
    /experiences[/][/]/,
  ]
  queryClient.removeQueries({
    predicate: ({ queryKey }) => {
      const queryCacheKey = Array.isArray(queryKey) ? queryKey[0] : queryKey
      for (const pattern of removeCacheKeyPatterns) {
        if (pattern.test(queryCacheKey)) {
          return true
        }
      }
      return false
    },
  })

  return async (r: RegExp) =>
    // @todo: Do we want to refetch all inactive queries? This can be a lot of data.
    await queryClient.invalidateQueries({
      active: true,
      inactive: true,
      refetchInactive: true,
      predicate: ({ queryKey }) =>
        r.test(Array.isArray(queryKey) ? queryKey[0] : queryKey),
    })
}
