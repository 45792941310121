import cx from 'classnames'

import { ButtonLink } from '@cais-group/equity/atoms/button'
import { isExternalLink } from '@cais-group/equity/atoms/link'
import { type Tracking } from '@cais-group/equity/util/tracking-utils'
import { ContentfulImage } from '@cais-group/shared/ui/contentful-image'

import { CardBase } from './card-base'
import { ExternalCardDescription } from './components/external-card-description'
import { DateTime } from './date-time'
import { FallbackImage } from './fallback-image'
import { getContentOwner, getPageType } from './helper'
import { OwnershipTag } from './ownership-tag'
import { TagAndTime } from './tag-and-time'
import type { CardProps } from './types'

export type FeaturedCardProps = CardProps &
  Tracking & {
    dateTime?: string
  }

export const CardFeatured = (props: FeaturedCardProps) => {
  const {
    callToAction,
    date,
    description,
    externalContentDetails,
    href,
    imageComponent,
    includeTime,
    thumbnail,
    timezone,
    title,
    type,
    testId,
    contentOwnershipTag,
    tracking,
  } = props
  const Image = imageComponent || ContentfulImage
  const withExternalContent = externalContentDetails && type

  return (
    <CardBase variant="featured" testId={`card-${testId}`} tracking={tracking}>
      <div className="group basis-full space-y-8 pt-16 md:basis-3/5 md:pt-0">
        <hgroup className="flex flex-col gap-8">
          {contentOwnershipTag?.name && (
            <OwnershipTag name={contentOwnershipTag.name} />
          )}
          <h3 className="headline-m-strong text-primary-600 group-hover:text-primary-700 line-clamp-2">
            {title}
          </h3>
          <DateTime date={date} includeTime={includeTime} timezone={timezone} />
          {description ? (
            <p
              className={cx('body line-clamp-3 text-neutral-600', {
                'mb-16': withExternalContent,
              })}
            >
              {description}
            </p>
          ) : null}
          {withExternalContent ? (
            <ExternalCardDescription
              externalContentDetails={externalContentDetails}
              withVerticalBorder
            />
          ) : null}
          <div className="mt-16 lg:mt-24">
            <ButtonLink
              href={href}
              tracking={{
                click_type: 'Content',
                content_type: getPageType(props),
                content_owner: getContentOwner(props),
                item_name: title,
              }}
              {...(isExternalLink(href) && { endAdornment: 'Launch' })}
            >
              {callToAction}
            </ButtonLink>
          </div>
        </hgroup>
      </div>
      <div className="relative order-first basis-full md:order-last md:basis-2/5">
        {thumbnail ? (
          <Image
            src={thumbnail.url}
            layout="fullWidth"
            loading="eager"
            alt={thumbnail.alt || thumbnail.title || ''}
          />
        ) : (
          <FallbackImage />
        )}
      </div>
    </CardBase>
  )
}

/**
 * @deprecated - delete when new layout is fully implemented
 */
export const CardFeaturedDeprecated = (props: FeaturedCardProps) => {
  const {
    callToAction,
    date,
    description,
    externalContentDetails,
    href,
    imageComponent,
    includeTime,
    thumbnail,
    timezone,
    title,
    type,
    testId,
    tracking,
    contentOwnershipTag,
  } = props
  const Image = imageComponent || ContentfulImage
  const withExternalContent = externalContentDetails && type

  return (
    <CardBase variant="featured" testId={`card-${testId}`} tracking={tracking}>
      <div className="space-y-8 p-24 lg:basis-1/2">
        <h2 className="overtext">Featured content</h2>
        <hgroup className="flex flex-col gap-8">
          <h3 className="headline-l line-clamp-2">{title}</h3>

          <TagAndTime
            contentOwnershipTag={contentOwnershipTag}
            date={date}
            includeTime={includeTime}
            timezone={timezone}
          />

          {description ? (
            <p
              className={cx('body line-clamp-3 text-neutral-600', {
                'mb-16': withExternalContent,
              })}
            >
              {description}
            </p>
          ) : null}
          {withExternalContent ? (
            <ExternalCardDescription
              externalContentDetails={externalContentDetails}
              withVerticalBorder
            />
          ) : null}
          <div className="mt-24">
            <ButtonLink
              href={href}
              tracking={{
                click_type: 'Content',
                content_type: getPageType(props),
                content_owner: getContentOwner(props),
                item_name: title,
              }}
              {...(isExternalLink(href) && { endAdornment: 'Launch' })}
            >
              {callToAction}
            </ButtonLink>
          </div>
        </hgroup>
      </div>
      <div className="relative order-first basis-3/4 md:basis-2/3 lg:basis-1/2">
        {thumbnail ? (
          <Image
            src={thumbnail.url}
            layout="fullWidth"
            loading="eager"
            alt={thumbnail.alt || thumbnail.title || ''}
          />
        ) : (
          <FallbackImage />
        )}
      </div>
    </CardBase>
  )
}
