import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { Label } from '@cais-group/approved/ui/typography'
import { Avatar } from '@cais-group/equity/atoms/avatar'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'

const FirmName = styled.div<{ isDimmed: boolean }>`
  display: flex;
  align-items: center;
  column-gap: ${SPACING.s16};

  > div:first-of-type > p:first-of-type {
    background: ${({ isDimmed }) => isDimmed && colors['eq-color-neutral-0']};
    color: ${({ isDimmed }) => isDimmed && colors['eq-color-neutral-200']};
    height: 22px;
  }
`

interface FirmNameBoxProps {
  name: string
  isDimmed?: boolean
  color?: string
  children: ReactNode
}

export function FirmNameBox(props: FirmNameBoxProps) {
  return (
    <FirmName isDimmed={props.isDimmed ?? false}>
      <Avatar testId="manage" type="group">
        {props.name}
      </Avatar>
      <Label
        style={{
          color: props.color,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {props.children}
      </Label>
    </FirmName>
  )
}
