import { memoize } from 'lodash-es'
import { difference } from 'remeda'

let lastFallbackImageIndex: number | undefined = undefined

// Since this gets called on every render we memoize with the course id
// to ensure that each course always ends up with the same fallback image, without
// this, as the assignment is randomised the courses will end up switching
// fallbacks on every render and get out of sync and display centered fallback
// images rather than bottom right aligned
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getNextFallbackImageIndex = memoize((courseId: string) => {
  const allIndices = [0, 1, 2, 3]
  const availableIndices =
    lastFallbackImageIndex === undefined
      ? allIndices
      : difference(allIndices, [lastFallbackImageIndex])
  const index =
    availableIndices[Math.floor(Math.random() * availableIndices.length)]
  return (lastFallbackImageIndex = index)
})
