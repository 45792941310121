import styled from '@emotion/styled'
import { Checkbox } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'

import { BodySmall } from '@cais-group/approved/ui/typography'
import { colors } from '@cais-group/equity/particles/colors'
import { CopyIcon } from '@cais-group/shared/ui/copy-icon'
import { moreExactPercentageFormatter } from '@cais-group/shared/util/number'
import { CourseMetadata } from '@cais-group/shared/util/type/caisiq-be'

import { NameCell } from './name-cell'

const ColumnCheckBox = styled(Checkbox)`
  padding: 0;
  color: ${colors['eq-color-neutral-500']};
`
const RowCheckBox = styled(Checkbox)`
  padding: 0;
  color: ${colors['eq-color-neutral-300']};
`

const IndeterminateCheckBoxIcon = styled(Checkbox)`
  padding: 0;
`

export const globalCoursesColumns: ColumnDef<CourseMetadata>[] = [
  {
    id: 'select',
    enableSorting: false,
    size: 64,
    meta: {
      ratio: 0,
    },
    header: ({ table }) => (
      <ColumnCheckBox
        disableRipple={true}
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
        sx={{
          '&.Mui-checked': {
            color: `${colors['eq-color-neutral-100']}`,
          },
        }}
        indeterminateIcon={
          <IndeterminateCheckBoxIcon
            disableRipple={true}
            sx={{
              '&.MuiCheckbox-indeterminate': {
                color: `${colors['eq-color-neutral-100']}`,
              },
            }}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        }
      />
    ),
    cell: ({ row }) => (
      <RowCheckBox
        disableRipple={true}
        {...{
          checked: row.getIsSelected(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
        onClick={(e) => e.stopPropagation()}
        sx={{
          '&.Mui-checked': {
            color: `${colors['eq-color-primary-600']}`,
          },
        }}
      />
    ),
  },
  {
    header: 'Course Name',
    accessorKey: 'fullname',
    id: 'fullname',
    sortingFn: 'alphanumeric',
    meta: {
      ratio: 4,
    },
    cell: (info) => {
      const image = info.row.original.imageUrl
      return <NameCell image={image} name={info.getValue<string>()} />
    },
    size: 400,
  },
  {
    header: 'Course ID',
    accessorKey: 'acpCourseId',
    sortingFn: 'alphanumeric',
    meta: {
      ratio: 1,
    },
    id: 'id',
    cell: (info) => (
      <div className="flex items-center">
        <BodySmall className="pr-8">{info.getValue<string>()}</BodySmall>
        <CopyIcon size="tiny" text={info.getValue<string>()} />
      </div>
    ),
  },
  {
    header: 'CFP credits',
    accessorFn: (row) => row.ce?.cfpCeCredit,
    sortingFn: 'alphanumeric',
    id: 'cfp',
    meta: {
      ratio: 0.5,
    },
    cell: (info) => <BodySmall>{info.getValue<string>() ?? '-'}</BodySmall>,
  },
  {
    header: 'CIMA credits',
    accessorFn: (row) => row.ce?.cimaCeCredit,
    sortingFn: 'alphanumeric',
    id: 'cima',
    meta: {
      ratio: 0.5,
    },
    cell: (info) => <BodySmall>{info.getValue<string>() ?? '-'}</BodySmall>,
  },
  {
    header: 'Required score',
    accessorKey: 'requiredAssessmentScore',
    id: 'requiredAssessmentScore',
    meta: {
      ratio: 0.5,
    },
    cell: (info) => (
      <BodySmall>
        {moreExactPercentageFormatter({
          value: info.getValue<number>(),
          decimalPlaces: 0,
        })}
      </BodySmall>
    ),
  },
]
