export const staticGridClassDict: Record<string, string> = {
  'col-span-1': 'lg:col-span-1',
  'col-span-2': 'lg:col-span-2',
  'col-span-3': 'lg:col-span-3',
  'col-span-4': 'lg:col-span-4',
  'col-span-5': 'lg:col-span-5',
  'col-span-6': 'lg:col-span-6',
  'col-span-7': 'lg:col-span-7',
  'col-span-8': 'lg:col-span-8',
  'col-span-9': 'lg:col-span-9',
  'col-span-10': 'lg:col-span-10',
  'col-span-11': 'lg:col-span-11',
  'col-span-12': 'lg:col-span-12',
  'col-span-13': 'lg:col-span-13',
  'col-start-1-end-2': 'lg:col-start-1 lg:col-end-2',
  'col-start-1-end-3': 'lg:col-start-1 lg:col-end-3',
  'col-start-1-end-4': 'lg:col-start-1 lg:col-end-4',
  'col-start-1-end-5': 'lg:col-start-1 lg:col-end-5',
  'col-start-1-end-6': 'lg:col-start-1 lg:col-end-6',
  'col-start-1-end-7': 'lg:col-start-1 lg:col-end-7',
  'col-start-1-end-8': 'lg:col-start-1 lg:col-end-8',
  'col-start-1-end-9': 'lg:col-start-1 lg:col-end-9',
  'col-start-1-end-10': 'lg:col-start-1 lg:col-end-10',
  'col-start-1-end-11': 'lg:col-start-1 lg:col-end-11',
  'col-start-1-end-12': 'lg:col-start-1 lg:col-end-12',
  'col-start-1-end-13': 'lg:col-start-1 lg:col-end-13',
  'col-start-2-end-3': 'lg:col-start-2 lg:col-end-3',
  'col-start-2-end-4': 'lg:col-start-2 lg:col-end-4',
  'col-start-2-end-5': 'lg:col-start-2 lg:col-end-5',
  'col-start-2-end-6': 'lg:col-start-2 lg:col-end-6',
  'col-start-2-end-7': 'lg:col-start-2 lg:col-end-7',
  'col-start-2-end-8': 'lg:col-start-2 lg:col-end-8',
  'col-start-2-end-9': 'lg:col-start-2 lg:col-end-9',
  'col-start-2-end-10': 'lg:col-start-2 lg:col-end-10',
  'col-start-2-end-11': 'lg:col-start-2 lg:col-end-11',
  'col-start-2-end-12': 'lg:col-start-2 lg:col-end-12',
  'col-start-2-end-13': 'lg:col-start-2 lg:col-end-13',
  'col-start-3-end-4': 'lg:col-start-3 lg:col-end-4',
  'col-start-3-end-5': 'lg:col-start-3 lg:col-end-5',
  'col-start-3-end-6': 'lg:col-start-3 lg:col-end-6',
  'col-start-3-end-7': 'lg:col-start-3 lg:col-end-7',
  'col-start-3-end-8': 'lg:col-start-3 lg:col-end-8',
  'col-start-3-end-9': 'lg:col-start-3 lg:col-end-9',
  'col-start-3-end-10': 'lg:col-start-3 lg:col-end-10',
  'col-start-3-end-11': 'lg:col-start-3 lg:col-end-11',
  'col-start-3-end-12': 'lg:col-start-3 lg:col-end-12',
  'col-start-3-end-13': 'lg:col-start-3 lg:col-end-13',
  'col-start-4-end-5': 'lg:col-start-4 lg:col-end-5',
  'col-start-4-end-6': 'lg:col-start-4 lg:col-end-6',
  'col-start-4-end-7': 'lg:col-start-4 lg:col-end-7',
  'col-start-4-end-8': 'lg:col-start-4 lg:col-end-8',
  'col-start-4-end-9': 'lg:col-start-4 lg:col-end-9',
  'col-start-4-end-10': 'lg:col-start-4 lg:col-end-10',
  'col-start-4-end-11': 'lg:col-start-4 lg:col-end-11',
  'col-start-4-end-12': 'lg:col-start-4 lg:col-end-12',
  'col-start-4-end-13': 'lg:col-start-4 lg:col-end-13',
  'col-start-5-end-6': 'lg:col-start-5 lg:col-end-6',
  'col-start-5-end-7': 'lg:col-start-5 lg:col-end-7',
  'col-start-5-end-8': 'lg:col-start-5 lg:col-end-8',
  'col-start-5-end-9': 'lg:col-start-5 lg:col-end-9',
  'col-start-5-end-10': 'lg:col-start-5 lg:col-end-10',
  'col-start-5-end-11': 'lg:col-start-5 lg:col-end-11',
  'col-start-5-end-12': 'lg:col-start-5 lg:col-end-12',
  'col-start-5-end-13': 'lg:col-start-5 lg:col-end-13',
  'col-start-6-end-7': 'lg:col-start-6 lg:col-end-7',
  'col-start-6-end-8': 'lg:col-start-6 lg:col-end-8',
  'col-start-6-end-9': 'lg:col-start-6 lg:col-end-9',
  'col-start-6-end-10': 'lg:col-start-6 lg:col-end-10',
  'col-start-6-end-11': 'lg:col-start-6 lg:col-end-11',
  'col-start-6-end-12': 'lg:col-start-6 lg:col-end-12',
  'col-start-6-end-13': 'lg:col-start-6 lg:col-end-13',
  'col-start-7-end-8': 'lg:col-start-7 lg:col-end-8',
  'col-start-7-end-9': 'lg:col-start-7 lg:col-end-9',
  'col-start-7-end-10': 'lg:col-start-7 lg:col-end-10',
  'col-start-7-end-11': 'lg:col-start-7 lg:col-end-11',
  'col-start-7-end-12': 'lg:col-start-7 lg:col-end-12',
  'col-start-7-end-13': 'lg:col-start-7 lg:col-end-13',
  'col-start-8-end-9': 'lg:col-start-8 lg:col-end-9',
  'col-start-8-end-10': 'lg:col-start-8 lg:col-end-10',
  'col-start-8-end-11': 'lg:col-start-8 lg:col-end-11',
  'col-start-8-end-12': 'lg:col-start-8 lg:col-end-12',
  'col-start-8-end-13': 'lg:col-start-8 lg:col-end-13',
  'col-start-9-end-10': 'lg:col-start-9 lg:col-end-10',
  'col-start-9-end-11': 'lg:col-start-9 lg:col-end-11',
  'col-start-9-end-12': 'lg:col-start-9 lg:col-end-12',
  'col-start-9-end-13': 'lg:col-start-9 lg:col-end-13',
  'col-start-10-end-11': 'lg:col-start-10 lg:col-end-11',
  'col-start-10-end-12': 'lg:col-start-10 lg:col-end-12',
  'col-start-10-end-13': 'lg:col-start-10 lg:col-end-13',
  'col-start-11-end-12': 'lg:col-start-11 lg:col-end-12',
  'col-start-11-end-13': 'lg:col-start-11 lg:col-end-13',
  'col-start-12-end-13': 'lg:col-start-12 lg:col-end-13',
}

// NOTE: generates static grid class map
// const gridClassMap: Record<string, string> = {
//   ...Array.from({ length: 13 }, (_, i) => i + 1).reduce((acc, i) => {
//     acc[`col-span-${i}`] = `lg:col-span-${i}`
//     return acc
//   }, {} as Record<string, string>),
//   ...Array.from({ length: 13 }, (_, i) => i + 1)
//     .flatMap((start) =>
//       Array.from({ length: 13 - start }, (_, i) => i + start + 1).map(
//         (end) => ({
//           [`col-start-${start}-end-${end}`]: `lg:col-start-${start} lg:col-end-${end}`,
//         })
//       )
//     )
//     .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
// }
