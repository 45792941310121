import { useEffect } from 'react'

import { APP_IDS } from '@cais-group/shared/domain/apps'
import { initEnvConfig, setEnvConfig, MfeEnv } from '@cais-group/shared/ui/env'

/**
 * This hooks inits and extends the current env config with the app specific
 * one (.env)
 */
export function useInitEnvConfig<CustomEnvVars>({
  appId,
  envConfig,
  initAppSpecificEnvConfig = false,
}: {
  appId: APP_IDS
  envConfig: MfeEnv
  initAppSpecificEnvConfig: boolean
}) {
  useEffect(() => {
    setEnvConfig(envConfig)
    const appendAppSpecificEnvConfig = async () => {
      const appSpecificConfig = await initEnvConfig<CustomEnvVars>(
        `/${appId}/env.json`
      )
      setEnvConfig({ ...envConfig, ...appSpecificConfig })
    }
    if (initAppSpecificEnvConfig) {
      appendAppSpecificEnvConfig()
    }
  }, [appId, envConfig, initAppSpecificEnvConfig])
}
