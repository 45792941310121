import { ReactNode } from 'react'

import { Checkbox } from '@cais-group/equity/atoms/checkbox'
import { LabelWithControl } from '@cais-group/shared/ui/label-with-control'

export interface ChecklistItemProps<T extends string = string> {
  label: string | ReactNode
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void
  onUpdate?: (value: T, wasPreviouslySelected: boolean) => void
  disabledOptions?: T[]
  disabled?: boolean
  selectedOptions: T[]
  testId: string
  value: T
  indeterminate?: boolean
}
export const ChecklistItem = <T extends string = string>({
  label,
  name,
  onChange,
  onUpdate,
  disabledOptions,
  disabled,
  selectedOptions,
  testId,
  value,
  indeterminate,
}: ChecklistItemProps<T>) => {
  const isDisabled =
    disabled ||
    (disabledOptions
      ? disabledOptions.includes(value) && !selectedOptions?.includes(value)
      : false)
  return (
    <LabelWithControl
      label={label}
      disabled={isDisabled}
      control={
        <div className="mr-16 p-0">
          <Checkbox
            name={name}
            checked={selectedOptions?.includes(value)}
            data-testid={`${testId}-${value}`}
            disabled={isDisabled}
            onChange={() => {
              const wasPreviouslySelected = selectedOptions?.includes(value)
              onChange(
                wasPreviouslySelected
                  ? selectedOptions?.filter((sO: T) => sO !== value)
                  : [...selectedOptions, value]
              )
              onUpdate?.(value, wasPreviouslySelected)
            }}
            indeterminate={indeterminate}
          />
        </div>
      }
    />
  )
}
