import styled from '@emotion/styled'

import { BodySmall, H4Strong } from '@cais-group/approved/ui/typography'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Button } from '@cais-group/equity/atoms/button'
import { LayoutCluster, LayoutStack } from '@cais-group/shared/ui/layout'
import { TypeCallToActionData } from '@cais-group/shared/util/type/call-to-action-data'

export type BannerWithButtonProps = {
  callToAction: TypeCallToActionData
  destination?: string
  onClick?: () => void
}

export const BannerWithButtonWrap = styled(LayoutCluster)`
  margin: var(--s56) 0;
  padding: 2.5rem;
  background-color: rgb(var(--colors-primary-500));
  color: rgb(var(--colors-neutral-0));
  width: 100%;
`
export const LeftInfo = styled(LayoutStack)``
export const Title = styled(H4Strong)``
export const Description = styled(BodySmall)`
  color: rgb(var(--colors-primary-200));
  margin-top: var(--s8);
  max-width: calc(var(--s608) + var(--s24));
`

export const BannerWithButton = ({
  callToAction,
  onClick,
}: BannerWithButtonProps) => {
  return (
    <BannerWithButtonWrap justify="space-between">
      <LeftInfo>
        <Title>{callToAction.title}</Title>
        <Description>{callToAction.description}</Description>
      </LeftInfo>
      <Button
        inverse
        onClick={() => {
          onClick && onClick()
          window.location.href = callToAction.destination
        }}
      >
        {callToAction.buttonText}
      </Button>
    </BannerWithButtonWrap>
  )
}

export const BannerWithButtonContainer = () => {
  const experience = userSettingsService.theme

  if (experience?.joinCaisBanner && experienceService.isDirect()) {
    return (
      <BannerWithButton
        callToAction={experience.joinCaisBanner}
        onClick={() => trackingService.signUpFreeClicked()}
      ></BannerWithButton>
    )
  }
  return null
}
