import CloseIcon from '@mui/icons-material/Close'

import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import { Button } from '@cais-group/equity/atoms/button'
import { UserRole } from '@cais-group/shared/util/type/caisiq-be'
export const EnableButton = ({
  onEnable,
  testId,
}: {
  onEnable: () => void
  testId?: string
}) => {
  return (
    <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
      <HasPermissions.Then>
        <Button
          variant="secondary"
          onClick={(e) => {
            e.stopPropagation()
            onEnable()
          }}
          size="medium"
          data-testid={testId}
        >
          Enable
        </Button>
      </HasPermissions.Then>
      <HasPermissions.Else>
        <CloseIcon className="fill-error-600" aria-label="not-enabled-icon" />
      </HasPermissions.Else>
    </HasPermissions>
  )
}
