import { enabledByAccessControl } from '@cais-group/shared/util/entitled-nav-links'

import { LOGOUT_ACTION } from './constants'
import { linkToCaisIq } from './link-to-cais-iq'
import { EnabledAccessControls, Menu, NavItemLink } from './types'

const logoutNavItem: NavItemLink = {
  testId: 'profile-logout-button',
  name: 'Log Out',
  href: LOGOUT_ACTION,
}

export const getProfileMenu = (
  enabledAccessControls: EnabledAccessControls,
  onLogout?: () => void
): Menu => {
  const navItems: NavItemLink[] = [
    {
      testId: 'profile-button',
      name: 'My Profile',
      href: '/access-manager/profile',
      personas: ['@NOT_EMPTY'],
    },
    {
      testId: 'settings-button',
      name: 'Continuing Education Profile',
      href: linkToCaisIq('authenticated/profile').toString(),
      urlType: 'absolute',
    },
    {
      name: 'Admin',
      href: '/access-manager/admin',
      permissions: ['admin:read'],
    },
    {
      ...(onLogout ? { ...logoutNavItem, onClick: onLogout } : logoutNavItem),
    },
  ]

  return {
    id: 'profile-menu',
    groups: [
      {
        items: navItems.filter((navItem) => {
          return enabledByAccessControl(navItem, enabledAccessControls)
        }),
      },
    ],
  }
}
