import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Link, LinkProps } from 'react-router-dom'

import {
  BodySmall,
  BodyStrong,
  Overline,
  SubtitleMono,
  shouldForwardProp,
} from '@cais-group/approved/ui/typography'
import { Button } from '@cais-group/equity/atoms/button'
import { ellipsify } from '@cais-group/equity/utilitarian'
import { LayoutStack } from '@cais-group/shared/ui/layout'
import type { TypeArticleData } from '@cais-group/shared/util/type/article-data'

export type ArticleCardProps = {
  article: TypeArticleData
  isFeatured?: boolean
  to: LinkProps['to']
  state: LinkProps['state']
  onClick?: () => void
}

const StyledContainer = styled(Link, {
  shouldForwardProp: shouldForwardProp('$isFeatured'),
})<{ $isFeatured?: boolean }>`
  display: flex;
  cursor: pointer;
  min-width: calc(100vw - calc(var(--s56) * 2));
  max-width: calc(100vw - calc(var(--s56) * 2));
  ${({ $isFeatured }) =>
    $isFeatured ? 'margin: var(--s56) 0;' : 'margin: 0 0;'}
  ${({ $isFeatured }) =>
    $isFeatured ? 'flex-direction: row;' : 'flex-direction: column;'}

  ${({ $isFeatured }) =>
    $isFeatured ? 'align-items: center;' : 'align-items: flex-start;'}
  & > img {
    transition: transform 150ms ease-out;
  }

  @media screen and (min-width: 960px) {
    ${({ $isFeatured }) =>
      $isFeatured ? 'min-width: 300px;' : 'min-width: 314px;'}
    ${({ $isFeatured }) =>
      $isFeatured ? 'max-width: 100%;' : 'max-width: 492px;'}
  }
  &:hover > img {
    transform: scale(0.99);
  }
  @media screen and (max-width: 1225px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const ThumbnailWrap = styled.div`
  @media screen and (max-width: 1225px) {
    width: 100%;
  }
`
const Thumbnail = styled.img`
  padding-bottom: var(--s16);
`
Thumbnail.displayName = 'Thumbnail'

const ArticleText = styled(LayoutStack)<{ isFeatured?: boolean }>`
  ${({ isFeatured }) =>
    isFeatured ? 'margin-left: var(--s56);' : 'margin-left: var(--s8);'}
  @media screen and (max-width: 1225px) {
    margin-top: 0;
    margin-left: var(--s8);
  }
`

ArticleText.displayName = 'ArticleText'

const TypePage = styled(Overline)`
  color: rgb(var(--colors-neutral-600));
`

const Subtitle = styled(SubtitleMono)<{
  isFeatured?: boolean
}>`
  color: rgb(var(--colors-neutral-600));
  ${({ isFeatured }) =>
    isFeatured ? 'margin-top:var(--s16);' : 'margin-top: 4px;'};
`
Subtitle.displayName = 'Subtitle'

const StyledName = styled(BodyStrong)<{
  isFeatured?: boolean
}>`
  color: rgb(var(--colors-neutral-900));
  ${({ isFeatured }) =>
    isFeatured ? 'font-size: 2.5rem;' : 'font-size: 1rem;'}
  ${({ isFeatured }) =>
    isFeatured ? 'line-height: var(--s56);' : 'line-height: var(--s24)'};
  ${({ isFeatured }) =>
    isFeatured ? 'margin-top:var(--s16);' : 'margin-top: var(--s8);'};
`
const Description = styled(BodySmall)<{
  isFeatured?: boolean
}>`
  color: rgb(var(--colors-neutral-600));
  padding-top: 0;
  max-width: 40rem;
  ${({ isFeatured }) =>
    isFeatured ? 'margin-top:var(--s16);' : 'margin-top: var(--s8);'};
`

export const ArticleCard = (props: ArticleCardProps) => {
  const { article, to, state, isFeatured, onClick } = props
  return (
    <StyledContainer
      to={to}
      state={state}
      onClick={onClick}
      $isFeatured={isFeatured}
      data-testid={`article-card${isFeatured ? '-featured' : ''}`}
    >
      <ThumbnailWrap>
        <Thumbnail
          src={article.thumbnail?.url ? `${article.thumbnail.url}?fm=jpg` : ''}
        />
      </ThumbnailWrap>
      <ArticleText isFeatured={isFeatured}>
        <TypePage>{isFeatured ? 'Featured' : 'Article'}</TypePage>
        <StyledName isFeatured={isFeatured}>
          {ellipsify(article.title, 75)}
        </StyledName>
        <Subtitle isFeatured={isFeatured}>
          {dayjs(article.displayDate || article.sys.firstPublishedAt).format(
            'MMM D'
          )}{' '}
          {article.author.name
            ? `• by ${article.author.name}`
            : article.author.authorName && `• by ${article.author.authorName}`}
        </Subtitle>
        <Description isFeatured={isFeatured}>{article.description}</Description>
        <div className="mt-32">
          {isFeatured && <Button>Read the article</Button>}
        </div>
      </ArticleText>
    </StyledContainer>
  )
}
