import styled from '@emotion/styled'

import { H5Strong } from '@cais-group/approved/ui/typography'
import { useGetCourseCeCredits } from '@cais-group/caisiq/domain/course'
import { useGetDefaultCourseImageSet } from '@cais-group/caisiq/domain/course-image'
import { getHeaderLabel } from '@cais-group/caisiq/feature/common/get-header-label'
import { CardCourse } from '@cais-group/caisiq/ui/card/course'
import { getNextFallbackImageIndex } from '@cais-group/caisiq/util/js/get-next-fallback-image-index'
import { Pill } from '@cais-group/caisiq-ui-pill'
import { LayoutGrid } from '@cais-group/shared/ui/layout'
import { Course } from '@cais-group/shared/util/type/caisiq-be'

const StyledGrid = styled(LayoutGrid)`
  padding-top: var(--s24);
  padding-bottom: var(--s56);
`
StyledGrid.displayName = 'StyledGrid'

const StyledPill = styled(Pill)`
  position: relative;
  margin-left: var(--s8);
  margin-top: var(--s8);
`
StyledPill.displayName = 'StyledPill'

const StyledContainerWithCounter = styled.div`
  display: flex;
  margin-top: var(--s56);
`
StyledContainerWithCounter.displayName = 'StyledContainerWithCounter'

const GridSeparator = styled.div`
  margin-bottom: var(--s56);
  border-bottom: 1px solid rgb(var(--colors-neutral-200));
`
GridSeparator.displayName = 'GridSeparator'

type CourseGroupProps = {
  name?: string
  courses: Course[]
  withSeparator?: boolean
  onClickViewDetails: (course: Course) => void
}

function CoursesThemeGroup(props: CourseGroupProps) {
  const { name, courses, withSeparator, onClickViewDetails } = props
  const data = useGetDefaultCourseImageSet()
  const { ceCreditsAvailable } = useGetCourseCeCredits()
  return (
    <>
      {name && (
        <StyledContainerWithCounter>
          <H5Strong data-testid="course-theme-name">{name}</H5Strong>
          <StyledPill
            label={courses.length}
            backgroundColor={
              courses.length === 0
                ? 'rgb(var(--colors-neutral-0))'
                : 'rgb(var(--colors-neutral-200))'
            }
            data-testid="course-theme-count"
          />
        </StyledContainerWithCounter>
      )}
      <StyledGrid>
        {courses.map((course) => (
          <CardCourse
            key={course.id}
            course={course}
            fallbackImageUrl={
              data?.length > 0
                ? data[getNextFallbackImageIndex(course.id)].url
                : undefined
            }
            onClickViewDetails={onClickViewDetails}
            headerLabel={getHeaderLabel(ceCreditsAvailable(course))}
          />
        ))}
      </StyledGrid>
      {withSeparator && <GridSeparator />}
    </>
  )
}

export { CoursesThemeGroup }
