import { fetchInstance } from '@cais-group/shared/util/fetch'

import {
  UploadDocumentRequestV1,
  UploadDocumentResponseV1,
} from '../generated/api.schemas'

/**
 * This function is exported in the code generation,
 * but due to a compatibility bug in the backend we must
 * encode the `fileUploadRequest` form part as a file.
 *
 * See: https://caisgroup.atlassian.net/browse/CAS-1896
 */
export const uploadDocument = (
  uploadDocumentRequestV1: UploadDocumentRequestV1
) => {
  const formData = new FormData()

  const textEncoder = new TextEncoder()

  const fileUploadRequest = new File(
    [
      textEncoder.encode(
        JSON.stringify(uploadDocumentRequestV1.fileUploadRequest)
      ),
    ],
    'fileUploadRequest',
    { type: 'application/json' }
  )

  formData.append('fileUploadRequest', fileUploadRequest)

  formData.append('file', uploadDocumentRequestV1.file)

  return fetchInstance<UploadDocumentResponseV1>({
    url: `/api/investor-profile/v1/documents`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}
