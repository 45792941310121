import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { useAxiosInstance } from '@cais-group/caisiq/util/hook/use-axios-instance'
import { ApiPaths, AlmToken } from '@cais-group/shared/util/type/caisiq-be'

export function usePostAcpToken() {
  const axios = useAxiosInstance()

  return useMutation<AlmToken, AxiosError<unknown>, string>((code) =>
    axios.post(`${ApiPaths.oauthCallback}?code=${code}`).then((res) => res.data)
  )
}
