import * as React from 'react'
import * as Rx from 'rxjs'

import { useUniformContext } from '../context'
import {
  RulesFieldProps,
  useFieldValidation,
  useUniformFieldRules,
} from '../rules'

type FeedValue = Partial<{
  shouldValidate: boolean
  isVisible: boolean
  isDisabled: boolean
  isRequired: boolean
  validate: ReturnType<typeof useFieldValidation>
  skippable: boolean
}>
export type FeedProps = Record<string, FeedValue>

export function useRulesFeedSubject() {
  const [subject] = React.useState(() => {
    return new Rx.BehaviorSubject<FeedProps>({})
  })
  const [sharedRulesFeed] = React.useState(() => {
    return subject.pipe(
      Rx.scan((acc, val) => ({ ...acc, ...val }), {} as FeedProps),
      Rx.shareReplay()
    )
  })

  return { subject, sharedRulesFeed }
}

export function useFeedRules({
  field,
  payload,
}: {
  field: RulesFieldProps
  payload: Omit<ReturnType<typeof useUniformFieldRules>, 'validate'>
}) {
  const { rulesFeed } = useUniformContext()

  React.useEffect(() => {
    if (!field.id) return
    rulesFeed.subject.next({
      [field.id]: { ...payload, skippable: field.skippable },
    })
  }, [field, payload, rulesFeed])
}
