/**
 * Shortens a string to the specified character limit and adds an ellipsis if necessary.
 *
 * @example
 * ellipsify("Lorem ipsum dolor sit amet", 15); // Output: "Lorem ipsum..."
 * ellipsify("Short string", 20); // Output: "Short string"
 * ellipsify("", 10); // Output: ""
 *
 * @param input - The input string to be shortened.
 * @param limit - The character limit for the output string.
 * @returns Returns the shortened input string with an ellipsis if it exceeds the limit, or the input string itself if it's shorter.
 */
export const ellipsify = (input: string, limit: number) => {
  if (input.length <= limit) {
    return input // Return input as is
  }

  return input.slice(0, limit).trim() + '…'
}
