import {
  createContext,
  PropsWithChildren,
  useContext,
  useCallback,
  useRef,
  useState,
} from 'react'

import { OrderedFieldsetState } from './shared-ui-ordered-fieldset'
import { deriveState } from './util-derive-step'

interface StepState {
  stepRefs: React.MutableRefObject<(HTMLFieldSetElement | null)[]>
  step: number | undefined
  steps: number
  setStep(s: number): void
  deriveState(o: number): OrderedFieldsetState | undefined
  updateStepCount(s: number): void
  showStep(s: number): boolean
}

export const StepContext = createContext<StepState | undefined>(undefined)

type FormStepProviderProps =
  | {
      stateless: true
      steps?: 0
    }
  | {
      stateless?: false
      steps: number
    }

export const FormStepProvider = ({
  steps = 0,
  stateless = false,
  children,
}: PropsWithChildren<FormStepProviderProps>) => {
  const [stepCount, updateStepCount] = useState(steps)
  const [step, setStep] = useState<number>(1)
  const stepRefs = useRef<(HTMLFieldSetElement | null)[]>([])

  const showStep = useCallback(
    (s: number) => (stateless ? true : step === s),
    [stateless, step]
  )

  return (
    <StepContext.Provider
      value={{
        stepRefs,
        step: stateless ? undefined : step,
        setStep: stateless ? () => {} : setStep,
        steps: stepCount,
        deriveState: (o: number) =>
          deriveState(o, stateless ? undefined : step),
        updateStepCount,
        showStep,
      }}
    >
      {children}
    </StepContext.Provider>
  )
}

export function useStepState() {
  const context = useContext(StepContext)
  if (context === undefined) {
    throw new Error('useStepState must be within FormStepProvider')
  }

  return context
}
