import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Checkbox } from '@mui/material'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'
import classNames from 'classnames'
import * as React from 'react'

import { Pill } from '@cais-group/equity/atoms/pill'
import {
  Table,
  TableHead,
  TBody,
  Row,
  RowCells,
} from '@cais-group/shared/ui/react-table-v8'

import type { UniformAny, UniformFieldOption } from '../../types'
import { pathOr } from '../../utils/path-or'
import { Shape, useGetRandomColors } from '../common/shape'

import { NestedTable, TableColumn, TableColumns } from './input'

export function addLeadingZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`
}

const useGetTableColumns = ({
  subColumnPath = '',
  columnsMap = [],
  onRemoveSingle,
  onRemoveMultiple,
  randomColors,
}: {
  subColumnPath: string
  columnsMap?: TableColumns
  onRemoveSingle?: (row: UniformFieldOption) => void
  onRemoveMultiple?: (rows: UniformFieldOption[]) => void
  randomColors: string[]
}) => {
  const [expanded, setExpanded] = React.useState<string[]>([])
  const firstColumn = React.useMemo(() => {
    const [firstTableColumn] = columnsMap

    const toggleExpanded = (id: string) => {
      if (expanded.includes(id)) {
        setExpanded(expanded.filter((itemId) => itemId !== id))
      } else {
        setExpanded([...expanded, id])
      }
    }

    const updatedFirstTableColumn: TableColumns[0] = {
      ...firstTableColumn,
      accessorFn: (row) => {
        return pathOr(undefined, firstTableColumn.accessorKey?.split('.'), row)
      },
      cell: (props) => {
        const rowId = props.row.original['id'] as string
        const subItems = pathOr<Array<unknown>>(
          [],
          ['original'].concat(subColumnPath.split('.') ?? []),
          props.row
        )
        const label = String(props.getValue()).slice(0, 1).toUpperCase()
        const backgroundColor = randomColors[props.row.index]
        return (
          <div className="flex items-center gap-12">
            <Shape label={label} backgroundColor={backgroundColor} />
            <span className="text-primary-700 small-strong uppercase">
              {props.getValue<string>()}
            </span>
            {subItems?.length > 0 && (
              <button
                type="button"
                className="text-primary-700 p-0"
                onClick={() => toggleExpanded(rowId)}
              >
                <span className="sr-only">toggle expand {rowId}</span>
                <KeyboardArrowRightIcon
                  className={classNames(
                    'block',
                    expanded.includes(rowId) ? 'rotate-90	' : ''
                  )}
                />
              </button>
            )}
          </div>
        )
      },
    }

    return updatedFirstTableColumn
  }, [expanded, columnsMap, randomColors, subColumnPath])

  const columns = React.useMemo(() => {
    const [, ...restTableColumns] = columnsMap

    const sideCellProps = {
      header: '',
      size: 50,
      meta: { ratio: 0 },
      enableSorting: false,
    }

    const cols: TableColumns = [
      // This assumes the checkboxes are only used for deletion, if you need to use them for anything else you would
      // need to add any additional logic here
      ...(onRemoveMultiple
        ? [
            {
              ...sideCellProps,
              accessorKey: 'select',
              header: (props) => {
                const allRows = props.table
                  .getRowModel()
                  .rows.map((r) => r.original)
                const selectedRows = props.table.getSelectedRowModel().rows
                const allRowsSelected = allRows?.length === selectedRows?.length
                const someRowsSelected = selectedRows?.length > 0
                const isIndeterminate = someRowsSelected && !allRowsSelected

                return (
                  <Checkbox
                    className="p-0"
                    checked={allRowsSelected || someRowsSelected}
                    onChange={() => {
                      const newSelection = allRows.reduce((acc, d) => {
                        const key = d['value'] as string
                        return {
                          ...acc,
                          [key]: !allRowsSelected,
                        }
                      }, {} as Record<string, boolean>)
                      props.table.setRowSelection(
                        Object.values(newSelection).every(Boolean)
                          ? newSelection
                          : {}
                      )
                    }}
                    indeterminate={isIndeterminate}
                  />
                )
              },
              cell: ({ row }) => {
                return (
                  <Checkbox
                    className="p-0"
                    value={row.original['id']}
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    indeterminate={row.getIsSomeSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                )
              },
            } as TableColumn,
          ]
        : []),
      firstColumn,
      ...restTableColumns.map((col) => ({
        ...col,
        accessorFn: (row: TableColumns[0]) => {
          return pathOr(undefined, col['accessorKey']?.split('.'), row)
        },
      })),
      {
        ...sideCellProps,
        accessorKey: 'actions',
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <button
                type="button"
                className="cursor-pointer px-1"
                aria-label={`remove ${row.original['label']} from selection`}
                onClick={() => {
                  if (typeof onRemoveSingle !== 'function') return
                  onRemoveSingle(row.original as UniformFieldOption)
                }}
              >
                &#10005;
              </button>
            </div>
          )
        },
      },
    ]
    return cols.filter((col) => {
      if (
        typeof onRemoveSingle !== 'function' &&
        ['actions', 'select'].includes(col.accessorKey)
      ) {
        return false
      }
      return true
    })
  }, [columnsMap, onRemoveMultiple, firstColumn, onRemoveSingle])

  return { columns, expanded }
}

type TableElements = Record<string, UniformAny>
type AccountModalTableProps = {
  data: TableElements[]
  onRemoveSingle?: (row: UniformFieldOption) => void
  onRemoveMultiple?: (rows: UniformFieldOption[]) => void
  table?: NestedTable
}
export function AccountModalTable({
  data = [],
  onRemoveSingle,
  onRemoveMultiple,
  table,
}: AccountModalTableProps) {
  const randomColors = useGetRandomColors(data.length)
  const [rowSelection, setRowSelection] = React.useState<
    Record<string, boolean>
  >({})
  const { columns, expanded } = useGetTableColumns({
    subColumnPath: table?.table?.path ?? '',
    columnsMap: table?.columns,
    onRemoveSingle,
    onRemoveMultiple,
    randomColors,
  })
  const tableInstance = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId(row) {
      return pathOr<string>('', table?.rowIdPath?.split('.') ?? [], row)
    },
  })

  return (
    <div className="flex flex-col gap-12">
      <div className="flex items-center justify-between px-16">
        <div className="flex items-center gap-8">
          <div className="small flex h-[28px] w-[28px] items-center justify-center rounded-full bg-neutral-300 font-mono">
            {addLeadingZero(data.length)}
          </div>
          <span className="small-strong">{table?.title}</span>
        </div>
        {typeof onRemoveMultiple === 'function' && (
          <Pill
            label="Remove"
            state={
              Object.keys(rowSelection).length > 0 ? 'regular' : 'disabled'
            }
            onClick={() => {
              const options = Object.keys(rowSelection)
                .map((key) => data.find((d) => d['value'] === key))
                .filter(Boolean)
              onRemoveMultiple(options as UniformFieldOption[])
            }}
          />
        )}
      </div>
      <Table noScroll className="border border-solid border-neutral-200">
        <TableHead
          headerGroups={tableInstance.getHeaderGroups()}
          variant="dark"
        />
        <TBody>
          {tableInstance.getRowModel().rows.map((row) => {
            const isExpanded = expanded.includes(row.id)
            const subTable = table?.table
            return (
              <div
                key={row.id}
                className={classNames(row.getIsSelected() && 'bg-success-100')}
              >
                <Row
                  className={classNames(
                    'table-row-item',
                    isExpanded && 'border-none'
                  )}
                >
                  <RowCells row={row} />
                </Row>
                {isExpanded && subTable && (
                  <SubTable
                    data={pathOr(
                      [],
                      ['original'].concat(subTable.path?.split('.') ?? []),
                      row
                    )}
                    table={subTable}
                  />
                )}
              </div>
            )
          })}
        </TBody>
      </Table>
    </div>
  )
}

const SubTable = ({
  data,
  table,
  className = 'pl-[74px] pr-[24px]',
}: {
  data: TableElements[]
  table?: NestedTable
  className?: string
}) => {
  const randomColors = useGetRandomColors(data.length)
  const columns = React.useMemo(() => {
    const [firstCol, secondCol, ...restCols] = table?.columns ?? []

    const cols: TableColumns = [
      {
        ...firstCol,
        accessorFn: (row) => {
          return pathOr(undefined, firstCol.accessorKey?.split('.'), row)
        },
        cell: (props) => {
          return (
            <div className="flex gap-12">
              <Shape
                rounded
                label={String(props.getValue()).slice(0, 2).toUpperCase()}
                backgroundColor={randomColors[props.row.index]}
                className="caption tracking-normal"
              />
              <span className="small-strong">{props.getValue<string>()}</span>
            </div>
          )
        },
      },
      {
        ...secondCol,
        accessorFn: (row) => {
          const roles = pathOr(
            undefined,
            secondCol.accessorKey?.split('.'),
            row
          )
          if (Array.isArray(roles)) {
            return roles
              .map((role) => role?.name)
              .filter(Boolean)
              .join(', ')
          }
          return undefined
        },
        cell: (props) => (
          <span className="small">{props.getValue<string>()}</span>
        ),
      },
      ...restCols.map((col) => ({
        ...col,
        accessorFn: (row: TableColumns[0]) => {
          return pathOr(undefined, col.accessorKey?.split('.'), row)
        },
      })),
    ]
    return cols
  }, [randomColors, table?.columns])

  const tableInstance = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId(row) {
      return pathOr<string>('', table?.rowIdPath?.split('.') ?? [], row)
    },
  })

  return (
    <div className="w-full">
      <div className={className}>
        <Table noScroll>
          <TableHead
            headerGroups={tableInstance.getHeaderGroups()}
            variant="light"
          />
          <TBody>
            {tableInstance.getRowModel().rows.map((row) => {
              const subTable = table?.table
              return (
                <div key={row.original['id']}>
                  <Row
                    className={classNames('table-row-item last:border-none')}
                  >
                    <RowCells row={row} />
                  </Row>
                  {subTable && (
                    <SubTable
                      data={pathOr(
                        [],
                        ['original'].concat(subTable.path?.split('.') ?? []),
                        row
                      )}
                      table={subTable}
                      className="pl-[56px]"
                    />
                  )}
                </div>
              )
            })}
          </TBody>
        </Table>
      </div>
    </div>
  )
}
