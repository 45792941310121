/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Identity and Access Management (IAM) API
 * API for Identity and Access Management within CAIS
 * OpenAPI spec version: v1
 */
export type UploadFirmRolesCsvV1Body = {
  file?: Blob
}

export type UploadUserRolesCsvV1Body = {
  file?: Blob
}

export type MigrateUserV1Params = {
  /**
   * Migrate entity if it is existing in IAM (if false existing entities are ignored)
   */
  migrateExisting?: boolean
}

export type MigrateTeamV1Params = {
  /**
   * Migrate entity if it is existing in IAM (if false existing entities are ignored)
   */
  migrateExisting?: boolean
}

export type MigrateFirmV1Params = {
  /**
   * Migrate child entities
   */
  migrateChildren?: boolean
  /**
   * Migrate entity if it is existing in IAM (if false existing entities are ignored)
   */
  migrateExisting?: boolean
}

export type MigrateCaisUsersV1Params = {
  /**
   * Migrate entity if it is existing in IAM (if false existing entities are ignored)
   */
  migrateExisting?: boolean
}

export type MigrateAllFirmsV1Params = {
  /**
   * Migrate entity if it is existing in IAM (if false existing entities are ignored)
   */
  migrateExisting?: boolean
}

export type GetResourcesPermissionsV1200 = { [key: string]: PolicyResultV1 }

export type GetResourcesPermissionsV1Params = {
  /**
   * IDs of IAM resources (user / team / firm / etc)
   */
  resourceIds: string[]
}

export type SearchFirmsNamesV1Params = {
  searchText?: string | null
  parentFirmId?: string | null
  maxRecords?: number | null
}

export type UpdateFirmByExternalIdV1Params = {
  /**
   * Root firm ID. Only required for service-scope requests (implied from authentication otherwise).
   */
  rootFirmId?: string
  /**
   * External ID of firm
   */
  externalId?: string
}

export type GetFirmByNameV1Params = {
  /**
   * Name for firm to be searched for
   */
  name?: string
}

export type UpdateTeamByExternalIdV1Params = {
  /**
   * Root firm ID. Only required for service-scope requests (implied from authentication otherwise).
   */
  rootFirmId?: string
  /**
   * External ID of team
   */
  externalId?: string
}

export type GetUserRegionsReportV1Params = {
  /**
   * Mandatory Firm Id
   */
  firmId: string
  /**
   * Optional team id
   */
  teamId?: string
}

export type GetUserRegionsV1Params = {
  /**
   * Mandatory Firm Id
   */
  firmId: string
  /**
   * Optional team id
   */
  teamId?: string
}

export type UpdateUserByExternalIdV1Params = {
  /**
   * Root firm ID. Only required for service-scope requests (implied from authentication otherwise).
   */
  rootFirmId?: string
  /**
   * External ID of user
   */
  externalId?: string
}

export type GetUserByExternalIdOrEmailV1Params = {
  /**
   * Root Firm ID for the user, must be used in conjunction with externalId
   */
  rootFirmId?: string
  /**
   * External ID of user. Must be provided with a firmId
   */
  externalId?: string
  /**
   * Email of the user to fetch. Must be used by itself, not with firmId or externalId
   */
  email?: string
}

/**
 * Migrate entity if it is existing in IAM (if false existing entities are ignored)
 */
export type MigrateExistingParameter = boolean

/**
 * Migrate child entities
 */
export type MigrateChildrenParameter = boolean

/**
 * IDs of IAM resources (user / team / firm / etc)
 */
export type ResourceIdsParameter = string[]

/**
 * List of Policies
 */
export type SaveEntityPolicyRequestsBody = SaveEntityPolicyRequestV1[]

/**
 * List of user SSO identity mappings
 */
export type UserSsoMappingsBody = UserSsoMappingV1[]

/**
 * List of Capability IDs
 */
export type CapabilityIdsBody = string[]

/**
 * List of Role IDs
 */
export type RoleIdsBody = UUIDListV1

/**
 * The values and paths to update on the object, following JsonPatch syntax
 */
export type PatchRequestBodyBody = PatchDocumentV1[]

/**
 * Internal Server Error
 */
export type N500Response = ErrorResponse

/**
 * Precondition failed
 */
export type N412Response = ErrorResponse

/**
 * Conflict
 */
export type N409Response = ErrorResponse

/**
 * Not found
 */
export type N404Response = ErrorResponse

/**
 * User not authorized to perform operation
 */
export type N403Response = ErrorResponse

/**
 * User not authenticated
 */
export type N401Response = ErrorResponse

/**
 * Bad request
 */
export type N400Response = ErrorResponse

/**
 * The operation to be performed
 */
export type PatchDocumentOp =
  (typeof PatchDocumentOp)[keyof typeof PatchDocumentOp]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchDocumentOp = {
  add: 'add',
  remove: 'remove',
  replace: 'replace',
} as const

/**
 * A JSONPatch document as defined by RFC 6902 https://jsonpatch.com/
 */
export interface PatchDocument {
  /** Previous value for replace operation */
  fromValue?: string
  /** The operation to be performed */
  op: PatchDocumentOp
  /** A JSON-Pointer */
  path: string
  /** The value to be used within the operations. */
  value?: unknown
}

/**
 * The type of action which the event occurred (create|update|delete|read)
 */
export type EnvelopeTypeActionType =
  (typeof EnvelopeTypeActionType)[keyof typeof EnvelopeTypeActionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnvelopeTypeActionType = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  read: 'read',
} as const

/**
 * Object to specify the type of the event enclosed within the envelope.
 */
export interface EnvelopeTypeAction {
  /** The name of action which the event occurred */
  name: string
  /** The type of action which the event occurred (create|update|delete|read) */
  type: EnvelopeTypeActionType
}

/**
 * Object to specify the type of the event enclosed within the envelope.
 */
export type EnvelopeType = {
  action: EnvelopeTypeAction
  /** The list of actions which describe the changes in the event occurred */
  actions?: EnvelopeTypeAction[]
  /** The domain of the event */
  domain: string
  /** The type of resource upon which the event occurred */
  resource: string
  /** The schema major version of the event type */
  version: number
}

export type EnvelopeRelatedIdsItem = {
  /** The domain of the related resource ID */
  domain: string
  /** Unique ID of the related resource ID */
  id: string
  /** The resource type of the related ID */
  resource: string
  /** The schema major version of the resource of the related ID */
  version?: number
}

/**
 * Object to specify the actor of the event enclosed within the envelope. This can include both the real and switch user.
 */
export type EnvelopeActor = {
  /** The ID of the real user. */
  actorId: string
  /** The ID of the effective user. Only required where the real user is different from the effective user. */
  switchActorId?: string | null
}

/**
 * CAIS Event envelope model
 */
export interface Envelope {
  /** Object to specify the actor of the event enclosed within the envelope. This can include both the real and switch user. */
  actor?: EnvelopeActor
  /**
   * Deprecated. Use actor instead.
   * @deprecated
   */
  actorId?: string
  /** Optional correlation identifier which can be used to logically group or associate events */
  correlationId?: string
  /** Human readable description of the event */
  description: string
  /** The list of differences represented as JSONPatch PatchDocument objects */
  diff?: PatchDocument[]
  /** The ID of the firm that owns the resource. May not be present if the event does not relate to a specific resource. */
  firmId?: string
  /** Unique identifier for an event */
  id: string
  /** An optional list of related IDs. As an example an Order event may include a related product's ID */
  relatedIds?: EnvelopeRelatedIdsItem[]
  /** The ID of the resource affected by the event. May not be present if the event does not relate to a specific resource. */
  resourceId?: string
  /** ISO Datetime when the event occurred. */
  timestamp: string
  /** Object to specify the type of the event enclosed within the envelope. */
  type: EnvelopeType
}

export type BooleanFilter = {
  eq?: boolean | null
} | null

export type UUIDFilter = {
  eq?: string | null
  in?: string[] | null
  notIn?: string[] | null
} | null

export type StringFilter = {
  caseSensitive?: boolean | null
  eq?: string | null
  exists?: boolean | null
  in?: string[] | null
  like?: string | null
  ne?: string | null
  notIn?: string[] | null
} | null

export type PageRequest = {
  after?: string | null
  before?: string | null
  first?: number | null
  last?: number | null
} | null

export interface FeatureFlagForAudienceResponseV1 {
  /** Type of audience. Supported values are: 
  - FIRMS
  - TEAMS
  - USERS */
  audience: string
  enabled: boolean
  entityId: string
  featureFlagDefinitionId: string
  id: string
}

export interface FeatureFlagsForAudiencesResponseV1 {
  featureFlagsForAudiences: FeatureFlagForAudienceResponseV1[]
}

export type FeatureFlagAudienceV1 =
  (typeof FeatureFlagAudienceV1)[keyof typeof FeatureFlagAudienceV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureFlagAudienceV1 = {
  FIRMS: 'FIRMS',
  TEAMS: 'TEAMS',
  USERS: 'USERS',
} as const

export interface FeatureFlagForAudienceRequestV1 {
  audience: FeatureFlagAudienceV1
  enabled: boolean
  entityId: string
}

export interface FeatureFlagsForAudiencesRequestV1 {
  featureFlagForAudiences: FeatureFlagForAudienceRequestV1[]
}

export interface AssignedFeatureFlagsSummaryResponseV1 {
  disabled: number
  enabled: number
}

export type FeatureFlagDefinitionWithSummaryResponseV1AllOf = {
  assignedFirms: AssignedFeatureFlagsSummaryResponseV1
  assignedTeams: AssignedFeatureFlagsSummaryResponseV1
  assignedUsers: AssignedFeatureFlagsSummaryResponseV1
  fineGrainedConfig?: boolean
}

export interface FeatureFlagDefinitionResponseV1 {
  /** Type of audience. Supported values are: 
- FIRMS
- TEAMS
- USERS */
  audience: string[]
  description?: string | null
  enabled: boolean
  fineGrainedConfig: boolean
  id: string
  key: string
  name: string
}

export type FeatureFlagDefinitionWithSummaryResponseV1 =
  FeatureFlagDefinitionResponseV1 &
    FeatureFlagDefinitionWithSummaryResponseV1AllOf

export interface UserFeatureFlagResponseV1 {
  email: string
  enabled: boolean
  featureFlagDefinitionId: string
  firstName: string
  id: string
  lastName: string
  rootFirmName: string
  userId: string
}

/**
 * Represents one page of user feature flag from a list of them.
 */
export interface PagedUserFeatureFlagsV1 {
  items: UserFeatureFlagResponseV1[]
  pageInfo: PageInfo
}

export interface TeamFeatureFlagResponseV1 {
  enabled: boolean
  featureFlagDefinitionId: string
  id: string
  rootFirmName: string
  teamId: string
  teamName: string
}

/**
 * Represents one page of teams feature flag from a list of them.
 */
export interface PagedTeamFeatureFlagsV1 {
  items: TeamFeatureFlagResponseV1[]
  pageInfo: PageInfo
}

export interface FirmFeatureFlagResponseV1 {
  enabled: boolean
  featureFlagDefinitionId: string
  firmId: string
  firmName: string
  id: string
}

/**
 * Represents one page of firms feature flag from a list of them.
 */
export interface PagedFirmFeatureFlagsV1 {
  items: FirmFeatureFlagResponseV1[]
  pageInfo: PageInfo
}

/**
 * Represents one page of feature flag definitions from a list of them.
 */
export interface PagedFeatureFlagDefinitionsV1 {
  items: FeatureFlagDefinitionWithSummaryResponseV1[]
  pageInfo: PageInfo
}

export type UserFeatureFlagSearchRequestV1SortingItem =
  (typeof UserFeatureFlagSearchRequestV1SortingItem)[keyof typeof UserFeatureFlagSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserFeatureFlagSearchRequestV1SortingItem = {
  FIRST_NAME_ASC: 'FIRST_NAME_ASC',
  FIRST_NAME_DESC: 'FIRST_NAME_DESC',
  LAST_NAME_ASC: 'LAST_NAME_ASC',
  LAST_NAME_DESC: 'LAST_NAME_DESC',
  EMAIL_ASC: 'EMAIL_ASC',
  EMAIL_DESC: 'EMAIL_DESC',
  ENABLED_ASC: 'ENABLED_ASC',
  ENABLED_DESC: 'ENABLED_DESC',
} as const

/**
 * Defines how to filter, page and sort user feature flag search
 */
export interface UserFeatureFlagSearchRequestV1 {
  filter?: UserFeatureFlagFilterV1
  pageRequest?: PageRequest
  sorting?: UserFeatureFlagSearchRequestV1SortingItem[] | null
}

export type TeamFeatureFlagSearchRequestV1SortingItem =
  (typeof TeamFeatureFlagSearchRequestV1SortingItem)[keyof typeof TeamFeatureFlagSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TeamFeatureFlagSearchRequestV1SortingItem = {
  TEAM_NAME_ASC: 'TEAM_NAME_ASC',
  TEAM_NAME_DESC: 'TEAM_NAME_DESC',
  ENABLED_ASC: 'ENABLED_ASC',
  ENABLED_DESC: 'ENABLED_DESC',
} as const

/**
 * Defines how to filter, page and sort team feature flag search
 */
export interface TeamFeatureFlagSearchRequestV1 {
  filter?: TeamFeatureFlagFilterV1
  pageRequest?: PageRequest
  sorting?: TeamFeatureFlagSearchRequestV1SortingItem[] | null
}

export type FirmFeatureFlagSearchRequestV1SortingItem =
  (typeof FirmFeatureFlagSearchRequestV1SortingItem)[keyof typeof FirmFeatureFlagSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FirmFeatureFlagSearchRequestV1SortingItem = {
  FIRM_NAME_ASC: 'FIRM_NAME_ASC',
  FIRM_NAME_DESC: 'FIRM_NAME_DESC',
  ENABLED_ASC: 'ENABLED_ASC',
  ENABLED_DESC: 'ENABLED_DESC',
} as const

/**
 * Defines how to filter, page and sort firm feature flag search
 */
export interface FirmFeatureFlagSearchRequestV1 {
  filter?: FirmFeatureFlagFilterV1
  pageRequest?: PageRequest
  sorting?: FirmFeatureFlagSearchRequestV1SortingItem[] | null
}

export type FeatureFlagDefinitionSearchRequestV1SortingItem =
  (typeof FeatureFlagDefinitionSearchRequestV1SortingItem)[keyof typeof FeatureFlagDefinitionSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureFlagDefinitionSearchRequestV1SortingItem = {
  KEY_ASC: 'KEY_ASC',
  KEY_DESC: 'KEY_DESC',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
  DESCRIPTION_ASC: 'DESCRIPTION_ASC',
  DESCRIPTION_DESC: 'DESCRIPTION_DESC',
  ENABLED_ASC: 'ENABLED_ASC',
  ENABLED_DESC: 'ENABLED_DESC',
  FINE_GRAINED_CONFIG_ASC: 'FINE_GRAINED_CONFIG_ASC',
  FINE_GRAINED_CONFIG_DESC: 'FINE_GRAINED_CONFIG_DESC',
} as const

/**
 * Defines how to filter, page and sort feature flag definitions search
 */
export interface FeatureFlagDefinitionSearchRequestV1 {
  filter?: FeatureFlagDefinitionFilterV1
  pageRequest?: PageRequest
  sorting?: FeatureFlagDefinitionSearchRequestV1SortingItem[] | null
}

/**
 * Defines how to filter for user feature flags on a search
 */
export type UserFeatureFlagFilterV1 = {
  email?: StringFilter
  enabled?: BooleanFilter
  firstName?: StringFilter
  id?: UUIDFilter
  lastName?: StringFilter
  searchText?: StringFilter
} | null

/**
 * Defines how to filter for team feature flags on a search
 */
export type TeamFeatureFlagFilterV1 = {
  enabled?: BooleanFilter
  id?: UUIDFilter
  teamName?: StringFilter
} | null

/**
 * Defines how to filter for firm feature flags on a search
 */
export type FirmFeatureFlagFilterV1 = {
  enabled?: BooleanFilter
  firmName?: StringFilter
  id?: UUIDFilter
} | null

/**
 * Defines how to filter for feature flags definitions on a search
 */
export type FeatureFlagDefinitionFilterV1 = {
  description?: StringFilter
  enabled?: BooleanFilter
  fineGrainedConfig?: BooleanFilter
  id?: UUIDFilter
  key?: StringFilter
  name?: StringFilter
  searchText?: StringFilter
} | null

export interface UserFirmDetailsV1 {
  id: string
  name: string
  /** Type of firm. Supported values are: 
- FUND_ADMIN_FIRM
- FUND_MANAGER_FIRM
- ADVISOR_FIRM
- CAIS_FIRM
 */
  types?: string[]
}

export type FirmTagV1 = (typeof FirmTagV1)[keyof typeof FirmTagV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FirmTagV1 = {
  ENTERPRISE: 'ENTERPRISE',
  SOLUTION: 'SOLUTION',
} as const

export interface BulkRefreshResponseRowV1 {
  id: string
  message: string
  status: string
}

export interface BulkRefreshResponseV1 {
  firms: BulkRefreshResponseRowV1[]
  teams: BulkRefreshResponseRowV1[]
  users: BulkRefreshResponseRowV1[]
}

export interface BulkRefreshRequestV1 {
  firmIds?: string[]
  teamIds?: string[]
  userIds?: string[]
}

export interface RoleRecertificationEventPayloadV1 {
  dismissedApprovalsUserIds?: string[]
  roleRecertification?: RoleRecertificationV1
}

export type RoleRecertificationEventV1 = Envelope & {
  payload: RoleRecertificationEventPayloadV1
}

export interface RoleChangeSubmissionEventPayloadV1 {
  dismissedApprovalsUserIds?: string[] | null
  roleChangeSubmission?: RoleChangeSubmissionV1
}

export type RoleChangeSubmissionEventV1 = Envelope & {
  payload: RoleChangeSubmissionEventPayloadV1
}

/**
 * Type of a role workflow
 */
export type WorkflowTypeNullableV1 =
  | (typeof WorkflowTypeNullableV1)[keyof typeof WorkflowTypeNullableV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowTypeNullableV1 = {
  ROLE_CHANGE: 'ROLE_CHANGE',
  ROLE_RECERTIFICATION: 'ROLE_RECERTIFICATION',
} as const

/**
 * Type of a role workflow
 */
export type WorkflowTypeV1 =
  (typeof WorkflowTypeV1)[keyof typeof WorkflowTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowTypeV1 = {
  ROLE_CHANGE: 'ROLE_CHANGE',
  ROLE_RECERTIFICATION: 'ROLE_RECERTIFICATION',
} as const

/**
 * Filtering on the value of RoleWorkflowType
 */
export type WorkflowTypeFilterV1 = {
  eq?: WorkflowTypeNullableV1
  exists?: boolean | null
  in?: WorkflowTypeNullableV1[] | null
  ne?: WorkflowTypeNullableV1
} | null

/**
 * Status of a role workflow
 */
export type WorkflowStatusNullableV1 =
  | (typeof WorkflowStatusNullableV1)[keyof typeof WorkflowStatusNullableV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowStatusNullableV1 = {
  PENDING: 'PENDING',
  ESCALATED: 'ESCALATED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
} as const

/**
 * Status of a role workflow
 */
export type WorkflowStatusV1 =
  (typeof WorkflowStatusV1)[keyof typeof WorkflowStatusV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowStatusV1 = {
  PENDING: 'PENDING',
  ESCALATED: 'ESCALATED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
} as const

/**
 * Status of a role workflow alongside the timestamp it became the status
 */
export interface WorkflowStatusRecordV1 {
  status?: WorkflowStatusV1
  timestamp?: string
}

export interface WorkflowCommentV1 {
  comment?: string
  timestamp?: string
  userId?: string
}

export type WorkflowActionV1Action =
  (typeof WorkflowActionV1Action)[keyof typeof WorkflowActionV1Action]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowActionV1Action = {
  REQUEST: 'REQUEST',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  CANCEL: 'CANCEL',
} as const

export interface WorkflowActionV1 {
  action: WorkflowActionV1Action
  timestamp: string
  userId: string
}

export interface RoleRecertificationV1 {
  actions: WorkflowActionV1[]
  approvals: WorkflowApprovalV1[]
  comments: WorkflowCommentV1[]
  createDate: string
  firmId: string
  id: string
  label?: string
  roleId: string
  roleName: string
  roleSnapshot?: RoleSnapshotV1
  status: WorkflowStatusV1
  statusHistory: WorkflowStatusRecordV1[]
  updateDate: string
  version: number
}

/**
 * Filtering on the value of RoleWorkflowStatus
 */
export type WorkflowStatusFilterV1 = {
  eq?: WorkflowStatusNullableV1
  exists?: boolean | null
  in?: WorkflowStatusNullableV1[] | null
  ne?: WorkflowStatusNullableV1
} | null

/**
 * Types of workflow actors
 */
export type WorkflowActorTypeNullableV1 =
  | (typeof WorkflowActorTypeNullableV1)[keyof typeof WorkflowActorTypeNullableV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowActorTypeNullableV1 = {
  REQUESTER: 'REQUESTER',
  APPROVER: 'APPROVER',
  ESCALATION_APPROVER: 'ESCALATION_APPROVER',
} as const

/**
 * Defines the possible filters for use in a role workflow search
 */
export interface RoleWorkflowItemFilterV1 {
  roleId?: UUIDFilter
  status?: WorkflowStatusFilterV1
  workflowActorType?: ArrayFilterV1
  workflowType?: WorkflowTypeFilterV1
}

export type RoleWorkflowItemSearchRequestV1SortingItem =
  (typeof RoleWorkflowItemSearchRequestV1SortingItem)[keyof typeof RoleWorkflowItemSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleWorkflowItemSearchRequestV1SortingItem = {
  ID_ASC: 'ID_ASC',
  ID_DESC: 'ID_DESC',
  ROLE_NAME_ASC: 'ROLE_NAME_ASC',
  ROLE_NAME_DESC: 'ROLE_NAME_DESC',
  CREATE_DATE_ASC: 'CREATE_DATE_ASC',
  CREATE_DATE_DESC: 'CREATE_DATE_DESC',
  UPDATE_DATE_ASC: 'UPDATE_DATE_ASC',
  UPDATE_DATE_DESC: 'UPDATE_DATE_DESC',
  STATUS_ASC: 'STATUS_ASC',
  STATUS_DESC: 'STATUS_DESC',
} as const

/**
 * Defines the filters, paging and sorting to use for a role workflow search
 */
export interface RoleWorkflowItemSearchRequestV1 {
  filter?: RoleWorkflowItemFilterV1
  pageRequest?: PageRequest
  sorting?: RoleWorkflowItemSearchRequestV1SortingItem[] | null
}

/**
 * Type of actor on a workflow item
 */
export type WorkflowActorTypeV1 =
  (typeof WorkflowActorTypeV1)[keyof typeof WorkflowActorTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowActorTypeV1 = {
  REQUESTER: 'REQUESTER',
  APPROVER: 'APPROVER',
  ESCALATION_APPROVER: 'ESCALATION_APPROVER',
} as const

export interface WorkflowActorV1 {
  type?: WorkflowActorTypeV1
  user?: string
}

export type WorkflowApprovalStatusV1 =
  (typeof WorkflowApprovalStatusV1)[keyof typeof WorkflowApprovalStatusV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowApprovalStatusV1 = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
} as const

export interface WorkflowApprovalV1 {
  escalationApprover: boolean
  status: WorkflowApprovalStatusV1
  userId: string
}

export type RoleDeltaV1Persona = PersonaV1 | null

export interface RoleDeltaV1 {
  description?: string | null
  inheritable?: boolean | null
  isSupervisor?: boolean | null
  name?: string | null
  numberOfChangeApprovals?: number | null
  owners?: UUIDDeltaV1
  permissions?: StringDeltaV1
  persona?: RoleDeltaV1Persona
  users?: UUIDDeltaV1
}

export interface RoleSnapshotV1 {
  archetype?: UserTypeV1
  createDate?: string
  description?: string
  firmId?: string
  id: string
  /** Indicates whether the role (from a root or parent firm) can be inherited by sub-firms. By default all roles are inherited. */
  inheritable?: boolean
  isSupervisor?: boolean | null
  name: string
  numberOfChangeApprovals?: number | null
  owners?: string[] | null
  permissions: PermissionV1[]
  persona?: PersonaV1
  updateDate?: string | null
  userIds: string[]
  version: number
}

export interface RoleWorkflowItemV1 {
  actors: WorkflowActorV1[]
  createDate: string
  description: string
  firmId: string
  id: string
  roleId: string
  roleName: string
  status: WorkflowStatusV1
  updateDate: string
  version: number
  workflowType: WorkflowTypeV1
}

export interface PagedRoleWorkflowItemsV1 {
  items: RoleWorkflowItemV1[]
  pageInfo: PageInfo
}

export interface RoleChangeSubmissionV1 {
  actions: WorkflowActionV1[]
  after: RoleSnapshotV1
  approvals: WorkflowApprovalV1[]
  before: RoleSnapshotV1
  comments?: WorkflowCommentV1[]
  createDate: string
  delta: RoleDeltaV1
  firmId: string
  id: string
  numApprovals: number
  numRequiredApprovals: number
  rationale: string
  requester: string
  roleId: string
  roleName: string
  status: WorkflowStatusV1
  statusHistory: WorkflowStatusRecordV1[]
  updateDate: string | null
  version: number
}

export interface RoleRecertificationActionInputV1 {
  roleVersion: number
}

export interface RoleChangeSubmissionActionInputV1 {
  comment?: string
  roleVersion: number
}

export interface UserPasswordLoginAccessResponseV1 {
  passwordLoginAllowed: boolean
}

export type ConnectionTypeResponseV1 =
  (typeof ConnectionTypeResponseV1)[keyof typeof ConnectionTypeResponseV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectionTypeResponseV1 = {
  PASSWORD: 'PASSWORD',
  SSO: 'SSO',
} as const

export type ConnectionTypeRequestV1 =
  (typeof ConnectionTypeRequestV1)[keyof typeof ConnectionTypeRequestV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectionTypeRequestV1 = {
  ALL: 'ALL',
  PASSWORD: 'PASSWORD',
  SSO: 'SSO',
} as const

export type DeleteTestDataRequestV1DataType =
  (typeof DeleteTestDataRequestV1DataType)[keyof typeof DeleteTestDataRequestV1DataType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeleteTestDataRequestV1DataType = {
  USER: 'USER',
  TEAM: 'TEAM',
  FIRM: 'FIRM',
  ALL: 'ALL',
} as const

export interface DeleteTestDataRequestV1 {
  createDate: string
  dataType: DeleteTestDataRequestV1DataType
  deleteFirms?: boolean
}

export interface DataSimulationRequestV1 {
  targetTeamCount?: number
  targetUserCount?: number
}

export interface RegionV1 {
  regionName?: string
  regionResolvedBy?: string
  source?: string
  subRegionName?: string
}

export interface RegionsV1 {
  advisorServices: RegionV1
  structuredProducts: RegionV1
}

export interface RegionLookupByAddressCandidateV1 {
  regionSource?: string
  sourceId?: string
  state: string
  zipCode?: string
}

export interface RegionResultsReportItemV1 {
  addressCandidate?: RegionLookupByAddressCandidateV1
  addressLookup: string
  errors?: string[]
  regions?: RegionsV1
}

export type UserV1EntityType =
  (typeof UserV1EntityType)[keyof typeof UserV1EntityType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserV1EntityType = {
  USER: 'USER',
} as const

export type TeamV1EntityType =
  (typeof TeamV1EntityType)[keyof typeof TeamV1EntityType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TeamV1EntityType = {
  TEAM: 'TEAM',
} as const

/**
 * A representation of a team in IAM. Teams can be nested under other teams or under firms to form a hierarchy. Teams cannot exist outside of a Firm hierarchy.
 */
export type TeamV1 = HierarchyEntityV1 & {
  address?: AddressV1
  attributes?: AttributesV1
  /** A unique identification numbers assigned by CAIS to registered teams */
  caisId?: string | null
  capabilities?: CapabilityV1[]
  createDate?: string
  deepLinkingSupported?: boolean
  deleteDate?: string | null
  description?: string | null
  entityType: TeamV1EntityType
  externalId?: string | null
  firmId?: string
  name: string
  parent: NullableHierarchyEdgeV1
  updateDate?: string | null
}

export type FirmV1EntityType =
  (typeof FirmV1EntityType)[keyof typeof FirmV1EntityType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FirmV1EntityType = {
  FIRM: 'FIRM',
} as const

export interface HierarchyEntityV1 {
  entityType: HierarchyEntityTypeV1
  id: EntityIdV1
  version: number
}

/**
 * A representation of a user in IAM. Users can be nested under teams or firms to form a hierarchy. A user cannot exist outside of a firm hierarchy. | All access to the system revolves around the user, and the permissions and capabilities they have (directly or inherited).
 */
export type UserV1 = HierarchyEntityV1 & {
  address?: AddressV1
  attributes?: AttributesV1
  block?: boolean | null
  /** A unique identification numbers assigned by CAIS to registered users */
  caisId?: string | null
  capabilities?: CapabilityV1[]
  /** A unique identification numbers assigned by FINRA to registered brokers and brokerage firms */
  crd?: string | null
  createDate: string
  deleteDate?: string | null
  email: string
  entityType: UserV1EntityType
  externalId?: string | null
  firmDetails?: UserFirmDetailsV1[]
  firmId?: string
  firmNames: string[]
  firmTypes?: FirmTypeV1[]
  firstName: string
  isSupervisor?: boolean
  lastName: string
  lastVisitDate?: string | null
  middleInitial?: string | null
  mobilePhone?: string | null
  mobileValidatedOn?: string | null
  parents?: HierarchyEdgeV1[]
  personas?: PersonaV1[]
  phone?: string | null
  qualifications?: QualificationV1[]
  registerDate?: string | null
  roles?: RoleV1[]
  salesforceId?: string | null
  ssoMappings?: UserSsoMappingV1[]
  status: UserStatusV1
  title?: string | null
  updateDate?: string | null
}

/**
 * A representation of a firm in IAM. Firms can be nested under other firms to form a hierarchy.
 */
export type FirmV1 = HierarchyEntityV1 & {
  address?: AddressV1
  advisorFirmInfo?: AdvisorFirmInfoV1
  attributes?: AttributesV1
  /** A unique identification numbers assigned by CAIS to registered firms */
  caisId?: string | null
  capabilities?: CapabilityV1[]
  /** A unique identification numbers assigned by FINRA to registered brokers and brokerage firms */
  crd?: string | null
  createDate: string
  deepLinkingSupported?: boolean
  deleteDate?: string | null
  emailDomainAllowlist: EmailDomainAllowlistV1
  entityType: FirmV1EntityType
  externalId?: string | null
  firmTypes?: FirmTypeV1[]
  fundAdminFirmInfo?: FundAdminFirmInfoV1
  fundManagerFirmInfo?: FundManagerFirmInfoV1
  isAllWelcomeEmailsEnabled?: boolean
  name: string
  parent?: NullableHierarchyEdgeV1
  parentFirmAccessLevelLimit?: number | null
  passwordLoginAllowed: boolean
  pershingMultiTenantId?: string | null
  policies: PolicyV1[]
  roleChangeWorkflowEnabled?: boolean
  salesforceId?: string | null
  /** Type of firm tag. Supported values are: 
  - SOLUTION 
  - ENTERPRISE
 */
  tags?: string[] | null
  totalAua?: number | null
  /** @deprecated */
  totalUsers?: number
  updateDate?: string | null
  /**
   * User validPersonasForFirmRoles instead
   * @deprecated
   */
  validArchetypesForFirm?: UserTypeV1[]
  validCapabilitiesForFirm?: CapabilityV1[]
  validPersonasForFirmRoles?: string[]
}

export type InitFlowV1 = (typeof InitFlowV1)[keyof typeof InitFlowV1] | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InitFlowV1 = {
  IDP: 'IDP',
  SP: 'SP',
} as const

export type SelfRegistrationStrategyV1 =
  | (typeof SelfRegistrationStrategyV1)[keyof typeof SelfRegistrationStrategyV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelfRegistrationStrategyV1 = {
  CREATE: 'CREATE',
  ASSIGN: 'ASSIGN',
} as const

export interface SsoUserResolutionRequestV1 {
  email?: string | null
  externalUserId: string
  firstName?: string | null
  lastName?: string | null
  phone?: string | null
  ssoProviderName: string
}

export interface SsoProviderRequestV1 {
  firmIds?: string[]
  initFlow?: InitFlowV1
  name: string
  selfRegistrationAllowed: boolean
  selfRegistrationFirmId?: string | null
  selfRegistrationRoleId?: string | null
  selfRegistrationTeamId?: string | null
  selfRegistrationTeamStrategy?: SelfRegistrationStrategyV1
}

export interface SsoProviderV1 {
  createDate: string
  deleteDate?: string | null
  firmIds?: string[]
  id: string
  /** Type in initiated flow for SSO provider. Supported values are:
- IDP: Initiated by Identity Provider
- SP: Initiated by Service Provider
 */
  initFlow?: string | null
  name: string
  selfRegistrationAllowed: boolean
  selfRegistrationFirmId?: string | null
  selfRegistrationRoleId?: string | null
  selfRegistrationTeamId?: string | null
  selfRegistrationTeamStrategy?: SelfRegistrationStrategyV1
  updateDate?: string | null
  version: number
}

export type Auth0EventV1LocationInfo = { [key: string]: unknown }

export type Auth0EventV1Details = { [key: string]: unknown }

export interface Auth0EventV1 {
  clientId?: string | null
  clientName?: string | null
  date?: string
  details?: Auth0EventV1Details
  id?: string | null
  ip?: string | null
  locationInfo?: Auth0EventV1LocationInfo
  logId?: string | null
  type?: string | null
  userId?: string | null
  userName?: string | null
}

export type Auth0AuditEventV1 = Envelope & {
  payload: Auth0EventV1
}

export type EnvelopeEventV1AllOfPayload = { [key: string]: unknown }

export type EnvelopeEventV1AllOf = {
  payload?: EnvelopeEventV1AllOfPayload
}

export type EnvelopeEventV1 = Envelope & EnvelopeEventV1AllOf

export interface TargetEntityV1 {
  /** The IAM id of the entity */
  id: string
  type: HierarchyEntityTypeV1
}

export type NullableHierarchyEdgeV1Attributes = { [key: string]: unknown }

export type NullableHierarchyEdgeV1 = {
  attributes?: NullableHierarchyEdgeV1Attributes
  childId: string
  childType: HierarchyEntityTypeV1
  parentId: string
  parentName?: string | null
  parentType: HierarchyEntityTypeV1
} | null

export type HierarchyEdgeV1Attributes = { [key: string]: unknown }

export interface HierarchyEdgeV1 {
  attributes?: HierarchyEdgeV1Attributes
  childId: string
  childType: HierarchyEntityTypeV1
  parentId: string
  parentName?: string | null
  parentType: HierarchyEntityTypeV1
}

export interface HierarchyChangeV1 {
  created?: HierarchyEdgeV1[]
  deleted?: HierarchyEdgeV1[]
  id: string
  rootFirmId: string
  target: TargetEntityV1
}

export type PolicyResultReasonV1Metadata = { [key: string]: unknown }

/**
 * Reason for particular policy evaluation decision
 */
export interface PolicyResultReasonV1 {
  allowed: boolean
  metadata: PolicyResultReasonV1Metadata
  reason: string
  result: string
}

/**
 * Policy evaluation result (allowed / denied permissions) for user accessing a resource
 */
export interface PolicyResultV1 {
  allowed: string[]
  denied: string[]
  reasons: PolicyResultReasonV1[]
}

export type ErrorResponseErrorAttributes = { [key: string]: string }

export interface ErrorResponse {
  displayMessage?: string | null
  errorAttributes?: ErrorResponseErrorAttributes
  errorCode?: string | null
  errorMessage?: string
  errorUri?: string | null
  status?: number
}

/**
 * Represents one page of service identities from a list of service identities.
 */
export interface PagedServiceIdentitiesV1 {
  items: ServiceIdentityV1[]
  pageInfo: PageInfo
}

/**
 * Defines the possible filters for use in a service identity search
 */
export type ServiceIdentityFilterV1 = {
  email?: StringFilter
  firmId?: UUIDFilter
  id?: UUIDFilter
  name?: StringFilter
  status?: UserStatusFilterV1
} | null

export type ServiceIdentitySearchRequestV1SortingItem =
  (typeof ServiceIdentitySearchRequestV1SortingItem)[keyof typeof ServiceIdentitySearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceIdentitySearchRequestV1SortingItem = {
  ID_ASC: 'ID_ASC',
  ID_DESC: 'ID_DESC',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
  EMAIL_ASC: 'EMAIL_ASC',
  EMAIL_DESC: 'EMAIL_DESC',
  STATUS_ASC: 'STATUS_ASC',
  STATUS_DESC: 'STATUS_DESC',
} as const

/**
 * Defines the filters, paging and sorting to use for a service identity search
 */
export interface ServiceIdentitySearchRequestV1 {
  filter?: ServiceIdentityFilterV1
  pageRequest?: PageRequest
  sorting?: ServiceIdentitySearchRequestV1SortingItem[] | null
}

/**
 * Response for rotate secret for service identity operation. Contains clientId and clientSecret for authenticating as this identity. | NB! service identity creation and rotation is the only time when clientSecret is available in plain text.
 */
export interface ServiceIdentityRotateSecretResponseV1 {
  clientId: string
  clientSecret: string
}

/**
 * Response for create service identity operation. Contains service identity information as well as clientId and clientSecret for authenticating as this identity. | NB! service identity creation and rotation is the only time when clientSecret is available in plain text.
 */
export interface ServiceIdentityCreateResponseV1 {
  clientId: string
  clientSecret: string
  serviceIdentity: ServiceIdentityV1
}

/**
 * Response for validate operation
 */
export interface MobileValidationResponseV1 {
  isValid: boolean
  mobileNumber: string
  validatedOn?: string | null
}

export type ServiceIdentityFirmDetailsV1 = {
  id: string
  name: string
} | null

/**
 * A representation of a service identity in IAM. A service identity cannot exist outside of a firm hierarchy.
 */
export interface ServiceIdentityV1 {
  attributes?: AttributesV1
  /** A unique identification numbers assigned by CAIS to registered service identities */
  caisId?: string | null
  clientId?: string
  createDate?: string
  deleteDate?: string | null
  email: string
  entityType?: HierarchyEntityTypeV1
  externalId?: string | null
  firm?: ServiceIdentityFirmDetailsV1
  id: string
  permissions?: PermissionV1[]
  serviceName: string
  status?: UserStatusV1
  updateDate?: string | null
  version: number
}

/**
 * Used to create a service identity in the IAM database.
 */
export interface ServiceIdentityRequestV1 {
  attributes?: AttributesRequestV1
  email: string
  externalId?: string | null
  firmId: string
  permissionIds: string[] | null
  serviceName: string
}

export interface NodeEntityV1 {
  attributes?: AttributesRequestV1
  capabilityIds?: string[]
  entityType?: HierarchyEntityTypeV1
  externalId?: string | null
  id?: string
  name?: string
  version: number
}

export interface IdNameTupleV1 {
  id?: string
  name?: string
}

export interface RolePatchV1 {
  [key: string]: unknown
}

export type RoleSearchRequestV1SortingItem =
  (typeof RoleSearchRequestV1SortingItem)[keyof typeof RoleSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleSearchRequestV1SortingItem = {
  ID_ASC: 'ID_ASC',
  ID_DESC: 'ID_DESC',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
} as const

/**
 * Defines how to filter, page and sort a role search
 */
export interface RoleSearchRequestV1 {
  filter?: RoleFilterV1
  pageRequest?: PageRequest
  sorting?: RoleSearchRequestV1SortingItem[] | null
}

/**
 * Defines how to filter for permissions on a role search
 */
export type PermissionFilterV1 = {
  in?: string[]
} | null

/**
 * Defines how to filter for roles on a role search
 */
export type RoleFilterV1 = {
  active?: BooleanFilter
  permissions?: PermissionFilterV1
} | null

export type TeamSearchRequestV1SortingItem =
  (typeof TeamSearchRequestV1SortingItem)[keyof typeof TeamSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TeamSearchRequestV1SortingItem = {
  ID_ASC: 'ID_ASC',
  ID_DESC: 'ID_DESC',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
} as const

/**
 * Defines how to filter for teams on a team search
 */
export type TeamFilterV1 = {
  externalId?: StringFilter
  firmId?: FirmIdFilterV1
  id?: UUIDFilter
  name?: StringFilter
  userId?: UUIDFilter
} | null

/**
 * Defines how to filter, page and sort a team search
 */
export interface TeamSearchRequestV1 {
  filter?: TeamFilterV1
  pageRequest?: PageRequest
  sorting?: TeamSearchRequestV1SortingItem[] | null
}

export type FirmSearchRequestV1SortingItem =
  (typeof FirmSearchRequestV1SortingItem)[keyof typeof FirmSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FirmSearchRequestV1SortingItem = {
  ID_ASC: 'ID_ASC',
  ID_DESC: 'ID_DESC',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
  FULL_NAME_ASC: 'FULL_NAME_ASC',
  FULL_NAME_DESC: 'FULL_NAME_DESC',
  TOTAL_AUA_ASC: 'TOTAL_AUA_ASC',
  TOTAL_AUA_DESC: 'TOTAL_AUA_DESC',
  SALESFORCE_ID_ASC: 'SALESFORCE_ID_ASC',
  SALESFORCE_ID_DESC: 'SALESFORCE_ID_DESC',
  PERSHING_MULTI_TENANT_ID_ASC: 'PERSHING_MULTI_TENANT_ID_ASC',
  PERSHING_MULTI_TENANT_ID_DESC: 'PERSHING_MULTI_TENANT_ID_DESC',
  ETA_FIRM_STATUS_ID_ASC: 'ETA_FIRM_STATUS_ID_ASC',
  ETA_FIRM_STATUS_ID_DESC: 'ETA_FIRM_STATUS_ID_DESC',
} as const

/**
 * Defines how to filter, page and sort a firm search
 */
export interface FirmSearchRequestV1 {
  filter?: FirmFilterV1
  pageRequest?: PageRequest
  sorting?: FirmSearchRequestV1SortingItem[] | null
}

export interface InheritedAttributesV1 {
  attributes: AttributesV1
  /** The id of the direct parent entity from which the attributes are inherited */
  parentId: string
  /** The type of the direct parent entity from which the attributes are inherited */
  parentType: string
}

/**
 * Defines a versioned terms and conditions agreement that can be accepted by a user
 */
export interface CreateTermsAndConditionsRequestV1 {
  type: string
  version: number
}

export type CreateFundAdminFirmInfoRequestV1 = {
  fundAdminId?: string
} | null

export type CreateFundManagerFirmInfoRequestV1 = {
  fundManagerId?: string
} | null

export interface AttributesV1 {
  [key: string]: unknown
}

export type AttributesRequestV1 = { [key: string]: unknown } | null

/**
 * Used to create a team in the IAM database. Can be put directly into a hierarchy by providing parents
 */
export interface TeamRequestV1 {
  address?: AddressRequestV1
  attributes?: AttributesRequestV1
  capabilities?: string[] | null
  description?: string | null
  externalId?: string | null
  name: string
  parent?: NestedHierarchyEdgeRequestV1
  parents?: NestedHierarchyEdgeRequestV1[] | null
  userIds?: string[] | null
}

/**
 * A representation of an email domain allowlist request.
 */
export interface EmailDomainAllowlistRequestV1 {
  domains?: string[]
  enabled?: boolean
  inheritFromParent?: boolean
}

export type AdvisorFirmInfoRequestV1 = {
  type: AdvisorFirmTypeV2
} | null

/**
 * Used to create a firm in the IAM database. Can be put directly into a hierarchy by providing a parent
 */
export interface FirmRequestV1 {
  address?: AddressRequestV1
  advisorFirmInfo?: AdvisorFirmInfoRequestV1
  attributes?: AttributesRequestV1
  capabilities?: string[] | null
  /** A unique identification numbers assigned by FINRA to registered brokers and brokerage firms */
  crd?: string | null
  emailDomainAllowlist?: EmailDomainAllowlistRequestV1
  externalId?: string | null
  fundAdminFirmInfo?: CreateFundAdminFirmInfoRequestV1
  fundManagerFirmInfo?: CreateFundManagerFirmInfoRequestV1
  isAllWelcomeEmailsEnabled?: boolean
  name: string
  parent?: NestedHierarchyEdgeRequestV1
  /** Users of how many levels up ancestor firms can access the firm */
  parentFirmAccessLevelLimit?: number | null
  parents?: NestedHierarchyEdgeRequestV1[] | null
  passwordLoginAllowed?: boolean | null
  pershingMultiTenantId?: string | null
  policies?: SaveEntityPolicyRequestV1[]
  roleChangeWorkflowEnabled?: boolean
  salesforceId?: string | null
  tags?: FirmTagV1[] | null
  totalAua?: number | null
}

export type ArrayFilterV1 = {
  in?: string[] | null
  overlap?: string[] | null
} | null

/**
 * Defines how to filter for firms on a firm search
 */
export type FirmFilterV1 = {
  externalId?: StringFilter
  firmType?: FirmTypeV1
  firmTypeExt?: ArrayFilterV1
  id?: UUIDFilter
  parentFirmId?: UUIDFilter
  searchText?: StringFilter
} | null

export type FirmIdFilterV1 = {
  eq?: string | null
  in?: string[] | null
  includeSubFirms?: boolean | null
  visibleOnly?: boolean | null
} | null

/**
 * Defines the possible filters for use in a User search
 */
export type UserFilterV1 = {
  email?: StringFilter
  externalId?: StringFilter
  firmId?: FirmIdFilterV1
  name?: StringFilter
  persona?: StringFilter
  roleId?: UUIDFilter
  searchText?: StringFilter
  status?: UserStatusFilterV1
  teamId?: UUIDFilter
  userId?: UUIDFilter
} | null

export type UserSearchRequestV1SortingItem =
  (typeof UserSearchRequestV1SortingItem)[keyof typeof UserSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserSearchRequestV1SortingItem = {
  ID_ASC: 'ID_ASC',
  ID_DESC: 'ID_DESC',
  FIRST_NAME_ASC: 'FIRST_NAME_ASC',
  FIRST_NAME_DESC: 'FIRST_NAME_DESC',
  LAST_NAME_ASC: 'LAST_NAME_ASC',
  LAST_NAME_DESC: 'LAST_NAME_DESC',
  EMAIL_ASC: 'EMAIL_ASC',
  EMAIL_DESC: 'EMAIL_DESC',
  STATUS_ASC: 'STATUS_ASC',
  STATUS_DESC: 'STATUS_DESC',
} as const

/**
 * Defines the filters, paging and sorting to use for a User search
 */
export interface UserSearchRequestV1 {
  filter?: UserFilterV1
  pageRequest?: PageRequest
  sorting?: UserSearchRequestV1SortingItem[] | null
}

/**
 * Filtering on the value of UserStatus
 */
export type UserStatusFilterV1 = {
  eq?: UserStatusNullableV1
  exists?: boolean | null
  in?: UserStatusNullableV1[] | null
  ne?: UserStatusNullableV1
} | null

/**
 * Defines a parent entity under which a newly created entity should sit. The entity can be a team or firm.
 */
export type NestedHierarchyEdgeRequestV1 = {
  attributes?: AttributesRequestV1
  parentId: string
  parentType: HierarchyEntityTypeV1
} | null

/**
 * Defines an external SSO identity for a user.
 */
export interface UserSsoMappingV1 {
  provider: string
  providerUserId: string
}

export interface QualificationV1 {
  qualification: string
  type: string
  validUntil?: string | null
}

/**
 * Used to create an address in the IAM database as part of creating a user/team/firm
 */
export type AddressRequestV1 = {
  addressType: AddressTypeV1
  alternateEmail?: string | null
  city: string
  country: string
  fax?: string | null
  phone?: string | null
  postalCode: string
  primaryEmail?: string | null
  state: string
  street1: string
  street2?: string | null
} | null

/**
 * Used to create a user in the IAM database. Can be put directly into a hierarchy by providing parents
 */
export interface UserRequestV1 {
  address?: AddressRequestV1
  attributes?: AttributesRequestV1
  block?: boolean
  capabilities?: string[]
  /** A unique identification numbers assigned by FINRA to registered brokers and brokerage firms */
  crd?: string | null
  email: string
  externalId?: string | null
  firstName: string
  forceResetPassword?: boolean | null
  isSupervisor?: boolean
  lastName: string
  middleInitial?: string | null
  mobilePhone?: string | null
  parents?: NestedHierarchyEdgeRequestV1[]
  phone?: string | null
  qualifications?: QualificationV1[]
  roleIds: string[] | null
  salesforceId?: string | null
  ssoMappings?: UserSsoMappingV1[] | null
  title?: string | null
}

/**
 * Accept a particular version of a terms and conditions agreement for a user.
 */
export interface AcceptTermsAndConditionsInputV1 {
  type: string
  version: number
}

/**
 * Used to create a Policy in the IAM database
 */
export interface SaveEntityPolicyRequestV1 {
  owner: PolicyOwnerV1
  policy: string
  policyType: PolicyTypeV1
  resourceType: string
}

/**
 * Used to create a Policy in the IAM database
 */
export interface CreatePolicyRequestV1 {
  owner: PolicyOwnerV1
  policy: string
  policyType: PolicyTypeV1
  resourceType: string
  subjectId: string
  subjectType: HierarchyEntityTypeV1
}

/**
 * A representation of when a user accepted a particular version of a terms and conditions agreement. The agreement has not been accepted if acceptedAt is null.
 */
export interface UserTermsAndConditionsV1 {
  acceptedAt?: string | null
  type?: string
  userId?: string
  version: number
}

/**
 * Representation of a terms and conditions agreement.
 */
export interface TermsAndConditionsV1 {
  id?: string
  type?: string
  version: number
}

/**
 * Represents the response to the initiation of a switch user session
 */
export interface StartSwitchUserResponseV1 {
  sessionId?: string
}

export interface PageInfo {
  endCursor?: string
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  startCursor?: string
}

/**
 * Represents one page of roles from a list of roles.
 */
export interface PagedRolesV1 {
  items: RoleV1[]
  pageInfo: PageInfo
}

/**
 * Represents one page of firms from a list of firms.
 */
export interface PagedFirmsV1 {
  items: FirmV1[]
  pageInfo: PageInfo
}

/**
 * Represents one page of teams from a list of teams.
 */
export interface PagedTeamsV1 {
  items: TeamV1[]
  pageInfo: PageInfo
}

/**
 * Represents one page of users from a list of users.
 */
export interface PagedUsersV1 {
  items: UserV1[]
  pageInfo: PageInfo
}

export interface UnswitchUserEventV1 {
  currentUserId: string
}

export interface SwitchUserEventV1 {
  currentUserId: string
  switchUserId: string
}

/**
 * Unique identifier for an iam entity
 */
export type EntityIdV1 = string

export type HierarchyEntityTypeV1 =
  (typeof HierarchyEntityTypeV1)[keyof typeof HierarchyEntityTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HierarchyEntityTypeV1 = {
  USER: 'USER',
  TEAM: 'TEAM',
  FIRM: 'FIRM',
} as const

export type AdvisorFirmTypeV2 =
  (typeof AdvisorFirmTypeV2)[keyof typeof AdvisorFirmTypeV2]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdvisorFirmTypeV2 = {
  BROKER_DEALER: 'BROKER_DEALER',
  RIA: 'RIA',
  HYBRID: 'HYBRID',
  BANK_AND_TRUST: 'BANK_AND_TRUST',
  RIA_AGGREGATOR: 'RIA_AGGREGATOR',
  NATIONAL_RIA: 'NATIONAL_RIA',
  FAMILY_OFFICE: 'FAMILY_OFFICE',
  OTHER: 'OTHER',
} as const

/**
 * @deprecated
 */
export type AdvisorFirmTypeV1 =
  (typeof AdvisorFirmTypeV1)[keyof typeof AdvisorFirmTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdvisorFirmTypeV1 = {
  BROKER_DEALER: 'BROKER_DEALER',
  FAMILY_OFFICE: 'FAMILY_OFFICE',
  HYBRID: 'HYBRID',
  RIA: 'RIA',
  OTHER: 'OTHER',
} as const

export type FirmTypeV1 = (typeof FirmTypeV1)[keyof typeof FirmTypeV1] | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FirmTypeV1 = {
  FUND_ADMIN_FIRM: 'FUND_ADMIN_FIRM',
  FUND_MANAGER_FIRM: 'FUND_MANAGER_FIRM',
  ADVISOR_FIRM: 'ADVISOR_FIRM',
  CAIS_FIRM: 'CAIS_FIRM',
} as const

export type UserStatusNullableV1 =
  | (typeof UserStatusNullableV1)[keyof typeof UserStatusNullableV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatusNullableV1 = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
} as const

export type UserStatusV1 = (typeof UserStatusV1)[keyof typeof UserStatusV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatusV1 = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
} as const

export type NullStringV1 =
  | (typeof NullStringV1)[keyof typeof NullStringV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NullStringV1 = {} as const

/**
 * A delta of changes to a list of entities identified by a string property
 */
export interface StringDeltaV1 {
  add?: string[]
  remove?: string[]
}

/**
 * A delta of changes to a list of entities identified by UUID
 */
export interface UUIDDeltaV1 {
  add?: string[]
  remove?: string[]
}

/**
 * Result of a role recertification request
 */
export interface FirmRoleRecertificationResultV1 {
  status?: WorkflowStatusV1
  workflowId?: string | null
}

/**
 * Request to recertify a role
 */
export interface FirmRoleRecertificationRequestV1 {
  label?: string | null
  roleIds: string[]
}

/**
 * Result of a role change request
 */
export interface FirmRoleChangeResultV1 {
  status?: WorkflowStatusV1
  workflowId?: string | null
}

export type RoleEnablementRequestV1Item = {
  isEnabled: boolean
  roleId: string
}

/**
 * Request to enable or disable a role
 */
export type RoleEnablementRequestV1 = RoleEnablementRequestV1Item[]

export type PersonaV1 = string

/**
 * @deprecated
 */
export type UserTypeV1 = (typeof UserTypeV1)[keyof typeof UserTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserTypeV1 = {
  ADVISOR: 'ADVISOR',
  ASSOCIATE: 'ASSOCIATE',
  PRODUCT: 'PRODUCT',
  LEGAL: 'LEGAL',
  HOME_OFFICE: 'HOME_OFFICE',
  OPERATIONS: 'OPERATIONS',
  PORTAL_USER: 'PORTAL_USER',
  FUND_MANAGER: 'FUND_MANAGER',
  CAIS: 'CAIS',
  CAIS_STRUCTURED_NOTES_SALES: 'CAIS_STRUCTURED_NOTES_SALES',
  ADMIN: 'ADMIN',
  PARTNER_DEVELOPER: 'PARTNER_DEVELOPER',
  SUPERVISOR: 'SUPERVISOR',
} as const

/**
 * Request to change properties of a role that may require approval
 */
export interface FirmRoleChangeRequestV1 {
  archetype?: UserTypeV1
  description?: string | null
  /** Indicates whether the role (from a root or parent firm) can be inherited by sub-firms. By default all roles are inherited. */
  inheritable?: boolean | null
  isSupervisor?: boolean
  name?: string | null
  numberOfChangeApprovals?: number | null
  owners?: UUIDDeltaV1
  permissionDelta?: StringDeltaV1
  persona?: PersonaV1
  rationale: string
  userDelta?: UUIDDeltaV1
}

/**
 * Input to save a new role
 */
export interface FirmRoleRequestV1 {
  archetype?: UserTypeV1
  description?: string | null
  firmId?: string | null
  /** Indicates whether the role (from a root or parent firm) can be inherited by sub-firms. By default all roles are inherited. */
  inheritable?: boolean
  isSupervisor?: boolean
  name: string | null
  numberOfChangeApprovals?: number
  owners?: string[]
  permissionIds?: string[] | null
  persona?: PersonaV1
}

/**
 * Roles in IAM are used to associate permissions to users. A role can either be an out-of-the-box role, or a custom role associated with a Firm. | One or more permissions are mapped to a role, and a role is mapped to a user. This means that when we resolve the permissions for a user, we retrieve all | of the permissions for all of the roles that they have mapped to them. A role can be mapped to multiple users. Access is defined by permissions, not by roles.
 */
export interface RoleV1 {
  archetype: UserTypeV1
  /** A unique identification numbers assigned by CAIS to registered roles */
  caisId?: string | null
  createDate?: string
  deleteDate?: string | null
  description?: string | null
  firmId?: string | null
  id: string
  /** Indicates whether the role (from a root or parent firm) can be inherited by sub-firms. By default all roles are inherited. */
  inheritable?: boolean
  isDefault?: boolean | null
  isEnabled?: boolean | null
  isSupervisor?: boolean | null
  name: string
  numberOfChangeApprovals?: number | null
  owners?: string[] | null
  permissions?: PermissionV1[]
  persona?: PersonaV1
  updateDate?: string | null
  version: number
}

/**
 * @deprecated
 */
export type ProfileUserTypeV1 =
  (typeof ProfileUserTypeV1)[keyof typeof ProfileUserTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProfileUserTypeV1 = {
  USER_TYPE_UNSPECIFIED: 'USER_TYPE_UNSPECIFIED',
  USER_TYPE_CAIS: 'USER_TYPE_CAIS',
  USER_TYPE_ADVISOR: 'USER_TYPE_ADVISOR',
  USER_TYPE_FUND_MANAGER: 'USER_TYPE_FUND_MANAGER',
  USER_TYPE_ADMIN: 'USER_TYPE_ADMIN',
  USER_TYPE_SUPERVISOR: 'USER_TYPE_SUPERVISOR',
} as const

export type PermissionV1SwitchUserMode =
  (typeof PermissionV1SwitchUserMode)[keyof typeof PermissionV1SwitchUserMode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionV1SwitchUserMode = {
  ALL_ENV: 'ALL_ENV',
  TEST_ENV: 'TEST_ENV',
  NONE: 'NONE',
} as const

/**
 * A capability describes a section of business functionality as part of the CAIS platform. For example, Insights or Structured Products.
 */
export interface CapabilityV1 {
  id: string
  name?: string | null
}

export interface PermissionV1 {
  capability: CapabilityV1
  deny: boolean
  description?: string | null
  id: string
  name: string
  switchUserMode?: PermissionV1SwitchUserMode
}

export type AddressTypeV1 = (typeof AddressTypeV1)[keyof typeof AddressTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressTypeV1 = {
  PERMANENT: 'PERMANENT',
  MAILING: 'MAILING',
  OFFICE: 'OFFICE',
  CUSTODIAN: 'CUSTODIAN',
} as const

/**
 * A representation of an address in IAM. Addresses can be associated with a user, team or firm. Addresses are retrieved as part of the entity with which they are associated.
 */
export type AddressV1 = {
  addressType: AddressTypeV1
  alternateEmail?: string | null
  city: string
  country: string
  createDate?: string
  deleteDate?: string | null
  fax?: string | null
  id: string
  phone?: string | null
  postalCode: string
  primaryEmail?: string | null
  state: string
  street1: string
  street2?: string | null
  updateDate?: string | null
} | null

export type UUIDListV1 = string[]

/**
 * The value to be used within the operations.
 */
export type PatchDocumentV1Value = unknown | null

/**
 * The operation to be performed
 */
export type PatchDocumentV1Op =
  (typeof PatchDocumentV1Op)[keyof typeof PatchDocumentV1Op]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchDocumentV1Op = {
  add: 'add',
  remove: 'remove',
  replace: 'replace',
  move: 'move',
  copy: 'copy',
  test: 'test',
} as const

/**
 * A JSONPatch document as defined by RFC 6902 | https://jsonpatch.com/
 */
export interface PatchDocumentV1 {
  /** A string containing a JSON Pointer value. */
  from?: string | null
  /** The operation to be performed */
  op: PatchDocumentV1Op
  /** A JSON-Pointer */
  path: string
  /** The value to be used within the operations. */
  value?: PatchDocumentV1Value
}

export type RefresherStatusV1 =
  (typeof RefresherStatusV1)[keyof typeof RefresherStatusV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefresherStatusV1 = {
  ALREADY_RUNNING: 'ALREADY_RUNNING',
  STARTED: 'STARTED',
} as const

export type UserProfileFeatureV1Status =
  (typeof UserProfileFeatureV1Status)[keyof typeof UserProfileFeatureV1Status]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileFeatureV1Status = {
  ON: 'ON',
  OFF: 'OFF',
} as const

export interface UserProfileFeatureV1 {
  name: string
  status: UserProfileFeatureV1Status
}

export type UserProfileFirmV1 = {
  id?: string
  name?: string
} | null

/**
 * UserProfile contains a subset of the data from an IAM user, along with additional contextual information that relates to that user.
 */
export interface UserProfileV1 {
  allParentFirms?: UserProfileFirmV1[]
  attributes?: AttributesV1
  capabilities?: CapabilityV1[]
  deepLinkingSupported?: boolean
  emailAddress?: string
  features: UserProfileFeatureV1[]
  firm?: UserProfileFirmV1
  firstName?: string
  id?: string
  inheritedAttributes?: InheritedAttributesV1[]
  isRoleOwner?: boolean
  isSwitched?: boolean
  lastName?: string
  permissions?: string[]
  personas?: PersonaV1[]
  termsAndConditions?: UserTermsAndConditionsV1[]
  /**
   * Use personas instead
   * @deprecated
   */
  userTypes?: ProfileUserTypeV1[]
}

/**
 * Firm statistics - user count, etc
 */
export interface FirmStatsV1 {
  firmId?: string
  totalUsers?: number
}

/**
 * Team statistics - user count, etc
 */
export interface TeamStatsV1 {
  activeUsers?: number
  teamId?: string
  totalUsers?: number
}

/**
 * A representation of an email domain allowlist entry in IAM.
 */
export interface EmailDomainAllowlistV1 {
  domains: string[]
  enabled: boolean
  inheritFromParent: boolean
}

export type FundManagerFirmInfoV1 = {
  fundManagerId?: string
} | null

export type FundAdminFirmInfoV1 = {
  fundAdminId?: string
} | null

export type AdvisorFirmInfoV1 = {
  type?: AdvisorFirmTypeV1
  typeV2?: string
} | null

export type SimulationV1 = (typeof SimulationV1)[keyof typeof SimulationV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimulationV1 = {
  NWM: 'NWM',
} as const

export type PolicyTypeV1 = (typeof PolicyTypeV1)[keyof typeof PolicyTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyTypeV1 = {
  CODE: 'CODE',
} as const

export type PolicyOwnerV1 = (typeof PolicyOwnerV1)[keyof typeof PolicyOwnerV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyOwnerV1 = {
  REPORTING_SERVICE: 'REPORTING_SERVICE',
  PORTAL: 'PORTAL',
} as const

/**
 * Policies define how to determine what access should be granted for a user when accessing a particular resource. The subject defines to | whom this policy is associated (e.g. a firm), and the resource defines when it is relevant. In most cases, subject is likely to be a Firm. | Policies are inherited from anywhere above them in the hierarchy, with the "closest" taking priority.
 */
export interface PolicyV1 {
  createDate: string
  deleteDate?: string | null
  id: string
  owner: PolicyOwnerV1
  policy: string
  policyType: PolicyTypeV1
  resourceType: string
  subjectId: string
  subjectType: HierarchyEntityTypeV1
  updateDate?: string | null
}

export interface ParentV1 {
  parentId?: string
  parents?: ParentV1[]
  type?: HierarchyEntityTypeV1
}

/**
 * A representation of a firm in IAM
 */
export interface FirmSnapshotV1 {
  address?: AddressV1
  advisorFirmInfo?: AdvisorFirmInfoV1
  attributes?: AttributesV1
  /** The unique identifier for the firm in CAIS. Always present but optional in the schema for backwards compatibility. */
  caisId?: string
  capabilities?: CapabilityV1[]
  /** The CRD number for the firm */
  crd?: string | null
  createDate?: string
  deleteDate?: string
  entityType?: HierarchyEntityTypeV1
  externalId?: string
  firmId?: string
  firmTypes?: FirmTypeV1[]
  fundAdminFirmInfo?: FundAdminFirmInfoV1
  fundManagerFirmInfo?: FundManagerFirmInfoV1
  id: string
  name?: string
  parent?: ParentV1
  parentFirmAccessLevelLimit?: number
  passwordLoginAllowed?: boolean
  pershingMultiTenantId?: string
  policies?: PolicyV1[]
  salesforceId?: string
  /** Type of firm tag. Supported values are: 
  - SOLUTION 
  - ENTERPRISE
 */
  tags?: string[] | null
  totalAua?: number
  totalUsers?: number
  updateDate?: string
  validArchetypesForFirm?: UserTypeV1[]
  validCapabilitiesForFirm?: CapabilityV1[]
  validPersonasForFirmRoles?: string[]
  version: number
}

export interface TeamSnapshotV1 {
  address?: AddressV1
  attributes?: AttributesV1
  /** The unique identifier for the team in CAIS. Always present but optional in the schema for backwards compatibility. */
  caisId?: string
  capabilities: CapabilityV1[]
  createDate?: string
  deleteDate?: string
  description?: string
  entityType?: HierarchyEntityTypeV1
  externalId?: string
  id: string
  name?: string
  parent?: ParentV1
  updateDate?: string
  version: number
}

export interface UserSnapshotV1 {
  address?: AddressV1
  attributes?: AttributesV1
  block?: boolean | null
  /** The unique identifier for the user in CAIS. Always present but optional in the schema for backwards compatibility. */
  caisId?: string
  capabilities?: CapabilityV1[]
  /** The CRD number for the user */
  crd?: string | null
  createDate: string
  deleteDate?: string | null
  email: string
  entityType?: HierarchyEntityTypeV1
  externalId?: string | null
  firmDetails?: UserFirmDetailsV1[] | null
  firmNames: string[]
  firmTypes?: FirmTypeV1[]
  firstName: string
  id: string
  isSupervisor?: boolean
  lastName: string
  lastVisitDate?: string | null
  middleInitial?: string | null
  mobilePhone?: string | null
  mobileValidatedOn?: string | null
  parents?: ParentV1[]
  personas?: PersonaV1[]
  phone?: string | null
  registerDate?: string | null
  roles?: RoleV1[]
  salesforceId?: string | null
  ssoMappings?: UserSsoMappingV1[]
  status: UserStatusV1
  title?: string | null
  updateDate?: string | null
  version: number
}

export type UserAuthDetailsV1Metadata = { [key: string]: unknown }

export interface UserAuthDetailsV1 {
  connectionType?: ConnectionTypeResponseV1[]
  createDate?: string
  email?: string
  metadata?: UserAuthDetailsV1Metadata
}
