import { useGetUserCeCredits } from '@cais-group/caisiq/domain/course'
import { useGetUserLicences } from '@cais-group/caisiq/domain/user'
import { useApiErrorHandler } from '@cais-group/caisiq/util/hook/use-api-error-handler'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'

import { ProfilePage } from './caisiq-feature-profile-page'

export const ProfilePageContainer = () => {
  const errorHandlerFactory = useApiErrorHandler()

  const { userCredits: earnedCredits } = useGetUserCeCredits({
    onError: errorHandlerFactory(),
  })
  const { data, isFetched } = useGetUserLicences()

  return (
    <ProfilePage
      companyName={userSettingsService.companyName}
      licences={data}
      isFetched={isFetched}
      fullName={userSettingsService.user.fullname}
      email={userSettingsService.user.email}
      coursesWithEarnedCredits={earnedCredits?.completedCourses}
      credits={earnedCredits?.credits}
      ceGuidePage={userSettingsService.theme.ceGuidePage}
    />
  )
}
