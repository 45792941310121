import { Skeleton } from '@mui/material'
import classNames from 'classnames'
import React from 'react'

type InfiniteLoadingIndicatorProps = {
  className?: string
  size?: string
  isLoading?: boolean
}
export const InfiniteLoadingIndicator = React.forwardRef<
  HTMLDivElement,
  InfiniteLoadingIndicatorProps
>(({ className, size = '1.8rem', isLoading }, ref) => {
  return (
    <div
      className={classNames(
        'relative flex w-full items-center justify-center',
        className
      )}
    >
      <div
        ref={ref}
        className="h-232 -top-376 absolute left-0 right-0 z-0 flex w-full"
      />
      {isLoading && (
        <div className="flex w-full flex-col px-12 pb-12">
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant="text"
              className="bg-neutral-100"
              sx={{ fontSize: size }}
            />
          ))}
        </div>
      )}
    </div>
  )
})

export const useWatchLastItemInList = (
  divRef: React.RefObject<Element>,
  {
    callback = () => {},
    dependencies = [],
  }: { callback: () => void; dependencies: unknown[] }
) => {
  React.useEffect(() => {
    const item = divRef.current
    if (!item) return

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries
      if (entry.isIntersecting && entry.intersectionRatio >= 0.9) {
        callback()
      }
    }

    const observer = new IntersectionObserver(handleIntersect, {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.9, // Fire the callback when 90% of the item is visible
    })

    observer.observe(item)
    return () => {
      observer.unobserve(item)
    }
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps
}
