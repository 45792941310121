import cx from 'classnames'

import { DateTime } from './date-time'
import { OwnershipTag } from './ownership-tag'

interface TagAndTimeProps {
  contentOwnershipTag?: { name?: string }
  date?: string
  includeTime?: boolean
  timezone?: string
}

export const TagAndTime = ({
  contentOwnershipTag,
  date,
  includeTime,
  timezone,
}: TagAndTimeProps) =>
  contentOwnershipTag || date ? (
    <div
      className={cx({
        'flex flex-wrap gap-8': contentOwnershipTag && date,
      })}
    >
      {contentOwnershipTag?.name && (
        <OwnershipTag name={contentOwnershipTag.name} />
      )}
      <DateTime date={date} includeTime={includeTime} timezone={timezone} />
    </div>
  ) : null
