import { PaletteOptions } from '@mui/material/styles'

import { colors } from '@cais-group/equity/particles/colors'

export const paletteColors = {
  neutral: {
    lighter: colors['eq-color-neutral-100'],
    light: colors['eq-color-neutral-200'],
    main: colors['eq-color-neutral-300'],
    dark: colors['eq-color-neutral-500'],
    darker: colors['eq-color-neutral-600'],
    contrastText: colors['eq-color-neutral-900'],
  },
  black: {
    main: colors['eq-color-neutral-900'],
    contrastText: colors['eq-color-neutral-0'],
  },
  primary: {
    main: colors['eq-color-primary-600'],
    contrastText: colors['eq-color-neutral-0'],
    dark: colors['eq-color-primary-700'],
    darker: colors['eq-color-primary-900'],
  },
  secondary: {
    main: colors['eq-color-accent-600'],
    contrastText: colors['eq-color-neutral-0'],
  },
  info: {
    light: colors['eq-color-primary-200'],
    main: colors['eq-color-primary-300'],
    contrastText: colors['eq-color-primary-600'],
    dark: colors['eq-color-primary-300'],
  },
  success: {
    main: colors['eq-color-success-600'],
    contrastText: colors['eq-color-neutral-0'],
  },
  warning: {
    main: colors['eq-color-warning-600'],
    contrastText: colors['eq-color-neutral-0'],
  },
  error: {
    main: colors['eq-color-error-600'],
    contrastText: colors['eq-color-neutral-0'],
  },
}

export const palette: PaletteOptions = {
  ...paletteColors,
  // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0,
  mode: 'light',
  common: {
    black: colors['eq-color-neutral-900'],
    white: colors['eq-color-neutral-0'],
  },
  text: {
    primary: colors['eq-color-neutral-900'],
    secondary: colors['eq-color-neutral-600'],
    disabled: colors['eq-color-neutral-400'],
  },
  background: {
    paper: colors['eq-color-neutral-0'],
    default: colors['eq-color-neutral-100'],
  },
}
