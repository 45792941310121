import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useCloseOnNavigate = () => {
  const location = useLocation()
  useEffect(() => {
    const bodyDomNode = document.getElementsByTagName('body')[0]
    bodyDomNode.style.overflow = 'auto'
  }, [location])
}
