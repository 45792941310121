import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { H4 } from '@cais-group/approved/ui/typography'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { BannerWithButtonContainer } from '@cais-group/caisiq/ui/banner-with-button'
import { CardCourseSkeleton } from '@cais-group/caisiq/ui/card/course'
import { CardWhitepaper } from '@cais-group/caisiq/ui/card/whitepaper'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { NoResults } from '@cais-group/caisiq-ui-no-results'
import { LayoutGrid } from '@cais-group/shared/ui/layout'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

const StyledContainer = styled.div<{ empty: boolean }>`
  padding-top: ${(props) => (props.empty ? 'var(--s32)' : '0')};
  padding-bottom: ${(props) => (props.empty ? 'var(--s144)' : '0')};
`

const StyledGrid = styled(LayoutGrid)`
  padding-top: var(--s24);
  padding-bottom: var(--s144);
`
StyledGrid.displayName = 'StyledGrid'

const EmptyMessage = styled(H4)`
  color: rgb(var(--colors-neutral-400));
  text-align: center;
  margin-top: var(--s144);
  margin-bottom: var(--s144);
`

export type WhitepapersProps = {
  maybeSearchFilteredWhitepapers?: WhitepaperData[]
}

export const Whitepapers = (props: WhitepapersProps) => {
  const { maybeSearchFilteredWhitepapers } = props
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)
  const location = useLocation()

  return (
    <StyledContainer empty={maybeSearchFilteredWhitepapers?.length === 0}>
      {!maybeSearchFilteredWhitepapers ? (
        <StyledGrid>
          {Array.from(Array(10).keys(), (i) => (
            <CardCourseSkeleton key={i} />
          ))}
        </StyledGrid>
      ) : maybeSearchFilteredWhitepapers?.length === 0 ? (
        searchTerm === '' ? (
          <EmptyMessage>There are no whitepapers</EmptyMessage>
        ) : (
          <NoResults
            searchSetName="Whitepapers"
            searchTerm={searchTerm}
            onClick={() => setSearchTerm('')}
          />
        )
      ) : (
        <StyledGrid space="var(--s32)" min="var(--s608)">
          {maybeSearchFilteredWhitepapers?.map((whitepaper) => (
            <CardWhitepaper
              key={whitepaper.sys.id}
              whitepaper={whitepaper}
              onClick={() => trackingService.whitepaperClicked(whitepaper)}
              to={ROUTES.whitepaper(whitepaper.slug)}
              state={{ from: `${location.pathname}` }}
            />
          ))}
        </StyledGrid>
      )}
      <BannerWithButtonContainer />
    </StyledContainer>
  )
}
