import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useInfiniteQuery, type UseInfiniteQueryResult } from 'react-query'
import { usePrevious } from 'react-use'
import * as R from 'remeda'

import { useUniformContext } from '../context'
import type { UniformServices } from '../services'
import type {
  UniformAny,
  UniformFieldOption,
  UniformFieldProps,
} from '../types'
import { matchUniformService } from '../utils'

import { useDelayedState } from '.'

export type AsyncOptionProps<
  TUniformServices extends UniformServices = UniformServices,
  TFormFieldIds extends keyof UniformAny = UniformAny
> = {
  /** Uniform service to use for fetching data. */
  service?: keyof TUniformServices
  /** The names of the fields to watch for changes, in order to refetch data from service. */
  watchRemoteKeys?: TFormFieldIds[]
  /** The debounce time (in ms) when loading from the remote data source. */
  debounceTime?: number
  /** Fetch options even when disabled. */
  fetchWhenDisabled?: boolean
}

export type LocalOptionProps = Array<UniformFieldOption>

export type OptionsOrAsyncOptions<
  TUniformServices extends UniformServices = UniformServices,
  TFormFieldIds extends keyof UniformAny = UniformAny
> =
  | { options: LocalOptionProps; asyncOptions?: never }
  | {
      options?: never
      asyncOptions: AsyncOptionProps<TUniformServices, TFormFieldIds>
    }

export type OptionsServiceArgs = {
  search?: string
  endCursor?: string
  [key: string]: UniformAny
}

const useOptionsFromService = <
  TUniformServices extends UniformServices = UniformServices
>(
  asyncOptions: AsyncOptionProps<TUniformServices>,
  watchedFormValues: Record<string, string> | undefined,
  enabled = true
) => {
  const { getValues } = useFormContext()
  const { services, selectedOptions } = useUniformContext<TUniformServices>()
  const { setState: setFilter, debouncedState: debouncedSearch } =
    useDelayedState<string | undefined>(undefined, {
      debounceTime: asyncOptions.debounceTime,
    })
  const entries = React.useMemo(() => {
    return Object.assign({ search: debouncedSearch }, watchedFormValues)
  }, [debouncedSearch, watchedFormValues])
  const { data, ...result } = useInfiniteQuery({
    queryKey: [asyncOptions.service, entries],
    enabled,
    queryFn: async ({ pageParam = undefined }) => {
      const fn = matchUniformService({
        services,
        service: asyncOptions.service,
      })
      const args: OptionsServiceArgs = {
        ...entries,
        pageParam,
        endCursor: pageParam,
      }
      const result = await fn(
        args,
        getValues(),
        selectedOptions.latestFieldValue
      )
      return result
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage = {}) => {
      const pageInfo = lastPage?.pageInfo
      if (!pageInfo) return undefined
      return pageInfo && pageInfo.hasNextPage && pageInfo.endCursor
    },
  })

  return {
    value: R.pipe(
      data?.pages ?? [],
      R.flatMap((page) => page.data),
      (x) => x as Array<UniformFieldOption>
    ),
    setAsyncOptionsFilter: setFilter,
    ...result,
  }
}

function useWatchFormValues<
  TUniformServices extends UniformServices = UniformServices
>({
  debounceTime,
  watchRemoteKeys = [],
}: Omit<AsyncOptionProps<TUniformServices>, 'service'>): {
  debouncedQuery: Record<string, string> | undefined
  depsValues: string[]
} {
  const depsValues = useWatch({
    name: watchRemoteKeys,
    disabled: watchRemoteKeys.length === 0,
  })
  const { setState: setQueryObject, debouncedState: debouncedQuery } =
    useDelayedState<Record<string, string> | undefined>(undefined, {
      debounceTime,
    })
  const values = Object.fromEntries(
    watchRemoteKeys.map((key, index) => [key, depsValues[index]])
  )
  const prevValues = usePrevious(values)
  React.useEffect(() => {
    if (R.isDeepEqual(values, prevValues)) return
    setQueryObject(R.omitBy(values, (value) => value === '' || value === null))
  }, [prevValues, values, setQueryObject])
  return { debouncedQuery, depsValues }
}

const useInitialOption = (
  fieldId?: string
): UniformFieldOption | UniformFieldOption[] | undefined => {
  const { initialOptions = {} } = useUniformContext()
  const option = React.useMemo(() => {
    if (!fieldId) return undefined
    return initialOptions[fieldId]
  }, [initialOptions, fieldId])
  return option
}

function useCombinedInitialOptions(
  fieldId: string,
  result: ReturnType<typeof useOptionsFromService>
) {
  const initialOption = useInitialOption(fieldId)

  if (Array.isArray(initialOption)) {
    const map = new Map(result.value.map((item) => [item.value, item]))

    initialOption.forEach((o) => {
      const itemFound = map.get(o.value)
      if (!itemFound) map.set(o.value, o)
    })

    const value = Array.from(map.values())

    return {
      ...result,
      value,
    }
  }

  if (initialOption) {
    // NOTE: when initialOptions provided we want to set or replace existing item in the array without changing the order.
    const map = new Map(result.value.map((item) => [item.value, item]))
    const itemFound = map.get(initialOption.value)
    // NOTE: we have to check if the item already exists in the array. We don't want to replace it in case it's been updated
    // because then initialOptions value is outdated. We want to keep the latest value from the server.
    if (!itemFound) map.set(initialOption.value, initialOption)
    const value = Array.from(map.values())

    return {
      ...result,
      value,
    }
  }
  return {
    ...result,
    value: result.value,
  }
}

function useOptionsSendToObserver(
  fieldId: string,
  items: UniformAny[],
  prefix?: string
) {
  const prefixedFieldId = prefix ? `${prefix}.${fieldId}` : fieldId
  // This should be prefix-ed but doing so cause setState nesting somehow?
  const selectedId = useWatch({ name: prefixedFieldId })
  const { selectedOptions } = useUniformContext()
  const option = React.useMemo(
    () => items.find((item) => item.value === selectedId),
    [items, selectedId]
  )

  React.useEffect(() => {
    selectedOptions.send({
      id: prefixedFieldId,
      option,
    })
  }, [selectedOptions, option, prefixedFieldId])
}

export type UseOptionsPropsReturns = Partial<
  UseInfiniteQueryResult & {
    value: UniformFieldOption[]
    setAsyncOptionsFilter?: (inputValue?: string) => void
    depsValues: string[]
  }
>
export const useOptions = <
  TUniformServices extends UniformServices = UniformServices
>(
  asyncOptions: AsyncOptionProps<TUniformServices>,
  fieldId: string,
  parentPrefix?: string,
  fieldDisabled?: boolean
): UseOptionsPropsReturns => {
  const { debouncedQuery, depsValues } = useWatchFormValues({
    ...asyncOptions,
    watchRemoteKeys: asyncOptions.watchRemoteKeys?.map((key) => {
      const newKey = key.replace(
        /\[prefix\]/,
        parentPrefix ? `${parentPrefix}.` : ''
      )
      return newKey
    }),
  })

  const queryResult = useOptionsFromService(
    asyncOptions,
    debouncedQuery,
    !fieldDisabled
  )
  const result = useCombinedInitialOptions(fieldId, queryResult)
  useOptionsSendToObserver(fieldId, result.value, parentPrefix)

  return { ...result, depsValues }
}

export const __SPECS__ = {
  useOptionsFromService,
  useCombinedInitialOptions,
  useOptionsSendToObserver,
  useWatchFormValues,
}

const AsyncOptionsWithHook = <
  TUniformServices extends UniformServices = UniformServices,
  TFormFieldIds extends keyof UniformAny = UniformAny
>({
  field,
  asyncOptions = () => null,
}: {
  field: UniformFieldProps & {
    id: string
    asyncOptions: AsyncOptionProps<TUniformServices, TFormFieldIds>
  }
  asyncOptions: (props: ReturnType<typeof useOptions>) => JSX.Element | null
}) => {
  const props = useOptions(
    field.asyncOptions,
    field.id,
    field.parentPrefix,
    field.asyncOptions.fetchWhenDisabled !== true && field.disabled
  )

  const ui = asyncOptions(props)
  return ui
}

export const AsyncOptions = <
  TUniformServices extends UniformServices = UniformServices,
  TFormFieldIds extends keyof UniformAny = UniformAny
>({
  field,
  asyncOptions = () => null,
  options = () => null,
}: {
  field: UniformFieldProps &
    OptionsOrAsyncOptions<TUniformServices, TFormFieldIds> & { id: string }
  asyncOptions: (props: ReturnType<typeof useOptions>) => JSX.Element | null
  options: (options: LocalOptionProps) => JSX.Element | null
}): JSX.Element | null => {
  if (field.asyncOptions) {
    return <AsyncOptionsWithHook field={field} asyncOptions={asyncOptions} />
  }
  if (field.options) {
    const ui = options(field.options ?? [])
    return ui
  }
  return null
}
