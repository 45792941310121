import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import {
  transformFirmRequest,
  usePutFirm,
} from '@cais-group/caisiq/domain/firm'
import {
  useFormSaveHandler,
  useGetExperienceCoursesMetadata,
} from '@cais-group/caisiq/domain/manage-caisiq'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { ManageNavbarHeader } from '@cais-group/caisiq/ui/manage/navbar-header'
import { useContentfulNameFromIdMapper } from '@cais-group/caisiq/util/contentful/use-contentful-name-from-id-mapper'
import { useInvalidateByRegex } from '@cais-group/caisiq/util/default-query-function'
import { useGetExperiencesQuery } from '@cais-group/caisiq/util/graphql/contentful'
import { useApiPathWithParams } from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { showBanner } from '@cais-group/equity/organisms/notifications'
import { FormStepProvider } from '@cais-group/shared/ui/ordered-fieldset'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  ApiPaths,
  CaisIqFirm,
  Catalog,
  ExperienceResponse,
} from '@cais-group/shared/util/type/caisiq-be'

import { EnableCaisiqPage } from './caisiq-feature-manage-enable-cais-iq-page'

export const EnableCaisiqContainer = () => {
  const firm = useQuery<CaisIqFirm>(useApiPathWithParams(ApiPaths.getFirm))
  const firmExperiences = useQuery<ExperienceResponse[]>(
    ApiPaths.getAllExperiences
  )
  const catalogs = useQuery<Catalog[]>(ApiPaths.getAllCatalogs)
  const contentfulExperiences = useGetExperiencesQuery({
    preview: previewService.enabled,
  })
  const {
    data: experiencesCoursesMetadata,
    isFetching: experienceCoursesMetadataFetching,
    isFetched: experienceCoursesMetadataFetched,
  } = useGetExperienceCoursesMetadata(firm?.data?.experience?.uuid)

  const invalidate = useInvalidateByRegex()
  const navigate = useNavigate()
  const { mutate } = usePutFirm()

  const getContentfulNameById = useContentfulNameFromIdMapper()

  const handleSubmit = useFormSaveHandler({
    mutate,
    transform: transformFirmRequest(firm.data),
    onSuccess: async () => {
      navigate(ROUTES.manageFirms)
      await invalidate(/manage[/]firms/)
      showBanner({
        type: 'success',
        title: `${firm.data?.name} has been enabled`,
      })
    },
  })

  const handleSubmitWithTracking = (params: ExperienceFields) => {
    return handleSubmit(params).then(() => {
      const { id, name, active, experience } = firm.data ?? {}

      trackingService.manageFirmEnabled({
        firmId: id ?? '',
        firmName: name ?? '',
        firmActive: Boolean(active),
        firmExperienceId: experience?.uuid ?? '',
        name: params.name,
        label: params.label,
        contentfulName: getContentfulNameById(params.contentfulId),
        almCatalog: params.catalogId ?? '',
        coursesEnabled: params.coursesEnabled,
        ceCreditsEnabled: params.ceCreditEnabled,
      })
    })
  }

  if (
    [
      firmExperiences.isFetching,
      firm.isFetching,
      catalogs.isFetching,
      experienceCoursesMetadataFetching,
    ].includes(true)
  ) {
    return <LoadingContainer state={LoadingState.LOADING} />
  }

  if (!firm.data) {
    return null
  }

  return (
    <FormStepProvider steps={2}>
      <EnableCaisiqPage
        saveLabel={`Enable CAIS IQ for ${firm.data.name}`}
        firmExperiences={firmExperiences.data || []}
        firm={firm.data}
        catalogs={catalogs.data || []}
        handleSubmit={handleSubmitWithTracking}
        defaultCourses={experiencesCoursesMetadata}
        coursesFetched={experienceCoursesMetadataFetched}
        contentfulExperiences={
          contentfulExperiences.data?.firmCollection?.items
        }
        headerNav={
          <ManageNavbarHeader
            submitLabel="Enable&nbsp;CAIS IQ"
            pageName="Enable CAIS IQ"
            onSubmit={handleSubmitWithTracking}
          />
        }
      />
    </FormStepProvider>
  )
}
