/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Investor Profile API
 * OpenAPI spec version: v1
 */
export type GetAccountVersionParams = {
  /**
   * When provided, the response will be expanded to include all details of the listed resources. Supported values are: household, contact
   */
  expand?: string[]
}

export type GetAccountParams = {
  /**
   * When provided, the response will be expanded to include all details of the listed resources. Supported values are: household, contact
   */
  expand?: string[]
}

/**
 * When provided, the response will be expanded to include all details of the listed resources. Supported values are: household, contact
 */
export type ExpandParameter = string[]

/**
 * Internal Server Error
 */
export type N500Response = ErrorResponse

/**
 * Not found
 */
export type N404Response = ErrorResponse

/**
 * User not allowed
 */
export type N403Response = ErrorResponse

/**
 * User not authenticated
 */
export type N401Response = ErrorResponse

/**
 * Bad request
 */
export type N400Response = ErrorResponse

/**
 * The name and number of a bank account.
 */
export type BankAccountNameAndNumberV1 = {
  name?: string
  nullable?: unknown
  number?: string | null
} | null

export type FundingBankV1Address = {
  addressLine1: string
  addressLine2?: string | null
  city: string
  country: CountriesResponseV1
  state: string
  zipCode: string
} | null

/**
 * Provides details about an investor, or if applicable, any underlying beneficial owner(s), who is a current or former senior foreign political figure, or has any immediate family member or close associate who is a senior foreign political figure.
 */
export type PoliticalFigureDetailsV1 = {
  /** For which government? */
  government: string
  /** What position in the government? */
  positionInGovernment: string
  /** If an immediate family member or close associated of a senior foreign political figure, what relationship to the senior foreign political figure? */
  relationshipToFigure?: string | null
} | null

/**
 * Response for principle place of business details.
 */
export type PrinciplePlaceOfBusinessDetailsResponseV1 = {
  /** City. */
  city: string
  country: CountriesResponseV1
  /** State. */
  state: string
}

/**
 * Information about the funding account (if not the custodian account).
 */
export type FundingAccountV1 = {
  abaOrChipsNumber?: string | null
  /** The type of bank account. Currently supported values are `BROKERAGE`, `CHECKING`, and `SAVINGS`, but others may be added in the future. */
  accountType?: string | null
  additionalReference?: string | null
  bankAccount?: BankAccountNameAndNumberV1
  clientAccount?: BankAccountNameAndNumberV1
  forFurtherCreditAccount?: BankAccountNameAndNumberV1
  nullable?: unknown
  swift?: string | null
} | null

/**
 * Bank information for a funding account (if not the custodian account).
 */
export type FundingBankV1 = {
  address?: FundingBankV1Address
  bankName?: string | null
  /** Is the bank located in a Financial Action Task Force (FATF) country? */
  inFatfCountry?: boolean | null
} | null

/**
 * Principle place of business details.
 */
export type PrinciplePlaceOfBusinessDetailsV1 = {
  /** City. */
  city: string
  country: CountriesV1
  /** State. */
  state: string
}

/**
 * Provides additional details for investors which are an insurance company whose general account assets (or the assets of a separate account or  bank common or collective trust that has investors who) are subject to the provisions of ERISA (or are a plan under Section 4975 of the IRSA code of 1986, including a Keogh Plan or an IRA).
 */
export type ErisaInsuranceCoDetailsV1 = {
  /** The applicable percentage of investors who are subject to the provisions of ERISA. */
  investorsSubjectToErisaPercent?: number | null
  /** The applicable percentage of investors who are a plan under Section 4975 of the Internal Revenue Code of 1986, including a Keogh Plan or an individual retirement account (an "IRA"). */
  planInvestorsPercent?: number | null
} | null

/**
 * Provides additional details for the investors subject to the provisions of the Employee Retirement Income Security Act of 1974 ("ERISA").
 */
export type ErisaDetailsV1 = {
  /** Does the investor have total assets in excess of $5,000,000? */
  assetsExceedFiveMillion: boolean
  /** Is the investment decision being made by a plan fiduciary which is a bank, savings and loan association, insurance company or registered investment Advisor? */
  planFiduciaryIsBankEtc: boolean
  /** Is the investor a self-directed plan and the decision to invest is made by those participants investing, and each such participant qualifies as an "accredited investor" */
  selfDirectedByAccreditedInvestors: boolean
} | null

/**
 * Provides details about the eligibility of the client(s), regarding ineligibility or limitation in participation in New Issues.
 */
export type RestrictedEligibilityV1 = {
  /** Investor elects to be treated as a Covered Person, and will be ineligible or limited in participating in New Issues. */
  coveredPerson: boolean
  /** Investor elects to be treated as a Restricted Person, and will be ineligible or limited in participating in New Issues. */
  restrictedPerson: boolean
}

/**
 * Provides details about the eligibility of the client(s), regarding Portfolio Managers (and their immediate family members).
 */
export type PortfolioManagerEligibilityV1 = {
  /** The Investor, or a person having a beneficial interest in the Investor, is an immediate family member, materially supports, or receives material support from a person described immediately as being a Portfolio Manager (see `portfolioManager`). */
  familyOrMaterialSupport: boolean
  /** The Investor, or a person having a beneficial interest in the Investor, has the authority to buy or sell securities for a bank, savings and loan institution, insurance company, investment adviser or collective investment account. */
  portfolioManager: boolean
}

/**
 * Provides details about the eligibility of the client(s), regarding Officers and Directors of a Public or Covered Non-Public Company.
 */
export type OfficersAndDirectorsEligibilityV1 = {
  /** The Investor, or a person having a beneficial interest in the Investor, is an executive officer or director of a Covered Non-Public Company. */
  coveredNonPublicCompany: boolean
  /** The name of the applicable company/companies. */
  coveredNonPublicCompanyName?: string | null
  /** A Person Materially Supported by an Investor, or a person having a beneficial interest in the Investor, who is an executive officer or director of a Public Company or Covered Non-Public Company? */
  materiallySupportedByEither: boolean
  /** The name of the applicable company/companies. */
  materiallySupportedByEitherName?: string | null
  /** The Investor, or a person having a beneficial interest in the Investor, is an executive officer or director of a Public Company. */
  publicCompany: boolean
  /** The name of the applicable company/companies. */
  publicCompanyName?: string | null
}

/**
 * Provides details about the eligibility of the client(s), regarding Finders and Fiduciaries (and their immediate family members).
 */
export type FinderFiduciaryEligibilityV1 = {
  /** The Investor, or a person having a beneficial interest in the Investor, is an immediate family member, materially supports, or receives material support from a person described in `managingUnderwriter` as a Finder or Fiduciary. */
  familyOrMaterialSupport: boolean
  /** The Investor, or a person having a beneficial interest in the Investor, acts as a finder or acts in a fiduciary capacity (including, among others, attorneys, accountants and financial consultants) to the managing underwriter in public offerings of New Issues securities. */
  finderOrFiduciary: boolean
}

/**
 * Provides details about the eligibility of the client(s), regarding Persons Owning a Broker-dealer. Each property indicates some threshold of ownership (direct or indirect) which is met.
 */
export type BrokerDealerOwnerEligibilityV1 = {
  /** Owner, directly or indirectly, of 10% or more of a broker-dealer. */
  owningBrokerDealer: boolean
  /** Owner, directly or indirectly, of 25% or more of an entity that owns, directly or indirectly, 10% more of a broker-dealer. */
  owningEntity: boolean
  /** Owner, directly or indirectly, of 10% or more of a public reporting company that owns 5% or more of a broker-dealer. */
  owningPublicReportingCompany: boolean
  /** Owner, directly or indirectly, of 25% or more of a public reporting company that has an indirect interest in a broker-dealer. */
  owningPublicReportingCompanyHavingInterestIn: boolean
}

/**
 * Provides details about the eligibility of the client(s), regarding Broker-Dealer and/or Its Personnel.
 */
export type BrokerDealerEligibilityV1 = {
  /** Agent of a member firm of FINRA or any broker-dealer engaged in the Investment banking or securities business. */
  agent: boolean
  /** Officer, director, general partners, or employee of, or person associated with, a member of FINRA or any other broker-dealer. */
  employeeOrAssociatedWith: boolean
  /** Employed by or associated with a FINRA member or its affiliates, and has the ability to control the allocation of the initial public offering of equity securities. */
  employeeWithIpoControl: boolean
  /** Member of FINRA or other broker-dealer. */
  member: boolean
}

/**
 * Provides details about the political affiliations of the client(s). The other fields may be provided (non-null) only if 'politicallyAffiliated' is true.
 */
export type PoliticalAffiliationsV1 = {
  /** Is the investor a government entity or subscribing on behalf of a government entity? */
  governmentEntity?: boolean | null
  /** If 'governmentEntity' is true, for which government? */
  governmentEntityOf?: string | null
  /** Is this account substantially owned by a government entity and the investment decisions of such account are made or directed by such government entity? */
  governmentOwned?: boolean | null
  /** If 'governmentOwned' is true, for which government? */
  governmentOwnedBy?: string | null
  /** Will any "pay to play" or other similar compliance obligations, other than the Pay to Play Rule, be imposed on the any Fund, a Master Fund, the Manager, the Master Fund Manager or their respective Affiliates in connection with the Investor's subscription? */
  payToPlay?: boolean | null
  /** Please list all other "pay to play" laws, rules or guidelines, or lobbyist disclosure laws or rules, the Fund, the Master Fund, the Manager, the Master Fund Manager or their respective Affiliates, employees or third-party placement agents would be subject to in connection with the Investor's subscription */
  payToPlayDetails?: string | null
  /** Is the investor, or if applicable, any underlying beneficial owner(s), a current or former senior foreign political figure, or is any immediate family member or close associate a senior foreign political figure? */
  politicalFigure?: boolean | null
  politicalFigureDetails?: PoliticalFigureDetailsV1
  /** Is the investor, or if applicable, any underlying beneficial owner(s) politically affiliated? */
  politicallyAffiliated?: boolean | null
} | null

/**
 * Provides details about fund-related affiliations of the client(s).
 */
export type FundAffiliationsV1 = {
  /** Is the investor an affiliate of any such person described in the previous 2 questions? */
  affiliateOfDiscretionaryAuthorityOrInvestmentAdvice?: boolean | null
  affiliateOfDiscretionaryAuthorityOrInvestmentAdviceFundIds?: string[] | null
  /** Does the investor have discretionary authority or control with respect to the assets of any fund listed on the CAIS platform? */
  discretionaryAuthority?: boolean | null
  discretionaryAuthorityFundIds?: string[] | null
  /** Does the investor provide investment advice for a fee (direct or indirect) with respect to the assets of any fund on the CAIS platform? */
  investmentAdvice?: boolean | null
  investmentAdviceFundIds?: string[] | null
  /** Do you expect the investor to be affiliated with any other person that is, or whom you expect will become, an investor in the same fund? */
  otherInvestorInSameFund?: boolean | null
  /** The name of the other person that is, or whom you expect will become, an investor in the same fund. */
  otherInvestorNameInSameFund?: string | null
} | null

/**
 * Provides details about the FINRA-related affiliations of the client(s).
 */
export type FinraAffiliationsV1 = {
  /** CFTC/NFA Registration number(s). This must be provided if `registeredWithCftcOrNfa` is `true`, otherwise it must be `null` */
  cftcOrNfaRegistrationNumbers?: string | null
  /** Is this an Institutional account as defined in FINRA Rule 4512(c)? */
  institutionalAccount?: boolean | null
  /** Is the investor registered with the CFTC or the National Futures Association (NFA)? */
  registeredWithCftcOrNfa?: boolean | null
} | null

/**
 * Possible values:
  * INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC - "Individual/sole proprietor or single-member LLC (for individuals and Disregarded Entities)"
  * C_CORPORATION - "C Corporation"
  * S_CORPORATION - "S Corporation"
  * PARTNERSHIP - "Partnership"
  * TRUST_ESTATE - "Trust/estate (for trusts which are not disregarded)"
  * LLC - "Limited Liability Company (for LLCs which are not disregarded)"
  * OTHER - "Other"

 */
export type TaxClassificationResponseV1 = string | null

/**
 * Possible values: 
  * A - "An organization exempt from tax under section 501(a) or any individual retirement plan as defined in section 7701(a)(37)"
  * B - "The United States or any of its agencies or instrumentalities C—A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities"
  * C - "A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities"
  * D - "A corporation the stock of which is regularly traded on one or more established securities markets, as described in Regulations section 1.1472-1(c)(1)(i)"
  * E - "A corporation that is a member of the same expanded affiliated group as a corporation described in Regulations section 1.1472-1(c)(1)(i)"
  * F - "A dealer in securities, commodities, or derivative financial instruments (including notional principal contracts, futures, forwards, and options) that is registered as such under the laws of the United States or any state"
  * G - "A real estate investment trust"
  * H - "A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment Company Act of 1940A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment Company Act of 1940"
  * I - "A common trust fund as defined in section 584(a)"
  * J - "A bank as defined in section 581"
  * K - "A broker"
  * L - "A trust exempt from tax under section 664 or described in section 4947(a)(1)"
  * M - "A tax exempt trust under a section 403(b) plan or section 457(g) plan"

 */
export type ExemptionFromFatcaCodeResponseV1 = string | null

/**
 * Possible values: 
  * 1 - "An organization exempt from tax under section 501(a), any IRA, or a custodial account under section 403(b)(7) if the account satisfies the requirements of section 401(f)(2)"
  * 2 - "The United States or any of its agencies or instrumentalities"
  * 3 - "A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities"
  * 4 - "A foreign government or any of its political subdivisions, agencies, or instrumentalities"
  * 5 - "A corporation"
  * 6 - "A dealer in securities or commodities required to register in the United States, the District of Columbia, or a U.S. commonwealth or possession"
  * 7 - "A futures commission merchant registered with the Commodity Futures Trading Commission"
  * 8 - "A real estate investment trust"
  * 9 - "An entity registered at all times during the tax year under the Investment Company Act of 1940"
  * 10 - "A common trust fund operated by a bank under section 584(a)"
  * 11 - "A financial institution"
  * 12 - "A middleman known in the investment community as a nominee or custodian"
  * 13 - "A trust exempt from tax under section 664 or described in section 4947"

 */
export type ExemptPayeeCodeResponseV1 = string | null

/**
 * Investment entity details.
 */
export type InvestmentEntityDetailsV1 = {
  /** EIN. */
  ein?: string | null
  /** Name of the investing entity. */
  investingEntityName: string
}

/**
 * Fund Administrator fund details.
 */
export type FundAdministratorFundDetailsV1 = {
  /** Fund account number. */
  fundAccountNumber: string
  /** Id of the fund. */
  fundId: string
}

/**
 * Summary of accounts to which a contact is a party.
 */
export type ContactAccountSummaryV1 = {
  /** Roles attached to the given contact. */
  contactRoles: AccountRoleResponseV1[]
  /** Number of accounts that the contact is party to. */
  numberOfAccounts: number
}

export type UUIDFilter = {
  eq?: string | null
  in?: string[] | null
  notIn?: string[] | null
} | null

export type StringFilter = {
  /** Determines whether the filter is case-sensitive or not. */
  caseSensitive?: boolean | null
  /** Filters for exact matches of the specified string. */
  eq?: string | null
  /** Filters for values that exist or don't exist. */
  exists?: boolean | null
  /** Filters for values that match any of the specified strings. */
  in?: string[] | null
  /** Filters for values that match a pattern. */
  like?: string | null
  /** Filters for values not equal to the specified string. */
  ne?: string | null
  /** Filters for values that do not match any of the specified strings. */
  notIn?: string[] | null
} | null

/**
 * category: 
  * AD - "Andorra"
  * AE - "United Arab Emirates"
  * AF - "Afghanistan"
  * AG - "Antigua and Barbuda"
  * AI - "Anguilla"
  * AL - "Albania"
  * AM - "Armenia"
  * AO - "Angola"
  * AR - "Argentina"
  * AS - "American Samoa"
  * AT - "Austria"
  * AU - "Australia"
  * AW - "Aruba"
  * AX - "Åland Islands"
  * AZ - "Azerbaijan"
  * BA - "Bosnia and Herzegovina"
  * BB - "Barbados"
  * BD - "Bangladesh"
  * BE - "Belgium"
  * BF - "Burkina Faso"
  * BG - "Bulgaria"
  * BH - "Bahrain"
  * BI - "Burundi"
  * BJ - "Benin"
  * BL - "Saint Barthélemy"
  * BM - "Bermuda"
  * BN - "Brunei"
  * BO - "Bolivia"
  * BQ - "Caribbean Netherlands"
  * BR - "Brazil"
  * BS - "Bahamas"
  * BT - "Bhutan"
  * BW - "Botswana"
  * BY - "Belarus"
  * BZ - "Belize"
  * CA - "Canada"
  * CC - "Cocos"
  * CD - "Congo"
  * CF - "Central African Republic"
  * CG - "Congo"
  * CH - "Switzerland"
  * CI - "Côte d’Ivoire"
  * CK - "Cook Islands"
  * CL - "Chile"
  * CM - "Cameroon"
  * CN - "China"
  * CO - "Colombia"
  * CR - "Costa Rica"
  * CU - "Cuba"
  * CV - "Cape Verde"
  * CW - "Curaçao"
  * CX - "Christmas Island"
  * CY - "Cyprus"
  * CZ - "Czech Republic"
  * DE - "Germany"
  * DJ - "Djibouti"
  * DK - "Denmark"
  * DM - "Dominica"
  * DO - "Dominican Republic"
  * DZ - "Algeria"
  * EC - "Ecuador"
  * EE - "Estonia"
  * EG - "Egypt"
  * EH - "Western Sahara"
  * ER - "Eritrea"
  * ES - "Spain"
  * ET - "Ethiopia"
  * FI - "Finland"
  * FJ - "Fiji"
  * FK - "Falkland Islands"
  * FM - "Micronesia"
  * FO - "Faroe Islands"
  * FR - "France"
  * GA - "Gabon"
  * GB - "United Kingdom"
  * GD - "Grenada"
  * GE - "Georgia"
  * GF - "French Guiana"
  * GG - "Guernsey"
  * GH - "Ghana"
  * GI - "Gibraltar"
  * GL - "Greenland"
  * GM - "Gambia"
  * GN - "Guinea"
  * GP - "Guadeloupe"
  * GQ - "Equatorial Guinea"
  * GR - "Greece"
  * GT - "Guatemala"
  * GU - "Guam"
  * GW - "Guinea-Bissau"
  * GY - "Guyana"
  * HK - "Hong Kong"
  * HN - "Honduras"
  * HR - "Croatia"
  * HT - "Haiti"
  * HU - "Hungary"
  * ID - "Indonesia"
  * IE - "Ireland"
  * IL - "Israel"
  * IM - "Isle of Man"
  * IN - "India"
  * IO - "British Indian Ocean Territory"
  * IQ - "Iraq"
  * IR - "Iran"
  * IS - "Iceland"
  * IT - "Italy"
  * JE - "Jersey"
  * JM - "Jamaica"
  * JO - "Jordan"
  * JP - "Japan"
  * KE - "Kenya"
  * KG - "Kyrgyzstan"
  * KH - "Cambodia"
  * KI - "Kiribati"
  * KM - "Comoros"
  * KN - "Saint Kitts and Nevis"
  * KP - "North Korea"
  * KR - "South Korea"
  * KW - "Kuwait"
  * KY - "Cayman Islands"
  * KZ - "Kazakhstan"
  * LA - "Laos"
  * LB - "Lebanon"
  * LC - "Saint Lucia"
  * LI - "Liechtenstein"
  * LK - "Sri Lanka"
  * LR - "Liberia"
  * LS - "Lesotho"
  * LT - "Lithuania"
  * LU - "Luxembourg"
  * LV - "Latvia"
  * LY - "Libya"
  * MA - "Morocco"
  * MC - "Monaco"
  * MD - "Moldova"
  * ME - "Montenegro"
  * MF - "Saint Martin"
  * MG - "Madagascar"
  * MH - "Marshall Islands"
  * MK - "North Macedonia"
  * ML - "Mali"
  * MM - "Myanmar"
  * MN - "Mongolia"
  * MO - "Macau"
  * MP - "Northern Mariana Islands"
  * MQ - "Martinique"
  * MR - "Mauritania"
  * MS - "Montserrat"
  * MT - "Malta"
  * MU - "Mauritius"
  * MV - "Maldives"
  * MW - "Malawi"
  * MX - "Mexico"
  * MY - "Malaysia"
  * MZ - "Mozambique"
  * NA - "Namibia"
  * NC - "New Caledonia"
  * NE - "Niger"
  * NF - "Norfolk Island"
  * NG - "Nigeria"
  * NI - "Nicaragua"
  * NL - "Netherlands"
  * NO - "Norway"
  * NP - "Nepal"
  * NR - "Nauru"
  * NU - "Niue"
  * NZ - "New Zealand"
  * OM - "Oman"
  * PA - "Panama"
  * PE - "Peru"
  * PF - "French Polynesia"
  * PG - "Papua New Guinea"
  * PH - "Philippines"
  * PK - "Pakistan"
  * PL - "Poland"
  * PM - "Saint Pierre and Miquelon"
  * PR - "Puerto Rico"
  * PS - "Palestine"
  * PT - "Portugal"
  * PW - "Palau"
  * PY - "Paraguay"
  * QA - "Qatar"
  * RE - "Réunion"
  * RO - "Romania"
  * RS - "Serbia"
  * RU - "Russia"
  * RW - "Rwanda"
  * SA - "Saudi Arabia"
  * SB - "Solomon Islands"
  * SC - "Seychelles"
  * SD - "Sudan"
  * SE - "Sweden"
  * SG - "Singapore"
  * SH - "Saint Helena"
  * SI - "Slovenia"
  * SJ - "Svalbard and Jan Mayen"
  * SK - "Slovakia"
  * SL - "Sierra Leone"
  * SM - "San Marino"
  * SN - "Senegal"
  * SO - "Somalia"
  * SR - "Suriname"
  * SS - "South Sudan"
  * ST - "São Tomé and Príncipe"
  * SV - "El Salvador"
  * SX - "Sint Maarten"
  * SY - "Syria"
  * SZ - "Eswatini"
  * TC - "Turks and Caicos Islands"
  * TD - "Chad"
  * TG - "Togo"
  * TH - "Thailand"
  * TJ - "Tajikistan"
  * TK - "Tokelau"
  * TL - "Timor-Leste"
  * TM - "Turkmenistan"
  * TN - "Tunisia"
  * TO - "Tonga"
  * TR - "Turkey"
  * TT - "Trinidad and Tobago"
  * TV - "Tuvalu"
  * TW - "Taiwan"
  * TZ - "Tanzania"
  * UA - "Ukraine"
  * UG - "Uganda"
  * US - "United States"
  * UY - "Uruguay"
  * UZ - "Uzbekistan"
  * VA - "Vatican City"
  * VC - "Saint Vincent and the Grenadines"
  * VE - "Venezuela"
  * VG - "British Virgin Islands"
  * VI - "U.S. Virgin Islands"
  * VN - "Vietnam"
  * VU - "Vanuatu"
  * WF - "Wallis and Futuna"
  * WS - "Samoa"
  * XK - "Kosovo"
  * YE - "Yemen"
  * YT - "Mayotte"
  * ZA - "South Africa"
  * ZM - "Zambia"
  * ZW - "Zimbabwe"

 */
export type CountriesV1 = (typeof CountriesV1)[keyof typeof CountriesV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CountriesV1 = {
  AD: 'AD',
  AE: 'AE',
  AF: 'AF',
  AG: 'AG',
  AI: 'AI',
  AL: 'AL',
  AM: 'AM',
  AO: 'AO',
  AR: 'AR',
  AS: 'AS',
  AT: 'AT',
  AU: 'AU',
  AW: 'AW',
  AX: 'AX',
  AZ: 'AZ',
  BA: 'BA',
  BB: 'BB',
  BD: 'BD',
  BE: 'BE',
  BF: 'BF',
  BG: 'BG',
  BH: 'BH',
  BI: 'BI',
  BJ: 'BJ',
  BL: 'BL',
  BM: 'BM',
  BN: 'BN',
  BO: 'BO',
  BQ: 'BQ',
  BR: 'BR',
  BS: 'BS',
  BT: 'BT',
  BW: 'BW',
  BY: 'BY',
  BZ: 'BZ',
  CA: 'CA',
  CC: 'CC',
  CD: 'CD',
  CF: 'CF',
  CG: 'CG',
  CH: 'CH',
  CI: 'CI',
  CK: 'CK',
  CL: 'CL',
  CM: 'CM',
  CN: 'CN',
  CO: 'CO',
  CR: 'CR',
  CU: 'CU',
  CV: 'CV',
  CW: 'CW',
  CX: 'CX',
  CY: 'CY',
  CZ: 'CZ',
  DE: 'DE',
  DJ: 'DJ',
  DK: 'DK',
  DM: 'DM',
  DO: 'DO',
  DZ: 'DZ',
  EC: 'EC',
  EE: 'EE',
  EG: 'EG',
  EH: 'EH',
  ER: 'ER',
  ES: 'ES',
  ET: 'ET',
  FI: 'FI',
  FJ: 'FJ',
  FK: 'FK',
  FM: 'FM',
  FO: 'FO',
  FR: 'FR',
  GA: 'GA',
  GB: 'GB',
  GD: 'GD',
  GE: 'GE',
  GF: 'GF',
  GG: 'GG',
  GH: 'GH',
  GI: 'GI',
  GL: 'GL',
  GM: 'GM',
  GN: 'GN',
  GP: 'GP',
  GQ: 'GQ',
  GR: 'GR',
  GT: 'GT',
  GU: 'GU',
  GW: 'GW',
  GY: 'GY',
  HK: 'HK',
  HN: 'HN',
  HR: 'HR',
  HT: 'HT',
  HU: 'HU',
  ID: 'ID',
  IE: 'IE',
  IL: 'IL',
  IM: 'IM',
  IN: 'IN',
  IO: 'IO',
  IQ: 'IQ',
  IR: 'IR',
  IS: 'IS',
  IT: 'IT',
  JE: 'JE',
  JM: 'JM',
  JO: 'JO',
  JP: 'JP',
  KE: 'KE',
  KG: 'KG',
  KH: 'KH',
  KI: 'KI',
  KM: 'KM',
  KN: 'KN',
  KP: 'KP',
  KR: 'KR',
  KW: 'KW',
  KY: 'KY',
  KZ: 'KZ',
  LA: 'LA',
  LB: 'LB',
  LC: 'LC',
  LI: 'LI',
  LK: 'LK',
  LR: 'LR',
  LS: 'LS',
  LT: 'LT',
  LU: 'LU',
  LV: 'LV',
  LY: 'LY',
  MA: 'MA',
  MC: 'MC',
  MD: 'MD',
  ME: 'ME',
  MF: 'MF',
  MG: 'MG',
  MH: 'MH',
  MK: 'MK',
  ML: 'ML',
  MM: 'MM',
  MN: 'MN',
  MO: 'MO',
  MP: 'MP',
  MQ: 'MQ',
  MR: 'MR',
  MS: 'MS',
  MT: 'MT',
  MU: 'MU',
  MV: 'MV',
  MW: 'MW',
  MX: 'MX',
  MY: 'MY',
  MZ: 'MZ',
  NA: 'NA',
  NC: 'NC',
  NE: 'NE',
  NF: 'NF',
  NG: 'NG',
  NI: 'NI',
  NL: 'NL',
  NO: 'NO',
  NP: 'NP',
  NR: 'NR',
  NU: 'NU',
  NZ: 'NZ',
  OM: 'OM',
  PA: 'PA',
  PE: 'PE',
  PF: 'PF',
  PG: 'PG',
  PH: 'PH',
  PK: 'PK',
  PL: 'PL',
  PM: 'PM',
  PR: 'PR',
  PS: 'PS',
  PT: 'PT',
  PW: 'PW',
  PY: 'PY',
  QA: 'QA',
  RE: 'RE',
  RO: 'RO',
  RS: 'RS',
  RU: 'RU',
  RW: 'RW',
  SA: 'SA',
  SB: 'SB',
  SC: 'SC',
  SD: 'SD',
  SE: 'SE',
  SG: 'SG',
  SH: 'SH',
  SI: 'SI',
  SJ: 'SJ',
  SK: 'SK',
  SL: 'SL',
  SM: 'SM',
  SN: 'SN',
  SO: 'SO',
  SR: 'SR',
  SS: 'SS',
  ST: 'ST',
  SV: 'SV',
  SX: 'SX',
  SY: 'SY',
  SZ: 'SZ',
  TC: 'TC',
  TD: 'TD',
  TG: 'TG',
  TH: 'TH',
  TJ: 'TJ',
  TK: 'TK',
  TL: 'TL',
  TM: 'TM',
  TN: 'TN',
  TO: 'TO',
  TR: 'TR',
  TT: 'TT',
  TV: 'TV',
  TW: 'TW',
  TZ: 'TZ',
  UA: 'UA',
  UG: 'UG',
  US: 'US',
  UY: 'UY',
  UZ: 'UZ',
  VA: 'VA',
  VC: 'VC',
  VE: 'VE',
  VG: 'VG',
  VI: 'VI',
  VN: 'VN',
  VU: 'VU',
  WF: 'WF',
  WS: 'WS',
  XK: 'XK',
  YE: 'YE',
  YT: 'YT',
  ZA: 'ZA',
  ZM: 'ZM',
  ZW: 'ZW',
} as const

export type CreateAddressRequestV1 = {
  addressLine1: string
  addressLine2?: string | null
  city: string
  country: CountriesV1
  state: string
  zipCode: string
}

export type MaritalStatusV1 =
  (typeof MaritalStatusV1)[keyof typeof MaritalStatusV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MaritalStatusV1 = {
  SINGLE: 'SINGLE',
  MARRIED: 'MARRIED',
  DIVORCED: 'DIVORCED',
  SEPARATED: 'SEPARATED',
  WIDOWED: 'WIDOWED',
} as const

/**
 * The operation to be performed
 */
export type PatchDocumentOp =
  (typeof PatchDocumentOp)[keyof typeof PatchDocumentOp]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchDocumentOp = {
  add: 'add',
  remove: 'remove',
  replace: 'replace',
} as const

/**
 * A JSONPatch document as defined by RFC 6902 https://jsonpatch.com/
 */
export type PatchDocument = {
  /** Previous value for replace operation */
  fromValue?: string
  /** The operation to be performed */
  op: PatchDocumentOp
  /** A JSON-Pointer */
  path: string
  /** The value to be used within the operations. */
  value?: unknown
}

/**
 * Payload for a KYC doc event.
 */
export type KycDocumentEventPayloadV1 = {
  /** Diff is only provided for an update event */
  diff?: PatchDocument[]
  document: UploadDocumentResponseV1
}

/**
 * Payload for an account event.
 */
export type AccountEventPayloadV1 = {
  account: AccountV1
  /** Diff is only provided for an update event */
  diff?: PatchDocument[]
}

/**
 * Payload for a household event.
 */
export type HouseholdEventPayloadV1 = {
  diff?: PatchDocument[]
  household: HouseholdResponseV1
}

/**
 * Payload for a contact event.
 */
export type ContactEventPayloadV1 = {
  contact: ContactResponseV1
  diff?: PatchDocument[]
}

/**
 * The type of action which the event occurred (create|update|delete|read)
 */
export type EnvelopeTypeActionType =
  (typeof EnvelopeTypeActionType)[keyof typeof EnvelopeTypeActionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnvelopeTypeActionType = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  read: 'read',
} as const

/**
 * Object to specify the type of the event enclosed within the envelope.
 */
export type EnvelopeTypeAction = {
  /** The name of action which the event occurred */
  name: string
  /** The type of action which the event occurred (create|update|delete|read) */
  type: EnvelopeTypeActionType
}

/**
 * Object to specify the type of the event enclosed within the envelope.
 */
export type EnvelopeType = {
  action: EnvelopeTypeAction
  /** The list of actions which describe the changes in the event occurred */
  actions?: EnvelopeTypeAction[]
  /** The domain of the event */
  domain: string
  /** The type of resource upon which the event occurred */
  resource: string
  /** The schema major version of the event type */
  version: number
}

export type EnvelopeRelatedIdsItem = {
  /** The domain of the related resource ID */
  domain: string
  /** Unique ID of the related resource ID */
  id: string
  /** The resource type of the related ID */
  resource: string
  /** The schema major version of the resource of the related ID */
  version?: number
}

/**
 * Object to specify the actor of the event enclosed within the envelope. This can include both the real and switch user.
 */
export type EnvelopeActor = {
  /** The ID of the real user. */
  actorId: string
  /** The ID of the effective user. Only required where the real user is different from the effective user. */
  switchActorId?: string | null
}

/**
 * CAIS Event envelope model
 */
export type Envelope = {
  /** Object to specify the actor of the event enclosed within the envelope. This can include both the real and switch user. */
  actor?: EnvelopeActor
  /**
   * Deprecated. Use actor instead.
   * @deprecated
   */
  actorId?: string
  /** Optional correlation identifier which can be used to logically group or associate events */
  correlationId?: string
  /** Human readable description of the event */
  description: string
  /** The list of differences represented as JSONPatch PatchDocument objects */
  diff?: PatchDocument[]
  /** The ID of the firm that owns the resource. May not be present if the event does not relate to a specific resource. */
  firmId?: string
  /** Unique identifier for an event */
  id: string
  /** An optional list of related IDs. As an example an Order event may include a related product's ID */
  relatedIds?: EnvelopeRelatedIdsItem[]
  /** The ID of the resource affected by the event. May not be present if the event does not relate to a specific resource. */
  resourceId?: string
  /** ISO Datetime when the event occurred. */
  timestamp: string
  /** Object to specify the type of the event enclosed within the envelope. */
  type: EnvelopeType
}

export type DocumentAwareResourceV1 =
  (typeof DocumentAwareResourceV1)[keyof typeof DocumentAwareResourceV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentAwareResourceV1 = {
  CONTACT: 'CONTACT',
  ACCOUNT: 'ACCOUNT',
} as const

export type FileTypeV1 = (typeof FileTypeV1)[keyof typeof FileTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileTypeV1 = {
  GOVERNMENT_ISSUED_ID: 'GOVERNMENT_ISSUED_ID',
  PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
  US_TAX: 'US_TAX',
  NON_US_TAX: 'NON_US_TAX',
  PLAN_DOCUMENTS: 'PLAN_DOCUMENTS',
  LLCAGREEMENT_CERTIFICATE_OF_CORPORATION:
    'LLCAGREEMENT_CERTIFICATE_OF_CORPORATION',
  CERTIFICATE_OF_INCUMBENCY: 'CERTIFICATE_OF_INCUMBENCY',
  LIMITED_PARTNERSHIP_AGREEMENT: 'LIMITED_PARTNERSHIP_AGREEMENT',
  ENTITY_FORMATION_DOCUMENTS: 'ENTITY_FORMATION_DOCUMENTS',
  GOVERNING_DOCUMENTS_OF_THE_FOUNDATION:
    'GOVERNING_DOCUMENTS_OF_THE_FOUNDATION',
  ENTITY_SELF_CERTIFICATION_FORM: 'ENTITY_SELF_CERTIFICATION_FORM',
  IRS_DETERMINATION_LETTER: 'IRS_DETERMINATION_LETTER',
  TRUST_DEED: 'TRUST_DEED',
  CERTIFICATE_OF_GOOD_STANDING: 'CERTIFICATE_OF_GOOD_STANDING',
  OTHER: 'OTHER',
} as const

/**
 * Request to upload a file.
 */
export type FileUploadRequestV1 = {
  /** The mime type of the file. Used as the content-type of the response when the file is downloaded. */
  contentType: string
  /** Date that the document expires. */
  expiryDate?: string | null
  /** Should be populated when updating an existing document, and null otherwise */
  fileId?: string | null
  /** Name of the file. */
  fileName: string
  fileType: FileTypeV1
  /** Indicates if the uploaded documents are for the joint entity of the account. Note - Only relevant when resourceType is 'ACCOUNT'. */
  isForJointEntity?: boolean | null
  /** Date that the document was issued. */
  issueDate?: string | null
  /** Unique identifier for the file. */
  resourceId: string
  resourceType: DocumentAwareResourceV1
}

export type KycMultiDocumentRequirementWrapperV1AllOf = {
  documents: KycDocumentDetailsV1[]
}

export type KycDocumentRequirementWrapperV1 = {
  optional: boolean
}

export type KycMultiDocumentRequirementWrapperV1 =
  KycDocumentRequirementWrapperV1 & KycMultiDocumentRequirementWrapperV1AllOf

export type KycSingleDocumentRequirementWrapperV1AllOf = {
  document?: KycDocumentDetailsV1
}

export type KycSingleDocumentRequirementWrapperV1 =
  KycDocumentRequirementWrapperV1 & KycSingleDocumentRequirementWrapperV1AllOf

/**
 * Possible values are: * CONTACT * ACCOUNT

 */
export type DocumentAwareResourceResponseV1 = DocumentAwareResourceV1

/**
 * Possible values are: * GOVERNMENT_ISSUED_ID * PROOF_OF_ADDRESS * US_TAX * NON_US_TAX * PLAN_DOCUMENTS * LLCAGREEMENT_CERTIFICATE_OF_CORPORATION * CERTIFICATE_OF_INCUMBENCY * LIMITED_PARTNERSHIP_AGREEMENT * ENTITY_FORMATION_DOCUMENTS * GOVERNING_DOCUMENTS_OF_THE_FOUNDATION * ENTITY_SELF_CERTIFICATION_FORM * IRS_DETERMINATION_LETTER * TRUST_DEED * CERTIFICATE_OF_GOOD_STANDING * OTHER

 */
export type FileTypeResponseV1 = FileTypeV1

export type KycDocumentFileTypeDetailsV1 = {
  account?: KycMultiDocumentRequirementWrapperV1
  additionalSignatories?: KycMultiDocumentRequirementWrapperV1
  entity?: KycSingleDocumentRequirementWrapperV1
  fileType: FileTypeResponseV1
  jointClient?: KycSingleDocumentRequirementWrapperV1
  jointEntity?: KycSingleDocumentRequirementWrapperV1
  jointSigner?: KycSingleDocumentRequirementWrapperV1
  primaryClient?: KycSingleDocumentRequirementWrapperV1
  primarySigner?: KycSingleDocumentRequirementWrapperV1
}

/**
 * Request to create beneficiary entity object.
 */
export type CreateBeneficiaryEntityRequestV1 = {
  address: CreateAddressRequestV1
  /** Beneficiary order of precedence. */
  beneficiaryOrderOfPrecedence: number
  /** Beneficiary Entity email address */
  email: string
  /** Indicates if email notifications are switched on. */
  emailNotifications: boolean
  /** Entity Name. */
  entityName: string
  /** Percentage of ownership. */
  percentageOfOwnership: number
  principlePlaceOfBusiness: PrinciplePlaceOfBusinessDetailsV1
}

/**
 * Response containing Beneficiary Entity.
 */
export type BeneficiaryEntityResponseV1 = {
  address: AddressResponseV1
  /** Beneficiary order of precedence. */
  beneficiaryOrderOfPrecedence: number
  /** may be null in old Kafka events; otherwise, should never be null */
  collectionId?: string | null
  /** Beneficiary email address. */
  email: string
  /** Indicates if email notifications are switched on. */
  emailNotifications: boolean
  /** Entity Name. */
  entityName: string
  /** Percentage of ownership. */
  percentageOfOwnership: number
  principlePlaceOfBusiness: PrinciplePlaceOfBusinessDetailsResponseV1
}

/**
 * category: 
  * JOINT_CLIENT - "Joint Client"
  * PRIMARY_CLIENT - "Primary Client"
  * PRIMARY_SIGNER -  "Primary Signer"
  * JOINT_TRUST - "Joint Trust" 
  * JOINT_PRIMARY_SIGNER - "Joint Primary Signer" 
  * SIGNATORY - "Signatory"
  * TRUSTEE - "Trustee"
  * CONTROLLING_PERSON - "Controlling Person"
  * BENEFICIARY_OWNER - "Beneficiary Owner"
  * INTERESTED_PARTY - "Interested Party"

 */
export type AccountRoleResponseV1 = string

/**
 * The account role and the time it was assigned to that contact.
 */
export type TimedAccountRoleResponseV1 = {
  /** ISO Datetime when the event occurred. */
  assignedAt: string
  /** category:
   * JOINT_CLIENT - "Joint Client"
   * PRIMARY_CLIENT - "Primary Client"
   * PRIMARY_SIGNER -  "Primary Signer"
   * JOINT_TRUST - "Joint Trust"
   * JOINT_PRIMARY_SIGNER - "Joint Primary Signer"
   * SIGNATORY - "Signatory"
   * TRUSTEE - "Trustee"
   * CONTROLLING_PERSON - "Controlling Person"
   * BENEFICIARY_OWNER - "Beneficiary Owner"
   * INTERESTED_PARTY - "Interested Party"
   */
  role: string
}

/**
 * Account Reference
 */
export type AccountReferenceV1 = {
  /** Account id. */
  id: string
  /** Account Version. */
  version: number
}

/**
 * Collects the data for an account, along with its parent household and firm id.
 */
export type AccountListItemV1 = {
  account: AccountV1
  household: HouseholdResponseV1
  primaryClientOrSigner: ContactResponseV1
}

/**
 * Defines the possible filters for use in the Account search. The `accountNumber` filter will match on custodian or brokerage account number - whichever is relevant to the given account.
 */
export type AccountFilterV1 = {
  accountIds?: UUIDFilter
  accountName?: StringFilter
  accountNumber?: StringFilter
  firmIds?: UUIDFilter
  householdId?: UUIDFilter
  teamIds?: UUIDFilter
} | null

/**
 * Provides wiring instructions for the associated account, if the funding account is different from the custodian account. If `useCustodianAccount` is `true`, the rest of this object be empty - use the information provided elsewhere.
 */
export type WireInstructionsV1 = {
  accountInformation?: FundingAccountV1
  bankInformation?: FundingBankV1
  collectionId?: string | null
  /** Set to `true` if there is a custodian, and the custodian account is the same as the funding account. Otherwise, set to `false`. */
  useCustodianAccount: boolean
}

export type UniformIsNullRuleV1Operation =
  (typeof UniformIsNullRuleV1Operation)[keyof typeof UniformIsNullRuleV1Operation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UniformIsNullRuleV1Operation = {
  _is_null: '_is_null',
} as const

export type UniformIsNullRuleV1AllOf = {
  fieldId?: string
}

export type UniformIsNullRuleV1 = UniformRuleV1 &
  UniformIsNullRuleV1AllOf & {
    operation: UniformIsNullRuleV1Operation
  }

export type UniformOrRuleV1Operation =
  (typeof UniformOrRuleV1Operation)[keyof typeof UniformOrRuleV1Operation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UniformOrRuleV1Operation = {
  _or: '_or',
} as const

export type UniformOrRuleV1 = UniformRuleV1 &
  UniformOrRuleV1AllOf & {
    operation: UniformOrRuleV1Operation
  }

export type UniformContainsRuleV1Operation =
  (typeof UniformContainsRuleV1Operation)[keyof typeof UniformContainsRuleV1Operation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UniformContainsRuleV1Operation = {
  _contains: '_contains',
} as const

export type UniformContainsRuleV1AllOf = {
  fieldId?: string
  /** value can be any of number, string, boolean */
  value?: unknown
}

export type UniformContainsRuleV1 = UniformRuleV1 &
  UniformContainsRuleV1AllOf & {
    operation: UniformContainsRuleV1Operation
  }

export type UniformInRuleV1Operation =
  (typeof UniformInRuleV1Operation)[keyof typeof UniformInRuleV1Operation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UniformInRuleV1Operation = {
  _in: '_in',
} as const

export type UniformInRuleV1AllOf = {
  fieldId?: string
  value?: unknown[]
}

export type UniformInRuleV1 = UniformRuleV1 &
  UniformInRuleV1AllOf & {
    operation: UniformInRuleV1Operation
  }

export type UniformEqualRuleV1Operation =
  (typeof UniformEqualRuleV1Operation)[keyof typeof UniformEqualRuleV1Operation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UniformEqualRuleV1Operation = {
  _eq: '_eq',
} as const

export type UniformEqualRuleV1AllOf = {
  fieldId?: string
  /** value can be any of number, string, boolean */
  value?: unknown
}

export type UniformRuleV1 = {
  /** Possible values are: * _eq * _in * _or
   */
  operation: string
}

export type UniformOrRuleV1AllOf = {
  conditions?: UniformRuleV1[]
}

export type UniformEqualRuleV1 = UniformRuleV1 &
  UniformEqualRuleV1AllOf & {
    operation: UniformEqualRuleV1Operation
  }

export type SkippedFieldReferenceV1 = {
  collectionId?: string | null
  fieldId: string
}

export type UpdateBeneficiaryEntityRequestV1 = {
  address: CreateAddressRequestV1
  beneficiaryOrderOfPrecedence: number
  collectionId: string
  email: string
  emailNotifications: boolean
  entityName: string
  percentageOfOwnership: number
  principlePlaceOfBusiness: PrinciplePlaceOfBusinessDetailsV1
}

/**
 * category:
  * SIGNATORY - "Signatory"
  * TRUSTEE - "Trustee"
  * CONTROLLING_PERSON - "Controlling Person"
  * BENEFICIARY_OWNER - "Beneficiary Owner"
  * INTERESTED_PARTY - "Interested Party"

 */
export type AccountRoleRequestV1 =
  (typeof AccountRoleRequestV1)[keyof typeof AccountRoleRequestV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountRoleRequestV1 = {
  SIGNATORY: 'SIGNATORY',
  TRUSTEE: 'TRUSTEE',
  CONTROLLING_PERSON: 'CONTROLLING_PERSON',
  BENEFICIARY_OWNER: 'BENEFICIARY_OWNER',
  INTERESTED_PARTY: 'INTERESTED_PARTY',
} as const

/**
 * Provides details about the investor regarding the Employee Retirement Income Security Act of 1974 ("ERISA").
 */
export type ErisaV1 = {
  erisaDetails?: ErisaDetailsV1
  insuranceCoDetails?: ErisaInsuranceCoDetailsV1
  /** Is the investor an insurance company whose general account assets (or the assets of a separate account or bank common or collective trust that has investors who) are subject to the provisions of ERISA (or are a plan under Section 4975 of the IRSA code of 1986, including a Keogh Plan or an IRA)? */
  insuranceCoSubjectToErisa: boolean
  /** Is the investor subject to the provisions of the Employee Retirement Income Security Act of 1974 ("ERISA")? */
  subjectToErisa: boolean
  /** Indicates whether or not the Investor is subject to any other federal, state, local, non-U.S. or other laws or regulations that could cause the underlying assets of the Fund to be treated as assets of the Plan Investor by virtue of its investment in the Fund and thereby subject the Fund and the General Partner (or other persons responsible for the investment and operation of the Fund's assets) to laws or regulations that are similar to the fiduciary responsibility or prohibited transaction provisions contained in Title I of ERISA or Section 4975 of the Code. */
  subjectToErisaOtherReason: boolean
} | null

/**
 * Provides details of the benefit plan. If `benefitPlanInvestor` is `false`, all other fields must be `null`.
 */
export type BenefitPlanV1 = {
  /** Set to `true` if the investing entity is a benefit plan investor, `false` if not. */
  benefitPlanInvestor: boolean
  /** Percent of the value of any class of equity interests in the Investor that is held by Benefit Plan Investors. */
  equityInterestPercent?: number | null
  /** Set to `true` if 25% or more of the value of any class of equity interests in the Investor is held by Benefit Plan Investors, or `false` if less than 25%. */
  equityInterestPercentAtLeast25?: boolean | null
  /** Set to `true` if the investing entity is a pooled investment fund, `false` if not. */
  pooledInvestmentFund?: boolean | null
} | null

/**
 * Provides details about the affiliations of client(s) which are entities (rather than individuals).
 */
export type ClientEntityAffiliationsV1 = {
  /** Are you a disregarded entity for US tax purposes? */
  disregardedEntity?: boolean | null
  /** Is the investing entity a private investment company or a non-US investment company that (i) is not required to register as an "investment company" under the Investment Company Act in reliance, in whole or in part, on Section 3(c)(1) or 3(c)(7) under the Investment Company Act and (ii) had one or more investors on or before April 30, 1996? */
  investmentCompanyExempt?: boolean | null
  /** Is this account registered with a regulatory government agency? */
  registeredWithRegulatoryAgency?: boolean | null
  /** SEC registration number. Required if `investmentCompanyExempt` is `true`; otherwise must be `null`. */
  secRegistrationNumber?: string | null
} | null

/**
 * Provides details about the account-related eligibility of the client(s). Only applicable to non-individual and tax-exempt clients.
 */
export type AccountEligibilityV1 = {
  /** A corporation, partnership, trust or other entity in which (i) the beneficial interests of restricted persons do not exceed in the aggregate 10% of such entity (an investor who limits the participation by restricted or covered persons in new issues to no more than 10% may check this item) and (ii) the beneficial interests of covered persons of any one particular company do not exceed in the aggregate 25% of such entity (an investor who limits the participation by covered persons of any particular company in new issues to no more than 25% may check this item.) */
  beneficialInterestsBelowThreshold: boolean
  /** A tax exempt charitable organization under Section 501(c) of the code. */
  charitableOrganization: boolean
  /** A church plan under Section 414(e) of the code. */
  churchPlan: boolean
  /** A common trust with investments from 1,000 or more accounts and such common trust fund is not limited principally to accounts of persons who are otherwise restricted or covered persons. */
  commonTrustAtLeastOneThousandAccounts: boolean
  /** A publicly traded entity (other than broker-dealers or affiliates thereof, if such broker-dealer is authorized to engage in the public offering of "new issues" either as a selling group member or underwriter) that (i) is listed on a national securities exchange, or (ii) is a foreign issuer whose securities meet the quantitative designation criteria for listing on a national securities exchange. */
  entityOnNationalSecuritiesExchange: boolean
  /** A fund organized under the laws of a foreign jurisdiction whose securities are listed on a foreign exchange for sale to the public or are authorized for sale to the public by a foreign regulatory authority, and no person owning more than 5% of the shares of such a fund is a restricted or covered person. */
  fundUnderForeignJurisdiction: boolean
  /** An insurance company general, separate or investment account with, or funded by premiums from 1,000 or more policyholders and such account is not limited principally to accounts of persons who are otherwise restricted or covered persons. */
  insuranceCoAccountAtLeastOneThousandPolicyholders: boolean
  /** A benefit plan established under ERISA that is qualified under Section 401(a) of the code, provided that such a plan is not sponsored by a broker-dealer. */
  qualifiedErisaBenefitPlan: boolean
  /** An investment company registered under the Investment Company Act. */
  registeredUnderInvestmentCompanyAct: boolean
} | null

/**
 * Provides details about the Australia qualification. Can be set only to individual account where the citizenship of the primary client is Australia.
 */
export type AustraliaQualificationV1 = {
  /** Does the client earn a gross annual income of at least $250,000 (AUD) over the last two financial years? */
  grossIncomeAtLeast250K: boolean
  /** Does the client have a net assets exceeding $2,500,000 (AUD)? */
  netAssetsOverTwoAndHalfMillion: boolean
  /** Is the subscription investment amount greater than $500,000 (AUD)? */
  subscriptionAmountOver500K: boolean
  /** Is the client a wholesale client? */
  wholesaleClient: boolean
} | null

/**
 * Provides details about the eligibility of the client(s). A Fund may invest in a "new issue" from time to time, as defined in Rules 5130 and 5131 of FINRA.
 */
export type NewIssuesEligibilityV1 = {
  brokerDealer: BrokerDealerEligibilityV1
  brokerDealerOwner: BrokerDealerOwnerEligibilityV1
  finderOrFiduciary: FinderFiduciaryEligibilityV1
  officersAndDirectors: OfficersAndDirectorsEligibilityV1
  portfolioManager: PortfolioManagerEligibilityV1
  restricted: RestrictedEligibilityV1
} | null

/**
 * Provides details about the affiliations of the client(s).
 */
export type AffiliationsV1 = {
  finra?: FinraAffiliationsV1
  fund?: FundAffiliationsV1
  political?: PoliticalAffiliationsV1
} | null

export type ContactReferenceV1 = {
  contactDetails?: ContactResponseV1
  id: string
  version: number
}

/**
 * Possible values are:
 * PRIVATE_FOUNDATION - "Private Foundation"
 * ENDOWMENT - "Endowment"
 * BENEFIT_PLAN - "Benefit Plan"
 * OTHER_TAX_EXEMPT_ORGANIZATION - "Other Tax-exempt organization"

 */
export type TaxExemptOrganizationSubTypeResponseV1 = string

/**
 * Place of Business.
 */
export type PlaceOfBusiness = {
  /** City. */
  city?: string | null
  country?: CountriesResponseV1
  /** State. */
  state?: string | null
} | null

/**
 * Account details for client entities.
 */
export type EntityClientAccountDetailsV1 = {
  exemptFromFatcaCode?: ExemptionFromFatcaCodeResponseV1
  exemptPayeeCode?: ExemptPayeeCodeResponseV1
  /** Formation date. */
  formationDate?: string | null
  placeOfBusiness?: PlaceOfBusiness
  taxClassification?: TaxClassification
} | null

/**
 * Account details relevant for tax exempt organization account type
 */
export type TaxExemptOrganizationAccountDetailsV1 = {
  accountEligibility?: AccountEligibilityV1
  clientEntityAffiliations?: ClientEntityAffiliationsV1
  primaryClient?: EntityClientAccountDetailsV1
} | null

/**
 * Account details relevant for non-individual account type
 */
export type NonIndividualAccountDetailsV1 = {
  accountEligibility?: AccountEligibilityV1
  clientEntityAffiliations?: ClientEntityAffiliationsV1
  jointClient?: EntityClientAccountDetailsV1
  primaryClient?: EntityClientAccountDetailsV1
} | null

export type NonIndividualQualifiedEligiblePersonStatusesResponseV1Item = {
  /** Possible values are:
   * NIQEP01 - Not a Qualified Eligible Person.
   * NIQEP02 - The Investor owns securities (including participations in the Fund and other pools) of issuers not affiliated with the Investor and other investments (e.g. real estate held for the investment purpose) with an aggregate market value of at least $2,000,000.
   * NIQEP03 - The Investor has on deposit for its own account with a futures commission merchant, at any time during the preceding six (6) months, at least $2,000,000 in exchange-specified initial margin and option premium for futures and other commodity interest positions.
   * NIQEP04 - The Investor owns a portfolio comprised of a proportionate combination of 1 and 2 (e.g., $1,000,000 in securities and $100,000 in margin and premium).
   */
  code: string
}

export type NonIndividualQualifiedEligiblePersonStatusesResponseV1 =
  | NonIndividualQualifiedEligiblePersonStatusesResponseV1Item[]
  | null

export type NonIndividualQualifiedPurchaserStatusesResponseV1Item = {
  /** description:
   * NIQP01 - Not a Qualified Purchaser.
   * NIQP02 - A company, partnership, trust or other entity that (i) is owned directly or indirectly by, or for, two or more natural persons who are related as siblings, spouses (including former spouses), direct lineal descendants by birth or adoption, spouses of such persons, the estates of such persons or foundations, charitable organizations or trusts established by, or for the benefit of, such persons, (ii) owns not less than $5,000,000 in Net Investments (each such entity satisfying (i) and (ii) constituting a "Family Company"), and (iii) not formed for the purpose of investing in any particular product offered on the CAIS Platform.
   * NIQP03 - A trust (other than a Family Company) (i) that was not formed for the purpose of investing in any particular product offered on the CAIS Platform and (ii) of which the trustee (or other person authorized to make decisions with respect to the trust) and each settlor or other person who has contributed assets to the trust is a "qualified purchaser" (a "Contributing Trustee QP").
   * NIQP04 - A company, partnership, trust, or other entity that (i) was not formed for the purpose of investing in any particular product offered on the CAIS Platform, (ii) acts for its own account or the accounts of other "qualified purchasers," and (iii) in the aggregate, owns and invests on a discretionary basis not less than $25,000,000 in "Net Investments."
   * NIQP05 - Certain "qualified institutional buyers" as defined in Rule 144A under the Securities Act (primarily institutions that manage at least $100 million in securities including banks, savings and loans, insurance companies, investment companies, employee benefit plans or an entity owned entirely by qualified investors. Also included are registered broker-dealers owning and investing, on a discretionary basis, $10,000,000 in securities of non-affiliates).
   * NIQP06 - A "Charitable Corporation" (i) of which all the persons who have contributed assets are related in one or more of the ways enumerated above in respect of a Family Company, (ii) that owns not less than $5,000,000 in Net Investments, and (iii) that was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
   * NIQP07 - A "Charitable Corporation" (i) of which each person authorized to make investment decisions, and each person who has contributed assets, is a "qualified purchaser" (other than a Contributing Trustee QP) and (ii) was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
   * NIQP08 - A company, partnership or other entity whose securities are beneficially owned exclusively by "qualified purchasers".
   * NIQP09 - Certain “qualified institutional buyers” as defined in Rule 144A under the Securities Act (primarily institutions that manage at least $100 million in securities including banks, savings and loans, insurance companies, investment companies, employee benefit plans or an entity owned entirely by qualified investors.  Also included are registered broker-dealers owning and investing, on a discretionary basis, $10,000,000 in securities of non-affiliates).
   * NIQP10 - A corporation or partnership (whether or not formed for the specific purpose of buying an Interest) all of whose securities and other ownership interests are beneficially owned by Knowledgeable Employees (Note:  no ownership interests may be held by or for the benefit of any person other than a Knowledgeable Employee or his or her estate).
   */
  code: string
}

export type NonIndividualQualifiedPurchaserStatusesResponseV1 =
  | NonIndividualQualifiedPurchaserStatusesResponseV1Item[]
  | null

export type NonIndividualQualifiedClientStatusesResponseV1Item = {
  /** description:
   * NIQC01 - Not an Qualified Client.
   * NIQC02 - The Investor is a corporation, partnership, association, joint stock company, trust or other organized group of persons with a net worth (i.e., total assets minus total liabilities) of at least $2,100,000 as of the date hereof, or it will invest at least $1,000,000 in a single investment and declares, represents and warrants that if it is (x) an entity that would be a registered investment company but for the exception provided from that definition in Section 3(c)(1) of the Investment Company Act, (y) a registered investment company under the Investment Company Act or (z) a business development company within the meaning of Section 202(a)(22) of the Advisors Act, each of its equity owners independently meet the requirements of category (i) or (ii) of this Qualified Client Section.
   */
  code: string
}

export type NonIndividualQualifiedClientStatusesResponseV1 =
  | NonIndividualQualifiedClientStatusesResponseV1Item[]
  | null

export type NonIndividualAccreditedInvestorStatusesResponseV1Item = {
  /** Possible values are:
   * NIAI01 - Any trust that is an accredited investor because it is a revocable trust which may be amended or revoked at any time by the grantors thereof and all of the grantors are accredited investors.
   * NIAI02 - Any trust, with total assets in excess of $5,000,000, not formed for the purpose of investing in any particular product offered on the CAIS Platform whose purchase is directed by a sophisticated person as described in Rule 506(b)(2)(ii) under Regulation D, promulgated under the Securities Act.
   * NIAI03 - The investor is a corporation, a partnership, a limited liability company, or a business trust that has total assets in excess of $5,000,000 and was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
   * NIAI04 - Any employee benefit plan within the meaning of ERISA and has total assets in excess of US$5,000,000 and the decision to invest in the Fund was made solely by persons that are accredited investors.
   * NIAI05 - Any employee benefit plan within the meaning of ERISA, and the decision to invest in the fund was made by a plan fiduciary (as defined in Section 3(21) of ERISA), which is either a bank, savings and loan association, insurance company or registered investment advisor.
   * NIAI06 - Any plan established and maintained by a state, its political subdivisions or any agency or instrumentality of a state or its political subdivisions for the benefit of its employees, if such a plan has total assets in excess of $5,000,000.
   * NIAI07 - Any organization described in Section 501(c)(3) of the Code, corporation, Massachusetts or similar business trust or partnership, not formed for the purpose of investing in any particular product offered on the CAIS Platform, with total assets in excess of $5,000,000.
   * NIAI08 - Any private business development company as defined in Section 202(a)(22) of the Investment Advisors Act of 1940, as amended.
   * NIAI09 - Any investment company registered under the Company Act or a business development company as defined in Section 2(a)(48) of the Company Act.
   * NIAI10 - Any Small Business Investment Company licensed by the U.S. Small Business Administration under Section 301(c) or (d) of the Small Business Investment Act of 1958.
   * NIAI11 - Any broker or dealer registered pursuant to Section 15 of the Securities Exchange Act of 1934, as amended (The "Exchange Act").
   * NIAI12 - Any bank as defined in Section 3(a)(2) of the Securities Act, or any savings and loan association or other institution as defined in Section 3(a)(5)(A) of the Securities Act whether acting in its individual or fiduciary capacity.
   * NIAI13 - Any insurance company as defined in Section 2(13) of the Securities Act.
   * NIAI14 - Any entity (other than a trust) in which all of the equity owners are accredited investors.
   * NIAI15 - Not an Accredited Investor.
   * NIAI16 - Any family office that has total assets in excess of $5,000,000, that not formed for the purpose of investing in any particular product offered on the CAIS Platform and whose investment in the Interests is directed by a sophisticated person.
   * NIAI17 - A family client (as defined in Rule 202(a)(11)(G)-1 of the Advisers Act) of a family office meeting the requirements set forth directly above, and the Investor’s investment in the Interests is directed by such family office.
   * NIAI18 - Any investment adviser registered under the Advisers Act.
   * NIAI19 - Any investment adviser registered pursuant to the laws of a U.S. state.
   * NIAI20 - Any exempt reporting adviser under Section 203(I) (i.e., a venture capital fund adviser) or Section 203(m) (i.e., a private fund adviser) of the Advisers Act.
   * NIAI21 - Any entities that own not less than $5,000,000 in investments (as defined in Rule 2a41-(b) of the Investment Company Act) and was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
   * NIAI22 - A corporation or partnership (whether or not formed for the specific purpose of buying an Interest) of whose securities and other ownership interests are beneficially owned by Knowledgeable Employees (Note: no ownership interests may be held by or for the benefit of any person other than a Knowledgeable Employee or his or her estate).
   * NIAI23 - A self-directed plan and the decision to invest is made by those participants investing, and each such participant qualifies as "accredited investor."
   * NIAI24 - The Investor is a self-directed plan and the decision to invest is made by those participants investing, and each such participant qualifies as an "accredited investor".
   */
  code: string
  /** If NIAI05 is selected, a description will be present */
  description?: string | null
}

export type NonIndividualAccreditedInvestorStatusesResponseV1 =
  | NonIndividualAccreditedInvestorStatusesResponseV1Item[]
  | null

export type PrimarySignersResponseV1 = {
  jointTenantPrimarySigner?: ContactReferenceV1
  jointTrustSigner?: ContactReferenceV1
  primarySigner: ContactReferenceV1
}

/**
 * Possible values are: * GRANTOR_TRUST - "Grantor Trust" * REVOCABLE_TRUST - "Revocable Trust" * IRREVOCABLE_TRUST - "Irrevocable Trust" * SIMPLE_TRUST - "Simple Trust" * COMPLEX_TRUST -  "Complex Trust" * CORPORATION - "Corporation" * PARTNERSHIP - "Partnership - DEPRECATED, use LIMITED_PARTNERSHIP instead" * LIMITED_LIABILITY_COMPANY - "Limited Liability Company" * FUND_OF_FUNDS - "Fund of Funds" * JOINT_TRUST - "Joint Trust" * JOINT_TENANTS_IN_COMMON_BOTH_OWNERS_ARE_ENTITIES - "Joint Tenants in Common (Both Owners are Entities)" * JOINT_RIGHTS_OF_SURVIVORSHIP_BOTH_OWNERS_ARE_ENTITIES - "Joint Rights of Survivorship (Both Owners are Entities)" * CCORP - "C-Corp" * SCORP - "S-Corp" * CHARITABLE_TRUST - "Charitable Trust" * REMAINDER_TRUST - "Remainder Trust" * LIMITED_PARTNERSHIP - "Limited Partnership" * OTHER - "Other"

 */
export type NonIndividualAccountSubTypeResponseV1 = string

/**
 * Tax Classification.
 */
export type TaxClassification = {
  /** Description. */
  description?: string | null
  type?: TaxClassificationResponseV1
} | null

/**
 * Account details relevant for individual clients.
 */
export type IndividualClientAccountDetailsV1 = {
  exemptFromFatcaCode?: ExemptionFromFatcaCodeResponseV1
  exemptPayeeCode?: ExemptPayeeCodeResponseV1
  taxClassification?: TaxClassification
} | null

/**
 * Account details relevant for individual account type
 */
export type IndividualAccountDetailsV1 = {
  australiaQualification?: AustraliaQualificationV1
  jointClient?: IndividualClientAccountDetailsV1
  /** Net Worth in USD. */
  netWorth?: number | null
  primaryClient?: IndividualClientAccountDetailsV1
} | null

export type IndividualQualifiedEligiblePersonStatusesResponseV1Item = {
  /** possible values are:
   * IQEP01 - Not a Qualified Eligible Person.
   * IQEP02 - The Investor owns securities (including participations in the Fund and other pools) of issuers not affiliated with the Investor and other investments (e.g., real estate held for the investment purpose) with an aggregate market value of at least $2,000,000.
   * IQEP03 - The Investor has on deposit for its own account with a futures commission merchant, at any time during the preceding six (6) months, at least $2,000,000 in exchange-specified initial margin and option premium for futures and other commodity interest positions.
   * IQEP04 - The Investor owns a portfolio comprised of a proportionate combination of 1 and 2 (e.g., $1,000,000 in securities and $100,000 in margin and premium).
   */
  code: string
}

export type IndividualQualifiedEligiblePersonStatusesResponseV1 =
  | IndividualQualifiedEligiblePersonStatusesResponseV1Item[]
  | null

export type IndividualQualifiedPurchaserStatusesResponseV1Item = {
  /** possible values are:
   * IQP01 - Not a Qualified Purchaser.
   * IQP02 - An individual that owns (separately, or through a joint, community property or other similar shared ownership interest with such person's spouse) not less than $5,000,000 in "Net Investments".
   * IQP03 - An individual, acting for its own account or the accounts of other qualified purchasers, who in the aggregate owns and invests on a discretionary basis not less than $25,000,000.
   * IQP04 - A “knowledgeable employee” of the Program Sponsor within the meaning of Rule 3c-5 under the Investment Company Act (a “Knowledgeable Employee”), or an IRA, Keogh, or other self-directed plan that is beneficially owned by a Knowledgeable Employee, or revocable trust each of whose grantors is a Knowledgeable Employee.
   */
  code: string
}

export type IndividualQualifiedPurchaserStatusesResponseV1 =
  | IndividualQualifiedPurchaserStatusesResponseV1Item[]
  | null

export type IndividualQualifiedClientStatusesResponseV1Item = {
  /** Possible values are:
   * IQC01 - Not a Qualified Client.
   * IQC02 - A natural person with a net worth of at least $2,100,000 as of the date hereof, either alone or with assets held jointly with such person's spouse, or it will invest at least $1,000,000 in a single investment. For purposes of determining a natural person's net worth, (i) the person's primary residence shall not be included as an asset; (ii) indebtedness that is secured by the person's primary residence up to the estimates fair market value of the primary residence at the time of the proposed subscription date, shall not be included as a liability (except that if the amount of such indebtedness outstanding at the proposed exceeds the amount outstanding 60 days before such date, other than as a result of the acquisition of the primary residence, the amount of such excess shall be included as a liability); and (iii) indebtedness that is secured by the person's primary residence in excess of the estimates fair market value of the primary residence at the proposed subscription date shall be included as a liability.
   */
  code: string
}

export type IndividualQualifiedClientStatusesResponseV1 =
  | IndividualQualifiedClientStatusesResponseV1Item[]
  | null

export type IndividualAccreditedInvestorStatusesResponseV1Item = {
  /** Possible values are:
   * IAI01 - Not an Accredited Investor
   * IAI02 - Any natural person who has a net worth (or joint net worth together with his/her spouse or spousal equivalent) in excess of $1,000,000 (excluding the value of his/her primary residence), and who has no reason to believe that his/her net worth will not remain in excess of $1,000,000 (excluding the value of his/her primary residence) for the foreseeable future.
   * IAI03 - An individual retirement account, Keogh Plan or other self-directed plan (i.e.: a tax-qualified defined contribution plan in which a participant may exercise control over the investment of assets credited to his or her account) in which all the participants are accredited investors because each participant has a net worth of at least $1,000,000 or has had an individual income of at least $200,000 (or a joint income with spouse of at least $300,000) in each of the last two years.
   * IAI04 - Any natural person who had individual income (exclusive of any income attributable to his or her spouse) of more than $200,000 in each of the past two years, or joint income with his or her spouse or spousal equivalent of more than $300,000 in each of those years, and reasonably expects to reach the same income level in the current year.
   * IAI05 - Any natural person holding, in good standing, a FINRA Series 7, Series 82 or Series 65 license or any such other professional certification, designation or credential from an accredited education institution that has been designated by an order of the SEC as qualifying an individual for accredited investor status.
   * IAI06 - A Knowledgeable Employee, or an IRA, Keogh, or other self-directed plan that is beneficially owned by a Knowledgeable Employee, or revocable trust each of whose grantors is a Knowledgeable Employee.
   * IAI07 - The Investor is a self-directed plan and the decision to invest is made by those participants investing, and each such participant qualifies as an "accredited investor".
   */
  code: string
  /** Applicable only if if IAI05 was selected */
  description?: string | null
}

export type IndividualAccreditedInvestorStatusesResponseV1 =
  | IndividualAccreditedInvestorStatusesResponseV1Item[]
  | null

export type ClientsResponseV1 = {
  jointClient?: ContactReferenceV1
  primaryClient: ContactReferenceV1
}

/**
 * Possible values are: * INDIVIDUAL - "Individual" * IRA - "IRA" * ROLLOVER_IRA - "Rollover IRA" * INHERITED_IRA - "Inherited IRA" * ROTH_IRA - "Roth IRA" * SEP_IRA - "Sep IRA" * JOINT_WITH_RIGHTS_OF_SURVIVORSHIP - "Joint with rights of survivorship" * JOINT_TENANTS_IN_COMMON - "Joint tenants in common" * COMMUNITY_PROPERTY - "Community Property" * KEOGH_PLAN - "Keogh plan" * OTHER_SELF_DIRECTED_RETIREMENT_ACCOUNT - "Other self directed retirement account"

 */
export type IndividualAccountSubTypeResponseV1 = string

/**
 * Taxable year end details.
 */
export type TaxableYearEndV1 = {
  /** Day of the month, between 1 and 31. */
  day: number
  /** Month, between 1 and 12. */
  month: number
} | null

/**
 * Account mailing address.
 */
export type AccountMailingAddressV1 = {
  address?: AddressResponseV1
  /** Indicates if the address is same as the legal address. If `true`, `address` must be null. */
  sameAsLegalAddress: boolean
} | null

/**
 * Account Legal Address.
 */
export type AccountLegalAddressV1 = {
  address?: AddressResponseV1
  /** Indicates if the address of the primary client is used as the legal address of the account. If `true`, `address` must be null. */
  sameAsPrimaryClientAddress: boolean
} | null

/**
 * Common account details.
 */
export type CommonAccountDetailsV1 = {
  affiliations?: AffiliationsV1
  /** Is investor subject to backup withholding? */
  backupWithholding?: boolean | null
  benefitPlan?: BenefitPlanV1
  erisa?: ErisaV1
  legalAddress?: AccountLegalAddressV1
  mailingAddress?: AccountMailingAddressV1
  newIssuesEligibility?: NewIssuesEligibilityV1
  /** Source of funds. */
  sourceOfFunds?: string | null
  taxableYearEnd?: TaxableYearEndV1
  /** Wire instructions. */
  wireInstructions?: WireInstructionsV1[] | null
} | null

/**
 * Possible values are: * ADVISORY * TRANSACTIONAL

 */
export type FeeStructureResponseV1 = string | null

/**
 * Response containing tax status details.
 */
export type TaxStatusDetailsResponseV1 = {
  /** Possible values are: * Corporation  * EstateOrTrustNotGrantOrRevocable   * Foundation   * GrantorOrRevocableTrust   * IRA   * LLC  * Other * Partnership
   */
  taxExemptionBasisCode?: string | null
  /** taxExemptionBasisReason will be provided when the selected taxExemptionBasisCode is 'Other' */
  taxExemptionBasisReason?: string | null
  /** Possible values are : * US_TAXABLE * NON_US * US_TAX_EXEMPT
   */
  taxStatus: string
} | null

export type AccountCustodianDetailsResponseV1 = {
  /** Account number. */
  accountNumber: string
  /** Custodian id. */
  custodianId: string
  name: string
  /** Needs custodian reporting. */
  needsCustodianReporting?: boolean | null
} | null

export type InvestorTypeResponseV1 = {
  /** category:
   * IFP01 - "Individual that is a United States Person (or a trust of such a person)"
   * IFP02 - "Individual that is not a US person (or a trust of such a person)"
   * IFP03 - "Broker-Dealer"
   * IFP04 - "Insurance Company"
   * IFP05 - "Investment Company registered with the US SEC"
   * IFP06 - "Private Fund"
   * IFP07 - "Non-profit organization (including endowments and foundation)"
   * IFP08 - "Pension plan (other than a governmental pension plan)"
   * IFP09 - "Banking or thrift institution (proprietary)"
   * IFP10 - "US state or municipal government entity (excl. governmental pension plans)"
   * IFP11 - "US state or municipal government pension plan"
   * IFP12 - "Sovereign wealth fund or foreign official institution"
   * IFP13 - "Other"
   */
  category: string
  description?: string | null
}

/**
 * Possible values are: * INDIVIDUAL * NON_INDIVIDUAL * TAX_EXEMPT_ORGANIZATION

 */
export type AccountCategoryResponseV1 = string

/**
 * category:
 * PRIVATE_FOUNDATION - "Private Foundation"
 * ENDOWMENT - "Endowment"
 * BENEFIT_PLAN - "Benefit Plan"
 * OTHER_TAX_EXEMPT_ORGANIZATION - "Other Tax-exempt organization"

 */
export type TaxExemptOrganizationSubTypeV1 =
  (typeof TaxExemptOrganizationSubTypeV1)[keyof typeof TaxExemptOrganizationSubTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxExemptOrganizationSubTypeV1 = {
  PRIVATE_FOUNDATION: 'PRIVATE_FOUNDATION',
  ENDOWMENT: 'ENDOWMENT',
  BENEFIT_PLAN: 'BENEFIT_PLAN',
  OTHER_TAX_EXEMPT_ORGANIZATION: 'OTHER_TAX_EXEMPT_ORGANIZATION',
} as const

/**
 * description:
 * NIQEP01 - Not a Qualified Eligible Person.
 * NIQEP02 - The Investor owns securities (including participations in the Fund and other pools) of issuers not affiliated with the Investor and other investments (e.g. real estate held for the investment purpose) with an aggregate market value of at least $2,000,000.
 * NIQEP03 - The Investor has on deposit for its own account with a futures commission merchant, at any time during the preceding six (6) months, at least $2,000,000 in exchange-specified initial margin and option premium for futures and other commodity interest positions.
 * NIQEP04 - The Investor owns a portfolio comprised of a proportionate combination of 1 and 2 (e.g., $1,000,000 in securities and $100,000 in margin and premium).

 */
export type NonIndividualQualifiedEligiblePersonStatusesV1ItemCode =
  (typeof NonIndividualQualifiedEligiblePersonStatusesV1ItemCode)[keyof typeof NonIndividualQualifiedEligiblePersonStatusesV1ItemCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NonIndividualQualifiedEligiblePersonStatusesV1ItemCode = {
  NIQEP01: 'NIQEP01',
  NIQEP02: 'NIQEP02',
  NIQEP03: 'NIQEP03',
  NIQEP04: 'NIQEP04',
} as const

export type NonIndividualQualifiedEligiblePersonStatusesV1Item = {
  /** description:
   * NIQEP01 - Not a Qualified Eligible Person.
   * NIQEP02 - The Investor owns securities (including participations in the Fund and other pools) of issuers not affiliated with the Investor and other investments (e.g. real estate held for the investment purpose) with an aggregate market value of at least $2,000,000.
   * NIQEP03 - The Investor has on deposit for its own account with a futures commission merchant, at any time during the preceding six (6) months, at least $2,000,000 in exchange-specified initial margin and option premium for futures and other commodity interest positions.
   * NIQEP04 - The Investor owns a portfolio comprised of a proportionate combination of 1 and 2 (e.g., $1,000,000 in securities and $100,000 in margin and premium).
   */
  code: NonIndividualQualifiedEligiblePersonStatusesV1ItemCode
}

export type NonIndividualQualifiedEligiblePersonStatusesV1 =
  | NonIndividualQualifiedEligiblePersonStatusesV1Item[]
  | null

/**
 * description:
 * NIQP01 - Not a Qualified Purchaser.
 * NIQP02 - A company, partnership, trust or other entity that (i) is owned directly or indirectly by, or for, two or more natural persons who are related as siblings, spouses (including former spouses), direct lineal descendants by birth or adoption, spouses of such persons, the estates of such persons or foundations, charitable organizations or trusts established by, or for the benefit of, such persons, (ii) owns not less than $5,000,000 in Net Investments (each such entity satisfying (i) and (ii) constituting a "Family Company"), and (iii) not formed for the purpose of investing in any particular product offered on the CAIS Platform.
 * NIQP03 - A trust (other than a Family Company) (i) that was not formed for the purpose of investing in any particular product offered on the CAIS Platform and (ii) of which the trustee (or other person authorized to make decisions with respect to the trust) and each settlor or other person who has contributed assets to the trust is a "qualified purchaser" (a "Contributing Trustee QP"). 
 * NIQP04 - A company, partnership, trust, or other entity that (i) was not formed for the purpose of investing in any particular product offered on the CAIS Platform, (ii) acts for its own account or the accounts of other "qualified purchasers," and (iii) in the aggregate, owns and invests on a discretionary basis not less than $25,000,000 in "Net Investments."
 * NIQP05 - Certain "qualified institutional buyers" as defined in Rule 144A under the Securities Act (primarily institutions that manage at least $100 million in securities including banks, savings and loans, insurance companies, investment companies, employee benefit plans or an entity owned entirely by qualified investors. Also included are registered broker-dealers owning and investing, on a discretionary basis, $10,000,000 in securities of non-affiliates).
 * NIQP06 - A "Charitable Corporation" (i) of which all the persons who have contributed assets are related in one or more of the ways enumerated above in respect of a Family Company, (ii) that owns not less than $5,000,000 in Net Investments, and (iii) that was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
 * NIQP07 - A "Charitable Corporation" (i) of which each person authorized to make investment decisions, and each person who has contributed assets, is a "qualified purchaser" (other than a Contributing Trustee QP) and (ii) was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
 * NIQP08 - A company, partnership or other entity whose securities are beneficially owned exclusively by "qualified purchasers".
 * NIQP09 - Certain “qualified institutional buyers” as defined in Rule 144A under the Securities Act (primarily institutions that manage at least $100 million in securities including banks, savings and loans, insurance companies, investment companies, employee benefit plans or an entity owned entirely by qualified investors.  Also included are registered broker-dealers owning and investing, on a discretionary basis, $10,000,000 in securities of non-affiliates).
 * NIQP10 - A corporation or partnership (whether or not formed for the specific purpose of buying an Interest) all of whose securities and other ownership interests are beneficially owned by Knowledgeable Employees (Note:  no ownership interests may be held by or for the benefit of any person other than a Knowledgeable Employee or his or her estate).

 */
export type NonIndividualQualifiedPurchaserStatusesV1ItemCode =
  (typeof NonIndividualQualifiedPurchaserStatusesV1ItemCode)[keyof typeof NonIndividualQualifiedPurchaserStatusesV1ItemCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NonIndividualQualifiedPurchaserStatusesV1ItemCode = {
  NIQP01: 'NIQP01',
  NIQP02: 'NIQP02',
  NIQP03: 'NIQP03',
  NIQP04: 'NIQP04',
  NIQP05: 'NIQP05',
  NIQP06: 'NIQP06',
  NIQP07: 'NIQP07',
  NIQP08: 'NIQP08',
  NIQP09: 'NIQP09',
  NIQP10: 'NIQP10',
} as const

export type NonIndividualQualifiedPurchaserStatusesV1Item = {
  /** description:
   * NIQP01 - Not a Qualified Purchaser.
   * NIQP02 - A company, partnership, trust or other entity that (i) is owned directly or indirectly by, or for, two or more natural persons who are related as siblings, spouses (including former spouses), direct lineal descendants by birth or adoption, spouses of such persons, the estates of such persons or foundations, charitable organizations or trusts established by, or for the benefit of, such persons, (ii) owns not less than $5,000,000 in Net Investments (each such entity satisfying (i) and (ii) constituting a "Family Company"), and (iii) not formed for the purpose of investing in any particular product offered on the CAIS Platform.
   * NIQP03 - A trust (other than a Family Company) (i) that was not formed for the purpose of investing in any particular product offered on the CAIS Platform and (ii) of which the trustee (or other person authorized to make decisions with respect to the trust) and each settlor or other person who has contributed assets to the trust is a "qualified purchaser" (a "Contributing Trustee QP").
   * NIQP04 - A company, partnership, trust, or other entity that (i) was not formed for the purpose of investing in any particular product offered on the CAIS Platform, (ii) acts for its own account or the accounts of other "qualified purchasers," and (iii) in the aggregate, owns and invests on a discretionary basis not less than $25,000,000 in "Net Investments."
   * NIQP05 - Certain "qualified institutional buyers" as defined in Rule 144A under the Securities Act (primarily institutions that manage at least $100 million in securities including banks, savings and loans, insurance companies, investment companies, employee benefit plans or an entity owned entirely by qualified investors. Also included are registered broker-dealers owning and investing, on a discretionary basis, $10,000,000 in securities of non-affiliates).
   * NIQP06 - A "Charitable Corporation" (i) of which all the persons who have contributed assets are related in one or more of the ways enumerated above in respect of a Family Company, (ii) that owns not less than $5,000,000 in Net Investments, and (iii) that was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
   * NIQP07 - A "Charitable Corporation" (i) of which each person authorized to make investment decisions, and each person who has contributed assets, is a "qualified purchaser" (other than a Contributing Trustee QP) and (ii) was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
   * NIQP08 - A company, partnership or other entity whose securities are beneficially owned exclusively by "qualified purchasers".
   * NIQP09 - Certain “qualified institutional buyers” as defined in Rule 144A under the Securities Act (primarily institutions that manage at least $100 million in securities including banks, savings and loans, insurance companies, investment companies, employee benefit plans or an entity owned entirely by qualified investors.  Also included are registered broker-dealers owning and investing, on a discretionary basis, $10,000,000 in securities of non-affiliates).
   * NIQP10 - A corporation or partnership (whether or not formed for the specific purpose of buying an Interest) all of whose securities and other ownership interests are beneficially owned by Knowledgeable Employees (Note:  no ownership interests may be held by or for the benefit of any person other than a Knowledgeable Employee or his or her estate).
   */
  code: NonIndividualQualifiedPurchaserStatusesV1ItemCode
}

export type NonIndividualQualifiedPurchaserStatusesV1 =
  | NonIndividualQualifiedPurchaserStatusesV1Item[]
  | null

/**
 * description:
 * NIQC01 - Not an Qualified Client.
 * NIQC02 - The Investor is a corporation, partnership, association, joint stock company, trust or other organized group of persons with a net worth (i.e., total assets minus total liabilities) of at least $2,100,000 as of the date hereof, or it will invest at least $1,000,000 in a single investment and declares, represents and warrants that if it is (x) an entity that would be a registered investment company but for the exception provided from that definition in Section 3(c)(1) of the Investment Company Act, (y) a registered investment company under the Investment Company Act or (z) a business development company within the meaning of Section 202(a)(22) of the Advisors Act, each of its equity owners independently meet the requirements of category (i) or (ii) of this Qualified Client Section.

 */
export type NonIndividualQualifiedClientStatusesV1ItemCode =
  (typeof NonIndividualQualifiedClientStatusesV1ItemCode)[keyof typeof NonIndividualQualifiedClientStatusesV1ItemCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NonIndividualQualifiedClientStatusesV1ItemCode = {
  NIQC01: 'NIQC01',
  NIQC02: 'NIQC02',
} as const

export type NonIndividualQualifiedClientStatusesV1Item = {
  /** description:
   * NIQC01 - Not an Qualified Client.
   * NIQC02 - The Investor is a corporation, partnership, association, joint stock company, trust or other organized group of persons with a net worth (i.e., total assets minus total liabilities) of at least $2,100,000 as of the date hereof, or it will invest at least $1,000,000 in a single investment and declares, represents and warrants that if it is (x) an entity that would be a registered investment company but for the exception provided from that definition in Section 3(c)(1) of the Investment Company Act, (y) a registered investment company under the Investment Company Act or (z) a business development company within the meaning of Section 202(a)(22) of the Advisors Act, each of its equity owners independently meet the requirements of category (i) or (ii) of this Qualified Client Section.
   */
  code: NonIndividualQualifiedClientStatusesV1ItemCode
}

export type NonIndividualQualifiedClientStatusesV1 =
  | NonIndividualQualifiedClientStatusesV1Item[]
  | null

/**
 * description: 
 * NIAI01 - Any trust that is an accredited investor because it is a revocable trust which may be amended or revoked at any time by the grantors thereof and all of the grantors are accredited investors.
 * NIAI02 - Any trust, with total assets in excess of $5,000,000, not formed for the purpose of investing in any particular product offered on the CAIS Platform whose purchase is directed by a sophisticated person as described in Rule 506(b)(2)(ii) under Regulation D, promulgated under the Securities Act.
 * NIAI03 - The investor is a corporation, a partnership, a limited liability company, or a business trust that has total assets in excess of $5,000,000 and was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
 * NIAI04 - Any employee benefit plan within the meaning of ERISA and has total assets in excess of US$5,000,000 and the decision to invest in the Fund was made solely by persons that are accredited investors.
 * NIAI05 - Any employee benefit plan within the meaning of ERISA, and the decision to invest in the fund was made by a plan fiduciary (as defined in Section 3(21) of ERISA), which is either a bank, savings and loan association, insurance company or registered investment advisor.
 * NIAI06 - Any plan established and maintained by a state, its political subdivisions or any agency or instrumentality of a state or its political subdivisions for the benefit of its employees, if such a plan has total assets in excess of $5,000,000.
 * NIAI07 - Any organization described in Section 501(c)(3) of the Code, corporation, Massachusetts or similar business trust or partnership, not formed for the purpose of investing in any particular product offered on the CAIS Platform, with total assets in excess of $5,000,000.
 * NIAI08 - Any private business development company as defined in Section 202(a)(22) of the Investment Advisors Act of 1940, as amended.
 * NIAI09 - Any investment company registered under the Company Act or a business development company as defined in Section 2(a)(48) of the Company Act.
 * NIAI10 - Any Small Business Investment Company licensed by the U.S. Small Business Administration under Section 301(c) or (d) of the Small Business Investment Act of 1958.
 * NIAI11 - Any broker or dealer registered pursuant to Section 15 of the Securities Exchange Act of 1934, as amended (The "Exchange Act").
 * NIAI12 - Any bank as defined in Section 3(a)(2) of the Securities Act, or any savings and loan association or other institution as defined in Section 3(a)(5)(A) of the Securities Act whether acting in its individual or fiduciary capacity.
 * NIAI13 - Any insurance company as defined in Section 2(13) of the Securities Act.
 * NIAI14 - Any entity (other than a trust) in which all of the equity owners are accredited investors.
 * NIAI15 - Not an Accredited Investor.
 * NIAI16 - Any family office that has total assets in excess of $5,000,000, that not formed for the purpose of investing in any particular product offered on the CAIS Platform and whose investment in the Interests is directed by a sophisticated person.
 * NIAI17 - A family client (as defined in Rule 202(a)(11)(G)-1 of the Advisers Act) of a family office meeting the requirements set forth directly above, and the Investor’s investment in the Interests is directed by such family office.
 * NIAI18 - Any investment adviser registered under the Advisers Act.
 * NIAI19 - Any investment adviser registered pursuant to the laws of a U.S. state.
 * NIAI20 - Any exempt reporting adviser under Section 203(I) (i.e., a venture capital fund adviser) or Section 203(m) (i.e., a private fund adviser) of the Advisers Act.
 * NIAI21 - Any entities that own not less than $5,000,000 in investments (as defined in Rule 2a41-(b) of the Investment Company Act) and was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
 * NIAI22 - A corporation or partnership (whether or not formed for the specific purpose of buying an Interest) of whose securities and other ownership interests are beneficially owned by Knowledgeable Employees (Note: no ownership interests may be held by or for the benefit of any person other than a Knowledgeable Employee or his or her estate).
 * NIAI23 - A self-directed plan and the decision to invest is made by those participants investing, and each such participant qualifies as "accredited investor."
 * NIAI24 - The Investor is a self-directed plan and the decision to invest is made by those participants investing, and each such participant qualifies as an "accredited investor".
Note - if NIAI05 is selected, please provide the description field

 */
export type NonIndividualAccreditedInvestorStatusesV1ItemCode =
  (typeof NonIndividualAccreditedInvestorStatusesV1ItemCode)[keyof typeof NonIndividualAccreditedInvestorStatusesV1ItemCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NonIndividualAccreditedInvestorStatusesV1ItemCode = {
  NIAI01: 'NIAI01',
  NIAI02: 'NIAI02',
  NIAI03: 'NIAI03',
  NIAI04: 'NIAI04',
  NIAI05: 'NIAI05',
  NIAI06: 'NIAI06',
  NIAI07: 'NIAI07',
  NIAI08: 'NIAI08',
  NIAI09: 'NIAI09',
  NIAI10: 'NIAI10',
  NIAI11: 'NIAI11',
  NIAI12: 'NIAI12',
  NIAI13: 'NIAI13',
  NIAI14: 'NIAI14',
  NIAI15: 'NIAI15',
  NIAI16: 'NIAI16',
  NIAI17: 'NIAI17',
  NIAI18: 'NIAI18',
  NIAI19: 'NIAI19',
  NIAI20: 'NIAI20',
  NIAI21: 'NIAI21',
  NIAI22: 'NIAI22',
  NIAI23: 'NIAI23',
  NIAI24: 'NIAI24',
} as const

export type NonIndividualAccreditedInvestorStatusesV1Item = {
  /** description: 
 * NIAI01 - Any trust that is an accredited investor because it is a revocable trust which may be amended or revoked at any time by the grantors thereof and all of the grantors are accredited investors.
 * NIAI02 - Any trust, with total assets in excess of $5,000,000, not formed for the purpose of investing in any particular product offered on the CAIS Platform whose purchase is directed by a sophisticated person as described in Rule 506(b)(2)(ii) under Regulation D, promulgated under the Securities Act.
 * NIAI03 - The investor is a corporation, a partnership, a limited liability company, or a business trust that has total assets in excess of $5,000,000 and was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
 * NIAI04 - Any employee benefit plan within the meaning of ERISA and has total assets in excess of US$5,000,000 and the decision to invest in the Fund was made solely by persons that are accredited investors.
 * NIAI05 - Any employee benefit plan within the meaning of ERISA, and the decision to invest in the fund was made by a plan fiduciary (as defined in Section 3(21) of ERISA), which is either a bank, savings and loan association, insurance company or registered investment advisor.
 * NIAI06 - Any plan established and maintained by a state, its political subdivisions or any agency or instrumentality of a state or its political subdivisions for the benefit of its employees, if such a plan has total assets in excess of $5,000,000.
 * NIAI07 - Any organization described in Section 501(c)(3) of the Code, corporation, Massachusetts or similar business trust or partnership, not formed for the purpose of investing in any particular product offered on the CAIS Platform, with total assets in excess of $5,000,000.
 * NIAI08 - Any private business development company as defined in Section 202(a)(22) of the Investment Advisors Act of 1940, as amended.
 * NIAI09 - Any investment company registered under the Company Act or a business development company as defined in Section 2(a)(48) of the Company Act.
 * NIAI10 - Any Small Business Investment Company licensed by the U.S. Small Business Administration under Section 301(c) or (d) of the Small Business Investment Act of 1958.
 * NIAI11 - Any broker or dealer registered pursuant to Section 15 of the Securities Exchange Act of 1934, as amended (The "Exchange Act").
 * NIAI12 - Any bank as defined in Section 3(a)(2) of the Securities Act, or any savings and loan association or other institution as defined in Section 3(a)(5)(A) of the Securities Act whether acting in its individual or fiduciary capacity.
 * NIAI13 - Any insurance company as defined in Section 2(13) of the Securities Act.
 * NIAI14 - Any entity (other than a trust) in which all of the equity owners are accredited investors.
 * NIAI15 - Not an Accredited Investor.
 * NIAI16 - Any family office that has total assets in excess of $5,000,000, that not formed for the purpose of investing in any particular product offered on the CAIS Platform and whose investment in the Interests is directed by a sophisticated person.
 * NIAI17 - A family client (as defined in Rule 202(a)(11)(G)-1 of the Advisers Act) of a family office meeting the requirements set forth directly above, and the Investor’s investment in the Interests is directed by such family office.
 * NIAI18 - Any investment adviser registered under the Advisers Act.
 * NIAI19 - Any investment adviser registered pursuant to the laws of a U.S. state.
 * NIAI20 - Any exempt reporting adviser under Section 203(I) (i.e., a venture capital fund adviser) or Section 203(m) (i.e., a private fund adviser) of the Advisers Act.
 * NIAI21 - Any entities that own not less than $5,000,000 in investments (as defined in Rule 2a41-(b) of the Investment Company Act) and was not formed for the purpose of investing in any particular product offered on the CAIS Platform.
 * NIAI22 - A corporation or partnership (whether or not formed for the specific purpose of buying an Interest) of whose securities and other ownership interests are beneficially owned by Knowledgeable Employees (Note: no ownership interests may be held by or for the benefit of any person other than a Knowledgeable Employee or his or her estate).
 * NIAI23 - A self-directed plan and the decision to invest is made by those participants investing, and each such participant qualifies as "accredited investor."
 * NIAI24 - The Investor is a self-directed plan and the decision to invest is made by those participants investing, and each such participant qualifies as an "accredited investor".
Note - if NIAI05 is selected, please provide the description field
 */
  code: NonIndividualAccreditedInvestorStatusesV1ItemCode
  /** If NIAI05 is selected, please provide the name of the plan fiduciary */
  description?: string | null
}

export type NonIndividualAccreditedInvestorStatusesV1 =
  | NonIndividualAccreditedInvestorStatusesV1Item[]
  | null

export type InvestmentEntitiesV1JointInvestmentEntity =
  | InvestmentEntityDetailsV1
  | NullableReferenceV1

/**
 * Investment Entities.
 */
export type InvestmentEntitiesV1 = {
  jointInvestmentEntity?: InvestmentEntitiesV1JointInvestmentEntity
  primaryInvestmentEntity: InvestmentEntityDetailsV1
}

/**
 * Primary signer details.
 */
export type PrimarySignersV1 = {
  /** Populate this when the subtype is JOINT_TENANTS_IN_COMMON_BOTH_OWNERS_ARE_ENTITIES or JOINT_RIGHTS_OF_SURVIVORSHIP_BOTH_OWNERS_ARE_ENTITIES. Will be renamed more generically in the future. */
  jointTenantPrimarySignerId?: string | null
  /** Populate this when the subtype is JOINT_TRUST */
  jointTrustSignerId?: string | null
  /** Primary signer id. */
  primarySignerId: string
}

/**
 * category:
 * GRANTOR_TRUST - "Grantor Trust"
 * REVOCABLE_TRUST - "Revocable Trust"
 * IRREVOCABLE_TRUST - "Irrevocable Trust"
 * SIMPLE_TRUST - "Simple Trust"
 * COMPLEX_TRUST -  "Complex Trust"
 * CORPORATION - "Corporation"
 * PARTNERSHIP - "Partnership - DEPRECATED"
 * LIMITED_LIABILITY_COMPANY - "Limited Liability Company"
 * FUND_OF_FUNDS - "Fund of Funds"
 * JOINT_TRUST - "Joint Trust"
 * JOINT_TENANTS_IN_COMMON_BOTH_OWNERS_ARE_ENTITIES - "Joint Tenants in Common (Both Owners are Entities)" 
 * JOINT_RIGHTS_OF_SURVIVORSHIP_BOTH_OWNERS_ARE_ENTITIES - "Joint Rights of Survivorship (Both Owners are Entities)"
 * CCORP - "C-Corp"
 * SCORP - "S-Corp"
 * CHARITABLE_TRUST - "Charitable Trust"
 * REMAINDER_TRUST - "Remainder Trust"
 * LIMITED_PARTNERSHIP - "Limited Partnership"
 * OTHER - "Other"

 */
export type NonIndividualAccountSubTypeV1 =
  (typeof NonIndividualAccountSubTypeV1)[keyof typeof NonIndividualAccountSubTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NonIndividualAccountSubTypeV1 = {
  GRANTOR_TRUST: 'GRANTOR_TRUST',
  REVOCABLE_TRUST: 'REVOCABLE_TRUST',
  IRREVOCABLE_TRUST: 'IRREVOCABLE_TRUST',
  SIMPLE_TRUST: 'SIMPLE_TRUST',
  COMPLEX_TRUST: 'COMPLEX_TRUST',
  CORPORATION: 'CORPORATION',
  PARTNERSHIP: 'PARTNERSHIP',
  LIMITED_LIABILITY_COMPANY: 'LIMITED_LIABILITY_COMPANY',
  FUND_OF_FUNDS: 'FUND_OF_FUNDS',
  JOINT_TRUST: 'JOINT_TRUST',
  JOINT_TENANTS_IN_COMMON_BOTH_OWNERS_ARE_ENTITIES:
    'JOINT_TENANTS_IN_COMMON_BOTH_OWNERS_ARE_ENTITIES',
  JOINT_RIGHTS_OF_SURVIVORSHIP_BOTH_OWNERS_ARE_ENTITIES:
    'JOINT_RIGHTS_OF_SURVIVORSHIP_BOTH_OWNERS_ARE_ENTITIES',
  CCORP: 'CCORP',
  SCORP: 'SCORP',
  CHARITABLE_TRUST: 'CHARITABLE_TRUST',
  REMAINDER_TRUST: 'REMAINDER_TRUST',
  LIMITED_PARTNERSHIP: 'LIMITED_PARTNERSHIP',
  OTHER: 'OTHER',
} as const

/**
 * description: 
 * IQEP01 - Not a Qualified Eligible Person.
 * IQEP02 - The Investor owns securities (including participations in the Fund and other pools) of issuers not affiliated with the Investor and other investments (e.g., real estate held for the investment purpose) with an aggregate market value of at least $2,000,000.
 * IQEP03 - The Investor has on deposit for its own account with a futures commission merchant, at any time during the preceding six (6) months, at least $2,000,000 in exchange-specified initial margin and option premium for futures and other commodity interest positions. 
 * IQEP04 - The Investor owns a portfolio comprised of a proportionate combination of 1 and 2 (e.g., $1,000,000 in securities and $100,000 in margin and premium). 

 */
export type IndividualQualifiedEligiblePersonStatusesV1ItemCode =
  (typeof IndividualQualifiedEligiblePersonStatusesV1ItemCode)[keyof typeof IndividualQualifiedEligiblePersonStatusesV1ItemCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IndividualQualifiedEligiblePersonStatusesV1ItemCode = {
  IQEP01: 'IQEP01',
  IQEP02: 'IQEP02',
  IQEP03: 'IQEP03',
  IQEP04: 'IQEP04',
} as const

export type IndividualQualifiedEligiblePersonStatusesV1Item = {
  /** description:
   * IQEP01 - Not a Qualified Eligible Person.
   * IQEP02 - The Investor owns securities (including participations in the Fund and other pools) of issuers not affiliated with the Investor and other investments (e.g., real estate held for the investment purpose) with an aggregate market value of at least $2,000,000.
   * IQEP03 - The Investor has on deposit for its own account with a futures commission merchant, at any time during the preceding six (6) months, at least $2,000,000 in exchange-specified initial margin and option premium for futures and other commodity interest positions.
   * IQEP04 - The Investor owns a portfolio comprised of a proportionate combination of 1 and 2 (e.g., $1,000,000 in securities and $100,000 in margin and premium).
   */
  code: IndividualQualifiedEligiblePersonStatusesV1ItemCode
}

export type IndividualQualifiedEligiblePersonStatusesV1 =
  | IndividualQualifiedEligiblePersonStatusesV1Item[]
  | null

/**
 * description:
 * IQP01 - Not a Qualified Purchaser.
 * IQP02 - An individual that owns (separately, or through a joint, community property or other similar shared ownership interest with such person's spouse) not less than $5,000,000 in "Net Investments".
 * IQP03 - An individual, acting for its own account or the accounts of other qualified purchasers, who in the aggregate owns and invests on a discretionary basis not less than $25,000,000.
 * IQP04 - A “knowledgeable employee” of the Program Sponsor within the meaning of Rule 3c-5 under the Investment Company Act (a “Knowledgeable Employee”), or an IRA, Keogh, or other self-directed plan that is beneficially owned by a Knowledgeable Employee, or revocable trust each of whose grantors is a Knowledgeable Employee.

 */
export type IndividualQualifiedPurchaserStatusesV1ItemCode =
  (typeof IndividualQualifiedPurchaserStatusesV1ItemCode)[keyof typeof IndividualQualifiedPurchaserStatusesV1ItemCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IndividualQualifiedPurchaserStatusesV1ItemCode = {
  IQP01: 'IQP01',
  IQP02: 'IQP02',
  IQP03: 'IQP03',
  IQP04: 'IQP04',
} as const

export type IndividualQualifiedPurchaserStatusesV1Item = {
  /** description:
   * IQP01 - Not a Qualified Purchaser.
   * IQP02 - An individual that owns (separately, or through a joint, community property or other similar shared ownership interest with such person's spouse) not less than $5,000,000 in "Net Investments".
   * IQP03 - An individual, acting for its own account or the accounts of other qualified purchasers, who in the aggregate owns and invests on a discretionary basis not less than $25,000,000.
   * IQP04 - A “knowledgeable employee” of the Program Sponsor within the meaning of Rule 3c-5 under the Investment Company Act (a “Knowledgeable Employee”), or an IRA, Keogh, or other self-directed plan that is beneficially owned by a Knowledgeable Employee, or revocable trust each of whose grantors is a Knowledgeable Employee.
   */
  code: IndividualQualifiedPurchaserStatusesV1ItemCode
}

export type IndividualQualifiedPurchaserStatusesV1 =
  | IndividualQualifiedPurchaserStatusesV1Item[]
  | null

/**
 * description: 
  * IQC01 - Not a Qualified Client. 
  * IQC02 - A natural person with a net worth of at least $2,100,000 as of the date hereof, either alone or with assets held jointly with such person's spouse, or it will invest at least $1,000,000 in a single investment. For purposes of determining a natural person's net worth, (i) the person's primary residence shall not be included as an asset; (ii) indebtedness that is secured by the person's primary residence up to the estimates fair market value of the primary residence at the time of the proposed subscription date, shall not be included as a liability (except that if the amount of such indebtedness outstanding at the proposed exceeds the amount outstanding 60 days before such date, other than as a result of the acquisition of the primary residence, the amount of such excess shall be included as a liability); and (iii) indebtedness that is secured by the person's primary residence in excess of the estimates fair market value of the primary residence at the proposed subscription date shall be included as a liability.

 */
export type IndividualQualifiedClientStatusesV1ItemCode =
  (typeof IndividualQualifiedClientStatusesV1ItemCode)[keyof typeof IndividualQualifiedClientStatusesV1ItemCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IndividualQualifiedClientStatusesV1ItemCode = {
  IQC01: 'IQC01',
  IQC02: 'IQC02',
} as const

export type IndividualQualifiedClientStatusesV1Item = {
  /** description:
   * IQC01 - Not a Qualified Client.
   * IQC02 - A natural person with a net worth of at least $2,100,000 as of the date hereof, either alone or with assets held jointly with such person's spouse, or it will invest at least $1,000,000 in a single investment. For purposes of determining a natural person's net worth, (i) the person's primary residence shall not be included as an asset; (ii) indebtedness that is secured by the person's primary residence up to the estimates fair market value of the primary residence at the time of the proposed subscription date, shall not be included as a liability (except that if the amount of such indebtedness outstanding at the proposed exceeds the amount outstanding 60 days before such date, other than as a result of the acquisition of the primary residence, the amount of such excess shall be included as a liability); and (iii) indebtedness that is secured by the person's primary residence in excess of the estimates fair market value of the primary residence at the proposed subscription date shall be included as a liability.
   */
  code: IndividualQualifiedClientStatusesV1ItemCode
}

export type IndividualQualifiedClientStatusesV1 =
  | IndividualQualifiedClientStatusesV1Item[]
  | null

/**
 * description:
 * IAI01 - Not an Accredited Investor
 * IAI02 - Any natural person who has a net worth (or joint net worth together with his/her spouse or spousal equivalent) in excess of $1,000,000 (excluding the value of his/her primary residence), and who has no reason to believe that his/her net worth will not remain in excess of $1,000,000 (excluding the value of his/her primary residence) for the foreseeable future.
 * IAI03 - An individual retirement account, Keogh Plan or other self-directed plan (i.e.: a tax-qualified defined contribution plan in which a participant may exercise control over the investment of assets credited to his or her account) in which all the participants are accredited investors because each participant has a net worth of at least $1,000,000 or has had an individual income of at least $200,000 (or a joint income with spouse of at least $300,000) in each of the last two years.
 * IAI04 - Any natural person who had individual income (exclusive of any income attributable to his or her spouse) of more than $200,000 in each of the past two years, or joint income with his or her spouse or spousal equivalent of more than $300,000 in each of those years, and reasonably expects to reach the same income level in the current year.
 * IAI05 - Any natural person holding, in good standing, a FINRA Series 7, Series 82 or Series 65 license or any such other professional certification, designation or credential from an accredited education institution that has been designated by an order of the SEC as qualifying an individual for accredited investor status.
 * IAI06 - A Knowledgeable Employee, or an IRA, Keogh, or other self-directed plan that is beneficially owned by a Knowledgeable Employee, or revocable trust each of whose grantors is a Knowledgeable Employee.
 * IAI07 - The Investor is a self-directed plan and the decision to invest is made by those participants investing, and each such participant qualifies as an "accredited investor".
Note - if IA105 is selected please provide the description field

 */
export type IndividualAccreditedInvestorStatusesV1ItemCode =
  (typeof IndividualAccreditedInvestorStatusesV1ItemCode)[keyof typeof IndividualAccreditedInvestorStatusesV1ItemCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IndividualAccreditedInvestorStatusesV1ItemCode = {
  IAI01: 'IAI01',
  IAI02: 'IAI02',
  IAI03: 'IAI03',
  IAI04: 'IAI04',
  IAI05: 'IAI05',
  IAI06: 'IAI06',
  IAI07: 'IAI07',
} as const

export type IndividualAccreditedInvestorStatusesV1Item = {
  /** description:
 * IAI01 - Not an Accredited Investor
 * IAI02 - Any natural person who has a net worth (or joint net worth together with his/her spouse or spousal equivalent) in excess of $1,000,000 (excluding the value of his/her primary residence), and who has no reason to believe that his/her net worth will not remain in excess of $1,000,000 (excluding the value of his/her primary residence) for the foreseeable future.
 * IAI03 - An individual retirement account, Keogh Plan or other self-directed plan (i.e.: a tax-qualified defined contribution plan in which a participant may exercise control over the investment of assets credited to his or her account) in which all the participants are accredited investors because each participant has a net worth of at least $1,000,000 or has had an individual income of at least $200,000 (or a joint income with spouse of at least $300,000) in each of the last two years.
 * IAI04 - Any natural person who had individual income (exclusive of any income attributable to his or her spouse) of more than $200,000 in each of the past two years, or joint income with his or her spouse or spousal equivalent of more than $300,000 in each of those years, and reasonably expects to reach the same income level in the current year.
 * IAI05 - Any natural person holding, in good standing, a FINRA Series 7, Series 82 or Series 65 license or any such other professional certification, designation or credential from an accredited education institution that has been designated by an order of the SEC as qualifying an individual for accredited investor status.
 * IAI06 - A Knowledgeable Employee, or an IRA, Keogh, or other self-directed plan that is beneficially owned by a Knowledgeable Employee, or revocable trust each of whose grantors is a Knowledgeable Employee.
 * IAI07 - The Investor is a self-directed plan and the decision to invest is made by those participants investing, and each such participant qualifies as an "accredited investor".
Note - if IA105 is selected please provide the description field
 */
  code: IndividualAccreditedInvestorStatusesV1ItemCode
  /** If IAI05 is selected, please list each such professional certification, designation or other credential held by the Investor. */
  description?: string | null
}

export type IndividualAccreditedInvestorStatusesV1 =
  | IndividualAccreditedInvestorStatusesV1Item[]
  | null

export type ClientsV1 = {
  /** Required when a joint subType is selected. The primaryClientId and the jointClientId can not be the same. */
  jointClientId?: string | null
  primaryClientId: string
}

/**
 * category:
 * INDIVIDUAL - "Individual"
 * IRA - "IRA"
 * ROLLOVER_IRA - "Rollover IRA"
 * INHERITED_IRA - "Inherited IRA"
 * ROTH_IRA - "Roth IRA"
 * SEP_IRA - "Sep IRA"
 * JOINT_WITH_RIGHTS_OF_SURVIVORSHIP - "Joint with rights of survivorship"
 * JOINT_TENANTS_IN_COMMON - "Joint tenants in common"
 * COMMUNITY_PROPERTY - "Community Property"
 * KEOGH_PLAN - "Keogh plan"
 * OTHER_SELF_DIRECTED_RETIREMENT_ACCOUNT - "Other self directed retirement account"

 */
export type IndividualAccountSubTypeV1 =
  (typeof IndividualAccountSubTypeV1)[keyof typeof IndividualAccountSubTypeV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IndividualAccountSubTypeV1 = {
  INDIVIDUAL: 'INDIVIDUAL',
  IRA: 'IRA',
  ROLLOVER_IRA: 'ROLLOVER_IRA',
  INHERITED_IRA: 'INHERITED_IRA',
  ROTH_IRA: 'ROTH_IRA',
  SEP_IRA: 'SEP_IRA',
  JOINT_WITH_RIGHTS_OF_SURVIVORSHIP: 'JOINT_WITH_RIGHTS_OF_SURVIVORSHIP',
  JOINT_TENANTS_IN_COMMON: 'JOINT_TENANTS_IN_COMMON',
  COMMUNITY_PROPERTY: 'COMMUNITY_PROPERTY',
  KEOGH_PLAN: 'KEOGH_PLAN',
  OTHER_SELF_DIRECTED_RETIREMENT_ACCOUNT:
    'OTHER_SELF_DIRECTED_RETIREMENT_ACCOUNT',
} as const

export type FeeStructureV1 =
  | (typeof FeeStructureV1)[keyof typeof FeeStructureV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeeStructureV1 = {
  ADVISORY: 'ADVISORY',
  TRANSACTIONAL: 'TRANSACTIONAL',
} as const

export type ReportingProviderFundDetailsV1 = {
  fundAccountNumber: string
  fundId: string
}

/**
 * Reporting Provider details.
 */
export type ReportingProviderDetailsV1 = {
  /** Funds. */
  funds?: ReportingProviderFundDetailsV1[] | null
  /** Account number for the reporting provider. */
  reportingProviderAccountNumber: string
  /** Reporting provider id */
  reportingProviderId: string
} | null

/**
 * Fund administrator details.
 */
export type FundAdministratorDetailsV1 = {
  /** Fund admin account number. */
  fundAdminAccountNumber: string
  /** Fund administrator id. */
  fundAdminId: string
  /** Collection of funds for this administrator. */
  funds?: FundAdministratorFundDetailsV1[] | null
}

/**
 * Tax Status.
 */
export type TaxStatusDetailsV1TaxStatus =
  (typeof TaxStatusDetailsV1TaxStatus)[keyof typeof TaxStatusDetailsV1TaxStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxStatusDetailsV1TaxStatus = {
  NON_US: 'NON_US',
  US_TAXABLE: 'US_TAXABLE',
  US_TAX_EXEMPT: 'US_TAX_EXEMPT',
} as const

/**
 * Tax exemption basis code.
 */
export type TaxStatusDetailsV1TaxExemptionBasisCode =
  | (typeof TaxStatusDetailsV1TaxExemptionBasisCode)[keyof typeof TaxStatusDetailsV1TaxExemptionBasisCode]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxStatusDetailsV1TaxExemptionBasisCode = {
  Corporation: 'Corporation',
  EstateOrTrustNotGrantOrRevocable: 'EstateOrTrustNotGrantOrRevocable',
  Foundation: 'Foundation',
  GrantorOrRevocableTrust: 'GrantorOrRevocableTrust',
  IRA: 'IRA',
  LLC: 'LLC',
  Other: 'Other',
  Partnership: 'Partnership',
} as const

/**
 * Tax Status Details.
 */
export type TaxStatusDetailsV1 = {
  /** Tax exemption basis code. */
  taxExemptionBasisCode?: TaxStatusDetailsV1TaxExemptionBasisCode
  /** taxExemptionBasisReason is mandatory when the selected taxExemptionBasisCode is Other */
  taxExemptionBasisReason?: string | null
  /** Tax Status. */
  taxStatus: TaxStatusDetailsV1TaxStatus
} | null

/**
 * Account Brokerage details.
 */
export type AccountBrokerageDetailsV1 = {
  /** Account number. */
  accountNumber: string
  /** Bank name. */
  bankName: string
} | null

/**
 * Account Custodian details.
 */
export type AccountCustodianDetailsV1 = {
  /** Account number. */
  accountNumber: string
  /** Custodian id. */
  custodianId: string
  /** Needs custodian reporting. */
  needsCustodianReporting?: boolean | null
} | null

/**
 * category:
 * IFP01 - "Individual that is a United States Person (or a trust of such a person)"
 * IFP02 - "Individual that is not a US person (or a trust of such a person)"
 * IFP03 - "Broker-Dealer"
 * IFP04 - "Insurance Company"
 * IFP05 - "Investment Company registered with the US SEC"
 * IFP06 - "Private Fund"
 * IFP07 - "Non-profit organization (including endowments and foundation)"
 * IFP08 - "Pension plan (other than a governmental pension plan)"       
 * IFP09 - "Banking or thrift institution (proprietary)"
 * IFP10 - "US state or municipal government entity (excl. governmental pension plans)"
 * IFP11 - "US state or municipal government pension plan"
 * IFP12 - "Sovereign wealth fund or foreign official institution"
 * IFP13 - "Other"

 */
export type InvestorTypeV1Category =
  (typeof InvestorTypeV1Category)[keyof typeof InvestorTypeV1Category]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestorTypeV1Category = {
  IFP01: 'IFP01',
  IFP02: 'IFP02',
  IFP03: 'IFP03',
  IFP04: 'IFP04',
  IFP05: 'IFP05',
  IFP06: 'IFP06',
  IFP07: 'IFP07',
  IFP08: 'IFP08',
  IFP09: 'IFP09',
  IFP10: 'IFP10',
  IFP11: 'IFP11',
  IFP12: 'IFP12',
  IFP13: 'IFP13',
} as const

export type InvestorTypeV1 = {
  /** category:
   * IFP01 - "Individual that is a United States Person (or a trust of such a person)"
   * IFP02 - "Individual that is not a US person (or a trust of such a person)"
   * IFP03 - "Broker-Dealer"
   * IFP04 - "Insurance Company"
   * IFP05 - "Investment Company registered with the US SEC"
   * IFP06 - "Private Fund"
   * IFP07 - "Non-profit organization (including endowments and foundation)"
   * IFP08 - "Pension plan (other than a governmental pension plan)"
   * IFP09 - "Banking or thrift institution (proprietary)"
   * IFP10 - "US state or municipal government entity (excl. governmental pension plans)"
   * IFP11 - "US state or municipal government pension plan"
   * IFP12 - "Sovereign wealth fund or foreign official institution"
   * IFP13 - "Other"
   */
  category: InvestorTypeV1Category
  description?: string | null
}

/**
 * category: 
  * INDIVIDUAL - "Individual"
  * NON_INDIVIDUAL - "Non Individual"
  * TAX_EXEMPT_ORGANIZATION -  "Tax Exempt Organization"

 */
export type AccountCategoryV1 =
  (typeof AccountCategoryV1)[keyof typeof AccountCategoryV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountCategoryV1 = {
  INDIVIDUAL: 'INDIVIDUAL',
  NON_INDIVIDUAL: 'NON_INDIVIDUAL',
  TAX_EXEMPT_ORGANIZATION: 'TAX_EXEMPT_ORGANIZATION',
} as const

/**
 * Collects a contact, its parent household, and a summary of the accounts to which it is a party.
 */
export type ContactListItemV1 = {
  accountSummary: ContactAccountSummaryV1
  contact: ContactResponseV1
  household: HouseholdResponseV1
}

/**
 * Defines the possible filters for use in a Contact search
 */
export type ContactFilterV1 = {
  contactIds?: UUIDFilter
  email?: StringFilter
  firmIds?: UUIDFilter
  firstName?: StringFilter
  lastName?: StringFilter
  middleInitial?: StringFilter
} | null

/**
 * category: 
  * JOINT_CLIENT - "Joint Client"
  * PRIMARY_CLIENT - "Primary Client"
  * PRIMARY_SIGNER -  "Primary Signer"
  * JOINT_TRUST - "Joint Trust" 
  * JOINT_PRIMARY_SIGNER - "Joint Primary Signer" 
  * SIGNATORY - "Signatory"
  * TRUSTEE - "Trustee"
  * CONTROLLING_PERSON - "Controlling Person"
  * BENEFICIARY_OWNER - "Beneficiary Owner"
  * INTERESTED_PARTY - "Interested Party"

 */
export type AccountRoleV1 = (typeof AccountRoleV1)[keyof typeof AccountRoleV1]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountRoleV1 = {
  JOINT_CLIENT: 'JOINT_CLIENT',
  PRIMARY_CLIENT: 'PRIMARY_CLIENT',
  PRIMARY_SIGNER: 'PRIMARY_SIGNER',
  JOINT_TRUST: 'JOINT_TRUST',
  JOINT_PRIMARY_SIGNER: 'JOINT_PRIMARY_SIGNER',
  SIGNATORY: 'SIGNATORY',
  TRUSTEE: 'TRUSTEE',
  CONTROLLING_PERSON: 'CONTROLLING_PERSON',
  BENEFICIARY_OWNER: 'BENEFICIARY_OWNER',
  INTERESTED_PARTY: 'INTERESTED_PARTY',
} as const

export type PageInfo = {
  endCursor?: string | null
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor?: string | null
} | null

export type PageRequest = {
  after?: string | null
  before?: string | null
  first?: number | null
  last?: number | null
} | null

/**
 * Defines the possible filters for use in a Household search
 */
export type HouseholdFilterV1 = {
  householdIds?: UUIDFilter
  householdName?: StringFilter
} | null

/**
 * for adding nullability to a ref
 */
export type NullableReferenceV1 =
  | (typeof NullableReferenceV1)[keyof typeof NullableReferenceV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NullableReferenceV1 = {} as const

export type ContactRequestV1MaritalStatus =
  (typeof ContactRequestV1MaritalStatus)[keyof typeof ContactRequestV1MaritalStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactRequestV1MaritalStatus = {
  ...MaritalStatusV1,
  ...NullableReferenceV1,
} as const

export type ContactRequestV1EmployerAddress =
  | CreateAddressRequestV1
  | NullableReferenceV1

export type ContactRequestV1Citizenship =
  (typeof ContactRequestV1Citizenship)[keyof typeof ContactRequestV1Citizenship]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactRequestV1Citizenship = {
  ...CountriesV1,
  ...NullableReferenceV1,
} as const

export type ContactRequestV1Address =
  | CreateAddressRequestV1
  | NullableReferenceV1

/**
 * Request properties for a contact.
 */
export type ContactRequestV1 = {
  address?: ContactRequestV1Address
  citizenship?: ContactRequestV1Citizenship
  /** Contact date of birth. */
  dateOfBirth?: string | null
  /** Contact email address. */
  email?: string | null
  employerAddress?: ContactRequestV1EmployerAddress
  /** Contact's employer name. */
  employerName?: string | null
  /** Contact first name. */
  firstName: string
  jobTitle?: string | null
  /** Contact last name. */
  lastName: string
  maritalStatus?: ContactRequestV1MaritalStatus
  /** Contact middle initial. */
  middleInitial?: string | null
  /** Contact phone number. */
  phoneNumber?: string | null
  /** Contact's position or role at employer company. */
  positionRole?: string | null
  /** Contact Social Security Number. */
  ssn?: string | null
  /** Contact title. */
  title?: string | null
} | null

/**
 * category: 
  * AD - "Andorra"
  * AE - "United Arab Emirates"
  * AF - "Afghanistan"
  * AG - "Antigua and Barbuda"
  * AI - "Anguilla"
  * AL - "Albania"
  * AM - "Armenia"
  * AO - "Angola"
  * AR - "Argentina"
  * AS - "American Samoa"
  * AT - "Austria"
  * AU - "Australia"
  * AW - "Aruba"
  * AX - "Åland Islands"
  * AZ - "Azerbaijan"
  * BA - "Bosnia and Herzegovina"
  * BB - "Barbados"
  * BD - "Bangladesh"
  * BE - "Belgium"
  * BF - "Burkina Faso"
  * BG - "Bulgaria"
  * BH - "Bahrain"
  * BI - "Burundi"
  * BJ - "Benin"
  * BL - "Saint Barthélemy"
  * BM - "Bermuda"
  * BN - "Brunei"
  * BO - "Bolivia"
  * BQ - "Caribbean Netherlands"
  * BR - "Brazil"
  * BS - "Bahamas"
  * BT - "Bhutan"
  * BW - "Botswana"
  * BY - "Belarus"
  * BZ - "Belize"
  * CA - "Canada"
  * CC - "Cocos"
  * CD - "Congo"
  * CF - "Central African Republic"
  * CG - "Congo"
  * CH - "Switzerland"
  * CI - "Côte d’Ivoire"
  * CK - "Cook Islands"
  * CL - "Chile"
  * CM - "Cameroon"
  * CN - "China"
  * CO - "Colombia"
  * CR - "Costa Rica"
  * CU - "Cuba"
  * CV - "Cape Verde"
  * CW - "Curaçao"
  * CX - "Christmas Island"
  * CY - "Cyprus"
  * CZ - "Czech Republic"
  * DE - "Germany"
  * DJ - "Djibouti"
  * DK - "Denmark"
  * DM - "Dominica"
  * DO - "Dominican Republic"
  * DZ - "Algeria"
  * EC - "Ecuador"
  * EE - "Estonia"
  * EG - "Egypt"
  * EH - "Western Sahara"
  * ER - "Eritrea"
  * ES - "Spain"
  * ET - "Ethiopia"
  * FI - "Finland"
  * FJ - "Fiji"
  * FK - "Falkland Islands"
  * FM - "Micronesia"
  * FO - "Faroe Islands"
  * FR - "France"
  * GA - "Gabon"
  * GB - "United Kingdom"
  * GD - "Grenada"
  * GE - "Georgia"
  * GF - "French Guiana"
  * GG - "Guernsey"
  * GH - "Ghana"
  * GI - "Gibraltar"
  * GL - "Greenland"
  * GM - "Gambia"
  * GN - "Guinea"
  * GP - "Guadeloupe"
  * GQ - "Equatorial Guinea"
  * GR - "Greece"
  * GT - "Guatemala"
  * GU - "Guam"
  * GW - "Guinea-Bissau"
  * GY - "Guyana"
  * HK - "Hong Kong"
  * HN - "Honduras"
  * HR - "Croatia"
  * HT - "Haiti"
  * HU - "Hungary"
  * ID - "Indonesia"
  * IE - "Ireland"
  * IL - "Israel"
  * IM - "Isle of Man"
  * IN - "India"
  * IO - "British Indian Ocean Territory"
  * IQ - "Iraq"
  * IR - "Iran"
  * IS - "Iceland"
  * IT - "Italy"
  * JE - "Jersey"
  * JM - "Jamaica"
  * JO - "Jordan"
  * JP - "Japan"
  * KE - "Kenya"
  * KG - "Kyrgyzstan"
  * KH - "Cambodia"
  * KI - "Kiribati"
  * KM - "Comoros"
  * KN - "Saint Kitts and Nevis"
  * KP - "North Korea"
  * KR - "South Korea"
  * KW - "Kuwait"
  * KY - "Cayman Islands"
  * KZ - "Kazakhstan"
  * LA - "Laos"
  * LB - "Lebanon"
  * LC - "Saint Lucia"
  * LI - "Liechtenstein"
  * LK - "Sri Lanka"
  * LR - "Liberia"
  * LS - "Lesotho"
  * LT - "Lithuania"
  * LU - "Luxembourg"
  * LV - "Latvia"
  * LY - "Libya"
  * MA - "Morocco"
  * MC - "Monaco"
  * MD - "Moldova"
  * ME - "Montenegro"
  * MF - "Saint Martin"
  * MG - "Madagascar"
  * MH - "Marshall Islands"
  * MK - "North Macedonia"
  * ML - "Mali"
  * MM - "Myanmar"
  * MN - "Mongolia"
  * MO - "Macau"
  * MP - "Northern Mariana Islands"
  * MQ - "Martinique"
  * MR - "Mauritania"
  * MS - "Montserrat"
  * MT - "Malta"
  * MU - "Mauritius"
  * MV - "Maldives"
  * MW - "Malawi"
  * MX - "Mexico"
  * MY - "Malaysia"
  * MZ - "Mozambique"
  * NA - "Namibia"
  * NC - "New Caledonia"
  * NE - "Niger"
  * NF - "Norfolk Island"
  * NG - "Nigeria"
  * NI - "Nicaragua"
  * NL - "Netherlands"
  * NO - "Norway"
  * NP - "Nepal"
  * NR - "Nauru"
  * NU - "Niue"
  * NZ - "New Zealand"
  * OM - "Oman"
  * PA - "Panama"
  * PE - "Peru"
  * PF - "French Polynesia"
  * PG - "Papua New Guinea"
  * PH - "Philippines"
  * PK - "Pakistan"
  * PL - "Poland"
  * PM - "Saint Pierre and Miquelon"
  * PR - "Puerto Rico"
  * PS - "Palestine"
  * PT - "Portugal"
  * PW - "Palau"
  * PY - "Paraguay"
  * QA - "Qatar"
  * RE - "Réunion"
  * RO - "Romania"
  * RS - "Serbia"
  * RU - "Russia"
  * RW - "Rwanda"
  * SA - "Saudi Arabia"
  * SB - "Solomon Islands"
  * SC - "Seychelles"
  * SD - "Sudan"
  * SE - "Sweden"
  * SG - "Singapore"
  * SH - "Saint Helena"
  * SI - "Slovenia"
  * SJ - "Svalbard and Jan Mayen"
  * SK - "Slovakia"
  * SL - "Sierra Leone"
  * SM - "San Marino"
  * SN - "Senegal"
  * SO - "Somalia"
  * SR - "Suriname"
  * SS - "South Sudan"
  * ST - "São Tomé and Príncipe"
  * SV - "El Salvador"
  * SX - "Sint Maarten"
  * SY - "Syria"
  * SZ - "Eswatini"
  * TC - "Turks and Caicos Islands"
  * TD - "Chad"
  * TG - "Togo"
  * TH - "Thailand"
  * TJ - "Tajikistan"
  * TK - "Tokelau"
  * TL - "Timor-Leste"
  * TM - "Turkmenistan"
  * TN - "Tunisia"
  * TO - "Tonga"
  * TR - "Turkey"
  * TT - "Trinidad and Tobago"
  * TV - "Tuvalu"
  * TW - "Taiwan"
  * TZ - "Tanzania"
  * UA - "Ukraine"
  * UG - "Uganda"
  * US - "United States"
  * UY - "Uruguay"
  * UZ - "Uzbekistan"
  * VA - "Vatican City"
  * VC - "Saint Vincent and the Grenadines"
  * VE - "Venezuela"
  * VG - "British Virgin Islands"
  * VI - "U.S. Virgin Islands"
  * VN - "Vietnam"
  * VU - "Vanuatu"
  * WF - "Wallis and Futuna"
  * WS - "Samoa"
  * XK - "Kosovo"
  * YE - "Yemen"
  * YT - "Mayotte"
  * ZA - "South Africa"
  * ZM - "Zambia"
  * ZW - "Zimbabwe"

 */
export type CountriesResponseV1 = string

export type AddressResponseV1 = {
  addressLine1: string
  addressLine2?: string | null
  city: string
  country: CountriesResponseV1
  state: string
  zipCode: string
}

/**
 * Possible values are:  * SINGLE * MARRIED * DIVORCED * SEPARATED * WIDOWED

 */
export type MaritalStatusResponseV1 = string | null

export type ViolationsGlobalViolationsItem = {
  message?: string
}

export type ViolationsFieldViolationsItem = {
  fieldName?: string
  message?: string
}

export type Violations = {
  fieldViolations?: ViolationsFieldViolationsItem[]
  globalViolations?: ViolationsGlobalViolationsItem[]
}

export type VersionedId = {
  id: string
  version: number
}

export type AdminAccountVersionUpdateResponse = {
  /** List of account IDs that failed to update. */
  failedAccountIds: string[]
  /** List of versioned account IDs that were successfully updated. */
  updatedAccountIds: VersionedId[]
}

/**
 * Request object for bulk updating account versions.
 */
export type AdminAccountVersionUpdateRequest = {
  /** List of account IDs to update. Each ID should be unique. */
  accountIds: string[]
}

export type FileUpdateRequestV1 = {
  expiryDate?: string | null
  issueDate?: string | null
}

/**
 * Response object containing details for the requested document.
 */
export type DownloadDocumentResponseV1 = {
  /** Expiry date time for the download URL. */
  downloadExpiresAt: string
  /** URL that the document can be downloaded from. */
  downloadUrl: string
  /** Date that the document expires. */
  expiryDate?: string | null
  /** The unique id of the file. */
  fileId: string
  /** The name of the document file. */
  fileName: string
  fileType: FileTypeResponseV1
  /** The document version. */
  fileVersion: number
  /** Whether this document is the latest version or not. */
  isLatest: boolean
  /** Date that the document was issued. */
  issueDate?: string | null
}

export type FileDetailsRequestV1 = {
  /** The mime type of the file. Used as the content-type of the response when the file is downloaded. */
  contentType: string
  /** Date the document expires. Only applicable to GOVERNMENT_ISSUED_ID and OTHER (where appropriate) document types. */
  expiryDate?: string | null
  /** The fileId of the document already stored in file-storage. */
  fileId: string
  /** The name of the document already stored in file-storage. */
  fileName: string
  fileType: FileTypeV1
  /**
   * The idempotency key calculated for the uploaded file. Deprecated: no need to provide this field when updating document details.
   * @deprecated
   */
  idempotencyKey?: string | null
  /** Indicates if the document is for the joint entity of the account. Note - Only relevant when resourceType is 'ACCOUNT'. */
  isForJointEntity?: boolean | null
  /** Date the document was issued. Only applicable to PROOF_OF_ADDRESS, US_TAX_FORM, NON_US_TAXFORM, CERTIFICATE_OF_GOOD_STANDING and OTHER (where appropriate) document types. */
  issueDate?: string | null
  /** The id of the contact or account the document relates to. */
  resourceId: string
  resourceType: DocumentAwareResourceV1
}

/**
 * Response to uploading a new document.
 */
export type UploadDocumentResponseV1 = {
  contentType: string
  /** Expiry date of the uploaded document. */
  expiryDate?: string
  /** The unique id of the uploaded file. */
  fileId: string
  /** The name of the uploaded file. */
  fileName: string
  fileType: FileTypeResponseV1
  /** The version of the uploaded file. */
  fileVersion: number
  /** Indicates if the uploaded documents are for the joint entity of the account. Note - Only relevant when resourceType is 'ACCOUNT'. */
  isForJointEntity?: boolean | null
  isLatest: boolean
  /** Date that the uploaded document was issued. */
  issueDate?: string
  /** Unique identifier supplied with the file. */
  resourceId: string
  resourceType: DocumentAwareResourceResponseV1
}

/**
 * Request to upload a new document.
 */
export type UploadDocumentRequestV1 = {
  file: Blob
  fileUploadRequest: FileUploadRequestV1
}

export type KycDocumentFileTypesResponseV1 = {
  documentFileTypes: KycDocumentFileTypeDetailsV1[]
}

/**
 * KYC Document Details.
 */
export type KycDocumentDetailsV1 = {
  contentType: string
  /** Expiry date for the document. */
  expiryDate?: string | null
  /** Unique id of the file. */
  fileId: string
  /** Name of ths file. */
  fileName: string
  fileType: FileTypeResponseV1
  /** Version number for this file. */
  fileVersion: number
  /** Indicates if the document is for the joint entity of the account. Note - Only relevant when resourceType is 'ACCOUNT'. */
  isForJointEntity?: boolean
  /** Indicates if this is the latest version of the document. */
  isLatest: boolean
  /** Date that the document was issued. */
  issueDate?: string | null
  /** Id of the document resource. */
  resourceId: string
  resourceType: DocumentAwareResourceResponseV1
}

/**
 * Update to interested party.
 */
export type UpdateInterestedPartyRequestV1 = {
  accountRoles: AccountRoleRequestV1[]
  beneficiaryEntities?: UpdateBeneficiaryEntityRequestV1[] | null
  /** Indicates if email notifications are turned on. */
  emailNotifications: boolean
  /** Percentage of ownership. */
  percentageOfOwnership?: number | null
  /** Preferred Signer. */
  preferredSigner?: boolean | null
}

/**
 * Request to create interested party object.
 */
export type CreateInterestedPartyRequestV1 = {
  /** Account Roles */
  accountRoles: AccountRoleRequestV1[]
  beneficiaryEntities?: CreateBeneficiaryEntityRequestV1[] | null
  /** Contact id */
  contactId: string
  /** Indicates if email notifications are turned on. */
  emailNotifications: boolean
  /** Percentage of ownership. */
  percentageOfOwnership?: number | null
  /** Indicates if preferred signer or not. */
  preferredSigner?: boolean | null
}

export type InterestedPartyResponseV1 = {
  account: AccountReferenceV1
  /**
   * Use `roles` instead.
   * @deprecated
   */
  accountRoles: AccountRoleResponseV1[]
  beneficiaryEntities?: BeneficiaryEntityResponseV1[] | null
  /** may be null in old Kafka events; otherwise, should never be null */
  collectionId?: string | null
  contact: ContactReferenceV1
  emailNotifications: boolean
  percentageOfOwnership?: number | null
  preferredSigner?: boolean | null
  roles?: TimedAccountRoleResponseV1[]
}

/**
 * Represents one page of contacts from a list of contacts.
 */
export type PagedAccountListItemsV1 = {
  items: AccountListItemV1[]
  pageInfo: PageInfo
}

export type AccountsSearchRequestV1SortingItem =
  (typeof AccountsSearchRequestV1SortingItem)[keyof typeof AccountsSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountsSearchRequestV1SortingItem = {
  CREATED_AT_ASC: 'CREATED_AT_ASC',
  CREATED_AT_DESC: 'CREATED_AT_DESC',
  UPDATED_AT_ASC: 'UPDATED_AT_ASC',
  UPDATED_AT_DESC: 'UPDATED_AT_DESC',
  ACCOUNT_NAME_ASC: 'ACCOUNT_NAME_ASC',
  ACCOUNT_NAME_DESC: 'ACCOUNT_NAME_DESC',
  CUSTODIAN_NAME_ASC: 'CUSTODIAN_NAME_ASC',
  CUSTODIAN_NAME_DESC: 'CUSTODIAN_NAME_DESC',
  ACCOUNT_NUMBER_ASC: 'ACCOUNT_NUMBER_ASC',
  ACCOUNT_NUMBER_DESC: 'ACCOUNT_NUMBER_DESC',
  CLIENT_FIRST_NAME_ASC: 'CLIENT_FIRST_NAME_ASC',
  CLIENT_FIRST_NAME_DESC: 'CLIENT_FIRST_NAME_DESC',
  CLIENT_LAST_NAME_ASC: 'CLIENT_LAST_NAME_ASC',
  CLIENT_LAST_NAME_DESC: 'CLIENT_LAST_NAME_DESC',
  CLIENT_MIDDLE_INITIAL_ASC: 'CLIENT_MIDDLE_INITIAL_ASC',
  CLIENT_MIDDLE_INITIAL_DESC: 'CLIENT_MIDDLE_INITIAL_DESC',
  HOUSEHOLD_NAME_ASC: 'HOUSEHOLD_NAME_ASC',
  HOUSEHOLD_NAME_DESC: 'HOUSEHOLD_NAME_DESC',
} as const

/**
 * Defines the filters, paging and sorting to use for a Account search
 */
export type AccountsSearchRequestV1 = {
  filter?: AccountFilterV1
  pageRequest?: PageRequest
  sorting?: AccountsSearchRequestV1SortingItem[] | null
}

/**
 * Account wire instructions details common for all account types.
 */
export type CommonAccountDetailsForWireInstructionsV1 = {
  /** Wire instructions. */
  wireInstructions?: WireInstructionsV1[] | null
} | null

/**
 * Request for updating wire instructions details.
 */
export type AccountWireInstructionsRequestV1 = {
  commonAccountDetails?: CommonAccountDetailsForWireInstructionsV1
}

export type AccountFieldsGroupMappingV1 = {
  accountFieldsGroup: string
  fieldReferences: string[]
}

export type FieldReferenceV1 = {
  fieldPath: string
  fieldReference: string
}

export type AccountDataRequirementsDictionaryV1 = {
  availableFieldReferences: FieldReferenceV1[]
  dictionary: AccountFieldsGroupMappingV1[]
}

export type AccountDataReadinessSkippedFieldsV1 = {
  skippedFields: SkippedFieldReferenceV1[]
}

export type UniformRulesV1 = {
  containsAllAmongstAll?: string[] | null
  containsOneAmongstAll?: string[] | null
  requiredIf?: UniformRuleV1[] | null
}

export type UniformRequirementsV1 = {
  fieldId: string
  required?: boolean | null
  rules?: UniformRulesV1
  skippable: boolean
}

export type AccountDataRequirementsResponseV1 = {
  account: AccountV1
  requirements: UniformRequirementsV1[]
  skippedFields: SkippedFieldReferenceV1[]
}

export type AccountDataReadinessResponseV1 = {
  accountDataComplete: boolean
  missingFields?: string[] | null
  unknownAccountFieldsGroups?: string[] | null
}

export type AccountDataReadinessRequestV1 = {
  accountFieldsGroups: string[]
}

export type AccountDocumentsDataReadinessResponseV1 = {
  documentsDataComplete: boolean
}

/**
 * Upsert interested parties.
 */
export type AccountUpsertInterestedPartyRequestV1 = {
  beneficiaryEntities?: UpdateBeneficiaryEntityRequestV1[] | null
  contactId: string
  /** Indicates if email notifications are turned on. */
  emailNotifications: boolean
  /** Percentage of ownership. */
  percentageOfOwnership?: number | null
  /** Preferred Signer. */
  preferredSigner?: boolean | null
  roles: AccountRoleRequestV1[]
}

/**
 * Request to update signatories and other account roles.
 */
export type AccountSignatoriesAndOtherAccountRolesRequestV1 = {
  advisorNotifications: boolean
  advisorNotificationsAlternativeEmail?: string | null
  interestedParties: AccountUpsertInterestedPartyRequestV1[]
}

/**
 * Account eligibility questions common for non-individual and tax-exempt organization account types.
 */
export type AccountDetailsForEligibilityQuestionsV1 = {
  accreditedInvestorStatuses?: NonIndividualAccreditedInvestorStatusesV1
  qualifiedClientStatuses?: NonIndividualQualifiedClientStatusesV1
  qualifiedEligiblePersonStatuses?: NonIndividualQualifiedEligiblePersonStatusesV1
  qualifiedPurchaserStatuses?: NonIndividualQualifiedPurchaserStatusesV1
} | null

/**
 * Request details of the eligibility questions for individual account type.
 */
export type IndividualAccountDetailsForEligibilityQuestionsV1 = {
  accreditedInvestorStatuses?: IndividualAccreditedInvestorStatusesV1
  qualifiedClientStatuses?: IndividualQualifiedClientStatusesV1
  qualifiedEligiblePersonStatuses?: IndividualQualifiedEligiblePersonStatusesV1
  qualifiedPurchaserStatuses?: IndividualQualifiedPurchaserStatusesV1
} | null

/**
 * Account eligibility questions for all account types
 */
export type CommonAccountDetailsForEligibilityQuestionsV1 = {
  feeStructure?: FeeStructureV1
  taxStatusDetails: TaxStatusDetailsV1
}

/**
 * Request for updating account eligibility and affiliations details.
 */
export type AccountEligibilityQuestionsRequestV1 = {
  commonAccountDetails: CommonAccountDetailsForEligibilityQuestionsV1
  individualAccountDetails?: IndividualAccountDetailsForEligibilityQuestionsV1
  nonIndividualAccountDetails?: AccountDetailsForEligibilityQuestionsV1
  taxExemptOrganizationAccountDetails?: AccountDetailsForEligibilityQuestionsV1
}

/**
 * Account benefit plan and ERISA details common for all account types.
 */
export type CommonAccountDetailsForBenefitPlanAndERISAV1 = {
  benefitPlan?: BenefitPlanV1
  erisa?: ErisaV1
} | null

/**
 * Request for updating account benefit plan and ERISA details.
 */
export type AccountBenefitPlanAndERISARequestV1 = {
  commonAccountDetails?: CommonAccountDetailsForBenefitPlanAndERISAV1
}

/**
 * Account details relevant for tax exempt organization account type
 */
export type TaxExemptOrganizationAccountDetailsForKYCAndTaxV1 = {
  primaryClient?: EntityClientAccountDetailsV1
} | null

/**
 * Account KYC and tax information relevant for non-individual account type
 */
export type NonIndividualAccountDetailsForKYCAndTaxV1 = {
  jointClient?: EntityClientAccountDetailsV1
  primaryClient?: EntityClientAccountDetailsV1
} | null

/**
 * Account KYC and tax information relevant for individual account type
 */
export type IndividualAccountDetailsForKYCAndTaxV1 = {
  jointClient?: IndividualClientAccountDetailsV1
  /** In USD. */
  netWorth?: number | null
  primaryClient?: IndividualClientAccountDetailsV1
} | null

/**
 * Account KYC and tax information common for all account types.
 */
export type CommonAccountDetailsForKYCAndTaxV1 = {
  /** Is investor subject to backup withholding? */
  backupWithholding?: boolean | null
  legalAddress?: AccountLegalAddressV1
  mailingAddress?: AccountMailingAddressV1
  sourceOfFunds?: string | null
  taxableYearEnd?: TaxableYearEndV1
} | null

/**
 * Request for updating account eligibility and affiliations details.
 */
export type AccountKYCAndTaxRequestV1 = {
  commonAccountDetails?: CommonAccountDetailsForKYCAndTaxV1
  individualAccountDetails?: IndividualAccountDetailsForKYCAndTaxV1
  nonIndividualAccountDetails?: NonIndividualAccountDetailsForKYCAndTaxV1
  taxExemptOrganizationAccountDetails?: TaxExemptOrganizationAccountDetailsForKYCAndTaxV1
}

/**
 * Account eligibility and affiliation details common for non-individual and tax-exempt organization account types.
 */
export type AccountDetailsForEligibilityAndAffiliationsV1 = {
  accountEligibility?: AccountEligibilityV1
  clientEntityAffiliations?: ClientEntityAffiliationsV1
} | null

/**
 * Request details of eligibility and affiliations section for individual account type.
 */
export type IndividualAccountDetailsForEligibilityAndAffiliationsV1 = {
  australiaQualification?: AustraliaQualificationV1
} | null

/**
 * Account eligibility and affiliation details common for all account types.
 */
export type CommonAccountDetailsForEligibilityAndAffiliationsV1 = {
  affiliations?: AffiliationsV1
  newIssuesEligibility?: NewIssuesEligibilityV1
} | null

/**
 * Request for updating account eligibility and affiliations details.
 */
export type AccountEligibilityAndAffiliationsRequestV1 = {
  commonAccountDetails?: CommonAccountDetailsForEligibilityAndAffiliationsV1
  individualAccountDetails?: IndividualAccountDetailsForEligibilityAndAffiliationsV1
  nonIndividualAccountDetails?: AccountDetailsForEligibilityAndAffiliationsV1
  taxExemptOrganizationAccountDetails?: AccountDetailsForEligibilityAndAffiliationsV1
}

/**
 * Request to update account advisor notifications.
 */
export type AccountAdvisorNotificationsRequestV1 = {
  advisorNotifications: boolean
  advisorNotificationsAlternativeEmail?: string | null
}

export type TaxExemptOrganizationAccountV1AccountCategory =
  (typeof TaxExemptOrganizationAccountV1AccountCategory)[keyof typeof TaxExemptOrganizationAccountV1AccountCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxExemptOrganizationAccountV1AccountCategory = {
  TAX_EXEMPT_ORGANIZATION: 'TAX_EXEMPT_ORGANIZATION',
} as const

export type TaxExemptOrganizationAccountV1AllOf = {
  accreditedInvestorStatuses?: NonIndividualAccreditedInvestorStatusesResponseV1
  investmentEntities?: InvestmentEntitiesV1
  /** A nominee account is a type of account where an individual or entity holds securities on behalf of the actual owner. */
  isNominee?: boolean
  primarySigner?: ContactReferenceV1
  qualifiedClientStatuses?: NonIndividualQualifiedClientStatusesResponseV1
  qualifiedEligiblePersonStatuses?: NonIndividualQualifiedEligiblePersonStatusesResponseV1
  qualifiedPurchaserStatuses?: NonIndividualQualifiedPurchaserStatusesResponseV1
  subType?: TaxExemptOrganizationSubTypeResponseV1
  taxExemptOrganizationAccountDetails?: TaxExemptOrganizationAccountDetailsV1
}

export type NonIndividualAccountV1AccountCategory =
  (typeof NonIndividualAccountV1AccountCategory)[keyof typeof NonIndividualAccountV1AccountCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NonIndividualAccountV1AccountCategory = {
  NON_INDIVIDUAL: 'NON_INDIVIDUAL',
} as const

export type NonIndividualAccountV1AllOf = {
  accreditedInvestorStatuses?: NonIndividualAccreditedInvestorStatusesResponseV1
  investmentEntities?: InvestmentEntitiesV1
  /** A nominee account is a type of account where an individual or entity holds securities on behalf of the actual owner. */
  isNominee?: boolean
  nonIndividualAccountDetails?: NonIndividualAccountDetailsV1
  primarySigners?: PrimarySignersResponseV1
  qualifiedClientStatuses?: NonIndividualQualifiedClientStatusesResponseV1
  qualifiedEligiblePersonStatuses?: NonIndividualQualifiedEligiblePersonStatusesResponseV1
  qualifiedPurchaserStatuses?: NonIndividualQualifiedPurchaserStatusesResponseV1
  subType?: NonIndividualAccountSubTypeResponseV1
  subTypeDescription?: string | null
}

export type IndividualAccountV1AccountCategory =
  (typeof IndividualAccountV1AccountCategory)[keyof typeof IndividualAccountV1AccountCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IndividualAccountV1AccountCategory = {
  INDIVIDUAL: 'INDIVIDUAL',
} as const

export type IndividualAccountV1AllOf = {
  accreditedInvestorStatuses?: IndividualAccreditedInvestorStatusesResponseV1
  clients?: ClientsResponseV1
  individualAccountDetails?: IndividualAccountDetailsV1
  planName?: string | null
  planType?: string | null
  qualifiedClientStatuses?: IndividualQualifiedClientStatusesResponseV1
  qualifiedEligiblePersonStatuses?: IndividualQualifiedEligiblePersonStatusesResponseV1
  qualifiedPurchaserStatuses?: IndividualQualifiedPurchaserStatusesResponseV1
  subType?: IndividualAccountSubTypeResponseV1
}

export type AccountV1 = {
  advisorNotifications?: boolean | null
  advisorNotificationsAlternativeEmail?: string | null
  brokerageDetails?: AccountBrokerageDetailsV1
  /** may be null in old Kafka events; otherwise, should never be null */
  caisId?: string | null
  commonAccountDetails?: CommonAccountDetailsV1
  createdAt: string
  custodianDetails?: AccountCustodianDetailsResponseV1
  externalId?: string | null
  feeStructure?: FeeStructureResponseV1
  fundAdministratorsDetails?: FundAdministratorDetailsV1[] | null
  householdDetails?: HouseholdResponseV1
  householdId: string
  id: string
  interestedParties?: InterestedPartyResponseV1[]
  investorType?: InvestorTypeResponseV1
  isLatest: boolean
  name: string
  reportingProviderDetails?: ReportingProviderDetailsV1
  reportingProvidersDetails?: ReportingProviderDetailsV1[] | null
  taxStatusDetails?: TaxStatusDetailsResponseV1
  updatedAt: string
  version: number
} & (
  | (TaxExemptOrganizationAccountV1AllOf & {
      accountCategory: TaxExemptOrganizationAccountV1AccountCategory
    })
  | (IndividualAccountV1AllOf & {
      accountCategory: IndividualAccountV1AccountCategory
    })
  | (NonIndividualAccountV1AllOf & {
      accountCategory: NonIndividualAccountV1AccountCategory
    })
)

export type TaxExemptOrganizationAccountV1 = AccountV1 &
  TaxExemptOrganizationAccountV1AllOf & {
    accountCategory: TaxExemptOrganizationAccountV1AccountCategory
  }

export type NonIndividualAccountV1 = AccountV1 &
  NonIndividualAccountV1AllOf & {
    accountCategory: NonIndividualAccountV1AccountCategory
  }

export type IndividualAccountV1 = AccountV1 &
  IndividualAccountV1AllOf & {
    accountCategory: IndividualAccountV1AccountCategory
  }

export type TaxExemptOrganizationV1AccountCategory =
  (typeof TaxExemptOrganizationV1AccountCategory)[keyof typeof TaxExemptOrganizationV1AccountCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxExemptOrganizationV1AccountCategory = {
  TAX_EXEMPT_ORGANIZATION: 'TAX_EXEMPT_ORGANIZATION',
} as const

export type TaxExemptOrganizationV1AllOf = {
  accreditedInvestorStatuses?: NonIndividualAccreditedInvestorStatusesV1
  investmentEntities?: InvestmentEntitiesV1
  /** A nominee account is a type of account where an individual or entity holds securities on behalf of the actual owner. */
  isNominee?: boolean | null
  primarySignerId?: string
  qualifiedClientStatuses?: NonIndividualQualifiedClientStatusesV1
  qualifiedEligiblePersonStatuses?: NonIndividualQualifiedEligiblePersonStatusesV1
  qualifiedPurchaserStatuses?: NonIndividualQualifiedPurchaserStatusesV1
  subType?: TaxExemptOrganizationSubTypeV1
}

export type TaxExemptOrganizationV1 = BaseAccountV1 &
  TaxExemptOrganizationV1AllOf & {
    accountCategory: TaxExemptOrganizationV1AccountCategory
  }

export type NonIndividualV1AccountCategory =
  (typeof NonIndividualV1AccountCategory)[keyof typeof NonIndividualV1AccountCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NonIndividualV1AccountCategory = {
  NON_INDIVIDUAL: 'NON_INDIVIDUAL',
} as const

export type NonIndividualV1AllOf = {
  accreditedInvestorStatuses?: NonIndividualAccreditedInvestorStatusesV1
  investmentEntities?: InvestmentEntitiesV1
  /** A nominee account is a type of account where an individual or entity holds securities on behalf of the actual owner. */
  isNominee?: boolean | null
  primarySigners?: PrimarySignersV1
  qualifiedClientStatuses?: NonIndividualQualifiedClientStatusesV1
  qualifiedEligiblePersonStatuses?: NonIndividualQualifiedEligiblePersonStatusesV1
  qualifiedPurchaserStatuses?: NonIndividualQualifiedPurchaserStatusesV1
  subType?: NonIndividualAccountSubTypeV1
  /** Required when subType is Other */
  subTypeDescription?: string | null
}

export type NonIndividualV1 = BaseAccountV1 &
  NonIndividualV1AllOf & {
    accountCategory: NonIndividualV1AccountCategory
  }

export type IndividualV1AccountCategory =
  (typeof IndividualV1AccountCategory)[keyof typeof IndividualV1AccountCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IndividualV1AccountCategory = {
  INDIVIDUAL: 'INDIVIDUAL',
} as const

/**
 * The plan type is relevant for KEOGH_PLAN accounts only. 
  When subType is KEOGH_PLAN, this field should contain one of the following values:
  * KEOGH_PROFIT_SHARING_PLAN 
  * KEOGH_DEFINED_BENEFIT_PLAN
  * KEOGH_MONEY_PURCHASE_PLAN

 */
export type IndividualV1AllOfPlanType =
  | (typeof IndividualV1AllOfPlanType)[keyof typeof IndividualV1AllOfPlanType]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IndividualV1AllOfPlanType = {
  KEOGH_PROFIT_SHARING_PLAN: 'KEOGH_PROFIT_SHARING_PLAN',
  KEOGH_DEFINED_BENEFIT_PLAN: 'KEOGH_DEFINED_BENEFIT_PLAN',
  KEOGH_MONEY_PURCHASE_PLAN: 'KEOGH_MONEY_PURCHASE_PLAN',
} as const

export type IndividualV1AllOf = {
  accreditedInvestorStatuses?: IndividualAccreditedInvestorStatusesV1
  clients?: ClientsV1
  /** The plan name is relevant for KEOGH_PLAN and OTHER_SELF_DIRECTED_RETIREMENT_ACCOUNT accounts only.
   */
  planName?: string | null
  /** The plan type is relevant for KEOGH_PLAN accounts only. 
  When subType is KEOGH_PLAN, this field should contain one of the following values:
  * KEOGH_PROFIT_SHARING_PLAN 
  * KEOGH_DEFINED_BENEFIT_PLAN
  * KEOGH_MONEY_PURCHASE_PLAN
 */
  planType?: IndividualV1AllOfPlanType
  qualifiedClientStatuses?: IndividualQualifiedClientStatusesV1
  qualifiedEligiblePersonStatuses?: IndividualQualifiedEligiblePersonStatusesV1
  qualifiedPurchaserStatuses?: IndividualQualifiedPurchaserStatusesV1
  subType?: IndividualAccountSubTypeV1
}

export type BaseAccountV1 = {
  brokerageDetails?: AccountBrokerageDetailsV1
  custodianDetails?: AccountCustodianDetailsV1
  externalId?: string | null
  feeStructure?: FeeStructureV1
  fundAdministratorsDetails?: FundAdministratorDetailsV1[] | null
  investorType?: InvestorTypeV1
  reportingProviderDetails?: ReportingProviderDetailsV1
  /**
   * Use reportingProviderDetails instead
   * @deprecated
   */
  reportingProvidersDetails?: ReportingProviderDetailsV1[] | null
  taxStatusDetails?: TaxStatusDetailsV1
} & (
  | (TaxExemptOrganizationV1AllOf & {
      accountCategory: TaxExemptOrganizationAccountV1AccountCategory
    })
  | (IndividualV1AllOf & {
      accountCategory: IndividualAccountV1AccountCategory
    })
  | (NonIndividualV1AllOf & {
      accountCategory: NonIndividualAccountV1AccountCategory
    })
)

export type IndividualV1 = BaseAccountV1 &
  IndividualV1AllOf & {
    accountCategory: IndividualV1AccountCategory
  }

export type AccountTypesV1ItemSubTypesItem = {
  label: string
  name: string
}

export type AccountTypesV1Item = {
  /** Label for this account type. */
  label: string
  /** Account type name. */
  name: string
  subTypes: AccountTypesV1ItemSubTypesItem[]
}

/**
 * Account Types.
 */
export type AccountTypesV1 = AccountTypesV1Item[]

/**
 * Represents one page of contacts from a list of contacts.
 */
export type PagedContactListItemsV1 = {
  items: ContactListItemV1[]
  pageInfo: PageInfo
}

export type ContactsSearchRequestV1SortingItem =
  (typeof ContactsSearchRequestV1SortingItem)[keyof typeof ContactsSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactsSearchRequestV1SortingItem = {
  CREATED_AT_ASC: 'CREATED_AT_ASC',
  CREATED_AT_DESC: 'CREATED_AT_DESC',
  UPDATED_AT_ASC: 'UPDATED_AT_ASC',
  UPDATED_AT_DESC: 'UPDATED_AT_DESC',
  FIRST_NAME_ASC: 'FIRST_NAME_ASC',
  FIRST_NAME_DESC: 'FIRST_NAME_DESC',
  MIDDLE_INITIAL_ASC: 'MIDDLE_INITIAL_ASC',
  MIDDLE_INITIAL_DESC: 'MIDDLE_INITIAL_DESC',
  LAST_NAME_ASC: 'LAST_NAME_ASC',
  LAST_NAME_DESC: 'LAST_NAME_DESC',
  EMAIL_ASC: 'EMAIL_ASC',
  EMAIL_DESC: 'EMAIL_DESC',
} as const

/**
 * Defines the filters, paging and sorting to use for a Contact search
 */
export type ContactsSearchRequestV1 = {
  filter?: ContactFilterV1
  pageRequest?: PageRequest
  sorting?: ContactsSearchRequestV1SortingItem[] | null
}

/**
 * Collects the data for an account, along with the roles contact has on it.
 */
export type AccountListItemForContactV1 = {
  account: AccountV1
  contactRoles: AccountRoleV1[]
}

export type UpdateContactRequestV1 = ContactRequestV1

/**
 * Represents one page of households from a list of households.
 */
export type PagedHouseholdsV1 = {
  items: HouseholdResponseV1[]
  pageInfo: PageInfo
}

export type HouseholdsSearchRequestV1SortingItem =
  (typeof HouseholdsSearchRequestV1SortingItem)[keyof typeof HouseholdsSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HouseholdsSearchRequestV1SortingItem = {
  CREATED_AT_ASC: 'CREATED_AT_ASC',
  CREATED_AT_DESC: 'CREATED_AT_DESC',
  UPDATED_AT_ASC: 'UPDATED_AT_ASC',
  UPDATED_AT_DESC: 'UPDATED_AT_DESC',
  HOUSEHOLD_NAME_ASC: 'HOUSEHOLD_NAME_ASC',
  HOUSEHOLD_NAME_DESC: 'HOUSEHOLD_NAME_DESC',
} as const

/**
 * Defines the filters, paging and sorting to use for a Household search
 */
export type HouseholdsSearchRequestV1 = {
  filter?: HouseholdFilterV1
  pageRequest?: PageRequest
  sorting?: HouseholdsSearchRequestV1SortingItem[] | null
}

export type CreateContactRequestV1 = ContactRequestV1

export type ContactResponseV1EmployerAddress =
  | AddressResponseV1
  | NullableReferenceV1

export type ContactResponseV1Address = AddressResponseV1 | NullableReferenceV1

/**
 * Response object for a contact entity
 */
export type ContactResponseV1 = {
  address?: ContactResponseV1Address
  /** may be null in old Kafka events; otherwise, should never be null */
  caisId?: string | null
  citizenship?: CountriesResponseV1
  /** Date time that this contact was created. */
  createdAt: string
  /** Contact's date of birth. */
  dateOfBirth?: string | null
  /** Contact's email address. */
  email?: string | null
  employerAddress?: ContactResponseV1EmployerAddress
  /** Contact's employer name. */
  employerName?: string
  /** Contact's first name. */
  firstName: string
  /** Contact's household id. */
  householdId: string
  /** Unique id of contact. */
  id: string
  /** Whether or not this version of the contact is that latest one. */
  isLatest: boolean
  jobTitle?: string | null
  /** Contact's last name. */
  lastName: string
  maritalStatus?: MaritalStatusResponseV1
  /** Contact's middle initial. */
  middleInitial?: string | null
  /** Contact's phone number. */
  phoneNumber?: string | null
  /** Contact's role or position at employer's company. */
  positionRole?: string
  /** Contact's social security number. */
  ssn?: string | null
  /** Contact's title. */
  title?: string | null
  /** Date time that this contact was last updated. */
  updatedAt: string
  /** Version number for this contact record. */
  version: number
}

/**
 * Move a household to different Teams
 */
export type MoveHouseholdRequestV1 = {
  /** The UUID of the new firm to which this household belongs. */
  firmId: string
  /** The UUIDs of the teams granted access to this household. These teams must belong to the same firm (not necessary the original firm associated to the household). Users are only able to access this household (and its constituent accounts and contacts) if they are a member of one of these teams, unless they have the read-all permission. */
  teamIds: string[]
}

/**
 * Update a household
 */
export type UpdateHouseholdRequestV1 = {
  /** The name of the household */
  name: string
  /** The UUIDs of the teams granted access to this household. These teams must belong to the firm containing the household. Users are only able to access this household (and its constituent accounts and contacts) if they are a member of one of these teams, unless they have the read-all permission. */
  teamIds: string[]
}

export type ErrorResponseErrorAttributes = {
  violations?: Violations
} & {
  [key: string]: string | null
}

export type ErrorResponse = {
  displayMessage?: string | null
  errorAttributes?: ErrorResponseErrorAttributes
  errorCode?: string | null
  errorMessage?: string
  errorUri?: string
  status?: number
}

/**
 * Response object containing a Household
 */
export type HouseholdResponseV1 = {
  /** may be null in old Kafka events; otherwise, should never be null */
  caisId?: string | null
  /** Date time the household was created. */
  createdAt: string
  /** Unique identifier used by the client. */
  externalId?: string | null
  /** The UUID of the firm to which this household belongs. */
  firmId: string
  /** Unique identifier for this household. */
  id: string
  /** The name of the household */
  name: string
  /** The UUIDs of the teams granted access to this household. These teams must belong to the firm containing the household. Users are only able to access this household (and its constituent accounts and contacts) if they are a member of one of these teams, unless they have the read-all permission. */
  teamIds: string[]
  /** Date time the household was last updated. */
  updatedAt: string
}

/**
 * Create a new household
 */
export type CreateHouseholdRequestV1 = {
  /** Unique identifier used by the client. */
  externalId?: string | null
  /** The UUID of the firm to which this household belongs. */
  firmId: string
  /** The name of the household */
  name: string
  /** The UUIDs of the teams granted access to this household. These teams must belong to the firm containing the household. Users are only able to access this household (and its constituent accounts and contacts) if they are a member of one of these teams, unless they have the read-all permission. */
  teamIds: string[]
}

/**
 * category: 
  * A - "An organization exempt from tax under section 501(a) or any individual retirement plan as defined in section 7701(a)(37)"
  * B - "The United States or any of its agencies or instrumentalities C—A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities"
  * C - "A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities"
  * D - "A corporation the stock of which is regularly traded on one or more established securities markets, as described in Regulations section 1.1472-1(c)(1)(i)"
  * E - "A corporation that is a member of the same expanded affiliated group as a corporation described in Regulations section 1.1472-1(c)(1)(i)"
  * F - "A dealer in securities, commodities, or derivative financial instruments (including notional principal contracts, futures, forwards, and options) that is registered as such under the laws of the United States or any state"
  * G - "A real estate investment trust"
  * H - "A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment Company Act of 1940A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment Company Act of 1940"
  * I - "A common trust fund as defined in section 584(a)"
  * J - "A bank as defined in section 581"
  * K - "A broker"
  * L - "A trust exempt from tax under section 664 or described in section 4947(a)(1)"
  * M - "A tax exempt trust under a section 403(b) plan or section 457(g) plan"

 */
export type ExemptionFromFatcaCodeV1 =
  | (typeof ExemptionFromFatcaCodeV1)[keyof typeof ExemptionFromFatcaCodeV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExemptionFromFatcaCodeV1 = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
} as const

/**
 * category: 
  * 1 - "An organization exempt from tax under section 501(a), any IRA, or a custodial account under section 403(b)(7) if the account satisfies the requirements of section 401(f)(2)"
  * 2 - "The United States or any of its agencies or instrumentalities"
  * 3 - "A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities"
  * 4 - "A foreign government or any of its political subdivisions, agencies, or instrumentalities"
  * 5 - "A corporation"
  * 6 - "A dealer in securities or commodities required to register in the United States, the District of Columbia, or a U.S. commonwealth or possession"
  * 7 - "A futures commission merchant registered with the Commodity Futures Trading Commission"
  * 8 - "A real estate investment trust"
  * 9 - "An entity registered at all times during the tax year under the Investment Company Act of 1940"
  * 10 - "A common trust fund operated by a bank under section 584(a)"
  * 11 - "A financial institution"
  * 12 - "A middleman known in the investment community as a nominee or custodian"
  * 13 - "A trust exempt from tax under section 664 or described in section 4947"

 */
export type ExemptPayeeCodeV1 =
  | (typeof ExemptPayeeCodeV1)[keyof typeof ExemptPayeeCodeV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExemptPayeeCodeV1 = {
  NUMBER_1: '1',
  NUMBER_2: '2',
  NUMBER_3: '3',
  NUMBER_4: '4',
  NUMBER_5: '5',
  NUMBER_6: '6',
  NUMBER_7: '7',
  NUMBER_8: '8',
  NUMBER_9: '9',
  NUMBER_10: '10',
  NUMBER_11: '11',
  NUMBER_12: '12',
  NUMBER_13: '13',
} as const

/**
 * category: 
  * INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC - "Individual/sole proprietor or single-member LLC (for individuals and Disregarded Entities)"
  * C_CORPORATION - "C Corporation"
  * S_CORPORATION - "S Corporation"
  * PARTNERSHIP - "Partnership"
  * TRUST_ESTATE - "Trust/estate (for trusts which are not disregarded)"
  * LLC - "Limited Liability Company (for LLCs which are not disregarded)"
  * OTHER - "Other"

 */
export type TaxClassificationV1 =
  | (typeof TaxClassificationV1)[keyof typeof TaxClassificationV1]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxClassificationV1 = {
  INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC:
    'INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC',
  C_CORPORATION: 'C_CORPORATION',
  S_CORPORATION: 'S_CORPORATION',
  PARTNERSHIP: 'PARTNERSHIP',
  TRUST_ESTATE: 'TRUST_ESTATE',
  LLC: 'LLC',
  OTHER: 'OTHER',
} as const

/**
 * A KYC Document event.
 */
export type KycDocumentEventV1 = Envelope & {
  payload: KycDocumentEventPayloadV1
}

/**
 * An account event.
 */
export type AccountEventV1 = Envelope & {
  payload: AccountEventPayloadV1
}

/**
 * A household event.
 */
export type HouseholdEventV1 = Envelope & {
  payload: HouseholdEventPayloadV1
}

/**
 * A contact event.
 */
export type ContactEventV1 = Envelope & {
  payload: ContactEventPayloadV1
}
