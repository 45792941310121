import { useMutation } from 'react-query'

import { defaultPersister } from '@cais-group/caisiq/util/default-query-function'
import {
  useApiPathWithParams,
  useApiPathWithPayload,
} from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import {
  ApiPaths,
  CreateExperienceRequest,
  ExperienceResponse,
} from '@cais-group/shared/util/type/caisiq-be'

export function usePutExperience(version: number | undefined) {
  const parsedPath = useApiPathWithParams(ApiPaths.updateExperience)
  const getParsedEndpoint = useApiPathWithPayload(parsedPath)

  return useMutation((data: CreateExperienceRequest) =>
    defaultPersister<CreateExperienceRequest, ExperienceResponse>(
      getParsedEndpoint(data),
      data,
      version,
      'PUT'
    )
  )
}
