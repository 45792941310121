import { Children, ReactNode } from 'react'

import { Avatar } from '@cais-group/equity/atoms/avatar'
import { Icon } from '@cais-group/equity/atoms/icon'

import { OptionType } from '../select'

export interface MultiValueProps<T> {
  selectedOption: OptionType<T> | null
  onRemove: () => void
}

export const DefaultMultiValue = <T extends { label?: string }>({
  selectedOption,
  onRemove,
}: MultiValueProps<T>) => {
  return (
    <div className="bodySmall flex max-w-full shrink items-center bg-neutral-100 pl-8 text-neutral-900">
      <span className="overflow-hidden break-words">
        {selectedOption?.label ?? selectedOption?.value}
      </span>
      <button
        className="flex cursor-pointer items-center"
        data-testid="remove-select-item"
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onRemove()
        }}
      >
        <Icon size="small" type="Close" color="eq-color-neutral-300" />
      </button>
    </div>
  )
}

export const GroupMultiValue = ({
  selectedOption,
  onRemove,
}: MultiValueProps<{ groupName: string }>) => {
  return (
    <div className="flex items-center bg-neutral-100">
      <Avatar type="group">{selectedOption?.groupName}</Avatar>
      <div className="flex flex-col px-8">
        <p className="bodySmall text-neutral-900">
          {selectedOption?.groupName}
        </p>
      </div>
      <button
        className="flex cursor-pointer items-center"
        data-testid="remove-select-item"
        onClick={(event) => {
          event.stopPropagation()
          onRemove()
        }}
      >
        <Icon size="small" type="Close" color="eq-color-neutral-300" />
      </button>
    </div>
  )
}

export interface ValueContainerProps {
  children: ReactNode
}
export const DefaultValueContainer = ({ children }: ValueContainerProps) => {
  return Children.count(children) ? (
    <div className="bodySmall flex w-full flex-wrap gap-8 text-neutral-500">
      {children}
    </div>
  ) : null
}
