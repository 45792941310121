import { Icon } from '@cais-group/equity/atoms/icon'
import { Tooltip } from '@cais-group/equity/atoms/tooltip'

export const CourseTableTooltip = ({ title }: { title: string }) => {
  return (
    <Tooltip title={title} placement="top">
      <div className="w-fit cursor-pointer justify-self-center text-center">
        <Icon size="small" type="InfoOutline" color="eq-color-neutral-600" />
      </div>
    </Tooltip>
  )
}
