import '@tanstack/table-core'
import * as CSS from 'csstype'

declare module '@tanstack/table-core' {
  // This is how we extend ColumnMeta. See https://tanstack.com/table/v8/docs/api/core/column-def#meta
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    headerCellStyle?: CSS.Properties
    rowCellStyle?: CSS.Properties
    fixed?: boolean
    stickyLeftPx?: number
    stickyRightPx?: number
    ratio?: number
    alignment?: CSS.Property.JustifyContent
    tooltip?: React.ReactElement
    disableLink?: boolean
  }
}
