import { cx } from '@emotion/css'

import { cloudinaryUrl } from '@cais-group/shared/ui/cloudinary-image'
import { ContentfulImage } from '@cais-group/shared/ui/contentful-image'
import {
  millisecondsToMinutes,
  secondsToMinutes,
} from '@cais-group/shared/util/js/format-length'
import { publishedDateFormat } from '@cais-group/shared/util/time/date-time-contentful'
import { TypeVideoData } from '@cais-group/shared/util/type/video-data'

export type PlaylistItemProps = {
  video: TypeVideoData
  active?: boolean
  episodeNumber: number
  onVideoSelected?: (video: TypeVideoData) => void
  theme?: 'dark' | 'light'
}

export const PlaylistItem = ({
  video,
  episodeNumber,
  active,
  onVideoSelected,
  theme,
}: PlaylistItemProps) => {
  const date = video.displayDate ?? video.sys.firstPublishedAt ?? ''
  const duration = video?.muxVideo?.duration
    ? secondsToMinutes(video.muxVideo.duration, 'round')
    : millisecondsToMinutes(video.duration ?? 0, 'round')

  const posterOrThumbnailUrl =
    Array.isArray(video.poster) && video.poster.length
      ? cloudinaryUrl(video.poster[0].public_id)
      : video.thumbnail?.url || ''

  const posterOrThumbnailWidth =
    Array.isArray(video.poster) && video.poster.length
      ? video.poster[0].width || 120
      : video.thumbnail?.width || 120

  return (
    <li
      data-testid="playlist-video-item"
      onClick={() => onVideoSelected && onVideoSelected(video)}
    >
      <div
        className={cx(
          'relative isolate flex cursor-pointer gap-16 overflow-hidden p-16 focus-within:outline focus-within:outline-2',
          active
            ? theme === 'dark'
              ? 'bg-brand-900 text-neutral-400'
              : 'bg-neutral-100 text-neutral-900'
            : theme === 'dark'
            ? 'bg-neutral-900 text-neutral-500'
            : ''
        )}
      >
        {/**
         *  @todo HP Does this need to allow for non contentful images?
         */}
        <div className="flex aspect-video w-full min-w-[120px] basis-2/5  overflow-hidden md:basis-1/5 lg:basis-1/3">
          <ContentfulImage
            src={posterOrThumbnailUrl}
            width={posterOrThumbnailWidth}
            aspectRatio={16 / 9}
            background="auto"
            alt=""
          />
        </div>
        <div className="flex basis-3/5 flex-col gap-8 text-left text-sm md:basis-4/5 lg:basis-2/3">
          {episodeNumber ? (
            <p className="overtext">Episode {episodeNumber}</p>
          ) : null}
          <p
            className={cx(
              'small line-clamp-2',
              theme === 'dark' ? 'text-neutral-0' : 'text-neutral-900'
            )}
          >
            {video.title}
          </p>
          <p className="caption-mono text-neutral-600">
            <time dateTime={date}>{publishedDateFormat(date, true)}</time>
            {` • ${duration} min`}
          </p>
        </div>
      </div>

      {theme === 'dark' && (
        <div className="border-1 ml-16 border-t-neutral-600 lg:mx-16" />
      )}
    </li>
  )
}
