import cx from 'classnames'
import * as React from 'react'
import { DeepPartial, StrictExtract } from 'ts-essentials'

import { Icon } from '@cais-group/equity/atoms/icon'
import {
  AllColor,
  EquitySemanticColor,
  allColors,
} from '@cais-group/equity/particles/colors'
import { IconType } from '@cais-group/equity/particles/icons'

export type StatusTagVariant = 'dark' | 'regular' | 'light' | 'ghost'

export type SemanticColor = StrictExtract<
  EquitySemanticColor,
  'neutral' | 'primary' | 'accent' | 'success' | 'error' | 'warning'
>
interface AttributeColor {
  borderColor: AllColor
  backgroundColor: AllColor
  textColor: AllColor
}

const getAttributeColors = (
  color: SemanticColor,
  variant: StatusTagVariant
): AttributeColor => {
  const defaultColors: Record<StatusTagVariant, AttributeColor> = {
    dark: {
      borderColor: `eq-color-${color}-800`,
      backgroundColor: `eq-color-${color}-800`,
      textColor: `eq-color-${color}-800-c`,
    },
    regular: {
      borderColor: `eq-color-${color}-600`,
      backgroundColor: `eq-color-${color}-600`,
      textColor: `eq-color-${color}-600-c`,
    },
    light: {
      borderColor: `eq-color-${color}-200`,
      backgroundColor: `eq-color-${color}-100`,
      textColor: `eq-color-${color}-600`,
    },
    ghost: {
      borderColor: `eq-color-${color}-600`,
      backgroundColor: `eq-color-${color}-600-c`,
      textColor: `eq-color-${color}-600`,
    },
  }
  const specificCases: DeepPartial<
    Record<`${SemanticColor}-${StatusTagVariant}`, AttributeColor>
  > = {
    'warning-light': {
      borderColor: `eq-color-warning-400`,
      textColor: `eq-color-warning-800`,
    },
    'warning-ghost': {
      backgroundColor: `eq-color-warning-800-c`,
      textColor: `eq-color-warning-800`,
    },
  }
  return {
    ...defaultColors[variant],
    ...(specificCases[`${color}-${variant}`] ?? {}),
  }
}

export type StatusTagProps = {
  /** The text to be displayed */
  children: string
  /** Optional property to set which variant to be used */
  variant?: StatusTagVariant
  /** Optional equity semantic color */
  color?: SemanticColor
  /** Optional property to allow using the whole container width */
  grow?: boolean
  /** Optional property for testing purpose */
  'data-testid'?: string
  /** Optional Icon shown before the text */
  startAdornment?: IconType
  /** Optional Icon shown after the text */
  endAdornment?: IconType
}

/**
 * A status tag is a read-only label that appears beside an item to represent the status or metadata for that area.
 */
export const StatusTag = ({
  children,
  variant = 'regular',
  color = 'neutral',
  grow = false,
  startAdornment,
  endAdornment,
  'data-testid': testId,
}: StatusTagProps) => {
  const colors = getAttributeColors(color, variant)

  return (
    <span
      data-testid={testId}
      className={cx(
        'small-strong flex h-24 items-center justify-center rounded-full border border-solid px-8',
        { 'max-w-fit': !grow }
      )}
      style={{
        backgroundColor: allColors[colors.backgroundColor],
        borderColor: allColors[colors.borderColor],
        color: allColors[colors.textColor],
      }}
    >
      {startAdornment ? (
        <Icon size="small" type={startAdornment} color={colors.textColor} />
      ) : null}
      <span className="overflow-hidden text-ellipsis whitespace-nowrap px-8">
        {children}
      </span>
      {endAdornment ? (
        <Icon size="small" type={endAdornment} color={colors.textColor} />
      ) : null}
    </span>
  )
}
