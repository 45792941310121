import { useHasAttribute } from '@cais-group/shared/ui/feature-flag'

// TODO HP-? (future PR): This can be removed once the new homepage experience is fully rolled out

export const useNewHomepageExperience = () => {
  const { hasAccess, status } = useHasAttribute('cais.new_homepage_enabled')

  if (status === 'loaded') {
    return hasAccess
  }

  return false
}
