import cx from 'classnames'
import React, { forwardRef, PropsWithChildren, ReactNode } from 'react'

export interface RootProps {
  id?: string
  children: ReactNode
  isOpen?: boolean
  disabled?: boolean
}

export const DefaultRoot = forwardRef<HTMLDivElement, RootProps>(
  ({ id, isOpen, children, disabled, ...rest }, ref) => {
    return (
      <div
        id={id}
        ref={ref}
        className={cx(
          // Note: 11px vertical padding, so it matches design
          'bg-neutral-0 relative flex items-center gap-x-8 border border-solid py-[11px] pl-16 pr-8',
          {
            'border-primary-600': isOpen,
            'focus-within:border-primary-600 border-neutral-200': !isOpen,
            'cursor-not-allowed bg-neutral-100 text-neutral-500': disabled,
          }
        )}
        {...rest}
      >
        {children}
      </div>
    )
  }
)

export type WrapperProps = PropsWithChildren

export const DefaultWrapper = ({ children }: WrapperProps) => (
  <div className="relative">{children}</div>
)
