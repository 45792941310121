import styled from '@emotion/styled'
import dayjs from 'dayjs'

import {
  H5,
  SubtitleMono,
  shouldForwardProp,
  BodyStrongCss,
} from '@cais-group/approved/ui/typography'
import { ArticleHeader, PageType } from '@cais-group/caisiq/ui/article/header'
import { Author } from '@cais-group/caisiq/ui/author'
import { BannerWithButtonContainer } from '@cais-group/caisiq/ui/banner-with-button'
import { ContentStack } from '@cais-group/caisiq/ui/content-stack'
import { Footnotes } from '@cais-group/caisiq/ui/footnotes'
import { RichText } from '@cais-group/caisiq/ui/get-rich-text-render-options'
import { HeroVideoHeader } from '@cais-group/caisiq/ui/hero/video-header'
import { colors } from '@cais-group/equity/particles/colors'
import { Accordion } from '@cais-group/shared/ui/accordion'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { LayoutBox, LayoutCenter } from '@cais-group/shared/ui/layout'
import {
  CaisiqArticleDropdown,
  TypeArticleData,
} from '@cais-group/shared/util/type/article-data'
import { TypeCallToActionData } from '@cais-group/shared/util/type/call-to-action-data'

import { LicenceFormContainer } from './LicenceForm/LicenceForm'

type StyledCenterProps = {
  $isVideo?: boolean
  $hasImage: boolean
  $isCeGuide?: boolean
}
export const StyledCenter = styled(LayoutCenter, {
  shouldForwardProp: shouldForwardProp(['$hasImage', '$isVideo', '$isCeGuide']),
})<StyledCenterProps>`
  display: grid;
  ${({ $isVideo }) => ($isVideo ? `display: inherit;` : 'display: grid;')}
  grid-template-columns: clamp(16rem, calc(96vw - 2 * var(--s24)), 104rem) 1fr;
  ${({ $isCeGuide }) =>
    $isCeGuide ? `padding-bottom: 0;` : 'padding-bottom: var(--s56);'}
  article p:first-of-type {
    margin-top: 0;
  }

  @media screen and (min-width: 960px) {
    ${({ $hasImage }) =>
      $hasImage
        ? `article p:first-of-type {
              margin-top: var(--s56);
          }`
        : null}
  }
`

const PageContainer = styled.div`
  background-color: ${colors['eq-color-neutral-0']};
  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

const StyledBox = styled(LayoutBox)`
  margin-left: var(--s24);
  margin-right: var(--s24);
`

const Introduction = styled(H5, {
  shouldForwardProp: shouldForwardProp('$hasImage'),
})<{ $hasImage: boolean }>`
  margin-bottom: var(--s24);
  ${({ $hasImage }) => ($hasImage ? `margin-top: var(--s56)` : 'margin-top: 0')}
`

const Wrapper = styled.div`
  width: calc(100vw - var(--s24));
  display: flex;
  flex-direction: column;
  padding-bottom: var(--s56);
  margin-top: var(--s56);
  padding-left: var(--s12);
  padding-right: var(--s24);
  @media screen and (min-width: 960px) {
    width: 100%;
    padding-left: var(--s56);
    padding-right: var(--s56);
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

const AuthorContainer = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    margin-top: var(--s56);
    margin-right: var(--s56);
    display: inline;
    width: 320px;
    margin-left: -28px;
    > * {
      margin-bottom: var(--s24);
    }
  }
  @media screen and (min-width: 1280px) {
    width: 320px;
    margin-left: var(--s88);
  }
`

const BodyAuthor = styled.div`
  display: inline;
  > * {
    margin-bottom: var(--s24);
  }
  @media screen and (min-width: 1024px) {
    display: none;
  }
`

const AuthorContainerTitle = styled(SubtitleMono)`
  color: rgb(var(--colors-neutral-600));
  border: solid rgb(var(--colors-neutral-300));
  border-width: 1px 0;
  padding: calc(var(--s24) - var(--s4)) 0;
  margin-bottom: var(--s32);
`

const SEGMENT_PAGE_ID = 'article'

const StyledAccordion = styled(Accordion)`
  .MuiAccordionSummary-expandIconWrapper {
    color: rgb(var(--colors-primary-600));
  }
  .MuiAccordionSummary-content {
    color: rgb(var(--colors-primary-600));
    > p {
      ${BodyStrongCss}
    }
  }
  .MuiAccordionSummary-root {
    height: var(--s56);
  }
  .MuiAccordionDetails-root {
    > ul > li {
      margin-top: var(--s24) !important;
    }
    > p:not(:first-of-type) {
      margin-top: var(--s24) !important;
    }
  }
`

const GuideFormContainer = styled.div`
  margin-bottom: var(--s144);
  display: flex;
  align-self: center;
  max-width: 740px;

  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    justify-content: center;
    width: 100%;
    > div:first-of-type {
      width: 100%;
    }
    padding: 0 var(--s24);
  }
`

type AccordionsProps = {
  accordions: CaisiqArticleDropdown[]
}
export const Accordions = ({ accordions }: AccordionsProps) => {
  return (
    <div>
      {accordions.map((accordion, i) => (
        <StyledAccordion
          key={`accordion-${i}`}
          summaryFlexDirection="row"
          noPadding
          testId="guide"
          children={<RichText field={accordion?.body} />}
          summary={<RichText field={accordion?.title} />}
        />
      ))}
    </div>
  )
}

export type ArticlePageProps = {
  article: TypeArticleData
  isCeGuide: boolean
  joinCaisBanner?: TypeCallToActionData
  typePage: Omit<PageType, 'Presentation' | 'Whitepaper'>
  cimaNumber: string
  cfpNumber: string
  isFetched: boolean
}
function ArticlePage({
  article,
  isCeGuide,
  joinCaisBanner,
  typePage,
  cimaNumber,
  cfpNumber,
  isFetched,
}: ArticlePageProps) {
  const {
    hero,
    title,
    introduction,
    author,
    displayDate,
    sys,
    body,
    footnotes,
    accordionsCollection,
    caisVideo,
  } = article
  const date = dayjs(displayDate || sys.firstPublishedAt).format('MMM D')
  const hasRichBody = body && body?.json
  const hasRichFootnotes = footnotes && footnotes?.json
  const hasFullAuthor = Boolean(author.authorName)

  // TODO: This logic can just check for a caisVideo once the muxVideo migration is complete
  const isVideo = hero?.url?.includes('videos') || Boolean(caisVideo)

  return (
    <PageContainer>
      {isVideo && (
        <HeroVideoHeader
          url={hero?.url ?? ''}
          video={caisVideo ?? undefined}
          title={title}
          author={author.name ?? 'CAIS'}
          date={date}
        />
      )}
      {!isVideo && (
        <ArticleHeader
          image={hero?.url ? `${hero.url}?fm=jpg` : ''}
          title={title}
          author={author.name}
          date={date}
          typePage={typePage as PageType}
          hasFullAuthor={hasFullAuthor}
        />
      )}

      <StyledCenter
        $isVideo={isVideo}
        $hasImage={!!hero?.url}
        $isCeGuide={isCeGuide}
      >
        <Wrapper>
          {!(isVideo || isCeGuide) && (
            <AuthorContainer>
              {hero?.url && hasFullAuthor && (
                <>
                  <AuthorContainerTitle>Authored by</AuthorContainerTitle>
                  <Author {...author} />
                </>
              )}
            </AuthorContainer>
          )}
          <ContentStack
            fakeColumn={!(isVideo || isCeGuide)}
            variant="richtext"
            data-testid="article-stack"
          >
            {introduction && (
              <Introduction $hasImage={!!hero?.url}>
                {introduction}
              </Introduction>
            )}
            {hasRichBody && (
              <RichText field={body} segmentPageId={SEGMENT_PAGE_ID} />
            )}
            {!isVideo && (
              <BodyAuthor>
                {hero?.url && hasFullAuthor && (
                  <>
                    <AuthorContainerTitle>Authored by</AuthorContainerTitle>
                    <Author {...author} />
                  </>
                )}
              </BodyAuthor>
            )}
            {accordionsCollection && accordionsCollection.items.length > 0 ? (
              <Accordions accordions={accordionsCollection.items} />
            ) : null}
            {hasRichFootnotes && (
              <Footnotes>
                <RichText field={footnotes} segmentPageId={SEGMENT_PAGE_ID} />
              </Footnotes>
            )}
          </ContentStack>
        </Wrapper>
      </StyledCenter>

      {isCeGuide && isFetched && (
        <GuideFormContainer>
          <LicenceFormContainer
            title="Enter your credential numbers to get started."
            description="You can enter one or both credential numbers."
            cimaNumber={cimaNumber}
            cfpNumber={cfpNumber}
          />
        </GuideFormContainer>
      )}

      {joinCaisBanner && (
        <StyledBox>
          <BannerWithButtonContainer />
        </StyledBox>
      )}
    </PageContainer>
  )
}

ArticlePage.displayName = 'ArticlePage'

export { ArticlePage }
