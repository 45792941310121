import { FlagsProvider } from 'flagged'
import { ReactElement } from 'react'

import { CaisiqEnvConfig, useConfig } from '@cais-group/caisiq-ui-env'

const extractFeatureFlags = (config: CaisiqEnvConfig) => {
  const hasFeatureFlags = (obj: CaisiqEnvConfig) =>
    obj?.['FEATURE_FLAGS'] !== undefined

  if (
    hasFeatureFlags(config) &&
    config.FEATURE_FLAGS &&
    typeof config.FEATURE_FLAGS === 'object'
  ) {
    const flags = config.FEATURE_FLAGS

    return Object.keys(flags).reduce((acc, key) => {
      return typeof flags[key as keyof typeof flags] === 'boolean'
        ? { ...acc, [key]: flags[key as keyof typeof flags] }
        : acc
    }, {})
  }

  return {}
}

interface FlaggedProviderProps {
  children: ReactElement
}
export const FlaggedProvider = ({ children }: FlaggedProviderProps) => {
  const config = useConfig()
  const featureFlags = extractFeatureFlags(config)

  return <FlagsProvider features={featureFlags}>{children}</FlagsProvider>
}
