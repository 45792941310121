import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetExperienceCoursesMetadata } from '@cais-group/caisiq/domain/manage-caisiq'
import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { ExperienceResponse } from '@cais-group/shared/util/type/caisiq-be'

export const useUpdateDisabledFormLabels = ({
  currentFirmExperience,
}: {
  currentFirmExperience?: ExperienceResponse
}) => {
  const { setValue } = useFormContext<ExperienceFields>()

  const { data: experienceCoursesMetadata } = useGetExperienceCoursesMetadata(
    currentFirmExperience?.uuid
  )

  useEffect(() => {
    setValue('label', currentFirmExperience?.label || '')
    setValue('contentfulId', currentFirmExperience?.contentfulId as string)
    setValue('catalogId', currentFirmExperience?.catalog?.id || '')
    setValue('coursesEnabled', currentFirmExperience?.coursesEnabled || false)
    setValue('ceCreditEnabled', currentFirmExperience?.ceCreditEnabled || false)
    if (experienceCoursesMetadata) {
      setValue('courses', experienceCoursesMetadata || [])
    }
  }, [
    currentFirmExperience?.ceCreditEnabled,
    currentFirmExperience?.contentfulId,
    currentFirmExperience?.coursesEnabled,
    setValue,
    currentFirmExperience?.label,
    currentFirmExperience?.catalog?.id,
    experienceCoursesMetadata,
    currentFirmExperience?.catalog,
  ])
}
