import { Row } from '@tanstack/react-table'
import { useFormContext, Controller } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { RadioGroup } from '@cais-group/equity/atoms/radio-group'
import {
  CourseMetadata,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'

export const RadioCell = ({
  row,
  enrollType,
}: {
  row: Row<CourseMetadata>
  enrollType: CourseMetadata['enrollType']
}) => {
  const isModal = Boolean(document.querySelector('#modal-layout-title'))
  const isFirmsPage = useLocation().pathname.includes('firms')
  const methods = useFormContext<ExperienceFields>()
  const { watch, control } = methods
  const courses = watch('courses')
  const courseId = row.original.acpCourseId
  /**
   * We need to find the index of the course in the courses array because
   * we need to update the correct course in the array and the row index
   * is not the same as the index in the array
   */
  const index = courses?.findIndex((c) => c.acpCourseId === courseId)

  const name = `courses.${index}.enrollType` as `courses.${number}.enrollType`

  const hasFullAccess =
    userSettingsService.hasRole(UserRole.CaisiqManageWrite) &&
    (!isFirmsPage || isModal)

  /**
   * @todo Checking with equity team if we can make children optional
   * also, waiting to hear if they can add a size prop as this is bigger than
   * the designs call for
   */
  const empty = ''

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ...rest } }) => (
        <RadioGroup.Option
          value={enrollType as string}
          disabled={!hasFullAccess}
          selectedValue={value}
          aria-label={`radio-${enrollType}`}
          {...rest}
        >
          {empty}
        </RadioGroup.Option>
      )}
    />
  )
}
