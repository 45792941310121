import { Prettify } from '@cais-group/equity/util/type-utils'

import { Modal, ModalProps } from './modal'
import { useModalDerived } from './useModalDerived'

export type ControlledModalProps = Prettify<
  Omit<ModalProps, 'control'> & {
    open: boolean
    onClose: () => void
  }
>

/**
 * A modal for cases where state is owned externally (and more compatible with legacy modal).
 */
export function ControlledModal({
  open,
  onClose,
  children,
  ...props
}: ControlledModalProps) {
  const control = useModalDerived(open, onClose)

  return (
    <Modal {...(props as ModalProps)} control={control}>
      {children}
    </Modal>
  )
}
