import cx from 'classnames'

import { ButtonLink } from '@cais-group/equity/atoms/button'
import { Icon } from '@cais-group/equity/atoms/icon'
import { Link } from '@cais-group/equity/atoms/link'

import { CardBase } from './card-base'
import { DateTime } from './date-time'
import { getContentOwner, getPageType } from './helper'
import { OwnershipTag } from './ownership-tag'
import { TagAndTime } from './tag-and-time'
import type { CuratedContentCardProps } from './types'

export type CuratedCardProps = CuratedContentCardProps & {
  variant: 'primary' | 'secondary'
}

/**
 * @deprecated to be replaced by new design in a 2-col layout
 */
export const CardCuratedDeprecated = (props: CuratedCardProps) => {
  const {
    callToAction,
    description,
    title,
    iconType,
    variant,
    contentOwnershipTag,
    date,
    timezone,
    __typename,
  } = props

  return (
    <CardBase variant={`curated-${variant}-deprecated`}>
      <span className="bg-brand-100 flex w-fit p-8">
        <Icon
          type={iconType}
          color="eq-color-brand-800"
          size={variant === 'primary' ? 'largest' : 'large'}
        />
      </span>
      <div
        className={cx('mr-24 max-w-prose flex-1', {
          'space-y-4': contentOwnershipTag,
        })}
      >
        <h2
          className={cx('text-brand-900 line-clamp-2 text-balance', {
            'headline-s-strong': variant === 'primary',
            'body-strong': variant !== 'primary',
          })}
        >
          {title}
        </h2>
        <TagAndTime
          contentOwnershipTag={contentOwnershipTag}
          date={date}
          timezone={timezone}
          includeTime={__typename === 'Event'}
        />

        {variant === 'primary' && (
          <p className="small line-clamp-2">{description}</p>
        )}
      </div>
      <div className="md:ml-auto">
        <ButtonLink
          href={callToAction.href}
          endAdornment={callToAction.icon}
          variant={variant === 'secondary' ? 'tertiary' : variant}
          tracking={{
            click_type: 'Content',
            content_type: getPageType(props),
            content_owner: getContentOwner(props),
            item_name: title,
            sub_section: 'Curated Content',
          }}
        >
          <span
            className={cx({
              'lg:sr-only xl:not-sr-only': variant === 'secondary',
            })}
          >
            {callToAction.text}
          </span>
        </ButtonLink>
      </div>
    </CardBase>
  )
}

export const CardCurated = (props: CuratedCardProps) => {
  const {
    callToAction,
    title,
    variant,
    contentOwnershipTag,
    date,
    timezone,
    __typename,
  } = props

  return (
    <CardBase variant={`curated${variant === 'primary' ? '-primary' : ''}`}>
      <h3 className="body line-clamp-2 text-balance">
        <Link
          href={callToAction.href}
          className="font-normal text-neutral-900"
          tracking={{
            click_type: 'Content',
            content_type: getPageType(props),
            content_owner: getContentOwner(props),
            item_name: title,
            sub_section: 'Curated Content',
          }}
        >
          {title}
        </Link>
      </h3>
      <DateTime
        date={date}
        includeTime={__typename === 'Event'}
        timezone={timezone}
      />
      <div className="order-first flex flex-wrap items-center justify-between gap-16">
        {contentOwnershipTag?.name ? (
          <OwnershipTag name={contentOwnershipTag.name} />
        ) : (
          <OwnershipTag name="CAIS" />
        )}
        {variant === 'primary' ? (
          <span className="text-brand-800 caption-strong">Highlighted</span>
        ) : (
          ''
        )}
      </div>
    </CardBase>
  )
}
