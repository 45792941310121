import { ReactNode } from 'react'

import { Disclaimer } from '@cais-group/shared/ui/disclaimer/default'
import { FooterDefault } from '@cais-group/shared/ui/footer/default'
import type { TypeDisclaimerData } from '@cais-group/shared/util/type/disclaimer-data'

export type FooterWithDisclaimerProps = {
  disclaimer?: TypeDisclaimerData
  children: ReactNode
}

export const FooterWithDisclaimer = (props: FooterWithDisclaimerProps) => {
  const { disclaimer, children } = props
  return (
    <>
      {children}
      <FooterDefault>
        {disclaimer && <Disclaimer disclaimer={disclaimer} />}
      </FooterDefault>
    </>
  )
}
