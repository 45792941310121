import { Components, RadioProps } from '@mui/material'

import {
  BodyCssObject,
  BodySmallCssObject,
} from '@cais-group/approved/ui/typography'

import { colors } from '@cais-group/equity/particles/colors'

declare module '@mui/material/Radio' {
  interface RadioPropsSizeOverrides {
    small: true
    medium: true
    large: true
  }
}

const iconFontSize = (ownerState: RadioProps) => {
  if (ownerState.size === 'small') {
    return 16
  }
  if (ownerState.size === 'large') {
    return 36
  }
  return 24
}

export const MuiRadio: Components['MuiRadio'] = {
  defaultProps: {
    size: 'medium',
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      svg: {
        rect: {
          fill: colors['eq-color-neutral-0'],
          stroke: colors['eq-color-neutral-400'],
        },
        circle: {
          fill: colors['eq-color-neutral-400'],
        },
      },
      '& .MuiSvgIcon-root': {
        fontSize: iconFontSize(ownerState),
      },
      '&:hover': {
        backgroundColor: 'transparent',
        svg: {
          rect: {
            stroke: colors['eq-color-primary-600'],
          },
          circle: {
            fill: colors['eq-color-primary-600'],
          },
        },
      },
      '&.Mui-checked': {
        svg: {
          rect: {
            stroke: colors['eq-color-primary-600'],
          },
          circle: {
            fill: colors['eq-color-primary-600'],
          },
        },
      },
      '&.Mui-disabled': {
        svg: {
          rect: {
            fill: colors['eq-color-neutral-100'],
            stroke: colors['eq-color-neutral-400'],
          },
          circle: {
            fill: colors['eq-color-neutral-400'],
          },
        },
      },
      '+ .MuiFormControlLabel-label': {
        color: colors['eq-color-neutral-900'],
        ...(ownerState.size === 'small' ? BodySmallCssObject : BodyCssObject),
      },
    }),
  },
}
