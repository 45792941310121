import * as React from 'react'

type RefEl = React.MutableRefObject<HTMLDivElement>

export type UseTrapFocusProps = {
  refEl: RefEl
  shouldTrapFocus?: boolean
}

type FocusableHTMLElement = HTMLElement & {
  focus: (event: FocusEvent) => void
}

export const focusableElements =
  'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)'

export const useTrapFocus = (props: UseTrapFocusProps) => {
  const { refEl, shouldTrapFocus = true } = props
  const firstFocusableElementRef = React.useRef<FocusableHTMLElement>()
  const lastFocusableElementRef = React.useRef<FocusableHTMLElement>()
  const allFocusableElementsRef =
    React.useRef<NodeListOf<FocusableHTMLElement>>()

  React.useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const focusedElement = document.activeElement

      if (event.key === 'Tab') {
        if (event.shiftKey) {
          if (focusedElement === firstFocusableElementRef.current) {
            lastFocusableElementRef.current?.focus()
            event.preventDefault()
          }
        } else {
          // When you click somewhere random. No element is selected.
          // In that case the focusedElement is equal to body
          // We want to focus the firstFocusableElement
          // when that happens
          if (focusedElement === document.body) {
            event.preventDefault()
            firstFocusableElementRef.current?.focus()
          }
          if (focusedElement === lastFocusableElementRef.current) {
            firstFocusableElementRef.current?.focus()
            event.preventDefault()
          }
        }
      }
    }

    window.addEventListener('keydown', handler)

    return () => window.removeEventListener('keydown', handler)
  }, [])

  React.useEffect(() => {
    if (shouldTrapFocus) {
      allFocusableElementsRef.current = refEl.current.querySelectorAll(
        focusableElements
      ) as NodeListOf<FocusableHTMLElement>
      firstFocusableElementRef.current = allFocusableElementsRef.current[0]
      lastFocusableElementRef.current = allFocusableElementsRef.current[
        allFocusableElementsRef.current.length - 1
      ] as FocusableHTMLElement

      firstFocusableElementRef.current.focus()
    }
  }, [shouldTrapFocus, refEl])
}

export const TrapFocus = ({ refEl }: { refEl: RefEl }) => {
  useTrapFocus({ refEl })
  return null
}
