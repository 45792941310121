import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { LabelCss } from '@cais-group/approved/ui/typography'

import { ButtonAbstract } from '../abstract/button-abstract'

export const RoundButton = styled(ButtonAbstract)<{ variant?: 'dark' }>`
  ${LabelCss}
  padding-top: var(--s8);
  padding-bottom: var(--s8);
  border: 1px solid rgb(var(--colors-neutral-200));
  border-radius: 100px;
  background-color: transparent;
  ${(p) => p.variant === 'dark' && darkVariant}
`

const darkVariant = css`
  background-color: rgb(var(--colors-neutral-900));
  border: 1px solid rgb(var(--colors-neutral-900));

  color: rgb(var(--colors-neutral-0));
`
