import { ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { queryDefaults } from '@cais-group/caisiq/util/default-query-function'

export type ReactQueryProviderProps = {
  children: ReactNode
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: queryDefaults,
  },
})

export const getQueryClientOutsideProvider = () => {
  return queryClient
}

export const ReactQueryProvider = ({ children }: ReactQueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
