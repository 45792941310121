import { StatusTagProps } from '@cais-group/equity/atoms/status-tag'

/**
 * The options that can be passed to `selectTagProps` to determine which tag to display and its color.
 * requiredTagEnabled: Whether or not the required status tag is enabled at all, set at the form level
 * required: Whether or not the field is required
 * skippable: Whether or not the field can be skipped (does not need to be completed)
 * recommended: Whether or not the field is recommended to be filled out (used for uploads primarily)
 */
export type SelectTagPropsOptions = {
  requiredTagEnabled: boolean
  required: boolean
  skippable: boolean
  recommended: boolean
}

/**
 * Multiple uniform adapters need to apply tags to their labels in the same scenarios. This function will take some options and return the appropriate tag string and styles.
 */
export function selectTagProps(options: SelectTagPropsOptions): StatusTagProps {
  const { requiredTagEnabled, required, skippable, recommended } = options
  const defaultColor = 'primary'
  const defaultVariant = 'light'

  const inactiveTagProps: StatusTagProps = {
    children: '',
    color: defaultColor,
    variant: defaultVariant,
  } as const

  if (skippable)
    // Skippable tags take precedence over required tags
    return {
      children: 'Required If Applicable',
      color: 'warning',
      variant: 'light',
    }

  if (requiredTagEnabled && required)
    return {
      children: 'Required',
      color: 'primary',
      variant: 'light',
    }

  if (recommended)
    return {
      children: 'Recommended',
      color: 'primary',
      variant: 'light',
    }

  return inactiveTagProps
}
