import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useEffectOnce } from 'react-use'
import {
  Subject,
  debounceTime,
  distinctUntilKeyChanged,
  switchMap,
  tap,
} from 'rxjs'

import { useUniformContext } from '../context'
import type { UniformServices } from '../services'
import type { UniformValues } from '../uniform'
import { matchUniformService } from '../utils'

export type DynamicTitleProps<TUniformServices extends UniformServices> =
  | string
  | {
      /** service name */
      invoke: keyof TUniformServices
      /** watch specific key for change only */
      watchedKey?: string
    }

export function useDynamicTitle<TUniformServices extends UniformServices>(
  title?: DynamicTitleProps<TUniformServices>
) {
  const { watch, getValues } = useFormContext()
  const { services, selectedOptions } = useUniformContext<TUniformServices>()
  const [subject] = React.useState(() => new Subject<UniformValues>())
  const [t, setT] = React.useState(() =>
    typeof title === 'string' ? title : ''
  )
  const isDynamic = typeof title !== 'string'
  const loading = isDynamic && !t

  /** call service with initial values */
  useEffectOnce(() => {
    if (!title || typeof title === 'string') return
    const fn = matchUniformService({ services, service: title.invoke })
    fn(getValues(), selectedOptions.latestFieldValue).then((t) => setT(t))
  })

  /** watch react-hook-form values and send updates to subject */
  useEffectOnce(() => {
    if (!title || typeof title === 'string') return
    const subscription = watch((value) => subject.next(value))
    return () => subscription.unsubscribe()
  })

  /** subscribe to subject and execute debounced service and update dynamic title */
  React.useEffect(() => {
    if (!title || typeof title === 'string') return
    const fn = matchUniformService({ services, service: title.invoke })

    const subscription = subject
      .pipe(
        debounceTime(500),
        title.watchedKey ? distinctUntilKeyChanged(title.watchedKey) : tap(),
        switchMap((formValues) =>
          fn(formValues, selectedOptions.latestFieldValue)
        )
      )
      .subscribe((t) => setT(t))

    return () => subscription.unsubscribe()
  }, [title, services, subject, selectedOptions])

  return { isDynamic, title: t, loading }
}
