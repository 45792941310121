import { Course } from '@cais-group/shared/util/type/caisiq-be'

export type Learned =
  | { learned?: false; learnedAt?: never }
  | { learned: true; learnedAt: string }

export interface Unstarted extends Course {
  type: 'Unstarted'
}

export interface Learning extends Course {
  type: 'Learning'
  progress: number
}

export interface AssessmentUnlocked extends Course {
  type: 'AssessmentUnlocked'
  progress: number
}

export interface Retry extends Course {
  type: 'Retry'
  progress: number
  score: number
}

export interface Passed extends Course {
  type: 'Passed'
  progress: number
  score: number
}

export interface LearningComplete extends Course {
  type: 'LearningComplete'
  progress: number
}

export interface LearningOrAssessing extends Course {
  type: 'LearningOrAssessing'
}
