import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { DEBOUNCE_RATE } from '@cais-group/caisiq/domain/manage-caisiq'
import { defaultSearchQuerier } from '@cais-group/caisiq/util/default-query-function'
import { useDebouncedState } from '@cais-group/shared/util/hook/use-debounced-state'
import { ApiPaths } from '@cais-group/shared/util/type/caisiq-be'

export function useInfiniteQueryWithDebouncedSearch<TData>(endpoint: ApiPaths) {
  const [searchText, setSearchText] = useState('')

  const debouncedSearchText = useDebouncedState(searchText, DEBOUNCE_RATE)

  const { data, fetchNextPage } = useInfiniteQuery<TData>({
    queryKey: [endpoint, { filter: { searchText: debouncedSearchText } }],
    queryFn: defaultSearchQuerier,
  })

  return {
    data: data?.pages.flatMap((page) => page) || [],
    fetchNextPage,
    setSearchText,
  }
}
