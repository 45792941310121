import styled from '@emotion/styled'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'

import { Body, BodySmall, BodyStrong } from '@cais-group/approved/ui/typography'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'

interface Option {
  value: string
  label: string
  helperText: string
}

export interface FormSettingsOptionProps {
  label?: string
  options: Option[]
  value: string
  onChange(): void
  onBlur(): void
}

const transparentBlue = colors['eq-color-primary-200'] + '1E'

const OptionText = styled.div`
  flex-grow: 1;
  margin: ${SPACING.s16} 0;
  margin-left: ${SPACING.s8};
`

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  margin: 0;
  background: ${(props) => (props.checked ? transparentBlue : 'none')};
  padding-left: ${SPACING.s16};
`

export function FormSettingsOptions({
  label,
  value,
  options,
  onChange,
  onBlur,
}: FormSettingsOptionProps) {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      aria-label={label}
    >
      {options.map((option) => {
        const Heading = value === option.value ? BodyStrong : Body
        return (
          <StyledFormControlLabel
            key={option.value}
            value={option.value}
            onChange={onChange}
            control={<Radio />}
            label={
              <OptionText>
                <Heading>{option.label}</Heading>
                <BodySmall>{option.helperText}</BodySmall>
              </OptionText>
            }
            checked={value === option.value}
          />
        )
      })}
    </RadioGroup>
  )
}

export default FormSettingsOptions
