import type { Node } from '@contentful/rich-text-types'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import React, { Suspense } from 'react'

import { ArticleCard } from '@cais-group/caisiq/ui/card/article'
import { CardWhitepaper } from '@cais-group/caisiq/ui/card/whitepaper'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { CardDownload } from '@cais-group/shared/ui/card/download'
import { ChartContentful } from '@cais-group/shared/ui/contentful/chart'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { ContentfulLightboxWrapper } from '@cais-group/shared/ui/lightbox'
import { MuxVideoPlayer } from '@cais-group/shared/ui/video/mux-video-player'
import { millisecondsToSeconds } from '@cais-group/shared/util/js/format-length'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'
import { EntryMap } from '@cais-group/shared/util/type/rich-text-json'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

import { getResourceParentPath } from './link-components'

const CardDownloadContainer = styled.div`
  padding-top: ${SPACING.s88};
`

export function embeddedEntry(node: Node, entryMap: EntryMap) {
  const id = node.data['target'].sys.id
  const entry = entryMap.get(id)

  if (!entry?.__typename) {
    return null
  }

  switch (entry.__typename) {
    case 'Chart': {
      return <ChartContentful {...entry} />
    }
    case 'CaisiqVideo': {
      const durationInSeconds = entry.muxVideo
        ? entry.muxVideo.duration
        : millisecondsToSeconds(entry.duration ?? 0, 'round')
      const videoSource = entry?.muxVideo
        ? { playbackId: entry.muxVideo.playbackId }
        : { src: entry?.video?.url }
      return (
        videoSource &&
        entry && (
          <Suspense fallback={null}>
            <MuxVideoPlayer
              {...videoSource}
              variant="inPageComponent"
              metadata={{
                url: window.location.href,
                video_title: entry.title || '',
                duration: durationInSeconds,
                watchId: (Math.random() * 10).toString(36).replace('.', ''),
                video_id: entry.sys.id,
                viewer_user_id: userSettingsService.user.sub,
              }}
            />
          </Suspense>
        )
      )
    }
    case 'Pdf': {
      const date = dayjs(entry?.displayDate).format('MMM D')
      return (
        <CardDownloadContainer>
          <CardDownload
            file={entry.pdf?.url || ''}
            image={entry.image?.url || ''}
            title={entry.title || ''}
            date={date}
            onClick={() => {
              trackingService.whitepaperDownloadClicked(
                String(entry.title),
                String(entry.pdf?.title)
              )
            }}
          />
        </CardDownloadContainer>
      )
    }
    case 'Whitepaper': {
      return (
        <CardWhitepaper
          key={entry.sys.id}
          whitepaper={entry as WhitepaperData}
          to={`/authenticated/${getResourceParentPath(entry)}/${entry.slug}`}
          state={{ from: `${getResourceParentPath(entry)}` }}
        />
      )
    }
    case 'CaisiqArticle': {
      return (
        <ArticleCard
          isFeatured={false}
          key={entry?.sys?.id}
          article={entry as TypeArticleData}
          to={{
            pathname: `/authenticated/${getResourceParentPath(entry)}/${
              entry?.slug
            }`,
          }}
          state={{ from: `${getResourceParentPath(entry)}` }}
        />
      )
    }
    case 'ComponentImage': {
      return <ContentfulLightboxWrapper {...entry} />
    }
    default:
      return null
  }
}
