import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import MuiCard from '@mui/material/Card'
import { AxiosError } from 'axios'
import * as React from 'react'
import { useMemo } from 'react'
import { UseQueryResult } from 'react-query'

import { H6Strong, Body } from '@cais-group/approved/ui/typography'
import { usePatchEnrollCourse } from '@cais-group/caisiq/domain/library-course'
import { STYLES } from '@cais-group/caisiq/ui/card/shared'
import { ImageOrFallback } from '@cais-group/caisiq/ui/image-or-fallback'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Button } from '@cais-group/equity/atoms/button'
import { ContextMenuTable } from '@cais-group/shared/ui/buttons'
import { LayoutBox } from '@cais-group/shared/ui/layout'
import { Course } from '@cais-group/shared/util/type/caisiq-be'

export type CardCourseLibraryProps = {
  course: Course
  onAttemptUnenroll: (callback: () => void) => void
  onClickViewDetails: (libraryCourse: Course) => void
  headerLabel?: string
  fallbackImageUrl: string
  courses: UseQueryResult<Course[], AxiosError<Course>>
}

export const Heading = styled(H6Strong)`
  color: rgb(var(--colors-neutral-900));
  flex: 0 1 auto;
`

const Header = styled.div`
  position: relative;
  height: 4.75rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  background-color: rgb(var(--colors-primary-600));
  background-size: cover;
`

export const LIBRARY_CARD_HEIGHT = '18rem' // 288px
const StyledCard = styled(MuiCard)`
  padding: 0;
  min-width: ${STYLES.CARD_WIDTH};
  height: ${LIBRARY_CARD_HEIGHT};
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
StyledCard.defaultProps = {
  variant: 'simple-hover',
}

const Content = styled(LayoutBox)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 'var(--s24)';
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TotalEnrolled = styled(Body)`
  position: absolute;
  right: var(--s24);
  bottom: var(--s8);
  color: rgb(var(--colors-neutral-0));
  font-weight: 600;
`
const HeaderLabel = styled(H6Strong)`
  position: absolute; // To bring this to the top vs absolute positioned background
  margin-left: var(--s24);
  bottom: var(--s8);
  width: fit-content;
  color: rgb(var(--colors-neutral-0));
`

const Enrolled = styled(Body)`
  color: rgb(var(--colors-success-600));
  font-weight: 600;
  display: flex;
  align-items: center;
`

const StyledCheckIcon = styled(CheckIcon)`
  fill: rgb(var(--colors-success-600));
  margin-left: var(--s8);
  margin-bottom: 3px; // vertically center icon against text label baseline
`

const Actions = styled.div`
  margin-top: auto;
`

const EnrollButton = styled(Button)`
  margin-top: var(--s56);
  &:hover {
    cursor: pointer;
  }
`

export const CardCourseLibrary = ({
  course,
  onAttemptUnenroll,
  onClickViewDetails,
  headerLabel,
  fallbackImageUrl,
  courses,
}: CardCourseLibraryProps) => {
  const { executeCourseEnrollPatch, courseEnrollPatchResult } =
    usePatchEnrollCourse()

  const inProgress = useMemo(() => {
    if (!courses.isFetched) {
      return false
    }
    const found = courses?.data?.find((c) => c.id === course.id)

    return (found?.progress ?? 0) < 1
  }, [courses, course.id])

  const isSwitchedUser = userSettingsService.user.switchedUser
  return (
    <StyledCard
      data-testid="course-card-Enroll"
      onClick={() => {
        onClickViewDetails(course)
      }}
    >
      <Header>
        <ImageOrFallback
          imageUrl={course.imageUrl}
          fallbackUrl={fallbackImageUrl !== '' ? fallbackImageUrl : undefined}
          minAspectRatio={376 / 88}
          maxAspectRatio={1120 / 160}
          data-chromatic="ignore"
        />
        {headerLabel && (
          <HeaderLabel data-testid="cardCourse-ceCredit-label">
            {headerLabel}
          </HeaderLabel>
        )}

        <TotalEnrolled>{course.totalEnrolled} others enrolled</TotalEnrolled>
      </Header>
      <Content>
        <HeadingContainer>
          <Heading>{course.name}</Heading>
          <ContextMenuTable
            className="translate-x-8 transform py-0"
            actions={[
              {
                text: 'View details',
                handler: (event?: React.MouseEvent) => {
                  event?.stopPropagation()
                  onClickViewDetails(course)
                },
              },
              ...(course.enrolled && inProgress
                ? [
                    {
                      disabled: isSwitchedUser,
                      text: 'Un-enroll',
                      handler: (event?: React.MouseEvent) => {
                        event?.stopPropagation()
                        onAttemptUnenroll(() =>
                          executeCourseEnrollPatch({
                            id: course.id,
                            enroll: false,
                          })
                        )
                      },
                    },
                  ]
                : []),
            ]}
          />
        </HeadingContainer>
        <Actions>
          {course.enrolled ? (
            <Enrolled>
              Enrolled
              <StyledCheckIcon />
            </Enrolled>
          ) : (
            <EnrollButton
              loading={courseEnrollPatchResult.isLoading}
              disabled={isSwitchedUser}
              variant="secondary"
              onClick={(event) => {
                event.stopPropagation()
                trackingService.courseCardClicked(course, 'Enroll')
                executeCourseEnrollPatch({
                  id: course.id,
                  enroll: true,
                })
              }}
            >
              Enroll
            </EnrollButton>
          )}
        </Actions>
      </Content>
    </StyledCard>
  )
}
