import { useFeature } from 'flagged'
import React from 'react'
import { Navigate } from 'react-router-dom'

import { ROUTES } from '@cais-group/caisiq/feature/routes'

type FeatureGateProps = {
  children: React.ReactElement
  feature: string
  hasAccess?: boolean
}
export const FeatureGate = ({
  children,
  feature,
  hasAccess = true,
}: FeatureGateProps) => {
  const isFeatureEnabled = useFeature(feature)

  return isFeatureEnabled && hasAccess ? (
    children
  ) : (
    <Navigate to={ROUTES.home} replace />
  )
}
