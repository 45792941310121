import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from 'react'

import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { TUserRole } from '@cais-group/shared/util/type/caisiq-be'

export const PermissionsContext = createContext<boolean>(false)

export type HasPermissionsProps = {
  children: ReactNode
  capabilities: TUserRole | TUserRole[]
}

export const HasPermissions = ({
  children,
  capabilities,
}: HasPermissionsProps) => {
  let state: boolean = false

  if (typeof capabilities === 'string') {
    state = userSettingsService.hasRole(capabilities)
  } else if (Array.isArray(capabilities)) {
    state = capabilities.every((p) => userSettingsService.hasRole(p))
  }

  let thereIsNoElse = false

  // Below ensures that if there have been Else and Then children provided, those will render but if not, the children are still rendered wrapped in Then
  React.Children.forEach(children as ReactElement, (child: JSX.Element) => {
    if (child.type.name !== 'Then' && child.type.name !== 'Else') {
      thereIsNoElse = true
      return <Then>{child}</Then>
    }
    return child
  })

  // Likely rare edgecase: If user does't have correct permission and there is no Else child provided
  if (!state && thereIsNoElse) {
    return null
  }

  return (
    <PermissionsContext.Provider value={state}>
      {children}
    </PermissionsContext.Provider>
  )
}

const Then = ({ children }: { children: JSX.Element }) => {
  const permissionsState = useContext(PermissionsContext)
  return permissionsState ? children : null
}

const Else = ({ children }: { children: JSX.Element }) => {
  const permissionsState = useContext(PermissionsContext)
  return !permissionsState ? children : null
}

HasPermissions.Then = Then
HasPermissions.Else = Else
