import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react'

export const PlaylistContainer = ({
  children,
  header,
}: PropsWithChildren & {
  header: ReactNode
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return
    ref.current.focus({ preventScroll: true })
  }, [])

  return (
    <div className="mx-auto w-full py-12 pb-24">
      {header}
      <div
        ref={ref}
        tabIndex={-1}
        className="relative mx-auto my-12 flex w-full flex-col gap-24 lg:flex-row lg:gap-0"
      >
        {children}
      </div>
    </div>
  )
}
