import styled from '@emotion/styled'

import { H4, BodySmall } from '@cais-group/approved/ui/typography'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { FooterDefault } from '@cais-group/shared/ui/footer/default'
import { LayoutCenter } from '@cais-group/shared/ui/layout'

const OuterContainer = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`

const Container = styled.div`
  background-color: rgb(var(--colors-neutral-0));
  padding: var(--s144) var(--s16) var(--s144) var(--s16);
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    padding: var(--s144);
  }
`

const Description = styled(BodySmall)`
  margin-top: var(--s24);
`

const CircleBg = styled.div`
  background-color: rgb(var(--colors-warning-600));
  height: 186px;
  width: calc(186px * 2);
  border-top-left-radius: calc(186px * 2);
  border-top-right-radius: calc(186px * 2);
  position: relative;
  left: 65%;
`

export const ExperienceError = () => {
  return (
    <OuterContainer>
      <Container>
        <LayoutCenter centerText={true}>
          <H4>The page you are looking for cannot be reached.</H4>
          <Description>
            Please check that the URL is correct and try again.
          </Description>
        </LayoutCenter>
      </Container>

      <CircleBg></CircleBg>
      <FooterDefault positionRelative />
    </OuterContainer>
  )
}
