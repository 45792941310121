import { Route } from 'react-router-dom'

import { APPS } from '@cais-group/shared/domain/apps'

export const HealthCheckRoute = (app?: APPS) => (
  <Route
    path="spa-health"
    element={<div className="p-24">{app}: All Systems go!</div>}
  ></Route>
)
