import { PropsWithChildren, ReactNode } from 'react'

import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'

export const useHasAttribute = (attribute: string) => {
  const {
    userProfileState: { userProfile, status },
  } = useUserProfile()

  const featureEnabled =
    attribute in (userProfile?.attributes ?? {}) &&
    userProfile?.attributes?.[attribute] === true

  const inheritedFeatureEnabled = userProfile?.inheritedAttributes?.some(
    ({ attributes }) => attributes?.[attribute] === true
  )

  return { hasAccess: inheritedFeatureEnabled || featureEnabled, status }
}

export const FeatureFlag = ({
  attribute,
  children,
  alternative = null,
}: PropsWithChildren<{ attribute: string; alternative?: ReactNode }>) => {
  const { hasAccess, status } = useHasAttribute(attribute)

  if (status === 'loaded') {
    return (hasAccess ? children : alternative) as ReactNode
  }

  if (status === 'error') {
    return alternative
  }

  return null
}
