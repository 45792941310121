import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { Block } from '@contentful/rich-text-types'

/**
 * Performs a simple string search on the stringified contents of a rich text document
 *
 * @param doc - A nested JSON object that represents rich text content
 * @param searchTerm - The target of search
 */
function inBody(doc: Block, searchTerm: string): boolean {
  // If doc is empty, it can't contain the search term
  if (doc && Object.keys(doc).length === 0) {
    return false
  }

  // TODO(SW): Consider caching this in case we notice performance issues.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const content = documentToPlainTextString(doc as any)
  return content.toLowerCase().includes(searchTerm.toLowerCase())
}

export { inBody }
