import styled from '@emotion/styled'
import { Suspense, useCallback, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  Body,
  H3Strong,
  H5Serif,
  Overline,
  SubtitleMono,
} from '@cais-group/approved/ui/typography'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { NavigationProps } from '@cais-group/caisiq/ui/navigation'
import { CaisiqVideo } from '@cais-group/caisiq/util/graphql/contentful'
import { useIsFullScreen } from '@cais-group/caisiq/util/hook/use-is-full-screen'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { BackPillButton } from '@cais-group/equity/atoms/pill'
import { ellipsify } from '@cais-group/equity/utilitarian'
import { LayoutCenter, LayoutCluster } from '@cais-group/shared/ui/layout'
import { MuxVideoPlayer } from '@cais-group/shared/ui/video/mux-video-player'

const HeroBackground = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: rgb(var(--colors-neutral-900));
`

const VideoContainer = styled.div`
  display: flex;
  margin: var(--s56) auto var(--s56);
  width: 60%;
  justify-content: center;
  @media screen and (max-width: 1260px) {
    width: 80%;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    margin-top: var(--s8);
    margin-bottom: 0;
  }
`

const VideoResizeWrapper = styled('div')<{
  maxHeight: number
  isFullScreen?: boolean
}>`
  width: 100%;
  line-height: 0; // Otherwise there is a gap below the video player and the height calculation is too large
  background: rgb(var(--colors-neutral-900)); // Form gray bars for wider videos
  > div > mux-player {
    ${({ maxHeight, isFullScreen }) =>
      maxHeight === undefined || isFullScreen
        ? ''
        : `max-height: ${maxHeight}px;`}
  }
`

const TitleInfo = styled(LayoutCenter)`
  max-width: 750px;
  margin-top: var(--s88);

  & * {
    color: rgb(var(--colors-neutral-900));
  }

  @media screen and (max-width: 1000px) {
    margin: 0;
  }
`

const Title = styled(H3Strong)`
  position: relative;
  left: -0.25rem;
  margin-top: var(--s8);

  @media screen and (max-width: 1000px) {
    font-size: var(--s32);
    line-height: var(--s56);
  }
  @media screen and (max-width: 700px) {
    font-size: var(--s24);
    line-height: var(--s32);
  }
`

const Line = styled.div`
  height: 2px;
  background-color: rgb(var(--colors-warning-600));
  width: var(--s56);
  margin-top: -38px;
  margin-bottom: calc(var(--s24) * 2);
  @media screen and (max-width: 1000px) {
    margin-top: var(--s56);
  }
`

const VideoTop = styled(LayoutCluster)`
  margin-bottom: var(--s12);
  @media screen and (max-width: 700px) {
    padding-left: var(--s8);
  }
`

const VideoTitle = styled(Body)`
  color: rgb(var(--colors-neutral-0));
  @media screen and (max-width: 1000px) {
    display: none;
  }
`

const Divider = styled(H5Serif)`
  color: rgb(var(--colors-neutral-400));
  @media screen and (max-width: 1000px) {
    display: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const randomNumber = () => (Math.random() * 10).toString(36).replace('.', '')

const makeVideoPlayerProps = (
  url: string,
  slug: string,
  title: string,
  caisVideo?: CaisiqVideo
) => {
  const watchId = randomNumber()
  const common: {
    variant: 'inPageComponent'
    autoPlay: boolean
  } = {
    variant: 'inPageComponent',
    autoPlay: true,
  }

  if (caisVideo?.muxVideo) {
    return {
      ...common,
      metadata: {
        video_id: caisVideo.sys.id,
        duration: caisVideo.muxVideo.duration,
        video_title: caisVideo.title ?? title,
        url: slug,
        watchId,
        viewer_user_id: userSettingsService.user.sub,
      },
      ratio: caisVideo.muxVideo?.ratio,
      src: caisVideo.muxVideo.playbackId,
    }
    // We shouldn't hit this edge case but leaving in for now
  } else if (caisVideo?.video) {
    return {
      ...common,
      metadata: {
        video_id: caisVideo.sys.id,
        duration: caisVideo.duration || 0,
        video_title: caisVideo.title ?? title,
        url: slug,
        watchId,
        viewer_user_id: userSettingsService.user.sub,
      },
      src: caisVideo.video.url,
    }
  } else if (url) {
    return {
      metadata: {
        video_id: url,
        video_title: title,
        url: slug,
        watchId,
        viewer_user_id: userSettingsService.user.sub,
      },
      src: url,
      ...common,
    }
  }
  return {
    metadata: {
      url: url,
      watchId: watchId,
      video_title: title,
      video_id: url,
      viewer_user_id: userSettingsService.user.sub,
    },
    src: url,
    ...common,
  }
}
export type HeroVideoHeaderProps = Omit<
  NavigationProps,
  'headerThemeName' | 'userName'
> & {
  url: string
  title: string
  date: string
  author: string
  video?: CaisiqVideo
}

export const HeroVideoHeader = (props: HeroVideoHeaderProps) => {
  const { url, title, author, date, video } = props

  const videoPlayerProps = makeVideoPlayerProps(
    url,
    useLocation().pathname,
    title,
    video as CaisiqVideo
  )

  const isFullScreen = useIsFullScreen()
  const [maxVideoHeight, setMaxVideoHeight] = useState<number>(0)
  const videoRef = useCallback((videoResizeWrapper: HTMLDivElement) => {
    const updateMeasurements = () => {
      if (videoResizeWrapper.clientHeight !== undefined) {
        const maxHeight = window.innerHeight - videoResizeWrapper.offsetTop
        setMaxVideoHeight(maxHeight)
      }
    }
    if (videoResizeWrapper) {
      const resizeObserver = new ResizeObserver(updateMeasurements)
      resizeObserver.observe(videoResizeWrapper)
      window.addEventListener('resize', updateMeasurements)
    }
  }, [])

  return (
    <>
      <HeroBackground>
        <VideoContainer data-testid="video-article-header">
          <Wrapper>
            <VideoTop>
              <Link to={ROUTES.articles}>
                <BackPillButton />
              </Link>
              <Divider>|</Divider>
              <VideoTitle>{title && ellipsify(title, 80)}</VideoTitle>
            </VideoTop>

            <VideoResizeWrapper
              isFullScreen={isFullScreen}
              maxHeight={maxVideoHeight}
              ref={videoRef}
            >
              {videoPlayerProps && (
                <Suspense fallback={null}>
                  <MuxVideoPlayer
                    {...videoPlayerProps}
                    variant="inPageComponent"
                    autoPlay={false}
                    preload="metadata"
                  />
                </Suspense>
              )}
            </VideoResizeWrapper>
          </Wrapper>
        </VideoContainer>
      </HeroBackground>
      <TitleInfo>
        <Line />
        <Overline />
        <Title>{title}</Title>
        <SubtitleMono>
          {date} • by {author}
        </SubtitleMono>
      </TitleInfo>
    </>
  )
}
