import dayjs from 'dayjs'

import { Icon } from '@cais-group/equity/atoms/icon'

export type LastUpdateDetailsProps = {
  email: string
  updatedAt: string
}

export function LastUpdateDetails({
  updatedAt,
  email,
}: LastUpdateDetailsProps) {
  return (
    <div
      data-testid="experience-last-updated-by-details"
      className="flex border border-solid border-neutral-200 p-16 text-neutral-900"
    >
      <div className="my-auto flex">
        <Icon type="User" color="eq-color-neutral-400" />
      </div>
      <div className="ml-16">
        <div>
          Last updated{' '}
          <span className="whitespace-nowrap">
            {dayjs(updatedAt).format('DD MMM YY HH:mm A').toUpperCase()}
          </span>
        </div>
        <div>
          by <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    </div>
  )
}
