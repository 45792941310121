import {
  AssessmentTag,
  AssessmentTagType,
} from '@cais-group/caisiq/ui/assessment-tag'
import { Course } from '@cais-group/shared/util/type/caisiq-be'

interface CourseAssessmentTagProps {
  course: Course
}

export const CourseAssessmentTag = ({ course }: CourseAssessmentTagProps) => {
  const { type, score, requiredAssessmentScore } = course

  // Course does not contain any assessments
  if (!course.anyAssessment) {
    return null
  }
  /**
   * @todo the BE will eventually map this to Learning and we can get rid of this case so will we be getting rid of Assessment Unlocked altogether?.
   */
  const isUnlocked = type === 'AssessmentUnlocked'

  const isAttempted = Boolean(
    score !== undefined &&
      ((requiredAssessmentScore !== undefined &&
        type !== 'LearningOrAssessing') ||
        (!requiredAssessmentScore && type === 'Retry'))
  )

  const isPassed = (score ?? 0) >= (requiredAssessmentScore ?? 1)

  let text = 'Contains assessment',
    calculatedType = AssessmentTagType.HAS_ASSESSMENT

  if (isUnlocked) {
    calculatedType = AssessmentTagType.UNLOCKED
    text = 'Assessment unlocked'
  } else if (isAttempted) {
    const scorePercentage = Math.round((score ?? 0) * 100)
    calculatedType = isPassed
      ? AssessmentTagType.PASSED
      : AssessmentTagType.FAILED
    text = isPassed
      ? `Assessment passed (${scorePercentage}%)`
      : `Assessment failed (${scorePercentage}%)`
  }

  return <AssessmentTag type={calculatedType} text={text} />
}
