import {
  UniformContainsRuleV1Operation,
  UniformEqualRuleV1Operation,
  UniformInRuleV1Operation,
  UniformIsNullRuleV1Operation,
  UniformOrRuleV1Operation,
} from '../generated/api.schemas'

type IsNullRule = {
  operation: UniformIsNullRuleV1Operation
  fieldId: string
}

type OrRule = {
  operation: UniformOrRuleV1Operation
  conditions: RequirementRule[]
}

type ContainsRule = {
  operation: UniformContainsRuleV1Operation
  fieldId: string
  value?: string
}

type EqualRule = {
  operation: UniformEqualRuleV1Operation
  fieldId: string
  value?: string
}

type InRule = {
  operation: UniformInRuleV1Operation
  fieldId: string
  value?: string[]
}

export type RequirementRule =
  | IsNullRule
  | OrRule
  | ContainsRule
  | EqualRule
  | InRule
