import styled from '@emotion/styled'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useMediaQuery, useTheme, Tooltip } from '@mui/material'
import { useTable, useSortBy } from 'react-table'

import { CaptionRegular } from '@cais-group/approved/ui/typography'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import {
  ColumnCommon,
  FormattedHomeOfficePivotData,
  HomeOfficeHeaderGroup,
} from '@cais-group/shared/util/type/homeoffice-data'

type TableProps = {
  isTablet?: boolean
}

const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  font-family: var(--font-text);
  text-align: left;
  font-weight: normal;
  width: 100%;
  box-shadow: 0px 10px 17px rgba(0, 0, 0, 0.06);
`

const TH = styled.th`
  font-size: var(--s12);
  font-weight: normal;
  height: 2.5rem;
  max-height: 2.5rem;
  &:first-of-type {
    padding-left: var(--s24);
  }

  &:nth-of-type(4) {
    > p {
      margin-left: var(--s4);
      > span {
        margin-right: var(--s4);
      }
    }
  }
`

const TR = styled.tr`
  padding-right: var(--s24);
  border-bottom: 1px solid rgba(227, 227, 227, 0.5);
  height: var(--s56);
  &:last-of-type {
    padding-right: 0px;
    border-bottom: none;
    box-shadow: 0px 0px 0px transparent;
  }
  @media screen and (max-width: ${BREAKPOINTS.breakpointMd}) {
    > td {
      &:first-of-type {
        background-color: rgb(var(--colors-neutral-100));
      }
    }
  }
`
const THead = styled.thead`
  background: rgb(var(--colors-neutral-100));
`
const TD = styled.td<TableProps>`
  padding: ${(p) => (p.isTablet ? '24px' : '16px 0 16px 0')};
  &:first-of-type {
    font-weight: bold;
    padding: ${(p) => (p.isTablet ? '24px' : '16px 24px ')};
  }
  &:nth-of-type(4) {
    > p {
      margin-left: var(--s8);
    }
  }
`

const Tbody = styled.tbody``
const ArrowContainer = styled.span`
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled(CaptionRegular)`
  display: flex;
  align-items: center;
  line-height: var(--s16);
`
const HeaderRow = styled.tr`
  @media screen and (max-width: ${BREAKPOINTS.breakpointMd}) {
    > th {
      &:first-of-type {
        background-color: rgb(var(--colors-neutral-200));
      }
    }
  }
`
const TooltipWrap = styled.span`
  display: flex;
  margin-bottom: var(--s32);
  margin-right: var(--s8);
`

const PivotTableHeaderToolTipContent = styled.span`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const StyledInfoIcon = styled(InfoOutlinedIcon)`
  margin-right: var(--s8);
  fill: rgb(var(--colors-neutral-400));
  width: 18px;
  height: 18px;
`

const StyledArrowDownUnsorted = styled(ArrowDropDownIcon)`
  fill: rgb(var(--colors-neutral-300));
  width: 28px;
  height: 28px;
  margin-top: calc(var(--s8) * -1.2);
`

const StyledArrowUpUnsorted = styled(ArrowDropUpIcon)`
  fill: rgb(var(--colors-neutral-300));
  width: 28px;
  height: 28px;
  margin-bottom: calc(var(--s8) * -1.2);
`

const StyledArrowUpSortedAscending = styled(ArrowDropUpIcon)`
  width: 28px;
  height: 28px;
  margin-bottom: calc(var(--s8) * -1.2);
`

const StyledArrowDownDescending = styled(ArrowDropDownIcon)`
  width: 28px;
  height: 28px;
  margin-top: calc(var(--s8) * -1.2);
`
const StyledArrowUpIconSorted = styled(StyledArrowUpSortedAscending)(
  ({ fill }) => `
  fill: ${fill};
`
)

const StyledArrowDownIconSorted = styled(StyledArrowDownDescending)(
  ({ fill }) => `
  fill: ${fill};
`
)

const isHeaderToolTip = (header: string) => {
  return (
    header === 'Progress to course mastery' ||
    header === 'Required learning progress'
  )
}

// As the tooltip becomes more shared, add to this function to render content of tooltip
const renderToolTipContent = (header: string) => {
  if (header === 'Required learning progress') {
    return 'This measures a learner’s progress towards the requirement set by the home office.'
  } else if (header === 'Progress to course mastery') {
    return 'This measures a learner’s progress toward reaching the highest possible level of retention. If available, CE credits are earned upon completion.'
  }
  return ''
}

export type PivotTableDefaultProps = {
  data: FormattedHomeOfficePivotData[]
  columns?: ColumnCommon<Record<string, unknown>>[]
}

export const TablePivot = (props: PivotTableDefaultProps) => {
  const { data, columns } = props

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 75))

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        // TODO: Make typesafe
        // @ts-ignore
        columns,
        // @ts-ignore
        data,
      },
      useSortBy
    )

  return (
    <Table {...getTableProps()} data-testid="pivot-table">
      <THead>
        {headerGroups.map((headerGroup, i: number) => (
          <HeaderRow
            {...headerGroup.getHeaderGroupProps()}
            key={`${headerGroup.headers[i].Header}-${i}`}
          >
            {headerGroup.headers.map((clmn) => {
              const column = clmn as unknown as HomeOfficeHeaderGroup<
                Record<string, unknown>
              >
              return (
                <TH
                  {...column.getHeaderProps({
                    style: {
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                      maxHeight: 40,
                    },
                  })}
                  key={column.id}
                >
                  <HeaderContainer
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                        maxHeight: 40,
                      },
                    })}
                    aria-label={`${column.Header}`}
                  >
                    {isHeaderToolTip(column.Header as string) && (
                      <Tooltip
                        placement="top"
                        title={
                          <PivotTableHeaderToolTipContent
                            aria-haspopup="true"
                            role="button"
                          >
                            <TooltipWrap data-testid="pivot-table-tooltip">
                              {renderToolTipContent(column.Header as string)}
                            </TooltipWrap>
                          </PivotTableHeaderToolTipContent>
                        }
                      >
                        <StyledInfoIcon />
                      </Tooltip>
                    )}

                    {column.render('Header')}

                    {
                      // below is necessary to pass type check on column.getSortByToggleProps()
                      column.getSortByToggleProps && !column.disableSortBy && (
                        <ArrowContainer
                          {...column.getHeaderProps({
                            ...column.getSortByToggleProps(),
                          })}
                        >
                          {column.isSorted ? (
                            <>
                              <StyledArrowUpIconSorted
                                fill={
                                  !column.isSortedDesc
                                    ? 'rgb(var(--colors-neutral-900))'
                                    : 'rgb(var(--colors-neutral-300))'
                                }
                              />
                              <StyledArrowDownIconSorted
                                fill={
                                  column.isSortedDesc
                                    ? 'rgb(var(--colors-neutral-900))'
                                    : 'rgb(var(--colors-neutral-300))'
                                }
                              />
                            </>
                          ) : (
                            <>
                              <StyledArrowUpUnsorted />
                              <StyledArrowDownUnsorted />
                            </>
                          )}
                        </ArrowContainer>
                      )
                    }
                  </HeaderContainer>
                </TH>
              )
            })}
          </HeaderRow>
        ))}
      </THead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <TR {...row.getRowProps()} key={row.id}>
              {row.cells.map((cell) => {
                return (
                  <TD
                    isTablet={isTablet}
                    {...cell.getCellProps()}
                    key={cell.getCellProps().key}
                  >
                    {cell.render('Cell')}
                  </TD>
                )
              })}
            </TR>
          )
        })}
      </Tbody>
    </Table>
  )
}
