import { Skeleton } from '@mui/material'

import { SPACING } from '@cais-group/shared/ui/design-tokens'

import { Cell, Row } from './elements'

export const SkeletonRow = ({
  columns,
}: {
  columns: Array<{ size?: string | number }>
}) => (
  <Row>
    {columns.map(({ size = 150 }, i) => (
      <Cell
        key={`skeleton-row-${i}`}
        style={{
          boxSizing: 'border-box',
          flex: `${size} 0 auto`,
          minWidth: '0px',
          width: `${size}px`,
        }}
      >
        <Skeleton
          variant="rectangular"
          width={Number(size) - 50}
          height={SPACING.s24}
        />
      </Cell>
    ))}
  </Row>
)

export const SkeletonRows = ({
  count,
  columns,
}: {
  count: number
  columns: Array<{ size?: string | number }>
}) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <SkeletonRow key={index} columns={columns} />
      ))}
    </>
  )
}
