import type { GraphQLError } from 'graphql'

import { pathOr } from './utils'

type GlobalViolation = unknown
type FieldViolation = {
  fieldName: string
  message: string
}
type ErrorResponsePayload = {
  status: number
  errorCode: string
  errorMessage: 'Bad Request'
  errorAttributes: {
    violations: {
      fieldViolations: FieldViolation[]
      globalViolations: GlobalViolation[]
    }
  }
}

export type ErrorResponse = {
  errors: Partial<GraphQLError>[]
}
function isErrorWithNonEmptyArrayOfErrors(
  error: unknown
): error is ErrorResponse {
  return (
    typeof error === 'object' &&
    error !== null &&
    'response' in (error as { response: unknown }) &&
    typeof (error as { response: unknown }).response === 'object' &&
    (error as { response: unknown }).response !== null &&
    'errors' in (error as { response: ErrorResponse }).response &&
    Array.isArray((error as { response: ErrorResponse }).response.errors) &&
    (error as { response: ErrorResponse }).response.errors.length > 0
  )
}

export type ErrorWithResponse = {
  response: ErrorResponse
}
export function isGraphQLError(error: unknown): error is ErrorWithResponse {
  if (
    typeof error === 'object' &&
    error !== null &&
    'response' in error &&
    isErrorWithNonEmptyArrayOfErrors(error)
  ) {
    return true
  }
  return false
}

export const __SPECS__ = { isErrorWithNonEmptyArrayOfErrors }

type FieldErrors = {
  status?: number
  errorCode?: string
  errorMessage?: string
  fieldViolations: FieldViolation[]
  globalViolations: GlobalViolation[]
}
type MessageError = { status?: number; message: string }

export function extractGraphQLError(
  response: ErrorResponse
): FieldErrors | MessageError {
  const error = pathOr<Partial<ErrorResponsePayload>, undefined>(
    undefined,
    ['errors', 0, 'extensions', 'error'],
    response
  )
  const violations = pathOr<
    { fieldViolations: FieldViolation[]; globalViolations: GlobalViolation[] },
    undefined
  >(undefined, ['errorAttributes', 'violations'], error)

  if (violations) {
    return {
      status: error?.status,
      errorCode: error?.errorCode,
      errorMessage: error?.errorMessage,
      fieldViolations: violations?.fieldViolations ?? [],
      globalViolations: violations?.globalViolations ?? [],
    }
  }

  return {
    status: error?.status,
    message: pathOr('', ['errors', 0, 'message'], response),
  }
}
