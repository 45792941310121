import styled from '@emotion/styled'
import { AxiosError } from 'axios'
import { PropsWithChildren, ReactElement } from 'react'
import {
  FormProvider,
  UseFormReturn,
  SubmitHandler,
  FieldValues,
  UseFormSetError,
} from 'react-hook-form'

import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { ErrorResponse } from '@cais-group/shared/util/type/caisiq-be'

export interface LayoutSettingsPageProps<TFields extends FieldValues> {
  methods: UseFormReturn<TFields>
  onSubmit: SubmitHandler<TFields>
  handleFormError?(
    e: AxiosError<ErrorResponse>,
    setError: UseFormSetError<TFields>
  ): void
  headerNav?: ReactElement
  sideNavigation?: ReactElement
  showSidebar?: boolean
  isOpen?: boolean
  /** provide form id to be able to submit from outside */
  id?: string
}

const LayoutGrid = styled.div<{ showSidebar?: boolean }>`
  display: grid;
  grid-template-columns: ${({ showSidebar }) =>
    showSidebar ? '1fr 5fr;' : '48px 4fr'};

  grid-template-rows: auto;
  grid-template-areas: 'headernav headernav' 'sidebar formcontent';
  gap: ${SPACING.s32};
  padding-bottom: ${SPACING.s32};
`

const HeaderArea = styled.header`
  position: sticky;
  top: 0;
  grid-area: headernav;
  border-bottom: 1px solid ${colors['eq-color-neutral-200']};
  padding: 0 ${SPACING.s32};
  background-color: ${colors['eq-color-neutral-0']};
  z-index: 10;
`

const FormArea = styled.form`
  grid-area: formcontent;
  padding-top: ${SPACING.s24};
  display: grid;
  grid-template-columns: 1fr max-content;
`

const SideNavigationArea = styled.nav`
  grid-area: sidebar;
`

export function LayoutSettingsPage<TFields extends FieldValues>({
  children,
  methods,
  sideNavigation,
  headerNav,
  onSubmit,
  handleFormError,
  showSidebar = true,
  isOpen,
  id,
}: PropsWithChildren<LayoutSettingsPageProps<TFields>>) {
  return (
    <FormProvider {...methods}>
      <LayoutGrid showSidebar={showSidebar}>
        <HeaderArea className="shadow-2">{headerNav}</HeaderArea>
        <FormArea
          id={id}
          onSubmit={(ev) => {
            ev.preventDefault()
            !isOpen &&
              methods
                .handleSubmit(onSubmit, () => {
                  // Scroll to top of page if a validation error occurs
                  setTimeout(() => window.scrollTo(0, 0), 100)
                })(ev)
                .catch((e) => {
                  handleFormError && handleFormError(e, methods.setError)
                })
            ev.stopPropagation()
          }}
        >
          {children}
        </FormArea>
        <SideNavigationArea aria-label="side-navigation">
          {sideNavigation}
        </SideNavigationArea>
      </LayoutGrid>
    </FormProvider>
  )
}
