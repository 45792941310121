import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'

import { H4, BodySmall } from '@cais-group/approved/ui/typography'
import { logoutService } from '@cais-group/caisiq/util/logout-service'
import { colors } from '@cais-group/equity/particles/colors'
import { ContextMenuApp } from '@cais-group/shared/ui/context-menu/app'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { FooterDefault } from '@cais-group/shared/ui/footer/default'
import { LayoutCenter } from '@cais-group/shared/ui/layout'

const OuterContainer = styled.div`
  overflow: hidden;
`

const Container = styled.div`
  background-color: rgb(var(--colors-neutral-0));
  padding: var(--s144);
  padding: var(--s144) var(--s16) var(--s144) var(--s16);
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    padding: var(--s144);
  }
`

const Description = styled(BodySmall)`
  margin-top: var(--s24);
`

const CircleBg = styled.div`
  background-color: rgb(var(--colors-warning-600));
  height: 186px;
  width: calc(186px * 2);
  border-top-left-radius: calc(186px * 2);
  border-top-right-radius: calc(186px * 2);
  position: relative;
  left: 65%;
`

const StyledAppContextMenu = styled(ContextMenuApp)`
  position: absolute;
  top: var(--s16);
  right: var(--s8);
`

export const EmailNotAllowedMessage = () => {
  const { logout: auth0Logout } = useAuth0()
  return (
    <OuterContainer>
      <StyledAppContextMenu fill={colors['eq-color-neutral-900']} />
      <Container>
        <LayoutCenter centerText={true}>
          <H4>You are not authorized to sign up</H4>
          <Description>
            Please{' '}
            <a
              href="/"
              onClick={(event) => {
                event.preventDefault()
                logoutService.logout(auth0Logout)
              }}
            >
              try again
            </a>{' '}
            using a valid work email.
          </Description>
        </LayoutCenter>
      </Container>
      <CircleBg></CircleBg>
      <FooterDefault />
    </OuterContainer>
  )
}
