import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  CaisiqArticleFilter,
  CaisiqVideoFilter,
  CaisiqVideoPlaylistFilter,
  ExternalContentFilter,
  TagFilter,
  WhitepaperFilter,
} from '@cais-group/shared/util/graphql/mfe-contentful'

import { FIRMS_COLLECTION_LIMIT, TAGS_COLLECTION_LIMIT } from '../constants'

type DefaultFilter = Record<string, unknown>
type AllResearchFilter =
  | {
      OR?: (
        | {
            category_exists: boolean
            category_not?: undefined
          }
        | {
            category_not: string
            category_exists?: undefined
          }
      )[]
      tags?: TagFilter
      AND?:
        | CaisiqArticleFilter['AND']
        | WhitepaperFilter['AND']
        | CaisiqVideoFilter['AND']
        | CaisiqVideoPlaylistFilter['AND']
        | ExternalContentFilter['AND']
    }[]

const PAGE_SIZE = 20

const makeResearchSearchFilter = (
  where: AllResearchFilter,
  searchText: string
) => {
  const articleWhere = searchText
    ? where?.map((filter) => {
        const contentFilter = filter.AND?.[0]?.OR ?? []
        return {
          ...filter,
          AND: [
            {
              OR: [
                ...contentFilter,
                {
                  description_contains: searchText,
                },
              ],
            },
          ] as CaisiqArticleFilter['AND'],
        }
      })
    : where

  const whitepaperWhere = searchText
    ? where?.map((filter) => {
        const contentFilter = filter.AND?.[0]?.OR ?? []
        return {
          ...filter,
          AND: [
            {
              OR: [
                ...contentFilter,
                {
                  introduction_contains: searchText,
                },
              ],
            },
          ] as WhitepaperFilter['AND'],
        }
      })
    : where

  const videoWhere = searchText
    ? where?.map((filter) => {
        const contentFilter = filter.AND?.[0]?.OR ?? []
        return {
          ...filter,
          AND: [
            {
              OR: [
                ...contentFilter,
                {
                  summary_contains: searchText,
                },
              ],
            },
          ] as CaisiqVideoFilter['AND'],
        }
      })
    : where

  const externalWhere = searchText
    ? where?.map((filter) => {
        const contentFilter = filter.AND?.[0]?.OR ?? []
        return {
          ...filter,
          AND: [
            {
              OR: [
                ...contentFilter,
                {
                  description_contains: searchText,
                },
              ],
            },
          ] as ExternalContentFilter['AND'],
        }
      })
    : where

  return {
    articleWhere: { AND: articleWhere },
    whitepaperWhere: { AND: whitepaperWhere },
    videoWhere: { AND: videoWhere },
    videoPlaylistWhere: { AND: videoWhere },
    externalWhere: { AND: externalWhere },
  }
}

export function makeQueryFilters(
  filters: Record<string, string[]>,
  defaultFilter: DefaultFilter,
  searchText = ''
) {
  const and = searchText
    ? {
        AND: [
          {
            OR: [
              {
                title_contains: searchText,
              },
            ],
          },
        ],
      }
    : {}
  const common = {
    ...defaultFilter,
    ...and,
  }
  const orTagGroups = Object.values(filters || {})
    .filter((tagGroup) => tagGroup.length > 0)
    .map((orTagGroup) => ({
      ...common,
      tags: {
        tagId_in: orTagGroup,
      },
    }))

  return orTagGroups.length > 0
    ? orTagGroups
    : [
        {
          ...common,
        },
      ]
}
export function makeResearchQueryVariables(
  filters: Record<string, string[]>,
  limit = PAGE_SIZE,
  defaultFilter: DefaultFilter = {},
  searchText = ''
) {
  const where = makeQueryFilters(filters, defaultFilter, searchText)

  const {
    articleWhere,
    videoPlaylistWhere,
    videoWhere,
    whitepaperWhere,
    externalWhere,
  } = makeResearchSearchFilter(where, searchText)

  return {
    limit,
    preview: previewService.enabled,
    articleWhere,
    videoWhere,
    videoPlaylistWhere,
    whitepaperWhere,
    externalWhere,
    firmsLimit: FIRMS_COLLECTION_LIMIT,
    tagsLimit: TAGS_COLLECTION_LIMIT,
  }
}
