import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { Course } from '@cais-group/shared/util/type/caisiq-be'

import { useGetLibraryCourses } from './useGetLibraryCourses'

export const useFilterLibraryCoursesBySearch = () => {
  const searchTerm = useRecoilValue(searchTermAtom)
  const courses = useGetLibraryCourses()

  return useMemo(() => {
    if (!courses.data) {
      return undefined
    }
    return filterLibraryCoursesBySearch(searchTerm, courses.data)
  }, [courses.data, searchTerm])
}

/**
 * Using this function directly will result in many rerenders
 * as it will create a new set of courses each render
 */
export const filterLibraryCoursesBySearch = (
  searchTerm: string,
  courses: Course[]
) => {
  return courses?.filter((libraryCourse) => {
    if (searchTerm === '') return true

    const target = searchTerm.toLowerCase()
    return (
      libraryCourse.name.toLowerCase().includes(target) ||
      libraryCourse.description.toLowerCase().includes(target) ||
      libraryCourse.theme?.toLowerCase()?.includes(target)
    )
  })
}
