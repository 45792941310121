import {
  ColumnDef,
  ColumnSort,
  Row,
  SortingState,
  TableOptions,
} from '@tanstack/react-table'
import { useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { BodySmall } from '@cais-group/approved/ui/typography'
import {
  useGetManageCaisIqFirms,
  useAsyncExpandableFirmsRow,
  DEBOUNCE_RATE,
} from '@cais-group/caisiq/domain/manage-caisiq'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { EnableButton } from '@cais-group/caisiq/ui/manage/enable-button'
import { FirmNameBox, ExpandableCell } from '@cais-group/caisiq/ui/table/manage'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { Icon } from '@cais-group/equity/atoms/icon'
import { StatusTag } from '@cais-group/equity/atoms/status-tag'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING, SPACING_UNITS } from '@cais-group/shared/ui/design-tokens'
import { useDebouncedState } from '@cais-group/shared/util/hook/use-debounced-state'
import { CaisIqFirm } from '@cais-group/shared/util/type/caisiq-be'

import { ManageFirms } from './caisiq-feature-manage-firms'

export const getColor = (info: { row: Row<CaisIqFirm> }) => {
  const caisIQ = info.row.original.active
  return !caisIQ
    ? colors['eq-color-neutral-600']
    : colors['eq-color-neutral-900']
}

const getSortParam = (sorting: ColumnSort): string => {
  if (!sorting) {
    return 'name'
  }
  if (sorting.desc === true) {
    return `-${sorting.id}`
  }
  return sorting.id
}

const checkIfValidUUID = (str: string) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

export const ManageFirmsContainer = () => {
  const navigate = useNavigate()
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false },
  ])
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)

  const debouncedSearchTerm = useDebouncedState(searchTerm, DEBOUNCE_RATE)

  useEffect(() => {
    trackingService.managePageViewed()
  }, [])

  const sortConfigApplied = sorting[0]

  const filter = useMemo(() => {
    return !debouncedSearchTerm
      ? undefined
      : checkIfValidUUID(debouncedSearchTerm)
      ? { parentFirmId: debouncedSearchTerm }
      : { searchText: debouncedSearchTerm }
  }, [debouncedSearchTerm])

  const {
    data: firmData,
    fetchNextPage,
    hasNextPage = false,
    isLoading,
    isFetched,
  } = useGetManageCaisIqFirms({
    sort: [getSortParam(sortConfigApplied)],
    filter,
  })

  const firmsDataMap = useMemo(() => {
    if (isLoading || !firmData?.pages) {
      return []
    }
    return firmData.pages.flatMap((page) => page.items)
  }, [firmData, isLoading])

  const { rows, handleClickRow, isRowLoading } =
    useAsyncExpandableFirmsRow(firmsDataMap)

  const firmColumns = useMemo<ColumnDef<CaisIqFirm>[]>(
    () => [
      {
        header: 'Firm name',
        accessorKey: 'name',
        id: 'name',
        sortingFn: 'alphanumeric',
        cell: ({ row, getValue }) => {
          const name = getValue<string>()
          const caisiqDisabled = !row.original.active
          if (!row.getCanExpand()) {
            return (
              <div
                style={{ paddingLeft: `${row.depth * SPACING_UNITS.s24}px` }}
              >
                <FirmNameBox
                  name={name}
                  isDimmed={row.depth > 0 || caisiqDisabled}
                >
                  {name}
                </FirmNameBox>
              </div>
            )
          }
          return (
            <div style={{ paddingLeft: `${row.depth * SPACING_UNITS.s24}px` }}>
              <ExpandableCell
                data-testid="expandable-cell"
                row={row}
                onExpandClick={() => {
                  handleClickRow(row)
                  row.toggleExpanded()
                }}
              >
                <FirmNameBox
                  name={name}
                  isDimmed={row.depth > 0}
                  data-testid="firm-name-box"
                >
                  {name}
                </FirmNameBox>
              </ExpandableCell>
            </div>
          )
        },
        meta: {
          ratio: 3,
        },
      },
      {
        header: 'Experience name',
        accessorFn: (originalRow) => originalRow?.experience?.name,
        accessorKey: 'experience.name',
        enableSorting: false,
        cell: (info) => {
          const isDefaultExperience =
            info.getValue() === 'CAIS Network Advanced'
          return (
            <>
              <BodySmall
                style={{ color: getColor(info), marginRight: SPACING.s8 }}
              >
                {isDefaultExperience ? 'CAIS Network' : info.getValue<string>()}
              </BodySmall>
              {isDefaultExperience ? (
                <StatusTag color="neutral" variant="light">
                  Default
                </StatusTag>
              ) : null}
            </>
          )
        },
        meta: {
          ratio: 2,
        },
      },
      {
        header: 'Offers CE',
        accessorFn: (originalRow) => originalRow?.experience?.ceCreditEnabled,
        accessorKey: 'experience.ceCreditEnabled',
        enableSorting: false,
        cell: (info) => {
          const offersCeText = info.row.original.active
            ? info.row.original?.experience?.ceCreditEnabled
              ? 'yes'
              : 'no'
            : '-'
          return (
            <BodySmall style={{ color: getColor(info) }}>
              {offersCeText}
            </BodySmall>
          )
        },
        meta: {
          ratio: 0,
          alignment: 'flex-start',
        },
      },
      {
        header: 'Number of courses',
        accessorFn: (originalRow) => originalRow?.experience?.coursesCount,
        accessorKey: 'experience.coursesCount',
        enableSorting: false,
        cell: (info) => {
          return (
            <BodySmall style={{ color: getColor(info) }}>
              {info.getValue<string>() ?? '-'}
            </BodySmall>
          )
        },
        size: 120,
        meta: {
          ratio: 0,
          alignment: 'flex-end',
        },
      },
      {
        header: 'CAIS IQ enabled',
        accessorKey: 'active',
        id: 'active',
        enableSorting: false,
        cell: (info) =>
          info.getValue() ? (
            <Icon
              size="small"
              color="eq-color-success-600"
              type="Check"
              aria-label="enabled-icon"
            />
          ) : (
            <EnableButton
              onEnable={() =>
                navigate(ROUTES.enableCaisIq(info.row.original.id))
              }
              data-testid="enable-button"
            />
          ),
        meta: {
          ratio: 0,
          alignment: 'center',
        },
      },
    ],
    [handleClickRow, navigate]
  )

  return (
    <ManageFirms
      data-testid="manage-firms-container"
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      searchTerm={debouncedSearchTerm}
      setSearchTerm={setSearchTerm}
      isFetched={isFetched}
      getRowCanExpand={(row) => row.original.hasChildFirms}
      getSubRows={(row) => row.children}
      isRowLoading={isRowLoading}
      tableOptions={
        {
          onSortingChange: setSorting,
          state: {
            sorting: sorting,
          },
          columns: firmColumns,
          data: rows,
        } as TableOptions<CaisIqFirm>
      }
    />
  )
}
