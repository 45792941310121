import styled from '@emotion/styled'

import { CeProfileCourseCard } from '@cais-group/caisiq/ui/card/course/ce-profile'
import { BREAKPOINTS, SPACING } from '@cais-group/shared/ui/design-tokens'
import { CourseCredits } from '@cais-group/shared/util/type/caisiq-be'

const StyledCardsContainer = styled.div<{ cols?: number }>`
  place-self: center;
  max-width: calc(100vw - ${SPACING.s32});
  /* ipad */
  @media screen and (min-width: ${BREAKPOINTS.breakpointLg}) {
    grid-column: auto / span 12;
  }
`

interface CeCreditProfileCardsProps {
  coursesWithEarnedCredits: CourseCredits[]
}

export const CeCreditProfileCards: React.FC<CeCreditProfileCardsProps> = ({
  coursesWithEarnedCredits,
}) => {
  return (
    <StyledCardsContainer>
      {coursesWithEarnedCredits.map((course) => (
        <CeProfileCourseCard course={course} key={course.courseMetadataUuid} />
      ))}
    </StyledCardsContainer>
  )
}
