import styled from '@emotion/styled'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { H4Strong } from '@cais-group/approved/ui/typography'
import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { LayoutSettingsPage } from '@cais-group/caisiq/ui/layout/settings-page'
import { CapabilitiesStep } from '@cais-group/caisiq/ui/manage/capabilities-step'
import { CourseConfigurationStep } from '@cais-group/caisiq/ui/manage/course-configuration-step'
import { filterExperienceCoursesBySearch } from '@cais-group/caisiq/ui/manage/course-configuration-table'
import { EnableDisableBox } from '@cais-group/caisiq/ui/manage/enable-disable-box'
import { ExperienceNameStepDropdown } from '@cais-group/caisiq/ui/manage/experience-name-step'
import { NavigationSidebar } from '@cais-group/caisiq/ui/navigation/sidebar'
import {
  ExperienceFields,
  ExperienceListBox,
} from '@cais-group/caisiq/util/type/manage-caisiq'
import { colors } from '@cais-group/equity/particles/colors'
import { BREAKPOINTS, SPACING } from '@cais-group/shared/ui/design-tokens'
import {
  CaisIqFirm,
  Catalog,
  CourseMetadata,
  ExperienceResponse,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'

import { SideBarLink } from './caisiq-feature-manage-edit-firm-container'
import { EditFirmsHeader } from './components/EditFirmsHeader'

const FirmSettingsGridContainer = styled.div`
  min-height: calc(100vh - 320px); /* minus footer height */
`

const GridAreaMain = styled.div`
  display: grid;
  padding: 0 ${SPACING.s32};
  column-gap: ${SPACING.s32};
  grid-template-columns: 1fr;
  justify-content: space-between;
  grid-template-areas: 'head .' 'form box';
  grid-auto-columns: 1fr;

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    padding: 0 ${SPACING.s56};
  }
`
/**
 * @todo we should probably consider redesigning the sidebar without a boxShadow
 * but adding extra height fixes the issue for now.
 */
const SidebarContainer = styled.div<{ showSidebar: boolean }>`
  box-shadow: 0 ${SPACING.s4} ${SPACING.s16} rgba(0, 0, 0, 0.04);
  height: calc(100% + 64px);
  position: relative;
  flex-direction: column;
  background: ${colors['eq-color-neutral-0']};
  padding: 0 ${({ showSidebar }) => (showSidebar ? '48px' : `${SPACING.s24}`)};
  margin-top: -${SPACING.s32};
`

const FormLeftContainer = styled.section`
  max-width: 560px;
  grid-area: form;
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    flex-direction: column;
    grid-column: 1;
    grid-row: 2;
    max-width: 560px;
    align-self: center;
    justify-content: center;
  }
`

const FormRightContainer = styled.section`
  place-self: start;
  grid-area: box;
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${SPACING.s56};
  justify-self: end;
  @media screen and (min-width: ${BREAKPOINTS.breakpointLg}) {
    max-width: 364px;
  }
  & .MuiBox-root {
    min-width: 228px;
    width: 100%;
    max-width: inherit;
  }

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    flex-direction: column;
    grid-column: 2;
    grid-row: 2;
  }
`
const StyledH4Strong = styled(H4Strong)<{ color: string }>`
  color: ${({ color }) => color};
`

const SubHeaderContainer = styled.div`
  display: flex;
  margin-top: calc(${SPACING.s56});
`

const getEnableDisableOptions = (firm: CaisIqFirm, active?: boolean) => {
  if (active) {
    return {
      title: 'Disable CAIS IQ',
      body: `All users at ${firm.name} will lose access to the CAIS IQ app.`,
      buttonText: 'Disable CAIS IQ',
    }
  } else {
    return {
      title: 'Enable CAIS IQ',
      body: `All users at ${firm.name} will have access to the CAIS IQ app.`,
      buttonText: 'Enable CAIS IQ',
    }
  }
}

export type EditFirmProps = {
  sidebarLinks: SideBarLink[]
  firmExperiences: ExperienceResponse[]
  firm: CaisIqFirm
  catalogs: Catalog[]
  handleSubmit(request: ExperienceFields): void
  defaultCourses?: CourseMetadata[]
  coursesFetched: boolean
  contentfulExperiences?: ExperienceListBox
}

export const EditFirm = ({
  sidebarLinks,
  firmExperiences,
  firm,
  catalogs,
  handleSubmit,
  defaultCourses = [],
  coursesFetched,
  contentfulExperiences,
}: EditFirmProps) => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)

  const [showSidebar, setShowSidebar] = useState(true)
  const navigate = useNavigate()
  const methods = useForm<ExperienceFields>({
    defaultValues: {
      name: firm.experience?.uuid,
      label: firm.experience?.label,
      contentfulId: firm.experience?.contentfulId as string,
      coursesEnabled: firm.experience?.coursesEnabled,
      ceCreditEnabled: firm.experience?.ceCreditEnabled,
      active: firm.active,
      catalogId: firm.experience?.catalog?.id,
      courses: defaultCourses || [],
    },
  })

  const handleEnableDisable = (active: boolean) => {
    methods.setValue('active', active, {
      shouldDirty: true,
    })
  }
  const experienceCourses = methods.watch('courses')

  const maybeSearchFilteredExperienceCourses = filterExperienceCoursesBySearch(
    searchTerm,
    experienceCourses
  )

  return (
    <LayoutSettingsPage<ExperienceFields>
      onSubmit={handleSubmit}
      methods={methods}
      showSidebar={showSidebar}
      sideNavigation={
        <SidebarContainer showSidebar={showSidebar}>
          <NavigationSidebar
            onBack={() => {
              setSearchTerm('')
              navigate(-1)
            }}
            links={sidebarLinks}
            firm={{ caisId: firm.id, name: firm.name }}
            backButtonText="Back"
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
          />
        </SidebarContainer>
      }
    >
      <FirmSettingsGridContainer>
        <GridAreaMain>
          <EditFirmsHeader />
          <FormLeftContainer>
            <ExperienceNameStepDropdown
              firmExperiences={firmExperiences}
              contentfulExperiences={contentfulExperiences}
              legend={
                <SubHeaderContainer>
                  <StyledH4Strong color={colors['eq-color-neutral-900']}>
                    Experience
                  </StyledH4Strong>
                  <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
                    <StyledH4Strong color={colors['eq-color-error-600']}>
                      *
                    </StyledH4Strong>
                  </HasPermissions>
                </SubHeaderContainer>
              }
              order={0}
            />
            <CapabilitiesStep
              disabled
              order={0}
              legend="Capabilities"
              catalogs={catalogs}
              formLabels={{ catalogId: 'ALM Course Catalog' }}
            />
            <CourseConfigurationStep
              order={0}
              disabled
              legend="Course Configuration"
              courses={maybeSearchFilteredExperienceCourses}
              isFetched={coursesFetched}
            />
          </FormLeftContainer>
          <FormRightContainer>
            <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
              <EnableDisableBox
                onSubmit={() =>
                  handleEnableDisable(Boolean(!methods.watch('active')))
                }
                options={getEnableDisableOptions(firm, methods.watch('active'))}
                control={methods.control}
                testId="experience-enable-disable-button"
              />
            </HasPermissions>
          </FormRightContainer>
        </GridAreaMain>
      </FirmSettingsGridContainer>
    </LayoutSettingsPage>
  )
}
