import type { Node } from '@contentful/rich-text-types'
import { BLOCKS } from '@contentful/rich-text-types'
import { Children, ReactNode } from 'react'

const trimHeaderWhiteSpace = (header: string) => {
  return header.replace(/\s{2,}/g, ' ')
}
export const richHeroHeader = {
  [BLOCKS.PARAGRAPH]: (_: Node, children: ReactNode) => (
    <p className="headline-l-strong rich-hero-header-p text-wrap">
      {trimHeaderWhiteSpace(Children.toArray(children)[0] as string)}
    </p>
  ),
}
