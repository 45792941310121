import styled from '@emotion/styled'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InputLabel from '@mui/material/InputLabel'
import Tooltip from '@mui/material/Tooltip'
import { ReactNode } from 'react'

import { BodySmallCssObject } from '@cais-group/approved/ui/typography'
import { StatusTag, StatusTagProps } from '@cais-group/equity/atoms/status-tag'

const StyledInputLabel = styled(InputLabel)`
  ${BodySmallCssObject};
  color: rgb(var(--colors-neutral-900));
  white-space: break-spaces;
`
const StyledInfoIcon = styled(InfoOutlinedIcon)`
  color: ${({ theme }) => theme.palette.neutral.dark};
  height: var(--s16);
  width: var(--s16);
  margin-left: var(--s4);
`
const StyledLabelDetailsContainer = styled.div`
  ${BodySmallCssObject};
  display: flex;
  align-items: center;
  margin-top: var(--s4);
  margin-bottom: var(--s4);
`

export type TextInputLabelProps = {
  label: ReactNode
  htmlFor?: string
  description?: string
  tag?: StatusTagProps
  required?: boolean
  className?: string
}

export function TextInputLabel({
  htmlFor,
  label,
  description,
  tag,
  required,
  className,
}: TextInputLabelProps) {
  return (
    <StyledLabelDetailsContainer>
      <StyledInputLabel
        data-testid={`${htmlFor?.replace(/\s/g, '-')}-label`}
        htmlFor={htmlFor}
        required={required}
        className={className}
      >
        {label}
      </StyledInputLabel>
      {description && (
        <Tooltip title={description} placement="top">
          <StyledInfoIcon data-testid={`${htmlFor}-info-icon`} />
        </Tooltip>
      )}
      {tag?.children !== '' && tag?.children !== undefined && (
        <div className="my-4 ml-8">
          <StatusTag {...tag} />
        </div>
      )}
    </StyledLabelDetailsContainer>
  )
}

export default TextInputLabel
