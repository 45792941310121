import { useMutation } from 'react-query'

import { defaultPersister } from '@cais-group/caisiq/util/default-query-function'
import {
  ApiPaths,
  CreateExperienceRequest,
  ExperienceResponse,
} from '@cais-group/shared/util/type/caisiq-be'

export function usePostExperience() {
  return useMutation((data: CreateExperienceRequest) =>
    defaultPersister<CreateExperienceRequest, ExperienceResponse>(
      ApiPaths.createExperience,
      data
    )
  )
}
