import { Theme as DefaultTheme } from '@emotion/react'

/**
 * Theme used where a theme cannot be loaded, such as if you are not logged in
 */
export const ThemeBackup: Partial<DefaultTheme> = {
  name: 'ThemeBackup',
  navHeaderWithHero: {
    backgroundColor: 'transparent',
    foregroundColor: '#ffffff',
    underlineColor: '#e3e3e3',
    underlineOpacity: 0.14,
    caisIqLogo: 'light',
    clientLogoUrl: undefined,
    poweredBy: undefined,
  },

  navHeaderWithIframe: {
    backgroundColor: '#ffffff',
    foregroundColor: '#211c21',
    underlineColor: '#e3e3e3',
    underlineOpacity: 0.14,
    caisIqLogo: 'dark',
    clientLogoUrl: undefined,
    poweredBy: undefined,
  },
  navHeaderForAdmin: {
    backgroundColor: 'transparent',
    foregroundColor: '#211c21',
    underlineColor: '#e3e3e3',
    underlineOpacity: 0.14,
    caisIqLogo: 'dark',
    clientLogoUrl: undefined,
    poweredBy: undefined,
  },
}
