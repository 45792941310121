import { UserProfileV1 } from '@cais-group/access-manager/domain/api'

import type { EnabledAccessControls, Feature } from './types'

export const getUserEnabledFeatures = (
  features: UserProfileV1['features'] = []
): EnabledAccessControls['enabledFeatures'] => {
  return (
    features.reduce<Feature[]>((acc, feature) => {
      if (feature.status === 'ON') {
        return [...acc, feature.name] as Feature[]
      }
      return acc
    }, []) || []
  )
}
