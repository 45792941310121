export {
  default as IconCeCreditsSvg,
  ReactComponent as IconCeCredits,
} from '../assets/ce-credits.svg'

export {
  default as IconCertificateSvg,
  ReactComponent as IconCertificate,
} from '../assets/certificate.svg'

export {
  default as IconExitAndArrowSvg,
  ReactComponent as IconExitAndArrow,
} from '../assets/exit-text-and-arrow.svg'

export {
  default as ManageCaisIqIconSvg,
  ReactComponent as ManageCaisIqIcon,
} from '../assets/manage-caisiq-icon.svg'
