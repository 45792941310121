import { Course } from '@cais-group/shared/util/type/caisiq-be'

/**
 * PageThemeTypes represent the ordering of course themes on the page
 *    Top:    courses with a theme
 *    Bottom: courses without a theme
 */
type PageThemeTypes = {
  thematic: CoursesThemeType
  nonThematic: Course[]
}

type CoursesThemeType = {
  [key: string]: Course[]
}

/**
 * @param courses an array of Course types
 * @returns an object representing the three different course theme types on the courses page
 */
export function coursesByThemeType(courses: Course[]): PageThemeTypes {
  const thematic: CoursesThemeType = {}
  let nonThematic: Course[] = []

  for (const course of courses) {
    const { theme } = course

    if (theme) {
      thematic[theme] = [...(thematic[theme] ?? []), course]
    } else {
      nonThematic = [...(nonThematic ?? []), course]
    }
  }

  return {
    thematic,
    nonThematic,
  }
}
