import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { useGetFilterGroupByIdQuery } from '@cais-group/shared/util/graphql/mfe-contentful'

import { minutesToMilliseconds } from '../helper'

const FIFTEEN_MINUTES = minutesToMilliseconds(15)

export function useGetFilterGroupById(id: string) {
  const { data, isLoading, error } = useGetFilterGroupByIdQuery(
    {
      id,
      preview: previewService.enabled,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: FIFTEEN_MINUTES,
      staleTime: FIFTEEN_MINUTES,
    }
  )

  return {
    data: data?.filterGroupCollection?.items[0]?.filtersCollection?.items,
    isLoading,
    error,
  }
}
