import styled from '@emotion/styled'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { shouldForwardProp } from '@cais-group/approved/ui/typography'
import { uiTypeSize } from '@cais-group/shared/ui/type/size'

/**
 * ### Specification
 *
 * Fills the width of its containing element.
 * Height determined by height of tallest child.
 * There is no horizontal padding so items will reach
 * the very ends of their container.
 * There is a standardised vertical padding.
 * Hitting next/prev will scroll by a single page, or to
 * the end, whichever is less.
 *
 * ### Instructions
 *
 * Use children of equal width and height.
 * Children may scale in size but the entire set of chilren
 * must scale uniformly for this to work.
 *
 * If you want each item to animate its size larger, those items
 * will have to use an absolutely positioned animation
 * container to avoid affecting the carousel layout.
 */

export type CarouselProps = {
  children: Array<ReactElement>
  arrowYPosition?: number // Defaults to 50% of item height
  arrowXPosition?: number
  className?: string
  space?: uiTypeSize // Space between children element, default to var(--s32)
  isMobile?: boolean // Carousel will render differently if true
}

const CarouselContainer = styled.div`
  position: relative;
`

const ScrollContainer = styled.div`
  overflow: auto;
  padding: var(--s24) 0;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const HORIZONTAL_SPACING = 32

const ItemContainer = styled.div<{ space?: uiTypeSize; $isMobile?: boolean }>`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 580px) {
    gap: ${({ space, $isMobile }) => ($isMobile && space ? `${space}` : '0')};
    flex-direction: ${({ $isMobile }) => ($isMobile ? 'row' : 'column')};
    & > * {
      margin-left: auto !important;
      margin-right: auto !important;
      width: ${({ $isMobile }) =>
        $isMobile
          ? 'fit-content !important;'
          : '100% !important;'}; // Important needed for firefox
    }
    & > * + * {
      margin-left: auto !important;
      margin-right: auto !important;
      margin-top: var(--s32);
    }
  }

  & > * {
    flex-shrink: 0;
  }

  & > * + * {
    margin-left: ${({ space }) => space || 'var(--s32)'};
  }
`

const NextPrev = styled('button', {
  shouldForwardProp: shouldForwardProp([
    '$isMobile',
    'visible',
    'arrowYPosition',
    'arrowXPosition',
  ]),
})<{
  visible: boolean
  arrowYPosition?: number
  arrowXPosition?: number
  $isMobile?: boolean
}>`
  cursor: pointer;
  position: absolute;
  top: ${({ arrowYPosition }) =>
    arrowYPosition !== undefined
      ? `${arrowYPosition}px`
      : 'calc(50% - calc(var(--s56) / 2))'};
  width: ${({ $isMobile }) => ($isMobile ? 'var(--s24)' : 'var(--s56)')};
  height: ${({ $isMobile }) => ($isMobile ? 'var(--s24)' : 'var(--s56)')};
  background-color: ${({ $isMobile }) =>
    $isMobile ? 'transparent' : 'var(--color-black)'};
  border-radius: ${({ $isMobile }) =>
    $isMobile ? '0' : 'calc(var(--s56) / 2)'};
  transition: box-shadow 200ms ease-out, visibility 200ms ease-out 200ms,
    opacity 200ms ease-out;
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  &:focus {
    border: 2px solid rgb(var(--colors-neutral-300));
  }
`

const Next = styled(NextPrev, {
  shouldForwardProp: shouldForwardProp('$isMobile'),
})`
  right: 0;
  transform: translate(50%, 0);
  padding-left: ${(props) => (props.$isMobile ? 'var(--eq-s-8)' : '0')};
  & > * {
    transition: transform 200ms ease-out;
    transform: translate(1px, 3px);
  }
  &:hover > * {
    transform: ${({ $isMobile }) =>
      !$isMobile ? 'translate(3px, 3px)' : 'translate(1px, 3px)'};
  }
`

// The position of the left arrow is off in the mobile tabs nav so passing position as prop
const Prev = styled(NextPrev, {
  shouldForwardProp: shouldForwardProp(['$isMobile', 'arrowXPosition']),
})`
  left: ${({ arrowXPosition }) =>
    arrowXPosition ? `${arrowXPosition}px` : '0'};
  transform: translate(-50%, 0);
  padding-right: ${(props) => (props.$isMobile ? 'var(--eq-s-8)' : '0')};
  & > * {
    transition: transform 200ms ease-out;
    transform: translate(-2px, 3px);
  }
  &:hover > * {
    transform: ${({ $isMobile }) =>
      !$isMobile ? 'translate(-4px, 3px)' : 'translate(-2px, 3px)'};
  }
`

type ChevronType = {
  $isMobile?: boolean
}
const StyledChevronRightIcon = styled(ChevronRightIcon, {
  shouldForwardProp: shouldForwardProp('$isMobile'),
})<ChevronType>`
  fill: ${({ $isMobile }) =>
    $isMobile ? 'var(--color-black)' : 'var(--color-white)'};
`

const StyledChevronLeftIcon = styled(ChevronLeftIcon, {
  shouldForwardProp: shouldForwardProp('$isMobile'),
})<ChevronType>`
  fill: ${({ $isMobile }) =>
    $isMobile ? 'var(--color-black)' : 'var(--color-white)'};
`

const NextButton = (props: Parameters<typeof Next>[0]) => {
  return (
    <Next {...props} $isMobile={props['$isMobile']}>
      <StyledChevronRightIcon $isMobile={props['$isMobile']} />
    </Next>
  )
}

const PrevButton = (props: Parameters<typeof Prev>[0]) => {
  return (
    <Prev {...props}>
      <StyledChevronLeftIcon $isMobile={props['$isMobile']} />
    </Prev>
  )
}

export const Carousel = (props: CarouselProps) => {
  const {
    className,
    children,
    arrowYPosition,
    arrowXPosition,
    space,
    isMobile,
  } = props

  const scrollContainerWidth = useRef(0)
  const contentWidth = useRef(0)
  const scrollPosition = useRef(0)
  const animationFrameRef = useRef<number>()
  const [targetScrollPosition, setTargetScrollPosition] = useState<
    number | undefined
  >(undefined)
  const [leftArrowVisible, setLeftArrowVisible] = useState(false)
  const [rightArrowVisible, setRightArrowVisible] = useState(false)

  const scrollContainerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
  const contentRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>

  const updateArrowVisiblity = useCallback(() => {
    if (
      // `<= 0` rather than `=== 0` required due to Safari overscrolling
      contentWidth.current <= scrollContainerWidth.current ||
      scrollPosition.current <= 0
    ) {
      setLeftArrowVisible(false)
    } else {
      setLeftArrowVisible(true)
    }
    if (
      contentWidth.current <= scrollContainerWidth.current ||
      // `>=` rather than `===` required due to Safari overscrolling
      scrollPosition.current >=
        contentWidth.current - scrollContainerWidth.current
    ) {
      setRightArrowVisible(false)
    } else {
      setRightArrowVisible(true)
    }
  }, [contentWidth, scrollContainerWidth])

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      if (scrollContainerRef.current?.scrollLeft !== undefined) {
        scrollPosition.current = scrollContainerRef.current?.scrollLeft
      }
      updateArrowVisiblity()
    },
    [updateArrowVisiblity]
  )

  const updateMeasurements = useCallback(() => {
    if (scrollContainerRef?.current?.clientWidth !== undefined) {
      scrollContainerWidth.current = scrollContainerRef?.current?.clientWidth
    }

    if (scrollContainerRef?.current?.scrollWidth !== undefined) {
      contentWidth.current = scrollContainerRef?.current?.scrollWidth
    }

    updateArrowVisiblity()
  }, [updateArrowVisiblity])

  const getScrollDetails = useCallback(() => {
    // HACK: Chrome bug, should not be required but will get slightly
    // off measurement without it (only affects horizontal, not vertical)
    updateMeasurements()
    const maxScroll = Math.abs(
      contentWidth.current - scrollContainerWidth.current
    )
    const numItems = children.length
    const totalPadding = (numItems - 1) * HORIZONTAL_SPACING
    const itemWidth = (contentWidth.current - totalPadding) / numItems
    const itemWidthWithSinglePadding = itemWidth + HORIZONTAL_SPACING
    const completeItemsPerPage = Math.floor(
      scrollContainerWidth.current / itemWidthWithSinglePadding
    )

    const scrollPerClick = completeItemsPerPage * itemWidthWithSinglePadding

    return {
      maxScroll,
      scrollPerClick,
    }
  }, [updateMeasurements, children.length])

  useLayoutEffect(() => {
    // Hack: this takes an initial measurement before the ResizeObserver
    // It seems that chrome doesn't measure this correctly first time
    // And occasionally not the second time, but always the third!
    updateMeasurements()
  }, [updateMeasurements])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateMeasurements)
    resizeObserver.observe(scrollContainerRef.current)
    resizeObserver.observe(contentRef.current)
    return () => resizeObserver.disconnect()
  }, [updateMeasurements])

  const move = useCallback(() => {
    if (targetScrollPosition === undefined) return
    const diff = targetScrollPosition - scrollPosition.current
    if (Math.abs(diff) >= 1) {
      // This is required because the browser only understands integers
      // for scroll position and it would otherwise never resolve
      const roundingFunction = diff > 0 ? Math.ceil : Math.floor
      scrollContainerRef.current.scrollTo({
        left: scrollPosition.current + roundingFunction(diff / 4),
      })
      animationFrameRef.current = requestAnimationFrame(move)
    } else {
      scrollContainerRef.current.scrollTo({
        left: targetScrollPosition,
      })
      // We need to set this to undefined fire effects when the user scrolls
      // manually, and the new targetScrollPosition is the same as the old one
      setTargetScrollPosition(undefined)
    }
  }, [targetScrollPosition, scrollPosition])

  useEffect(() => {
    if (animationFrameRef.current !== undefined) {
      window.cancelAnimationFrame(animationFrameRef.current)
    }
    animationFrameRef.current = window.requestAnimationFrame(move)
    return () => {
      if (animationFrameRef.current !== undefined) {
        window.cancelAnimationFrame(animationFrameRef.current)
      }
    }
  }, [targetScrollPosition, move])

  const onNext = () => {
    const { scrollPerClick, maxScroll } = getScrollDetails()
    setTargetScrollPosition(
      Math.min(scrollPosition.current + scrollPerClick, maxScroll)
    )
  }

  const onPrev = () => {
    const { scrollPerClick } = getScrollDetails()
    setTargetScrollPosition(
      Math.max(scrollPosition.current - scrollPerClick, 0)
    )
  }

  return (
    <CarouselContainer>
      <ScrollContainer
        ref={scrollContainerRef}
        className={className}
        onScroll={handleScroll}
      >
        <ItemContainer ref={contentRef} space={space} $isMobile={isMobile}>
          {children}
        </ItemContainer>
      </ScrollContainer>
      <PrevButton
        aria-label="Previous Item"
        onClick={onPrev}
        tabIndex={leftArrowVisible ? 0 : -1}
        visible={leftArrowVisible}
        arrowYPosition={arrowYPosition}
        arrowXPosition={arrowXPosition}
        $isMobile={isMobile}
      />
      <NextButton
        aria-label="Next Item"
        onClick={onNext}
        tabIndex={rightArrowVisible ? 0 : -1}
        visible={rightArrowVisible}
        arrowYPosition={arrowYPosition}
        arrowXPosition={arrowXPosition}
        $isMobile={isMobile}
      />
    </CarouselContainer>
  )
}

Carousel.displayName = 'Carousel'
