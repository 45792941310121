import { AppState, useAuth0 } from '@auth0/auth0-react'
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { EmailNotAllowedMessage } from '@cais-group/caisiq/feature/error/email-not-allowed-message'
import { LoginError } from '@cais-group/caisiq/feature/error/login'
import { LoginContainer } from '@cais-group/caisiq/feature/login'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { VerifyEmailMessage } from '@cais-group/caisiq/feature/verify-email-message'
import { acpService } from '@cais-group/caisiq/util/acp-service'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { useQueryParams } from '@cais-group/caisiq/util/hook/use-query-params'
import { loginService } from '@cais-group/caisiq/util/logout-service'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { Experience } from '@cais-group/shared/util/type/experience'

export const AuthRoutes = () => (
  <Routes>
    <Route
      path="cais"
      key="CAIS_IQ_DIRECT"
      element={<SetExperience experience={Experience.CAIS_IQ_DIRECT} />}
    />
    {/**
     *  @todo FO-1546 - Eventually sunset and remove these routes
     */}
    <Route
      path="salt-2021"
      key="SALT_2021"
      element={<SetExperience experience={Experience.CAIS_IQ_DIRECT} />}
    />
    <Route
      path="milken-2021"
      key="MILKEN_2021"
      element={<SetExperience experience={Experience.CAIS_IQ_DIRECT} />}
    />
    <Route
      path="pershing-direct"
      key="PERSHING_DIRECT"
      element={<SetExperience experience={Experience.PERSHING_DIRECT} />}
    />
    <Route path="/acp-oauth-callback" element={<AcpCallbackHandlerRoute />} />
    <Route path="loginerror" element={<LoginError />} />
    <Route path="login" element={<LoginContainer />} />
    <Route path="signup" element={<RedirectToSignup />} />
    <Route path="verifyemail" element={<VerifyEmailMessage />} />
    <Route path="emailnotallowed" element={<EmailNotAllowedMessage />} />
    <Route path="*" element={<SetExperience />} />
  </Routes>
)

export const RedirectToSignup = () => {
  const { loginWithRedirect } = useAuth0()
  const params = useQueryParams()
  const experience = experienceService.getExperience()

  useEffect(() => {
    if (experience) {
      // Track signup redirect event in Segment
      trackingService.signUpRedirect(params)

      loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
          redirect_uri: `${window.location.origin}${ROUTES.home}`,
        },
      })
    }
  }, [experience, loginWithRedirect, params])

  return <LoadingContainer state={LoadingState.LOADING} />
}

function useCheckEmailVerified() {
  const navigate = useNavigate()
  const [params] = useSearchParams()

  useEffect(() => {
    if (
      params.has('error') &&
      params.get('error_description') === 'EMAIL_NOT_VERIFIED'
    ) {
      navigate(ROUTES.verifyEmail)
    }
  })
}

export const CaisAuthProtected: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const navigate = useNavigate()

  useCheckEmailVerified()

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginService.setDeeplink(window.location.pathname)

      if (experienceService.hasCustomLogin()) {
        navigate(`/login`, { replace: true })
      } else {
        loginWithRedirect()
      }
    }
  }, [isLoading, isAuthenticated, navigate, loginWithRedirect])

  if (!isLoading && isAuthenticated) {
    return <ServiceLoader>{children}</ServiceLoader>
  }

  return (
    <LoadingContainer state={LoadingState.LOADING} coverPage="FULL_SCREEN" />
  )
}

const ServiceLoader: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [isLoadingServices, setIsLoadingServices] = useState(true)
  useEffect(() => {
    loginService.onLogin().then(() => setIsLoadingServices(false))
  }, [])

  if (isLoadingServices) {
    return (
      <LoadingContainer state={LoadingState.LOADING} coverPage="FULL_SCREEN" />
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

interface AuthReviewProps {
  experience?: string
}

export const SetExperience = ({ experience }: AuthReviewProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as AppState

  useCheckEmailVerified()

  loginService.setDeeplink(window.location.pathname.replace(/^\/[^/]+/, ''))

  useEffect(() => {
    if (experience) {
      experienceService.setExperience(experience as Experience)
    }

    const t = setTimeout(
      () =>
        navigate(state?.returnTo ? `${state?.returnTo}` : ROUTES.dashboard, {
          replace: true,
        }),
      100
    )
    return () => clearTimeout(t)
  }, [experience, navigate, state])

  return (
    <LoadingContainer state={LoadingState.LOADING} coverPage="FULL_SCREEN" />
  )
}

// This appears in the iframe only
const AcpCallbackHandlerRoute = () => {
  const code = useQueryParams().get('code') as string

  useEffect(() => {
    if (code) {
      acpService.handleCallback(code)
    }
  }, [code])

  return null
}

export function useRedirectToFunds() {
  const navigate = useNavigate()
  const fundId = useQueryParams().get('fundId')

  /**
   * This is a little hacky but needed to handle a possible redirect
   * to the fund page. Currently when a user clicks 'Learn' in Portal
   * they will be redirected to the root CAIS IQ URL with a fund ID
   * as a query param.
   */

  useEffect(() => {
    if (fundId !== null) {
      navigate(ROUTES.fund(fundId))
    }
  }, [fundId, navigate])
}
