import {
  isData,
  useReactQueryResultAsApiState,
} from '@cais-group/caisiq/domain/common'
import {
  GetDefaultCourseImageSetQuery,
  useGetDefaultCourseImageSetQuery,
} from '@cais-group/caisiq/util/graphql/contentful'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'

import { UNIVERSAL_COURSE_IMAGE_SET } from './config'
import { DefaultCourseImage } from './types'

export type DefaultCourseImageSetResult = {
  caisiqDefaultCourseImageSet: {
    imagesCollection: {
      items: Array<DefaultCourseImage>
    }
  }
}

const hourInMs = 1000 * 60 * 60 * 1

export const useGetDefaultCourseImageSet = (options?: { paused: boolean }) => {
  const state = useReactQueryResultAsApiState<
    GetDefaultCourseImageSetQuery,
    DefaultCourseImage[]
  >(
    useGetDefaultCourseImageSetQuery(
      {
        id: UNIVERSAL_COURSE_IMAGE_SET,
        preview: previewService.enabled,
      },
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: hourInMs,
        staleTime: hourInMs,
      }
    ),
    (data) =>
      data.caisiqDefaultCourseImageSet?.imagesCollection
        ?.items as DefaultCourseImage[],
    'Error fetching default course image set'
  )

  return isData(state) ? state : []
}
