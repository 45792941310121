import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import {
  ChangeEvent,
  forwardRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'

import { BodyCss } from '@cais-group/approved/ui/typography'

const Container = styled.div`
  background-color: rgb(var(--colors-neutral-0));
  border: 1px solid rgb(var(--colors-neutral-300));
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 var(--s16);
  &:focus-within {
    outline: 1px solid rgb(var(--colors-primary-600));
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  flex-grow: 0;
  margin-right: var(--s8);
  width: var(--s24);
  height: var(--s24);
`

const StyledCloseIcon = styled(CloseIcon)`
  fill: rgb(var(--colors-neutral-400));
  cursor: pointer;
`

const StyledInput = styled.input`
  ${BodyCss}
  flex-grow: 1;
  background-color: transparent;
  color: rgb(var(--colors-neutral-900));
  text-rendering: geometricPrecision;
  border: none;
  // Align to visual position of icon
  margin-top: 1px;
  margin-right: var(--s8);
  padding: var(--s4);
  ::placeholder {
    color: rgb(var(--colors-neutral-600));
  }
  &:focus {
    outline: none;
  }
`

export type InputSearchProps = {
  className?: string
  id?: string
  onChange?: (value: string) => void
  searchTerm: string
  setSearchTerm: (value: string) => void
  testId?: string
  placeholder?: string
}

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  (props, ref) => {
    const {
      className,
      id = 'search-input',
      onChange,
      placeholder = 'Search CAIS IQ',
      searchTerm,
      setSearchTerm,
      testId = 'search-cais-iq-input',
    } = props
    const ownRef = useRef() as MutableRefObject<HTMLInputElement>
    const [showClearButton, setShowClearButton] = useState(false)

    useEffect(() => {
      setShowClearButton(searchTerm.length > 0)
      onChange && onChange(searchTerm)
    }, [onChange, searchTerm])

    const usedRef = ref || ownRef
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value)
    }

    const handleClear = () => {
      setSearchTerm('')
    }

    return (
      <Container className={className}>
        <StyledSearchIcon aria-hidden="true" />
        <StyledInput
          id={id}
          value={searchTerm}
          ref={usedRef}
          onChange={handleChange}
          placeholder={placeholder}
          data-testid={testId}
        ></StyledInput>
        {showClearButton && (
          <StyledCloseIcon onClick={handleClear}></StyledCloseIcon>
        )}
      </Container>
    )
  }
)
