import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'remeda'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import {
  usePostExperience,
  usePutExperienceCourseMetadata,
} from '@cais-group/caisiq/domain/experience'
import { useFormSaveHandler } from '@cais-group/caisiq/domain/manage-caisiq'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { ManageNavbarHeader } from '@cais-group/caisiq/ui/manage/navbar-header'
import { useContentfulNameFromIdMapper } from '@cais-group/caisiq/util/contentful/use-contentful-name-from-id-mapper'
import { useInvalidateByRegex } from '@cais-group/caisiq/util/default-query-function'
import { useGetExperiencesQuery } from '@cais-group/caisiq/util/graphql/contentful'
import { UseModalState } from '@cais-group/caisiq/util/hooks/use-modal-state'
import { transformCourseMetadataRequest } from '@cais-group/caisiq/util/manage/transform-course-metadata-request'
import { transformExperienceRequest } from '@cais-group/caisiq/util/manage/transform-experience-request'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { ModalType } from '@cais-group/caisiq/util/type/manage-caisiq'
import {
  showBanner,
  showToast,
} from '@cais-group/equity/organisms/notifications'
import { FormStepProvider } from '@cais-group/shared/ui/ordered-fieldset'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { ApiPaths, Catalog } from '@cais-group/shared/util/type/caisiq-be'

import CreateUpdateExperience from './caisiq-feature-manage-create-update-experience'
import { experienceResponseFormErrorHandler } from './hooks/experience-response-form-error-handler'

export function CreateExperiencePageContainer() {
  const { isOpen, onOpen } = UseModalState<ModalType>()

  const navigate = useNavigate()
  const experiences = useGetExperiencesQuery({
    preview: previewService.enabled,
  })
  const catalogs = useQuery<Catalog[]>(ApiPaths.getAllCatalogs)
  const invalidate = useInvalidateByRegex()

  const getContentfulNameById = useContentfulNameFromIdMapper()

  const { mutateAsync, isLoading: experienceMutateLoading } =
    usePostExperience()
  const { mutate: mutateCourses, isLoading: courseMutateLoading } =
    usePutExperienceCourseMetadata()
  const handleSubmit = useFormSaveHandler({
    mutate: mutateAsync,
    transform: transformExperienceRequest,
    onSuccess: async (data, params) => {
      const courses = params?.courses ?? []
      const containsMetadata = params?.coursesEnabled && !isEmpty(courses)
      if (containsMetadata && data?.uuid) {
        const { metadata } = transformCourseMetadataRequest(courses)
        mutateCourses(
          {
            experienceId: data.uuid,
            metadata,
          },
          {
            onSettled: async () => {
              navigate(ROUTES.manageExperiences)
              await invalidate(
                /manage[/]((?:courses[/]metadata)|(?:experiences))/
              )
              showBanner({
                type: 'success',
                title: 'A new experience has been created',
              })
            },
          }
        )
      }
    },
    onError: () => {
      showToast({
        type: 'error',
        title: 'There was an error creating a new experience',
      })
    },
  })

  if (experiences.isFetching) {
    return <LoadingContainer state={LoadingState.LOADING} />
  }

  return (
    <FormStepProvider steps={4}>
      <CreateUpdateExperience
        saveLabel="Create&nbsp;Experience"
        contentfulExperiences={experiences.data?.firmCollection?.items}
        catalogs={catalogs.data || []}
        isOpen={isOpen}
        onOpen={onOpen}
        mutateLoading={experienceMutateLoading || courseMutateLoading}
        handleSubmit={(params) => {
          return handleSubmit(params).then(() => {
            trackingService.manageNewExperienceSubmitted({
              name: params.name,
              label: params.label,
              contentfulName: getContentfulNameById(params.contentfulId),
              almCatalog: params.catalogId ?? '',
              coursesEnabled: params.coursesEnabled,
              ceCreditsEnabled: params.ceCreditEnabled,
              type: params.experienceType,
              firmIds: params.firmIds ?? [],
            })
          })
        }}
        headerNav={
          <ManageNavbarHeader
            submitLabel="Create&nbsp;Experience"
            pageName="Create New Experience"
            onSubmit={handleSubmit}
            handleFormError={experienceResponseFormErrorHandler}
            onOpen={onOpen}
            open={Boolean(isOpen)}
            mutateLoading={courseMutateLoading || experienceMutateLoading}
          />
        }
      />
    </FormStepProvider>
  )
}

export default CreateExperiencePageContainer
