import dayjs from 'dayjs'
import {
  Row,
  UseGroupByCellProps,
  UseRowStateCellProps,
  HeaderGroup,
  TableInstance,
  Cell,
  UseSortByOptions,
  Column,
  UseSortByColumnProps,
  UseExpandedRowProps,
  UsePaginationInstanceProps,
} from 'react-table'

import {
  AlphabeticalOrNumericOrDate,
  WithinGroupServerSortKind,
} from '@cais-group/shared/util/use-within-group-sort-config'

export interface HomeOfficeUserType {
  statuses: StatusesType[]
  pagination: PaginationDataType
}

export type StatusesType = {
  status: HomeOfficeStatusType
  teams: string[]
}
export type HomeOfficeStatusType = {
  id: string
  name?: string
  email?: string
  courses: HomeOfficeCourseStatusCommon[]
  lastStudied?: string | number
  lastCourseCompletionDate?: string
  firmName: string
}

export type HomeOfficeCourseStatusCommon = {
  id: string
  name: string
  learned: boolean
  progress?: number
  learnedAt?: string
  assessment?: Assessment
}

export type Assessment = {
  status: AssessmentStatus
  score?: number
  completed?: string
}

export interface PaginationDataType {
  page: number
  totalPages: number
  limit: number
  totalRecords: number
}
export interface HomeOfficeTableCell<
  D extends Record<string, unknown> = Record<string, unknown>
> extends UseGroupByCellProps<D>,
    UseRowStateCellProps<D>,
    Cell {
  row: CustomSortRowType<Record<string, unknown>>
  rows: CustomSortRowType<Record<string, unknown>>[]
}

export enum AssessmentStatus {
  Failed = 'failed',
  InProgress = 'in_progress',
  Passed = 'passed',
  Unstarted = 'unstarted',
  Locked = 'locked',
  Unlocked = 'unlocked',
  NotApplicable = 'not_applicable',
}

export type CustomSortRowType<T extends object> = Row &
  UseExpandedRowProps<T> & {
    original: {
      [key: string]: string | HomeOfficeCourseStatusCommon[]
    }
  }

export type FormattedHomeOfficePivotData = Omit<
  HomeOfficeCourseStatusCommon,
  'assessment'
> & {
  assessmentScore?: string | number
  assessmentDateCompleted?: string
  assessmentStatus: AssessmentStatus
}

export type ColumnCommon<T extends object> = Column &
  UseSortByColumnProps<T> &
  UseSortByOptions<T> & {
    serverSort: WithinGroupServerSortKind
    disableSortBy?: boolean
    title: string
    id: string
    sort: {
      kind: AlphabeticalOrNumericOrDate
    }
  }

// @ts-ignore - tsCheck job failing here - To be fixed
export interface HomeOfficeHeaderGroup<T extends object>
  extends HeaderGroup,
    UseSortByOptions<T>,
    UseSortByColumnProps<T> {}

export type HomeOfficeTableInstance<D extends object> = TableInstance &
  UsePaginationInstanceProps<D> & {
    page?: CustomSortRowType<Record<string, unknown>>[]
    state: { [key: string]: number }
  }

export type HomeOfficeRequirementMet = {
  name?: string
  email?: string
  teams: string[]
  course: string
  date?: string
  learned: boolean
}

export type FormattedHomeOfficeRequirementMetData = {
  name: JSX.Element
  email: JSX.Element
  teams: JSX.Element
  course: JSX.Element
  productAccessRequirement?: JSX.Element
  date: JSX.Element
  learned: JSX.Element
}

export type FormattedRequirementColumnsAndData<T extends object> = {
  requirementData: FormattedHomeOfficeRequirementMetData[]
  requirementColumns: ColumnCommon<T>[]
}

export interface HomeOfficeCompletedRequirementsTableCellRow<
  D extends Record<string, unknown> = Record<string, unknown>
> extends UseGroupByCellProps<D>,
    UseRowStateCellProps<D> {
  original: {
    course: { props: { children: HomeOfficeCourseStatusCommon } }
    firstName: { props: { children: { props: { children: string } } } }
    lastName: {
      props: {
        children: { props: { children: { props: { children: string } } } }
      }
    }
    email?: { props: { children: { props: { children: string } } } }
    learnedAt: { props: { children: string | number | dayjs.Dayjs | Date } }
    learned: { props: { children: boolean } }
    date: { props: { children: string | number | dayjs.Dayjs | Date } }
  }
}

export interface HomeOfficeCompletedRequirementsTableCell<
  D extends Record<string, unknown> = Record<string, unknown>
> extends UseGroupByCellProps<D>,
    UseRowStateCellProps<D> {
  value: { props: { children: string | number } }
  row: HomeOfficeCompletedRequirementsTableCellRow
}

// TODO: put homeoffice tabs in contentful
export enum TabEnum {
  ALL_LEARNERS = 'All learners',
  COMPLETED_REQUIREMENTS = 'Completed requirements',
}

export enum SlugEnum {
  ALL_LEARNERS = 'all-learners',
  COMPLETED_REQUIREMENTS = 'completed-requirements',
}

export type TabType = {
  tab: TabEnum
  slug: SlugEnum
}[]
