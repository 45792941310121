import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import { Overline, H3Serif, H3Strong } from '@cais-group/approved/ui/typography'
import { LayoutStack } from '@cais-group/shared/ui/layout'

export type CountdownProps = {
  date?: string // Format yyyy-mm-dd
  currentDate?: string // Format yyyy-mm-dd
}
export type TimeLeft = {
  days: number
  hours: number
  minutes: number
  seconds: number
}
export const CountdownContainer = styled.div`
  background-color: rgb(var(--colors-neutral-900));
  padding: var(--s32) var(--s56);
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
const CountdownText = styled(Overline)`
  color: rgb(var(--colors-neutral-0));
  min-width: var(--s88);
`
const Count = styled(H3Strong)`
  color: rgb(var(--colors-neutral-0));
  display: flex;
`
const CountWrap = styled(LayoutStack)`
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const Colons = styled(H3Serif)`
  color: rgb(var(--colors-neutral-0));
  margin: 0 var(--s16);
`

const hasExpiredMoreThanAnHourAgo = (timeStampDifference: number) => {
  const ONE_HOUR = 60 * 60 * 1000

  return timeStampDifference < 0 && Math.abs(timeStampDifference) > ONE_HOUR
}

export const Countdown = (props: CountdownProps) => {
  const { date = new Date(), currentDate = new Date() } = props

  const difference =
    +new Date(date) - (currentDate ? +new Date(currentDate) : +new Date())
  const calculateTimeLeft = () => {
    let timeLeft: TimeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    return clearTimeout(timeout)
  })
  if (hasExpiredMoreThanAnHourAgo(difference)) return null

  return (
    <CountdownContainer data-testid="countdown-timer">
      <CountWrap>
        <Count>{timeLeft.days.toString().padStart(2, '0')}</Count>
        <CountdownText>Days</CountdownText>
      </CountWrap>
      <Colons>:</Colons>
      <CountWrap>
        <Count>{timeLeft.hours.toString().padStart(2, '0')}</Count>
        <CountdownText>Hours</CountdownText>
      </CountWrap>
      <Colons>:</Colons>
      <CountWrap>
        <Count>{timeLeft.minutes.toString().padStart(2, '0')}</Count>
        <CountdownText>Minutes</CountdownText>
      </CountWrap>
      <Colons>:</Colons>
      <CountWrap>
        <Count>{timeLeft.seconds.toString().padStart(2, '0')}</Count>
        <CountdownText>Seconds</CountdownText>
      </CountWrap>
    </CountdownContainer>
  )
}
