import {
  ApiState,
  useReactQueryResultAsApiState,
} from '@cais-group/caisiq/domain/common'
import {
  GetWhitepaperBySlugQuery,
  useGetWhitepaperBySlugQuery,
} from '@cais-group/caisiq/util/graphql/contentful'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

export const useGetWhitepaper = (
  slug?: string,
  options?: {
    pause?: boolean
  }
): ApiState<WhitepaperData> =>
  useReactQueryResultAsApiState<GetWhitepaperBySlugQuery, WhitepaperData>(
    useGetWhitepaperBySlugQuery(
      {
        slug: slug || '',
        preview: previewService.enabled,
      },
      { enabled: !(slug === undefined || options?.pause) }
    ),
    (result) =>
      result.whitepaperCollection?.items[0] as unknown as WhitepaperData,
    'Could not load whitepaperCollectionQuery'
  )
