import { SetterOrUpdater } from 'recoil'

import { TableControls } from '@cais-group/caisiq/ui/table/manage'
import { InputSearch } from '@cais-group/caisiq-ui-input-search'

type TableNavProps = {
  setSearchTerm: SetterOrUpdater<string>
  searchTerm: string
  count: number
}
export const TableNav = ({
  count,
  searchTerm,
  setSearchTerm,
}: TableNavProps) => (
  <div className="flex gap-16 py-16">
    <TableControls count={count} information="total courses" />{' '}
    <InputSearch
      placeholder="Search"
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      className="h-[48px] min-w-[304px]"
    />
  </div>
)
