import type { UniformServices } from '../services'

export function matchUniformService<
  TUniformServices extends UniformServices = UniformServices,
  TUniformService extends keyof TUniformServices = keyof TUniformServices
>({
  services = {} as TUniformServices,
  service,
}: {
  services: TUniformServices
  service?: TUniformService
}) {
  if (!service) {
    throw new Error('matchUniformSerice: service name is required!')
  }
  const fn = services[service]
  if (typeof fn !== 'function') {
    throw new Error(
      `matchUniformService services function: '${String(
        service
      )}' is not a function`
    )
  }
  return fn
}
