import cx from 'classnames'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useQueryParam } from 'use-query-params'
import { Event, EventData, SingleOrArray } from 'xstate'

import { Button } from '@cais-group/equity/atoms/button'
import { Step } from '@cais-group/equity/atoms/step'

import { UniformSection } from '../components/uniform-section'
import { useUniformContext } from '../context'
import { evaluateWhereFilters } from '../rules'
import {
  StepperContext,
  StepperEvents,
} from '../stepper/useGenerateMachineFromSchema'

import { UniformFormHandler } from './form'

type StepperProps = {
  submitText?: string
  nextLabel?: string
  nextDisabled?: boolean
  onCancel?: () => void
  context: StepperContext
  send(event: SingleOrArray<Event<StepperEvents>>, payload?: EventData): unknown
  state: string
}

export function Stepper({
  submitText,
  nextLabel = 'Next',
  nextDisabled = false,
  context,
  send,
  state,
}: StepperProps) {
  const { schema } = useUniformContext()
  const methods = useFormContext()
  const [step] = useQueryParam<string>('step')

  if (!schema) {
    throw new Error('Schema not defined')
  }

  if (Array.isArray(schema)) {
    throw new Error('Stepper can only be used with a single schema')
  }

  const visibleChildren = schema.children.filter((child) => {
    if (!child?.rules?.visibleIf) {
      return true
    }

    const result = evaluateWhereFilters(
      child?.rules?.['visibleIf'] ?? {},
      methods.getValues()
    )

    if (result.allPassed) {
      return true
    }

    return false
  })

  useEffect(() => {
    send({ type: 'UPDATE_STEPS', steps: visibleChildren })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send, visibleChildren.length])

  useEffect(() => {
    if (step) {
      send({ type: 'GOTO', stepId: step })
    }
  }, [send, step])

  const { steps, stepState, hasNext, hasPrevious } = context

  return (
    <div>
      {visibleChildren.map((section, index) => {
        const resolvedState = stepState[index]
        const title = typeof section.title === 'string' ? section.title : ''

        return (
          <fieldset
            key={title}
            aria-expanded={resolvedState === 'active'}
            className={cx(
              'border-l-1 ml-8 border-dashed border-neutral-600 pb-32',
              {
                'border-none': index + 1 >= steps.length,
              }
            )}
            aria-labelledby={`create-order-step-${index}`}
          >
            <div className="ml-[-27px]">
              <Step
                id={`create-order-step-${index}`}
                count={1 + index}
                title={title}
                state={resolvedState}
                onClick={() => send({ type: 'GOTO', stepId: section.id })}
              />
            </div>

            <div
              className={cx('ml-[57px]', {
                hidden: resolvedState !== 'active',
              })}
            >
              <div
                className={cx(
                  'flex flex-1 flex-col gap-56 text-left',
                  '[&>*]:border-b-1 [&>*]:border-solid [&>*]:border-neutral-200',
                  '[&>*]:pb-56 [&>:last-of-type]:border-none'
                )}
              >
                <UniformSection key={index} {...section} title="" />
              </div>
              <footer className="flex gap-x-16">
                {hasPrevious && (
                  <Button
                    onClick={() => send({ type: 'PREVIOUS' })}
                    variant="secondary"
                    disabled={state !== 'collectingData'}
                  >
                    Previous
                  </Button>
                )}
                {hasNext ? (
                  <Button
                    disabled={nextDisabled || state !== 'collectingData'}
                    onClick={(e) => {
                      e.preventDefault()
                      send({ type: 'NEXT' })
                    }}
                  >
                    {nextLabel}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    disabled={methods.formState.isSubmitting}
                    loading={methods.formState.isSubmitting}
                  >
                    {submitText ?? 'Submit'}
                  </Button>
                )}
              </footer>
            </div>
          </fieldset>
        )
      })}
    </div>
  )
}

export function StepperLayout(proxyProps: StepperProps) {
  return (
    <UniformFormHandler>
      <Stepper {...proxyProps} />
    </UniformFormHandler>
  )
}
