import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { BodySmall, H4 } from '@cais-group/approved/ui/typography'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { colors } from '@cais-group/equity/particles/colors'
import { ContextMenuApp } from '@cais-group/shared/ui/context-menu/app'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { FooterDefault } from '@cais-group/shared/ui/footer/default'
import { LayoutCenter } from '@cais-group/shared/ui/layout'

const OuterContainer = styled.div`
  overflow: hidden;
`

const Container = styled.div`
  background-color: rgb(var(--colors-neutral-0));
  padding: var(--s144);
  padding: var(--s144) var(--s16) var(--s144) var(--s16);
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    padding: var(--s144);
  }
`
const Description = styled(BodySmall)`
  margin-top: var(--s24);
`

const CircleBg = styled.div`
  background-color: rgb(var(--colors-warning-600));
  height: 186px;
  width: calc(186px * 2);
  border-top-left-radius: calc(186px * 2);
  border-top-right-radius: calc(186px * 2);
  position: relative;
  left: 65%;
`

const StyledAppContextMenu = styled(ContextMenuApp)`
  position: absolute;
  top: var(--s16);
  right: var(--s8);
`

export const VerifyEmailMessage = () => {
  const navigate = useNavigate()
  const { user } = useAuth0()

  useEffect(() => {
    if (user?.email_verified) {
      navigate(ROUTES.dashboard, { replace: true })
    }
  }, [user?.email_verified, navigate])

  return (
    <OuterContainer>
      <StyledAppContextMenu fill={colors['eq-color-neutral-900']} />
      <Container>
        <LayoutCenter centerText={true}>
          <H4>Thank you for signing up.</H4>
          <Description>
            You're almost in. Check your email for a verification link to access
            the CAIS IQ.
          </Description>
        </LayoutCenter>
      </Container>
      <CircleBg></CircleBg>
      <FooterDefault />
    </OuterContainer>
  )
}
