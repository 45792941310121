import { Navigate, useParams, useMatch } from 'react-router-dom'

import { useGetArticle } from '@cais-group/caisiq/domain/article'
import { isData } from '@cais-group/caisiq/domain/common'
import { useGetUserLicences } from '@cais-group/caisiq/domain/user'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'

import { ArticlePage } from './caisiq-ui-article-page'

type ArticleParams = {
  slug?: string
}

enum ProtectedSlugs {
  CONTINUING_EDUCATION_GUIDE = 'continuing-education-guide',
}

enum PotentialBadResources {
  ARTICLES = 'articles',
}
const BAD_ROUTES: { [key in PotentialBadResources]: string } = {
  [PotentialBadResources.ARTICLES]: ProtectedSlugs.CONTINUING_EDUCATION_GUIDE,
}

type BadRoute = keyof typeof BAD_ROUTES

function ArticlePageContainer() {
  const { slug } = useParams<ArticleParams>()
  const match = useMatch(`/authenticated/:resource/*`)

  const article = useGetArticle(slug)

  const experience = userSettingsService.theme

  const getUserLicencesResult = useGetUserLicences()
  const { data, isFetched } = getUserLicencesResult

  if (!isData(article) || !isData(getUserLicencesResult)) {
    return null
  }

  // This prevents specific protected content/slugs from being exposed through incorrect resource
  if (slug && BAD_ROUTES[match?.params.resource as BadRoute] === slug) {
    return <Navigate to="/" replace />
  }

  const typePage = experience.ceGuidePage?.slug === slug ? 'Guide' : 'Article'
  const isCeGuide = Boolean(
    userSettingsService.hasCapability('ceCredit') && typePage === 'Guide'
  )

  return (
    <ArticlePage
      article={article}
      isCeGuide={isCeGuide}
      typePage={typePage}
      cimaNumber={data?.cima?.number || ''}
      cfpNumber={data?.cfp?.number || ''}
      isFetched={isFetched}
    />
  )
}

ArticlePageContainer.displayName = 'ArticlePageContainer'

export { ArticlePageContainer }
