import { useMutation } from 'react-query'

import { defaultPersister } from '@cais-group/caisiq/util/default-query-function'
import {
  ApiPaths,
  CourseMetadata,
  CourseMetadataRequest,
} from '@cais-group/shared/util/type/caisiq-be'

export function usePutBaseCoursesMetadata(version?: number) {
  return useMutation((data: CourseMetadataRequest) => {
    return defaultPersister<CourseMetadataRequest, CourseMetadata>(
      ApiPaths.createOrUpdateCourseMetadata,
      { metadata: data.metadata },
      version,
      'PUT'
    )
  })
}
