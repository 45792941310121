import axios, {
  isAxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
} from 'axios'

import {
  HOMEPAGE_BFF,
  HOMEPAGE_BFF_PORT,
} from '@cais-group/homepage/util/common'
import { ApiError } from '@cais-group/shared/domain/contentful/api'
import { getEnvConfig } from '@cais-group/shared/ui/env'
import { authService } from '@cais-group/shared/util/auth-service'

let axiosInstance: AxiosInstance

type RequestConfig = AxiosRequestConfig & {
  url: string
}

async function createAxiosInstance() {
  const env = getEnvConfig()

  // TODO HP-537 - consider creating a new fetch that returns both user profile and access token
  //  since the token is use by fetchUserProfile
  //  or, move it to the BFF (but then will need to request every time)

  const accessToken = await authService.getAccessTokenSilently()
  axiosInstance = axios.create({
    baseURL:
      env.ENVIRONMENT === 'localhost'
        ? `http://localhost:${HOMEPAGE_BFF_PORT}${HOMEPAGE_BFF}`
        : HOMEPAGE_BFF,
  })

  axiosInstance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${accessToken}`

  return axiosInstance
}

export async function fetchInstance<T>({
  url,
  ...config
}: RequestConfig): Promise<T> {
  try {
    const axios = axiosInstance || (await createAxiosInstance())
    const response = await axios(url, {
      ...config,
    })

    return response.data
  } catch (error) {
    if (isAxiosError(error)) {
      throw new ApiError(error)
    } else {
      throw error
    }
  }
}
