import * as React from 'react'

import { useUniformContext } from '../context'

export function useUniformFocus(autoFocus: boolean = false) {
  const { formRef } = useUniformContext()
  /** if autoFocus is on then focus first input */
  React.useEffect(() => {
    if (!formRef.current) return
    const firstInput = formRef.current.querySelector('input')
    if (autoFocus && firstInput) {
      firstInput.focus()
    }
  }, [autoFocus]) // eslint-disable-line react-hooks/exhaustive-deps
}
