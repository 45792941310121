import MuiMenuItem from '@mui/material/MenuItem'
import MuiMenuList from '@mui/material/MenuList'

import {
  ButtonMenuContainer,
  ButtonMenuContainerProps,
} from '../button-menu-container'

export interface ButtonMenuListOption<V = string> {
  label: string
  value: V
}
export interface ButtonMenuListProps<T = string> {
  label: string
  options: Array<ButtonMenuListOption<T>>
  value?: ButtonMenuListOption<T> | null
  onChange: (option: ButtonMenuListOption<T>) => void
  buttonProps?: ButtonMenuContainerProps['buttonProps']
  menuPlacement?: ButtonMenuContainerProps['menuPlacement']
}
export function ButtonMenuList<T = string>({
  label,
  options,
  value = null,
  onChange,
  buttonProps,
  menuPlacement,
}: ButtonMenuListProps<T>) {
  return (
    <ButtonMenuContainer
      label={label}
      buttonProps={buttonProps}
      menuPlacement={menuPlacement}
    >
      {({ isMenuActive, setIsMenuActive }) => (
        <MuiMenuList data-testid="ButtonMenuList-menu" sx={{ padding: '0' }}>
          {options.map((option) => (
            <MuiMenuItem
              key={option.label}
              selected={value === option}
              onClick={() => {
                onChange(option)
                setIsMenuActive(!isMenuActive)
              }}
              data-testid="ButtonMenuList-menuItem"
            >
              {option.label}
            </MuiMenuItem>
          ))}
        </MuiMenuList>
      )}
    </ButtonMenuContainer>
  )
}
