import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import { Body } from '@cais-group/approved/ui/typography'

function getRichTextDisclaimerOptions(): Options {
  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return <Body>{children}</Body>
      },
    },
  }
}

export { getRichTextDisclaimerOptions }
