import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import type { ContentDataType } from '@cais-group/homepage/util/types'
import {
  useReactQueryResultAsApiState,
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  ContentPermissionsData,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { useGetTutorialsPageContentQuery } from '@cais-group/shared/util/graphql/mfe-contentful'
import type { GetTutorialsPageContentQuery } from '@cais-group/shared/util/graphql/mfe-contentful'

import {
  FEATURED_CONTENT_APP_ID,
  FILTERS,
  FIRMS_COLLECTION_LIMIT,
} from '../constants'
import { excludeBySysId, selectFeaturedItem, sortContentData } from '../helper'
import { selectTutorials as filterAllowedTutorials } from '../helper/select-content-helpers'

type Data = {
  featured: ContentDataType | null
  tutorials: ContentDataType[] | null
} | null

const getOrVideo = (searchText?: string) => ({
  OR: [
    {
      title_contains: searchText,
    },
    {
      summary_contains: searchText,
    },
  ],
})

const makeTutorialQueryVariables = (variables?: {
  limit?: number
  searchText?: string
}) => {
  const { searchText, limit } = variables || {}
  const orVideo = getOrVideo(searchText)
  const whereTitle = {
    title_contains: searchText,
  }

  return {
    limit,
    firmsLimit: FIRMS_COLLECTION_LIMIT,
    includeFeatured: !searchText,
    preview: previewService.enabled,
    featuredId: FEATURED_CONTENT_APP_ID,
    articleWhere: {
      AND: [
        {
          OR: [FILTERS.tutorials.is],
          AND: [
            {
              OR: [
                whereTitle,
                {
                  description_contains: searchText,
                },
              ],
            },
          ],
        },
      ],
    },
    videoWhere: {
      AND: [
        {
          OR: [FILTERS.tutorials.is],
          AND: [orVideo],
        },
      ],
    },
    videoPlaylistWhere: {
      AND: [
        {
          OR: [FILTERS.tutorials.is],
          AND: [orVideo],
        },
      ],
    },
    whitepaperWhere: {
      AND: [
        {
          OR: [FILTERS.tutorials.is],
          AND: [
            {
              OR: [
                whereTitle,
                {
                  introduction_contains: searchText,
                },
              ],
            },
          ],
        },
      ],
    },
  }
}
// This query breaks with a limit over about 36
export const useGetTutorials = (
  variables?: { limit?: number; searchText?: string },
  options?: {
    enabled?: boolean
  },
  handleResponse = selectTutorials
) => {
  const enableRequest = options?.enabled ?? true
  const queryParams = makeTutorialQueryVariables(variables)
  const allowedUserPermissions = useGetAllowedContentPermissions()
  const response = useReactQueryResultAsApiState<
    GetTutorialsPageContentQuery,
    { featured: ContentDataType | null; tutorials: ContentDataType[] }
  >(
    useGetTutorialsPageContentQuery(
      {
        ...queryParams,
      },
      {
        enabled: enableRequest,
        refetchOnWindowFocus: false,
      }
    ),
    (data) => handleResponse(data, allowedUserPermissions.data),
    'Could not load tutorials query'
  )
  const loading =
    response === ApiStateEnum.LOADING || allowedUserPermissions.isLoading
  const error = isError(response)

  return compileResults(response, loading, error)
}

const compileResults = (
  data: Data | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}

const selectTutorials = (
  data: GetTutorialsPageContentQuery,
  allowedPermissionsData: ContentPermissionsData
) => {
  const featuredItems = data.featured?.items?.[0]
  const featured = selectFeaturedItem<ContentDataType>({
    options: featuredItems?.options?.items as ContentDataType[],
    fallback: featuredItems?.fallback?.items[0] as ContentDataType,
    userAccessData: allowedPermissionsData,
  })
  const allowedTutorials = filterAllowedTutorials(allowedPermissionsData, data)
  const withFeaturedItemExcluded = excludeBySysId<ContentDataType>(
    allowedTutorials,
    featured?.sys.id
  )
  const tutorials = sortContentData(withFeaturedItemExcluded)

  return {
    featured,
    tutorials,
  }
}
