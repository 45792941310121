import { enableMapSet } from 'immer'
import { Route, Routes } from 'react-router-dom'

import { useGetUserByIdV1 } from '@cais-group/access-manager/domain/api'
import { APPS, APP_IDS } from '@cais-group/shared/domain/apps'
import { AppErrorBoundary } from '@cais-group/shared/ui/app-error-boundary'
import {
  Auth0ProviderWithHistory,
  AuthProtected,
} from '@cais-group/shared/ui/auth-protected'
import { EnvConfigSubAppProvider, MfeEnv } from '@cais-group/shared/ui/env'
import { HealthCheckRoute } from '@cais-group/shared/ui/health-check'
import { useSetAuthTokenGetter } from '@cais-group/shared/util/auth-service'
import {
  UserProfileProvider,
  useUserProfile,
} from '@cais-group/shared/util/hook/use-user-profile'
import { useSetGlobalContext } from '@cais-group/shared/util/logging'
import { LocationTrackingValuesProvider } from '@cais-group/shared/util/segment'

import { usePendo } from './hooks/usePendo'
import { useInitEnvConfig } from './utils/useInitEnvConfig'

interface MfeContainerAppWrapperProps {
  children: React.ReactNode
  appName: APPS
  appId: APP_IDS
  envConfig: MfeEnv
  initAppSpecificEnvConfig?: boolean
}

// Allows us to use Sets and Maps in immer https://immerjs.github.io/immer/installation/
enableMapSet()

const WithUserProfileAndPendo = ({
  children,
}: {
  children: React.ReactNode
}) => {
  useSetAuthTokenGetter()
  const userProfileValue = useUserProfile()
  const {
    userProfileState: { userProfile },
  } = userProfileValue

  const { data: userRoles } = useGetUserByIdV1(userProfile?.id || '', {
    query: {
      enabled: typeof userProfile?.id === 'string',
    },
  })

  usePendo(userProfile, userRoles?.roles)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

const AuthenticatedContainer = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <Auth0ProviderWithHistory>
    <AuthProtected>
      <UserProfileProvider>
        <WithUserProfileAndPendo>{children}</WithUserProfileAndPendo>
      </UserProfileProvider>
    </AuthProtected>
  </Auth0ProviderWithHistory>
)

/*
 *
 * Use this wrapper when you are creating protected views
 *
 */
export function MfeSubAppWrapper<CustomEnvVars = Record<string, string>>({
  children,
  appName,
  appId,
  envConfig,
  initAppSpecificEnvConfig = false,
}: MfeContainerAppWrapperProps) {
  useInitEnvConfig<CustomEnvVars>({
    envConfig,
    appId,
    initAppSpecificEnvConfig,
  })
  useSetGlobalContext({ appName })
  return (
    <AppErrorBoundary appName={appName}>
      <EnvConfigSubAppProvider envConfig={envConfig}>
        <LocationTrackingValuesProvider appName={appName}>
          <Routes>
            {HealthCheckRoute(appName)}
            <Route
              path="*"
              element={
                <AuthenticatedContainer>{children}</AuthenticatedContainer>
              }
            />
          </Routes>
        </LocationTrackingValuesProvider>
      </EnvConfigSubAppProvider>
    </AppErrorBoundary>
  )
}
