import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import type {
  ControllerFieldState,
  ControllerRenderProps,
  FieldError,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form'

import { FieldSurround } from '../components/field-surround'
import { useUniformContext } from '../context'
import { useAsyncValidationService } from '../hooks'
import { useGetFieldError } from '../hooks/use-get-field-error'
import {
  useFieldValidationDeps,
  useUniformFieldRules,
  useGetRequiredBooleanOrMessage,
} from '../rules'
import type { UniformServices } from '../services'
import type {
  Repeatable,
  UniformAny,
  UniformFieldProps,
  UniformSettings,
} from '../types'
import { selectTagProps } from '../utils/selectTagProps'

export type AdapterFieldProps<
  FieldName extends string = string,
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
> = UniformFieldProps<FieldName, TFormFieldIds> & {
  id: string
  validationService?: keyof TUniformServices
}

export type UniformAdapterProps<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices,
  TFieldValues extends FieldValues = FieldValues
> = {
  field: AdapterFieldProps<string, TFormFieldIds, TUniformServices>
  render: ({
    field,
    fieldState,
    formState,
    uniform: { id, isDisabled, isRequired, error, required },
  }: {
    field: ControllerRenderProps
    fieldState: ControllerFieldState
    formState: UseFormStateReturn<TFieldValues>
    uniform: {
      /** forwarded field id */
      id: string
      isDisabled?: boolean
      isRequired?: boolean
      error: FieldError | undefined
      required?: string | boolean
      skippable?: boolean
      settings?: UniformSettings
      tag?: ReturnType<typeof selectTagProps>
    }
    asyncValidation: { isLoading: boolean; data?: string | undefined }
  }) => React.ReactElement
}

function getFieldId(field: {
  repeatable?: Repeatable['repeatable']
  parentPrefix?: string
  id?: string
}): string {
  const fieldId = field.repeatable
    ? `${field.parentPrefix}`
    : field.parentPrefix
    ? `${field.parentPrefix}.${field.id}`
    : field.id

  return fieldId ?? ''
}

export const UniformAdapter = <
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
>({
  render,
  field,
}: UniformAdapterProps<TFormFieldIds, TUniformServices>) => {
  const uniform = useUniformFieldRules(field)
  const { control } = useFormContext()
  const { settings, rules } = useUniformContext()
  const { error } = useGetFieldError(field)
  const fetcher = useAsyncValidationService({
    field,
    enabled: uniform.isVisible,
  })
  const required = useGetRequiredBooleanOrMessage(field, uniform.isRequired)
  const deps = useFieldValidationDeps(field.id, field.parentPrefix)

  const tag = React.useMemo(() => {
    return selectTagProps({
      requiredTagEnabled:
        !!settings?.displayRequiredStatusTags && !field.recommended,
      required: !!required,
      skippable: rules?.[field?.id ?? '']?.skippable || !!field.skippable,
      recommended: !!field.recommended,
    })
  }, [settings, required, field, rules])

  const fieldId = getFieldId(field)

  if (!uniform.isVisible) return null

  return (
    <FieldSurround
      fieldId={fieldId}
      cols={field.cols}
      testId={`${field.type}__${fieldId}`}
      className={uniform.shouldHideFromVisibility ? 'hidden' : 'visible'}
    >
      <Controller
        key={fieldId}
        name={fieldId}
        control={control}
        defaultValue={field.defaultValue}
        shouldUnregister={field.shouldUnregister ?? true}
        rules={{
          value: field.value,
          deps,
          // NOTE: when the form is submitted with required but empty value - validation fn bellow is not executed and error message is not displayed.
          // If the message is provided on required field directly - we are forwarding it here.
          required,
          validate: (value, allValues) => {
            // NOTE: async field validation overrides rules validation.
            if (fetcher.isEnabled) {
              // FIXME: problem when editing field with pre-populated data. Will show error message because data already exists.
              return fetcher.data
            }
            // NOTE: if not required and no value, skip validation
            if (!required && !value) {
              // https://github.com/react-hook-form/react-hook-form/issues/1781
              return undefined
            }
            if (uniform.shouldValidate) {
              const result = uniform.validate(allValues)
              return result?.allPassed
                ? undefined
                : result?.messages
                    .filter(Boolean)
                    .map((item) => item?.message)
                    .join(', ')
            }
            return undefined
          },
        }}
        render={(args) => {
          return render({
            uniform: {
              settings,
              id: fieldId,
              required,
              skippable: field.skippable,
              error,
              isDisabled: uniform.isDisabled,
              isRequired: !!required,
              tag,
            },
            asyncValidation: {
              isLoading: fetcher.isLoading,
              data: fetcher.data,
            },
            ...args,
          })
        }}
      />
    </FieldSurround>
  )
}

/**
 * Use this function to wrap your component to make it compatible with Uniform.
 *
 * @note
 *
 * currently it will not be able to infer component props that should appear in the schema
 * and won't work if the component defines its own props
 *
 * @example
 *
 * const TheComponent = () => {}
 *
 * const componentDictionary = createUniformDictionary({
 *   CustomComponentName: withUniform<'CustomComponentName'>(TheComponent)
 * })
 */
export const withUniform = <FieldName extends string>(
  BaseComponent: React.ComponentType<
    AdapterFieldProps<FieldName> & { [key: string]: UniformAny }
  >
) => {
  return function EnhancedComponent(field: AdapterFieldProps<FieldName>) {
    return (
      <UniformAdapter
        field={field}
        render={({
          uniform: { id, isDisabled, isRequired, error },
          field: { onChange, onBlur, value, ref },
        }) => (
          <BaseComponent
            ref={ref}
            id={id}
            data-testid={id}
            value={value ?? ''}
            type={field.type}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={field.placeholder}
            required={isRequired}
            label={field.label ?? ''}
            disabled={isDisabled}
            helper={
              error?.message
                ? { type: 'error', text: error.message }
                : undefined
            }
          />
        )}
      />
    )
  }
}
