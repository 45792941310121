import * as R from 'remeda'

import { toTitleCase } from '@cais-group/equity/utilitarian'
import { AccountListItemForContactV1 } from '@cais-group/shared/domain/investor-profile'

import { pathOr } from '../../utils'
import { AccountModalTable } from '../multiple-combobox/table'

export const ContactSummaryTable = ({
  contactAccounts,
}: {
  contactAccounts: AccountListItemForContactV1[] | undefined
}) => {
  return (
    <AccountModalTable
      table={{
        title: 'Accounts',
        rowIdPath: 'account.id',
        columns: [
          {
            header: 'Account Name',
            accessorKey: 'account.name',
            enableSorting: true,
          },
          {
            header: 'Account Role',
            accessorKey: 'contactRoles',
            enableSorting: true,
            cell: (row) => {
              const values = row.getValue()
              if (!Array.isArray(values)) return null
              const roles = values.map((role: string) =>
                toTitleCase(role, { delimiter: '_' })
              )
              return <div>{roles.join(', ')}</div>
            },
          },
          {
            header: 'Custodian/Bank Name',
            accessorKey: 'account',
            enableSorting: true,
            cell: (row) => {
              const name = R.pipe(
                [
                  'brokerageDetails.name',
                  'brokerageDetails.bankName',
                  'custodianDetails.name',
                ],
                R.map((path) =>
                  pathOr<string, undefined>(
                    undefined,
                    path.split('.'),
                    row.getValue()
                  )
                ),
                R.filter(R.isDefined),
                R.first()
              )

              return (
                <div className="truncate" title={name}>
                  {name}
                </div>
              )
            },
          },
          {
            header: 'Custodian/Bank Account Number',
            accessorKey: 'account',
            enableSorting: true,
            cell: (row) => {
              const accountNumber = R.pipe(
                [
                  'brokerageDetails.accountNumber',
                  'custodianDetails.accountNumber',
                ],
                R.map((path) =>
                  pathOr<string, undefined>(
                    undefined,
                    path.split('.'),
                    row.getValue()
                  )
                ),
                R.filter(R.isDefined),
                R.first()
              )

              return (
                <div className="truncate" title={accountNumber}>
                  {accountNumber}
                </div>
              )
            },
          },
          {
            header: 'Account Type',
            accessorKey: 'account.subType',
            enableSorting: true,
            cell: (row) => (
              <div className="truncate" title={row.getValue<string>()}>
                {row.getValue<string>()}
              </div>
            ),
          },
        ],
      }}
      data={contactAccounts ?? []}
    />
  )
}
