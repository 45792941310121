import cx from 'classnames'

import { Avatar } from '@cais-group/equity/atoms/avatar'
import { Icon } from '@cais-group/equity/atoms/icon'

import { OptionType } from '../select'

export interface SingleValueProps<T> {
  selectedOption: OptionType<T> | null
  inputValue: string | null
  isSearchable?: boolean
  onRemove?: () => void
}

export const DefaultSingleValue = <T extends { label?: string }>({
  selectedOption,
  isSearchable,
  inputValue = '',
  onRemove,
}: SingleValueProps<T>) => {
  return selectedOption && !inputValue ? (
    <span
      className={cx(
        'body grow-1 mr-16 flex overflow-hidden text-ellipsis whitespace-nowrap text-neutral-900',
        {
          'absolute left-16 right-16 z-10': isSearchable,
          'text-neutral-500': !isSearchable && !selectedOption,
          'cursor-default': !isSearchable,
        }
      )}
    >
      {selectedOption?.label ?? selectedOption?.value}
      {onRemove && isSearchable && (
        <button
          className="ml-auto flex cursor-pointer items-center"
          data-testid="remove-select-item"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            onRemove?.()
          }}
        >
          <Icon size="small" type="Close" color="eq-color-neutral-300" />
        </button>
      )}
    </span>
  ) : null
}

export const GroupSingleValue = <T extends { groupName: string }>({
  selectedOption,
  isSearchable,
  inputValue = '',
}: SingleValueProps<T>) => {
  return selectedOption && !inputValue ? (
    <div
      className={cx('bodySmall text-neutral-900', {
        'text-neutral-500': !isSearchable && !selectedOption,
        'cursor-default': !isSearchable,
      })}
    >
      <div className="flex items-center bg-neutral-100">
        <Avatar type="group">{selectedOption?.groupName}</Avatar>
        <div className="flex flex-col px-8">
          <p className="bodySmall text-neutral-900">
            {selectedOption?.groupName}
          </p>
        </div>
      </div>
    </div>
  ) : null
}
