import { ColumnDef, ColumnSort, SortingState } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

import {
  DEBOUNCE_RATE,
  useGetManageCaisIqExperiences,
} from '@cais-group/caisiq/domain/manage-caisiq'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { EnableButton } from '@cais-group/caisiq/ui/manage/enable-button'
import { useContentfulNameFromIdMapper } from '@cais-group/caisiq/util/contentful/use-contentful-name-from-id-mapper'
import { UseModalState } from '@cais-group/caisiq/util/hooks/use-modal-state'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { getEnvConfig } from '@cais-group/caisiq-ui-env'
import { Icon } from '@cais-group/equity/atoms/icon'
import { Tooltip } from '@cais-group/equity/atoms/tooltip'
import { TableCellWithOverflow } from '@cais-group/shared/ui/table-cell-with-overflow'
import { useDebouncedState } from '@cais-group/shared/util/hook/use-debounced-state'
import { ExperienceResponse } from '@cais-group/shared/util/type/caisiq-be'

import { ManageExperiences } from './caisiq-feature-manage-experiences'

const getSortParam = (sorting: ColumnSort): string => {
  if (!sorting) {
    return 'label'
  }
  if (sorting.desc === true) {
    return `-${sorting.id}`
  }
  return sorting.id
}

/**
 *  A utility function to make a Contentful web app entry URL for a given entry ID.

 *  CONTENTFUL_URL is: https://graphql.contentful.com/content/v1/spaces/[space ID]/environments/[environment]
 *  while the web app URL is https://app.contentful.com/spaces/[space ID]/environments/[environment]/entries/[entry ID]
 */
const makeContentfulExperienceUrl = (entryId: string) => {
  const { CONTENTFUL_URL } = getEnvConfig()
  const contentfulSpacePathname = CONTENTFUL_URL.split('content/v1/')[1]
  return `https://app.contentful.com/${contentfulSpacePathname}/entries/${entryId}`
}

export const ManageExperiencesContainer = () => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false },
  ])
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)
  const { isOpen, onOpen } = UseModalState<boolean>()
  const debouncedSearchTerm = useDebouncedState(searchTerm, DEBOUNCE_RATE)

  const [selectedExperience, setSelectedExperience] = useState<
    ExperienceResponse | undefined
  >()
  useEffect(() => {
    trackingService.managePageViewed()
  }, [])

  const sortConfigApplied: ColumnSort = sorting[0]

  const {
    data: experiencesData,
    isLoading,
    fetchNextPage,
    hasNextPage = false,
    isFetched,
  } = useGetManageCaisIqExperiences({
    sort: [getSortParam(sortConfigApplied)],
    filter: debouncedSearchTerm
      ? { searchText: debouncedSearchTerm }
      : undefined,
  })

  const getContentfulNameById = useContentfulNameFromIdMapper()
  const experiencesColumns = useMemo<ColumnDef<ExperienceResponse>[]>(
    () => [
      {
        header: 'Experience name',
        accessorKey: 'name',
        id: 'name',
        sortingFn: 'alphanumeric',
        cell: (info) => {
          return (
            <TableCellWithOverflow columnWidth={info.column.getSize()}>
              <p className="small-strong">{info.getValue<string>()}</p>
            </TableCellWithOverflow>
          )
        },
        meta: {
          ratio: 2.5,
        },
        size: 100,
      },
      {
        header: 'Experience label',
        accessorKey: 'label',
        id: 'label',
        cell: (info) => (
          <TableCellWithOverflow columnWidth={info.column.getSize()}>
            <p className="eq-small truncate">{info.getValue<string>()}</p>
          </TableCellWithOverflow>
        ),
        meta: {
          ratio: 1.5,
        },
        size: 100,
      },
      {
        header: 'ALM catalog',
        accessorKey: 'catalogName',
        enableSorting: false,
        accessorFn: (originalRow) => originalRow?.catalog?.name,
        cell: (info) => (
          <TableCellWithOverflow
            columnWidth={info.column.getSize()}
            className="flex items-center gap-16"
          >
            <p className="eq-small truncate">
              {info.getValue<string>() ? info.getValue<string>() : '-'}
            </p>
          </TableCellWithOverflow>
        ),
        meta: {
          ratio: 2,
        },
        size: 100,
      },
      {
        header: 'Contentful name',
        accessorKey: 'contentfulId',
        id: 'contentfulId',
        cell: (info) => {
          const contentfulId = info.row.original.contentfulId
          const contentfulExperienceUrl = makeContentfulExperienceUrl(
            contentfulId ?? ''
          )
          return (
            <TableCellWithOverflow
              columnWidth={info.column.getSize()}
              className="flex items-center"
            >
              <p className="eq-small truncate">
                {getContentfulNameById(contentfulId)}{' '}
              </p>
              <div className="flex content-center">
                <a
                  href={contentfulExperienceUrl}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    window.open(contentfulExperienceUrl, '_blank')?.focus()
                  }}
                >
                  <Tooltip
                    title="Edit in Contentful"
                    placement="top"
                    size="large"
                  >
                    <div className="px-16 py-[2px]">
                      <Icon type="Launch" size="small" />
                    </div>
                  </Tooltip>
                </a>
              </div>
            </TableCellWithOverflow>
          )
        },
        meta: {
          ratio: 1.5,
        },
        size: 100,
      },
      {
        header: 'No. of courses',
        accessorKey: 'coursesCount',
        id: 'coursesCount',
        enableSorting: false,
        cell: (info) => <p className="eq-small">{info.getValue<string>()}</p>,
        meta: {
          ratio: 0,
          alignment: 'flex-end',
        },
        size: 100,
      },
      {
        header: 'Experience enabled',
        accessorKey: 'active',
        id: 'active',
        enableSorting: false,
        cell: (info) => (
          <div className="h-24 pr-24">
            {info.getValue() ? (
              <Icon
                size="small"
                color="eq-color-success-600"
                type="Check"
                aria-label="enabled-icon"
              />
            ) : (
              <EnableButton
                testId="enable-experience-button"
                onEnable={() => {
                  setSelectedExperience(info.row.original)
                  onOpen(true)
                }}
              />
            )}
          </div>
        ),
        meta: {
          ratio: 1,
          alignment: 'center',
        },
      },
    ],
    [getContentfulNameById, onOpen]
  )

  const experiencesDataMap = useMemo(() => {
    if (isLoading || !experiencesData?.pages) {
      return []
    }
    return experiencesData.pages.flatMap((page) => page.items)
  }, [experiencesData?.pages, isLoading])

  return (
    <ManageExperiences
      tableOptions={{
        onSortingChange: setSorting,
        state: {
          sorting: sorting,
        },
        columns: experiencesColumns,
        data: experiencesDataMap,
      }}
      fetchNextPage={fetchNextPage}
      searchTerm={debouncedSearchTerm}
      setSearchTerm={setSearchTerm}
      hasNextPage={hasNextPage}
      isFetched={isFetched}
      isOpen={isOpen}
      onClose={() => {
        setSelectedExperience(undefined)
        onOpen(false)
      }}
      selectedExperience={selectedExperience}
    />
  )
}
