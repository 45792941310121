import * as React from 'react'
import { type FieldError, useFormContext, useFormState } from 'react-hook-form'
import { usePrevious } from 'react-use'
import * as R from 'remeda'
import { fromEvent } from 'rxjs'

import { AttentionBox } from '@cais-group/equity/molecules/attention-box'

import { useUniformContext } from '../context'
import { UniformAny, UniformFieldProps } from '../types'
import { extractSchemaFieldIds, findObjectBy } from '../utils'
import { pathOr } from '../utils/path-or'

type ServerError = {
  message?: string
  ref: UniformAny
  type: string
}

export type GenericErrors = Array<{
  id?: string
  status?: number
  label?: string
  message: string
}>

export type ServerErrorMessageProps = {
  hasError: boolean
}
export const ServerErrorMessage = () => {
  const { schemaIds, schema } = useUniformContext()
  const { setFocus } = useFormContext()
  const ref = React.useRef<HTMLDivElement>(null)
  const formState = useFormState()
  const generalServerError = pathOr<ServerError, undefined>(
    undefined,
    ['errors', 'root', 'generalServerError'],
    formState
  )
  const serverErrorForFields = pathOr<
    Record<string, string>,
    Record<string, string>
  >({}, ['errors', 'root', 'serverErrorForFields', 'types'], formState)
  const isSubmitted = !formState.isSubmitting && formState.isSubmitted
  const generalOrFieldsError = [
    generalServerError,
    Object.keys(serverErrorForFields).length > 0,
  ].some(Boolean)
  const numberOfClientSideErrors = R.pipe(
    formState.errors,
    R.omit(['root']),
    R.keys,
    R.length()
  )
  const showError = [
    isSubmitted,
    generalOrFieldsError,
    numberOfClientSideErrors === 0,
  ].every(Boolean)

  React.useEffect(() => {
    if (ref.current && showError) {
      ref.current.focus({ preventScroll: true })
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [showError])

  return (
    <AttentionBox
      ref={ref}
      type="error"
      hidden={!showError}
      title={
        generalServerError
          ? 'Something went wrong, please try again later.'
          : 'Please correct the following errors or report your issue by contacting us.'
      }
    >
      {showError && (
        <ul className="list-disc space-y-4 pl-16 pt-12">
          {generalServerError && (
            <li
              key={generalServerError.type}
              className="text-left text-sm font-medium text-neutral-500"
            >
              {generalServerError.message}
            </li>
          )}
          {Object.entries(serverErrorForFields).map(([key, value]) => {
            // NOTE: field ids from the backend doesn't match the schema ids, so we partially match them and if found we assume it's the correct field
            const fieldId = schemaIds.find((id) => id.includes(key))
            const field = findObjectBy(schema, 'id', fieldId ?? '')
            const label = field?.['label'] ?? 'Field'

            return (
              <li
                key={key}
                className="space-x-8 text-left text-sm font-medium text-neutral-500"
              >
                {fieldId ? (
                  <button type="button" onClick={() => setFocus(fieldId)}>
                    <b>{`${label}:`}</b>
                  </button>
                ) : (
                  <em>{`${label}:`}</em>
                )}
                <span>{value}</span>
              </li>
            )
          })}
        </ul>
      )}
    </AttentionBox>
  )
}

export function useGetFormErrors() {
  const { schema } = useUniformContext()
  const ids = React.useMemo(() => extractSchemaFieldIds(schema), [schema])
  const { formState } = useFormContext()
  const errors = (() => {
    const result: [string, FieldError][] = R.pipe(
      ids,
      R.map((id) => {
        const result = pathOr<FieldError | undefined>(
          undefined,
          id.split('.'),
          formState.errors
        )
        if (!result) return []
        return [id, result]
      }),
      R.filter((arr) => arr.length !== 0),
      R.map((arr) => arr as [string, FieldError]),
      R.map(([id, value]) => {
        const field = findObjectBy(schema, 'id', id) as UniformFieldProps
        return [field?.label ?? id, value]
      })
    )
    return result
  })()

  return {
    errors,
    hasErrors: errors.flatMap((x) => x).length > 0,
    isSubmitted: formState.isSubmitted,
    submitCount: formState.submitCount,
    isSubmitSuccessful: formState.isSubmitSuccessful,
  }
}

export function ClientErrorMessages() {
  const ref = React.useRef<HTMLDivElement>(null)
  const { errors, hasErrors, isSubmitted, submitCount, isSubmitSuccessful } =
    useGetFormErrors()
  const prevSubmitCount = usePrevious(submitCount)
  const [err, setErr] = React.useState<
    [string, FieldError | Record<string, FieldError>][]
  >([])
  const showBox =
    typeof prevSubmitCount === 'number' && submitCount > 0 && err.length > 0

  // Persist errors so they don't update when form changes and only reset on submit.
  React.useEffect(() => {
    if (typeof prevSubmitCount === 'number' && submitCount > prevSubmitCount) {
      if (hasErrors) {
        setErr(errors)
      } else {
        // When you fix all the client side errors and submit the form
        // we need to clear the previous errors. If we don't do this
        // it can sometimes result in the UI showing previously fixed errors.
        // This happens when there is a BE error.
        setErr([])
      }
    }
  }, [errors, hasErrors, prevSubmitCount, submitCount])

  React.useEffect(() => {
    const sub = fromEvent(document, 'submit').subscribe(() => {
      if (showBox && isSubmitted && ref.current && errors.length > 0) {
        ref.current.focus()
      }
    })

    return () => sub.unsubscribe()
  }, [showBox, isSubmitted, errors])

  return (
    <AttentionBox
      ref={ref}
      type="error"
      title="Please check the following fields for errors"
      hidden={!showBox || isSubmitSuccessful}
    >
      <ul className="list-disc space-y-4 pl-16 pt-12">
        {err.map(([key, value]) => {
          if (isFieldError(value)) {
            return (
              <li key={key} className="text-neutral-600">
                <button
                  type="button"
                  className="text-left align-top"
                  onClick={() => value.ref?.focus?.()}
                >
                  {key}
                </button>
              </li>
            )
          }

          const [firstErrorField] = Object.values(value)

          return (
            <li key={key} className="text-neutral-600">
              <button
                type="button"
                className="text-left align-top"
                onClick={() => firstErrorField.ref?.focus?.()}
              >
                {key}
              </button>
            </li>
          )
        })}
      </ul>
    </AttentionBox>
  )
}

function isFieldError(value: object): value is FieldError {
  if (Object.keys(value).includes('ref')) {
    return true
  }
  return false
}
