import * as React from 'react'

import { useUniformContext } from '../context'
import type { UniformSectionChildren, UniformAny } from '../types'

/** matches all form components */
export const FieldMatcher = (fieldProps: UniformSectionChildren) => {
  const { componentDict } = useUniformContext()
  const Component = (componentDict as UniformAny)[fieldProps['type']]
  if (!Component) {
    throw new Error(
      `Uniform component ${String(
        fieldProps['type']
      )} not found in components dictionary!`
    )
  }

  return (
    <React.Suspense fallback={null}>
      <Component {...fieldProps} />
    </React.Suspense>
  )
}
