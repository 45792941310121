import type {
  AccessControls,
  EnabledAccessControls,
  MenuGroup,
  NavItem,
} from './types'

/**
 * Filters the navigation items by which permissions, features or personas are enabled
 * for the user. Generally quite permissive.
 * NavItems with no requirements are allowed.
 * NavItems with submenu's which have allowed items are allowed.
 */
export const getEntitledNavLinks = (
  navItems: NavItem[],
  enabledAccessControls: EnabledAccessControls
) => {
  return navItems.reduce<NavItem[]>(
    (navItemsAccumulator, currentNavItem: NavItem) => {
      const enabled = isNavItemEnabled(currentNavItem, enabledAccessControls)

      // If it is not enabled and does not have enabled children, ignore it
      if (!enabled) return navItemsAccumulator

      // If it is enabled and does not have a menu, add it
      if (!('menu' in currentNavItem)) {
        return [...navItemsAccumulator, currentNavItem]
      }

      // It is enabled and 'menu' in currentNavItem

      // If it is menu with permission attribute, hide if permissions do not pass
      if (
        disabledByPermissionMenuNavItem(
          currentNavItem,
          enabledAccessControls.enabledPermissions
        )
      )
        return navItemsAccumulator

      // If it is enabled and has a menu, trim the menu items to those which are enabled
      return [
        ...navItemsAccumulator,
        {
          ...currentNavItem,
          menu: {
            ...currentNavItem.menu,
            groups: currentNavItem.menu.groups.reduce<MenuGroup[]>(
              (groupAccumulator, currentGroup) => {
                // Ignore empty groups
                if (currentGroup.items.length === 0) return groupAccumulator

                const currentGroupsEnabledNavItems = currentGroup.items.filter(
                  (groupNavItem) =>
                    enabledByAccessControl(groupNavItem, enabledAccessControls)
                )

                // Ignore groups with no enabled items
                if (currentGroupsEnabledNavItems.length === 0)
                  return groupAccumulator

                // Add groups with enabled navItems
                return [
                  ...groupAccumulator,
                  { ...currentGroup, items: currentGroupsEnabledNavItems },
                ]
              },
              []
            ),
          },
        },
      ]
    },
    []
  )
}

export const enabledByAccessControl = (
  navItem: NavItem,
  enabledAccessControls: EnabledAccessControls
) => {
  // Enable if no access controls are required
  if (
    (navItem.capabilities === undefined || navItem.capabilities.length === 0) &&
    (navItem.features === undefined || navItem.features.length === 0) &&
    (navItem.permissions === undefined || navItem.permissions.length === 0) &&
    (navItem.personas === undefined || navItem.personas.length === 0)
  ) {
    return true
  }

  // Check each access control type
  const mapping: Record<string, AccessControlKeys> = {
    enabledCapabilities: 'capabilities',
    enabledFeatures: 'features',
    enabledPermissions: 'permissions',
    enabledPersonas: 'personas',
  }

  let isAllowed = false

  // Might need to track which ones are forbidden and required or not

  // Iterate over the user's access control types and check against the navItem
  for (const [key, value] of Object.entries(enabledAccessControls)) {
    const enabledType = mapping[key]
    if (!enabledType) {
      continue // type doesn't exist so skip to the next one
    }
    const { status, type } = enabledBy(enabledType, navItem, value)

    // Note: false always means test for access failed

    // Exit early if adhoc, forbidden or required checks have failed
    // Remember: adhoc is currently only used to early return false
    if (
      status === false &&
      ['adhoc', 'forbidden', 'required'].includes(type || '')
    ) {
      isAllowed = false
      break
    }

    if (status === true) {
      isAllowed = true
    }
  }

  return isAllowed
}

// Accounts for child menus
export const isNavItemEnabled = (
  navItem: NavItem,
  enabledAccessControls: EnabledAccessControls
) => {
  if (!('menu' in navItem)) {
    // For items without a menu just check the individual item
    return enabledByAccessControl(navItem, enabledAccessControls)
  }

  // Now deal with items with menus, ignore own requirements

  // Ignore empty groups
  if (navItem.menu.groups.length === 0) return false

  // If any item in any group is enabled, then enable this item
  return navItem.menu.groups.some((group) =>
    group.items.some((groupNavItem) =>
      enabledByAccessControl(groupNavItem, enabledAccessControls)
    )
  )
}

const disabledByPermissionMenuNavItem = (
  navItem: NavItem,
  enabledPermissions?: string[]
) => {
  const { status } = enabledBy('permissions', navItem, enabledPermissions)
  return 'menu' in navItem && navItem.permissions && !status
}

type AccessControlKeys = keyof AccessControls
type AccessControlValues = AccessControls[keyof AccessControls]

/**
 * Step through the various access control types checking the user's enabled controls
 */
function enabledBy(
  key: AccessControlKeys = 'personas',
  navItem: NavItem,
  enabledAccessControls?: AccessControlValues
): { status: boolean; type: string | undefined } {
  if (navItem[key] === undefined) {
    return { status: false, type: undefined }
  }

  if (key === 'features') {
    return {
      status: Boolean(includesSome(navItem.features, enabledAccessControls)),
      type: 'feature',
    }
  }

  const guestList = navItem[key] || []

  if (guestList.length === 0) {
    return { status: false, type: 'empty' }
  }

  const { adhoc, forbidden, required, remaining } =
    createControlLists(guestList)

  if (forbidden.length && includesSome(forbidden, enabledAccessControls)) {
    // user has one or more of the forbidden access controls
    return { status: false, type: 'forbidden' }
  }

  if (required.length) {
    return {
      status: includesAll(required, enabledAccessControls),
      type: 'required',
    }
  }

  if (includesSome(remaining, enabledAccessControls)) {
    // user has at least one of the allowed access controls
    return { status: true, type: 'remaining' }
  }

  if (adhoc.length) {
    return {
      status: handleAdhocRules(adhoc, enabledAccessControls),
      type: 'adhoc',
    }
  }

  // Final check (do it last): there are forbidden controls but none apply to the user
  if (forbidden.length && includesNone(forbidden, enabledAccessControls)) {
    return { status: true, type: 'forbidden' }
  }

  // if in doubt don't allow access
  return { status: false, type: 'final' }
}

// To be honest these are only here here to reduce changes to the existing tests.
// That sounds lazy but it ensures that the existing tests work with the refactor.
type EnabledByProps = {
  navItem: NavItem
  enabledAccessControls?: AccessControlValues
}
export const enabledByFeature = (props: EnabledByProps) =>
  enabledBy('features', props.navItem, props.enabledAccessControls)

export const enabledByPermission = (props: EnabledByProps) =>
  enabledBy('permissions', props.navItem, props.enabledAccessControls)

export const enabledByPersona = (props: EnabledByProps) =>
  enabledBy('personas', props.navItem, props.enabledAccessControls)

function createControlLists(controls: string[]) {
  const adhoc = []
  const forbidden = []
  const required = []
  const remaining = []

  const ADHOC = '@'
  const FORBIDDEN = '!'
  const REQUIRED = '&'

  for (const control of controls) {
    switch (control.slice(0, 1)) {
      case FORBIDDEN: {
        forbidden.push(control.slice(1))
        break
      }
      case REQUIRED: {
        required.push(control.slice(1))
        break
      }
      case ADHOC: {
        adhoc.push(control.slice(1))
        break
      }
      default: {
        remaining.push(control)
      }
    }
  }

  return {
    adhoc,
    forbidden,
    required,
    remaining,
  }
}

// COULD MOVE THIS TO A NEW MODULE
const adHocRules: Record<string, (input: AccessControlValues) => boolean> = {
  NOT_EMPTY: (input: AccessControlValues = []) => input.length > 0,
}

function handleAdhocRules(
  adhoc: string[],
  enabledAccessControls: AccessControlValues
): boolean {
  let adhocStatus

  for (const rule of adhoc) {
    const ruleToRun = adHocRules[rule]
    if (typeof ruleToRun === 'function') {
      adhocStatus = ruleToRun(enabledAccessControls)
      if (adhocStatus === false) {
        break
      }
    }
  }
  return !!adhocStatus
}

const includesAll = (a: string[] = [], b: string[] = []) =>
  a.every((value) => b.includes(value))

const includesSome = (a: string[] = [], b: string[] = []) =>
  a.some((value) => b.includes(value))

const includesNone = (a: string[] = [], b: string[] = []) => !includesSome(a, b)
