import {
  TextAreaInput,
  TextAreaInputProps,
} from '@cais-group/equity/atoms/text-input'

import { Field, FieldProps } from '../field'
import { getTextInputColor } from '../utils'

export type TextAreaInputFieldProps = TextAreaInputProps & FieldProps

export const TextInputAreaField = (props: TextAreaInputFieldProps) => {
  const { id, label, required, supportingText, helper, tag, ...textAreaInput } =
    props

  const color = getTextInputColor(helper, textAreaInput.color)

  return (
    <Field
      htmlFor={id}
      label={label}
      tag={tag}
      required={required}
      helper={helper}
      supportingText={supportingText}
    >
      <TextAreaInput {...textAreaInput} color={color} />
    </Field>
  )
}
