import styled from '@emotion/styled'

import { CaptionRegular, H5Strong } from '@cais-group/approved/ui/typography'
import { MercerRatingType } from '@cais-group/shared/util/type/caisiq-be'

export type MercerRatingProps = {
  rating?: MercerRatingType
  small?: boolean
}

const MercerRatingContainer = styled.div`
  min-width: 80px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const RatingValue = styled(H5Strong)<{
  small: boolean
}>`
  color: rgb(var(--colors-accent-600));
  ${({ small }) => (small ? `font-size: 14px;` : 'font-size: 24px;')}
`
const Caption = styled(CaptionRegular)`
  color: rgb(var(--colors-neutral-600));
`

export const MercerRating = (props: MercerRatingProps) => {
  const { rating, small = false } = props

  return (
    <MercerRatingContainer aria-label="mercer rating">
      <RatingValue small={small}>{rating?.trim() ? rating : '-'}</RatingValue>
      <Caption>Mercer</Caption>
    </MercerRatingContainer>
  )
}
