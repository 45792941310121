import * as React from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'

import { UniformProvider } from './context'
import { useRulesFeedSubject } from './hooks'
import { useSelectedOptions } from './hooks/use-selected-options'
import { UniformBasicLayout } from './layout'
import type { UniformServices } from './services'
import type { ComponentDict, UniformAny, UniformConfig } from './types'

export type UniformValues = Record<string, UniformAny>

export function useUniform<
  TUniformValues extends UniformValues = UniformValues,
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TPossibleEntries extends keyof UniformAny = UniformAny
>({
  methods: methodsOverride,
  mode,
  defaultValues,
  context,
  reValidateMode,
  resolver,
  shouldFocusError,
  shouldUnregister,
  criteriaMode,
  shouldUseNativeValidation,
  delayError,
  resetOptions,
  values,
  initialOptions,
  settings,
  ...props
}: UniformConfig<
  TUniformValues,
  TUniformServices,
  TComponentDict,
  TPossibleEntries
>) {
  // 💡: maybe we should always require to provide methods and useForm outside of this hook?
  const methods = useForm<TUniformValues>({
    mode: mode,
    defaultValues: defaultValues,
    context: context,
    reValidateMode: reValidateMode,
    resolver: resolver,
    shouldFocusError: shouldFocusError,
    shouldUnregister: shouldUnregister,
    criteriaMode: criteriaMode,
    shouldUseNativeValidation: shouldUseNativeValidation,
    delayError: delayError,
    resetOptions: resetOptions,
    values: values,
  })
  const selectedOptions = useSelectedOptions(initialOptions)
  const rulesFeed = useRulesFeedSubject()

  return {
    methods: methodsOverride ? methodsOverride : methods,
    selectedOptions,
    settings,
    initialOptions,
    rulesFeed,
    ...props,
  }
}

export interface UniformProps<
  TUniformValues extends UniformValues = UniformValues,
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TPossibleEntries extends keyof UniformAny = UniformAny
> extends UniformConfig<
    TUniformValues,
    TUniformServices,
    TComponentDict,
    TPossibleEntries
  > {
  selectedOptions: ReturnType<typeof useSelectedOptions>
  rulesFeed: ReturnType<typeof useRulesFeedSubject>
  methods: UseFormReturn<TUniformValues>
}

export const Uniform = <
  TUniformValues extends UniformValues = UniformValues,
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TPossibleEntries extends keyof UniformAny = UniformAny
>({
  schema,
  initialOptions,
  services,
  methods,
  componentDictionary,
  selectedOptions,
  rulesFeed,
  rules,
  future,
  settings,
  onSubmit = () => Promise.resolve(),
  onFailure,
  onSuccess,
  onSubmitAndRedirect,
  children,
  requiredFields,
  refetchFormData,
  ...rest
}: React.PropsWithChildren<
  UniformProps<
    TUniformValues,
    TUniformServices,
    TComponentDict,
    TPossibleEntries
  >
>) => {
  if (!schema) {
    console.warn("'schema' was not provided to <Uniform /> component!")
    return null
  }

  return (
    <FormProvider {...methods}>
      <UniformProvider
        formId={rest.id}
        schema={schema}
        selectedOptions={selectedOptions}
        rulesFeed={rulesFeed}
        initialOptions={initialOptions}
        services={services}
        componentDict={componentDictionary}
        settings={settings}
        rules={rules}
        future={future}
        onSubmit={onSubmit}
        onFailure={onFailure}
        onSuccess={onSuccess}
        onSubmitAndRedirect={onSubmitAndRedirect}
        requiredFields={requiredFields}
        refetchFormData={refetchFormData}
      >
        {children}
      </UniformProvider>
    </FormProvider>
  )
}

export type UniformBasicProps<
  TUniformValues extends UniformValues = UniformValues,
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TPossibleEntries extends keyof UniformAny = UniformAny
> = UniformProps<
  TUniformValues,
  TUniformServices,
  TComponentDict,
  TPossibleEntries
> &
  Parameters<typeof UniformBasicLayout>[0]

export const UniformBasic = <
  TUniformValues extends UniformValues = UniformValues,
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TPossibleEntries extends keyof UniformAny = UniformAny
>({
  onCancel,
  submitText,
  submitDisabled,
  className,
  ...methods
}: UniformBasicProps<
  TUniformValues,
  TUniformServices,
  TComponentDict,
  TPossibleEntries
>) => {
  return (
    <Uniform {...methods}>
      <UniformBasicLayout
        onCancel={onCancel}
        submitText={submitText}
        submitDisabled={submitDisabled}
        className={className}
      />
    </Uniform>
  )
}
