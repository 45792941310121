import styled from '@emotion/styled'
import { useRecoilState } from 'recoil'

import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { BannerWithButtonContainer } from '@cais-group/caisiq/ui/banner-with-button'
import { TypeVideoPlaylistData } from '@cais-group/shared/util/type/video-playlist-data'

import { VideosSeries } from './caisiq-feature-video-videos-series'

const StyledContainer = styled.div`
  padding-top: var(--s32);
  padding-bottom: var(--s144);
`

export type VideosProps = {
  playlists?: TypeVideoPlaylistData[]
}
export const Videos = ({ playlists }: VideosProps) => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)

  return (
    <StyledContainer>
      <VideosSeries
        playlists={playlists}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <BannerWithButtonContainer />
    </StyledContainer>
  )
}
