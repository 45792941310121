import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import MuiModal from '@mui/material/Modal'
import { ReactNode } from 'react'

import { Body, H4Strong } from '@cais-group/approved/ui/typography'
import { Button, ButtonProps } from '@cais-group/equity/atoms/button'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'

const Actions = styled.div<{ alignment: Alignment }>`
  display: flex;
  justify-content: ${({ alignment }) =>
    alignment === 'left' ? 'flex-end' : 'center'};
  column-gap: var(--s16);
  margin-top: var(--s24);
`

const Icon = styled.div<{ alignment: Alignment }>`
  display: flex;
  border-radius: 100%;
  margin: var(--s8);
  justify-content: ${({ alignment }) =>
    alignment === 'left' ? 'flex-end' : 'center'};
`

const Divider = styled.div`
  border-top: 1px solid rgb(var(--colors-neutral-200));
  width: 100%;
`

const Title = styled(H4Strong)<{ alignment: Alignment }>`
  text-align: ${({ alignment }) => (alignment === 'left' ? 'auto' : 'center')};
  transform: translateY(calc(-1 * (var(--s16))));
`

const StyledBody = styled(Body)<{
  isShort: boolean
  alignment: Alignment
  $renderFullHeight: boolean
}>`
  color: rgb(var(--colors-neutral-600));
  margin-top: var(--s24);
  min-height: ${({ isShort }) => (isShort ? 'var(--s56)' : 'var(--s144)')};
  max-height: ${({ $renderFullHeight }) =>
    $renderFullHeight ? '84vh' : 'var(--s376)'};
  overflow-y: auto;
  text-align: ${({ alignment }) => (alignment === 'left' ? 'auto' : 'center')};
`
const BodyDivider = styled.div`
  box-shadow: inset 0px -4px var(--s8) rgba(0, 0, 0, 0.04);
  width: 100%;
  height: var(--s8);
  padding-top: var(--s8);
  margin-top: var(--s12);
`
type Alignment = 'left' | 'center'

export type Variant = 'primary' | 'secondary' | 'danger' | 'tertiary'

type Action =
  | {
      text: string
      loading?: boolean
      disabled?: boolean
      handler: () => void
      variant: Variant
      testId?: string
      additionalProps?: Omit<ButtonProps, 'children'>
    }
  | {
      variant: 'spacer'
    }

export type ModalProps = {
  alignment?: Alignment
  title: string | JSX.Element
  body: ReactNode
  icon?: ReactNode
  open: boolean
  onClose: () => void
  actions: Action[]
  disablePortal?: boolean
  hideBackdrop?: boolean
  disableAutoFocus?: boolean
  disableScrollLock?: boolean
  $renderFullHeight?: boolean
  hasMoreContent?: boolean
  extraLarge?: boolean
  hideCloseButton?: boolean
  children?: ReactNode
  testId?: string
}

const StyledCloseIcon = styled(CloseIcon)`
  color: rgba(33, 28, 33, 0.34);
`

const StyledBox = styled(Box)<{ extraLarge: boolean }>`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgb(var(--colors-neutral-0));
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 6px rgba(33, 28, 33, 0.06);
  padding: var(--s8) var(--s24) var(--s32) var(--s32);
  outline: 0;

  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
    top: 50%;
    left: 50%;
    width: ${({ extraLarge }) =>
      extraLarge ? 'calc(100% - var(--s56))' : '50%'};
    height: ${({ extraLarge }) =>
      extraLarge ? 'calc(100% - var(--s56))' : 'fit-content'};
    background-color: rgb(var(--colors-neutral-0));
    transform: translate(-50%, -50%);
  }
`

const Spacer = styled.div`
  flex: 1;
`

/**
 * @deprecated Please use `ControlledModal` from equity instead, imported from
 * `import { ControlledModal } from '@cais-group/equity/organisms/modal'` as it offers a mostly compatible
 * API with a more modern implementation.
 *
 * To update `body` should be moved to `children`. The actions require a little work to update: now more
 * directly exposes underlying `Button` props; you may need a combination of `variant` and `color` to
 * achieve what was previously just `variant` e.g. `variant="danger"` comes `variant="primary" color="error"`.
 *
 * The actions use `children` and `onClick` rather than `text` and `handler`
 *
 * Integration tests can be a bit tricky to update as the new modal is kept in DOM when closed; one drop in that might help a lot is `.filter(':visible')`. Additionally ModalLayout sets up a rather generic testIds like 'modal-primary-button' unless you override. The new ControlledModal does not, but actions take a `testId` item so should be easy to add (and clearer than using the generic ones).
 */
export const ModalLayout = ({
  title,
  alignment = 'left',
  body,
  open,
  onClose,
  actions,
  icon,
  children,
  disablePortal = false,
  hideBackdrop = false,
  disableAutoFocus = false,
  disableScrollLock = false,
  $renderFullHeight = false,
  hasMoreContent = false,
  extraLarge = false,
  hideCloseButton = false,
  testId = '',
}: ModalProps) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-layout-title"
      aria-describedby="modal-layout-description"
      disablePortal={disablePortal}
      hideBackdrop={hideBackdrop}
      disableScrollLock={disableScrollLock}
      disableAutoFocus={disableAutoFocus}
      classes={{ root: 'modal-root' }}
      data-testid={testId}
    >
      <StyledBox extraLarge={extraLarge}>
        {!hideCloseButton && (
          <Box display="flex" alignItems="flex-start" justifyContent="end">
            <IconButton
              onClick={onClose}
              edge="end"
              aria-label="Close"
              data-testid="modal-layout-close-button"
            >
              <StyledCloseIcon />
            </IconButton>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent={alignment === 'left' ? 'space-between' : 'auto'}
          flexDirection="column"
          sx={{ height: '100%' }}
        >
          <Box>
            {icon ? <Icon alignment={alignment}>{icon}</Icon> : null}
            <Title alignment={alignment} id="modal-layout-title">
              {title}
            </Title>
            {alignment === 'left' ? <Divider /> : null}
            <StyledBody
              $renderFullHeight={$renderFullHeight}
              as="div"
              alignment={alignment}
              id="modal-modal-description"
              isShort={!!children}
            >
              {body}
            </StyledBody>
            {hasMoreContent ? <BodyDivider /> : null}
            {children}
          </Box>
          <Actions alignment={alignment}>
            {actions.map((action, index) => {
              switch (action.variant) {
                case 'spacer':
                  return <Spacer key={index} />
                case 'tertiary':
                  return (
                    <Button
                      variant="tertiary"
                      key={index}
                      onClick={action.handler}
                      loading={action.loading}
                      disabled={action.disabled}
                      data-testid={action.testId ?? 'modal-tertiary-button'}
                      {...action.additionalProps}
                    >
                      {action.text}
                    </Button>
                  )
                case 'secondary':
                  return (
                    <Button
                      variant="secondary"
                      key={index}
                      onClick={action.handler}
                      loading={action.loading}
                      disabled={action.disabled}
                      data-testid={action.testId ?? 'modal-secondary-button'}
                      {...action.additionalProps}
                    >
                      {action.text}
                    </Button>
                  )
                case 'primary':
                  return (
                    <Button
                      key={index}
                      onClick={action.handler}
                      loading={action.loading}
                      disabled={action.disabled}
                      data-testid={action.testId ?? 'modal-primary-button'}
                      {...action.additionalProps}
                    >
                      {action.text}
                    </Button>
                  )
                case 'danger':
                  return (
                    <Button
                      color="error"
                      key={index}
                      onClick={action.handler}
                      loading={action.loading}
                      disabled={action.disabled}
                      data-testid={action.testId ?? 'modal-primary-button'}
                      {...action.additionalProps}
                    >
                      {action.text}
                    </Button>
                  )
                default:
                  // This will actually never happen but lint won't believe TS?
                  return null
              }
            })}
          </Actions>
        </Box>
      </StyledBox>
    </MuiModal>
  )
}
