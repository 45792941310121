import styled from '@emotion/styled'
import { Skeleton } from '@mui/material'
import { Link } from 'react-router-dom'
import { SetterOrUpdater } from 'recoil'

import { H5Strong, BodyStrong } from '@cais-group/approved/ui/typography'
import { INDIVIDUAL_VIDEO_PROJECT } from '@cais-group/caisiq/domain/video'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { VideoItem } from '@cais-group/caisiq/ui/video/item'
import { Carousel } from '@cais-group/caisiq-ui-carousel-default'
import { NoResults } from '@cais-group/caisiq-ui-no-results'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { LayoutCluster, LayoutGrid } from '@cais-group/shared/ui/layout'
import { TypeVideoData } from '@cais-group/shared/util/type/video-data'
import { TypeVideoPlaylistData } from '@cais-group/shared/util/type/video-playlist-data'

const VideoSeriesContainer = styled.div`
  min-height: 341px; // Temporary height while loading
  padding-bottom: var(--s8);
  margin-bottom: var(--s32);
  border-bottom: 1px solid rgb(var(--colors-neutral-200));
`

const StyledLink = styled(Link)`
  cursor: pointer;
`

const StyledGridContainer = styled.div`
  padding-top: var(--s16);
`

const StyledGrid = styled(LayoutGrid)`
  padding: var(--s24) 0;
`
const StyledName = styled(BodyStrong)`
  color: rgb(var(--colors-neutral-900));
  max-width: 362px;
`
const VideoItemSkeleton = styled.div`
  height: 204px;
  width: 362px;
  padding-bottom: var(--s16);
`

const SkeletonContainer = styled(LayoutGrid)`
  padding: var(--s24) 0;
  display: flex;
  overflow: hidden;
`

type VideosContentType = {
  playlists?: TypeVideoPlaylistData[]
  searchTerm: string
  setSearchTerm: SetterOrUpdater<string>
}

export const VideosSeries = (props: VideosContentType) => {
  const { playlists, searchTerm, setSearchTerm } = props

  if (!playlists || playlists.length === 0) {
    if (searchTerm === '') {
      return (
        <>
          {Array.from(Array(3).keys(), (idx) => (
            <VideoCarouselSkeleton key={idx} />
          ))}
        </>
      )
    } else {
      return (
        <NoResults
          searchSetName="Videos"
          searchTerm={searchTerm}
          onClick={() => setSearchTerm('')}
        />
      )
    }
  }

  return (
    <>
      {playlists.map((playlist) => {
        const firstVideoId = playlist.videosCollection.items[0]?.sys.id
        return playlist.sys.id === INDIVIDUAL_VIDEO_PROJECT ? (
          <StyledGridContainer key={playlist.sys.id}>
            <H5Strong>Other videos</H5Strong>
            <ProjectGrid playlist={playlist}></ProjectGrid>
          </StyledGridContainer>
        ) : (
          <VideoSeriesContainer
            key={playlist.sys.id}
            data-testid="video-series"
          >
            <LayoutCluster justify="space-between">
              <H5Strong>{playlist.title}</H5Strong>
              {firstVideoId && (
                <StyledLink to={ROUTES.video(playlist.sys.id, firstVideoId)}>
                  <BodyStrong>View the full series</BodyStrong>
                </StyledLink>
              )}
            </LayoutCluster>
            <PlaylistCarousel playlist={playlist}></PlaylistCarousel>
          </VideoSeriesContainer>
        )
      })}
    </>
  )
}

const renderVideo = ({
  videos,
  episodic,
  isSingleVideo = false,
  playlistId,
}: {
  videos: TypeVideoData[]
  episodic: boolean
  isSingleVideo?: boolean
  playlistId?: string
}) => {
  return (
    videos?.map((video, index) => (
      <VideoItem
        video={video}
        key={video.sys.id}
        to={ROUTES.video(playlistId, video.sys.id)}
        episode={isSingleVideo || !episodic ? undefined : index + 1}
      />
    )) ?? []
  )
}

const PlaylistCarousel = (props: { playlist: TypeVideoPlaylistData }) => {
  const { playlist } = props
  if (playlist.videosCollection.items === undefined) {
    return null
  }

  const THUMBNAIL_HEIGHT = 204
  return (
    <Carousel arrowYPosition={THUMBNAIL_HEIGHT / 2}>
      {renderVideo({
        videos: playlist.videosCollection.items,
        episodic: Boolean(playlist.episodic),
        playlistId: playlist.sys.id,
      })}
    </Carousel>
  )
}

const ProjectGrid = (props: { playlist: TypeVideoPlaylistData }) => {
  const { playlist } = props

  if (playlist === undefined) {
    return null
  }
  return (
    <StyledGrid>
      {renderVideo({
        videos: playlist.videosCollection.items,
        episodic: Boolean(playlist.episodic),
        playlistId: playlist.sys.id,
        isSingleVideo: true,
      })}
    </StyledGrid>
  )
}

const VideoCarouselSkeleton = () => {
  return (
    <VideoSeriesContainer
      style={{ overflow: 'hidden' }}
      data-testid="videos-skeleton"
    >
      <LayoutCluster justify="space-between">
        <Skeleton
          variant="rectangular"
          height={SPACING.s32}
          width={SPACING.s144}
        />
        <Skeleton
          variant="rectangular"
          height={SPACING.s32}
          width={SPACING.s144}
        />
      </LayoutCluster>
      <SkeletonContainer>
        {Array.from(Array(5).keys(), (i) => (
          <VideoItemSkeleton key={i}>
            <Skeleton variant="rectangular" height="176px" />
            <StyledName>
              <Skeleton width="362px" />
            </StyledName>
            <Skeleton width={SPACING.s144} />
          </VideoItemSkeleton>
        ))}
      </SkeletonContainer>
    </VideoSeriesContainer>
  )
}
