import { ListItem } from '@cais-group/shared/ui/disabled-list'
import {
  Catalog,
  ExperienceResponse,
} from '@cais-group/shared/util/type/caisiq-be'

const tranformCatalogToList = ({ name }: Pick<Catalog, 'name'>): ListItem[] => [
  {
    label: 'ALM Catalog',
    value: name,
  },
]

const transformExperienceToList = ({
  name,
  label,
  contentfulName,
}: Pick<ExperienceResponse, 'name' | 'label'> & {
  contentfulName: string
}): ListItem[] => [
  {
    label: 'Experience name',
    value: name,
  },
  {
    label: 'Experience label',
    value: label,
  },
  {
    label: 'Contentful name',
    value: contentfulName || '',
  },
]

export { tranformCatalogToList, transformExperienceToList }
