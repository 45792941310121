import * as React from 'react'

export function useRandomId(randomness: string = '') {
  const [sectionId] = React.useState(() =>
    randomness
      .concat('__')
      .concat(String(Math.floor(Math.random() * Date.now())))
  )
  return sectionId
}
