import { Chip } from '@mui/material'
import { ReactElement } from 'react'
import { Controller } from 'react-hook-form'

/**
 * A simple component that reads a piece of form state and shows values based on labels (also to be supplied); this is based on component used in SP orders; probably will get actual design at some point(!)
 */
export function SelectedItems({
  name,
  labels,
}: {
  name: string
  labels: Record<string, string>
}) {
  return (
    <Controller
      defaultValue={[]}
      name={name}
      render={({ field: { onChange, value: selectedOptions } }) => {
        const labelledItems: { value: string; label: string }[] =
          selectedOptions.map((id: string) => ({
            value: id,
            label: labels[id],
          }))

        // Types from library seem too restrictive
        if (labelledItems.length === 0) return null as unknown as ReactElement

        return (
          <div className="max-h-144 flex flex-row flex-wrap gap-8 overflow-auto">
            {labelledItems.map(({ value, label }) => (
              <Chip
                key={value}
                label={label}
                onDelete={() =>
                  onChange(selectedOptions.filter((id: string) => id !== value))
                }
              />
            ))}
          </div>
        )
      }}
    />
  )
}
