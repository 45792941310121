import { useFormContext } from 'react-hook-form'

import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { CaisIqFirm } from '@cais-group/shared/util/type/caisiq-be'

export function useFirmChecklist(firmData: CaisIqFirm[]) {
  const { getValues, setValue } = useFormContext<ExperienceFields>()

  return (value: string, wasPreviouslySelected: boolean) => {
    const firms = getValues('firms') ?? []

    if (!wasPreviouslySelected) {
      const firm = firmData.find((f) => f.id === value)
      if (firm) {
        setValue('firms', [...firms, firm])
      }
    } else {
      setValue(
        'firms',
        firms.filter((f) => f.id !== value)
      )
    }
  }
}
