import type { createDefaultUniformDictionary } from './adapters'
import type { UniformServices } from './services'
import type {
  UniformConfig,
  ComponentDict,
  UniformAny,
  UniformSchema,
} from './types'
import type { UniformValues } from './uniform'

/**
 * This just improves the type, otherwise just a pass-through
 */
export const createUniform = <
  TUniformValues extends UniformValues = UniformValues,
  TUniformServices extends UniformServices = UniformServices,
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TComponentDict extends ComponentDict = ComponentDict
>(
  props: UniformConfig<
    TUniformValues,
    TUniformServices,
    TComponentDict,
    TFormFieldIds
  >
) => {
  return props
}

/**
 * This just improves the type, otherwise just a pass-through
 */
export const createUniformSchema = <
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ReturnType<
    typeof createDefaultUniformDictionary<TFormFieldIds, TUniformServices>
  >
>(
  schema: UniformSchema<TUniformServices, TComponentDict, TFormFieldIds>
) => {
  return schema
}
