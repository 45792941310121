import { useState } from 'react'
import { createMachine, assign } from 'xstate'

import { StepProps } from '@cais-group/equity/atoms/step'

import type { UniformAny, UniformSchema } from '../types'

type StepState = StepProps['state']

export type StepperEvents =
  | {
      type: 'NEXT'
    }
  | { type: 'PREVIOUS' }
  | { type: 'GOTO'; stepId: string }
  | {
      type: 'UPDATE_STEPS'
      steps: UniformSchema<UniformAny>['children']
    }

export type StepperContext = {
  currentStepIndex: number
  currentStep: string | undefined
  steps: UniformSchema<UniformAny>['children']
  stepState: StepState[]
  hasNext: boolean
  hasPrevious: boolean
}

export function useGenerateMachineFromSchema() {
  const [machine] = useState(
    createMachine<StepperContext, StepperEvents>(
      {
        id: 'stepper',
        context: {
          currentStepIndex: 1,
          currentStep: undefined,
          stepState: [],
          steps: [],
          hasPrevious: false,
          hasNext: false,
        },
        initial: 'initialiseStepper',
        schema: {
          events: {} as StepperEvents,
        },
        on: {
          UPDATE_STEPS: {
            actions: ['updateSteps', 'calculateContext'],
          },
        },
        states: {
          initialiseStepper: {
            exit: ['calculateContext'],
            always: {
              target: 'collectingData',
            },
          },
          collectingData: {
            on: {
              PREVIOUS: {
                actions: ['movePrevious', 'calculateContext'],
              },
              NEXT: {
                target: 'validatingStep',
              },
              GOTO: {
                target: 'validatingStep',
                actions: ['goToStep', 'calculateContext'],
              },
            },
          },
          validatingStep: {
            invoke: {
              id: 'validateStep',
              src: 'validateStep',
              onDone: {
                target: 'collectingData',
                actions: ['moveNext', 'calculateContext'],
              },
              onError: {
                target: 'collectingData',
                actions: ['calculateContext'],
              },
            },
          },
          submitting: {},
        },
      },
      {
        actions: {
          goToStep: assign<StepperContext, StepperEvents>({
            currentStep: (_, event) => {
              return 'stepId' in event ? (event?.stepId as string) : ''
            },
            currentStepIndex: ({ currentStepIndex, steps }, event) => {
              if (!('stepId' in event)) {
                return currentStepIndex
              }
              const found = steps.findIndex(
                (step) => step.id === event['stepId']
              )
              // Note: This returns the step before, but we target validation, which then moves to the next step
              // Doing it this way ensure that all steps before the step we jump to are valid
              return found
            },
          }),
          moveNext: assign<StepperContext, StepperEvents>({
            currentStepIndex: ({ currentStepIndex }) => {
              return currentStepIndex + 1
            },
          }),
          updateSteps: assign<StepperContext, StepperEvents>({
            steps: (_, event) => {
              return 'steps' in event ? event.steps : []
            },
          }),
          movePrevious: assign<StepperContext, StepperEvents>({
            currentStepIndex: ({ currentStepIndex }) => currentStepIndex - 1,
          }),
          calculateContext: assign<StepperContext, StepperEvents>({
            stepState: ({ steps, currentStepIndex }) => {
              return steps.map((_, i) => {
                if (i + 1 === currentStepIndex) {
                  return 'active'
                } else if (i < currentStepIndex) {
                  return 'complete'
                } else {
                  return 'incomplete'
                }
              })
            },
            hasNext: ({ currentStepIndex, steps }) =>
              currentStepIndex < steps.length,
            hasPrevious: ({ currentStepIndex }) => currentStepIndex > 1,
          }),
        },
      }
    )
  )

  return machine
}
