import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

import {
  BodyStrong,
  Overline,
  SubtitleMono,
} from '@cais-group/approved/ui/typography'
import { ellipsify } from '@cais-group/equity/utilitarian'
import {
  millisecondsToMinutes,
  secondsToMinutes,
} from '@cais-group/shared/util/js/format-length'
import { TypeVideoData } from '@cais-group/shared/util/type/video-data'

export type VideoItemProps = {
  episode?: number
  video: TypeVideoData
  to: Parameters<typeof Link>[0]['to']
}

const StyledContainer = styled(Link)`
  cursor: pointer;
  & > img {
    transition: transform 150ms ease-out;
  }
  &:hover > img {
    transform: scale(0.98);
  }
`

const THUMBNAIL_HEIGHT = 204
const THUMBNAIL_WIDTH = 362
const Thumbnail = styled.img`
  width: ${THUMBNAIL_WIDTH};
  height: ${THUMBNAIL_HEIGHT};
  padding-bottom: var(--s16);
`
Thumbnail.displayName = 'Thumbnail'

const Subtitle = styled(SubtitleMono)`
  color: rgb(var(--colors-neutral-600));
`
Subtitle.displayName = 'Subtitle'

const StyledName = styled(BodyStrong)`
  color: rgb(var(--colors-neutral-900));
  max-width: 362px;
`

const StyledEpisode = styled(Overline)`
  color: rgb(var(--colors-neutral-900));
  padding-bottom: calc(var(--s8) - 2px);
`

export const VideoItem = (props: VideoItemProps) => {
  const { video, to, episode } = props

  const duration = video?.muxVideo?.duration
    ? secondsToMinutes(video.muxVideo.duration, 'ceil')
    : millisecondsToMinutes(video.duration ?? 0, 'ceil')

  return (
    <StyledContainer to={to} data-testid={`video-item-${video.sys.id}`}>
      <Thumbnail src={`${video.thumbnail?.url}?fm=jpg`} />
      {episode && <StyledEpisode>{`Episode ${episode}`}</StyledEpisode>}
      <StyledName>{ellipsify(video.title || '', 75)}</StyledName>
      <Subtitle>
        {dayjs(video.displayDate || video.sys.firstPublishedAt).format('MMM D')}
        {` • ${duration} min`}
      </Subtitle>
    </StyledContainer>
  )
}
