import { useAuth0 } from '@auth0/auth0-react'
import { AxiosError, AxiosInstance } from 'axios'
import { throttle } from 'lodash-es'
import { useCallback, useRef } from 'react'
import { useQuery } from 'react-query'

import { useAxiosInstance } from '@cais-group/caisiq/util/hook/use-axios-instance'
import { QueryParams } from '@cais-group/caisiq/util/type/common'
import { OptionType } from '@cais-group/caisiq-ui-autocomplete'
import { showToast } from '@cais-group/equity/organisms/notifications'
import { ApiPaths } from '@cais-group/shared/util/type/caisiq-be'
import { HomeOfficeUserType } from '@cais-group/shared/util/type/homeoffice-data'

export interface HomeOfficeQueryParams extends QueryParams {
  user?: string
  team?: string
}

export const useGetHomeOfficeUserState = (options: {
  pause?: boolean
  queryParams: HomeOfficeQueryParams
}) => {
  const skeletonLoadingState = useRef(true)
  const { pause, queryParams } = options
  const { isAuthenticated } = useAuth0()
  const axios = useAxiosInstance()

  const setTableLoadingState = useCallback((loadingState: boolean) => {
    skeletonLoadingState.current = loadingState
  }, [])

  const { isLoading, data, isPreviousData } = useQuery<
    HomeOfficeUserType,
    AxiosError
  >(
    [ApiPaths.homeOfficeStatuses, queryParams],
    async () =>
      axios
        .get(ApiPaths.homeOfficeStatuses, {
          params: {
            ...queryParams,
          },
        })
        .then((res) => res.data),
    {
      enabled: isAuthenticated && !pause,
      keepPreviousData: true,
      retry: 3,
      staleTime: 300_000, // 5 minutes
      onError: (error) => {
        console.error(
          'There was a problem retrieving the retreiving home office data.',
          error,
          error.response?.status
        )
        showToast({
          type: 'error',
          title: 'An error occurred, some features may be unavailable.',
        })
      },
      onSuccess: () => {
        if (skeletonLoadingState.current) {
          setTableLoadingState(false)
        }
      },
    }
  )

  return {
    isLoading,
    data: data?.statuses,
    pagination: data?.pagination,
    isPreviousData,
    setTableLoadingState,
    skeletonLoadingState: skeletonLoadingState.current,
  }
}

function getAdvisorCounts(
  query: string,
  limit: number,
  axiosInstance: AxiosInstance
) {
  return axiosInstance
    .get(`${ApiPaths.advisorsCounts}?match=${query}&limit=${limit}`, {
      timeout: 1000 * 60 * 5, // 5 minutes
      transformResponse: (dataJSONString: string) => {
        const data: OptionType[] = JSON.parse(dataJSONString)
        const learners = data?.filter((d: OptionType) => d.type === 'advisor')
        const teams = data?.filter((d: OptionType) => d.type === 'team')
        const groups = [
          {
            label: 'Learners',
            options: learners,
            count: learners.length,
          },
          {
            label: 'Teams',
            options: teams,
            count: teams
              .map((team: OptionType) => team.count)
              .reduce((prev: number, current: number) => prev + current, 0),
          },
        ]

        return groups ?? []
      },
    })
    .then((response) => response.data)
}

export const useGetAdvisorCounts = (options?: { limit?: number }) => {
  const limit = options?.limit ?? 1000
  const axios = useAxiosInstance()

  return useCallback(
    async (query: string) => {
      if (query.length === 0) {
        return []
      }

      try {
        // Throttle every 1 second
        const getAdvisorCountsThrottled = throttle(getAdvisorCounts, 1000, {
          trailing: true,
        })
        const groups = await getAdvisorCountsThrottled(query, limit, axios)
        return groups
      } catch (error) {
        throw new Error((error as AxiosError).message)
      }
    },
    [axios, limit]
  )
}
