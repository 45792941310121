import type { UniformAny, UniformFieldProps } from '../types'

/**
 * This function, getDepsByDepKey, performs a deep search through a given object (schema)
 * and finds all objects that contain a certain key (dependencyKey).
 * The function returns an array of all such objects.
 *
 * The function accepts two parameters:
 * 1. schema: An object of a generic type T which extends Record<string, UniformAny>, where 'UniformAny' represents any type.
 *    This is the object to perform the operation on.
 * 2. dependencyKey: The key to look for in the object.
 *
 * For example:
 *
 * Input:
 * ```
 * schema = {
 *  "name": "object1",
 *  "dependencyKey": "value1",
 *  "children": [
 *    {
 *      "name": "object2",
 *      "dependencyKey": "value2"
 *    },
 *    {
 *      "name": "object3"
 *    }
 *  ]
 * },
 * dependencyKey = "dependencyKey"
 *```
 *```
 * Output:
 * [
 *  {
 *    "name": "object1",
 *    "dependencyKey": "value1",
 *    "children": [
 *      {
 *        "name": "object2",
 *        "dependencyKey": "value2"
 *      },
 *      {
 *        "name": "object3"
 *      }
 *    ]
 *  },
 *  {
 *    "name": "object2",
 *    "dependencyKey": "value2"
 *  }
 * ]
 *```
 */
export function getDepsByDepKey<T extends Record<string, UniformAny>>(
  schema: T,
  dependencyKey: string
): UniformFieldProps[] {
  const result: UniformFieldProps[] = []

  function extract(obj: T) {
    if (typeof obj === 'object' && obj !== null) {
      if (obj[dependencyKey]) {
        result.push(obj as unknown as UniformFieldProps)
      }
      Object.values(obj).forEach((val) => extract(val))
    }
  }
  extract(schema)

  return result
}
