import { datadogRum } from '@datadog/browser-rum'
import { AxiosError } from 'axios'
import { useState } from 'react'

import { useAxiosInstance } from '@cais-group/caisiq/util/hook/use-axios-instance'
import { showToast } from '@cais-group/equity/organisms/notifications'
import { ApiPaths } from '@cais-group/shared/util/type/caisiq-be'

export const useGetHomeOfficeReport = () => {
  const [exportingCSV, setExportingCSV] = useState(false)

  const axios = useAxiosInstance()

  return {
    exporting: exportingCSV,
    callback: (searchParams: {
      user?: string
      team?: string
      sort: string
    }) => {
      setExportingCSV(true)
      axios
        .get(ApiPaths.homeOfficeStatusesReport, {
          responseType: 'blob',
          params: {
            ...searchParams,
          },
        })
        .then((res) => {
          const url = window.URL.createObjectURL(res.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'homeoffice.csv')
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(link)
          setExportingCSV(false)
        })
        .catch((error: AxiosError<unknown>) => {
          datadogRum.addError(error, {
            appName: 'CaisIQ',
            type: 'HomeOfficeExportCSV',
            response: error.response,
          })
          showToast({
            type: 'error',
            title:
              'There was an error exporting the CSV. If this problem persists, contact clientservice@caisgroup.com.',
          })
          setExportingCSV(false)
        })
    },
  }
}
