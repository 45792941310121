import styled from '@emotion/styled'
import CircleIcon from '@mui/icons-material/Circle'
import { ComponentProps, ReactNode, Ref } from 'react'
import { omit } from 'remeda'

type Size = 'large' | 'medium' | 'small'
export type MaterialBtnColor =
  | 'btn-white'
  | 'btn-green'
  | 'btn-gray'
  | 'btn-red'
  | 'btn-blue'

const getPadding = (size?: Size): string => {
  switch (size) {
    case 'large':
      return '0.75rem var(--s24)'
    case 'medium':
      return 'var(--s8) var(--s16)'
    case 'small':
      return '0.25rem 0.75rem'
    default:
      return '0.75rem var(--s24)'
  }
}

// TODO: use font components from the Text file
const getFontSize = (size?: Size): string => {
  switch (size) {
    case 'large':
      return 'var(--s16)'
    case 'medium':
      return '0.875rem'
    case 'small':
      return '0.75rem'
    default:
      return 'var(--s16)'
  }
}
// circle
export const Loading = styled(CircleIcon)`
  width: 24px;
  height: 24px;
  fill: transparent;
  stroke: rgb(var(--colors-neutral-0)) !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Hidden = styled.div`
  visibility: hidden;
`

const ButtonAbstractStyles = styled.div`
  position: relative;
`

export type ButtonProps = ComponentProps<ReturnType<typeof styled.button>> & {
  disabled?: boolean
  size?: Size
  children?: ReactNode
  loading?: boolean
  className?: string
  color?: MaterialBtnColor
  ref?: Ref<HTMLButtonElement>
}

export const MaterialUIButtonAbstract = styled((props: ButtonProps) => {
  const { children, loading } = props
  const passedProps = omit(props, ['children', 'loading'])
  return (
    // @ts-ignore
    <ButtonAbstractStyles {...passedProps}>
      {loading ? (
        <>
          <Hidden>{children}</Hidden> <Loading />{' '}
        </>
      ) : (
        children
      )}
    </ButtonAbstractStyles>
  )
})`
  padding: ${(props) => getPadding(props.size)};
  font-size: ${(props) => getFontSize(props.size)};
  display: flex;
`
MaterialUIButtonAbstract.displayName = 'MaterialUIButtonAbstract'
