import styled from '@emotion/styled'

import {
  BodyCss,
  CaptionRegularCss,
  H1StrongCss,
  H4Css,
} from '@cais-group/approved/ui/typography'
import { Pie } from '@cais-group/caisiq-ui-chart-pie'
import { colors } from '@cais-group/equity/particles/colors'

export interface ChartCreditsCompletedProps {
  color: string
  completed: number
  total: number
  type: string
}

const ProgressText = styled.text`
  ${H1StrongCss}
  fill: ${colors['eq-color-neutral-900']};
`

const OfStyle = styled.tspan`
  ${H4Css}
  baseline-shift: 20%;
  fill: ${colors['eq-color-neutral-200']};
`
const TotalStyle = styled.tspan`
  ${BodyCss}
  baseline-shift: 67%;
  fill: ${colors['eq-color-neutral-500']};
`

const CELabel = styled.text`
  ${CaptionRegularCss}
  fill: ${colors['eq-color-neutral-600']};
`

export const ChartCreditsCompleted: React.FC<ChartCreditsCompletedProps> = ({
  color,
  completed,
  total,
  type,
}) => {
  return (
    <Pie
      size={200}
      strokeWidth={5}
      pies={[
        { percent: (completed / total) * 100, color: color },
        {
          percent: ((total - completed) / total) * 100,
          color: colors['eq-color-neutral-200'],
        },
      ]}
    >
      <ProgressText
        x="-0.1em"
        y="0"
        role="progressbar"
        aria-valuenow={completed}
        aria-valuemax={total}
        aria-label={`${type} credits`}
        aria-valuetext={`${completed} of ${total}`}
      >
        {completed}
        <OfStyle dx="5">/</OfStyle>
        <TotalStyle dx="3">{total}</TotalStyle>
      </ProgressText>
      <CELabel y="2.2em">
        <tspan x="0" dy="0">
          {type} credits
        </tspan>
        <tspan x="0" dy="1.2em">
          completed*
        </tspan>
      </CELabel>
    </Pie>
  )
}

export default ChartCreditsCompleted
