import { Components } from '@mui/material'
import { LabelCssObject } from '@cais-group/approved/ui/typography'

export const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      '.MuiFormControlLabel-label': {
        ...LabelCssObject,
        color: 'var(--color-gray-64)',
      },
    },
  },
}
