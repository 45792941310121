import styled from '@emotion/styled'
import { List, ListItemButton } from '@mui/material'

import { LabelCss } from '@cais-group/approved/ui/typography'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'

const StyledSectionItem = styled(ListItemButton)`
  ${LabelCss}
  list-style: none;
  padding-top: var(--s8);
  padding-left: var(--s8);
  padding-bottom: var(--s8);
  &:hover {
    background-color: rgb(var(--colors-neutral-100));
  }
  &.Mui-selected {
    background: none;
    color: rgb(var(--colors-primary-600));
    &:hover {
      background-color: rgb(var(--colors-neutral-100));
    }
  }
  &.Mui-disabled {
    color: var(--color-gray);
  }
`

const StyledSideNavigationWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-left: var(--s24);
  padding-right: var(--s24);
  padding-top: var(--s12);
  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
    padding-left: var(--s32);
    padding-right: var(--s32);
    top: 120px;
  }
`

export type SideNavigationSection = {
  sectionName: string
  sectionId: string
  selected: boolean
  disabled?: boolean
}
export type SideNavigationProps = {
  sections: SideNavigationSection[]
  onChange?: (id: number) => void
}

export const handleChange = (
  index: number,
  sectionId: string,
  onChange?: (id: number) => void
) => {
  if (onChange) {
    onChange(index)
  }
  const section = document.querySelector(`#${sectionId}`)
  if (section) section.scrollIntoView({ behavior: 'smooth' })
}

export const SideNavigation = ({ sections, onChange }: SideNavigationProps) => {
  return sections.length > 0 ? (
    <StyledSideNavigationWrapper data-testid="side-navigation">
      <List className="mb-144">
        {sections.map(
          (
            { sectionName, sectionId, selected = false, disabled = false },
            idx
          ) => (
            <li key={`side-navigation-${sectionId}`}>
              <StyledSectionItem
                selected={selected}
                disabled={disabled}
                onClick={() => {
                  handleChange(idx, sectionId, onChange)
                }}
                data-testid={`side-navigation-${sectionId}`}
              >
                {sectionName}
              </StyledSectionItem>
            </li>
          )
        )}
      </List>
    </StyledSideNavigationWrapper>
  ) : null
}
