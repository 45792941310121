import cx from 'classnames'
import { ComponentPropsWithoutRef, ReactNode, useLayoutEffect } from 'react'

import { ModalControlProps } from './useModal'

export enum ModalVariants {
  Small = 'small',
  Default = 'default',
  Large = 'large',
}

/**
 * Properties for the BaseModal component.
 */
export type BaseModalProps = {
  /** The control props for managing modal state. */
  control: ModalControlProps
  /**
   * An optional callback to be invoked when the backdrop is clicked.
   * Receives the mouse event as an argument.
   * Note that BaseModal doesn't handle any interaction
   */
  onBackdropClick?: (event: MouseEvent) => void
  /**
   * An optional variant of the modal.
   * This is a template literal type based on the ModalVariants enum.
   */
  variant?: `${ModalVariants}`
  /** An optional flag indicating whether the backdrop is transparent or not. */
  transparentBackdrop?: boolean
  /** The content to be rendered within the modal. */
  children: ReactNode
} & ComponentPropsWithoutRef<'dialog'>

export const BaseModal = ({
  control,
  onBackdropClick = () => {},
  variant = ModalVariants.Default,
  transparentBackdrop = false,
  children,
  ...otherProps
}: BaseModalProps) => {
  useLayoutEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const rect = control.modalRef.current?.getBoundingClientRect()
      if (!rect) return

      const isInDialog =
        rect.top <= event.clientY &&
        event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX &&
        event.clientX <= rect.left + rect.width

      if (!isInDialog) onBackdropClick(event)
    }

    control.modalRef.current?.addEventListener('click', handleClick)

    return () => {
      control.modalRef.current?.removeEventListener('click', handleClick)
    }
  }, [control.modalRef, onBackdropClick])

  const dialogClasses = cx(
    'bg-neutral-0 p-0 shadow-4 overflow-auto overflow-visible',
    transparentBackdrop
      ? 'backdrop:bg-neutral-900/0'
      : 'backdrop:bg-neutral-900/70',
    variant === ModalVariants.Small &&
      'h-[400px] max-md:w-[calc(100%-64px)] max-w-[754px] w-full',
    variant === ModalVariants.Default &&
      'max-h-[90vh] h-fit max-md:max-h-[100vh] max-md:max-w-[100vw] max-md:w-[calc(100%-64px)] max-sm:max-h-[100vh] max-sm:max-w-[100vw] max-sm:w-full w-[754px]',
    variant === ModalVariants.Large &&
      'h-[calc(100%-112px)] max-md:h-full max-md:max-h-[100vh] max-md:max-w-[100vw] max-md:w-full w-[calc(100%-112px)]'
  )

  return (
    <dialog className={dialogClasses} ref={control.modalRef} {...otherProps}>
      {children}
    </dialog>
  )
}
