import { useParams } from 'react-router-dom'

import { isData } from '@cais-group/caisiq/domain/common'
import { useGetWhitepaper } from '@cais-group/caisiq/domain/whitepaper'

import { WhitepaperPage } from './caisiq-feature-whitepaper-page'

type WhitepaperParams = {
  slug: string
}

function WhitepaperPageContainer() {
  const { slug } = useParams<WhitepaperParams>()
  const whitepaper = useGetWhitepaper(slug)

  if (!isData(whitepaper)) {
    return null
  }

  return <WhitepaperPage whitepaper={whitepaper} slug={slug} />
}

WhitepaperPageContainer.displayName = 'WhitepaperPageContainer'

export { WhitepaperPageContainer }
