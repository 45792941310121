import * as R from 'remeda'

import type { UniformAny, UniformFieldProps, UniformSchema } from '../types'

export function extractSchemaFieldIds(
  schema?:
    | UniformSchema<UniformAny, UniformAny, UniformAny>
    | UniformSchema<UniformAny, UniformAny, UniformAny>[]
): string[] {
  if (!schema) return []
  const schemas: UniformSchema[] = Array.isArray(schema) ? schema : [schema]

  return R.pipe(
    schemas,
    R.reduce((acc, step) => {
      const stepFieldIds = R.pipe(
        step?.children ?? [],
        R.flatMap((section) => section.children),
        R.flatMap((field) => {
          if (field['type'] === 'group') {
            const items = field['items'] as UniformFieldProps[]
            return items.map((field) => field.id)
          }
          return field['id'] ?? ''
        })
      )
      return acc.concat(stepFieldIds)
    }, [] as string[]),
    R.filter((x): x is string => Boolean(x))
  )
}
