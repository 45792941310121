import styled from '@emotion/styled'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'

import { BodySmall } from '@cais-group/approved/ui/typography'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'

const StyledInfoIcon = styled(InfoIcon)`
  fill: ${colors['eq-color-neutral-300']};
  width: ${SPACING.s12};
  height: ${SPACING.s12};
  order: 4;
`
export const FormLabel = ({
  label,
  helperText,
}: {
  label: string
  helperText?: string
}) => (
  <>
    <BodySmall>{label}</BodySmall>
    {helperText && (
      <Tooltip title={helperText} placement="top">
        <StyledInfoIcon />
      </Tooltip>
    )}
  </>
)
