import { AxiosError, AxiosResponse } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'

import {
  ApiPaths,
  CourseMetadata,
} from '@cais-group/shared/util/type/caisiq-be'

export function useGetManageCaisiqCoursesByCatalogId(
  options:
    | Omit<
        UseQueryOptions<
          AxiosResponse<CourseMetadata[]>,
          AxiosError,
          CourseMetadata[],
          string
        >,
        'queryKey'
      > & {
        catalogId?: string
        enabled: boolean
      }
) {
  return useQuery<CourseMetadata[]>(
    [ApiPaths.getAllBaseCourseConfigs, { catalogId: options.catalogId }],
    {
      enabled: options.enabled,
    }
  )
}
