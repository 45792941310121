import { faker } from '@faker-js/faker/locale/en'
import { Sync } from 'factory.ts'

import {
  ContactReferenceV1,
  ContactResponseV1,
  ContactResponseV1Address,
  InterestedPartyResponseV1,
} from '../../generated/api.schemas'

export const contactAddressFactory = Sync.makeFactory<ContactResponseV1Address>(
  () => ({
    addressLine1: faker.location.streetAddress(),
    city: faker.location.city(),
    country: 'US',
    state: faker.location.state({ abbreviated: true }),
    zipCode: faker.location.zipCode('#####'),
  })
)

export const contactResponseFactory = Sync.makeFactoryWithRequired<
  ContactResponseV1,
  'householdId' | 'id'
>(() => {
  const createdAt = faker.date.past().toISOString()
  return {
    version: 1,
    firstName: faker.person.firstName(),
    lastName: faker.person.lastName(),
    address: contactAddressFactory.build(),
    isLatest: true,
    createdAt,
    updatedAt: createdAt,
  }
})

export const contactReferenceFactory = Sync.makeFactory<
  Required<ContactReferenceV1>
>(() => {
  const userId = faker.string.uuid()

  return {
    id: userId,
    version: 1,
    contactDetails: contactResponseFactory.build({
      id: userId,
      householdId: faker.string.uuid(),
      email: faker.internet.email(),
    }),
  }
})

export const interestedPartyFactory = Sync.makeFactoryWithRequired<
  InterestedPartyResponseV1,
  'account' | 'accountRoles'
>(() => {
  return {
    contact: contactReferenceFactory.build(),
    emailNotifications: false,
  }
})
