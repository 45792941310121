import { useRecoilValue } from 'recoil'

import { isData } from '@cais-group/caisiq/domain/common'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { TypeVideoPlaylistData } from '@cais-group/shared/util/type/video-playlist-data'

import { INDIVIDUAL_VIDEO_PROJECT } from './config'
import {
  useGetVideoPlaylists,
  VideoPlaylistsApiState,
} from './useGetVideoPlaylists'

export function useFilteredVideos() {
  const searchTerm = useRecoilValue(searchTermAtom)
  const videos = useGetVideoPlaylists()
  const filteredVideos = filterVideosApiState(videos, searchTerm)

  return filteredVideos
}

export function filterVideosApiState(
  videosApiState: VideoPlaylistsApiState,
  searchTerm: string
) {
  if (!isData(videosApiState)) return undefined

  const searchTermLower = searchTerm.toLowerCase()

  const individualVideosPlaylist = videosApiState?.find(
    (playlist: TypeVideoPlaylistData) =>
      playlist.sys.id === INDIVIDUAL_VIDEO_PROJECT
  )

  const foundInPlaylistTopLevel = videosApiState?.filter(
    (playlist: TypeVideoPlaylistData) => {
      return (
        playlist.sys.id !== INDIVIDUAL_VIDEO_PROJECT &&
        (playlist.title?.toLowerCase().includes(searchTermLower) ||
          playlist.summary?.toLowerCase().includes(searchTermLower))
      )
    }
  )

  const notFoundInPlaylistTopLevel = videosApiState?.filter(
    (playlist: TypeVideoPlaylistData) => {
      return (
        playlist.sys.id !== INDIVIDUAL_VIDEO_PROJECT &&
        !(
          playlist.title?.toLowerCase().includes(searchTermLower) ||
          playlist.summary?.toLowerCase().includes(searchTermLower)
        )
      )
    }
  )

  const filteredByVideo = notFoundInPlaylistTopLevel.reduce<
    Array<TypeVideoPlaylistData>
  >((accProjects, currPlaylist) => {
    const filteredVideos = currPlaylist.videosCollection.items.filter(
      (videos) => {
        return (
          videos.title?.toLowerCase().includes(searchTermLower) ||
          videos.summary?.toLowerCase().includes(searchTermLower)
        )
      }
    )
    if (filteredVideos.length === 0) return accProjects
    return [
      ...accProjects,
      {
        ...currPlaylist,
        videosCollection: {
          items: filteredVideos,
        },
      },
    ]
  }, [])

  const filteredIndividualVideos =
    individualVideosPlaylist?.videosCollection.items.filter((video) => {
      return (
        video.title?.toLowerCase().includes(searchTermLower) ||
        video.summary?.toLowerCase().includes(searchTermLower)
      )
    })

  const filteredIndividualVideosPlaylist: TypeVideoPlaylistData | undefined =
    individualVideosPlaylist
      ? {
          ...individualVideosPlaylist,
          videosCollection: {
            items: filteredIndividualVideos ? filteredIndividualVideos : [],
          },
        }
      : undefined

  return searchTerm === ''
    ? videosApiState
    : [
        ...foundInPlaylistTopLevel,
        ...filteredByVideo,
        ...(filteredIndividualVideosPlaylist &&
        filteredIndividualVideosPlaylist.videosCollection.items.length > 0
          ? [filteredIndividualVideosPlaylist]
          : []),
      ]
}
