import classNames from 'classnames'
import * as React from 'react'

export const Container = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={className}>
      <div
        data-testid="ip-content-container"
        className="flex w-full px-24 md:px-32 lg:px-56"
      >
        {children}
      </div>
    </div>
  )
}

export const MainWrapper = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <Container>
      <main tabIndex={-1} className={classNames('flex w-full', className)}>
        {children}
      </main>
    </Container>
  )
}

export const Main = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <Container>
      <main
        tabIndex={-1}
        className={classNames(
          'flex w-full flex-col gap-16 md:flex-row',
          className
        )}
      >
        {children}
      </main>
    </Container>
  )
}

export const Aside = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <aside
      className={classNames(
        'lg:flex lg:min-w-[191px] lg:max-w-[191px] xl:min-w-[287px] xl:max-w-[287px]',
        className
      )}
    >
      <div className="h-full w-full">{children}</div>
    </aside>
  )
}

/**
 * This is used for fields which are not editable
 * @param title The heading of the field
 * @param children The children of the field, usually a string
 * @returns A field with a title and children
 */
export function ReadOnlyField({
  children,
  title,
}: React.PropsWithChildren<{ title: string }>) {
  return (
    <div className="flex flex-col">
      <h3 className="body-strong">{title}</h3>
      {children}
    </div>
  )
}

export function Section({
  title,
  children,
  border = true,
}: React.PropsWithChildren<{ title: string; border?: boolean }>) {
  return (
    <section
      className={classNames(
        'flex flex-col gap-24 pb-24',
        border && 'border-0 border-b border-solid border-b-neutral-200'
      )}
    >
      <h2 className="headline-s-strong">{title}</h2>
      {children}
    </section>
  )
}

export const MainAside = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <>
      <Aside className="flex" />
      <div
        className={classNames(
          'col-span-12 w-full lg:col-span-7 lg:col-start-3 lg:px-32',
          className
        )}
      >
        <div className="flex w-full flex-col gap-32 pt-8 sm:pt-16 md:pt-24 lg:pt-56">
          {children}
        </div>
      </div>
      <Aside />
    </>
  )
}
