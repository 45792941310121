import { Skeleton } from '@mui/material'
import classNames from 'classnames'
import { useFormState } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from '@cais-group/equity/atoms/button'
import type { PageHeaderProps } from '@cais-group/equity/molecules/page-header'
import { PageHeader } from '@cais-group/equity/molecules/page-header'

import { createUniformDictionary } from '../adapters'
import {
  ClientErrorMessages,
  ServerErrorMessage,
} from '../components/error-message'
import { FieldSurround } from '../components/field-surround'
import { UniformSection } from '../components/uniform-section'
import { useUniformContext } from '../context'
import { MainAside, MainWrapper } from '../equity/layout'
import { UniformServices } from '../services'
import type { UniformAny, UniformSchema } from '../types'
import { UniformBasic, useUniform } from '../uniform'

import { UniformFormHandler } from './form'

export function UniformBasicLayout({
  onCancel,
  submitText,
  submitDisabled,
  className,
}: {
  submitText?: string
  submitDisabled?: boolean
  onCancel?: () => void
  className?: string
}) {
  const { isSubmitting } = useFormState()
  const { schema } = useUniformContext()
  const schemas = Array.isArray(schema) ? schema[0] : schema

  return (
    <UniformFormHandler className={className}>
      <div className="flex flex-col gap-32">
        <ServerErrorMessage />
        <ClientErrorMessages />
        <div
          className={classNames(
            'flex w-full flex-col gap-56 text-left',
            '[&>*]:border-b-1 [&>*]:border-solid [&>*]:border-neutral-200',
            '[&>*]:pb-56 [&>:last-of-type]:border-none'
          )}
        >
          {schemas?.children.map((section, index) => (
            <UniformSection key={index} {...section} />
          ))}
        </div>
        <footer className="flex min-h-[7rem] gap-16">
          {typeof onCancel === 'function' && (
            <Button
              variant="secondary"
              onClick={onCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          )}
          {submitText && (
            <Button
              type="submit"
              disabled={isSubmitting || submitDisabled}
              loading={isSubmitting}
            >
              {submitText}
            </Button>
          )}
        </footer>
      </div>
    </UniformFormHandler>
  )
}

export function BareSingleStepLoading<TUniformSchema extends UniformSchema>({
  schema,
  services = {},
}: {
  schema: TUniformSchema
  services?: UniformServices
}) {
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uniform = useUniform<any, any, any>({
    componentDictionary: createUniformDictionary()({
      skeleton: (_: { type: 'skeleton' }) => (
        <FieldSurround className="flex flex-col gap-8">
          <Skeleton variant="rectangular" width="45%" height={24} />
          <Skeleton variant="rectangular" width="100%" height={48} />
        </FieldSurround>
      ),
    }),
    services,
    settings: { defaultFieldCols: 8 },
    schema: {
      ...schema,
      children: [
        ...schema.children.map(({ title, ...section }) => ({
          ...section,
          title: typeof title === 'string' ? title : '', // exclude dynamic titles
          children: section.children
            // NOTE: map all items to skeleton (could potentially have unique skeletons for every/some item(s)?
            .map(({ id }) => ({
              id,
              type: 'skeleton',
            }))
            // NOTE: show max 3 items in loading section
            .slice(0, 3),
        })),
      ],
    },
    onSubmit: () => Promise.resolve({}),
  })

  return (
    <UniformBasic
      {...uniform}
      onCancel={() => navigate(-1)}
      submitDisabled
      submitText="Save Changes"
      className="flex flex-1"
    />
  )
}

export function SingleStepLoading({
  title,
  breadcrumbs,
  schema,
}: {
  title: string
  breadcrumbs?: PageHeaderProps['breadcrumbs']
  schema: UniformSchema<UniformAny, UniformAny, UniformAny>
}) {
  const navigate = useNavigate()

  return (
    <>
      <PageHeader
        applyNewLayout
        title={title}
        breadcrumbs={breadcrumbs}
        actions={[
          {
            type: 'button',
            variant: 'secondary',
            children: 'Cancel',
            onClick: () => navigate(-1),
          },
          {
            type: 'button',
            children: 'Save Changes',
          },
        ]}
      />
      <MainWrapper>
        <MainAside>
          <BareSingleStepLoading schema={schema} />
        </MainAside>
      </MainWrapper>
    </>
  )
}
