import { Skeleton } from '@mui/material'
import classNames from 'classnames'
import * as React from 'react'
import { useFormState } from 'react-hook-form'

import { useDynamicTitle, useRandomId } from '../hooks'
import { useUniformFieldRules, useUniformRulesValidate } from '../rules'
import type { UniformServices } from '../services'
import type { UniformSectionChildren, UniformSectionProps } from '../types'

import { FieldMatcher } from './field-matcher'
import { Tooltip } from './tooltip'
import { useShowSection } from './use-show-section'

export const ComponentRenderer = <
  TUniformServices extends UniformServices = UniformServices
>(
  props: UniformSectionChildren<TUniformServices>
) => {
  const [repeatedFields, setRepeatedFields] = React.useState([0])

  if (!props['repeatable']) return <FieldMatcher {...props} />

  return (
    <React.Fragment>
      {repeatedFields.map((_, index) => {
        const prefix = `${
          props['parentPrefix'] ? `${props['parentPrefix']}.` : ''
        }${props?.['repeatable']?.prefix}.${index}`
        return (
          <React.Fragment key={prefix}>
            <FieldMatcher {...{ ...props, parentPrefix: prefix }} />
            {props['repeatable'] && index === repeatedFields.length - 1 && (
              <button
                className="bg-neutral-200"
                type="button"
                onClick={() => setRepeatedFields((f) => [...f, f.length + 1])}
              >
                {props['repeatable'].label}
              </button>
            )}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

const Grid = <TUniformServices extends UniformServices = UniformServices>({
  id,
  title,
  tooltip,
  description,
  children = [],
  parentPrefix,
  isDisabled,
  isValid,
  validationMessage,
  shouldShowSectionIfItHasChildren,
}: UniformSectionProps<TUniformServices> & {
  parentPrefix?: string
  isDisabled?: boolean
  isValid?: boolean
  validationMessage?: string
  shouldShowSectionIfItHasChildren?: boolean
}) => {
  const { title: dynamicTitle, loading } = useDynamicTitle(title)
  const { isSubmitting } = useFormState()

  return (
    <fieldset
      className={classNames(
        // NOTE: we have to keep section in the DOM, otherwise it will unmount the components and there will be no further rule updates.
        shouldShowSectionIfItHasChildren ? 'm-0' : 'hidden'
      )}
      disabled={isDisabled || isSubmitting}
      data-testid={`section__${id}`}
    >
      <div className="grid grid-cols-12 gap-32">
        {title || description ? (
          <legend className="col-start-1 col-end-13 flex flex-col gap-4">
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height={32} />
            ) : (
              <div className="flex items-center">
                <h2 id={id} className="headline-s-strong leading-none">
                  {dynamicTitle}
                </h2>
                {tooltip && <Tooltip tooltip={tooltip} />}
              </div>
            )}
            {description && <p className="text-neutral-600">{description}</p>}
            {typeof isValid === 'boolean' && isValid && (
              <p>{validationMessage}</p>
            )}
          </legend>
        ) : null}
        {children.map((props, index) => (
          <ComponentRenderer
            key={index}
            parentPrefix={parentPrefix}
            {...props}
          />
        ))}
      </div>
    </fieldset>
  )
}

export const UniformSection = <
  TUniformServices extends UniformServices = UniformServices
>({
  repeatable,
  rules,
  ...props
}: UniformSectionProps<TUniformServices>) => {
  const sectionId = useRandomId(
    typeof props.title === 'string' ? props.title : ''
  )
  const [repeatedFields, setRepeatedFields] = React.useState([0])
  const uniform = useUniformFieldRules({
    id: props.id ?? sectionId,
    rules,
    children: props.children,
    disabled: props.disabled,
  })
  const { isValid, validationMessage } = useUniformRulesValidate(uniform)
  const shouldShowSectionIfItHasChildren = useShowSection({
    fieldIds: props.children.map((field) => field['id']),
  })

  if (!uniform.isVisible) return null
  if (!repeatable) {
    return (
      <Grid
        id={sectionId}
        shouldShowSectionIfItHasChildren={shouldShowSectionIfItHasChildren}
        isDisabled={uniform.isDisabled}
        isValid={isValid}
        validationMessage={validationMessage}
        {...props}
      />
    )
  }

  return (
    <React.Fragment key={props.id ?? sectionId}>
      {repeatedFields.map((_, index) => (
        <React.Fragment key={`${repeatable.prefix}.${index}`}>
          <Grid
            {...props}
            parentPrefix={`${repeatable.prefix}.${index}`}
            isDisabled={uniform.isDisabled}
          />
          {index === repeatedFields.length - 1 && (
            <button
              className="bg-neutral-200"
              type="button"
              onClick={() => setRepeatedFields((f) => [...f, f.length + 1])}
            >
              {repeatable.label}
            </button>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}
