import cx from 'classnames'

import * as styles from './styles'
import type {
  BaseButtonProps,
  ButtonColor,
  ButtonState,
  ColorCombination,
} from './types'

const defineColorCombination = (
  color: ButtonColor,
  inverse: boolean
): ColorCombination => `${color}-${inverse ? 'inverse' : 'regular'}`

const getButtonState = ({
  loading,
  disabled,
  variant,
}: Required<
  Pick<BaseButtonProps, 'loading' | 'disabled' | 'variant'>
>): ButtonState => {
  if (loading && variant !== 'tertiary') return 'loading'
  if (disabled) return 'disabled'
  return 'default'
}

type ButtonStyles = Required<
  Pick<
    BaseButtonProps,
    'color' | 'inverse' | 'disabled' | 'grow' | 'loading' | 'size' | 'variant'
  >
>

export const buttonStyles = (props: ButtonStyles) => {
  const { color, inverse, disabled, grow, loading, size, variant } = props
  const colorCombination = defineColorCombination(color, inverse)
  const state = getButtonState({ disabled, loading, variant })
  const className = cx(
    'flex shrink-0 grow items-center justify-center gap-x-8 overflow-hidden text-ellipsis whitespace-nowrap transition focus-visible:outline focus-visible:outline-offset-2',
    { 'max-w-fit': !grow },
    { relative: loading },
    styles.getBackgroundColor(variant, colorCombination),
    styles.getTextColor(variant, colorCombination, state),
    styles.getBorderColor(variant, colorCombination),
    styles.getBorderSize(variant),
    styles.getCursor(state),
    styles.getFontSize(size),
    styles.getHeight(size),
    styles.getPadding(size, variant),
    styles.getOutlineColor(colorCombination)
  )
  return { state, className }
}
