import {
  parseFromCollection,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  GetWhitepaperCollectionQuery,
  useGetWhitepaperCollectionQuery,
  WhitepaperFilter,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

import { FIRMS_COLLECTION_LIMIT } from '../constants'

export const useGetWhitepapers = (where?: WhitepaperFilter) => {
  return useReactQueryResultAsApiState<
    GetWhitepaperCollectionQuery,
    WhitepaperData[]
  >(
    useGetWhitepaperCollectionQuery(
      {
        preview: previewService.enabled,
        where,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      { refetchOnWindowFocus: false }
    ),
    (data) => parseFromCollection(data, 'whitepaperCollection'),
    'Could not load articlesCollectionQuery'
  )
}
