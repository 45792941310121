import styled from '@emotion/styled'
import { createContext, ReactNode, useContext } from 'react'

import { LoadingIndicator } from '@cais-group/equity/atoms/loading-indicator'
import { SPACING_UNITS } from '@cais-group/shared/ui/design-tokens'
import { Cover, LayoutStack } from '@cais-group/shared/ui/layout'

const NAV_HEIGHT = SPACING_UNITS.s56 + SPACING_UNITS.s24

const Wrapper = styled.div<{
  $bg: 'rgb(var(--colors-neutral-900))' | 'rgb(var(--colors-neutral-0))'
  $coverPage: 'FULL_SCREEN' | 'FULL_SCREEN_WITH_HEADER' | false
}>`
  ${(props) =>
    props.$coverPage === 'FULL_SCREEN_WITH_HEADER' &&
    `
    height: calc(100vh - var(--s56) - var(--s24));
      `}
  & > div {
    background-color: ${(props) => `${props.$bg}`};
    ${(props) =>
      props.$coverPage === 'FULL_SCREEN_WITH_HEADER' &&
      `
      justify-content: center;
      height: 100%;
      transform: translateY(${-NAV_HEIGHT / 2}px);
    `}
  }
`

export type LoadingContainerProps = {
  state: LoadingState
  children?: ReactNode
  type?: 'large' | 'small'
  darkMode?: boolean
  coverPage?: 'FULL_SCREEN' | 'FULL_SCREEN_WITH_HEADER' | false
  className?: string
  label?: string
}

export enum LoadingState {
  INIT = 'init',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

const LoadingContainerContext = createContext<LoadingState>(LoadingState.INIT)

export const LoadingContainer = ({
  children,
  state,
  darkMode = false,
  type = 'large',
  coverPage = false,
  className,
  label = 'Spinner',
}: LoadingContainerProps) => {
  const spinner = (
    <div className="p-88 flex items-center justify-center">
      <LoadingIndicator
        data-cover-id="center"
        size={type === 'large' ? 'large' : 'medium'}
        color={darkMode ? 'inverse' : 'primary'}
        label={label}
      />
    </div>
  )

  return (
    <LoadingContainerContext.Provider value={state}>
      {state === LoadingState.LOADING ? (
        <Wrapper
          className={className}
          $bg={
            darkMode
              ? 'rgb(var(--colors-neutral-900))'
              : 'rgb(var(--colors-neutral-0))'
          }
          $coverPage={coverPage}
        >
          {coverPage === 'FULL_SCREEN' ? (
            <Cover
              className="flex items-center justify-center"
              centered="[data-cover-id=center]"
            >
              {spinner}
            </Cover>
          ) : (
            <LayoutStack className="flex items-center justify-center">
              {spinner}
            </LayoutStack>
          )}
        </Wrapper>
      ) : (
        children
      )}
    </LoadingContainerContext.Provider>
  )
}
LoadingContainer.displayName = 'LoadingContainer'

const LoadingError = (props: { children: JSX.Element | null }) => {
  const loadingState = useContext(LoadingContainerContext)

  if (loadingState === LoadingState.ERROR) {
    return props.children
  }

  return null
}

const LoadingContent = (props: { children: JSX.Element | null }) => {
  const loadingState = useContext(LoadingContainerContext)

  if (loadingState !== LoadingState.SUCCESS) {
    return null
  }

  return props.children
}

LoadingContainer.Error = LoadingError
LoadingContainer.Content = LoadingContent
