import { Link } from 'react-router-dom'

import { usePatchEnrollCourse } from '@cais-group/caisiq/domain/library-course'
import { TrackingId } from '@cais-group/caisiq/domain/segment'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Button } from '@cais-group/equity/atoms/button'
import { Course } from '@cais-group/shared/util/type/caisiq-be'
import { isLibraryCourse } from '@cais-group/shared/util/type/library-course'
import { Segment } from '@cais-group/shared/util/type/segment'

export type CourseCallToActionButtonProps = {
  course: Course
  size: 'large' | 'medium' | 'small'
  segment?: Segment<TrackingId>
  buttonWidth?: number
}
const removeDeeplink = () => {
  const deeplink = sessionStorage.getItem('auth0:deeplink')
  if (deeplink) {
    sessionStorage.removeItem('auth0:deeplink')
  }
}

const handleOnClick = (course: Course, buttonText: string) => {
  removeDeeplink()
  trackingService.courseCardClicked(course, buttonText)
}

export function CourseCallToActionButton(props: CourseCallToActionButtonProps) {
  const { course, size } = props
  const buttonSize = size === 'large' ? 'regular' : size
  const isSwitchedUser = userSettingsService.user.switchedUser
  const { executeCourseEnrollPatch, courseEnrollPatchResult } =
    usePatchEnrollCourse()

  /* Only show the option to enroll if the user is not yet enrolled */
  if (isLibraryCourse(course) && !course.enrolled) {
    return (
      <Button
        size={buttonSize}
        disabled={isSwitchedUser}
        loading={courseEnrollPatchResult.isLoading}
        onClick={(event) => {
          event.stopPropagation()
          executeCourseEnrollPatch({
            id: course.id,
            enroll: true,
          })
          trackingService.courseCardClicked(course, 'Enroll')
        }}
        variant="secondary"
      >
        Enroll
      </Button>
    )
  }

  switch (course.type) {
    case 'Unstarted': {
      const buttonText = 'Start learning'
      return (
        <Link
          to={ROUTES.assignment(course.id)}
          onClick={() => handleOnClick(course, buttonText)}
        >
          <Button
            size={buttonSize}
            disabled={isSwitchedUser}
            variant="secondary"
          >
            {buttonText}
          </Button>
        </Link>
      )
    }

    case 'Learning': {
      const buttonText = 'Continue learning'
      return (
        <Link
          to={ROUTES.assignment(course.id)}
          onClick={() => handleOnClick(course, buttonText)}
        >
          <Button
            color="success"
            size={buttonSize}
            disabled={isSwitchedUser}
            variant="primary"
          >
            {buttonText}
          </Button>
        </Link>
      )
    }
    /**
     * @todo the BE will eventually map this to Learning and we can get rid of this case.
     */
    case 'AssessmentUnlocked': {
      const buttonText = 'Continue'
      return (
        <Link
          to={ROUTES.assignment(course.id)}
          onClick={() => handleOnClick(course, buttonText)}
        >
          <Button size={buttonSize} disabled={isSwitchedUser} variant="primary">
            {buttonText}
          </Button>
        </Link>
      )
    }
    case 'LearningOrAssessing': {
      const buttonText = 'Continue'
      return (
        <Link
          to={ROUTES.assignment(course.id)}
          onClick={() => handleOnClick(course, buttonText)}
        >
          <Button size={buttonSize} disabled={isSwitchedUser} variant="primary">
            {buttonText}
          </Button>
        </Link>
      )
    }
    case 'Retry': {
      const buttonText = 'Retry assessment'
      return (
        <Link
          to={ROUTES.assignment(course.id)}
          onClick={() => handleOnClick(course, buttonText)}
        >
          <Button
            color="error"
            size={buttonSize}
            disabled={isSwitchedUser}
            variant="secondary"
          >
            {buttonText}
          </Button>
        </Link>
      )
    }
    case 'Passed': {
      const buttonText = 'Review'
      return (
        <Link
          to={ROUTES.assignment(course.id)}
          onClick={() => handleOnClick(course, buttonText)}
        >
          <Button
            color="success"
            size={buttonSize}
            disabled={isSwitchedUser}
            variant="secondary"
          >
            {buttonText}
          </Button>
        </Link>
      )
    }
    case 'LearningComplete': {
      const buttonText = 'Review'
      return (
        <Link
          to={ROUTES.assignment(course.id)}
          onClick={() => handleOnClick(course, buttonText)}
        >
          <Button
            disabled={isSwitchedUser}
            color="success"
            size={buttonSize}
            variant="secondary"
          >
            {buttonText}
          </Button>
        </Link>
      )
    }

    default:
      console.error(`Unknown Course State: ${course}`)
      return null
  }
}
