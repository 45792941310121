import { ChangeEvent } from 'react'
import { FieldErrors } from 'react-hook-form'
import * as yup from 'yup'

import { ModalFields } from '../caisiq-ui-manage-update-course-modal'

// Ensure that numbers are stored in formState instead of strings
export const transform = {
  output: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const num = parseFloat(e.target.value)
    const output = Math.trunc(num * 10) / 10

    return e.target.value === '' ? '' : output
  },
  input: (value?: number | string) => {
    if (value === 0) {
      return value
    }
    return value ? (value === '' ? '' : value.toString()) : ''
  },
}

export const getErrorMessage = (
  key: keyof ModalFields,
  errors: FieldErrors<ModalFields>
) => {
  const error = errors[key]
  return error?.message
}

const checkForCorrectIncrement = (value: number, increment: number) =>
  value % increment === 0

export const createCourseSchema = yup.object().shape({
  requiredScore: yup
    .number()
    .required()
    .typeError(`Please provide a required score`)
    .max(100, 'Please enter a value between 0 - 100')
    .min(0, 'Please enter a value between 0 - 100'),
  cfp: yup
    .string()
    .nullable()
    .test('maxMin', 'CFP credits must be between 0 and 1.5', (value) =>
      value ? Number(value) <= 1.5 && Number(value) >= 0 : true
    )
    .test('incrementOf.5', 'CFP credits must be in increments of .5', (value) =>
      checkForCorrectIncrement(Number(value), 0.5)
    ),
  cima: yup
    .string()
    .nullable()
    .test('maxMin', 'CIMA credits must be between 0 and 1.5', (value) =>
      value ? Number(value) <= 1.5 && Number(value) >= 0 : true
    )
    .test('incrementOf.5', 'CFP credits must be in increments of .5', (value) =>
      checkForCorrectIncrement(Number(value), 0.5)
    ),
})
