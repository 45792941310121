/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContentPermissionsData } from '@cais-group/shared/domain/contentful/api'

type FPIDs = {
  fundProductIds?:
    | {
        fundProductIds: string[]
      }
    | any
}

type FIRMIDs = {
  firmsCollection?:
    | {
        items: Array<{ id: string }>
      }
    | any
}

type PermissionType = 'fundProductIds' | 'firmIds'
const isAccessAllowed =
  (key: PermissionType) => (xs: string[], col: ContentPermissionsData) => {
    return xs.length > 0
      ? xs.some((x: string) => col?.[key as PermissionType]?.includes(x))
      : true
  }

const isFirmAccessAllowed = isAccessAllowed('firmIds')
const isFundAccessAllowed = isAccessAllowed('fundProductIds')

export function filterAllowedPermissionData<TData extends FPIDs & FIRMIDs>(
  items: TData[],
  allowedPermissionsData: ContentPermissionsData
) {
  return items.filter((item) => {
    const itemsFundIds = item?.fundProductIds?.fundProductIds || []
    const itemsFirmIds =
      item?.firmsCollection?.items?.map((item: { id: string }) => item.id) || []
    return (
      isFundAccessAllowed(itemsFundIds, allowedPermissionsData) &&
      isFirmAccessAllowed(itemsFirmIds, allowedPermissionsData)
    )
  })
}
