import type { TooltipProps } from '@mui/material'
import { Tooltip } from '@mui/material'
import { useState } from 'react'

import { Icon, IconProps, iconSizes } from '@cais-group/equity/atoms/icon'
import { AllColor } from '@cais-group/equity/particles/colors'

export interface CopyIconProps {
  text: string
  color?: AllColor
  className?: string
  portal?: boolean
  placement?: TooltipProps['placement']
  size?: IconProps['size']
  onCopy?: () => void
}

type CopyOrCopied = 'Copy' | 'Copied'

export function CopyIcon({
  text,
  color = 'eq-color-neutral-300',
  placement = 'top',
  portal = false,
  size = 'regular',
  onCopy,
}: CopyIconProps) {
  const [openToolTip, setOpenTooltip] = useState(false)
  const [message, setMessage] = useState<CopyOrCopied>('Copy')

  const iconSize = iconSizes[size]

  const handleTooltipClose = () => {
    setOpenTooltip(false)
    setMessage('Copy')
  }

  const handleTooltipOpen = () => {
    setOpenTooltip(true)
  }

  function copyIdToClipboard(evt: React.MouseEvent<HTMLButtonElement>) {
    evt.preventDefault()
    evt.stopPropagation()
    navigator.clipboard.writeText(text)
    setMessage('Copied')
    onCopy?.()
  }

  return (
    <Tooltip
      title={message}
      PopperProps={{
        disablePortal: !portal,
      }}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      open={openToolTip}
      disableFocusListener
      placement={placement}
    >
      <button
        onClick={copyIdToClipboard}
        style={{ height: iconSize, width: iconSize }}
      >
        <Icon type="Copy" size={size} color={color} />
      </button>
    </Tooltip>
  )
}

export default CopyIcon
