import styled from '@emotion/styled'

import { H4Strong } from '@cais-group/approved/ui/typography'
import { Avatar } from '@cais-group/equity/atoms/avatar'
import { colors } from '@cais-group/equity/particles/colors'
import { BREAKPOINTS, SPACING } from '@cais-group/shared/ui/design-tokens'

interface UserProfileHeaderProps {
  fullName?: string
  email?: string
  firmName?: string
  className?: string
}

export const UserProfileHeader: React.FC<UserProfileHeaderProps> = ({
  fullName,
  email,
  firmName,
  className,
}) => {
  const [firstName, ...rest] = fullName?.split(' ') || []
  const lastName = rest.join(' ')

  return (
    <HeaderContainer className={className}>
      <Avatar size="large">{`${firstName} ${lastName}`}</Avatar>
      <HeaderContent>
        <H4Strong>{fullName}</H4Strong>
        <UserDetailsList aria-label="User Information">
          <li>{firmName}</li>
          <li>{email}</li>
        </UserDetailsList>
      </HeaderContent>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  padding: ${SPACING.s24};
  align-items: center;
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${SPACING.s24};
`

const UserDetailsList = styled.ul`
  display: flex;
  color: ${colors['eq-color-neutral-600']};
  margin: 0;
  padding: 0;
  flex-direction: column;
  list-style: none;

  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    list-style-position: outside;
    list-style-type: disc;
    flex-direction: row;

    li {
      padding-right: ${SPACING.s8};
      margin-left: ${SPACING.s16};
    }

    li:first-of-type {
      margin: 0;
      list-style: none;
    }
  }
`
