import { useState } from 'react'

import {
  useGetUserLicences,
  usePutUserLicences,
} from '@cais-group/caisiq/domain/user'
import { LicenceForm } from '@cais-group/caisiq/ui/licence-form'
import { showToast } from '@cais-group/equity/organisms/notifications'

type LicenceFormContainerProps = {
  cimaNumber: string
  cfpNumber: string
  title?: string
  description?: string
}
export const LicenceFormContainer = ({
  title,
  description,
  cimaNumber,
  cfpNumber,
}: LicenceFormContainerProps) => {
  const [error, setError] = useState('')
  const saveLicenceForm = usePutUserLicences()

  const { refetch } = useGetUserLicences()

  return (
    <LicenceForm
      title={title}
      description={description}
      fields={{
        cimaNumber: cimaNumber,
        cfpNumber: cfpNumber,
      }}
      formError={error}
      onSubmit={({ cfpNumber, cimaNumber }) =>
        saveLicenceForm.mutateAsync(
          {
            cfp: {
              number: cfpNumber,
            },
            cima: {
              number: cimaNumber,
            },
          },
          {
            onSuccess: () => {
              setError('')
              refetch()
              showToast({
                type: 'success',
                title: 'Your credential numbers have been successfully saved',
              })
            },
            onError: () => {
              setError(
                'There was an error saving one or more credential numbers'
              )
            },
          }
        )
      }
    />
  )
}
