import { useApiErrorHandler } from '@cais-group/caisiq/util/hook/use-api-error-handler'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Course } from '@cais-group/shared/util/type/caisiq-be'

import { useGetUserCeCredits } from '..'

export enum CourseCeStatus {
  EARNED = 'earned',
  ELIGIBLE = 'eligible',
  MASTERED = 'mastered',
  UNAVAILABLE = 'unavailable',
}
export type CeCreditsType = {
  hasCeCredits?: boolean
  cfpCeCredit?: number | null
  cimaCeCredit?: number | null
  courseCeStatus: CourseCeStatus
  totalCeCreditsAvailable?: number
}

interface GetTotalCeCreditsFuncType {
  isFetched: boolean
  ceCreditsAvailable: (course: Course) => CeCreditsType
}
export const useGetCourseCeCredits = (
  enabled: boolean = true
): GetTotalCeCreditsFuncType => {
  const errorHandlerFactory = useApiErrorHandler()
  const { userCredits, isFetched } = useGetUserCeCredits({
    onError: errorHandlerFactory(),
  })

  const getTotalCeCredits = (course: Course) => {
    if (
      !userSettingsService.hasCapability('ceCredit') ||
      course?.ce === undefined
    ) {
      return undefined
    }
    const { cimaCeCredit, cfpCeCredit } = course?.ce || {}
    return Math.max(cimaCeCredit ?? 0, cfpCeCredit ?? 0) || undefined
  }

  const getCeCreditStatus = (course: Course) => {
    if (!(course?.ce && userSettingsService.hasCapability('ceCredit'))) {
      return CourseCeStatus.UNAVAILABLE
    }

    if (
      userCredits?.completedCourses.some(
        (earned) => earned.courseMetadataUuid === course.metadataUuid
      )
    ) {
      return CourseCeStatus.EARNED
    } else {
      return CourseCeStatus.ELIGIBLE
    }
  }

  return {
    isFetched,
    ceCreditsAvailable: (course: Course) => ({
      hasCeCredits: Boolean(
        Object.keys(course?.ce || {}).length > 0 &&
          userSettingsService.hasCapability('ceCredit')
      ),
      cfpCeCredit: course?.ce?.cfpCeCredit,
      cimaCeCredit: course?.ce?.cimaCeCredit,
      courseCeStatus: getCeCreditStatus(course),
      totalCeCreditsAvailable: getTotalCeCredits(course),
    }),
  }
}
