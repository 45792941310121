import { type Menu } from '@cais-group/shared/util/entitled-nav-links'
export {
  type EnabledAccessControls,
  type Menu,
  type MenuGroup,
  type NavItem,
  type NavItemLink,
  type NavItemMenu,
  type UrlType,
} from '@cais-group/shared/util/entitled-nav-links'

export type Profile = {
  firstName: string
  lastName: string
  companyName: string
  menu: Menu
}

export type EnvType = {
  isCiq: boolean
  isEphemeral: boolean
  isLocalhost: boolean
  isMfe: boolean
}
