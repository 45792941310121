import { ReactNode, createContext, useContext, useMemo } from 'react'
import { usePrevious } from 'react-use'

import { APPS } from '@cais-group/shared/domain/apps'

export interface ILocationTrackingValues {
  app: APPS
  url: string
  search: string
  path: string
  referrer: string
}

export const LocationTrackingValuesContext =
  createContext<ILocationTrackingValues | null>(null)

export function LocationTrackingValuesProvider(props: {
  appName: APPS
  children: ReactNode
}) {
  const { appName, children } = props

  /**
   * We have to manually keep track of the referrer becuase in SPA's, the referrer is not populated as expected.
   * For more context, see: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/single-page-apps/.
   */
  const currentUrl = window.location.href
  const previousUrl = usePrevious(currentUrl) ?? ''

  const locationProps = useMemo<ILocationTrackingValues>(() => {
    return {
      app: appName,
      url: currentUrl,
      path: globalThis.location.pathname,
      referrer: previousUrl,
      search: globalThis.location.search,
    }
  }, [appName, currentUrl, previousUrl])

  return (
    <LocationTrackingValuesContext.Provider value={locationProps}>
      {children}
    </LocationTrackingValuesContext.Provider>
  )
}

/**
 * A hook that groups the common location tracking values along with keeping track of the referrer (previous URL).
 * For more context see: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/single-page-apps/.
 */
export const useLocationTrackingValues = () => {
  const ctx = useContext(LocationTrackingValuesContext)
  if (!ctx) {
    console.warn(
      'useLocationTrackingValues must be used within a LocationTrackingValuesProvider'
    )
    return {
      app: '' as APPS,
      url: globalThis.location.href,
      path: globalThis.location.pathname,
      referrer: globalThis.document.referrer,
      search: globalThis.location.search,
    }
  }
  return ctx
}
