import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import {
  ApiError,
  ApiStateEnum,
  useReactQueryResultAsApiState,
  isData,
  isError,
  transformContentPermissionsData,
  checkContentAccess,
  parseFirstFromCollection,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  GetArticleBySlugQuery,
  useGetArticleBySlugQuery,
  useGetArticlePermissionsQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'

import { FIRMS_COLLECTION_LIMIT } from '../constants'

type Data = TypeArticleData | null

export const useGetArticleBySlug = (slug?: string) => {
  const allowedUserPermissions = useGetAllowedContentPermissions()
  const { fundProductIds, firmIds } = allowedUserPermissions.data
  // TODO use articlePermissions instead of destructuring?
  const { data: articlePermissionData, isError: articlePermissionDataError } =
    useGetArticlePermissionsQuery(
      {
        slug: slug || '',
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      {
        enabled: Boolean(fundProductIds && firmIds),
        select: (data) =>
          transformContentPermissionsData({
            data: data,
            name: 'caisiqArticleCollection',
          }),
        refetchOnWindowFocus: false,
      }
    )

  const hasAccess = checkContentAccess(
    allowedUserPermissions.data,
    articlePermissionData
  )

  const notFound = Boolean(articlePermissionData?.notFound)

  const existsWithNoAccess = !hasAccess && !notFound

  const response = useReactQueryResultAsApiState<
    GetArticleBySlugQuery,
    TypeArticleData
  >(
    useGetArticleBySlugQuery(
      {
        slug: slug || '',
        preview: previewService.enabled,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      {
        enabled:
          hasAccess &&
          !notFound &&
          Boolean(fundProductIds && articlePermissionData && firmIds),
        refetchOnWindowFocus: false,
      }
    ),
    (data) =>
      parseFirstFromCollection(
        data,
        'caisiqArticleCollection'
      ) as TypeArticleData,

    'Could not load articleBySlugQuery'
  )

  // TODO include articlePermissions.isLoading in loading calculation?
  const loading =
    response === ApiStateEnum.LOADING || allowedUserPermissions.isLoading
  const error = isError(response) || articlePermissionDataError

  return compileResults(response, existsWithNoAccess, notFound, loading, error)
}
const compileResults = (
  data: Data | ApiError | ApiStateEnum,
  existsWithNoAccess: boolean,
  notFound: boolean,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
    existsWithNoAccess,
    notFound,
  }
}
