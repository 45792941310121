import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { useUniformContext } from '../context'
import { useUniformFocus, useUniformSubmit } from '../hooks'

export const UniformFormHandler = ({
  autoFocus = false,
  className,
  children,
}: React.PropsWithChildren<{
  autoFocus?: boolean
  className?: string
}>) => {
  const methods = useFormContext()
  const { formId, formRef, onSubmit, onSuccess, onFailure } =
    useUniformContext()
  const handleSubmit = useUniformSubmit({ onSubmit, onSuccess, onFailure })
  useUniformFocus(autoFocus)

  return (
    <form
      id={formId}
      ref={formRef}
      data-testid={formId}
      className={twMerge('flex flex-col gap-32', className)}
      onSubmit={methods.handleSubmit(handleSubmit)}
    >
      {children}
    </form>
  )
}
