/**
 * Converts a string to proper case.
 *
 * @example
 * toProperCase('hello world') // 'Hello World'
 * toProperCase(' how are you ') // 'How Are You'
 * toProperCase('') // ''
 * toProperCase(null) // ''
 * toProperCase(undefined) // ''
 * toProperCase('123') // '123'
 *
 * @param str - The string to be converted.
 * @returns The string in proper case, or an empty string if the input is not a string, is empty, or only contains whitespace.
 */
export function toProperCase(str: string): string {
  if (typeof str !== 'string' || str.trim().length === 0) {
    return ''
  }
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
