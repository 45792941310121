import { useAuth0 } from '@auth0/auth0-react'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import {
  ApiPaths,
  Fund,
  FundWithThumbnail,
} from '@cais-group/shared/util/type/caisiq-be'

import { useGetDefaultFundThumbnailSet } from './useGetDefaultFundThumbnailSet'

export type FundsResult = ReturnType<typeof useGetFunds>

export const useGetFunds = (options?: { pause?: boolean }) => {
  const { isAuthenticated } = useAuth0()

  const result = useQuery<Fund[], AxiosError>(ApiPaths.allFunds, {
    enabled: isAuthenticated && !options?.pause,
  })

  const thumbnails = useGetDefaultFundThumbnailSet({
    paused: result.data ? false : true,
  })

  let fundsWithThumbnails: undefined | FundWithThumbnail[]
  if (result.data && thumbnails) {
    fundsWithThumbnails = result.data.map((fund) => {
      const [defaultFallbackThumbnail, overrideFallbackThumbnail] =
        thumbnails.data

      const thumbnail =
        thumbnails.data.find((t) => fund.type === t.fundType.toUpperCase()) ??
        overrideFallbackThumbnail ??
        defaultFallbackThumbnail
      return {
        ...fund,
        largeThumbnailUrl: thumbnail?.large.url,
        smallThumbnailUrl: thumbnail?.small.url,
      }
    })
  }

  return {
    ...fundsWithThumbnails,
    data: fundsWithThumbnails,
  }
}
