import React, { forwardRef } from 'react'

import { Icon } from '@cais-group/equity/atoms/icon'

// Ignoring for now as we may wish to extend this
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ToggleButtonProps {}

export const DefaultToggleButton = forwardRef<
  HTMLButtonElement,
  ToggleButtonProps
>(({ ...rest }, ref) => {
  return (
    <button ref={ref} {...rest} className="flex" type="button">
      <Icon size="small" color="eq-color-neutral-900" type="ArrowDropDown" />
    </button>
  )
})
