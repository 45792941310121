import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useDebounce } from 'react-use'
import * as R from 'remeda'

import type { AdapterFieldProps } from '../adapters/adapter'
import { useUniformContext } from '../context'
import type { UniformAny } from '../types'
import { matchUniformService } from '../utils'

export function useAsyncValidationService({
  field,
  enabled,
}: {
  field: AdapterFieldProps<string, UniformAny, UniformAny>
  enabled: boolean
}) {
  const isEnabled = [R.isString(field.validationService), enabled].every(
    Boolean
  )
  const { services, selectedOptions } = useUniformContext()
  const { setError, clearErrors, getValues } = useFormContext()
  const value = useWatch({
    name: field.id,
    disabled: !isEnabled,
  })
  const [debouncedValue, setDebouncedValue] = React.useState(value)
  useDebounce(() => setDebouncedValue(value), 250, [value])
  const result = useQuery<string | undefined>(
    [field.id, field.validationService, debouncedValue],
    async () => {
      const fn = matchUniformService({
        services,
        service: R.isString(field.validationService)
          ? field.validationService
          : undefined,
      })

      const result = await fn(
        { fieldId: field.id, value: debouncedValue },
        getValues(),
        selectedOptions.latestFieldValue
      )

      if (typeof result === 'string') {
        setError(field.id, {
          type: 'server',
          message: result,
        })
      } else {
        clearErrors(field.id)
      }

      return result
    },
    {
      enabled: isEnabled,
      refetchOnWindowFocus: false,
    }
  )
  return { ...result, isEnabled }
}
