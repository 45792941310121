import { AnalyticsBrowser } from '@segment/analytics-next'
import { debounce, isEqual } from 'lodash-es'
import { useContext, useEffect, useMemo, useState, useRef } from 'react'

import { APPS } from '@cais-group/shared/domain/apps'

import { SegmentContext } from './segment-context-provider'

interface UseBuildTrackerArgs {
  app: APPS
  primaryTab?: string
  url: string
  segment?: AnalyticsBrowser
}

export interface TrackArgs<Data> {
  event: string
  data?: Data
}

type Tracker<TrackingData> = (trackerArgs: TrackArgs<TrackingData>) => void

const useBuildTrack = <TrackingData extends Record<string, unknown>>({
  app,
  primaryTab,
  url,
  segment,
}: UseBuildTrackerArgs): Tracker<TrackingData> => {
  return useMemo(
    () =>
      ({ event, data }) => {
        segment?.track(event, { ...data, app, primary_tab: primaryTab, url })
      },
    [segment, app, primaryTab, url]
  )
}

export const useSegment = () => {
  return useContext(SegmentContext)
}

interface UseTrackArgs {
  app: APPS
  primaryTab?: string
  url?: string
}

/*
 * Use this method if you want to manually call the Segment's page method
 * const pageTrack = usePageTrack({ app, primaryTab, url })
 * pageTrack({ event, data })
 */
export const usePageTrack = <TrackingData extends Record<string, unknown>>({
  app,
  url = window.location.href,
}: UseTrackArgs) => {
  const segment = useSegment()
  return useBuildPageTrack<TrackingData>({
    segment,
    app,
    url,
  })
}

const useBuildPageTrack = <TrackingData extends Record<string, unknown>>({
  app,
  primaryTab,
  url,
  segment,
}: UseBuildTrackerArgs): Tracker<TrackingData> => {
  return useMemo(
    () =>
      ({ event, data }) => {
        segment?.page(event, { ...data, app, primary_tab: primaryTab, url })
      },
    [segment, app, primaryTab, url]
  )
}

/*
 * Use this method if you want to manually track something
 * const track = useTrack({ app, primaryTab, url })
 * track({ event, data })
 */
export const useTrack = <TrackingData extends Record<string, unknown>>({
  app,
  primaryTab,
  url = window.location.pathname,
}: UseTrackArgs) => {
  const segment = useSegment()
  return useBuildTrack<TrackingData>({
    segment,
    app,
    primaryTab,
    url,
  })
}

export const useDebouncedTrack = <
  TrackingData extends Record<string, unknown>
>({
  app,
  primaryTab,
  url = window.location.pathname,
  ms,
}: UseTrackArgs & { ms: number }): Tracker<TrackingData> => {
  const track = useTrack<TrackingData>({ app, primaryTab, url })
  const debouncedTrack = useMemo(() => debounce(track, ms), [track, ms])
  return debouncedTrack
}

interface useStateWithTracking<TrackingData, State> {
  defaultState: State
  event: string
  track: Tracker<TrackingData>
  getData: (newState: State, oldState: State) => TrackingData
  shouldTrack?: () => boolean
}

interface UseTrackState<TrackingData, State>
  extends useStateWithTracking<TrackingData, State> {
  state: State
}

/*
 * This method allows you to track existing state
 * tracker can be created from useTrack
 */
export const useTrackState = <
  State,
  TrackingData extends Record<string, unknown>
>({
  state,
  defaultState,
  event,
  track,
  getData,
  shouldTrack,
}: UseTrackState<TrackingData, State>) => {
  const lastState = useRef<State>(defaultState)

  useEffect(() => {
    if (shouldTrack && !shouldTrack()) return
    if (!isEqual(lastState.current, state)) {
      track({ event, data: getData(state, lastState.current) })
      lastState.current = state
    }
  }, [state, track, getData, event, shouldTrack])
}

/*
 * This method allows you to create state + track it at the same time
 * tracker can be created from useTrack
 */
export const useStateWithTracking = <
  State,
  TrackingData extends Record<string, unknown>
>({
  defaultState,
  event,
  track,
  getData,
}: useStateWithTracking<TrackingData, State>): [
  State,
  React.Dispatch<React.SetStateAction<State>>
] => {
  const [state, setState] = useState<State>(defaultState)

  useTrackState<State, TrackingData>({
    state,
    defaultState,
    event,
    track,
    getData,
  })

  return [state, setState]
}
