import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { isData, isError } from '@cais-group/caisiq/domain/common'
import { INDIVIDUAL_VIDEO_PROJECT } from '@cais-group/caisiq/domain/video'
import type {
  TypeVideoDataWithParentPlaylist,
  VideoApiState,
} from '@cais-group/caisiq/domain/video'
import { showToast } from '@cais-group/equity/organisms/notifications'

import { VideoPage } from './video-page'

export const VideoPageContainer = (props: {
  data: VideoApiState
  nav?: ReactNode
  userId: string
}) => {
  const { data, nav, userId } = props
  const { id, playlistId } = useParams<{ id: string; playlistId: string }>()
  const [videoId, setVideoId] = useState<string | undefined>(id)
  const videoApiState = data

  // Cache video data so switching between videos doesn't
  // cause the playlist to be unmounted while loading
  const [cachedVideoData, setCachedVideoData] = useState<
    undefined | TypeVideoDataWithParentPlaylist
  >()
  const videoData = isData(videoApiState) ? videoApiState : undefined

  useEffect(() => {
    if (videoData !== undefined) {
      setCachedVideoData(videoData)
    }
    if (isError(videoApiState)) {
      showToast({
        type: 'error',
        title: 'An error occurred, some features may be unavailable.',
      })
    }
  }, [videoApiState, videoData])

  const playlist =
    cachedVideoData?.linkedFrom.caisiqVideoPlaylistCollection.items.find(
      (list) => list.sys.id === playlistId
    )

  return (
    <VideoPage
      nav={nav}
      cachedVideoData={cachedVideoData}
      playlist={playlist}
      episodeNumber={
        playlist?.videosCollection.items &&
        playlist.videosCollection.items.findIndex(
          (video) => video.sys.id === videoId
        ) + 1
      }
      videoId={videoId}
      setVideoId={setVideoId}
      isSingleVideo={playlistId === INDIVIDUAL_VIDEO_PROJECT || !playlist}
      userId={userId}
    />
  )
}

VideoPageContainer.displayName = 'VideoPageContainer'
