import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

import { useApiPathWithPayload } from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import { useAxiosInstance } from '@cais-group/caisiq/util/hook/use-axios-instance'
import { showToast } from '@cais-group/equity/organisms/notifications'
import { ApiPaths, Course } from '@cais-group/shared/util/type/caisiq-be'

import { useGetLibraryCourses } from './useGetLibraryCourses'

export type PatchEnrollLibraryCoursePayload = { id: string; enroll: boolean }

/**
 * This returns an object containing an execute function you can call
 * which will attempt to patch the entity on the server, and if
 * successful will update the react-query cache
 */
export const usePatchEnrollCourse = () => {
  const axios = useAxiosInstance()

  const libraryCoursesResult = useGetLibraryCourses()

  const queryClient = useQueryClient()

  const mutationPathFn = useApiPathWithPayload(ApiPaths.updateEnrollment)

  const mutation = useMutation<
    Course,
    AxiosError,
    PatchEnrollLibraryCoursePayload,
    { previousLibraryCourses: Array<Course> | undefined }
  >(
    ApiPaths.updateEnrollment,
    (payload: PatchEnrollLibraryCoursePayload) =>
      axios
        .patch(
          mutationPathFn({ AcpCourseId: payload.id }),
          JSON.stringify({ enrolled: payload.enroll })
        )
        .then((res) => res.data),
    {
      onMutate: async (mutation) => {
        // Prevent other refetches on this query overwriting our update
        await queryClient.cancelQueries(ApiPaths.libraryCourses)

        const previousLibraryCourses = queryClient.getQueryData<Course[]>(
          ApiPaths.libraryCourses
        )

        // Optimistially update libraryCourses
        queryClient.setQueryData<Course[]>(
          ApiPaths.libraryCourses,
          (oldData = []) =>
            [...oldData].map((libraryCourse) =>
              libraryCourse.id === mutation.id
                ? { ...libraryCourse, enrolled: mutation.enroll }
                : libraryCourse
            )
        )
        // Place previous on context for use in onError to undo optimistic update
        return { previousLibraryCourses }
      },
      onSuccess: (data, variables) => {
        showToast({
          type: 'success',
          title: `You have ${
            variables.enroll ? `enrolled to` : `unenrolled from`
          } ${data.name}`,
        })
      },
      onError: (error, variables, context) => {
        if (context?.previousLibraryCourses) {
          queryClient.setQueryData<Course[]>(
            ApiPaths.libraryCourses,
            context.previousLibraryCourses
          )
        }
        console.error(
          'There was a problem patching the enroll field on a LibraryCourse.',
          error,
          error.response?.status
        )
        showToast({
          type: 'error',
          title:
            'There was a problem patching the enroll field on a LibraryCourse.',
        })
      },
      onSettled: () => {
        queryClient.refetchQueries({ queryKey: ApiPaths.courses })
        queryClient.refetchQueries({ queryKey: ApiPaths.libraryCourses })
      },
    }
  )

  const executeCourseEnrollPatch = (options: {
    id: string
    enroll: boolean
  }) => {
    const selectedLibraryCourse = libraryCoursesResult.data?.find(
      (libraryCourse) => libraryCourse.id === options.id
    )
    if (selectedLibraryCourse === undefined) {
      throw new Error(`LibraryCourse with id '${options.id}' not found`)
    } else if (selectedLibraryCourse.enrolled !== options.enroll) {
      mutation.mutate(options)
    }
  }

  return {
    executeCourseEnrollPatch,
    courseEnrollPatchResult: mutation,
  }
}
