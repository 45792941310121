import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { ModalLayout } from '@cais-group/approved/ui/modal/layout'
import { BodySmall } from '@cais-group/approved/ui/typography'
import { modalStateAtom } from '@cais-group/caisiq/domain/common'

export function ModalCancel() {
  const [modalState, setModalState] = useRecoilState(modalStateAtom)
  const navigate = useNavigate()
  return (
    <ModalLayout
      title="Are you sure you want to cancel?"
      open={Boolean(modalState)}
      onClose={() => setModalState(undefined)}
      body={<BodySmall>Any changes will be lost</BodySmall>}
      actions={[
        {
          text: 'Back',
          handler: () => setModalState(undefined),
          variant: 'secondary',
        },
        {
          text: 'Yes, cancel',
          handler: () => {
            setModalState(undefined)
            navigate(-1)
          },
          variant: 'danger',
        },
      ]}
    />
  )
}

export default ModalCancel
