import cx from 'classnames'
import { debounce } from 'lodash-es'
import React, { Children, ReactNode, forwardRef, useMemo } from 'react'
import { match, P } from 'ts-pattern'

import { Button, ButtonProps } from '@cais-group/equity/atoms/button'
import { LoadingIndicator } from '@cais-group/equity/atoms/loading-indicator'

export interface MenuProps {
  children: ReactNode
  isOpen: boolean
  inputValue: string
  isSearchable: boolean
  isLoading: boolean
  onScrollToBottomOfOptions?: () => void
}

export const DefaultMenu = forwardRef<HTMLDivElement, MenuProps>(
  (
    {
      children,
      inputValue,
      isLoading,
      isSearchable,
      isOpen,
      onScrollToBottomOfOptions,
      ...rest
    }: MenuProps,
    ref
  ) => {
    const debouncedOnScrollToBottom = useMemo(
      () =>
        onScrollToBottomOfOptions && debounce(onScrollToBottomOfOptions, 100),
      [onScrollToBottomOfOptions]
    )

    const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement
      const { scrollTop, scrollHeight, clientHeight } = target

      // The user has scrolled to the bottom of the component
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        debouncedOnScrollToBottom && debouncedOnScrollToBottom()
      }
    }

    return (
      <div
        ref={ref}
        className={cx('max-h-376 w-full overflow-auto px-24 py-16', {
          hidden: !isOpen,
        })}
        onScroll={onScroll}
        {...rest}
      >
        {match({ inputValue, isSearchable, isLoading, children })
          .with(
            {
              isLoading: true,
              children: P.when((children) => Children.count(children) !== 0),
            },
            () => (
              <>
                {children}
                <div className="flex items-center justify-center">
                  <LoadingIndicator size="small" />
                </div>
              </>
            )
          )
          .with(
            { children: P.when((children) => Children.count(children) !== 0) },
            ({ children }) => children
          )
          .with(
            {
              isLoading: true,
            },
            () => (
              <div className="flex items-center justify-center">
                <LoadingIndicator size="small" />
              </div>
            )
          )
          .with(
            {
              inputValue: P.not(P.nullish),
              isSearchable: true,
              isLoading: false,
            },
            () => (
              <span className="bodySmall text-neutral-900">
                No results matching <b>{inputValue}</b>
              </span>
            )
          )
          .otherwise(() => null)}
      </div>
    )
  }
)

export interface MenuContainerProps {
  children: ReactNode
  isOpen: boolean
}

export const MenuContainer = ({
  children,
  isOpen,
}: {
  children: React.ReactNode
  isOpen: boolean
}) => {
  return (
    <div
      className={cx(
        'shadow-3 bg-neutral-0 overflow-none absolute z-50 w-[max(420px,100%)]',
        { hidden: !isOpen }
      )}
    >
      {children}
    </div>
  )
}

export const MenuActions = ({
  menuActions,
}: {
  menuActions: [ButtonProps, ButtonProps]
}) => {
  return (
    <div className="px-24">
      <div className="flex w-full justify-between border-0 border-t border-neutral-100 py-8">
        {menuActions.map((action) => (
          <Button
            key={action.children?.toString()}
            {...action}
            size="small"
            variant="tertiary"
          >
            {action.children}
          </Button>
        ))}
      </div>
    </div>
  )
}
