import { match, P } from 'ts-pattern'

/**
 * Converts a string to Title Case, where the first letter of each word is capitalized
 * except for minor words.
 *
 * @example
 * toTitleCase("andrew and John Made a Great Pull Request"); Output: "Andrew and John Made a Great Pull Request"
 *
 * @param {string} input - The input string to convert to Title Case.
 * @param {Object=} options - formatting options
 * @property {string=} options.delimiter - the string to split the word on
 * @property {string[]=} options.excludedWords - a list of words that won't be capitalized
 * @returns {string} The Title Case string with the first letter of each word capitalized, except for minor words. If the input string is empty, the function returns an empty string.
 */
export function toTitleCase(
  input: string,
  options?: {
    excludedWords?: string[]
    delimiter?: string
  }
): string {
  const excludedWords =
    options?.excludedWords ?? toTitleCase.defaultExcludedWords

  return input
    .toLowerCase()
    .split(options?.delimiter ?? ' ')
    .map(
      (word, index) =>
        match([index === 0, excludedWords.includes(word)])
          .with([true, P._], () => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the word if it's the first word in the sentence
          .with(
            [P._, false],
            () => word.charAt(0).toUpperCase() + word.slice(1)
          ) // Capitalize the word if it's not a minor word
          .otherwise(() => word) // Leave the word as it is (lowercase) if it's not the first word and a minor word
    )
    .join(' ')
}

toTitleCase.defaultExcludedWords = ['a', 'at', 'of', 'to', 'from', 'for', 'the']
