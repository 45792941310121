import { type FieldError, useFormState } from 'react-hook-form'

import { UniformFieldProps } from '../types'
import { pathOr } from '../utils/path-or'

export const useGetFieldError = (
  field: UniformFieldProps
): { error: FieldError | undefined } => {
  const { errors } = useFormState({ name: field.id })
  const fieldPath = field.parentPrefix
    ? `${field.parentPrefix}.${field.id}`
    : field.id
  const error = pathOr<FieldError, undefined>(
    undefined,
    fieldPath?.split('.') ?? [],
    errors
  )

  return { error }
}
