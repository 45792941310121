import { useGetCuratedFilterGroupQuery } from '@cais-group/shared/util/graphql/mfe-contentful'

import { minutesToMilliseconds } from '../helper'

const FIFTEEN_MINUTES = minutesToMilliseconds(15)

export const useGetCuratedFilterGroupByLabel = (label: string) => {
  const { data, isLoading, error } = useGetCuratedFilterGroupQuery(
    { label },

    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: FIFTEEN_MINUTES,
      staleTime: FIFTEEN_MINUTES,
    }
  )

  return {
    data: data?.curatedFilterGroupCollection?.items,
    isLoading,
    error,
  }
}
