import { components } from './generated'

export type CeCredits = NonNullable<components['schemas']['Ce']>

export type CoursesCredits = components['schemas']['CoursesCredits']

export type CourseCredits = components['schemas']['CourseCredits']

export type CreditSummary = components['schemas']['CreditSummary']

export type CreditProvider = 'cima' | 'cfp'

export type CreditStatus = CourseCredits['cfp']['status']

export type CreditValue = components['schemas']['CreditValue']
