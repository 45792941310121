import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ReactElement } from 'react'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'

const PassThrough = ({ children }: { children: ReactElement }) => children

/**
 * Redirects logged out users attempting to access this route to
 * the login page
 */
export const AuthProtected = withAuthenticationRequired(PassThrough, {
  onRedirecting: () => (
    <LoadingContainer
      state={LoadingState.LOADING}
      coverPage="FULL_SCREEN"
      type="large"
    ></LoadingContainer>
  ),
  // This gets passed to the onRedirectCallback of Auth0Provider as
  // appState.returnTo
  returnTo: () => window.location.pathname + window.location.search,
})
