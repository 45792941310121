import styled from '@emotion/styled'
import { useMediaQuery, useTheme, Skeleton } from '@mui/material'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { pickBy } from 'remeda'

import {
  H5Strong,
  Body,
  BodySmallCss,
  H6Strong,
} from '@cais-group/approved/ui/typography'
import {
  useGetUserLicences,
  usePutUserLicences,
} from '@cais-group/caisiq/domain/user'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { CeCreditGuideLink } from '@cais-group/caisiq/ui/ce-credit-guide-link'
import { useDashboardTabs } from '@cais-group/caisiq/ui/dashboard'
import { LicenceForm, TSize } from '@cais-group/caisiq/ui/licence-form'
import { NavigationSidebar } from '@cais-group/caisiq/ui/navigation/sidebar'
import {
  CeCreditsTable,
  useGetProfilePageTableColumns,
} from '@cais-group/caisiq/ui/table/ce-credits'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { showToast } from '@cais-group/equity/organisms/notifications'
import { colors } from '@cais-group/equity/particles/colors'
import { BREAKPOINTS, SPACING } from '@cais-group/shared/ui/design-tokens'
import { Disclaimer } from '@cais-group/shared/ui/disclaimer/default'
import {
  ApiPaths,
  CourseCredits,
  CreditSummary,
  Learner,
  LearnerDto,
} from '@cais-group/shared/util/type/caisiq-be'
import { ExperienceData } from '@cais-group/shared/util/type/experience-data'

import { CeCreditProfileCards } from './components/CeCreditProfileCards'
import { CreditsProgressCharts } from './components/CreditProgressCharts'
import { UserProfileHeader } from './components/Header'

const ProfileContainer = styled.div<{
  addMargin: boolean
}>`
  min-height: calc(100vh - 320px); /* minus footer height */
  display: grid;

  /* Mobile first */
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: 'head' 'sidebar' 'main';
  margin-bottom: ${({ addMargin }) => addMargin && SPACING.s56};
  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    grid-template-rows: min-content max-content;
    grid-template-columns: max-content 1fr 1fr;
    grid-template-areas: '. head head' 'sidebar main main';
  }
`

const StyledUserProfileHeader = styled(UserProfileHeader)`
  grid-area: head;
`

const GridAreaMain = styled.div`
  grid-area: main;
  display: grid;

  grid-template-columns: 1fr;

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointLg}) {
    border-top: 2px solid ${colors['eq-color-neutral-100']};
    grid-template-columns: repeat(12, 1fr);
  }
`

const StyledLicenceForm = styled(LicenceForm)`
  grid-column: auto / span 12;
  box-shadow: none !important;
  width: calc(100vw - ${SPACING.s24});
  place-self: center;

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    place-self: start;
  }
  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointLg}) {
    grid-column: auto / span 6;
    max-width: ${SPACING.s608};
  }
  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    margin-right: 0;
  }
`

const StyledChartCreditsCompleted = styled(CreditsProgressCharts)`
  grid-column: auto / span 12;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointLg}) {
    grid-column: auto / span 6;
  }
`

export const StyledTableContainer = styled.div<{ cols?: number }>`
  place-self: center;
  grid-column: auto / span ${({ cols }) => cols || 6};
  padding: var(--s32) var(--s56) var(--s32) var(--s32);
  width: 100%;
`

const FormHeaderContainer = styled.div`
  grid-column: auto / span 12;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: var(--s32) var(--s32) 0 var(--s32);

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointLg}) {
    flex-direction: row;
    padding-right: ${SPACING.s56};
  }
`

const FormHeader = styled.div<{ size: TSize }>`
  max-width: 520px;

  ${({ size }) => size === 'regular' && `padding-right: ${SPACING.s88};`}
  & svg {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`

const FormHeaderCaption = styled(Body)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => {
    // @ts-ignore
    return theme.palette.neutral.darker
  }};
`
const NavigationSidebarContainer = styled.div`
  border-top: 2px solid ${colors['eq-color-neutral-100']} !important;
  grid-area: sidebar;
`
const CeCreditsLink = styled.div`
  min-width: 232px;
`

const StyledH6 = styled(H6Strong)`
  margin-bottom: var(--s16);
  color: rgb(var(--colors-neutral-900));
`

const DisclaimerContainer = styled.div`
  grid-column: auto / span 12;
  color: rgb(var(--colors-neutral-600));
  padding: var(--s24) var(--s32) var(--s56);

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    padding: var(--s24) var(--s56) var(--s56) var(--s32);
  }

  & p {
    ${BodySmallCss}
  }
`

export type ProfilePageProps = {
  companyName: string
  licences?: Learner
  isFetched: boolean
  fullName?: string
  email?: string
  coursesWithEarnedCredits?: CourseCredits[]
  credits?: CreditSummary
  ceGuidePage?: ExperienceData['ceGuidePage']
}

export const ProfilePage = ({
  companyName,
  licences,
  isFetched,
  fullName,
  email,
  coursesWithEarnedCredits,
  credits,
  ceGuidePage,
}: ProfilePageProps) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'))
  const queryClient = useQueryClient()

  const { refetch } = useGetUserLicences()
  const saveLicenceForm = usePutUserLicences()

  const hasSavedLicences = Boolean(
    licences?.cima?.number || licences?.cfp?.number
  )

  const profilePageColumns = useGetProfilePageTableColumns()

  // Each CAISIQ Tab referenced by a CAISIQ Firm in Contentful can have its own specific disclaimer.
  const { disclaimer: tabSpecificDisclaimer } = useDashboardTabs()

  return (
    <ProfileContainer
      // Bottom margin for WarningPanel
      addMargin={
        credits
          ? Boolean(
              credits.completed.cfpCeCredit + credits.completed.cimaCeCredit ===
                0
            )
          : false
      }
    >
      <StyledUserProfileHeader
        fullName={fullName}
        email={email}
        firmName={companyName}
      />
      <NavigationSidebarContainer>
        <NavigationSidebar
          onBack={() => navigate(ROUTES.courses)}
          backButtonText="Back to Dashboard"
        />
      </NavigationSidebarContainer>
      <GridAreaMain>
        <FormHeaderContainer>
          <FormHeader size="compact">
            <H5Strong>Continuing Education</H5Strong>
            <FormHeaderCaption>
              Earn CE credits for eligible courses through CAIS IQ. Manage your
              credential numbers and track your credits.
            </FormHeaderCaption>
          </FormHeader>

          {ceGuidePage && (
            <CeCreditsLink>
              <CeCreditGuideLink
                slug={ceGuidePage.slug}
                onClick={() =>
                  trackingService.ceLearnMoreAboutCeCreditsLinkClicked()
                }
              />
            </CeCreditsLink>
          )}
        </FormHeaderContainer>

        {isFetched && (
          <StyledLicenceForm
            fields={{
              cimaNumber: licences?.cima?.number ?? '',
              cfpNumber: licences?.cfp?.number ?? '',
              cimaTargetCompletionDate: licences?.cima?.targetCompletionDate,
              cfpTargetCompletionDate: licences?.cfp?.targetCompletionDate,
            }}
            size="compact"
            cta="Save"
            addDatePicker={true}
            onSubmit={({
              cfpNumber,
              cimaNumber,
              cimaTargetCompletionDate,
              cfpTargetCompletionDate,
            }) => {
              const payload: LearnerDto = {
                cfp: {
                  number: cfpNumber.trim() || '',
                  targetCompletionDate: cfpTargetCompletionDate,
                },
                cima: {
                  number: cimaNumber.trim() || '',
                  targetCompletionDate: cimaTargetCompletionDate,
                },
              }

              /**
               * @todo how do we want to handle toast notifications now that targetCompletion dates are included?
               */
              return saveLicenceForm.mutateAsync(
                pickBy<LearnerDto>(payload, (val) => Boolean(val?.number)),
                {
                  onSuccess: async () => {
                    refetch()
                    await queryClient.invalidateQueries(ApiPaths.coursesCredits)
                    showToast({
                      type: 'success',
                      title:
                        'Your credential numbers have been successfully saved',
                    })
                  },
                  onError: () => {
                    showToast({
                      type: 'error',
                      title:
                        'There was an error saving one or more credential numbers',
                    })
                  },
                }
              )
            }}
          />
        )}
        {credits !== undefined ? (
          <StyledChartCreditsCompleted
            availableCredits={credits.available}
            completedCredits={credits.completed}
            hasSavedLicences={hasSavedLicences}
          />
        ) : (
          <Skeleton
            sx={{ gridColumn: 'span 6' }}
            role="status"
            aria-label="Loading chart data"
            aria-live="polite"
          />
        )}

        {coursesWithEarnedCredits?.length && hasSavedLicences ? (
          !isMobile ? (
            <StyledTableContainer cols={12}>
              <StyledH6>Completed courses eligible for CE credit</StyledH6>
              <CeCreditsTable
                courses={coursesWithEarnedCredits}
                profilePageColumns={profilePageColumns}
              />
            </StyledTableContainer>
          ) : (
            <CeCreditProfileCards
              coursesWithEarnedCredits={coursesWithEarnedCredits}
            />
          )
        ) : null}

        <DisclaimerContainer data-testid="ce-disclaimer">
          <Disclaimer disclaimer={tabSpecificDisclaimer} />
        </DisclaimerContainer>
      </GridAreaMain>
    </ProfileContainer>
  )
}
