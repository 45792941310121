import { List, ListItem as ListItemComponent } from '@mui/material'

export type ListItem = {
  label: string
  value: string
}
export type DisabledListProps = {
  details: ListItem[]
  helperText?: string
}
export function DisabledList({ details, helperText }: DisabledListProps) {
  return (
    <div className="mt-32 bg-neutral-100 p-32" data-testid="disabled-form-list">
      {details.map(({ label, value }, idx) => (
        <List
          className={`body flex flex-row items-center border-neutral-200 ${
            idx > 0 ? 'border-0 border-t border-solid' : ''
          }`}
          key={label}
          data-testid={`disabled-form-item-${label}`}
        >
          <ListItemComponent className="pl-0">{label}</ListItemComponent>
          <ListItemComponent className="pl-0">{value}</ListItemComponent>
        </List>
      ))}
      {helperText && <p className="bodySmall text-neutral-600">{helperText}</p>}
    </div>
  )
}

export default DisabledList
