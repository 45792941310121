import { ChecklistItem } from '@cais-group/shared/ui/checklist-item'

import {
  SelectableListItem,
  SelectableListOnChange,
} from '../shared-ui-selectable-list'

export interface WithSelectAllProps<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
> {
  children: JSX.Element
  items: TItem[]
  selectedItems: TValue[]
  disabledItems: TValue[]
  onChange: SelectableListOnChange
}

export function WithSelectAll<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
>({
  items,
  onChange,
  selectedItems,
  disabledItems,
  children,
}: WithSelectAllProps<TItem, TValue>) {
  const allEnabledItems = items.filter(
    ({ value }) => !disabledItems.includes(value)
  )
  const isAllSelected = allEnabledItems.every(({ value }) =>
    selectedItems.includes(value)
  )
  const isSomeSelected = allEnabledItems.some(({ value }) =>
    selectedItems.includes(value)
  )
  const allValues = allEnabledItems.map(({ value }) => value)
  const isIndeterminate = !isAllSelected && isSomeSelected

  return (
    <>
      <div className="mb-24">
        <ChecklistItem
          label="Select all"
          onChange={() => {
            isAllSelected ? onChange([]) : onChange([...allValues])
          }}
          name="select-all"
          value="select-all"
          selectedOptions={isAllSelected ? ['select-all'] : []}
          testId="select-all"
          indeterminate={isIndeterminate}
        />
      </div>
      {children}
    </>
  )
}
