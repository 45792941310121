import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { getEnvConfig } from '@cais-group/caisiq-ui-env'
import { TUserRole } from '@cais-group/shared/util/type/caisiq-be'

export type SideBarLink = {
  destination: string
  buttonText: string
  roles?: Array<string>
}

interface GetSidebarLinksOptions {
  firmId?: string
}

export const useGetSidebarLinks = ({ firmId }: GetSidebarLinksOptions) => {
  const { PORTAL_URL } = getEnvConfig()

  /**
   * @todo: At some point we will allow firms to edit their own child firms, at this point
   * then we should potentially allow linking into IAM conditionally on firmId sent in
   * options matching that of the user, and then use a different capability, such as: 'iam:firms:read'
   *
   * Note you cannot access IAM from main nav right now without both ['iam:firms:list:all', 'iam:users:list:all']
   * so I've assumed same access from this sidebar, but it is easy to change this as needed.
   */
  const sidebarLinks: SideBarLink[] = [
    {
      destination: `${PORTAL_URL}/access-manager/firms/${firmId}/overview`,
      buttonText: 'Overview',
      roles: ['fake:not-currently-available'],
    },
    {
      destination: `${PORTAL_URL}/access-manager/firms/${firmId}/users`,
      buttonText: 'Users',
      roles: ['iam:firms:list:all', 'iam:users:list:all'],
    },
    {
      destination: `${PORTAL_URL}/access-manager/firms/${firmId}/teams`,
      buttonText: 'Teams',
      roles: ['iam:firms:list:all', 'iam:users:list:all'],
    },
    {
      destination: `${PORTAL_URL}/access-manager/firms/${firmId}/apps`,
      buttonText: 'Apps',
      roles: ['iam:firms:list:all', 'iam:users:list:all'],
    },
    {
      destination: `${PORTAL_URL}/access-manager/firms/${firmId}/roles`,
      buttonText: 'Roles',
      roles: ['iam:firms:list:all', 'iam:users:list:all'],
    },
  ]

  return sidebarLinks.filter((link) => {
    if (!link.roles) {
      return true
    }
    return link.roles.every((r) => userSettingsService.hasRole(r as TUserRole))
  })
}
