import styled from '@emotion/styled'
import { Tooltip } from '@mui/material'

import { BodySmall } from '@cais-group/approved/ui/typography'
import { SPACING } from '@cais-group/shared/ui/design-tokens'

const DIMENSIONS_SMALL = 62
const DIMENSIONS_LARGE = 82

const TooltipContainer = styled.div`
  padding: ${SPACING.s8};
  display: flex;
  justify-content: center;
`

const Reference = styled.div`
  display: flex;
  flex-direction: row;
`

const ReferenceText = styled(BodySmall)`
  margin: 0 0 0 ${SPACING.s8};
  display: flex;
  flex-direction: column;
`

const ColorLeft = styled.div`
  max-width: ${SPACING.s16};
  flex-grow: 0;
`

const ColorReference = styled.div`
  width: 12px;
  height: 12px;
  margin-top: 0.4rem;
  background-color: rgb(var(--colors-success-600));
  margin-right: ${SPACING.s8};
`

const ProgressRingContainer = styled.div<{ size: 'small' | 'large' }>`
  width: ${({ size }) =>
    size === 'small' ? DIMENSIONS_SMALL : DIMENSIONS_LARGE}px;
  height: ${({ size }) =>
    size === 'small' ? DIMENSIONS_SMALL : DIMENSIONS_LARGE}px;
  position: relative;
  display: flex;
  justify-content: center;
`

const CircularChart = styled.svg`
  display: block;
`

const ProgressText = styled.text`
  fill: rgb(var(--colors-neutral-900));
  font-size: 0.6em;
  text-anchor: middle;
`

const ProgressTextSymbol = styled.tspan`
  font-size: 5px;
`

const CircleColor = styled.path`
  fill: none;
  stroke: rgb(var(--colors-success-600));
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
`

const CircleBg = styled.path`
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
`

export type ProgressRingProps = {
  testId?: string
  progress: number
  progressTooltipText?: string
  size?: 'small' | 'large'
}

export const ProgressRing = (props: ProgressRingProps) => {
  return (
    <ProgressRingContainer
      data-testid={props.testId ?? 'progress-ring'}
      size={props.size ?? 'small'}
    >
      <Tooltip
        placement="top"
        title={
          <TooltipContainer>
            <Reference>
              <ColorLeft>
                <ColorReference />
              </ColorLeft>
              <ReferenceText>
                <div>{props.progress}%</div>
                <div>{props.progressTooltipText ?? 'progress to mastery'}</div>
              </ReferenceText>
            </Reference>
          </TooltipContainer>
        }
      >
        <CircularChart viewBox="0 0 36 36">
          <CircleBg
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <CircleColor
            strokeDasharray={props.progress + ' 100'}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <ProgressText x="19" y="21.35">
            {props.progress}
            <ProgressTextSymbol dy="-2">%</ProgressTextSymbol>
          </ProgressText>
        </CircularChart>
      </Tooltip>
    </ProgressRingContainer>
  )
}

export default ProgressRing
