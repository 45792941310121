import styled from '@emotion/styled'
import { FormControlLabel } from '@mui/material'
import MuiSwitch from '@mui/material/Switch'
import React from 'react'

import { BodySmall, H6Strong } from '@cais-group/approved/ui/typography'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  margin: 0;
  border-top: 1px solid ${colors['eq-color-neutral-300']};
  padding-top: ${SPACING.s16};
  margin-top: ${SPACING.s32};
`

const OptionText = styled.div`
  flex-grow: 1;
`

export interface FormSettingsToggleProps {
  label: string
  helperText: string
  checked: boolean
  onChange(e: React.SyntheticEvent<Element, Event>, checked: boolean): void
  disabled?: boolean
}

export function FormSettingsToggle({
  label,
  helperText,
  checked,
  onChange,
  disabled = false,
}: FormSettingsToggleProps) {
  return (
    <StyledFormControlLabel
      control={<MuiSwitch disabled={disabled} />}
      checked={checked}
      onChange={onChange}
      disableTypography={true}
      labelPlacement="start"
      aria-label={label}
      label={
        <OptionText>
          <H6Strong>{label}</H6Strong>
          <BodySmall className="text-neutral-600">{helperText}</BodySmall>
        </OptionText>
      }
    />
  )
}

export default FormSettingsToggle
