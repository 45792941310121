import React from 'react'
import { useRecoilState } from 'recoil'

import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { TypeDashboardPath } from '@cais-group/shared/util/type/dashboard-path'

import { ArticleCount } from '../components/ArticleCount'
import { CourseCount } from '../components/CourseCount'
import { CourseLibraryCount } from '../components/CourseLibraryCount'
import { FundCount } from '../components/FundCount'
import { VideoCount } from '../components/VideoCount'
import { WhitepaperCount } from '../components/WhitepaperCount'

export function useDashboardSearch() {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)

  /**
   * Note: Count components provided here do not mount and render until the
   * getCountForTab function is called below, so we are only counting for tabs
   * that we have access to
   */
  const countComponents: Partial<
    Record<TypeDashboardPath, React.ReactElement>
  > = {
    articles: <ArticleCount />,
    courses: <CourseCount />,
    'course-library': <CourseLibraryCount />,
    funds: <FundCount />,
    videos: <VideoCount />,
    whitepapers: <WhitepaperCount />,
  }

  /**
   * @todo: we probably want to update TypeDashboardPath to include all apps' paths and make it shared
   */
  const getCountForTab = (
    path: TypeDashboardPath | string
  ): React.ReactElement | undefined => {
    const tab = path.replace(/^.*[/]/, '')

    return searchTerm === ''
      ? undefined
      : countComponents[tab as TypeDashboardPath]
  }

  return { searchTerm, setSearchTerm, getCountForTab }
}
