import styled from '@emotion/styled'
import { useCallback, useEffect, useRef, useState } from 'react'

const CoverImage = styled.div<{ url: string; bgPosition: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: ${({ url }) => `url('${url}');`};
  background-size: cover;
  background-position: ${({ bgPosition }) => bgPosition};
`

export type ImageOrFallbackProps = {
  imageUrl?: string
  fallbackUrl?: string
  minAspectRatio: number
  maxAspectRatio: number
}

/**
 * Uses the imageUrl as a centered cover background if it is within
 * the min and max aspect ratios, otherwise it uses the fallbackUrl
 * locked to the bottom right. It will also use the fallback if the
 * imageUrl fails to load.
 */
export const ImageOrFallback = ({
  imageUrl,
  fallbackUrl,
  minAspectRatio,
  maxAspectRatio,
}: ImageOrFallbackProps) => {
  const [chosenImageUrl, setChosenImageUrl] = useState<string>()
  const memoryImage = useRef<HTMLImageElement>(new Image())

  const handleLoad = useCallback(() => {
    const ratio = memoryImage.current.width / memoryImage.current.height
    if (ratio > maxAspectRatio || ratio < minAspectRatio) {
      setChosenImageUrl(fallbackUrl)
    } else {
      setChosenImageUrl(imageUrl)
    }
  }, [fallbackUrl, imageUrl, maxAspectRatio, minAspectRatio])

  const handleError = useCallback(() => {
    setChosenImageUrl(fallbackUrl)
  }, [fallbackUrl])

  useEffect(() => {
    const image = memoryImage.current
    if (imageUrl) {
      image.addEventListener('load', handleLoad)
      image.addEventListener('error', handleError)
      if (imageUrl) {
        image.src = imageUrl
      }
    } else if (fallbackUrl) {
      setChosenImageUrl(fallbackUrl)
    }
    return () => {
      image.removeEventListener('load', handleLoad)
      image.removeEventListener('error', handleError)
    }
  }, [
    imageUrl,
    fallbackUrl,
    maxAspectRatio,
    minAspectRatio,
    handleLoad,
    handleError,
  ])

  return chosenImageUrl ? (
    <CoverImage
      url={chosenImageUrl}
      bgPosition={chosenImageUrl === fallbackUrl ? 'bottom right' : 'center'}
      data-chromatic="ignore"
      role="img"
      aria-label="course-header-image"
    />
  ) : null
}
