import { useRecoilState } from 'recoil'

import { modalStateAtom } from '@cais-group/caisiq/domain/common'
import { Course } from '@cais-group/shared/util/type/caisiq-be'

export function useCourseModal() {
  const [modalState, setModalState] = useRecoilState(modalStateAtom)

  const closeModal = () => {
    setModalState(undefined)
  }

  const openModal = (c: Course) => {
    setModalState(c)
  }

  return {
    course: typeof modalState === 'boolean' ? undefined : modalState,
    closeModal,
    openModal,
  }
}

export default useCourseModal
