import cx from 'classnames'
import { motion } from 'framer-motion'
import { throttle } from 'lodash-es'
import * as React from 'react'
import type { MouseEvent } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { useWindowSize } from 'react-use'

import { Button, ButtonProps } from '@cais-group/equity/atoms/button'
import { DotTag, DotTagProps } from '@cais-group/equity/atoms/dot-tag'
import { StatusTag, StatusTagProps } from '@cais-group/equity/atoms/status-tag'
import { ListItem } from '@cais-group/equity/molecules/list-item'
import { iconsMapping } from '@cais-group/equity/particles/icons'
import { Prettify } from '@cais-group/equity/util/type-utils'

export type NavigationTabItem = {
  link: string
  label: string
  disabled?: boolean
  end?: NavLinkProps['end']
  testId?: string
  dotTag?: DotTagProps
  statusTag?: StatusTagProps
}
type NavigationTabsButtonProps = Pick<
  ButtonProps,
  'children' | 'onClick' | 'disabled' | 'loading'
>
export function NavigationTabs({
  items,
  onNavigate,
  size = 'default',
  buttons,
}: {
  items: Prettify<NavigationTabItem>[]
  onNavigate?: (
    item: Prettify<NavigationTabItem>,
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => void
  size?: 'default' | 'small'
  buttons?:
    | {
        primary: NavigationTabsButtonProps
        secondary?: NavigationTabsButtonProps
      }
    | React.ReactElement // escape hatch for custom buttons, avoid using if you can
}) {
  const mainTabNavRef = React.useRef<HTMLDivElement>(null) // not strictly accurate but HTMLNavElement doesn't exist
  const secondaryTabNavRef = React.useRef<HTMLDivElement>(null) // not strictly accurate but HTMLNavElement doesn't exist
  const moreItemsButtonRef = React.useRef<HTMLLIElement>(null)
  const [showMoreMenu, setShowMoreMenu] = React.useState(false)
  const [hiddenTabsFromIndex, setHiddenTabsFromIndex] = React.useState<
    number | null
  >(null)
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)

  useClearAndRecalculateTabs({
    mainTabNavRef,
    moreItemsButtonRef,
    secondaryTabNavRef,
    items,
    setHiddenTabsFromIndex,
  })

  return (
    <motion.div
      className="relative flex w-full items-center justify-between"
      layout
      layoutRoot
    >
      <MainNav
        mainTabNavRef={mainTabNavRef}
        items={items}
        onNavigate={onNavigate}
        moreItemsButtonRef={moreItemsButtonRef}
        size={size}
        hiddenTabsFromIndex={hiddenTabsFromIndex}
        showMoreMenu={showMoreMenu}
        setShowMoreMenu={setShowMoreMenu}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />

      <MoreItemsNav
        secondaryTabNavRef={secondaryTabNavRef}
        items={items}
        onNavigate={onNavigate}
        hiddenTabsFromIndex={hiddenTabsFromIndex}
        showMoreMenu={showMoreMenu}
        setShowMoreMenu={setShowMoreMenu}
        setActiveTabIndex={setActiveTabIndex}
      />

      {buttons ? <ActionButtons buttons={buttons} /> : null}
    </motion.div>
  )
}

// After discussion with design, preferred approach is to use regular font weight on selected or non selected; as otherwise either text spacing is inconsistent or layout shift happens.
const getItemStyle = (isActive: boolean, isDisabled: boolean) =>
  cx('flex flex-col relative font-normal', {
    'text-primary-600 ': isActive,
    'text-neutral-900': !isActive && !isDisabled,
    'hover:text-primary-600': !isDisabled,
    'text-neutral-300': isDisabled,
  })

// React Router's NavLink doesn't support being disabled; this does make this part a bit more tedious, require types above in children function; may want to do more around testId, onClick etc as that just gets thrown away here
function DisabledNavLink({
  children,
}: {
  children: (props: { isActive: boolean; isPending: boolean }) => JSX.Element
}) {
  return children({ isActive: false, isPending: true })
}

// Need to be able to style DisabledNavLink
function ButtonWrappedDisabledNavLink({
  index,
  className,
  dataTestId,
}: {
  index: number
  className: string
  dataTestId: string
}) {
  return (props: Parameters<typeof DisabledNavLink>[0]) => (
    <button
      disabled
      role="tab"
      key={index}
      data-testid={dataTestId}
      className={className}
    >
      <DisabledNavLink>{props.children}</DisabledNavLink>
    </button>
  )
}

function ListItemContent({ item }: { item: Prettify<NavigationTabItem> }) {
  return (
    <>
      {item.label} {item.statusTag ? <StatusTag {...item.statusTag} /> : null}
      {item.dotTag ? <DotTag {...item.dotTag} /> : null}
    </>
  )
}

function ActiveTabIndicator({ id, show }: { id: string; show: boolean }) {
  return show ? (
    <motion.div
      className="bg-primary-600 absolute bottom-0 left-0 right-0 h-[6px]"
      layoutId={`tab-underline-${id}`}
    />
  ) : null
}

function MoreItemsButton({
  activeTabIndicatorId,
  showMoreMenu,
  setShowMoreMenu,
  highlight,
}: {
  activeTabIndicatorId: string
  showMoreMenu: boolean
  setShowMoreMenu: React.Dispatch<React.SetStateAction<boolean>>
  highlight: boolean
}) {
  const [[, ArrowDropDownIcon], [, ArrowDropUpIcon]] = [
    iconsMapping['ArrowDropDown'],
    iconsMapping['ArrowDropUp'],
  ]
  return (
    <button
      id="nav-more-items-button"
      data-testid="nav-more-items-button"
      type="button"
      className="hover:text-primary-600 relative flex flex-col items-center"
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        setShowMoreMenu(!showMoreMenu)
      }}
    >
      <div className="flex flex-row items-center gap-8 py-32">
        More {showMoreMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      <ActiveTabIndicator id={activeTabIndicatorId} show={highlight} />
    </button>
  )
}

function MainNav({
  mainTabNavRef,
  items,
  onNavigate,
  moreItemsButtonRef,
  size,
  hiddenTabsFromIndex,
  showMoreMenu,
  setShowMoreMenu,
  activeTabIndex,
  setActiveTabIndex,
}: {
  mainTabNavRef: React.RefObject<HTMLDivElement>
  items: Prettify<NavigationTabItem>[]
  onNavigate?: (
    item: Prettify<NavigationTabItem>,
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => void
  moreItemsButtonRef: React.RefObject<HTMLLIElement>
  size: 'default' | 'small'
  hiddenTabsFromIndex: number | null
  showMoreMenu: boolean
  setShowMoreMenu: React.Dispatch<React.SetStateAction<boolean>>
  activeTabIndex: number
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>
}) {
  const activeTabIndicatorId = React.useId()

  const showMoreButtonHighlight = React.useMemo(
    () => activeTabIndex >= (hiddenTabsFromIndex || Number.POSITIVE_INFINITY),
    [activeTabIndex, hiddenTabsFromIndex]
  )

  return (
    <nav
      id="main-tab-nav-container"
      ref={mainTabNavRef}
      className="grow overflow-hidden"
    >
      <ul
        id="main-tab-nav"
        role="tablist"
        className={cx('flex w-full', {
          'gap-x-32': size === 'default',
          'gap-x-24': size === 'small',
        })}
      >
        {items.map((item, index) => {
          const NavElement = item.disabled ? DisabledNavLink : NavLink
          return (
            // removing shrink-0 would probably improve overall behaviour/make it more flexible but this replicates existing layout
            <li
              key={index}
              className="shrink-0"
              data-main-tab-nav-item-index={index}
            >
              <NavElement
                to={item.link}
                end={item.end}
                onClick={(event) => {
                  setActiveTabIndex(index)
                  setShowMoreMenu(false)
                  onNavigate?.(item, event)
                }}
                data-testid={item.testId ?? `tab-${item.label}`}
                role="tab"
              >
                {({
                  isActive,
                  isPending,
                }: {
                  isActive: boolean
                  isPending: boolean
                }) => (
                  <div
                    className={getItemStyle(isActive, item.disabled ?? false)}
                  >
                    <div
                      className={cx('flex flex-row items-center gap-8', {
                        'py-32': size === 'default',
                        'py-24': size === 'small',
                      })}
                    >
                      <ListItemContent item={item} />
                    </div>
                    <ActiveTabIndicator
                      id={activeTabIndicatorId}
                      show={!isPending && isActive && !showMoreButtonHighlight}
                    />
                  </div>
                )}
              </NavElement>
            </li>
          )
        })}
        <li
          className={cx('shrink-0 grow-0', {
            hidden: hiddenTabsFromIndex === null,
          })}
          ref={moreItemsButtonRef}
        >
          <MoreItemsButton
            activeTabIndicatorId={activeTabIndicatorId}
            showMoreMenu={showMoreMenu}
            setShowMoreMenu={setShowMoreMenu}
            highlight={showMoreButtonHighlight}
          />
        </li>
      </ul>
    </nav>
  )
}

function MoreItemsNav({
  secondaryTabNavRef,
  items,
  onNavigate,
  hiddenTabsFromIndex,
  showMoreMenu,
  setShowMoreMenu,
  setActiveTabIndex,
}: {
  secondaryTabNavRef: React.RefObject<HTMLDivElement>
  items: Prettify<NavigationTabItem>[]
  onNavigate?: (
    item: Prettify<NavigationTabItem>,
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => void
  hiddenTabsFromIndex: number | null
  showMoreMenu: boolean
  setShowMoreMenu: React.Dispatch<React.SetStateAction<boolean>>
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>
}) {
  React.useLayoutEffect(() => {
    function handleClickOutside(event: Event) {
      if (secondaryTabNavRef.current) {
        const child = event.target as HTMLElement
        const parent = child.parentElement
        const grandparent = parent?.parentElement
        if (
          [child?.id, parent?.id, grandparent?.id].includes(
            'nav-more-items-button'
          )
        ) {
          return // let MoreItemsButton handle its own clicks
        } else if (!secondaryTabNavRef.current.contains(event.target as Node)) {
          setShowMoreMenu(false)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [secondaryTabNavRef, setShowMoreMenu])

  return (
    <nav
      id="secondary-tab-nav-container"
      ref={secondaryTabNavRef}
      className={cx('relative z-10 block grow-0', {
        hidden: hiddenTabsFromIndex === null || !showMoreMenu,
      })}
    >
      <ul
        id="secondary-tab-nav"
        role="tablist"
        className="bg-neutral-0 shadow-3 absolute left-0 top-0 flex flex-col p-16"
      >
        {items.map((item, index) => {
          const dataTestId = item.testId
            ? `more-items-menu-${item.testId}`
            : `more-items-menu-tab-${item.label}`
          const outerClassName = cx(
            'shrink-0',
            hiddenTabsFromIndex === null || index < hiddenTabsFromIndex
              ? 'hidden'
              : ''
          )
          const NavElement = item.disabled
            ? ButtonWrappedDisabledNavLink({
                index,
                className: outerClassName,
                dataTestId,
              })
            : NavLink
          return (
            <NavElement
              key={index}
              to={item.link}
              onClick={(event) => {
                setActiveTabIndex(index)
                setShowMoreMenu(false)
                onNavigate?.(item, event)
              }}
              role="tab"
              data-testid={dataTestId}
              className={outerClassName}
            >
              {({
                isActive,
                isPending: _isPending,
              }: {
                isActive: boolean
                isPending: boolean
              }) => (
                <ListItem isSelected={isActive}>
                  <div
                    className={cx(
                      '-m-8 flex w-full grow gap-x-8 p-8', // tricks to ensure hover over any part of the item triggers hover effect
                      getItemStyle(isActive, item.disabled ?? false),
                      '!flex-row', // override flex-col default
                      item.disabled
                        ? 'hover:bg-neutral-0 cursor-text hover:!text-neutral-300'
                        : '!text-neutral-900 hover:!text-neutral-900'
                    )}
                  >
                    <ListItemContent item={item} />
                  </div>
                </ListItem>
              )}
            </NavElement>
          )
        })}
      </ul>
    </nav>
  )
}

function ActionButtons({
  buttons,
}: {
  buttons: Parameters<typeof NavigationTabs>[0]['buttons']
}) {
  return (
    <div className="ml-16 flex grow-0 gap-x-16">
      {buttons && !('primary' in buttons || 'secondary' in buttons)
        ? buttons
        : null}
      {buttons && 'secondary' in buttons && buttons.secondary ? (
        <Button
          onClick={buttons.secondary.onClick}
          disabled={buttons.secondary.disabled}
          loading={buttons.secondary.loading}
          variant="secondary"
          testId="tabs-secondary-button"
        >
          {buttons.secondary.children}
        </Button>
      ) : null}
      {buttons && 'primary' in buttons && buttons.primary ? (
        <Button
          onClick={buttons.primary.onClick}
          disabled={buttons.primary.disabled}
          loading={buttons.primary.loading}
          testId="tabs-primary-button"
        >
          {buttons.primary.children}
        </Button>
      ) : null}
    </div>
  )
}

function useClearAndRecalculateTabs({
  mainTabNavRef,
  moreItemsButtonRef,
  secondaryTabNavRef,
  items,
  setHiddenTabsFromIndex,
}: {
  mainTabNavRef: React.RefObject<HTMLDivElement>
  moreItemsButtonRef: React.RefObject<HTMLLIElement>
  secondaryTabNavRef: React.RefObject<HTMLDivElement>
  items: Prettify<NavigationTabItem>[]
  setHiddenTabsFromIndex: React.Dispatch<React.SetStateAction<number | null>>
}) {
  const { width: windowWidth } = useWindowSize()

  const clearHiddenTabs = React.useCallback(() => {
    if (mainTabNavRef?.current) {
      const tabEls = items.map((_, index) =>
        mainTabNavRef.current?.querySelector(
          `[data-main-tab-nav-item-index="${index}"]`
        )
      )
      tabEls.forEach((tabEl) => {
        if (tabEl) {
          tabEl.classList.remove('hidden')
        }
      })
    }
  }, [items, mainTabNavRef])

  const calculateTabsVisibility = React.useCallback(() => {
    if (mainTabNavRef?.current) {
      const mainNavBounds = mainTabNavRef.current.getBoundingClientRect()
      const tabElements = items.map((_, index) =>
        mainTabNavRef.current?.querySelector(
          `[data-main-tab-nav-item-index="${index}"]`
        )
      )

      const lastTab = tabElements[tabElements.length - 1]
      const allTabsFitWithinAvailableSpace =
        lastTab &&
        (lastTab?.getBoundingClientRect() as DOMRect).right <=
          mainNavBounds.width
      if (allTabsFitWithinAvailableSpace) {
        setHiddenTabsFromIndex(null)
        return
      } else {
        const moreItemsButtonBounds =
          moreItemsButtonRef.current?.getBoundingClientRect()

        const tabsToHide = tabElements.filter((tabElement) => {
          if (tabElement) {
            const MORE_ITEMS_BUTTON_DEFAULT_WIDTH = 70
            const TAB_GAP_WIDTH = 32
            const requiredSpaceForMoreItemsButton =
              (moreItemsButtonBounds?.width ??
                MORE_ITEMS_BUTTON_DEFAULT_WIDTH) + TAB_GAP_WIDTH
            return (
              (tabElement.getBoundingClientRect() as DOMRect).right >=
              mainNavBounds.width - requiredSpaceForMoreItemsButton
            )
          }
          return false
        })

        tabsToHide.forEach((tabElement, idx) => {
          tabElement?.classList.add('hidden')
          if (idx === 0) {
            const newHiddenTabsFromIndex = Number(
              tabsToHide[0]?.attributes?.[
                'data-main-tab-nav-item-index' as unknown as number
              ]?.value
            )
            setHiddenTabsFromIndex(newHiddenTabsFromIndex || 0)
          }
        })

        // place secondary nav under more items button
        const secondaryTabNav = secondaryTabNavRef.current
        if (secondaryTabNav) {
          const moreItemsButtonBounds =
            moreItemsButtonRef.current?.getBoundingClientRect()
          const secondaryTabNavBounds = secondaryTabNav.firstChild // secondaryTabNav has position absolute, so we need to get the first child to get the actual bounds
            ? (
                secondaryTabNav.firstChild as HTMLUListElement
              ).getBoundingClientRect()
            : null
          secondaryTabNav.style.position = 'absolute'
          secondaryTabNav.style.top = `${moreItemsButtonBounds?.height ?? 0}px`
          secondaryTabNav.style.left = `${
            -(mainNavBounds.left ?? 0) + // pull left by the left of main nav to account for main nav starting posiition
            (moreItemsButtonBounds?.left ?? 0) - // align left of secondary nav with left of more items button
            (secondaryTabNavBounds?.width ?? 0) + // move secondary nav to the left by its entire width
            (moreItemsButtonBounds?.width ?? 0) // move secondary nav to the right by the width of the more items button so that right edge of secondary nav aligns with right edge of more items button
          }px`
        }
      }
    }
  }, [
    items,
    mainTabNavRef,
    moreItemsButtonRef,
    secondaryTabNavRef,
    setHiddenTabsFromIndex,
  ])

  const clearAndRecalculateTabs = React.useCallback(() => {
    clearHiddenTabs()
    calculateTabsVisibility()
  }, [calculateTabsVisibility, clearHiddenTabs])

  const throttledClearAndRecalculateTabs = throttle(
    clearAndRecalculateTabs,
    100,
    { leading: false, trailing: true }
  )

  React.useEffect(() => {
    clearAndRecalculateTabs()
    if (windowWidth) {
      throttledClearAndRecalculateTabs()
    }
  }, [windowWidth, clearAndRecalculateTabs, throttledClearAndRecalculateTabs])
}
