import { useParams } from 'react-router-dom'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { useGetCourses } from '@cais-group/caisiq/domain/course'
import { useGetUserLicences } from '@cais-group/caisiq/domain/user'
import {
  CeCreditsListItem,
  renderLicenseNumberAndStatus,
} from '@cais-group/caisiq/ui/ce-credits-list'

import { CeCreditsSuccessPage } from './ce-credits-success-page'

export const CeCreditsSuccessPageContainer = () => {
  const { courseId } = useParams<{ courseId: string }>()
  const { data: courses } = useGetCourses()
  const course = courses?.find((course) => course.id === courseId)
  const { data, isFetched } = useGetUserLicences()

  const cimaNumber = data?.cima?.number || ''
  const cfpNumber = data?.cfp?.number || ''

  if (courses === undefined || course === undefined || !isFetched) {
    return (
      <LoadingContainer state={LoadingState.LOADING} coverPage="FULL_SCREEN" />
    )
  }

  // We only show credits for which the user has a valid license number.
  const rows: CeCreditsListItem[] = []
  if (cfpNumber.length && course.ce?.cfpCeCredit) {
    rows.push({
      type: 'cfp',
      credits: course.ce?.cfpCeCredit,
      licenseNumber: cfpNumber,
      renderRow: renderLicenseNumberAndStatus,
    })
  }
  if (cimaNumber.length && course.ce?.cimaCeCredit) {
    rows.push({
      type: 'cima',
      credits: course.ce?.cimaCeCredit,
      licenseNumber: cimaNumber,
      renderRow: renderLicenseNumberAndStatus,
    })
  }
  return <CeCreditsSuccessPage credits={rows} />
}
