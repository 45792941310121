import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { SetterOrUpdater } from 'recoil'

import { H4 } from '@cais-group/approved/ui/typography'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { BannerWithButtonContainer } from '@cais-group/caisiq/ui/banner-with-button'
import { ArticleCard } from '@cais-group/caisiq/ui/card/article'
import { CardCourseSkeleton } from '@cais-group/caisiq/ui/card/course'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { NoResults } from '@cais-group/caisiq-ui-no-results'
import { LayoutGrid } from '@cais-group/shared/ui/layout'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'
import { ExperienceData } from '@cais-group/shared/util/type/experience-data'

const StyledGrid = styled(LayoutGrid)`
  padding-top: var(--s24);
  padding-bottom: var(--s56);
  grid-gap: var(--s56) var(--s24);
`
StyledGrid.displayName = 'StyledGrid'

const EmptyMessage = styled(H4)`
  color: rgb(var(--colors-neutral-400));
  text-align: center;
  margin-top: var(--s144);
  margin-bottom: var(--s144);
`

const ArticlePage = styled.div<{ empty: boolean }>`
  padding-bottom: ${(props) => (props.empty ? 'var(--s144)' : '0')};
`

export type ArticlesProps = {
  maybeSearchFilteredArticles?: TypeArticleData[]
  setSearchTerm: SetterOrUpdater<string>
  searchTerm: string
  maybeFeaturedArticle?: TypeArticleData
  maybeSearchFilteredArticlesWithoutFeatured?: TypeArticleData[]
  experience: ExperienceData
}
export const Articles = (props: ArticlesProps) => {
  const {
    maybeSearchFilteredArticles,
    setSearchTerm,
    searchTerm,
    experience,
    maybeFeaturedArticle,
    maybeSearchFilteredArticlesWithoutFeatured,
  } = props

  const location = useLocation()

  return (
    <ArticlePage empty={maybeSearchFilteredArticles?.length === 0}>
      {!maybeSearchFilteredArticles ? (
        <StyledGrid>
          {Array.from(Array(10).keys(), (i) => (
            <CardCourseSkeleton key={i} />
          ))}
        </StyledGrid>
      ) : maybeSearchFilteredArticles?.length === 0 ? (
        searchTerm === '' ? (
          <EmptyMessage>There are no articles</EmptyMessage>
        ) : (
          <NoResults
            searchSetName="Articles"
            searchTerm={searchTerm}
            onClick={() => setSearchTerm('')}
          />
        )
      ) : (
        <>
          {maybeFeaturedArticle && (
            <ArticleCard
              isFeatured={
                experience.featuredArticle?.sys.id ===
                maybeFeaturedArticle.sys.id
              }
              key={maybeFeaturedArticle.sys.id}
              article={maybeFeaturedArticle}
              onClick={() =>
                trackingService.articleClicked(maybeFeaturedArticle, true)
              }
              to={ROUTES.article(maybeFeaturedArticle.slug)}
              state={{ from: `${location.pathname}` }}
            />
          )}
          <StyledGrid>
            {maybeSearchFilteredArticlesWithoutFeatured?.map((article) => (
              <ArticleCard
                key={article.sys.id}
                isFeatured={false}
                article={article}
                onClick={() => trackingService.articleClicked(article, false)}
                to={ROUTES.article(article.slug)}
                state={{ from: `${location.pathname}` }}
              />
            ))}
          </StyledGrid>
        </>
      )}
      <BannerWithButtonContainer />
    </ArticlePage>
  )
}
