import { useAuth0 } from '@auth0/auth0-react'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import { ApiPaths, Course } from '@cais-group/shared/util/type/caisiq-be'

export const useGetCourses = (options?: {
  pause?: boolean
  select?: (data: Array<Course>) => Array<Course>
}) => {
  const { isAuthenticated } = useAuth0()
  return useQuery<Array<Course>, AxiosError<Course>>({
    queryKey: ApiPaths.courses,
    enabled: isAuthenticated && !options?.pause,
    select: options?.select,
  })
}
