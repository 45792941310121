import { useGetDefaultCourseImageSet } from '@cais-group/caisiq/domain/course-image'
import { getNextFallbackImageIndex } from '@cais-group/caisiq/util/js/get-next-fallback-image-index'
import { TableCellWithOverflow } from '@cais-group/shared/ui/table-cell-with-overflow'

export const NameCell = ({ image, name }: { image?: string; name: string }) => {
  const courseImages = useGetDefaultCourseImageSet()
  const fallbackImageUrl = courseImages?.length
    ? courseImages[getNextFallbackImageIndex(name)].url
    : ''

  return (
    <TableCellWithOverflow
      columnWidth={326}
      className="flex items-center gap-16"
    >
      <img
        className="h-[calc(38px)] w-[calc(38px)] object-cover"
        src={image || fallbackImageUrl}
        alt="course"
      />
      <p className="label">{name}</p>
    </TableCellWithOverflow>
  )
}
