import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

export const customTableSort = (
  rowA: { values: { [x: string]: string } },
  rowB: { values: { [x: string]: string } },
  id: string
) => {
  dayjs.extend(duration)

  let a: string | number = 0,
    b: string | number = 0,
    now: dayjs.Dayjs
  switch (id) {
    case 'name':
      if (rowB.values['name']) {
        b = rowB.values['name']
        a = rowA.values['name']
      }

      if (a > b) return 1
      if (a < b) return -1
      return 0

    case 'email':
      a = rowA.values['email']
      b = rowB.values['email']

      if (a > b) return 1
      if (a < b) return -1
      return 0

    case 'totalStudyTimeMs':
      a = rowA.values[id] as unknown as number
      b = rowB.values[id] as unknown as number

      return a - b

    case 'assessmentScore':
      if (
        rowB.values['assessmentScore'] &&
        rowB.values['assessmentScore'] !== '-'
      ) {
        b = rowB.values['assessmentScore']
      } else {
        b = 0
      }
      if (
        rowA.values['assessmentScore'] &&
        rowA.values['assessmentScore'] !== '-'
      ) {
        a = rowA.values['assessmentScore']
      } else {
        a = 0
      }

      if (a > b) return 1
      if (a < b) return -1
      return 0

    case 'assessmentStatus':
      if (rowA.values['assessmentStatus']) {
        a = rowA.values['assessmentStatus']
      } else {
        a = 'a'
      }

      if (rowB.values['assessmentStatus']) {
        b = rowB.values['assessmentStatus']
      } else {
        b = 'a'
      }

      if (a > b) return 1
      if (a < b) return -1
      return 0

    case 'teams':
      if (rowA.values['teams'][0]) {
        a = rowA.values['teams'][0]
      } else {
        a = 'a'
      }

      if (rowB.values['teams'][0]) {
        b = rowB.values['teams'][0]
      } else {
        a = 'a'
      }

      if (a > b) return 1
      if (a < b) return -1
      return 0
    default:
      switch (id) {
        case 'assessmentDateCompleted':
          now = dayjs(new Date())
          if (rowA.values['assessmentDateCompleted']) {
            a = -dayjs
              .duration(
                now.diff(
                  dayjs(
                    new Date(
                      rowA.values[
                        'assessmentDateCompleted'
                      ] as unknown as string
                    )
                  )
                )
              )
              .asMilliseconds()
          }
          if (rowB.values['assessmentDateCompleted']) {
            b = -dayjs
              .duration(
                now.diff(
                  dayjs(
                    new Date(
                      rowB.values[
                        'assessmentDateCompleted'
                      ] as unknown as string
                    )
                  )
                )
              )
              .asMilliseconds()
          }

          break

        case 'learnedAt':
          now = dayjs(new Date())
          a = -dayjs
            .duration(
              now.diff(
                dayjs(new Date(rowA.values['learnedAt'] as unknown as string))
              )
            )
            .asMilliseconds()

          b = -dayjs
            .duration(
              now.diff(
                dayjs(new Date(rowB.values['learnedAt'] as unknown as string))
              )
            )
            .asMilliseconds()

          break

        case 'date':
          now = dayjs(new Date())
          a = -dayjs
            .duration(
              now.diff(
                dayjs(new Date(rowA.values['date'] as unknown as string))
              )
            )
            .asMilliseconds()

          b = -dayjs
            .duration(
              now.diff(
                dayjs(new Date(rowB.values['date'] as unknown as string))
              )
            )
            .asMilliseconds()

          break

        case 'lastStudied':
          now = dayjs(new Date())
          a = dayjs
            .duration(
              now.diff(dayjs(new Date(rowA.values[id] as unknown as string)))
            )
            .asMilliseconds()

          b = dayjs
            .duration(
              now.diff(dayjs(new Date(rowB.values[id] as unknown as string)))
            )
            .asMilliseconds()
      }

      if (!Number.isNaN(a) && !Number.isNaN(b)) {
        if (a > b) return 1
        if (a < b) return -1
        return 0
      }
      if (!Number.isNaN(a)) {
        return 1
      }
      if (!Number.isNaN(b)) {
        return -1
      }
      return a - b
  }
}
