import { useEffect, useState } from 'react'

/**
 * Simple hooks for debouncing state
 * @param value
 * @param delay ms
 * @returns
 */
export function useDebouncedState<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])
  return debouncedValue
}
