import { useRecoilState } from 'recoil'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { useGetBaseCoursesMetadata } from '@cais-group/caisiq/domain/manage-caisiq'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import {
  globalCoursesColumns,
  filterBaseCoursesMetadataBySearch,
} from '@cais-group/caisiq/ui/manage/global-courses-table'

import { ManageCourses } from './caisiq-feature-manage-courses'

export const ManageCoursesContainer = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)

  const { data: coursesData, isFetched } = useGetBaseCoursesMetadata()
  const filteredCourses = filterBaseCoursesMetadataBySearch(
    searchTerm,
    coursesData ?? []
  )

  if (!coursesData || !isFetched) {
    return <LoadingContainer state={LoadingState.LOADING} />
  }

  return (
    <ManageCourses
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      isFetched={isFetched}
      tableOptions={{
        columns: globalCoursesColumns,
        data: filteredCourses ?? [],
      }}
    />
  )
}
