import { OrderedFieldsetState } from './shared-ui-ordered-fieldset'

export const deriveState = (
  order: number,
  step: number | undefined
): OrderedFieldsetState | undefined => {
  switch (true) {
    case step && order < step:
      return 'complete'
    case order === step:
      return 'active'
    case step && order > step:
      return 'inactive'
    case step === undefined:
    default:
      return undefined
  }
}
