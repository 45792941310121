import { CourseMetadata } from '@cais-group/shared/util/type/caisiq-be'

export const filterExperienceCoursesBySearch = (
  searchTerm: string,
  courses?: CourseMetadata[]
) => {
  return courses?.filter((c) => {
    if (searchTerm === '') return true

    const target = searchTerm.toLowerCase()
    return (
      c.name?.toLowerCase().includes(target) ||
      c.theme?.toLowerCase()?.includes(target)
    )
  })
}
