import { ReactNode } from 'react'

export interface WithMaxHeightProps {
  children: ReactNode
  className?: string
}

export const WithMaxHeight = ({
  children,
  className = 'max-h-376',
}: WithMaxHeightProps) => (
  <div className={`${className} overflow-y-auto`}>{children}</div>
)
