import { datadogRum } from '@datadog/browser-rum'
import { AxiosError } from 'axios'

import { getEnvConfig } from '@cais-group/caisiq-ui-env'
import { showToast } from '@cais-group/equity/organisms/notifications'

interface ErrorHandlerFactoryConfig {
  message?: string
  sendToDatadog?: boolean
}

type ErrorHandlerFactory = (config?: ErrorHandlerFactoryConfig) => ErrorHandler

type ErrorHandler = (e: AxiosError<unknown>) => void

export function useApiErrorHandler(): ErrorHandlerFactory {
  const { ENVIRONMENT } = getEnvConfig()

  return (config) => {
    const {
      message = 'An error occurred, some features may be unavailable.',
      sendToDatadog = true,
    } = config || {}

    return (error: AxiosError<unknown>) => {
      if (ENVIRONMENT !== 'production') {
        console.error(
          `API error occurred when retrieving endpoint (${error.response?.config?.url}).`,
          error,
          error.response?.status
        )
      }
      if (sendToDatadog) {
        datadogRum.addError(error, {
          appName: 'CaisIQ',
          type: 'ApiError',
          response: error.response,
        })
      }
      showToast({
        type: 'error',
        title: message,
      })
    }
  }
}
