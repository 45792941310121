import styled from '@emotion/styled'
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
} from '@tanstack/react-table'

import { ManageTableProps } from '@cais-group/caisiq/util/type/manage-caisiq'
import { SPACING_UNITS } from '@cais-group/shared/ui/design-tokens'
import {
  SkeletonRows,
  Table,
  TableHead,
  Row as TableRow,
  RowCells,
  TBody,
} from '@cais-group/shared/ui/react-table-v8'
import { CaisIqFirmWithChildren } from '@cais-group/shared/util/type/caisiq-be'

import { Scrollable } from './components/Scrollable'
import { TableNoResults } from './components/TableNoResults'

const StyledTableRows = styled.div<{ unclickable?: boolean }>`
  * > div {
    display: flex;
    align-items: center;
    // prevents hovering on rows that aren't clickable
    cursor: ${({ unclickable }) => (unclickable ? 'default' : 'pointer')};
  }

  * > div.table-cell:last-of-type {
    justify-content: flex-end;
  }
`

export function ManageCaisIqTable<TData extends Record<string, unknown>>({
  tableType,
  tableOptions,
  fetchNextPage,
  hasNextPage,
  unclickable,
  getRowCanExpand,
  getSubRows,
  searchTerm,
  setSearchTerm,
  isFetched,
  isRowLoading,
  onRowClick,
  noScroll = false,
  classNames,
  /**
   * @todo waiting to hear from design if we want to use the shared/approved fallback for no results
   * in all Manage caisiq tables
   */
  noResults = (
    <TableNoResults
      searchTerm={searchTerm ?? ''}
      tab={tableType ?? ''}
      setSearchTerm={setSearchTerm}
    />
  ),
  helperText,
}: ManageTableProps<TData> & {
  // @TODO: Clean up props and see if they can be part of the ManageTableProps.
  tableType?:
    | 'firms'
    | 'courses'
    | 'experiences'
    | 'course-configuration'
    | 'experience-firms'
  addPadding?: boolean
  isFetching?: boolean
  getRowCanExpand?: (row: Row<CaisIqFirmWithChildren>) => boolean
  getSubRows?: (
    originalRow: CaisIqFirmWithChildren,
    index: number
  ) => CaisIqFirmWithChildren[] | undefined
  isRowLoading?: Record<string, boolean>
  unclickable?: boolean
  params?: {
    id?: string
    experienceId?: string
  }
}) {
  const { getHeaderGroups, getRowModel } = useReactTable<TData>({
    ...tableOptions,
    //@ts-ignore
    getRowCanExpand,
    //@ts-ignore
    getSubRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  const { columns, data } = tableOptions

  const handleRowClick = (
    row: Row<TData>,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (onRowClick) {
      return onRowClick(row.original)
    }
  }

  const TableBody = () => {
    if (data.length === 0 && searchTerm !== '' && isFetched) {
      return noResults
    } else {
      return (
        <TBody>
          {!data.length ? (
            <div data-testid="experiences-skeleton-rows">
              <SkeletonRows count={15} columns={columns as []} />
            </div>
          ) : (
            <>
              {getRowModel().rows.map((row) => {
                return (
                  <StyledTableRows
                    key={row.id}
                    unclickable={unclickable}
                    className={classNames?.rows}
                  >
                    <TableRow
                      key={row.id}
                      onClick={(e) => handleRowClick(row, e)}
                      className="table-row-item"
                      $hoverBg
                      $selectedBg={row.getIsSelected()}
                      data-testid={`table-row-${row.id}`}
                    >
                      <RowCells row={row} />
                    </TableRow>
                    {isRowLoading && isRowLoading[row.id] && (
                      <div
                        key={`${row.original['uuid']}`}
                        style={{
                          paddingLeft: `${
                            (row.depth + 1) * SPACING_UNITS.s32
                          }px`,
                        }}
                      >
                        <SkeletonRows count={3} columns={columns as []} />
                      </div>
                    )}
                  </StyledTableRows>
                )
              })}
            </>
          )}
        </TBody>
      )
    }
  }
  return (
    <div data-testid={`${tableType}-table`} className={classNames?.container}>
      <Scrollable
        scrollToBottomCallback={() =>
          hasNextPage && fetchNextPage && fetchNextPage()
        }
      >
        <Table data-testid={`${tableType}-table`} noScroll={noScroll}>
          <TableHead<TData> headerGroups={getHeaderGroups()} variant="dark" />
          <TableBody />
        </Table>
      </Scrollable>
      {helperText && helperText}
    </div>
  )
}
