import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'

interface Context
  // deeply nested objects don't seem to get sent to datadog correctly, so we need to flatten them/JSON.stringify them
  extends Record<string, string | number | boolean | undefined | null> {
  notifyImmediately?: boolean
}

type LogArgs = {
  message: string
  context?: Context
  error?: unknown
}

export function logError({ message, context, error }: LogArgs) {
  const trackedError = formatError(error)

  datadogRum.addError(error || message, { ...context, errorMessage: message })
  datadogLogs.logger.error(message, context, trackedError)
}

export function logWarning({ context, message, error }: LogArgs) {
  const trackedError = formatError(error)

  datadogLogs.logger.warn(message, context, trackedError)
}

export function useSetGlobalContext(context: Record<string, string>) {
  useEffect(() => {
    datadogLogs.setGlobalContext(context)
  }, [context])
}

function formatError(error: unknown): Error | undefined {
  if (!error) {
    return undefined
  } else if (error instanceof Error) {
    return error
  } else if (typeof error === 'string') {
    // datadog doesn't support sending strings as errors, so we need to wrap in an Error
    return new Error(error)
  } else {
    return new Error(JSON.stringify(error))
  }
}
