import cx from 'classnames'
import * as React from 'react'
import { ForwardedRef, useId } from 'react'

import { getBackgroundColor, getBorderColor } from './styles'
import { RadioColor } from './types'

/**
 * Props for the RadioGroup component.
 * This component is a wrapper around a group of radio buttons.
 */
export type RadioGroupProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'value' | 'children' | 'style' | 'className'
> & {
  /**
   * The value of the selected radio button.
   */
  value?: string | number | readonly string[] | undefined
  /**
   * The color of the radio buttons.
   */
  color?: RadioColor
  /**
   * The child components of the RadioGroup component.
   */
  children: React.ReactElement<typeof Option>[]
}

/**
 * Props for the forwardRef function returned by the RadioGroup component.
 */
interface RadioGroupRefProps
  extends React.ForwardRefExoticComponent<
    RadioGroupProps & React.RefAttributes<HTMLInputElement>
  > {
  /**
   * The Option component that is used as a child of RadioGroup.
   */
  Option: typeof RadioGroupOption
}

/**
 * A wrapper around a group of radio buttons.
 */
export const RadioGroup = React.forwardRef(
  (props: RadioGroupProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { children, value: selectedValue, ...rest } = props
    const childProps = { ...rest }

    return (
      <div role="radiogroup" className="flex flex-col gap-y-8" ref={ref}>
        {React.Children.map(children, (child, index) => {
          const radioChild =
            child as unknown as React.ReactElement<RadioGroupOptionProps>
          return (
            React.isValidElement(radioChild) &&
            React.cloneElement(radioChild, {
              ...childProps,
              key: index,
              ...(selectedValue !== undefined &&
              childProps.checked === undefined
                ? { checked: selectedValue === radioChild.props.value }
                : {}),
            })
          )
        })}
      </div>
    )
  }
) as RadioGroupRefProps

/**
 * Props for the RadioGroupOption component.
 * This component represents a single radio button option in a RadioGroup.
 */
type RadioGroupOptionProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'value' | 'children' | 'style' | 'className'
> & {
  /**
   * The value of the radio button option.
   */
  value: string | number | readonly string[] | undefined
  /**
   * The value of the currently selected radio button in the RadioGroup component.
   */
  selectedValue?: string | number | boolean
  /**
   * The color of the radio button option.
   */
  color?: RadioColor
  /**
   * Whether we should show option in a "box"
   */
  hasBorder?: boolean
  /**
   * The label for the radio button option.
   */
  children: string | React.ReactNode
}

/**
 * A single radio button option in a RadioGroup.
 */
const RadioGroupOption = React.forwardRef(
  (props: RadioGroupOptionProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      children,
      value,
      color = 'primary',
      disabled,
      selectedValue,
      hasBorder = false,
      ...rest
    } = props
    const id = useId()
    const isChecked = selectedValue === value

    return (
      <label
        className={cx('small flex items-center justify-start gap-x-16', {
          'h-full w-full border py-12 pl-16 pr-32': hasBorder,
          'border-neutral-200': hasBorder && !isChecked,
          'border-brand-500 bg-brand-100': hasBorder && isChecked,
        })}
        htmlFor={rest.id ?? id}
      >
        <div className="group relative h-16 w-16 shrink-0 grow-0">
          <input
            className={cx(
              'peer absolute inset-0 z-10 m-0 h-16 w-16 opacity-0',
              {
                'cursor-not-allowed': disabled,
                'cursor-pointer': !disabled,
              }
            )}
            type="radio"
            disabled={disabled}
            value={value}
            ref={ref}
            id={id}
            checked={props.onChange ? isChecked : undefined}
            aria-checked={isChecked}
            {...rest}
          />
          <div
            className={cx(
              'absolute flex h-16 w-16 items-center justify-center rounded-full border border-solid',
              {
                'peer-disabled:bg-neutral-100': disabled,
              },
              getBorderColor(color)
            )}
          />
          <div
            className={cx(
              'absolute left-[50%] top-[50%] flex h-8 w-8 translate-x-[-50%] translate-y-[-50%]  items-center justify-center rounded-full',
              getBackgroundColor(color)
            )}
          />
        </div>
        {children}
      </label>
    )
  }
)

RadioGroup.Option = RadioGroupOption
