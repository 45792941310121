import styled from '@emotion/styled'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useMemo } from 'react'

import { Icon } from '@cais-group/equity/atoms/icon'

const PaginationContainer = styled.ul`
  display: flex;
  align-items: center;
`

const PaginationButton = styled.li`
  margin: var(--s8) 0 var(--s8) 0;
  padding: var(--s8) var(--s16);
  cursor: pointer;
`

const PaginationItem = styled.li<{ active?: boolean }>`
  margin: var(--s8) 0 var(--s8) 0;
  padding: var(--s8) var(--s16);
  cursor: pointer;
  background-color: ${({ active }) =>
    active ? 'var(--color-black)' : 'transparent'};
  color: ${({ active }) =>
    active ? 'var(--color-white)' : 'var(--color-gray-64)'};
`

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  color: rgb(var(--colors-neutral-600));
`

const FakePaginationItem = styled.li<{ active?: boolean }>`
  margin: var(--s8) 0 var(--s8) 0;
  padding: var(--s8) var(--s16);
  background-color: transparent;
  color: white;
`

export type PaginationProps = {
  maxLinks?: number
  pages: number
  currentPage: number
  onPageSelect: (page: number) => void
}

const range = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export const Pagination = (props: PaginationProps) => {
  const { maxLinks = 5, pages, currentPage, onPageSelect } = props
  const aroundPagesNumber = 1
  const DOTS = pages >= 50 ? pages + 1 : 50
  const paginationRange = useMemo(() => {
    if (pages <= maxLinks) {
      return range(1, pages)
    }
    const leftPageIndex = Math.max(currentPage - aroundPagesNumber, 1)
    const rightPageIndex = Math.min(currentPage + aroundPagesNumber, pages)
    const showLeftEllipsis = leftPageIndex > 2
    const showRightEllipsis = rightPageIndex < pages - 2
    const firstPageIndex = 1
    const lastPageIndex = pages

    if (!showLeftEllipsis && showRightEllipsis) {
      const leftItemCount = 3 + 2 * aroundPagesNumber
      const leftRange = range(1, leftItemCount)
      return [...leftRange, DOTS, pages]
    }

    if (showLeftEllipsis && !showRightEllipsis) {
      const rightItemCount = 3 + 2 * aroundPagesNumber
      const rightRange = range(pages - rightItemCount + 1, pages)

      return [firstPageIndex, DOTS, ...rightRange]
    }

    if (showLeftEllipsis && showRightEllipsis) {
      const middleRange = range(leftPageIndex, rightPageIndex)

      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }

    if (!showLeftEllipsis && !showRightEllipsis) {
      const middleRange = range(leftPageIndex, rightPageIndex)

      return [firstPageIndex, ...middleRange, lastPageIndex]
    }

    return
  }, [pages, maxLinks, currentPage, DOTS])

  return (
    <PaginationContainer>
      {currentPage !== 1 ? (
        <PaginationButton onClick={() => onPageSelect(currentPage - 1)}>
          <StyledChevronLeftIcon />
        </PaginationButton>
      ) : (
        <FakePaginationItem />
      )}

      {paginationRange?.map((pageNumber, i) => {
        if (pageNumber === DOTS) {
          return (
            <PaginationItem key={`${pageNumber}-${i}`}>&#8230;</PaginationItem>
          )
        }
        return (
          <PaginationItem
            active={pageNumber === currentPage}
            onClick={() => onPageSelect(pageNumber)}
            key={`${pageNumber}-${i}`}
          >
            {pageNumber}
          </PaginationItem>
        )
      })}
      {currentPage !== pages ? (
        <PaginationButton onClick={() => onPageSelect(currentPage + 1)}>
          <Icon type="KeyboardArrowRight" />
        </PaginationButton>
      ) : (
        <FakePaginationItem />
      )}
    </PaginationContainer>
  )
}
