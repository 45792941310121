import { Auth0Provider } from '@auth0/auth0-react'
import { ReactNode } from 'react'

import { getEnvConfig } from '@cais-group/shared/ui/env'
import {
  AUTH0_RETURN_TO,
  authService,
} from '@cais-group/shared/util/auth-service'

export const DOM_EVENT_USER_SIGNED_IN = 'usersignedin'

export type Auth0ProviderWithHistoryProps = {
  children: ReactNode
}

// This is only used during Cypress tests
const sessionStorageCache = {
  get: function (key: string): string | null {
    const storageItem = sessionStorage.getItem(key)
    return storageItem ? JSON.parse(storageItem) : null
  },
  set: function (key: string, value: string) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  remove: function (key: string) {
    sessionStorage.removeItem(key)
  },
}

export const Auth0ProviderWithHistory = ({
  children,
}: Auth0ProviderWithHistoryProps) => {
  const envConfig = getEnvConfig()

  // There is an issue where you can get stuck on the auth-redirect page
  // if the container switches version and the user doesn't reload the page, but signs out and in
  const cancelAuthRedirectExit = setTimeout(() => {
    if (window.location.href.includes('auth-redirect')) {
      // if we are still on the auth-redirect page, we go to the homepage
      window.location.assign(
        `${window.location.origin}/homepage${window.location.search}`
      )
    }
  }, 1500)

  const onRedirectCallback = (): void => {
    // I initially tried to pass a callback prop to handle the login event,
    // but the analytics context (SegmentProvider) was not available in the callback.
    //
    // I'm decoupling this by just dispatching a custom event that is listened for in the analytics module.
    // This is a bit of a hack, but it works.
    const returnTo = authService.getAuthReturnTo()
    const navigateTo = returnTo || window.location.search

    sessionStorage.removeItem('sessionId')
    setTimeout(() => {
      document.dispatchEvent(new Event(DOM_EVENT_USER_SIGNED_IN))
    }, 1_500)

    authService.resetSessionStart()

    // Not redirecting if the app is the same
    const [, appBasePath] = window.location.pathname.split('/')
    const [, navigateToBasePath] = navigateTo.split('/')
    if (appBasePath.includes(navigateToBasePath)) return

    // Preventing race condition with the auth-redirect exit
    clearInterval(cancelAuthRedirectExit)

    // We can't rely on the router to do this redirect because we still can land on either the container or an app
    // meaning that if we were to land in the container and redirect to an app without module federation, it will break.
    // Until we get rid of the container, we are going to .assign the next route in
    window.location.assign(`${window.location.origin}${navigateTo}`)
  }

  const sessionId = sessionStorage.getItem('sessionId')
  const [, appBasePath] = window.location.pathname.split('/')

  // we just want to set this once (if empty).
  // Otherwise on the way back from Auth0, we will set it again right before the onRedirectCallback runs
  if (
    !authService.getAuthReturnTo() ||
    sessionStorage.getItem(AUTH0_RETURN_TO)?.includes('auth-redirect')
  ) {
    authService.setAuthReturnTo()
  }

  return envConfig ? (
    <Auth0Provider
      domain={envConfig.AUTH0_DOMAIN}
      clientId={envConfig.AUTH0_CLIENT_ID}
      authorizationParams={{
        authorizationParams: sessionId
          ? JSON.stringify({ session_id: sessionId })
          : JSON.stringify({}),
        audience: envConfig.AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}/${
          __NX_LONE__ ? appBasePath : `auth-redirect?app=${appBasePath}`
        }`,
      }}
      // Callback to call once user arrives at the above redirectUri to redirect to specific page and query string
      onRedirectCallback={onRedirectCallback}
      // When Cypress tests are running, swap the default inMemory cache for sessionStorageCache so we can manipulate the state
      // @ts-ignore
      cache={window.Cypress ? sessionStorageCache : undefined}
    >
      {children}
    </Auth0Provider>
  ) : null
}
