import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios'

import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { getEnvConfig } from '@cais-group/caisiq-ui-env'
import { authService } from '@cais-group/shared/util/auth-service'
import { addCloudflareChallengeToAxiosInstance } from '@cais-group/shared/util/cloudflare-interceptor'

let axiosApiInstance: AxiosInstance

export function useAxiosInstance(): AxiosInstance {
  return getAxiosInstance()
}

export const getAxiosInstance = () => {
  axiosApiInstance = axios.create({ withCredentials: true })

  const envConfig = getEnvConfig()

  axiosApiInstance.interceptors.request.use(async (config) => {
    let authToken
    try {
      authToken = await authService.getAccessTokenSilently()
    } catch (e) {
      console.error(e)
    }

    // Add experience if it is defined
    const experienceParam = experienceService.isExperienceOverride()
      ? { experience: experienceService.getExperience() }
      : {}

    const conf = {
      ...config,
      params: {
        ...config.params,
        ...experienceParam,
      },
      headers: {
        ...config.headers,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      } as AxiosRequestHeaders,
      baseURL: envConfig.CAISIQ_API_HOST,
    }

    return conf
  })

  addCloudflareChallengeToAxiosInstance(envConfig, axiosApiInstance)

  return axiosApiInstance
}
