import { isData } from '@cais-group/caisiq/domain/common'
import { useGetVideoPlaylist } from '@cais-group/caisiq/domain/video'
import { User } from '@cais-group/shared/util/type/caisiq-be'

import { VideoHeader } from './react-ui-video-header'

export type VideoHeaderContainerProps = {
  playlistId: string
  backgroundImageUrl?: string
  userProfile?: User
}

export const VideoHeaderContainer = (props: VideoHeaderContainerProps) => {
  const { playlistId, userProfile, backgroundImageUrl } = props

  const videoPlaylistApiState = useGetVideoPlaylist(playlistId)

  return isData(videoPlaylistApiState) ? (
    <VideoHeader
      userProfile={userProfile}
      playlist={videoPlaylistApiState}
      backgroundImageUrl={backgroundImageUrl}
    />
  ) : null
}
