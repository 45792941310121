import { MouseEvent, PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type TableCellWithOverflowProps = {
  columnWidth: number
  className?: string
  childClassName?: string
}

type OnMouseEvent = MouseEvent<HTMLDivElement, globalThis.MouseEvent>

/**
 * Table cells are absolutely positioned and have their text truncated if it is
 * too long.
 */
const baseClasses = [
  'absolute',
  'inset-y-0',
  'h-full',
  'py-16',
  'pr-24',
  'truncate',
  'w-[calc(100%-24px)]', // Shared .table-cell has 24px padding-left on the first 2 cols
]
/**
 * The element gets overflow:visible and is bumped up the z-index on hover.
 * This makes it 'overflow' over the top of its neighbouring cell.
 */
const hoverClasses = [
  'hover:w-max',
  'hover:overflow-visible',
  'hover:z-10',
  'hover:pr-24',
]
/**
 * Adds a border-right on hover. This is added with JS.
 */
const borderRightClasses = [
  'hover:border-r-1',
  'hover:border-neutral-200',
  'hover:border-0',
  'hover:border-solid',
]

/**
 * The only bit not possible with pure CSS: adding the border-right to elements that
 * have overflowed.
 * This only runs when the element is hovered over, so should be pretty performant.
 */
function addBorderIfOverflowed(width: number) {
  return function handler(e: OnMouseEvent) {
    if (e.currentTarget.classList.contains(borderRightClasses[0])) {
      return
    }
    // The shared .table-cell has some styles that can change the final width of the
    // column, so we need to try to get that if possible.
    const parent = e.currentTarget.closest('.table-cell') as HTMLDivElement
    const columnWidth = parent?.offsetWidth ?? width

    // If the width of the cell is greater than the col width, show the border.
    if (Math.round(e.currentTarget.scrollWidth) > columnWidth) {
      e.currentTarget.classList.add(...borderRightClasses)
    }
  }
}

/**
 * The text in the cell will be truncated (i.e. ellipses added) if it
 * doesn't fit in the column width. On hover, truncated cells overflow
 * so the user can read them.
 */
export function TableCellWithOverflow({
  columnWidth,
  className: additionalStyles,
  children,
}: PropsWithChildren<TableCellWithOverflowProps>) {
  const onMouseOver = addBorderIfOverflowed(columnWidth)
  return (
    <div className="bg-inherit">
      &nbsp;{/* This preserves the height of the table cell */}
      <div
        onMouseOver={onMouseOver}
        className={twMerge(
          'bg-inherit',
          baseClasses.join(' '),
          hoverClasses.join(' '),
          additionalStyles || ''
        )}
      >
        {children}
      </div>
    </div>
  )
}
