import { MouseEvent, useEffect } from 'react'

import { ModalLayout, Variant } from '@cais-group/approved/ui/modal/layout'
import { BodySmall } from '@cais-group/approved/ui/typography'

type SummaryType = {
  body: string
  title: string
}
type ButtonType = {
  variant?: Variant
  text: string
}
export type EnableDisableExperienceModalType = {
  summary: SummaryType
  primaryButton: ButtonType
  secondaryButton?: ButtonType
}

type EnableDisableExperienceModalPropTypes = {
  open?: boolean
  onClose: () => void
  onConfirm: () => void
  onConfirmWithoutEnable?(): void
  options: EnableDisableExperienceModalType
}
export function EnableDisableExperienceModal({
  open,
  onClose,
  onConfirm,
  options,
  onConfirmWithoutEnable,
}: EnableDisableExperienceModalPropTypes) {
  const handleClosedModal = (e: globalThis.MouseEvent | MouseEvent) => {
    e.stopPropagation()
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClosedModal, false)
    }
    return () => {
      document.removeEventListener('click', handleClosedModal, false)
    }
  }, [open])

  return (
    <div onClick={handleClosedModal}>
      <ModalLayout
        open={Boolean(open)}
        onClose={onClose}
        title={options.summary.title || ''}
        actions={[
          {
            variant: 'secondary',
            handler: () =>
              onConfirmWithoutEnable ? onConfirmWithoutEnable() : onClose(),
            text: options.secondaryButton?.text || 'Cancel',
          },
          {
            /**
             * @todo The equity team will be adding a variant that allows green buttons
             */
            variant: options.primaryButton.variant ?? 'primary',
            handler: () => {
              onConfirm()
            },
            text: options.primaryButton.text,
            testId: 'confirm-enable-disable-experience',
          },
        ]}
        body={<BodySmall as="span">{options.summary.body}</BodySmall>}
      />
    </div>
  )
}
