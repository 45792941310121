import { AxiosError } from 'axios'
import { useState } from 'react'
import { useQuery } from 'react-query'

import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import {
  HandleCloseTabType,
  PreviewBanner,
} from '@cais-group/shared/ui/contentful/preview-banner'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  ExperienceResponse,
  ApiPaths,
} from '@cais-group/shared/util/type/caisiq-be'

import { ConfirmPreviewCoursesExperienceModalContainer } from './components/caisiq-ui-preview-experience-courses-modal'

export function PreviewBannerContainer() {
  const [isOpen, setIsOpen] = useState(false)
  const showDeleteSwitchTab = Boolean(
    previewService.enabledFirm &&
      userSettingsService.user.experience.contentfulId ===
        previewService.enabledFirm
  )
  /**
   * @note I figure this is all ok to use if the user has ADMIN role but possibly not caisiq:manage:read
   */
  const { data: experiences } = useQuery<ExperienceResponse[], AxiosError>(
    ApiPaths.getAllExperiences,
    {
      enabled: Boolean(previewService.enabledFirm && previewService.enabled),
    }
  )

  const previewedExperience = experiences?.find(
    (exp) => exp.contentfulId === previewService.enabledFirm
  )

  const onHandleCloseTab: HandleCloseTabType = {
    openModal: () => setIsOpen(true),
    onExit: previewService.turnOff,
  } as const
  if (!previewService.enabled) {
    return null
  }
  return (
    <>
      <PreviewBanner
        onHandleCloseTab={onHandleCloseTab}
        isFirmExperience={Boolean(previewedExperience?.catalog)}
        showDeleteSwitchTab={showDeleteSwitchTab}
      />
      <ConfirmPreviewCoursesExperienceModalContainer
        isOpen={isOpen}
        onOpen={setIsOpen}
        showDeleteSwitchTab={showDeleteSwitchTab}
        previewedExperience={previewedExperience}
      />
    </>
  )
}
