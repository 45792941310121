import { useGetDefaultFundThumbnailQuery } from '@cais-group/caisiq/util/graphql/contentful'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { DefaultFundThumbnail } from '@cais-group/shared/util/type/fund'

import { FUND_DEFAULT_THUMBNAIL_SET_ID } from './config'

export const useGetDefaultFundThumbnailSet = (options?: {
  paused: boolean
}) => {
  const result = useGetDefaultFundThumbnailQuery(
    {
      id: FUND_DEFAULT_THUMBNAIL_SET_ID,
      preview: previewService.enabled,
    },
    { enabled: !!options?.paused }
  )

  return {
    ...result,
    data: [
      ...(result.data?.caisiqFundThumbnailSet?.thumbnailCollection?.items ??
        []),
    ] as DefaultFundThumbnail[],
  }
}
