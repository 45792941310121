export { ReactComponent as LogoCaisIqDark } from '../assets/caisiq_logo_dark.svg'

export { ReactComponent as LogoCaisIqLight } from '../assets/caisiq_logo_light.svg'

export { ReactComponent as LogoCais } from '../assets/cais_logo.svg'

export { ReactComponent as LogoPoweredByCais } from '../assets/powered-by-cais.svg'

export { ReactComponent as LogoPoweredByCaisStacked } from '../assets/powered-by-cais-stacked.svg'

export { ReactComponent as CaisiqLogo } from '../assets/caisiq-logo.svg'
