type DateTypes = Date | string | [year: number, month: number, day: number]

const toDate = (date: DateTypes): Date => {
  if (date instanceof Date) return date

  if (Array.isArray(date)) {
    const [year, month, day] = date
    return new Date(year, month - 1, day)
  }

  return new Date(date)
}

/**
 * Formats dates in UTC time zone as 'MM/DD/YYYY'.
 * UTC is enforced since backend dates are stored in UTC.
 * https://github.com/cais-group/architecture-decision-records/blob/main/0009-using-utc-timezone.md
 */
export const formatDate = (date: DateTypes): string => {
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
  })

  return dateFormatter.format(toDate(date))
}
