import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { H5Strong } from '@cais-group/approved/ui/typography'
import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import { useGetManageCaisiqCoursesByCatalogId } from '@cais-group/caisiq/domain/manage-caisiq'
import { useContentfulNameFromIdMapper } from '@cais-group/caisiq/util/contentful/use-contentful-name-from-id-mapper'
import { transformExperienceToList } from '@cais-group/caisiq/util/manage/transform-data-to-list-items'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import {
  ExperienceFields,
  ExperienceListBox,
  FormStepProps,
} from '@cais-group/caisiq/util/type/manage-caisiq'
import { DisabledList } from '@cais-group/shared/ui/disabled-list'
import { TextInput } from '@cais-group/shared/ui/input/text'
import {
  OrderedFieldset,
  StepNavigation,
  useStepState,
} from '@cais-group/shared/ui/ordered-fieldset'
import { SelectBox, SelectOption } from '@cais-group/shared/ui/select-box'
import { UserRole } from '@cais-group/shared/util/type/caisiq-be'

import { usePopulateLabelOnNameChange } from './hooks/use-populate-label-on-name-change'

interface ExperienceNameStepProps extends FormStepProps {
  defaultLabel?: string
  experiences?: ExperienceListBox
  legend?: string
  contentfulId?: string
}

function useSelectContentfulExperienceOnLabelChange(
  experiences: ExperienceListBox
) {
  const { watch, setValue, getValues } = useFormContext()

  const label = watch('label')

  const found = experiences?.find((e) => e?.firmId === label)

  if (found && getValues('contentfulExperience') !== found.firmId) {
    setValue('contentfulExperience', found.firmId)
  }
}

function useUpdateCourseConfigTable() {
  const { resetField, setValue, formState, watch } =
    useFormContext<ExperienceFields>()

  const catalogId = watch('catalogId')
  const {
    data: coursesByCatalogId,
    isFetched,
    isFetching,
  } = useGetManageCaisiqCoursesByCatalogId({
    catalogId: catalogId,
    enabled:
      Boolean(catalogId) &&
      Boolean(formState.dirtyFields.catalogId) &&
      formState.defaultValues?.catalogId !== catalogId,
  })

  useEffect(() => {
    if (isFetching) {
      setValue('courses', [])
    } else if (isFetched) {
      if (
        coursesByCatalogId &&
        // For some reason after updating an experience, the coursesByCatalogId is still cached so added secondary conditional
        formState.defaultValues?.catalogId !== catalogId
      ) {
        setValue('courses', coursesByCatalogId)
      } else {
        resetField('courses')
      }
    }
  }, [
    catalogId,
    coursesByCatalogId,
    formState.defaultValues?.catalogId,
    isFetched,
    isFetching,
    resetField,
    setValue,
  ])
}

export function ExperienceNameStep({
  defaultLabel,
  experiences,
  order,
  legend,
  formLabels,
}: ExperienceNameStepProps) {
  const { control, trigger, getValues } = useFormContext()

  const getContentfulNameById = useContentfulNameFromIdMapper()

  usePopulateLabelOnNameChange(defaultLabel)
  useSelectContentfulExperienceOnLabelChange(experiences || [])
  useUpdateCourseConfigTable()

  const { deriveState, showStep } = useStepState()
  const state = deriveState(order)

  const details = transformExperienceToList({
    name: getValues('name'),
    label: getValues('label'),
    contentfulName: getContentfulNameById(getValues('contentfulId')),
  })

  return (
    <OrderedFieldset
      legend={
        legend !== undefined ? (
          <H5Strong id="experience-name" className="scroll-mt-144">
            {legend}
          </H5Strong>
        ) : undefined
      }
      state={state}
    >
      {showStep(order) && (
        <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
          <HasPermissions.Then>
            <>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Experience name is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    id="experienceName"
                    label={formLabels?.name}
                    required
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="label"
                control={control}
                rules={{
                  required: 'Experience label is required',
                  pattern: {
                    value: /^[A-Za-z0-9-]+$/,
                    message:
                      'Experience label may only contain alpha-numerics with no spaces',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextInput
                    id="experienceLabel"
                    label={formLabels?.label}
                    description="This is the technical term for the experience and is used in URLs shared with third parties."
                    required
                    {...field}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="contentfulId"
                control={control}
                rules={{
                  validate: {
                    isValidOption: (value) =>
                      experiences?.some((e) => e?.sys.id === value) ||
                      `${formLabels?.contentfulId} is required`,
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <SelectBox
                    variant="standard"
                    id="contentfulExperience"
                    label={formLabels?.contentfulId}
                    description="This determines content and capabilities that come from Contentful"
                    hideSelectPrompt={true}
                    required
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    error={Boolean(error)}
                    errorMessage={error?.message}
                    selectOptions={
                      experiences?.map((e) => ({
                        value: e?.sys.id,
                        label: e?.name,
                      })) as SelectOption[]
                    }
                  />
                )}
              />
            </>
          </HasPermissions.Then>
          <HasPermissions.Else>
            <DisabledList details={details} />
          </HasPermissions.Else>
        </HasPermissions>
      )}
      <StepNavigation
        order={order}
        onBeforeNext={() => {
          trackingService.manageNewExperienceNameEntered({
            name: getValues('name'),
            label: getValues('label'),
            contentfulName: getValues('contentfulName'),
          })
          return trigger(['name', 'label', 'contentfulName'])
        }}
      />
    </OrderedFieldset>
  )
}
