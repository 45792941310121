import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { H5SerifCss } from '@cais-group/approved/ui/typography'
import { LayoutCenter } from '@cais-group/shared/ui/layout'

export type WithDescriptionProps = {
  children: ReactNode
  description?: string
}

const Description = styled.p`
  ${H5SerifCss}
  font-family: var(--font-text);
  max-width: var(--measure);
  width: 100%;
  margin: var(--s88) auto calc(var(--s56) + var(--s8));
`
export const WithDescription = (props: WithDescriptionProps) => {
  const { description, children } = props
  return description ? (
    <>
      <LayoutCenter centerText>
        <Description>{description}</Description>
      </LayoutCenter>
      {children}
    </>
  ) : (
    (children as JSX.Element)
  )
}
