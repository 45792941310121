import { AxiosError } from 'axios'
import { useInfiniteQuery } from 'react-query'

import { useDefaultInfiniteQueryFunction } from '@cais-group/caisiq/util/default-query-function'
import { useApiErrorHandler } from '@cais-group/caisiq/util/hook/use-api-error-handler'
import {
  ApiPaths,
  GetCaisIqFirmSearchResult,
  SearchRequestFilterSort,
} from '@cais-group/shared/util/type/caisiq-be'

export function useGetManageCaisIqFirms(
  options: SearchRequestFilterSort & {
    searchTerm?: string
  }
) {
  const queryFn = useDefaultInfiniteQueryFunction<GetCaisIqFirmSearchResult>(
    options,
    ApiPaths.searchFirms
  )

  const errorHandlerFactory = useApiErrorHandler()

  return useInfiniteQuery<GetCaisIqFirmSearchResult, AxiosError>(
    [
      ApiPaths.searchFirms,
      options.sort && options.sort[0],
      options.searchTerm,
      options.filter,
    ],
    queryFn,
    {
      onError: errorHandlerFactory(),
      getNextPageParam: (lastPage) =>
        (lastPage.pageInfo.hasNextPage && lastPage.pageInfo.endCursor) ||
        undefined,
    }
  )
}
