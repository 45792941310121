import cx from 'classnames'
import * as React from 'react'
import { StrictExtract } from 'ts-essentials'

import { Icon } from '@cais-group/equity/atoms/icon'
import { Color, EquitySemanticColor } from '@cais-group/equity/particles/colors'
import { IconType } from '@cais-group/equity/particles/icons'

export type TextInputColor = StrictExtract<
  EquitySemanticColor,
  'primary' | 'success' | 'error' | 'warning'
>

export type TextInputSize = 'small' | 'large'

const getPadding = (
  size: TextInputSize,
  hasStartAdornment: boolean,
  hasEndAdornment: boolean
): string => {
  return [
    size === 'large' ? 'py-[11px]' : 'py-[7px]',
    hasStartAdornment ? 'pl-[44px]' : 'pl-16',
    hasEndAdornment ? 'pr-[44px]' : 'pr-16',
  ].join(' ')
}

const getFontSize = (size: TextInputSize): string => {
  return size === 'large' ? 'body' : 'small'
}

const filledBorderColorMap: Record<TextInputColor, string> = {
  primary: 'border-primary-200 focus:border-primary-600',
  success: 'border-success-200 focus:border-success-600',
  error: 'border-error-200 focus:border-error-600',
  warning: 'border-warning-400 focus:border-warning-600',
}

const borderColorMap: Record<TextInputColor, string> = {
  primary: 'border-neutral-200 focus:border-primary-600',
  success: 'border-success-300 focus:border-success-600',
  error: 'border-error-300 focus:border-error-600',
  warning: 'border-warning-300 focus:border-warning-600',
}

const getBorderColor = (color: TextInputColor, highlight: boolean): string =>
  highlight ? filledBorderColorMap[color] : borderColorMap[color]

const backgroundColorMap: Record<TextInputColor, string> = {
  primary: 'bg-primary-100',
  success: 'bg-success-100',
  error: 'bg-error-100',
  warning: 'bg-warning-100',
} as const

const getBackgroundColor = (
  color: TextInputColor,
  highlight: boolean
): string => (highlight ? backgroundColorMap[color] : 'enabled:bg-neutral-0')

type Adornment =
  | {
      type: IconType
      color: Color
      onClick?: () => void
    }
  | {
      text: string
      color: Color
      onClick?: () => void
    }

export type TextInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'style' | 'className' | 'size' | 'value'
> & {
  /** Optional property to define which semantic color to use. Defaults to `"primary"` */
  color?: TextInputColor
  /** There are 2 different sizes. Affects font-size and padding. Defaults to `"large"` */
  size?: TextInputSize
  /** If a value is defined it means the text input is controlled and cannot be updated directly */
  value?: string
  /** Affects the border and background-color */
  highlight?: boolean
  /** Optional object to allow adding an icon or text  at the start of the text input */
  startAdornment?: Adornment
  /** Optional object to allow adding an icon or text at the end of the text input */
  endAdornment?: Adornment
}

/**
 * Text Inputs let users enter and edit text.
 */
export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      size = 'large',
      highlight = false,
      color = 'primary',
      value = undefined,
      startAdornment,
      endAdornment,
      ...rest
    } = props
    return (
      <div className="relative">
        {startAdornment ? (
          <div
            className={cx(
              'absolute bottom-0 left-16 top-0 flex items-center justify-center',
              { 'cursor-pointer': startAdornment.onClick }
            )}
            onClick={startAdornment.onClick}
          >
            {'type' in startAdornment ? (
              <Icon
                size="small"
                type={startAdornment.type}
                color={startAdornment.color}
              />
            ) : (
              <span className="text-neutral-600">{startAdornment.text}</span>
            )}
          </div>
        ) : null}
        <input
          id={props.id}
          ref={ref}
          className={cx(
            'w-full rounded-none border border-solid outline-0 ring-0 placeholder:text-neutral-500 enabled:text-neutral-900 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-500',
            getPadding(size, !!startAdornment, !!endAdornment),
            getFontSize(size),
            getBorderColor(color, highlight),
            getBackgroundColor(color, highlight)
          )}
          value={value}
          {...rest}
        />
        {endAdornment ? (
          <div
            className={cx(
              'absolute bottom-0 right-8 top-0 flex items-center justify-center',
              { 'cursor-pointer': endAdornment.onClick }
            )}
            data-testid={`${rest['data-testid'] ?? 'end-adornment'}-${
              'type' in endAdornment ? endAdornment.type : endAdornment.text
            }`}
            onClick={endAdornment.onClick}
          >
            {'type' in endAdornment ? (
              <Icon
                size="small"
                type={endAdornment.type}
                color={endAdornment.color}
              />
            ) : (
              <span className="text-neutral-600">{endAdornment.text}</span>
            )}
          </div>
        ) : null}
      </div>
    )
  }
)

export type TextAreaInputProps = Omit<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  'style' | 'className' | 'size' | 'value'
> & {
  color?: TextInputColor
  size?: TextInputSize
  value?: string
  highlight?: boolean
  minHeight?: number
}

export const TextAreaInput = (props: TextAreaInputProps) => {
  const {
    size = 'large',
    highlight = false,
    color = 'primary',
    value = undefined,
    minHeight = 358,
    ...rest
  } = props
  return (
    <textarea
      className={cx(
        `min-h-[${minHeight}px] w-full rounded-none border border-solid outline-0 ring-0 placeholder:text-neutral-500 enabled:text-neutral-900 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-500`,
        getPadding(size, false, false),
        getFontSize(size),
        getBorderColor(color, highlight),
        getBackgroundColor(color, highlight)
      )}
      value={value}
      {...rest}
    />
  )
}
