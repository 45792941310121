import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { defaultPersister } from '@cais-group/caisiq/util/default-query-function'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { useApiPathWithPayload } from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import { ApiPaths, User } from '@cais-group/shared/util/type/caisiq-be'
export const useDeleteSwitchExperience = (version?: number) => {
  const getParsedEndpoint = useApiPathWithPayload(
    ApiPaths.deleteLearnerExperienceSwitch
  )

  const { mutateAsync: deleteSwitchExperienceMutate } = useMutation<
    unknown,
    AxiosError,
    Pick<User, 'email'>,
    unknown
  >(
    async (data: Pick<User, 'email'>) => {
      return await defaultPersister(
        getParsedEndpoint(data),
        undefined,
        version,
        'DELETE'
      )
    },
    {
      onSuccess: async () => {
        experienceService.reset()
        window.location.replace(window.location.pathname)
      },
      onError: (apiError) => {
        console.error(
          `There was a problem delete the switch from an API call to ${apiError.config?.url}`,
          apiError,
          apiError.response?.status
        )
      },
    }
  )

  return {
    deleteSwitchExperienceMutate,
  }
}
