import { TypeVideoData } from '@cais-group/shared/util/type/video-data'
import { TypeVideoPlaylistData } from '@cais-group/shared/util/type/video-playlist-data'

export type VideoPlaylistCollection = {
  caisiqVideoPlaylistCollection: {
    items: Array<TypeVideoPlaylistData>
  }
}

export type TypeVideoDataWithParentPlaylist = TypeVideoData & {
  linkedFrom: {
    caisiqVideoPlaylistCollection: {
      items: Array<TypeVideoPlaylistData>
    }
  }
}
