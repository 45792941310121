import * as React from 'react'
import * as Rx from 'rxjs'

import { useUniformContext } from '../context'

export function useShowSection({
  fieldIds = [],
}: {
  fieldIds: string[]
}): boolean {
  const { rulesFeed, settings } = useUniformContext()
  const [show, setShow] = React.useState<boolean>(true)
  const setting = settings?.showSectionsWithRequiredFieldsOnly

  React.useEffect(() => {
    if (typeof setting === 'undefined') return
    if (fieldIds.length === 0) return

    const sub = rulesFeed.sharedRulesFeed
      .pipe(
        Rx.map((obj) => {
          return Object.entries(obj).reduce((acc, [key, val]) => {
            if (fieldIds.includes(key)) {
              if (val?.isVisible && val?.isRequired) {
                return acc.concat(key)
              }
              if (val?.skippable) {
                return acc.concat(key)
              }
              return acc
            }
            return acc
          }, [] as string[])
        }),
        Rx.map((arr) => arr.length > 0),
        Rx.distinctUntilChanged((a, b) => a === b)
      )
      .subscribe(setShow)

    return () => sub.unsubscribe()
  }, [fieldIds, rulesFeed, setting])

  if (typeof setting === 'undefined') return true
  if (typeof setting === 'boolean') {
    if (fieldIds.length === 0) return false
    if (setting === false) return true
  }
  return show
}
