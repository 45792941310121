import { UserProfileV1 } from '@cais-group/access-manager/domain/api'
import { FirmExperienceV1 } from '@cais-group/shared/domain/experience-manager'

const LOGO_ATTRIBUTE_KEY = 'firm_logo_url'

export function getFirmLogo({
  experience,
  userProfile,
}: {
  experience?: FirmExperienceV1
  userProfile: UserProfileV1 | null
}): string | null {
  const userProfileFirmLogo = userProfile
    ? extractInheritedAttributeFromUserProfile(LOGO_ATTRIBUTE_KEY, userProfile)
    : null
  const experienceFirmLogo = experience?.assets?.['logo']?.asset_url || null

  return (
    cloudinaryAutoFormat(userProfileFirmLogo) ||
    cloudinaryAutoFormat(experienceFirmLogo)
  )
}

function extractInheritedAttributeFromUserProfile(
  key: string,
  userProfile: UserProfileV1
): string | null {
  if (!userProfile || !userProfile.inheritedAttributes) {
    return null
  }
  const value = userProfile.inheritedAttributes.find(
    (attribute) => attribute.attributes[key]
  )?.attributes?.[key]
  return (value as string) ?? null
}

function cloudinaryAutoFormat(url: string | null) {
  if (!url || url.endsWith('.svg') || url.indexOf('f_auto/q_auto') > -1) {
    return url
  }
  return url.replace('cais/image/upload', 'cais/image/upload/f_auto/q_auto')
}
