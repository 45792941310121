import {
  ApiState,
  useReactQueryResultAsApiState,
} from '@cais-group/caisiq/domain/common'
import {
  GetWhitepapersByFirmQuery,
  useGetWhitepapersByFirmQuery,
} from '@cais-group/caisiq/util/graphql/contentful'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

export type WhitepapersApiState = ApiState<Array<WhitepaperData>>

export const useGetWhitepapers = (options?: {
  pause?: boolean
}): WhitepapersApiState => {
  return useReactQueryResultAsApiState<
    GetWhitepapersByFirmQuery,
    WhitepaperData[]
  >(
    useGetWhitepapersByFirmQuery(
      { firmId: userSettingsService.themeId, preview: previewService.enabled },
      { enabled: !options?.pause }
    ),
    (data) => data.firm?.whitepapersCollection?.items as WhitepaperData[],
    'Could not load whitepapersCollectionQuery'
  )
}
