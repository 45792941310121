import { merge } from 'lodash-es'
import * as R from 'remeda'

import { WhereInput, operatorsFns } from '../rules'
import type { UniformServices } from '../services'
import type {
  FieldRulesKeys,
  UniformAny,
  UniformFieldRules,
  UniformSchema,
} from '../types'

import { collectAllValuesFrom } from './collect-all-values-from'
import { getDepsByDepKey } from './get-deps-by-dep-key'

function getAllKeys(obj: Record<string, UniformAny> = {}, keys = new Set()) {
  if (typeof obj === 'object') {
    for (const key in obj) {
      if (!isNaN(Number(key))) continue // Skip if key is a number
      keys.add(key)
      getAllKeys(obj[key], keys)
    }
  }
  return keys as Set<string>
}

export function extractFieldsIdsFromWhereFilters<
  TPossibleEntries extends keyof UniformAny
>(where: WhereInput<TPossibleEntries>) {
  return R.pipe(
    Array.from(getAllKeys(where)),
    R.filter((operator) =>
      R.pipe(
        R.keys(operatorsFns),
        R.concat(['field', 'value', 'message']),
        R.concat(['_and', '_or', '_not']),
        (x) => !x.includes(operator)
      )
    ),
    // collect field ids from "field" key in operators
    R.concat(collectAllValuesFrom('field', where))
  )
}

export function getAllKeysFromOperators<
  TPossibleEntries extends keyof UniformAny
>(rules?: WhereInput<TPossibleEntries>): string[] {
  if (!rules) return []

  const result = Object.keys(rules).flatMap((key) => {
    switch (key) {
      case '_and':
        return (
          rules._and?.value.flatMap((x) => getAllKeysFromOperators(x)) ?? []
        )
      case '_or':
        return rules._or?.value.flatMap((x) => getAllKeysFromOperators(x)) ?? []
      case '_not':
        return rules._not?.flatMap((x) => getAllKeysFromOperators(x)) ?? []
      default:
        return extractFieldsIdsFromWhereFilters({
          [key]: (rules as UniformAny)[key],
        })
    }
  })

  return result
}

const getValidationDependencies = <
  TUniformServices extends UniformServices = UniformServices
>(
  schema: UniformSchema<TUniformServices> | UniformSchema<TUniformServices>[],
  key: keyof UniformFieldRules,
  rules?: Partial<Record<UniformAny, Partial<UniformFieldRules<UniformAny>>>>
) => {
  return R.pipe(
    R.concat(
      // collect all rules from schema by rule type
      R.pipe(
        getDepsByDepKey(schema, 'rules'),
        // filter rules for the same key
        R.filter(({ rules = {} }) => (rules[key] ? true : false)),
        R.map((field) => ({
          id: field.id,
          rules: merge({}, field.rules, rules?.[field.id ?? '']),
        }))
      ),
      // collect all rules from global rules
      R.pipe(
        Object.entries(rules ?? {}),
        // filter rules for the same key
        R.filter(([_fieldId, rules = {}]) => (rules[key] ? true : false)),
        R.map(([key, value]) => ({
          id: key,
          rules: value,
        }))
      )
    ),
    R.reduce((acc, field) => {
      if (!field.id) return acc
      const rulesByKey = field.rules?.[key]

      if (typeof rulesByKey === 'boolean' || typeof rulesByKey === 'string') {
        return acc
      }

      if (acc[field.id]) {
        return {
          ...acc,
          [field.id]: Array.from(
            new Set([...acc[field.id], ...getAllKeysFromOperators(rulesByKey)])
          ),
        }
      }

      // 💡: we might need this for validation only?
      const remoteIds = collectAllValuesFrom('field', field)

      return {
        ...acc,
        [field.id]: Array.from(
          new Set([...getAllKeysFromOperators(rulesByKey), ...remoteIds])
        ),
      }
    }, {} as Record<string, string[]>)
  )
}

export type DependencyTreeReturns = Record<string, string[]>
export function getDependencyTree<
  TFormFieldIds extends keyof UniformAny = UniformAny
>(
  key: keyof UniformFieldRules,
  schema:
    | UniformSchema<UniformAny, UniformAny, UniformAny>
    | UniformSchema<UniformAny, UniformAny, UniformAny>[],
  rules?: Partial<
    Record<TFormFieldIds, Partial<UniformFieldRules<TFormFieldIds>>>
  >
): DependencyTreeReturns {
  const dependencies = getValidationDependencies(schema, key, rules)

  const reversedDependencies = Object.entries(
    dependencies
  ).reduce<DependencyTreeReturns>((acc, [key, value]) => {
    const obj: DependencyTreeReturns = {}

    if (Array.isArray(value)) {
      for (const val of value) {
        // NOTE: reversedDependency may also have its own dependencies
        const fieldDependencies = dependencies[val]

        if (Array.isArray(fieldDependencies)) {
          // merge dependencies and remove duplicates
          obj[val] = Array.from(new Set([...fieldDependencies, key]))
        } else {
          // otherwise just return key inside array
          obj[val] = [key]
        }
      }
    }

    return {
      ...acc,
      ...obj,
    }
  }, {})

  return { ...dependencies, ...reversedDependencies }
}

export function getFieldRulesDeps<
  TPossibleEntries extends keyof UniformAny = UniformAny
>(
  schema: UniformSchema<UniformAny> | UniformSchema<UniformAny>[],
  rules?: Partial<
    Record<TPossibleEntries, Partial<UniformFieldRules<TPossibleEntries>>>
  >
): Record<FieldRulesKeys, Record<string, string[]>> {
  const keys: FieldRulesKeys[] = [
    'requiredIf',
    'validation',
    'visibleIf',
    'disabledIf',
  ]
  return keys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: getDependencyTree(key, schema, rules),
    }
  }, {} as Record<FieldRulesKeys, Record<string, string[]>>)
}
