import { Theme } from './types'

export const colors: Theme = {
  brand: {
    '100': '#dfe7e0',
    '100-c': '#211c21',
    '200': '#c8d5ca',
    '200-c': '#211c21',
    '300': '#99b29c',
    '300-c': '#211c21',
    '400': '#6c8f70',
    '400-c': '#211c21',
    '500': '#3a6f41',
    '500-c': '#ffffff',
    '600': '#094e12',
    '600-c': '#ffffff',
    '700': '#073c0e',
    '700-c': '#ffffff',
    '800': '#052a0a',
    '800-c': '#ffffff',
    '900': '#021605',
    '900-c': '#ffffff',
  },
  primary: {
    '100': '#f6f6f6',
    '100-c': '#211c21',
    '200': '#e3e3e3',
    '200-c': '#211c21',
    '300': '#c7c6c7',
    '300-c': '#211c21',
    '400': '#b5b3b5',
    '400-c': '#211c21',
    '500': '#8f8c8f',
    '500-c': '#211c21',
    '600': '#052a0a',
    '600-c': '#ffffff',
    '700': '#021605',
    '700-c': '#ffffff',
    '800': '#2e2a2e',
    '800-c': '#ffffff',
    '900': '#211c21',
    '900-c': '#ffffff',
  },
  datavis: {
    '1': '#6c8f70',
    '1-c': '#211c21',
    '2': '#d1e0a5',
    '2-c': '#211c21',
    '3': '#f3d7bc',
    '3-c': '#211c21',
    '4': '#a8864d',
    '4-c': '#211c21',
    '5': '#925364',
    '5-c': '#ffffff',
    '6': '#917da3',
    '6-c': '#211c21',
    '7': '#cdd1d8',
    '7-c': '#211c21',
    '8': '#3958a1',
    '8-c': '#ffffff',
    '9': '#c0e0db',
    '9-c': '#211c21',
    '10': '#f9d974',
    '10-c': '#211c21',
    '11': '#c47774',
    '11-c': '#211c21',
    '12': '#00407b',
    '12-c': '#ffffff',
    '13': '#8cade9',
    '13-c': '#211c21',
    '14': '#327174',
    '14-c': '#ffffff',
    '15': '#96dbc8',
    '15-c': '#211c21',
    '16': '#ffc170',
    '16-c': '#211c21',
    '17': '#bb530a',
    '17-c': '#ffffff',
    '18': '#65162c',
    '18-c': '#ffffff',
    '19': '#c0bbf7',
    '19-c': '#211c21',
    '20': '#595c5c',
    '20-c': '#ffffff',
    '21': '#2a92ba',
    '21-c': '#211c21',
    '22': '#86d3f2',
    '22-c': '#211c21',
  },
  gradient: {
    '1-1': '#99b29c',
    '1-1-c': '#211c21',
    '1-2': '#dfe7e0',
    '1-2-c': '#211c21',
    '2-1': '#094e12',
    '2-1-c': '#ffffff',
    '2-2': '#99b29c',
    '2-2-c': '#ffffff',
    '3-1': '#021605',
    '3-1-c': '#ffffff',
    '3-2': '#094e12',
    '3-2-c': '#ffffff',
    '4-1': '#094e12',
    '4-1-c': '#ffffff',
    '4-2': '#99b29c',
    '4-2-c': '#ffffff',
  },
} as const
