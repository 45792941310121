import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useLayoutEffect, useState } from 'react'
import * as React from 'react'
import {
  useTable,
  useSortBy,
  useExpanded,
  usePagination,
  Cell,
  ColumnInstance,
} from 'react-table'

import {
  H6Strong,
  BodySmall,
  CaptionRegular,
  H5Strong,
  Body,
} from '@cais-group/approved/ui/typography'
import { useFormatPivotTableData } from '@cais-group/caisiq/util/hook/use-format-pivot-table-data'
import { TablePivot } from '@cais-group/caisiq-ui-table-pivot'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { Pagination } from '@cais-group/shared/ui/pagination'
import {
  SortMenuColumnHeader,
  actionsForColumnHeader,
  sortIconForColumnHeader,
} from '@cais-group/shared/ui/sort-menu-column-header'
import {
  ColumnCommon,
  CustomSortRowType,
  FormattedHomeOfficeRequirementMetData,
  HomeOfficeCourseStatusCommon,
  HomeOfficeTableInstance,
  HomeOfficeUserType,
  SlugEnum,
  StatusesType,
  TabEnum,
} from '@cais-group/shared/util/type/homeoffice-data'
import { WithinGroupSortConfig } from '@cais-group/shared/util/use-within-group-sort-config'

const TableContainer = styled.div`
  border-spacing: 0;
  border-collapse: collapse;
  font-family: var(--font-text);
  text-align: left;
  font-weight: normal;
  width: 100%;
  height: auto;
  background-color: rgb(var(--colors-neutral-0));
`

type PaginationContainerProps = {
  tab?: string
  width?: number
}
const PaginationContainer = styled.div<PaginationContainerProps>`
  justify-content: center;
  display: flex;
  height: var(--s88);
  box-shadow: 0px 10px 17px rgba(0, 0, 0, 0.06);
  background-color: rgb(var(--colors-neutral-0));
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};

  @media screen and (max-width: ${BREAKPOINTS.breakpointMd}) {
    overflow: scroll;
    position: ${(props) =>
      props.tab === 'Completed requirements' ? 'absolute' : 'relative'};
    max-width: 656px;
  }
`

type TableProps = {
  isTablet?: boolean
  tab?: string
}
const Table = styled.table<TableProps>`
  box-shadow: 0px 10px 17px rgba(0, 0, 0, 0.06);
  border-spacing: 0;
  border-collapse: collapse;
  font-family: var(--font-text);
  font-size: 12px;
  text-align: left;
  font-weight: normal;
  width: 100%;
  min-height: 1222px;
  background-color: rgb(var(--colors-neutral-0));
  @media screen and (max-width: ${BREAKPOINTS.breakpointSm}) {
    width: ${(props) =>
      props.tab === 'Completed requirements' ? '80rem' : '100%'};
  }
`

const THead = styled.thead`
  background: rgb(var(--colors-neutral-900));
  color: rgb(var(--colors-neutral-0));
`
const TH = styled.th<{ width: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  padding: 0;
  @media screen and (max-width: ${BREAKPOINTS.breakpointMd}) {
    &:last-of-type {
      padding-right: var(--s24);
    }
  }
`

const HeaderContainer = styled(CaptionRegular)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  justify-content: flex-start;
  line-height: var(--s16);
  max-height: 40px;
  min-height: 40px;
  width: 100%;
`

const Line = styled.div`
  height: 1px;
  background: rgb(var(--colors-neutral-200));
  width: calc(100% - 48px);
  margin-left: var(--s24);
  @media screen and (max-width: ${BREAKPOINTS.breakpointMd}) {
    width: 100%;
    margin: 0;
    height: 0px;
    background: none;
  }
`

const PaginationLine = styled.div<{ tab?: string }>`
  height: 1px;
  width: calc(100% - 48px);
  margin-left: var(--s24);
  @media screen and (max-width: ${BREAKPOINTS.breakpointMd}) {
    height: ${(props) =>
      props.tab === 'Completed requirements' ? '0px' : '1px'};
  }
`

type TRProps = {
  isSkeleton?: boolean
  tab?: string
}
const TR = styled.tr<TRProps>`
  padding-right: var(--s24);
  max-height: var(--s56);
  height: var(--s56);
  &:last-of-type {
    padding-right: 0px;
  }
  td:first-of-type {
    overflow-wrap: break-word;
    &:first-of-type {
      padding-left: var(--s16);
    }
  }

  &:hover {
    background-color: ${(props) =>
      !props.isSkeleton && 'var(--color-background)'};
  }

  @media screen and (max-width: ${BREAKPOINTS.breakpointMd}) {
    height: var(--s56);
    max-height: var(--s56);
    &:hover {
      background-color: transparent;
    }
    > td {
      &:first-of-type {
        background-color: rgb(var(--colors-neutral-100));
      }
    }
  }
`

const LineTR = styled.tr`
  max-height: 1px;
  height: 1px;
  @media screen and (max-width: ${BREAKPOINTS.breakpointMd}) {
    height: 0px;
    > td {
      background: rgb(var(--colors-neutral-200));
    }
  }
`

const TD = styled.td<{ isTablet?: boolean }>`
  padding: ${(p) => (p.isTablet ? ' ' : '16px 24px')};
  padding-right: 0px;
  &:last-of-type {
    padding-right: var(--s24);
  }
  @media screen and (max-width: ${BREAKPOINTS.breakpointMd}) {
    padding: 16px 0px 16px 24px;
  }
  ${BodySmall} {
    margin: 0;
  }

  ${H6Strong} {
    font-size: 14px;
  }
`

const TFoot = styled.tfoot`
  height: 1px;
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;

  }
  75% {
    opacity: 1;
    transform: translateY(-50%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
`
const SubTableContainer = styled.td<{ isTablet?: boolean }>`
  padding: 16px 24px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
  max-width: ${(props) => (props.isTablet ? 'var(--s608)' : '100%')};
  overflow: hidden;
  z-index: 0;
  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) and (max-width: ${BREAKPOINTS.breakpointXl}) {
    max-width: var(--s608);
  }
  @media screen and (max-width: ${BREAKPOINTS.breakpointSm}) {
    max-width: var(--s232);
  }
`
const SubTableContainerRow = styled.tr``

type DivSubTableWrapperProps = {
  showRow?: boolean
}
const DivSubTableWrapper = styled.div<DivSubTableWrapperProps>`
  overflow: scroll;
  z-index: 0;
  animation: ${(props) => (props.showRow ? fadeIn : fadeOut)}
    ${(props) => (props.showRow ? `0.5s` : `0.25s`)} ease-in-out;
`

const TBody = styled.tbody``

const EmptyTable = styled.div`
  height: 70rem;
  box-shadow: 0px 10px 17px rgba(0, 0, 0, 0.06);
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  background-color: rgb(var(--colors-neutral-0));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${BREAKPOINTS.breakpointSm}) {
    height: 372px;
  }
`

const EmptyContentContainer = styled.div`
  width: 506px;
  height: auto;
  @media screen and (max-width: ${BREAKPOINTS.breakpointSm}) {
    width: 320px;
  }
`
const EmptyTableHeader = styled(H5Strong)`
  text-align: center;
  margin-bottom: var(--s16);
  @media screen and (max-width: ${BREAKPOINTS.breakpointSm}) {
    width: 320px;
  }
`

const EmptyTableContent = styled(Body)`
  color: rgb(var(--colors-neutral-600));
  text-align: center;
  @media screen and (max-width: ${BREAKPOINTS.breakpointSm}) {
    width: 320px;
  }
`

const EmptyTableState = () => (
  <EmptyTable>
    <EmptyContentContainer>
      <EmptyTableHeader>
        There are no completed requirements yet.
      </EmptyTableHeader>
      <EmptyTableContent>
        When a learner completes a requirement set by home office, they will
        appear here along with the date the requirement was met.
      </EmptyTableContent>
    </EmptyContentContainer>
  </EmptyTable>
)

interface DefaultRowProps {
  index: number
  row: CustomSortRowType<Record<string, unknown>>
  visibleColumns: ColumnInstance<Record<string, unknown>>[]
  isLast: boolean
  isTablet: boolean
  isLoading: boolean
  tab?: string
}
const DefaultRow = ({
  index,
  row,
  isLast,
  visibleColumns,
  isTablet,
  isLoading,
  tab,
}: DefaultRowProps) => {
  const { pivotData, pivotColumns } = useFormatPivotTableData({
    data: (row.original['courses'] as HomeOfficeCourseStatusCommon[]) || [],
  })

  return (
    <>
      <TR {...row.getRowProps()} tab={tab} isSkeleton={isLoading}>
        {row.cells.map((cell: Cell) => (
          <TD
            {...cell.getCellProps()}
            isTablet={isTablet}
            key={`${cell.row.id}-${cell.column.id}-${index}`}
          >
            {cell.render('Cell')}
          </TD>
        ))}
      </TR>
      {row.isExpanded ? (
        <SubTableContainerRow key={`subTableContainerRow-${row.id}-${index}`}>
          <SubTableContainer
            colSpan={visibleColumns.length}
            isTablet={isTablet}
          >
            <DivSubTableWrapper
              showRow={row.isExpanded}
              data-testid="pivot-container"
            >
              <TablePivot
                columns={
                  pivotColumns as unknown as ColumnCommon<
                    Record<string, unknown>
                  >[]
                }
                data={pivotData}
              />
            </DivSubTableWrapper>
          </SubTableContainer>
        </SubTableContainerRow>
      ) : (
        /* This is to display the border bottom for each row except the last one on the page */
        <LineTR key={`line-${row.id}-${index}`}>
          <td colSpan={visibleColumns.length}>{!isLast && <Line />}</td>
        </LineTR>
      )}
    </>
  )
}

// TODO: this will expand as the component is shared / reused
export type TableDefaultProps = {
  data: StatusesType[] | FormattedHomeOfficeRequirementMetData[]
  columns: ColumnCommon<Record<string, unknown>>[]
  tab?: string
  totalPages: number
  pageIndex: number
  onSetPage: (arg: number) => void
  isPreviousData?: boolean
  queryData?: HomeOfficeUserType[] | undefined
  shouldTableUpdateRef: React.MutableRefObject<boolean>
  isLoading?: boolean
  pageLength: number
  defaultPageSize: number
  setSortConfig?: (sortConfig: WithinGroupSortConfig) => void
  sortConfigApplied?: WithinGroupSortConfig
}

export const TableDefault = (props: TableDefaultProps) => {
  const {
    data,
    columns,
    tab,
    totalPages,
    onSetPage,
    pageIndex,
    shouldTableUpdateRef,
    isLoading = false,
    pageLength,
    defaultPageSize,
    setSortConfig,
    sortConfigApplied,
  } = props

  const theme = useTheme()
  // Custom breakpoint to ensure all size tablets get correct styling
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 75))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [paginationWidth, setPaginationWidth] = useState<number>()
  const [pageCount, setPageCount] = useState(0)

  // @ts-ignore - tsCheck job failing here - To be fixed
  const {
    getTableProps,
    visibleColumns,
    prepareRow,
    page,
    state: { pageSize },
  }: HomeOfficeTableInstance<Record<string, unknown>> = useTable(
    {
      // TODO: Make typesafe
      // @ts-ignore
      columns: columns,
      // @ts-ignore
      data: data,
      // @ts-ignore
      initialState: { pageSize: pageLength },
      disableSortBy: true,
      manualSortBy: true,
      autoResetPage: !shouldTableUpdateRef.current,
      autoResetExpanded: !shouldTableUpdateRef.current,
      autoResetGroupBy: !shouldTableUpdateRef.current,
      autoResetSelectedRows: !shouldTableUpdateRef.current,
      autoResetSortBy: !shouldTableUpdateRef.current,
      autoResetFilters: !shouldTableUpdateRef.current,
      autoResetRowState: !shouldTableUpdateRef.current,
    },
    useSortBy,
    useExpanded,
    usePagination
  )

  useEffect(() => {
    if (pageSize) {
      // TODO: Eventually, all the tabs will be in contentful
      if (tab === 'all-learners' || tab === 'All learners') {
        setPageCount(totalPages)
      } else {
        // TODO: this will likely change once we figure out the complete requirements tab data
        setPageCount(Math.ceil(data.length / defaultPageSize))
      }
    }
    // this is necessary to adjust the pagination component to be visible in a scrollable table.
    if ((isMobile || isTablet) && tab === 'Completed requirements') {
      const footer = document.getElementsByTagName('footer')
      footer[0].style.marginTop = '88px'
    }
  }, [
    data.length,
    defaultPageSize,
    isMobile,
    isTablet,
    pageSize,
    tab,
    totalPages,
  ])

  // Since the pagination container needs to be outside the table, this will ensure that the pagination and table are of equal width
  useLayoutEffect(() => {
    if (columns) {
      const table = document.getElementsByTagName('table')
      setPaginationWidth(table[0].offsetWidth)
    }
  }, [columns])

  return (
    <TableContainer key={pageIndex} data-testid="default-table-container">
      <Table {...getTableProps()} isTablet={isTablet} tab={tab}>
        <THead>
          <tr>
            {columns?.map((column, i) => (
              <TH key={`th-${column.id}-${i}`} width={column.width as number}>
                <HeaderContainer as="div">
                  {!column.disableSortBy ? (
                    <SortMenuColumnHeader
                      stickLeft={0}
                      active={sortConfigApplied?.sortId === column.id}
                      actions={actionsForColumnHeader({
                        id: column.id as string,
                        setSortConfig,
                        sortKind: column.sort.kind,
                        required: true,
                      })}
                      sortIcon={sortIconForColumnHeader({
                        order: sortConfigApplied?.sortOrder,
                        kind: column.sort?.kind ?? column.serverSort?.kind,
                      })}
                      alignment="left"
                      listItemIconColor="eq-color-neutral-400"
                    >
                      {column.title}
                    </SortMenuColumnHeader>
                  ) : (
                    column.title
                  )}
                </HeaderContainer>
              </TH>
            ))}
          </tr>
        </THead>

        <TBody>
          {page &&
            page.map(
              (row: CustomSortRowType<Record<string, unknown>>, i: number) => {
                prepareRow(row)

                return (
                  <DefaultRow
                    index={i}
                    key={row.id}
                    row={row}
                    isLast={!!pageSize && i === pageSize - 1}
                    visibleColumns={visibleColumns}
                    isTablet={isTablet}
                    isLoading={isLoading}
                    tab={tab}
                  />
                )
              }
            )}
          {/* This will render fake/blank rows on the final page to maintain consistent height of the table*/}
          {tab === TabEnum.ALL_LEARNERS ||
            (tab === SlugEnum.ALL_LEARNERS &&
              [...Array(pageSize && page ? pageSize - page.length : 20)].map(
                (_fakeRow, i: number) => {
                  return (
                    <TR
                      key={`fakerow-${i}`}
                      style={{
                        height: `var(--s56)`,
                      }}
                    ></TR>
                  )
                }
              ))}
        </TBody>
        <TFoot />
      </Table>
      {/* display message if there are no users with completed requirements */}
      {/* TODO: might want to adjust to make more dynamic for any tab */}
      {pageCount === 0 &&
        (tab === TabEnum.COMPLETED_REQUIREMENTS ||
          tab === SlugEnum.COMPLETED_REQUIREMENTS) && <EmptyTableState />}
      <>
        {/* This is to display a replacement border for the last row on the page above the pagination */}
        <PaginationLine tab={tab} />
        <PaginationContainer
          data-testid="homeoffice-pagination"
          tab={tab}
          width={paginationWidth}
        >
          <Pagination
            pages={pageCount ?? 0}
            currentPage={pageIndex}
            onPageSelect={(page) => onSetPage(page)}
          />
        </PaginationContainer>
      </>
    </TableContainer>
  )
}
