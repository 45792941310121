import { Checkbox, CheckboxProps, Tooltip } from '@mui/material'

import { SelectableListItem } from '@cais-group/shared/ui/selectable-list'

interface ConcatUuidWithTooltipProps {
  uuid: string
  truncateLength?: number
}

function ConcatUuidWithTooltip({
  uuid,
  truncateLength = 4,
}: ConcatUuidWithTooltipProps) {
  return (
    <Tooltip title={uuid} arrow placement="top">
      <div className="bodySmall text-neutral-600">
        ...{uuid.slice(-truncateLength)}
      </div>
    </Tooltip>
  )
}

export interface FormFirmCheckListItemProps
  extends SelectableListItem<string>,
    Omit<CheckboxProps, 'value'> {
  parentFirmName?: string
  existingExperienceName?: string
}

export function FirmCheckListItem({
  label,
  value,
  parentFirmName,
  existingExperienceName,
  ...checkBoxProps
}: FormFirmCheckListItemProps) {
  return (
    <label>
      <div className="hover:bg-primary-100 flex w-full flex-row items-center px-24 py-4">
        <Checkbox {...checkBoxProps} size="small" className="mr-16 p-0" />
        <div className="bodySmall flex flex-grow flex-col">
          <div>{label}</div>
          {parentFirmName && (
            <div className="text-neutral-600">
              Parent Firm: {parentFirmName}
            </div>
          )}
          {existingExperienceName && (
            <div className="text-success-600">
              Has Experience: {existingExperienceName}
            </div>
          )}
        </div>
        <div className="ml-16 ">
          <ConcatUuidWithTooltip uuid={value} />
        </div>
      </div>
    </label>
  )
}

export default FirmCheckListItem
