import styled, { CSSObject } from '@emotion/styled'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { ReactNode, useState } from 'react'

import { SubtitleMonoCss } from '@cais-group/approved/ui/typography'

const StyledAccordion = styled(MuiAccordion)`
  &:focus-within {
    background-color: rgb(var(--colors-primary-100));
  }
`

type StyledAccordionDetailsProps = {
  borderColor?: CSSObject['borderColor']
  paddingRight?: CSSObject['paddingRight']
}
const StyledAccordionDetails = styled(MuiAccordionDetails, {
  shouldForwardProp: (prop) => {
    return !['borderColor', 'paddingRight'].includes(prop as string)
  },
})<StyledAccordionDetailsProps>`
  ${SubtitleMonoCss};
  background: rgb(var(--colors-neutral-0));
  padding-left: var(--s32);
  padding-right: ${({ paddingRight }) => paddingRight || 'var(--s12)'};
  margin-left: auto;
  outline: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : 'inherit'};
`

type StyledAccordionSummaryProps = AccordionSummaryProps & {
  borderColor: CSSObject['borderColor']
  flexDirection: CSSObject['borderColor']
  backgroundColor: CSSObject['borderColor']
  justifyContent: CSSObject['justifyContent']
  noPaddingLeft?: boolean
  size?: 'normal' | 'large'
}
const StyledAccordionSummary = styled(MuiAccordionSummary, {
  shouldForwardProp: (prop) => {
    return ![
      'noPaddingLeft',
      'backgroundColor',
      'flexDirection',
      'borderColor',
      'justifyContent',
      'size',
    ].includes(prop as string)
  },
})<StyledAccordionSummaryProps>`
  ${SubtitleMonoCss}
  font-family: var(--font-text);
  font-weight: 600;
  padding-left: 0;
  padding-top: ${({ size }) => (size === 'normal' ? '0' : 'var(--s4)')};
  padding-bottom: ${({ size }) => (size === 'normal' ? '0' : 'var(--s4)')};
  background: var(--color - white);
  flex-direction: ${({ flexDirection }) => flexDirection || 'row-reverse'};
  color: rgb(var(--colors-neutral-900));
  outline: ${({ borderColor }) => borderColor && `1px solid ${borderColor}`};
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'inherit'};

  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
    color: rgb(var(--colors-primary-600));
  }

  .MuiAccordionSummary-content.Mui-expanded {
    color: rgb(var(--colors-primary-600));
  }

  .MuiAccordionSummary-content {
    padding-left: ${({ noPaddingLeft }) => !noPaddingLeft && 'var(--s8)'};
    justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
    margin: 0;
  }
`

export type AccordionProps = {
  summary: ReactNode
  children: ReactNode
  testId?: string
  onChange?: () => void
  summarybackGroundColor?: CSSObject['backgroundColor']
  summaryBorderColor?: CSSObject['borderColor']
  detailsBorderColor?: CSSObject['borderColor']
  detailsPaddingRight?: CSSObject['paddingRight']
  summaryFlexDirection?: CSSObject['flexDirection']
  summaryJustifyContent?: CSSObject['justifyContent']
  expanded?: boolean
  controlled?: boolean
  noPadding?: boolean
  className?: string
  size?: 'normal' | 'large'
}
export const Accordion = ({
  summary,
  children,
  testId,
  onChange,
  expanded = false,
  detailsBorderColor,
  detailsPaddingRight,
  summaryBorderColor,
  summaryFlexDirection,
  summarybackGroundColor,
  noPadding = false,
  summaryJustifyContent,
  controlled = false,
  className,
  size = 'large',
}: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded)

  const handleChange = () => {
    setIsExpanded(!isExpanded)
    if (onChange) {
      onChange()
    }
  }

  return (
    <StyledAccordion
      disableGutters
      expanded={controlled ? expanded : isExpanded}
      onChange={handleChange}
      elevation={0}
      data-testid={`accordion-${testId}`}
      className={className}
    >
      <StyledAccordionSummary
        data-testid={`accordion-summary-${testId}`}
        borderColor={summaryBorderColor}
        flexDirection={summaryFlexDirection}
        backgroundColor={summarybackGroundColor}
        noPaddingLeft={noPadding}
        size={size}
        justifyContent={summaryJustifyContent}
        expandIcon={<KeyboardArrowRight />}
      >
        {summary}
      </StyledAccordionSummary>
      <StyledAccordionDetails
        data-testid={`accordion-details-${testId}`}
        borderColor={detailsBorderColor}
        paddingRight={detailsPaddingRight}
      >
        {children}
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}
