import cx from 'classnames'
import { throttle } from 'lodash-es'
import {
  PropsWithChildren,
  ReactElement,
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  SearchInput,
  SearchInputProps,
} from '@cais-group/equity/atoms/text-input'
import { SelectableListItem } from '@cais-group/shared/ui/selectable-list'

const defaultListContainer = ({ children }: PropsWithChildren) => (
  <div className="mt-16">{children}</div>
)

export type SearchableListProps<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
> = {
  id?: string
  filterFn?: (item: TItem, searchValue: string) => boolean
  items: TItem[]
  onChange?: SearchInputProps['onChange']
  isControlled?: boolean
  placeholder?: SearchInputProps['placeholder']
  renderList: (items: TItem[]) => ReactElement
  ListContainer?: (props: PropsWithChildren) => ReactElement
  setSearchText?: (value: string) => void
  testId?: string
  onSearchFocus?(): void
  className?: string
  stickySearch?: boolean
  scrollToBottomCallback?(): void
}
export const SearchableList = <
  TItem extends SelectableListItem<TValue>,
  TValue extends string
>({
  id,
  filterFn,
  onChange,
  items,
  isControlled = false,
  placeholder,
  renderList,
  setSearchText,
  onSearchFocus,
  testId = 'searchable-list',
  ListContainer = defaultListContainer,
  className,
  stickySearch = false,
  scrollToBottomCallback,
}: SearchableListProps<TItem, TValue>) => {
  const [searchValue, setSearchValue] = useState('')

  const defaultItemFilter = useCallback(
    ({ label }: TItem, trimmedSearchValue: string) =>
      label.toLowerCase().includes(trimmedSearchValue),
    []
  )

  const itemFilter = filterFn ?? defaultItemFilter

  const filteredItems: TItem[] = useMemo(() => {
    const trimmedSearchValue = searchValue.trim().toLowerCase()
    if (!trimmedSearchValue || isControlled) return items
    return items.filter((item) => itemFilter(item, trimmedSearchValue))
  }, [isControlled, items, itemFilter, searchValue])

  useEffect(() => {
    isControlled && setSearchText?.(searchValue)
  }, [searchValue, isControlled, setSearchText])

  const noItems = filteredItems.length === 0 && items.length > 0

  const containerRef = useRef<HTMLDivElement>(null)

  const onScroll: UIEventHandler<HTMLDivElement> = () => {
    const el = containerRef.current

    if (!el) return

    if (el.scrollHeight - 10 < el.offsetHeight + el.scrollTop) {
      if (scrollToBottomCallback) {
        scrollToBottomCallback()
      }
    }
  }

  return (
    <div
      ref={containerRef}
      className={className}
      data-testid={`${testId}-container`}
      onScroll={scrollToBottomCallback ? throttle(onScroll, 100) : undefined}
    >
      <div
        className={cx({ 'bg-neutral-0 sticky top-0 z-10 pb-16': stickySearch })}
      >
        <SearchInput
          data-testid={id}
          id={id}
          onChange={(e) => {
            setSearchValue(e.target.value)
            onChange?.(e)
          }}
          onClear={() => setSearchValue('')}
          placeholder={placeholder}
          value={searchValue}
          onFocus={onSearchFocus}
        />
      </div>
      <ListContainer>
        {noItems ? (
          <div className="mt-16 flex flex-col items-center">
            <p className="body-strong" data-testid={`${testId}-noResults`}>
              No results match search
            </p>
          </div>
        ) : (
          renderList(filteredItems)
        )}
      </ListContainer>
    </div>
  )
}
