import cx from 'classnames'

import { Icon } from '@cais-group/equity/atoms/icon'
import { Color, TailwindEquityColor } from '@cais-group/equity/particles/colors'
import { IconType } from '@cais-group/equity/particles/icons'

export type HelperMessageType = 'error' | 'success' | 'warning' | 'info'

const helperMessageIconColorMap: Record<HelperMessageType, Color> = {
  info: 'eq-color-primary-600',
  success: 'eq-color-success-600',
  error: 'eq-color-error-600',
  warning: 'eq-color-warning-600',
}

const helperMessageIconMap: Record<HelperMessageType, IconType> = {
  info: 'Info',
  success: 'Check',
  error: 'Error',
  warning: 'Warning',
}

const helperMessageTextColorMap: Record<
  HelperMessageType,
  TailwindEquityColor
> = {
  info: 'text-primary-600',
  success: 'text-success-700',
  error: 'text-error-700',
  warning: 'text-neutral-900',
}

export type HelperMessageProps = {
  /** An id to apply to the message div for accessibility purpose */
  id?: string
  /** The type of message to show */
  type?: HelperMessageType
  /** The message to show next to the icon */
  text: string
}

/**
 * The helper message is utilised to show text that guides a user.
 * A typical use case would be to show a user where they made an error with a text input.
 */
export const HelperMessage = ({
  id,
  type = 'error',
  text,
}: HelperMessageProps) => {
  return (
    <div className="flex w-fit items-center gap-x-8">
      <Icon
        type={helperMessageIconMap[type]}
        size="small"
        color={helperMessageIconColorMap[type]}
      />
      <div
        id={id}
        className={cx(
          'bodySmall whitespace-pre-line',
          helperMessageTextColorMap[type]
        )}
        data-testid="helper-message-text"
      >
        {text}
      </div>
    </div>
  )
}
