import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { ManageCaisIqNav } from '@cais-group/caisiq/ui/manage/nav'
import { ManageCaisIqTable } from '@cais-group/caisiq/ui/table/manage'
import { ManageTableProps } from '@cais-group/caisiq/util/type/manage-caisiq'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { CaisIqFirm } from '@cais-group/shared/util/type/caisiq-be'

const Container = styled.div`
  display: flex;
  padding: ${SPACING.s12} ${SPACING.s56} ${SPACING.s12} ${SPACING.s56};
  background-color: rgb(var(--colors-neutral-100));
  flex-direction: column;
  position: relative;
  flex-grow: 1;

  * > div.table-header:last-of-type {
    justify-content: flex-end;
  }
`

export const ManageFirms = (props: ManageTableProps<CaisIqFirm>) => {
  const navigate = useNavigate()
  const [, setSearchTerm] = useRecoilState(searchTermAtom)
  return (
    <>
      <ManageCaisIqNav placeholder="Search by firm name or ID" />
      <Container data-testid="firms-table-container">
        <ManageCaisIqTable
          tableType="firms"
          {...props}
          onRowClick={(firm) => {
            setSearchTerm('')
            navigate(ROUTES.manageEditFirm(firm.id))
          }}
          classNames={{ container: 'pt-12 pb-56' }}
        />
      </Container>
    </>
  )
}
