import { Theme } from './types'

export const colors: Theme = {
  brand: {
    '100': '#ebf1f2',
    '100-c': '#211c21',
    '200': '#d7e6e6',
    '200-c': '#211c21',
    '300': '#c3d8d9',
    '300-c': '#211c21',
    '400': '#88b1b3',
    '400-c': '#211c21',
    '500': '#619799',
    '500-c': '#211c21',
    '600': '#327174',
    '600-c': '#ffffff',
    '700': '#054b4e',
    '700-c': '#ffffff',
    '800': '#04383a',
    '800-c': '#ffffff',
    '900': '#022527',
    '900-c': '#ffffff',
  },
  primary: {
    '100': '#f6f6f6',
    '100-c': '#211c21',
    '200': '#e3e3e3',
    '200-c': '#211c21',
    '300': '#c7c6c7',
    '300-c': '#211c21',
    '400': '#b5b3b5',
    '400-c': '#211c21',
    '500': '#8f8c8f',
    '500-c': '#211c21',
    '600': '#04383a',
    '600-c': '#ffffff',
    '700': '#022527',
    '700-c': '#ffffff',
    '800': '#2e2a2e',
    '800-c': '#ffffff',
    '900': '#211c21',
    '900-c': '#ffffff',
  },
  datavis: {
    '1': '#327174',
    '1-c': '#ffffff',
    '2': '#96dbc8',
    '2-c': '#211c21',
    '3': '#ffc170',
    '3-c': '#211c21',
    '4': '#bb530a',
    '4-c': '#ffffff',
    '5': '#65162c',
    '5-c': '#ffffff',
    '6': '#c0bbf7',
    '6-c': '#211c21',
    '7': '#595c5c',
    '7-c': '#ffffff',
    '8': '#2a92ba',
    '8-c': '#211c21',
    '9': '#86d3f2',
    '9-c': '#211c21',
    '10': '#6c8f70',
    '10-c': '#211c21',
    '11': '#d1e0a5',
    '11-c': '#211c21',
    '12': '#f3d7bc',
    '12-c': '#211c21',
    '13': '#a8864d',
    '13-c': '#211c21',
    '14': '#925364',
    '14-c': '#ffffff',
    '15': '#917da3',
    '15-c': '#211c21',
    '16': '#cdd1d8',
    '16-c': '#211c21',
    '17': '#3958a1',
    '17-c': '#ffffff',
    '18': '#c0e0db',
    '18-c': '#211c21',
    '19': '#f9d974',
    '19-c': '#211c21',
    '20': '#c47774',
    '20-c': '#211c21',
    '21': '#00407b',
    '21-c': '#ffffff',
    '22': '#8cade9',
    '22-c': '#211c21',
  },
  gradient: {
    '1-1': '#c3d8d9',
    '1-1-c': '#211c21',
    '1-2': '#ebf1f2',
    '1-2-c': '#211c21',
    '2-1': '#327174',
    '2-1-c': '#ffffff',
    '2-2': '#c3d8d9',
    '2-2-c': '#ffffff',
    '3-1': '#022527',
    '3-1-c': '#ffffff',
    '3-2': '#327174',
    '3-2-c': '#ffffff',
    '4-1': '#327174',
    '4-1-c': '#ffffff',
    '4-2': '#c3d8d9',
    '4-2-c': '#ffffff',
  },
} as const
