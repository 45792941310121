import cx from 'classnames'
import React from 'react'

import { ReactComponent as ChevronDown } from '../assets/chevron-down.svg'
import { ReactComponent as ChevronRight } from '../assets/chevron-right.svg'
import { ReactComponent as Hamburger } from '../assets/hamburger.svg'
import { prepareEnvData } from '../prepareEnvData'
import { EnvType, NavItem, NavItemMenu } from '../types'

import { NavLink } from './nav-link'

export const MobileMenu = ({ navItems }: { navItems: NavItem[] }) => {
  const envData = prepareEnvData()
  const ref = React.useRef<HTMLButtonElement>(null)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const toggleMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsMenuOpen((o) => !o)
  }
  const handleNavItemClick = React.useCallback(() => {
    setIsMenuOpen(false)
    if (ref?.current) {
      ref.current?.focus({
        preventScroll: true,
      })
    }
  }, [])

  React.useEffect(() => {
    if (ref?.current)
      ref.current?.focus({
        preventScroll: true,
      })
  }, [])

  return (
    <div className="bg-neutral-0 z-30 min-[1311px]:hidden">
      <button
        className="p-0"
        onClick={toggleMenu}
        aria-haspopup="menu"
        aria-expanded={isMenuOpen}
        aria-controls="hamburger-menu"
        data-testid="mobile-menu-button"
        ref={ref}
      >
        <Hamburger className="fill-neutral-600" />
      </button>
      {isMenuOpen && (
        <ul
          className="bg-neutral-0 absolute bottom-0 left-0 right-0 top-[theme(constants.mainMenu.height)px] h-screen space-y-32 overflow-y-auto p-32 pl-24"
          id="hamburger-menu"
          aria-hidden={!isMenuOpen}
          role="menu"
          data-testid="mobile-menu"
        >
          {navItems.map((navItem) => {
            return 'menu' in navItem ? (
              <SubMenu
                navItem={navItem}
                key={navItem.name}
                onClick={handleNavItemClick}
                trackMenuLabel={navItem.name}
                env={envData}
              />
            ) : (
              <NavLink
                to={navItem.href}
                className="h5Strong block text-neutral-600 outline-none ring-offset-4 focus-visible:text-neutral-900 focus-visible:ring"
                key={navItem.href}
                urlType={navItem.urlType}
                onClick={handleNavItemClick}
                env={envData}
              >
                {navItem.name}
              </NavLink>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const SubMenu = ({
  navItem,
  onClick,
  trackMenuLabel,
  env,
}: {
  navItem: NavItemMenu
  onClick: (to: string) => void
  trackMenuLabel?: string
  env: EnvType
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false)
  const toggleSubMenuMenu = () => setIsSubMenuOpen((o) => !o)

  return (
    <div>
      <button
        onClick={toggleSubMenuMenu}
        className={cx(
          'h5Strong group flex w-full items-center justify-between p-0 text-neutral-600 outline-none ring-offset-4 focus-visible:text-neutral-900 focus-visible:ring',
          {
            'text-neutral-900': isSubMenuOpen,
          }
        )}
        aria-haspopup="menu"
        aria-expanded={isSubMenuOpen}
        aria-controls="hamburger-menu-sub-menu"
        data-testid={`subMenu${navItem.name.replace(' ', '')}`}
      >
        {navItem.name}
        {isSubMenuOpen ? (
          <ChevronDown className="fill-neutral-900" aria-hidden />
        ) : (
          <ChevronRight
            className="fill-neutral-600 group-focus-visible:fill-neutral-900"
            aria-hidden
          />
        )}
      </button>
      {navItem.menu && isSubMenuOpen && (
        <ul
          className="w-full list-none divide-y divide-solid divide-neutral-200 px-12 pt-32 [&>*]:border-0"
          id="hamburger-menu-sub-menu"
          aria-hidden={!isSubMenuOpen}
          role="menu"
        >
          {navItem.menu.groups.map((group, idx) => (
            <li
              className="py-16 last:pb-0 first-of-type:pt-0"
              role="presentation"
              key={idx}
            >
              {group.name && (
                <p className="caption w-fit px-12 py-8 uppercase text-neutral-600">
                  {group.name}
                </p>
              )}
              <ul className="list-none" role="group">
                {group.items.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className="body focus-visible:bg-14 block px-12 py-8 text-neutral-900 outline-none focus-visible:ring"
                      urlType={item.urlType}
                      onClick={() => onClick(item.href)}
                      data-track-item_name={
                        trackMenuLabel
                          ? `${trackMenuLabel} - ${item.name}`
                          : item.name
                      }
                      env={env}
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
