import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

const AVERAGE_MONTH_LENTH = 365 / 12

export const getDurationString = (milliseconds: number) => {
  if (!milliseconds || milliseconds === 0) {
    return '-'
  }
  dayjs.extend(duration)
  let totalDuration = ''
  let years = 0
  let days = 0
  let hours = 0
  let minutes = 0
  const time = dayjs.duration(milliseconds)

  if (time.years() > 0) {
    years = time.years()
    totalDuration += `${years}yr `
  }
  if (time.months() > 0) {
    days += time.months() * AVERAGE_MONTH_LENTH
  }

  if (time.days() > 0) {
    days += time.days()
    days = Math.floor(days)

    if (days >= 365) {
      years += Math.round(days / 365)
      days += Math.floor(days % 365)
    }
    totalDuration += `${days}d `
  }

  if (time.hours() > 0) {
    hours = time.hours()
    totalDuration += `${hours}hr `
  }

  if (
    (time.minutes() > 0 && time.days() > 0 && time.hours() <= 0) ||
    (time.hours() >= 0 && time.days() <= 0)
  ) {
    minutes = time.minutes()
    totalDuration += `${minutes}m`
  }
  return totalDuration.trim()
}
