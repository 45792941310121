import { LogoutOptions } from '@auth0/auth0-react'

import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { acpService } from '@cais-group/caisiq/util/acp-service'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'

/**
 * @todo: Can we initialise this service with the logout method from auth0,
 * instead of passing it into the logout method.
 *
 * We should then pass the LogoutOptions only, optionally, with the default
 * being only a returnTo (but allow it to override as needed)
 */
export const logoutService = {
  logout: (auth0Logout: (o?: LogoutOptions) => void) => {
    trackingService.logoutClicked()
    trackingService.endSession()

    setTimeout(() => {
      acpService.reset()
      experienceService.reset()
      // Do this 'next tick'
      setTimeout(() => {
        auth0Logout({
          logoutParams: {
            returnTo: `${window.location.origin}${ROUTES.home}`,
          },
        })
      }, 0)
    }, 100)
  },
}
