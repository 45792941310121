import cx from 'classnames'
import { useMemo } from 'react'

import { EquityText } from '@cais-group/equity/atoms/markdown'
import { RadioGroup } from '@cais-group/equity/atoms/radio-group'
import { Field } from '@cais-group/equity/molecules/field'

import { generateGridClasses } from '../components/field-surround'
import { staticGridClassDict } from '../form-grid'
import { AsyncOptions, OptionsOrAsyncOptions } from '../hooks/async-options'
import type { UniformServices } from '../services'
import {
  UniformAny,
  UniformFieldLayoutProps,
  UniformFieldOption,
  UniformFieldProps,
} from '../types'

import { UniformAdapter } from './adapter'

type RadioGroupComponentProps<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
> = {
  field: RadioGroupInputProps<TFormFieldIds, TUniformServices>
  options: UniformFieldOption[]
}
const RadioGroupComponent = <
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
>({
  field,
  options,
}: RadioGroupComponentProps<TFormFieldIds, TUniformServices>) => {
  const gridClassName = useMemo(() => {
    return generateGridClasses(staticGridClassDict, {
      cols: field.layout ?? 12,
    })
  }, [field])

  return (
    <UniformAdapter
      field={field}
      render={({
        uniform: { error, id, isDisabled, required, tag },
        field: { ref, value, onChange, onBlur },
      }) => {
        return (
          <Field
            id={id}
            htmlFor={id}
            label={field.label ?? ''}
            required={!!required}
            supportingText={error?.message}
            tag={tag?.children !== '' ? tag : undefined}
            helper={
              error?.message
                ? { type: 'error', text: error.message }
                : undefined
            }
            description={field.tooltip}
          >
            <div
              className={cx('grid grid-cols-12 gap-x-24 gap-y-8', {
                'border-error-500 border-1': error,
              })}
            >
              {options.map((option) => (
                <div
                  key={option.value}
                  className={cx(
                    'col-span-full flex items-start',
                    gridClassName
                  )}
                  data-checked={value === option.value}
                >
                  <RadioGroup.Option
                    id={`${id}-${option.value}`}
                    ref={ref}
                    name={id}
                    value={option.value}
                    disabled={isDisabled || option?.disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    selectedValue={value}
                    hasBorder={field?.border || option?.border}
                  >
                    <div className="grow-1 flex flex-col gap-x-16">
                      <div className="text-neutral-900">{option.label}</div>
                      {option.description && (
                        <div className="text-neutral-600">
                          {option.description}
                        </div>
                      )}
                    </div>
                  </RadioGroup.Option>
                </div>
              ))}
            </div>
          </Field>
        )
      }}
    />
  )
}

export type RadioGroupInputProps<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
> = UniformFieldProps<'RadioGroupInput'> & {
  id: TFormFieldIds
  value?: string
  label?: EquityText
  layout?: UniformFieldLayoutProps
  border?: boolean
} & OptionsOrAsyncOptions<TUniformServices>
export const RadioGroupInput = <
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
>(
  field: RadioGroupInputProps<TFormFieldIds, TUniformServices>
) => {
  return (
    <AsyncOptions
      field={field}
      asyncOptions={({ value: options = [] }) => (
        <RadioGroupComponent field={field} options={options} />
      )}
      options={(options) => (
        <RadioGroupComponent field={field} options={options} />
      )}
    />
  )
}

export default RadioGroupInput
