import styled from '@emotion/styled'
import {
  Location,
  useLocation,
  useNavigate,
  useMatch,
  PathMatch,
} from 'react-router-dom'

import {
  Overline,
  shouldForwardProp,
  SubtitleMono,
  H3Strong,
} from '@cais-group/approved/ui/typography'
import { ContentStack } from '@cais-group/caisiq/ui/content-stack'
import { NavigationProps } from '@cais-group/caisiq/ui/navigation'
import { getLoneOrMfePath } from '@cais-group/caisiq/util/js/get-lone-or-mfe-path'
import { BackPillButton } from '@cais-group/equity/atoms/pill'
import { colors, setOpacity } from '@cais-group/equity/particles/colors'
import { LayoutCenter } from '@cais-group/shared/ui/layout'

export enum PageType {
  WHITEPAPER = 'Whitepaper',
  PRESENTATION = 'Presentation',
  ARTICLE = 'Article',
  GUIDE = 'Guide',
}

export const StyledCenter = styled(LayoutCenter)`
  display: grid;
  grid-template-columns: clamp(16rem, calc(96vw - 2 * var(--s24)), 104rem) 1fr;
  padding-left: 0;
  padding-right: 0;
`

export type ArticleHeaderProps = Omit<
  NavigationProps,
  'headerThemeName' | 'userName'
> & {
  image: string
  title: string
  date: string
  typePage: PageType
  author: string
  hasFullAuthor: boolean
}

const Container = styled.div<ArticleHeaderProps>``

const Title = styled(H3Strong)`
  width: calc(100vw - calc(var(--s24)));
  position: relative;
  left: -0.25rem;
  margin-top: var(--s8);
  width: 100%;

  @media screen and (min-width: 828px) {
    // Width needs to account for fake columns
    width: calc(100vw - 518px);
  }
  @media screen and (min-width: 1280px) {
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    font-size: var(--s32);
    line-height: var(--s56);
  }
  @media screen and (max-width: 700px) {
    font-size: var(--s24);
    line-height: var(--s32);
  }
`

const HeroContainer = styled.div`
  position: relative;
  background-color: transparent;
  padding: var(--s24) var(--s24) var(--s32) var(--s24);
  & * {
    text-rendering: geometricPrecision; // Fix light text thickness
  }
  @media screen and (min-width: 960px) {
    padding: var(--s56) var(--s56) var(--s32) var(--s56);
  }
  @media screen and (min-width: 1801px) {
    display: flex;
    justify-content: center;
  }
`

const BackgroundImage = styled.div<{ backgroundImageUrl?: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${({ backgroundImageUrl }) =>
    backgroundImageUrl ? `background-image: url(${backgroundImageUrl});` : ''}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: var(--s32) var(--s56);
  & * {
    text-rendering: geometricPrecision; // Fix light text thickness
    color: rgb(var(--colors-neutral-0));
  }
`

const TitleInfo = styled(LayoutCenter, {
  shouldForwardProp: shouldForwardProp('$hasImage'),
})<{ $hasImage: boolean }>`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  ${({ $hasImage }) => ($hasImage ? `margin-top: 311px;` : 'margin-top: 0;')}
  & * {
    ${({ $hasImage }) =>
      $hasImage
        ? `color: rgb(var(--colors-neutral-0));`
        : 'color: var(--color-black);'}
  }

  @media screen and (min-width: 960px) {
    ${({ $hasImage }) =>
      $hasImage ? `margin-top: var(--s144);` : 'margin-top: 0;'}
  }
`

const FadeBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40%;
  background: linear-gradient(
    0deg,
    rgb(0 0 0 / 54%) 0%,
    ${setOpacity('eq-color-neutral-900', 0.005)} 100%
  );

  @media screen and (max-width: 860px) {
    height: 5%;
  }
`

const Foreground = styled.div`
  position: relative;
  display: grid;
  grid-template-columns:
    1fr
    clamp(calc(32rem, 96vw - 2 * var(--s24)), 104rem)
    1fr;
`

const Line = styled.div`
  height: 2px;
  background-color: rgb(var(--colors-warning-600));
  width: var(--s56);
  margin-top: -38px;
  margin-bottom: calc(var(--s24) * 2);
  @media screen and (max-width: 1260px) {
    margin-top: var(--s56);
  }
`

const StyledBackPillButton = styled(BackPillButton)<{
  $hasImage: boolean
}>`
  color: rgb(var(--colors-neutral-900));
  ${({ $hasImage }) => {
    return $hasImage
      ? [
          `background-color: rgba(255, 255, 255, 0.2);
          &:hover {
            background-color: ${colors['eq-color-neutral-0']};
          }`,
        ]
      : [
          `background-color: rgb(var(--colors-neutral-100));
          &:hover {
            background-color: ${colors['eq-color-neutral-200']};
          }`,
        ]
  }}
`

const Wrapper = styled.div`
  width: calc(100vw - calc(var(--s24) * 2));
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 960px) {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

const FakeColumn = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    margin-top: var(--s56);
    margin-right: var(--s56);
    display: inline;
    width: 320px;
    margin-left: -28px;
    > * {
      margin-bottom: var(--s24);
    }
  }
  @media screen and (min-width: 1280px) {
    width: 320px;
    margin-left: var(--s88);
    margin-right: calc(var(--s88) + var(--s32));
  }
`

interface LocationState {
  from: string
}

// If the user came from the articles page the `location.state` will have a `from` property.
const hasMatchAndFrom = (
  match: PathMatch<'resource'> | null,
  location: Location
) =>
  match?.params?.resource &&
  (location?.state as LocationState)?.from?.includes(match.params.resource)

export const ArticleHeader = (props: ArticleHeaderProps) => {
  const { image, title, date, typePage, author, hasFullAuthor } = props
  const match = useMatch(getLoneOrMfePath(`authenticated/:resource/*`))

  const location = useLocation()
  const navigate = useNavigate()

  const buttonText = hasMatchAndFrom(match, location)
    ? `All ${match?.params['resource']}`
    : 'Back'

  const showFakeColumn = typePage !== PageType.GUIDE

  return (
    <Container {...props}>
      <HeroContainer>
        {image && (
          <>
            <BackgroundImage backgroundImageUrl={image}></BackgroundImage>
            <FadeBackground></FadeBackground>
          </>
        )}
        <Foreground>
          <StyledBackPillButton
            // When a hash is present in the URL we want to navigate back to the grid view not the top of the page.
            // This would be the case when a user clicks on a footnote link.
            onClick={() => navigate(location.hash ? -2 : -1)}
            label={buttonText}
            $hasImage={!!image}
          />

          <StyledCenter>
            <Wrapper>
              {showFakeColumn && <FakeColumn />}
              <ContentStack fakeColumn={showFakeColumn}>
                <TitleInfo as="section" $hasImage={!!image}>
                  {!image && <Line></Line>}
                  <Overline>{typePage}</Overline>
                  <Title>{title}</Title>
                  {typePage !== PageType.GUIDE && (
                    <SubtitleMono>
                      {date}{' '}
                      {!image || !hasFullAuthor ? ` • by ${author}` : null}
                    </SubtitleMono>
                  )}
                </TitleInfo>
              </ContentStack>
            </Wrapper>
          </StyledCenter>
        </Foreground>
      </HeroContainer>
    </Container>
  )
}
