import { RichTextBase } from '@cais-group/shared/ui/contentful/rich-text-base'
import type { RichTextOptionalProps } from '@cais-group/shared/ui/contentful/rich-text-base'

import { richTextRenderOptions } from './caisiq-ui-get-rich-text-render-options'

export function RichText(props: RichTextOptionalProps) {
  const { renderOptions, ...rest } = props
  return (
    <RichTextBase
      renderOptions={renderOptions || richTextRenderOptions}
      {...rest}
    />
  )
}
