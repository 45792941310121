import { useQueryClient } from 'react-query'

import { pathOr } from '../utils/path-or'

type Result = {
  pages: unknown[]
}
export type UseQueryClientDataProps = {
  fieldId?: string
  service?: string
  path?: string
}
/** @deprecated */
export const useQueryClientData = ({
  queries,
  enabled = false,
}: UseQueryClientDataProps & {
  queries: UseQueryClientDataProps[]
  enabled?: boolean
}): Record<string, unknown[]> => {
  const client = useQueryClient()

  if (!enabled) return {}

  return queries.reduce<Record<string, unknown[]>>(
    (acc, { fieldId = '', service, path }) => {
      if (!service || !path) return acc

      const [[, result]] = client.getQueriesData(service)

      if (
        result &&
        typeof result === 'object' &&
        'pages' in result &&
        Array.isArray((result as Result).pages)
      ) {
        const items = (result as Result).pages
          .flatMap((page) => pathOr(undefined, path.split('.'), page))
          .filter(Boolean)
        return { ...acc, [fieldId]: items }
      } else {
        return acc
      }
    },
    {}
  )
}
