/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Investor Profile API
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { fetchInstance, ErrorType } from '@cais-group/shared/util/fetch'

import type {
  AccountAdvisorNotificationsRequestV1,
  AccountBenefitPlanAndERISARequestV1,
  AccountDataReadinessRequestV1,
  AccountDataReadinessResponseV1,
  AccountDataReadinessSkippedFieldsV1,
  AccountDataRequirementsDictionaryV1,
  AccountDataRequirementsResponseV1,
  AccountDocumentsDataReadinessResponseV1,
  AccountEligibilityAndAffiliationsRequestV1,
  AccountEligibilityQuestionsRequestV1,
  AccountKYCAndTaxRequestV1,
  AccountListItemForContactV1,
  AccountSignatoriesAndOtherAccountRolesRequestV1,
  AccountTypesV1,
  AccountV1,
  AccountWireInstructionsRequestV1,
  AccountsSearchRequestV1,
  AdminAccountVersionUpdateRequest,
  AdminAccountVersionUpdateResponse,
  BaseAccountV1,
  ContactResponseV1,
  ContactsSearchRequestV1,
  CreateContactRequestV1,
  CreateHouseholdRequestV1,
  CreateInterestedPartyRequestV1,
  DownloadDocumentResponseV1,
  ErrorResponse,
  FileDetailsRequestV1,
  FileUpdateRequestV1,
  GetAccountParams,
  GetAccountVersionParams,
  HouseholdResponseV1,
  HouseholdsSearchRequestV1,
  InterestedPartyResponseV1,
  KycDocumentDetailsV1,
  KycDocumentFileTypesResponseV1,
  MoveHouseholdRequestV1,
  PagedAccountListItemsV1,
  PagedContactListItemsV1,
  PagedHouseholdsV1,
  UpdateContactRequestV1,
  UpdateHouseholdRequestV1,
  UpdateInterestedPartyRequestV1,
  UploadDocumentRequestV1,
  UploadDocumentResponseV1,
} from './api.schemas'

/**
 * @summary Create household
 */
export const createHousehold = (
  createHouseholdRequestV1: CreateHouseholdRequestV1
) => {
  return fetchInstance<HouseholdResponseV1>({
    url: `/api/investor-profile/v1/households`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createHouseholdRequestV1,
  })
}

export const getCreateHouseholdMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createHousehold>>,
    TError,
    { data: CreateHouseholdRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createHousehold>>,
  TError,
  { data: CreateHouseholdRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createHousehold>>,
    { data: CreateHouseholdRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return createHousehold(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateHouseholdMutationResult = NonNullable<
  Awaited<ReturnType<typeof createHousehold>>
>
export type CreateHouseholdMutationBody = CreateHouseholdRequestV1
export type CreateHouseholdMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create household
 */
export const useCreateHousehold = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createHousehold>>,
    TError,
    { data: CreateHouseholdRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateHouseholdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get the last version of a specific household by their householdId
 */
export const getHousehold = (householdId: string, signal?: AbortSignal) => {
  return fetchInstance<HouseholdResponseV1>({
    url: `/api/investor-profile/v1/households/${householdId}`,
    method: 'GET',
    signal,
  })
}

export const getGetHouseholdQueryKey = (householdId: string) => {
  return [`/api/investor-profile/v1/households/${householdId}`] as const
}

export const getGetHouseholdQueryOptions = <
  TData = Awaited<ReturnType<typeof getHousehold>>,
  TError = ErrorType<ErrorResponse>
>(
  householdId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getHousehold>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetHouseholdQueryKey(householdId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHousehold>>> = ({
    signal,
  }) => getHousehold(householdId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!householdId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getHousehold>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetHouseholdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHousehold>>
>
export type GetHouseholdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get the last version of a specific household by their householdId
 */
export const useGetHousehold = <
  TData = Awaited<ReturnType<typeof getHousehold>>,
  TError = ErrorType<ErrorResponse>
>(
  householdId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getHousehold>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetHouseholdQueryOptions(householdId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a household by id.
 */
export const updateHousehold = (
  householdId: string,
  updateHouseholdRequestV1: UpdateHouseholdRequestV1
) => {
  return fetchInstance<HouseholdResponseV1>({
    url: `/api/investor-profile/v1/households/${householdId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateHouseholdRequestV1,
  })
}

export const getUpdateHouseholdMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateHousehold>>,
    TError,
    { householdId: string; data: UpdateHouseholdRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateHousehold>>,
  TError,
  { householdId: string; data: UpdateHouseholdRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateHousehold>>,
    { householdId: string; data: UpdateHouseholdRequestV1 }
  > = (props) => {
    const { householdId, data } = props ?? {}

    return updateHousehold(householdId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateHouseholdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateHousehold>>
>
export type UpdateHouseholdMutationBody = UpdateHouseholdRequestV1
export type UpdateHouseholdMutationError = ErrorType<ErrorResponse>

export const useUpdateHousehold = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateHousehold>>,
    TError,
    { householdId: string; data: UpdateHouseholdRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateHouseholdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Admin feature: move a household by id and associate it to different teams. This operation has profound impact on the household's ownership and of the resources associated to it: - contacts. - accounts. - downstream resources associated to household, contacts and accounts. **Permission:** `cais:tech-ops:admin`

 * @summary Move a household to a different firm and team
 */
export const moveHousehold = (
  householdId: string,
  moveHouseholdRequestV1: MoveHouseholdRequestV1
) => {
  return fetchInstance<HouseholdResponseV1>({
    url: `/api/investor-profile/v1/households/${householdId}/move`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: moveHouseholdRequestV1,
  })
}

export const getMoveHouseholdMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof moveHousehold>>,
    TError,
    { householdId: string; data: MoveHouseholdRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof moveHousehold>>,
  TError,
  { householdId: string; data: MoveHouseholdRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof moveHousehold>>,
    { householdId: string; data: MoveHouseholdRequestV1 }
  > = (props) => {
    const { householdId, data } = props ?? {}

    return moveHousehold(householdId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type MoveHouseholdMutationResult = NonNullable<
  Awaited<ReturnType<typeof moveHousehold>>
>
export type MoveHouseholdMutationBody = MoveHouseholdRequestV1
export type MoveHouseholdMutationError = ErrorType<ErrorResponse>

/**
 * @summary Move a household to a different firm and team
 */
export const useMoveHousehold = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof moveHousehold>>,
    TError,
    { householdId: string; data: MoveHouseholdRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getMoveHouseholdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get the list of contacts associated with the household
 */
export const getHouseholdContacts = (
  householdId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<ContactResponseV1[]>({
    url: `/api/investor-profile/v1/households/${householdId}/contacts`,
    method: 'GET',
    signal,
  })
}

export const getGetHouseholdContactsQueryKey = (householdId: string) => {
  return [
    `/api/investor-profile/v1/households/${householdId}/contacts`,
  ] as const
}

export const getGetHouseholdContactsQueryOptions = <
  TData = Awaited<ReturnType<typeof getHouseholdContacts>>,
  TError = ErrorType<ErrorResponse>
>(
  householdId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getHouseholdContacts>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetHouseholdContactsQueryKey(householdId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getHouseholdContacts>>
  > = ({ signal }) => getHouseholdContacts(householdId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!householdId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getHouseholdContacts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetHouseholdContactsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHouseholdContacts>>
>
export type GetHouseholdContactsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get the list of contacts associated with the household
 */
export const useGetHouseholdContacts = <
  TData = Awaited<ReturnType<typeof getHouseholdContacts>>,
  TError = ErrorType<ErrorResponse>
>(
  householdId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getHouseholdContacts>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetHouseholdContactsQueryOptions(householdId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create a contact for a household.
 */
export const createContact = (
  householdId: string,
  createContactRequestV1: CreateContactRequestV1
) => {
  return fetchInstance<ContactResponseV1>({
    url: `/api/investor-profile/v1/households/${householdId}/contacts`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createContactRequestV1,
  })
}

export const getCreateContactMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createContact>>,
    TError,
    { householdId: string; data: CreateContactRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createContact>>,
  TError,
  { householdId: string; data: CreateContactRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createContact>>,
    { householdId: string; data: CreateContactRequestV1 }
  > = (props) => {
    const { householdId, data } = props ?? {}

    return createContact(householdId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateContactMutationResult = NonNullable<
  Awaited<ReturnType<typeof createContact>>
>
export type CreateContactMutationBody = CreateContactRequestV1
export type CreateContactMutationError = ErrorType<ErrorResponse>

export const useCreateContact = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createContact>>,
    TError,
    { householdId: string; data: CreateContactRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateContactMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get the list of households the logged in user has access to.
 * @summary Search households
 */
export const searchHouseholds = (
  householdsSearchRequestV1: HouseholdsSearchRequestV1
) => {
  return fetchInstance<PagedHouseholdsV1>({
    url: `/api/investor-profile/v1/households/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: householdsSearchRequestV1,
  })
}

export const getSearchHouseholdsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchHouseholds>>,
    TError,
    { data: HouseholdsSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchHouseholds>>,
  TError,
  { data: HouseholdsSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchHouseholds>>,
    { data: HouseholdsSearchRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return searchHouseholds(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchHouseholdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchHouseholds>>
>
export type SearchHouseholdsMutationBody = HouseholdsSearchRequestV1
export type SearchHouseholdsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Search households
 */
export const useSearchHouseholds = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchHouseholds>>,
    TError,
    { data: HouseholdsSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchHouseholdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get the latest version of a contact by its ID
 */
export const getContact = (contactId: string, signal?: AbortSignal) => {
  return fetchInstance<ContactResponseV1>({
    url: `/api/investor-profile/v1/contacts/${contactId}`,
    method: 'GET',
    signal,
  })
}

export const getGetContactQueryKey = (contactId: string) => {
  return [`/api/investor-profile/v1/contacts/${contactId}`] as const
}

export const getGetContactQueryOptions = <
  TData = Awaited<ReturnType<typeof getContact>>,
  TError = ErrorType<ErrorResponse>
>(
  contactId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getContact>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetContactQueryKey(contactId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContact>>> = ({
    signal,
  }) => getContact(contactId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!contactId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getContact>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetContactQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContact>>
>
export type GetContactQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get the latest version of a contact by its ID
 */
export const useGetContact = <
  TData = Awaited<ReturnType<typeof getContact>>,
  TError = ErrorType<ErrorResponse>
>(
  contactId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getContact>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetContactQueryOptions(contactId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a household contact by id.
 */
export const updateContact = (
  contactId: string,
  updateContactRequestV1: UpdateContactRequestV1
) => {
  return fetchInstance<ContactResponseV1>({
    url: `/api/investor-profile/v1/contacts/${contactId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateContactRequestV1,
  })
}

export const getUpdateContactMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContact>>,
    TError,
    { contactId: string; data: UpdateContactRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateContact>>,
  TError,
  { contactId: string; data: UpdateContactRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateContact>>,
    { contactId: string; data: UpdateContactRequestV1 }
  > = (props) => {
    const { contactId, data } = props ?? {}

    return updateContact(contactId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateContactMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateContact>>
>
export type UpdateContactMutationBody = UpdateContactRequestV1
export type UpdateContactMutationError = ErrorType<ErrorResponse>

export const useUpdateContact = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContact>>,
    TError,
    { contactId: string; data: UpdateContactRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateContactMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get a specific version of a contact by its ID
 */
export const getContactVersion = (
  contactId: string,
  version: number,
  signal?: AbortSignal
) => {
  return fetchInstance<ContactResponseV1>({
    url: `/api/investor-profile/v1/contacts/${contactId}/versions/${version}`,
    method: 'GET',
    signal,
  })
}

export const getGetContactVersionQueryKey = (
  contactId: string,
  version: number
) => {
  return [
    `/api/investor-profile/v1/contacts/${contactId}/versions/${version}`,
  ] as const
}

export const getGetContactVersionQueryOptions = <
  TData = Awaited<ReturnType<typeof getContactVersion>>,
  TError = ErrorType<ErrorResponse>
>(
  contactId: string,
  version: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getContactVersion>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetContactVersionQueryKey(contactId, version)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getContactVersion>>
  > = ({ signal }) => getContactVersion(contactId, version, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(contactId && version),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getContactVersion>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetContactVersionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContactVersion>>
>
export type GetContactVersionQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a specific version of a contact by its ID
 */
export const useGetContactVersion = <
  TData = Awaited<ReturnType<typeof getContactVersion>>,
  TError = ErrorType<ErrorResponse>
>(
  contactId: string,
  version: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getContactVersion>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetContactVersionQueryOptions(
    contactId,
    version,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get accounts associated with the contact
 */
export const getContactAccounts = (contactId: string, signal?: AbortSignal) => {
  return fetchInstance<AccountListItemForContactV1[]>({
    url: `/api/investor-profile/v1/contacts/${contactId}/accounts`,
    method: 'GET',
    signal,
  })
}

export const getGetContactAccountsQueryKey = (contactId: string) => {
  return [`/api/investor-profile/v1/contacts/${contactId}/accounts`] as const
}

export const getGetContactAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof getContactAccounts>>,
  TError = ErrorType<ErrorResponse>
>(
  contactId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getContactAccounts>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetContactAccountsQueryKey(contactId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getContactAccounts>>
  > = ({ signal }) => getContactAccounts(contactId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!contactId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getContactAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetContactAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContactAccounts>>
>
export type GetContactAccountsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get accounts associated with the contact
 */
export const useGetContactAccounts = <
  TData = Awaited<ReturnType<typeof getContactAccounts>>,
  TError = ErrorType<ErrorResponse>
>(
  contactId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getContactAccounts>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetContactAccountsQueryOptions(contactId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get the list of contacts the logged in user has access to.
 * @summary Search contacts
 */
export const searchContacts = (
  contactsSearchRequestV1: ContactsSearchRequestV1
) => {
  return fetchInstance<PagedContactListItemsV1>({
    url: `/api/investor-profile/v1/contacts/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: contactsSearchRequestV1,
  })
}

export const getSearchContactsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchContacts>>,
    TError,
    { data: ContactsSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchContacts>>,
  TError,
  { data: ContactsSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchContacts>>,
    { data: ContactsSearchRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return searchContacts(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchContactsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchContacts>>
>
export type SearchContactsMutationBody = ContactsSearchRequestV1
export type SearchContactsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Search contacts
 */
export const useSearchContacts = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchContacts>>,
    TError,
    { data: ContactsSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchContactsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get a list of possible account types (categories) and sub types
 */
export const getAccountTypes = (signal?: AbortSignal) => {
  return fetchInstance<AccountTypesV1>({
    url: `/api/investor-profile/v1/account-types`,
    method: 'GET',
    signal,
  })
}

export const getGetAccountTypesQueryKey = () => {
  return [`/api/investor-profile/v1/account-types`] as const
}

export const getGetAccountTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccountTypes>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAccountTypes>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccountTypesQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountTypes>>> = ({
    signal,
  }) => getAccountTypes(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccountTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAccountTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountTypes>>
>
export type GetAccountTypesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a list of possible account types (categories) and sub types
 */
export const useGetAccountTypes = <
  TData = Awaited<ReturnType<typeof getAccountTypes>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAccountTypes>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAccountTypesQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Note: There are 3 different types of accounts that can be created.

  To construct the request bodies, please see the schema documentation relevant for the account type: 
 - IndividualV1 
 - NonIndividualV1 
 - TaxExemptOrganizationV1

To view the response types for each account please see the schema documentation for:

- IndividualAccountV1
 - NonIndividualAccountV1
 - TaxExemptOrganizationAccountV1
 
 * @summary Create account
 */
export const createAccount = (
  householdId: string,
  baseAccountV1: BaseAccountV1
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/households/${householdId}/accounts`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: baseAccountV1,
  })
}

export const getCreateAccountMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAccount>>,
    TError,
    { householdId: string; data: BaseAccountV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAccount>>,
  TError,
  { householdId: string; data: BaseAccountV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAccount>>,
    { householdId: string; data: BaseAccountV1 }
  > = (props) => {
    const { householdId, data } = props ?? {}

    return createAccount(householdId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof createAccount>>
>
export type CreateAccountMutationBody = BaseAccountV1
export type CreateAccountMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create account
 */
export const useCreateAccount = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAccount>>,
    TError,
    { householdId: string; data: BaseAccountV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateAccountMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get the latest version of an account by its ID
 */
export const getAccount = (
  accountId: string,
  params?: GetAccountParams,
  signal?: AbortSignal
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetAccountQueryKey = (
  accountId: string,
  params?: GetAccountParams
) => {
  return [
    `/api/investor-profile/v1/accounts/${accountId}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetAccountQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccount>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  params?: GetAccountParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccount>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetAccountQueryKey(accountId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccount>>> = ({
    signal,
  }) => getAccount(accountId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!accountId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccount>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAccountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccount>>
>
export type GetAccountQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get the latest version of an account by its ID
 */
export const useGetAccount = <
  TData = Awaited<ReturnType<typeof getAccount>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  params?: GetAccountParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccount>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAccountQueryOptions(accountId, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update an account by id.
 */
export const updateAccount = (
  accountId: string,
  baseAccountV1: BaseAccountV1
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: baseAccountV1,
  })
}

export const getUpdateAccountMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccount>>,
    TError,
    { accountId: string; data: BaseAccountV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccount>>,
  TError,
  { accountId: string; data: BaseAccountV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccount>>,
    { accountId: string; data: BaseAccountV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return updateAccount(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAccount>>
>
export type UpdateAccountMutationBody = BaseAccountV1
export type UpdateAccountMutationError = ErrorType<ErrorResponse>

export const useUpdateAccount = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccount>>,
    TError,
    { accountId: string; data: BaseAccountV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateAccountMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Update account advisor notifications
 */
export const updateAccountAdvisorNotifications = (
  accountId: string,
  accountAdvisorNotificationsRequestV1: AccountAdvisorNotificationsRequestV1
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/advisor-notifications`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: accountAdvisorNotificationsRequestV1,
  })
}

export const getUpdateAccountAdvisorNotificationsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountAdvisorNotifications>>,
    TError,
    { accountId: string; data: AccountAdvisorNotificationsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccountAdvisorNotifications>>,
  TError,
  { accountId: string; data: AccountAdvisorNotificationsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccountAdvisorNotifications>>,
    { accountId: string; data: AccountAdvisorNotificationsRequestV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return updateAccountAdvisorNotifications(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateAccountAdvisorNotificationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAccountAdvisorNotifications>>
>
export type UpdateAccountAdvisorNotificationsMutationBody =
  AccountAdvisorNotificationsRequestV1
export type UpdateAccountAdvisorNotificationsMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Update account advisor notifications
 */
export const useUpdateAccountAdvisorNotifications = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountAdvisorNotifications>>,
    TError,
    { accountId: string; data: AccountAdvisorNotificationsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateAccountAdvisorNotificationsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Update account eligibility and affiliations
 */
export const updateAccountEligibilityAndAffiliations = (
  accountId: string,
  accountEligibilityAndAffiliationsRequestV1: AccountEligibilityAndAffiliationsRequestV1
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/eligibility-affiliations`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: accountEligibilityAndAffiliationsRequestV1,
  })
}

export const getUpdateAccountEligibilityAndAffiliationsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountEligibilityAndAffiliations>>,
    TError,
    { accountId: string; data: AccountEligibilityAndAffiliationsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccountEligibilityAndAffiliations>>,
  TError,
  { accountId: string; data: AccountEligibilityAndAffiliationsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccountEligibilityAndAffiliations>>,
    { accountId: string; data: AccountEligibilityAndAffiliationsRequestV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return updateAccountEligibilityAndAffiliations(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateAccountEligibilityAndAffiliationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAccountEligibilityAndAffiliations>>
>
export type UpdateAccountEligibilityAndAffiliationsMutationBody =
  AccountEligibilityAndAffiliationsRequestV1
export type UpdateAccountEligibilityAndAffiliationsMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Update account eligibility and affiliations
 */
export const useUpdateAccountEligibilityAndAffiliations = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountEligibilityAndAffiliations>>,
    TError,
    { accountId: string; data: AccountEligibilityAndAffiliationsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateAccountEligibilityAndAffiliationsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Update account KYC and tax information
 */
export const updateAccountKYCAndTaxInformation = (
  accountId: string,
  accountKYCAndTaxRequestV1: AccountKYCAndTaxRequestV1
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/kyc-tax`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: accountKYCAndTaxRequestV1,
  })
}

export const getUpdateAccountKYCAndTaxInformationMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountKYCAndTaxInformation>>,
    TError,
    { accountId: string; data: AccountKYCAndTaxRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccountKYCAndTaxInformation>>,
  TError,
  { accountId: string; data: AccountKYCAndTaxRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccountKYCAndTaxInformation>>,
    { accountId: string; data: AccountKYCAndTaxRequestV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return updateAccountKYCAndTaxInformation(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateAccountKYCAndTaxInformationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAccountKYCAndTaxInformation>>
>
export type UpdateAccountKYCAndTaxInformationMutationBody =
  AccountKYCAndTaxRequestV1
export type UpdateAccountKYCAndTaxInformationMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Update account KYC and tax information
 */
export const useUpdateAccountKYCAndTaxInformation = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountKYCAndTaxInformation>>,
    TError,
    { accountId: string; data: AccountKYCAndTaxRequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateAccountKYCAndTaxInformationMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Update account benefit plan and ERISA details
 */
export const updateAccountBenefitPlanAndERISA = (
  accountId: string,
  accountBenefitPlanAndERISARequestV1: AccountBenefitPlanAndERISARequestV1
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/benefit-plan-erisa`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: accountBenefitPlanAndERISARequestV1,
  })
}

export const getUpdateAccountBenefitPlanAndERISAMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountBenefitPlanAndERISA>>,
    TError,
    { accountId: string; data: AccountBenefitPlanAndERISARequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccountBenefitPlanAndERISA>>,
  TError,
  { accountId: string; data: AccountBenefitPlanAndERISARequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccountBenefitPlanAndERISA>>,
    { accountId: string; data: AccountBenefitPlanAndERISARequestV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return updateAccountBenefitPlanAndERISA(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateAccountBenefitPlanAndERISAMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAccountBenefitPlanAndERISA>>
>
export type UpdateAccountBenefitPlanAndERISAMutationBody =
  AccountBenefitPlanAndERISARequestV1
export type UpdateAccountBenefitPlanAndERISAMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Update account benefit plan and ERISA details
 */
export const useUpdateAccountBenefitPlanAndERISA = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountBenefitPlanAndERISA>>,
    TError,
    { accountId: string; data: AccountBenefitPlanAndERISARequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateAccountBenefitPlanAndERISAMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Update account eligibility questions details
 */
export const updateAccountEligibilityQuestions = (
  accountId: string,
  accountEligibilityQuestionsRequestV1: AccountEligibilityQuestionsRequestV1
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/eligibility-questions`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: accountEligibilityQuestionsRequestV1,
  })
}

export const getUpdateAccountEligibilityQuestionsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountEligibilityQuestions>>,
    TError,
    { accountId: string; data: AccountEligibilityQuestionsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccountEligibilityQuestions>>,
  TError,
  { accountId: string; data: AccountEligibilityQuestionsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccountEligibilityQuestions>>,
    { accountId: string; data: AccountEligibilityQuestionsRequestV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return updateAccountEligibilityQuestions(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateAccountEligibilityQuestionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAccountEligibilityQuestions>>
>
export type UpdateAccountEligibilityQuestionsMutationBody =
  AccountEligibilityQuestionsRequestV1
export type UpdateAccountEligibilityQuestionsMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Update account eligibility questions details
 */
export const useUpdateAccountEligibilityQuestions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountEligibilityQuestions>>,
    TError,
    { accountId: string; data: AccountEligibilityQuestionsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateAccountEligibilityQuestionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Update account signatories and other account roles
 */
export const updateAccountSignatoriesAndOtherAccountRoles = (
  accountId: string,
  accountSignatoriesAndOtherAccountRolesRequestV1: AccountSignatoriesAndOtherAccountRolesRequestV1
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/signatories-and-other-account-roles`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: accountSignatoriesAndOtherAccountRolesRequestV1,
  })
}

export const getUpdateAccountSignatoriesAndOtherAccountRolesMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountSignatoriesAndOtherAccountRoles>>,
    TError,
    {
      accountId: string
      data: AccountSignatoriesAndOtherAccountRolesRequestV1
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccountSignatoriesAndOtherAccountRoles>>,
  TError,
  { accountId: string; data: AccountSignatoriesAndOtherAccountRolesRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccountSignatoriesAndOtherAccountRoles>>,
    { accountId: string; data: AccountSignatoriesAndOtherAccountRolesRequestV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return updateAccountSignatoriesAndOtherAccountRoles(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateAccountSignatoriesAndOtherAccountRolesMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof updateAccountSignatoriesAndOtherAccountRoles>>
  >
export type UpdateAccountSignatoriesAndOtherAccountRolesMutationBody =
  AccountSignatoriesAndOtherAccountRolesRequestV1
export type UpdateAccountSignatoriesAndOtherAccountRolesMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Update account signatories and other account roles
 */
export const useUpdateAccountSignatoriesAndOtherAccountRoles = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountSignatoriesAndOtherAccountRoles>>,
    TError,
    {
      accountId: string
      data: AccountSignatoriesAndOtherAccountRolesRequestV1
    },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateAccountSignatoriesAndOtherAccountRolesMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Verify if the required account documents data is complete
 */
export const isAccountDocumentsDataReady = (
  accountId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<AccountDocumentsDataReadinessResponseV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/documents/ready`,
    method: 'GET',
    signal,
  })
}

export const getIsAccountDocumentsDataReadyQueryKey = (accountId: string) => {
  return [
    `/api/investor-profile/v1/accounts/${accountId}/documents/ready`,
  ] as const
}

export const getIsAccountDocumentsDataReadyQueryOptions = <
  TData = Awaited<ReturnType<typeof isAccountDocumentsDataReady>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof isAccountDocumentsDataReady>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getIsAccountDocumentsDataReadyQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof isAccountDocumentsDataReady>>
  > = ({ signal }) => isAccountDocumentsDataReady(accountId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!accountId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof isAccountDocumentsDataReady>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type IsAccountDocumentsDataReadyQueryResult = NonNullable<
  Awaited<ReturnType<typeof isAccountDocumentsDataReady>>
>
export type IsAccountDocumentsDataReadyQueryError = ErrorType<ErrorResponse>

/**
 * @summary Verify if the required account documents data is complete
 */
export const useIsAccountDocumentsDataReady = <
  TData = Awaited<ReturnType<typeof isAccountDocumentsDataReady>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof isAccountDocumentsDataReady>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIsAccountDocumentsDataReadyQueryOptions(
    accountId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Verify if the required account data is complete
 */
export const isAccountDataReady = (
  accountId: string,
  accountDataReadinessRequestV1: AccountDataReadinessRequestV1
) => {
  return fetchInstance<AccountDataReadinessResponseV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/ready`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: accountDataReadinessRequestV1,
  })
}

export const getIsAccountDataReadyMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof isAccountDataReady>>,
    TError,
    { accountId: string; data: AccountDataReadinessRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof isAccountDataReady>>,
  TError,
  { accountId: string; data: AccountDataReadinessRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof isAccountDataReady>>,
    { accountId: string; data: AccountDataReadinessRequestV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return isAccountDataReady(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type IsAccountDataReadyMutationResult = NonNullable<
  Awaited<ReturnType<typeof isAccountDataReady>>
>
export type IsAccountDataReadyMutationBody = AccountDataReadinessRequestV1
export type IsAccountDataReadyMutationError = ErrorType<ErrorResponse>

/**
 * @summary Verify if the required account data is complete
 */
export const useIsAccountDataReady = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof isAccountDataReady>>,
    TError,
    { accountId: string; data: AccountDataReadinessRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getIsAccountDataReadyMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Returns the account data requirements based on the provided account field groups
 */
export const accountDataRequirements = (
  accountId: string,
  accountDataReadinessRequestV1: AccountDataReadinessRequestV1
) => {
  return fetchInstance<AccountDataRequirementsResponseV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/requirements`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: accountDataReadinessRequestV1,
  })
}

export const getAccountDataRequirementsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountDataRequirements>>,
    TError,
    { accountId: string; data: AccountDataReadinessRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountDataRequirements>>,
  TError,
  { accountId: string; data: AccountDataReadinessRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountDataRequirements>>,
    { accountId: string; data: AccountDataReadinessRequestV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return accountDataRequirements(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AccountDataRequirementsMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountDataRequirements>>
>
export type AccountDataRequirementsMutationBody = AccountDataReadinessRequestV1
export type AccountDataRequirementsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Returns the account data requirements based on the provided account field groups
 */
export const useAccountDataRequirements = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountDataRequirements>>,
    TError,
    { accountId: string; data: AccountDataReadinessRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getAccountDataRequirementsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Specify the list of fields skipped by the user during the profile lite experience
 */
export const accountDataRequirementsSkipFields = (
  accountId: string,
  accountDataReadinessSkippedFieldsV1: AccountDataReadinessSkippedFieldsV1
) => {
  return fetchInstance<AccountDataReadinessSkippedFieldsV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/requirements/skip`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: accountDataReadinessSkippedFieldsV1,
  })
}

export const getAccountDataRequirementsSkipFieldsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountDataRequirementsSkipFields>>,
    TError,
    { accountId: string; data: AccountDataReadinessSkippedFieldsV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountDataRequirementsSkipFields>>,
  TError,
  { accountId: string; data: AccountDataReadinessSkippedFieldsV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountDataRequirementsSkipFields>>,
    { accountId: string; data: AccountDataReadinessSkippedFieldsV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return accountDataRequirementsSkipFields(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AccountDataRequirementsSkipFieldsMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountDataRequirementsSkipFields>>
>
export type AccountDataRequirementsSkipFieldsMutationBody =
  AccountDataReadinessSkippedFieldsV1
export type AccountDataRequirementsSkipFieldsMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Specify the list of fields skipped by the user during the profile lite experience
 */
export const useAccountDataRequirementsSkipFields = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountDataRequirementsSkipFields>>,
    TError,
    { accountId: string; data: AccountDataReadinessSkippedFieldsV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getAccountDataRequirementsSkipFieldsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get the full field requirements dictionary
 */
export const accountDataRequirementsDictionary = (signal?: AbortSignal) => {
  return fetchInstance<AccountDataRequirementsDictionaryV1>({
    url: `/api/investor-profile/v1/accounts/requirements/dictionary/json`,
    method: 'GET',
    signal,
  })
}

export const getAccountDataRequirementsDictionaryQueryKey = () => {
  return [
    `/api/investor-profile/v1/accounts/requirements/dictionary/json`,
  ] as const
}

export const getAccountDataRequirementsDictionaryQueryOptions = <
  TData = Awaited<ReturnType<typeof accountDataRequirementsDictionary>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof accountDataRequirementsDictionary>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAccountDataRequirementsDictionaryQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof accountDataRequirementsDictionary>>
  > = ({ signal }) => accountDataRequirementsDictionary(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountDataRequirementsDictionary>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountDataRequirementsDictionaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountDataRequirementsDictionary>>
>
export type AccountDataRequirementsDictionaryQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get the full field requirements dictionary
 */
export const useAccountDataRequirementsDictionary = <
  TData = Awaited<ReturnType<typeof accountDataRequirementsDictionary>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof accountDataRequirementsDictionary>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountDataRequirementsDictionaryQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get the csv file used to generate the account requirements dictionary
 */
export const accountDataRequirementsCsvDictionary = (signal?: AbortSignal) => {
  return fetchInstance<Blob>({
    url: `/api/investor-profile/v1/accounts/requirements/dictionary/csv`,
    method: 'GET',
    responseType: 'blob',
    signal,
  })
}

export const getAccountDataRequirementsCsvDictionaryQueryKey = () => {
  return [
    `/api/investor-profile/v1/accounts/requirements/dictionary/csv`,
  ] as const
}

export const getAccountDataRequirementsCsvDictionaryQueryOptions = <
  TData = Awaited<ReturnType<typeof accountDataRequirementsCsvDictionary>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof accountDataRequirementsCsvDictionary>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getAccountDataRequirementsCsvDictionaryQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof accountDataRequirementsCsvDictionary>>
  > = ({ signal }) => accountDataRequirementsCsvDictionary(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountDataRequirementsCsvDictionary>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountDataRequirementsCsvDictionaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountDataRequirementsCsvDictionary>>
>
export type AccountDataRequirementsCsvDictionaryQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get the csv file used to generate the account requirements dictionary
 */
export const useAccountDataRequirementsCsvDictionary = <
  TData = Awaited<ReturnType<typeof accountDataRequirementsCsvDictionary>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof accountDataRequirementsCsvDictionary>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getAccountDataRequirementsCsvDictionaryQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update account wire instructions
 */
export const updateAccountWireInstructions = (
  accountId: string,
  accountWireInstructionsRequestV1: AccountWireInstructionsRequestV1
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/wire-instructions`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: accountWireInstructionsRequestV1,
  })
}

export const getUpdateAccountWireInstructionsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountWireInstructions>>,
    TError,
    { accountId: string; data: AccountWireInstructionsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccountWireInstructions>>,
  TError,
  { accountId: string; data: AccountWireInstructionsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccountWireInstructions>>,
    { accountId: string; data: AccountWireInstructionsRequestV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return updateAccountWireInstructions(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateAccountWireInstructionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAccountWireInstructions>>
>
export type UpdateAccountWireInstructionsMutationBody =
  AccountWireInstructionsRequestV1
export type UpdateAccountWireInstructionsMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Update account wire instructions
 */
export const useUpdateAccountWireInstructions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountWireInstructions>>,
    TError,
    { accountId: string; data: AccountWireInstructionsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateAccountWireInstructionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get a specific version of an account by its ID and version
 */
export const getAccountVersion = (
  accountId: string,
  version: number,
  params?: GetAccountVersionParams,
  signal?: AbortSignal
) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/versions/${version}`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetAccountVersionQueryKey = (
  accountId: string,
  version: number,
  params?: GetAccountVersionParams
) => {
  return [
    `/api/investor-profile/v1/accounts/${accountId}/versions/${version}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetAccountVersionQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccountVersion>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  version: number,
  params?: GetAccountVersionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccountVersion>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAccountVersionQueryKey(accountId, version, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAccountVersion>>
  > = ({ signal }) => getAccountVersion(accountId, version, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(accountId && version),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccountVersion>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAccountVersionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountVersion>>
>
export type GetAccountVersionQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a specific version of an account by its ID and version
 */
export const useGetAccountVersion = <
  TData = Awaited<ReturnType<typeof getAccountVersion>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  version: number,
  params?: GetAccountVersionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccountVersion>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAccountVersionQueryOptions(
    accountId,
    version,
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Move an account (selected by id) to a different household. The contact used in the selected account will be copied to the new household. **Permission:** `cais:tech-ops:admin`

 * @summary Move account to a different household
 */
export const moveAccount = (accountId: string, householdId: string) => {
  return fetchInstance<AccountV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/move-to/household/${householdId}`,
    method: 'PUT',
  })
}

export const getMoveAccountMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof moveAccount>>,
    TError,
    { accountId: string; householdId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof moveAccount>>,
  TError,
  { accountId: string; householdId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof moveAccount>>,
    { accountId: string; householdId: string }
  > = (props) => {
    const { accountId, householdId } = props ?? {}

    return moveAccount(accountId, householdId)
  }

  return { mutationFn, ...mutationOptions }
}

export type MoveAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof moveAccount>>
>

export type MoveAccountMutationError = ErrorType<ErrorResponse>

/**
 * @summary Move account to a different household
 */
export const useMoveAccount = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof moveAccount>>,
    TError,
    { accountId: string; householdId: string },
    TContext
  >
}) => {
  const mutationOptions = getMoveAccountMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get the list of accounts the logged in user has access to.
 * @summary Search accounts
 */
export const searchAccounts = (
  accountsSearchRequestV1: AccountsSearchRequestV1
) => {
  return fetchInstance<PagedAccountListItemsV1>({
    url: `/api/investor-profile/v1/accounts/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: accountsSearchRequestV1,
  })
}

export const getSearchAccountsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchAccounts>>,
    TError,
    { data: AccountsSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchAccounts>>,
  TError,
  { data: AccountsSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchAccounts>>,
    { data: AccountsSearchRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return searchAccounts(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchAccountsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchAccounts>>
>
export type SearchAccountsMutationBody = AccountsSearchRequestV1
export type SearchAccountsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Search accounts
 */
export const useSearchAccounts = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchAccounts>>,
    TError,
    { data: AccountsSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchAccountsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get interested parties for a given account
 */
export const getInterestedParties = (
  accountId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<InterestedPartyResponseV1[]>({
    url: `/api/investor-profile/v1/accounts/${accountId}/interested-parties`,
    method: 'GET',
    signal,
  })
}

export const getGetInterestedPartiesQueryKey = (accountId: string) => {
  return [
    `/api/investor-profile/v1/accounts/${accountId}/interested-parties`,
  ] as const
}

export const getGetInterestedPartiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getInterestedParties>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInterestedParties>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetInterestedPartiesQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInterestedParties>>
  > = ({ signal }) => getInterestedParties(accountId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!accountId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getInterestedParties>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetInterestedPartiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInterestedParties>>
>
export type GetInterestedPartiesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get interested parties for a given account
 */
export const useGetInterestedParties = <
  TData = Awaited<ReturnType<typeof getInterestedParties>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInterestedParties>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInterestedPartiesQueryOptions(accountId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Creates an interested party on a given account.
 */
export const createInterestedParty = (
  accountId: string,
  createInterestedPartyRequestV1: CreateInterestedPartyRequestV1
) => {
  return fetchInstance<InterestedPartyResponseV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/interested-parties`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createInterestedPartyRequestV1,
  })
}

export const getCreateInterestedPartyMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInterestedParty>>,
    TError,
    { accountId: string; data: CreateInterestedPartyRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createInterestedParty>>,
  TError,
  { accountId: string; data: CreateInterestedPartyRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createInterestedParty>>,
    { accountId: string; data: CreateInterestedPartyRequestV1 }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return createInterestedParty(accountId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateInterestedPartyMutationResult = NonNullable<
  Awaited<ReturnType<typeof createInterestedParty>>
>
export type CreateInterestedPartyMutationBody = CreateInterestedPartyRequestV1
export type CreateInterestedPartyMutationError = ErrorType<ErrorResponse>

export const useCreateInterestedParty = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInterestedParty>>,
    TError,
    { accountId: string; data: CreateInterestedPartyRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateInterestedPartyMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get interested party by contact id for a given account
 */
export const getInterestedParty = (
  accountId: string,
  contactId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<InterestedPartyResponseV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/interested-parties/${contactId}`,
    method: 'GET',
    signal,
  })
}

export const getGetInterestedPartyQueryKey = (
  accountId: string,
  contactId: string
) => {
  return [
    `/api/investor-profile/v1/accounts/${accountId}/interested-parties/${contactId}`,
  ] as const
}

export const getGetInterestedPartyQueryOptions = <
  TData = Awaited<ReturnType<typeof getInterestedParty>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  contactId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInterestedParty>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetInterestedPartyQueryKey(accountId, contactId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInterestedParty>>
  > = ({ signal }) => getInterestedParty(accountId, contactId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(accountId && contactId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getInterestedParty>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetInterestedPartyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInterestedParty>>
>
export type GetInterestedPartyQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get interested party by contact id for a given account
 */
export const useGetInterestedParty = <
  TData = Awaited<ReturnType<typeof getInterestedParty>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  contactId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInterestedParty>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInterestedPartyQueryOptions(
    accountId,
    contactId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Updates an interested party by contact id for a given account.
 */
export const updateInterestedParty = (
  accountId: string,
  contactId: string,
  updateInterestedPartyRequestV1: UpdateInterestedPartyRequestV1
) => {
  return fetchInstance<InterestedPartyResponseV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/interested-parties/${contactId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateInterestedPartyRequestV1,
  })
}

export const getUpdateInterestedPartyMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInterestedParty>>,
    TError,
    {
      accountId: string
      contactId: string
      data: UpdateInterestedPartyRequestV1
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInterestedParty>>,
  TError,
  {
    accountId: string
    contactId: string
    data: UpdateInterestedPartyRequestV1
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInterestedParty>>,
    {
      accountId: string
      contactId: string
      data: UpdateInterestedPartyRequestV1
    }
  > = (props) => {
    const { accountId, contactId, data } = props ?? {}

    return updateInterestedParty(accountId, contactId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateInterestedPartyMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateInterestedParty>>
>
export type UpdateInterestedPartyMutationBody = UpdateInterestedPartyRequestV1
export type UpdateInterestedPartyMutationError = ErrorType<ErrorResponse>

export const useUpdateInterestedParty = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInterestedParty>>,
    TError,
    {
      accountId: string
      contactId: string
      data: UpdateInterestedPartyRequestV1
    },
    TContext
  >
}) => {
  const mutationOptions = getUpdateInterestedPartyMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Deletes an interested party by contact id for a given account.
 */
export const deleteInterestedParty = (accountId: string, contactId: string) => {
  return fetchInstance<number>({
    url: `/api/investor-profile/v1/accounts/${accountId}/interested-parties/${contactId}`,
    method: 'DELETE',
  })
}

export const getDeleteInterestedPartyMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInterestedParty>>,
    TError,
    { accountId: string; contactId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteInterestedParty>>,
  TError,
  { accountId: string; contactId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteInterestedParty>>,
    { accountId: string; contactId: string }
  > = (props) => {
    const { accountId, contactId } = props ?? {}

    return deleteInterestedParty(accountId, contactId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteInterestedPartyMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteInterestedParty>>
>

export type DeleteInterestedPartyMutationError = ErrorType<ErrorResponse>

export const useDeleteInterestedParty = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInterestedParty>>,
    TError,
    { accountId: string; contactId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteInterestedPartyMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get interested party by contact id for a given version of an account
 */
export const getInterestedPartyVersion = (
  accountId: string,
  version: number,
  contactId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<InterestedPartyResponseV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/versions/${version}/interested-parties/${contactId}`,
    method: 'GET',
    signal,
  })
}

export const getGetInterestedPartyVersionQueryKey = (
  accountId: string,
  version: number,
  contactId: string
) => {
  return [
    `/api/investor-profile/v1/accounts/${accountId}/versions/${version}/interested-parties/${contactId}`,
  ] as const
}

export const getGetInterestedPartyVersionQueryOptions = <
  TData = Awaited<ReturnType<typeof getInterestedPartyVersion>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  version: number,
  contactId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInterestedPartyVersion>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetInterestedPartyVersionQueryKey(accountId, version, contactId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInterestedPartyVersion>>
  > = ({ signal }) =>
    getInterestedPartyVersion(accountId, version, contactId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(accountId && version && contactId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getInterestedPartyVersion>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetInterestedPartyVersionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInterestedPartyVersion>>
>
export type GetInterestedPartyVersionQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get interested party by contact id for a given version of an account
 */
export const useGetInterestedPartyVersion = <
  TData = Awaited<ReturnType<typeof getInterestedPartyVersion>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  version: number,
  contactId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInterestedPartyVersion>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInterestedPartyVersionQueryOptions(
    accountId,
    version,
    contactId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get documents associated with a given account ID
 */
export const getAccountDocuments = (
  accountId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<KycDocumentDetailsV1[]>({
    url: `/api/investor-profile/v1/accounts/${accountId}/documents`,
    method: 'GET',
    signal,
  })
}

export const getGetAccountDocumentsQueryKey = (accountId: string) => {
  return [`/api/investor-profile/v1/accounts/${accountId}/documents`] as const
}

export const getGetAccountDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccountDocuments>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccountDocuments>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetAccountDocumentsQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAccountDocuments>>
  > = ({ signal }) => getAccountDocuments(accountId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!accountId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccountDocuments>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAccountDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountDocuments>>
>
export type GetAccountDocumentsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get documents associated with a given account ID
 */
export const useGetAccountDocuments = <
  TData = Awaited<ReturnType<typeof getAccountDocuments>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccountDocuments>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAccountDocumentsQueryOptions(accountId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get the list of document types compatible with the selected account for the given ID. For each document type, the related documents are provided.

 * @summary Get a list of document types compatible with an account
 */
export const getAccountDocumentsTypes = (
  accountId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<KycDocumentFileTypesResponseV1>({
    url: `/api/investor-profile/v1/accounts/${accountId}/document-types`,
    method: 'GET',
    signal,
  })
}

export const getGetAccountDocumentsTypesQueryKey = (accountId: string) => {
  return [
    `/api/investor-profile/v1/accounts/${accountId}/document-types`,
  ] as const
}

export const getGetAccountDocumentsTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccountDocumentsTypes>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccountDocumentsTypes>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetAccountDocumentsTypesQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAccountDocumentsTypes>>
  > = ({ signal }) => getAccountDocumentsTypes(accountId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!accountId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccountDocumentsTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAccountDocumentsTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountDocumentsTypes>>
>
export type GetAccountDocumentsTypesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a list of document types compatible with an account
 */
export const useGetAccountDocumentsTypes = <
  TData = Awaited<ReturnType<typeof getAccountDocumentsTypes>>,
  TError = ErrorType<ErrorResponse>
>(
  accountId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccountDocumentsTypes>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAccountDocumentsTypesQueryOptions(
    accountId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Upload a document
 */
export const uploadDocument = (
  uploadDocumentRequestV1: UploadDocumentRequestV1
) => {
  const formData = new FormData()
  formData.append(
    'fileUploadRequest',
    JSON.stringify(uploadDocumentRequestV1.fileUploadRequest)
  )
  formData.append('file', uploadDocumentRequestV1.file)

  return fetchInstance<UploadDocumentResponseV1>({
    url: `/api/investor-profile/v1/documents`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

export const getUploadDocumentMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadDocument>>,
    TError,
    { data: UploadDocumentRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadDocument>>,
  TError,
  { data: UploadDocumentRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadDocument>>,
    { data: UploadDocumentRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return uploadDocument(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadDocument>>
>
export type UploadDocumentMutationBody = UploadDocumentRequestV1
export type UploadDocumentMutationError = ErrorType<ErrorResponse>

/**
 * @summary Upload a document
 */
export const useUploadDocument = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadDocument>>,
    TError,
    { data: UploadDocumentRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUploadDocumentMutationOptions(options)

  return useMutation(mutationOptions)
}

export const uploadDocumentDetails = (
  fileDetailsRequestV1: FileDetailsRequestV1
) => {
  return fetchInstance<UploadDocumentResponseV1>({
    url: `/api/investor-profile/v1/documents/detail`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fileDetailsRequestV1,
  })
}

export const getUploadDocumentDetailsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadDocumentDetails>>,
    TError,
    { data: FileDetailsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadDocumentDetails>>,
  TError,
  { data: FileDetailsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadDocumentDetails>>,
    { data: FileDetailsRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return uploadDocumentDetails(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadDocumentDetailsMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadDocumentDetails>>
>
export type UploadDocumentDetailsMutationBody = FileDetailsRequestV1
export type UploadDocumentDetailsMutationError = ErrorType<ErrorResponse>

export const useUploadDocumentDetails = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadDocumentDetails>>,
    TError,
    { data: FileDetailsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUploadDocumentDetailsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get a document by its file id
 */
export const getDocument = (fileId: string, signal?: AbortSignal) => {
  return fetchInstance<DownloadDocumentResponseV1>({
    url: `/api/investor-profile/v1/documents/${fileId}`,
    method: 'GET',
    signal,
  })
}

export const getGetDocumentQueryKey = (fileId: string) => {
  return [`/api/investor-profile/v1/documents/${fileId}`] as const
}

export const getGetDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  fileId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocument>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetDocumentQueryKey(fileId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocument>>> = ({
    signal,
  }) => getDocument(fileId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!fileId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getDocument>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocument>>
>
export type GetDocumentQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a document by its file id
 */
export const useGetDocument = <
  TData = Awaited<ReturnType<typeof getDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  fileId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocument>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDocumentQueryOptions(fileId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update expiry or issue date of an existing document
 */
export const updateDocument = (
  fileId: string,
  fileUpdateRequestV1: FileUpdateRequestV1
) => {
  return fetchInstance<KycDocumentDetailsV1>({
    url: `/api/investor-profile/v1/documents/${fileId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: fileUpdateRequestV1,
  })
}

export const getUpdateDocumentMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDocument>>,
    TError,
    { fileId: string; data: FileUpdateRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateDocument>>,
  TError,
  { fileId: string; data: FileUpdateRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateDocument>>,
    { fileId: string; data: FileUpdateRequestV1 }
  > = (props) => {
    const { fileId, data } = props ?? {}

    return updateDocument(fileId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDocument>>
>
export type UpdateDocumentMutationBody = FileUpdateRequestV1
export type UpdateDocumentMutationError = ErrorType<ErrorResponse>

/**
 * @summary Update expiry or issue date of an existing document
 */
export const useUpdateDocument = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDocument>>,
    TError,
    { fileId: string; data: FileUpdateRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateDocumentMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get a specific version of a document by its ID
 */
export const getDocumentVersion = (
  fileId: string,
  version: number,
  signal?: AbortSignal
) => {
  return fetchInstance<DownloadDocumentResponseV1>({
    url: `/api/investor-profile/v1/documents/${fileId}/versions/${version}`,
    method: 'GET',
    signal,
  })
}

export const getGetDocumentVersionQueryKey = (
  fileId: string,
  version: number
) => {
  return [
    `/api/investor-profile/v1/documents/${fileId}/versions/${version}`,
  ] as const
}

export const getGetDocumentVersionQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocumentVersion>>,
  TError = ErrorType<ErrorResponse>
>(
  fileId: string,
  version: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentVersion>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentVersionQueryKey(fileId, version)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDocumentVersion>>
  > = ({ signal }) => getDocumentVersion(fileId, version, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(fileId && version),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getDocumentVersion>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetDocumentVersionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentVersion>>
>
export type GetDocumentVersionQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a specific version of a document by its ID
 */
export const useGetDocumentVersion = <
  TData = Awaited<ReturnType<typeof getDocumentVersion>>,
  TError = ErrorType<ErrorResponse>
>(
  fileId: string,
  version: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentVersion>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDocumentVersionQueryOptions(
    fileId,
    version,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `cais:tech-ops:admin` Bulk update account versions.

 * @summary Bulk update account versions
 */
export const updateAccountVersions = (
  adminAccountVersionUpdateRequest: AdminAccountVersionUpdateRequest
) => {
  return fetchInstance<AdminAccountVersionUpdateResponse>({
    url: `/api/investor-profile/v1/admin/account-version-update`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: adminAccountVersionUpdateRequest,
  })
}

export const getUpdateAccountVersionsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountVersions>>,
    TError,
    { data: AdminAccountVersionUpdateRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAccountVersions>>,
  TError,
  { data: AdminAccountVersionUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAccountVersions>>,
    { data: AdminAccountVersionUpdateRequest }
  > = (props) => {
    const { data } = props ?? {}

    return updateAccountVersions(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateAccountVersionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAccountVersions>>
>
export type UpdateAccountVersionsMutationBody = AdminAccountVersionUpdateRequest
export type UpdateAccountVersionsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Bulk update account versions
 */
export const useUpdateAccountVersions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAccountVersions>>,
    TError,
    { data: AdminAccountVersionUpdateRequest },
    TContext
  >
}) => {
  const mutationOptions = getUpdateAccountVersionsMutationOptions(options)

  return useMutation(mutationOptions)
}
