import { useCallback } from 'react'
import { FieldValues } from 'react-hook-form'
import { UseMutateFunction } from 'react-query'

export function useFormSaveHandler<
  TData,
  TRequest,
  TFields extends FieldValues
>(options: {
  mutate: UseMutateFunction<TData, unknown, TRequest, unknown>
  transform: (params: TFields) => TRequest
  onSuccess: (data?: TData, params?: TFields) => Promise<unknown>
  onError?: (error: unknown) => void
}) {
  const { transform, onSuccess, mutate, onError } = options
  return useCallback(
    async (params: TFields) => {
      const mutationBody = transform(params)
      try {
        return await mutate(mutationBody, {
          onSuccess: async (data) => {
            await onSuccess(data, params)
          },
          onError,
        })
      } catch (e) {
        return Promise.reject(e)
      }
    },
    [mutate, onError, onSuccess, transform]
  )
}
