import { ModalType } from '@cais-group/caisiq/util/type/manage-caisiq'

export type ConfirmModalSummaryType = {
  body: string
  title: string
  confirmButtonText: string
}

export const buildConfirmFirmModalDetails = (
  firmNames?: string,
  type?: Partial<ModalType>
): ConfirmModalSummaryType | undefined => {
  if (!firmNames) return

  if (type === 'confirm') {
    return {
      title: `Are you sure you want to assign a new experience to ${firmNames}?`,
      body: `This will override the current experience for ${firmNames}.`,
      confirmButtonText: 'Yes, assign',
    }
  } else if (type === 'remove') {
    return {
      title: `Are you sure you want to remove ${firmNames} from this experience?`,
      body: `${firmNames} will receive the default experience. `,
      confirmButtonText: 'Yes, remove',
    }
  }

  return
}
