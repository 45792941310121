import cx from 'classnames'
import { ReactElement } from 'react'

export type SelectableListItem<TValue extends string = string> = {
  label: string
  value: TValue
}

export interface SelectableListRenderItemOnChangeArgs<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
> {
  selectedItems: TValue[]
  item: TItem
}

export type SelectableListRenderItemArgs<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
> = {
  item: TItem
  onChange: ({
    selectedItems,
    item,
  }: SelectableListRenderItemOnChangeArgs<TItem, TValue>) => void
  selectedItems: TValue[]
}

export type SelectableListRenderItem<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
> = ({
  item,
  onChange,
  selectedItems,
}: SelectableListRenderItemArgs<TItem, TValue>) => ReactElement

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectableListOnChange = (...event: any[]) => void

export interface SelectableListProps<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
> {
  items: TItem[]
  onChange: SelectableListOnChange
  renderItem: SelectableListRenderItem<TItem, TValue>
  selectedItems: TValue[]
  testId?: string
  gap?: '8' | '12'
}
export const SelectableList = <
  TItem extends SelectableListItem<TValue>,
  TValue extends string
>({
  items,
  onChange,
  renderItem,
  selectedItems,
  testId,
  gap = '8',
}: SelectableListProps<TItem, TValue>) => {
  const onChangeHandler = ({
    selectedItems,
    item,
  }: SelectableListRenderItemOnChangeArgs<TItem, TValue>) => {
    const wasPreviouslySelected = selectedItems?.includes(item.value)
    onChange(
      wasPreviouslySelected
        ? selectedItems?.filter(
            (selectedItem: TValue) => selectedItem !== item.value
          )
        : [...selectedItems, item.value]
    )
  }
  return (
    <div
      className={cx('flex flex-1 flex-col', {
        'gap-8': gap === '8',
        'gap-12': gap === '12',
      })}
      data-testid={testId}
    >
      {items.map((item) =>
        renderItem({ item, onChange: onChangeHandler, selectedItems })
      )}
    </div>
  )
}
