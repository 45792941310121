import cx from 'classnames'
import { useRef, useState } from 'react'

import { SearchableList } from '@cais-group/shared/ui/searchable-list'
import { SelectableListItem } from '@cais-group/shared/ui/selectable-list'

import { FilterList, FilterListProps } from './shared-ui-filter-list'

export interface SearchableDropdownListProps<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
> extends FilterListProps<TItem, TValue> {
  id?: string
  filterFn?: (item: TItem, searchValue: string) => boolean
  setSearchText: (s: string) => void
  placeholder?: string
}

export const SearchableDropdownList = <
  TItem extends SelectableListItem<TValue>,
  TValue extends string
>({
  id,
  filterFn,
  items,
  testId,
  setSearchText,
  placeholder = 'Search',
  ...rest
}: SearchableDropdownListProps<TItem, TValue>) => {
  const [showPopup, setShowPopup] = useState<boolean>(false)

  const containerEl = useRef<HTMLDivElement>(null)

  const handleOutsideClick = (target: Element | null) => {
    const clickIsInside =
      containerEl.current !== null && containerEl.current.contains(target)

    const clickedAnItem = target?.getAttribute('type') === 'checkbox'

    if (!(clickedAnItem || clickIsInside)) {
      setShowPopup(false)
      removeClickOutsideListener()
    }
  }

  const addClickOutsideListener = () => {
    document.addEventListener(
      'click',
      (e) => handleOutsideClick(e.target as Element),
      false
    )
  }

  const removeClickOutsideListener = () => {
    document.removeEventListener(
      'click',
      (e) => handleOutsideClick(e.target as Element),
      false
    )
  }

  return (
    <div ref={containerEl} className="relative">
      <SearchableList
        placeholder={placeholder}
        id={id}
        filterFn={filterFn}
        items={items}
        setSearchText={setSearchText}
        ListContainer={({ children }) => (
          <div
            className={cx('shadow-6 bg-neutral-0 absolute z-50 w-full pt-8', {
              hidden: !showPopup,
            })}
          >
            {children}
          </div>
        )}
        renderList={(listItems) => (
          <FilterList {...rest} items={listItems} testId={testId} />
        )}
        testId={testId}
        onSearchFocus={() => {
          setShowPopup(true)
          addClickOutsideListener()
        }}
        isControlled={true}
      />
    </div>
  )
}
