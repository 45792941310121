import { useMutation } from 'react-query'

import { useAxiosInstance } from '@cais-group/caisiq/util/hook/use-axios-instance'
import {
  ApiPaths,
  PutUserLicencesErrorResponse,
  LearnerDto,
} from '@cais-group/shared/util/type/caisiq-be'

export function usePutUserLicences() {
  const axios = useAxiosInstance()

  return useMutation<void, PutUserLicencesErrorResponse, LearnerDto>((props) =>
    axios.put(ApiPaths.saveLearnerV2, props)
  )
}
