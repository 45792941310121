export interface WithTitleProps {
  children: JSX.Element
  title?: string
  className?: string
}

export const WithTitle = ({
  children,
  title,
  className = 'mt-24',
}: WithTitleProps) =>
  title ? (
    <div className={className}>
      <p className="small-strong mb-12">{title}</p>
      {children}
    </div>
  ) : (
    children
  )
