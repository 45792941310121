import cx from 'classnames'
import * as React from 'react'

import { DotTag, DotTagProps } from '@cais-group/equity/atoms/dot-tag'
import { Link, LinkProps } from '@cais-group/equity/atoms/link'
import { StatusTag, StatusTagProps } from '@cais-group/equity/atoms/status-tag'

const getStyle = (isSelected: boolean = false, isDisabled: boolean = false) =>
  cx('relative flex min-w-fit cursor-pointer items-center gap-x-8 p-0 body', {
    'text-primary-600': isSelected,
    'text-neutral-900': !isSelected && !isDisabled,
    'hover:text-primary-600': !isDisabled,
    'text-neutral-300': isDisabled,
  })

type TabProps = {
  children: React.ReactNode
  onClick?: () => void
  isSelected?: boolean
  statusTagProps?: StatusTagProps
  dotTagProps?: Omit<DotTagProps, 'children'>
  disabled?: boolean
}

export type TabsProps = {
  children: React.ReactNode
  selectedIndex: number
  setSelectedIndex: (newIndex: number) => void
  size?: 'default' | 'small'
}

/**
 * @deprecated Use {@link NavigationTabs} instead
 */
export const Tabs = ({
  children,
  selectedIndex,
  setSelectedIndex,
  size = 'default',
}: TabsProps) => {
  const [indicatorPosition, setIndicatorPosition] = React.useState<
    null | number
  >(null)
  const [indicatorWidth, setIndicatorWidth] = React.useState(0)
  const tabsRef = React.useRef<HTMLUListElement>(null)

  React.useEffect(() => {
    const tabsContainer = tabsRef.current
    if (tabsContainer) {
      const selectedTab = tabsContainer.children[selectedIndex] as HTMLElement
      if (selectedTab) {
        const newIndicatorPosition = selectedTab.offsetLeft
        const newIndicatorWidth = selectedTab.offsetWidth
        setIndicatorPosition(newIndicatorPosition)
        setIndicatorWidth(newIndicatorWidth)
      }
    }
  }, [selectedIndex])

  return (
    <div className="relative w-full">
      <ul
        role="tablist"
        className={cx('flex w-full', {
          'gap-x-32 py-32': size === 'default',
          'gap-x-24 py-24': size === 'small',
        })}
        ref={tabsRef}
      >
        {React.Children.map(children, (child, index) => {
          if (
            React.isValidElement<TabProps | TabLinkProps>(child) &&
            (child.type === Tab || child.type === TabLink)
          ) {
            const isSelected: boolean = index === selectedIndex
            return React.cloneElement<TabProps>(
              child as React.ReactElement<TabProps>,
              {
                onClick: () => {
                  setSelectedIndex(index)
                  ;(child as React.ReactElement<TabProps>)?.props?.onClick?.()
                },
                isSelected,
              }
            )
          } else {
            console.warn(
              'An invalid child component was passed and therefore was not rendered. Please pass Tab components only.'
            )
          }
          return null // Filter out children that are not Tabs
        })}
      </ul>
      {typeof indicatorPosition === 'number' ? (
        <div
          className="bg-primary-600 absolute bottom-0 h-[6px] transition-all"
          style={{
            left: indicatorPosition,
            width: `${indicatorWidth}px`,
            transition: 'left 0.3s ease, width 0.3s ease',
          }}
        />
      ) : null}
    </div>
  )
}

/**
 * @deprecated Use {@link NavigationTabs} instead
 */
const Tab = ({
  children,
  onClick,
  isSelected,
  disabled = false,
  ...tabChildrenProps
}: TabProps) => (
  <TabChildren {...tabChildrenProps}>
    <button
      className={getStyle(isSelected, disabled)}
      role="tab"
      aria-selected={isSelected}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {children}
    </button>
  </TabChildren>
)

Tabs.Item = Tab // Assign Tab component to Tabs.Item for convenient usage

type TabLinkProps = TabProps & {
  linkTagProps: LinkProps
}

const TabLink = ({
  onClick,
  isSelected,
  linkTagProps,
  children,
  ...tabChildrenProps
}: TabLinkProps) => (
  <TabChildren {...tabChildrenProps}>
    <Link
      {...linkTagProps}
      className={getStyle(isSelected)}
      onClick={onClick}
      isNav
    >
      {children}
    </Link>
  </TabChildren>
)

Tabs.Link = TabLink

type TabChildrenProps = React.PropsWithChildren<
  Omit<TabProps, 'children' | 'onClick' | 'isSelected'>
>

const TabChildren = ({
  children,
  statusTagProps,
  dotTagProps,
}: TabChildrenProps) => {
  return (
    <li className="m-0 flex min-w-max gap-x-8 p-0">
      {children}
      {statusTagProps ? <StatusTag {...statusTagProps} /> : null}
      {dotTagProps ? <DotTag {...dotTagProps} /> : null}
    </li>
  )
}
