import * as R from 'remeda'

import type { UniformServices } from '../services'
import type { UniformSchema } from '../types'

import { collectAllValuesFrom } from './collect-all-values-from'
import { DependencyTreeReturns } from './get-dependency-tree'
import { getDepsByDepKey } from './get-deps-by-dep-key'

export const getDependencies = <
  TUniformServices extends UniformServices = UniformServices
>(
  schema: UniformSchema<TUniformServices> | UniformSchema<TUniformServices>[],
  dependencyKey: string,
  valuesFromKey: string
) => {
  return R.pipe(
    getDepsByDepKey(schema, dependencyKey),
    R.reduce((acc, field) => {
      if (!field.id) return acc
      const deps = collectAllValuesFrom(valuesFromKey, field)
      return {
        ...acc,
        [field.id]: Array.from(new Set(deps)),
      }
    }, {} as DependencyTreeReturns)
  )
}
