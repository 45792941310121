import styled from '@emotion/styled'
import { useMediaQuery, useTheme } from '@mui/material'
import { ReactElement, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { shouldForwardProp } from '@cais-group/approved/ui/typography'
import { ExperienceHeader } from '@cais-group/caisiq/ui/hero/header'
import { WithDescription } from '@cais-group/caisiq/ui/with-description'
import { useContentfulNameFromIdMapper } from '@cais-group/caisiq/util/contentful/use-contentful-name-from-id-mapper'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { InputSearch } from '@cais-group/caisiq-ui-input-search'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { FooterWithDisclaimer } from '@cais-group/shared/ui/footer/with-disclaimer'
import {
  LayoutBox,
  LayoutCenter,
  LayoutCluster,
} from '@cais-group/shared/ui/layout'
import { TabsNavBar } from '@cais-group/shared/ui/tabs-nav-bar'

import { useDashboardSearch } from './hooks/use-dashboard-search'
import { useDashboardTabs } from './hooks/use-dashboard-tabs'

const SearchContainer = styled(LayoutBox)`
  // Align right within flex-start aligned flex
  display: flex;
  width: 100%;
  height: var(--s56);
  width: calc(100vw - calc(var(--s56) * 2));

  @media (min-width: 960px) {
    width: var(--s376);
    max-width: var(--s376);
    padding: var(--s4) 0;
    margin-left: auto;
  }
`

const StyledCenter = styled(LayoutCenter, {
  shouldForwardProp: shouldForwardProp('$selectedTab'),
})<{ $selectedTab: string }>`
  background-color: ${({ $selectedTab }) =>
    ['courses', 'course-library', 'funds'].includes($selectedTab)
      ? 'var(--color-background)'
      : 'var(--color-white)'};
  padding-bottom: var(--s144);
  overflow: hidden;
  > div:first-of-type {
    margin-bottom: 96px;
  }
  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    > div:first-of-type {
      margin-bottom: var(--s88);
    }
  }
  @media screen and (min-width: 960px) {
    > div:first-of-type {
      margin-bottom: var(--s24);
    }
  }
`
StyledCenter.displayName = 'StyledCenter'

const TabsNav = styled(LayoutCluster)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(var(--colors-neutral-200));
  width: calc(100vw - calc(var(--s56) * 2));
  margin-bottom: var(--s24);

  // Allow focus overlines to be visible on edges
  overflow: visible;

  > div:first-of-type {
    width: calc(100vw - calc(var(--s56) * 2) + 80px);
    margin-bottom: -100px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  > div > div:first-of-type {
    width: 100%;
    display: flex;
    align-items: center;
  }
  > div > div > div:first-of-type {
    width: 100%;
    justify-content: flex-start;
  }

  @media screen and (min-width: 960px) {
    > div > div:first-of-type {
      width: calc(100vw - calc(var(--s56) * 2));
      display: flex;
      align-items: center;
    }
    .MuiTabs-root {
      // The width of the TabsNavBar root needs to be the 100vw minus the width of the SearchComponent(376px) and the view's padding(112px)
      width: calc(100vw - calc(var(--s56) * 2) - var(--s376));
      margin-left: 0;
      margin-top: var(--s16);
    }

    > div:first-of-type {
      margin-bottom: calc(var(--s12) * -1);
    }
  }
`

TabsNav.displayName = 'TabsNav'

const StyledInputSearch = styled(InputSearch)`
  width: calc(100vw - calc(var(--s56) * 2));
`

TabsNav.displayName = 'InputSearch'
interface DashboardProps {
  children?: ReactElement
}

// A helper that attaches a click handler to the 'Continuing Education' tab
function useContinuingEducationTabClickListener() {
  useEffect(() => {
    const handleClick = () => trackingService.ceContinuingEducationLinkClicked()
    const ceTab = Array.from(document.querySelectorAll('[role="tab"]')).find(
      (tab) => tab.textContent === 'Continuing Education'
    )
    if (ceTab) {
      ceTab.addEventListener('click', handleClick)
    }
    return () => ceTab?.removeEventListener('click', handleClick)
  }, [])
}

export const Dashboard = ({ children }: DashboardProps) => {
  const { currentTab, filteredTabs, description, disclaimer } =
    useDashboardTabs()
  const { searchTerm, setSearchTerm, getCountForTab } = useDashboardSearch()

  const getExperienceName = useContentfulNameFromIdMapper()
  useContinuingEducationTabClickListener()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLandscapeTablet = useMediaQuery(theme.breakpoints.down('xl'))

  // If we do not have a current tab, redirect to the first tab
  if (filteredTabs && currentTab === undefined) {
    return <Navigate to={filteredTabs[0].path} replace />
  }

  return (
    <div
      data-experience={getExperienceName(
        userSettingsService.user.experience.contentfulId
      )}
    >
      <ExperienceHeader
        userProfile={userSettingsService.user}
        currentExperience={experienceService.getExperience()}
        contentfulExperience={userSettingsService.theme}
      />
      <StyledCenter minGutters="var(--s56)" $selectedTab={currentTab as string}>
        {filteredTabs && (
          <TabsNav>
            <TabsNavBar
              addScroll={isLandscapeTablet}
              tabs={filteredTabs}
              getCountForTab={getCountForTab}
              // TODO: This prop may need to be more dynamic in future
              $tabToExtend={!isMobile ? 'profile' : undefined}
              searchComponent={
                <SearchContainer padding="0">
                  <StyledInputSearch
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    onChange={(value) => setSearchTerm(value.toLowerCase())}
                  />
                </SearchContainer>
              }
            />
          </TabsNav>
        )}
        <FooterWithDisclaimer disclaimer={disclaimer}>
          <WithDescription description={description}>
            {children}
          </WithDescription>
        </FooterWithDisclaimer>
      </StyledCenter>
    </div>
  )
}

Dashboard.displayName = 'Dashboard'
