import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useMediaQuery } from '@mui/material'
import { useRecoilState } from 'recoil'

import { H5StrongCss } from '@cais-group/approved/ui/typography'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { InputSearch } from '@cais-group/caisiq-ui-input-search'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { LayoutBox, LayoutCluster } from '@cais-group/shared/ui/layout'
import { TabItem, TabsNavBar } from '@cais-group/shared/ui/tabs-nav-bar'

const Actions = styled.div`
  display: flex;
  justify-self: end;
  align-items: center;
  column-gap: var(--s12);
  width: 100%;
`
const Container = styled.div`
  background-color: rgb(var(--colors-neutral-0));
  box-shadow: 0px 4px 6px -6px ${colors['eq-color-neutral-400']};
`

const TitleContainer = styled.div`
  background-color: rgb(var(--colors-neutral-0));
  padding: ${SPACING.s24};
  padding-left: ${SPACING.s56};
  padding-right: ${SPACING.s56};
  display: flex;
  column-gap: ${SPACING.s4};
`

const StyledTitle = styled.div`
  ${H5StrongCss};
  color: ${colors['eq-color-neutral-500']};
`

const StyledSubTitle = styled.div`
  ${H5StrongCss};
  color: ${colors['eq-color-neutral-900']};
`
const TabsNav = styled(LayoutCluster)`
  border-top: 2px solid ${colors['eq-color-neutral-100']};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING.s8};
  // Allow focus overlines to be visible on edges
  overflow: visible;

  > div:first-of-type {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  > div > div:first-of-type {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    height: 80px;
  }
  > div > div > div:first-of-type {
    width: 100%;
    justify-content: flex-start;
  }

  @media screen and (min-width: 960px) {
    border-bottom: 2px solid ${colors['eq-color-neutral-100']};
    > div > div:first-of-type {
      width: calc(100vw - calc(${SPACING.s56} * 2));
      display: flex;
      align-items: center;
    }

    > div > div > div:first-of-type {
      width: 50%;
      justify-content: flex-start;
    }
    .MuiTabs-root {
      // The width of the TabsNavBar root needs to be the 100vw minus the width of the SearchComponent(376px) and the view's padding(112px)
      width: calc(100vw - calc(${SPACING.s56}* 2) - ${SPACING.s376});
      margin-left: 0;
    }
  }
`

const SearchContainer = styled(LayoutBox)`
  // Align right within flex-start aligned flex
  display: flex;
  width: 100%;
  height: ${SPACING.s56};
  width: calc(100vw - calc(${SPACING.s56} * 2));
  @media (min-width: 960px) {
    width: ${SPACING.s376};
    max-width: ${SPACING.s376};
    padding: ${SPACING.s4} 0;
    margin-left: auto;
  }
`
const StyledInputSearch = styled(InputSearch)`
  width: calc(100vw - calc(${SPACING.s56} * 2));
`

/**
 * @todo we need to probably create a different way tabs are displayed based on features and permissions
 */
const tabs: TabItem[] = [
  { label: 'Experiences', path: ROUTES.manageExperiences },
  { label: 'Firms', path: ROUTES.manageFirms },
  { label: 'Courses', path: ROUTES.manageCourses },
]

export type ManageCaisIqNavProps = {
  actions?: React.ReactElement
  rightButton?: React.ReactElement
  placeholder: string
}
export const ManageCaisIqNav = ({
  actions,
  rightButton,
  placeholder,
}: ManageCaisIqNavProps) => {
  const theme = useTheme()
  const isLandscapeTablet = useMediaQuery(theme.breakpoints.down('xl'))
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)
  return (
    <>
      <TitleContainer>
        <StyledTitle>Manage</StyledTitle>
        <StyledSubTitle>CAIS IQ</StyledSubTitle>
      </TitleContainer>
      <Container>
        <TabsNav>
          <TabsNavBar
            addScroll={isLandscapeTablet}
            tabs={tabs}
            rightButton={rightButton}
            onNavigate={() => setSearchTerm('')}
            searchComponent={
              <Actions>
                {actions}
                <SearchContainer padding="0">
                  <StyledInputSearch
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    onChange={setSearchTerm}
                    placeholder={placeholder}
                  />
                </SearchContainer>
              </Actions>
            }
          />
        </TabsNav>
      </Container>
    </>
  )
}
