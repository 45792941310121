import { useUniformContext } from '../context'

export function useShowOnlyRequired({
  isRequired = false,
  isSkippable = false,
}: {
  isRequired: boolean | undefined
  isSkippable?: boolean | undefined
}): boolean {
  const { settings } = useUniformContext()
  const settingOn = settings?.showRequiredFieldsOnly

  return [
    typeof settingOn === 'boolean',
    settingOn === true,
    isRequired === false,
    isSkippable === false,
  ].every(Boolean)
}
