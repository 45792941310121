import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import { colors, Color, setOpacity } from '@cais-group/equity/particles/colors'

export type ProgressBarWrapProps = {
  width?: number
  rounded?: boolean
}
export type ProgressBarProps = {
  fraction: number
  width?: number
  progressColor?: Color
  rounded?: boolean
}
export type ProgressProps = {
  fraction: number
  rounded?: boolean
  color?: Color
}

const HEIGHT = 6

const ProgressBarWrap = styled.div<ProgressBarWrapProps>`
  width: ${(props) =>
    props.width === undefined ? '100%' : `${props.width}px`};
  background-color: ${setOpacity('eq-color-neutral-200', 0.44)};
  height: ${HEIGHT}px;
  ${({ rounded }) => rounded && `border-radius: ${HEIGHT / 2}px;`}
`
const Progress = styled.div<ProgressProps>`
  background-color: ${(props) => colors[props.color || 'eq-color-success-600']};
  height: ${HEIGHT}px;
  ${({ rounded }) => rounded && `border-radius: ${HEIGHT / 2}px;`}
  width: ${(props) => props.fraction * 100}%;
  transition: width 600ms cubic-bezier(0.55, 0, 0.45, 1);
`

export const ProgressBar = (props: ProgressBarProps) => {
  const { fraction, progressColor, width, rounded } = props
  // Prevent animation on initial render by only rendering once
  // there is non-zero fraction value, unless we are not in the
  // first run, in which case always render to ensure that an
  // animation from x fraction to 0 fraction will be displayed.
  const [isFirstRun, setIsFirstRun] = useState(true)

  useEffect(() => {
    if (fraction > 0) setIsFirstRun(false)
  }, [fraction])

  return (
    <ProgressBarWrap width={width} rounded={rounded}>
      {(fraction || !isFirstRun) && (
        <Progress
          fraction={fraction}
          color={progressColor}
          rounded={rounded}
        ></Progress>
      )}
    </ProgressBarWrap>
  )
}
