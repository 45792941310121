import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'

import { shouldForwardProp } from '@cais-group/approved/ui/typography'

const StyledButton = styled.button`
  fill: rgb(var(--colors-neutral-0));
  cursor: pointer;
  line-height: 0;
  &:focus {
    outline: 3px solid rgb(var(--colors-primary-600));
  }
`

const StyledCloseIcon = styled(CloseIcon, {
  shouldForwardProp: shouldForwardProp('$fillColor'),
})<{ $fillColor?: string }>`
  fill: ${({ $fillColor }) =>
    $fillColor ? $fillColor : 'var(--color-gray-34)'};
`

export type CloseButtonProps = {
  onClick?: () => void
  className?: string
  color?: string
}

export const ButtonClose = (props: CloseButtonProps) => {
  const { className, onClick, color } = props
  return (
    <StyledButton
      onClick={onClick}
      className={className}
      data-testid="ui-button-close"
      aria-label="close button"
    >
      <StyledCloseIcon $fillColor={color} />
    </StyledButton>
  )
}
