import { atom } from 'recoil'

import { ApiStateEnum } from '@cais-group/caisiq/domain/common'

import { WhitepapersApiState } from './useGetWhitepapers'

export const whitepapersApiStateAtom = atom<WhitepapersApiState>({
  key: 'whitepapersApiState',
  default: ApiStateEnum.INIT,
})
