import styled from '@emotion/styled'
import { Stack, useMediaQuery, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { pickBy } from 'remeda'

import { H2Strong, H4Strong, Body } from '@cais-group/approved/ui/typography'
import {
  useGetUserLicences,
  usePutUserLicences,
} from '@cais-group/caisiq/domain/user'
import {
  CeCreditsList,
  CeCreditsListItem,
  renderCeCreditCircles,
} from '@cais-group/caisiq/ui/ce-credits-list'
import {
  LicenceForm,
  LicenceFormFields,
} from '@cais-group/caisiq/ui/licence-form'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { showToast } from '@cais-group/equity/organisms/notifications'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { CenteredColumn } from '@cais-group/shared/ui/grid'
import { LearnerDto, Course } from '@cais-group/shared/util/type/caisiq-be'

const Line = styled.div`
  height: 2px;
  background-color: rgb(var(--colors-warning-600));
  width: var(--s56);
  margin-top: 10px;
  margin-bottom: calc(var(--s24) * 2);
  margin-top: var(--s24);

  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
    margin-top: var(--s24);
  }
`

const PageContainer = styled(CenteredColumn)`
  min-height: calc(100vh - 390.5px);
  display: flex;
  flex-direction: column;
  padding-top: var(--s16);

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: var(--s144);
    padding-right: var(--s56);
  }
`

const LayoutContainer = styled(Stack)`
  max-width: var(--measure);
  padding: var(--s16) 0;

  &:last-of-type {
    margin-bottom: var(--s32);
  }

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    margin: 0;
  }
`

const StyledLicenceForm = styled(LicenceForm)`
  box-shadow: none !important;

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(5)}`};
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08) !important;
  }
`

const CeCreditsInfo = styled(Body)`
  max-width: var(--measure);
  margin-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => {
    // @ts-ignore
    return theme.palette.neutral.darker
  }};
`

export type InterstitialsPageProps = {
  course: Course
}

export const InterstitialsPage = ({ course }: InterstitialsPageProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'))

  const { refetch } = useGetUserLicences()
  const saveLicenceForm = usePutUserLicences()

  const Label = isMobile ? H4Strong : H2Strong

  const { ce } = course
  const offersCimaCredits = ce?.cimaCeCredit
  const offersCfpCredits = ce?.cfpCeCredit

  // Build the rows for the CE credits table.
  const rows = []
  if (offersCfpCredits) {
    rows.push({
      type: 'cfp',
      credits: ce?.cfpCeCredit,
      licenseNumber: '',
      renderRow: renderCeCreditCircles,
    })
  }
  if (offersCimaCredits) {
    rows.push({
      type: 'cima',
      credits: ce?.cimaCeCredit,
      licenseNumber: '',
      renderRow: renderCeCreditCircles,
    })
  }

  // Build the form fields we want to display.
  // If a course only offers CIMA credits then we do not want to show the CFP field.
  const fields = {} as LicenceFormFields
  if (offersCfpCredits) {
    fields.cfpNumber = ''
  }
  if (offersCimaCredits) {
    fields.cimaNumber = ''
  }

  const titleText =
    offersCfpCredits && offersCimaCredits
      ? 'Enter your credential numbers to claim your credits.'
      : `Enter your ${
          offersCfpCredits ? 'CFP' : 'CIMA'
        } number to claim your credits.`

  const descriptionText =
    offersCfpCredits && offersCimaCredits
      ? 'You can claim credits for one or both credentials.'
      : ''

  useEffect(() => {
    trackingService.ceInterstitialsPageViewed()
  }, [])

  return (
    <PageContainer>
      <LayoutContainer>
        <Line />
        <Label>Congratulations!</Label>
        <CeCreditsInfo>
          You are eligible to earn continuing education credits for{' '}
          <strong>{course.name}</strong>
        </CeCreditsInfo>
        <CeCreditsList rows={rows as CeCreditsListItem[]} />
      </LayoutContainer>

      <LayoutContainer>
        <StyledLicenceForm
          fields={fields}
          size="compact"
          cta="Save to Profile"
          ctaSecondary="Skip and return to Dashboard"
          title={titleText}
          description={descriptionText}
          onSubmit={(values) => {
            const payload = {
              cfp: {
                number: values.cfpNumber.trim() || '',
              },
              cima: {
                number: values.cimaNumber.trim() || '',
              },
            }
            return saveLicenceForm.mutateAsync(
              // Remove keys that are empty
              pickBy<LearnerDto>(payload, (val) => Boolean(val?.number)),
              {
                onSuccess: () => {
                  refetch()
                  showToast({
                    type: 'success',
                    title:
                      'Your credential numbers have been successfully saved',
                  })
                },
                onError: () => {
                  showToast({
                    type: 'error',
                    title:
                      'There was an error saving one or more credential numbers',
                  })
                },
              }
            )
          }}
        />
      </LayoutContainer>
    </PageContainer>
  )
}
