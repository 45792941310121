import { InputBaseComponentProps } from '@mui/material'
import { ElementType } from 'react'
import { Controller } from 'react-hook-form'

import { TextInput } from '@cais-group/shared/ui/input/text'

import { getErrorMessage, transform } from '../utils'
import { ModalBodyProps } from '../utils/buildUpdateCourseModal'

import { PercentageNumericFormat } from './percentage-numeric-format'

export const RequiredScoreModalBody = ({ control, errors }: ModalBodyProps) => {
  return (
    <>
      <p className="body">
        This will override any existing credit values for the selected courses.
      </p>
      <div className="mx-0 my-32 flex w-[calc(100%-(theme('spacing.232')))] flex-wrap justify-between first-of-type:mr-0 [&>*]:basis-full">
        <Controller
          name="requiredScore"
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                type="text"
                id="requiredScore"
                size="regular"
                label="Required score"
                error={Boolean(getErrorMessage('requiredScore', errors))}
                helperText={getErrorMessage('requiredScore', errors)}
                className="mb-24"
                {...{
                  ...field,
                  onChange: (e) => field.onChange(transform.output(e)),
                }}
                InputProps={{
                  inputComponent:
                    PercentageNumericFormat as unknown as ElementType<InputBaseComponentProps>,
                }}
              />
            )
          }}
        />
      </div>
    </>
  )
}
