import { useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { useALM } from '@cais-group/caisiq/util/acp-service'
import { useApiPathWithPayload } from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import { useAxiosInstance } from '@cais-group/caisiq/util/hook/use-axios-instance'
import { logWarning } from '@cais-group/shared/util/logging'
import { ApiPaths } from '@cais-group/shared/util/type/caisiq-be'

type AssignmentParams = {
  assignmentId: string
}

interface AdobeProps {
  assignmentId: string
  token?: string
}

const Adobe = ({ token, assignmentId }: AdobeProps) => {
  return (
    <>
      {/* The Adobe close button has a width/height of 35px and a padding-left of 10px */}
      <div className="relative mr-[45px]">
        <div className="body-strong absolute right-0 z-10 flex h-[35px] items-center text-white">
          Exit
        </div>
      </div>
      <iframe
        data-testid="acp-iframe"
        title="ACP Assignment"
        allowFullScreen
        src={`https://learningmanager.adobe.com/app/player?lo_id=${assignmentId}&access_token=${token}`}
        className="h-[calc(100%_-_var(--eq-s-56))] w-full border-none"
      />
    </>
  )
}

export const Assignment = () => {
  const { assignmentId } = useParams<AssignmentParams>()
  const navigate = useNavigate()
  const axios = useAxiosInstance()
  const queryClient = useQueryClient()

  const { learningProviderAccessToken } = useALM()

  if (!assignmentId) {
    throw new Error(
      'There was an error retrieving the course you attempted to play'
    )
  }

  const trackProgressUrl = useApiPathWithPayload(ApiPaths.trackProgress)({
    courseId: assignmentId,
  })

  const redirectToCourseExitPage = useCallback(() => {
    navigate('exit')
  }, [navigate])

  useEffect(() => {
    const playerEventListener = async (event: MessageEvent) => {
      if (
        event.origin.match(/http:\/\/localhost:3000/g) ||
        event.origin.match(/https:\/\/learningmanager\.adobe\.com/g) ||
        event.origin.match(/https:\/\/cpcontents\.adobe\.com/g)
      ) {
        if (event.data.type === 'PLAYER_LOADED') {
          // Tell the BE we started a course, we do not need to wait for this request.
          // The BE will mark the course state record as DIRTY and sync progress with
          // ALM whenever the `/api/courses` endpoint gets called.
          try {
            await axios.get(trackProgressUrl)
          } catch (error) {
            logWarning({
              message: `Failed to track course progress for: ${assignmentId}`,
              error,
            })
          }
        }

        if (event.data === 'status:close') {
          // Make the queries do a full reload so stale data is not used from the cache
          // on the exit page.
          queryClient.removeQueries(ApiPaths.courses)
          queryClient.removeQueries(ApiPaths.coursesCredits)

          redirectToCourseExitPage()
        }
      }
    }
    window.addEventListener('message', playerEventListener)
    return () => window.removeEventListener('message', playerEventListener)
  }, [
    assignmentId,
    axios,
    queryClient,
    redirectToCourseExitPage,
    trackProgressUrl,
  ])

  return (
    <div className="flex h-screen w-screen flex-col">
      {!learningProviderAccessToken ? (
        <LoadingContainer state={LoadingState.LOADING} />
      ) : (
        <Adobe
          assignmentId={assignmentId}
          token={learningProviderAccessToken}
        />
      )}
    </div>
  )
}
