import { EquityText, renderText } from '@cais-group/equity/atoms/markdown'
import { StatusTag, StatusTagProps } from '@cais-group/equity/atoms/status-tag'

export type HelperLabelProps = {
  /** Useful to be linked by other HTML elements for a11y purpose */
  id?: string
  /** Useful to link to other HTML elements for a11y purpose */
  htmlFor?: string
  /** The label to be displayed */
  children: EquityText
  /** Add an asterix next to the label */
  required?: boolean
  /** Props for the optional status tag (please refer to the StatusTag atom page for more info) */
  statusTagProps?: StatusTagProps
}

/**
 * Make sure the basic formatting inside of the label remains consistent with the label bodySmall size
 */
const smallTags = {
  overrides: {
    b: {
      props: {
        className: 'small-strong',
      },
    },
    strong: {
      props: {
        className: 'small-strong',
      },
    },
    i: {
      props: {
        className: 'small italic',
      },
    },
    em: {
      props: {
        className: 'small italic',
      },
    },
  },
}

/**
 * The helper label is utilised to show text that guides a user.
 * A typical use case would be to show a user the title of a text input.
 */
export const HelperLabel = ({
  id,
  htmlFor,
  children,
  required,
  statusTagProps,
}: HelperLabelProps) => {
  return (
    <label id={id} htmlFor={htmlFor} className="flex items-center gap-8">
      <div className="bodySmall text-neutral-600">
        {renderText(children, smallTags)}
      </div>
      {required && <div className="bodySmall text-error-600">*</div>}
      {statusTagProps ? (
        <div className="my-8">
          <StatusTag {...statusTagProps} />
        </div>
      ) : null}
    </label>
  )
}
