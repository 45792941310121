import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'

export const DEBOUNCE_RATE = 375
export const formLabels: Record<keyof ExperienceFields, string> = {
  name: 'Experience name',
  label: 'Experience label',
  contentfulId: 'Contentful experience',
  coursesEnabled: 'Courses enabled',
  ceCreditEnabled: 'CE Credits enabled',
  catalogId: 'ALM Course Catalog',
  experienceType: 'Select experience type',
  firms: 'Firms',
  active: '',
  firmIds: 'Firms',
  courses: 'Courses',
}
