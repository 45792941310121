import { AxiosError, AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import {
  ApiPaths,
  FirmBasicInfoDto,
} from '@cais-group/shared/util/type/caisiq-be'

export function useGetFirmsInAnExperience(
  options?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<FirmBasicInfoDto[]>,
          AxiosError,
          FirmBasicInfoDto[],
          string
        >,
        'queryKey'
      > & {
        experienceId?: string
        sort: string[]
      }
) {
  return useQuery(
    ApiPaths.getFirmsInExperience.replace(
      /:experienceId/,
      options?.experienceId || ''
    ),
    options
  )
}
