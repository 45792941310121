export function linkToCaisIq(relativeLink: string) {
  const hostName = window.location.hostname

  // Default to prod
  let caisIqHost = 'caisiq.caisgroup.com'

  // Specific check for localhost
  if (hostName === 'localhost') {
    caisIqHost = 'localhost:3000'
  }

  // Match using regex, this will _not_ match prod
  const match = window.location.hostname.match(/[.]([^.]+)\.caisgroup\.com/)
  const env = match ? match[1] : 'prod' // we don't handle this below as it's the default

  switch (env) {
    case 'dev':
      caisIqHost = 'caisiq.dev.caisgroup.com'
      break
    case 'qa':
      caisIqHost = 'caisiq.qa.caisgroup.com'
      break
    case 'ephemeral':
      caisIqHost = hostName.replace('members-monorepo', 'caisiq-monorepo')
      break
  }

  return new URL(relativeLink, `${window.location.protocol}//${caisIqHost}`)
}
