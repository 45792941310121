import { Navigate, useParams, NavigateProps } from 'react-router-dom'

export function RouteRedirect({ to, ...props }: NavigateProps) {
  const paramsObj = useParams()
  const params = paramsObj['*'] ? `/${paramsObj['*']}` : ''
  // Crude but must easier than extracting each search params via useSearchParams and reconstructing with encoding etc
  const queryString = window.location.search
  return <Navigate {...props} to={`${to}${params}${queryString}`} />
}

export default RouteRedirect
