import styled from '@emotion/styled'
import { MutableRefObject, ReactNode } from 'react'
import ReactDOM from 'react-dom'

import { useCloseOnEsc } from '@cais-group/shared/util/hook/use-close-on-esc'
import { useCloseOnNavigate } from '@cais-group/shared/util/hook/use-close-on-navigate'
import { useOnClickOutside } from '@cais-group/shared/util/hook/use-on-click-outside'
import { useOnUnmountFocusLastElement } from '@cais-group/shared/util/hook/use-on-unmount-focus-last-element'
import { useTrapFocus } from '@cais-group/shared/util/hook/use-trap-focus'

const FillScreen = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`

export type SharedPortalProps = {
  children?: ReactNode
  onClose: () => void
  modalRef: MutableRefObject<HTMLDivElement>
  portalId: string
}

/**
 * SharedPortal manages
 *
 * This modal adds tab trapping and keyboard functionality for ADA compliance
 */
export const SharedPortal = (props: SharedPortalProps) => {
  const { children, onClose, modalRef, portalId } = props

  useOnUnmountFocusLastElement()
  useTrapFocus({ refEl: modalRef, shouldTrapFocus: !!children })
  useCloseOnEsc(onClose)
  useOnClickOutside({ ref: modalRef, callback: onClose })
  useCloseOnNavigate()

  if (!children) {
    const bodyDomNode = document.getElementsByTagName('body')[0]
    bodyDomNode.style.overflow = 'auto'
    return null
  }

  const modalDomNode = document.getElementById(portalId)

  const bodyDomNode = document.getElementsByTagName('body')[0]
  bodyDomNode.style.overflow = 'hidden'

  if (modalDomNode === null)
    throw new Error(`DOM node "${portalId}" not found.`)

  return ReactDOM.createPortal(
    // Wrap children with FillScreen, to prevent elements on the background from being clicked
    <FillScreen data-testid="shared-portal">{children}</FillScreen>,
    modalDomNode
  )
}
