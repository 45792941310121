import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useRecoilState } from 'recoil'

import { ModalLayout } from '@cais-group/approved/ui/modal/layout'
import {
  usePostExperience,
  usePutExperienceCourseMetadata,
} from '@cais-group/caisiq/domain/experience'
import {
  formLabels,
  useFormSaveHandler,
  useGetManageCaisiqCoursesByCatalogId,
} from '@cais-group/caisiq/domain/manage-caisiq'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { LayoutSettingsPage } from '@cais-group/caisiq/ui/layout/settings-page'
import { CapabilitiesStep } from '@cais-group/caisiq/ui/manage/capabilities-step'
import { CourseConfigurationStep } from '@cais-group/caisiq/ui/manage/course-configuration-step'
import { filterExperienceCoursesBySearch } from '@cais-group/caisiq/ui/manage/course-configuration-table'
import { ExperienceNameStep } from '@cais-group/caisiq/ui/manage/experience-name-step-textfield'
import { useContentfulNameFromIdMapper } from '@cais-group/caisiq/util/contentful/use-contentful-name-from-id-mapper'
import { useInvalidateByRegex } from '@cais-group/caisiq/util/default-query-function'
import { useGetExperiencesQuery } from '@cais-group/caisiq/util/graphql/contentful'
import { transformCourseMetadataRequest } from '@cais-group/caisiq/util/manage/transform-course-metadata-request'
import { transformExperienceRequest } from '@cais-group/caisiq/util/manage/transform-experience-request'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Button } from '@cais-group/equity/atoms/button'
import { showToast } from '@cais-group/equity/organisms/notifications'
import { FormStepProvider } from '@cais-group/shared/ui/ordered-fieldset'
import { ActionToolbar } from '@cais-group/shared/ui/uniform'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  Catalog,
  ApiPaths,
  ExperienceResponse,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'

const ScrollableForm = ({
  children,
  onSetReachBottom,
}: {
  children: ReactNode
  onSetReachBottom: Dispatch<SetStateAction<boolean>>
}) => {
  const scrollableRef = useRef<HTMLDivElement>(null)

  const callback = useCallback(
    (bottom: boolean) => {
      onSetReachBottom(bottom)
    },
    [onSetReachBottom]
  )

  const onScroll = () => {
    if (scrollableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current

      // The user has scrolled to the bottom of the component
      if (scrollTop + clientHeight >= scrollHeight) {
        callback(true)
      } else {
        callback(false)
      }
    }
  }
  return (
    <div
      className="max-h-[calc(84vh-56px)] min-h-[fit-content] overflow-y-auto"
      onScroll={onScroll}
      ref={scrollableRef}
    >
      {children}
    </div>
  )
}
const createExperienceDefaults: Omit<ExperienceFields, 'firms'> = {
  name: '',
  label: '',
  contentfulId: '',
  coursesEnabled: false,
  ceCreditEnabled: false,
  catalogId: undefined,
  experienceType: 'FIRM',
}

interface CreateExperienceModalProps {
  open: boolean
  closeModal: () => void
  onCreateExperience: (data: ExperienceResponse) => void
}
export const CreateExperienceModal = ({
  open,
  closeModal,
  onCreateExperience,
}: CreateExperienceModalProps) => {
  const [searchTerm] = useRecoilState(searchTermAtom)

  const [hasMoreContent, setHasMoreContent] = useState(false)
  const [reachedBottom, setReachedBottom] = useState(false)

  const contentfulExperiences = useGetExperiencesQuery({
    preview: previewService.enabled,
  })
  const catalogs = useQuery<Catalog[]>(ApiPaths.getAllCatalogs)

  const methods = useForm<ExperienceFields>({
    mode: 'onBlur',
    defaultValues: createExperienceDefaults,
  })

  const catalogId = methods.watch('catalogId')
  const formCourses = methods.watch('courses')
  const maybeSearchFilteredExperienceCourses = filterExperienceCoursesBySearch(
    searchTerm,
    formCourses
  )

  const { mutate } = usePostExperience()

  const { data: courses, isFetched } = useGetManageCaisiqCoursesByCatalogId({
    catalogId: catalogId,
    enabled: Boolean(catalogId),
  })

  const formName = useId()
  const { getValues, reset } = methods
  const formRef = useRef<HTMLDivElement | null>(null)

  const values = getValues()
  const getContentfulNameById = useContentfulNameFromIdMapper()
  const invalidate = useInvalidateByRegex()
  const { mutate: mutateCourses } = usePutExperienceCourseMetadata()
  const handleSubmit = useFormSaveHandler({
    mutate,
    transform: transformExperienceRequest,
    onSuccess: async (data?: ExperienceResponse, params?) => {
      if (params?.courses && data?.uuid) {
        const { metadata } = transformCourseMetadataRequest(params.courses)
        mutateCourses({
          experienceId: data.uuid,
          metadata,
        })
      }
      await invalidate(/manage[/]experiences/)
      await invalidate(/manage[/]experiences[/]/)
      await invalidate(/v1[/]manage[/]courses[/]metadata/)
      if (data) {
        onCreateExperience(data)
      }
      closeModal()
      showToast({
        type: 'success',
        title: 'A new experience has been created',
      })
    },
  })

  const handleSubmitWithTracking = async (params: ExperienceFields) => {
    await handleSubmit(params)
    trackingService.manageNewExperienceSubmitted({
      name: params.name,
      label: params.label,
      contentfulName: getContentfulNameById(params.contentfulId),
      almCatalog: params.catalogId ?? '',
      coursesEnabled: params.coursesEnabled,
      ceCreditsEnabled: params.ceCreditEnabled,
      type: params.experienceType,
      firmIds: params.firmIds ?? [],
    })
  }
  useLayoutEffect(() => {
    const body = document.getElementById('modal-modal-description')
    // We want to display a bottom shadow on the modal body if the form has more content to scroll
    if (values && body?.clientHeight && formRef?.current?.clientHeight) {
      setHasMoreContent(body.clientHeight < formRef.current.clientHeight)
    }
  }, [values])

  const hasFullAccess = userSettingsService.hasRole(UserRole.CaisiqManageWrite)

  useEffect(() => {
    if (courses) {
      methods.setValue('courses', courses)
    }
  }, [courses, methods])

  return (
    <ModalLayout
      extraLarge
      hideCloseButton
      $renderFullHeight
      // Do not show the bottom box shadow if the user has scrolled to bottom of the form
      hasMoreContent={Boolean(hasMoreContent && !reachedBottom)}
      open={open}
      onClose={() => {
        closeModal()
        reset()
      }}
      title={
        <div className="[&>div>div>nav]:pb-0 [&>div]:border-b-0">
          <ActionToolbar
            title="Create New Experience"
            onClickBack={() => {
              closeModal()
              reset()
            }}
            actions={[
              <Button
                key="1"
                variant="secondary"
                disabled={methods.formState.isSubmitting}
                onClick={() => {
                  closeModal()
                  reset()
                }}
              >
                Cancel
              </Button>,
              <Button
                key="2"
                type="submit"
                form={formName}
                loading={methods.formState.isSubmitting}
              >
                {methods.formState.isSubmitting
                  ? `Creating...`
                  : 'Create New Experience'}
              </Button>,
            ]}
          />
        </div>
      }
      actions={[]}
      body={
        <FormStepProvider stateless={true}>
          <FormProvider {...methods}>
            <ScrollableForm onSetReachBottom={setReachedBottom}>
              <div
                ref={formRef}
                className="text-neutral-900 [&>div>form]:pt-0 [&>div>header]:border-b-0"
              >
                <LayoutSettingsPage<ExperienceFields>
                  onSubmit={handleSubmitWithTracking}
                  methods={methods}
                  id={formName}
                >
                  <section className="col-span-1 max-w-[560px] [&>fieldset>legend]:mb-0 [&>fieldset]:pb-0 ">
                    <ExperienceNameStep
                      order={0}
                      experiences={
                        contentfulExperiences.data?.firmCollection?.items
                      }
                      formLabels={formLabels}
                      legend="Experience Name"
                    />
                    <CapabilitiesStep
                      order={0}
                      catalogs={catalogs.data || []}
                      formLabels={formLabels}
                      legend="Capabilities"
                    />
                    <CourseConfigurationStep
                      order={0}
                      legend="Course Configuration"
                      disabled={!hasFullAccess}
                      courses={maybeSearchFilteredExperienceCourses || []}
                      isFetched={isFetched}
                      className="pt-32"
                    />
                  </section>
                </LayoutSettingsPage>
              </div>
            </ScrollableForm>
          </FormProvider>
        </FormStepProvider>
      }
    />
  )
}
