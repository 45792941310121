import { Suspense, lazy } from 'react'

import { useModal } from './context'
import type { ConfirmModalProps } from './templates/confirm'
import type { CreateUniformModalProps } from './templates/uniform'
import type { ViewClientModalProps } from './templates/view-client'
import type { ViewHouseholdModalProps } from './templates/view-household'

export type ModalComponents = {
  CONFIRM_MODAL: React.ComponentType<ConfirmModalProps>
  CREATE_UNIFORM_MODAL: React.ComponentType<CreateUniformModalProps>
  VIEW_CLIENT_MODAL: React.ComponentType<ViewClientModalProps>
  VIEW_HOUSEHOLD_MODAL: React.ComponentType<ViewHouseholdModalProps>
}

const MODAL_COMPONENTS: ModalComponents = {
  CONFIRM_MODAL: lazy(() => import('./templates/confirm')),
  CREATE_UNIFORM_MODAL: lazy(() => import('./templates/uniform')),
  VIEW_CLIENT_MODAL: lazy(() => import('./templates/view-client')),
  VIEW_HOUSEHOLD_MODAL: lazy(() => import('./templates/view-household')),
}

export const ModalRoot = () => {
  const { modalType, modalProps = {} } = useModal()

  if (!modalType) {
    return null
  }

  const SpecificModal = MODAL_COMPONENTS[modalType] as React.ComponentType

  return (
    <Suspense fallback={null}>
      <SpecificModal {...modalProps} />
    </Suspense>
  )
}
