import styled from '@emotion/styled'
import type { UIEvent, ReactNode, MutableRefObject } from 'react'

import { colors, Color, setOpacity } from '@cais-group/equity/particles/colors'
import {
  isHorizontal,
  getGradientAngle,
} from '@cais-group/shared/util/js/common'
import { Side } from '@cais-group/shared/util/type/side'

export type FadeDefaultProps = {
  length?: string
  side?: Side
  allowScroll?: boolean
  onScroll?: (event: UIEvent<HTMLElement>) => void
  containerRef?: MutableRefObject<HTMLDivElement>
  contentRef?: MutableRefObject<HTMLDivElement>
  children?: ReactNode
  fadeColor?: Color
}

// flex-grow required for safari only
const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-grow: 1;
`

const ScrollBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
`

type GradientProps = { length: string; side: Side; fadeColor?: Color }

const Gradient = styled.div<GradientProps>(
  ({ length, side, fadeColor = 'eq-color-neutral-0' }: GradientProps) => {
    return `
      position: absolute;
      bottom: ${side === Side.TOP ? 'auto' : 0};
      left: ${side === Side.RIGHT ? 'auto' : 0};
      right: ${side === Side.LEFT ? 'auto' : 0};
      top: ${side === Side.BOTTOM ? 'auto' : 0};
      height: ${isHorizontal(side) ? 'auto' : length};
      width: ${isHorizontal(side) ? length : 'auto'};
      background: linear-gradient(
        ${getGradientAngle(side)},
        ${setOpacity(fadeColor, 0)} 0%,
        ${colors[fadeColor]} 90%
      );
      pointer-events: none;
      overflow: hidden;
    `
  }
)

export const FadeDefault = (props: FadeDefaultProps) => {
  const {
    length = '20%',
    side = Side.BOTTOM,
    allowScroll = false,
    onScroll,
    containerRef,
    contentRef,
    children,
    fadeColor,
  } = props

  return (
    <Container data-component="Fade">
      {allowScroll ? (
        <ScrollBox ref={containerRef} onScroll={onScroll}>
          <div className="h-full" ref={contentRef}>
            {children}
          </div>
        </ScrollBox>
      ) : (
        children
      )}
      <Gradient length={length} side={side} fadeColor={fadeColor} />
    </Container>
  )
}

FadeDefault.displayName = 'FadeDefault'
