import { components } from './generated'

// Currently need to fix some odd type generation on BE where Sealed Classes extra fields are removed
export type Course = components['schemas']['Course'] & {
  imageUrl?: string
  enrolled?: boolean
  totalEnrolled?: number
  progress?: number
  score?: number
}

export type LibraryCourse = components['schemas']['LibraryCourse']

export type CourseMetadata = components['schemas']['CourseMetadata']
export type CourseMetadataRequest =
  components['schemas']['CourseMetadataRequest']
