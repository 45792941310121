import {
  ApiState,
  useReactQueryResultAsApiState,
} from '@cais-group/caisiq/domain/common'
import { useGetArticleBySlugQuery } from '@cais-group/caisiq/util/graphql/contentful'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'

export type ArticleApiState = ApiState<TypeArticleData>

export const useGetArticle = (
  slug?: string,
  options?: {
    pause?: boolean
  }
): ArticleApiState =>
  useReactQueryResultAsApiState(
    useGetArticleBySlugQuery(
      { slug: slug || '', preview: previewService.enabled },
      { enabled: !(slug === undefined || options?.pause) }
    ),
    (data) =>
      data.caisiqArticleCollection?.items[0] as unknown as TypeArticleData,
    'Could not load caisiqArticleCollectionQuery'
  )
