import { Row } from '@tanstack/react-table'
import { useState, useCallback, useMemo } from 'react'
import { useMutation } from 'react-query'

import { getAxiosInstance } from '@cais-group/caisiq/util/hook/use-axios-instance'
import {
  ApiPaths,
  CaisIqFirm,
  CaisIqFirmWithChildren,
  GetCaisIqFirmSearchResult,
} from '@cais-group/shared/util/type/caisiq-be'

export type FirmChildrenRow = {
  parentFirmId: string
  data: CaisIqFirm[]
}

export const searchChildFirms = (parentFirmId: string) => {
  const axios = getAxiosInstance()

  return axios.post<GetCaisIqFirmSearchResult>(ApiPaths.searchFirms, {
    filter: {
      parentFirmId: parentFirmId,
    },
  })
}

export function useSearchChildFirms() {
  return useMutation((parentFirmId: string) => searchChildFirms(parentFirmId))
}

export function useAsyncExpandableFirmsRow(
  firmsData: CaisIqFirmWithChildren[]
) {
  const [isRowLoading, setIsRowLoading] = useState<Record<string, boolean>>({})
  const [rowChildren, setRowChildren] = useState<
    Record<string, FirmChildrenRow>
  >({})
  const { mutateAsync } = useSearchChildFirms()
  const handleSearchChildFirms = useCallback(
    (parentFirmId: string) => mutateAsync(parentFirmId),
    [mutateAsync]
  )

  const handleClickRow = useCallback(
    async (row: Row<CaisIqFirmWithChildren>) => {
      if (isRowLoading[row.id] === false) {
        return
      }
      setIsRowLoading({ [row.id]: true })

      const { data } = await handleSearchChildFirms(row.original.id)

      setIsRowLoading({ ...isRowLoading, [row.id]: false })
      setRowChildren({
        ...rowChildren,
        [row.id]: {
          parentFirmId: row.original.id,
          data: data.items,
        },
      })
    },
    [handleSearchChildFirms, isRowLoading, rowChildren]
  )

  const rows = useMemo(() => {
    const firmsWithChildren = [...firmsData]

    for (const id of Object.keys(rowChildren)) {
      const childRows = rowChildren[id]
      const parent = firmsWithChildren[Number(id)]

      // Handle case where id is for a nested row in the form x.y
      if (id.includes('.')) {
        const [parentId, childId] = id.split('.').map((id) => Number(id))
        if (
          !firmsWithChildren[parentId] ||
          !firmsWithChildren[parentId].children ||
          // We want to make sure that child rows are only added to the parentFirmId for which the request
          // was made.
          //@ts-ignore
          firmsWithChildren[parentId].children[childId].id !==
            childRows.parentFirmId
        ) {
          return firmsWithChildren
        }
        //@ts-ignore
        const parent = firmsWithChildren[parentId].children[childId]
        //@ts-ignore
        firmsWithChildren[parentId].children[childId] = {
          ...parent,
          children: childRows.data,
        }
      } else if (
        parent?.hasChildFirms &&
        // This ensures that child firms are added to the correct parent firm.
        parent.id === childRows.parentFirmId
      ) {
        //@ts-ignore
        firmsWithChildren[id] = { ...parent, children: childRows.data }
      }
    }
    return firmsWithChildren
  }, [firmsData, rowChildren])

  return {
    rows,
    handleClickRow,
    isRowLoading,
  }
}
