import { useEffect, useState } from 'react'

import { AttentionBox } from '@cais-group/equity/molecules/attention-box'
import {
  CONTENT_OWNERSHIP_DISCLAIMER_QUERY_SLUG,
  checkIfDisclaimerNeededAndReturnFirms,
  transformDisclaimer,
  useGetDisclaimer,
} from '@cais-group/homepage/domain/contentful'
import { useGetAllowedFirms } from '@cais-group/homepage/domain/members'
import type {
  ContentDataType,
  CuratedContentDataType,
} from '@cais-group/homepage/util/types'
import { getEnvConfig, type MfeEnv } from '@cais-group/shared/ui/env'
import { logWarning } from '@cais-group/shared/util/logging'

/**
 Note: can be used to identify the component and apply styles.
 Since we're using Tailwind cannot use dynamically so search for the name instead.
*/
const componentName = 'content-disclaimer'

type ContentDisclaimerProps = {
  items: (ContentDataType | CuratedContentDataType)[] | null
  className?: string
}

export function MfeContentDisclaimer(props: ContentDisclaimerProps) {
  const disclaimer = useGetDisclaimer({
    id: CONTENT_OWNERSHIP_DISCLAIMER_QUERY_SLUG,
  })
  const { data: userProfileFirms } = useGetAllowedFirms()

  const [contentOwnershipDisclaimer, setContentOwnershipDisclaimer] = useState<
    string | undefined
  >()

  const { items, className } = props

  const contentfulFirms = checkIfDisclaimerNeededAndReturnFirms(items ?? [])

  useEffect(() => {
    // We want the disclaimer to persist if it's already set for a page
    if (contentOwnershipDisclaimer) {
      return
    }
    try {
      const transformedDisclaimer = transformDisclaimer({
        disclaimer,
        userProfileFirms,
        contentfulFirms,
      })
      setContentOwnershipDisclaimer(transformedDisclaimer)
    } catch (e) {
      logWarning({
        message: 'Error setting content disclaimer',
        error: e,
      })
    }
  }, [
    contentOwnershipDisclaimer,
    contentfulFirms,
    disclaimer,
    userProfileFirms,
  ])

  return contentOwnershipDisclaimer ? (
    <section
      className={className}
      aria-label="Content ownership disclaimer"
      data-component={componentName}
    >
      <AttentionBox title={disclaimer?.title || 'Disclaimer'}>
        <p className="small text-neutral-600">{contentOwnershipDisclaimer}</p>
      </AttentionBox>
    </section>
  ) : null
}

export const ContentDisclaimer = (
  props: ContentDisclaimerProps & {
    envConfig?: MfeEnv
  }
) => {
  const { envConfig = getEnvConfig() } = props

  if (isCiq(envConfig)) {
    return null
  }

  return <MfeContentDisclaimer {...props} />
}

function isCiq(
  envConfig:
    | {
        CAISIQ?: boolean
      }
    | MfeEnv
): envConfig is { CAISIQ: boolean } {
  const env = envConfig as { CAISIQ: boolean }
  return env.CAISIQ === true
}
