import { AxiosResponse, AxiosError } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'

import {
  CourseMetadata,
  ApiPaths,
} from '@cais-group/shared/util/type/caisiq-be'

export function useGetBaseCoursesMetadata(
  options?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<CourseMetadata[]>,
          AxiosError,
          CourseMetadata[],
          string
        >,
        'queryKey'
      > & {
        paused: boolean
      }
) {
  return useQuery<CourseMetadata[]>(ApiPaths.getAllBaseCourseConfigs, {
    enabled: !options?.paused,
  })
}
