import { forwardRef } from 'react'

import { TextInput, TextInputProps } from './text-input'

export type SearchInputProps = TextInputProps & {
  onClear?: () => void
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => {
    const { onClear, ...textInputProps } = props
    const onClearIsActive = props.value && typeof onClear === 'function'
    return (
      <>
        <label htmlFor={props.id} className="sr-only">
          Search
        </label>
        <TextInput
          {...textInputProps}
          startAdornment={{
            type: 'Search',
            color: 'eq-color-neutral-900',
          }}
          endAdornment={{
            type: 'Close',
            color: onClearIsActive
              ? 'eq-color-neutral-300'
              : 'eq-color-neutral-0',
            onClick: onClearIsActive ? onClear : undefined,
          }}
          ref={ref}
        />
      </>
    )
  }
)
