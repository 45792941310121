import { useCallback } from 'react'
import { QueryClient, useMutation } from 'react-query'

import {
  defaultPersister,
  queryDefaults,
  useInvalidateByRegex,
} from '@cais-group/caisiq/util/default-query-function'
import { useApiPathWithPayload } from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { showToast } from '@cais-group/equity/organisms/notifications'
import {
  ApiPaths,
  CaisIqFirm,
  CreateExperienceRequest,
  ExperienceResponse,
} from '@cais-group/shared/util/type/caisiq-be'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: queryDefaults,
  },
})

export const usePutEnableExperience = (experience?: ExperienceResponse) => {
  const invalidate = useInvalidateByRegex()

  const endpoint = useApiPathWithPayload(ApiPaths.getFirmsInExperience)({
    id: experience?.uuid,
    experienceId: experience?.uuid,
  })

  const getParsedEndpoint = useApiPathWithPayload(ApiPaths.updateExperience)({
    experienceId: experience?.uuid,
  })

  const { mutate } = useMutation((data: CreateExperienceRequest) =>
    defaultPersister<CreateExperienceRequest, ExperienceResponse>(
      getParsedEndpoint,
      data,
      experience?.version,
      'PUT'
    )
  )

  const handleEnable = useCallback(async () => {
    if (experience?.uuid) {
      // since we invalidate the cache onSuccess, using the hook useQuery to fetch for this endpoint gets called with an undefined uuid throwing a console error
      const firms = await queryClient.fetchQuery<CaisIqFirm[]>(endpoint)
      const firmIds = firms.map((firm) => firm.id)

      try {
        const firmsOrEmpty =
          experience.type === 'DIRECT' || !firmIds ? [] : firmIds

        return mutate(
          {
            name: experience.name,
            active: true,
            firmIds: firmsOrEmpty,
            experienceType: experience.type,
            catalogId: experience.catalog?.id,
            label: experience.label,
            ceCreditEnabled: experience.ceCreditEnabled,
            coursesEnabled: experience.coursesEnabled,
            contentfulId: experience.contentfulId,
          },

          {
            onSuccess: async () => {
              await invalidate(/manage[/]experiences/)
              await invalidate(/manage[/]firms/)
              showToast({
                type: 'success',
                title: `${experience.name} has been enabled`,
              })
              trackingService.manageExperienceUpdated({
                name: experience.name,
                label: experience.label,
                contentfulName: experience.contentfulId || 'unspecified',
                almCatalog: experience.catalog?.id || '',
                coursesEnabled: experience.coursesEnabled,
                ceCreditsEnabled: experience.ceCreditEnabled,
                type: experience.type,
                firmIds: firmsOrEmpty,
              })
            },
            onError: () => {
              showToast({
                type: 'error',
                title: `There was an error enabling ${experience.name}`,
              })
            },
          }
        )
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }, [endpoint, experience, invalidate, mutate])

  return { handleEnable }
}
