import styled from '@emotion/styled'
import { Switch } from '@mui/material'
import MuiButton from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import MuiCheckbox from '@mui/material/Checkbox'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import MuiFormGroup from '@mui/material/FormGroup'
import { useState, useCallback, useEffect } from 'react'

import { BodyStrong } from '@cais-group/approved/ui/typography'

import {
  ButtonMenuContainer,
  ButtonMenuContainerProps,
} from '../button-menu-container'

const ButtonMenuCheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const OptionsAndTitleWrapper = styled.div`
  max-height: var(--s376);
  overflow-y: auto;
  margin: calc(-1 * var(--s32)) calc(-1 * var(--s32)) 0 0;
  padding-top: var(--s32);
  padding-right: var(--s32);
`

const OptionsTitleWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--s12);
`

const OptionsTitle = styled(BodyStrong)`
  margin-right: auto;
`

const OptionsWrapper = styled.div`
  padding: 0 var(--s12) var(--s32);
`

export interface ButtonMenuCheckboxOption<V = string> {
  label: string
  value: V
}
export interface ButtonMenuCheckboxProps<T = string> {
  label: string
  title?: string
  options: Array<ButtonMenuCheckboxOption<T>>
  value: Array<ButtonMenuCheckboxOption<T>>
  onChange: (option: Array<ButtonMenuCheckboxOption<T>>) => void
  buttonProps?: ButtonMenuContainerProps['buttonProps']
  menuPlacement?: ButtonMenuContainerProps['menuPlacement']
  'data-testid'?: string
}
export function ButtonMenuCheckbox<T = string>({
  label,
  title,
  options,
  value: externalValue,
  onChange,
  buttonProps,
  menuPlacement,
  'data-testid': dataTestid,
}: ButtonMenuCheckboxProps<T>) {
  const [internalValue, setInternalValue] = useState(externalValue)

  useEffect(() => {
    setInternalValue(externalValue)
  }, [externalValue])

  const toggleCheckbox = useCallback(
    (option: ButtonMenuCheckboxOption<T>) => {
      let newValue: Array<ButtonMenuCheckboxOption<T>> = []

      const foundOptionIndex = internalValue.findIndex(
        (opt) => opt.value === option.value
      )
      const isCurrentlyActive = foundOptionIndex > -1

      if (!isCurrentlyActive) {
        newValue = [...internalValue, option]
      } else {
        newValue = [...internalValue]
        newValue.splice(foundOptionIndex, 1)
      }
      setInternalValue(newValue)
    },
    [internalValue, setInternalValue]
  )

  const onSaveHandler = useCallback(
    ({
      setIsMenuActive,
    }: {
      setIsMenuActive: React.Dispatch<React.SetStateAction<boolean>>
    }) => {
      onChange(internalValue)
      setIsMenuActive(false)
    },
    [onChange, internalValue]
  )

  const onClearHandler = useCallback(() => {
    setInternalValue([])
  }, [setInternalValue])

  const toggleAll = useCallback(() => {
    if (internalValue.length === options.length) {
      setInternalValue([])
    } else {
      setInternalValue(options)
    }
  }, [internalValue.length, options])

  return (
    <ButtonMenuContainer
      data-testid={dataTestid}
      label={label}
      variant={externalValue.length ? 'dark' : 'neutral'}
      buttonProps={{ disabled: options.length < 2, ...buttonProps }}
      menuPlacement={menuPlacement}
    >
      {({ setIsMenuActive }) => (
        <ButtonMenuCheckBoxContainer>
          <OptionsAndTitleWrapper>
            {title && (
              <OptionsTitleWrapper>
                <OptionsTitle>{title}</OptionsTitle>
                <Switch
                  onClick={toggleAll}
                  checked={internalValue.length === options.length}
                  size="small"
                />
              </OptionsTitleWrapper>
            )}
            <OptionsWrapper>
              {options.map((option, idx) => (
                <MuiFormGroup key={`${option.label}-${idx}`}>
                  <MuiFormControlLabel
                    control={
                      <MuiCheckbox
                        data-testid="ButtonMenuCheckbox-menuItem"
                        checked={
                          internalValue.findIndex(
                            (opt) => opt.value === option.value
                          ) > -1
                        }
                        onChange={() => {
                          toggleCheckbox(option)
                        }}
                        sx={{ padding: '0', marginRight: 'var(--s16)' }}
                      />
                    }
                    label={option.label}
                    sx={{ alignItems: 'flex-start', margin: 'var(--s4) 0' }}
                  />
                </MuiFormGroup>
              ))}
            </OptionsWrapper>
          </OptionsAndTitleWrapper>
          <CardActions
            sx={{
              borderTop: `1px solid rgb(var(--colors-neutral-200))`,
              justifyContent: 'space-between',
              position: 'sticky',
              bottom: 'calc(-1 * var(--s32))',
              margin: '0 calc(-1 * var(--s8)) calc(-1 * var(--s32)) ',
              background: 'rgb(var(--colors-neutral-0))',
            }}
          >
            <MuiButton
              data-testid="ButtonMenuCheckbox-clear"
              onClick={onClearHandler}
              color="neutral"
              size="small"
            >
              Clear
            </MuiButton>
            <MuiButton
              data-testid="ButtonMenuCheckbox-save"
              onClick={() => onSaveHandler({ setIsMenuActive })}
              size="small"
            >
              Apply
            </MuiButton>
          </CardActions>
        </ButtonMenuCheckBoxContainer>
      )}
    </ButtonMenuContainer>
  )
}
