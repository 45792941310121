import './react-table-columnmeta.d.ts'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'
import { HeaderGroup, Row as RowType, flexRender } from '@tanstack/react-table'
import * as React from 'react'
import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  Cell,
  Row,
  SortButton,
  TableContainer,
  TableScrollWrapper,
  TH,
  THead,
} from './elements'

export const TableHead = <T extends Record<string, unknown>>({
  headerGroups,
  stickyFirstColumn = true,
  hidePadding = false,
  variant = 'dark',
  className,
}: {
  headerGroups: HeaderGroup<T>[]
  stickyFirstColumn?: boolean
  hidePadding?: boolean
  variant?: 'light' | 'dark'
  className?: string
}) => {
  return (
    <THead $variant={variant} className={className}>
      {headerGroups.map((headerGroup) => (
        <Row key={headerGroup.id} {...headerGroup.headers}>
          {headerGroup.headers.map((header, i) => {
            const column = header.column
            const tooltip = column.columnDef.meta?.tooltip

            return (
              <TH
                className="table-header"
                key={header.id}
                data-testid={header.id}
                {...header.getContext()}
                style={{
                  ...(column.columnDef.meta?.headerCellStyle
                    ? column.columnDef.meta.headerCellStyle
                    : {}),
                  width: column.getSize(),
                  minWidth: column.getSize(),
                  flex: column.columnDef.meta?.fixed ? '0' : undefined,
                  flexGrow: !column.columnDef.meta?.fixed
                    ? column.columnDef.meta?.ratio
                    : '0',
                  flexBasis: !column.columnDef.meta?.fixed
                    ? column.columnDef.meta?.ratio
                    : undefined,
                }}
                $hidePadding={hidePadding}
                $type={stickyFirstColumn && i === 0 ? 'stick' : ''}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(column.columnDef.header, header.getContext())}
                {tooltip && (
                  <Tooltip title={tooltip} placement="top">
                    <InfoOutlinedIcon
                      fontSize="small"
                      htmlColor="rgb(var(--colors-neutral-600))"
                    />
                  </Tooltip>
                )}

                {column.getCanSort() ? (
                  <SortButton
                    onClick={column.getToggleSortingHandler()}
                    data-testid={`${header.id}-sort`}
                    aria-label={`${header.id}-sort`}
                    aria-sort={
                      (column.getIsSorted() === 'asc' && 'ascending') ||
                      (column.getIsSorted() === 'desc' && 'descending') ||
                      undefined
                    }
                    $variant={variant}
                    $sort={column.getIsSorted() || undefined}
                  />
                ) : null}
              </TH>
            )
          })}
        </Row>
      ))}
    </THead>
  )
}

export const TableRows = <T extends Record<string, unknown>>({
  rows,
  hidePadding = false,
  hoverBg = false,
  selectedBg = false,
  onRowClick,
  className,
}: {
  rows: RowType<T>[]
  hidePadding?: boolean
  hoverBg?: boolean
  selectedBg?: boolean
  onRowClick?: (
    row: RowType<T>,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void
  className?: string
}) => {
  return (
    <>
      {rows.map((row) => {
        const isSelected = row.getIsSelected()
        return (
          <Row
            key={row.id}
            $hoverBg={hoverBg}
            $selectedBg={isSelected && selectedBg}
            onClick={(e) => onRowClick && onRowClick(row, e)}
            style={{ cursor: onRowClick ? 'pointer' : 'inherit' }}
            className={twMerge('table-row-item', className)}
          >
            <RowCells<T> hidePadding={hidePadding} row={row} />
          </Row>
        )
      })}
    </>
  )
}

export const CollapsibleTableRows = <T extends Record<string, unknown>>({
  rows,
  renderRowSubComponent,
  hoverBg = false,
  center = false,
  hidePadding = false,
  onRowClick,
}: {
  rows: RowType<T>[]
  renderRowSubComponent: ({
    row,
  }: {
    row: RowType<T>
  }) => React.ReactElement | null
  hoverBg?: boolean
  center?: boolean
  hidePadding?: boolean
  onRowClick?: (
    row: RowType<T>,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void
}) => {
  return (
    <>
      {rows.map((row, idx) => {
        return (
          <CollapsibleRow
            hoverBg={hoverBg}
            center={center}
            hidePadding={hidePadding}
            onRowClick={onRowClick}
            key={`CollapsibleTableRow-${idx}`}
            row={row}
            renderRowSubComponent={renderRowSubComponent}
          />
        )
      })}
    </>
  )
}

export const CollapsibleRow = <T extends Record<string, unknown>>({
  row,
  renderRowSubComponent,
  rowCellProps,
  hoverBg = false,
  center = false,
  hidePadding = false,
  onRowClick,
}: {
  row: RowType<T>
  hoverBg?: boolean
  center?: boolean
  hidePadding?: boolean
  renderRowSubComponent: ({
    row,
  }: {
    row: RowType<T>
  }) => React.ReactElement | null
  rowCellProps?: Omit<RowCellProps<T>, 'row'>
  onRowClick?: (
    row: RowType<T>,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void
}) => {
  return (
    <div>
      <Row
        onClick={(e) => onRowClick && onRowClick(row, e)}
        style={{ cursor: onRowClick ? 'pointer' : 'inherit' }}
        aria-expanded={row.getIsExpanded()}
        $hoverBg={hoverBg}
        $center={center}
      >
        <RowCells<T> row={row} hidePadding={hidePadding} {...rowCellProps} />
      </Row>

      {row.getCanExpand() && (
        <Row $expanded={row.getIsExpanded()}>
          {renderRowSubComponent({ row })}
        </Row>
      )}
    </div>
  )
}

export interface RowCellProps<T extends Record<string, unknown>> {
  row: RowType<T>
  stickyFirstColumn?: boolean
  hidePadding?: boolean
  props?: { [key: string]: unknown }
  toggleRow?: () => void
}

export const RowCells = <T extends Record<string, unknown>>({
  row,
  props,
  hidePadding = false,
  stickyFirstColumn = true,
  toggleRow,
}: RowCellProps<T>) => {
  return (
    <>
      {row.getVisibleCells().map((cell, i) => {
        return (
          <Cell
            className="table-cell"
            {...props}
            onClick={() => {
              toggleRow && toggleRow()
            }}
            $hidePadding={hidePadding}
            data-testid={cell.id}
            key={cell.id}
            $type={stickyFirstColumn && i === 0 ? 'stick' : ''}
            style={{
              ...(cell.column.columnDef.meta?.rowCellStyle
                ? cell.column.columnDef.meta.rowCellStyle
                : {}),
              minWidth: cell.column.getSize(),
              width: cell.column.getSize(),
              flex: cell.column.columnDef.meta?.fixed ? '0' : undefined,
              flexGrow: !cell.column.columnDef.meta?.fixed
                ? cell.column.columnDef.meta?.ratio
                : '0',
              flexBasis: !cell.column.columnDef.meta?.fixed
                ? cell.column.columnDef.meta?.ratio
                : undefined,
              justifyContent: cell.column.columnDef.meta?.alignment,
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Cell>
        )
      })}
    </>
  )
}

export type GroupComponent = React.FunctionComponent<{
  name: string
  toggleGroup: () => void
  isCollapsed: boolean
  total?: string
  color?: string
}>

export type Group<T extends Record<string, unknown>> = {
  component: GroupComponent
  rows: RowType<T>[]
  total?: string
}

export const TableGroups = <T extends Record<string, unknown>>({
  groups,
  groupTotals,
  expandGroupOnDataChange = false,
}: {
  groups: { [groupName: string]: Group<T> }
  groupTotals?: Record<string, string>
  expandGroupOnDataChange?: boolean
}) => {
  if (!groups) return null
  return (
    <>
      {Object.keys(groups).map((key) => {
        return (
          <TableGroup<T>
            data-testid={key}
            key={key}
            name={key}
            group={groups[key]}
            total={groupTotals ? groupTotals[key] : ''}
            expandOnDataChange={expandGroupOnDataChange}
          />
        )
      })}
    </>
  )
}
const TableGroup = <T extends Record<string, unknown>>({
  name,
  group,
  total,
  expandOnDataChange = false,
}: {
  name: string
  group: Group<T>
  total?: string
  expandOnDataChange: boolean
}) => {
  const { rows, component: Component } = group
  const [isCollapsed, setIsCollapsed] = React.useState(false)

  useEffect(() => {
    expandOnDataChange && setIsCollapsed(false)
  }, [rows.length, expandOnDataChange])

  return (
    <React.Fragment key={name}>
      <Component
        name={name}
        total={total}
        toggleGroup={() => setIsCollapsed((t) => !t)}
        isCollapsed={isCollapsed}
      />
      {!isCollapsed && <TableRows rows={rows} />}
    </React.Fragment>
  )
}

export const groupRows = <T extends { groupName?: string }>({
  rows,
  component,
}: {
  rows: RowType<T>[]
  component: GroupComponent
}) => {
  if (rows.length === 0) return {}

  const groups = rows.reduce(
    (acc: { [groupName: string]: Group<T> }, row: RowType<T>) => {
      const groupName = row.original.groupName
      if (groupName)
        return {
          ...acc,
          [groupName]: {
            component: component, // <-- the column component
            rows: [...(acc?.[groupName]?.rows || []), row], // <-- the rows for this groupName : array of all the rows previously accumulated for this groupName and the current row
          },
        }
      return acc
    },
    {}
  )

  return groups
}

/**
 * For use with react-table, see examples in storybook
 *
 */
export const Table = ({
  children,
  noScroll,
  'data-testid': dataTestId,
  className,
  $noResultMode,
}: {
  children: React.ReactNode
  noScroll?: boolean
  'data-testid'?: string
  className?: string
  $noResultMode?: boolean
}) => {
  return (
    <TableScrollWrapper data-testid={dataTestId} $noResultMode={$noResultMode}>
      <TableContainer
        $noScroll={noScroll}
        className={className}
        $noResultMode={$noResultMode}
      >
        {children}
      </TableContainer>
    </TableScrollWrapper>
  )
}
