import { APP_PATHS } from '@cais-group/shared/domain/apps'

import * as homepage from './homepage'
export { trackingLabels as homepageTrackingLabels } from './homepage'

type AppIds = (typeof APP_PATHS)[number]
type MappingFunction = (pathname: string) => string
type SectionMap = Partial<Record<AppIds, MappingFunction>>

const sectionMapByAppId: SectionMap = {
  homepage: homepage.mapUrlToSection,
}

const getMappingFunction = (appId: AppIds) =>
  sectionMapByAppId[appId] ?? (() => 'Unknown')

/**
 * Get the section name for the current URL
 * @param appId
 * @returns string
 */
export function getUrlToSectionMap(appId: AppIds) {
  const mappingFunction = getMappingFunction(appId)
  return mappingFunction(window.location.pathname)
}

/**
 * Initialize the mapping function for the given app
 * @param appId
 * @returns function that will perform the mapping when called
 */
export function initUrlToSectionMap(appId: AppIds) {
  const mappingFunction = getMappingFunction(appId)
  return () => mappingFunction(window.location.pathname)
}
