import chroma from 'chroma-js'
import classNames from 'classnames'
import * as React from 'react'

export const useGetRandomColors = (count: number): string[] => {
  return React.useMemo(() => {
    return chroma.scale(['#6C60EC', chroma.random().saturate()]).colors(count)
  }, [count])
}

export const Shape: React.FC<{
  label: string
  rounded?: boolean
  backgroundColor?: string
  className?: string
}> = React.memo(
  ({ label, rounded, backgroundColor = 'transparent', className }) => {
    return (
      <div
        className={classNames(
          'flex h-[24px] w-[24px] flex-none items-center justify-center',
          rounded && 'rounded-full',
          backgroundColor === 'transparent'
            ? 'text-neutral-900'
            : 'text-neutral-0',
          className
        )}
        style={{ backgroundColor }}
      >
        {label}
      </div>
    )
  }
)
