import * as React from 'react'

type EventListener = keyof DocumentEventMap

export function useEventListeners<T extends EventListener>(
  listeners: T[],
  {
    enabled = false,
    subscribe = () => {},
  }: {
    enabled?: boolean
    subscribe?: (event: T | null) => void
  } = {}
): void {
  const eventHandlers = React.useMemo(() => {
    const handlers: Record<string, () => void> = {}

    // Create event handlers based on the event listeners
    listeners.forEach((eventName) => {
      handlers[eventName] = () => subscribe(eventName)
    })

    return handlers
  }, [listeners, subscribe])

  React.useEffect(() => {
    if (!enabled) return // Do nothing if not enabled

    // Add event listeners when enabled
    listeners.forEach((eventName) => {
      document.addEventListener(eventName, eventHandlers[eventName])
    })

    // Remove event listeners when unmounted or disabled
    return () => {
      listeners.forEach((eventName) => {
        document.removeEventListener(eventName, eventHandlers[eventName])
      })
    }
  }, [listeners, eventHandlers, enabled])

  return undefined
}
