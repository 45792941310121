import { faker } from '@faker-js/faker/locale/en'
import { Sync } from 'factory.ts'

import { HouseholdResponseV1 } from '../../generated/api.schemas'

export const householdFactory = Sync.makeFactory<HouseholdResponseV1>(() => ({
  name: `${faker.person.fullName()} Household`,
  caisId: faker.string.uuid(),
  createdAt: faker.date.past().toISOString(),
  updatedAt: faker.date.recent().toISOString(),
  firmId: faker.string.uuid(),
  id: faker.string.uuid(),
  teamIds: [],
}))
