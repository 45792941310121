import { Box, CardActions } from '@mui/material'
import MuiCard from '@mui/material/Card'
import MuiCardContent from '@mui/material/CardContent'
import { Control, Controller } from 'react-hook-form'

import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { Button } from '@cais-group/equity/atoms/button'

export type EnableDisableBoxProps = {
  onSubmit: () => void
  options: {
    title: string
    body: string
    buttonText: string
  }
  testId?: string
  control: Control<ExperienceFields, unknown>
}

export const EnableDisableBox = ({
  onSubmit,
  options,
  control,
  testId,
}: EnableDisableBoxProps) => {
  return (
    <Box>
      <MuiCard
        variant="outlined"
        sx={{
          borderColor: 'rgb(var(--colors-neutral-200))',
        }}
      >
        <MuiCardContent>
          <h6 className="h6Strong">{options.title}</h6>
          <p className="bodySmall w-[281px] break-words pb-8 pt-8 text-neutral-600">
            {options.body}
          </p>
          <CardActions>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <Button
                  onBlur={field.onBlur}
                  aria-pressed={field.value}
                  data-testid={testId ?? 'enable-disable-button'}
                  size="medium"
                  variant="primary"
                  color={field.value ? 'error' : 'primary'}
                  onClick={onSubmit}
                >
                  {options.buttonText}
                </Button>
              )}
            />
          </CardActions>
        </MuiCardContent>
      </MuiCard>
    </Box>
  )
}
