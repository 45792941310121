import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Link, LinkProps } from 'react-router-dom'

import {
  H4Strong,
  Overline,
  SubtitleMono,
} from '@cais-group/approved/ui/typography'
import { PageType } from '@cais-group/caisiq/ui/article/header'
import { setOpacity } from '@cais-group/equity/particles/colors'
import { ellipsify } from '@cais-group/equity/utilitarian'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

export type CardWhitepaperProps = {
  whitepaper: WhitepaperData
  to: LinkProps['to']
  state: LinkProps['state']
  onClick?: () => void
}

const StyledContainer = styled.div<{ backgroundImageUrl?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 425px;
  min-width: calc(100vw - calc(var(--s56) * 2));
  ${({ backgroundImageUrl }) =>
    backgroundImageUrl ? `background-image: url(${backgroundImageUrl});` : ''}
  background-size: cover;
  justify-content: flex-end;
  &:hover {
    transform: scale(0.99);
  }
  @media screen and (min-width: 960px) {
    min-width: 300px;
  }
`

const FadeBackground = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: var(--s24);
  background: linear-gradient(
    0deg,
    rgb(0 0 0 / 54%) 0%,
    ${setOpacity('eq-color-neutral-900', 0.005)} 100%
  );
  & > * {
    margin-left: var(--s56);
    margin-bottom: var(--s24);
  }
`
const Subtitle = styled(SubtitleMono)`
  color: rgb(var(--colors-neutral-0));
  margin-left: var(--s24);
  margin-right: var(--s24);
  @media screen and (min-width: 960px) {
    margin-left: var(--s56);
    margin-right: var(--s56);
  }
`
Subtitle.displayName = 'Subtitle'

const StyledName = styled(H4Strong)`
  color: rgb(var(--colors-neutral-0));
  width: fit-content;
  margin-left: var(--s24);
  margin-right: var(--s24);

  @media screen and (min-width: 960px) {
    margin-left: var(--s56);
    margin-right: var(--s56);
  }
`

const Type = styled(Overline)`
  color: rgb(var(--colors-neutral-0));
  margin-bottom: 10px;
  margin-left: var(--s24);
  margin-right: var(--s24);
  @media screen and (min-width: 960px) {
    margin-left: var(--s56);
    margin-right: var(--s56);
  }
`

export const CardWhitepaper = (props: CardWhitepaperProps) => {
  const { whitepaper, to, state, onClick } = props

  return (
    <Link to={to} state={state} onClick={onClick} data-testid="whitepaper-card">
      <StyledContainer
        backgroundImageUrl={
          whitepaper.thumbnail?.url ? `${whitepaper.thumbnail.url}?fm=jpg` : ''
        }
      >
        <FadeBackground>
          <Type>{whitepaper.type || `${PageType.WHITEPAPER}`}</Type>
          <StyledName>{ellipsify(whitepaper.title, 75)}</StyledName>
          <Subtitle>
            {dayjs(
              whitepaper.displayDate || whitepaper.sys.firstPublishedAt
            ).format('MMM D')}{' '}
            • by {whitepaper.author.name}
          </Subtitle>
        </FadeBackground>
      </StyledContainer>
    </Link>
  )
}
