import * as React from 'react'

import { createDefaultUniformDictionary } from '../adapters'
import { UniformServices } from '../services'
import type { ComponentDict, UniformAny } from '../types'

export function useComponentDict<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict
>(componentDictionary?: TComponentDict): TComponentDict {
  const dict = React.useMemo(() => {
    return componentDictionary
      ? componentDictionary
      : createDefaultUniformDictionary<TFormFieldIds, TUniformServices>()
  }, [componentDictionary])
  return dict as TComponentDict
}
