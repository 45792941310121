import React from 'react'

import type { UserProfileV1 } from '@cais-group/access-manager/domain/api'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import {
  LOGOUT_ACTION,
  Menu,
  MenuGroup,
  NavItem,
  NavItemLink,
  globalNavManager,
} from '@cais-group/shared/ui/main-nav'
import { User } from '@cais-group/shared/util/type/caisiq-be'

import { linkToMfe } from './link-to-mfe'

type UserProfile = UserProfileV1 & Pick<User, 'firmExperience'>

function transformLink(link: NavItemLink) {
  const caisiqPattern = /^https:[/][/]caisiq/
  const localCaisiqPattern = /^http:\/\/localhost:3000\//
  if (link.href.match(caisiqPattern) || link.href.match(localCaisiqPattern)) {
    return link
  }
  return {
    ...link,
    href: linkToMfe(link.href),
  }
}

const menuReducer = (menuGroup: MenuGroup[]) => {
  return menuGroup.reduce<MenuGroup[]>((groupAccumulator, currentGroup) => {
    if (currentGroup.items.length > 0) {
      const currentGroupsTransformedNavItems = currentGroup.items.map(
        (groupNavItem) =>
          // handle case for log out button
          groupNavItem.href === LOGOUT_ACTION
            ? groupNavItem
            : transformLink(groupNavItem)
      )
      // Add groups with transformed navItems.
      groupAccumulator.push({
        ...currentGroup,
        items: currentGroupsTransformedNavItems,
      })
    }
    return groupAccumulator
  }, [])
}

/**
 * A helper function that transforms CAIS IQ link hrefs into environment-specific variants.
 */
export function transformNavigationItems(navs: NavItem[]) {
  return navs.reduce<NavItem[]>(
    (navItemsAccumulator, currentNavItem: NavItem) => {
      if (!('menu' in currentNavItem)) {
        return [...navItemsAccumulator, transformLink(currentNavItem)]
      }

      // If we are dealing with a menu, transform items with CAIS IQ links in each group.
      return [
        ...navItemsAccumulator,
        {
          ...currentNavItem,
          menu: {
            ...currentNavItem.menu,
            groups: menuReducer(currentNavItem.menu.groups),
          },
        },
      ]
    },
    []
  )
}

/**
 * A helper reducer function transforms CAIS IQ link hrefs for the profile menu into environment-specific variants.
 */

export function transformProfileMenuItems(profileMenu: Menu) {
  return {
    ...profileMenu,
    groups: menuReducer(profileMenu.groups),
  }
}
/**
 * A helper that generates user-specific nav items and menu settings consumed by the main nav.
 * @returns nav items {NavItem[]} and profile menu {Menu} used for main navigation.
 */
export function getUserNavSettings(
  onLogout: (event?: React.MouseEvent<HTMLAnchorElement>) => void
) {
  const userProfile =
    (userSettingsService.user as unknown as UserProfile) || null

  const experience = userProfile.firmExperience

  const globalNav = globalNavManager({
    userProfile,
    options: {
      onLogout,
    },
    experience,
  })

  return {
    navItems: transformNavigationItems(globalNav.navItems),
    profileMenu: transformProfileMenuItems(globalNav.profileMenu),
    logos: globalNav.logos,
  }
}
