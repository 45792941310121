import styled from '@emotion/styled'
import { useMediaQuery, useTheme } from '@mui/material'

import { ExperienceHeader } from '@cais-group/caisiq/ui/hero/header'
import { Subnav, SubnavItem } from '@cais-group/caisiq/ui/subnav'
import {
  AutoComplete,
  OptionType,
  GroupType,
} from '@cais-group/caisiq-ui-autocomplete'
import {
  TableDefault,
  TableDefaultProps,
} from '@cais-group/caisiq-ui-table-default'
import { Button } from '@cais-group/equity/atoms/button'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { LayoutStack, LayoutBox } from '@cais-group/shared/ui/layout'
import { User } from '@cais-group/shared/util/type/caisiq-be'
import { Experience } from '@cais-group/shared/util/type/experience'
import { ExperienceData } from '@cais-group/shared/util/type/experience-data'
import { TabType } from '@cais-group/shared/util/type/homeoffice-data'

const MainContent = styled(LayoutStack)`
  background-color: rgb(var(--colors-neutral-100));
  min-height: 100vh; // Full gray background
`

const StyledLayoutBox = styled(LayoutBox)`
  padding: var(--s24) var(--s56) var(--s56) var(--s56);
  overflow: hidden;
  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) and (max-width: ${BREAKPOINTS.breakpointXl}) {
    padding: var(--s24);
  }
  @media screen and (max-width: ${BREAKPOINTS.breakpointSm}) {
    padding: 0;
  }
`

const SubNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const SubNavRight = styled.div`
  display: flex;
`

const ExportButtonContainer = styled.div`
  margin-left: var(--s16);
`

const StyledTabsNav = styled(Subnav)`
  & {
    margin-bottom: 0;
  }
  width: auto;
  height: var(--s56);
  padding-top: var(--s8);
  // Allow focus overlines to be visible on edges
  overflow: visible;

  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) and (max-width: ${BREAKPOINTS.breakpointXl}) {
    margin-left: var(--s4);
    margin-right: var(--s4);
  }
  @media screen and (max-width: ${BREAKPOINTS.breakpointSm}) {
    min-width: 24.375rem;
    margin-left: var(--s4);
    margin-right: var(--s4);
  }
`

const StyledTab = styled(SubnavItem)`
  position: relative;
  top: var(--s4);
  padding-bottom: var(--s16);
  cursor: pointer;
  border-bottom: 3px solid transparent;
  margin-right: var(--s8);
  color: rgb(var(--colors-primary-600));
  opacity: 100;
  font-weight: 600;
  &.active {
    border-bottom: 3px solid rgb(var(--colors-neutral-900));
  }
`

const StyledDivider = styled.div`
  padding-top: var(--s24);
  border-top: 1px solid rgb(var(--colors-neutral-200));
`

interface HomeOfficeUIProps {
  userProfile?: User
  experience?: Experience
  contentfulExperience: ExperienceData
  tabs: TabType
  advisorCount?: number
  tab: string
  onHandleTabChange: (tab: string) => void
  fetchCounts: (
    inputValue: string
  ) => Promise<ReadonlyArray<OptionType | GroupType>> | void
  clearSelection: () => void
  setSearchParams: (searchParams: { user?: string; team?: string }) => void
  hasCompletedRequirementData: boolean
  tableProps: TableDefaultProps
  exportCSV: {
    exporting: boolean
    callback: () => void
  }
}
export const HomeOfficeUI = ({
  userProfile,
  experience,
  contentfulExperience,
  tabs,
  advisorCount,
  tab,
  onHandleTabChange,
  fetchCounts,
  clearSelection,
  setSearchParams,
  hasCompletedRequirementData,
  tableProps,
  exportCSV,
}: HomeOfficeUIProps) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 75))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <ExperienceHeader
        userProfile={userProfile}
        currentExperience={experience}
        contentfulExperience={contentfulExperience}
      />
      <MainContent data-testid="default-table">
        <StyledLayoutBox>
          <SubNavContainer>
            <StyledTabsNav>
              {tabs.map((currentTab) => (
                <StyledTab
                  key={currentTab.tab}
                  count={
                    currentTab.tab === tabs[0].tab ? advisorCount : undefined
                  }
                  className={
                    currentTab.slug === tab || currentTab.tab === tab
                      ? 'active'
                      : ''
                  }
                  onClick={() => {
                    onHandleTabChange(currentTab.tab)
                  }}
                  data-testid={`tab-${tab}`}
                  disableRipple={false}
                >
                  {currentTab.tab}
                </StyledTab>
              ))}
            </StyledTabsNav>
            <SubNavRight>
              <AutoComplete
                placeholderText="Search by name, email or team"
                promiseOptions={(inputValue) => fetchCounts(inputValue)}
                onSelectionChange={(option, { action }) => {
                  if (action === 'clear') {
                    clearSelection()
                  } else if (action === 'select-option' && option) {
                    const queryParam =
                      option.type === 'advisor'
                        ? option.email ?? option.name
                        : option.name
                    setSearchParams({
                      [option.type === 'advisor' ? 'user' : 'team']: queryParam,
                    })
                  }
                }}
              />
              <ExportButtonContainer>
                <Button
                  loading={exportCSV.exporting}
                  disabled={exportCSV.exporting}
                  onClick={exportCSV.callback}
                >
                  Export to CSV
                </Button>
              </ExportButtonContainer>
            </SubNavRight>
          </SubNavContainer>
          <StyledDivider />
          <div
            style={
              (isMobile || isTablet) && hasCompletedRequirementData
                ? { overflow: 'scroll' }
                : { overflow: 'visible' }
            }
          >
            <TableDefault {...tableProps} />
          </div>
        </StyledLayoutBox>
      </MainContent>
    </div>
  )
}
