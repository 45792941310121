import { Maybe } from 'graphql/jsutils/Maybe'
import { camelCase } from 'lodash-es'

import {
  CuratedFilterGroup,
  TagFamily,
} from '@cais-group/shared/util/graphql/mfe-contentful'

import { FamilyType, FilterGroupType } from '../types'

export const makeFiltersFromCuratedFilterGroup = (
  filterGroups: CuratedFilterGroup[]
) => {
  const filters = filterGroups.reduce<FilterGroupType[]>((acc, filterGroup) => {
    const filterGroupLabel = filterGroup.label ?? ''
    const filterFamilies =
      filterGroup?.filtersCollection?.items.reduce<FamilyType>(
        (acc, filter: Maybe<Omit<TagFamily, 'name'>>) => {
          const filterName = filter?.tagFamilyType
            ? camelCase(filter.tagFamilyType)
            : ''
          const filterTags =
            filter?.tagsCollection?.items.map((tag) => tag?.tagId ?? '') || []
          return {
            ...acc,
            [filterName]: filterTags,
          }
        },
        {}
      ) || {}

    acc.push({ label: filterGroupLabel, filter: filterFamilies })
    return acc
  }, [])

  return filters
}
