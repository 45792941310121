import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'

import {
  BodySmall,
  H2Strong,
  H6Strong,
  shouldForwardProp,
} from '@cais-group/approved/ui/typography'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { Countdown } from '@cais-group/caisiq/ui/countdown'
import { Button } from '@cais-group/equity/atoms/button'
import { LayoutCluster } from '@cais-group/shared/ui/layout'
import { LogoCaisIqLight, LogoPoweredByCais } from '@cais-group/shared/ui/logos'
import { TypeLoginScreenData } from '@cais-group/shared/util/type/login-screen-data'

const LoginPage = styled.div<{ backgroundImageUrl?: string }>`
  position: fixed;
  ${({ backgroundImageUrl }) =>
    backgroundImageUrl ? `background-image: url(${backgroundImageUrl});` : ''}
  background-size: cover;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
`
const GreyLayout = styled.div`
  position: fixed;
  background-color: rgba(33, 28, 33, 0.3);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Date = styled(H6Strong)`
  color: rgb(var(--colors-neutral-0));
  margin-top: var(--s24);
`
const Title = styled(H2Strong)`
  color: rgb(var(--colors-neutral-0));
  margin-bottom: var(--s16);
`
const TitleContainer = styled.div`
  display: flex;
  align-items: end;
  flex-direction: row;
`
const TitleLogo = styled.img<{ position?: 'left' | 'right' }>`
  ${({ position }) =>
    position === 'left'
      ? `order: 0; margin-right: var(--s32);`
      : 'order: 1; margin-left: var(--s32);'}
`
const ExperienceLogo = styled.img<{ position?: 'left' | 'right' }>`
  width: 8.12rem;
  height: var(--s32);
  ${({ position }) => (position === 'left' ? `order: 0; ` : 'order: 2; ')}
`
const LogosWraper = styled.div`
  display: flex;
  align-items: center;
`

type LogoCaisProps = {
  $experienceLogoPosition?: 'left' | 'right'
}
const LogoCais = styled(LogoCaisIqLight, {
  shouldForwardProp: shouldForwardProp(`$experienceLogoPosition`),
})<LogoCaisProps>`
  width: var(--s232);
  height: var(--s32);
  ${({ $experienceLogoPosition }) =>
    $experienceLogoPosition === 'left' ? `order: 2; ` : 'order: 0; '}
`

const Line = styled.div`
  width: 2px;
  height: var(--s56);
  background-color: rgb(var(--colors-neutral-0));
  opacity: 0.24;
  margin: 0 var(--s24);
  order: 1;
`
const Description = styled(BodySmall)`
  max-width: 38.75rem;
  color: rgb(var(--colors-neutral-0));
  text-align: center;
  margin: var(--s32) 0;
`
const ButtonsWrap = styled(LayoutCluster)`
  margin-bottom: var(--s88);
`

export type LoginProps = {
  loginScreenData: TypeLoginScreenData
  canSignup: boolean
}
export const Login = (props: LoginProps) => {
  const { loginScreenData, canSignup } = props
  const { loginWithRedirect } = useAuth0()

  return (
    <>
      <LoginPage
        backgroundImageUrl={loginScreenData.backgroundImage.url}
      ></LoginPage>
      <GreyLayout>
        {loginScreenData.title ? (
          <>
            <TitleContainer>
              <TitleLogo
                src={loginScreenData.logo.url}
                position={loginScreenData.logoPosition}
              />
              <Title>{loginScreenData.title}</Title>
            </TitleContainer>
            <LogoPoweredByCais fill="rgb(var(--colors-neutral-0))" />
          </>
        ) : (
          <LogosWraper>
            <LogoCais $experienceLogoPosition={loginScreenData.logoPosition} />
            {loginScreenData.logo && (
              <>
                <Line />
                <ExperienceLogo
                  src={loginScreenData.logo.url}
                  position={loginScreenData.logoPosition}
                />
              </>
            )}
          </LogosWraper>
        )}
        <Description>{loginScreenData.intro}</Description>
        <ButtonsWrap>
          <Button
            color="neutral"
            inverse
            onClick={() => {
              loginWithRedirect({
                authorizationParams: {
                  redirect_uri: `${window.location.origin}${ROUTES.home}`,
                },
              })
              if (Array.isArray(window.dataLayer)) {
                window.dataLayer.push({ event: 'login' })
              }
            }}
          >
            Log in
          </Button>
          {canSignup && (
            <Button
              onClick={() => {
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: 'signup',
                    redirect_uri: `${window.location.origin}${ROUTES.home}`,
                  },
                })
                if (Array.isArray(window.dataLayer)) {
                  window.dataLayer.push({ event: 'sign_up' })
                }
              }}
            >
              Sign up
            </Button>
          )}
        </ButtonsWrap>
        <Countdown
          date={loginScreenData.countdownDatetime}
          currentDate={loginScreenData.currentDate}
        ></Countdown>
        <Date>{loginScreenData.footerText}</Date>
      </GreyLayout>
    </>
  )
}

Login.displayName = 'Login'
