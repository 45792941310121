import styled from '@emotion/styled'

import {
  Body,
  CaptionRegular,
  H5Strong,
} from '@cais-group/approved/ui/typography'
import { ChartCreditsCompleted } from '@cais-group/caisiq/ui/chart/credits-completed'
import { colors } from '@cais-group/equity/particles/colors'
import { Card } from '@cais-group/shared/ui/card/dismissable'
import {
  BREAKPOINTS,
  DATA_VISUALIZATION_COLORS,
  SPACING,
} from '@cais-group/shared/ui/design-tokens'
import { CeCredits } from '@cais-group/shared/util/type/course'

const CreditsEarnedFootnote = styled(CaptionRegular)`
  color: ${colors['eq-color-neutral-500']};
`

const ChartWithFootnote = styled('div')`
  grid-column: auto/span 12;
  max-width: ${SPACING.s608};
  padding: ${SPACING.s32};
  & > figure {
    margin-left: 0;

    /* not mobile*/
    @media screen and (min-width: ${BREAKPOINTS.breakpointLg}) {
      padding: ${SPACING.s32};
    }
  }

  /* not mobile*/
  @media screen and (min-width: ${BREAKPOINTS.breakpointLg}) {
    grid-column: auto/span 6;
    place-self: flex-end;
  }
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    margin-right: ${SPACING.s56};
    padding-right: 0;
  }
`

const ChartsContainer = styled('figure')`
  display: flex;
  flex: 50% 1 1;
  flex-direction: column;
  justify-content: center;
  place-self: center;
  width: 100%;
  max-width: calc(100vw - ${SPACING.s32});
  margin-bottom: ${SPACING.s16};
  gap: ${SPACING.s32};
  & > svg {
    flex: 50% 1 1;
    margin-left: 0;
    margin-top: ${SPACING.s24};
    margin-bottom: ${SPACING.s24};
    max-width: 235px;
    align-self: center;
  }
  & > svg:first-of-type {
    margin-bottom: 0;
  }
  & > svg:nth-of-type(2) {
    margin-top: 0;
  }
  & > svg:last-of-type {
    margin-bottom: ${SPACING.s24};
  }

  /* ipad*/
  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) and (max-width: ${BREAKPOINTS.breakpointLg}) {
    place-self: flex-start;
    flex-direction: row;
    max-width: calc(100vw - ${SPACING.s32} * 2);

    margin-left: ${SPACING.s32};
    & > svg:first-of-type {
      margin-bottom: ${SPACING.s24};
    }

    & > svg:nth-of-type(2) {
      margin-top: ${SPACING.s24};
    }
  }

  /* not mobile*/
  @media screen and (min-width: ${BREAKPOINTS.breakpointLg}) {
    flex-direction: row;
    margin-left: ${SPACING.s32};
    max-width: 624px;

    place-self: end;
    margin-left: 0;
    margin-right: ${SPACING.s56};
    & > svg:first-of-type {
      margin-bottom: ${SPACING.s24};
    }

    & > svg:nth-of-type(2) {
      margin-top: ${SPACING.s24};
    }
  }
`

const WarningPanel = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  max-width: ${SPACING.s608};
  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) and (max-width: ${BREAKPOINTS.breakpointLg}) {
    place-self: flex-start;
  }

  @media screen and (min-width: ${BREAKPOINTS.breakpointLg}) {
    place-self: end;
    margin-bottom: 0;
    margin-right: ${SPACING.s56};
  }
`

const PanelContent = styled(Body)`
  color: ${colors['eq-color-neutral-600']};
  margin-top: ${SPACING.s16};
`

interface CreditsProgressChartsProps {
  availableCredits: CeCredits
  completedCredits: CeCredits
  hasSavedLicences: boolean
}

/**
 * @todo: hasSavedLicences, we should pass both licences individually and
 * use these to determine what can be displayed.
 */
export const CreditsProgressCharts: React.FC<CreditsProgressChartsProps> = ({
  hasSavedLicences,
  availableCredits,
  completedCredits,
  ...rest
}) => {
  const noCreditsEarned =
    completedCredits.cfpCeCredit + completedCredits.cimaCeCredit === 0

  if (noCreditsEarned) {
    return (
      <WarningPanel {...rest}>
        <H5Strong>You have not completed any CE credits</H5Strong>
        <PanelContent>
          Enter one or more credential numbers to get started
        </PanelContent>
      </WarningPanel>
    )
  }

  if (!hasSavedLicences) {
    return (
      <WarningPanel {...rest}>
        <H5Strong>You have unclaimed credits</H5Strong>
        <PanelContent>
          Enter one or more credential numbers to claim your credits. You can
          only claim credits that for courses that were completed within the
          past two weeks for CFP or past month for CIMA.
        </PanelContent>
      </WarningPanel>
    )
  }

  return (
    <ChartWithFootnote>
      <ChartsContainer {...rest}>
        {availableCredits.cfpCeCredit > 0 && (
          <ChartCreditsCompleted
            type="CFP"
            completed={completedCredits.cfpCeCredit}
            total={availableCredits.cfpCeCredit}
            color={DATA_VISUALIZATION_COLORS.Marigold}
          />
        )}
        {availableCredits.cimaCeCredit > 0 && (
          <ChartCreditsCompleted
            type="CIMA"
            completed={completedCredits.cimaCeCredit}
            total={availableCredits.cimaCeCredit}
            color={DATA_VISUALIZATION_COLORS.Ube}
          />
        )}
      </ChartsContainer>
      {hasSavedLicences ? (
        <CreditsEarnedFootnote>
          *represents credits completed in CAIS IQ
        </CreditsEarnedFootnote>
      ) : null}
    </ChartWithFootnote>
  )
}
