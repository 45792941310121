import { InputMaybe } from '@cais-group/shared/util/graphql'

import { components } from './generated'
import { PageRequest } from './pagination'

export type CreateExperienceRequest = components['schemas']['ExperienceRequest']
export type LearnerExperienceSwitchRequest =
  components['schemas']['LearnerExperienceSwitchRequest']

type GeneratedExperience = components['schemas']['ExperienceResponse']

export type ExperienceResponse = GeneratedExperience

export type CreateUpdateExperienceRequest =
  components['schemas']['ExperienceRequest']

export type FirmBasicInfoDto = components['schemas']['FirmBasicInfoDto']

export type GeneratedSearchExperienceFilter = NonNullable<
  components['schemas']['SearchDtoExperienceFilter']
>

export type SearchRequestExperienceFilterFirmSort = {
  filter?: InputMaybe<GeneratedSearchExperienceFilter['filter']>
  pageRequest?: PageRequest
  sort?: InputMaybe<GeneratedSearchExperienceFilter['sort']>
}

export type PageResultExperienceResponse =
  components['schemas']['PageResultExperienceResponse']

export type ExperienceContentful = components['schemas']['ExperienceContentful']
