import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import {
  SideNavigation,
  SideNavigationSection,
} from '@cais-group/shared/ui/side-navigation'

type PageSection = {
  sectionName: SideNavigationSection['sectionName']
  sectionId: SideNavigationSection['sectionId']
}

export const PAGE_SECTIONS: PageSection[] = [
  {
    sectionName: 'Experience Name',
    sectionId: 'experience-name',
  },
  {
    sectionName: 'Capabilities',
    sectionId: 'capabilities',
  },
  {
    sectionName: 'Course Configuration',
    sectionId: 'course-config',
  },
  {
    sectionName: 'Experience Type',
    sectionId: 'experience-type',
  },
  {
    sectionName: 'Firms',
    sectionId: 'firms',
  },
]

export const ExperiencePageSideNavigation = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { getValues, watch } = useFormContext<ExperienceFields>()
  const isDirect = getValues('experienceType') === 'DIRECT'
  const isCoursesEnabled = watch('catalogId') && watch('coursesEnabled')
  // Design would like to not render Firms as a navSection/button if experience-type === DIRECT instead of rendering a disabled button/section
  const filteredPageSections =
    isDirect || !isCoursesEnabled
      ? PAGE_SECTIONS.filter((section) => {
          if (section.sectionId === 'firms' && isDirect) {
            return false
          } else if (
            section.sectionId === 'course-config' &&
            !isCoursesEnabled
          ) {
            return false
          } else {
            return true
          }
        })
      : PAGE_SECTIONS

  // If the user happens to have firms selected when they disable firms, below will change the selected index to the previous index
  useEffect(() => {
    if (isDirect && PAGE_SECTIONS[selectedIndex].sectionId === 'firms') {
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1)
      }
    }
    if (
      !isCoursesEnabled &&
      PAGE_SECTIONS[selectedIndex].sectionId === 'course-config'
    ) {
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1)
      }
    }
  }, [isCoursesEnabled, isDirect, selectedIndex])

  return (
    <SideNavigation
      sections={filteredPageSections.map(({ sectionId, ...rest }, idx) => ({
        ...rest,
        selected: selectedIndex === idx,
        sectionId,
      }))}
      onChange={setSelectedIndex}
    />
  )
}
