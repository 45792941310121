import { useFeature } from 'flagged'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGetHomeOfficeReport } from '@cais-group/caisiq/domain/homeoffice/hooks/use-get-home-office-report'
import {
  useGetHomeOfficeUserState,
  useGetAdvisorCounts,
  HomeOfficeQueryParams,
} from '@cais-group/caisiq/domain/homeoffice/hooks/use-get-home-office-user-state'
import { useGetUserProfile } from '@cais-group/caisiq/domain/user'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { useFormatCompleteRequirementsTableData } from '@cais-group/caisiq/util/hook/use-format-complete-requirements-table-data'
import { useFormatTableData } from '@cais-group/caisiq/util/hook/use-format-table-data'
import { QueryParams } from '@cais-group/caisiq/util/type/common'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import {
  ColumnCommon,
  FormattedHomeOfficeRequirementMetData,
  HomeOfficeCourseStatusCommon,
  HomeOfficeRequirementMet,
  HomeOfficeStatusType,
  SlugEnum,
  StatusesType,
  TabEnum,
  TabType,
} from '@cais-group/shared/util/type/homeoffice-data'
import {
  SortOrder,
  useWithinGroupSortConfig,
  WithinGroupSortConfig,
} from '@cais-group/shared/util/use-within-group-sort-config'

import { HomeOfficeUI } from './caisiq-feature-home-office-ui'

const defaultTabs = {
  tab: TabEnum.ALL_LEARNERS,
  slug: SlugEnum.ALL_LEARNERS,
}

const DEFAULT_PAGE_SIZE = 20

const initialParams: QueryParams = {
  page: 1,
  sort: 'lastCourseCompletionDate',
  limit: DEFAULT_PAGE_SIZE,
}

const getSortParam = (sortOrder: SortOrder, sortId: string): string =>
  sortOrder === 'asc' ? sortId : `-${sortId}`

export const HomeOffice = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const shouldTableUpdateRef = useRef<boolean>(false)
  const userProfile = useGetUserProfile()

  const areTabFeaturesEnabled = useFeature('HOMEOFFICE_TABS')
  const activeTab = decodeURI(location.hash.replace(/#|_/g, ''))

  const [tabs, setTabs] = useState<TabType>([defaultTabs])

  // TODO: put homeoffice tabs in contentful
  const [tab, setTab] = useState<string>(
    activeTab ? activeTab : defaultTabs.slug
  )

  const [advisorCount, setAdvisorCount] = useState<number | undefined>(0)

  /**
   * @todo when we bring back other tab, we need to add that as an optional type
   */
  const [tableData, setTableData] = useState<
    Array<HomeOfficeStatusType | FormattedHomeOfficeRequirementMetData>
  >([])

  const [tableColumns, setTableColumns] =
    useState<ColumnCommon<Record<string, unknown>>[]>()
  const [completedRequirementData, setCompletedRequirementData] = useState<
    HomeOfficeRequirementMet[]
  >([])

  const [queryParams, setQueryParams] =
    useState<HomeOfficeQueryParams>(initialParams)

  const exportCSV = useGetHomeOfficeReport()

  const {
    isLoading,
    data,
    pagination,
    isPreviousData,
    setTableLoadingState,
    skeletonLoadingState,
  } = useGetHomeOfficeUserState({
    queryParams,
  })

  const { requirementData, requirementColumns } =
    useFormatCompleteRequirementsTableData({
      data: completedRequirementData ?? [],
    })

  const { learnersData, learnersColumns } = useFormatTableData({
    data: data ?? [],
    isLoading,
    skeletonLoadingState,
    isPreviousData,
  })

  const { setSortConfig, sortConfigs } = useWithinGroupSortConfig(
    'home-office',
    [
      {
        sortId: 'lastCourseCompletionDate',
        sortOrder: 'asc',
      },
      {
        sortId: 'homeOffice',
        sortOrder: 'asc',
      },
    ]
  )

  const sortConfigApplied: WithinGroupSortConfig = sortConfigs[0]

  const onHandleTabChange = (
    // TODO: put homeoffice tabs in contentful
    clickedTab: string
  ) => {
    // TODO: Backend team is creating a new endpoint to fetch this data
    const coursesWithRequirementMet: HomeOfficeRequirementMet[] = []
    if (clickedTab === tabs[1].tab) {
      data?.forEach((user: StatusesType) => {
        user.status.courses.forEach((course: HomeOfficeCourseStatusCommon) => {
          if (course.learned === true) {
            coursesWithRequirementMet.push({
              name: user.status.name,
              email: user.status.email,
              teams: user.teams,
              course: course.name,
              learned: course.learned,
              date: course.learnedAt,
            })
          }
        })
      })
    }
    setCompletedRequirementData(coursesWithRequirementMet)
    setTab(clickedTab)
    setQueryParams((params) =>
      params.page !== 1 ? { ...params, page: 1 } : params
    )
    clickedTab = clickedTab.replace(/\s/g, '-').toLocaleLowerCase()

    navigate(`#${clickedTab}`)
  }

  const onSetPage = (page: number) => {
    if (!skeletonLoadingState) {
      setTableLoadingState(true)
    }
    if (!isPreviousData) {
      // TODO: Will this logic need to change when we have multiple tabs?
      setTableData([])
      setQueryParams((params) =>
        params.page !== page
          ? {
              ...params,
              page,
              sort: getSortParam(
                sortConfigApplied.sortOrder,
                sortConfigApplied.sortId
              ),
            }
          : params
      )
    }
  }

  // Likely temporary. UseEffect ensures pill and completed requirements tab do not render in production until we finalize endpoint
  useEffect(() => {
    if (areTabFeaturesEnabled) {
      setTabs([
        {
          tab: TabEnum.ALL_LEARNERS,
          slug: SlugEnum.ALL_LEARNERS,
        },
        {
          tab: TabEnum.COMPLETED_REQUIREMENTS,
          slug: SlugEnum.COMPLETED_REQUIREMENTS,
        },
      ])
    } else {
      setTabs([
        {
          tab: TabEnum.ALL_LEARNERS,
          slug: SlugEnum.ALL_LEARNERS,
        },
      ])
    }

    setAdvisorCount(pagination?.totalRecords)
  }, [areTabFeaturesEnabled, learnersData.length, pagination])

  useEffect(() => {
    shouldTableUpdateRef.current = true

    if (
      completedRequirementData &&
      tabs[1] &&
      (tab === tabs[1].tab || (tabs[1].slug && tab === tabs[1].slug))
    ) {
      /**
       * @todo get rid of this casting when we bring in completedRequirements tab / feature
       */
      setTableData(requirementData)
      setTableColumns(
        requirementColumns as unknown as ColumnCommon<Record<string, unknown>>[]
      )
    } else if (data) {
      setTableData(learnersData)
      setTableColumns(
        learnersColumns as unknown as ColumnCommon<Record<string, unknown>>[]
      )
    }

    shouldTableUpdateRef.current = false

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedRequirementData, data, tab, learnersColumns])

  const fetchCounts = useGetAdvisorCounts()

  const clearSelection = () => {
    setTableLoadingState(true)
    setQueryParams(initialParams)
  }

  const setSearchParams = (
    searchParams: Pick<HomeOfficeQueryParams, 'user' | 'team'>
  ) => {
    setTableLoadingState(true)
    setQueryParams((params) => ({ ...params, page: 1, ...searchParams }))
  }

  useEffect(() => {
    setQueryParams((params) => ({
      ...params,
      page: 1,
      sort: getSortParam(sortConfigApplied.sortOrder, sortConfigApplied.sortId),
    }))
  }, [sortConfigApplied])

  return (
    <HomeOfficeUI
      userProfile={userProfile?.data}
      experience={experienceService.getExperience()}
      contentfulExperience={userSettingsService.theme}
      tabs={tabs}
      advisorCount={advisorCount}
      tab={tab}
      onHandleTabChange={(tab: string) =>
        areTabFeaturesEnabled && onHandleTabChange(tab)
      }
      fetchCounts={fetchCounts}
      clearSelection={clearSelection}
      setSearchParams={setSearchParams}
      hasCompletedRequirementData={
        tab === 'Completed requirements' && completedRequirementData.length > 0
      }
      tableProps={{
        data: tableData.length !== 0 ? tableData : learnersData,
        columns:
          (tableColumns as ColumnCommon<Record<string, unknown>>[]) ??
          learnersColumns,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        pageIndex: queryParams.page,
        pageLength: queryParams.limit,
        totalPages: pagination?.totalPages ?? 0,
        shouldTableUpdateRef,
        isLoading: isLoading || skeletonLoadingState,
        tab,
        onSetPage,
        setSortConfig: setSortConfig,
        sortConfigApplied: sortConfigApplied,
      }}
      exportCSV={{
        ...exportCSV,
        callback: () =>
          // Once the Remeda lib is in the main branch we can use the `pick` function which is less verbose
          exportCSV.callback({
            user: queryParams.user,
            team: queryParams.team,
            sort: queryParams.sort,
          }),
      }}
    />
  )
}
