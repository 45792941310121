import { Theme } from './types'

export const colors: Theme = {
  brand: {
    '100': '#e6ebee',
    '100-c': '#211c21',
    '200': '#cdd6de',
    '200-c': '#211c21',
    '300': '#9baebd',
    '300-c': '#211c21',
    '400': '#51718b',
    '400-c': '#ffffff',
    '500': '#1f486a',
    '500-c': '#ffffff',
    '600': '#063459',
    '600-c': '#ffffff',
    '700': '#052A47',
    '700-c': '#ffffff',
    '800': '#041F35',
    '800-c': '#ffffff',
    '900': '#031a2d',
    '900-c': '#ffffff',
  },
  primary: {
    '100': '#E6EBEE',
    '100-c': '#211c21',
    '200': '#CDD6DE',
    '200-c': '#211c21',
    '300': '#9BAEBD',
    '300-c': '#211c21',
    '400': '#51718B',
    '400-c': '#ffffff',
    '500': '#1F486A',
    '500-c': '#ffffff',
    '600': '#063459',
    '600-c': '#ffffff',
    '700': '#052A47',
    '700-c': '#ffffff',
    '800': '#041F35',
    '800-c': '#ffffff',
    '900': '#031A2D',
    '900-c': '#ffffff',
  },
  datavis: {
    '1': '#3958a1',
    '1-c': '#ffffff',
    '2': '#c0e0db',
    '2-c': '#211c21',
    '3': '#f9d974',
    '3-c': '#211c21',
    '4': '#00407b',
    '4-c': '#ffffff',
    '5': '#8cade9',
    '5-c': '#211c21',
    '6': '#327174',
    '6-c': '#ffffff',
    '7': '#96dbc8',
    '7-c': '#211c21',
    '8': '#ffc170',
    '8-c': '#211c21',
    '9': '#bb530a',
    '9-c': '#ffffff',
    '10': '#65162c',
    '10-c': '#ffffff',
    '11': '#c0bbf7',
    '11-c': '#211c21',
    '12': '#c47774',
    '12-c': '#211c21',
    '13': '#595c5c',
    '13-c': '#ffffff',
    '14': '#2a92ba',
    '14-c': '#211c21',
    '15': '#86d3f2',
    '15-c': '#211c21',
    '16': '#6c8f70',
    '16-c': '#211c21',
    '17': '#d1e0a5',
    '17-c': '#211c21',
    '18': '#f3d7bc',
    '18-c': '#211c21',
    '19': '#a8864d',
    '19-c': '#211c21',
    '20': '#925364',
    '20-c': '#ffffff',
    '21': '#917da3',
    '21-c': '#211c21',
    '22': '#cdd1d8',
    '22-c': '#211c21',
  },
  gradient: {
    '1-1': '#9baebd',
    '1-1-c': '#211c21',
    '1-2': '#e6ebee',
    '1-2-c': '#211c21',
    '2-1': '#063459',
    '2-1-c': '#ffffff',
    '2-2': '#9baebd',
    '2-2-c': '#ffffff',
    '3-1': '#031a2d',
    '3-1-c': '#ffffff',
    '3-2': '#063459',
    '3-2-c': '#ffffff',
    '4-1': '#CC910D',
    '4-1-c': '#ffffff',
    '4-2': '#F5E9CF',
    '4-2-c': '#ffffff',
  },
} as const
