import styled from '@emotion/styled'

import { Button } from '@cais-group/equity/atoms/button'
import { SPACING } from '@cais-group/shared/ui/design-tokens'

import { useStepState } from './step-state-provider'

interface StepNavigationProps {
  order: number
  align?: 'left' | 'right'
  previousButtonType?: 'primary' | 'secondary'

  onBeforeNext?(): Promise<boolean>
  onAfterNext?(): void
  onAfterPrevious?(): void
}

const StyledNav = styled.nav<{ align: 'left' | 'right' }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ align }) => (align === 'left' ? 'row' : 'row-reverse')};
  margin-top: ${SPACING.s24};
`

const StyledButtonList = styled.ol<{ align: 'left' | 'right' }>`
  display: flex;

  & > li {
    ${({ align }) =>
      align === 'left'
        ? `margin-right: ${SPACING.s12};`
        : `margin-left: ${SPACING.s12};`}
  }
`

export const StepNavigation = ({
  order,
  onBeforeNext = () => Promise.resolve(true),
  onAfterNext,
  onAfterPrevious,
  align = 'right',
  previousButtonType = 'primary',
}: StepNavigationProps) => {
  const { step, steps, setStep } = useStepState()

  if (!step || step !== order) {
    return null
  }
  return (
    <StyledNav align={align}>
      <StyledButtonList align={align}>
        {step > 1 && (
          <li>
            <Button
              variant={
                previousButtonType === 'secondary' ? 'secondary' : 'primary'
              }
              onClick={() => {
                setStep(step - 1)
                onAfterPrevious?.()
              }}
            >
              Previous
            </Button>
          </li>
        )}
        {step < steps && (
          <li>
            <Button
              onClick={() => {
                onBeforeNext().then((valid) => {
                  if (valid) {
                    setStep(step + 1)
                  }
                  onAfterNext?.()
                })
              }}
            >
              Next
            </Button>
          </li>
        )}
      </StyledButtonList>
    </StyledNav>
  )
}
