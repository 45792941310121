import styled from '@emotion/styled'
import { InputAdornment } from '@mui/material'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import { FormEvent, forwardRef, ReactElement, useState } from 'react'

import {
  BodySmallCssObject,
  H6StrongCss,
} from '@cais-group/approved/ui/typography'
import { Icon } from '@cais-group/equity/atoms/icon'
import { TextInputLabel } from '@cais-group/shared/ui/input/label'

export interface TextInputProps
  extends Omit<OutlinedTextFieldProps, 'variant'> {
  description?: string
  maxLength?: number
  showMaxLength?: boolean
  bold?: boolean
  endAdornment?: ReactElement
  displayErrorIconOnError?: boolean
  tooltip?: React.ReactNode
}

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'bold',
})<TextInputProps>`
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: var(--s8) var(--s4);
  }
  .MuiOutlinedInput-input {
    &.Mui-disabled {
      background-color: ${({ theme }) => {
        // @ts-ignore
        return theme.palette.neutral.lighter
      }};
    }

    /* To hide number arrows in input field */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
  .MuiFormHelperText-root {
    margin-left: 0;
    ${BodySmallCssObject};
    color: ${({ error, theme }) => {
      // @ts-ignore
      return error ? theme.palette.error.main : theme.palette.text.primary
    }};
  }
  input {
    ${({ bold }) => {
      return bold ? H6StrongCss : ''
    }};
  }
`

export const TextInput = forwardRef(
  (
    {
      id,
      label,
      error,
      maxLength,
      required,
      className,
      helperText,
      endAdornment,
      onInput,
      onBlur,
      description,
      displayErrorIconOnError = true,
      showMaxLength = true,
      tooltip,
      ...props
    }: TextInputProps,
    ref
  ) => {
    const [characterCount, setCharacterCount] = useState(0)

    const handleInput = (e: FormEvent<HTMLInputElement>) => {
      setCharacterCount((e.target as HTMLInputElement).value.length)
      onInput && onInput(e)
    }

    return (
      <StyledInput className={className}>
        {label && (
          <div className="flex items-center">
            <TextInputLabel
              required={required}
              htmlFor={id}
              label={label}
              description={description}
            />

            {tooltip && tooltip}
          </div>
        )}
        <StyledTextField
          id={id}
          variant="outlined"
          onInput={handleInput}
          onBlur={onBlur}
          error={error}
          helperText={
            helperText ||
            (maxLength && showMaxLength && `${characterCount} / ${maxLength}`)
          }
          inputRef={ref}
          InputProps={{
            endAdornment:
              (error && displayErrorIconOnError && (
                <InputAdornment position="end">
                  {endAdornment}
                  <Icon
                    type="Error"
                    color="eq-color-error-600"
                    aria-label="Error icon"
                    data-testid={`${id}-error-icon`}
                  />
                </InputAdornment>
              )) ||
              (endAdornment && (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              )),
          }}
          {...props}
          inputProps={{ maxLength, ...(props.inputProps ?? {}) }}
        />
      </StyledInput>
    )
  }
)

export default TextInput
