import { useAuth0 } from '@auth0/auth0-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import {
  ApiPaths,
  CoursesCredits,
} from '@cais-group/shared/util/type/caisiq-be'

export function useGetUserCeCredits(
  options?: Omit<
    UseQueryOptions<
      AxiosResponse<CoursesCredits>,
      AxiosError,
      CoursesCredits,
      ApiPaths.coursesCredits
    >,
    'queryKey'
  >
) {
  const { isAuthenticated } = useAuth0()

  const { data, isFetched } = useQuery(ApiPaths.coursesCredits, {
    enabled: isAuthenticated,
    ...options,
  })

  return {
    isFetched,
    userCredits: data,
  }
}
