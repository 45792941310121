import { type IconType } from '@cais-group/equity/particles/icons'

/**
 * The concept of features will be removed once portal in general is replaced.
 */

export type Feature =
  | 'PORTAL_EDUCATION_CAISIQ'
  | 'PORTAL_EDUCATION_INTELLIGENCE_AND_INSIGHTS'
  | 'PORTAL_DASHBOARD'
  | 'PORTAL_EDUCATION_ALTERNATIVE_INVESTMENTS'
  | 'PORTAL_EDUCATION_STRUCTURED_SOLUTIONS'
  | 'PORTAL_EDUCATION_INVESTMENT_RESEARCH'
  | 'PORTAL_EDUCATION_CAIS_PLATFORM'
  | 'PORTAL_PIPELINE_ALTERNATIVES_ACTIVE_USER'
  | 'PORTAL_PIPELINE_ALTERNATIVES_ACTIVE_ADMIN'
  | 'PORTAL_REPORTS'
  | 'PORTAL_CAIS_USERS'
  | 'PORTAL_MANAGE_CAIS'
  | 'PORTAL_SWITCH_USER'

export type NavItem = NavItemMenu | NavItemLink

export type AccessControls = {
  capabilities?: string[]
  features?: Feature[]
  permissions?: string[]
  personas?: string[]
}

export type EnabledAccessControls = {
  [K in keyof AccessControls as `enabled${Capitalize<K>}`]: AccessControls[K]
}

export type NavItemMenu = UrlType & {
  name: string
  menu: Menu
} & AccessControls

export type NavItemLink = UrlType & {
  name: string
  href: string
  icon?: IconType
  testId?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
} & AccessControls

export type Menu = {
  id: string
  groups: MenuGroup[]
}

export type MenuGroup = {
  name?: string
  items: NavItemLink[]
}

export type UrlType = {
  urlType?: 'absolute' | 'relative'
}
