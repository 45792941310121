import { FieldErrors, Path, FieldValues } from 'react-hook-form'
import { stringToPath, pathOr } from 'remeda'

import { HelperMessageProps } from '@cais-group/equity/atoms/helper-message'

export function getErrorMessage<T extends FieldValues>(
  errors: FieldErrors<T>,
  key: Path<T>
): HelperMessageProps | undefined {
  // @ts-ignore both `pathOr` and `stringToPath` have wrong type definitions so ignoring and instead using a guard function
  const error: unknown = pathOr(errors, stringToPath(key), undefined)
  return isError(error) ? { text: error.message, type: 'error' } : undefined
}

function isError(obj: unknown): obj is { message: string } {
  return typeof obj === 'object' && obj !== null && 'message' in obj
}
