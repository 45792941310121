import { linkToCaisIq } from './link-to-cais-iq'
import * as menus from './menus'
import { NavItem } from './types'

export const navigation: NavItem[] = [
  {
    name: 'Offerings',
    menu: menus.OfferingsMenu,
  },
  {
    name: 'Orders',
    menu: menus.OrdersMenu,
  },
  {
    name: 'Holdings',
    menu: menus.HoldingsMenu,
  },
  {
    name: 'Accounts',
    href: '/investor-profile',
    permissions: ['ips:profiles:read'],
  },
  {
    name: 'Analytics',
    menu: menus.AnalyticsMenu,
  },
  {
    name: 'CAIS IQ',
    href: linkToCaisIq('/authenticated/courses').toString(),
    features: ['PORTAL_EDUCATION_CAISIQ'],
    urlType: 'absolute',
  },
  {
    name: 'Insights',
    menu: menus.InsightsMenu,
  },
  {
    name: 'Help',
    menu: menus.HelpMenu,
  },
  {
    name: 'Manage CAIS',
    menu: menus.ManageCAISMenu,
  },
]
