import { useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import {
  useGetCourses,
  useShowCeInterstitialsPage,
  useCourseModal,
} from '@cais-group/caisiq/domain/course'
import { useUserHasLicences } from '@cais-group/caisiq/domain/user'
import { ROUTES } from '@cais-group/caisiq/feature/routes'

export const AssignmentExitPage = () => {
  const { assignmentId } = useParams<{ assignmentId: string }>()

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { closeModal } = useCourseModal()

  const courseApiState = useGetCourses()

  const { showCeInterstitials } = useShowCeInterstitialsPage(
    courseApiState.isFetched
  )
  const { hasSavedLicences } = useUserHasLicences()

  const redirectToCourses = useCallback(() => {
    closeModal()
    navigate(ROUTES.courses)
  }, [closeModal, navigate])

  const redirectToCeCreditsPage = useCallback(() => {
    closeModal()
    navigate(ROUTES.ceCourseCredits(assignmentId))
  }, [closeModal, navigate, assignmentId])

  const redirectToCeCreditsSuccessPage = useCallback(() => {
    closeModal()
    navigate(ROUTES.ceCourseCreditsSuccess(assignmentId))
  }, [closeModal, navigate, assignmentId])

  const finishedFetching = queryClient.isFetching() === 0

  const assignmentCourse = courseApiState.data?.find(
    (course) => course?.id === assignmentId
  )

  useEffect(() => {
    if (courseApiState.data === undefined || !finishedFetching) {
      return
    }

    const showInterstitials = assignmentCourse
      ? showCeInterstitials(assignmentCourse)
      : undefined

    if (showInterstitials === undefined) {
      return
    }

    if (showInterstitials === true) {
      if (!hasSavedLicences) {
        redirectToCeCreditsPage()
      } else {
        redirectToCeCreditsSuccessPage()
      }
    } else {
      redirectToCourses()
    }
  }, [
    hasSavedLicences,
    assignmentId,
    courseApiState,
    assignmentCourse,
    finishedFetching,
    redirectToCourses,
    redirectToCeCreditsPage,
    redirectToCeCreditsSuccessPage,
    showCeInterstitials,
  ])

  return <LoadingContainer state={LoadingState.LOADING} />
}
