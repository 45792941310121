import type { Options, RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import {
  Overrides,
  assetsAndEntriesById,
  baseBlockComponents,
  embeddedAsset,
  mergeOptions,
} from '@cais-group/shared/ui/contentful/rich-text-base'
import { BodyLinks } from '@cais-group/shared/util/type/body'

import { embeddedEntry } from './components/embedded-content-components'
import { embeddedEntryLink, inlineLink } from './components/link-components'

export type RichTextRenderProps = {
  links: BodyLinks
  segmentPageId?: string
  renderNode?:
    | RenderNode
    | ((links?: BodyLinks | undefined) => RenderNode)
    | undefined
  overrides?: Overrides
}
function richTextRenderOptions(props: RichTextRenderProps): Options {
  const { links, segmentPageId } = props
  const { assetMap, entryMap } = assetsAndEntriesById(links)

  const renderOptions = {
    renderNode:
      typeof props.renderNode === 'function'
        ? props.renderNode(links)
        : props.renderNode,
  }

  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => embeddedAsset(node, assetMap),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => embeddedEntry(node, entryMap),
      [INLINES.HYPERLINK]: (node, children) =>
        inlineLink(node, children, segmentPageId),
      [INLINES.ENTRY_HYPERLINK]: (node, children) =>
        embeddedEntryLink(node, children, entryMap),
      [INLINES.EMBEDDED_ENTRY]: (node) => embeddedEntry(node, entryMap),
      ...mergeOptions({
        renderNode: { ...baseBlockComponents },
        overrides: props.overrides,
      }),
      ...renderOptions.renderNode,
    },
  }
}

export { richTextRenderOptions }
