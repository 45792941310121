export type AlphabeticalOrNumericOrDate = 'alphabetical' | 'numeric' | 'date'

export type WithinGroupSortKind<ItemType> =
  | {
      kind: 'numeric'
      keyExtractor: (item: ItemType) => number | null | undefined
    }
  | {
      kind: 'alphabetical'
      keyExtractor: (item: ItemType) => string | null | undefined
    }

export type WithinGroupServerSortKind = {
  kind: AlphabeticalOrNumericOrDate
  asc: string
  desc: string
}

export type AuxillarySortKind = {
  kind: AlphabeticalOrNumericOrDate
}

// The below sorts are a bit verbose, but JS is weird, want nulls etc always at end and typically the consumer doesn't have to worry about the details of the sort/should be generic enough

function numberSortAsc(
  a: number | null | undefined,
  b: number | null | undefined
) {
  if ((a === null || a === undefined) && (b === null || b === undefined))
    return 0

  // null stuff should always go at end of list:
  if (a === null || a === undefined) return 1
  if (b === null || b === undefined) return -1

  return a - b
}

function stringSortAsc(
  a: string | null | undefined,
  b: string | null | undefined
) {
  if ((a === null || a === undefined) && (b === null || b === undefined))
    return 0

  // null stuff should always go at end of list:
  if (a === null || a === undefined) return 1
  if (b === null || b === undefined) return -1

  return a.localeCompare(b)
}

function numberSortDesc(
  a: number | null | undefined,
  b: number | null | undefined
) {
  if ((a === null || a === undefined) && (b === null || b === undefined))
    return 0

  // null stuff should always go at end of list:
  if (a === null || a === undefined) return 1
  if (b === null || b === undefined) return -1

  return b - a
}

function stringSortDesc(
  a: string | null | undefined,
  b: string | null | undefined
) {
  if ((a === null || a === undefined) && (b === null || b === undefined))
    return 0

  // null stuff should always go at end of list:
  if (a === null || a === undefined) return 1
  if (b === null || b === undefined) return -1

  return b.localeCompare(a)
}

export function withinGroupSort<ItemType>(
  kind: WithinGroupSortKind<ItemType>,
  order: 'asc' | 'desc'
): (a: ItemType, b: ItemType) => number {
  const { kind: sortKind, keyExtractor } = kind
  switch (sortKind) {
    case 'alphabetical':
      return (a: ItemType, b: ItemType) => {
        const aKey = keyExtractor(a) as string
        const bKey = keyExtractor(b) as string
        return order === 'asc'
          ? stringSortAsc(aKey, bKey)
          : stringSortDesc(aKey, bKey)
      }
    case 'numeric':
      return (a: ItemType, b: ItemType) => {
        const aKey = keyExtractor(a) as number
        const bKey = keyExtractor(b) as number
        return order === 'asc'
          ? numberSortAsc(aKey, bKey)
          : numberSortDesc(aKey, bKey)
      }
    default:
      throw new Error(`Invalid sort kind: ${sortKind}`)
  }
}
