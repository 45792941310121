import styled from '@emotion/styled'

import { H5Strong, H5 } from '@cais-group/approved/ui/typography'
import { Button } from '@cais-group/equity/atoms/button'
import { LayoutStack, LayoutCenter } from '@cais-group/shared/ui/layout'

export type NoResultsProps = {
  searchTerm: string
  searchSetName: string
  onClick: () => void
}

const Container = styled.div`
  margin-top: var(--s32);
`
const StackWrap = styled(LayoutStack)`
  align-items: center;
`

export const NoResults = (props: NoResultsProps) => {
  const { searchTerm, searchSetName, onClick } = props
  return (
    <Container>
      <LayoutCenter centerText>
        <StackWrap space="var(--s24)">
          <H5>
            There are no {searchSetName} matching{' '}
            <H5Strong as="span">{searchTerm}</H5Strong>
          </H5>
          <LayoutCenter>
            <Button
              variant="secondary"
              data-testid="clear-search"
              onClick={onClick}
            >
              Clear Search
            </Button>
          </LayoutCenter>
        </StackWrap>
      </LayoutCenter>
    </Container>
  )
}
