// Note while it would be nice to shared core with useModal the investigation in SP-4969-related-modal-poc suggested this didn't quite work (sacrificed some capabilities on the non-controlled version)

import { useEffect, useRef } from 'react'

import { ModalControlProps } from './useModal'

/**
 * Custom hook for controlling a modal dialog but where state is managed externally
 * @returns An object that contains the state of the modal (isOpen), a ref to the modal (modalRef),
 * and two functions for showing and hiding the modal (showModal and onClose).
 */
export function useModalDerived(
  isOpen: boolean,
  close: () => void
): ModalControlProps {
  /**
   * A ref to the HTMLDialogElement.
   */
  const modalRef = useRef<HTMLDialogElement>(null)

  /**
   * Effect that observes the 'open' attribute of the dialog.
   * When the 'open' attribute changes, it updates the 'isOpen' state.
   */
  useEffect(() => {
    let observer: MutationObserver

    if (modalRef.current) {
      observer = new MutationObserver((mutations) => {
        const openMutations = mutations
          .filter((mutation) => mutation.attributeName === 'open')
          .reverse() // Reverse the order to get the last occurrence

        if (openMutations.length > 0) {
          const target = openMutations[0].target as HTMLDialogElement

          //   If open action driven by boolean only care about this direction of travel?
          if (!target.open) close()
        }
      })

      observer.observe(modalRef.current, { attributes: true })
    }

    return () => observer?.disconnect()
  }, [close])

  useEffect(() => {
    if (isOpen) {
      modalRef?.current?.showModal()
    } else {
      modalRef?.current?.close()
    }
  }, [isOpen])

  return {
    isOpen,
    modalRef,
    openModal: () => {
      if (__NX_BUILD_MODE__ === 'test') {
        modalRef.current?.setAttribute('open', '')
        return
      }
      modalRef.current?.showModal()
    },
    closeModal: () => {
      if (__NX_BUILD_MODE__ === 'test') {
        modalRef.current?.removeAttribute('open')
        return
      }
      modalRef.current?.close()
    },
  }
}
