import {
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  GetPageResourcesQuery,
  PageResource,
  useGetPageResourcesQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

import { FIRMS_COLLECTION_LIMIT } from '../constants'
type Data = PageResource | null

export const useGetResourcePage = ({ slug }: { slug: string }) => {
  const result = useReactQueryResultAsApiState<
    GetPageResourcesQuery,
    PageResource
  >(
    useGetPageResourcesQuery(
      {
        preview: previewService.enabled,
        where: { slug: slug },
        shouldIncludeDate: false,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      { refetchOnWindowFocus: false }
    ),
    selectPageResource,
    'Could not load pageResourceCollection'
  )
  return compileResults(
    result,
    result === ApiStateEnum.LOADING,
    isError(result)
  )
}
const selectPageResource = (data: GetPageResourcesQuery) =>
  data.pageResourceCollection?.items?.[0] as PageResource

const compileResults = (
  data: Data | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}
