import styled from '@emotion/styled'
import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'

import { BodySmall } from '@cais-group/approved/ui/typography'
import { colors } from '@cais-group/equity/particles/colors'
import {
  Table,
  TableHead,
  TableRows,
  TBody,
} from '@cais-group/shared/ui/react-table-v8'
import {
  CourseCredits,
  CreditStatus,
} from '@cais-group/shared/util/type/caisiq-be'

/* Create a Type as the interface doesn't match T extends Record<string, undefined> */
export interface TableCeCreditsProps {
  courses: CourseCredits[]
  profilePageColumns: ColumnDef<CourseCredits>[]
}

export const StyledTableHeaderWrapper = styled.p<{
  width?: number
  addPadding?: boolean
}>`
  line-height: var(--s16);
  padding-top: ${(props) => (props.addPadding ? `2px` : '0')};
  max-width: ${(props) => (props.width ? `${props.width}px` : '200px')};
`

export const CreditCell = styled(BodySmall)<{
  status: CreditStatus | undefined
}>`
  position: absolute;
  text-align: center;
  top: 0;
  bottom: 0;
  line-height: 56px; /* Line height plus top and bottom margins */
  width: 100%;

  ${({ status }) =>
    status === 'ARCHIVED' &&
    `
      color: ${colors['eq-color-neutral-500']};
      background-color: ${colors['eq-color-neutral-100']};
    `}
`

const StyledTable = styled(Table)`
  .table-header:nth-of-type(3),
  .table-header:last-of-type {
    justify-content: center;
    padding-right: 0;
  }
`

export function CeCreditsTable({
  courses,
  profilePageColumns,
}: TableCeCreditsProps) {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'defaultSort', desc: true },
  ])

  const { getHeaderGroups, getRowModel } = useReactTable<CourseCredits>({
    columns: profilePageColumns,
    data: courses,
    state: {
      sorting,
      pagination: { pageSize: 50, pageIndex: 0 },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  return (
    <StyledTable data-testid="profile-table">
      <TableHead headerGroups={getHeaderGroups()} />
      <TBody>
        <TableRows rows={getRowModel().rows} data-testid="profile-table-rows" />
      </TBody>
    </StyledTable>
  )
}

export default CeCreditsTable
