import classNames from 'classnames'

import { UniformFieldOption } from '../../types'

import { Shape, useGetRandomColors } from './shape'

export type OptionLayoutProps = {
  optionLayout?: 'basic'
}
export function OptionLayout({
  optionLayout,
  item,
  highlighted,
  selected,
  className,
}: OptionLayoutProps & {
  item: UniformFieldOption
  highlighted: boolean
  selected?: boolean
  className?: string
}) {
  const [randomColor] = useGetRandomColors(1)
  switch (optionLayout) {
    case 'basic':
      // TODO: implement all types of layouts
      return (
        <div
          className={classNames(
            'flex items-center px-16 py-8',
            className,
            highlighted && 'bg-primary-200',
            !item.disabled && 'cursor-pointer'
          )}
        >
          <div className="flex flex-1 items-center gap-16">
            <Shape
              backgroundColor={randomColor}
              rounded
              className="caption tracking-normal"
              label={item.label.slice(0, 2).toUpperCase()}
            />
            <div className="flex flex-col">
              <span>{item.label}</span>
              <span className="caption tracking-normal text-neutral-500">
                {item['id']}
              </span>
            </div>
          </div>
          <div
            className={classNames(
              'caption flex h-[22px] w-[22px] items-center justify-center rounded-full tracking-normal',
              highlighted ? 'bg-accent-300 text-neutral-0' : 'bg-neutral-200'
            )}
          >
            {Array.isArray(item['users']?.items) && item['users']?.items.length}
          </div>
        </div>
      )
    default:
      return (
        <span
          className={classNames('bodySmall flex w-full items-center p-8', {
            'bg-primary-100': selected && !item.disabled,
            'bg-neutral-100': highlighted && !selected && !item.disabled,
            'hover:bg-neutral-100': !selected && !item.disabled,
            'hover:bg-primary-200': selected && !item.disabled,
            'cursor-not-allowed': item.disabled,
            'cursor-pointer': !item.disabled,
          })}
        >
          {item.label}
        </span>
      )
  }
}
