import { ModalLayout } from '@cais-group/approved/ui/modal/layout'
import { BodySmall } from '@cais-group/approved/ui/typography'

type ConfirmAlmChangeModalPropTypes = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  onConfirmWithoutEnable?(): void
}
export function ConfirmAlmChangeModal({
  open,
  onClose,
  onConfirm,
}: ConfirmAlmChangeModalPropTypes) {
  return (
    <ModalLayout
      open={open}
      onClose={onClose}
      title="Are you sure you want to change the ALM catalog?"
      actions={[
        {
          variant: 'secondary',
          handler: () => onClose(),
          text: 'Cancel',
        },
        {
          /**
           * @todo The equity team will be adding a variant that allows green buttons
           */
          variant: 'danger',
          handler: () => {
            onConfirm()
          },
          text: 'Yes, Continue',
          testId: 'confirm-change-alm-catalog',
        },
      ]}
      body={
        <BodySmall as="span">Any course configuration will be lost.</BodySmall>
      }
    />
  )
}
