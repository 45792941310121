import { Controller, useFormContext } from 'react-hook-form'

import { H5Strong } from '@cais-group/approved/ui/typography'
import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import { FormSettingsOptions } from '@cais-group/caisiq/ui/form/settings-option'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import {
  ExperienceFields,
  FormStepProps,
} from '@cais-group/caisiq/util/type/manage-caisiq'
import {
  OrderedFieldset,
  StepNavigation,
  useStepState,
} from '@cais-group/shared/ui/ordered-fieldset'
import { UserRole } from '@cais-group/shared/util/type/caisiq-be'

const ReadOnlyExperienceType = ({
  label,
  helperText,
}: {
  label: string
  helperText: string
}) => (
  <div className="mt-32 p-0" data-testid="read-only-experience-type">
    <h6 className="h6Strong">{label}</h6>
    <p className="bodySmall text-neutral-600">{helperText}</p>
  </div>
)

export function ExperienceTypeStep({ order, formLabels }: FormStepProps) {
  const { control, getValues } = useFormContext<ExperienceFields>()
  const { deriveState, showStep } = useStepState()

  return (
    <OrderedFieldset
      legend={
        <H5Strong id="experience-type" className="scroll-mt-144">
          Experience Type
        </H5Strong>
      }
      state={deriveState(order)}
    >
      {showStep(order) && (
        <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
          <HasPermissions.Then>
            <Controller
              data-testid="experience-type-controller"
              control={control}
              name="experienceType"
              render={({ field: { onChange, onBlur, value } }) => (
                <FormSettingsOptions
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  label={formLabels?.experienceType}
                  options={[
                    {
                      value: 'FIRM',
                      label: experienceTypeOption['FIRM'].label,
                      helperText: experienceTypeOption['FIRM'].helperText,
                    },
                    {
                      value: 'DIRECT',
                      label: experienceTypeOption['DIRECT'].label,
                      helperText: experienceTypeOption['DIRECT'].helperText,
                    },
                  ]}
                />
              )}
            />
          </HasPermissions.Then>
          <HasPermissions.Else>
            <ReadOnlyExperienceType
              label={experienceTypeOption[getValues('experienceType')].label}
              helperText={
                experienceTypeOption[getValues('experienceType')].helperText
              }
            />
          </HasPermissions.Else>
        </HasPermissions>
      )}
      <StepNavigation
        order={order}
        onBeforeNext={() => {
          trackingService.manageNewExperienceTypeEntered({
            type: getValues('experienceType'),
          })
          return Promise.resolve(true)
        }}
      />
    </OrderedFieldset>
  )
}

type ExperienceTypeKeys = ExperienceFields['experienceType']

const experienceTypeOption: {
  [K in ExperienceTypeKeys]: {
    label: string
    helperText: string
  }
} = {
  FIRM: {
    label: 'Associated with platform firms',
    helperText: 'This experience must be assigned to one or more firms',
  },
  DIRECT: {
    label: 'Not associated with platform firms',
    helperText: 'This experience is not assigned to any firms on the platform',
  },
}
