/**
 * This module exports fetchers that match the behaviour of their GraphQL counterparts.
 *
 * They are temporary functions and should be replaced when their respective services support resource expansion,
 * as outlined here: https://caisgroup.atlassian.net/wiki/spaces/ER/pages/3722379318/CAIS+REST+API+Standards+V1#Resource-Expansion
 *
 */
import {
  PageInfo,
  PagedUsersV1,
  TeamSearchRequestV1,
  TeamV1,
  getFirmByIdV1,
  loadTeamsStatsV1,
  searchTeamsInFirmV1,
  searchTeamsV1,
  searchUsersInTeamV1,
} from '@cais-group/access-manager/domain/api'

import {
  getInterestedParties,
  getContact,
  getHousehold,
} from '../generated/api'
import {
  AccountV1,
  UniformRequirementsV1,
  UniformRulesV1,
} from '../generated/api.schemas'

import { RequirementRule } from './types'

export type AdapterRequirementsResponse = {
  account: AccountV1
  requirements: (Omit<UniformRequirementsV1, 'rules'> & {
    rules?: Omit<UniformRulesV1, 'requiredIf'> & {
      requiredIf?: RequirementRule[]
    }
  })[]
}

export type RequirementsResponse = {
  account: AccountV1
  requirements: (Omit<UniformRequirementsV1, 'rules'> & {
    rules?: { requiredIf: RequirementRule[] }
  })[]
}

export const getInterestedPartiesWithContacts = async (accountId?: string) => {
  if (!accountId) return
  const result = await getInterestedParties(accountId)
  const updatedResult = await Promise.all(
    result.map(async (ip) => ({
      ...ip,
      contact: await getContact(ip.contact.id),
    }))
  )
  return updatedResult
}

export const teamsSearch = async (
  teamsRequest: TeamSearchRequestV1
): Promise<PagedTeamsWithMembers> => {
  const paginatedTeams = await searchTeamsV1(teamsRequest)
  const teamIds = paginatedTeams.items.map((it) => it.id)
  const teamStatsDeferred = loadTeamsStatsV1(teamIds)

  return {
    pageInfo: paginatedTeams.pageInfo,
    items: await Promise.all(
      paginatedTeams.items.map(async (team, index) => {
        const users = await searchUsersInTeamV1(team.id, {})
        const teamStats = await teamStatsDeferred
        return {
          ...team,
          activeUsers: teamStats[index]?.activeUsers,
          totalUsers: teamStats[index]?.totalUsers,
          users,
        }
      })
    ),
  }
}

export const searchTeamsWithMembers = async (
  firmId: string,
  teamsRequest: TeamSearchRequestV1
): Promise<PagedTeamsWithMembers> => {
  const paginatedTeams = await searchTeamsInFirmV1(firmId, teamsRequest)
  const teamIds = paginatedTeams.items.map((it) => it.id)
  const teamStatsDeferred = loadTeamsStatsV1(teamIds)

  return {
    pageInfo: paginatedTeams.pageInfo,
    items: await Promise.all(
      paginatedTeams.items.map(async (team, index) => {
        const users = await searchUsersInTeamV1(team.id, {})
        const teamStats = await teamStatsDeferred
        return {
          ...team,
          activeUsers: teamStats[index]?.activeUsers,
          totalUsers: teamStats[index]?.totalUsers,
          users,
        }
      })
    ),
  }
}

export type PagedTeamsWithMembers = {
  pageInfo: PageInfo
  items: Array<
    TeamV1 & {
      activeUsers?: number
      totalUsers?: number
      users: PagedUsersV1
    }
  >
}

export const getHouseholdWithFirm = async (householdId: string) => {
  const household = await getHousehold(householdId)
  const firm = await getFirmByIdV1(household.firmId)
  return {
    ...household,
    firm,
  }
}
