export const FooterContents = {
  LEGAL_TERMS: (
    <p>
      The summary of terms above is preliminary and is subject to change. Either
      a registration statement relating to these securities has been filed with
      the Securities and Exchange Commission and is effective or the securities
      have not been, and will not be, registered under the Securities Act of
      1933, as amended (the “Securities Act”), or the state securities laws of
      any state of the United States or the securities laws of any other
      jurisdiction and are being offered pursuant to the registration exemption
      contained in Section 3(a) (2) of the Securities Act. Those securities that
      are being offered pursuant to registration may not be sold, nor may offers
      to buy be accepted, prior to the time the relevant registration statement
      becomes effective. Under no circumstances shall the foregoing summary of
      terms constitute an offer to sell or a solicitation of an offer to buy,
      nor shall there be any sale of these securities, in any jurisdiction in
      which such offer, solicitation or sale would be unlawful prior to
      registration or qualification under the securities law of any such
      jurisdiction.
    </p>
  ),
}
