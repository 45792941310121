import styled from '@emotion/styled'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Button as MuiButton,
  Paper,
  Popper,
} from '@mui/material'
import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Icon } from '@cais-group/equity/atoms/icon'

import {
  CurrentBreadcrumbLink,
  StyledBreadcrumbLink,
  StyledMuiBreadcrumbs,
} from './BreadcrumbsFull'
import { BreadcrumbItem, BreadcrumbsProps } from './approved-ui-breadcrumbs'

const StyledMuiButton = styled(MuiButton)`
  min-width: 0;
  padding: 0;
  :focus {
    outline: solid;
  }
  &[aria-expanded='true'] {
    svg {
      color: rgb(var(--colors-neutral-900));
    }
  }
`

const StyledMoreHorizIcon = styled(MoreHorizIcon)`
  color: rgb(var(--colors-neutral-300));
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  :hover {
    background-color: rgb(var(--colors-neutral-100));
  }
`

const StyledDividerWrapper = styled.div`
  padding-left: var(--s24);
  padding-right: var(--s24);
  width: 100%;
`

const StyledMenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: calc(var(--s8) + var(--s4)) var(--s32);
`

const StyledPaper = styled(Paper)`
  border-radius: 0;
`

interface BreadcrumbsCollapsedProps {
  items: BreadcrumbsProps['items']
}

export const BreadcrumbsCollapsed = (props: BreadcrumbsCollapsedProps) => {
  const { items } = props
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const navigate = useNavigate()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleItemClick = (
    event: React.MouseEvent<HTMLElement>,
    path?: BreadcrumbItem['path'],
    onClick?: BreadcrumbItem['onClick']
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.currentTarget)) {
      return
    }
    setOpen(false)
    if (onClick) {
      onClick(event)
    } else if (path) {
      navigate(path)
    }
  }

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.currentTarget as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current && !open && anchorRef.current) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const first = items[0]
  const secondLast = items[items.length - 2]
  const thirdLast = items[items.length - 3]
  const last = items[items.length - 1]
  const rest = items.slice(1, items.length - 3)
  return (
    <StyledMuiBreadcrumbs
      aria-label="breadcrumb"
      separator={
        <Icon
          size="small"
          color="eq-color-neutral-300"
          type="KeyboardArrowRight"
        />
      }
      data-testid="breadcrumbsCollapsed"
    >
      <StyledBreadcrumbLink
        href=""
        onClick={(event) => {
          if (first.onClick) {
            first.onClick(event)
          } else if (first.path?.length) {
            navigate(first.path)
          }
        }}
      >
        {first.label}
      </StyledBreadcrumbLink>
      <div>
        <StyledMuiButton
          ref={anchorRef}
          aria-expanded={open ? 'true' : 'false'}
          aria-haspopup="true"
          onClick={handleToggle}
          data-testid="breadcrumbExpandBtn"
          aria-label="breadcrumb expand button"
        >
          <StyledMoreHorizIcon />
        </StyledMuiButton>
        <Popper
          placeholder={false}
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <StyledPaper>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {rest.map((it, idx) => {
                      const isLast = idx === rest.length - 1
                      return (
                        <StyledMenuItem
                          onClick={(event) =>
                            handleItemClick(event, it.path, it.onClick)
                          }
                          key={it.path ?? idx}
                        >
                          <StyledMenuItemWrapper>
                            <StyledBreadcrumbLink
                              href=""
                              onClick={(event) => {
                                if (it.onClick) {
                                  it.onClick(event)
                                } else if (it.path?.length) {
                                  navigate(it.path)
                                }
                              }}
                            >
                              {it.label}
                            </StyledBreadcrumbLink>
                          </StyledMenuItemWrapper>
                          {!isLast && (
                            <StyledDividerWrapper>
                              <Divider />
                            </StyledDividerWrapper>
                          )}
                        </StyledMenuItem>
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </StyledPaper>
            </Grow>
          )}
        </Popper>
      </div>
      <StyledBreadcrumbLink
        href=""
        onClick={(event) => {
          if (thirdLast.onClick) {
            thirdLast.onClick(event)
          } else if (thirdLast.path?.length) {
            navigate(thirdLast.path)
          }
        }}
      >
        {thirdLast.label}
      </StyledBreadcrumbLink>
      <StyledBreadcrumbLink
        href=""
        onClick={(event) => {
          if (secondLast.onClick) {
            secondLast.onClick(event)
          } else if (secondLast.path?.length) {
            navigate(secondLast.path)
          }
        }}
      >
        {secondLast.label}
      </StyledBreadcrumbLink>
      <CurrentBreadcrumbLink>{last.label}</CurrentBreadcrumbLink>
    </StyledMuiBreadcrumbs>
  )
}
