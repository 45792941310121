import { Row } from '@tanstack/react-table'
import {
  useRef,
  MutableRefObject,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import { useFilterThemeOptions } from '@cais-group/caisiq/domain/manage-caisiq'
import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { Icon } from '@cais-group/equity/atoms/icon'
import { SelectOption } from '@cais-group/shared/ui/select-box'
import { SelectBoxAutocomplete } from '@cais-group/shared/ui/select-box-autocomplete'
import { useCloseOnEsc } from '@cais-group/shared/util/hook/use-close-on-esc'
import { useOnClickOutside } from '@cais-group/shared/util/hook/use-on-click-outside'
import {
  CourseMetadata,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'

export const ThemeCell = ({
  row,
  theme,
}: {
  theme: CourseMetadata['theme']
  row: Row<
    CourseMetadata & {
      isThemeOverriden?: boolean
    }
  >
}) => {
  const isFirmsPage = useLocation().pathname.includes('firms')
  const selectEl = useRef<HTMLDivElement>(null)

  useOnClickOutside({
    ref: selectEl as MutableRefObject<Element>,
    callback: () => {},
  })

  useCloseOnEsc(() => {
    setShowSelect(false)
  })
  const selectOptions = useFilterThemeOptions() ?? []

  const [showSelect, setShowSelect] = useState(false)
  const methods = useFormContext<ExperienceFields>()
  const { control, watch } = methods

  const courses = watch('courses')
  const courseId = row.original.acpCourseId

  /**
   * We need to find the index of the course in the courses array because
   * we need to update the correct course in the array and the row index
   * is not the same as the index in the array
   */
  const index = courses?.findIndex((c) => c.acpCourseId === courseId)
  const name = `courses.${index}.theme`

  // focusSelect will automatically focus on the input when the edit button is clicked
  // which will open the select box
  const focusSelect = useCallback(() => {
    if (selectEl.current) {
      const select = selectEl.current.querySelector('input')
      select?.focus()
    }
  }, [])

  useEffect(() => {
    if (showSelect) {
      focusSelect()
    }
  }, [focusSelect, showSelect])

  return !showSelect ? (
    <div className="flex items-center gap-8">
      <p className="small">{theme}</p>

      {!isFirmsPage && (
        <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
          <span
            className="invisible h-16 w-16 group-hover:visible"
            onClick={() => setShowSelect(true)}
          >
            <Icon type="Edit" size="tiny" color="eq-color-neutral-300" />
          </span>
        </HasPermissions>
      )}
    </div>
  ) : (
    <div ref={selectEl}>
      <Controller
        name={name as `courses.${number}.theme`}
        control={control}
        render={({ field: { onChange, value, onBlur } }) => (
          <SelectBoxAutocomplete
            id="theme"
            freeSolo={true}
            label={undefined}
            value={value}
            onBlur={(e) => {
              onChange(e.target.value)
            }}
            onChange={onChange}
            selectOptions={(selectOptions as SelectOption[]) || []}
            className="[&>*>div]:w-[190px]"
            openOnFocus
          />
        )}
      />
    </div>
  )
}
