import styled from '@emotion/styled'
import { FunctionComponent, useState } from 'react'
import { SetterOrUpdater } from 'recoil'

import { H4 } from '@cais-group/approved/ui/typography'
import {
  useCourseModal,
  useGetCourseCeCredits,
} from '@cais-group/caisiq/domain/course'
import { DefaultCourseImage } from '@cais-group/caisiq/domain/course-image'
import { usePatchEnrollCourse } from '@cais-group/caisiq/domain/library-course'
import { getHeaderLabel } from '@cais-group/caisiq/feature/common/get-header-label'
import { coursesByThemeType } from '@cais-group/caisiq/feature/courses'
import { BannerWithButtonContainer } from '@cais-group/caisiq/ui/banner-with-button'
import { CardCourseSkeleton } from '@cais-group/caisiq/ui/card/course'
import { ModalConfirmation } from '@cais-group/caisiq-ui-modal-confirmation'
import {
  CourseModalHeader,
  CourseDetailModal,
} from '@cais-group/caisiq-ui-modal-course-detail'
import { ModalPortal } from '@cais-group/caisiq-ui-modal-portal'
import { NoResults } from '@cais-group/caisiq-ui-no-results'
import { LayoutCenter, LayoutGrid } from '@cais-group/shared/ui/layout'
import { Course } from '@cais-group/shared/util/type/caisiq-be'

import { LibraryCoursesThemeGroup } from './LibraryCoursesGroup'

/**
 * Sorts an array of tuples in alphabetical order
 * based on the first element in the tuple.
 */
function alphaSort(items: Array<[string, Array<Course>]>) {
  return items.sort(([a], [b]) => {
    return a > b ? 1 : -1
  })
}

const StyledGrid = styled(LayoutGrid)`
  padding-top: var(--s24);
  padding-bottom: var(--s144);
`
StyledGrid.displayName = 'StyledGrid'

const NoResultsContainer = styled(LayoutCenter)`
  padding-top: var(--s24);
  padding-bottom: var(--s56);
`
NoResultsContainer.displayName = 'NoResultsContainer'

const EmptyMessage = styled(H4)`
  color: rgb(var(--colors-neutral-400));
  text-align: center;
  margin-top: var(--s144);
  margin-bottom: var(--s144);
`

const WideModalPortal = styled(ModalPortal)`
  width: 60%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

export interface CourseLibraryProps {
  searchTerm: string
  setSearchTerm: SetterOrUpdater<string>
  searchFilteredLibraryCourses: Course[]
  courseImages: DefaultCourseImage[]
}

export const CourseLibrary: FunctionComponent<CourseLibraryProps> = (props) => {
  const {
    searchTerm,
    setSearchTerm,
    searchFilteredLibraryCourses,
    courseImages,
  } = props

  const [pendingUnenrollCallback, setPendingUnenrollCallback] = useState<
    undefined | (() => void)
  >(undefined)

  const [libraryCourseToUnenrollId, setLibraryCourseToUnenrollId] = useState<
    string | undefined
  >()

  const { executeCourseEnrollPatch } = usePatchEnrollCourse()
  const { course: modalCourse, closeModal } = useCourseModal()

  const selectedLibraryCourse = searchFilteredLibraryCourses
    ? searchFilteredLibraryCourses.find(
        (course) => course.id === modalCourse?.id
      )
    : undefined

  const { ceCreditsAvailable } = useGetCourseCeCredits()

  const selectedCourseToUnenroll = searchFilteredLibraryCourses
    ? searchFilteredLibraryCourses.find(
        (course) => course.id === libraryCourseToUnenrollId
      )
    : undefined

  const { thematic, nonThematic } = coursesByThemeType(
    searchFilteredLibraryCourses ?? []
  )

  return searchFilteredLibraryCourses === undefined ? (
    <StyledGrid>
      {Array.from(Array(10).keys(), (i) => (
        <CardCourseSkeleton key={i} />
      ))}
    </StyledGrid>
  ) : searchFilteredLibraryCourses?.length === 0 ? (
    searchTerm === '' ? (
      // The user has not entered a search term so user must have no elective courses assigned.
      <EmptyMessage data-testid="no-elective-courses-message">
        You have no elective courses
      </EmptyMessage>
    ) : (
      // Given the search term, no matches exist in the elective courses.
      <NoResultsContainer>
        <NoResults
          searchSetName="Courses"
          searchTerm={searchTerm}
          onClick={() => setSearchTerm('')}
        />
      </NoResultsContainer>
    )
  ) : (
    <>
      {/* Thematic Library Courses */}
      {alphaSort(Object.entries(thematic)).map(
        ([name, libraryCourses], index) => (
          <LibraryCoursesThemeGroup
            key={`thematic-${index}`}
            name={name}
            libraryCourses={libraryCourses}
            setPendingUnenrollCallback={setPendingUnenrollCallback}
            setLibraryCourseToUnenrollId={setLibraryCourseToUnenrollId}
            withSeparator={true}
            courseImages={courseImages}
          />
        )
      )}
      {/* Non-thematic Library Courses */}
      <LibraryCoursesThemeGroup
        key="nonThematic"
        name=""
        libraryCourses={nonThematic}
        setPendingUnenrollCallback={setPendingUnenrollCallback}
        setLibraryCourseToUnenrollId={setLibraryCourseToUnenrollId}
        courseImages={courseImages}
      />
      <BannerWithButtonContainer />
      <WideModalPortal
        onClose={() => {
          setPendingUnenrollCallback(undefined)
          setLibraryCourseToUnenrollId(undefined)
        }}
      >
        {pendingUnenrollCallback && (
          <ModalConfirmation
            heading={`Are you sure you want to un-enroll from ${selectedCourseToUnenroll?.name}?`}
            continueText="Yes, un-enroll"
            onContinue={() => {
              pendingUnenrollCallback && pendingUnenrollCallback()
              setPendingUnenrollCallback(undefined)
            }}
            onCancel={() => {
              setPendingUnenrollCallback(undefined)
              setLibraryCourseToUnenrollId(undefined)
            }}
          >
            If you decide to enroll again later, you will keep any progress you
            made.
          </ModalConfirmation>
        )}
      </WideModalPortal>
      {selectedLibraryCourse && (
        <WideModalPortal
          onClose={closeModal}
          header={
            <CourseModalHeader
              imageUrl={selectedLibraryCourse?.imageUrl || ''}
              enrolledNumber={selectedLibraryCourse?.totalEnrolled || 0}
              headerLabel={getHeaderLabel(
                ceCreditsAvailable(selectedLibraryCourse)
              )}
            />
          }
        >
          {modalCourse && (
            <CourseDetailModal
              ceCreditsAvailable={ceCreditsAvailable(selectedLibraryCourse)}
              onClickEnroll={(libraryCourse: Course) =>
                executeCourseEnrollPatch({
                  id: libraryCourse.id,
                  enroll: true,
                })
              }
            />
          )}
        </WideModalPortal>
      )}
    </>
  )
}
