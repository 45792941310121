import { Controller } from 'react-hook-form'

import { Grid } from '@cais-group/shared/ui/grid'
import { TextInput } from '@cais-group/shared/ui/input/text'

import { AccessorKeys } from '../caisiq-ui-manage-update-course-modal'
import { getErrorMessage, transform } from '../utils'
import { ModalBodyProps } from '../utils/buildUpdateCourseModal'

const ceCreditInputs: Array<Partial<AccessorKeys>> = ['cfp', 'cima']

export const CeCreditModalBody = ({ control, errors }: ModalBodyProps) => {
  return (
    <>
      <p className="body">
        This will override any existing credit values for the selected courses.
      </p>
      <div className="mx-0 my-32 flex flex-wrap justify-between first-of-type:mr-0 [&>*]:basis-full">
        <Grid className="flex flex-row" container spacing={4}>
          {ceCreditInputs.map((creditType) => {
            const label = `${creditType.toUpperCase()} credits`
            return (
              <Grid item key={creditType}>
                <Controller
                  name={creditType}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextInput
                        id={creditType}
                        size="regular"
                        label={label}
                        type="number"
                        error={Boolean(getErrorMessage(creditType, errors))}
                        helperText={getErrorMessage(creditType, errors)}
                        className="mb-24"
                        {...{
                          ...field,
                          onChange: (e) => field.onChange(transform.output(e)),
                          value: transform.input(field.value),
                        }}
                        onWheel={(e) => {
                          const element = e.target as HTMLInputElement
                          element.blur()
                        }}
                      />
                    )
                  }}
                />
              </Grid>
            )
          })}
        </Grid>
      </div>
    </>
  )
}
