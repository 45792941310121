import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  UseFormReturn,
} from 'react-hook-form'

import {
  AccessorKeys,
  ModalFields,
} from '../caisiq-ui-manage-update-course-modal'
import { CeCreditModalBody } from '../components/ce-credit-modal-body'
import { GroupModalBody } from '../components/group-modal-body'
import { RequiredScoreModalBody } from '../components/required-score-modal-body'

export type BuildModalType = {
  accessorKey: AccessorKeys
  methods: UseFormReturn<ModalFields, object>
}

export type ModalBodyProps = {
  control: Control<ModalFields, object>
  errors: FieldErrorsImpl<DeepRequired<ModalFields>>
}

export const buildUpdateCourseModal = ({
  accessorKey,
  methods,
}: BuildModalType) => {
  switch (accessorKey) {
    case 'cfp':
    case 'cima':
      return {
        title: `Edit CE Credits`,
        body: (
          <CeCreditModalBody
            control={methods.control}
            errors={methods.formState.errors}
          />
        ),
      }

    case 'group':
      return {
        title: `Edit Group`,
        body: <GroupModalBody control={methods.control} />,
      }
    case 'requiredScore':
      return {
        title: `Edit Required Score`,
        body: (
          <RequiredScoreModalBody
            control={methods.control}
            errors={methods.formState.errors}
          />
        ),
      }
  }
}
