/**
 * Formats a number with commas as thousands separators.
 *
 * @param value The number to format.
 * @param options Pass format options to Intl.NumberFormat
 * @returns The formatted string.
 *
 * @example
 * formatNumberWithCommas(1234567); // "1,234,567"
 */
export function formatNumberWithCommas(
  value: number,
  options?: Intl.NumberFormatOptions
): string {
  if (typeof value !== 'number') {
    throw new TypeError('Expected a number')
  }

  if (!Number.isFinite(value) || Number.isNaN(value)) {
    throw new RangeError('Expected a finite number')
  }
  return new Intl.NumberFormat('en-US', options).format(value)
}
