import { forwardRef } from 'react'

import { TextInput, TextInputProps } from '@cais-group/equity/atoms/text-input'

import { Field, FieldProps } from '../field'
import { getTextInputColor } from '../utils'

export type TextInputFieldProps = TextInputProps & FieldProps

export const TextInputField = forwardRef<HTMLInputElement, TextInputFieldProps>(
  (props, ref) => {
    const {
      label,
      required,
      supportingText,
      helper,
      tag,
      description,
      ...textInput
    } = props

    const color = getTextInputColor(helper, textInput.color)

    return (
      <Field
        id={textInput.id}
        label={label}
        required={required}
        helper={helper}
        supportingText={supportingText}
        tag={tag}
        description={description}
      >
        <TextInput
          ref={ref}
          {...textInput}
          color={color}
          aria-errormessage={`${textInput.id}--helper`}
        />
      </Field>
    )
  }
)
