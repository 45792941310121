import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { ReactNode } from 'react'

import { SubtitleMono } from '@cais-group/approved/ui/typography'
import { LayoutStack } from '@cais-group/shared/ui/layout'

export enum AssessmentTagType {
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  UNLOCKED = 'UNLOCKED',
  HAS_ASSESSMENT = 'HAS_ASSESSMENT',
}

export type AssessmentTagProps = {
  type: AssessmentTagType
  text: string
}

const SubtitleStrong = styled(SubtitleMono)`
  font-family: var(--font-text);
  font-weight: 600;
`

const PassedIcon = styled(CheckIcon)`
  fill: rgb(var(--colors-success-600));
`
const FailedIcon = styled(CloseIcon)`
  fill: rgb(var(--colors-error-600));
`

const UnlockedIcon = styled(LockOpenIcon)`
  fill: rgb(var(--colors-primary-600));
`

const AssessmentTagContainer = styled(LayoutStack)`
  align-items: center;
  flex-direction: row;
  gap: var(--s8);

  & > ${SubtitleStrong} {
    color: rgb(var(--colors-neutral-600));
    margin-top: var(--s8);
    margin-bottom: var(--s8);
    line-height: 24px;
  }
`
AssessmentTagContainer.displayName = 'AssessmentTagContainer'

export const AssessmentTag = (props: AssessmentTagProps) => {
  const { type, text } = props

  let Icon: ReactNode = null
  if (type === AssessmentTagType.PASSED) {
    Icon = (
      <PassedIcon
        aria-label="Assessment passed"
        data-testid="assessment-passed-icon"
      />
    )
  } else if (type === AssessmentTagType.FAILED) {
    Icon = (
      <FailedIcon
        aria-label="Assessment failed"
        data-testid="assessment-failed-icon"
      />
    )
  } else if (type === AssessmentTagType.UNLOCKED) {
    Icon = (
      <UnlockedIcon
        aria-label="Assessment unlocked"
        data-testid="assessment-unlocked-icon"
      />
    )
  }

  return (
    <AssessmentTagContainer data-testid="assessment-tag">
      {Icon}
      <SubtitleStrong>{text}</SubtitleStrong>
    </AssessmentTagContainer>
  )
}
