export const trackingLabels = {
  section: {
    Homepage: 'Homepage',
    Articles: 'Articles',
    AllResearch: 'All Research',
    Disclosure: 'Disclosure',
    FeaturedResearch: 'Featured Research',
    Help: 'Help',
    Insights: 'Insights',
    Tutorials: 'Tutorials',
    Videos: 'Videos',
    Webinars: 'Webinars',
    Whitepapers: 'Whitepapers',
  },
  subSection: {
    AtoZNavigation: 'A to Z Navigation',
    BrowseContent: 'Browse Content',
    FAQ: 'FAQ',
    FeaturedContent: 'Featured Content',
    Glossary: 'Glossary',
    GridOfContent: 'Grid of Content',
    InPersonEvents: 'In Person Events',
    PressResealses: 'Press Releases',
    StructuredInvestmentsResources: 'Structured Investments Resources',
    StructuredInvestmentsSMAs: 'Structured Investments SMAs',
    UpcomingWebinars: 'Upcoming Webinars',
    WebinarReplays: 'Webinar Replays',
  },
} as const

export const mapUrlToSection = (pathname: string) => {
  if (pathname === '/homepage' || pathname === '/homepage/') {
    return trackingLabels.section.Homepage
  }
  if (pathname.includes('/research/all-research')) {
    return trackingLabels.section.AllResearch
  }
  if (pathname.includes('/research/featured')) {
    return trackingLabels.section.FeaturedResearch
  }
  if (pathname.includes('/tutorials')) {
    return trackingLabels.section.Tutorials
  }
  if (pathname.includes('/webinars')) {
    return trackingLabels.section.Webinars
  }
  if (pathname.includes('/articles')) {
    return trackingLabels.section.Articles
  }
  if (pathname.includes('/whitepapers')) {
    return trackingLabels.section.Whitepapers
  }
  if (pathname.includes('/videos')) {
    return trackingLabels.section.Videos
  }
  if (pathname.includes('/disclosure')) {
    return trackingLabels.section.Disclosure
  }
  if (pathname.endsWith('/help/faqs')) {
    return trackingLabels.subSection.FAQ
  }
  if (pathname.includes('/help/glossary')) {
    return trackingLabels.subSection.Glossary
  }
  if (pathname.includes('/events/in-person')) {
    return trackingLabels.subSection.InPersonEvents
  }
  if (
    pathname.includes('/structured-investments/structured-investments-smas')
  ) {
    return trackingLabels.subSection.StructuredInvestmentsSMAs
  }
  if (pathname.includes('/resources/structured-investments')) {
    return trackingLabels.subSection.StructuredInvestmentsResources
  }

  return 'Unknown'
}
