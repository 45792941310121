import { DEFEDERATED_APP_PATHS } from './shared-domain-apps'

// The HACK! enjoy :) SP doesn't want to change their app name, so...
const getAppBasePath = (path: string) =>
  path === 'structured-investments' ? 'structured-products' : path

export function getAppEnvConfigPath() {
  if (typeof globalThis.location === 'undefined') {
    return '/env.json'
  }

  const appPath = globalThis.location.pathname.split('/')[1]

  if (isAppDefederated(appPath)) {
    return `/${getAppBasePath(appPath)}/env.json`
  }

  return `/container-assets/env.json`
}

export function isAppDefederated(appPath: string) {
  return (DEFEDERATED_APP_PATHS as unknown as string[]).includes(appPath)
}
