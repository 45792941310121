import { Block } from '@contentful/rich-text-types'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { ApiState, isData } from '@cais-group/caisiq/domain/common'
import { inBody, searchTermAtom } from '@cais-group/caisiq/domain/search'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'

import { useGetArticles } from './useGetArticles'

type ArticlesApiState = ApiState<TypeArticleData[]>

export function useFilteredArticles() {
  const searchTerm = useRecoilValue(searchTermAtom)
  const articles = useGetArticles()
  return useMemo(
    () => filterArticles(articles, searchTerm),
    [articles, searchTerm]
  )
}

export function filterArticles(
  articlesApiState: ArticlesApiState,
  searchTerm: string
) {
  return isData(articlesApiState)
    ? articlesApiState.filter((article: TypeArticleData) => {
        if (searchTerm === '') return true

        const searchTermLower = searchTerm.toLowerCase()

        return (
          article.title.toLowerCase().includes(searchTermLower) ||
          article.description?.toLowerCase().includes(searchTermLower) ||
          article.introduction?.toLowerCase().includes(searchTermLower) ||
          article.author.name.toLowerCase().includes(searchTermLower) ||
          inBody(article?.body?.json as Block, searchTermLower)
        )
      })
    : undefined
}
