import styled from '@emotion/styled'
import * as React from 'react'

import { H4Strong, Body } from '@cais-group/approved/ui/typography'
import { Button } from '@cais-group/equity/atoms/button'
import { LayoutCluster, LayoutBox } from '@cais-group/shared/ui/layout'

export type ConfirmationModalProps = {
  heading: string
  children: React.ReactNode
  cancelText?: string
  continueText?: string
  onCancel: () => void
  onContinue: () => void
}

const StyledH4Strong = styled(H4Strong)`
  padding-right: var(--s24);
`

const MinHeightBody = styled(Body)`
  min-height: var(--s144);
  color: rgb(var(--colors-neutral-600));
  padding-top: var(--s16);
`

export const ModalConfirmation = (props: ConfirmationModalProps) => {
  const {
    heading,
    children,
    cancelText = 'Cancel',
    continueText = 'Continue',
    onCancel,
    onContinue,
  } = props

  return (
    <LayoutBox>
      <StyledH4Strong>{heading}</StyledH4Strong>
      <MinHeightBody>{children}</MinHeightBody>
      <LayoutCluster justify="flex-end">
        <Button variant="secondary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button onClick={onContinue}>{continueText}</Button>
      </LayoutCluster>
    </LayoutBox>
  )
}
