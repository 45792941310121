import * as React from 'react'

import { useUniformContext } from '../context'
import type { OptionLayoutProps } from '../equity/common/option-layout'
import type { OptionsLayoutProps } from '../equity/common/options-layout'
import { Select } from '../equity/select'
import type {
  OptionsOrAsyncOptions,
  UseOptionsPropsReturns,
} from '../hooks/async-options'
import { AsyncOptions } from '../hooks/async-options'
import type { ActionModalProps } from '../hooks/use-dropdown-action'
import { useDropdownAction } from '../hooks/use-dropdown-action'
import type { UniformServices } from '../services'
import type {
  UniformFieldOption,
  UniformFieldProps,
  UniformAny,
  InitialOptions,
} from '../types'

import { UniformAdapter } from './adapter'

type RenderUnderComponent = (props: {
  initialOptions: InitialOptions
  refetch?: () => Promise<UniformAny>
  setSelectedValue?: (id: string) => void
}) => React.ReactNode

type CombinedSelectInputProps<
  TFormFieldIds extends keyof UniformAny = UniformAny
> = UniformFieldProps<'SelectInput'> &
  OptionLayoutProps &
  OptionsLayoutProps & {
    id: TFormFieldIds
    actionModal?: ActionModalProps
    renderUnderComponent?: RenderUnderComponent
  }
export type SelectInputProps<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
> = CombinedSelectInputProps<TFormFieldIds> &
  OptionsOrAsyncOptions<TUniformServices>

type SelectComponentProps<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
> = {
  field: SelectInputProps<TFormFieldIds, TUniformServices>
  onFocus?: () => void
  selectOptions: UniformFieldOption[]
  isLoading?: boolean
  hasNextPage?: boolean
  depsValues?: string[]
} & UseOptionsPropsReturns

const SelectComponent = <
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
>({
  field,
  onFocus,
  selectOptions,
  fetchNextPage,
  isLoading,
  hasNextPage,
  refetch,
  depsValues = [],
}: SelectComponentProps<TFormFieldIds, TUniformServices>) => {
  const { initialOptions } = useUniformContext()
  const { handleDropdownAction, actionName } = useDropdownAction({
    modalProps: field.actionModal,
    refetch,
  })

  return (
    <UniformAdapter
      field={field}
      render={({
        uniform: { error, id, isDisabled, required, tag },
        field: { ref, onChange, onBlur, value, name },
      }) => {
        return (
          <>
            <Select
              ref={ref}
              id={id}
              label={field.label}
              required={!!required}
              disabled={isDisabled}
              tag={tag?.children !== '' ? tag : undefined}
              error={!!error}
              errorMessage={error?.message}
              options={selectOptions}
              onFocus={onFocus}
              depsValues={depsValues}
              onChange={(option) => {
                if (option === undefined) return
                onChange(option?.value)
              }}
              onBlur={onBlur}
              value={value}
              name={name}
              optionLayout={field.optionLayout}
              optionsLayout={field.optionsLayout}
              optionsLayoutGroupBy={field.optionsLayoutGroupBy}
              fetchNextPage={fetchNextPage}
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              dropdownActionName={actionName}
              dropdownAction={handleDropdownAction}
              tooltip={field.tooltip}
            />
            {field.renderUnderComponent?.({
              initialOptions,
              refetch: async () => await refetch?.(),
              setSelectedValue: (id) => onChange(id),
            })}
          </>
        )
      }}
    />
  )
}

export const SelectInput = <
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
>(
  field: SelectInputProps<TFormFieldIds, TUniformServices>
) => {
  return (
    <AsyncOptions
      field={field}
      asyncOptions={({
        value: options = [],
        fetchNextPage,
        isLoading,
        isFetching,
        hasNextPage,
        refetch,
        depsValues,
      }) => (
        <SelectComponent
          field={field}
          selectOptions={options}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading || isFetching}
          hasNextPage={hasNextPage}
          refetch={refetch}
          depsValues={depsValues}
        />
      )}
      options={(options) => (
        <SelectComponent field={field} selectOptions={options} />
      )}
    />
  )
}

export default SelectInput
