import { uiTypeSize } from '@cais-group/shared/ui/type/size'
import styled from '@emotion/styled'

export type LayoutBoxProps = {
  padding?: uiTypeSize
  [key: string]: unknown
}

export const LayoutBox = styled.div<LayoutBoxProps>`
  padding: ${(props) => props.padding || 'var(--s16)'};
`
LayoutBox.displayName = 'Box'
LayoutBox.defaultProps = {
  'data-layout': 'Box',
}
