import {
  TRACKING_PREFIX,
  type TrackingAttributes,
  type TrackingProps,
} from './constants'

export function mapTrackingKeysToDataAttributes(
  props: TrackingProps = {}
): TrackingAttributes {
  const { status, ...tracking } = props

  if (status === 'off') {
    return {
      [TRACKING_PREFIX]: false,
    }
  }

  const mappedKeys: Record<string, unknown> = {}

  for (const [key, value] of Object.entries(tracking)) {
    const mappedKey = `${TRACKING_PREFIX}-${key}`
    mappedKeys[mappedKey] = value
  }

  return {
    ...(status === 'on' && { [TRACKING_PREFIX]: true }),
    ...mappedKeys,
  }
}
