import { useEffect } from 'react'

type FocusableHTMLElement = HTMLElement & {
  focus: (event: FocusEvent) => void
}

/**
 * What is the purpose of this hook?
 * ----------------------------------------------------------------------------------------------------
 * Most of the time this hook will be used when opening a modal or similar.
 * When we open a modal we want to remember the element that opened the modal so we can refocus that
 * same element when the modal closes for accessibility reasons.
 *
 * How to use?
 * ------------------------------------------------------------------------------------------------------
 *
 * --- AS A HOOK ---
 *
 * const Modal = () => {
 *  useOnUnmountFocusLastElement();
 *  useFocusFirstElement();
 *
 *  return (...)
 * }
 *
 *
 * --- AS A COMPONENT ---
 *
 * const MainComponent = () => {
 *  const [isOpen, setIsOpen] = React.useState(false);
 *
 *  return (
 *    <>
 *      <button onClick={() => setIsOpen(true)}>Open</button>
 *      <button onClick={() => setIsOpen(false)}>Close</button>
 *      {isOpen && (
 *        <>
 *          <FocusLastElementOnUnmount />
 *          <ModalThatWillFocusItsFirstElement />
 *        </>
 *       )}
 *    </>
 *  )
 * }
 */
export const useOnUnmountFocusLastElement = () => {
  const lastElement = document.activeElement as FocusableHTMLElement

  useEffect(() => {
    return () => {
      lastElement?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const FocusLastElementOnUnmount = () => {
  useOnUnmountFocusLastElement()
  return null
}
