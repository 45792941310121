import cx from 'classnames'

import { Icon } from '@cais-group/equity/atoms/icon'

type StepState = 'incomplete' | 'active' | 'complete'
type StepSize = 'large' | 'small'

export type StepProps = {
  /** A property that represents the step state */
  state: StepState
  /** The step count */
  count: number
  /** The label to show next to the count */
  title: string
  /** An optional callback function to call when clicking on the step component */
  onClick?: () => void
  /** An optional id value to apply to the heading element of the component */
  id?: string
  /** Controls the size of the step. Defaults to large */
  size?: StepSize
}

/**
 * The Step is used in Form components to indicate progress through a form.
 * It has 3 documented states: incomplete, active, and complete,
 * and optionally can have a callback function to call when clicking on the step component.
 */
export const Step = ({
  state,
  count,
  title,
  onClick,
  id,
  size = 'large',
}: StepProps) => {
  return (
    <div
      className={cx('flex items-center', {
        'cursor-pointer': !!onClick,
        'gap-x-24': size === 'large',
        'gap-x-8': size === 'small',
      })}
      onClick={onClick}
    >
      <StepCount state={state} count={count} size={size} />
      <StepTitle id={id} state={state} title={title} size={size} />
    </div>
  )
}

const StepCount = ({
  state,
  count,
  size,
}: {
  state: StepState
  count: number
  size: StepSize
}) => (
  <div
    className={cx(
      'z-[1] flex min-w-max items-center justify-center rounded-full border-2 border-solid',
      {
        'bg-neutral-0 border-neutral-500': state === 'incomplete',
        'border-primary-600 bg-neutral-0': state === 'active',
        'border-success-600 bg-success-600': state === 'complete',
        'text-neutral-500': state === 'incomplete',
        'text-primary-600': state === 'active',
        'headline-s-strong h-56 w-56': size === 'large',
        'body-strong h-32 w-32': size === 'small',
      }
    )}
  >
    {state !== 'complete' ? (
      count
    ) : (
      <Icon type="Check" color="eq-color-neutral-0" size={size} />
    )}
  </div>
)

const StepTitle = ({
  id,
  state,
  title,
  size,
}: {
  state: StepState
  title: string
  id?: string
  size: StepSize
}) => (
  <h2
    id={id}
    className={cx('', {
      'text-neutral-500': state === 'incomplete',
      'text-primary-600': state === 'active',
      'text-neutral-900 underline underline-offset-4': state === 'complete',
      'headline-s-strong': size === 'large',
      'body-strong': size === 'small',
    })}
    data-testid={`form-section-title-${title}`}
  >
    {title}
  </h2>
)
