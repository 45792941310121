import styled from '@emotion/styled'
import { Skeleton } from '@mui/material'
import * as React from 'react'

import { Body, H6Strong } from '@cais-group/approved/ui/typography'
import { LIBRARY_CARD_HEIGHT } from '@cais-group/caisiq/ui/card/course-library'
import { STYLES } from '@cais-group/caisiq/ui/card/shared'
import { IconCertificate } from '@cais-group/caisiq/ui/icons'
import { ImageOrFallback } from '@cais-group/caisiq/ui/image-or-fallback'
import { StatusTag } from '@cais-group/equity/atoms/status-tag'
import { ContextMenuTable } from '@cais-group/shared/ui/buttons'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import {
  LayoutBox,
  LayoutCluster,
  LayoutStack,
} from '@cais-group/shared/ui/layout'
import { Course } from '@cais-group/shared/util/type/caisiq-be'
import {
  isLearningComplete,
  isPassed,
} from '@cais-group/shared/util/type/course'

import { CourseAssessmentTag } from './course-assessment-tag'
import { CourseCallToActionButton } from './course-call-to-action'
import { CourseProgressState } from './course-progress-state'

export type CardCourseProps = {
  course: Course
  fallbackImageUrl?: string
  onClickViewDetails: (course: Course) => void
  headerLabel?: string
}

const LayoutCard = styled(LayoutBox)`
  background-color: rgb(var(--colors-neutral-0));
  &:hover {
    box-shadow: 0px 4px 24px rgb(var(--colors-neutral-300));
  }
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Heading = styled(H6Strong)`
  display: flex;
  color: rgb(var(--colors-neutral-900));
  flex: 0 1 auto;
`

const Header = styled.div`
  position: relative;
  height: 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  background-color: rgb(var(--colors-primary-600));
  background-size: cover;
`

export const ContextMenuItem = styled.li`
  color: rgb(var(--colors-neutral-900));
  margin: 0;
  padding: 0.75rem var(--s24);
  list-style: none;
  &:hover {
    background-color: rgb(var(--colors-neutral-200));
    cursor: pointer;
  }
`

export const ContextMenuLine = styled.div`
  border-bottom: 1px solid rgb(var(--colors-neutral-300));
  margin: 0 var(--s24);
`

const Certificate = styled(IconCertificate)`
  position: absolute; // To bring this to the top vs absolute positioned background
  width: calc(var(--s8) * 5);
  height: calc(var(--s8) * 5);
  align-self: flex-end;
  right: var(--s24);
`
const HeaderLabel = styled(H6Strong)`
  position: absolute; // To bring this to the top vs absolute positioned background
  margin-left: var(--s24);
  bottom: var(--s8);
  width: fit-content;
  color: rgb(var(--colors-neutral-0));
`
// Flex properties required for safari only
const DescriptionContainer = styled.div`
  margin-top: var(--s8);
  margin-bottom: var(--s8);
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`

const Description = styled(Body)`
  text-align: left;
  color: rgb(var(--colors-neutral-600));
`

const StyledCard = styled(LayoutCard)`
  padding: 0;
  min-width: ${STYLES.CARD_WIDTH};
  height: ${LIBRARY_CARD_HEIGHT};
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${BREAKPOINTS.breakpointSm}) {
    padding: 0;
  }
`

const StyledContent = styled(LayoutBox)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 'var(--s24)';
`

const Actions = styled(LayoutCluster)`
  margin-top: auto;
  position: relative;
  overflow: visible;
  flex: 0 0 auto;
`

export const CardCourseSkeleton = () => {
  return (
    <StyledCard>
      <Skeleton variant="rectangular" height="var(--s88)" />
      <StyledContent>
        <Heading>
          <Skeleton width="30ch" />
        </Heading>
        <DescriptionContainer>
          <Description>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="30ch" />
          </Description>
        </DescriptionContainer>
        <Actions justify="space-between">
          <Skeleton
            variant="rectangular"
            height="var(--s32)"
            width="var(--s144)"
          />
        </Actions>
      </StyledContent>
    </StyledCard>
  )
}

export const CardCourse = ({
  course,
  fallbackImageUrl,
  onClickViewDetails,
  headerLabel,
}: CardCourseProps) => {
  const isCompleteOrPassed = isLearningComplete(course) || isPassed(course)
  const isWithAssessment = Boolean(course.anyAssessment)

  return (
    <StyledCard
      data-testid={`course-card-${course.type}`}
      onClick={() => onClickViewDetails(course)}
    >
      <Header>
        <ImageOrFallback
          imageUrl={course.imageUrl}
          fallbackUrl={fallbackImageUrl}
          minAspectRatio={376 / 88}
          maxAspectRatio={1120 / 160}
          data-chromatic="ignore"
        />
        {headerLabel && (
          <HeaderLabel data-testid="cardCourse-ceCredit-label">
            {headerLabel}
          </HeaderLabel>
        )}
        {isCompleteOrPassed && (
          <Certificate
            data-testid="course-complete-certificate"
            fill="rgb(var(--colors-neutral-0))"
          />
        )}
      </Header>
      <StyledContent>
        <HeadingContainer>
          <Heading>{course.name}</Heading>
          <ContextMenuTable
            className="translate-x-8 transform py-0"
            actions={[
              {
                text: 'View details',
                handler: (event?: React.MouseEvent) => {
                  event?.stopPropagation()
                  onClickViewDetails(course)
                },
              },
            ]}
          />
        </HeadingContainer>
        <LayoutStack
          style={{ flexGrow: '1' }}
          space="0"
          justify="space-between"
        >
          <div>
            {isCompleteOrPassed && (
              <div className="mt-8">
                <StatusTag variant="light" color="success">
                  Completed
                </StatusTag>
              </div>
            )}

            {isWithAssessment && <CourseAssessmentTag course={course} />}
          </div>
          <div>
            <Actions
              data-testid={`course-card-cta-${course.id}`}
              justify="space-between"
              space="0"
              align="flex-end"
            >
              <CourseCallToActionButton
                course={course}
                buttonWidth={0}
                size="medium"
              />
              <CourseProgressState
                course={course}
                withCeCredits={Boolean(headerLabel)}
              />
            </Actions>
          </div>
        </LayoutStack>
      </StyledContent>
    </StyledCard>
  )
}
