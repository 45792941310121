import styled from '@emotion/styled'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  CSSProperties,
  MouseEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react'

import { useCloseOnEsc } from '@cais-group/shared/util/hook/use-close-on-esc'
import { useOnClickOutside } from '@cais-group/shared/util/hook/use-on-click-outside'
import { useTrapFocus } from '@cais-group/shared/util/hook/use-trap-focus'

type CSSWidth = CSSProperties['width']

type MenuPosition = 'bottom-left' | 'bottom-right'

type ContextMenuWrapProps = {
  position?: MenuPosition
  width?: CSSWidth
}

const ContextMenuWrap = styled.div<ContextMenuWrapProps>`
  display: inline-block;
  background-color: rgb(var(--colors-neutral-0));
  box-shadow: 0 4px var(--s16) rgba(0, 0, 0, 0.14);
  padding: 0;
  z-index: 1;
  position: absolute;
  top: 100%;
  width: ${({ width }) => width || 'var(--s144)'};
  ${({ position }) => (position === 'bottom-left' ? 'left: 0' : 'right: 0;')};
`

const StyledMoreVertIcon = styled(MoreVertIcon)`
  fill: rgb(var(--colors-neutral-300));
`

const ContextMenu = styled.div`
  position: relative;
  display: inline-block;
  height: max-content;
`

const ContextMenuButton = styled.button`
  cursor: pointer;
  &:hover svg {
    fill: rgb(var(--colors-neutral-600));
  }
  &:focus svg {
    fill: rgb(var(--colors-neutral-600));
  }
`

export type ContextMenuProps = {
  alternativeIcon?: ReactNode
  ariaLabel?: string
  children?: ReactElement | ((props: { closeMenu: () => void }) => ReactElement)
  className?: string
  position?: MenuPosition
  testId?: string
  width?: CSSWidth
}

type PopUpMenuProps = {
  children: ReactNode
  callback: () => void
  menuRef: MutableRefObject<HTMLDivElement>
  position?: MenuPosition
  width?: CSSWidth
}

const PopUpMenu = ({
  children,
  callback,
  menuRef,
  position,
  width,
}: PopUpMenuProps) => {
  useOnClickOutside<HTMLDivElement>({
    ref: menuRef,
    callback,
  })
  useTrapFocus({ refEl: menuRef, shouldTrapFocus: !!children })
  useCloseOnEsc(callback)

  return (
    <ContextMenuWrap role="menu" width={width} position={position}>
      {children}
    </ContextMenuWrap>
  )
}

export const ContextMenuDefault = (props: ContextMenuProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const contextMenuRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
  const {
    alternativeIcon,
    ariaLabel = 'menu',
    children,
    className,
    width,
    position = 'bottom-right',
    testId,
  } = props

  const closeMenu = () => {
    setIsOpen(false)
  }

  return (
    <ContextMenu ref={contextMenuRef} data-testid={testId}>
      <ContextMenuButton
        type="button"
        aria-label={ariaLabel}
        aria-pressed={isOpen}
        onClick={(event: MouseEvent) => {
          event.stopPropagation()
          setIsOpen(!isOpen)
        }}
        className={className}
      >
        {alternativeIcon ?? <StyledMoreVertIcon />}
      </ContextMenuButton>
      {isOpen && (
        <PopUpMenu
          callback={closeMenu}
          width={width}
          menuRef={contextMenuRef}
          position={position}
        >
          {typeof children === 'function' ? children({ closeMenu }) : children}
        </PopUpMenu>
      )}
    </ContextMenu>
  )
}
