import { Theme } from './types'

export const colors: Theme = {
  brand: {
    '100': '#f8eee7',
    '100-c': '#211c21',
    '200': '#f1ddce',
    '200-c': '#211c21',
    '300': '#e4ba9d',
    '300-c': '#211c21',
    '400': '#d6986c',
    '400-c': '#211c21',
    '500': '#ca7c44',
    '500-c': '#211c21',
    '600': '#bb530a',
    '600-c': '#ffffff',
    '700': '#964208',
    '700-c': '#ffffff',
    '800': '#703206',
    '800-c': '#ffffff',
    '900': '#4b2104',
    '900-c': '#ffffff',
  },
  primary: {
    '100': '#f6f6f6',
    '100-c': '#211c21',
    '200': '#e3e3e3',
    '200-c': '#211c21',
    '300': '#c7c6c7',
    '300-c': '#211c21',
    '400': '#b5b3b5',
    '400-c': '#211c21',
    '500': '#8f8c8f',
    '500-c': '#211c21',
    '600': '#703206',
    '600-c': '#ffffff',
    '700': '#4b2104',
    '700-c': '#ffffff',
    '800': '#2e2a2e',
    '800-c': '#ffffff',
    '900': '#211c21',
    '900-c': '#ffffff',
  },
  datavis: {
    '1': '#bb530a',
    '1-c': '#ffffff',
    '2': '#65162c',
    '2-c': '#ffffff',
    '3': '#c0bbf7',
    '3-c': '#211c21',
    '4': '#a8864d',
    '4-c': '#211c21',
    '5': '#925364',
    '5-c': '#ffffff',
    '6': '#917da3',
    '6-c': '#211c21',
    '7': '#cdd1d8',
    '7-c': '#211c21',
    '8': '#3958a1',
    '8-c': '#ffffff',
    '9': '#c0e0db',
    '9-c': '#211c21',
    '10': '#f9d974',
    '10-c': '#211c21',
    '11': '#c47774',
    '11-c': '#211c21',
    '12': '#00407b',
    '12-c': '#ffffff',
    '13': '#8cade9',
    '13-c': '#211c21',
    '14': '#327174',
    '14-c': '#ffffff',
    '15': '#96dbc8',
    '15-c': '#211c21',
    '16': '#ffc170',
    '16-c': '#211c21',
    '17': '#595c5c',
    '17-c': '#ffffff',
    '18': '#2a92ba',
    '18-c': '#211c21',
    '19': '#86d3f2',
    '19-c': '#211c21',
    '20': '#6c8f70',
    '20-c': '#211c21',
    '21': '#d1e0a5',
    '21-c': '#211c21',
    '22': '#f3d7bc',
    '22-c': '#211c21',
  },
  gradient: {
    '1-1': '#e4ba9d',
    '1-1-c': '#211c21',
    '1-2': '#f8eee7',
    '1-2-c': '#211c21',
    '2-1': '#bb530a',
    '2-1-c': '#ffffff',
    '2-2': '#e4ba9d',
    '2-2-c': '#ffffff',
    '3-1': '#4b2104',
    '3-1-c': '#ffffff',
    '3-2': '#bb530a',
    '3-2-c': '#ffffff',
    '4-1': '#bb530a',
    '4-1-c': '#ffffff',
    '4-2': '#e4ba9d',
    '4-2-c': '#ffffff',
  },
} as const
