import { useContext } from 'react'

import {
  CaisiqEnvConfig,
  EnvContext as CaisIqEnvContext,
} from './caisiq-ui-env'

export function useConfig(): CaisiqEnvConfig {
  const envConfig = useContext<CaisiqEnvConfig | undefined>(CaisIqEnvContext)

  if (!envConfig) {
    throw new Error(
      'Attempted to useConfig outside of EnvConfigContainerProvider'
    )
  }

  return envConfig
}
