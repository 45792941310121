import {
  useCallback,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'

type SetValue<IStoredValue> = Dispatch<SetStateAction<IStoredValue>>

/**
 * A helper hook for storing values in `localStorage` with an API that matches
 * React's useState. Like useState, the updater function
 * is referentially stable which means we can pass it as a dependency in hooks.
 *
 * TODO: Do we need to sync the updates to localStorage across browser tabs?
 *
 * @param key
 * @param initialValue
 * @returns
 */

export function useLocalStorage<IKey extends string, IStoredValue>(
  key: IKey,
  initialValue: IStoredValue
): [IStoredValue, SetValue<IStoredValue>] {
  // Return initialValue if window is not defined.
  const readValue = useCallback((): IStoredValue => {
    // NOTE: This prevent build errors when window is undefined.
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)
      return item ? (parseJSON(item) as IStoredValue) : initialValue
    } catch (error) {
      console.warn(`Error reading “${key} from localStorage”:`, error)
      return initialValue
    }
  }, [initialValue, key])

  /**
   * NOTE: Since the readValue argument to useState is a function, React will only run it
   *       on mount. This is nice because reading from localStorage can be expensive.
   */
  const [storedValue, setStoredValue] = useState<IStoredValue>(readValue)

  const setValueRef = useRef<SetValue<IStoredValue>>()

  setValueRef.current = (value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      )
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(newValue))

      setStoredValue(newValue)
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error)
    }
  }

  /**
   * Returns a referentially stable version of the setter function.
   */
  const setValue: SetValue<IStoredValue> = useCallback(
    (value) => setValueRef.current?.(value),
    []
  )

  useEffect(() => {
    setStoredValue(readValue())
  }, [])

  return [storedValue, setValue]
}

/**
 * A wrapper around JSON.parse to handle paring 'undefined' correctly
 *
 * @param value to be parsed from a JSON string
 * @returns
 */
function parseJSON<IStoredValue>(
  value: string | null
): IStoredValue | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    console.warn('parsing error on', { value })
    return undefined
  }
}
