import * as React from 'react'
import { useEffectOnce } from 'react-use'
import * as R from 'remeda'
import {
  Subject,
  BehaviorSubject,
  scan,
  distinctUntilChanged,
  shareReplay,
} from 'rxjs'

import { useUniformContext } from '../context'
import type { InitialOptions, UniformAny, UniformFieldOption } from '../types'

type SubjectProps = {
  id: string
  option?: UniformFieldOption
}

type StoreProps = {
  [k: string]: UniformFieldOption | UniformFieldOption[] | undefined
}

export function useSelectedOptions(
  initialOptions: InitialOptions<string> = {}
) {
  const [store$] = React.useState(
    () => new BehaviorSubject<StoreProps>(initialOptions)
  )
  const [event$] = React.useState(() => new Subject<SubjectProps>())

  useEffectOnce(() => {
    const storeInitialValue = store$.getValue()

    const sub = event$
      .pipe(
        scan((acc, value) => {
          if (!value.id) return acc
          return { ...acc, [value.id]: value.option }
        }, storeInitialValue),
        distinctUntilChanged((a, b) => R.isDeepEqual(a, b))
      )
      .subscribe(store$)

    return () => sub.unsubscribe()
  })

  return {
    send: (props: SubjectProps) => {
      event$.next(props)
    },
    subscribe: (callback: (value: StoreProps) => void) => {
      return store$.pipe(shareReplay()).subscribe(callback)
    },
    latestFieldValue: <
      T extends Record<string, UniformAny> = Record<string, UniformAny>
    >(
      fieldId: string
    ): T | undefined => {
      const result = store$.getValue()
      if (!fieldId) return undefined
      return result[fieldId] as unknown as T
    },
    store$,
  }
}

export function useSelectedOptionValue<T extends UniformFieldOption>(
  fieldId: string
) {
  const { selectedOptions } = useUniformContext()
  const [state, setState] = React.useState<T | undefined>(() =>
    selectedOptions.latestFieldValue<T>(fieldId)
  )

  useEffectOnce(() => {
    const sub = selectedOptions.subscribe((value) => {
      setState(value[fieldId] as T)
    })
    return () => sub.unsubscribe()
  })

  return state
}
