import { Theme as DefaultTheme, ThemeProvider } from '@emotion/react'
import {
  Theme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import { ReactNode } from 'react'

import { muiTheme } from '@cais-group/shared/ui/mui'

/**
 * Supplies mui theme to both MUI and emotion
 * Also uses injectFirst to ensure that MUI injects its styles before
 * emotion, allowing us to override MUI components styles.
 *
 * The way the mui and emotion share a theme is by merging them,
 * so emotion will receive a merge of whatever is supplied to
 * each provider.
 */

export type ThemeProvidersProps = {
  children: ReactNode
  secondaryTheme?: Theme | Partial<DefaultTheme>
}

export function ThemeProviders({
  children,
  secondaryTheme = muiTheme,
}: ThemeProvidersProps) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={secondaryTheme}>{children}</ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProviders
