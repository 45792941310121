import styled from '@emotion/styled'
import dayjs from 'dayjs'

import { H5, SubtitleMono } from '@cais-group/approved/ui/typography'
import { ArticleHeader, PageType } from '@cais-group/caisiq/ui/article/header'
import { Author } from '@cais-group/caisiq/ui/author'
import { ContentStack } from '@cais-group/caisiq/ui/content-stack'
import { Footnotes } from '@cais-group/caisiq/ui/footnotes'
import { RichText } from '@cais-group/caisiq/ui/get-rich-text-render-options'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { colors } from '@cais-group/equity/particles/colors'
import { toProperCase } from '@cais-group/equity/utilitarian'
import { CardDownload } from '@cais-group/shared/ui/card/download'
import { LayoutCenter, LayoutStack } from '@cais-group/shared/ui/layout'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

const StyledCenter = styled(LayoutCenter)`
  display: grid;
  grid-template-columns: clamp(16rem, calc(96vw - 2 * var(--s24)), 104rem) 1fr;

  article > p:first-of-type {
    margin-top: var(--s56);
  }
  @media screen and (max-width: 775px) {
    h5 {
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }
`

const PageContainer = styled(LayoutStack)`
  background-color: ${colors['eq-color-neutral-0']};
  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

const DownloadCardContainer = styled.div`
  margin-top: var(--s56);
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: var(--s56);
  margin-top: var(--s56);

  @media screen and (min-width: 960px) {
    width: 100%;
    padding-left: var(--s56);
    padding-right: var(--s56);
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

const AuthorContainer = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    margin-top: var(--s56);
    margin-right: var(--s56);
    display: inline;
    width: 320px;
    margin-left: -28px;
    > * {
      margin-bottom: var(--s24);
    }
  }
  @media screen and (min-width: 1280px) {
    width: 320px;
    margin-left: var(--s88);
  }
`

const AuthorContainerTitle = styled(SubtitleMono)`
  color: rgb(var(--colors-neutral-600));
  border: solid rgb(var(--colors-neutral-300));
  border-width: 1px 0;
  padding: calc(var(--s24) - var(--s4)) 0;
  margin-bottom: var(--s32);
`

const BodyAuthor = styled.div`
  display: inline;
  > * {
    margin-bottom: var(--s24);
  }
  @media screen and (min-width: 1024px) {
    display: none;
  }
`
const Introduction = styled(H5)<{ hasFullAuthor: boolean }>`
  ${({ hasFullAuthor }) => (hasFullAuthor ? `margin-top: 44px` : '0')};
  margin-bottom: var(--s24);
`

const SEGMENT_PAGE_ID = 'whitepaper'

export type WhitepaperPageProps = {
  slug?: string
  whitepaper: WhitepaperData
}

function WhitepaperPage({ slug, whitepaper }: WhitepaperPageProps) {
  const {
    thumbnail,
    hero,
    title,
    introduction,
    author,
    displayDate,
    sys,
    body,
    pdfsCollection,
    footnotes,
    type,
  } = whitepaper
  const date = dayjs(displayDate || sys.firstPublishedAt).format('MMM D')
  const hasRichText = body && body?.json
  const hasFullAuthor = Boolean(author.authorName)

  const typePage = type ? (toProperCase(type) as PageType) : PageType.WHITEPAPER

  return (
    <PageContainer>
      <ArticleHeader
        image={hero?.url ? `${hero.url}?fm=jpg` : ''}
        title={title}
        author={author.name}
        date={date}
        typePage={typePage}
        hasFullAuthor={hasFullAuthor}
      />
      <StyledCenter>
        <Wrapper>
          <AuthorContainer>
            {hero?.url && hasFullAuthor && (
              <>
                <AuthorContainerTitle>Authored by</AuthorContainerTitle>
                <Author {...author} />
              </>
            )}
          </AuthorContainer>

          <ContentStack fakeColumn variant="richtext">
            {introduction && (
              <Introduction hasFullAuthor={hasFullAuthor}>
                {introduction}
              </Introduction>
            )}
            {hasRichText && (
              <RichText field={body} segmentPageId={SEGMENT_PAGE_ID} />
            )}

            <BodyAuthor>
              {hero?.url && hasFullAuthor && (
                <>
                  <AuthorContainerTitle>Authored by</AuthorContainerTitle>
                  <Author {...author} />
                </>
              )}
            </BodyAuthor>
            <DownloadCardContainer>
              {/* TODO(SW): How many download cards can we have max? */}
              {pdfsCollection?.items.map(
                (file) =>
                  file && (
                    <CardDownload
                      key={file.sys.id}
                      image={thumbnail?.url ? `${thumbnail.url}?fm=jpg` : ''}
                      title={title}
                      date={date}
                      file={file.pdf?.url || ''}
                      onClick={() => {
                        trackingService.whitepaperDownloadClicked(
                          title,
                          file.title || null
                        )
                      }}
                    />
                  )
              )}
            </DownloadCardContainer>
            {footnotes?.json && (
              <Footnotes>
                <RichText field={footnotes} segmentPageId={SEGMENT_PAGE_ID} />
              </Footnotes>
            )}
          </ContentStack>
        </Wrapper>
      </StyledCenter>
    </PageContainer>
  )
}

WhitepaperPage.displayName = 'WhitepaperPage'

export { WhitepaperPage }
