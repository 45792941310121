import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { ButtonClose } from '@cais-group/shared/ui/buttons'
import { LayoutStack, LayoutBox } from '@cais-group/shared/ui/layout'

export type NotificationProps = {
  onClose: () => void
  id: string
  children: ReactNode
}

const Container = styled(LayoutBox)`
  background-color: rgb(var(--colors-neutral-0));
  padding: var(--s32) var(--s56);
  position: relative;
`

const StyledCloseButton = styled(ButtonClose)`
  position: absolute;
  right: var(--s24);
  top: var(--s24);
`

export const Notification = ({ children, id, onClose }: NotificationProps) => {
  return (
    <Container data-testid={`notification-${id}`}>
      <StyledCloseButton onClick={onClose} />
      <LayoutStack>{children}</LayoutStack>
    </Container>
  )
}
