import styled from '@emotion/styled'
import { ForwardedRef, forwardRef, ReactNode } from 'react'

import { ButtonClose } from '@cais-group/shared/ui/buttons'
import { LayoutBox, LayoutStack } from '@cais-group/shared/ui/layout'

export type CardDismissableProps = {
  children?: ReactNode
  topAligned?: boolean
  className?: string
  onClose: () => void
  ref?: ForwardedRef<HTMLDivElement>
  header?: ReactNode
}

export const Card = styled(LayoutBox)`
  background-color: rgb(var(--colors-neutral-0));
`

const TopAlignedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

const RightAlignedCloseButton = styled(ButtonClose)`
  align-self: flex-end;
  position: absolute;
  right: var(--s16);
`

/**
 * A simple card with a close button in the top right corner
 *
 * @topAligned if true the content will align top close to the close button, if false the content will start below the close button
 */
export const CardDismissable = forwardRef<HTMLDivElement, CardDismissableProps>(
  (props, ref) => {
    const { children, onClose, className, topAligned, header } = props
    return (
      <Card
        ref={ref}
        className={className}
        padding={header ? '0' : 'var(--s16)'}
      >
        {topAligned ? (
          <TopAlignedContainer>
            <ButtonClose onClick={onClose} />
            <div>{children}</div>
          </TopAlignedContainer>
        ) : (
          <LayoutStack>
            <>
              {header && <span>{header}</span>}
              <RightAlignedCloseButton
                color={
                  header
                    ? 'rgb(var(--colors-neutral-0))'
                    : 'rgb(var(--colors-neutral-400))'
                }
                onClick={onClose}
              />
            </>
            {children}
          </LayoutStack>
        )}
      </Card>
    )
  }
)
