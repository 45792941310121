import { useFormContext } from 'react-hook-form'

import { H5Strong } from '@cais-group/approved/ui/typography'
import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import { FirmCheckListItem } from '@cais-group/caisiq/ui/form/firm-check-list-item'
import {
  ExperienceFields,
  FormStepProps,
} from '@cais-group/caisiq/util/type/manage-caisiq'
import { SearchableDropdownList } from '@cais-group/shared/ui/filter-list'
import {
  OrderedFieldset,
  StepNavigation,
  useStepState,
} from '@cais-group/shared/ui/ordered-fieldset'
import { SelectableListItem } from '@cais-group/shared/ui/selectable-list'
import {
  ApiPaths,
  CaisIqFirm,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'

import { useFirmChecklist } from '../hooks/use-firm-checklist'
import { useInfiniteQueryWithDebouncedSearch } from '../hooks/use-infinite-query-with-debounced-search'

interface FirmSelectorItem extends SelectableListItem<string> {
  parentFirmName: string
  existingExperienceName: string
}

type FirmSelectionStepType = FormStepProps & {
  onRemoveUpdateFirm: (firmId: string, checked: boolean) => void
}
export function FirmSelectionStep({
  order,
  onRemoveUpdateFirm,
}: FirmSelectionStepType) {
  const { watch } = useFormContext<ExperienceFields>()

  const { deriveState, showStep } = useStepState()
  const { data: firmData, setSearchText } =
    useInfiniteQueryWithDebouncedSearch<CaisIqFirm>(ApiPaths.searchFirms)

  const handleUpdate = useFirmChecklist(firmData)

  if (watch('experienceType') === 'DIRECT') {
    return null
  }

  const firmOptions = firmData.map((firm) => ({
    label: firm.name,
    value: firm.id,
    parentFirmName: firm?.parent?.name,
    existingExperienceName: firm?.experience?.name,
  }))

  return (
    <OrderedFieldset
      legend={
        <H5Strong id="firms" className="scroll-mt-144">
          Firms
        </H5Strong>
      }
      state={deriveState(order)}
    >
      {showStep(order) && (
        <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
          <HasPermissions.Then>
            <SearchableDropdownList<FirmSelectorItem, string>
              name="firmIds"
              id="firm-search"
              items={firmOptions as unknown as FirmSelectorItem[]}
              onUpdate={handleUpdate}
              setSearchText={setSearchText}
              renderListItem={({
                item,
                onChange: onChangeHandler,
                selectedItems,
              }) => (
                <FirmCheckListItem
                  key={item.value}
                  label={item.label}
                  value={item.value}
                  checked={selectedItems.includes(item.value)}
                  parentFirmName={item.parentFirmName}
                  existingExperienceName={item.existingExperienceName}
                  onChange={() => {
                    const wasPreviouslySelected = selectedItems.includes(
                      item.value
                    )
                    onChangeHandler({
                      selectedItems,
                      item,
                    })
                    handleUpdate(item.value, wasPreviouslySelected)

                    onRemoveUpdateFirm(item.value, wasPreviouslySelected)
                  }}
                />
              )}
            />
          </HasPermissions.Then>
          <HasPermissions.Else>
            <p className="bodySmall mb-0 text-neutral-600">
              Assigned to the following firms
            </p>
          </HasPermissions.Else>
        </HasPermissions>
      )}
      <StepNavigation order={order} />
    </OrderedFieldset>
  )
}
