import { useEffect, useRef, useState } from 'react'

/** We may want to for example show a tooltip or similar when some text is truncated.
 * This encapsulates the standard logic to figure out when that is happening.
 *
 * Note while this seems to work reliably in many cases it is possible to set up CSS
 * that won't lead to a difference in offsetWidth and scrollWidth; in which case this
 * will not work. Please check carefully in your case.
 *
 * The use of >= internally seems to be compatible with the flexbox case, but may not be
 * correct in edge cases elsewhere.
 **/
export function useIsTruncated<ElementType extends HTMLElement>() {
  const [disabled, setDisabled] = useState(false)
  const ref = useRef<ElementType>(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) {
      setDisabled(ref.current.offsetWidth >= ref.current.scrollWidth)
    }
  })

  return { disabled, ref }
}
