import { MutableRefObject, useCallback, useEffect } from 'react'

const targetNotContained = <T extends Element>(
  ref: MutableRefObject<T>,
  target: T
) => !ref.current?.contains(target)

export const useOnClickOutside = <T extends Element>(options: {
  callback: () => void
  ref: MutableRefObject<T>
}) => {
  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Element
      if (targetNotContained(options.ref, target)) {
        options.callback()
      }
    },
    [options]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [handleMouseDown])
}
