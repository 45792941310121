import { useRecoilState } from 'recoil'

import { useFilteredArticles } from '@cais-group/caisiq/domain/article'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'

import { Articles } from './caisiq-feature-articles'

export const ArticlesContainer = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)

  const maybeSearchFilteredArticles = useFilteredArticles()

  const maybeSearchFilteredArticlesWithoutFeatured =
    maybeSearchFilteredArticles?.filter(
      (maybeArticle) =>
        maybeArticle.sys.id !==
        userSettingsService.theme.featuredArticle?.sys.id
    )

  const maybeFeaturedArticle = maybeSearchFilteredArticles?.find(
    (maybeArticle) =>
      maybeArticle.sys.id === userSettingsService.theme.featuredArticle?.sys.id
  )

  return (
    <Articles
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      maybeSearchFilteredArticles={maybeSearchFilteredArticles}
      maybeSearchFilteredArticlesWithoutFeatured={
        maybeSearchFilteredArticlesWithoutFeatured
      }
      maybeFeaturedArticle={maybeFeaturedArticle}
      experience={userSettingsService.theme}
    />
  )
}
