import {
  useState,
  createContext,
  useContext,
  useCallback,
  useLayoutEffect,
} from 'react'
import type { PropsWithChildren } from 'react'

import { UniformAny } from '../types'

import { ModalRoot } from './modalRoot'
import type { ModalComponents } from './modalRoot'

// NOTE: at some point could be moved to Equity

export type ModalProps = {
  [K in keyof ModalComponents]: React.ComponentProps<ModalComponents[K]>
}
export type ModalType = keyof ModalProps
type OpenModal = <T extends ModalType>(
  modalType: T,
  modalProps: ModalProps[T],
  finalFocusRef?: HTMLElement | null
) => (
  evt?:
    | { currentTarget: HTMLElement | null }
    | Pick<React.FocusEvent<HTMLElement>, 'currentTarget'>
    | React.MouseEvent<HTMLButtonElement, MouseEvent>
) => void

interface ContextProps extends ModalProviderState {
  openModal: OpenModal
  closeModal: () => void
}

const ModalContext = createContext<ContextProps>({
  isOpen: false,
  modalType: null,
  modalProps: {},
  openModal: () => () => {},
  closeModal: () => {},
})

const defaultState: ModalProviderState = {
  isOpen: false,
  modalType: null,
  modalProps: {},
}

interface ModalProviderState {
  isOpen: boolean
  modalType: keyof ModalComponents | null
  modalProps?: Record<string, UniformAny>
}
type ModalProviderProps = PropsWithChildren<{
  initialState?: ModalProviderState
}>
export const ModalProvider = ({
  children,
  initialState = defaultState,
}: ModalProviderProps) => {
  const [state, setState] = useState<ModalProviderState>(initialState)
  const [parentEl, setParentEl] = useState<HTMLElement | null>(null)

  const openModal: OpenModal = useCallback(
    (modalType, modalProps, finalFocusRef) => (evt) => {
      if (finalFocusRef) {
        setParentEl(finalFocusRef)
      } else {
        setParentEl(evt?.currentTarget as HTMLElement)
      }
      setState({
        isOpen: true,
        modalType,
        modalProps,
      })
    },
    []
  )

  const closeModal = useCallback(() => {
    setState(defaultState)
  }, [])

  useLayoutEffect(() => {
    if (!state.isOpen && parentEl) {
      parentEl.focus()
      setParentEl(null)
    }
  }, [state, parentEl, setParentEl])

  const value = {
    ...state,
    openModal,
    closeModal,
  }

  return (
    <ModalContext.Provider value={value}>
      <ModalRoot />
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const utils = useContext(ModalContext)
  if (!utils) {
    throw new Error(
      `Modal compound components cannot be rendered outside the ModalProvider component`
    )
  }
  return utils
}
