import { Control, Controller } from 'react-hook-form'

import { useFilterThemeOptions } from '@cais-group/caisiq/domain/manage-caisiq'
import { SelectBoxAutocomplete } from '@cais-group/shared/ui/select-box-autocomplete'

import { ModalFields } from '../caisiq-ui-manage-update-course-modal'

export const GroupModalBody = ({
  control,
}: {
  control: Control<ModalFields, object>
}) => {
  const selectOptions = useFilterThemeOptions()

  return (
    <div className="mx-0 my-32 flex w-[calc(100%-(theme('spacing.232')))] flex-wrap justify-between first-of-type:mr-0 [&>*]:basis-full">
      <Controller
        name="group"
        control={control}
        render={({ field }) => {
          return (
            <SelectBoxAutocomplete
              id="group"
              freeSolo={true}
              label="Group name"
              value={field.value}
              onChange={field.onChange}
              onInputChange={field.onChange}
              onBlur={field.onBlur}
              selectOptions={selectOptions ?? []}
            />
          )
        }}
      />
    </div>
  )
}
