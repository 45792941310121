// A hook that fetches all the themes from the all course base endpoints and reduces down to a unique list of themes

import { useQuery } from 'react-query'
import { concat } from 'remeda'

import { SelectOption } from '@cais-group/shared/ui/select-box'
import {
  CourseMetadata,
  ApiPaths,
} from '@cais-group/shared/util/type/caisiq-be'

export const useFilterThemeOptionsForExperience = (experienceId?: string) => {
  const { data: baseConfigs, isFetched } = useQuery<CourseMetadata[]>(
    ApiPaths.getAllBaseCourseConfigs
  )

  const { data: overrideConfigs, isFetched: coursesFetched } = useQuery<
    CourseMetadata[]
  >(
    ApiPaths.getAllCourseMetadataPerExperience.replace(
      ':experienceId',
      experienceId || ''
    ),
    {
      enabled: Boolean(experienceId),
    }
  )

  const allConfigs =
    !isFetched || !coursesFetched
      ? []
      : concat(baseConfigs ?? [], overrideConfigs ?? []).map(
          (config) => config.theme
        )

  const selectOptions = allConfigs?.reduce(
    (acc: Array<SelectOption>, theme) => {
      if (theme && !acc.find((item) => item.value === theme)) {
        acc.push({
          label: theme,
          value: theme,
        })
      }

      return acc
    },
    []
  )

  return selectOptions
}

export const useFilterThemeOptions = () => {
  const { data: baseConfigs } = useQuery<CourseMetadata[]>(
    ApiPaths.getAllBaseCourseConfigs
  )

  const selectOptions = baseConfigs?.reduce(
    (acc: Array<SelectOption>, { theme }) => {
      if (theme && !acc.find((item) => item.value === theme)) {
        acc.push({
          label: theme,
          value: theme,
        })
      }

      return acc
    },
    []
  )

  return selectOptions
}
