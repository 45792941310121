import '@cais-group/shared/ui/css'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import ReactDOM from 'react-dom/client'
import '@cais-group/shared/util/tailwind'

import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import {
  CaisiqEnvConfig,
  EnvConfigContainerProvider,
} from '@cais-group/caisiq-ui-env'
import { APPS } from '@cais-group/shared/domain/apps'

import App from './app/App'

const onConfigLoaded = (config: CaisiqEnvConfig) => {
  trackingService.initialise(config.SEGMENT_API_KEY, APPS.CAIS_IQ)
  dataDogInitialise(config)
}

const dataDogInitialise = ({
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  ENVIRONMENT,
}: CaisiqEnvConfig) => {
  const appVersion = __NX_BUILD_VERSION__ ?? 'unknown'
  if (__NX_BUILD_MODE__ === 'production') {
    datadogRum.init({
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'caisiq',
      version: appVersion,
      env: ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      // Suggested default, not available in our version of sdk (at least types); we are on 2.8.1, latest is 4.4.something
      // defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: window.location.origin
        .toString()
        .includes('localhost')
        ? undefined
        : [window.location.origin],
    })
  }

  const ignoredErrorMessages = ['Login required']

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    forwardErrorsToLogs: false,
    site: 'datadoghq.com',
    version: appVersion,
    service: 'caisiq',
    env: ENVIRONMENT,
    beforeSend: (log) => {
      if (
        typeof log.error?.['message'] === 'string' &&
        ignoredErrorMessages.includes(log.error['message'])
      ) {
        return false
      }
      return true
    },
  })

  // This will get overwritten by the sub-apps as they load
  datadogLogs.setGlobalContext({ appName: APPS.CAIS_IQ })

  // log to console in localhost
  // log to datadog in all other envs
  // do not log anywhere in unit tests
  if (ENVIRONMENT === 'localhost') {
    datadogLogs.logger.setHandler('console')
  } else if (__NX_BUILD_MODE__ === 'test') {
    datadogLogs.logger.setHandler('silent')
  } else {
    datadogLogs.logger.setHandler('http')
  }
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <EnvConfigContainerProvider initializers={onConfigLoaded}>
    <App />
  </EnvConfigContainerProvider>
)
