import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { ModalLayout } from '@cais-group/approved/ui/modal/layout'
import { CourseData } from '@cais-group/caisiq/util/type/manage-caisiq'
import { FormStepProvider } from '@cais-group/shared/ui/ordered-fieldset'
import { moreExactPercentageFormatter } from '@cais-group/shared/util/number'

import { createCourseSchema } from './utils'
import { buildUpdateCourseModal } from './utils/buildUpdateCourseModal'

type ModalCourseData = Pick<
  CourseData,
  'cfp' | 'cima' | 'group' | 'requiredScore' | 'id'
>
export type AccessorKeys = keyof Omit<ModalCourseData, 'id'>

/**
 * @todo Not sure if we plan to pass the course data or if we want to do something like we do with the course detail modal
 *  onEditCell={(course) => {
 *  openModal(course)
 *  }}
 *
 * where openModal comes from a useModal hook as does closeModal and then we call the modal hook to retreat the course state
 *  It's a little unclear on the flow of submitting the data and calling the api. Will onSubmit just store the new formData or does it do the call?
 */
export type UpdateCourseModalProps = {
  open: boolean
  closeModal: () => void
  course: ModalFields
  accessorKey: AccessorKeys
  onSubmit: (data: ModalFields) => void
}

export type ModalFields = {
  [key in keyof Omit<ModalCourseData, 'id'>]?: ModalCourseData[key]
}

export const UpdateCourseModal = ({
  open,
  closeModal,
  accessorKey,
  course,
  onSubmit,
}: UpdateCourseModalProps) => {
  const methods = useForm<ModalFields>({
    mode: 'onBlur',
    defaultValues: mapCourseToFormValues(course),
    resolver: yupResolver(createCourseSchema),
  })

  const { title, body } = buildUpdateCourseModal({
    accessorKey,
    methods,
  })
  const { formState, reset } = methods

  const { isValid } = formState

  return (
    <FormStepProvider stateless={true}>
      <FormProvider {...methods}>
        <ModalLayout
          $renderFullHeight
          title={title}
          open={open}
          onClose={() => {
            closeModal()
            reset()
          }}
          actions={[
            {
              text: 'Cancel',
              handler: () => {
                closeModal()
                reset()
              },
              variant: 'secondary',
            },
            {
              text: 'Save',
              handler: () => {
                onSubmit(methods.getValues())
                methods.handleSubmit(onSubmit)
              },
              variant: 'primary',
              disabled: !isValid,
            },
          ]}
          body={
            <form
              id="form-area"
              onSubmit={(e) => {
                e.preventDefault()
                methods.handleSubmit(onSubmit)
              }}
            >
              {body}
            </form>
          }
        />
      </FormProvider>
    </FormStepProvider>
  )
}

const mapCourseToFormValues = (
  c: Pick<ModalFields, AccessorKeys>
): ModalFields => ({
  cfp: c.cfp || 0,
  cima: c.cima || 0,
  group: c.group || '',
  requiredScore:
    Number(
      moreExactPercentageFormatter({
        value: c.requiredScore,
        decimalPlaces: 0,
      }).replace('%', '')
    ) || 0,
})
