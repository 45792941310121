import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MuiButton from '@mui/material/Button'
import MuiButtonBase from '@mui/material/ButtonBase'
import MuiButtonGroup, {
  ButtonGroupProps as MuiButtonGroupProps,
} from '@mui/material/ButtonGroup'
import Menu from '@mui/material/Menu'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

import { TrackingProps } from '@cais-group/shared/util/segment'

export type ButtonGroupRadioProps<Value extends string = string> = Omit<
  MuiButtonGroupProps,
  'onChange'
> & {
  buttons: Value[]
  value: Value
  onChange: (button: Value) => void
  getLabel?: (value: Value) => string
} & TrackingProps

export function ButtonGroupRadio<Value extends string>({
  buttons,
  value,
  onChange,
  getLabel = (value) => value,
  ...props
}: ButtonGroupRadioProps<Value>) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  })
  const unsortedButtons = [...buttons]
  const [firstButton, ...remainingButtons] = [...buttons].sort((text) =>
    text === value ? -1 : 1
  )

  if (value && buttons.indexOf(value) === -1) {
    throw new Error('value must be one of buttons array')
  }

  return (
    <MuiButtonGroup variant="contained" size="small" {...props}>
      {isDesktop ? (
        unsortedButtons.map((text) => (
          <MuiButton
            key={text}
            data-track-event={props['data-track-event']}
            data-track-item-name={getLabel(text)}
            onClick={() => onChange(text)}
            data-testid="button-group-radio-button"
            color={text === value ? 'black' : 'neutral'}
            variant="contained"
          >
            {getLabel(text)}
          </MuiButton>
        ))
      ) : (
        <PopupState variant="popover" popupId="button-group-radio-popup-menu">
          {(popupState) => (
            <>
              <div style={{ color: 'black' }}>
                <MuiButtonBase
                  {...bindTrigger(popupState)}
                  key={firstButton}
                  data-testid="button-group-radio-button"
                  color="black"
                  sx={{
                    width: 120,
                    display: 'block',
                    textAlign: 'left',
                    color: 'black !important',
                  }}
                >
                  <span>{getLabel(firstButton)}</span>
                  <ArrowDropDownIcon
                    color="inherit"
                    sx={{
                      verticalAlign: 'middle',
                      float: 'right',
                      color: 'black !important',
                    }}
                  />
                </MuiButtonBase>
              </div>
              <Menu {...bindMenu(popupState)}>
                {remainingButtons.map((text) => (
                  <MuiButton
                    {...bindTrigger(popupState)}
                    key={text}
                    data-testid="button-group-radio-button-in-menu"
                    color="neutral"
                    variant="contained"
                    sx={{ width: 120, display: 'block', textAlign: 'left' }}
                    onClick={() => {
                      popupState.close()
                      onChange(text)
                    }}
                  >
                    {getLabel(text)}
                  </MuiButton>
                ))}
              </Menu>
            </>
          )}
        </PopupState>
      )}
    </MuiButtonGroup>
  )
}

export default ButtonGroupRadio
