import { BreakpointsOptions } from '@mui/material/styles'

import { breakpoints as EquityBreakpoints } from '@cais-group/equity/particles/breakpoints'

export const breakpoints: BreakpointsOptions = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 0,
    ...EquityBreakpoints,
  },
  unit: 'px',
}
