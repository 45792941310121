import { ModalType } from '@cais-group/caisiq/util/type/manage-caisiq'

import { EnableDisableExperienceModalType } from '../caisiq-ui-modal-enable-disable-experience'

export const buildEnableDisableModalDetails = (
  experience: string,
  type?: ModalType
): EnableDisableExperienceModalType => {
  switch (type) {
    case 'create':
      return {
        summary: {
          title: `Would you like to enable ${experience}?`,
          body: `All assigned firms will receive the experience`,
        },
        primaryButton: {
          text: 'Yes, enable',
          variant: 'primary',
        },
        secondaryButton: {
          text: 'Create experience without enabling',
        },
      }

    case 'disable': {
      return {
        summary: {
          title: `Are you sure you want to disable ${experience}?`,
          body: `By disabling this experience, users will no longer have access to their existing courses and progress. They will receive the default experience.`,
        },
        primaryButton: {
          text: 'Yes, disable',
          variant: 'danger',
        },
        secondaryButton: {
          text: 'Cancel',
        },
      }
    }
    default:
      return {
        summary: {
          title: `Are you sure you want to enable ${experience}?`,
          body: `All assigned firms will receive the experience`,
        },
        primaryButton: {
          text: 'Yes, enable',
          variant: 'primary',
        },
      }
  }
}
