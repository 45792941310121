import '@cais-group/shared/ui/css'
import '@cais-group/shared/ui/fonts'
import '@cais-group/shared/ui/theme/primary'
import './modernizr'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { NotificationsManager } from '@cais-group/equity/organisms/notifications'
import { APPS } from '@cais-group/shared/domain/apps'
import { AppErrorBoundary } from '@cais-group/shared/ui/app-error-boundary'
import { EnvConfigContainerProvider } from '@cais-group/shared/ui/env'
import { ThemeProviders } from '@cais-group/shared/util/theme-providers'

import { TestBrowserSupport } from './test-browser-support'

import '@cais-group/shared/util/tailwind'

const defaultQueryClient = new QueryClient()

export type MfeContainerAppWrapperProps = {
  children: React.ReactNode
  queryClient?: QueryClient
}

/**
 * Wraps children in app level providers, error boundary and strict mode
 */
export const MfeContainerAppWrapper = ({
  children,
  queryClient,
}: MfeContainerAppWrapperProps) => {
  return (
    <AppErrorBoundary appName={APPS.CONTAINER}>
      <QueryClientProvider client={queryClient ?? defaultQueryClient}>
        <EnvConfigContainerProvider
          initializers={(config) => {
            const appVersion = __NX_BUILD_VERSION__ ?? 'unknown'
            if (__NX_BUILD_MODE__ === 'production') {
              datadogRum.init({
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                applicationId: config.DATADOG_APPLICATION_ID,
                clientToken: config.DATADOG_CLIENT_TOKEN,
                site: 'datadoghq.com',
                service: 'caisapp',
                version: appVersion,
                env: config.ENVIRONMENT,
                // Specify a version number to identify the deployed version of your application in Datadog
                // version: '1.0.0',
                sessionSampleRate: 100,
                // Suggested default, not available in our version of sdk (at least types); we are on 2.8.1, latest is 4.4.something
                // defaultPrivacyLevel: 'mask-user-input',
                allowedTracingUrls: window.location.origin
                  .toString()
                  .includes('localhost')
                  ? undefined
                  : [window.location.origin],
              })
            }

            const ignoredErrorMessages = ['Login required']

            datadogLogs.init({
              clientToken: config.DATADOG_CLIENT_TOKEN,
              forwardErrorsToLogs: false,
              site: 'datadoghq.com',
              version: appVersion,
              service: 'caisapp',
              env: config.ENVIRONMENT,
              beforeSend: (log) => {
                if (
                  typeof log.error?.['message'] === 'string' &&
                  ignoredErrorMessages.includes(log.error['message'])
                ) {
                  return false
                }
                return true
              },
            })

            // This will get overwritten by the sub-apps as they load
            datadogLogs.setGlobalContext({ appName: APPS.CONTAINER })

            // log to console in localhost
            // log to datadog in all other envs
            // do not log anywhere in unit tests
            if (config.ENVIRONMENT === 'localhost') {
              datadogLogs.logger.setHandler('console')
            } else if (__NX_BUILD_MODE__ === 'test') {
              datadogLogs.logger.setHandler('silent')
            } else {
              datadogLogs.logger.setHandler('http')
            }
          }}
        >
          <div
            id="tt-widget"
            style={{
              display: 'none',
            }}
          ></div>
          <TestBrowserSupport />
          <ThemeProviders>
            <NotificationsManager />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {children}
            </LocalizationProvider>
          </ThemeProviders>
        </EnvConfigContainerProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AppErrorBoundary>
  )
}
