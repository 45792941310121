import { UserProfileV1 } from '@cais-group/access-manager/domain/api'

import { useUserProfile } from './shared-util-hook-use-user-profile'

export type InferElementType<ArrayType> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

type HasUserPersonasType =
  | {
      personas: UserProfileV1['personas']

      strategy: 'AND' | 'OR'
    }
  | {
      personas: InferElementType<UserProfileV1['personas']>
      strategy: 'ONLY'
    }

export const useHasUserPersonas = ({
  personas,
  strategy,
}: HasUserPersonasType) => {
  const { userProfileState } = useUserProfile()
  const { userProfile } = userProfileState

  const userPersonas = userProfile?.personas || []

  if (strategy === 'ONLY') {
    return userPersonas.length === 1 && userPersonas.includes(personas)
  }
  if (strategy === 'AND') {
    return personas?.every((persona) => userPersonas.includes(persona))
  }
  return !!userPersonas?.some((persona) => personas?.includes(persona))
}
