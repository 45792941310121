import cx from 'classnames'
import { StrictExtract } from 'ts-essentials'

import {
  EquitySemanticColor,
  TailwindEquityColor,
} from '@cais-group/equity/particles/colors'

export type DotTagProps = {
  /** Optional property to set which Equity color to be used  */
  color?: SemanticColor
  /** Which variant to use */
  variant?: DotTagVariant
  /** The string to display next to the dot */
  children?: string
}

export type DotTagVariant = 'regular' | 'light'

export type SemanticColor = StrictExtract<
  EquitySemanticColor,
  'neutral' | 'primary' | 'accent' | 'success' | 'error' | 'warning'
>

const backgroundColorMap: Record<
  DotTagVariant,
  Record<SemanticColor, TailwindEquityColor>
> = {
  regular: {
    neutral: 'bg-neutral-600',
    primary: 'bg-primary-600',
    accent: 'bg-accent-600',
    success: 'bg-success-600',
    error: 'bg-error-600',
    warning: 'bg-warning-600',
  },
  light: {
    neutral: 'bg-neutral-300',
    primary: 'bg-primary-300',
    accent: 'bg-accent-300',
    success: 'bg-success-300',
    error: 'bg-error-300',
    warning: 'bg-warning-300',
  },
}

const getBackgroundColor = ({
  variant,
  color,
}: Required<Omit<DotTagProps, 'children'>>): string => {
  return backgroundColorMap[variant][color]
}

/**
 * A dot tag is a read-only label that appears beside an item to represent the status or metadata for that area.
 */
export const DotTag = ({
  color = 'primary',
  variant = 'regular',
  children,
}: DotTagProps) => {
  return (
    <div className="flex items-center justify-center gap-x-8">
      <div
        className={cx(
          'h-8 w-8 rounded-full',
          getBackgroundColor({ color, variant })
        )}
      />
      {children ? <div className="bodySmall">{children}</div> : null}
    </div>
  )
}
