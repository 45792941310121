import { Color, TailwindEquityColor } from '@cais-group/equity/particles/colors'
import { IconType } from '@cais-group/equity/particles/icons'

import { ToastType } from './types'

export const toastTypeToBackgroundColorMap: Record<
  ToastType,
  TailwindEquityColor
> = {
  error: 'bg-error-700',
  success: 'bg-success-700',
  info: 'bg-primary-700',
}

export const toastTypeToIconMap: Record<ToastType, IconType> = {
  error: 'Error',
  success: 'Check',
  info: 'Info',
}

export const toastTypeToIconColorMap: Record<ToastType, Color> = {
  error: 'eq-color-error-200',
  success: 'eq-color-success-200',
  info: 'eq-color-primary-200',
}
