import { useSearchParams } from 'react-router-dom'

import { useHasAttribute } from '@cais-group/shared/ui/feature-flag'

export function useUniformParamFlags() {
  const [searchParams] = useSearchParams()
  const debuggerEnabled = searchParams.get('debugger') === '1'
  const profileLiteAllTagsEnabled = searchParams.get('alltags') === '1'

  return { debuggerEnabled, profileLiteAllTagsEnabled }
}

/**
 * When the profile-lite-enabled feature flag is enabled, this hook will return the orderId from the URL search params.
 * TODO: This hook should be removed once the profile-lite-enabled feature flag is removed.
 */
export const useProfileLiteOrderId = (): string | null => {
  const { hasAccess: profileLiteEnabled } = useHasAttribute(
    'profile-lite-enabled'
  )

  const [searchParams] = useSearchParams()
  return profileLiteEnabled ? searchParams.get('orderId') : null
}
