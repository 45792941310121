import { useQuery } from 'react-query'

import {
  ApiError,
  ApiStateEnum,
  useReactQueryResultAsApiState,
  isData,
  isError,
} from '@cais-group/shared/domain/contentful/api'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'

import { fetchInstance } from './fetch-instance'

type Data = {
  data: TypeArticleData | null
  existsWithNoAccess: boolean
  notFound: boolean
} | null

export const useGetArticleBySlugBff = (slug?: string) => {
  // TODO HP-537 not sure we need to use the useReactQueryResultAsApiState function
  // - we still want the loading and error states to be handled by the useQuery hook

  const response = useReactQueryResultAsApiState<Data, Data>(
    useQuery(
      ['getArticleBySlug', slug],
      () =>
        fetchInstance<TypeArticleData>({
          url: `/article/${slug}`,
        }),
      {
        refetchOnWindowFocus: false,
      }
    ),
    (data) => data,
    'Could not load articleBySlugQuery'
  )

  const loading = response === ApiStateEnum.LOADING

  const error = isError(response)

  return compileResults(response, loading, error)
}
const compileResults = (
  data: Data | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data?.data : null,
    error,
    loading,
    // Feels like we may need to rethink this and error handling
    // Do we need to use useReactQueryResultAsApiState? Feels like it makes things trickier
    existsWithNoAccess: isData(data) && data?.existsWithNoAccess,
    notFound: isData(data) && data?.notFound,
  }
}
