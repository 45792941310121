import styled from '@emotion/styled'
import {
  SortingState,
  getSortedRowModel,
  TableOptions,
} from '@tanstack/react-table'
import { isEmpty } from 'lodash-es'
import { useState } from 'react'

import { Fallback } from '@cais-group/approved/ui/fallback'
import { usePutBaseCoursesMetadata } from '@cais-group/caisiq/domain/manage-caisiq'
import { UpdateCourseModal } from '@cais-group/caisiq/ui/manage/update-course-modal'
import { ManageCaisIqTable } from '@cais-group/caisiq/ui/table/manage'
import { useInvalidateByRegex } from '@cais-group/caisiq/util/default-query-function'
import { transformCourseMetadataRequest } from '@cais-group/caisiq/util/manage/transform-course-metadata-request'
import { ManageTableProps } from '@cais-group/caisiq/util/type/manage-caisiq'
import { Counter } from '@cais-group/equity/atoms/counter'
import { Icon } from '@cais-group/equity/atoms/icon'
import { Tooltip } from '@cais-group/equity/atoms/tooltip'
import { showToast } from '@cais-group/equity/organisms/notifications'
import { colors } from '@cais-group/equity/particles/colors'
import { ContextMenuTable } from '@cais-group/shared/ui/buttons'
import { TableContainer } from '@cais-group/shared/ui/react-table-v8'
import { CourseMetadata } from '@cais-group/shared/util/type/caisiq-be'

import { globalCoursesColumns } from './components/global-courses-columns'

const StyledTableContainer = styled(TableContainer)`
  background-color: rgb(var(--colors-neutral-100));
  box-shadow: 0px 4px 6px -6px ${colors['eq-color-neutral-400']};
`

export type ManageGlobalCoursesTableProps = Omit<
  ManageTableProps<CourseMetadata>,
  'tableOptions'
> & {
  disabled?: boolean
  tableOptions: Omit<
    TableOptions<CourseMetadata>,
    'getCoreRowModel' | 'columns'
  >
}

function useModal() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  return {
    isModalOpen,
    openModal: () => setIsModalOpen(true),
    closeModal: () => setIsModalOpen(false),
  }
}

export const ManageGlobalCoursesTable = (
  props: ManageGlobalCoursesTableProps
) => {
  const {
    searchTerm = '',
    setSearchTerm = () => {},
    tableOptions,
    fetchNextPage = () => {},
    hasNextPage = false,
    isFetched,
  } = props

  const { isModalOpen, openModal, closeModal } = useModal()

  const handleResetRowSelections = () => {
    setRowSelection({})
  }

  const [bulkActionType, setBulkActionType] = useState<
    'cfp' | 'cima' | 'group' | 'requiredScore' | undefined
  >(undefined)

  const [rowSelection, setRowSelection] = useState({})
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'fullname', desc: false },
  ])

  const invalidate = useInvalidateByRegex()

  const { mutate: mutateCoursesMetadata } = usePutBaseCoursesMetadata()

  const bulkActionsDisabled = isEmpty(rowSelection)

  const selectedCourses = Object.keys(rowSelection).map(
    (rowIndex) => props.tableOptions.data[Number(rowIndex)]
  )

  return (
    <>
      <StyledTableContainer
        data-testid="courses-table-container"
        $noScroll={true}
        className="h-full w-auto px-56 pb-56 pt-24"
      >
        <div className="bg-neutral-0 flex justify-between px-24 py-16">
          <div className="flex items-center gap-8">
            <Counter value={tableOptions.data.length} variant="light" />
            <p className="small">total courses</p>
          </div>
          <Tooltip
            title="Select one or more courses"
            placement="top"
            size="large"
          >
            <div>
              <ContextMenuTable
                actions={[
                  {
                    handler: () => {
                      openModal()
                      setBulkActionType('cfp')
                    },
                    text: 'Edit CE credits',
                  },
                  {
                    handler: () => {
                      openModal()
                      setBulkActionType('group')
                    },
                    text: 'Edit default group',
                  },
                  {
                    handler: () => {
                      openModal()
                      setBulkActionType('requiredScore')
                    },
                    text: 'Edit required score',
                  },
                ]}
                disabled={bulkActionsDisabled}
                label="Actions"
                testId="round-button-menu"
                variant="RoundButton"
              ></ContextMenuTable>
            </div>
          </Tooltip>
        </div>

        <ManageCaisIqTable<CourseMetadata>
          tableType="courses"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isFetched={isFetched}
          tableOptions={{
            enableRowSelection: true,
            enableMultiRowSelection: true,
            onSortingChange: setSorting,
            onRowSelectionChange: setRowSelection,
            state: {
              sorting: sorting,
              rowSelection: rowSelection,
            },
            columns: globalCoursesColumns,
            data: tableOptions.data,
            getSortedRowModel: getSortedRowModel(),
          }}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          noResults={
            <div className="flex h-full flex-col p-24 [&>div>div>.MuiSvgIcon-root]:h-56 [&>div>div>.MuiSvgIcon-root]:w-56">
              <Fallback
                width="100%"
                height="100%"
                title="No courses available"
                text="Try modifying your search criteria"
                icon={() => (
                  <Icon
                    type="Search"
                    size="large"
                    color="eq-color-neutral-200"
                  />
                )}
                backgroundColor="rgb(var(--colors-neutral-100))"
                testId="global-courses-table-no-results"
              />
            </div>
          }
        />
      </StyledTableContainer>
      {bulkActionType ? (
        <UpdateCourseModal
          open={isModalOpen}
          accessorKey={bulkActionType}
          closeModal={closeModal}
          onSubmit={(data) => {
            const { metadata } = transformCourseMetadataRequest(selectedCourses)
            const metadataPayload = metadata.map((course) => {
              if (bulkActionType === 'cima' || bulkActionType === 'cfp') {
                return {
                  ...course,
                  ce: {
                    cfpCeCredit: data.cfp,
                    cimaCeCredit: data.cima,
                  },
                }
              } else if (bulkActionType === 'group') {
                return {
                  ...course,
                  theme: data.group,
                }
              } else if (bulkActionType === 'requiredScore') {
                return {
                  ...course,
                  requiredAssessmentScore: data.requiredScore
                    ? data.requiredScore / 100
                    : course.requiredAssessmentScore,
                }
              } else {
                return course
              }
            })
            mutateCoursesMetadata(
              { metadata: metadataPayload },
              {
                onSuccess: async () => {
                  handleResetRowSelections()
                  await invalidate(/v1[/]manage[/]courses[/]metadata/)
                  closeModal()
                  showToast({
                    type: 'success',
                    title: 'Course metadata has been updated',
                  })
                },
              }
            )
          }}
          course={{}}
        />
      ) : null}
    </>
  )
}

export default ManageGlobalCoursesTable
