import styled from '@emotion/styled'
import { Divider } from '@mui/material'
import dayjs from 'dayjs'
import { useState, ReactNode } from 'react'
import { useMedia, useDeepCompareEffect } from 'react-use'

import { Body, H6Strong } from '@cais-group/approved/ui/typography'
import {
  CourseCeStatus,
  useGetCourseCeCredits,
} from '@cais-group/caisiq/domain/course'
import {
  useGetUserLicences,
  useGetUserProfile,
} from '@cais-group/caisiq/domain/user'
import { CaisiqLink } from '@cais-group/caisiq/feature/routes'
import { CeCreditGuideLink } from '@cais-group/caisiq/ui/ce-credit-guide-link'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { useLocalStorage } from '@cais-group/caisiq/util/hook/use-local-storage'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Button } from '@cais-group/equity/atoms/button'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { Course } from '@cais-group/shared/util/type/caisiq-be'
import { isAssessmentUnlocked } from '@cais-group/shared/util/type/course'
import { Experience } from '@cais-group/shared/util/type/experience'
import { ExperienceData } from '@cais-group/shared/util/type/experience-data'

const StyledDivider = styled(Divider)`
  height: var(--s24);
  margin: 0 var(--s16);
`
export const NotificationStyles = {
  Bold: styled.span`
    font-weight: 600;
  `,

  Description: styled(Body)`
    opacity: 0.64;
    color: rgb(var(--colors-neutral-900));
  `,

  Title: styled(H6Strong)`
    margin-left: calc(var(--s8) * -1);
    font-weight: 600;
    &:before {
      height: var(--s8);
      width: var(--s8);
      content: '';
      display: inline-block;
      border-radius: 50%;
      background-color: rgb(var(--colors-success-600));
      position: relative;
      left: calc(var(--s16) * -1);
      top: -0.125rem;
    }
  `,
  Cta: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: ${BREAKPOINTS.breakpointMd}) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: baseline;
    }
  `,
}
export interface INotification {
  id: string
  children: ReactNode
  onClose?: () => void
}

const CE_CREDITS_NOTIFICATION_DISMISSED = 'CE_CREDITS_NOTIFICATION_DISMISSED'
const COURSE_NOTIFICATION_DISMISSED = 'COURSE_NOTIFICATION_DISMISSED'

const DoubleCeNotificationCta = ({
  isTablet,
  ceGuidePage,
}: {
  isTablet: boolean
  ceGuidePage: ExperienceData['ceGuidePage']
}) => (
  <NotificationStyles.Cta>
    <CaisiqLink data-testid="link-to-profile" to="profile">
      <Button
        onClick={() => trackingService.ceEnterCredentialNumbersLinkClicked()}
        variant="tertiary"
      >
        Enter credential numbers
      </Button>
    </CaisiqLink>
    {/* Don't show the divider on narrower viewports or if guide is disabled */}
    {!isTablet && ceGuidePage?.slug && <StyledDivider orientation="vertical" />}
    <CeCreditGuideLink
      slug={ceGuidePage?.slug}
      onClick={() => {
        trackingService.ceLearnMoreAboutCeCreditsLinkClicked()
      }}
    />
  </NotificationStyles.Cta>
)
export const useNotifications = (courses?: Course[]) => {
  const isTablet = useMedia(`(max-width: ${BREAKPOINTS.breakpointMd})`)
  const [notifications, setNotifications] = useState<INotification[]>([])
  const { data: userProfileData } = useGetUserProfile()

  const { data } = useGetUserLicences()

  const { ceCreditsAvailable } = useGetCourseCeCredits()

  const [ceCreditsDismissed, setCeCreditsDismissed] = useLocalStorage(
    CE_CREDITS_NOTIFICATION_DISMISSED,
    false
  )

  const hasSavedLicences =
    Boolean(data?.cima?.number) || Boolean(data?.cfp?.number)

  type NotificationType =
    | 'new-assessment'
    | 'daily-max'
    | 'completed-ce-course-no-license'
    | 'completed-ce-course-has-license'

  interface CourseNotification {
    name: string
    type: NotificationType
    dismissedUntilDate: string | null
  }

  const DATE_FORMAT = 'YYYY-MM-DD'

  const [notificationsDismissed, setNotificationsDismissed] = useLocalStorage<
    string,
    CourseNotification[]
  >(COURSE_NOTIFICATION_DISMISSED, [])
  const hasCoursesWithCeCredits =
    courses?.some((course) => ceCreditsAvailable(course).hasCeCredits) ?? false

  useDeepCompareEffect(() => {
    if (courses) {
      // TODO: Figure out a way to make logic more maintainable and notifications configurable per experience/firm.
      const isAdvisorGroupUser =
        experienceService.getExperience() === Experience.ADVISOR_GROUP

      const setNotificationDismissed = (
        course: Course,
        type: NotificationType,
        dismissedUntilDate: string | null = null
      ) => {
        setNotificationsDismissed([
          ...notificationsDismissed,
          { name: course.name, type, dismissedUntilDate },
        ])
      }

      const today = dayjs().format(DATE_FORMAT)

      const hasBeenDismissed = (
        course: Course,
        notificationType: NotificationType
      ) => {
        const hasDismissExpired = (dismissedUntilDate: string | null) => {
          if (dismissedUntilDate === null) {
            return false
          }

          return dismissedUntilDate <= today
        }

        return (
          notificationsDismissed.find(
            ({ name, type, dismissedUntilDate }) =>
              course.name === name &&
              type === notificationType &&
              !hasDismissExpired(dismissedUntilDate)
          ) !== undefined
        )
      }

      const lessThanTwoWeeksSinceCompletion = (completionDate: string) => {
        const twoWeeksFromCompletion = dayjs(completionDate)
          .add(2, 'week')
          .format(DATE_FORMAT)

        return today <= twoWeeksFromCompletion
      }

      setNotifications(
        courses.reduce((acc, course) => {
          // NOTE: We have a special case for Advisor Group because technically AG does not have assessment at the moment so
          //       the notification would not make sense.
          if (
            isAssessmentUnlocked(course) &&
            !isAdvisorGroupUser &&
            !hasBeenDismissed(course, 'new-assessment')
          ) {
            return acc.concat({
              id: course.name,
              onClose: () => setNotificationDismissed(course, 'new-assessment'),
              children: (
                <>
                  <NotificationStyles.Title>
                    A new assessment is available
                  </NotificationStyles.Title>
                  <NotificationStyles.Description>
                    You’ve unlocked the assessment for{' '}
                    <NotificationStyles.Bold>
                      {course.name}
                    </NotificationStyles.Bold>
                    .
                    <br />
                    If you pass with a score of{' '}
                    <NotificationStyles.Bold>70%</NotificationStyles.Bold> or
                    higher, you’ll get access to related products.
                  </NotificationStyles.Description>
                  <NotificationStyles.Cta>
                    <CaisiqLink to="assignment" args={[course.id]}>
                      <Button variant="tertiary">Start assessment</Button>
                    </CaisiqLink>
                  </NotificationStyles.Cta>
                </>
              ),
            })
          }
          /**
           * If the user has completed a course with CE credits and has not yet dismissed the
           * CE credits notification and has user entered either license number, include it in the list.
           *  We only want to show 1 such notification for each course that applies.
           */
          if (
            !hasBeenDismissed(course, 'completed-ce-course-has-license') &&
            course.learnedAt &&
            hasSavedLicences &&
            lessThanTwoWeeksSinceCompletion(course.learnedAt) &&
            ceCreditsAvailable(course).courseCeStatus !==
              CourseCeStatus.UNAVAILABLE
          ) {
            return acc.concat({
              id: 'completed-ce-course-has-license',
              onClose: () =>
                setNotificationDismissed(
                  course,
                  'completed-ce-course-has-license'
                ),
              children: (
                <>
                  <NotificationStyles.Title>
                    Congrats! You met a requirement for Continuing Education
                    credits.
                  </NotificationStyles.Title>
                  <NotificationStyles.Description>
                    By completing {course.name}, you met a requirement for CE
                    credits. You
                    <br />
                    can track your credits and manage credential numbers in your
                    profile.
                  </NotificationStyles.Description>
                  <NotificationStyles.Cta>
                    <CaisiqLink data-testid="link-to-profile" to="profile">
                      <Button variant="tertiary">View My Profile</Button>
                    </CaisiqLink>
                  </NotificationStyles.Cta>
                </>
              ),
            })
          }
          /**
           * If the user has completed a course with CE credits and has not yet dismissed the
           * CE credits notification nor has user entered either license number, include it in the list.
           *  We only want to show 1 such notification for each course that applies.
           */
          if (
            !hasBeenDismissed(course, 'completed-ce-course-no-license') &&
            course.learnedAt &&
            !hasSavedLicences &&
            lessThanTwoWeeksSinceCompletion(course.learnedAt) &&
            ceCreditsAvailable(course).courseCeStatus !==
              CourseCeStatus.UNAVAILABLE
          ) {
            return acc.concat({
              id: 'completed-ce-course-no-license',
              onClose: () =>
                setNotificationDismissed(
                  course,
                  'completed-ce-course-no-license'
                ),
              children: (
                <>
                  <NotificationStyles.Title>
                    Congrats! You met a requirement for Continuing Education
                    credits.
                  </NotificationStyles.Title>
                  <NotificationStyles.Description>
                    By completing {course.name}, you are eligible to earn CE
                    credits. Enter CFP
                    <br />
                    or CIMA credential numbers to claim your credits before they
                    are no longer eligible.
                  </NotificationStyles.Description>
                  <DoubleCeNotificationCta
                    isTablet={isTablet}
                    ceGuidePage={userSettingsService.theme.ceGuidePage}
                  />
                </>
              ),
            })
          } else {
            return acc
          }
        }, new Array<INotification>())
      )
    } else {
      setNotifications([])
    }
  }, [courses, userProfileData?.firm, notificationsDismissed, setNotifications])

  return {
    /**
     * If the user is enrolled in courses with CE credits and has not yet dismissed the
     * CE credits notification, include it in the list. We only want to show 1 such notification.
     */
    notifications:
      // Ensure the  notification is not already included in the list
      notifications.findIndex((n) => n.id === 'ce-credits-intro') === -1 &&
      hasCoursesWithCeCredits &&
      !ceCreditsDismissed
        ? [
            {
              id: 'ce-credits-intro',
              onClose: () => setCeCreditsDismissed(true),
              children: (
                <>
                  <NotificationStyles.Title>
                    Earn Continuing Education Credits through CAIS IQ
                  </NotificationStyles.Title>
                  <NotificationStyles.Description>
                    You can earn CFP and CIMA credits through eligible courses.
                  </NotificationStyles.Description>

                  <DoubleCeNotificationCta
                    isTablet={isTablet}
                    ceGuidePage={userSettingsService.theme?.ceGuidePage}
                  />
                </>
              ),
            },
            ...notifications,
          ]
        : notifications,
    setNotifications,
  }
}
