/**
 * Returns a string with the appropriate singular or plural form of a word based on the count.
 * Optionally includes the count in the returned string.
 *
 * @param options - An object containing the options for pluralizing the word.
 * @param options.count - The count of the word.
 * @param options.singular - The singular form of the word.
 * @param [options.plural] - The optional plural form of the word. If not provided,
 *                           the function will add an "s" to the end of the singular form.
 * @param [options.includeCount=true] - Whether to include the count in the returned string. Defaults to true.
 * @returns The appropriate singular or plural form of the word, optionally with the count.
 *
 * @example
 * pluralize({ count: 1, singular: 'apple', includeCount: false }); // Returns "apple"
 *
 * @example
 * pluralize({ count: 2, singular: 'apple', includeCount: false }); // Returns "apples"
 *
 * @example
 * pluralize({ count: 0, singular: 'apple', plural: 'apples' }); // Returns "0 apples"
 *
 * @example
 * pluralize({ count: 1, singular: 'child', includeCount: false }); // Returns "child"
 *
 * @example
 * pluralize({ count: 2, singular: 'child', plural: 'children', includeCount: false }); // Returns "children"
 */
interface PluralizeOptions {
  count: number
  singular: string
  plural?: string
  includeCount?: boolean
}

export function pluralize({
  count,
  singular,
  plural = `${singular}s`,
  includeCount = true,
}: PluralizeOptions): string {
  if (count === 1) {
    return includeCount ? `${count} ${singular}` : singular
  }

  return includeCount ? `${count} ${plural}` : plural
}
