import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { defaultPersister } from '@cais-group/caisiq/util/default-query-function'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import {
  ApiPaths,
  LearnerExperienceSwitchRequest,
} from '@cais-group/shared/util/type/caisiq-be'

export const useSwitchExperience = (version?: number) => {
  const { mutateAsync: switchExperienceMutate } = useMutation<
    unknown,
    AxiosError,
    LearnerExperienceSwitchRequest,
    unknown
  >(
    async (props) =>
      await defaultPersister<LearnerExperienceSwitchRequest, unknown>(
        ApiPaths.createLearnerExperienceSwitch,
        props,
        version,
        'POST'
      ),
    {
      onSuccess: async () => {
        experienceService.reset()
        window.location.replace(window.location.pathname)
      },
      onError: (apiError) => {
        console.error(
          `There was a problem retrieving data from an API call to ${apiError.config?.url}`,
          apiError,
          apiError.response?.status
        )
      },
    }
  )

  return {
    switchExperienceMutate,
  }
}
