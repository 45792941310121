import classNames from 'classnames'
import * as React from 'react'

import { useUniformContext } from '../context'
import type { UniformFieldLayoutProps } from '../types'

type ColsType =
  | { start?: number; span: number }
  | { start?: number; end: number }

function isSpanType(cols: ColsType): cols is { start?: number; span: number } {
  return 'span' in cols
}

export function generateGridClasses(
  staticGrid: Record<string, string>,
  layout: { cols?: UniformFieldLayoutProps }
): string {
  if (layout === undefined) {
    return ''
  }

  const { cols } = layout

  if (typeof cols === 'number') {
    return staticGrid[`col-span-${cols}`]
  }

  if (cols === 'fit') {
    return `max-w-[496px] ${staticGrid[`col-start-1-end-13`]}`
  }

  if (cols) {
    const { start } = cols

    if (isSpanType(cols)) {
      const { span } = cols
      return staticGrid[`col-start-${start}-end-${span}`]
    } else {
      const { end } = cols
      return staticGrid[`col-start-${start}-end-${end}`]
    }
  }

  return ''
}

export type FieldSurroundProps = {
  className?: string
  cols?: UniformFieldLayoutProps
  testId?: string
  fieldId?: string
}
/**
 * By pulling the container css into the individual inputs we ensure that when
 * making a fields visibility conditional, all code relating to this field is
 * removed from the DOM, so things like dividers don't appear when the field
 * itself does not.
 */
const defaultCols: UniformFieldLayoutProps = { start: 1, end: 7 }
export const FieldSurround = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<FieldSurroundProps>
>(({ children, cols, className, testId }, ref) => {
  const { staticGridClassDict, settings } = useUniformContext()

  const gridClassName = React.useMemo(() => {
    if (typeof cols !== 'undefined') {
      return generateGridClasses(staticGridClassDict, {
        cols,
      })
    }
    return generateGridClasses(staticGridClassDict, {
      cols: {
        ...defaultCols,
        end: settings?.defaultFieldCols ?? defaultCols.end,
      },
    })
  }, [cols, staticGridClassDict, settings?.defaultFieldCols])

  return (
    <div
      ref={ref}
      className={classNames(
        'col-start-1 col-end-13 w-full',
        gridClassName,
        className
      )}
      data-testid={testId}
      tabIndex={-1}
    >
      {children}
    </div>
  )
})
