import { GraphQLClient, RequestDocument } from 'graphql-request'
import type { Variables } from 'graphql-request'
import { QueryFunctionContext } from 'react-query'

import { getEnvConfig } from '@cais-group/shared/ui/env'
import { authService } from '@cais-group/shared/util/auth-service'

export const createMembersFetcher =
  <TData, TVariables extends Variables>(
    query: string | RequestDocument,
    variables?: TVariables
  ) =>
  async (context?: Partial<QueryFunctionContext>): Promise<TData> => {
    const client = new GraphQLClient(getEnvConfig().GRAPHQL_API, {
      signal: context?.signal,
    })

    return client.request(query, variables, {
      Authorization: `Bearer ${await authService.getAccessTokenSilently()}`,
      ...(context?.meta?.['headers'] ?? {}),
    })
  }
