import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { ExperienceError } from '@cais-group/caisiq/feature/error/experience'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { useGetExperienceQuery } from '@cais-group/caisiq/util/graphql/contentful'
import { useApiPathWithPayload } from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import { getEnvConfig } from '@cais-group/caisiq-ui-env'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  ApiPaths,
  ExperienceContentful,
} from '@cais-group/shared/util/type/caisiq-be'
import { TypeLoginScreenData } from '@cais-group/shared/util/type/login-screen-data'

import { Login } from './caisiq-feature-login'

export const LoginContainer = () => {
  const { isLoading, isAuthenticated } = useAuth0()

  const resolvedPath = useApiPathWithPayload(ApiPaths.findContentfulMapping)({
    label: experienceService.getExperience() || '',
  })

  const { CAISIQ_API_HOST } = getEnvConfig()

  // Note: directly use axio here as we do not want auth
  const mappingResponse = useQuery<ExperienceContentful>(
    resolvedPath,
    ({ queryKey }) =>
      axios.get(`${CAISIQ_API_HOST}${queryKey[0]}`).then((r) => r.data),
    {
      enabled: experienceService.getExperience() !== undefined,
    }
  )

  const experienceResponse = useGetExperienceQuery(
    {
      id: mappingResponse.data?.contentfulId || '',
      preview: previewService.enabled,
    },
    {
      enabled: mappingResponse.isSuccess,
    }
  )
  const navigate = useNavigate()

  useEffect(() => {
    // If we land on the login page without an experience set, redirect away
    if (experienceService.getExperience() === undefined) {
      navigate(ROUTES.home, { replace: true })
    }

    // If we land on the login page but we are already logged in, redirect away
    if (isAuthenticated) {
      navigate(ROUTES.home, { replace: true })
    }
  }, [isAuthenticated, navigate])

  const experience = experienceResponse.data?.firm

  if (isLoading || !experienceResponse.isFetched) {
    return (
      <LoadingContainer state={LoadingState.LOADING} coverPage="FULL_SCREEN" />
    )
  }

  if (
    experienceResponse.isError ||
    mappingResponse.isError ||
    experience?.loginScreen === undefined
  ) {
    return <ExperienceError />
  }

  return (
    <Login
      loginScreenData={experience?.loginScreen as TypeLoginScreenData}
      canSignup={experienceService.isDirect()}
    />
  )
}

LoginContainer.displayName = 'LoginContainer'
