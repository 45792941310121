import { ReactComponent as AccountsIcon } from './assets/accounts.svg'
import { ReactComponent as AlternativesIcon } from './assets/alternatives.svg'
import { ReactComponent as AssetManagersIcon } from './assets/asset-managers.svg'
import { ReactComponent as BankIssuersIcon } from './assets/bank-issuers.svg'
import { ReactComponent as CaisIQIcon } from './assets/cais-iq.svg'
import { ReactComponent as CaisSolutionsIcon } from './assets/cais-solutions.svg'
import { ReactComponent as CustomFundsIcon } from './assets/custom-funds.svg'
import { ReactComponent as DashboardIcon } from './assets/dashboard.svg'
import { ReactComponent as EnterpriseIcon } from './assets/enterprise.svg'
import { ReactComponent as MarketplaceIcon } from './assets/marketplace.svg'
import { ReactComponent as StructuredNotesIcon } from './assets/structured-notes.svg'
import { ReactComponent as TradeIcon } from './assets/trade.svg'
import { ReactComponent as TutorialsIcon } from './assets/tutorials.svg'
import { ReactComponent as WebinarsIcon } from './assets/webinars.svg'
import { BrandIconType } from './brand-icons'

type Icon = [string, typeof AccountsIcon]

export const brandIconsMapping: Record<BrandIconType, Icon> = {
  Accounts: ['Accounts', AccountsIcon],
  Alternatives: ['Alternatives', AlternativesIcon],
  AssetManagers: ['AssetManagers', AssetManagersIcon],
  BankIssuers: ['BankIssuers', BankIssuersIcon],
  CaisIQ: ['CaisIQ', CaisIQIcon],
  CaisSolutions: ['CaisSolutions', CaisSolutionsIcon],
  CustomFunds: ['CustomFunds', CustomFundsIcon],
  Dashboard: ['Dashboard', DashboardIcon],
  Enterprise: ['Enterprise', EnterpriseIcon],
  Marketplace: ['Marketplace', MarketplaceIcon],
  StructuredNotes: ['StructuredNotes', StructuredNotesIcon],
  Trade: ['Trade', TradeIcon],
  Tutorials: ['Tutorials', TutorialsIcon],
  Webinars: ['Webinars', WebinarsIcon],
}
