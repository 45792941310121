import { LogoutOptions, useAuth0 } from '@auth0/auth0-react'
import cx from 'classnames'
import { MouseEventHandler } from 'react'
import { LinkProps, NavLink as RRNavLink } from 'react-router-dom'

import { APP_PATHS, isAppDefederated } from '@cais-group/shared/domain/apps'
import { authService } from '@cais-group/shared/util/auth-service'

import { LOGOUT_ACTION } from '../constants'
import type { EnvType, UrlType } from '../types'

export const NavLink = ({
  onLogout,
  urlType,
  env,
  ...props
}: LinkProps &
  UrlType &
  React.RefAttributes<HTMLAnchorElement> & {
    onLogout?: MouseEventHandler<HTMLAnchorElement>
    env: EnvType
  }) => {
  const { logout: auth0Logout } = useAuth0()
  const to = props.to as string
  const logout = (options?: LogoutOptions) => {
    authService.invalidateSession()
    sessionStorage.removeItem('sessionId')

    if (env.isLocalhost || env.isEphemeral) {
      // Convenience logout for local dev, only logs out of auth0
      // This is because those environments don't include portal so the
      // portal logout url (`/j_spring_security_logout`) would not work
      auth0Logout(options)
    } else {
      // This will log the user out of both the portal session and auth0
      window.location.href =
        window.location.origin + '/j_spring_security_logout'
    }
  }

  if (to === LOGOUT_ACTION) {
    const { children, to, ...linkProps } = props
    const logoutAction = onLogout
      ? onLogout
      : () => {
          document.dispatchEvent(new Event(LOGOUT_ACTION))
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}/`,
            },
          })
        }

    return (
      <a {...linkProps} onClick={logoutAction}>
        {children}
      </a>
    )
  }

  if (to === '/') {
    const inFundManager = window.location.href.includes('fund-manager')
    if (inFundManager) {
      // Fund manager root is '/fund-manager'
      return <RRNavLink {...props} to="/fund-manager" />
    }
  }

  const linkingToMfeApp = APP_PATHS.some((mfeApp) => to.includes(mfeApp))
  const isTheSameApp =
    linkingToMfeApp && window.location.href.includes(to.split('/')[1])
  const linkingToCiq = to.includes('authenticated')
  const trackingProps = props as { 'data-track-item_name'?: string }
  const trackingAttributes = {
    'data-track-item_name': trackingProps['data-track-item_name']
      ? trackingProps['data-track-item_name']
      : props.children,
  }

  // Use react-router-link when linking from a mfeApp to another mfeApp
  if (isTheSameApp) {
    const basepath = window.location.pathname.split('/')[1]
    props.to = isAppDefederated(basepath)
      ? (props.to as string).replace(`/${basepath}`, '')
      : props.to

    return <RRNavLink {...props} {...trackingAttributes} />
  }

  // Use react-router-link when linking from CIQ to CIQ and remove host from the link
  if (env.isCiq && linkingToCiq) {
    const to =
      typeof props.to === 'string' ? new URL(props.to).pathname : props.to
    return <RRNavLink {...props} {...trackingAttributes} to={to} />
  }

  /**
   * For CAIS IQ links we do not want to prepend 'http://localhost:8080'.
   * This is needed because CAIS IQ is not yet migrated to the MFE container
   * and navigation item links are specified as absolute URLs starting with: https://caisiq.
   */
  const href = env.isLocalhost
    ? linkingToMfeApp
      ? 'http://localhost:4206' + to
      : urlType === 'absolute'
      ? to
      : 'http://localhost:8080' + to
    : to

  const { className, to: ignoreTo, ...otherProps } = props
  const fullWindowPath = `${window.location.pathname}${window.location.search}${window.location.hash}`
  const hasCurrentPage = fullWindowPath.startsWith(to)

  if (env.isLocalhost && env.isCiq && linkingToMfeApp) {
    otherProps.onClick = undefined
  }

  return (
    <a
      href={href}
      className={cx(className, {
        active: hasCurrentPage,
      })}
      {...otherProps}
      {...trackingAttributes}
    >
      {props.children}
    </a>
  )
}
