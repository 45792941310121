export interface LabelWithControlProps {
  control: React.ReactElement
  disabled?: boolean
  label: string | React.ReactNode
  labelProps?: React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >
}

/**
 * A simple label for a checkbox that can be disabled (grays out).
 *
 * Attempted to use MUI's stuff but even with things like FormGroup either led to weird layout issues (first checkbox un-indented) or failed to match designs (mostly both).
 */
export function LabelWithControl({
  control,
  disabled,
  label,
  labelProps,
}: LabelWithControlProps) {
  return (
    <label className="flex flex-row items-center justify-start" {...labelProps}>
      {control}
      <div
        className={`bodySmall eq-break-words ${
          disabled ? 'text-neutral-400' : 'text-neutral-900'
        }`}
      >
        {label}
      </div>
    </label>
  )
}

export default LabelWithControl
