import { UserProfileV1 } from '@cais-group/access-manager/domain/api'
import { FirmExperienceV1 } from '@cais-group/shared/domain/experience-manager'
import {
  getUserEnabledFeatures,
  getEntitledNavLinks,
  type EnabledAccessControls,
  type NavItem,
} from '@cais-group/shared/util/entitled-nav-links'

import { ReactComponent as PoweredByCAISLogo } from './assets/powered-by-cais.svg'
import { FirmLogo } from './components/firm-logo'
import { getFirmLogo } from './getFirmLogo'
import { getProfileMenu } from './getProfileMenu'
import { navigation } from './navigation'

type GlobalNav = {
  profileMenu: ReturnType<typeof getProfileMenu>
  navItems: NavItem[]
  logos: JSX.Element[] | null
}

type Options = {
  onLogout?: () => void
}

export function globalNavManager({
  userProfile,
  options,
  experience,
}: {
  userProfile: UserProfileV1 | null
  options?: Options
  experience?: FirmExperienceV1
}): GlobalNav {
  const enabledAccessControls = {
    enabledCapabilities: getEnabledCapabilities(userProfile?.capabilities),
    enabledFeatures: getUserEnabledFeatures(userProfile?.features),
    enabledPermissions: userProfile?.permissions || [],
    enabledPersonas: userProfile?.personas || [],
  }

  const firmLogoUrl = getFirmLogo({ experience, userProfile })
  const firmName = userProfile?.firm?.name || ''

  return {
    navItems: getEntitledNavLinks(navigation, enabledAccessControls),
    profileMenu: getProfileMenu(enabledAccessControls, options?.onLogout),
    logos: firmLogoUrl
      ? [
          <FirmLogo key={firmName} alt={firmName} src={firmLogoUrl} />,
          <PoweredByCAISLogo key="powered-by-cais" />,
        ]
      : null,
  }
}

function getEnabledCapabilities(
  capabilities: UserProfileV1['capabilities'] = []
): EnabledAccessControls['enabledCapabilities'] {
  return capabilities.map((capability) => capability.id)
}
