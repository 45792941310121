import React from 'react'
import { useParams, Link, Outlet } from 'react-router-dom'

import { isData } from '@cais-group/caisiq/domain/common'
import { useGetVideo } from '@cais-group/caisiq/domain/video'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { BackPillButton } from '@cais-group/equity/atoms/pill'
import { VideoPageContainer as VideoPageWrapper } from '@cais-group/shared/ui/video/video-page-container'

export const VideoPageContainer = () => {
  const { id } = useParams<{ id: string }>()
  const data = useGetVideo(id)
  return (
    <>
      {isData(data) && (
        <VideoPageWrapper
          data={data}
          userId={userSettingsService.user.sub}
          nav={
            <Link to={ROUTES.videos}>
              <BackPillButton />
            </Link>
          }
        />
      )}
      {/**
       *
       * @todo CIQ - HP We need to added this here since the Video Player is
       * now lazy loaded * We should consider added the same loading and error
       * pattern to CIQ Video Page Container
       *
       * */}

      <React.Suspense fallback={null}>
        <Outlet />
      </React.Suspense>
    </>
  )
}

VideoPageContainer.displayName = 'VideoPageContainer'
