import { faker } from '@faker-js/faker/locale/en'
import { Sync } from 'factory.ts'

import {
  KycDocumentDetailsV1,
  UploadDocumentResponseV1,
} from '../../generated/api.schemas'

export const documentDetailsFactory = Sync.makeFactory<
  KycDocumentDetailsV1 & UploadDocumentResponseV1
>({
  fileName: 'mock-doc.pdf',
  fileId: faker.string.uuid(),
  fileType: 'OTHER',
  fileVersion: 1,
  resourceId: faker.string.uuid(),
  resourceType: 'CONTACT',
  contentType: 'application/pdf',
  isLatest: true,
  expiryDate: faker.date.future().toISOString(),
  issueDate: faker.date.past().toISOString(),
})
