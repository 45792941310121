import { createEnvConfigContext, Feature } from '@cais-group/shared/ui/env'
export interface CaisiqEnvConfig {
  AUTH0_DOMAIN: string
  AUTH0_CLIENT_ID: string
  AUTH0_MEMBERS_CLIENT_ID: string
  AUTH0_DIRECT_AUDIENCE: string
  AUTH0_MEMBERS_AUDIENCE: string
  CONTENTFUL_ACCESS_TOKEN: string
  CONTENTFUL_PREVIEW_ACCESS_TOKEN: string
  CONTENTFUL_URL: string
  DATADOG_APPLICATION_ID: string
  DATADOG_CLIENT_TOKEN: string
  ENVIRONMENT: string
  FEATURE_FLAGS: Partial<Record<Feature, boolean>>
  PORTAL_URL: string
  SEGMENT_API_KEY: string
  ACP_REDIRECT_URL?: string
  CAISIQ_API_HOST: string
  GRAPHQL_API: string
  CAISIQ: boolean
  TURNSTILE_SITE_KEY: string
}

// A helper function to determine if caisiq is running in the MFE
function isMfe() {
  return window?.location?.pathname?.match(/^\/cais-iq/) !== null
}

// Export the previously available components / functions to maintain backward compatability in other libraries
export const {
  EnvContext,
  EnvConfigContainerProvider,
  EnvContextRenderProp,
  getEnvConfig,
  setEnvConfig,
} = createEnvConfigContext<CaisiqEnvConfig>(
  isMfe() ? `/cais-iq/env.json` : `/env.json`
)
