import styled from '@emotion/styled'
import { Stack } from '@mui/material'
import { ReactNode } from 'react'

import { BodyStrong, Overline } from '@cais-group/approved/ui/typography'
import { Counter } from '@cais-group/equity/atoms/counter'
import { colors } from '@cais-group/equity/particles/colors'
import { CreditProvider } from '@cais-group/shared/util/type/caisiq-be'

const TableLabel = styled('div')`
  margin-top: var(--s56);
  margin-bottom: var(--s16);
`

const CeTableRow = styled.div`
  height: 64px;
  border-top: solid ${colors['eq-color-neutral-200']} 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export interface CeCreditsListItem {
  type: CreditProvider
  credits: number
  licenseNumber: string
  renderRow: (row: CeCreditsListItem) => ReactNode
}

type CeCreditsListProps = {
  rows: CeCreditsListItem[]
}

export const CeCreditsList = (props: CeCreditsListProps) => {
  const { rows } = props
  return (
    <div style={{ width: '100%' }}>
      <TableLabel>
        <Overline>Your credits</Overline>
      </TableLabel>
      {rows.map((row) => {
        return (
          <CeTableRow key={row.type} data-testid={`ce-row-${row.type}`}>
            <Stack flexDirection="row" alignItems="center">
              <Counter
                variant="light"
                testId={`ce-counter-${row.type}`}
                value={row.credits}
              />
              <div style={{ marginLeft: 'var(--s12)' }}>
                <BodyStrong>{row.type.toUpperCase()}</BodyStrong>
              </div>
            </Stack>
            {row.renderRow(row)}
          </CeTableRow>
        )
      })}
    </div>
  )
}
