import styled from '@emotion/styled'
import { Skeleton } from '@mui/material'
import { SetterOrUpdater } from 'recoil'

import { FundCard } from '@cais-group/caisiq/ui/card/fund'
import { NoResults } from '@cais-group/caisiq-ui-no-results'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { LayoutCenter, LayoutGrid } from '@cais-group/shared/ui/layout'
import { Fund } from '@cais-group/shared/util/type/caisiq-be'

const NoResultsContainer = styled(LayoutCenter)`
  padding-top: var(--s24);
  padding-bottom: var(--s56);
`

const SkeletonWrapper = styled.div`
  display: flex;
  gap: ${SPACING.s8};
  flex-direction: column;
`

const SkeletonContainer = styled(LayoutGrid)`
  background-color: ${colors['eq-color-neutral-0']};
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${SPACING.s8};
  padding-left: ${SPACING.s32};
  grid-gap: var(--s24) var(--s32);
`

const StyledFundGrid = styled.div`
  padding-top: var(--s24);
  padding-bottom: var(--s56);

  display: grid;
  grid-gap: var(--s24) var(--s32);
  align-content: center;
  grid-template-columns: repeat(auto-fill, minmax(min(440px, 100%), 1fr));

  @media screen and (min-width: 2000px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(min(calc(var(--s608) - 100px), 100%), 1fr)
    );
  }
`

const StyledSkeletonGrid = styled.div`
  padding-top: var(--s24);
  padding-bottom: var(--s56);

  display: grid;
  grid-gap: var(--s24) var(--s32);
  place-content: center;
  grid-template-columns: repeat(auto-fill, minmax(min(440px, 100%), 1fr));

  @media screen and (min-width: 2000px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(min(calc(var(--s608) - 100px), 100%), 1fr)
    );
  }
`

type FundsListProps = {
  filteredFunds?: Fund[]
  searchTerm: string
  setSearchTerm: SetterOrUpdater<string>
  onSetSelectedFund: (fund: Fund) => void
}

export const FundsList = (props: FundsListProps) => {
  const { filteredFunds, searchTerm, setSearchTerm, onSetSelectedFund } = props

  if (!filteredFunds && searchTerm === '') {
    return (
      <>
        {Array.from(Array(13).keys(), (idx) => (
          <FundSkeleton key={`outer-fund-skeleton-${idx}`} />
        ))}
      </>
    )
  } else if (filteredFunds?.length === 0) {
    return (
      <NoResultsContainer>
        <NoResults
          searchSetName="Funds"
          searchTerm={searchTerm}
          onClick={() => setSearchTerm('')}
        />
      </NoResultsContainer>
    )
  }

  return (
    <StyledFundGrid>
      {filteredFunds?.map((fund) => {
        return (
          <FundCard
            key={`fund-${fund.fundIdentifier}`}
            fund={fund}
            onSetSelectedFund={onSetSelectedFund}
          />
        )
      })}
    </StyledFundGrid>
  )
}

const FundSkeleton = () => {
  return (
    <StyledSkeletonGrid data-testid="videos-skeleton">
      {Array.from(Array(3).keys(), (i) => (
        <SkeletonContainer key={`fund-skeleton-${i}`}>
          <Skeleton variant="rectangular" height="54px" width="54px" />
          <SkeletonWrapper>
            <Skeleton variant="rectangular" height="21px" width="364px" />
            <Skeleton variant="rectangular" height="21px" width="364px" />
          </SkeletonWrapper>
        </SkeletonContainer>
      ))}
    </StyledSkeletonGrid>
  )
}
