import { uiTypeSize } from '@cais-group/shared/ui/type/size'
import styled from '@emotion/styled'
import { forwardRef, ReactNode } from 'react'
import { omit } from 'remeda'

export type SwitcherProps = {
  threshold?: uiTypeSize // Target width of each item
  space?: uiTypeSize // CSS margin value
  limit?: number // Max number of items in a horizontal layout
  children: ReactNode
  className?: string
}

export const LayoutSwitcher = styled(
  forwardRef<HTMLDivElement, SwitcherProps>((props, ref) => {
    const passedProps = omit(props, [
      'threshold',
      'space',
      'limit',
      'className',
      'children',
    ])
    return (
      <div
        ref={ref}
        className={props.className}
        data-layout="LayoutSwitcher"
        {...passedProps}
      >
        {props.children}
      </div>
    )
  })
)(({ threshold = 'var(--s144)', space = 'var(--s24)', limit = 4 }) => {
  const spaceValue = space === '0' ? '0px' : space

  return `
    display: block;

    & > * {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      margin: calc((${spaceValue} / 2) * -1);
    }

    & > * > * {
      flex-grow: 1;
      flex-basis: calc((${threshold} - (100% - ${spaceValue})) * 999);
      margin: calc(${spaceValue} / 2);
    }

    & > * > :nth-last-child(n + ${limit + 1}),
    & > * > :nth-last-child(n + ${limit + 1}) ~ * {
      flex-basis: 100%;
    }
  `
})
LayoutSwitcher.displayName = 'LayoutSwitcher'
