import '@cais-group/shared/ui/fonts'

import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import {
  Authenticated,
  AuthRoutes,
  CaisAuthProtected,
  useRedirectToFunds,
} from '@cais-group/caisiq/feature/router-config'
import { Auth0ExperienceAwareProvider } from '@cais-group/caisiq/ui/auth0-experience-aware-provider'
import { FlaggedProvider } from '@cais-group/caisiq-util-provider-flagged'
import { APPS } from '@cais-group/shared/domain/apps'
import { AppErrorBoundary } from '@cais-group/shared/ui/app-error-boundary'
import { useSetAuthTokenGetter } from '@cais-group/shared/util/auth-service'
import { useSetGlobalContext } from '@cais-group/shared/util/logging'

import { ReactQueryProvider } from './providers/ReactQueryProvider/ReactQueryProvider'

function App() {
  useSetGlobalContext({ appName: APPS.CAIS_IQ })

  return (
    <AppErrorBoundary appName={APPS.CAIS_IQ}>
      <FlaggedProvider>
        <RecoilRoot>
          <ReactQueryProvider>
            <Router>
              <Auth0ExperienceAwareProvider>
                <InnerApp />
              </Auth0ExperienceAwareProvider>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
          </ReactQueryProvider>
        </RecoilRoot>
      </FlaggedProvider>
    </AppErrorBoundary>
  )
}

/**
 * InnerApp is used to set the authService up and initiate ACP login,
 * which must be called inside of Auth0ExperienceAwareProvider
 */
const InnerApp = () => {
  useSetAuthTokenGetter(true)

  return <AppRoutes />
}

/**
 * AppRoutes seperate from InnerApp so that we don't call the hooks here
 * until we have correctly initiated the user profile and ACP
 */
const AppRoutes = () => {
  useRedirectToFunds()

  return (
    <Routes>
      <Route
        path="authenticated/*"
        element={
          <CaisAuthProtected>
            <Authenticated />
          </CaisAuthProtected>
        }
      />
      <Route path="*" element={<AuthRoutes />} />
    </Routes>
  )
}

export default App
