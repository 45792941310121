import { useState } from 'react'

export function UseModalState<TState>() {
  const [isOpen, setIsOpen] = useState<TState>()

  return {
    isOpen,
    onOpen: setIsOpen,
  }
}

export default UseModalState
