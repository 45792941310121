import styled from '@emotion/styled'
import { ReactNode } from 'react'

export type FootnotesProps = {
  children: ReactNode
}

const StyledFootnotes = styled.footer`
  border-top: 1px rgb(var(--colors-neutral-200)) solid;
  padding-top: var(--s32);
  margin-top: var(--s32);
  font-size: calc(0.875 * var(--s16));
  * {
    font-size: inherit !important;
  }
  p:empty {
    margin-top: 0 !important;
  }
  ol {
    list-style: decimal !important;
    margin-top: 0 !important;
    margin-left: var(--s24) !important;
  }
  li + li {
    margin-top: var(--s4);
  }
  li p {
    margin-top: 0 !important;
  }
  a {
    color: rgb(var(--colors-primary-600));
    font-weight: 600;
  }
`

export function Footnotes(props: FootnotesProps) {
  return (
    <StyledFootnotes id="footnotes" data-testid="footnotes-container">
      {props.children}
    </StyledFootnotes>
  )
}
