import type { PropsWithChildren, ReactNode } from 'react'

import type { Presets } from './presets'
import { Wave } from './wave'

type WaveHeaderProps = PropsWithChildren<{
  wavePreset?: Presets
}>

export function WaveHeader(props: WaveHeaderProps) {
  return (
    <header
      role="banner"
      className="grid grid-cols-[auto_1fr_auto] grid-rows-2 place-items-center"
    >
      {props.children}
      <div className="col-span-full row-span-full w-full">
        <Wave preset={props.wavePreset} />
      </div>
    </header>
  )
}

type WaveBannerProps = PropsWithChildren<{
  icon: ReactNode
  callToAction: ReactNode
  labelledBy?: string
}>

export function WaveBanner(props: WaveBannerProps) {
  const { callToAction, children, icon, labelledBy } = props
  return (
    <aside
      aria-labelledby={labelledBy}
      className="bg-gradient-3-1 text-primary-900-c relative isolate overflow-hidden"
    >
      <Wave preset="breaker-default" />
      <div className="relative flex min-h-[150px] flex-col items-center justify-stretch gap-32 overflow-hidden px-56 py-32 md:flex-row md:gap-56 md:py-0">
        {icon}
        <header className="flex-1 text-center md:text-left">{children}</header>
        <div className="pt-32">{callToAction}</div>
      </div>
    </aside>
  )
}
