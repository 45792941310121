import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

/**
 * These exist to make sure links do not render a line height,
 * we want normal a and Link tags to continue to function within
 * a paragraph etc, so we do not want to alter a in css.
 *
 * Use these wherever you have a link that is not part of some text,
 * eg. for social icons, or where you want to use something like
 * `align: center` and you want to use the visual height not the line
 * height for alignment.
 */

export const BlockLink = styled(Link)`
  line-height: 0;
  display: block;
`

export const ExternalLink = styled.a``
ExternalLink.defaultProps = {
  target: '_blank',
  rel: 'noreferrer noopener',
}

export const ExternalBlockLink = styled(ExternalLink)`
  line-height: 0;
  display: block;
`
