import { faker } from '@faker-js/faker/locale/en'
import { Sync } from 'factory.ts'

import { ContactResponseV1 } from '../../generated/api.schemas'

export const contactResponseV1Builder = Sync.makeFactory<ContactResponseV1>({
  id: faker.string.uuid(),
  firstName: 'Dave',
  middleInitial: 'o',
  lastName: 'Jones',
  dateOfBirth: '2020-06-05',
  ssn: '101051919',
  phoneNumber: null,
  email: 'investorprofile@dave.caisgroup.com',
  address: null,
  createdAt: faker.date.past().toISOString(),
  householdId: faker.string.uuid(),
  isLatest: true,
  updatedAt: faker.date.past().toISOString(),
  version: 1,
})
