import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { LayoutStack } from '@cais-group/shared/ui/layout'

type Variants = {
  variant?: 'richtext'
}

const StyledStack = styled(LayoutStack)<ContentStackProps>`
  ${({ fakeColumn }) =>
    fakeColumn
      ? ['max-width: var(--measure); margin-left: 0 !important']
      : ['max-width: 750px; margin-left: auto !important;']};
  margin-right: auto !important;
  width: 100%;
  @media screen and (max-width: 1024px) {
    margin-left: auto !important;
  }

  /* syle elements from Contentful that may break small screens */
  a:not([class]),
  p,
  blockquote {
    overflow-wrap: break-word;
  }

  /* Fallback to ensure any element not using "overflow-wrap" doesn't break the layout  */
  ${({ variant }) => variant === 'richtext' && `overflow-x: hidden;`}
`

type ContentStackProps = Variants & {
  children: ReactNode
  fakeColumn?: boolean
  type?: string
}

export const ContentStack = ({
  children,
  variant,
  fakeColumn,
}: ContentStackProps) => (
  <StyledStack
    as="article"
    space="var(--s56)"
    variant={variant}
    fakeColumn={fakeColumn}
    data-testid="content-stack"
  >
    {children}
  </StyledStack>
)
