import { forwardRef } from 'react'
import { NumericFormatProps, NumericFormat } from 'react-number-format'

type PercentageNumericFormatProps = {
  onChange: (event: {
    target: { name: string; value: number | string }
  }) => void
  name: string
}

export const PercentageNumericFormat = forwardRef<
  NumericFormatProps,
  PercentageNumericFormatProps
>(function PercentageNumericFormat(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      required
      getInputRef={ref}
      {...other}
      onValueChange={({ floatValue, value }) => {
        onChange({
          target: {
            name: props.name,
            value: floatValue || value,
          },
        })
      }}
      suffix="%"
      valueIsNumericString
      decimalScale={0}
    />
  )
})
