import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

import {
  H3Strong,
  H6Strong,
  Overline,
  SubtitleMono,
} from '@cais-group/approved/ui/typography'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { Button } from '@cais-group/equity/atoms/button'
import { setOpacity } from '@cais-group/equity/particles/colors'
import { ellipsify } from '@cais-group/equity/utilitarian'
import { LayoutStack } from '@cais-group/shared/ui/layout'
import { User } from '@cais-group/shared/util/type/caisiq-be'
import { TypeVideoPlaylistData } from '@cais-group/shared/util/type/video-playlist-data'

export type VideoHeaderProps = {
  playlist?: TypeVideoPlaylistData
  userProfile?: User
  backgroundImageUrl?: string
}

const Container = styled.div<VideoHeaderProps>``

const SeriesTitle = styled(H3Strong)`
  width: 50%;
  position: relative;
  left: -0.25rem;
  @media screen and (max-width: 1000px) {
    font-size: var(--s32);
    line-height: var(--s56);
  }
  @media screen and (max-width: 700px) {
    font-size: var(--s24);
    line-height: var(--s32);
  }
`

const HeroContainer = styled.div`
  min-height: var(--s376);
  max-height: var(--s608);
  position: relative;
  background-color: rgb(var(--colors-neutral-900));
  padding: var(--s32) var(--s56);
  & div {
    text-rendering: geometricPrecision; // Fix light text thickness
    color: rgb(var(--colors-neutral-0));
  }
`

const BackgroundImage = styled.div<{ backgroundImageUrl?: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 34%;
  right: 0;
  background-color: rgb(var(--colors-neutral-900));
  ${({ backgroundImageUrl }) =>
    backgroundImageUrl ? `background-image: url(${backgroundImageUrl});` : ''}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: var(--s32) var(--s56);
  & * {
    text-rendering: geometricPrecision; // Fix light text thickness
    color: rgb(var(--colors-neutral-0));
  }
  @media screen and (max-width: 860px) {
    left: 24%;
  }
`

const IntroMessage = styled(H6Strong)`
  padding-bottom: var(--s88);
  margin-bottom: var(--s8);
`

const FadeBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 70%;
  background: linear-gradient(
    90deg,
    rgb(var(--colors-neutral-900)) 0%,
    rgb(var(--colors-neutral-900)) 50%,
    ${setOpacity('eq-color-neutral-900', 0)} 100%
  );
  @media screen and (max-width: 860px) {
    width: 50%;
  }
`

const CTA = styled(Button)`
  margin-top: calc(var(--s32) - var(--s16));
`

const Foreground = styled.div`
  position: relative;
  margin-bottom: var(--s144);
`

export const VideoHeader = (props: VideoHeaderProps) => {
  const { playlist, backgroundImageUrl, userProfile } = props
  // TODO: temp
  const firstVideo = playlist?.videosCollection.items[0]

  const firstName =
    userProfile?.fullname === userProfile?.email
      ? undefined
      : userProfile?.fullname.split(' ')[0]

  const numVideos = playlist?.videosCollection.items.length || 0
  return (
    <Container {...props}>
      <HeroContainer>
        <BackgroundImage
          backgroundImageUrl={
            backgroundImageUrl ||
            (playlist?.heroImage?.url ? `${playlist.heroImage.url}?fm=jpg` : '')
          }
        ></BackgroundImage>
        <FadeBackground></FadeBackground>
        <Foreground>
          <IntroMessage>{firstName ? `Welcome ${firstName}` : ''}</IntroMessage>
          <LayoutStack>
            <Overline>Featured</Overline>
            <SeriesTitle>{ellipsify(playlist?.title || '', 75)}</SeriesTitle>
            <SubtitleMono>
              {playlist &&
                dayjs(
                  playlist.displayDate || playlist.sys.firstPublishedAt
                ).format('MMM D')}{' '}
              • {numVideos === 1 ? `${numVideos} video` : `${numVideos} videos`}
            </SubtitleMono>
            <Link to={ROUTES.video(playlist?.sys.id, firstVideo?.sys.id)}>
              <CTA variant="secondary" color="neutral">
                View series
              </CTA>
            </Link>
          </LayoutStack>
        </Foreground>
      </HeroContainer>
    </Container>
  )
}
