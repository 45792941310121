import * as React from 'react'
import { usePrevious } from 'react-use'
import { equals } from 'remeda'

import type { UniformFieldOption } from '../types'

export const useSelectAsyncInitialValueOrReset = ({
  options = [],
  selectItem = () => {},
  value,
  depsValues = [],
}: {
  options: UniformFieldOption[]
  selectItem: (item: UniformFieldOption | UniformFieldOption[] | null) => void
  value: string | string[]
  depsValues: string[]
}) => {
  const initialized = React.useRef(false)
  const prevOptions = usePrevious(options)
  /** initialize async options for the first time */
  React.useEffect(() => {
    if (
      !initialized.current &&
      !equals(prevOptions, options) &&
      options.length > 0
    ) {
      initialized.current = true
      let alreadySelected:
        | UniformFieldOption
        | UniformFieldOption[]
        | undefined = undefined

      if (Array.isArray(value)) {
        alreadySelected = options.filter((option) =>
          value.includes(option.value)
        )
      } else {
        alreadySelected = options.find((option) => option.value === value)
      }

      if (alreadySelected) {
        selectItem(alreadySelected)
      } else {
        selectItem(null)
      }
    }
  }, [options, prevOptions, selectItem, value])

  /** watch dependency value change and reset selected items */
  const prevDepsValues = usePrevious(depsValues)
  React.useEffect(() => {
    if (!prevDepsValues || !initialized.current) return

    const shouldntReset = prevDepsValues.every((value, index) => {
      // if deps value is undefined, it means it's not been set yet and we want to ignore if this value has changed.
      if (typeof value === 'undefined') return true
      return value === depsValues[index]
    })

    if (!shouldntReset) {
      selectItem(null)
    }
  }, [prevDepsValues, depsValues, selectItem])
}
