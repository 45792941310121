import { CircularProgress } from '@mui/material'
import classNames from 'classnames'
import { useSelect } from 'downshift'
import React from 'react'
import { usePrevious } from 'react-use'
import { equals } from 'remeda'
import { useSpinDelay } from 'spin-delay'

import { BodySmall } from '@cais-group/approved/ui/typography'
import { Icon } from '@cais-group/equity/atoms/icon'
import { StatusTag, StatusTagProps } from '@cais-group/equity/atoms/status-tag'
import { DefaultValueContainer } from '@cais-group/equity/molecules/select'

import { Tooltip } from '../../components/tooltip'
import { useEventListeners } from '../../hooks'
import { useSelectAsyncInitialValueOrReset } from '../../hooks/use-select-async-initial-value-or-reset'
import { UniformFieldOption } from '../../types'
import { ActionButton } from '../common/action-button'
import {
  InfiniteLoadingIndicator,
  useWatchLastItemInList,
} from '../common/infinite-loading'
import type { OptionLayoutProps } from '../common/option-layout'
import { OptionLayout } from '../common/option-layout'
import type { OptionsLayoutProps } from '../common/options-layout'
import { CustomOptionsLayouts } from '../common/options-layout'

export interface SelectProps extends OptionLayoutProps, OptionsLayoutProps {
  id: string
  name?: string
  placeholder?: string
  showSearchIcon?: boolean
  value: string
  onBlur?: () => void
  onFocus?: () => void
  onChange: (option?: UniformFieldOption | null) => void
  label?: string
  options: UniformFieldOption[]
  required?: boolean
  disabled?: boolean
  errorMessage?: string
  error?: boolean
  tag?: StatusTagProps
  fetchNextPage?: () => void
  isLoading?: boolean
  hasNextPage?: boolean
  dropdownActionName?: string
  dropdownAction?: (finalRef: HTMLButtonElement | null) => void
  tooltip?: string
  depsValues: string[]
}
export const Select = React.forwardRef<Partial<HTMLDivElement>, SelectProps>(
  (
    {
      id,
      name,
      placeholder,
      showSearchIcon,
      onChange = () => {},
      onFocus = () => {},
      value,
      required,
      label,
      options = [],
      error,
      errorMessage,
      optionLayout,
      optionsLayout,
      optionsLayoutGroupBy,
      tag,
      fetchNextPage = () => {},
      isLoading,
      hasNextPage,
      disabled,
      dropdownAction,
      tooltip,
      dropdownActionName,
      depsValues,
    },
    ref
  ) => {
    const [usingKeyboard, setUsingKeyboard] = React.useState(false)
    const btnRef = React.useRef<HTMLButtonElement>(null)
    const divRef = React.useRef<HTMLDivElement>(null)
    const showLoader = useSpinDelay(Boolean(isLoading), {
      delay: 400,
      minDuration: 1000,
    })
    React.useImperativeHandle(ref, () => ({
      focus() {
        btnRef.current?.focus()
      },
      scrollIntoView() {
        btnRef.current?.scrollIntoView()
      },
    }))
    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
      closeMenu,
      selectItem,
    } = useSelect({
      id,
      items: options,
      itemToString(item) {
        return item ? item.label : ''
      },
      initialSelectedItem: options.find((o) => o.value === value),
      onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
      stateReducer(state, actionAndChanges) {
        const { changes, type } = actionAndChanges
        switch (type) {
          case useSelect.stateChangeTypes.ToggleButtonBlur: {
            // if not using keyboard then use default behavior
            if (!usingKeyboard) return changes
            // if no dropdown action then use default behavior
            if (!dropdownAction) return changes
            // otherwise if using keyboard keep the menu open on blur to reach the action button
            return {
              ...changes,
              isOpen: true, // keep the menu open after selection.
              selectedItem: state.selectedItem ?? undefined,
            }
          }
          default:
            return changes
        }
      },
    })
    useSelectAsyncInitialValueOrReset({
      options,
      selectItem: (value) => {
        if (!Array.isArray(value) || value === null) {
          selectItem(value)
        }
      },
      value: selectedItem?.value ?? value,
      depsValues,
    })
    useEventListeners(['keydown', 'mousedown'], {
      enabled: isOpen,
      subscribe: (type) => setUsingKeyboard(type === 'keydown'),
    })
    useWatchLastItemInList(divRef, {
      callback: () => isOpen && fetchNextPage(),
      dependencies: [isOpen],
    })

    /** if already selected item in options was updated, we want to update inner state */
    const prevOptions = usePrevious(options)
    React.useEffect(() => {
      if (!equals(prevOptions, options)) {
        const alreadySelected = options.find((option) => option.value === value)
        if (alreadySelected) selectItem(alreadySelected)
      }
    }, [options, prevOptions, value, selectItem])

    /** if rhf value was updated, we want to update selected option */
    const prevValue = usePrevious(value)
    React.useEffect(() => {
      if (prevValue === value) return
      const item = options.find((option) => option.value === value)
      if (item) selectItem(item)
    }, [value, prevValue, options, selectItem])

    return (
      <div className="relative w-full">
        <div className="flex flex-col gap-4">
          <label className="flex w-fit items-center" {...getLabelProps()}>
            <BodySmall className="text-neutral-600">
              {label}{' '}
              {required ? <span className="text-error-500">*</span> : null}
            </BodySmall>
            {tooltip && <Tooltip tooltip={tooltip} />}
            {tag?.children !== '' && tag?.children !== undefined && (
              <div className="my-8 ml-8">
                <StatusTag {...tag} />
              </div>
            )}
          </label>
          <div
            {...getToggleButtonProps({
              ref: btnRef,
              onFocus,
              // onBlur, not used because it triggers the validation for split second before the value is updated
              name,
              disabled,
              className: classNames(
                'bg-neutral-0 inline-flex flex-wrap items-center gap-8 border border-solid p-12',
                disabled && 'bg-neutral-100 border-neutral-200 ',
                error || errorMessage
                  ? 'border-error-500'
                  : 'border-neutral-200'
              ),
            })}
          >
            <span className="bodySmall flex flex-1">
              {selectedItem ? (
                selectedItem.label
              ) : (
                <DefaultValueContainer>
                  {showSearchIcon ? (
                    <Icon
                      type="Search"
                      color="eq-color-neutral-900"
                      size="small"
                    />
                  ) : null}
                  {placeholder ? placeholder : null}
                </DefaultValueContainer>
              )}
            </span>
            <div className="flex-0 flex">
              {showLoader ? (
                <CircularProgress size={24} thickness={5} />
              ) : (
                <span className="flex items-center px-4">
                  <Icon
                    size="small"
                    color="eq-color-neutral-900"
                    type="ArrowDropDown"
                  />
                </span>
              )}
            </div>
          </div>
          {error && errorMessage && (
            <BodySmall className="text-error-500">{errorMessage}</BodySmall>
          )}
        </div>
        {optionsLayout ? (
          <div
            className={classNames(
              'shadow-2 bg-neutral-0 max-h-376 absolute z-10 mt-4 w-full overflow-auto p-0',
              !(isOpen && options.length) && 'hidden'
            )}
            {...getMenuProps()}
          >
            {isOpen && (
              <>
                <CustomOptionsLayouts
                  items={options}
                  getItemProps={getItemProps}
                  optionLayout={optionLayout}
                  highlightedIndex={highlightedIndex}
                  optionsLayout={optionsLayout}
                  selectedItem={selectedItem}
                  optionsLayoutGroupBy={optionsLayoutGroupBy}
                />
                <InfiniteLoadingIndicator
                  ref={divRef}
                  isLoading={isLoading}
                  className={hasNextPage ? '' : 'hidden'}
                />
                {dropdownAction && (
                  <ActionButton
                    text={
                      dropdownActionName ? dropdownActionName : 'Create New'
                    }
                    onBlur={() => closeMenu()}
                    onClick={() => dropdownAction(btnRef.current)}
                  />
                )}
              </>
            )}
          </div>
        ) : (
          <ul
            className={classNames(
              'shadow-2 bg-neutral-0 max-h-376 absolute z-10 mt-4 w-full overflow-auto p-0 px-24 py-16',
              !isOpen && 'hidden'
            )}
            {...getMenuProps()}
          >
            {isOpen && (
              <>
                {options.map((item, index) => {
                  return (
                    <li key={index} {...getItemProps({ item, index })}>
                      <OptionLayout
                        optionLayout={optionLayout}
                        item={item}
                        highlighted={highlightedIndex === index}
                        selected={selectedItem?.value === item.value}
                      />
                    </li>
                  )
                })}
                {options.length > 0 && (
                  <InfiniteLoadingIndicator
                    ref={divRef}
                    isLoading={isLoading}
                    className={hasNextPage ? '' : 'hidden'}
                  />
                )}
                {dropdownAction && (
                  <ActionButton
                    text={
                      dropdownActionName ? dropdownActionName : 'Create New'
                    }
                    onBlur={() => closeMenu()}
                    onClick={() => dropdownAction(btnRef.current)}
                  />
                )}
              </>
            )}
          </ul>
        )}
      </div>
    )
  }
)
