import {
  ApiState,
  useReactQueryResultAsApiState,
} from '@cais-group/caisiq/domain/common'
import { useGetVideoQuery } from '@cais-group/caisiq/util/graphql/contentful'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'

import { TypeVideoDataWithParentPlaylist } from './types'

export type VideoApiState = ApiState<TypeVideoDataWithParentPlaylist>

export type VideoResult = {
  caisiqVideo: TypeVideoDataWithParentPlaylist
}

export const useGetVideo = (id?: string): VideoApiState =>
  useReactQueryResultAsApiState(
    useGetVideoQuery(
      { id: id || '', preview: previewService.enabled },
      { enabled: id !== undefined }
    ),
    (data) => data.caisiqVideo as unknown as TypeVideoDataWithParentPlaylist,
    'Could not load caisiqVideoEntryQuery'
  )
