import { Theme as DefaultTheme } from '@emotion/react'

export const uiThemePrimary: Partial<DefaultTheme> = {
  name: 'primary',
  navHeaderWithHero: {
    backgroundColor: '#0067c6',
    foregroundColor: '#ffffff',
    underlineColor: 'transparent',
    underlineOpacity: 0,
    caisIqLogo: 'light',
    clientLogoUrl: undefined,
    poweredBy: undefined,
  },

  navHeaderWithIframe: {
    backgroundColor: '#0067c6',
    foregroundColor: '#ffffff',
    underlineColor: 'transparent',
    underlineOpacity: 0,
    caisIqLogo: 'light',
    clientLogoUrl: undefined,
    poweredBy: undefined,
  },

  navHeaderForAdmin: {
    backgroundColor: '#0067c6',
    foregroundColor: '#ffffff',
    underlineColor: 'transparent',
    underlineOpacity: 0,
    caisIqLogo: 'light',
    clientLogoUrl: undefined,
    poweredBy: undefined,
  },
}
