import styled from '@emotion/styled'

import { BodySmallCss } from '@cais-group/approved/ui/typography'

import { ErrorPanel } from '..'

const StyledErrorsList = styled.ul`
  ${BodySmallCss}
  &&& {
    list-style: inside;
  }
`

export type FieldsErrorPanelProps = {
  fields: string[]
  className?: string
}
export function FieldsErrorPanel({ fields, className }: FieldsErrorPanelProps) {
  return fields.length > 0 ? (
    <ErrorPanel
      className={className}
      title="Please check the following fields for errors"
    >
      <StyledErrorsList>
        {fields.map((field) => (
          <li key={`${field}-error-field`}>{field}</li>
        ))}
      </StyledErrorsList>
    </ErrorPanel>
  ) : null
}
