import styled from '@emotion/styled'
import * as React from 'react'

import { H5Strong } from '@cais-group/approved/ui/typography'
import {
  useCourseModal,
  useGetCourseCeCredits,
  useGetCourses,
} from '@cais-group/caisiq/domain/course'
import { DefaultCourseImage } from '@cais-group/caisiq/domain/course-image'
import { getHeaderLabel } from '@cais-group/caisiq/feature/common/get-header-label'
import { CardCourseLibrary } from '@cais-group/caisiq/ui/card/course-library'
import { getNextFallbackImageIndex } from '@cais-group/caisiq/util/js/get-next-fallback-image-index'
import { Pill } from '@cais-group/caisiq-ui-pill'
import { LayoutGrid } from '@cais-group/shared/ui/layout'
import { Course } from '@cais-group/shared/util/type/caisiq-be'

const StyledGrid = styled(LayoutGrid)`
  padding-top: var(--s24);
  padding-bottom: var(--s56);
`
StyledGrid.displayName = 'StyledGrid'

const StyledPill = styled(Pill)`
  position: relative;
  margin-left: var(--s8);
  margin-top: var(--s8);
`
StyledPill.displayName = 'StyledPill'

const StyledContainerWithCounter = styled.div`
  display: flex;
`
StyledContainerWithCounter.displayName = 'StyledContainerWithCounter'

const GridSeparator = styled.div`
  margin-bottom: var(--s56);
  border-bottom: 1px solid rgb(var(--colors-neutral-200));
`
GridSeparator.displayName = 'GridSeparator'

type CourseGroupProps = {
  name?: string
  libraryCourses: Course[]
  setPendingUnenrollCallback: (
    value: React.SetStateAction<(() => void) | undefined>
  ) => void
  setLibraryCourseToUnenrollId: (value?: string) => void
  withSeparator?: boolean
  courseImages: DefaultCourseImage[]
}

function LibraryCoursesThemeGroup(props: CourseGroupProps) {
  const {
    name,
    libraryCourses,
    setPendingUnenrollCallback,
    setLibraryCourseToUnenrollId,
    withSeparator,
    courseImages,
  } = props

  const { openModal } = useCourseModal()
  const { ceCreditsAvailable } = useGetCourseCeCredits()
  const courses = useGetCourses()
  return (
    <>
      {name && (
        <StyledContainerWithCounter>
          <H5Strong data-testid="course-theme-name">{name}</H5Strong>
          <StyledPill
            label={libraryCourses.length}
            backgroundColor={
              libraryCourses.length === 0
                ? 'rgb(var(--colors-neutral-0))'
                : 'rgb(var(--colors-neutral-200))'
            }
            data-testid="course-theme-count"
          />
        </StyledContainerWithCounter>
      )}
      <StyledGrid>
        {libraryCourses.map((libraryCourse) => (
          <CardCourseLibrary
            fallbackImageUrl={
              courseImages?.length
                ? courseImages[getNextFallbackImageIndex(libraryCourse.id)].url
                : ''
            }
            key={libraryCourse.id}
            course={libraryCourse}
            onAttemptUnenroll={(callback) => {
              // Anonymous function needed to set state to a function
              setPendingUnenrollCallback(() => callback)
              setLibraryCourseToUnenrollId(libraryCourse.id)
            }}
            onClickViewDetails={(libraryCourse) => {
              openModal(libraryCourse)
            }}
            headerLabel={getHeaderLabel(ceCreditsAvailable(libraryCourse))}
            courses={courses}
          />
        ))}
      </StyledGrid>
      {withSeparator && <GridSeparator />}
    </>
  )
}

export { LibraryCoursesThemeGroup }
