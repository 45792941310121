import { useNavigate } from 'react-router-dom'

import { Pill } from '../lib/pill'

type SemanticColor = 'neutral' | 'primary' | 'accent' | 'success' | 'error'

interface BackPillButtonProps {
  /** Optional action to perform when the button is clicked instead of the default one which navigate to the previous page `navigate(-1)`*/
  onClick?: () => void
  /** The label to display on the button */
  label?: string
  /** The color of the button */
  color?: SemanticColor
}

/**
 * Since some uses of the Pill are common, they have been prebuilt.
 * Below is the Back Pill Button that is used frequently across all applications.
 */
export const BackPillButton = ({
  onClick,
  label = 'Back',
  color = 'neutral',
}: BackPillButtonProps) => {
  const navigate = useNavigate()
  return (
    <Pill
      label={label}
      color={color}
      icon="ArrowBackLrg"
      size="small"
      onClick={onClick ? onClick : () => navigate(-1)}
    />
  )
}
