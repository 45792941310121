import { Icon, IconProps, iconSizes } from '@cais-group/equity/atoms/icon'
import { AllColor } from '@cais-group/equity/particles/colors'
import { Prettify } from '@cais-group/equity/util/type-utils'

import { Tooltip, TooltipProps } from './tooltip'

/**
 * A simple ℹ icon which shows a tooltip on hover
 *
 * {@link IconTooltip} exposes a much fuller API for custom appearance of icon and tooltip
 */
export function SimpleInfoTooltip({
  children,
  color = 'eq-color-neutral-400',
  size = 'regular',
  placement,
}: {
  children: React.ReactElement | string
  size?: IconProps['size']
  placement?: TooltipProps['placement']
  color?: AllColor
}) {
  // Note at the moment this API is very simple; if you do need more control consider adding things with defaults (so default use is still very simple but is more customizable)
  return (
    <IconTooltip
      type="InfoOutline"
      color={color}
      size={size}
      placement={placement}
    >
      {children}
    </IconTooltip>
  )
}

export type IconTooltipProps = Prettify<
  Omit<TooltipProps, 'children' | 'color' | 'size' | 'title'> &
    Pick<IconProps, 'type' | 'color' | 'size'> & {
      children: React.ReactElement | string
    }
>

/**
 * When you want to have an icon with a tooltip this wraps the icon to avoid issues whereby the icon doesn't expose props the tooltip functionality depends upon
 *
 * {@link SimpleInfoTooltip} may be sufficient for most common use cases
 */
export function IconTooltip({
  type,
  color,
  size = 'regular',
  children,
  ...props
}: IconTooltipProps) {
  const iconSize = iconSizes[size]
  return (
    <Tooltip {...props} title={children}>
      {/* Didn't really want to have to export the iconSize, but want div to match size of icon for tooltip placement, plus want to avoid the extra margin thing on images that would be added with the SVG image that powers Icon, at least in some cases */}
      <div style={{ width: iconSize, height: iconSize }}>
        <Icon type={type} color={color} size={size} />
      </div>
    </Tooltip>
  )
}
