import { ThemeOptions, createTheme } from '@mui/material/styles'

import { SPACING_UNITS } from '@cais-group/shared/ui/design-tokens'

import { breakpoints } from './breakpoints'
import { components } from './components'
import { palette } from './palette'
import { shadows } from './shadows'
import { typography } from './typography'

export * from './components'

const theme: ThemeOptions = {
  palette,
  breakpoints,
  components,
  shadows,
  typography,
  shape: {
    borderRadius: 0,
  },
  spacing: Object.values(SPACING_UNITS),
}

export const muiTheme = createTheme(theme)
