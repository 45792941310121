import * as React from 'react'

import type { UniformServices } from '../services'
import type { ComponentDict, UniformAny } from '../types'

import { AddressInput } from './address'
import type { CheckboxInputProps } from './checkbox'
import type { CheckboxGroupInputProps } from './checkbox-group'
import type { ComboBoxInputProps } from './combobox'
import type { DatePickerInputProps } from './date-picker'
import type { MultiSelectProps } from './equity-multi-select'
import type { SelectProps } from './equity-select'
import type { FileCardProps } from './file-card'
import type { FileUploaderProps } from './file-uploader'
import type { GroupProps } from './group'
import type { MultipleComboBoxInputProps } from './multiple-combobox'
import type { RadioGroupInputProps } from './radio-group'
import type { SelectInputProps } from './select'
import type { SwitchInputProps } from './switch-input'
import type { PhoneInputProps } from './tel'
import type { TextInputProps } from './text'

type RCT<T> = React.ComponentType<T>

export function createDefaultUniformDictionary<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
>() {
  // NOTE: all components have to support generics for ids and services in order for the types to properly work in schema.
  const text = React.lazy<RCT<TextInputProps<TFormFieldIds, TUniformServices>>>(
    () => import('./text')
  )

  return {
    password: text,
    email: text,
    number: text,
    text: text,
    heading: React.lazy(() => import('./heading')),
    FileCard: React.lazy<RCT<FileCardProps<TFormFieldIds, TUniformServices>>>(
      () => import('./file-card')
    ),
    FileUploader: React.lazy<
      RCT<FileUploaderProps<TFormFieldIds, TUniformServices>>
    >(() => import('./file-uploader')),
    PhoneInput: React.lazy<RCT<PhoneInputProps<TFormFieldIds>>>(
      () => import('./tel')
    ),
    MultipleComboBoxInput: React.lazy<
      RCT<MultipleComboBoxInputProps<TFormFieldIds, TUniformServices>>
    >(() => import('./multiple-combobox')),
    ComboBoxInput: React.lazy<
      RCT<ComboBoxInputProps<TFormFieldIds, TUniformServices>>
    >(() => import('./combobox')),
    // FIXME: SelectInput and Select are confusing - we should probably get rid of SelectInput?
    SelectInput: React.lazy<
      RCT<SelectInputProps<TFormFieldIds, TUniformServices>>
    >(() => import('./select')),
    Select: React.lazy<RCT<SelectProps<TFormFieldIds, TUniformServices>>>(
      () => import('./equity-select')
    ),
    MultiSelect: React.lazy<
      RCT<MultiSelectProps<TFormFieldIds, TUniformServices>>
    >(() => import('./equity-multi-select')),
    RadioGroupInput: React.lazy<
      RCT<RadioGroupInputProps<TFormFieldIds, TUniformServices>>
    >(() => import('./radio-group')),
    CheckboxInput: React.lazy<RCT<CheckboxInputProps<TFormFieldIds>>>(
      () => import('./checkbox')
    ),
    DatePickerInput: React.lazy<RCT<DatePickerInputProps<TFormFieldIds>>>(
      () => import('./date-picker')
    ),
    CheckboxGroupInput: React.lazy<
      RCT<CheckboxGroupInputProps<TFormFieldIds, TUniformServices>>
    >(() => import('./checkbox-group')),
    SwitchInput: React.lazy<RCT<SwitchInputProps<TFormFieldIds>>>(
      () => import('./switch-input')
    ),
    AddressInput: AddressInput<TFormFieldIds>, // NOTE: lazy loading composite component causes a circular reference bug
  }
}

/**
 * @description Creates a dictionary of components that can be used to render the form.
 * please not that the component key has to match the `type` property of the UniformFieldProps<"">
 * @example
 * ```createUniformDictionary({ uploader: (props: UniformFieldProps<'uploader'>) => <div>hello</div> }) })```
 */
export function createUniformDictionary<
  TFormFieldIds extends keyof UniformAny = UniformAny,
  TUniformServices extends UniformServices = UniformServices
>() {
  const components = createDefaultUniformDictionary<
    TFormFieldIds,
    TUniformServices
  >()

  return <TComponentDict extends ComponentDict = ComponentDict>(
    extend: TComponentDict = {} as TComponentDict
  ) => ({
    // NOTE: components declared in here won't be able to nest themselves because of circular reference issue.
    group: React.lazy<
      RCT<
        GroupProps<
          TUniformServices,
          ReturnType<
            typeof createDefaultUniformDictionary<
              TFormFieldIds,
              TUniformServices
            >
          > &
            TComponentDict,
          TFormFieldIds
        >
      >
    >(() => import('./group')),
    ...components,
    ...extend,
  })
}

export function createEquityUniformDictionary<
  TComponentDict extends ComponentDict = ComponentDict
>(extend: TComponentDict = {} as TComponentDict) {
  return {
    text: React.lazy(() => import('./text')),
    CheckboxInput: React.lazy(() => import('./checkbox')),
    CheckboxGroupInput: React.lazy(() => import('./checkbox-group')),
    // ...extend,
  }
}
