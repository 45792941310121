import styled from '@emotion/styled'

import { Label } from '@cais-group/approved/ui/typography'
import { SPACING } from '@cais-group/shared/ui/design-tokens'

const NumberOfRows = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${SPACING.s12};
`

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const StyledCounter = styled.div`
  background-color: rgb(var(--colors-neutral-200));
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  min-width: ${SPACING.s32};
  width: fit-content;
  height: ${SPACING.s32};
  padding: ${SPACING.s4} 10px;
`
export const TableControls = ({
  count,
  information,
}: {
  count: number
  information: string
}) => (
  <Container>
    <NumberOfRows>
      <StyledCounter>{count}</StyledCounter>
      <Label>{information}</Label>
    </NumberOfRows>
  </Container>
)
