import { Components } from '@mui/material'

import { H6StrongCssObject } from '@cais-group/approved/ui/typography'

export const MuiPopover: Components['MuiPopover'] = {
  styleOverrides: {
    root: { '& .MuiButton-text': H6StrongCssObject },
    paper: {
      boxShadow: ['none'],
      borderRadius: 0,
    },
  },
}
