export function linkToMfe(relativeLink: string) {
  const hostName = window.location.hostname

  // Specific check for localhost
  if (hostName === 'localhost') {
    return relativeLink
  }

  let mfeHost

  // Match using regex, this will _not_ match prod
  const match = window.location.hostname.match(/[.]([^.]+)\.caisgroup\.com/)
  const env = match ? match[1] : 'prod' // we don't handle this below as it's the default
  switch (env) {
    case 'dev':
      mfeHost = 'members.dev.caisgroup.com/'
      break
    case 'qa':
      mfeHost = 'members.qa.caisgroup.com/'
      break
    case 'ephemeral':
      mfeHost = hostName.replace('caisiq-monorepo', 'members-monorepo')
      break
    default:
      mfeHost = 'members.caisgroup.com/'
  }

  return new URL(
    relativeLink,
    `${window.location.protocol}//${mfeHost}`
  ).toString()
}
