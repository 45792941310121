import { uiTypeSize } from '@cais-group/shared/ui/type/size'
import styled from '@emotion/styled'

import { Justify } from '../common'

export type Align =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'stretch'
  | 'baseline'

export interface LayoutStackProps {
  space?: uiTypeSize
  justify?: Justify
  'data-layout'?: string
  href?: string
  target?: string
}

export const LayoutStack = styled.div<LayoutStackProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || 'flex-start'};

  & > * {
    margin: 0;
  }
  & > * + * {
    margin-top: ${(props) => props.space || 'var(--s16)'};
  }
`
LayoutStack.displayName = 'LayoutStack'
LayoutStack.defaultProps = {
  'data-layout': 'LayoutStack',
}
