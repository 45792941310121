import styled from '@emotion/styled'
import { forwardRef, ReactNode } from 'react'

import { CardDismissable } from '@cais-group/shared/ui/card/dismissable'
import { LayoutImposter } from '@cais-group/shared/ui/layout'

const ResponsiveImposter = styled(LayoutImposter)`
  @media screen and (max-width: 860px) {
    position: relative;
    top: 0;
    left: 0;
    min-height: 100%;
    transform: none;
    max-width: none;
    max-height: none;
    overflow: auto;
  }
`

/**
 * A full screen clickable semi-transparent background,
 * on smaller screens this becomes a white opaque background.
 */
export const Blanker = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(var(--colors-neutral-900));
  opacity: 84%;
  @media screen and (max-width: 860px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(var(--colors-neutral-0));
    opacity: 1;
  }
`

export type ModalDefaultProps = {
  children?: ReactNode
  header?: ReactNode
  onClose: () => void
  className?: string
  dataTestId?: string
}

/**
 * This modal is only used inside ModalPortal, it is the visual component of the modal
 * @deprecated Use MUI modal instead
 */
export const ModalDefault = forwardRef<HTMLDivElement, ModalDefaultProps>(
  (props, ref) => {
    const { children, onClose, header, className, dataTestId } = props
    return (
      <>
        <Blanker />
        <ResponsiveImposter
          margin="var(--s16)"
          className={className}
          data-testid={dataTestId}
        >
          <CardDismissable ref={ref} onClose={onClose} header={header}>
            {children}
          </CardDismissable>
        </ResponsiveImposter>
      </>
    )
  }
)
