import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'

import { fetchInstance } from '@cais-group/shared/util/fetch'

export const formDataForDocumentUpload = (body: {
  file: Blob
  request: object
}): FormData => {
  const formData = new FormData()
  const blob = new Blob([JSON.stringify(body.request)], {
    type: 'application/json',
  })
  formData.append('request', blob)
  formData.append('file', body.file)
  return formData
}

export async function fetchInstanceAxios<T>(
  config: AxiosRequestConfig,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) {
  return fetchInstance<T>({
    ...config,
    onUploadProgress,
  })
}
