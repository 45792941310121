import { AxiosError, AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { ApiPaths, Learner } from '@cais-group/shared/util/type/caisiq-be'

export function useGetUserLicences(
  options?: Omit<
    UseQueryOptions<
      AxiosResponse<Learner>,
      AxiosError,
      Learner,
      ApiPaths.getLearnerV2
    >,
    'queryKey'
  >
) {
  return useQuery(ApiPaths.getLearnerV2, options)
}

/**
 * Convenience wrapper to API call to add test for if user has one or more licences saved
 */
export function useUserHasLicences() {
  const { data, isFetched } = useGetUserLicences()

  const cimaNumber = data?.cima?.number
  const cfpNumber = data?.cfp?.number
  return { hasSavedLicences: Boolean(cimaNumber || cfpNumber), isFetched }
}
