import { TailwindEquityColor } from '@cais-group/equity/particles/colors'

import { RadioColor } from './types'

/**
 * A map of border colors for different radio states for each radio color.
 */
const borderColorMap: Record<RadioColor, Array<TailwindEquityColor>> = {
  primary: [
    'border-neutral-300',
    'group-hover:peer-enabled:peer-checked:border-primary-700',
    'group-hover:peer-enabled:border-primary-600',
    'peer-checked:peer-enabled:border-primary-600',
  ],
  success: [
    'border-neutral-300',
    'group-hover:peer-enabled:peer-checked:border-success-700',
    'group-hover:peer-enabled:border-success-600',
    'peer-checked:peer-enabled:border-success-600',
  ],
  error: [
    'border-neutral-300',
    'group-hover:peer-enabled:peer-checked:border-error-700',
    'group-hover:peer-enabled:border-error-600',
    'peer-checked:peer-enabled:border-error-600',
  ],
}

/**
 * A map of background colors for different radio states for each radio color.
 */
const backgroundColorMap: Record<RadioColor, Array<TailwindEquityColor>> = {
  primary: [
    'peer-checked:peer-disabled:bg-neutral-300',
    'peer-disabled:bg-neutral-100',
    'peer-checked:peer-enabled:bg-primary-600',
    'group-hover:peer-checked:peer-enabled:bg-primary-700',
  ],
  success: [
    'peer-checked:peer-disabled:bg-neutral-300',
    'peer-disabled:bg-neutral-100',
    'peer-checked:peer-enabled:bg-success-600',
    'group-hover:peer-checked:bg-success-700',
  ],
  error: [
    'peer-checked:peer-disabled:bg-neutral-300',
    'peer-disabled:bg-neutral-100',
    'peer-checked:peer-enabled:bg-error-600',
    'group-hover:peer-checked:bg-error-700',
  ],
}

/**
 * Returns a string of tailwind class names representing the border colors for the given radio color.
 * @param color The radio semantic color.
 */
export const getBorderColor = (color: RadioColor): string => {
  return borderColorMap[color].join(' ')
}

/**
 * Returns a string of tailwind class names representing the background colors for the given radio color.
 * @param color The radio semantic color.
 */
export const getBackgroundColor = (color: RadioColor): string => {
  return backgroundColorMap[color].join(' ')
}
