import styled from '@emotion/styled'
import { Box, InputAdornment } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useRecoilState } from 'recoil'

import { HasPermissions } from '@cais-group/caisiq/domain/common/has-permissions'
import { modalFormStateAtom } from '@cais-group/caisiq/domain/manage-caisiq'
import { useContentfulNameFromIdMapper } from '@cais-group/caisiq/util/contentful/use-contentful-name-from-id-mapper'
import { transformExperienceToList } from '@cais-group/caisiq/util/manage/transform-data-to-list-items'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import {
  ExperienceFields,
  ExperienceListBox,
} from '@cais-group/caisiq/util/type/manage-caisiq'
import { Button } from '@cais-group/equity/atoms/button'
import { Icon } from '@cais-group/equity/atoms/icon'
import { StatusTag } from '@cais-group/equity/atoms/status-tag'
import { colors } from '@cais-group/equity/particles/colors'
import { DisabledList } from '@cais-group/shared/ui/disabled-list'
import { TextInput } from '@cais-group/shared/ui/input/text'
import {
  OrderedFieldset,
  useStepState,
} from '@cais-group/shared/ui/ordered-fieldset'
import { SelectBox, SelectOption } from '@cais-group/shared/ui/select-box'
import { SelectBoxAutocomplete } from '@cais-group/shared/ui/select-box-autocomplete'
import {
  ExperienceResponse,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'

import { CreateExperienceModal } from './components/CreateExperienceModal'
import { FormLabel } from './components/FormLabel'
import { useUpdateDisabledFormLabels } from './hooks/useUpdateDisabledFormLabels'

const Container = styled.div`
  & .MuiFormLabel-root {
    display: flex;
    align-items: center;
  }
  & .MuiFormLabel-asterisk {
    color: ${colors['eq-color-error-600']};
    margin-right: 2px;
  }
`

const TagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

interface ExperienceNameProps {
  firmExperiences?: ExperienceResponse[]
  order: number
  legend?: ReactElement
  contentfulExperiences?: ExperienceListBox
}
export const ExperienceNameStepDropdown = ({
  firmExperiences,
  order,
  legend,
  contentfulExperiences,
}: ExperienceNameProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([])
  const [modalFormState, setModalFormState] = useRecoilState(modalFormStateAtom)
  const { control, getValues, setValue } = useFormContext<ExperienceFields>()
  const currentFirmId = useWatch<ExperienceFields>({ name: 'name' })
  const getContentfulNameById = useContentfulNameFromIdMapper()
  const { deriveState, showStep } = useStepState()
  const state = deriveState(order)
  const currentFirmExperience = firmExperiences?.find(
    (firmExperience) => firmExperience.uuid === currentFirmId
  )
  const contentfulName = getContentfulNameById(
    currentFirmExperience?.contentfulId
  )
  const isDefaultExperience = (firmName?: string) =>
    firmName === 'CAIS Network Advanced'

  const closeModal = () => {
    setIsModalOpen(false)
  }

  useUpdateDisabledFormLabels({
    currentFirmExperience,
  })

  // Set the experience name to the newly created experience
  useEffect(() => {
    if (modalFormState && firmExperiences && !isModalOpen) {
      setValue('name', modalFormState.uuid, {
        shouldDirty: true,
      })
    }
  }, [firmExperiences, isModalOpen, modalFormState, setValue])

  useEffect(() => {
    if (firmExperiences?.length) {
      const options: SelectOption[] | undefined = firmExperiences.map(
        (firmExperience) => {
          return {
            value: firmExperience?.uuid,
            label: !isDefaultExperience(firmExperience?.name)
              ? firmExperience?.name
              : 'CAIS Network',
          }
        }
      )
      options.push({
        value: 'button',
        label: 'Create new experience',
        bottomButton: (
          <div
            className=" h-56 w-full border-0 border-t-2 border-solid border-neutral-200"
            key="create-experience-button"
          >
            <div
              className="[&>svg]:hover:fill-primary-700 flex w-fit cursor-pointer items-center"
              onClick={() => {
                setIsModalOpen(true)
                trackingService.manageNewExperienceStarted()
              }}
            >
              <Icon type="Add" size="small" color="eq-color-primary-600" />
              <Button variant="tertiary">Create new experience</Button>
            </div>
          </div>
        ),
      })
      setSelectOptions(options)
    }
  }, [firmExperiences])

  const details = transformExperienceToList({
    name:
      selectOptions?.find((item) => item.value === getValues('name'))?.label ||
      getValues('name'),
    label: getValues('label'),
    contentfulName: contentfulName,
  })

  return (
    <OrderedFieldset state={state} legend={legend}>
      {showStep(order) && (
        <HasPermissions capabilities={UserRole.CaisiqManageWrite}>
          <HasPermissions.Then>
            <>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Container>
                    <SelectBoxAutocomplete
                      id="name"
                      label="Experience name"
                      required
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      selectOptions={selectOptions as SelectOption[]}
                      adornmentTag={
                        isDefaultExperience(currentFirmExperience?.name) ? (
                          <InputAdornment
                            position="end"
                            sx={{
                              order: 2,
                            }}
                          >
                            <StatusTag variant="light" color="neutral">
                              Default
                            </StatusTag>
                          </InputAdornment>
                        ) : undefined
                      }
                      renderOption={(props, option) => {
                        const label = selectOptions?.find(
                          (item) => item.value === option
                        )?.label
                        const bottomButton = selectOptions?.find(
                          (item) => item.value === option
                        )?.bottomButton

                        return option !== 'button' ? (
                          <Box component="li" {...props} key={option as string}>
                            <TagContainer as="span">
                              {label}
                              {label?.includes('CAIS Network') && (
                                <StatusTag variant="light" color="neutral">
                                  Default
                                </StatusTag>
                              )}
                            </TagContainer>
                          </Box>
                        ) : (
                          bottomButton
                        )
                      }}
                    />
                  </Container>
                )}
              />
              <Controller
                name="label"
                control={control}
                render={({ field }) => (
                  <Container>
                    <TextInput
                      required
                      id="label"
                      label={
                        <FormLabel
                          label="Experience label"
                          helperText="This is the technical term for the experience and is used in URLs shared with third parties."
                        />
                      }
                      disabled
                      value={
                        isDefaultExperience(currentFirmExperience?.name)
                          ? 'caisnetwork'
                          : field.value
                      }
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                  </Container>
                )}
              />
              <Controller
                name="contentfulId"
                control={control}
                render={({ field }) => (
                  <Container>
                    <SelectBox
                      variant="standard"
                      disabled
                      required
                      id="contentfulId"
                      label={
                        <FormLabel
                          label="Contentful name"
                          helperText="This determines content and capabilities that come from Contentful."
                        />
                      }
                      hideSelectPrompt={true}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      selectOptions={
                        contentfulExperiences?.map((e) => ({
                          value: e?.sys.id,
                          label: e?.name,
                        })) as SelectOption[]
                      }
                    />
                  </Container>
                )}
              />
            </>
          </HasPermissions.Then>
          <HasPermissions.Else>
            <DisabledList details={details} />
          </HasPermissions.Else>
        </HasPermissions>
      )}
      <CreateExperienceModal
        open={isModalOpen}
        closeModal={closeModal}
        onCreateExperience={setModalFormState}
      />
    </OrderedFieldset>
  )
}
