import { colors as burgundy } from './burgundy'
import { colors as burntOrange } from './burnt-orange'
import { colors as dandelion } from './dandelion'
import { colors as goldenBrown } from './golden-brown'
import { colors as grape } from './grape'
import { colors as midnight } from './midnight'
import { colors as royalBlue } from './royal-blue'
import { colors as seaweed } from './seaweed'
import { colors as skyBlue } from './sky-blue'
import { colors as turquoise } from './turquoise'
import { Theme } from './types'

export const themes: Array<{ name: string; title: string; colors: Theme }> = [
  { name: 'burgundy', title: 'Burgundy', colors: burgundy },
  { name: 'burntOrange', title: 'Burnt Orange', colors: burntOrange },
  { name: 'dandelion', title: 'Dandelion', colors: dandelion },
  { name: 'goldenBrown', title: 'Golden Brown', colors: goldenBrown },
  { name: 'grape', title: 'Grape', colors: grape },
  { name: 'midnight', title: 'Midnight', colors: midnight },
  { name: 'royalBlue', title: 'Royal Blue', colors: royalBlue },
  { name: 'seaweed', title: 'Seaweed', colors: seaweed },
  { name: 'skyBlue', title: 'Sky Blue', colors: skyBlue },
  { name: 'turquoise', title: 'Turquoise', colors: turquoise },
]

export default themes
