import { useEffect, useState } from 'react'

import {
  createSerialisedExpirableValue,
  readSerialisedExpirableValue,
} from '@cais-group/caisiq/util/expirable-session'
import { getAxiosInstance } from '@cais-group/caisiq/util/hook/use-axios-instance'
import { getEnvConfig } from '@cais-group/caisiq-ui-env'
import { authService } from '@cais-group/shared/util/auth-service'
import { GeneralError } from '@cais-group/shared/util/general-error'
import { ApiPaths, User } from '@cais-group/shared/util/type/caisiq-be'

const ACP_TOKEN_EXPIRY = 300 // 300 minute (6 hour) expiry

const LOCAL_STORAGE_ALM_REFRESH_TOKEN = 'acpService.refreshToken'

const SESSION_KEY_TOKEN = 'acpService.acpToken'
const SESSION_KEY_PATH = 'acpService.location.path'

let learningProvider: User['learningProvider']
let isSwitchedUser = false
let exchangePromise: Promise<string> | undefined = undefined
let didInitiateFetchRedirect = false

// This initiates a redirect
const fetchRefreshToken = () => {
  const config = getEnvConfig()

  if (didInitiateFetchRedirect) return

  didInitiateFetchRedirect = true

  authService.getAccessTokenSilently().then(() => {
    sessionStorage.setItem(
      SESSION_KEY_PATH,
      sessionStorage.getItem('auth0:deeplink') ?? window.location.pathname
    )
    sessionStorage.removeItem('auth0:deeplink')
    window.location.assign(config.ACP_REDIRECT_URL as string)
  })
}

const fetchAccessToken = () => {
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_ALM_REFRESH_TOKEN)

  // If we do not have the access token then do that first
  if (typeof refreshToken !== 'string' || refreshToken.length === 0) {
    return fetchRefreshToken()
  }

  if (exchangePromise) return exchangePromise

  // Now remove the refreshToken, it can only be used once
  localStorage.removeItem(LOCAL_STORAGE_ALM_REFRESH_TOKEN)

  // Exchange the refreshToken for an accessToken
  exchangePromise = postExchangeToken(
    `${ApiPaths.oauthCallback}?code=${refreshToken}`
  )

  return exchangePromise
}

const postExchangeToken = (url: string) => {
  const axiosInstance = getAxiosInstance()

  return axiosInstance
    .post(url)
    .then(({ data }) => {
      setAccessToken(data.accessToken)
      return data.accessToken
    })
    .catch((e) => {
      console.error(e)
      sessionStorage.removeItem(SESSION_KEY_TOKEN)
      throw new GeneralError(
        'Unable to access',
        'There was a problem accessing the course from the learning provider'
      )
    })
    .finally(() => {
      exchangePromise = undefined
      localStorage.removeItem(LOCAL_STORAGE_ALM_REFRESH_TOKEN)
    })
}

const getAccessToken = async () => {
  const rawSession = sessionStorage.getItem(SESSION_KEY_TOKEN)
  const acpToken =
    rawSession === null
      ? undefined
      : readSerialisedExpirableValue<string>(rawSession)

  if (learningProvider === 'ADOBE_CAPTIVATE_PRIME') {
    if (isSwitchedUser) {
      throw new GeneralError(
        'Switch User Error!',
        'Switch user cannot be used for this experience'
      )
    }
  }

  if (!acpToken) {
    return fetchAccessToken()
  }

  return Promise.resolve(acpToken)
}

const setAccessToken = (t: string | undefined) => {
  if (t) {
    sessionStorage.setItem(
      SESSION_KEY_TOKEN,
      createSerialisedExpirableValue(t, ACP_TOKEN_EXPIRY)
    )
  } else {
    sessionStorage.removeItem(SESSION_KEY_TOKEN)
  }
}

export const acpService = {
  isActive: () => learningProvider === 'ADOBE_CAPTIVATE_PRIME',
  setUser: async (user: User) => {
    learningProvider = user.learningProvider
    isSwitchedUser = user.switchedUser
  },
  getAccessToken,
  setAccessToken,
  handleCallback: (refreshToken: string) => {
    localStorage.setItem(LOCAL_STORAGE_ALM_REFRESH_TOKEN, refreshToken)
    // redirect to our intial path
    const initialPath = sessionStorage.getItem(SESSION_KEY_PATH)
    sessionStorage.removeItem(SESSION_KEY_PATH)
    window.location.assign(initialPath ?? '/')
  },
  reset: () => sessionStorage.removeItem(SESSION_KEY_TOKEN),
}

export function useALM() {
  const [learningProviderAccessToken, setToken] = useState<string | null>(null)

  useEffect(() => {
    acpService.getAccessToken().then((token) => setToken(token ?? ''))
  })

  return { learningProviderAccessToken }
}
