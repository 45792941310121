import {
  getSortedRowModel,
  SortingState,
  TableOptions,
} from '@tanstack/react-table'
import { useState } from 'react'

import { Fallback } from '@cais-group/approved/ui/fallback'
import { ManageCaisIqTable } from '@cais-group/caisiq/ui/table/manage'
import { ManageTableProps } from '@cais-group/caisiq/util/type/manage-caisiq'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { Icon } from '@cais-group/equity/atoms/icon'
import { TableContainer } from '@cais-group/shared/ui/react-table-v8'
import {
  CourseMetadata,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'

import { courseConfigColumns } from './components/courses-configuration-columns'
import { TableNav } from './components/table-nav'

export type CourseConfigExperiencePageTableProps = Omit<
  ManageTableProps<CourseMetadata>,
  'tableOptions'
> & {
  params: Readonly<
    Partial<{
      experienceId: string | undefined
    }>
  >
  disabled?: boolean
  tableOptions: Omit<
    TableOptions<CourseMetadata>,
    'getCoreRowModel' | 'columns'
  >
}

export const ManageCoursesConfigurationTable = (
  props: CourseConfigExperiencePageTableProps
) => {
  const {
    searchTerm = '',
    setSearchTerm = () => {},
    tableOptions,
    fetchNextPage = () => {},
    hasNextPage = false,
    params,
  } = props
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'fullname', desc: false },
  ])

  const hasFullAccess = userSettingsService.hasRole(UserRole.CaisiqManageWrite)

  return (
    /**
     * @todo What height do we want the table to be?
     */
    <TableContainer $noScroll={true} className="h-fit w-[70vw]">
      <TableNav
        count={tableOptions.data.length}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
      <ManageCaisIqTable<CourseMetadata>
        tableOptions={{
          onSortingChange: setSorting,
          getSortedRowModel: getSortedRowModel(),
          state: {
            sorting: sorting,
            columnVisibility: { actions: hasFullAccess },
          },
          columns: courseConfigColumns,
          data: tableOptions.data,
        }}
        isFetched={props.isFetched}
        params={params}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        noScroll={true}
        tableType="courses"
        classNames={{
          rows: 'group [&>div]:h-[72px]',
        }}
        /**
         * @todo waiting to hear from design if we want to use the shared/approved fallback for no results
         * in all Manage caisiq tables
         */
        noResults={
          <div className="flex h-full flex-col p-24 [&>div>div>.MuiSvgIcon-root]:h-56 [&>div>div>.MuiSvgIcon-root]:w-56">
            <Fallback
              width="100%"
              height="100%"
              title="No courses available"
              text="Try modifying your search criteria"
              icon={() => (
                <Icon type="Search" size="large" color="eq-color-neutral-200" />
              )}
              backgroundColor="rgb(var(--colors-neutral-100))"
              testId="course-configuration-table-no-results"
            />
          </div>
        }
        helperText={
          <div className="text-primary-600 flex items-center gap-4 pt-16">
            <Icon type="Info" size="small" color="eq-color-primary-600" />
            <p className="small">You can add or remove courses through ALM</p>
          </div>
        }
      />
    </TableContainer>
  )
}

export default ManageCoursesConfigurationTable
