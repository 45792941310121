import { useMemo } from 'react'

import { useGetExperiencesQuery } from '@cais-group/caisiq/util/graphql/contentful'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'

export function useContentfulNameFromIdMapper() {
  const experiences = useGetExperiencesQuery({
    preview: previewService.enabled,
  })

  const map = useMemo(() => {
    const m = new Map()
    if (!experiences.isFetched) {
      return m
    }
    experiences.data?.firmCollection?.items.forEach((item) => {
      m.set(item?.sys.id, item?.name)
    })
    return m
  }, [experiences])

  return (id: string | undefined | null): string => {
    if (!map.has(id)) {
      // Return a default name @todo: pull this from Contentful; need to add a isDefault field
      return 'CAIS Default'
    }
    return map.get(id)
  }
}
