import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import PersonIcon from '@mui/icons-material/Person'

import {
  BodyStrong,
  shouldForwardProp,
} from '@cais-group/approved/ui/typography'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { logoutService } from '@cais-group/caisiq/util/logout-service'
import { ContextMenuDefault } from '@cais-group/shared/ui/buttons'

export type ContextMenuAppProps = {
  className?: string
  fill: string
}

const LogoutButton = styled.button`
  white-space: nowrap;
  padding: 10px 22px;
  margin-right: var(--s8);
  background-color: rgb(var(--colors-neutral-0));
`

const StyledPersonIcon = styled(PersonIcon, {
  shouldForwardProp: shouldForwardProp(`$fillColor`),
})<{ $fillColor: string }>`
  fill: ${({ $fillColor }) => $fillColor};
`

export const ContextMenuApp = ({ className, fill }: ContextMenuAppProps) => {
  const { logout: auth0Logout } = useAuth0()

  if (!experienceService.hasLogout()) {
    return <StyledPersonIcon $fillColor={fill} />
  }

  return (
    <ContextMenuDefault
      className={className}
      testId="logout-button"
      width="auto"
      alternativeIcon={<StyledPersonIcon $fillColor={fill} />}
    >
      <LogoutButton
        type="button"
        onClick={() => {
          logoutService.logout(auth0Logout)
        }}
      >
        <BodyStrong>Log out</BodyStrong>
      </LogoutButton>
    </ContextMenuDefault>
  )
}
