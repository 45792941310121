import styled from '@emotion/styled'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useState, useEffect } from 'react'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Breadcrumbs } from '@cais-group/approved/ui/breadcrumbs'
import {
  BodyStrongCss,
  BodyStrong,
  shouldForwardProp,
} from '@cais-group/approved/ui/typography'
import { Pill } from '@cais-group/caisiq-ui-pill'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'
import { TypeDashboardPath } from '@cais-group/shared/util/type/dashboard-path'

const StyledContainer = styled.div<{ $extend?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: inherit;
  @media screen and (min-width: 960px) {
    flex-direction: row;
    align-items: normal;
  }
`

const StyledLeftColumn = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

const StyledRightColumn = styled.div`
  display: flex;
  padding-top: var(--s16);
  padding-bottom: var(--s16);
  gap: var(--s16);
`

const StyledTab = styled(Tab, {
  shouldForwardProp: shouldForwardProp('$extend'),
})<{ $extend?: boolean }>`
  ${BodyStrongCss}
  display: flex;
  flex-direction: row-reverse;
  text-transform: none;
  color: rgb(var(--colors-primary-600));
  padding: 0;
  min-width: 0;
  margin-right: var(--s32);
  height: calc(
    // 72px. For visually centering the Tab component with a 4px bottom indicator line
    var(--s16) + var(--s56)
  );
  &.Mui-selected {
    color: rgb(var(--colors-primary-600));
  }

  @media screen and (min-width: 1025px) {
    position: ${({ $extend }) => ($extend ? 'absolute' : 'relative')};
    margin-left: ${({ $extend }) =>
      $extend
        ? // To make the margin dynamic for every screen size, it needs to be 100vw minus the padding, searchComponent, width of extended tab and margin between tab and searchComponent
          'calc(100vw - calc(var(--s376) ) - calc(var(--s56) * 2) - var(--s32) - 168px)'
        : '0'};
    margin-right: ${({ $extend }) => ($extend ? '0' : 'var(--s32)')};
  }
`

const StyledRightButtonWrapper = styled.div<{ breadCrumbs?: boolean }>`
  padding-left: ${({ breadCrumbs }) => (breadCrumbs ? 'var(--s16)' : '')};
  transform: ${({ breadCrumbs }) =>
    breadCrumbs ? 'translateY(var(--s24))' : ''};
  display: flex;
  align-items: center;
`
const StyledPill = styled(Pill)<{ isMobile?: boolean }>`
  position: relative;
  color: black;
  align-self: center;
  margin-left: var(--s8);
`

const TabSeparator = styled.span`
  width: 2px;
  height: var(--s32);
  background-color: rgb(var(--colors-neutral-300));
  margin-left: var(--s32);
`

const SeparatedTabContainer = styled.div`
  display: flex;
  justify-content: center;
`

export type TabItem = {
  label: string
  path: string
  hasSeparator?: boolean
  disabled?: boolean
}

export interface TabsNavBarProps {
  getCountForTab?: (arg: string) => number | undefined | React.ReactElement
  /**
   * @todo: we probably want to update TypeDashboardPath to include all apps' paths and make it shared
   */
  $tabToExtend?: TypeDashboardPath
  addScroll?: boolean
  tabs?: Array<TabItem>
  searchComponent?: React.ReactElement
  rightButton?: React.ReactElement
  breadCrumbs?: boolean
  onNavigate?: (path: string, metadata: { label: string }) => void
}

type TabIconProps = {
  count?: number | React.ReactElement
  hasSeparator?: boolean
}
const TabIcon = ({ hasSeparator, count }: TabIconProps) => {
  return (
    <>
      {hasSeparator && (
        <SeparatedTabContainer>
          <TabSeparator />
        </SeparatedTabContainer>
      )}
      {count && (
        <StyledPill
          label={count ?? 0}
          backgroundColor={colors['eq-color-neutral-200']}
          data-testid="search-count-active"
        />
      )}
    </>
  )
}

function TabsNavBar(props: TabsNavBarProps) {
  const {
    tabs = [],
    rightButton,
    searchComponent,
    getCountForTab = () => undefined,
    $tabToExtend,
    addScroll,
    breadCrumbs = false,
    onNavigate,
  } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [tabWidth, setTabWidth] = useState(120)

  // default to first tab, but if this is used you have messed up as using tab outside of where it can be nav-ed to
  let value = 0
  if (location?.pathname) {
    value =
      tabs.findIndex(
        (tab) => location.pathname.includes(tab.path.replace('../', '/')) // Fixes bug here where 2 paths have same substring eg holdings & add-holdings and it goes to wrong one in list
      ) ?? 0
  }

  const handleChange = (event: React.SyntheticEvent, idx: number) => {
    navigate(tabs[idx].path)
    onNavigate?.(tabs[idx].path, {
      label: tabs[idx].label,
    })
  }

  useEffect(() => {
    const tabs = document.querySelectorAll('[role=tab]')
    const label =
      tabs &&
      tabs[value] &&
      (tabs[value].getElementsByClassName('tabLabel')[0] as HTMLElement)
    if (!label) return

    setTabWidth(label.clientWidth)
  }, [setTabWidth, value])

  return (
    <StyledContainer>
      <StyledLeftColumn data-testid="muiTabsContainer">
        {tabs.length > 0 && breadCrumbs && <Breadcrumbs items={tabs} />}
        {tabs.length > 0 && !breadCrumbs && (
          <Tabs
            value={value === -1 ? false : value}
            TabIndicatorProps={{
              style: {
                background: colors['eq-color-neutral-900'],
                height: `${SPACING.s4}`,
                width: tabWidth,
              },
            }}
            variant={addScroll ? 'scrollable' : 'standard'}
            allowScrollButtonsMobile
            data-testid="muiTabs"
            onChange={handleChange}
          >
            {tabs.map((tab, idx) => {
              const { label, path, hasSeparator, disabled } = tab
              const count = getCountForTab(path)

              return (
                <StyledTab
                  disabled={disabled}
                  $extend={Boolean($tabToExtend && path.endsWith($tabToExtend))}
                  value={idx}
                  label={
                    <BodyStrong
                      data-testid={`label-${idx}${label}`}
                      className="tabLabel"
                    >
                      {label}
                    </BodyStrong>
                  }
                  icon={
                    // Will this need to be more dynamic in the future?
                    <TabIcon
                      count={count || undefined}
                      hasSeparator={hasSeparator}
                    />
                  }
                  key={`${idx}${label}`}
                  data-testid={`${idx}${label}`}
                />
              )
            })}
          </Tabs>
        )}
      </StyledLeftColumn>
      <StyledRightColumn>
        {searchComponent}
        {rightButton && (
          <StyledRightButtonWrapper
            breadCrumbs={breadCrumbs}
            data-testid="rightButtonWrapper"
          >
            {rightButton}
          </StyledRightButtonWrapper>
        )}
      </StyledRightColumn>
    </StyledContainer>
  )
}

const MemoizedTabNavBar = React.memo(TabsNavBar)
export { MemoizedTabNavBar as TabsNavBar }
