import { ContentPermissionsData } from '@cais-group/shared/domain/contentful/api'

import { useGetAllowedFirms } from './firms/useGetAllowedFirms'
import { useGetAllowedFundIds } from './funds/useGetAllowedFunds'

export function useGetAllowedContentPermissions(): {
  data: Omit<ContentPermissionsData, 'notFound'>
  isLoading: boolean
  isError: boolean
} {
  const allowedFundIds = useGetAllowedFundIds()
  const allowedFirms = useGetAllowedFirms()
  const allowedFirmIds = allowedFirms.data
    .map((firm) => firm?.id)
    .filter(Boolean) as string[]
  return {
    data: {
      fundProductIds: allowedFundIds.data,
      firmIds: allowedFirmIds,
    },
    isLoading: allowedFundIds.isLoading || allowedFirms.isLoading,
    isError: allowedFundIds.isError || allowedFirms.isError,
  }
}
