import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'

import {
  CaptionRegularCss,
  shouldForwardProp,
} from '@cais-group/approved/ui/typography'

export const TableScrollWrapper = styled.div<{ $noResultMode?: boolean }>`
  width: 100%;
  background: rgb(var(--colors-neutral-0));
  height: ${({ $noResultMode }) => {
    return $noResultMode ? '100%' : undefined
  }};
`
export const TableContainer = styled.div<{
  $noScroll?: boolean
  $noResultMode?: boolean
}>`
  text-align: left;
  height: 100%;
  font-weight: normal;
  @media (max-width: 1270px) {
    width: ${({ $noScroll }) =>
      $noScroll
        ? undefined
        : '1270px'}; // Table needs a fixed width to allow for horizontal scrolling
  }
  height: ${({ $noResultMode }) => {
    return $noResultMode ? '100%' : undefined
  }};
`

export const THead = styled('div', {
  shouldForwardProp: shouldForwardProp('$variant'),
})<{ $variant?: 'light' | 'dark' }>`
  ${(props) =>
    props.$variant === 'light'
      ? css`
          background: rgb(var(--colors-neutral-100));
          color: rgb(var(--colors-neutral-900));
        `
      : css`
          background: rgb(var(--colors-neutral-900));
          color: rgb(var(--colors-neutral-0));
        `};

  display: flex;
  justify-content: space-between;
  min-width: fit-content;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
`

export const TH = styled.div<{
  $type?: string
  $hidePadding?: boolean
}>`
  ${CaptionRegularCss};
  line-height: 1rem; /* TODO: Non standard font */

  display: inline-flex;
  flex: 1;
  align-items: center;
  padding: 0;
  ${(p) => !p.$hidePadding && 'padding-right: var(--s24);'}
  height: 40px;

  &:first-of-type,
  :nth-of-type(2) {
    justify-content: flex-start;
    text-align: left;
    ${(p) => !p.$hidePadding && 'padding-left: var(--s24);'}
    padding-right: 0;
  }

  ${(p) =>
    p.$type === 'sticky' &&
    css`
      &:first-of-type {
        position: sticky;
        z-index: 1;
        left: 0;
        top: 0;
      }
    `}
`

export const TBody = styled.div<{ $noResultMode?: boolean }>`
  min-width: fit-content;
  background: white;
  height: ${({ $noResultMode }) => {
    return $noResultMode ? '100%' : undefined
  }};
`

export const Row = styled.div<{
  $hoverBg?: boolean
  $selectedBg?: boolean
  $expanded?: boolean
  $center?: boolean
}>`
  display: flex;
  flex: 1 0 auto;
  border-bottom: 1px solid rgb(var(--colors-neutral-200));
  width: 100%;
  min-width: 0px;
  justify-content: space-between;
  background: ${(p) => (p.$selectedBg ? 'var(--color-blue-4)' : 'inherit')};

  ${(p) => p.$center && 'align-items: center'};

  ${(p) =>
    p.$expanded === false &&
    `
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s ease-out;
    border-bottom: none;`}

  ${(p) =>
    p.$expanded === true &&
    `
    max-height: 1000px;
    transition: max-height 0.8s ease-in;`}

  ${(p) =>
    p.$hoverBg &&
    `&:hover {
      background: rgb(var(--colors-neutral-100));
      & > * {
        background: rgb(var(--colors-neutral-100));
      }
    }`}
`

export type CellProps = {
  $type?: string
  $overflow?: 'ellipsis'
  $hidePadding?: boolean
  style?: React.CSSProperties
}

export const Cell = styled.div<CellProps>`
  & > & {
    display: contents;
  }
  position: relative;
  flex: 1;
  padding: var(--s16) 0;
  ${(p) => !p.$hidePadding && 'padding-right: var(--s24);'}
  background: inherit;
  ${(p) =>
    p.$overflow === 'ellipsis' &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  &:first-of-type,
  :nth-of-type(2) {
    text-align: left;
    ${(p) => !p.$hidePadding && 'padding-left: var(--s24);'}
    padding-right: 0;
  }

  ${(p) =>
    p.$type === 'sticky' &&
    css`
      position: sticky;
      z-index: 1;
      left: 0;
      top: 0;
    `}
`

export const TableFooter = styled.div`
  height: var(--s56);
  background: rgb(var(--colors-neutral-100));
`

const SortIcon = (props: HTMLAttributes<HTMLButtonElement>) => (
  <button type="button" {...props}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 12">
      <path d="m5 0 4.33 4.5H.67L5 0Z" />
      <path d="M5 12 .67 7.5h8.66L5 12Z" />
    </svg>
  </button>
)

const sortColors = {
  dark: {
    active: 'var(--color-white)',
    inactive: 'var(--color-gray-64)' as const,
  },
  light: {
    active: 'var(--color-black)',
    inactive: 'var(--color-gray-14)' as const,
  },
} as const

export const SortButton = styled(SortIcon, {
  shouldForwardProp: shouldForwardProp(['$variant', '$sort']),
})<
  HTMLAttributes<HTMLButtonElement> & {
    $variant: 'light' | 'dark'
    $sort?: 'asc' | 'desc'
  }
>`
  margin-left: var(--eq-s-4);
  margin-right: var(--eq-s-4);

  &,
  & > svg {
    width: 10px;
    height: 12px;
  }

  path:first-of-type {
    fill: ${({ $sort, $variant }) => {
      return sortColors[$variant][$sort === 'asc' ? 'active' : 'inactive']
    }};
  }

  path:last-of-type {
    fill: ${({ $sort, $variant }) => {
      return sortColors[$variant][$sort === 'desc' ? 'active' : 'inactive']
    }};
  }
`
