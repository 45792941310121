import { Navigate, useParams } from 'react-router-dom'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { useGetCourses } from '@cais-group/caisiq/domain/course'
import { useUserHasLicences } from '@cais-group/caisiq/domain/user'
import { ROUTES } from '@cais-group/caisiq/feature/routes'

import { InterstitialsPage } from './caisiq-feature-interstitials'

export const InterstitialsPageContainer = () => {
  const { courseId } = useParams<{ courseId: string }>()
  const { data: courses } = useGetCourses()
  const course = courses?.find((it) => it.id === courseId)

  const { hasSavedLicences, isFetched } = useUserHasLicences()

  if (course === undefined || !isFetched) {
    return (
      <LoadingContainer state={LoadingState.LOADING} coverPage="FULL_SCREEN" />
    )
  }

  if (hasSavedLicences) {
    return <Navigate to={ROUTES.ceCourseCreditsSuccess(courseId)} />
  }

  return <InterstitialsPage course={course} />
}
