import type { FieldValues } from 'react-hook-form'
import type { Params } from 'react-router-dom'
import type { Dictionary } from 'ts-essentials'

import type { OptionsServiceArgs } from './hooks/async-options'
import { useSelectedOptions } from './hooks/use-selected-options'
import type { UniformAny, UniformFieldOption } from './types'

export type UniformServices = Dictionary<
  (...args: UniformAny[]) => Promise<UniformAny>
>

type OptionsService<TFieldValues extends FieldValues = FieldValues> =
  Dictionary<
    (
      args: OptionsServiceArgs,
      allValues: TFieldValues,
      latestFieldValue: ReturnType<
        typeof useSelectedOptions
      >['latestFieldValue']
    ) => Promise<{
      data: UniformFieldOption[]
      pageInfo?: {
        hasNextPage?: boolean
        endCursor?: string | null
        [key: string]: UniformAny
      }
    }>
  >

type ValidationService<TFieldValues extends FieldValues = FieldValues> =
  Dictionary<
    (
      args: { fieldId: string; value: UniformAny },
      allValues: TFieldValues,
      latestFieldValue: ReturnType<
        typeof useSelectedOptions
      >['latestFieldValue']
    ) => Promise<string | undefined>
  >

type TitleService<TFieldValues extends FieldValues = FieldValues> = Dictionary<
  (
    allValues: TFieldValues,
    latestFieldValue: ReturnType<typeof useSelectedOptions>['latestFieldValue']
  ) => Promise<string | undefined>
>

type ModalServices<TFieldValues extends FieldValues = FieldValues> = Dictionary<
  (payload: TFieldValues, allValues: TFieldValues) => Promise<UniformAny>
>

type SubmitService<TFieldValues extends FieldValues = FieldValues> = Dictionary<
  (
    payload: Record<string, UniformAny>,
    allValues: TFieldValues,
    params: Params<string>
  ) => Promise<void>
>

export function createStrictUniformServices<
  TFieldValues extends FieldValues = FieldValues
>() {
  return <
    TGeneralServices extends UniformServices = UniformServices,
    TOptionServices extends OptionsService<TFieldValues> = OptionsService<TFieldValues>,
    TTitlesServices extends TitleService<TFieldValues> = TitleService<TFieldValues>,
    TValidationServices extends ValidationService<TFieldValues> = ValidationService<TFieldValues>,
    TSubmitServices extends SubmitService<TFieldValues> = SubmitService<TFieldValues>,
    TModalServices extends ModalServices<TFieldValues> = ModalServices<TFieldValues>
  >({
    general,
    options,
    titles,
    validation,
    submits,
    modals,
  }: {
    /**
     *  FIXME: all keys are required because TS is unable to properly infer keys if at least one object is not provided (even empty). Improvement welcome.
     */
    general: TGeneralServices
    options: TOptionServices
    titles: TTitlesServices
    validation: TValidationServices
    submits: TSubmitServices
    modals: TModalServices
  }) => {
    return {
      ...general,
      ...options,
      ...titles,
      ...validation,
      ...submits,
      ...modals,
    }
  }
}

export function createLooseUniformServices<T extends UniformServices>(
  services: T
): T {
  return services
}
