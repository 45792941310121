import { Course } from './course'
import { components } from './generated'

// This should come from the generated types but while we wait for BE to explicitely define it as enum we list here the known types
export type MercerRatingType =
  | 'A+'
  | 'A'
  | 'B'
  | 'B+'
  | 'C'
  | 'R'
  | 'Global'
  | 'N/A'
  | 'B+ (W)'
  | 'ESG 1'
  | ''

export type Fund = Omit<components['schemas']['Fund'], 'mercerRating'> & {
  mercerRating: MercerRatingType
}
export type FundType = Fund['type']

export const fundTypes: Readonly<{ [key in FundType]: key }> = {
  HEDGE_FUND: 'HEDGE_FUND',
  PRIVATE_EQUITY: 'PRIVATE_EQUITY',
  PRIVATE_MARKETS: 'PRIVATE_MARKETS',
  OPERATING_COMPANY: 'OPERATING_COMPANY',
}
export type FundWithThumbnail = Fund & {
  courses?: Array<Course>
  smallThumbnailUrl?: string
  largeThumbnailUrl?: string
}
