import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'remeda'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import {
  usePutExperience,
  usePutExperienceCourseMetadata,
} from '@cais-group/caisiq/domain/experience'
import {
  useFormSaveHandler,
  useGetExperienceCoursesMetadata,
} from '@cais-group/caisiq/domain/manage-caisiq'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { ExperiencePageSideNavigation } from '@cais-group/caisiq/ui/manage/experience-page-side-navigation'
import { ManageNavbarHeader } from '@cais-group/caisiq/ui/manage/navbar-header'
import { useContentfulNameFromIdMapper } from '@cais-group/caisiq/util/contentful/use-contentful-name-from-id-mapper'
import { useInvalidateByRegex } from '@cais-group/caisiq/util/default-query-function'
import { useGetExperiencesQuery } from '@cais-group/caisiq/util/graphql/contentful'
import {
  useApiPathWithParams,
  useApiPathWithPayload,
} from '@cais-group/caisiq/util/hook/use-api-path-with-params'
import { UseModalState } from '@cais-group/caisiq/util/hooks/use-modal-state'
import { transformCourseMetadataRequest } from '@cais-group/caisiq/util/manage/transform-course-metadata-request'
import { transformExperienceRequest } from '@cais-group/caisiq/util/manage/transform-experience-request'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import {
  ExperienceFields,
  ModalType,
} from '@cais-group/caisiq/util/type/manage-caisiq'
import {
  showBanner,
  showToast,
} from '@cais-group/equity/organisms/notifications'
import { FormStepProvider } from '@cais-group/shared/ui/ordered-fieldset'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  ApiPaths,
  CaisIqFirm,
  Catalog,
  ExperienceResponse,
} from '@cais-group/shared/util/type/caisiq-be'

import CreateUpdateExperience from './caisiq-feature-manage-create-update-experience'
import { experienceResponseFormErrorHandler } from './hooks/experience-response-form-error-handler'

export function UpdateExperiencePageContainer() {
  const { isOpen, onOpen } = UseModalState<ModalType>()
  const navigate = useNavigate()
  const { experienceId } = useParams()

  const experiences = useGetExperiencesQuery({
    preview: previewService.enabled,
  })
  const catalogs = useQuery<Catalog[]>(ApiPaths.getAllCatalogs)

  const experience = useQuery<ExperienceResponse>(
    useApiPathWithParams(ApiPaths.getExperience)
  )

  const {
    data: experienceCoursesMetadata,
    isFetched: experienceCoursesMetadataFetched,
    isFetching: experienceCoursesMetadataFetching,
  } = useGetExperienceCoursesMetadata(experienceId)

  const endpoint = useApiPathWithPayload(ApiPaths.getFirmsInExperience)({
    experienceId: experience.data?.uuid ?? experienceId,
  })
  const experienceFirms = useQuery<CaisIqFirm[]>(endpoint, {
    enabled: Boolean(experience.data?.uuid),
  })

  const invalidate = useInvalidateByRegex()
  const { mutate, isLoading: experienceMutateLoading } = usePutExperience(
    experience.data?.version
  )
  const { mutate: mutateCourses, isLoading: courseMutateLoading } =
    usePutExperienceCourseMetadata()
  const getContentfulNameById = useContentfulNameFromIdMapper()

  const handleSuccess = async () => {
    navigate(ROUTES.manageExperiences)
    await Promise.all([
      // @todo: See if we can be more specific with the invalidation targeting to reduce
      //        the number of unrelated queries that will potentially be refetched.
      invalidate(/manage[/]experiences/),
      invalidate(/manage[/]firms/),
    ])
    showBanner({
      type: 'success',
      title: 'The experience has been updated',
    })
  }

  const handleSubmit = useFormSaveHandler({
    mutate,
    transform: transformExperienceRequest,
    onSuccess: async (data, params) => {
      const courses = params?.courses ?? []
      const containsMetadata = params?.coursesEnabled && !isEmpty(courses)
      if (containsMetadata && data?.uuid) {
        const { metadata } = transformCourseMetadataRequest(courses)
        mutateCourses(
          {
            experienceId: data.uuid,
            metadata,
            // The version is updated after the experience is updated so need to use the mutated data.
            newVersion: data.version,
          },
          {
            onSuccess: () => {
              handleSuccess()
            },
          }
        )
      } else {
        // NOTE: This condition will run when a user updates an experience that has courses disabled.
        handleSuccess()
      }
    },
    onError: () => {
      showToast({
        type: 'error',
        title: 'There was an error updating the experience',
      })
    },
  })

  const handleSubmitWithTracking = (params: ExperienceFields) => {
    return handleSubmit(params).then(() => {
      trackingService.manageExperienceUpdated({
        name: params.name,
        label: params.label,
        contentfulName: getContentfulNameById(params.contentfulId),
        almCatalog: params.catalogId ?? '',
        coursesEnabled: params.coursesEnabled,
        ceCreditsEnabled: params.ceCreditEnabled,
        type: params.experienceType,
        firmIds: params.firmIds ?? [],
      })
    })
  }

  if (
    experiences.isFetching ||
    experience.isFetching ||
    experienceFirms.isFetching ||
    experienceCoursesMetadataFetching
  ) {
    return <LoadingContainer state={LoadingState.LOADING} />
  }

  return (
    <FormStepProvider stateless={true}>
      <CreateUpdateExperience
        experience={experience.data}
        firms={experienceFirms.data}
        courses={experienceCoursesMetadata}
        coursesFetched={experienceCoursesMetadataFetched}
        saveLabel="Save"
        contentfulExperiences={experiences.data?.firmCollection?.items}
        catalogs={catalogs.data || []}
        handleSubmit={handleSubmitWithTracking}
        sideNavigation={<ExperiencePageSideNavigation />}
        isOpen={isOpen}
        onOpen={onOpen}
        mutateLoading={courseMutateLoading || experienceMutateLoading}
        headerNav={
          <ManageNavbarHeader<ExperienceFields>
            submitLabel="Save"
            pageName={`Experience: ${experience.data?.name}`}
            onSubmit={handleSubmitWithTracking}
            handleFormError={experienceResponseFormErrorHandler}
            mutateLoading={courseMutateLoading || experienceMutateLoading}
          />
        }
      />
    </FormStepProvider>
  )
}

export default UpdateExperiencePageContainer
