import { useEffect, useState } from 'react'
import { uniqueBy } from 'remeda'

export type BaseOptionType = {
  value: string
}

export type SingleSelectType<T> = {
  isMulti: false
  options: (T & BaseOptionType)[]
  onChange?: (newValue: (T & BaseOptionType) | null) => void
  value?: (T & BaseOptionType)['value']
}

export type MultiSelectType<T> = {
  isMulti: true
  options: (T & BaseOptionType)[]
  onChange?: (newValue: (T & BaseOptionType)[]) => void
  value?: (T & BaseOptionType)['value'][]
}

export type UseSelectedOptionsType<T> = SingleSelectType<T> | MultiSelectType<T>

/**
 * Hook for managing selected options.
 * @param isMulti - Determines if it's a multi-select or single-select.
 * @param onChange - Callback function to be called when selected options change.
 * @returns An object containing the selected options, addOption function, and removeOption function.
 */
export const useSelectedOptions = <T extends BaseOptionType>({
  isMulti,
  options,
  value,
  onChange,
}: UseSelectedOptionsType<T>) => {
  const [selectedOptions, setSelectedOptions] = useState<T[]>(() => {
    if (value) {
      if (Array.isArray(value)) {
        return options.filter((option) => value.includes(option.value))
      } else {
        const option = options.find((option) => option.value === value)
        return option ? [option] : []
      }
    } else {
      return []
    }
  })

  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        setSelectedOptions((state) =>
          uniqueBy(
            // This is done so when `options` changes (e.g.: due to a search) we don't lose previously selected options if they don't exists in `options` anymore
            state
              .filter((option) => value.includes(option.value)) // Keep/remove whatever selected/unselected options are still part of `value`
              .concat(options.filter((option) => value.includes(option.value))), // Add any new options that have been selected
            (option) => option.value // remove duplicates
          )
        )
      } else {
        const option = options.find((option) => option.value === value)
        setSelectedOptions(option ? [option] : [])
      }
    } else {
      setSelectedOptions([])
    }
  }, [value, options])

  const addOption = (selectedOption: T | null) => {
    if (
      selectedOption &&
      !isOptionAlreadySelected(selectedOption, selectedOptions)
    ) {
      const updatedOptions = isMulti
        ? [...selectedOptions, selectedOption]
        : [selectedOption]

      setSelectedOptions(updatedOptions)

      if (!onChange) return

      // @ts-ignore
      onChange?.(isMulti ? updatedOptions : updatedOptions[0]) // HELP?: Fix type error
    }

    if (
      selectedOption &&
      isOptionAlreadySelected(selectedOption, selectedOptions)
    ) {
      removeOption(selectedOption)
    }
  }

  const removeOption = (option: T) => {
    const updatedOptions = selectedOptions.filter(
      (selectedOption) => selectedOption.value !== option.value
    )

    setSelectedOptions(updatedOptions)

    // @ts-ignore
    onChange?.(isMulti ? updatedOptions : updatedOptions[0]) // HELP?: Fix type error
  }

  return { selectedOptions, addOption, removeOption }
}

const isOptionAlreadySelected = <T extends BaseOptionType>(
  option: T,
  selectedOptions: T[]
) => {
  return selectedOptions.some((selectedOption) => {
    return selectedOption.value === option.value
  })
}

export type FilterOptionsInput<T extends BaseOptionType> = {
  options: T[]
  isSearchable: boolean
  inputValue: string | null
}

export const defaultFilterOptionsFn =
  <T extends object>(
    searchKey: keyof (T & BaseOptionType) = 'value' as keyof T
  ) =>
  ({
    options,
    isSearchable,
    inputValue,
  }: FilterOptionsInput<T & BaseOptionType>): T[] =>
    isSearchable && inputValue
      ? options.filter((option) =>
          (option[searchKey as keyof T] as string)
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        )
      : options

/**
 * Represents a segment of text with a mark flag indicating whether it should be marked.
 */
interface TextSegment {
  text: string
  mark: boolean
}

/**
 * Finds all instances of a string within another string and returns an array of TextSegment objects
 * representing the segments of the string. Each object contains the text and a boolean flag indicating
 * whether it should be marked.
 *
 * @param sentence - The input string to search within.
 * @param searchString - The string to search for within the input string.
 * @returns An array of TextSegment objects representing the segments of the string.
 */
export function findInstances(
  sentence: string,
  searchString: string
): TextSegment[] {
  const regex = new RegExp(searchString, 'gi')
  const matches = Array.from(sentence.matchAll(regex))

  if (matches.length === 0) {
    // No matches found, return original string as a single segment
    return [{ text: sentence, mark: false }]
  }

  const segments: TextSegment[] = []
  let lastIndex = 0

  for (const match of matches) {
    const [fullMatch] = match
    const startIndex = match.index

    if (startIndex !== undefined) {
      // startIndex is defined, add non-matching segment before the current match
      if (startIndex > lastIndex) {
        segments.push({
          text: sentence.slice(lastIndex, startIndex),
          mark: false,
        })
      }

      // Add the matching segment
      segments.push({ text: fullMatch, mark: true })
      lastIndex = startIndex + fullMatch.length
    }
  }

  if (lastIndex < sentence.length) {
    // Add remaining text after the last match as a non-matching segment
    segments.push({ text: sentence.slice(lastIndex), mark: false })
  }

  return segments
}
