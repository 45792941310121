import { uiTypeSize } from '@cais-group/shared/ui/type/size'
import styled from '@emotion/styled'
import * as React from 'react'
import { forwardRef } from 'react'
import { omit } from 'remeda'

import { Justify } from '../common'

type Align = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'

export type ClusterProps = {
  space?: uiTypeSize
  justify?: Justify
  align?: Align
  overflow?: 'hidden' | 'visible'
  className?: string
  children?: React.ReactNode
  'data-testid'?: string
}

// TODO: the children should be in <li> in <ul> for accessibility purposes

export const Test = styled.div<ClusterProps>``

export const LayoutCluster = styled(
  forwardRef<HTMLDivElement, ClusterProps>((props, ref) => {
    const passedProps = omit(props, [
      'space',
      'justify',
      'align',
      'overflow',
      'className',
      'children',
    ])
    return (
      <div
        ref={ref}
        className={props.className}
        data-layout="LayoutCluster"
        {...passedProps}
      >
        <div>{props.children}</div>
      </div>
    )
  })
)(
  ({
    justify = 'flex-start',
    align = 'center',
    space = 'var(--s24)',
    overflow = 'hidden',
  }) => {
    return `
      overflow: ${overflow};

      & > * {
        display: flex;
        flex-wrap: wrap;
        justify-content: ${justify};
        align-items: ${align};
        margin: calc(${space} / 2 * -1);
      }

      & > * > * {
        /* ↓ Half margins meet to make the whole space value together */
        margin: calc(${space} / 2);
      }
    `
  }
)
LayoutCluster.displayName = 'LayoutCluster'
