import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import { useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'

import { Body, H2Strong, H4Strong } from '@cais-group/approved/ui/typography'
import { ROUTES } from '@cais-group/caisiq/feature/routes'
import {
  CeCreditsListItem,
  CeCreditsList,
} from '@cais-group/caisiq/ui/ce-credits-list'
import { Button } from '@cais-group/equity/atoms/button'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import { CenteredColumn } from '@cais-group/shared/ui/grid'

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: var(--s56);
  margin-bottom: var(--s56);

  > a:first-of-type {
    margin-bottom: var(--s16);
  }

  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
    flex-direction: row;
  }

  > a:first-of-type {
    @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
      margin-right: var(--s24);
      margin-bottom: 0;
    }
  }
`

const PageContainer = styled(CenteredColumn)`
  min-height: calc(100vh - 390.5px);
  display: flex;
  flex-direction: column;
  padding-top: var(--s16);

  /* Not mobile */
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: var(--s144);
    padding-right: var(--s56);
  }
`
const StyledCheck = styled(CheckIcon)`
  fill: rgb(var(--colors-success-600));
  width: var(--s56);
  height: var(--s88);
`

const CeCreditsInfo = styled(Body)`
  max-width: var(--measure);
  margin-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => {
    // @ts-ignore
    return theme.palette.neutral.darker
  }};
`

export type CeCreditsSuccessPageProps = {
  credits: CeCreditsListItem[]
}

export const CeCreditsSuccessPage = (props: CeCreditsSuccessPageProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'))
  const Label = isMobile ? H4Strong : H2Strong

  return (
    <PageContainer>
      <CenteredColumn>
        <StyledCheck />
        <Label>Success!</Label>
        <CeCreditsInfo>
          Your credential numbers have been saved to your profile. We are in the
          process of submitting your credits.
        </CeCreditsInfo>
        <CeCreditsList rows={props.credits} />
        <ButtonsContainer>
          <Link to={ROUTES.courses}>
            <Button variant="secondary">Back to Dashboard</Button>
          </Link>
          <Link to={ROUTES.profile}>
            <Button>See all Credits</Button>
          </Link>
        </ButtonsContainer>
      </CenteredColumn>
    </PageContainer>
  )
}
