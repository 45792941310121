import { faker } from '@faker-js/faker/locale/en'
import { Sync } from 'factory.ts'

import {
  InvestmentEntitiesV1,
  InvestmentEntityDetailsV1,
} from '../../generated/api.schemas'

export const investmentEntityDetailsFactory =
  Sync.makeFactory<InvestmentEntityDetailsV1>(() => ({
    investingEntityName: faker.company.name(),
    ein: faker.string.uuid(),
  }))

export const investmentEntityFactory = Sync.makeFactory<InvestmentEntitiesV1>(
  () => ({
    primaryInvestmentEntity: investmentEntityDetailsFactory.build(),
  })
)
