import { Skeleton } from '@mui/material'
import * as React from 'react'

// TODO: moved to investor-profile. DELETE
import { Avatar } from '@cais-group/equity/atoms/avatar'
import {
  ContextMenuTable,
  type MenuTableActions,
} from '@cais-group/shared/ui/buttons'
import { useProtectedCallback } from '@cais-group/shared/util/hook/use-user-profile'

type CardProps = {
  heading: string
  subHeading?: string | null
  actions?: MenuTableActions
}
export const Card = ({
  heading,
  subHeading,
  actions,
  children,
}: React.PropsWithChildren<CardProps>) => {
  const isAuthorized = useProtectedCallback()

  const filteredActions = actions?.filter((action) =>
    isAuthorized({
      permissions: action.permissions,
      somePermissions: action.somePermissions,
    })
  )

  return (
    <div className="flex flex-col gap-16 border border-solid border-neutral-200 p-24">
      <div className="flex items-center gap-16">
        <Avatar />
        <div className="flex flex-1 flex-col overflow-hidden">
          <p className="small">{heading}</p>
          {subHeading && (
            <p className="small truncate text-neutral-600" title={subHeading}>
              {subHeading}
            </p>
          )}
        </div>
        {filteredActions && <ContextMenuTable actions={filteredActions} />}
      </div>
      {children && (
        <div className="border-t border-neutral-200 pt-16">{children}</div>
      )}
    </div>
  )
}

export const CardLoading = () => {
  return (
    <div className="flex flex-col gap-16 border border-solid border-neutral-200 p-24">
      <div className="flex items-center gap-16">
        <Avatar />
        <div className="flex flex-1 flex-col gap-4">
          <Skeleton variant="rectangular" width="45%" height={24} />
          <Skeleton variant="rectangular" width="60%" height={24} />
        </div>
        <Skeleton variant="rectangular" width={24} height={24} />
      </div>
      <div className="flex flex-col gap-4 border-t border-neutral-200 pt-16">
        <Skeleton variant="rectangular" width="45%" height={24} />
        <Skeleton variant="rectangular" width="60%" height={24} />
      </div>
    </div>
  )
}
