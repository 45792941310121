import styled from '@emotion/styled'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'

type FullScreenButtonType = {
  isFullScreen?: boolean
  fullScreenButtonDisabled: boolean
  onFullScreen: () => void
}

const StyledFullscreenExitIcon = styled(FullscreenExitIcon)`
  fill: rgb(var(--colors-neutral-0));
`

const StyledFullscreenIcon = styled(FullscreenIcon)`
  fill: rgb(var(--colors-neutral-0));
`

const Button = styled.button`
  cursor: pointer;
  fill: rgb(var(--colors-neutral-0));
  width: calc(var(--s24) - 2px);
  height: calc(var(--s24) - 2px);
  background: rgb(var(--colors-neutral-900));
`

export const FullScreenButton = ({
  onFullScreen,
  isFullScreen,
  fullScreenButtonDisabled = true,
}: FullScreenButtonType) => (
  <Button disabled={fullScreenButtonDisabled} onClick={onFullScreen}>
    {isFullScreen ? <StyledFullscreenExitIcon /> : <StyledFullscreenIcon />}
  </Button>
)
