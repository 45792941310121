import { useLocation } from 'react-router-dom'

import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import {
  TypeDashboardPath,
  VALID_PATHS,
} from '@cais-group/shared/util/type/dashboard-path'
import { TypeTabData } from '@cais-group/shared/util/type/tab-data'

export function useDashboardTabs() {
  const filteredTabs = usePermittedTabs(
    userSettingsService.theme.tabsCollection?.items || []
  )

  const getTabByPath = (
    path: TypeDashboardPath | undefined
  ): TypeTabData | undefined => {
    if (!filteredTabs || !path) {
      return undefined
    }
    return filteredTabs.find((tab) => tab.path.endsWith(path))
  }

  const currentTab = validPathOrUndefined(
    useLocation().pathname.split('/').pop() || ''
  )
  const tabContent = getTabByPath(currentTab)
  const description = tabContent?.description
  const disclaimer = tabContent?.disclaimer

  return {
    currentTab,
    filteredTabs,
    description,
    disclaimer,
  }
}

function usePermittedTabs(tabsCollection: TypeTabData[]) {
  return tabsCollection.reduce((filtered: TypeTabData[], tab: TypeTabData) => {
    filtered.push({
      ...tab,
      path: `../${tab.path}`,
    })
    return filtered
  }, [])
}

const validPathOrUndefined = (path: string): TypeDashboardPath | undefined =>
  VALID_PATHS.includes(path as TypeDashboardPath | never)
    ? (path as TypeDashboardPath)
    : undefined
