import request, { Variables } from 'graphql-request'

import { getEnvConfig } from '@cais-group/shared/ui/env'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'

export const createFetcher = <_Q, V extends Variables = Variables>(
  document: string,
  variables?: V,
  requestHeaders?: RequestInit['headers']
) => {
  const {
    CONTENTFUL_ACCESS_TOKEN,
    CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    CONTENTFUL_URL,
  } = getEnvConfig()

  return async (): Promise<_Q> =>
    request(CONTENTFUL_URL, document, variables, {
      Authorization: `Bearer ${
        previewService.enabled
          ? CONTENTFUL_PREVIEW_ACCESS_TOKEN
          : CONTENTFUL_ACCESS_TOKEN
      }`,
      ...requestHeaders,
    })
}
