import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import {
  CaisIqFirm,
  UpdateFirmRequest,
} from '@cais-group/shared/util/type/caisiq-be'

export const transformFirmRequest =
  (firm?: CaisIqFirm) =>
  (params: ExperienceFields): UpdateFirmRequest => {
    const { name, active = true } = params
    return {
      experienceId: name,
      active,
      id: firm?.id || '',
      name: firm?.name || '',
    }
  }
