import type { Node } from '@contentful/rich-text-types'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'
import {
  BlockEntry,
  EntryMap,
} from '@cais-group/shared/util/type/rich-text-json'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

function externalLink(node: Node, children: ReactNode, segmentPageId?: string) {
  return (
    <a
      href={node.data['uri']}
      onClick={() => {
        trackingService.externalLinkClicked(
          segmentPageId || '',
          String(children),
          node.data['uri']
        )
      }}
      target="_blank"
      rel="nofollow noreferrer"
    >
      {children}
    </a>
  )
}

function footnoteLink(props: { to: string; children: React.ReactNode }) {
  return (
    <a href={props.to}>
      <sup style={{ lineHeight: '1' }}>{props.children}</sup>
    </a>
  )
}

function inlineLink(node: Node, children: ReactNode, segmentPageId?: string) {
  const footnotesId = `#footnotes`
  const { pathname, search } = window.location
  // Check if link is a footnote
  if (node.data['uri'].indexOf(footnotesId) === 0) {
    return footnoteLink({ to: `${pathname}${search}${footnotesId}`, children })
  }

  return externalLink(node, children, segmentPageId)
}

function isCaisiqArticle(entry?: BlockEntry) {
  return entry !== undefined && entry.__typename === 'CaisiqArticle'
}

function isWhitepaper(entry?: BlockEntry) {
  return entry !== undefined && entry.__typename === 'Whitepaper'
}

export function getResourceParentPath(entry: BlockEntry) {
  if (isCaisiqArticle(entry)) {
    return 'articles'
  }

  if (isWhitepaper(entry)) {
    return 'whitepapers'
  }

  return undefined
}

function embeddedEntryLink(
  node: Node,
  children: ReactNode,
  entryMap: EntryMap
) {
  const id = node.data['target'].sys.id
  const entry = entryMap.get(id) as WhitepaperData | TypeArticleData

  if (!entry) {
    return children
  }

  return (
    <Link
      to={`/authenticated/${getResourceParentPath(entry)}/${entry.slug}`}
      state={{ from: `${getResourceParentPath(entry)}` }}
    >
      {children}
    </Link>
  )
}

export { footnoteLink, inlineLink, embeddedEntryLink }
