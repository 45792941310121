export const VALID_PATHS = [
  'videos',
  'courses',
  'course-library',
  'articles',
  'whitepapers',
  'funds',
  'guides',
  'profile',
] as const

export type TypeDashboardPath = typeof VALID_PATHS[number]
