import {
  CourseMetadata,
  CourseMetadataRequest,
} from '@cais-group/shared/util/type/caisiq-be'

export const transformCourseMetadataRequest = (
  courses: CourseMetadata[]
): CourseMetadataRequest => {
  const metatada = courses.map((course) => ({
    acpCourseId: course.acpCourseId,
    theme: course.theme,
    requiredAssessmentScore: course.requiredAssessmentScore,
    ce: course.ce,
    requiredProgressLevel: course.requiredProgressLevel,
    allowSelfEnroll: course.allowSelfEnroll,
    enrollType: course.enrollType,
  }))

  return {
    metadata: metatada,
  }
}
