import { useState, useEffect } from 'react'
import screenfull from 'screenfull'

export const useIsFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsFullScreen(screenfull.isEnabled && screenfull.isFullscreen)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isFullScreen
}
