import type { UniformSectionChildren, UniformAny } from '../types'

/**
 * This function, findObjectBy, searches through a given object and its children to find a specific value for a specific key.
 * The search is performed recursively, allowing it to handle deeply nested structures.
 * The function returns the first object where the key's value matches the desired value.
 * If no match is found, it returns undefined.
 *
 * It accepts three parameters:
 * 1. obj: An object of any type that may contain nested objects.
 * 2. key: The key to search for.
 * 3. value: The value to search for.
 *
 * For example:
 *
 * Input:
 * ```
 * obj = {
 *  "id": "1",
 *  "name": "object1",
 *  "children": [
 *    {
 *      "id": "2",
 *      "name": "object2"
 *    },
 *    {
 *      "id": "3",
 *      "name": "object3"
 *    }
 *  ]
 * },
 * key = "name",
 * name = "object2"
 *```
 *```
 * Output:
 * {
 *  "id": "2",
 *  "name": "object2"
 * }
 *```
 */
export function findObjectBy(
  obj: UniformAny,
  key: string,
  value: string
): UniformSectionChildren | undefined {
  if (obj[key] === value) {
    return obj
  } else if (obj.children) {
    for (let i = 0; i < obj.children.length; i++) {
      const child = findObjectBy(obj.children[i], key, value)
      if (child !== undefined) {
        return child
      }
    }
  } else if (obj.items) {
    for (let i = 0; i < obj.items.length; i++) {
      const child = findObjectBy(obj.items[i], key, value)
      if (child !== undefined) {
        return child
      }
    }
  }
  return undefined
}
