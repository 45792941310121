import { useGetAllowedContentPermissions } from '@cais-group/homepage/domain/members'
import {
  ApiError,
  ApiStateEnum,
  checkContentAccess,
  isData,
  isError,
  parseFirstFromCollection,
  transformContentPermissionsData,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  GetWhitepaperBySlugQuery,
  useGetWhitepaperBySlugQuery,
  useGetWhitepaperPermissionDataQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

import { FIRMS_COLLECTION_LIMIT } from '../constants'

type Data = WhitepaperData | null

export const useGetWhitepaperBySlug = (slug?: string) => {
  const allowedUserPermissions = useGetAllowedContentPermissions()
  const { fundProductIds, firmIds } = allowedUserPermissions.data

  const {
    data: whitepaperPermissionsData,
    isError: whitepaperPermissionsDataError,
  } = useGetWhitepaperPermissionDataQuery(
    {
      slug: slug || '',
      firmsLimit: FIRMS_COLLECTION_LIMIT,
    },
    {
      enabled: Boolean(fundProductIds && firmIds),
      select: (data) =>
        transformContentPermissionsData({
          data: data,
          name: 'whitepaperCollection',
        }),
      refetchOnWindowFocus: false,
    }
  )

  const hasAccess = checkContentAccess(
    allowedUserPermissions.data,
    whitepaperPermissionsData
  )

  const notFound = Boolean(whitepaperPermissionsData?.notFound)

  const existsWithNoAccess = !hasAccess && !notFound

  const response = useReactQueryResultAsApiState<
    GetWhitepaperBySlugQuery,
    WhitepaperData
  >(
    useGetWhitepaperBySlugQuery(
      {
        slug: slug || '',
        preview: previewService.enabled,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      {
        enabled:
          hasAccess &&
          !notFound &&
          Boolean(fundProductIds && firmIds && whitepaperPermissionsData),
        refetchOnWindowFocus: false,
      }
    ),
    (data) =>
      parseFirstFromCollection(data, 'whitepaperCollection') as WhitepaperData,

    'Could not load whitepaperBySlugQuery'
  )
  const loading =
    response === ApiStateEnum.LOADING || allowedUserPermissions.isLoading
  const error = isError(response) || whitepaperPermissionsDataError

  return compileResults(response, existsWithNoAccess, notFound, loading, error)
}
const compileResults = (
  data: Data | ApiError | ApiStateEnum,
  existsWithNoAccess: boolean,
  notFound: boolean,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
    existsWithNoAccess,
    notFound,
  }
}
