import { components } from './generated'

export type PageRequest = components['schemas']['PageRequest']

export type PageResult =
  | components['schemas']['PageResultFirmResponse']
  | components['schemas']['PageResultExperienceResponse']

export type SearchRequestFilterSort =
  | NonNullable<components['schemas']['SearchDtoExperienceFilter']>
  | NonNullable<components['schemas']['SearchDtoFirmFilterRequest']>

export type PageInfo = components['schemas']['PageInfo']
