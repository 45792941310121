import { useLocation } from 'react-router-dom'

import { CaisiqLink } from '@cais-group/caisiq/feature/routes'
import { Button } from '@cais-group/equity/atoms/button'

export interface CeCreditGuideLinkProps {
  slug?: string
  title?: string
  onClick?(): void
}

export function CeCreditGuideLink({
  slug,
  title = 'Learn more about CE Credits',
  onClick,
}: CeCreditGuideLinkProps) {
  const location = useLocation()

  if (!slug) {
    return null
  }

  return (
    <CaisiqLink
      data-testid="link-to-ce-guide"
      onClick={onClick}
      to="guide"
      args={[slug]}
      state={{ from: location.pathname }}
    >
      <Button variant="tertiary">{title}</Button>
    </CaisiqLink>
  )
}

export default CeCreditGuideLink
