import cx from 'classnames'
import { forwardRef, MouseEventHandler, ReactNode } from 'react'

import { Avatar } from '@cais-group/equity/atoms/avatar'
import { Checkbox } from '@cais-group/equity/atoms/checkbox'
import { Counter } from '@cais-group/equity/atoms/counter'
import { Highlighter } from '@cais-group/equity/atoms/highlighter'
import { Link } from '@cais-group/equity/atoms/link'

// "value" field gets omitted to support using ListItem (and its variants)
// as Select's Option component. Removing this Omit causes type issues elsewhere
export interface ListItemProps
  extends Omit<React.ComponentPropsWithRef<'li'>, 'value'> {
  children?: ReactNode
  /** Specifies whether the list item is disabled. */
  isDisabled?: boolean
  /** Used with `isSearchable` and is the text to be highlighted. */
  inputValue?: string
  /** Specifies whether the list item is currently focused. */
  isFocused?: boolean
  /** Specifies whether the list item is interactive. Defaults to true. */
  isInteractive?: boolean
  /** Specifies whether the list item is selected. */
  isSelected?: boolean
  /** Highlights the matching text. */
  isSearchable?: boolean
}

/**
 * `ListItem` is a component designed to be rendered within select lists such as:
 * - [Select](/docs/molecules-select-about--docs)
 * - [PillSelect](/docs/molecules-pillselect-about--docs)
 *
 * Various types are available including:
 *  - checkbox
 *  - checkbox with counter
 *  - checkbox with group
 *  - link
 *  - text
 *
 * The `ListItem` component itself is a **"base"** that's used by the variants.
 * Please consult these variants to find out how to use them.
 */
export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  (props, ref) => {
    const {
      isDisabled,
      isFocused,
      isSelected,
      children,
      inputValue,
      isSearchable,
      isInteractive = true,
      ...validProps
    } = props

    return (
      <li
        ref={ref}
        {...validProps}
        {...((isDisabled || !isInteractive) && { onClick: () => {} })}
        className={cx('bodySmall flex w-full items-center p-8', {
          'bg-primary-100': isSelected && !isDisabled,
          'hover:bg-neutral-100': !isSelected && !isDisabled && isInteractive,
          'cursor-not-allowed': isDisabled,
          'bg-neutral-100': isFocused && !isSelected && !isDisabled,
          'hover:bg-primary-200': isSelected && !isDisabled && isInteractive,
          'cursor-pointer': !isDisabled && isInteractive,
        })}
      >
        {children}
      </li>
    )
  }
)

export interface ListItemTextProps extends Omit<ListItemProps, 'children'> {
  /** Visible text for the list item. */
  label: string
}

/**
 * Use `ListItemText` when only text is needed. Supports highlighting the input value.
 */
export const ListItemText = forwardRef<HTMLLIElement, ListItemTextProps>(
  ({ label, ...props }, ref) => (
    <ListItem {...props} ref={ref}>
      <HighlightedText
        isSearchable={props.isSearchable}
        textToHighlight={props.inputValue}
        inputText={label}
      />
    </ListItem>
  )
)

/**
 * Use `ListItemCheckbox` when the item needs a checkbox.
 */
export const ListItemTextWithCheckbox = forwardRef<
  HTMLLIElement,
  ListItemTextProps
>(({ label, ...props }, ref) => (
  <ListItemCheckbox {...props} ref={ref}>
    <HighlightedText
      isSearchable={props.isSearchable}
      textToHighlight={props.inputValue}
      inputText={label}
    />
  </ListItemCheckbox>
))

export interface ListItemWithGroupAndAvatarProps
  extends Omit<ListItemProps, 'children'> {
  /** The name, usually initials, used with the avatar. */
  groupName: string
  /** Optional function to handle mouse down event. */
  onMouseDown?: MouseEventHandler<HTMLLIElement>
  /** Optional line of text displayed under the group name. */
  subtext?: string
}

/**
 * Use `ListItemWithGroupAndAvatar` when the item has an avatar. Supports highlighting the input value.
 */
export const ListItemWithGroupAndAvatar = forwardRef<
  HTMLLIElement,
  ListItemWithGroupAndAvatarProps
>(({ groupName, subtext, ...props }, ref) => (
  <ListItem {...props} ref={ref} isInteractive={!!props.onMouseDown}>
    <div className="flex items-center gap-x-16">
      <Avatar type="group">{groupName}</Avatar>
      <div className="flex flex-col items-start">
        <HighlightedText
          isSearchable={props.isSearchable}
          textToHighlight={props.inputValue}
          inputText={groupName}
          includeWrapper
        />
        {subtext ? <p className="small text-neutral-600">{subtext}</p> : null}
      </div>
    </div>
  </ListItem>
))

export interface ListItemWithGroupAndCheckboxAndAvatarProps
  extends Omit<ListItemProps, 'children'> {
  /** The name, usually initials, used with the avatar. */
  groupName: string
  /** Optional function to handle mouse down event. */
  onMouseDown?: MouseEventHandler<HTMLLIElement>
}

/**
 * Use `ListItemWithGroupAndCheckboxAndAvatar` when the item needs a checkbox and an avatar. Supports highlighting the input value.
 */
export const ListItemWithGroupAndCheckboxAndAvatar = forwardRef<
  HTMLLIElement,
  ListItemWithGroupAndCheckboxAndAvatarProps
>(({ groupName, ...props }, ref) => (
  <ListItem {...props} ref={ref}>
    <Checkbox readOnly checked={props.isSelected}>
      <div className="flex items-center gap-x-16">
        <Avatar type="group">{groupName}</Avatar>
        <HighlightedText
          isSearchable={props.isSearchable}
          textToHighlight={props.inputValue}
          inputText={groupName}
          includeWrapper
        />
      </div>
    </Checkbox>
  </ListItem>
))

export interface ListItemCheckboxCounterProps
  extends Omit<ListItemProps, 'children'> {
  /** Visible text for the list item. */
  label: string
  /** The number used by the counter. */
  total?: number
  /** Optional function to handle mouse down event. */
  onMouseDown?: MouseEventHandler<HTMLLIElement>
}

/**
 * Use `ListItemCheckboxCounter` when the item needs a checkbox and a counter.
 */
export const ListItemCheckboxCounter = forwardRef<
  HTMLLIElement,
  ListItemCheckboxCounterProps
>(({ isDisabled = false, label, total, ...props }, ref) => (
  <ListItem {...props} isDisabled={isDisabled} ref={ref}>
    <Checkbox readOnly checked={props.isSelected} disabled={isDisabled}>
      {label}
    </Checkbox>
    {typeof total === 'number' ? (
      <span className="ml-auto">
        <Counter
          color={props.isSelected ? 'primary' : 'neutral'}
          size="small"
          value={total}
          variant={props.isSelected ? 'dark' : 'light'}
        />
      </span>
    ) : null}
  </ListItem>
))

export interface ListItemLinkProps extends ListItemProps {
  /** The `href` of the link. */
  href: string
  /** Include a dot after the link text. */
  hasDotTag?: boolean
}

/**
 * Use `ListItemLink` when the content is a link. Supports an optional "error" dot after the text.
 */
export const ListItemLink: React.FC<ListItemLinkProps> = (props) => (
  <ListItem {...props}>
    <Link
      href={props.href}
      className={cx(
        'small flex w-full cursor-pointer items-center justify-between',
        {
          'text-neutral-900 hover:bg-neutral-100': !props.isSelected,
          'bg-primary-100 text-primary-600': props.isSelected,
        }
      )}
    >
      {props.children}
      {props.hasDotTag ? (
        <span className="bg-error-600 h-8 w-8 rounded-full" />
      ) : null}
    </Link>
  </ListItem>
)

/**
 * Use `ListItemCheckbox` when the item needs a checkbox.
 */
export const ListItemCheckbox = forwardRef<HTMLLIElement, ListItemProps>(
  (props, ref) => (
    <ListItem {...props} ref={ref}>
      <Checkbox readOnly checked={props.isSelected}>
        {props.children}
      </Checkbox>
    </ListItem>
  )
)

interface HighlightedTextProps extends Pick<ListItemProps, 'isSearchable'> {
  inputText: string
  textToHighlight: ListItemProps['inputValue']
  includeWrapper?: boolean
}

export const HighlightedText = (props: HighlightedTextProps) => {
  const { includeWrapper, isSearchable, ...rest } = props
  const children = isSearchable ? <Highlighter {...rest} /> : rest.inputText

  return includeWrapper ? (
    <div className="flex flex-col">{children}</div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  )
}
