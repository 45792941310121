import { Course } from '@cais-group/shared/util/type/caisiq-be'
import {
  isLearningComplete,
  isPassed,
} from '@cais-group/shared/util/type/course'

import { useGetCourseCeCredits } from './useGetCourseCeCredits'

/**
 * A helper function that aids in deciding whether we should surface the CE interstitials page
 * to the user.
 */
export function useShowCeInterstitialsPage(enabled: boolean = true) {
  const { ceCreditsAvailable, isFetched } = useGetCourseCeCredits(enabled)

  const showCeInterstitials = (course: Course) => {
    if (!isFetched) {
      return undefined
    }

    const isLearned = isLearningComplete(course) || isPassed(course)

    const hasCreditsAvailable = ceCreditsAvailable(course).hasCeCredits

    return Boolean(isLearned && hasCreditsAvailable)
  }

  return {
    showCeInterstitials: (course: Course) => showCeInterstitials(course),
  }
}
