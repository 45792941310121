import { useQuery } from 'react-query'

import { getContactAccounts } from '@cais-group/shared/domain/investor-profile'

/**
 * FIXME: this is being used in investor-profile and uniform (for modals). It should be moved into IP once modals PoC goes in.
 * PoC: https://github.com/cais-group/monorepo/pull/7866
 */
export function useContactAccountData(contactId: string) {
  return useQuery({
    queryKey: ['getContactAccounts', contactId],
    queryFn: async () => {
      const result = await getContactAccounts(contactId)
      return {
        contactAccounts: result,
      }
    },
    cacheTime: 0,
  })
}
