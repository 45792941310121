import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import cx from 'classnames'
import { PropsWithChildren, ReactElement } from 'react'

import { H5StrongCss } from '@cais-group/approved/ui/typography'
import { colors } from '@cais-group/equity/particles/colors'
import { SPACING } from '@cais-group/shared/ui/design-tokens'

import { useStepState } from './step-state-provider'

export type OrderedFieldsetState = 'inactive' | 'active' | 'complete'

export interface OrderedFieldsetProps {
  state?: OrderedFieldsetState
  step?: number
  legend?: ReactElement | undefined
}

const getColorsByState = (state: OrderedFieldsetState) => {
  switch (state) {
    case 'active':
      return {
        color: colors['eq-color-primary-600'],
        border: colors['eq-color-primary-600'],
        bg: colors['eq-color-neutral-0'],
      }
    case 'complete':
      return {
        color: colors['eq-color-neutral-0'],
        border: colors['eq-color-success-600'],
        bg: colors['eq-color-success-600'],
      }
    case 'inactive':
    default:
      return {
        color: colors['eq-color-neutral-500'],
        border: colors['eq-color-neutral-500'],
        bg: colors['eq-color-neutral-0'],
      }
  }
}

const StyledFieldset = styled.fieldset<{ hasState: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
  margin-left: ${({ hasState }) => (hasState ? `calc(${SPACING.s56} / 2)` : 0)};
  padding-left: ${({ hasState }) =>
    hasState ? `calc(${SPACING.s56} / 2 + ${SPACING.s32})` : 0};
  padding-top: ${({ hasState }) => (hasState ? SPACING.s12 : 0)};
  padding-bottom: ${SPACING.s32};
  border-width: 0;
  border-left: ${({ hasState }) =>
    hasState ? `1px dashed ${colors['eq-color-neutral-600']}` : 'none'};
  & > legend {
    float: left;
  }
  &:last-of-type {
    border: none;
  }
  > * {
    margin-bottom: ${SPACING.s32};
  }
  &:first-of-type {
    counter-set: form-section 1;
  }
  counter-increment: form-section;
`

const Marker = styled.span<{ state: OrderedFieldsetState }>`
  ${H5StrongCss}
  ${(props) =>
    props.state !== 'complete' &&
    `
  &:before {
    content: counter(form-section)
  }
  `}
  border-radius: 40px;
  color: ${(props) => getColorsByState(props.state).color};
  border: 2px solid ${(props) => getColorsByState(props.state).border};
  width: ${SPACING.s56};
  height: ${SPACING.s56};
  position: absolute;
  display: block;
  left: calc(-${SPACING.s56} / 2);
  top: 0px;
  background: ${(props) => getColorsByState(props.state).bg};
  display: flex;
  align-items: center;
  justify-content: center;
`

export function OrderedFieldset({
  state,
  step,
  legend,
  children,
}: PropsWithChildren<OrderedFieldsetProps>) {
  const { stepRefs } = useStepState()

  return (
    <StyledFieldset
      hasState={Boolean(state)}
      aria-expanded={state === undefined || state === 'active'}
      ref={(el) => {
        if (step) stepRefs.current[step - 1] = el
      }}
    >
      {state && (
        <Marker
          role="progressbar"
          aria-valuemin={0}
          aria-valuemax={2}
          aria-valuenow={state === 'complete' ? 2 : state === 'active' ? 1 : 0}
          aria-valuetext={state}
          aria-label={`field-step-${legend}`}
          state={state}
        >
          {state === 'complete' && <CheckIcon />}
        </Marker>
      )}

      {legend && (
        <legend
          className={cx({
            'text-primary-600': state === 'active',
            'text-neutral-900': state === 'complete',
            'text-neutral-600': state === 'inactive',
          })}
        >
          {legend}
        </legend>
      )}
      {children}
    </StyledFieldset>
  )
}

export default OrderedFieldset
