/* WARNING: DO NOT EDIT */
/* THESE VALUES ARE FROM STYLE GUIDE - DO NOT ADD OR REMOVE UNLESS THERE ARE AMENDMENTS THERE */

/* REFERENCE https://www.figma.com/file/2tZq7RGKNp3o8r9hiIFpxn/Grids-and-spacing?node-id=3%3A2 */
export const SPACING_UNITS = {
  s4: 4,
  // █
  s8: 8,
  // ██
  s12: 12,
  // ███
  s16: 16,
  // ████
  s24: 24,
  // ██████
  s32: 32,
  // ████████
  s56: 56,
  // ██████████████
  s88: 88,
  // ██████████████████████
  s144: 144,
  // ████████████████████████████████████
  s232: 232,
  // ██████████████████████████████████████████████████████████
  s376: 376,
  // ██████████████████████████████████████████████████████████████████████████████████████████████
  s608: 608,
  // ████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████████
}

export const SPACING = {
  s4: `${SPACING_UNITS.s4}px`,
  s8: `${SPACING_UNITS.s8}px`,
  s12: `${SPACING_UNITS.s12}px`,
  s16: `${SPACING_UNITS.s16}px`,
  s24: `${SPACING_UNITS.s24}px`,
  s32: `${SPACING_UNITS.s32}px`,
  s56: `${SPACING_UNITS.s56}px`,
  s88: `${SPACING_UNITS.s88}px`,
  s144: `${SPACING_UNITS.s144}px`,
  s232: `${SPACING_UNITS.s232}px`,
  s376: `${SPACING_UNITS.s376}px`,
  s608: `${SPACING_UNITS.s608}px`,
}

/* REFERENCE: https://www.figma.com/file/1q7uzq285UmqkNTRXWpye9/Color?node-id=1062%3A1065 */
export const SHADOWS = {
  shadow4: '0 0 4px 0 rgba(0, 0, 0, .08)',
  shadow8: '0 4px 8px 0 rgba(0, 0, 0, .04)',
  shadow16: '0 4px 16px 0 rgba(0, 0, 0, .04)',
  shadow24: '0 8px 24px 0 rgba(0, 0, 0, .08)',
  shadow32: '0 0 32px 0 rgba(0, 0, 0, .12)',
}

/* REFERENCE: https://www.figma.com/file/9p46RPQfnXRph68T9RvTOR/Type-system?node-id=38%3A7 */
export const FONTS = {
  fontText: "'Suisse Intl', Helvetica, sans-serif",
  fontMono: "'GT America', monospace",
  fontSerif: "'Suisse Works', Georgia, serif",
}

/* REFERENCE: https://www.figma.com/file/2tZq7RGKNp3o8r9hiIFpxn/Grids-and-spacing?node-id=523%3A177 */
/* We choose 481, 769 and 1025 as we want 480, 768, 1024 to be inclusive upper bounds: https://app.shortcut.com/cais/story/19151/ui-adjust-breakpoints */
export const BREAKPOINTS = {
  breakpointSm: '30.0625rem' /* 481px */,
  breakpointMd: '48.0625rem' /* 769px */,
  breakpointLg: '64.0625rem' /* 1025px */,
  breakpointXl: '103.75rem' /* 1660px */,
}

export const DRAWER_BREAKPOINTS = {
  small: 500,
  medium: 1880,
  large: 1920,
}

/* REFERENCE: https://www.figma.com/file/1q7uzq285UmqkNTRXWpye9/Color?node-id=617%3A3 */
const Mariner = '#313B97'
const Cornflower = '#8CADE9'
const Peacock = '#328883'
const Pistachio = '#96DBC8'
const Marigold = '#FFC170'
const TerraCotta = '#BB530A'
const Wine = '#87394D'
const Ube = '#C0BBF7'
const Mako = '#595C5C'
const Aegean = '#3958a1'
const Birch = '#f4f0db'
const ButtonHover = '#002C55'
const Grass = '#a2cd7f'
const Harissa = '#c47774'
const Heather = '#6f80ba'
const Moss = '#d1e0a5'
const Oyster = '#cdd1d8'
const Periwinkle = '#dadeff'
const PowderBlue = '#cee3fc'
const Rosewood = '#925364'
const Sienna = '#e38852'
const Toast = '#f3d7bc'

// REFERENCE: https://www.figma.com/file/1q7uzq285UmqkNTRXWpye9/Color?node-id=617%3A3
export const DATA_VISUALIZATION_PALETTE = [
  Mariner,
  Cornflower,
  Peacock,
  Pistachio,
  Marigold,
  TerraCotta,
  Wine,
  Ube,
  Mako,
  Heather,
  PowderBlue,
  Grass,
  Moss,
  Toast,
  Sienna,
  Rosewood,
  Periwinkle,
  Oyster,
  ButtonHover,
  Aegean,
  Birch,
  Harissa,
]

export const DATA_VISUALIZATION_COLORS = {
  Aegean,
  Birch,
  ButtonHover,
  Cornflower,
  Grass,
  Harissa,
  Heather,
  Mako,
  Marigold,
  Mariner,
  Moss,
  Oyster,
  Peacock,
  Periwinkle,
  Pistachio,
  PowderBlue,
  Rosewood,
  Sienna,
  TerraCotta,
  Toast,
  Ube,
  Wine,
}
