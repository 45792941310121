import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'

import { SPACING } from '@cais-group/shared/ui/design-tokens'

import {
  ButtonMenuContainer,
  ButtonMenuContainerProps,
} from '../button-menu-container'

export interface ButtonMenuRadioOption<V = string> {
  label: string
  value: V
}
export interface ButtonMenuRadioProps<T = string> {
  label: string
  options: Array<ButtonMenuRadioOption<T>>
  value?: ButtonMenuRadioOption<T> | null
  onChange: (option: ButtonMenuRadioOption<T>) => void
  buttonProps?: ButtonMenuContainerProps['buttonProps']
  menuPlacement?: ButtonMenuContainerProps['menuPlacement']
}
export function ButtonMenuRadio<T = string>({
  label,
  options,
  value = null,
  onChange,
  buttonProps,
  menuPlacement,
}: ButtonMenuRadioProps<T>) {
  return (
    <ButtonMenuContainer
      label={label}
      buttonProps={buttonProps}
      menuPlacement={menuPlacement}
    >
      {({ isMenuActive, setIsMenuActive }) => (
        <FormControl>
          <RadioGroup
            aria-labelledby="default-label"
            name="radio-buttons-group"
            data-testid="ButtonMenuRadio-menu"
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.label}
                control={
                  <Radio
                    size="small"
                    onChange={() => {
                      onChange(option)
                      setIsMenuActive(!isMenuActive)
                    }}
                    checked={value === option}
                  />
                }
                value={option.value}
                label={option.label}
                style={{ marginBottom: SPACING.s4 }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </ButtonMenuContainer>
  )
}
