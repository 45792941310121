import { useRecoilState } from 'recoil'

import { useGetDefaultCourseImageSet } from '@cais-group/caisiq/domain/course-image'
import { useFilterLibraryCoursesBySearch } from '@cais-group/caisiq/domain/library-course'
import { searchTermAtom } from '@cais-group/caisiq/domain/search'

import { CourseLibrary } from './caisiq-feature-course-library'

export const CourseLibraryContainer = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom)

  const maybeSearchFilteredLibraryCourses = useFilterLibraryCoursesBySearch()
  const courseImages = useGetDefaultCourseImageSet()
  return (
    <CourseLibrary
      searchFilteredLibraryCourses={maybeSearchFilteredLibraryCourses || []}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      courseImages={courseImages}
    />
  )
}
