import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { useUniformContext } from '../context'
import { type ModalType, useModal } from '../modals/context'
import type { CreateUniformModalProps } from '../modals/templates/uniform'
import type { ComponentDict } from '../types'

import { useOptions } from './async-options'

export type ActionModalProps = {
  type: ModalType
  name?: string
  /**
   * If initializing infinite scroll with default value, we need to provide initial selected object because it might not be in the initially loaded list of options.
   * Key represents the field id of the infinite scroll component, value is the key where to extract the option object from query.
   */
  initialOptions?: Record<
    string,
    {
      /** path to access value in object */
      path: string
      /** map object key to value */
      value: string
      /** map object key to label */
      label: string | string[]
    }
  >
  props: CreateUniformModalProps
}

const isActionModalProps = (props?: unknown): props is ActionModalProps => {
  if (
    typeof props === 'object' &&
    props !== null &&
    props &&
    'type' in props &&
    'props' in props
  ) {
    return true
  }
  return false
}

export const useDropdownAction = ({
  modalProps,
  refetch,
}: {
  modalProps?: ActionModalProps
  refetch?: ReturnType<typeof useOptions>['refetch']
}): {
  handleDropdownAction?: (finalRef: HTMLButtonElement | null) => void
  actionName?: string
} => {
  const { openModal } = useModal()
  const { services, settings, componentDict } = useUniformContext()
  const { getValues } = useFormContext()
  const actionName = modalProps?.name
  const allValues = getValues()

  const fn = React.useCallback(
    (finalRef: HTMLButtonElement | null) => {
      if (isActionModalProps(modalProps)) {
        const { type, props } = modalProps

        openModal(
          type,
          {
            ...props,
            defaultValues: { ...allValues, ...props.defaultValues },
            services,
            settings,
            componentDict: componentDict as ComponentDict,
            onSuccess: async () => {
              // TODO: select the newly created item
              // QUESTION: (how would we select item from infinite query if it's not in the list?
              // Possibly would have to be sorted by newly create items and it would need to appear at the top)
              if (typeof refetch === 'function') {
                await refetch()
              }
            },
          },
          finalRef
        )()
      } else {
        console.error('No dropdown modal type or props provided')
      }
    },
    [
      modalProps,
      openModal,
      allValues,
      services,
      settings,
      componentDict,
      refetch,
    ]
  )

  return {
    handleDropdownAction: isActionModalProps(modalProps) ? fn : undefined,
    actionName,
  }
}
