import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'

export function usePopulateLabelOnNameChange(originalLabel?: string) {
  const { getValues, getFieldState, resetField, setValue } = useFormContext()
  const name = useWatch<ExperienceFields>({ name: 'name' })
  const oldLabel = getValues('label')
  const labelState = getFieldState('label')
  const [lastSeenName, setLastSeenName] = useState('')

  useEffect(() => {
    if (name === lastSeenName) {
      return
    }
    setLastSeenName(String(name))

    if (oldLabel.length === 0) {
      resetField('label')
    }

    // When label is untouched but had default value
    if (originalLabel && !labelState.isTouched) {
      return
    }

    if (labelState.isTouched) {
      return
    }

    const label = String(name)
      .toLowerCase()
      .replace(/[^A-Za-z0-9-]/g, '')
    if (label !== oldLabel) {
      setValue('label', label)
    }
  }, [
    labelState,
    name,
    oldLabel,
    originalLabel,
    resetField,
    setValue,
    lastSeenName,
    setLastSeenName,
  ])
}
