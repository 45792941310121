import { ReactNode, useCallback } from 'react'

import { ChecklistItem } from '@cais-group/shared/ui/checklist-item'
import {
  SelectableList,
  SelectableListItem,
  SelectableListRenderItem,
  TwoColumnSelectableList,
  WithController,
  WithMaxHeight,
  WithTitle,
  WithSelectAll,
  WithSelectAllProps,
} from '@cais-group/shared/ui/selectable-list'

const ConditionalMaxHeight = ({
  children,
  maxHeight,
}: {
  children: JSX.Element
  maxHeight?: string
}) =>
  maxHeight ? (
    <WithMaxHeight className={maxHeight}>{children}</WithMaxHeight>
  ) : (
    children
  )

const ConditionalSelectAll = <
  TItem extends SelectableListItem<TValue>,
  TValue extends string
>({
  children,
  hasSelectAll,
  ...withSelectAllProps
}: WithSelectAllProps<TItem, TValue> & { hasSelectAll: boolean }) =>
  hasSelectAll ? (
    <WithSelectAll {...withSelectAllProps}>{children}</WithSelectAll>
  ) : (
    children
  )

export interface FilterListProps<
  TItem extends SelectableListItem<TValue>,
  TValue extends string
> {
  items: TItem[]
  disabledItems?: TValue[]
  maxHeight?: string // Eg: 'max-h-376'
  name: string
  onUpdate?: (value: TValue, wasPreviouslySelected: boolean) => void
  renderListItem?: SelectableListRenderItem<TItem, TValue>
  renderLabel?: (label: string) => ReactNode
  testId?: string
  title?: string
  titleTopMargin?: string
  columns?: 1 | 2
  hasSelectAll?: boolean
  listGap?: '8' | '12'
}

export const FilterList = <
  TItem extends SelectableListItem<TValue>,
  TValue extends string
>({
  items,
  disabledItems = [],
  maxHeight,
  name,
  onUpdate,
  renderListItem,
  testId = 'checklist',
  title,
  titleTopMargin = 'mt-24',
  columns = 1,
  hasSelectAll = false,
  renderLabel,
  listGap,
}: FilterListProps<TItem, TValue>) => {
  const renderDefaultListItem = useCallback<
    SelectableListRenderItem<TItem, TValue>
  >(
    ({ item, onChange: onItemChange, selectedItems }) => (
      <ChecklistItem<TValue>
        disabledOptions={disabledItems}
        key={item.value}
        label={renderLabel ? renderLabel(item.label) : item.label}
        name={`${name}-${item.value}`}
        onChange={() => onItemChange({ selectedItems, item })}
        onUpdate={onUpdate}
        selectedOptions={selectedItems}
        testId={`${testId}-${item.value}`}
        value={item.value}
      />
    ),
    [disabledItems, name, onUpdate, testId, renderLabel]
  )

  const renderItem = renderListItem ?? renderDefaultListItem

  return (
    <WithController<TValue>
      name={name}
      renderList={({ onChange, selectedItems }) => (
        <ConditionalSelectAll
          hasSelectAll={hasSelectAll}
          items={items}
          selectedItems={selectedItems}
          onChange={onChange}
          disabledItems={disabledItems}
        >
          <ConditionalMaxHeight maxHeight={maxHeight}>
            <WithTitle className={titleTopMargin} title={title}>
              {columns === 2 ? (
                <TwoColumnSelectableList<TItem, TValue>
                  items={items}
                  onChange={onChange}
                  renderItem={renderItem}
                  selectedItems={selectedItems}
                />
              ) : (
                <SelectableList<TItem, TValue>
                  items={items}
                  onChange={onChange}
                  renderItem={renderItem}
                  selectedItems={selectedItems}
                  gap={listGap}
                  testId={`${testId}-container`}
                />
              )}
            </WithTitle>
          </ConditionalMaxHeight>
        </ConditionalSelectAll>
      )}
    />
  )
}
