import { Button } from '@cais-group/equity/atoms/button'

type ActionButtonProps = {
  onClick?: () => void
  onBlur?: () => void
  text: string
}
export const ActionButton = ({ onClick, onBlur, text }: ActionButtonProps) => {
  return (
    <div className="bg-neutral-0 sticky bottom-0 flex items-center justify-start border-t border-solid border-neutral-100 py-12">
      <Button
        testId="action-button"
        variant="tertiary"
        size="small"
        type="button"
        onBlur={onBlur}
        startAdornment="Add"
        onClick={onClick}
      >
        {text}
      </Button>
    </div>
  )
}
