import { ellipsify } from '@cais-group/equity/utilitarian'
import { LayoutCluster } from '@cais-group/shared/ui/layout'

export const VideoHeader = (props: {
  playlistTitle?: string | null
  videoTitle?: string | null
  nav: React.ReactNode
  seriesTitleMaxChars: number
}) => {
  const { playlistTitle, videoTitle, nav, seriesTitleMaxChars } = props
  return (
    <LayoutCluster
      justify="space-between"
      className="w-full flex-nowrap self-center lg:pb-12"
    >
      <LayoutCluster className="[&>div]:flex-nowrap lg:[&>div]:flex-wrap">
        {nav}
        {nav ? (
          <p className="body text-neutral-0">
            {playlistTitle
              ? ellipsify(playlistTitle, seriesTitleMaxChars)
              : videoTitle && ellipsify(videoTitle, seriesTitleMaxChars)}
          </p>
        ) : null}
      </LayoutCluster>
    </LayoutCluster>
  )
}
