import styled from '@emotion/styled'
import { Grid as MuiGrid, GridProps as MuiGridProps } from '@mui/material'

import {
  BREAKPOINTS,
  SPACING,
  SPACING_UNITS,
} from '@cais-group/shared/ui/design-tokens'

// Implementation of CAIS grid
// Figma links:
// - https://www.figma.com/file/2tZq7RGKNp3o8r9hiIFpxn/Grids-and-spacing?node-id=7%3A82
// - https://www.figma.com/file/2tZq7RGKNp3o8r9hiIFpxn/Grids-and-spacing?node-id=518%3A230
// - https://www.figma.com/file/2tZq7RGKNp3o8r9hiIFpxn/Grids-and-spacing?node-id=943%3A14
// - https://www.figma.com/file/2tZq7RGKNp3o8r9hiIFpxn/Grids-and-spacing?node-id=523%3A177
// xs: 2 columns up to 480
// sm: 6 columns up to 768
// md: 12 columns up to 1024
// lg: 12 columns up to 1660
// xl: 12 columns above 1660

export type GridProps = Omit<MuiGridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'> & {
  xs?: 6 | 12
  sm?: 2 | 4 | 6 | 8 | 10 | 12
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}
export const Grid = (props: GridProps) => {
  const { xs, sm, md, lg, xl, ...rest } = props
  const xsCols = xs || 12
  const smCols = sm || xsCols
  const mdCols = md || smCols
  const lgCols = lg || mdCols
  const xlCols = xl || lgCols
  return (
    <MuiGrid
      columnSpacing={{
        xs: SPACING.s16,
        sm: SPACING.s24,
        md: SPACING.s32,
        lg: SPACING.s32,
        xl: SPACING.s32,
      }}
      xs={rest.item && xsCols}
      sm={rest.item && smCols}
      md={rest.item && mdCols}
      lg={rest.item && lgCols}
      xl={rest.item && xlCols}
      maxWidth={(theme) =>
        rest.container
          ? { xl: `${theme.breakpoints.values.xl - 2 * SPACING_UNITS.s56}px` }
          : undefined
      } // max width is 1548px
      {...rest}
    />
  )
}

export const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-left: var(--s24);
  padding-right: var(--s24);
  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
    padding-left: var(--s32);
    padding-right: var(--s32);
  }
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    padding-left: var(--s56);
    padding-right: var(--s56);
  }
`

export const StickyCenteredColumn = styled(CenteredColumn)`
  position: sticky;
  left: 0;
  top: 0;
  z-index: var(--z10);
  border-bottom: 1px solid rgb(var(--colors-neutral-100));
  background-color: rgb(var(--colors-neutral-0));
`
