import { merge } from 'lodash-es'
import * as React from 'react'
import { BehaviorSubject } from 'rxjs'

import { staticGridClassDict } from './form-grid'
import { useRulesFeedSubject } from './hooks'
import { useComponentDict } from './hooks/use-component-dict'
import { useSelectedOptions } from './hooks/use-selected-options'
import type { UniformServices } from './services'
import type {
  ComponentDict,
  FieldRulesKeys,
  InitialOptions,
  UniformAny,
  UniformFieldRules,
  UniformSchema,
  UniformSettings,
} from './types'
import { extractSchemaFieldIds, getDependencies } from './utils'
import { getFieldRulesDeps } from './utils/get-dependency-tree'

type UniformContextProps<
  TUniformServices extends UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TPossibleEntries extends keyof UniformAny = UniformAny
> = {
  schemaIds: string[]
  future?: Record<string, boolean>
  selectedOptions: ReturnType<typeof useSelectedOptions>
  rulesFeed: ReturnType<typeof useRulesFeedSubject>
  staticGridClassDict: Record<string, string>
  initialOptions: InitialOptions<TPossibleEntries>
  formRef: React.RefObject<HTMLFormElement>
  services: TUniformServices
  settings?: UniformSettings
  componentDict: TComponentDict
  ruleDeps: Record<FieldRulesKeys, Record<string, string[]>>
  rules?: Partial<
    Record<TPossibleEntries, Partial<UniformFieldRules<TPossibleEntries>>>
  >
  schema?:
    | UniformSchema<TUniformServices, TComponentDict>
    | UniformSchema<TUniformServices, TComponentDict>[]
  formId?: string
  onSubmit?: (
    values: UniformAny,
    rules?: Partial<
      Record<TPossibleEntries, Partial<UniformFieldRules<TPossibleEntries>>>
    >
  ) => Promise<UniformAny>
  onSuccess?: (response: unknown) => void
  onFailure?: (error: unknown) => void
  onSubmitAndRedirect?: () => Promise<void>
  requiredFields: string[]
  refetchFormData: (data?: Record<string, unknown>) => Promise<UniformAny>
}

const UniformContext =
  React.createContext<UniformContextProps<UniformAny> | null>(null)

type RenderPropsChildren = (
  props: UniformContextProps<UniformAny>
) => React.ReactNode

type UniformProviderProps<
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TPossibleEntries extends keyof UniformAny = UniformAny
> = {
  schema?:
    | UniformSchema<TUniformServices, TComponentDict, TPossibleEntries>
    | UniformSchema<TUniformServices, TComponentDict, TPossibleEntries>[]
  selectedOptions?: ReturnType<typeof useSelectedOptions>
  rulesFeed?: ReturnType<typeof useRulesFeedSubject>
  future?: Record<string, boolean>
  initialOptions?: InitialOptions<TPossibleEntries>
  settings?: UniformSettings
  formRef?: React.RefObject<HTMLFormElement>
  services?: TUniformServices
  children?: React.ReactNode | RenderPropsChildren
  componentDict?: TComponentDict
  rules?: Partial<
    Record<TPossibleEntries, Partial<UniformFieldRules<TPossibleEntries>>>
  >
  formId?: string
  onSubmit?: (
    values: UniformAny,
    rules?: Partial<Record<string, UniformFieldRules>>
  ) => Promise<UniformAny>
  onSuccess?: (response: unknown, allData?: unknown) => void
  onFailure?: (error: unknown) => void
  onSubmitAndRedirect?: () => Promise<void>
  requiredFields?: string[]
  refetchFormData?: (data?: Record<string, unknown>) => Promise<UniformAny>
}

export const UniformProvider = <
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TPossibleEntries extends keyof UniformAny = UniformAny
>({
  schema,
  initialOptions,
  children,
  formRef: initialFormRef,
  services = {} as TUniformServices,
  componentDict: componentDictionary,
  rules,
  selectedOptions: initialSelectedOptions,
  rulesFeed = {
    subject: new BehaviorSubject({}),
    sharedRulesFeed: new BehaviorSubject({}),
  },
  settings,
  future,
  formId,
  onSubmit,
  onFailure,
  onSuccess,
  onSubmitAndRedirect,
  requiredFields = [],
  refetchFormData = () => {
    // NOTE:: refetchFormData might not always be needed. For example forms that "create new" most likely don't need it.
    console.warn('refetchFormData not implemented!')
    return Promise.resolve()
  },
}: UniformProviderProps<
  TUniformServices,
  TComponentDict,
  TPossibleEntries
>) => {
  const formRef = React.useRef<HTMLFormElement>(null)
  const componentDict = useComponentDict<UniformAny, TUniformServices>(
    componentDictionary
  )
  const selectedOptions = useSelectedOptions(initialOptions)
  const [options] = React.useState(() => initialOptions ?? {})

  // Note: The schema is dynamic and as such it is not possible to cache these computed values,
  // although we could memoize I don't think the trade-off between memory and compute makes sense here.
  const schemaIds = extractSchemaFieldIds(schema)

  const ruleDeps = (() => {
    if (schema) {
      const deps = getFieldRulesDeps(schema, rules)
      const asyncOptionsDepsFlattened = Object.entries(
        getDependencies(schema, 'asyncOptions', 'watchRemoteKeys')
      ).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value.flat(Infinity) }
      }, {})

      return {
        disabledIf: deps.disabledIf,
        validation: merge({}, deps.validation, asyncOptionsDepsFlattened),
        visibleIf: deps.visibleIf,
        requiredIf: deps.requiredIf,
      }
    }
    return {
      disabledIf: {},
      validation: {},
      visibleIf: {},
      requiredIf: {},
    }
  })()

  const value: UniformContextProps<UniformAny, UniformAny, UniformAny> = {
    schema,
    schemaIds,
    selectedOptions: initialSelectedOptions ?? selectedOptions,
    rulesFeed,
    staticGridClassDict,
    initialOptions: options,
    formRef: initialFormRef ?? formRef,
    services,
    settings,
    componentDict,
    ruleDeps,
    rules,
    future,
    formId,
    onSubmit,
    onFailure,
    onSuccess,
    onSubmitAndRedirect,
    requiredFields,
    refetchFormData,
  }

  const ui = typeof children === 'function' ? children(value) : children
  return <UniformContext.Provider value={value}>{ui}</UniformContext.Provider>
}

export const useUniformContext = <
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict
>() => {
  const utils = React.useContext(UniformContext)
  if (!utils) {
    throw new Error('useUniformContext must be used within UniformProvider')
  }
  return {
    ...utils,
    services: utils.services as TUniformServices,
    componentDict: utils.componentDict as unknown as TComponentDict,
  }
}
