import { Transition } from '@headlessui/react'
import { toast as hotToast } from 'react-hot-toast'

import {
  AlertBanner,
  AlertBannerAction,
  AlertVariant,
} from '@cais-group/equity/atoms/alert-banner'

/**
 * Props for the `Banner` component.
 */
export type BannerProps = {
  /** The id of the banner message to display. */
  id?: string
  /** The type of banner message to display. Defaults to info */
  type?: AlertVariant
  /** The title of the banner message. */
  title: string
  /** A boolean indicating whether the banner can be dismissed. */
  dismissable?: boolean
  /** An optional action to display in the alert. */
  action?: AlertBannerAction
}

/**
 * A component that displays a banner alert message.
 */
export const Banner = ({
  title,
  type = 'info',
  id,
  dismissable,
  action,
}: BannerProps): JSX.Element => {
  return (
    <AlertBanner
      onDismiss={dismissable ? () => hotToast.dismiss(id) : undefined}
      text={title}
      action={action}
      variant={type}
      testId="banner-title"
    />
  )
}

const DISMISS_TIMEOUT_MS = 3000
export const showBanner = (props: BannerProps) =>
  hotToast(
    (t) => (
      <Transition
        appear
        show={t.visible}
        className="duration-long-3 flex w-full transform transition-all"
        enter="transition-all ease-standard duration-medium-2"
        enterFrom="opacity-0 translate-y-[-60px]"
        enterTo="opacity-100"
        leave="transition-all ease-standard duration-long-3"
        leaveFrom="opacity-100 translate-y-[60px]"
        leaveTo="opacity-0 translate-y-[-60px]"
      >
        <Banner {...props} />
      </Transition>
    ),
    {
      duration: props.dismissable ? Infinity : DISMISS_TIMEOUT_MS,
      position: 'top-center',
      id: props.id || `default-id-${Date.now().toString()}`,
    }
  )
