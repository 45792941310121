import styled from '@emotion/styled'
import { FormHelperText } from '@mui/material'
import Autocomplete, {
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete'
import { ReactElement, Ref, SyntheticEvent } from 'react'

import { TextInput } from '@cais-group/shared/ui/input/text'
import { SelectOption } from '@cais-group/shared/ui/select-box'
import { TextInputLabel } from '@cais-group/shared/ui/text-field-label'

export type SelectBoxAutocompleteProps = {
  selectOptions: SelectOption[]
  defaultValue?: string
  value?: string
  className?: string
  onChange: (data: string) => void
  onInputChange?: (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void

  onBlur?: React.FocusEventHandler<HTMLInputElement>
  label?: string
  description?: string
  id: string
  disabled?: boolean
  $renderBlueVariant?: boolean
  required?: boolean
  errorMessage?: string
  supportingText?: string
  freeSolo?: boolean // means that the user input is not bound to provided options.
  forcePopupIcon?: boolean
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: unknown,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode
  size?: 'small' | 'medium' | undefined
  adornmentTag?: ReactElement
  disableClearable?: boolean
  inputClassName?: string
  width?: string
  placeholder?: string
  openOnFocus?: boolean
  autocompleteRef?: Ref<HTMLDivElement>
  blurOnSelect?: boolean
}

const StyledFormHelperText = styled(FormHelperText)`
  // This make sure the error message is included in the dom before the field.
  // This gives a better experience for screen reader users
  order: 3;
`

const StyledErrorText = styled.span`
  color: rgb(var(--colors-error-600));
`

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  .MuiAutocomplete-option {
    &:hover {
      background-color: rgb(var(--colors-primary-100));
    }
  }
`

const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (prop) => {
    return prop !== '$renderBlueVariant' && prop !== 'error'
  },
})<{
  $renderBlueVariant?: boolean
  error?: boolean
}>`
  .MuiOutlinedInput-root {
    padding: var(--s4) var(--s8);
    border-radius: 0;
    background-color: ${({ $renderBlueVariant, disabled }) =>
      disabled
        ? 'rgb(var(--colors-neutral-100))'
        : $renderBlueVariant && 'rgb(var(--colors-primary-100))'};
    .MuiAutocomplete-input {
      padding: var(--s8) 6px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: ${({ $renderBlueVariant, error }) =>
      error
        ? '1px solid rgb(var(--colors-error-600)) !important'
        : $renderBlueVariant
        ? '1px solid rgb(var(--colors-primary-300))'
        : '1px solid rgb(var(--colors-neutral-200))'};
  }
`

export function SelectBoxAutocomplete(props: SelectBoxAutocompleteProps) {
  const {
    id,
    selectOptions,
    label,
    onChange,
    onInputChange,
    onBlur,
    className,
    value,
    description,
    disabled,
    $renderBlueVariant,
    required,
    errorMessage,
    renderOption,
    size,
    forcePopupIcon = true,
    adornmentTag,
    supportingText,
    disableClearable = true,
    inputClassName,
    width = 'auto',
    placeholder = 'Select value',
    freeSolo = false,
    openOnFocus = false,
    autocompleteRef = null,
    blurOnSelect,
  } = props

  return (
    <StyledInput className={className}>
      {label && (
        <TextInputLabel
          required={required}
          htmlFor={id}
          label={label}
          description={description}
        />
      )}
      {errorMessage && (
        <StyledFormHelperText>
          <StyledErrorText>{errorMessage}</StyledErrorText>
        </StyledFormHelperText>
      )}
      <StyledAutocomplete
        blurOnSelect={blurOnSelect}
        freeSolo={freeSolo}
        forcePopupIcon={forcePopupIcon}
        size={size}
        openOnFocus={openOnFocus}
        autoComplete
        disablePortal
        disableClearable={disableClearable}
        autoHighlight
        disabled={disabled}
        id={id}
        data-testid={id}
        getOptionLabel={(value) =>
          selectOptions.find((item) => item.value === value)?.label ||
          (freeSolo ? (value as string) : '')
        }
        renderOption={renderOption}
        options={selectOptions.map(({ value }) => value)}
        value={
          value === ''
            ? null
            : value /* we can't fallback to empty string '' because that gives a MUI warning in console */
        }
        $renderBlueVariant={$renderBlueVariant}
        onBlur={onBlur}
        error={!!errorMessage}
        onChange={(_, value) => onChange && onChange(value as string)} // Casting as due to Styled type issue on Autocomplete: https://github.com/mui/material-ui/issues/21727
        onInputChange={onInputChange}
        renderInput={(params) => {
          return (
            <TextInput
              {...params}
              inputProps={{
                ...params.inputProps,
                className: `${params.inputProps.className} ${inputClassName}`,
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: adornmentTag,
              }}
              label={null}
              placeholder={placeholder}
            />
          )
        }}
        ref={autocompleteRef}
        sx={{ width }}
      />
      {supportingText && (
        <p className="bodySmall pt-4 text-neutral-600">{supportingText}</p>
      )}
    </StyledInput>
  )
}
