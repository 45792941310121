import { useUserRolesQuery } from '@cais-group/shared/util/graphql'

import { useUserProfile } from './shared-util-hook-use-user-profile'

type Options = {
  strategy?: 'AND' | 'OR'
}
export const useHasUserRoles = (
  roles: string[],
  { strategy = 'AND' }: Options = {}
) => {
  const userProfileValue = useUserProfile()
  const {
    userProfileState: { userProfile },
  } = userProfileValue
  const { data: userRoles } = useUserRolesQuery({
    userId: userProfile?.id ?? '',
  })
  if (strategy === 'AND') {
    const userRoleNames = userRoles?.user.roles.map((role) => role.name) || []
    return roles.every((role) => userRoleNames.includes(role))
  }

  // strategy === 'OR'
  return !!userRoles?.user.roles.some((role) => roles.includes(role.name))
}
