import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { BodySmallCss } from '@cais-group/approved/ui/typography'
import { getRichTextDisclaimerOptions } from '@cais-group/shared/util/js/get-rich-text-disclaimer-options'
import { TypeDisclaimerData } from '@cais-group/shared/util/type/disclaimer-data'

const StyledDisclaimerContainer = styled.div`
  p {
    ${BodySmallCss};
  }
`

export type DisclaimerProps = {
  disclaimer?: TypeDisclaimerData
  children?: ReactNode
}

export const Disclaimer = (props: DisclaimerProps) => {
  const { disclaimer, children } = props
  return (
    <StyledDisclaimerContainer>
      {disclaimer?.body?.json &&
        documentToReactComponents(
          disclaimer.body.json as unknown as Document,
          getRichTextDisclaimerOptions()
        )}
      {children}
    </StyledDisclaimerContainer>
  )
}
