import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { searchTermAtom } from '@cais-group/caisiq/domain/search'
import { FundWithThumbnail } from '@cais-group/shared/util/type/caisiq-be'

export const useFilterFundsBySearch = (
  funds: FundWithThumbnail[] | undefined
) => {
  const searchTerm = useRecoilValue(searchTermAtom)
  return useMemo(() => {
    return filterFundsBySearch(funds, searchTerm)
  }, [funds, searchTerm])
}

export const filterFundsBySearch = (
  funds: FundWithThumbnail[] | undefined,
  searchTerm: string
) => {
  return funds?.filter((fund) => {
    if (searchTerm === '') return true

    const target = searchTerm.toLowerCase()
    return (
      fund.name.toLowerCase().includes(target) ||
      fund.description?.toLowerCase().includes(target) ||
      fund.managerName?.toLowerCase()?.includes(target)
    )
  })
}
