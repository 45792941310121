import { LandingPageData } from '@cais-group/homepage/util/types'
import {
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  GetLandingPageQuery,
  useGetLandingPageQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

import { FIRMS_COLLECTION_LIMIT } from '../constants'

export const useGetResourceLandingPage = ({ slug }: { slug: string }) => {
  const result = useReactQueryResultAsApiState<
    GetLandingPageQuery,
    LandingPageData
  >(
    useGetLandingPageQuery(
      {
        preview: previewService.enabled,
        slug: slug,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      { refetchOnWindowFocus: false }
    ),
    selectLandingPageResource,
    'Could not load pageResourceCollection'
  )
  return compileResults(
    result,
    result === ApiStateEnum.LOADING,
    isError(result)
  )
}
const selectLandingPageResource = (data: GetLandingPageQuery) =>
  data.pageCollection?.items[0] as LandingPageData

const compileResults = (
  data: LandingPageData | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    pageData: isData(data) ? data : null,
    error,
    loading,
  }
}
