import merge from 'lodash/merge'
import qs from 'qs'
import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import * as R from 'remeda'

import { useUniformContext } from '../context'
import { useFeedRules, useShowOnlyRequired } from '../hooks'
import type { FieldRulesKeys, UniformAny, UniformFieldProps } from '../types'
import type { UniformValues } from '../uniform'
import { collectAllValuesFrom } from '../utils'

import { evaluateWhereFilters } from './rules'

export type RulesFieldProps = Pick<
  UniformFieldProps,
  'id' | 'disabled' | 'rules' | 'parentPrefix' | 'skippable' | 'required'
> & {
  children?: UniformAny
}

const useFieldDepsForKey = (
  fieldId: string | undefined,
  rule: FieldRulesKeys,
  prefix?: string
): string[] => {
  const { ruleDeps } = useUniformContext()

  return React.useMemo(() => {
    if (!fieldId) return []
    return (
      ruleDeps[rule]?.[fieldId]
        ?.map((field) => field?.replace(/\[prefix\]/, prefix ?? ''))
        ?.filter((f) => f !== '') ?? []
    )
  }, [ruleDeps, fieldId, rule, prefix])
}

export const useFieldValidationDeps = (
  fieldId: string | undefined,
  parentPrefix?: string
): string | string[] | undefined => {
  const validation = useFieldDepsForKey(fieldId, 'validation', parentPrefix)
  return React.useMemo(() => {
    if (validation.length === 0) return undefined
    if (validation.length === 1) return validation[0]
    return validation
  }, [validation])
}

const useFormValues = (deps: string[]) => {
  const { getValues } = useFormContext()
  const values = useWatch({ name: deps, disabled: deps.length === 0 })
  const watchEntries = Object.fromEntries(
    deps.map((key, index) => [key, values[index]])
  )
  const expandedEntries = qs.parse(watchEntries, { allowDots: true })
  return merge({}, expandedEntries, getValues())
}

export const uniformFieldValidation = (
  field: Pick<RulesFieldProps, 'rules' | 'id' | 'parentPrefix'>,
  values: UniformValues
): ReturnType<typeof evaluateWhereFilters> | undefined => {
  const validation = field.rules?.validation
  if (!validation) return undefined
  const e = evaluateWhereFilters(
    validation,
    values,
    field.id,
    field.parentPrefix
  )
  return e
}

/**
 * @description rules provided separately from schema.
 */
export const useExternalFieldRules = (fieldId?: string) => {
  const { rules = {} } = useUniformContext()
  return rules[fieldId ?? '']
}

export const useFieldValidation = (field: RulesFieldProps) => {
  const optionalRules = useExternalFieldRules(field.id)
  const rules = React.useMemo(() => {
    return merge({}, field.rules, optionalRules)
  }, [field.rules, optionalRules])

  return (values: UniformValues) => {
    return uniformFieldValidation(
      { id: field.id, parentPrefix: field.parentPrefix, rules },
      values
    )
  }
}

export function findRequiredMessageAndReturnInsteadOfBoolean(
  fieldId: string = '',
  required: boolean | string | undefined,
  messages: Array<string | boolean | undefined | object>
): boolean | string | undefined {
  if (typeof required === 'undefined') return undefined
  if (typeof required === 'string') return required
  if (typeof required === 'boolean') {
    if (required === false) return false

    const msgs = R.pipe(
      messages,
      R.flatMap((msg) => {
        if (typeof msg === 'string') return msg
        if (R.isObjectType(msg)) {
          const result = collectAllValuesFrom('message', msg)
          return result
        }
        return []
      }),
      R.filter(Boolean)
    )

    if (msgs.length > 1) {
      console.warn(
        `field ${fieldId} has error messages from multiple sources. Only the first one in the list will be displayed.`
      )
    }

    const firstMesage = msgs.at(0)

    return typeof firstMesage === 'string' ? firstMesage : required
  }
  return undefined
}

export const useGetRequiredBooleanOrMessage = (
  field: RulesFieldProps,
  isRequiredByEvaluatedRules?: boolean
): boolean | string | undefined => {
  const externalRules = useExternalFieldRules(field.id)

  const result = React.useMemo(() => {
    if (R.isObjectType(externalRules?.requiredIf)) {
      return findRequiredMessageAndReturnInsteadOfBoolean(
        field.id,
        isRequiredByEvaluatedRules,
        [field.required, field.rules?.requiredIf, externalRules?.requiredIf]
      )
    }
    if (
      typeof externalRules?.requiredIf === 'boolean' ||
      typeof externalRules?.requiredIf === 'string'
    ) {
      return externalRules?.requiredIf
    }
    if (R.isObjectType(field.rules?.requiredIf)) {
      return findRequiredMessageAndReturnInsteadOfBoolean(
        field.id,
        isRequiredByEvaluatedRules,
        [field.required, field.rules?.requiredIf, externalRules?.requiredIf]
      )
    }
    if (
      typeof field.rules?.requiredIf === 'boolean' ||
      typeof field.rules?.requiredIf === 'string'
    ) {
      return field.rules?.requiredIf
    }
    if (
      typeof field.required === 'boolean' ||
      typeof field.required === 'string'
    ) {
      return field.required
    }

    return undefined
  }, [
    field.required,
    externalRules?.requiredIf,
    field.rules?.requiredIf,
    isRequiredByEvaluatedRules,
    field.id,
  ])

  return result
}

export const useFieldRequirement = (field: RulesFieldProps) => {
  const optionalRules = useExternalFieldRules(field.id)
  const deps = useFieldDepsForKey(field.id, 'requiredIf', field.parentPrefix)
  const values = useFormValues(deps)
  const requiredIsBooleanOrString = useGetRequiredBooleanOrMessage(field)
  const requiredIf = React.useMemo(() => {
    if (requiredIsBooleanOrString) return requiredIsBooleanOrString
    return merge({}, field.rules?.requiredIf, optionalRules?.requiredIf)
  }, [
    requiredIsBooleanOrString,
    field.rules?.requiredIf,
    optionalRules?.requiredIf,
  ])

  const result = React.useMemo(() => {
    if (typeof requiredIf === 'boolean') {
      return { allPassed: requiredIf, messages: [] }
    }
    if (typeof requiredIf === 'string') {
      return {
        allPassed: true,
        messages: [requiredIf.length > 0 ? requiredIf : undefined].filter(
          Boolean
        ),
      }
    }
    if (Object.values(requiredIf).length === 0) {
      return { allPassed: undefined, messages: [] }
    }
    return evaluateWhereFilters(requiredIf, values, '', field.parentPrefix)
  }, [requiredIf, values, field.parentPrefix])

  return result
}

export const useFieldVisibility = (
  field: RulesFieldProps
): ReturnType<typeof evaluateWhereFilters> => {
  const optionalRules = useExternalFieldRules(field.id)
  const deps = useFieldDepsForKey(field.id, 'visibleIf', field.parentPrefix)

  const values = useFormValues(deps)
  const visibleIf = React.useMemo(
    () => merge({}, field.rules?.visibleIf, optionalRules?.visibleIf),
    [field.rules?.visibleIf, optionalRules?.visibleIf]
  )

  const result = React.useMemo(() => {
    if (Object.values(visibleIf).length === 0) {
      return { allPassed: true, messages: [] }
    }
    return evaluateWhereFilters(visibleIf, values, '', field.parentPrefix)
  }, [visibleIf, values, field.parentPrefix])

  return result
}

export const useFieldDisablement = (
  field: RulesFieldProps
): ReturnType<typeof evaluateWhereFilters> => {
  const optionalRules = useExternalFieldRules(field.id)
  const deps = useFieldDepsForKey(field.id, 'disabledIf', field.parentPrefix)

  const values = useFormValues(deps)
  const disabledIf = React.useMemo(
    () => merge({}, field.rules?.disabledIf, optionalRules?.disabledIf),
    [field.rules?.disabledIf, optionalRules?.disabledIf]
  )

  // NOTE: values are reversed for disabled fields
  const result = React.useMemo(() => {
    if (Object.values(disabledIf).length === 0) {
      return { allPassed: false, messages: [] }
    }
    const result = evaluateWhereFilters(
      disabledIf,
      values,
      '',
      field.parentPrefix
    )
    return { allPassed: !result.allPassed, messages: result.messages }
  }, [disabledIf, values, field.parentPrefix])

  return result
}

export const useUniformFieldRules = (field: RulesFieldProps) => {
  const { rules = {} } = useUniformContext()
  const { allPassed: allVisibilityPassed } = useFieldVisibility(field)
  const { allPassed: allDisablementPassed } = useFieldDisablement(field)
  const { allPassed: allRequirementPassed } = useFieldRequirement(field)

  const shouldHideFromVisibility = useShowOnlyRequired({
    isRequired: allRequirementPassed,
    isSkippable: rules?.[field?.id ?? '']?.skippable || field.skippable,
  })
  const validate = useFieldValidation(field)
  const result = {
    shouldValidate: [
      Object.keys(field.rules?.validation ?? {}).length,
      typeof rules[field.id ?? ''] !== 'undefined',
    ].some(Boolean),
    isVisible: allVisibilityPassed,
    isDisabled: [allDisablementPassed, field.disabled].some(Boolean),
    // FIXME: CAS-2562 - messages from requiredIf are not being forwarded to `required` field as a string. It's always a boolean at this point.
    isRequired: allRequirementPassed,
    shouldHideFromVisibility,
  }

  useFeedRules({ field, payload: result })

  return { ...result, validate }
}

export function useUniformRulesValidate(
  uniform: ReturnType<typeof useUniformFieldRules>
) {
  const { getValues } = useFormContext()
  const [isValid, setIsValid] = React.useState<boolean>()
  const [validationMessage, setValidationMessage] = React.useState<string>()

  React.useEffect(() => {
    if (!uniform.shouldValidate) return
    const result = uniform.validate(getValues())
    if (!result) return

    setIsValid(result.allPassed)
    setValidationMessage(
      R.pipe(
        result.messages,
        R.filter(R.isNonNull),
        R.map((item) => item?.message),
        R.filter(R.isNonNull),
        R.join(', ')
      )
    )
  }, [getValues, uniform])

  return { isValid, validationMessage }
}
