import { useAuth0 } from '@auth0/auth0-react'
import { datadogRum } from '@datadog/browser-rum'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'

import { acpService } from '@cais-group/caisiq/util/acp-service'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import {
  ACCESS_FORBIDDEN_DESCRIPTION,
  ACCESS_FORBIDDEN_STATUS_CODE,
  ACCESS_FORBIDDEN_TITLE,
} from '@cais-group/caisiq/util/logout-service'
import { trackingService } from '@cais-group/caisiq/util/tracking-service'
import { GeneralError } from '@cais-group/shared/util/general-error'
import { ApiPaths, User } from '@cais-group/shared/util/type/caisiq-be'

export const useGetUserProfile = () => {
  const { isAuthenticated } = useAuth0()

  return useQuery<User, AxiosError>(ApiPaths.getLoggedInLearner, {
    enabled: isAuthenticated,
    onError: (error) => {
      // Access forbidden
      if (error.response?.status === ACCESS_FORBIDDEN_STATUS_CODE) {
        throw new GeneralError(
          ACCESS_FORBIDDEN_TITLE,
          ACCESS_FORBIDDEN_DESCRIPTION
        )
      }

      throw new GeneralError(
        'User not found',
        'Unable to load user profile so cannot access app'
      )
    },
    refetchInterval: 1000 * 60 * 10, // 10 minutes
    onSuccess: async (user: User) => {
      await experienceService.setUser(user)
      trackingService.onUserFetched(user)
      datadogRum.setUser({
        id: user.sub,
        name: user.fullname,
        email: user.email,
      })
      await acpService.setUser(user)
    },
  })
}
