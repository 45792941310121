import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import { Label, BodySmall } from '@cais-group/approved/ui/typography'
import {
  CourseCredits,
  CreditValue,
} from '@cais-group/shared/util/type/caisiq-be'

import {
  CreditCell,
  StyledTableHeaderWrapper,
} from './caisiq-ui-table-ce-credits'

export const useGetProfilePageTableColumns = () => {
  const profilePageColumns = useMemo<ColumnDef<CourseCredits>[]>(
    () => [
      {
        accessorKey: 'courseName',
        size: 400,
        cell: (info) => <Label>{info.getValue<string>()}</Label>,
        header: () => (
          <StyledTableHeaderWrapper>Course name</StyledTableHeaderWrapper>
        ),
        meta: {
          ratio: 4,
        },
      },
      {
        size: 244,
        header: () => (
          <StyledTableHeaderWrapper width={72}>
            Date of completion
          </StyledTableHeaderWrapper>
        ),
        accessorKey: 'learnedAt',
        id: 'defaultSort',
        cell: (info) => (
          <BodySmall>
            {info.getValue<string>()
              ? dayjs(info.getValue<string>()).format('MM/DD/YY')
              : '-'}
          </BodySmall>
        ),
      },

      {
        size: 100,
        meta: {
          ratio: 0,
        },
        header: () => (
          <StyledTableHeaderWrapper addPadding>CFP</StyledTableHeaderWrapper>
        ),
        accessorKey: 'cfp',
        cell: (info) => {
          const { value, status } = info.getValue<CreditValue>()
          return (
            <CreditCell status={status} data-testid="cfp-table-cell">
              {value}
            </CreditCell>
          )
        },
      },
      {
        size: 100,
        meta: {
          ratio: 0,
        },
        header: () => (
          <StyledTableHeaderWrapper addPadding>CIMA</StyledTableHeaderWrapper>
        ),
        accessorKey: 'cima',
        cell: (info) => {
          const { value, status } = info.getValue<CreditValue>()
          return (
            <CreditCell status={status} data-testid="cima-table-cell">
              {value}
            </CreditCell>
          )
        },
      },
    ],
    []
  )

  return profilePageColumns
}
