import { debounce } from '@mui/material'
import { ReactNode, useRef, useCallback } from 'react'

/**
 * @todo - Move this to it's own lib
 */
export const Scrollable = ({
  children,
  scrollToBottomCallback,
}: {
  children: ReactNode
  scrollToBottomCallback: () => void
}) => {
  const scrollableRef = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(
    debounce(() => scrollToBottomCallback(), 100),
    [scrollToBottomCallback]
  )

  const onScroll = () => {
    if (scrollableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current

      // The user has scrolled to the bottom of the component
      if (scrollTop + clientHeight >= scrollHeight - 300) {
        callback()
      }
    }
  }
  return (
    <div
      className="max-h-[70vh] min-h-[fit-content] overflow-y-scroll"
      onScroll={onScroll}
      ref={scrollableRef}
    >
      {children}
    </div>
  )
}
