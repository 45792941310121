import { useCallback, useState } from 'react'

export const usePlaylist = () => {
  const [autoplay, setAutoplay] = useState(false)

  const startAutoplay = useCallback(() => {
    setAutoplay(true)
  }, [])

  const stopAutoplay = useCallback(() => {
    setAutoplay(false)
  }, [])

  return {
    autoplay,
    startAutoplay,
    stopAutoplay,
  }
}
