import React from 'react'
import type { UseFormProps, UseFormReturn } from 'react-hook-form'

import type { AsyncOptionProps } from './hooks/async-options'
import type { DynamicTitleProps } from './hooks/use-dynamic-title'
import type { WhereInput } from './rules'
import type { UniformServices } from './services'
import type { UniformValues } from './uniform'

export type UniformAny = any // eslint-disable-line @typescript-eslint/no-explicit-any

export type UniformFieldOption<T = Record<string, UniformAny>> = {
  value: UniformAny
  label: string
  tooltip?: string
  disabled?: boolean
  description?: string
  border?: boolean
} & T

type ValidGridColumn = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13

export type UniformFieldLayoutProps =
  | ValidGridColumn
  | { start?: ValidGridColumn; span: ValidGridColumn }
  | { start?: ValidGridColumn; end: ValidGridColumn }
  | 'fit'

export type Repeatable = {
  repeatable?: {
    prefix: string
    label: string
  }
}

export type UniformFieldRules<
  TFormFieldIds extends keyof UniformAny = UniformAny
> = {
  validation?: WhereInput<TFormFieldIds>
  visibleIf?: WhereInput<TFormFieldIds>
  disabledIf?: WhereInput<TFormFieldIds>
  /** If a string is provided, this field becomes required and represents the error message displayed when the field is invalid. */
  requiredIf?: boolean | string | WhereInput<TFormFieldIds>
  skippable?: boolean | undefined
}
export type FieldRulesKeys = keyof Omit<UniformFieldRules, 'skippable'>

export type UniformFieldProps<
  FieldName extends string = string,
  TFormFieldIds extends keyof UniformAny = UniformAny
> = {
  id?: string
  type: FieldName
  name?: string
  label?: string
  description?: string
  placeholder?: string
  value?: string | string[]
  disabled?: boolean
  required?: boolean | string
  skippable?: boolean
  recommended?: boolean
  rules?: Partial<UniformFieldRules<TFormFieldIds>>
  cols?: UniformFieldLayoutProps
  tooltip?: string
  /** By default, an input value will be retained when input is removed. However, you can set shouldUnregister to true to unregister input during unmount.  */
  shouldUnregister?: boolean | undefined
  defaultValue?: UniformAny
  parentPrefix?: string
} & Repeatable

type OverrideIfPresent<T, U> = {
  [P in keyof T]: P extends keyof U ? U[P] : T[P] extends never ? never : T[P]
}

type ExtractFnArgs<T> = T extends (...args: infer U) => UniformAny
  ? U[0]
  : never

// NOTE: we are overriding all these props for components if prop is present, otherwise generics won't work in schema.
export type UniformSectionChildren<
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TFormFieldIds extends keyof UniformAny = UniformAny
> = OverrideIfPresent<
  ExtractFnArgs<TComponentDict[keyof TComponentDict]>,
  {
    id: TFormFieldIds
    service: keyof TUniformServices
    // currently rules will be overridden for every adapter.
    rules: Partial<UniformFieldRules<TFormFieldIds>>
    skippable?: boolean
    asyncOptions: AsyncOptionProps<TUniformServices, TFormFieldIds>
  }
>

export type UniformSectionProps<
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TFormFieldIds extends keyof UniformAny = UniformAny
> = {
  id?: TFormFieldIds
  title?: DynamicTitleProps<TUniformServices>
  tooltip?: string
  description?: string
  rules?: Partial<UniformFieldRules<TFormFieldIds>>
  disabled?: boolean
  stepId?: string
  children: UniformSectionChildren<
    TUniformServices,
    TComponentDict,
    TFormFieldIds
  >[]
} & Repeatable

export type UniformSchema<
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends ComponentDict = ComponentDict,
  TFormFieldIds extends keyof UniformAny = UniformAny
> = {
  id: string
  /** used on multistep layouts */
  title?: string
  children: UniformSectionProps<
    TUniformServices,
    TComponentDict,
    TFormFieldIds
  >[]
}

export type ComponentDict = Record<string, React.ComponentType<UniformAny>>

export type Future = {
  [futureKey: string]: boolean
}

export type UniformSettings = {
  displayRequiredStatusTags?: boolean
  binaryInputStateToValueMapping?: BinaryInputStateToValueMapping
  /** hides sections that have no required fields */
  showSectionsWithRequiredFieldsOnly?: boolean
  /** hide all non required fields */
  showRequiredFieldsOnly?: boolean
  defaultFieldCols?: ValidGridColumn
}

/**
 * This type represents a mapping between binary states and custom values.
 * This is useful when `true`, `false`, and `undefined` values may be ambiguous and you need to use your own constants.
 *
 * @example { On: 'MY_CUSTOM_CHECKED', Off: 'MY_CUSTOM_UNCHECKED', Indeterminate: 'MY_CUSTOM_INDETERMINATE' }
 */
export interface BinaryInputStateToValueMapping {
  On: UniformAny
  Off: UniformAny

  // In addition to the on/off binary, there is a third "indeterminate" non-binary state
  // Note: Not all components respect the indeterminate state.
  //
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes
  Indeterminate: UniformAny
}

export interface UniformConfig<
  TUniformValues extends UniformValues = UniformValues,
  TUniformServices extends UniformServices = UniformServices,
  TComponentDict extends Record<
    string,
    React.ComponentType<UniformAny>
  > = Record<string, React.ComponentType<UniformAny>>,
  TPossibleEntries extends keyof UniformAny = UniformAny
> extends UseFormProps<TUniformValues> {
  /**
   * form id, can be helpful when form has to be submitted from outside
   * (e.g. from a button outside of the form)
   * ```<button type="submit" form="form-id">Submit</button>```
   * */
  id?: string
  types?: UniformTypes<
    TUniformValues,
    TUniformServices,
    TComponentDict,
    TPossibleEntries
  >
  /** General form-wide settings */
  settings?: UniformSettings
  componentDictionary?: TComponentDict
  refetchFormData?: (data?: Record<string, unknown>) => Promise<UniformAny>
  onSubmit?: (
    values: TUniformValues,
    rules?: Partial<Record<string, UniformFieldRules>>
  ) => Promise<UniformAny>
  onSuccess?: (response: UniformAny) => void
  onFailure?: (error: unknown) => void
  /** @deprecated TODO: remove once multistep is removed */
  /** @deprecated TODO: remove once multistep is removed */
  onSubmitAndRedirect?: () => Promise<void>
  onCancel?: () => void
  /** initialize infinite scroll dropdowns with an initial option so they can select the initial value provided by the backend (currently dropdowns may only select values that are present in their options)*/
  initialOptions?: InitialOptions<TPossibleEntries>
  /** services like queries, mutations or rest endpoints to be used in the schema */
  services?: TUniformServices
  /** rules for fields */
  rules?: Partial<
    Record<TPossibleEntries, Partial<UniformFieldRules<TPossibleEntries>>>
  >
  future?: Future
  /** react-hook-form methods override */
  methods?: UseFormReturn<TUniformValues, UniformAny>
  schema:
    | UniformSchema<TUniformServices, TComponentDict, TPossibleEntries>
    | UniformSchema<TUniformServices, TComponentDict, TPossibleEntries>[]
  requiredFields?: string[]
}

export interface UniformTypes<
  TUniformValues extends UniformValues,
  TUniformServices extends UniformServices,
  TComponentDict extends ComponentDict,
  TPossibleEntries extends keyof UniformAny
> {
  values?: TUniformValues
  componentDictionary?: TComponentDict
  services?: TUniformServices
  ids?: TPossibleEntries
}

export type InitialOptions<
  TPossibleEntries extends keyof UniformAny = UniformAny
> = Partial<Record<TPossibleEntries, UniformFieldOption | UniformFieldOption[]>>
