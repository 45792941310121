import styled from '@emotion/styled'
import {
  ColumnDef,
  getSortedRowModel,
  SortingState,
  TableOptions,
  Row,
} from '@tanstack/react-table'
import { Dispatch, SetStateAction, useState, useMemo } from 'react'
import { SetterOrUpdater } from 'recoil'

import { BodySmall } from '@cais-group/approved/ui/typography'
import { useAsyncExpandableFirmsRow } from '@cais-group/caisiq/domain/manage-caisiq'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'
import { StatusTag } from '@cais-group/equity/atoms/status-tag'
import { colors } from '@cais-group/equity/particles/colors'
import {
  ContextMenuTable,
  MenuTableActions,
} from '@cais-group/shared/ui/buttons'
import { SPACING, SPACING_UNITS } from '@cais-group/shared/ui/design-tokens'
import { TableContainer } from '@cais-group/shared/ui/react-table-v8'
import {
  CaisIqFirm,
  CaisIqFirmWithChildren,
  FirmBasicInfoDto,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'

import { ManageCaisIqTable } from './caisiq-ui-table-manage'
import { ExpandableCell } from './components/expandable-cell'
import { FirmNameBox } from './components/firm-name-box'
import { TableControls } from './components/table-controls'

const StyledTableContainer = styled(TableContainer)`
  width: 1080px;
  min-width: ${SPACING.s608};
  height: fit-content;
  * > div.table-cell:last-of-type {
    // this ensures that there is padding after last column if there are only two columns on the table
    padding-right: ${SPACING.s24};
  }
`
type ExperiencePageTableProps = {
  tableOptions: Omit<
    TableOptions<FirmBasicInfoDto>,
    'getCoreRowModel' | 'columns'
  >
  fetchNextPage?: () => void
  hasNextPage?: boolean
  setSearchTerm?: SetterOrUpdater<string>
  searchTerm?: string
  sorting?: SortingState
  setSorting?: Dispatch<SetStateAction<SortingState>>
  params: Readonly<
    Partial<{
      firmId?: string | undefined
      experienceId?: string | undefined
    }>
  >
  actions: (removedFirmId: string) => MenuTableActions
}

const getSubRows = (originalRow: CaisIqFirmWithChildren, index: number) => {
  return originalRow?.hasChildFirms
    ? originalRow.children
      ? originalRow.children
      : []
    : undefined
}

/**
 * A helper function that determines if a firm row should show the experience overridden state.
 */
function showExperienceOverriddenState(row: Row<CaisIqFirmWithChildren>) {
  const firm = row.original

  // Do not show overridden message if firm does not have a parent or is not a nested row.
  if (
    !firm.parent ||
    row.depth === 0 ||
    !firm.experience ||
    !firm.parent.experience
  ) {
    return false
  }

  return firm.experience.name !== firm.parent.experience.name
}

export const ExperiencePageTable = (props: ExperiencePageTableProps) => {
  const {
    searchTerm = '',
    setSearchTerm = () => {},
    tableOptions,
    fetchNextPage = () => {},
    hasNextPage = false,
    params,
    actions,
  } = props
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false },
  ])

  const { rows, handleClickRow, isRowLoading } = useAsyncExpandableFirmsRow(
    tableOptions.data as CaisIqFirmWithChildren[]
  )

  const hasFullAccess = userSettingsService.hasRole(UserRole.CaisiqManageWrite)

  const firmColumns = useMemo<ColumnDef<CaisIqFirmWithChildren>[]>(
    () => [
      {
        header: 'Firm Name',
        accessorKey: 'name',
        id: 'name',
        sortingFn: 'alphanumeric',
        cell: ({ row, getValue }) => {
          const name = getValue<string>()
          const indentDepth = row.depth * SPACING_UNITS.s24

          const overridesParentExperience = showExperienceOverriddenState(row)

          if (!row.getCanExpand()) {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  paddingLeft: `${indentDepth}px`,
                }}
              >
                <div>
                  <FirmNameBox
                    color={
                      overridesParentExperience
                        ? colors['eq-color-neutral-500']
                        : colors['eq-color-neutral-900']
                    }
                    name={name}
                    isDimmed={row.depth > 0}
                  >
                    {name}
                  </FirmNameBox>
                  {overridesParentExperience && (
                    <div style={{ marginLeft: SPACING.s8 }}>
                      <StatusTag variant="light" color="error">
                        Excluded
                      </StatusTag>
                    </div>
                  )}
                </div>
                {overridesParentExperience && (
                  <div
                    style={{
                      color: colors['eq-color-neutral-500'],
                      maxWidth: '300px',
                      // 24px is added to account for the width of the firm name box.
                      paddingLeft: `${indentDepth + SPACING_UNITS.s24}px`,
                    }}
                  >
                    <BodySmall>
                      This child firm has overridden the parent firm experience.
                    </BodySmall>
                  </div>
                )}
              </div>
            )
          }
          return (
            <div style={{ paddingLeft: `${indentDepth}px` }}>
              <ExpandableCell
                row={row}
                onExpandClick={() => {
                  handleClickRow(row)
                  row.toggleExpanded()
                }}
              >
                <FirmNameBox name={name} isDimmed={row.depth > 0}>
                  {name}
                </FirmNameBox>
              </ExpandableCell>
            </div>
          )
        },
        size: 200,
        meta: {
          ratio: 3,
        },
      },
      {
        header: 'Firm ID',
        accessorKey: 'id',
        cell: (info) => {
          return <BodySmall>{info.getValue<string>()}</BodySmall>
        },
        size: 350,
        meta: {
          ratio: 1,
        },
      },
      {
        header: '',
        accessorKey: 'actions',
        enableSorting: false,
        cell: ({ row }) => {
          if (row.depth > 0) {
            return null
          }
          return <ContextMenuTable actions={actions(row.original.id)} />
        },
        size: 40,
        meta: {
          ratio: 0,
        },
      },
    ],
    [actions, handleClickRow]
  )

  return (
    <StyledTableContainer $noScroll={true}>
      <div className="flex gap-16 py-16">
        <TableControls count={rows.length} information="total firms" />
      </div>
      <ManageCaisIqTable<CaisIqFirmWithChildren>
        tableOptions={{
          onSortingChange: setSorting,
          getSortedRowModel: getSortedRowModel(),
          state: {
            sorting: sorting,
            columnVisibility: { actions: hasFullAccess },
          },
          columns: firmColumns,
          /** todo: We shouldn't cast here, but the BasicDto doesn't yet have enough data to create an effective union type, redop this after BE changes */
          data: rows as CaisIqFirm[],
        }}
        getRowCanExpand={(row) => row.original.hasChildFirms}
        getSubRows={getSubRows}
        isRowLoading={isRowLoading}
        params={params}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        noScroll={true}
        tableType="experience-firms"
        classNames={{
          container: 'pb-56',
        }}
      />
    </StyledTableContainer>
  )
}

export default ExperiencePageTable
