import * as React from 'react'
import { useDebounce } from 'react-use'

export const useDelayedState = <T>(
  initialState: T,
  { debounceTime = 300 }: { debounceTime?: number } = {}
) => {
  const [state, setState] = React.useState<T>(initialState)
  const [debouncedState, setDebouncedState] = React.useState(() => state)
  useDebounce(() => setDebouncedState(state), debounceTime, [state])
  return { state, setState, debouncedState }
}
