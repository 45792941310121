import type { Options, RenderNode } from '@contentful/rich-text-react-renderer'
import React from 'react'

import { Maybe, Scalars } from '@cais-group/shared/util/graphql/mfe-contentful'
import {
  Body,
  BodyLinks,
  WithOptional,
} from '@cais-group/shared/util/type/body'

type HTMLTags = Extract<
  React.ElementType,
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'ul'
  | 'ol'
  | 'li'
  | 'blockquote'
>

/**
 * RequireAtLeastOne<{ ... }> <- only requires at least one key
 */
type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

export type Override = RequireAtLeastOne<{
  props: React.HTMLAttributes<React.ElementType>
  customTag: React.ElementType
  component: ({ children }: { children: React.ReactNode }) => JSX.Element
}>

export type Overrides = {
  [key in HTMLTags]?: Override
}
export type RenderOptions = (props: RichTextRenderProps) => Options

export type RichTextBaseProps = {
  field: { json: Scalars['JSON']; links: BodyLinks } | undefined | null
  renderOptions: RenderOptions
  segmentPageId?: RichTextRenderProps['segmentPageId']
  renderNode?: RichTextRenderProps['renderNode']
  urlPrefix?: string
  overrides?: Overrides
}

export type RichTextRenderProps = {
  links: BodyLinks
  segmentPageId?: string
  renderNode?:
    | RenderNode
    | ((links?: BodyLinks | undefined) => RenderNode)
    | undefined
  urlPrefix?: string
  overrides?: Overrides
}

export type RichTextOptionalProps = WithOptional<
  RichTextBaseProps,
  'renderOptions'
>

export type MaybeBodyEntry = Maybe<Body> | undefined
