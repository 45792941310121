import type { UseComboboxGetItemPropsOptions } from 'downshift'
import * as React from 'react'

import { UniformFieldOption } from '../../types'

import { OptionLayout, OptionLayoutProps } from './option-layout'

export type OptionsLayouts = 'grouped'
export type OptionsLayoutProps = {
  /** custom options layouts like groups */
  optionsLayout?: OptionsLayouts
  /** specify a key that we can find in each object to group by */
  optionsLayoutGroupBy?: string
}

type CustomOptionsLayoutsProps = OptionsLayoutProps &
  OptionLayoutProps & {
    items: UniformFieldOption[]
    selectedItem?: UniformFieldOption | null
    getItemProps: (
      options: UseComboboxGetItemPropsOptions<UniformFieldOption>
    ) => any // eslint-disable-line @typescript-eslint/no-explicit-any
    highlightedIndex?: number
  }
export const CustomOptionsLayouts = (props: CustomOptionsLayoutsProps) => {
  switch (props.optionsLayout) {
    case 'grouped':
      return <GroupedOptions {...props} />
    default:
      throw new Error('Invalid options layout')
  }
}

export function GroupedOptions({
  items,
  getItemProps,
  optionLayout,
  selectedItem,
  highlightedIndex,
  optionsLayoutGroupBy = '',
}: CustomOptionsLayoutsProps) {
  const groupedOptions = React.useMemo(() => {
    return items.reduce<Record<string, UniformFieldOption[]>>(
      (acc, option, index) => {
        const groupName = option[optionsLayoutGroupBy]
        if (!acc[groupName]) {
          acc[groupName] = []
        }
        acc[groupName].push({ ...option, index })
        return acc
      },
      {}
    )
  }, [items, optionsLayoutGroupBy])

  return (
    <div className="px-24">
      {Object.entries(groupedOptions).map(([groupName, options]) => (
        <React.Fragment key={groupName}>
          <div className="mt-12 bg-neutral-100 px-8" id={`group:${groupName}`}>
            <p className="body text-neutral-600">{groupName}</p>
          </div>
          <ul
            className="m-0 list-none p-0"
            role="group"
            aria-labelledby={`group:${groupName}`}
          >
            {options.map((item) => {
              const index = item['index']
              return (
                <li key={index} {...getItemProps({ item, index })}>
                  <OptionLayout
                    optionLayout={optionLayout}
                    item={item}
                    highlighted={highlightedIndex === index}
                    selected={selectedItem?.value === item.value}
                    className="pl-16"
                  />
                </li>
              )
            })}
          </ul>
        </React.Fragment>
      ))}
    </div>
  )
}
