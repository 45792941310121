import { Component, ErrorInfo, ReactNode } from 'react'

import { ErrorGeneral } from '@cais-group/approved/ui/error/general'
import { APPS } from '@cais-group/shared/domain/apps'
import { GeneralError } from '@cais-group/shared/util/general-error'
import { logError } from '@cais-group/shared/util/logging'

type Props = {
  children: ReactNode
  appName: APPS
}

type State = {
  hasError: boolean
  error?: Error
}

export class AppErrorBoundary extends Component<Props, State> {
  public override state: State = {
    hasError: false,
    error: undefined,
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.logError(error, errorInfo)
  }

  logError(error: Error, errorInfo?: ErrorInfo) {
    const { appName } = this.props

    logError({
      message: `Uncaught error on APP ${appName}`,
      error,
      context: {
        appName,
        componentStack: errorInfo?.componentStack,
      },
    })
  }

  handleErrorAndLog(error: Error) {
    this.setState({ hasError: true, error })
    this.logError(error)
  }

  override componentDidMount() {
    // global errors that happens in events, etc.
    window.onerror = (message, file, lineNo, columnNo, error) => {
      if (error) {
        this.handleErrorAndLog(error)
        // prevent firing the default event handler
        return true
      }

      // some browsers like Edge don't supply the last error argument
      const errorMessage = JSON.stringify({
        message,
        file,
        lineNo,
        columnNo,
      })
      this.handleErrorAndLog(new Error(errorMessage))

      return true
    }

    // Async errors
    window.onunhandledrejection = (event: PromiseRejectionEvent) => {
      event.preventDefault()
      this.handleErrorAndLog(event.reason)
    }
  }

  public override render() {
    if (this.state.hasError) {
      if (this.state.error instanceof GeneralError) {
        return (
          <ErrorGeneral
            title={this.state.error.title}
            description={this.state.error.description}
          />
        )
      }
      return <ErrorGeneral />
    }

    return this.props.children
  }
}
