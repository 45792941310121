import { ExperienceFields } from '@cais-group/caisiq/util/type/manage-caisiq'
import { CreateExperienceRequest } from '@cais-group/shared/util/type/caisiq-be'

export const transformExperienceRequest = (
  params: ExperienceFields
): CreateExperienceRequest => {
  const firmsOrEmpty =
    params.experienceType === 'DIRECT' || !params.firms
      ? []
      : params.firms.map((f) => f.id)

  return {
    firmIds: firmsOrEmpty,
    active: Boolean(params.active),
    name: params.name,
    experienceType: params.experienceType,
    catalogId: params.catalogId,
    label: params.label,
    ceCreditEnabled: params.ceCreditEnabled,
    coursesEnabled: params.coursesEnabled,
    contentfulId: params.contentfulId,
  }
}
