import { AxiosError } from 'axios'

import { components } from './generated'

export interface CELicenseNumber {
  number: string
  targetCompletionDate?: string
}

export type LearnerDto = components['schemas']['LearnerDto']

export type Learner = Omit<components['schemas']['Learner'], 'type'> & {
  type: 'OK'
}

export type PutUserLicencesError = Omit<
  components['schemas']['Learner'],
  'type'
> & {
  type: 'INVALID'
} & components['schemas']['LicenseValidationError']

export type PutUserLicencesErrorResponse = AxiosError<PutUserLicencesError>
