import Downshift, { Actions, DownshiftState, PropGetters } from 'downshift'
import {
  ForwardRefExoticComponent,
  ForwardRefRenderFunction,
  Fragment,
  forwardRef,
  useCallback,
  useRef,
  useId,
} from 'react'

import { ButtonProps } from '@cais-group/equity/atoms/button'
import {
  ListItemText,
  ListItemTextWithCheckbox,
  ListItemProps,
  ListItemTextProps,
} from '@cais-group/equity/molecules/list-item'

import { DefaultInput, InputProps } from './components/input'
import {
  DefaultMenu,
  MenuContainer as DefaultMenuContainer,
  MenuActions,
  MenuContainerProps,
  MenuProps,
} from './components/menu'
import {
  DefaultMultiValue,
  DefaultValueContainer,
  MultiValueProps,
  ValueContainerProps,
} from './components/multi-value'
import {
  DefaultRoot,
  DefaultWrapper,
  RootProps,
  WrapperProps,
} from './components/root'
import { DefaultSingleValue, SingleValueProps } from './components/single-value'
import {
  DefaultToggleButton,
  ToggleButtonProps,
} from './components/toggle-button'
import {
  FilterOptionsInput,
  defaultFilterOptionsFn,
  useSelectedOptions,
  BaseOptionType,
  SingleSelectType,
  MultiSelectType,
} from './utils'

type RenderListType<Item> = Pick<
  DownshiftState<Item>,
  'highlightedIndex' | 'inputValue'
> &
  Pick<PropGetters<Item>, 'getItemProps'> &
  Pick<Actions<Item>, 'toggleMenu' | 'setState'>

export type OptionType<T> = BaseOptionType & {
  label?: string
  category?: React.ReactNode | string
  group?: React.ReactNode | string
} & ListItemProps &
  T

type ForwardRefType<ElementWithRef, Props> =
  | ForwardRefExoticComponent<React.RefAttributes<ElementWithRef> & Props>
  | ForwardRefRenderFunction<ElementWithRef, Props>
  | React.ComponentType<Props>

export type CommonSelectProps<T> = {
  id?: string
  options: OptionType<T>[]
  isLoading?: boolean
  placeholder?: string
  inputChildren?: React.ReactNode
  onScrollToBottomOfOptions?: () => void
  filterOptionsFn?: (
    input: FilterOptionsInput<OptionType<T>>
  ) => OptionType<T>[]
  isSearchable?: boolean
  disabled?: boolean
  showSelectedValues?: boolean
  menuActions?: [ButtonProps, ButtonProps]
  components?: {
    Root?: ForwardRefType<HTMLDivElement, RootProps>
    Container?: React.ComponentType<ValueContainerProps> | null
    ToggleButton?: ForwardRefType<HTMLButtonElement, ToggleButtonProps> | null
    Input?: ForwardRefType<HTMLInputElement, InputProps>
    SingleValue?: React.ComponentType<SingleValueProps<T>>
    MultiValue?: React.ComponentType<MultiValueProps<T>>
    Option?: ForwardRefType<HTMLLIElement, OptionType<T>>
    Menu?: ForwardRefType<HTMLUListElement, MenuProps>
    MenuContainer?: React.ComponentType<MenuContainerProps>
    Wrapper?: React.ComponentType<WrapperProps>
    List?: React.ComponentType
    CategoryTitle?: React.ComponentType<
      React.HTMLAttributes<HTMLElement> & { role: 'presentation' }
    >
    GroupTitle?: React.ComponentType<
      React.HTMLAttributes<HTMLElement> & { role: 'presentation' }
    >
  }
  testId?: string
  removeOptionOnBackspace?: boolean
}

export type SingleSelectProps<T> = CommonSelectProps<T> & {
  isMulti?: false
} & Pick<SingleSelectType<OptionType<T>>, 'onChange' | 'value'>

export type MultiSelectProps<T> = CommonSelectProps<T> & {
  isMulti: true
} & Pick<MultiSelectType<OptionType<T>>, 'onChange' | 'value'>

export type SelectProps<T> = SingleSelectProps<T> | MultiSelectProps<T>

const getDefaultOption = (isMulti: boolean) =>
  forwardRef<HTMLLIElement, ListItemTextProps>((props, ref) => (
    <div className="my-8">
      {isMulti ? (
        <ListItemTextWithCheckbox ref={ref} {...props} />
      ) : (
        <ListItemText ref={ref} {...props} />
      )}
    </div>
  ))
/**
Select component with customizable components and props.
@param {Object} props.components - Customizable components for the Select component.
@param {React.ComponentType} [props.components.Container=DefaultValueContainer] - Custom container component.
@param {React.ComponentType} [props.components.Root=DefaultRoot] - Custom root component.
@param {React.ComponentType} [props.components.ToggleButton=DefaultToggleButton] - Custom toggle button component.
@param {React.ComponentType} [props.components.Input=SelectInput] - Custom input component.
@param {React.ComponentType} [props.components.Option=ListItemText|ListItemTextWithCheckbox] - Custom option component.
@param {React.ComponentType} [props.components.Input=DefaultInput] - Custom input component.
@param {React.ComponentType} [props.components.MultiValue=DefaultMultiValue] - Custom multi value component.
@param {React.ComponentType} [props.components.Menu=DefaultMenu] - Custom menu component.
@param {React.ComponentType} [props.components.MenuContainer=DefaultMenuContainer] - Custom menu container component.
@param {React.ComponentType} [props.components.Wrapper=DefaultWrapper] - Custom wrapper component.
@param {React.ComponentType} [props.components.List='ul'] - Custom list component.
@param {React.ComponentType} [props.components.CategoryTitle='li'] - Custom category title component.
@param {React.ComponentType} [props.components.GroupTitle='li'] - Custom group title component.
@param {boolean} [props.isSearchable=true] - Determines if the select component is searchable.
@param {boolean} [props.isMulti=false] - Determines if the select component allows multiple selections.
@param {Array} props.menuActions - An array of custom actions for the menu e.g. Reset and Apply
@param {Array} props.options - An array of options for the select component.
@param {string} [props.placeholder] - The placeholder text for the select component.
@param {Function} [props.onChange] - The callback function to be called when the select value changes.
@param {any} [props.value] - The current value(s) of the select component.
@returns {React.ReactElement} - The rendered Select component.
*/
export const Select = <T = BaseOptionType,>({
  id,
  components,
  isSearchable = true,
  isLoading = false,
  isMulti = false,
  showSelectedValues = true,
  disabled = false,
  menuActions,
  options,
  filterOptionsFn = defaultFilterOptionsFn<OptionType<T>>('value'),
  onScrollToBottomOfOptions,
  placeholder = 'Select',
  inputChildren,
  onChange,
  testId,
  value,
  removeOptionOnBackspace = true,
}: SelectProps<T>) => {
  const altId = useId()
  const { selectedOptions, addOption, removeOption } = useSelectedOptions<
    OptionType<T>
  >({
    isMulti: isMulti as typeof isMulti extends true ? true : false,
    options,
    value: value as typeof isMulti extends true
      ? MultiSelectType<T>['value']
      : SingleSelectType<T>['value'],
    onChange: onChange as typeof isMulti extends true
      ? MultiSelectType<T>['onChange']
      : SingleSelectType<T>['onChange'],
  })

  const inputRef = useRef<HTMLInputElement>(null)
  const Root = components?.Root ?? DefaultRoot
  const ToggleButton =
    components?.ToggleButton === null
      ? () => null
      : components?.ToggleButton ?? DefaultToggleButton
  const Input = components?.Input ?? DefaultInput
  const Option = components?.Option ?? getDefaultOption(isMulti)
  const SingleValue = components?.SingleValue ?? DefaultSingleValue
  const MultiValue = components?.MultiValue ?? DefaultMultiValue
  const Menu = components?.Menu ?? DefaultMenu
  const MenuContainer = components?.MenuContainer ?? DefaultMenuContainer
  const Wrapper = components?.Wrapper ?? DefaultWrapper
  const List = components?.List ?? 'ul'
  const CategoryTitle = components?.CategoryTitle ?? 'li'
  const GroupTitle = components?.GroupTitle ?? 'li'

  const renderList = useCallback(
    ({
      getItemProps,
      highlightedIndex,
      inputValue,
      toggleMenu,
      setState,
    }: RenderListType<OptionType<T>>) => {
      const renderOption = (option: OptionType<T>, index: number) => {
        const isSelected = !!selectedOptions.find(
          (selectedOption) => selectedOption.value === option.value
        )

        const itemProps = getItemProps({
          key: option.value,
          index,
          item: option,
        })

        return (
          <Option
            {...itemProps}
            key={option.value}
            isFocused={highlightedIndex === index}
            isSearchable={isSearchable}
            inputValue={inputValue}
            onClick={
              isMulti && isSelected
                ? () => {
                    removeOption(option)
                  }
                : () => {
                    if (!isSelected) {
                      addOption(option)
                    }
                    if (!isMulti) {
                      inputRef?.current?.blur()
                      toggleMenu()
                    }
                    setState({
                      type: Downshift.stateChangeTypes.clickItem,
                    })
                  }
            }
            isSelected={isSelected}
            data-testid={`${testId ? `${testId}-` : ''}select-option`}
            role="option"
            {...option}
          />
        )
      }

      const renderedOptions = filterOptionsFn({
        options,
        isSearchable,
        inputValue,
      }).map((option, index, opts) => (
        <Fragment key={`set-${option.value}`}>
          {option['category'] &&
          (index === 0 ||
            opts[index - 1]['category'] !== option['category']) ? (
            <CategoryTitle role="presentation" className="mt-8 p-8 font-bold">
              {option['category']}
            </CategoryTitle>
          ) : null}
          {option['group'] &&
          (index === 0 || opts[index - 1]['group'] !== option['group']) ? (
            <GroupTitle
              role="presentation"
              className="overtext mx-8 bg-neutral-100 px-8 py-0"
            >
              {option['group']}
            </GroupTitle>
          ) : null}
          {renderOption(option, index)}
        </Fragment>
      ))

      return renderedOptions.length ? (
        <List>{renderedOptions}</List>
      ) : (
        <div className="bodySmall select-none text-neutral-500">
          No matches found
        </div>
      )
    },
    [
      CategoryTitle,
      GroupTitle,
      List,
      Option,
      addOption,
      filterOptionsFn,
      isMulti,
      isSearchable,
      options,
      removeOption,
      selectedOptions,
      testId,
    ]
  )

  return (
    <Downshift<OptionType<T>>
      id={id ?? `select-${altId}`}
      onChange={addOption}
      onOuterClick={({ closeMenu }) => {
        closeMenu()
      }}
      // @ts-ignore
      initialSelectedItem={Array.isArray(value) ? undefined : value} // HELP?: Fix type error
      itemToString={(item) => (item ? item.value : '')}
      initialInputValue=""
      onSelect={() => {
        if (!isMulti) {
          inputRef?.current?.blur()
        }
      }}
      stateReducer={(state, changes) => {
        switch (changes.type) {
          case Downshift.stateChangeTypes.keyDownEnter:
          case Downshift.stateChangeTypes.mouseUp:
          case Downshift.stateChangeTypes.clickItem:
            // Reset the input value, maintain the multi-select behavior if enabled
            return {
              ...changes,
              inputValue: '',
              isOpen: isMulti ? state.isOpen : false,
              highlightedIndex: isMulti ? state.highlightedIndex : 0,
            }
          case Downshift.stateChangeTypes.keyDownEscape:
          case Downshift.stateChangeTypes.blurInput:
            // Reset the input value, close the menu, and reset the highlighted index
            return {
              ...changes,
              inputValue: '',
              isOpen: false,
              highlightedIndex: 0,
            }
          default:
            return changes
        }
      }}
    >
      {({
        getInputProps,
        getItemProps,
        setState,
        getMenuProps,
        getToggleButtonProps,
        isOpen,
        inputValue,
        highlightedIndex,
        getRootProps,
        toggleMenu,
      }) => {
        const rootProps = getRootProps({}, { suppressRefError: true })

        return (
          <Wrapper>
            <Root
              {...rootProps}
              disabled={disabled}
              onClick={() => !disabled && toggleMenu()}
              isOpen={isOpen}
            >
              <DefaultValueContainer>
                {showSelectedValues ? (
                  isMulti ? (
                    selectedOptions.map((selectedOption) => (
                      <MultiValue
                        key={selectedOption.value}
                        selectedOption={selectedOption}
                        onRemove={() => removeOption(selectedOption)}
                      />
                    ))
                  ) : (
                    <SingleValue
                      inputValue={inputValue}
                      isSearchable={isSearchable}
                      selectedOption={selectedOptions[0]}
                      onRemove={() => removeOption(selectedOptions[0])}
                    />
                  )
                ) : null}
                {!isSearchable && selectedOptions.length === 0
                  ? placeholder
                  : null}
                {isSearchable ? (
                  <Input
                    {...getInputProps({
                      ref: inputRef,
                      onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
                        if (
                          event.key === 'Backspace' &&
                          !inputValue &&
                          isMulti &&
                          removeOptionOnBackspace
                        ) {
                          removeOption(
                            selectedOptions[selectedOptions.length - 1]
                          )
                        }
                      },
                    })}
                    disabled={disabled}
                    placeholder={
                      selectedOptions.length === 0 ? placeholder : ''
                    }
                    isSearchable={isSearchable}
                    isOpen={isOpen}
                    selectedOptionsLength={selectedOptions.length}
                    data-testid={`${testId ? `${testId}-` : ''}select-input`}
                  >
                    {inputChildren}
                  </Input>
                ) : null}
              </DefaultValueContainer>
              <ToggleButton
                aria-label="toggle menu"
                type="button"
                {...getToggleButtonProps({
                  disabled,
                  onClick(event) {
                    event.stopPropagation()
                  },
                })}
                id={`${id}-button`}
              />
            </Root>
            <MenuContainer isOpen={isOpen}>
              <Menu
                isSearchable={isSearchable}
                inputValue={inputValue}
                isOpen={isOpen}
                isLoading={isLoading}
                onScrollToBottomOfOptions={onScrollToBottomOfOptions}
                aria-label={placeholder}
                data-testid={`${testId ? `${testId}-` : ''}select-menu`}
                {...getMenuProps()}
              >
                {isOpen
                  ? renderList({
                      getItemProps,
                      setState,
                      inputValue,
                      highlightedIndex,
                      toggleMenu,
                    })
                  : null}
              </Menu>
              {menuActions?.length ? (
                <MenuActions menuActions={menuActions} />
              ) : null}
            </MenuContainer>
          </Wrapper>
        )
      }}
    </Downshift>
  )
}
