import {
  AlphabeticalOrNumericOrDate,
  WithinGroupSortConfig,
} from '@cais-group/shared/util/use-within-group-sort-config'

import {
  ColumnHeaderMenuItem,
  SortIndicatorIcon,
} from './shared-ui-sort-menu-column-header'

/**
 * When columns have an id, work with generic sort config you can use this to build a set of actions.
 */
export function actionsForColumnHeader({
  id,
  setSortConfig,
  hideColumn,
  sortKind,
  required,
  auxillarySortKind,
  setAuxillarySortConfig,
}: {
  id: string
  setSortConfig?: (sortConfig: WithinGroupSortConfig) => void
  hideColumn?: (id: string) => void
  sortKind?: AlphabeticalOrNumericOrDate
  auxillarySortKind?: AlphabeticalOrNumericOrDate
  setAuxillarySortConfig?: (sortConfig: WithinGroupSortConfig) => void
  required?: boolean
}): ColumnHeaderMenuItem[] | undefined {
  // This is a bit verbose but explicit, flexible and works with the serialisable form of in group sort
  let actions: ColumnHeaderMenuItem[] = []
  const setter = auxillarySortKind ? setAuxillarySortConfig : setSortConfig
  if (setter)
    switch (sortKind ?? auxillarySortKind) {
      case 'alphabetical':
        actions = [
          {
            onClick: () => {
              setter({
                sortId: id,
                sortOrder: 'asc',
              })
            },
            icon: 'alphabetical',
            label: 'Sort A to Z',
          },
          {
            onClick: () => {
              setter({
                sortId: id,
                sortOrder: 'desc',
              })
            },
            icon: 'alphabeticalReverse',
            label: 'Sort Z to A',
          },
        ]
        break
      case 'numeric':
        actions = [
          {
            onClick: () => {
              setter({
                sortId: id,
                sortOrder: 'asc',
              })
            },
            icon: 'sortReverse',
            label: 'Sort low to high',
          },
          {
            onClick: () => {
              setter({
                sortId: id,
                sortOrder: 'desc',
              })
            },
            icon: 'sort',
            label: 'Sort high to low',
          },
        ]
        break
      case 'date':
        actions = [
          {
            onClick: () => {
              setter({
                sortId: id,
                sortOrder: 'asc',
              })
            },
            icon: 'date',
            label: 'Sort oldest to newest',
          },
          {
            onClick: () => {
              setter({
                sortId: id,
                sortOrder: 'desc',
              })
            },
            icon: 'dateReverse',
            label: 'Sort newest to oldest',
          },
        ]
        break
      default:
        actions = []
        !!sortKind &&
          console.warn(
            `Have sort kind but unable to get actions for ${sortKind}`
          )
    }

  if (!required && hideColumn) {
    actions.push({
      onClick: () => {
        hideColumn(id)
      },
      icon: 'hide',
      label: 'Hide column',
    })
  }

  return actions.length > 0 ? actions : undefined
}

export function sortIconForColumnHeader(
  primary: {
    order: 'asc' | 'desc' | null | undefined
    kind: 'alphabetical' | 'numeric' | 'date' | null | undefined
  },
  auxiliary?: {
    order: 'asc' | 'desc' | null | undefined
    kind: 'alphabetical' | 'numeric' | 'date' | null | undefined
  }
): SortIndicatorIcon | undefined {
  let { kind, order } = primary
  if (!order) {
    kind = auxiliary?.kind
    order = auxiliary?.order
  }

  if (kind === 'alphabetical' && order) {
    return order === 'asc' ? 'alphabetical' : 'alphabeticalReverse'
  }

  if (kind === 'numeric' && order) {
    return order === 'asc' ? 'sortReverse' : 'sort'
  }

  if (kind === 'date' && order) {
    return order === 'asc' ? 'date' : 'dateReverse'
  }

  return undefined
}
