import { CircularProgress } from '@mui/material'
import classNames from 'classnames'
import { useCombobox } from 'downshift'
import * as React from 'react'
import { useSpinDelay } from 'spin-delay'

import { BodySmall } from '@cais-group/approved/ui/typography'
import { Icon } from '@cais-group/equity/atoms/icon'
import { StatusTag, StatusTagProps } from '@cais-group/equity/atoms/status-tag'

import { Tooltip } from '../../components/tooltip'
import { useEventListeners } from '../../hooks'
import { useSelectAsyncInitialValueOrReset } from '../../hooks/use-select-async-initial-value-or-reset'
import { UniformFieldOption } from '../../types'
import { ActionButton } from '../common/action-button'
import {
  InfiniteLoadingIndicator,
  useWatchLastItemInList,
} from '../common/infinite-loading'
import { OptionLayout, OptionLayoutProps } from '../common/option-layout'
import {
  CustomOptionsLayouts,
  type OptionsLayoutProps,
} from '../common/options-layout'

const useOptionsState = (
  options: UniformFieldOption[],
  { serverFiltering = false }: { serverFiltering?: boolean } = {}
) => {
  const [items, setItems] = React.useState<UniformFieldOption[]>(options)

  React.useEffect(() => {
    setItems(options)
  }, [options, serverFiltering])

  return [items, setItems] as const
}

export interface ComboBoxProps extends OptionLayoutProps, OptionsLayoutProps {
  id: string
  name?: string
  placeholder?: string
  value: string
  onBlur?: () => void
  onFocus?: () => void
  onChange: (option?: UniformFieldOption | null) => void
  label?: string
  options: UniformFieldOption[]
  required?: boolean
  disabled?: boolean
  errorMessage?: string
  error?: boolean
  isLoading?: boolean
  hasNextPage?: boolean
  tag?: StatusTagProps
  tooltip?: string
  dropdownActionName?: string
  dropdownAction?: (finalRef: HTMLButtonElement | null) => void
  fetchNextPage?: () => void
  setAsyncOptionsFilter?: (inputValue?: string) => void
  depsValues: string[]
}
export const ComboBox = React.forwardRef<HTMLInputElement, ComboBoxProps>(
  (
    {
      id,
      name,
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {},
      value,
      required,
      placeholder,
      label,
      options = [],
      error,
      errorMessage,
      optionLayout,
      optionsLayout,
      optionsLayoutGroupBy,
      fetchNextPage,
      isLoading,
      hasNextPage,
      tag,
      disabled,
      dropdownActionName,
      dropdownAction,
      tooltip,
      setAsyncOptionsFilter,
      depsValues,
    },
    ref
  ) => {
    const btnRef = React.useRef<HTMLButtonElement>(null)
    const [usingKeyboard, setUsingKeyboard] = React.useState(false)
    const [items, setItems] = useOptionsState(options, {
      serverFiltering: [setAsyncOptionsFilter, fetchNextPage].some(
        (fn) => typeof fn === 'function'
      ),
    })
    const divRef = React.useRef<HTMLDivElement>(null)
    const showLoader = useSpinDelay(Boolean(isLoading), {
      delay: 400,
      minDuration: 1000,
    })
    const {
      isOpen,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      getInputProps,
      highlightedIndex,
      getItemProps,
      selectItem,
      selectedItem,
      closeMenu,
    } = useCombobox({
      id,
      initialSelectedItem: options.find((option) => option.value === value),
      onSelectedItemChange({ selectedItem }) {
        onChange(selectedItem)
      },
      onInputValueChange({ selectedItem, inputValue }) {
        if (typeof setAsyncOptionsFilter === 'function') {
          // do not call setAsyncOptionsFilter if the selected item is the same as the input value because
          // it will trigger a call to the backend to filter out selected item
          if (selectedItem?.label === inputValue) return
          setAsyncOptionsFilter(inputValue)
        } else {
          setItems(
            options.filter((option) => {
              const lowerCasedInputValue = inputValue?.toLowerCase()
              return (
                !lowerCasedInputValue ||
                option.label.toLowerCase().includes(lowerCasedInputValue)
              )
            })
          )
        }
      },
      items,
      itemToString(item) {
        return item ? item.label : ''
      },
      stateReducer(state, actionAndChanges) {
        const { changes, type } = actionAndChanges
        switch (type) {
          case useCombobox.stateChangeTypes.InputBlur: {
            // if not using keyboard then use default behavior
            if (!usingKeyboard) return changes
            // if no dropdown action then use default behavior
            if (!dropdownAction) return changes
            // otherwise if using keyboard keep the menu open on blur to reach the action button
            return {
              ...changes,
              isOpen: true, // keep the menu open after selection.
              selectedItem: state.selectedItem ?? undefined,
            }
          }
          case useCombobox.stateChangeTypes.InputKeyDownArrowDown: {
            if (typeof fetchNextPage === 'function') {
              if (state.highlightedIndex === items.length - 1) {
                // Don't change the highlightedIndex if we're at the end of the list
                return { ...changes, highlightedIndex: state.highlightedIndex }
              }
              return changes
            }
            return changes
          }
          default:
            return changes
        }
      },
    })
    useSelectAsyncInitialValueOrReset({
      options,
      selectItem: (value) => {
        if (!Array.isArray(value) || value === null) {
          selectItem(value)
        }
      },
      value: selectedItem?.value ?? value,
      depsValues,
    })
    useEventListeners(['keydown', 'mousedown'], {
      enabled: isOpen,
      subscribe: (type) => setUsingKeyboard(type === 'keydown'),
    })
    useWatchLastItemInList(divRef, {
      callback: () => {
        if (isOpen && typeof fetchNextPage === 'function') {
          fetchNextPage()
        }
      },
      dependencies: [isOpen],
    })

    return (
      <div className="relative w-full">
        <div className="flex flex-col gap-4">
          <label
            className="bodySmall flex w-fit items-center text-neutral-600"
            {...getLabelProps()}
          >
            <span className="flex items-center gap-8">
              {label}{' '}
              {required ? <span className="text-error-500">*</span> : null}
              {tag && tag.children !== '' && (
                <div className="my-8">
                  <StatusTag {...tag} />
                </div>
              )}
            </span>
            {tooltip && <Tooltip tooltip={tooltip} />}
          </label>
          <div
            className={classNames(
              'focus-within:outline-primary-500 bg-neutral-0 inline-flex items-center gap-8 border border-solid p-12 focus-within:outline focus-within:outline-1 focus-within:-outline-offset-1',
              disabled && 'bg-neutral-100',
              error || errorMessage
                ? 'border-error-500 border-1'
                : 'border-neutral-200'
            )}
          >
            <Icon type="Search" color="eq-color-neutral-900" size="small" />
            <div className="bodySmall relative flex grow">
              <input
                className="w-full border-none focus:outline-none"
                {...getInputProps({
                  onFocus,
                  onBlur,
                  name,
                  ref,
                  placeholder,
                  disabled,
                })}
              />
              {showLoader ? (
                <div className="absolute bottom-0 right-0 top-0">
                  <CircularProgress size={24} thickness={5} />
                </div>
              ) : (
                <button
                  aria-label="toggle menu"
                  className="flex items-center px-2"
                  type="button"
                  {...getToggleButtonProps({ ref: btnRef, disabled })}
                >
                  <Icon
                    size="small"
                    color="eq-color-neutral-900"
                    type="ArrowDropDown"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
        {optionsLayout ? (
          <div
            className={classNames(
              'body shadow-2 bg-neutral-0 max-h-376 absolute z-[5] mt-4 w-full overflow-auto p-0',
              !(isOpen && (items.length || dropdownAction)) && 'hidden'
            )}
            {...getMenuProps()}
          >
            {isOpen && (
              <>
                <CustomOptionsLayouts
                  items={items}
                  getItemProps={getItemProps}
                  optionLayout={optionLayout}
                  highlightedIndex={highlightedIndex}
                  optionsLayout={optionsLayout}
                  optionsLayoutGroupBy={optionsLayoutGroupBy}
                />
                <InfiniteLoadingIndicator
                  ref={divRef}
                  isLoading={isLoading}
                  className={hasNextPage ? '' : 'hidden'}
                />
                {dropdownAction && (
                  <ActionButton
                    text={
                      dropdownActionName ? dropdownActionName : 'Create New'
                    }
                    onBlur={closeMenu}
                    onClick={() => dropdownAction(btnRef.current)}
                  />
                )}
              </>
            )}
          </div>
        ) : (
          <ul
            className={classNames(
              'shadow-2 bg-neutral-0 max-h-376 absolute z-[5] mt-4 w-full overflow-auto px-24 pt-16',
              !(isOpen && (items.length || dropdownAction)) && 'hidden'
            )}
            {...getMenuProps()}
          >
            {isOpen && (
              <>
                {items.map((item, index) => {
                  return (
                    <li key={item.value} {...getItemProps({ item, index })}>
                      <OptionLayout
                        optionLayout={optionLayout}
                        item={item}
                        highlighted={highlightedIndex === index}
                      />
                    </li>
                  )
                })}
                <InfiniteLoadingIndicator
                  ref={divRef}
                  isLoading={isLoading}
                  className={hasNextPage ? '' : 'hidden'}
                />
              </>
            )}
            {dropdownAction && (
              <ActionButton
                text={dropdownActionName ?? 'Create New'}
                onBlur={() => closeMenu()}
                onClick={() => dropdownAction(btnRef.current)}
              />
            )}
          </ul>
        )}
        {/* TODO: error message spacing, also fix on other select boxes */}
        {error && errorMessage && (
          <BodySmall className="text-error-500">{errorMessage}</BodySmall>
        )}
      </div>
    )
  }
)
